import React from "react";
import { View, StyleSheet, Platform, Pressable } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useEffect, useState } from "react";
import { LeftArrow, MagnifyingGlass } from "../../../components/svgs/common";
import CourseDegreesTable from "./CourseDegreesTable";
import { MinusCircleIcon, PlusCircle } from "../../../components/svgIcons";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import {
  Course,
  GetCoursesBySchoolByQueryDocument,
  useAddDegreesToCourseMutation,
  useGetDegreesByCourseByQueryQuery,
  useRemoveDegreesFromCourseMutation,
  useSchoolsQuery,
} from "../../../graphql/generated/graphql";
import Pagination from "../../../components/common/Pagination/Pagination";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import Pill from "../../../components/common/Pill/Pill";
import { CourseTypes } from "../CoursesTabContainer/CoursesTable";
import { useAppConfig } from "../../../AppConfigProvider";
import AddDegreesModalContainer from "./AddDegreesModalContainer";

const modals = {
  removeDegrees: {
    title: "Remove Selected Degrees from the Course?",
    ariaLabel: "Remove Degrees From Course Modal",
    warningText: "This action cannot be undone. These degrees will be removed from the course.",
    actionButtonText: "Remove Degrees",
  },
  removeConfirmation: {
    title: "Degrees Removed",
    ariaLabel: "Degrees Removed Modal",
    warningText: "The selected degrees have been removed from the course.",
    actionButtonText: "OK",
  },
};

export enum CourseDegreesModalState {
  CLOSED = "CLOSED",
  ADD_DEGREES = "ADD_DEGREES",
  REMOVE_DEGREES = "REMOVE_DEGREES",
  REMOVE_CONFIRMATION = "REMOVE_CONFIRMATION",
}

interface CourseDegreesTabContainerProps {
  course: Pick<Course, "id" | "name" | "courseType">;
  onBack: () => void;
}

const CourseDegreesTabContainer: React.FC<CourseDegreesTabContainerProps> = ({ course, onBack }) => {
  const courseType = CourseTypes[course.courseType] || CourseTypes.General;

  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedDegrees, setSelectedDegrees] = useState<string[]>([]);
  const [modalState, setModalState] = useState<{
    type: CourseDegreesModalState;
  }>({
    type: CourseDegreesModalState.CLOSED,
  });

  const { data: getDegreesByCourseData, refetch: getDegreesByCourseRefetch } = useGetDegreesByCourseByQueryQuery({
    variables: {
      courseId: course.id,
      query: searchQuery,
      page: page,
      pageSize: pageSize,
    },
    skip: !course.id,
  });

  const [addDegreesToCourse] = useAddDegreesToCourseMutation();
  const [removeDegreesFromCourse] = useRemoveDegreesFromCourseMutation();

  const { data: schoolsData } = useSchoolsQuery();

  const refetchQueries = [
    {
      query: GetCoursesBySchoolByQueryDocument,
      variables: {
        schoolId: schoolsData?.getSchools[0].id,
        query: "",
        page: 1,
        pageSize: 5,
      },
    },
  ];

  const addDegrees = async (degreeIds: Array<string>) => {
    try {
      const result = await addDegreesToCourse({
        variables: {
          input: {
            courseId: course.id,
            degreeIds,
          },
        },
        refetchQueries,
      });

      if (result.data?.addDegreesToCourse.success) {
        getDegreesByCourseRefetch();
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleRemoveDegrees = async () => {
    if (!selectedDegrees.length) return;

    try {
      await removeDegreesFromCourse({
        variables: {
          input: {
            courseId: course.id,
            degreeIds: selectedDegrees,
          },
        },
        refetchQueries,
      });
      getDegreesByCourseRefetch();
      setSelectedDegrees([]);
      setModalState({ type: CourseDegreesModalState.REMOVE_CONFIRMATION });
    } catch (error) {
      console.error("Error removing degrees:", error);
    }
  };

  const handleModalClose = () => {
    setModalState({ type: CourseDegreesModalState.CLOSED });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= (getDegreesByCourseData?.getDegreesByCourseByQuery.count || 0)) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    getDegreesByCourseRefetch();
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <Pressable style={styles.backButton} onPress={onBack}>
            <LeftArrow />
            <CustomText text="Back" size="m" weight="semiBold" style={styles.backText} onPress={onBack} />
          </Pressable>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <Pill
                text={courseType.name}
                leftIcon={courseType.icon}
                backgroundColor={courseType.color}
                textColor={courseType.textColor}
              />
              <CustomText
                text={course.name}
                size="l"
                weight="bold"
                style={styles.headerText}
                useTranslationText={false}
              />
            </View>
            <NewCustomActionButton
              text="Add Degrees"
              onPress={() => setModalState({ type: CourseDegreesModalState.ADD_DEGREES })}
              leftIcon={<PlusCircle color={primaryColor} />}
            />
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Total Students" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${getDegreesByCourseData?.getDegreesByCourseByQuery.data.totalCount || 0})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
              {selectedDegrees.length > 0 && (
                <CustomButton
                  text={`Remove Selected`}
                  styleType="danger"
                  onPress={() => setModalState({ type: CourseDegreesModalState.REMOVE_DEGREES })}
                  leftIcon={<MinusCircleIcon />}
                  rightIcon={null}
                  style={{}}
                  textStyle={{ color: "#fff", fontWeight: "bold" }}
                  disabled={false}
                  textProps={{}}
                />
              )}
            </View>
            <View style={styles.searchContainer}>
              <MagnifyingGlass style={styles.searchIcon} />
              <CustomTextInput
                placeholder="Search by any value"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={[
                  styles.searchInput,
                  Platform.select({
                    web: {
                      outlineStyle: "none",
                      outlineWidth: 0,
                      WebkitAppearance: "none",
                    } as unknown,
                  }),
                ]}
                placeholderTextColor="#9CA3AF"
                selectionColor="#6B7280"
                cursorColor="#6B7280"
              />
            </View>
          </View>
        </View>
        <CourseDegreesTable
          degrees={getDegreesByCourseData?.getDegreesByCourseByQuery.data.degrees || []}
          setSelectedDegrees={setSelectedDegrees}
          selectedDegrees={selectedDegrees}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil(
            (getDegreesByCourseData?.getDegreesByCourseByQuery.data.totalCount || 0) / pageSize
          )}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.type === CourseDegreesModalState.REMOVE_DEGREES}
        onClose={handleModalClose}
        onAction={handleRemoveDegrees}
        modalTexts={modals.removeDegrees}
      />
      <NewCustomAxioActionModal
        visible={modalState.type === CourseDegreesModalState.REMOVE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.removeConfirmation}
      />
      <AddDegreesModalContainer
        visible={modalState.type === CourseDegreesModalState.ADD_DEGREES}
        onClose={handleModalClose}
        onSubmit={addDegrees}
        courseId={course.id}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  backButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  backText: {
    color: "#000",
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  headerText: {
    color: "#000",
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  subHeaderText: {
    color: "#000",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});

export default CourseDegreesTabContainer;
