import { View, StyleSheet } from "react-native";
import { LeftArrow, RightArrow } from "../../../components/svgs/common";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";

const Pagination = ({
  page,
  handlePageChange,
  totalPageCount,
}: {
  page: number;
  handlePageChange: (newPage: number) => void;
  totalPageCount: number;
}) => {
  const renderPaginationNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    const currentPage = page;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPageCount, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <CustomButton
          key={i}
          text={i.toString()}
          size="s"
          weight="medium"
          useTranslationText={false}
          styleType="primaryLightened"
          style={[styles.paginationButton, page === i ? styles.activeButton : styles.inactiveButton]}
          textStyle={page === i ? styles.activeText : styles.inactiveText}
          onPress={() => handlePageChange(i)}
          leftIcon={null}
          rightIcon={null}
          disabled={false}
          textProps={{}}
        />
      );
    }

    return pages;
  };

  return (
    <View style={styles.paginationContainer}>
      <CustomButton
        text=""
        styleType="primaryLightened"
        style={[styles.arrowButton, page === 1 && styles.disabledArrow]}
        onPress={() => handlePageChange(page - 1)}
        disabled={page === 1}
        leftIcon={<LeftArrow fill={page === 1 ? "#9CA3AF" : "#6B7280"} />}
        rightIcon={null}
        textStyle={{}}
        textProps={{}}
      />
      {renderPaginationNumbers()}
      <CustomButton
        text=""
        styleType="primaryLightened"
        style={[styles.arrowButton, page === totalPageCount && styles.disabledArrow]}
        onPress={() => handlePageChange(page + 1)}
        disabled={page === totalPageCount}
        leftIcon={null}
        rightIcon={<RightArrow fill={page === totalPageCount ? "#9CA3AF" : "#6B7280"} />}
        textStyle={{}}
        textProps={{}}
      />
    </View>
  );
};

export default Pagination;

const styles = StyleSheet.create({
  paginationContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
    marginTop: 16,
  },
  paginationButton: {
    minWidth: 32,
    height: 32,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 4,
  },
  activeButton: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#4285f4",
  },
  inactiveButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
  },
  arrowButton: {
    width: 32,
    height: 32,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  disabledArrow: {
    backgroundColor: "#F3F4F6",
  },
  activeText: {
    color: "#4285f4",
  },
  inactiveText: {
    color: "#6B7280",
  },
});
