import React, { useEffect } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import {
  GetCourseByIdDocument,
  Module,
  useCreateModuleMutation,
  useUpdateModuleMutation,
} from "../../../graphql/generated/graphql";
import { CustomCourseworkModulesViewModalState } from "../../../views/CustomCourseworkModulesView";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import Sentry from "../../../utils/sentry";

const moduleSchema = z.object({
  moduleName: z.string().min(1, "Module name is required"),
  order: z.number().optional(),
});

type ModuleFormData = z.infer<typeof moduleSchema>;

interface AddModuleModalProps {
  visible: boolean;
  setVisibility: (state: {
    state: CustomCourseworkModulesViewModalState;
    resourceType: "module" | "activity";
    resourceId: string;
  }) => void;
  courseId: string;
  existingModule?: Module;
}

const AddModuleModalContainer = ({ visible, setVisibility, courseId, existingModule }: AddModuleModalProps) => {
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();

  const [createModuleMutation] = useCreateModuleMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateModuleMutation] = useUpdateModuleMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const handleClose = () => {
    setVisibility({
      state: CustomCourseworkModulesViewModalState.CLOSED,
      resourceType: null,
      resourceId: null,
    });
  };

  console.log(existingModule?.title);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<ModuleFormData>({
    resolver: zodResolver(moduleSchema),
    defaultValues: {
      moduleName: existingModule?.title ?? "",
      order: existingModule?.order ?? 0,
    },
  });

  useEffect(() => {
    if (existingModule) {
      setValue("moduleName", existingModule.title);
      setValue("order", existingModule.order);
    }
  }, [existingModule]);

  const onSubmit = async (data: ModuleFormData) => {
    try {
      if (existingModule) {
        const response = await updateModuleMutation({
          variables: {
            id: existingModule.id,
            title: data.moduleName,
            order: data.order ?? 0,
          },
        });

        if (response?.data?.updateModule?.success) {
          dispatch({
            type: actions.SET_META,
            payload: { keypressIsListening: true },
          });
        } else {
          throw new Error(response?.data?.updateModule?.message || "Failed to update module");
        }
      } else {
        const response = await createModuleMutation({
          variables: {
            title: data.moduleName,
            order: data.order ?? 0,
            parentCourseId: courseId,
          },
        });

        if (response?.data?.createModule?.success) {
          dispatch({
            type: actions.SET_META,
            payload: { keypressIsListening: true },
          });
        } else {
          throw new Error(response?.data?.createModule?.message || "Failed to create module");
        }
      }
      handleClose();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  return (
    <CustomModal visible={visible} onRequestClose={handleClose} style={{ width: isMedium ? "50%" : "90%", padding: 0 }}>
      <View style={styles.container}>
        <View style={styles.header}>
          <CustomText style={styles.title} text={existingModule ? "Edit Module" : "Add Module"} />
        </View>

        <View style={styles.content}>
          <Controller
            control={control}
            name="moduleName"
            render={({ field: { value, onChange } }) => (
              <>
                <CustomText style={styles.label} text="Module Name" />
                <CustomTextInput
                  style={styles.input}
                  value={value}
                  placeholder="Enter module name"
                  onChangeText={onChange}
                  accessibilityHint="Enter your module name"
                />
                {errors?.moduleName?.message && (
                  <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.moduleName?.message} />
                )}
              </>
            )}
          />
          <CustomSpacing type="vertical" size="m" />
          <Controller
            control={control}
            name="order"
            render={({ field: { value, onChange } }) => (
              <>
                <CustomText style={styles.label} text="Order" />
                <CustomTextInput
                  style={styles.input}
                  placeholder="Ex: 9"
                  onChangeText={(text: string) => {
                    const parsed = parseInt(text, 10);
                    onChange(isNaN(parsed) ? undefined : parsed);
                  }}
                  value={value}
                />
                <CustomText
                  style={styles.helperText}
                  text="Leave empty to place at the bottom of the list"
                  useTranslationText={false}
                />
                {errors?.order?.message && (
                  <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.order?.message} />
                )}
              </>
            )}
          />
          <CustomSpacing type="vertical" size="l" />
          <View style={styles.buttonContainer}>
            <CustomButton text="Cancel" onPress={handleClose} styleType="primaryLight" size="m" />
            <CustomButton
              text="Save"
              onPress={handleSubmit(onSubmit)}
              styleType="primary"
              style={!isValid ? { backgroundColor: "#ccc" } : {}}
              size="m"
              disabled={!isValid}
            />
          </View>
        </View>
      </View>
    </CustomModal>
  );
};

export default AddModuleModalContainer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    overflow: "hidden",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    backgroundColor: "#F2F4F7",
    width: "100%",
    alignSelf: "stretch",
  },
  title: {
    fontSize: 20,
    fontWeight: "600",
    color: "#1A1A1A",
  },
  content: {
    padding: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: "600",
    color: "#1A1A1A",
    marginBottom: 8,
  },
  input: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 8,
    padding: 12,
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 16,
  },
  helperText: {
    fontSize: 14,
    color: "#667085",
    marginTop: 4,
  },
});
