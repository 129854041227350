import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_1238_4503)">
        <Path
          d="M23.37 12.037h-.75v-3a1.5 1.5 0 00-1.5-1.5h-1.5v-.75a1.5 1.5 0 00-1.5-1.5h-2.25a1.5 1.5 0 00-1.5 1.5v5.25h-4.5v-5.25a1.5 1.5 0 00-1.5-1.5H6.12a1.5 1.5 0 00-1.5 1.5v.75h-1.5a1.5 1.5 0 00-1.5 1.5v3H.87a.75.75 0 100 1.5h.75v3a1.5 1.5 0 001.5 1.5h1.5v.75a1.5 1.5 0 001.5 1.5h2.25a1.5 1.5 0 001.5-1.5v-5.25h4.5v5.25a1.5 1.5 0 001.5 1.5h2.25a1.5 1.5 0 001.5-1.5v-.75h1.5a1.5 1.5 0 001.5-1.5v-3h.75a.75.75 0 100-1.5zm-20.25 4.5v-7.5h1.5v7.5h-1.5zm5.25 2.25H6.12v-12h2.25v12zm9.75 0h-2.25v-12h2.25v12zm3-2.25h-1.5v-7.5h1.5v7.5z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1238_4503">
          <Path fill="#fff" transform="translate(.12 .787)" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
