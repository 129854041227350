import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { UnChecked, Checked } from "../../svgs/common";

function Notifications({ onChange, isEnabled, errorMessage, buttonText }) {
  return (
    <>
      <View style={styles.notificationsContainer}>
        <CustomButton
          styleType="transparent"
          role="checkbox"
          aria-checked={isEnabled}
          aria-label="checkbox button"
          style={styles.checkboxButton}
          leftIcon={isEnabled ? <Checked /> : <UnChecked />}
          onPress={onChange}
        />
        <CustomText text={buttonText} style={styles.checkboxText} />
      </View>
      {errorMessage && (
        <View style={styles.notificationsContainer}>
          <CustomText text={errorMessage} useTranslationText={false} style={{ color: "#E57373" }} />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  notificationsContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  checkboxButton: {
    padding: 0,
    marginRight: 10,
  },
  checkboxText: {
    fontSize: 14,
  },
});

export default Notifications;
