import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, LayoutAnimation } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { MotiView } from "moti";
import ClockImage from "../../../assets/Clock.png";
import { Barbell, Footprints, ThumbUp } from "../../svgs/common";
import AnalyticsFeedbackCard from "../../customCoursework/AnalyticsFeedbackCard/AnalyticsFeedbackCard";

const StudentInsights = ({ data }) => {
  const [expandedClass, setExpandedClass] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);

  const toggleClassExpand = (classId) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpandedClass(expandedClass === classId ? null : classId);
  };

  const toggleModuleExpand = (moduleId) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpandedModule(expandedModule === moduleId ? null : moduleId);
  };

  // Get data from the different analytics sources
  const studentData = data?.getStudentDetailedAnalytics?.data?.[0];
  const insightsData = data?.getStudentLearningInsights?.data?.[0];
  const chatData = data?.getStudentChatAnalytics?.data || [];

  if (!studentData) {
    return <Text>No data available</Text>;
  }

  // Count total active sessions (chats)
  const countTotalActiveSessions = () => {
    return chatData.length || 0;
  };

  // Convert total time from seconds to hours
  const calculateHours = () => {
    const totalSeconds = studentData.total_time_taken || 0;
    return (totalSeconds / 3600).toFixed(2); // 3600 seconds in an hour
  };

  // Directly use questions_asked from the student data
  const totalQuestions = studentData.questions_asked || 0;

  // Calculate total active sessions
  const totalActiveSessions = countTotalActiveSessions();

  // Get total hours spent
  const formattedTotalHours = calculateHours();

  // Determine if an activity is focused on success or needs improvement
  const categorizeActivity = (activity, successPoints, confusionPoints) => {
    // First check if we have actual properties on the activity
    const activitySuccessPoints = activity.success_points || 0;
    const activityConfusionPoints = activity.confusion_points || 0;

    // Consider both points from chats and the activity itself
    const totalSuccessPoints = successPoints.length + activitySuccessPoints;
    const totalConfusionPoints = confusionPoints.length + activityConfusionPoints;

    // Check engagement metrics too
    const engagementScore = (activity.active_interaction_count || 0) + (activity.questions_asked || 0);
    const distractions = activity.distractions || 0;

    // Determine the category based on points, engagement, and distractions
    if (totalSuccessPoints > totalConfusionPoints && engagementScore > distractions) {
      return "success";
    } else if (totalConfusionPoints > totalSuccessPoints || distractions > engagementScore) {
      return "needs-focus";
    } else if (activity.completion_percentage && activity.completion_percentage > 75) {
      // If high completion percentage, consider it a success
      return "success";
    } else {
      // Default to balanced if we can't clearly decide
      return "balanced";
    }
  };

  // Transform data for class breakdown display
  const transformStudentData = () => {
    if (!studentData.details || !studentData.details.courses) {
      return [];
    }

    return studentData.details.courses.map((course) => {
      const modules =
        course.details && course.details.modules
          ? course.details.modules
              .filter((module) => module)
              .map((module) => {
                // Extract activities from module data
                let activities = [];

                if (module.details && module.details.activities) {
                  // Map activities from the module details
                  activities = module.details.activities.map((activity) => {
                    // Get chats related to this activity
                    const activityChats = chatData.filter((chat) => chat.activityId === activity.id);

                    // Extract success and confusion points from chats and activity
                    const successPoints = [];
                    const confusionPoints = [];
                    let totalTime = activity.total_time_taken || 0;

                    // Extract completion percentage from activity data
                    const completion = activity.completion_percentage
                      ? activity.completion_percentage.toFixed(2) + "%"
                      : "0%";

                    // Extract success and confusion points from chats
                    activityChats.forEach((chat) => {
                      if (chat.success_points && chat.success_points.length) {
                        successPoints.push(...chat.success_points);
                      }
                      if (chat.confusion_points && chat.confusion_points.length) {
                        confusionPoints.push(...chat.confusion_points);
                      }
                      totalTime += chat.total_time_taken || 0;
                    });

                    // If no real success points, extract from objectives data if available
                    if (successPoints.length === 0 && activity.details && activity.details.objectives) {
                      activity.details.objectives.forEach((objective) => {
                        if (objective.progress_details && objective.progress_details.success_points) {
                          successPoints.push(...objective.progress_details.success_points);
                        }
                      });
                    }

                    // If no real confusion points, extract from objectives data if available
                    if (confusionPoints.length === 0 && activity.details && activity.details.objectives) {
                      activity.details.objectives.forEach((objective) => {
                        if (objective.progress_details && objective.progress_details.confusion_points) {
                          confusionPoints.push(...objective.progress_details.confusion_points);
                        }
                      });
                    }

                    // If we still have no data points, use global insights as a last resort
                    if (successPoints.length === 0 && insightsData && insightsData.success_points_details) {
                      // Get a relevant success point from global insights
                      const randomIndex = Math.floor(Math.random() * insightsData.success_points_details.length);
                      if (insightsData.success_points_details[randomIndex]) {
                        successPoints.push(insightsData.success_points_details[randomIndex]);
                      }
                    }

                    if (confusionPoints.length === 0 && insightsData && insightsData.confusion_points_details) {
                      // Get a relevant confusion point from global insights
                      const randomIndex = Math.floor(Math.random() * insightsData.confusion_points_details.length);
                      if (insightsData.confusion_points_details[randomIndex]) {
                        confusionPoints.push(insightsData.confusion_points_details[randomIndex]);
                      }
                    }

                    // If we still have no data, create at least one generic data point
                    if (successPoints.length === 0) {
                      successPoints.push("Engaged effectively with the learning content");
                    }

                    if (confusionPoints.length === 0) {
                      confusionPoints.push("Could benefit from reviewing key concepts");
                    }

                    // Determine activity category
                    const activityType = categorizeActivity(activity, successPoints, confusionPoints);

                    return {
                      id: activity.id,
                      name: activity.label,
                      timeSpent: `${(totalTime / 3600).toFixed(2)} hrs`,
                      successPoints: successPoints,
                      confusionPoints: confusionPoints,
                      completion: completion,
                      type: activityType,
                    };
                  });
                }

                // Process any chats related to this module that aren't already included with an activity
                const moduleChats = chatData.filter(
                  (chat) => chat.moduleId === module.id && !activities.some((a) => a.id === chat.activityId)
                );

                if (moduleChats.length > 0) {
                  // Group chats by activity to combine metrics
                  const activitiesByChat = {};

                  moduleChats.forEach((chat) => {
                    const activityId = chat.activityId || `chat-${chat.id}`;
                    if (!activitiesByChat[activityId]) {
                      activitiesByChat[activityId] = {
                        id: activityId,
                        name: chat.activityName || `Activity in ${module.label}`,
                        timeSpent: 0,
                        successPoints: [],
                        confusionPoints: [],
                        completion: "N/A", // Will update if we have data
                        type: "balanced", // Default, will update based on data
                      };
                    }

                    // Add chat data to activity aggregates
                    const activity = activitiesByChat[activityId];
                    activity.timeSpent += chat.total_time_taken || 0;

                    // Add success/confusion points
                    if (chat.success_points && chat.success_points.length) {
                      activity.successPoints.push(...chat.success_points);
                    }
                    if (chat.confusion_points && chat.confusion_points.length) {
                      activity.confusionPoints.push(...chat.confusion_points);
                    }
                  });

                  // Process the chat-based activities
                  const chatActivities = Object.values(activitiesByChat).map((activity) => {
                    // Format time in hours
                    activity.timeSpent = `${(activity.timeSpent / 3600).toFixed(2)} hrs`;

                    // Make sure we have at least one point in each category
                    if (activity.successPoints.length === 0 && insightsData?.success_points_details?.length > 0) {
                      activity.successPoints.push(insightsData.success_points_details[0]);
                    } else if (activity.successPoints.length === 0) {
                      activity.successPoints.push("Successfully engaged with content");
                    }

                    if (activity.confusionPoints.length === 0 && insightsData?.confusion_points_details?.length > 0) {
                      activity.confusionPoints.push(insightsData.confusion_points_details[0]);
                    } else if (activity.confusionPoints.length === 0) {
                      activity.confusionPoints.push("Continue reviewing concepts");
                    }

                    // Determine if this activity was more success or needs focus
                    activity.type =
                      activity.successPoints.length >= activity.confusionPoints.length ? "success" : "needs-focus";

                    return activity;
                  });

                  // Add chat-based activities to our activity list
                  activities = [...activities, ...chatActivities];
                }

                // If we still don't have activities but have module-level data, create one placeholder activity
                if (activities.length === 0 && module.label) {
                  const moduleTimeHours = module.total_time_taken > 0 ? (module.total_time_taken / 3600).toFixed(2) : 0;

                  // Try to use real success/confusion points from module-level data or insights
                  let successPoints = [];
                  let confusionPoints = [];

                  // Look for module-level metrics
                  if (module.success_points > 0 || module.confusion_points > 0) {
                    // Determine activity type based on module metrics
                    const moduleType = module.success_points > module.confusion_points ? "success" : "needs-focus";

                    // Use global insights for actual points
                    if (insightsData?.success_points_details?.length > 0) {
                      successPoints = [insightsData.success_points_details[0]];
                    } else {
                      successPoints = ["Successfully engaged with module content"];
                    }

                    if (insightsData?.confusion_points_details?.length > 0) {
                      confusionPoints = [insightsData.confusion_points_details[0]];
                    } else {
                      confusionPoints = ["Review module material for improved understanding"];
                    }

                    activities.push({
                      id: `placeholder-${module.id}`,
                      name: `Overview: ${module.label}`,
                      timeSpent: `${moduleTimeHours} hrs`,
                      successPoints: successPoints,
                      confusionPoints: confusionPoints,
                      completion: module.completion_percentage ? module.completion_percentage.toFixed(2) + "%" : "0%",
                      type: moduleType,
                    });
                  }
                }

                return {
                  id: module.id,
                  name: module.label,
                  activities,
                  completion: module.completion_percentage ? module.completion_percentage.toFixed(2) + "%" : "0%",
                  timeSpent: `${(module.total_time_taken / 3600).toFixed(2)} hrs`,
                };
              })
          : [];

      // Calculate time spent for course in hours from seconds
      const timeSpentHours = course.total_time_taken > 0 ? (course.total_time_taken / 3600).toFixed(2) : 0;

      return {
        id: course.id,
        name: course.label,
        modules,
        completion: course.completion_percentage ? course.completion_percentage.toFixed(2) + "%" : "0%",
        timeSpent: `${timeSpentHours} hrs`,
      };
    });
  };

  // Use the getStudentLearningInsights data if available
  const getHighlightsFromAPI = () => {
    if (insightsData && insightsData.success_points_details && insightsData.success_points_details.length > 0) {
      return insightsData.success_points_details;
    }

    // Fallback if no specific insights data
    return ["Keep up the great work!"];
  };

  const getConfusionPointsFromAPI = () => {
    if (insightsData && insightsData.confusion_points_details && insightsData.confusion_points_details.length > 0) {
      return insightsData.confusion_points_details;
    }

    // Fallback if no specific insights data
    return ["Continue reviewing topics to strengthen understanding."];
  };

  const getFurtherRecommendationsFromAPI = () => {
    if (insightsData && insightsData.further_recommendations && insightsData.further_recommendations.length > 0) {
      return insightsData.further_recommendations;
    }

    // Fallback if no specific insights data
    return ["Keep practicing regularly to reinforce your understanding."];
  };

  const feedbackData = () => {
    const successHighlights = getHighlightsFromAPI();
    const confusionHighlights = getConfusionPointsFromAPI();
    const furtherRecommendations = getFurtherRecommendationsFromAPI();

    return [
      {
        title: "What They Are Doing Well",
        description:
          "The student demonstrates strong engagement with thoughtful questions, showing creative thinking and quickly grasping challenging concepts.",
        highlights: successHighlights,
        icon: <ThumbUp color="#FFFFFF" />,
        iconColor: "#66C61C",
      },
      {
        title: "Where They Can Improve",
        description:
          "Areas for development include refining explanations and reviewing complex problem-solving approaches for better clarity.",
        highlights: confusionHighlights,
        icon: <Barbell color="#FFFFFF" />,
        iconColor: "#0084FF",
      },
      {
        title: "Suggested Next Steps",
        description: "Encourage continued enthusiasm and dedication to achieve even better learning outcomes.",
        highlights: furtherRecommendations,
        icon: <Footprints color="#FFFFFF" />,
        iconColor: "#8E55EA",
      },
    ];
  };

  const feedback = feedbackData();
  const classes = transformStudentData();

  return (
    <View>
      <CustomText
        useTranslationText={false}
        textType="text"
        text="Student Learning Snapshot"
        size="xl"
        style={{ marginTop: 48 }}
      />
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.engagementContainer}>
          <View style={{ flex: 1, gap: 16 }}>
            <CustomText
              useTranslationText={false}
              textType="text"
              text="Total Engagement"
              size="s"
              style={{ color: "#101828", letterSpacing: 1.26, textTransform: "uppercase" }}
            />
            <View style={{ gap: 8, alignSelf: "stretch" }}>
              <CustomText
                useTranslationText={false}
                textType="text"
                text="Total Time Spent"
                size="xxl"
                style={{ color: "#667085" }}
              />
              <CustomText
                useTranslationText={false}
                textType="text"
                text={`${formattedTotalHours} Hours`}
                weight="ultraBold"
                style={{ paddingTop: 8, fontSize: 36, color: "#101828" }}
              />
            </View>
            <View>
              <View style={{ flexDirection: "row", gap: 3 }}>
                <CustomText
                  useTranslationText={false}
                  textType="text"
                  text="Active sessions:"
                  size="m"
                  style={{ color: "#667085" }}
                />
                <CustomText
                  useTranslationText={false}
                  size="m"
                  text={totalActiveSessions}
                  weight="ultraBold"
                  style={{ color: "#667085" }}
                />
              </View>
              <View style={{ flexDirection: "row", gap: 3 }}>
                <CustomText
                  useTranslationText={false}
                  textType="text"
                  text="Questions asked:"
                  size="m"
                  style={{ color: "#667085" }}
                />
                <CustomText
                  useTranslationText={false}
                  size="m"
                  text={totalQuestions}
                  weight="ultraBold"
                  style={{ color: "#667085" }}
                />
              </View>
            </View>
          </View>
          <View style={styles.clockContainer}>
            <Image source={ClockImage} style={{ width: 180, height: 181 }} />
            <View style={styles.clockBorder} />
          </View>
        </View>
      </MotiView>
      <CustomText
        useTranslationText={false}
        textType="text"
        text="Areas of Focus"
        size="xl"
        style={{ marginTop: 48, marginBottom: 24 }}
      />
      <MotiView style={styles.container}>
        {feedback.map((item, index) => (
          <AnalyticsFeedbackCard
            key={index}
            title={item.title}
            description={item.description}
            highlights={item.highlights}
            icon={item.icon}
            iconColor={item.iconColor}
          />
        ))}
      </MotiView>
      <CustomText
        useTranslationText={false}
        textType="text"
        text="Class Breakdown"
        size="xl"
        style={{ marginTop: 48, marginBottom: 24 }}
      />
      <MotiView style={styles.classListContainer}>
        {classes.map((classItem) => (
          <View key={classItem.id} style={styles.classContainer}>
            <TouchableOpacity onPress={() => toggleClassExpand(classItem.id)} style={styles.classHeader}>
              <CustomText useTranslationText={false} textType="text" text={classItem.name} size="xl" weight="normal" />
              <View style={styles.statsContainer}>
                <View style={styles.statBox}>
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    weight="ultraBold"
                    text={classItem.completion}
                    size="xl"
                  />
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    text="completed"
                    size="xs"
                    style={styles.statLabel}
                  />
                </View>
                <View style={styles.statBox}>
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    weight="ultraBold"
                    text={classItem.timeSpent}
                    size="xl"
                  />
                  <CustomText
                    useTranslationText={false}
                    textType="text"
                    text="time spent"
                    size="xs"
                    style={styles.statLabel}
                  />
                </View>
              </View>
            </TouchableOpacity>
            {expandedClass === classItem.id && (
              <View style={styles.moduleList}>
                {classItem.modules.map((module) => (
                  <View key={module.id} style={styles.moduleContainer}>
                    <TouchableOpacity onPress={() => toggleModuleExpand(module.id)} style={styles.moduleHeader}>
                      <CustomText
                        useTranslationText={false}
                        textType="text"
                        text={module.name}
                        size="m"
                        weight="ultraBold"
                      />
                      <View style={styles.statsContainer}>
                        <View style={styles.statBox}>
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            weight="ultraBold"
                            text={module.completion}
                            size="m"
                          />
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            text="completed"
                            size="xs"
                            style={styles.statLabel}
                          />
                        </View>
                        <View style={styles.statBox}>
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            weight="ultraBold"
                            text={module.timeSpent}
                            size="m"
                          />
                          <CustomText
                            useTranslationText={false}
                            textType="text"
                            text="time spent"
                            size="xs"
                            style={styles.statLabel}
                          />
                        </View>
                      </View>
                    </TouchableOpacity>
                    {expandedModule === module.id && (
                      <View style={styles.detailsContainer}>
                        <View style={styles.detailColumns}>
                          <View style={styles.detailBox}>
                            <View style={styles.greenBackground}>
                              <CustomText
                                useTranslationText={false}
                                textType="text"
                                weight="ultraBold"
                                text="What they did well"
                                size="m"
                              />
                            </View>
                            {/* Only show activities that are categorized as success or balanced */}
                            {module.activities
                              .filter((activity) => activity.type === "success")
                              .map((activity, index) => (
                                <View key={`success-${activity.id}-${index}`} style={styles.activityRow}>
                                  <View style={styles.activityTextContainer}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={`Activity: ${activity.name}`}
                                      size="m"
                                    />
                                    {/* Limit success points to max 2 */}
                                    {activity.successPoints.slice(0, 2).map((point, idx) => (
                                      <CustomText
                                        key={idx}
                                        useTranslationText={false}
                                        textType="text"
                                        text={point}
                                        size="s"
                                        style={styles.detailText}
                                      />
                                    ))}
                                  </View>
                                  <View style={styles.statBox}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={activity.timeSpent}
                                      size="s"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text="time spent"
                                      size="xs"
                                      style={styles.statLabel}
                                    />
                                  </View>
                                </View>
                              ))}
                            {/* Show balanced activities but only if we don't have enough success activities */}
                            {module.activities.filter((a) => a.type === "success").length === 0 &&
                              module.activities
                                .filter((a) => a.type === "balanced")
                                .slice(0, 1) // Limit to just one balanced activity
                                .map((activity, index) => (
                                  <View key={`balanced-success-${activity.id}-${index}`} style={styles.activityRow}>
                                    <View style={styles.activityTextContainer}>
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        weight="ultraBold"
                                        text={`Activity: ${activity.name}`}
                                        size="m"
                                      />
                                      {/* Limit success points to max 2 */}
                                      {activity.successPoints.slice(0, 2).map((point, idx) => (
                                        <CustomText
                                          key={idx}
                                          useTranslationText={false}
                                          textType="text"
                                          text={point}
                                          size="s"
                                          style={styles.detailText}
                                        />
                                      ))}
                                    </View>
                                    <View style={styles.statBox}>
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        weight="ultraBold"
                                        text={activity.timeSpent}
                                        size="s"
                                      />
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        text="time spent"
                                        size="xs"
                                        style={styles.statLabel}
                                      />
                                    </View>
                                  </View>
                                ))}
                            {module.activities.filter((a) => a.type === "success" || a.type === "balanced").length ===
                              0 && (
                              <View style={styles.noDataContainer}>
                                <CustomText
                                  useTranslationText={false}
                                  textType="text"
                                  text="No notable achievements identified yet"
                                  size="s"
                                  style={styles.noDataText}
                                />
                              </View>
                            )}
                          </View>
                          <View style={styles.detailBox}>
                            <View style={styles.redBackground}>
                              <CustomText
                                useTranslationText={false}
                                textType="text"
                                weight="ultraBold"
                                text="Where they can put more focus"
                                size="m"
                              />
                            </View>
                            {/* Only show activities categorized as needing focus */}
                            {module.activities
                              .filter((activity) => activity.type === "needs-focus")
                              .map((activity, index) => (
                                <View key={`focus-${activity.id}-${index}`} style={styles.activityRow}>
                                  <View style={styles.activityTextContainer}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={`Activity: ${activity.name}`}
                                      size="m"
                                    />
                                    {/* Limit confusion points to max 2 */}
                                    {activity.confusionPoints.slice(0, 2).map((point, idx) => (
                                      <CustomText
                                        key={idx}
                                        useTranslationText={false}
                                        textType="text"
                                        text={point}
                                        size="s"
                                        style={styles.detailText}
                                      />
                                    ))}
                                  </View>
                                  <View style={styles.statBox}>
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      weight="ultraBold"
                                      text={activity.completion}
                                      size="s"
                                    />
                                    <CustomText
                                      useTranslationText={false}
                                      textType="text"
                                      text="completed"
                                      size="xs"
                                      style={styles.statLabel}
                                    />
                                  </View>
                                </View>
                              ))}
                            {/* Show balanced activities but only if we don't have enough needs-focus activities */}
                            {module.activities.filter((a) => a.type === "needs-focus").length === 0 &&
                              module.activities
                                .filter((a) => a.type === "balanced")
                                .slice(0, 1) // Limit to just one balanced activity
                                .map((activity, index) => (
                                  <View key={`balanced-needs-${activity.id}-${index}`} style={styles.activityRow}>
                                    <View style={styles.activityTextContainer}>
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        weight="ultraBold"
                                        text={`Activity: ${activity.name}`}
                                        size="m"
                                      />
                                      {/* Limit confusion points to max 2 */}
                                      {activity.confusionPoints.slice(0, 2).map((point, idx) => (
                                        <CustomText
                                          key={idx}
                                          useTranslationText={false}
                                          textType="text"
                                          text={point}
                                          size="s"
                                          style={styles.detailText}
                                        />
                                      ))}
                                    </View>
                                    <View style={styles.statBox}>
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        weight="ultraBold"
                                        text={activity.completion}
                                        size="s"
                                      />
                                      <CustomText
                                        useTranslationText={false}
                                        textType="text"
                                        text="completed"
                                        size="xs"
                                        style={styles.statLabel}
                                      />
                                    </View>
                                  </View>
                                ))}
                            {module.activities.filter((a) => a.type === "needs-focus" || a.type === "balanced")
                              .length === 0 && (
                              <View style={styles.noDataContainer}>
                                <CustomText
                                  useTranslationText={false}
                                  textType="text"
                                  text="No areas of concern identified"
                                  size="s"
                                  style={styles.noDataText}
                                />
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    )}
                  </View>
                ))}
              </View>
            )}
          </View>
        ))}
      </MotiView>
    </View>
  );
};

const styles = StyleSheet.create({
  engagementContainer: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    justifyContent: "space-between",
    marginTop: 24,
    flex: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#EAECF0",
    backgroundColor: "#FFF",
    overflow: "hidden",
  },
  clockContainer: {
    margin: 17,
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 175,
    height: 181,
  },
  clockBorder: {
    position: "absolute",
    top: -85,
    left: -85,
    width: 350,
    height: 350,
    borderRadius: 200,
    borderWidth: 85,
    borderColor: "#EAECF0",
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 16,
  },
  classListContainer: {
    flexDirection: "column",
  },
  classContainer: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    padding: 20,
    borderColor: "#EAECF0",
    marginBottom: 20,
    borderRadius: 20,
    justifyContent: "space-between",
  },
  classHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  statsContainer: {
    flexDirection: "row",
    gap: 20,
  },
  statBox: {
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statLabel: {
    textTransform: "uppercase",
    color: "#667085",
  },
  moduleList: { flexDirection: "column", marginTop: 10 },
  moduleContainer: { flexDirection: "column", backgroundColor: "#FFFFFF", borderTopWidth: 1, borderColor: "#EAECF0" },
  moduleHeader: { flexDirection: "row", justifyContent: "space-between", padding: 20 },
  detailsContainer: { flexDirection: "column", gap: 20, padding: 20, borderRadius: 10 },
  detailColumns: { flexDirection: "row", gap: 20 },
  detailBox: { flex: 1, flexDirection: "column" },
  greenBackground: { backgroundColor: "#F3FEE7", padding: 10, borderRadius: 8 },
  redBackground: { backgroundColor: "#F2F9FF", padding: 10, borderRadius: 8 },
  detailText: { color: "#667085", marginTop: 5 },
  activityRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: "#EAECF0",
    paddingTop: 10,
    marginTop: 10,
  },
  activityTextContainer: { flexDirection: "column", flexShrink: 1 },
  noDataContainer: {
    padding: 16,
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
    borderColor: "#EAECF0",
    marginTop: 10,
  },
  noDataText: {
    color: "#667085",
    fontStyle: "italic",
  },
});

export default StudentInsights;
