import React, { useState } from "react";
import { View, StyleSheet, Platform, Text, ActivityIndicator } from "react-native";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";

// Configure PDF.js worker
if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
}

interface PDFViewerProps {
  url: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setIsLoading(false);
    setError(null);
  };

  const onDocumentLoadError = (err: Error) => {
    console.error("PDF load error:", err);
    setError("Failed to load PDF. Please try again later.");
    setIsLoading(false);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages || 1));
  };

  if (Platform.OS !== "web") {
    return null;
  }

  if (error) {
    return (
      <View style={styles.container}>
        <CustomText text={error} color="red" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {isLoading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#3E68FE" />
          <CustomText text="Loading PDF..." style={styles.loadingText} />
        </View>
      )}
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
        <Page
          pageNumber={pageNumber}
          width={Math.min(window.innerWidth * 0.6, 800)}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <View style={styles.controls}>
        <CustomButton
          text="Previous"
          onPress={goToPrevPage}
          disabled={pageNumber <= 1}
          styleType="secondary"
          leftIcon={null}
          rightIcon={null}
          style={{}}
          textStyle={{}}
          textProps={{}}
          useTranslationText={false}
        />
        <View style={styles.pageInfo}>
          <Text>
            Page {pageNumber} of {numPages}
          </Text>
        </View>
        <CustomButton
          text="Next"
          onPress={goToNextPage}
          disabled={pageNumber >= (numPages || 1)}
          styleType="secondary"
          leftIcon={null}
          rightIcon={null}
          style={{}}
          textStyle={{}}
          textProps={{}}
          useTranslationText={false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    height: "100%",
    backgroundColor: "#f5f5f5",
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    zIndex: 1,
  },
  loadingText: {
    marginTop: 10,
  },
  controls: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    gap: 20,
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  pageInfo: {
    marginHorizontal: 10,
    minWidth: 100,
    textAlign: "center",
  },
});

export default PDFViewer;
