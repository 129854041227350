import { useContext } from "react";
import { CourseDetailsContext } from "../../../contexts/CourseDetailsContext";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useAppConfig } from "../../../AppConfigProvider";
import { StyleSheet, Animated, View, Pressable } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomHtml from "../../../components/common/general/CustomHtml/CustomHtml";
import { ScrollView } from "react-native-gesture-handler";

function SlideInCourseDetails({ visible, slideAnim, onClose, notifications = [] }) {
  if (!visible) return null;
  const { onEnroll, courseData, loading } = useContext(CourseDetailsContext);
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const { isMedium, isXLarge } = useResponsiveScreen();

  const handleEnrollPress = () => {
    onEnroll(courseData?.id, !!courseData?.cost);
  };
  return (
    <Animated.View
      style={[
        styles.container,
        { transform: [{ translateX: slideAnim }], width: isXLarge ? 500 : isMedium ? 370 : "100%" },
      ]}>
      <View style={styles.innerContainer}>
        <View style={styles.topRow}>
          <CustomText
            size="m"
            weight="bold"
            useTranslationText={false}
            style={styles.headerText}
            text={courseData?.name}
          />
          <Pressable role="button" aria-label="Close course detail" style={styles.closeButton} onPress={onClose}>
            <Ionicons name="close" size={24} color="black" />
          </Pressable>
        </View>

        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <View style={styles.contentContainer}>
            <View style={styles.notificationsList}>
              <CustomText text="Course Description" size="xl" weight="ultraBold" style={{ color: "#101828" }} />
              <CustomSpacing type="vertical" size="l" />
              <CustomHtml
                html={courseData?.description || "<p>No description available</p>"}
                style={styles.htmlTextStyle}
              />
              <CustomSpacing type="vertical" size="l" />
              <CustomSpacing type="vertical" size="xl" />
              <CustomText text="What You'll Learn" size="xl" weight="ultraBold" style={{ color: "#101828" }} />
              <CustomSpacing type="vertical" size="l" />
              <CustomHtml html={courseData?.objectives || "<p>No content available</p>"} style={styles.htmlTextStyle} />
              <CustomSpacing type="vertical" size="l" />
              <CustomSpacing type="vertical" size="xl" />

              <View style={styles.targetAudienceBox}>
                <CustomText text="Ideal For" size="xl" weight="ultraBold" style={{ color: "#101828" }} />
                <CustomHtml
                  html={courseData?.targetAudience || "<p>No information available</p>"}
                  style={styles.htmlTextStyle}
                />
              </View>
              <CustomSpacing type="vertical" size="l" />
            </View>
          </View>
        </ScrollView>
        <View style={styles.enrollButtonContainer}>
          <CustomButton
            text={courseData?.cost ? `Enroll for $${courseData?.cost}` : "Enroll"}
            onPress={handleEnrollPress}
            style={[{ backgroundColor: primaryColor }, styles.enrollButtonStyle]}
            textStyle={styles.enrollButtonText}
            disabled={loading}
          />
        </View>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 20,
  },
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  innerContainer: {
    flex: 1,
    backgroundColor: "white",
    borderLeftWidth: 1,
    borderLeftColor: "#e0e0e0",
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
  headerText: {
    fontSize: 18,
  },
  closeButton: {
    padding: 5,
  },
  contentContainer: {
    flex: 1,
    padding: 16,
  },
  notificationsList: {
    flex: 1,
  },
  enrollButtonText: {
    color: "white",
    fontWeight: "700",
  },
  enrollButtonStyle: {
    width: "100%",
    padding: 8,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 8,
  },
  enrollButtonContainer: {
    padding: 16,
  },
  targetAudienceBox: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 20,
    gap: 8,
    backgroundColor: "#F2F4F7",
    borderRadius: 20,
  },
  htmlTextStyle: {
    color: "#344054",
    fontSize: 18,
    lineHeight: 28,
    fontFamily: "Inter",
    fontWeight: "400",
  },
});

export default SlideInCourseDetails;
