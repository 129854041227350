import React from "react";
import { DataTable, Menu } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { FlatList, View, StyleSheet } from "react-native";
import { useState } from "react";
import { DegreesTabModalState } from "./DegreesTabContainer";

export interface DegreesTableProps {
  degrees: {
    id: string;
    name: string;
    coursesHasCourse: Array<{
      id: string;
      name?: string;
    }>;
    usersEnrolledIn: Array<{
      id: string;
      name: string;
    }>;
  }[];
  onActionModalOpen: (degreeId: string, degreeName: string, modalType: DegreesTabModalState) => void;
}

type MenuState = {
  visible: boolean;
  x: number;
  y: number;
  degreeId: string;
  degreeName: string;
};

const DegreesTable = ({ degrees, onActionModalOpen }: DegreesTableProps) => {
  const [menuState, setMenuState] = useState<MenuState>({
    visible: false,
    x: 0,
    y: 0,
    degreeId: null,
    degreeName: null,
  });

  const handleOpenMenu = (event: React.MouseEvent, degreeId: string, degreeName: string) => {
    const { pageX, pageY } = event.nativeEvent;
    setMenuState({
      visible: true,
      x: pageX - 100,
      y: pageY,
      degreeId,
      degreeName,
    });
  };

  const handleCloseMenu = () => {
    setMenuState((prev) => ({ ...prev, visible: false, degreeId: null, degreeName: null }));
  };

  const handleActionModalOpen = (modalType: DegreesTabModalState, degreeId?: string, degreeName?: string) => {
    onActionModalOpen(menuState.degreeId ?? degreeId, menuState.degreeName ?? degreeName, modalType);
    handleCloseMenu();
  };

  return (
    <>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Degree Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.courseColumn}>
            <CustomText text="Courses" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.enrollmentColumn}>
            <CustomText text="Current Enrollment" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.actionsColumn}>
            <CustomText text="Actions" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>

        <FlatList
          data={degrees}
          renderItem={({ item: degree }) => {
            return (
              <DataTable.Row style={styles.tableRow}>
                <DataTable.Cell style={styles.nameColumn}>
                  <View style={styles.nameCell}>
                    <CustomText text={degree.name} size="s" style={styles.degreeName} useTranslationText={false} />
                  </View>
                </DataTable.Cell>
                <DataTable.Cell style={styles.courseColumn}>
                  {degree.coursesHasCourse.length > 0 ? (
                    <CustomText
                      text={degree.coursesHasCourse.map((course) => course.name).join(", ")}
                      size="s"
                      useTranslationText={false}
                      style={styles.facultyText}
                    />
                  ) : (
                    <CustomText
                      text="No Courses Assigned"
                      size="s"
                      useTranslationText={false}
                      style={styles.facultyText}
                    />
                  )}
                </DataTable.Cell>
                <DataTable.Cell style={styles.enrollmentColumn}>
                  <CustomText
                    text={degree.usersEnrolledIn.length.toLocaleString()}
                    size="s"
                    style={styles.enrollmentText}
                    useTranslationText={false}
                  />
                </DataTable.Cell>
                <DataTable.Cell style={styles.actionsColumn}>
                  <CustomButton
                    text="⋮"
                    size="xxl"
                    styleType="primaryTransparent"
                    style={[styles.moreButton, styles.buttonBase]}
                    useTranslationText={false}
                    onPress={(e) => handleOpenMenu(e, degree.id, degree.name)}
                    leftIcon={null}
                    rightIcon={null}
                    textStyle={styles.buttonText}
                    disabled={false}
                    textProps={{}}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            );
          }}
          keyExtractor={(item) => item.id}
        />
      </DataTable>

      <Menu
        visible={menuState.visible}
        onDismiss={handleCloseMenu}
        anchor={{ x: menuState.x, y: menuState.y }}
        contentStyle={styles.menuItem}>
        <Menu.Item
          onPress={() => handleActionModalOpen(DegreesTabModalState.DELETE_DEGREE)}
          title="Delete Degree"
          titleStyle={{ color: "#DC2626" }}
        />
        <Menu.Item
          onPress={() => handleActionModalOpen(DegreesTabModalState.DEGREE_COURSES)}
          title="Manage Courses"
          titleStyle={{ color: "#3E68FE" }}
        />
        <Menu.Item
          onPress={() => handleActionModalOpen(DegreesTabModalState.CHANGE_NAME)}
          title="Change Name"
          titleStyle={{ color: "#3E68FE" }}
        />
      </Menu>
    </>
  );
};

const styles = StyleSheet.create({
  nameColumn: { flex: 5 },
  courseColumn: { flex: 5, paddingHorizontal: 30 },
  enrollmentColumn: { flex: 2 },
  actionsColumn: { flex: 1, justifyContent: "flex-end", flexShrink: 1 },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
  },
  nameCell: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  degreeName: {
    color: "#111827",
    fontSize: 14,
    fontWeight: "500",
  },
  facultyText: {
    color: "#374151",
    fontSize: 14,
  },
  enrollmentText: {
    color: "#6B7280",
    fontSize: 14,
  },
  buttonBase: {
    minWidth: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  moreButton: {
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#3E68FE",
    fontSize: 14,
    fontWeight: "500",
  },
  menuItem: {
    minWidth: 150,
    backgroundColor: "#fff",
  },
});

export default DegreesTable;
