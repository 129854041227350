import { useEffect } from "react";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useNavigation } from "@react-navigation/native";

function WebSocketNavigator() {
  const { state, dispatch } = useAppState();
  const navigation = useNavigation();

  useEffect(() => {
    // Send the meta pixel when exiting onboarding
    if (state.meta.sendOnboardingPixelEvent) {
      dispatch({
        type: actions.SET_META,
        payload: { sendOnboardingPixelEvent: false },
      });
    }
  }, [state.state.view, navigation, state.meta.sendOnboardingPixelEvent]);
}

export default WebSocketNavigator;
