import { View } from "react-native";
import { StyleSheet } from "react-native";
import WebView from "react-native-webview";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";

const YoutubeVideoPlayerAdvanced = ({ id, timestamp }) => {
  const { isMedium } = useResponsiveScreen();

  // Construct the URL with timestamp if provided
  const videoUrl = timestamp
    ? `https://www.youtube.com/embed/${id}?start=${timestamp}`
    : `https://www.youtube.com/embed/${id}`;

  return (
    <View style={!isMedium ? styles.mobileVideoContainer : styles.videoContainer}>
      <WebView
        style={styles.webView}
        source={{
          uri: videoUrl,
        }}
        javaScriptEnabled={true}
        allowsFullscreenVideo={true}
      />
    </View>
  );
};

export default YoutubeVideoPlayerAdvanced;

const styles = StyleSheet.create({
  videoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
    overflow: "hidden",
  },
  mobileVideoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
    overflow: "hidden",
  },
  webView: {
    width: "100%",
    height: "100%",
  },
});
