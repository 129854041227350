import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import { ExitDoor } from "../../svgs/common";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function AssessmentMenu({ handleExitPress, remainingTime, moduleName }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <View
      style={[styles.container, isMedium ? styles.desktop : styles.mobile]}
      aria-live="polite"
      aria-label="Assessment Menu"
      role="menu">
      <View style={styles.leftContainer}>
        <View style={styles.exitButtonContainer}>
          <CustomButton leftIcon={<ExitDoor />} onPress={handleExitPress} size="s" styleType="transparent" />
        </View>
        {isMedium && (
          <>
            <View style={styles.verticalDivider} />
            <CustomSpacing type="horizontal" size="xl" />
          </>
        )}
        <CustomText
          text="assessment_menu_title"
          role="header"
          aria-level="3"
          size="m"
          numberOfLines={1}
          ellipsizeMode="tail"
          weight="bold"
          textType="text"
          interpolation={{ moduleName }}
        />
      </View>
      <View style={styles.timerContainer}>
        <CustomText
          text={remainingTime}
          useTranslationText={false}
          size="s"
          weight="ultraBold"
          style={{ color: "#475467" }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    zIndex: 20,
    elevation: 5,
    height: 52,
    maxHeight: 52,
    borderBottomWidth: 1,
    borderBottomColor: "#EAECF0",
  },
  desktop: {
    flexDirection: "row",
    height: 52,
    maxHeight: 52,
    paddingRight: 20,
    gap: 20,
  },
  mobile: {
    flexDirection: "column",
    height: 100,
    maxHeight: 100,
    paddingRight: 0,
    gap: 5,
    paddingBottom: 10,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  exitButtonContainer: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 12,
    flex: 1,
  },
  timerContainer: {
    backgroundColor: "#EAECF0",
    paddingHorizontal: 12,
    paddingVertical: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  verticalDivider: {
    width: 1,
    height: 52,
    backgroundColor: "#EAECF0",
  },
});

export default AssessmentMenu;
