import React from "react";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import CustomText from "../components/common/general/CustomText/CustomText";
import { MotiView } from "moti";
import StudentInsights from "../components/analytics/StudentInsights/StudentInsights";
import {
  useSchoolsQuery,
  useMeQuery,
  useGetStudentLearningInsightsQuery,
  useGetStudentDetailedAnalyticsQuery,
  useGetStudentChatAnalyticsQuery,
} from "../graphql/generated/graphql";

const InsightsView = () => {
  const { data: schoolsData } = useSchoolsQuery();
  const schoolId = schoolsData?.getSchools[0]?.id;

  const { data: meData } = useMeQuery();
  const studentId = meData?.me?.id;

  const { data: insightsData, loading: insightsLoading } = useGetStudentLearningInsightsQuery({
    variables: { schoolId, studentId },
    skip: !schoolId || !studentId,
  });

  const { data: detailedData, loading: detailedLoading } = useGetStudentDetailedAnalyticsQuery({
    variables: { schoolId, studentId },
    skip: !schoolId || !studentId,
  });

  const { data: chatData, loading: chatLoading } = useGetStudentChatAnalyticsQuery({
    variables: { schoolId, studentId },
    skip: !schoolId || !studentId,
  });

  // Combine all data into a single object for StudentInsights component
  const combinedData = {
    getStudentDetailedAnalytics: detailedData?.getStudentDetailedAnalytics,
    getStudentLearningInsights: insightsData?.getStudentLearningInsights,
    getStudentChatAnalytics: chatData?.getStudentChatAnalytics,
  };

  const isLoading = insightsLoading || detailedLoading || chatLoading;

  return (
    <NoChatBarLayout>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <CustomText useTranslationText={false} textType="display" text="Insights" size="m" weight="ultraBold" />
      </MotiView>

      {isLoading ? (
        <CustomText
          useTranslationText={false}
          textType="text"
          text="Loading insights..."
          size="m"
          style={{ marginTop: 48 }}
        />
      ) : (
        <StudentInsights data={combinedData} />
      )}
    </NoChatBarLayout>
  );
};

export default InsightsView;
