import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { ListItem } from "@rneui/themed";
import { MotiView } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";
import { ChartLine } from "../../svgs/common";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function AnalyticsAccordion({
  title,
  children,
  onExplorePress,
  onToggleExpand,
  data,
  initialExpanded = false,
  isExpanded,
}) {
  // If isExpanded is provided, use it; otherwise use internal state
  const [internalExpanded, setInternalExpanded] = useState(initialExpanded);
  const expanded = isExpanded !== undefined ? isExpanded : internalExpanded;

  // Track hover state for the border animation
  const [hovered, setHovered] = useState(false);

  const handleToggle = () => {
    // Call the external handler if provided
    if (onToggleExpand) {
      onToggleExpand();
    } else {
      // Otherwise, use internal state
      setInternalExpanded(!expanded);
    }
  };

  // Separate handler for explore button to avoid event bubbling
  const handleExploreClick = (e) => {
    // Stop event propagation to prevent accordion toggle
    e.stopPropagation();
    console.log("Explore button clicked");
    onExplorePress();
  };

  const completionStats = data?.completionStats || {
    completed: 0,
    inProgress: 0,
    notStarted: 100,
  };

  // Calculate sentiment counts for display
  const calculateSentimentCounts = () => {
    return { positive: 0, negative: 0 }; // Simplified for now
  };

  const { positive, negative } = calculateSentimentCounts();

  return (
    <View
      style={[styles.container, hovered && { borderWidth: 3 }]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <ListItem.Accordion
        noIcon={true}
        Component={TouchableOpacity} // Explicitly use TouchableOpacity
        content={
          <View style={styles.headerContainer}>
            <View style={styles.titleSection}>
              <CustomText useTranslationText={false} text={title} size="l" weight="bold" />
              <View style={styles.sentimentRow}>
                <CustomText
                  useTranslationText={false}
                  text="Overall Sentiment:"
                  size="s"
                  style={styles.sentimentLabel}
                />
                {positive > 0 && <CustomText useTranslationText={false} text={`😊 ${positive}`} size="s" />}
                {negative > 0 && <CustomText useTranslationText={false} text={`😟 ${negative}`} size="s" />}
              </View>
            </View>
            <View style={styles.headerActions}>
              <View style={styles.statsRow}>
                <View style={{ flexDirection: "column" }}>
                  <CustomText
                    useTranslationText={false}
                    text={`${Math.round(completionStats.completed)}%`}
                    weight="ultraBold"
                    size="m"
                    style={styles.statValue}
                  />
                  <CustomText useTranslationText={false} text="COMPLETED" size="xs" style={styles.statLabel} />
                </View>
                <View style={{ flexDirection: "column" }}>
                  <CustomText
                    useTranslationText={false}
                    text={`${Math.round(completionStats.inProgress)}%`}
                    weight="ultraBold"
                    size="m"
                    style={styles.statValue}
                  />
                  <CustomText useTranslationText={false} text="IN PROGRESS" size="xs" style={styles.statLabel} />
                </View>
                <View style={{ flexDirection: "column" }}>
                  <CustomText
                    useTranslationText={false}
                    text={`${Math.round(completionStats.notStarted)}%`}
                    weight="ultraBold"
                    size="m"
                    style={styles.statValue}
                  />
                  <CustomText useTranslationText={false} text="NOT ATTEMPTED" size="xs" style={styles.statLabel} />
                </View>
              </View>
              <CustomSpacing type="horizontal" size="m" />
              {/* Use TouchableOpacity to avoid nested button issue */}
              <TouchableOpacity onPress={handleExploreClick} style={styles.exploreButton}>
                <ChartLine fill="#667085" />
              </TouchableOpacity>
            </View>
          </View>
        }
        isExpanded={expanded}
        onPress={handleToggle}
        role="button"
        aria-checked={expanded}
        aria-label={`Accordion, ${expanded ? "expanded" : "collapsed"}`}>
        <MotiView
          from={{ opacity: 0, translateY: -20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ type: "timing", duration: 300 }}>
          {children}
        </MotiView>
      </ListItem.Accordion>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    borderWidth: 1,
    borderColor: "#EAECF0",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  titleSection: {
    flex: 1,
  },
  sentimentRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginTop: 4,
  },
  sentimentLabel: {
    color: "#6B7280",
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  statsRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 16,
  },
  statLabel: {
    color: "#667085",
  },
  statValue: {
    color: "#101828",
  },
  exploreButton: {
    borderWidth: 1,
    borderColor: "#EAECF0",
    borderRadius: 8,
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AnalyticsAccordion;
