export const RolePermissions = {
  viewSchoolAnalytics: "viewSchoolAnalytics",
  editCourse: "editCourse",
  manageFaculty: "manageFaculty",
  viewCourse: "viewCourse",
  viewDegree: "viewDegree",
  editDegree: "editDegree",
  viewTenantSettings: "viewTenantSettings",
  editTenantSettings: "editTenantSettings",
} as const;
