import { StyleSheet, ScrollView } from "react-native";
import DegreeCard from "./DegreeCard";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";

function DegreeCardList({ degrees = [], onPress }) {
  const { isMedium } = useResponsiveScreen();
  return (
    <ScrollView
      horizontal={!isMedium}
      contentContainerStyle={isMedium ? styles.verticalScrollContainer : styles.horizontalScrollContainer}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}>
      {degrees.map((degree, index) => (
        <DegreeCard
          key={index.toString()}
          title={degree.title}
          description={degree.description}
          exampleEmployer={degree.exampleEmployer}
          onPress={onPress}
          degreeId={degree.id}
        />
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  verticalScrollContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: 20,
  },
  horizontalScrollContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 20,
  },
});

export default DegreeCardList;
