import { useEffect, useState } from "react";
import { GradingData } from "../../../views/TeacherGradingView";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import { StyleSheet, View, TextInput, TouchableOpacity, Platform, Linking, Alert } from "react-native";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useGetAssignmentAndSubmissionBySubmissionIdQuery } from "../../../graphql/generated/graphql";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Sentry from "../../../utils/sentry";
import Folder from "../../../components/svgs/common/Folder";

const AssignmentSubmissionGradingModalContainer = ({
  isVisible,
  onClose,
  gradingData,
  onSave,
  isSubmitting,
}: {
  isVisible: boolean;
  onClose: () => void;
  gradingData: GradingData | null;
  onSave: (gradingData: GradingData, newScore: number, feedback: string) => Promise<void>;
  isSubmitting: boolean;
}) => {
  const [grade, setGrade] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");

  const { data, loading, error } = useGetAssignmentAndSubmissionBySubmissionIdQuery({
    variables: { submissionId: gradingData?.id },
    skip: !gradingData?.id,
  });

  const assignment = data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.assignment;
  const submission = data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.submission;

  useEffect(() => {
    if (data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.submission) {
      setGrade(data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.submission?.grade.toString());
    }
    if (data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.submission?.feedback) {
      setFeedback(data?.getAssignmentAndSubmissionBySubmissionId?.data[0]?.submission?.feedback);
    }
  }, [data]);

  const handleSave = async () => {
    const newScore = Number(grade);

    if (newScore < 0 || newScore > 100) {
      Alert.alert("Invalid score", "Please enter a score between 0 and 100");
      return;
    }

    if (Number.isNaN(newScore)) {
      Alert.alert("Invalid score", "Please enter a valid number");
      return;
    }

    await onSave(gradingData, newScore, feedback);
  };

  const handleOpenURL = async (url) => {
    if (Platform.OS === "web") {
      try {
        // Get JWT token from AsyncStorage
        const token = await AsyncStorage.getItem("token");

        // Create a new request with the authorization header
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Convert the response to a blob
        const blob = await response.blob();

        // Create a local URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Open the blob URL in a new window
        const win = window.open(blobUrl, "_blank");
        if (win) win.focus();

        // Optionally revoke the blob URL when no longer needed
        // setTimeout(() => URL.revokeObjectURL(blobUrl), 60000);
      } catch (err) {
        console.error("Failed to fetch and open URL:", err);
        Sentry.captureException(err);

        // Fallback to direct opening if fetch fails
        const win = window.open(url, "_blank");
        if (win) win.focus();
      }
    } else {
      Linking.openURL(url).catch((err) => {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      });
    }
  };

  if (loading) {
    return (
      <CustomModal
        visible={isVisible}
        onRequestClose={onClose}
        title="Loading..."
        showCloseButton
        style={styles.modalContent}
        exitFill="#6B7280"
        onShow={() => {}}
        ariaLabel="Loading Assignment Modal">
        <CustomText text="Loading assignment details..." size="m" useTranslationText={false} />
      </CustomModal>
    );
  }

  if (error || !assignment) {
    return (
      <CustomModal
        visible={isVisible}
        onRequestClose={onClose}
        title="Error"
        showCloseButton
        style={styles.modalContent}
        exitFill="#6B7280"
        onShow={() => {}}
        ariaLabel="Error Modal">
        <CustomText text="Failed to load assignment details." size="m" useTranslationText={false} />
      </CustomModal>
    );
  }

  return (
    <CustomModal
      visible={isVisible}
      onRequestClose={onClose}
      title={assignment.name}
      showCloseButton
      style={styles.modalContent}
      exitFill="#6B7280"
      onShow={() => {}}
      ariaLabel="Assignment Details Modal">
      <CustomText text={assignment.description} size="m" style={styles.description} useTranslationText={false} />

      <View style={styles.submissionSection}>
        <CustomText
          text="Student Submission"
          size="l"
          weight="bold"
          style={styles.sectionTitle}
          useTranslationText={false}
        />

        <View style={styles.fileUploadContainer}>
          <View style={styles.fileHeaderRow}>
            <View style={styles.iconTextRow}>
              <Folder />
              <CustomText text="File upload" size="m" weight="bold" useTranslationText={false} />
            </View>
          </View>
          {submission?.files?.map((file) => (
            <TouchableOpacity style={styles.dateResubmitRow} onPress={() => handleOpenURL(file.fileURL)} key={file.id}>
              <CustomText text={file.fileName} size="m" style={styles.fileName} useTranslationText={false} />
              <CustomText
                text={new Date(submission.completedAt).toLocaleDateString()}
                size="s"
                style={styles.date}
                useTranslationText={false}
              />
            </TouchableOpacity>
          ))}
        </View>

        <View style={styles.gradingSection}>
          <View style={styles.gradeContainer}>
            <CustomText text="Grade %" size="m" weight="bold" style={styles.label} useTranslationText={false} />
            <TextInput
              style={styles.gradeInput}
              value={grade}
              onChangeText={setGrade}
              keyboardType="numeric"
              placeholder="Enter grade percentage"
            />
          </View>

          <View style={styles.feedbackContainer}>
            <CustomText
              text="Feedback for the Student (Optional)"
              size="m"
              weight="bold"
              style={styles.label}
              useTranslationText={false}
            />
            <TextInput
              style={styles.feedbackInput}
              value={feedback}
              onChangeText={setFeedback}
              multiline
              numberOfLines={4}
              placeholder="Enter feedback"
            />
          </View>
        </View>
      </View>

      <View style={styles.modalFooter}>
        <View style={styles.buttonGroup}>
          <CustomButton text="Cancel" styleType="secondary" onPress={onClose} useTranslationText={false} />
          <CustomButton
            text="Save"
            styleType="primary"
            onPress={handleSave}
            useTranslationText={false}
            disabled={isSubmitting}
          />
        </View>
      </View>
    </CustomModal>
  );
};

export default AssignmentSubmissionGradingModalContainer;

const styles = StyleSheet.create({
  modalContent: {
    maxHeight: "90%",
    padding: 24,
  },
  description: {
    color: "#6B7280",
    marginBottom: 32,
    lineHeight: 24,
  },
  submissionSection: {
    marginBottom: 24,
  },
  sectionTitle: {
    marginBottom: 16,
  },
  fileUploadContainer: {
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
  },
  fileHeaderRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  iconTextRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  dateResubmitRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  fileName: {
    color: "#111827",
  },
  date: {
    color: "#6B7280",
  },
  gradingSection: {
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
    padding: 16,
  },
  gradeContainer: {
    marginBottom: 16,
  },
  feedbackContainer: {
    marginBottom: 16,
  },
  label: {
    marginBottom: 8,
  },
  gradeInput: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 12,
    fontSize: 16,
  },
  feedbackInput: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 12,
    fontSize: 16,
    minHeight: 120,
    textAlignVertical: "top",
  },
  modalFooter: {
    flexDirection: "row",
    marginTop: 24,
    borderTopWidth: 1,
    borderTopColor: "#E5E7EB",
    paddingTop: 16,
  },
  buttonGroup: {
    flexDirection: "row",
    gap: 12,
  },
});
