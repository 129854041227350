import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

function UserMessage({ text }) {
  return (
    <View style={styles.bubble}>
      <CustomText useTranslationText={false} text={text} style={styles.text} />
    </View>
  );
}

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: "#EAECF0",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
    paddingHorizontal: 15,
    paddingVertical: 12,
    alignSelf: "flex-end",
    flexShrink: 1,
    maxWidth: "99.8%",
  },
  text: {
    flexWrap: "wrap",
    lineHeight: 24,
  },
});

export default UserMessage;
