import React, { useState, useEffect } from "react";
import { View, StyleSheet, Platform } from "react-native";
import DraggableFlatList, { ScaleDecorator } from "react-native-draggable-flatlist";
import ActivityCard from "../ActivityCard/ActivityCard";
import { sortResourceItemsOfModules } from "../../../utils/sortResourceItemsOfModules";

const ActivityCardList = ({ module, onItemOptionsPress, onItemToggle, selectedItemId, style, onDragEnd }) => {
  const [localItems, setLocalItems] = useState([]);

  useEffect(() => {
    const items = [
      ...(module?.aiTutors || []).map((item) => ({
        ...item,
        type: "Interactive Learning Experience",
      })),
      ...(module?.pages || []).map((item) => ({
        ...item,
        type: "Page",
      })),
      ...(module?.axioResources || []).map((item) => ({
        ...item,
        title: item.name, // Map name to title for consistency
        type: "Resource",
      })),
      ...(module?.assignments || []).map((item) => ({
        ...item,
        title: item.name,
        type: "Assignment",
      })),
    ];

    const sortedItems = sortResourceItemsOfModules(items, module.resourceOrder);

    setLocalItems(sortedItems);
  }, [module]);

  const handleDragEnd = (data) => {
    setLocalItems(data);
    if (onDragEnd) {
      onDragEnd(data);
    }
  };

  const renderItem = ({ item, drag, isActive }) => (
    <ScaleDecorator>
      <ActivityCard
        key={item.id}
        id={item.id}
        title={item.title}
        type={item.type}
        onOptionsPress={(action) => onItemOptionsPress(action, item)}
        onToggle={() => onItemToggle?.(item, module)}
        onLongPress={drag}
        style={[styles.card, isActive && styles.draggingItem, selectedItemId === item.id && styles.selectedCard]}
      />
    </ScaleDecorator>
  );

  return (
    <View style={[styles.container, style]}>
      <DraggableFlatList
        data={localItems}
        onDragEnd={({ data }) => handleDragEnd(data)}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        dragItemOverflow={true}
        activationDistance={5}
        autoscrollSpeed={50}
        containerStyle={{ flex: 1 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
  },
  card: {
    width: "100%",
    marginBottom: 8,
  },
  draggingItem: {
    opacity: 0.9,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 1,
    ...Platform.select({
      web: {
        cursor: "grabbing",
      },
    }),
  },
  selectedCard: {
    borderColor: "#3B82F6",
    borderWidth: 2,
  },
});

export default ActivityCardList;
