import * as React from "react";
import Svg, { Path } from "react-native-svg";

function NonComplete(props, fill = "#D0D5DD") {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 15c0 6.904-5.596 12.5-12.5 12.5S2.5 21.904 2.5 15 8.096 2.5 15 2.5 27.5 8.096 27.5 15zm-6.2-4.634a1.25 1.25 0 010 1.768l-8.333 8.333a1.25 1.25 0 01-1.768 0l-3.333-3.333a1.25 1.25 0 011.768-1.768l2.45 2.45 7.449-7.45a1.25 1.25 0 011.768 0z"
        fill={fill}
      />
    </Svg>
  );
}

export default NonComplete;
