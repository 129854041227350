import { useEffect } from "react";
import { View, StyleSheet, Platform, Linking, Image } from "react-native";
import { usePostHog } from "posthog-js/react";
import { useAppConfig } from "../AppConfigProvider";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { MotiView } from "moti";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { shadowStyles } from "../styles/shadow";
import JourneyBreadcrumbs from "../components/journey/JourneyBreadcrumbs/JourneyBreadcrumbs";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useGetFacultyByCourseIdQuery } from "../graphql/generated/graphql";
import Loader from "../components/common/status/Loader/Loader";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const CalendlyEmbed = ({ calendlyUrl }) => {
  if (Platform.OS === "web") {
    return <iframe src={calendlyUrl} width="100%" height="700px" frameBorder="0" style={{ borderRadius: 8 }} />;
  }

  return (
    <CustomButton
      text="Open Schedule"
      styleType="primary"
      onPress={() => Linking.openURL(calendlyUrl)}
      style={styles.scheduleButton}
      disabled={undefined}
      leftIcon={undefined}
      rightIcon={undefined}
      textStyle={undefined}
      textProps={undefined}
    />
  );
};

function FacultyView() {
  const posthog = usePostHog();
  const appConfig = useAppConfig();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Faculty">>();
  const { courseName, courseId, integration_id } = route.params;

  const isLTI = appConfig?.isLTI;
  const { isXLarge } = useResponsiveScreen();

  const { data: facultyData, loading } = useGetFacultyByCourseIdQuery({ variables: { courseId } });

  const calendlyUrl = facultyData?.getFacultyByCourseId?.data?.faculties[0]?.meetingLink;
  const facultyEmail = facultyData?.getFacultyByCourseId?.data?.faculties[0]?.email;
  const facultyName = facultyData?.getFacultyByCourseId?.data?.faculties[0]?.name;
  const facultyProfilePicture = facultyData?.getFacultyByCourseId?.data?.faculties[0]?.profilePicture;
  const facultyBio = facultyData?.getFacultyByCourseId?.data?.faculties[0]?.biography;

  const handleSendEmail = () => {
    Linking.openURL(`mailto:${facultyEmail}`);
  };

  const handleBackPress = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integration_id,
    });
  };

  useEffect(() => {
    posthog?.capture("Faculty View Visited");
  }, []);

  if (loading) {
    return (
      <NoChatBarLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </NoChatBarLayout>
    );
  }

  return (
    <NoChatBarLayout>
      <MotiView
        style={{ flex: 1 }}
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={500}>
        <JourneyBreadcrumbs onPress={handleBackPress} firstItem={courseName} secondItem={facultyName} />

        <CustomSpacing type="vertical" size="l" />

        {!isLTI && (
          <View style={[styles.mainContent, isXLarge ? styles.rowLayout : styles.columnLayout]}>
            {/* Left Column - Profile & Buttons */}
            <View style={[styles.leftColumn, isXLarge ? styles.columnWidth : styles.fullWidth]}>
              <View style={styles.profileSection}>
                <Image
                  source={{
                    uri: facultyProfilePicture,
                  }}
                  style={styles.profileImage}
                />
                <CustomText
                  style={{}}
                  useTranslationText={false}
                  text={facultyName}
                  size="xs"
                  textType="display"
                  weight="bold"
                />
                <CustomSpacing type="vertical" size="s" />
                <CustomText
                  useTranslationText={false}
                  text={facultyBio}
                  size="s"
                  color="secondary"
                  textType="text"
                  style={{}}
                />
              </View>

              <CustomSpacing type="vertical" size="xxl" />

              <View style={styles.questionSection}>
                <CustomText
                  useTranslationText={false}
                  text="Have a Question?"
                  size="xs"
                  textType="display"
                  weight="ultraBold"
                  style={{}}
                />
                <CustomSpacing type="vertical" size="s" />
                <CustomText
                  useTranslationText={false}
                  text="We're here to help with any questions or concerns you may have."
                  size="xs"
                  style={{}}
                />
                <CustomSpacing type="vertical" size="l" />
                <View style={styles.buttonContainer}>
                  <CustomButton
                    text="Send Email"
                    styleType="primaryTransparent"
                    style={[styles.actionButton, shadowStyles.smallShadowButton]}
                    onPress={handleSendEmail}
                    disabled={undefined}
                    leftIcon={undefined}
                    rightIcon={undefined}
                    textStyle={undefined}
                    textProps={undefined}
                  />
                </View>
              </View>
            </View>

            {/* Right Column - Calendar */}
            <View style={[styles.rightColumn, isXLarge ? styles.columnWidth : styles.fullWidth]}>
              <CustomText
                useTranslationText={false}
                text="Schedule Office Hours"
                size="xs"
                textType="display"
                weight="ultraBold"
                style={{}}
              />
              <CustomSpacing type="vertical" size="l" />
              <View style={styles.calendlyContainer}>
                <CalendlyEmbed calendlyUrl={calendlyUrl} />
              </View>
              <CustomSpacing type="vertical" size="l" />
            </View>
          </View>
        )}
      </MotiView>
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  mainContent: {
    width: "100%",
  },
  profileImage: {
    marginTop: 10,
    marginBottom: 20,
    width: 200,
    height: 200,
    borderRadius: 100,
    alignSelf: "flex-start",
  },
  rowLayout: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 48,
  },
  columnLayout: {
    flexDirection: "column",
    gap: 48,
  },
  leftColumn: {
    minWidth: 0,
  },
  rightColumn: {
    minWidth: 0,
  },
  columnWidth: {
    flex: 1,
  },
  fullWidth: {
    width: "100%",
  },
  profileSection: {
    marginTop: 24,
    marginBottom: 24,
  },
  questionSection: {
    marginBottom: 32,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  actionButton: {
    flex: 1,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  calendlyContainer: {
    backgroundColor: "#fff",
    borderRadius: 8,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    ...Platform.select({
      ios: {
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
      },
      android: {
        elevation: 4,
      },
      web: {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    }),
  },
  scheduleButton: {
    margin: 20,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default FacultyView;
