import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import EditActivity from "../../../components/customCoursework/EditActivity/EditActivity";
import ChooseActivity from "../../../components/customCoursework/ChooseActivity/ChooseActivity";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const EditActivityModalContainer = ({ visible, onClose, onSave, error, isSubmitting, existingActivity = null }) => {
  const { isMedium } = useResponsiveScreen();
  // Get activity type from existing activity or default to null
  const getActivityType = () => {
    if (!existingActivity) return null;

    // Use the type from the activity or determine based on __typename
    if (existingActivity.type) {
      return existingActivity.type;
    } else if (existingActivity.__typename === "AITutor") {
      return "Interactive Learning Experience";
    } else if (existingActivity.__typename === "AxioResource") {
      return "Resource";
    } else if (existingActivity.__typename === "Assignment") {
      return "Assignment";
    }

    // Default to Interactive Learning Experience if type can't be determined
    console.warn("Could not determine activity type, defaulting to Interactive Learning Experience");
    return "Interactive Learning Experience";
  };

  const [step, setStep] = useState(existingActivity ? "edit" : "choose");
  const [selectedActivityType, setSelectedActivityType] = useState(getActivityType());

  // Process objectives to ensure they are in the correct format
  const processObjectives = () => {
    if (!existingActivity || !existingActivity.objectives) return [];

    // Check if we're dealing with a clone from our cloning function
    if (existingActivity._isClone) {
      // If it's already in the right format (from our cloning function), use as is
      return existingActivity.objectives;
    }

    // Convert from API format (objects with objective property) to array of strings
    // that EditActivity component expects
    if (existingActivity.objectives[0] && typeof existingActivity.objectives[0] === "object") {
      // If this is original data from API, extract just the objective text
      return existingActivity.objectives.filter((obj) => obj.objective).map((obj) => obj.objective);
    }

    // If it's already an array of strings, return as is
    return existingActivity.objectives;
  };

  // Format the activity data to match what EditActivity expects
  const formattedActivityData = existingActivity
    ? {
        title: existingActivity.title,
        description: existingActivity.description,
        objectives: processObjectives(),
        id: existingActivity.id,
        type: selectedActivityType,
        files: existingActivity.files || [],
        videoUrl: existingActivity.videoUrl || "",
        dueDate: existingActivity.dueDate || "",
        enableFileUpload: existingActivity.enableFileUpload || false,
        contextText: existingActivity.contextText || "",
        // Include visualization ID if it exists
        visualizationId: existingActivity.visualization?.id || null,
        // Pass through evaluation criteria if available
        evalCriteria: existingActivity.evalCriteria || [],
        // Preserve the raw objectives for AI Tutors that need the full structure
        rawObjectives: existingActivity.objectives,
        visualization: existingActivity.visualization,
        videoPool: existingActivity.videoPool
          ? existingActivity.videoPool.map((video) => ({
              ...video,
              isTeacherAdded: video.isTeacherAdded === true,
            }))
          : [],
      }
    : null;

  // Add additional logging to help debug evaluation criteria issues
  if (existingActivity) {
    console.log(
      "EditActivityModalContainer - existingActivity.evalCriteria:",
      existingActivity.evalCriteria ? JSON.stringify(existingActivity.evalCriteria, null, 2) : "undefined"
    );

    // Check if evaluation criteria might be in the objectives
    if (existingActivity.objectives && existingActivity.objectives.length > 0) {
      const firstObj = existingActivity.objectives[0];
      if (typeof firstObj === "object" && firstObj.evalCriteria) {
        console.log(
          "EditActivityModalContainer - Found evalCriteria in objectives[0]:",
          JSON.stringify(firstObj.evalCriteria, null, 2)
        );
      }
    }
  }

  useEffect(() => {
    if (!visible) {
      setStep(existingActivity ? "edit" : "choose");
      setSelectedActivityType(getActivityType());
    } else {
      // Update activity type when modal becomes visible or existingActivity changes
      setSelectedActivityType(getActivityType());
    }
  }, [visible, existingActivity]);

  const handleActivityTypeSelect = (type) => {
    setSelectedActivityType(type);
    setStep("edit");
  };

  const handleSave = async (activityData, activityType, videoPool = []) => {
    try {
      const result = await onSave({
        ...activityData,
        type: selectedActivityType,
        id: existingActivity?.id,
      });

      return result;
    } catch (error) {
      console.error("Error saving activity:", error);
      throw error; // Re-throw to allow the caller to handle the error
    }
  };

  const handleClose = () => {
    onClose();
    setStep(existingActivity ? "edit" : "choose");
    setSelectedActivityType(getActivityType());
  };

  console.log("EditActivityModalContainer - selectedActivityType:", selectedActivityType);
  console.log("EditActivityModalContainer - existingActivity:", existingActivity);

  return (
    <CustomModal
      style={{
        margin: 10,
        padding: -20,
        maxHeight: "90vh", // Changed from 800px to 90vh
        overflow: "auto", // Added to enable scrolling only when needed
        width: isMedium ? "60vw" : "90vw",
      }}
      visible={visible}
      onClose={handleClose}>
      <View style={styles.container}>
        {!existingActivity && step === "choose" ? (
          <ChooseActivity onSelect={handleActivityTypeSelect} onCancel={handleClose} />
        ) : (
          <EditActivity
            activityType={selectedActivityType}
            onSave={handleSave}
            onCancel={handleClose}
            isNewActivity={!existingActivity?.id}
            error={error}
            activityData={formattedActivityData}
            isSubmitting={isSubmitting}
          />
        )}
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
});

export default EditActivityModalContainer;
