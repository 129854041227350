import React, { useState, useEffect } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import {
  useGetModuleAnalyticsByCourseLazyQuery,
  useGetActivityAnalyticsByModuleLazyQuery,
  useGetObjectiveAnalyticsByActivityLazyQuery,
  useGetModuleCompletionDetailsLazyQuery,
  useGetActivityCompletionDetailsLazyQuery,
  useGetObjectiveCompletionDetailsLazyQuery,
} from "../../../graphql/generated/graphql";
import AnalyticsAccordion from "../AnalyticsAccordion/AnalyticsAccordion";
import AnalyticsItemList from "../AnalyticsItemList/TaskList/AnalyticsItemList";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../common/general/CustomText/CustomText";

const nextLevelMapping = {
  course: "module",
  module: "activity",
  activity: "objective",
};

function getCurrentLevel(breadcrumb) {
  return breadcrumb?.length ? breadcrumb[breadcrumb.length - 1].level || "course" : "course";
}

function formatTime(seconds) {
  if (!seconds) return "N/A";
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  if (hrs === 0) return `${mins} min`;
  if (mins === 0) return `${hrs} hr${hrs > 1 ? "s" : ""}`;
  return `${hrs} hr${hrs > 1 ? "s" : ""} ${mins} min`;
}

function transformForList(item, index, level) {
  if (!item) return null;
  return {
    id: item.id || `item-${index}`,
    name: item.label || "Untitled",
    avgTime: formatTime(item.average_time_taken),
    successPoints: item.success_points || 0,
    confusionPoints: item.confusion_points || 0,
    questionsAsked: item.questions_asked || 0,
    activeInteractionCount: item.active_interaction_count || 0,
    enrollment: item.enrollment || 0,
    sentiment: item.sentiment || "neutral",
    distractions: item.distractions || 0,
    completionStats: item.completionStats || { completed: 0, inProgress: 0, notStarted: 100 },
    label: item.label,
    level,
    ...item,
    struggleScore: (item.confusion_points || 0) + (item.distractions || 0),
    engagementScore: (item.success_points || 0) + (item.questions_asked || 0) + (item.active_interaction_count || 0),
  };
}

function pickStrugglingAndEngaging(items) {
  if (!items?.length) return { struggling: null, engaging: null };
  const validItems = items.filter(Boolean);
  if (!validItems.length) return { struggling: null, engaging: null };
  if (validItems.length === 1) {
    const item = validItems[0];
    return item.engagementScore >= item.struggleScore
      ? { struggling: null, engaging: item }
      : { struggling: item, engaging: null };
  }
  const engaging = [...validItems].sort((a, b) => b.engagementScore - a.engagementScore)[0];
  const struggling = [...validItems].sort((a, b) => b.struggleScore - a.struggleScore)[0];
  return engaging === struggling ? { struggling: null, engaging } : { struggling, engaging };
}

function AnalyticsAccordionList({
  data,
  onListDrillDown,
  breadcrumb,
  loadChildData,
  expandedItemIds,
  setExpandedItemIds,
  childrenData,
  setChildrenData,
  loadingChildren,
  setLoadingChildren,
}) {
  const level = getCurrentLevel(breadcrumb);
  const [strugglingItems, setStrugglingItems] = useState({});
  const [engagingItems, setEngagingItems] = useState({});

  const [fetchModuleCompletion] = useGetModuleCompletionDetailsLazyQuery();
  const [fetchActivityCompletion] = useGetActivityCompletionDetailsLazyQuery();
  const [fetchObjectiveCompletion] = useGetObjectiveCompletionDetailsLazyQuery();

  // Reset struggling/engaging items when breadcrumb changes
  useEffect(() => {
    setStrugglingItems({});
    setEngagingItems({});
  }, [breadcrumb]);

  const handleToggleExpand = (item) => {
    const itemId = item.id;
    if (!itemId) return;
    const isExpanded = !!expandedItemIds[itemId];
    setExpandedItemIds((prev) => ({ ...prev, [itemId]: !isExpanded }));
    if (isExpanded || childrenData[itemId]?.length) return;

    // If custom loadChildData exists, use it, otherwise use our internal function
    if (loadChildData) {
      loadChildData(itemId, item.level);
    } else {
      fetchChildDataForItem(item);
    }
  };

  const fetchChildDataForItem = async (item) => {
    const itemId = item.id;
    setLoadingChildren((prev) => ({ ...prev, [itemId]: true }));

    try {
      // This is the internal version, only used if loadChildData prop is not provided
      let childItems = [];

      // Fetch the data based on item level
      if (item.level === "course") {
        const result = await fetchModulesByCourse(itemId);
        childItems = result?.data?.getModuleAnalyticsByCourse?.data || [];
      } else if (item.level === "module") {
        const result = await fetchActivitiesByModule(itemId);
        childItems = result?.data?.getActivityAnalyticsByModule?.data || [];
      } else if (item.level === "activity") {
        const result = await fetchObjectivesByActivity(itemId);
        childItems = result?.data?.getObjectiveAnalyticsByActivity?.data || [];
      }

      if (childItems.length > 0) {
        setChildrenData((prev) => ({ ...prev, [itemId]: childItems }));
        updateStrugglingAndEngaging(itemId, childItems, item.level);
      }
    } catch (error) {
      console.error(`Error fetching child data for ${item.level} ${itemId}:`, error);
    } finally {
      setLoadingChildren((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  const updateStrugglingAndEngaging = (parentId, items, itemLevel) => {
    const nextLevel = nextLevelMapping[itemLevel];
    if (!nextLevel) return;

    const { struggling, engaging } = pickStrugglingAndEngaging(
      items.map((item, i) => transformForList(item, i, nextLevel))
    );

    if (struggling) {
      fetchCompletionDataForItem(struggling, parentId, "struggling");
    }

    if (engaging) {
      fetchCompletionDataForItem(engaging, parentId, "engaging");
    }
  };

  const fetchCompletionDataForItem = (item, parentId, type) => {
    if (item.level === "module") {
      fetchModuleCompletion({
        variables: { moduleId: item.id },
        onCompleted: (completionResponse) => {
          const completionData = completionResponse?.getModuleCompletionDetails?.data?.[0];
          if (completionData) {
            if (type === "struggling") {
              setStrugglingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            } else {
              setEngagingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            }
          }
        },
      });
    } else if (item.level === "activity") {
      fetchActivityCompletion({
        variables: { aiTutorId: item.id },
        onCompleted: (completionResponse) => {
          const completionData = completionResponse?.getActivityCompletionDetails?.data?.[0];
          if (completionData) {
            if (type === "struggling") {
              setStrugglingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            } else {
              setEngagingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            }
          }
        },
      });
    } else if (item.level === "objective") {
      fetchObjectiveCompletion({
        variables: { objectiveId: item.id },
        onCompleted: (completionResponse) => {
          const completionData = completionResponse?.getObjectiveCompletionDetails?.data?.[0];
          if (completionData) {
            if (type === "struggling") {
              setStrugglingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            } else {
              setEngagingItems((prev) => ({
                ...prev,
                [parentId]: { ...item, completionStats: completionData.completionStats },
              }));
            }
          } else {
            // If no completion data, still set the item
            if (type === "struggling") {
              setStrugglingItems((prev) => ({
                ...prev,
                [parentId]: item,
              }));
            } else {
              setEngagingItems((prev) => ({
                ...prev,
                [parentId]: item,
              }));
            }
          }
        },
        onError: () => {
          // Still set the item without completion data
          if (type === "struggling") {
            setStrugglingItems((prev) => ({
              ...prev,
              [parentId]: item,
            }));
          } else {
            setEngagingItems((prev) => ({
              ...prev,
              [parentId]: item,
            }));
          }
        },
      });
    }
  };

  // Effect to update struggling/engaging items when childrenData changes
  useEffect(() => {
    Object.entries(childrenData).forEach(([parentId, items]) => {
      if (items && items.length > 0) {
        // Figure out the level from any item in the collection
        const parentItem = data.find((item) => item.id === parentId);
        if (parentItem) {
          updateStrugglingAndEngaging(parentId, items, parentItem.level);
        }
      }
    });
  }, [childrenData, data]);

  const topLevelItems = Array.isArray(data) ? data : [];

  return (
    <View style={styles.container}>
      {topLevelItems.map((item, idx) => {
        const itemId = item.id;
        if (!itemId) return null;
        const isExpanded = !!expandedItemIds[itemId];
        const isLoading = !!loadingChildren[itemId];
        const childItems = childrenData[itemId] || [];
        const nextLevel = nextLevelMapping[item.level];
        const nextLevelName = nextLevel
          ? nextLevel === "activity"
            ? "Activities"
            : nextLevel.charAt(0).toUpperCase() + nextLevel.slice(1) + "s"
          : "Items";
        const struggling = strugglingItems[itemId];
        const engaging = engagingItems[itemId];
        const strugglingData = struggling ? [struggling] : [];
        const engagingData = engaging ? [engaging] : [];

        return (
          <AnalyticsAccordion
            key={`accordion-${itemId}-${idx}`}
            title={item.label}
            data={item}
            initialExpanded={idx === 0 && !breadcrumb?.length}
            onExplorePress={() => onListDrillDown(item)}
            onToggleExpand={() => handleToggleExpand(item)}
            isExpanded={isExpanded}>
            {isLoading ? (
              <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0084FF" />
                <CustomText
                  useTranslationText={false}
                  text={`Loading ${nextLevelName.toLowerCase()}...`}
                  size="m"
                  style={{ marginTop: 10 }}
                />
              </View>
            ) : childItems.length === 0 && isExpanded ? (
              <View style={styles.emptyContainer}>
                <CustomText useTranslationText={false} text={`No ${nextLevelName.toLowerCase()} found`} size="m" />
              </View>
            ) : (
              isExpanded && (
                <View style={styles.row}>
                  <View style={styles.column}>
                    <AnalyticsItemList
                      headerText={`Struggling ${nextLevelName} 😟`}
                      headerColor="#FFF1F3"
                      items={{ data: { data: strugglingData } }}
                      onListItemPress={onListDrillDown}
                    />
                  </View>
                  <CustomSpacing type="horizontal" size="l" />
                  <View style={styles.column}>
                    <AnalyticsItemList
                      headerText={`Engaged ${nextLevelName} 😊`}
                      headerColor="#F3FEE7"
                      items={{ data: { data: engagingData } }}
                      onListItemPress={onListDrillDown}
                    />
                  </View>
                </View>
              )
            )}
          </AnalyticsAccordion>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 10,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flex: 1,
    paddingHorizontal: 5,
  },
  loadingContainer: {
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  emptyContainer: {
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AnalyticsAccordionList;
