import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useEffect, useState } from "react";
import { MagnifyingGlass } from "../../../components/svgs/common";
import { PlusCircle } from "../../../components/svgIcons";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import {
  useSchoolsQuery,
  useCreateDegreeMutation,
  useDeleteDegreeMutation,
  CreateDegreeMutation,
  GetDegreesBySchoolByQueryDocument,
  useGetDegreesBySchoolByQueryQuery,
  useUpdateDegreeMutation,
  UpdateDegreeMutation,
} from "../../../graphql/generated/graphql";
import Pagination from "../../../components/common/Pagination/Pagination";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import DegreesTable from "./DegreesTable";
import { useAppConfig } from "../../../AppConfigProvider";
import AddDegreeModal from "./AddDegreeModalContainer";
import DegreeCoursesTabContainer from "../DegreeCoursesTabContainer/DegreeCoursesTabContainer";
import UpdateDegreeModal from "./UpdateDegreeModalContainer";

const modals = {
  deleteDegree: {
    title: "Delete Degree?",
    ariaLabel: "Delete Degree Modal",
    warningText: "Are you sure you want to permanently delete this degree? This action cannot be undone.",
    actionButtonText: "Delete Degree",
  },
  deleteConfirmation: {
    title: "Degree Deleted",
    ariaLabel: "Degree Deleted Modal",
    warningText: "The degree has been successfully deleted.",
    actionButtonText: "Ok",
  },
};

export enum DegreesTabModalState {
  CLOSED = "CLOSED",
  DELETE_DEGREE = "DELETE_DEGREE",
  DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
  ADD_DEGREE = "ADD_DEGREE",
  DEGREE_COURSES = "DEGREE_COURSES",
  CHANGE_NAME = "CHANGE_NAME",
}

const DegreesTabContainer = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [modalState, setModalState] = useState({
    state: DegreesTabModalState.CLOSED,
    degreeId: "",
    degreeName: "",
  });

  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  const { data: schoolsData } = useSchoolsQuery();

  const { data: degreesData, refetch: degreesRefetch } = useGetDegreesBySchoolByQueryQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0].id,
      query: searchQuery,
      page: page,
      pageSize: pageSize,
    },
  });

  const refetchQueries = [
    {
      query: GetDegreesBySchoolByQueryDocument,
      variables: {
        schoolId: schoolsData?.getSchools[0].id,
        query: searchQuery,
        page: page,
        pageSize: pageSize,
      },
    },
  ];

  const [createDegree] = useCreateDegreeMutation();
  const [deleteDegree] = useDeleteDegreeMutation();
  const [updateDegree] = useUpdateDegreeMutation();

  const addDegree = async ({ degreeName }: { degreeName: string }) => {
    try {
      const result = await createDegree({
        variables: {
          name: degreeName,
          schoolId: schoolsData?.getSchools[0].id,
        },
        refetchQueries: refetchQueries,
      });

      return result.data;
    } catch (error) {
      return {
        createDegree: {
          data: null,
          success: false,
        },
      } as unknown as CreateDegreeMutation;
    }
  };

  const handleDeleteDegree = async () => {
    await deleteDegree({
      variables: {
        id: modalState.degreeId,
      },
      refetchQueries: refetchQueries,
    });
    setModalState({ state: DegreesTabModalState.DELETE_CONFIRMATION, degreeId: "", degreeName: "" });
    setSearchQuery("");
  };

  const handleActionModalOpen = async (degreeId: string, degreeName: string, modalType: DegreesTabModalState) => {
    setModalState({ state: modalType, degreeId, degreeName });
  };

  const handleModalClose = () => {
    setModalState({ state: DegreesTabModalState.CLOSED, degreeId: "", degreeName: "" });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= degreesData?.getDegreesBySchoolByQuery?.data.totalCount) {
      setPage(newPage);
    }
  };

  const handleChangeDegreeName = async (formData: { degreeName: string }) => {
    try {
      const result = await updateDegree({
        variables: {
          id: modalState.degreeId,
          degree: {
            name: formData.degreeName,
          },
        },
        refetchQueries,
      });
      return result.data;
    } catch (error) {
      console.log("change name err", error);

      return {
        updateDegree: {
          data: null,
          success: false,
        },
      } as unknown as UpdateDegreeMutation;
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    degreesRefetch();
  }, [searchQuery, page, pageSize]);

  if (modalState.state === DegreesTabModalState.DEGREE_COURSES) {
    const selectedDegree = degreesData?.getDegreesBySchoolByQuery?.data.degrees.find(
      (degree) => degree.id === modalState.degreeId
    );
    return (
      <DegreeCoursesTabContainer
        degree={{ id: selectedDegree.id, name: selectedDegree.name, coursesHasCourse: selectedDegree.coursesHasCourse }}
        onBack={() => setModalState((prev) => ({ ...prev, state: DegreesTabModalState.CLOSED }))}
      />
    );
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.headerRow}>
            <CustomText text="Manage Degrees" size="l" weight="bold" style={styles.headerText} />
            <View style={styles.buttonGroup}>
              <NewCustomActionButton
                text="Add a Degree"
                onPress={() => setModalState({ state: DegreesTabModalState.ADD_DEGREE, degreeId: "", degreeName: "" })}
                leftIcon={<PlusCircle color={primaryColor} />}
              />
            </View>
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Total Degrees" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${degreesData?.getDegreesBySchoolByQuery?.data.totalCount || 0})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
            </View>
            <View style={styles.searchContainer}>
              <MagnifyingGlass style={styles.searchIcon} />
              <CustomTextInput
                placeholder="Search by any value"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={[
                  styles.searchInput,
                  Platform.select({
                    web: {
                      outlineStyle: "none",
                      outlineWidth: 0,
                      WebkitAppearance: "none",
                    } as unknown,
                  }),
                ]}
                placeholderTextColor="#9CA3AF"
                selectionColor="#6B7280"
                cursorColor="#6B7280"
              />
            </View>
          </View>
        </View>
        <DegreesTable
          degrees={degreesData?.getDegreesBySchoolByQuery?.data.degrees || []}
          onActionModalOpen={handleActionModalOpen}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil(degreesData?.getDegreesBySchoolByQuery?.data.totalCount / pageSize)}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.state === DegreesTabModalState.DELETE_DEGREE}
        onClose={handleModalClose}
        onAction={handleDeleteDegree}
        modalTexts={modals.deleteDegree}
        contextualText={modalState.degreeName}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === DegreesTabModalState.DELETE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.deleteConfirmation}
        contextualText={modalState.degreeName}
      />
      <AddDegreeModal
        visible={modalState.state === DegreesTabModalState.ADD_DEGREE}
        onClose={handleModalClose}
        onSubmit={addDegree}
      />
      <UpdateDegreeModal
        visible={modalState.state === DegreesTabModalState.CHANGE_NAME}
        onClose={handleModalClose}
        onSubmit={handleChangeDegreeName}
        currentDegreeName={modalState.degreeName}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  buttonGroup: {
    flexDirection: "row",
    gap: 12,
  },
  headerText: {
    color: "#000",
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  subHeaderText: {
    color: "#000",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});

export default DegreesTabContainer;
