import React from "react";
import { DataTable } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { FlatList, StyleSheet } from "react-native";
import { Checkbox } from "react-native-paper";
import { Course } from "../../../graphql/generated/graphql";
import Pill from "../../../components/common/Pill/Pill";
import { CourseTypes } from "../CoursesTabContainer/CoursesTable";

export interface DegreeCoursesTableProps {
  courses: Pick<Course, "id" | "name" | "courseType">[];
  setSelectedCourses: (courseIds: string[]) => void;
  selectedCourses: string[];
}

const DegreeCoursesTable = ({ courses, setSelectedCourses, selectedCourses }: DegreeCoursesTableProps) => {
  const toggleSelection = (id: string) => {
    const newSelectedIds = new Set(selectedCourses);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
    } else {
      newSelectedIds.add(id);
    }
    setSelectedCourses(Array.from(newSelectedIds));
  };

  const toggleSelectAll = () => {
    if (selectedCourses.length === courses.length) {
      setSelectedCourses([]);
    } else {
      setSelectedCourses(courses.map((s) => s.id));
    }
  };

  return (
    <>
      <DataTable>
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Cell style={styles.checkboxColumn} id="checkboxColumn">
            <Checkbox
              status={
                selectedCourses.length === courses.length && courses.length > 0
                  ? "checked"
                  : selectedCourses.length > 0
                    ? "indeterminate"
                    : "unchecked"
              }
              onPress={toggleSelectAll}
              theme={{ colors: { primary: "#3e68fe" } }}
            />
          </DataTable.Cell>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Course Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
          <DataTable.Title style={styles.typeColumn}>
            <CustomText text="Course Type" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>

        <FlatList
          data={courses}
          renderItem={({ item: course }) => {
            const courseType = CourseTypes[course.courseType] || CourseTypes.General;

            return (
              <DataTable.Row style={styles.tableRow}>
                <DataTable.Cell style={styles.checkboxColumn}>
                  <Checkbox
                    status={selectedCourses.includes(course.id) ? "checked" : "unchecked"}
                    onPress={() => toggleSelection(course.id)}
                    theme={{ colors: { primary: "#3e68fe" } }}
                  />
                </DataTable.Cell>
                <DataTable.Cell style={styles.nameColumn}>
                  <CustomText text={course.name} size="s" style={styles.courseName} useTranslationText={false} />
                </DataTable.Cell>
                <DataTable.Cell style={styles.typeColumn}>
                  <Pill
                    text={courseType.name}
                    leftIcon={courseType.icon}
                    backgroundColor={courseType.color}
                    textColor={courseType.textColor}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            );
          }}
          keyExtractor={(item) => item.id}
        />
      </DataTable>
    </>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    minHeight: 48,
    height: 48,
  },
  checkboxColumn: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 48,
  },
  nameColumn: { flex: 4, minHeight: 48 },
  typeColumn: { flex: 2 },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
    alignItems: "center",
    minHeight: 48,
  },
  courseName: {
    color: "#111827",
    fontSize: 14,
    fontWeight: "500",
  },
});

export default DegreeCoursesTable;
