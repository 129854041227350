import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity, Image } from "react-native";
import { useMutation } from "@apollo/client";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomTextInput from "../../components/common/general/CustomTextInput/CustomTextInput";
import { getYoutubeVideoId } from "../../utils/youtubeUtils.js";
import YoutubeVideoPlayerAdvanced from "../../components/youtube/YoutubeVideoPlayerAdvanced";
import { gql } from "@apollo/client";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomModal from "../../components/common/general/CustomModal/CustomModal";

// Create a VideoItem component that handles its own metadata
const VideoItem = ({ video, objectiveText, onRemove, onUpdateUsage }) => {
  const [title, setTitle] = useState(video.title || "Loading...");
  const [description, setDescription] = useState(video.description || "");
  const [isLoading, setIsLoading] = useState(!video.title);
  const [usage, setUsage] = useState(video.usage || "both");

  useEffect(() => {
    // Only fetch if we don't already have title
    if (!video.title || video.title === "Video" || video.title === "Loading...") {
      fetchVideoInfo();
    }
  }, [video.videoId]);

  const fetchVideoInfo = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${video.videoId}&part=snippet&key=AIzaSyA8Luh5WbUdXzxY6JvmsNfFXdpLUQaZO_c`
      );
      const data = await response.json();

      if (data.items && data.items.length > 0) {
        const videoInfo = data.items[0].snippet;
        setTitle(videoInfo.title);
        setDescription(videoInfo.description);
      } else {
        setTitle("Custom Video");
      }
    } catch (error) {
      console.error("Error fetching video info:", error);
      setTitle("Custom Video");
    } finally {
      setIsLoading(false);
    }
  };

  /* Commented out video usage feature
  const handleUsageChange = (newUsage) => {
    setUsage(newUsage);
    onUpdateUsage(objectiveText, video.videoId, newUsage);
  };
  */

  return (
    <View style={styles.videoItem}>
      <View style={styles.videoPreview}>
        <YoutubeVideoPlayerAdvanced id={video.videoId} />
      </View>
      <View style={styles.videoInfo}>
        <CustomText
          text={isLoading ? "Loading..." : title}
          useTranslationText={false}
          weight="bold"
          numberOfLines={2}
        />
        {description && (
          <CustomText text={description} useTranslationText={false} numberOfLines={3} style={styles.videoDescription} />
        )}

        {/* Commented out video usage selector UI
        <View style={styles.usageSelector}>
          <CustomText text="Video Usage:" useTranslationText={false} style={styles.usageLabel} />
          <View style={styles.usageOptions}>
            <TouchableOpacity
              style={[styles.usageOption, usage === "rag" && styles.selectedUsage]}
              onPress={() => handleUsageChange("rag")}>
              <CustomText
                text="Context for AI"
                useTranslationText={false}
                style={usage === "rag" ? styles.selectedUsageText : styles.usageText}
                size="xs"
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.usageOption, usage === "display" && styles.selectedUsage]}
              onPress={() => handleUsageChange("display")}>
              <CustomText
                text="Display for Students"
                useTranslationText={false}
                style={usage === "display" ? styles.selectedUsageText : styles.usageText}
                size="xs"
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.usageOption, usage === "both" && styles.selectedUsage]}
              onPress={() => handleUsageChange("both")}>
              <CustomText
                text="Both"
                useTranslationText={false}
                style={usage === "both" ? styles.selectedUsageText : styles.usageText}
                size="xs"
              />
            </TouchableOpacity>
          </View>
        </View>
        */}

        <TouchableOpacity style={styles.removeButton} onPress={() => onRemove(objectiveText, video.videoId)}>
          <CustomText text="Remove" useTranslationText={false} style={styles.removeButtonText} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

// GraphQL mutations
const CREATE_VIDEO_POOL = gql`
  mutation CreateVideoPool($title: String!, $description: String!, $objectives: [ObjectiveForVideoInput!]!) {
    createVideoPool(title: $title, description: $description, objectives: $objectives) {
      success
      message
      data {
        videoId
        url
        title
        description
        thumbnailUrl
        objectiveText
        objectiveSequence
      }
      count
    }
  }
`;

interface Objective {
  objective: string;
  sequence: number;
}

interface VideoData {
  videoId: string;
  url: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  objectiveText: string;
  objectiveSequence: number;
  usage?: "rag" | "display" | "both";
  isTeacherAdded?: boolean;
}

interface VideoPoolManagerProps {
  title: string;
  description: string;
  objectives: Objective[];
  onVideoPoolReady: (
    videoPool: Array<{
      videoId: string;
      objectiveId: string;
      objectiveSequence?: number;
      usage?: "rag" | "display" | "both";
      isTeacherAdded?: boolean;
    }>
  ) => void;
  initialVideoPool?: Array<{
    videoId: string;
    objectiveId: string;
    objectiveSequence?: number;
    usage?: "rag" | "display" | "both";
    isTeacherAdded?: boolean;
  }>;
}

const VideoPoolManager: React.FC<VideoPoolManagerProps> = ({
  title,
  description,
  objectives,
  onVideoPoolReady,
  initialVideoPool = [],
}) => {
  // Log when component is mounted to verify we're getting data
  console.log("VideoPoolManager mounted with initialVideoPool:", initialVideoPool);

  const [videosByObjective, setVideosByObjective] = useState<Record<string, VideoData[]>>({});
  const [customVideoUrl, setCustomVideoUrl] = useState("");
  const [customVideoTitle, setCustomVideoTitle] = useState("");
  const [customVideoDescription, setCustomVideoDescription] = useState("");
  const [selectedObjectiveForCustomVideo, setSelectedObjectiveForCustomVideo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVideoInfo, setIsLoadingVideoInfo] = useState(false);
  const [error, setError] = useState("");

  // New state for the URL popup modal
  const [showAddUrlModal, setShowAddUrlModal] = useState(false);
  const [currentObjective, setCurrentObjective] = useState<Objective | null>(null);
  const [urlModalInput, setUrlModalInput] = useState("");
  const [modalVideoTitle, setModalVideoTitle] = useState("");
  const [modalVideoDescription, setModalVideoDescription] = useState("");
  const [validVideoUrl, setValidVideoUrl] = useState(false);

  // Add ref to track if component has initialized with initial data
  const hasInitializedRef = useRef(false);

  const [createVideoPool] = useMutation(CREATE_VIDEO_POOL);

  // Effect to handle initialization from initialVideoPool
  useEffect(() => {
    if (hasInitializedRef.current) return;

    // Initialize the videosByObjective with empty arrays for each objective
    const initialVideosByObjective: Record<string, VideoData[]> = {};
    objectives.forEach((obj) => {
      initialVideosByObjective[obj.objective] = [];
    });

    // If initialVideoPool is provided, populate the videos by objective
    if (initialVideoPool && initialVideoPool.length > 0) {
      console.log("Initializing with video pool:", initialVideoPool);

      initialVideoPool.forEach((video) => {
        const objective = objectives.find(
          (obj) => obj.sequence === video.objectiveSequence || video.objectiveId === obj.objective
        );

        if (objective && objective.objective) {
          const videoData: VideoData = {
            videoId: video.videoId,
            url: `https://www.youtube.com/watch?v=${video.videoId}`,
            title: "", // Will be fetched by VideoItem component
            description: "", // Will be fetched by VideoItem component
            thumbnailUrl: `https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`,
            objectiveText: objective.objective,
            objectiveSequence: objective.sequence,
            usage: video.usage || "both",
            isTeacherAdded: video.isTeacherAdded || false, // Preserve isTeacherAdded flag from initialVideoPool
          };

          if (!initialVideosByObjective[objective.objective]) {
            initialVideosByObjective[objective.objective] = [];
          }

          initialVideosByObjective[objective.objective].push(videoData);
        }
      });

      // Immediately trigger metadata fetching after initialization
      setTimeout(() => {
        const videoIds = initialVideoPool.map((v) => v.videoId);
        console.log("Scheduled metadata fetch for:", videoIds);
      }, 100);
    }

    setVideosByObjective(initialVideosByObjective);
    hasInitializedRef.current = true;
  }, [objectives, initialVideoPool]);

  const handleGenerateVideos = async () => {
    if (!title || !description || objectives.length === 0) {
      setError("Title, description, and at least one objective are required");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      const response = await createVideoPool({
        variables: {
          title,
          description,
          objectives: objectives.map((obj) => ({
            objective: obj.objective,
            sequence: obj.sequence,
          })),
        },
      });

      if (response.data?.createVideoPool?.success) {
        const videos = response.data.createVideoPool.data;

        // Create a copy of the current videos to preserve user-added URLs
        const mergedVideosByObjective = { ...videosByObjective };

        // Add new recommended videos to existing ones
        videos.forEach((video: VideoData) => {
          if (mergedVideosByObjective[video.objectiveText]) {
            // Check if this video already exists (avoid duplicates)
            const videoExists = mergedVideosByObjective[video.objectiveText].some(
              (existingVideo) => existingVideo.videoId === video.videoId
            );

            if (!videoExists) {
              mergedVideosByObjective[video.objectiveText].push(video);
            }
          }
        });

        setVideosByObjective(mergedVideosByObjective);
      } else {
        setError(response.data?.createVideoPool?.message || "Failed to generate videos");
      }
    } catch (err) {
      console.error("Error generating videos:", err);
      setError("An error occurred while generating videos");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch video title and description from YouTube API
  const fetchVideoInfo = async (videoId: string) => {
    if (!videoId) return;

    setIsLoadingVideoInfo(true);
    try {
      // Using a public API proxy to fetch video info
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=AIzaSyA8Luh5WbUdXzxY6JvmsNfFXdpLUQaZO_c`
      );
      const data = await response.json();

      if (data.items && data.items.length > 0) {
        const videoInfo = data.items[0].snippet;
        setCustomVideoTitle(videoInfo.title);
        setCustomVideoDescription(videoInfo.description);
      } else {
        setCustomVideoTitle("Custom Video");
        setCustomVideoDescription("");
      }
    } catch (error) {
      console.error("Error fetching video info:", error);
      setCustomVideoTitle("Custom Video");
      setCustomVideoDescription("");
    } finally {
      setIsLoadingVideoInfo(false);
    }
  };

  // Function to fetch modal video info when URL is pasted
  const fetchModalVideoInfo = async (videoId: string) => {
    if (!videoId) return;

    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=AIzaSyA8Luh5WbUdXzxY6JvmsNfFXdpLUQaZO_c`
      );
      const data = await response.json();

      if (data.items && data.items.length > 0) {
        const videoInfo = data.items[0].snippet;
        setModalVideoTitle(videoInfo.title);
        setModalVideoDescription(videoInfo.description);
        setValidVideoUrl(true);
      } else {
        setModalVideoTitle("");
        setModalVideoDescription("");
        setValidVideoUrl(false);
      }
    } catch (error) {
      console.error("Error fetching video info:", error);
      setModalVideoTitle("");
      setModalVideoDescription("");
      setValidVideoUrl(false);
    }
  };

  // Effect to fetch video info when modal URL changes
  useEffect(() => {
    setModalVideoTitle("");
    setModalVideoDescription("");
    setValidVideoUrl(false);

    const videoId = getYoutubeVideoId(urlModalInput);
    if (videoId) {
      fetchModalVideoInfo(videoId);
    }
  }, [urlModalInput]);

  // Update the handleAddCustomVideo function to work with the popup modal
  const handleAddUrlFromModal = () => {
    if (!urlModalInput || !currentObjective) {
      return;
    }

    const videoId = getYoutubeVideoId(urlModalInput);
    if (!videoId) {
      setError("Invalid YouTube URL");
      return;
    }

    // Add the custom video to the selected objective
    setVideosByObjective((prev) => {
      const updatedVideos = { ...prev };
      updatedVideos[currentObjective.objective] = [
        ...updatedVideos[currentObjective.objective],
        {
          videoId,
          url: urlModalInput,
          title: modalVideoTitle || "Custom Video",
          description: modalVideoDescription || "",
          thumbnailUrl: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
          objectiveText: currentObjective.objective,
          objectiveSequence: currentObjective.sequence,
          usage: "both",
          isTeacherAdded: true, // Mark videos added by teachers
        },
      ];
      return updatedVideos;
    });

    // Clear the input and close the modal
    setUrlModalInput("");
    setModalVideoTitle("");
    setModalVideoDescription("");
    setValidVideoUrl(false);
    setShowAddUrlModal(false);
  };

  const handleRemoveVideo = (objectiveText: string, videoId: string) => {
    setVideosByObjective((prev) => {
      const updatedVideos = { ...prev };
      updatedVideos[objectiveText] = updatedVideos[objectiveText].filter((v) => v.videoId !== videoId);
      return updatedVideos;
    });
  };

  // Add effect to fetch metadata for initial videos
  useEffect(() => {
    const fetchAllVideoInfo = async () => {
      const allVideosIds = Object.values(videosByObjective)
        .flat()
        .filter((video) => video.title === "Video" || !video.title) // Only fetch for videos without titles
        .map((video) => video.videoId);

      if (allVideosIds.length === 0) return;

      console.log("Fetching metadata for videos:", allVideosIds);

      // Fetch in batches of 5 to avoid rate limits
      for (let i = 0; i < allVideosIds.length; i += 5) {
        const batch = allVideosIds.slice(i, i + 5);
        if (batch.length === 0) continue;

        try {
          const response = await fetch(
            `https://www.googleapis.com/youtube/v3/videos?id=${batch.join(",")}&part=snippet&key=AIzaSyA8Luh5WbUdXzxY6JvmsNfFXdpLUQaZO_c`
          );
          const data = await response.json();

          if (data.items && data.items.length > 0) {
            const videoUpdates: Record<string, { title: string; description: string }> = {};

            data.items.forEach((item) => {
              const { id, snippet } = item;
              videoUpdates[id] = {
                title: snippet.title,
                description: snippet.description,
              };
            });

            // Update all videos with the fetched metadata
            setVideosByObjective((prev) => {
              const updated = { ...prev };

              Object.keys(updated).forEach((objKey) => {
                updated[objKey] = updated[objKey].map((video) => {
                  if (videoUpdates[video.videoId]) {
                    return {
                      ...video,
                      title: videoUpdates[video.videoId].title,
                      description: videoUpdates[video.videoId].description,
                    };
                  }
                  return video;
                });
              });

              return updated;
            });
          }
        } catch (error) {
          console.error("Error fetching video info batch:", error);
        }

        // Brief delay to avoid API rate limits
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    };

    // Call this function when the component is mounted and videos are initialized
    if (hasInitializedRef.current) {
      fetchAllVideoInfo();
    }
  }, [videosByObjective]); // Run when videosByObjective changes

  // Add function to handle updating video usage
  const handleUpdateVideoUsage = (objectiveText: string, videoId: string, usage: "rag" | "display" | "both") => {
    setVideosByObjective((prev) => {
      const updatedVideos = { ...prev };
      updatedVideos[objectiveText] = updatedVideos[objectiveText].map((video) => {
        if (video.videoId === videoId) {
          return { ...video, usage };
        }
        return video;
      });
      return updatedVideos;
    });
  };

  const handleFinalize = () => {
    // Prepare the final video data for the onVideoPoolReady callback
    const videoPool: Array<{
      videoId: string;
      objectiveId: string;
      objectiveSequence?: number;
      usage?: "rag" | "display" | "both";
      isTeacherAdded?: boolean;
    }> = [];

    // Now include the objective text and sequence information
    Object.entries(videosByObjective).forEach(([objectiveText, videos]) => {
      // Find the objective to get its sequence
      const objective = objectives.find((obj) => obj.objective === objectiveText);
      const sequence = objective ? objective.sequence : 0;

      videos.forEach((video) => {
        videoPool.push({
          videoId: video.videoId,
          objectiveId: objectiveText, // This will match the objectiveText in the backend
          objectiveSequence: sequence, // Add the sequence for additional verification
          usage: video.usage || "both", // Include the usage setting
          isTeacherAdded: video.isTeacherAdded === true, // Explicitly check for true value
        });
      });
    });

    // This directly calls the parent component's handler which will close the modal
    onVideoPoolReady(videoPool);
  };

  return (
    <View style={styles.container}>
      <CustomText text="Video Recommendations" size="m" weight="ultraBold" />
      <CustomText
        text="Add your custom videos for each learning objective or generate recommendations."
        useTranslationText={false}
        style={styles.subText}
      />

      <CustomSpacing type="vertical" size="m" />

      <ScrollView style={styles.videosContainer}>
        {objectives.map((objective) => (
          <View key={objective.objective} style={styles.objectiveSection}>
            <View style={styles.objectiveHeader}>
              <CustomText
                text={`Objective ${objective.sequence}: ${objective.objective}`}
                useTranslationText={true}
                weight="bold"
              />
              <CustomButton
                text="Add URL"
                onPress={() => {
                  setCurrentObjective(objective);
                  setShowAddUrlModal(true);
                }}
                styleType="transparent"
                size="s"
              />
            </View>

            <CustomSpacing type="vertical" size="s" />

            {videosByObjective[objective.objective]?.length === 0 ? (
              <CustomText
                text="No videos added for this objective yet."
                useTranslationText={false}
                style={styles.emptyText}
              />
            ) : (
              <View style={styles.videosList}>
                {videosByObjective[objective.objective]?.map((video, index) => (
                  <VideoItem
                    key={`${video.videoId}-${index}`}
                    video={video}
                    objectiveText={objective.objective}
                    onRemove={handleRemoveVideo}
                    onUpdateUsage={handleUpdateVideoUsage}
                  />
                ))}
              </View>
            )}
          </View>
        ))}
      </ScrollView>

      {/* URL Add Modal */}
      {showAddUrlModal && currentObjective && (
        <CustomModal
          visible={showAddUrlModal}
          onRequestClose={() => setShowAddUrlModal(false)}
          onClose={() => setShowAddUrlModal(false)}
          title={`Add Video for Objective ${currentObjective.sequence}`}
          useTranslationText={false}
          size="sm"
          showCloseButton={false}
          style={{}}
          exitFill={true}
          onShow={() => {}}
          ariaLabel="Add YouTube URL">
          <View style={styles.urlModalContent}>
            <CustomText
              text={currentObjective.objective}
              useTranslationText={false}
              weight="semiBold"
              style={styles.urlModalObjective}
            />
            <CustomSpacing type="vertical" size="m" />
            <CustomTextInput
              placeholder="Paste YouTube URL here"
              value={urlModalInput}
              onChangeText={setUrlModalInput}
              style={styles.urlModalInput}
              autoFocus
            />

            {validVideoUrl && (
              <>
                <CustomSpacing type="vertical" size="m" />
                <View style={styles.videoInfoPreview}>
                  <View style={styles.thumbnailContainer}>
                    <Image
                      source={{ uri: `https://img.youtube.com/vi/${getYoutubeVideoId(urlModalInput)}/mqdefault.jpg` }}
                      style={styles.urlModalThumbnail}
                      resizeMode="cover"
                    />
                  </View>
                  <CustomSpacing type="vertical" size="s" />
                  <CustomText text={modalVideoTitle} useTranslationText={false} weight="bold" numberOfLines={2} />
                  {modalVideoDescription && (
                    <CustomText
                      text={modalVideoDescription.substring(0, 120) + (modalVideoDescription.length > 120 ? "..." : "")}
                      useTranslationText={false}
                      numberOfLines={3}
                      style={styles.videoDescription}
                    />
                  )}
                </View>
              </>
            )}

            <CustomSpacing type="vertical" size="m" />
            <View style={styles.urlModalButtons}>
              <CustomButton
                text="Cancel"
                onPress={() => {
                  setUrlModalInput("");
                  setModalVideoTitle("");
                  setModalVideoDescription("");
                  setValidVideoUrl(false);
                  setShowAddUrlModal(false);
                }}
                styleType="secondary"
                style={styles.urlModalCancelButton}
              />
              <CustomSpacing type="horizontal" size="m" />
              {validVideoUrl && <CustomButton text="Add Video" onPress={handleAddUrlFromModal} styleType="primary" />}
            </View>
          </View>
        </CustomModal>
      )}

      <CustomSpacing type="vertical" size="l" />

      {/* Generate recommendations button moved to bottom */}
      {!isLoading && (
        <CustomButton
          text="Get Video Recommendations"
          onPress={handleGenerateVideos}
          disabled={!title || objectives.length === 0}
          styleType={!title || objectives.length === 0 ? "disabled" : "secondary"}
        />
      )}

      {isLoading && (
        <CustomText text="Generating video recommendations..." useTranslationText={false} style={styles.loadingText} />
      )}

      {error && <CustomText text={error} useTranslationText={false} style={styles.errorText} />}

      <CustomSpacing type="vertical" size="m" />

      <View style={styles.buttonContainer}>
        <CustomButton
          text="Cancel"
          onPress={() => onVideoPoolReady([])}
          styleType="secondary"
          style={styles.cancelButton}
        />
        <CustomSpacing type="horizontal" size="m" />
        <CustomButton text="Done" onPress={handleFinalize} styleType="primary" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  subText: {
    color: "#666",
    marginTop: 4,
  },
  errorText: {
    color: "red",
    marginTop: 8,
  },
  videosContainer: {
    maxHeight: 500,
  },
  objectiveSection: {
    marginBottom: 20,
    padding: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
  },
  emptyText: {
    fontStyle: "italic",
    color: "#888",
  },
  videosList: {
    marginTop: 8,
  },
  videoItem: {
    flexDirection: "row",
    marginBottom: 16,
    backgroundColor: "#fff",
    borderRadius: 8,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  videoPreview: {
    width: 220,
    aspectRatio: 16 / 9, // Standard YouTube aspect ratio (16:9)
    overflow: "hidden",
  },
  videoInfo: {
    flex: 1,
    padding: 8,
    justifyContent: "space-between",
  },
  videoDescription: {
    fontSize: 12,
    color: "#666",
  },
  removeButton: {
    alignSelf: "flex-end",
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: "#f8f8f8",
    borderRadius: 4,
  },
  removeButtonText: {
    color: "#ff3b30",
    fontSize: 12,
  },
  videoInfoPreview: {
    marginTop: 8,
    padding: 12,
    backgroundColor: "#f8f8f8",
    borderRadius: 8,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    backgroundColor: "#f8f8f8",
  },
  objectiveHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  urlModalContent: {
    padding: 16,
  },
  urlModalObjective: {
    fontSize: 14,
  },
  urlModalInput: {
    width: "100%",
  },
  urlModalButtons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  urlModalCancelButton: {
    backgroundColor: "#f8f8f8",
  },
  urlModalThumbnail: {
    width: "100%",
    height: "100%",
    borderRadius: 4,
  },
  thumbnailContainer: {
    width: "100%",
    aspectRatio: 16 / 9, // Standard YouTube aspect ratio (16:9)
    borderRadius: 4,
    marginBottom: 8,
    backgroundColor: "#e0e0e0",
    overflow: "hidden",
  },
  loadingText: {
    fontStyle: "italic",
    color: "#666",
    marginTop: 8,
  },
  usageSelector: {
    marginTop: 8,
    marginBottom: 4,
  },
  usageLabel: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
  },
  usageOptions: {
    flexDirection: "row",
  },
  usageOption: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: "#f0f0f0",
    borderRadius: 4,
    marginRight: 6,
    borderWidth: 1,
    borderColor: "#e0e0e0",
  },
  selectedUsage: {
    backgroundColor: "#007bff",
    borderColor: "#0056b3",
  },
  usageText: {
    fontSize: 10,
    color: "#333",
  },
  selectedUsageText: {
    fontSize: 10,
    color: "#fff",
  },
});

export default VideoPoolManager;
