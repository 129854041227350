import React from "react";
import { Image } from "moti";
import { View, Text, Pressable, StyleSheet, ImageBackground } from "react-native";
import Divider from "../../common/layout/Divider/Divider";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";
import TimeLeftSmall from "../../svgs/common/TimeLeftSmall";
import CoreLabel from "../../svgs/common/CoreLabel";
import ElectiveLabel from "../../svgs/common/ElectiveLabel";
import GeneralLabel from "../../svgs/common/GeneralLabel";
import CheckedBadge from "../../svgs/common/CheckedBadge";
import { PlusCircle } from "../../svgIcons";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { shadowStyles } from "../../../styles/shadow";
import Edit from "../../svgs/common/Edit";
import { ChartLine } from "../../svgs/common";
import { useAppConfig } from "../../../AppConfigProvider";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import RoundedLabel from "../../common/label/RoundedLabel/RoundedLabel";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomHtml from "../../common/general/CustomHtml/CustomHtml";
import { he } from "react-native-paper-dates";

function CatalogueCard({ title, cost, description, imageUrl, onEnrollPress, onDetailsPress, isLoading }) {
  const { primaryColor } = useAppConfig();

  return (
    <View aria-label={`Course card ${title}`} style={styles.container}>
      <ImageBackground source={{ uri: imageUrl }} style={styles.image} />
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.textContainer}>
        <View style={styles.upperSection}>
          <CustomText
            useTranslationText={false}
            role="header"
            aria-level="1"
            weight="ultraBold"
            text={title}
            size="m"
            style={{ color: "#101828" }}
          />
          {cost && (
            <CustomText
              useTranslationText={false}
              role="header"
              aria-level="2"
              weight="ultraBold"
              text={`$${cost}`}
              size="l"
              style={{ color: "#000" }}
            />
          )}
          <CustomText
            useTranslationText={false}
            role="text"
            aria-level="3"
            weight="normal"
            text={description}
            size="s"
            style={{ color: "#667085", height: 60 }}
            numberOfLines={3}
            ellipsizeMode="tail"
          />
        </View>
        <View style={styles.lowerSection}>
          <CustomButton
            text="Course Details"
            styleType="primaryTransparent"
            onPress={onDetailsPress}
            textStyle={styles.detailsButtonText}
            style={{ paddingHorizontal: 0 }}
          />

          <CustomButton
            text="Enroll"
            onPress={onEnrollPress}
            style={[{ backgroundColor: primaryColor }, styles.enrollButtonStyle]}
            textStyle={styles.enrollButtonText}
            disabled={isLoading}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    overflow: "hidden",
    maxWidth: 570,
    minWidth: 400,
    width: "100%",
    borderColor: "#EAECF0",
    borderWidth: 1,
  },
  lowerSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopColor: "#EAECF0",
    borderTopWidth: 1,
    paddingTop: 16,
  },
  image: {
    width: "100%",
    height: 200,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingHorizontal: 24,
    paddingBottom: 24,
    backgroundColor: "white",
    gap: 9,
    height: 215,
  },
  upperSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 9,
  },
  enrollButtonText: {
    color: "white",
    fontWeight: "700",
  },
  enrollButtonStyle: {
    padding: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
  },
  detailsButtonText: {
    fontWeight: "700",
  },
});

export default CatalogueCard;
