import React, { useState, useEffect } from "react";
import { View, StyleSheet, ActivityIndicator, Modal } from "react-native";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useNavigation } from "@react-navigation/native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { ExitDoor } from "../components/svgs/common";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import DeleteResourceModalContainer from "../components/content/ContentModalContainers/DeleteResourceModalContainer";
import ModuleContainer from "../containers/customCoursework/ModuleContainer";
import ActivityContainer from "../containers/customCoursework/ActivityContainer";
import AddModuleModalContainer from "../components/content/ContentModalContainers/AddModuleModalContainer";
import EditActivityModalContainer from "../containers/customCoursework/EditActivityModalContainer/EditActivityModalContainer";
import {
  GetCourseByIdDocument,
  useCreateAiTutorMutation,
  useGetCourseByIdQuery,
  useDeleteModuleCascadeMutation,
  useDeleteAiTutorMutation,
  useEditAiTutorMutation,
  useCreateAxioResourceMutation,
  useUpdateAxioResourceMutation,
  useDeleteAxioResourceMutation,
  useUpdateCourseMutation,
  useUpdateResourceOrderMutation,
  useGenerateCourseContentMutation,
  useCreateAssignmentMutation,
  useUpdateModuleMutation,
} from "../graphql/generated/graphql";
import Sentry from "../utils/sentry";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";

export enum CustomCourseworkModulesViewModalState {
  CLOSED = "CLOSED",
  ADD_MODULE = "ADD_MODULE",
  EDIT_MODULE_NAME = "EDIT_MODULE_NAME",
  DELETE_MODULE_OR_ACTIVITY = "DELETE_MODULE_OR_ACTIVITY",
}

function CustomCourseworkModulesView({ route }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { dispatch } = useAppState();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const courseId = route.params?.courseId;

  // States
  const [deleteResourceModalVisible, setDeleteResourceModalVisible] = useState(false);
  const [deleteResourceType, setDeleteResourceType] = useState(null);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editActivityModalVisible, setEditActivityModalVisible] = useState(false);
  const [editingModule, setEditingModule] = useState(null);
  const [editingActivity, setEditingActivity] = useState(null);
  const [activityModalError, setActivityModalError] = useState(null);
  const [loadingGenerateCourse, setLoadingGenerateCourse] = useState(false);
  const [errorUpdatingNotifications, setErrorUpdatingNotifications] = useState("");
  const [modalState, setModalState] = useState({
    state: CustomCourseworkModulesViewModalState.CLOSED,
    resourceType: null,
    resourceId: null,
  });

  // Queries
  const { data: courseData, loading: courseLoading } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });

  const data = courseData?.getCourseById?.data?.[0];
  const isDraft = data?.isDraft;
  const loading = courseLoading;
  const currentModule = selectedModule || data?.modules?.[0];

  // Mutations
  const [createAiTutorMutation] = useCreateAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [editAiTutorMutation] = useEditAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateModuleMutation] = useUpdateModuleMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteModuleMutation] = useDeleteModuleCascadeMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteAiTutorMutation] = useDeleteAiTutorMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [createAxioResourceMutation] = useCreateAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateAxioResourceMutation] = useUpdateAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [deleteAxioResourceMutation] = useDeleteAxioResourceMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateCourseMutation] = useUpdateCourseMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [updateResourceOrderMutation] = useUpdateResourceOrderMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [createAssignmentMutation] = useCreateAssignmentMutation({
    refetchQueries: [{ query: GetCourseByIdDocument, variables: { id: courseId } }],
  });

  const [generateCourseContentMutation] = useGenerateCourseContentMutation({});

  useEffect(() => {
    if (data) {
      // Update selectedModule if it's stale
      if (selectedModule) {
        const updatedModule = data.modules.find((m) => m.id === selectedModule.id);
        if (updatedModule) {
          setSelectedModule(updatedModule);
        } else {
          // If the module was deleted, reset selectedModule
          setSelectedModule(null);
        }
      }

      // Update selectedActivity if it's stale
      if (selectedActivity) {
        let updatedActivity = null;
        data.modules.forEach((module) => {
          const items = [...(module?.aiTutors || []), ...(module?.pages || [])];
          const activity = items.find((item) => item.id === selectedActivity.id);
          if (activity) {
            updatedActivity = activity;
          }
        });
        if (updatedActivity) {
          setSelectedActivity(updatedActivity);
        } else {
          // If the activity was deleted, reset selectedActivity
          setSelectedActivity(null);
        }
      }
    }
  }, [data]);

  // Activity Handlers
  const handleSaveActivity = async (activityData) => {
    setIsSubmitting(true);
    try {
      const {
        title,
        description,
        objectives,
        files,
        videoUrl,
        type,
        targetAudience,
        id,
        contextText,
        enableVideoInsight,
        enableFileUpload,
        dueDate,
        videoPool = [],
      } = activityData;

      if (!title) {
        setActivityModalError("Title is required");
        throw new Error("Title is required");
      }

      if (!description) {
        setActivityModalError("Description is required");
        throw new Error("Description is required");
      }

      const cleanedDescription = description?.replace(/\n\s+/g, "").trim() || "";
      const encodedDescription = encodeURIComponent(cleanedDescription);

      // Process videoPool data for Interactive Learning Experience activities
      if (type === "Interactive Learning Experience" && videoPool.length > 0) {
        // Format video data to match the VideoDataInput type
        const formattedVideoPool = videoPool.map((video) => {
          // Find the objective by text to get its sequence
          const objective = objectives.find(
            (obj, index) =>
              obj === video.objectiveId || (typeof obj === "object" && obj.objective === video.objectiveId)
          );

          // Get the sequence either from the objective object or its index
          const sequence = typeof objective === "object" ? objective.sequence : objectives.indexOf(objective) + 1;

          return {
            videoId: video.videoId,
            objectiveText: video.objectiveId || video.objectiveText, // Support both formats
            objectiveSequence: sequence,
            isTeacherAdded: video.isTeacherAdded === true, // Explicitly check for true value
          };
        });

        console.log("=== CUSTOM COURSEWORK MODULES VIEW ===");
        console.log("Input videoPool:", JSON.stringify(videoPool, null, 2));
        console.log("Formatted videoPool:", JSON.stringify(formattedVideoPool, null, 2));
        console.log("=== END OF CUSTOM COURSEWORK MODULES VIEW ===");

        // Update the videoPool in activityData
        activityData.videoPool = formattedVideoPool;
      }

      if (type === "Assignment") {
        const preparedFiles =
          files?.map((file) => ({
            fileId: file.fileId || String(Date.now()),
            fileName: file.fileName,
            fileData: file.fileData,
            contentType: file.contentType,
            size: file.size,
          })) || [];

        const assignment = {
          name: title,
          description: encodedDescription,
          files: preparedFiles,
          videoUrl: videoUrl || "",
          enableStudentFileUpload: enableFileUpload,
          dueDate: dueDate,
          moduleId: currentModule.id,
        };

        const response = await createAssignmentMutation({
          variables: {
            assignment,
          },
        });

        if (response?.data?.createAssignment?.success) {
          dispatch({
            type: actions.SET_META,
            payload: { keypressIsListening: true },
          });
          setEditActivityModalVisible(false);
          setSelectedActivity(null);
          setActivityModalError(null);
        } else {
          setActivityModalError(response?.data?.createAssignment?.message || "Failed to create assignment");
          throw new Error(response?.data?.createAssignment?.message || "Failed to create assignment");
        }
      } else if (type === "Resource") {
        if (!currentModule?.id) {
          setActivityModalError("Module ID is required");
          throw new Error("Module ID is required");
        }

        // Prepare file data for upload
        const preparedFiles =
          files?.map((file) => ({
            fileId: file.fileId || String(Date.now()),
            fileName: file.fileName,
            fileData: file.fileData,
            contentType: file.contentType,
            size: file.size,
          })) || [];

        if (id) {
          //console.log("Updating resource with data:", {
          //  id,
          //  name: title,
          //  description: encodedDescription,
          // files: preparedFiles,
          //  videoUrl: videoUrl || "",
          //  order: editingActivity?.order || 0,
          // });

          const response = await updateAxioResourceMutation({
            variables: {
              id,
              resource: {
                name: title,
                description: encodedDescription,
                files: preparedFiles,
                videoUrl: videoUrl || "",
                order: editingActivity?.order || 0,
              },
            },
          });

          if (response?.data?.updateAxioResource?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setEditingActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.updateAxioResource?.message || "Failed to edit resource");
            throw new Error(response?.data?.updateAxioResource?.message || "Failed to edit resource");
          }
        } else {
          // console.log("Creating new resource with data:", {
          //  moduleId: currentModule.id,
          //   name: title,
          //   description: encodedDescription,
          //   files: preparedFiles,
          //   videoUrl: videoUrl || "",
          //   order: editingActivity?.order || 0,
          // });

          const response = await createAxioResourceMutation({
            variables: {
              moduleId: currentModule.id,
              order: editingActivity?.order || 0,
              name: title,
              description: encodedDescription,
              files: preparedFiles,
              videoUrl: videoUrl || "",
            },
          });

          if (response?.data?.createAxioResource?.success) {
            dispatch({
              type: actions.SET_META,
              payload: { keypressIsListening: true },
            });
            setEditActivityModalVisible(false);
            setSelectedActivity(null);
            setActivityModalError(null);
          } else {
            setActivityModalError(response?.data?.createAxioResource?.message || "Failed to create resource");
            throw new Error(response?.data?.createAxioResource?.message || "Failed to create resource");
          }
        }
      } else if (type === "Interactive Learning Experience") {
        if (!objectives || objectives.length === 0) {
          setActivityModalError("Objectives are required");
          throw new Error("Objectives are required");
        }

        // Objectives now come with evalCriteria already formatted
        const formattedObjectives = objectives;

        if (!currentModule?.id) {
          setActivityModalError("Parent module ID is required");
          throw new Error("Parent module ID is required");
        }

        // Add detailed logging to see the exact payload being sent
        console.log("==== AI TUTOR MUTATION PAYLOAD ====");
        console.log("title:", title);
        console.log("description:", encodedDescription);
        console.log("parentModuleId:", currentModule.id);
        console.log("objectives:", JSON.stringify(formattedObjectives, null, 2));
        console.log("context:", contextText || "");
        console.log("visualizationId:", activityData?.visualizationId || null);
        console.log("videoPool:", JSON.stringify(activityData?.videoPool || [], null, 2));
        console.log(
          "videoPool type:",
          Array.isArray(activityData?.videoPool) ? "Array" : typeof activityData?.videoPool
        );
        console.log("videoPool length:", activityData?.videoPool ? activityData.videoPool.length : 0);
        console.log("================================");

        const response = await createAiTutorMutation({
          variables: {
            title,
            description: encodedDescription,
            parentModuleId: currentModule.id,
            objectives: formattedObjectives,
            context: contextText || "",
            ...(!enableVideoInsight && { enableVideoInsight }),
            visualizationId: activityData?.visualizationId || null,
            videoPool: activityData?.videoPool || [],
            targetAudience: targetAudience || "",
          },
        });

        if (response?.data?.createAITutor?.success) {
          dispatch({
            type: actions.SET_META,
            payload: { keypressIsListening: true },
          });
          setEditActivityModalVisible(false);
          setSelectedActivity(null);
          setActivityModalError(null);
        } else {
          setActivityModalError(response?.data?.createAITutor?.message || "Failed to create AI Tutor");
          throw new Error(response?.data?.createAITutor?.message || "Failed to create AI Tutor");
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error saving activity:", error);
      Sentry.captureException(error);
      setIsSubmitting(false);
      throw error; // Re-throw to show error in UI
    }
  };

  const handleDeleteActivity = (activity) => {
    setResourceToDelete(activity);
    setDeleteResourceType("Activity");
    setDeleteResourceModalVisible(true);
  };

  const handleOpenAddActivity = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditActivityModalVisible(true);
  };

  // Module Handlers
  const handleDeleteModule = async (module) => {
    setResourceToDelete(module);
    setDeleteResourceType("Module");
    setDeleteResourceModalVisible(true);
  };

  const handleOpenAddModule = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalState({
      state: CustomCourseworkModulesViewModalState.ADD_MODULE,
      resourceType: "module",
      resourceId: null,
    });
  };

  const handleEditModule = (module) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditingModule({
      ...module,
      order: module.order ?? 0,
    });
    setModalState({
      state: CustomCourseworkModulesViewModalState.EDIT_MODULE_NAME,
      resourceType: "module",
      resourceId: module.id,
    });
  };

  // General Handlers
  const handleConfirmDelete = async () => {
    if (deleteResourceType === "Module") {
      try {
        const response = await deleteModuleMutation({
          variables: {
            id: resourceToDelete.id,
          },
        });

        if (response?.data?.deleteModuleCascade?.success) {
          setDeleteResourceModalVisible(false);
          setResourceToDelete(null);
          if (selectedModule?.id === resourceToDelete.id) {
            setSelectedModule(null);
          }
        } else {
          throw new Error(response?.data?.deleteModuleCascade?.message || "Failed to delete module");
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error deleting module:", error);
      }
    } else if (deleteResourceType === "Activity") {
      try {
        if (resourceToDelete.type === "Resource") {
          const response = await deleteAxioResourceMutation({
            variables: {
              id: resourceToDelete.id,
            },
          });

          if (response?.data?.deleteAxioResource?.success) {
            setDeleteResourceModalVisible(false);
            setResourceToDelete(null);
            if (selectedActivity?.id === resourceToDelete.id) {
              setSelectedActivity(null);
            }
          } else {
            throw new Error(response?.data?.deleteAxioResource?.message || "Failed to delete resource");
          }
        } else {
          const response = await deleteAiTutorMutation({
            variables: {
              id: resourceToDelete.id,
            },
          });

          if (response?.data?.deleteAITutor?.success) {
            setDeleteResourceModalVisible(false);
            setResourceToDelete(null);
            if (selectedActivity?.id === resourceToDelete.id) {
              setSelectedActivity(null);
            }
          } else {
            throw new Error(response?.data?.deleteAITutor?.message || "Failed to delete activity");
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error("Error deleting activity:", error);
      }
    }
  };

  const handleItemOptionsPress = (action, activity) => {
    // EDITING IS DISABLED FOR NOW.
    // console.log("handleItemOptionsPress:", action, activity);
    if (action === "edit") {
      // dispatch({
      //   type: actions.SET_META,
      //   payload: { keypressIsListening: false },
      // });
      // setEditingActivity(activity);
      // setEditActivityModalVisible(true);
    } else if (action === "delete") {
      handleDeleteActivity(activity);
    } else if (action === "clone") {
      handleCloneActivity(activity);
    }
  };

  const handleCloneActivity = (activity) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });

    // Determine the activity type correctly
    let activityType = activity.type;

    // For AI Tutors, ensure we're using the correct type name
    if (activity.__typename === "AITutor" || activityType === "AITutor") {
      activityType = "Interactive Learning Experience";
    } else if (activity.__typename === "AxioResource") {
      activityType = "Resource";
    }

    // Advanced debugging to find where evaluation criteria are stored
    console.log("********** INTENSIVE DEBUGGING OF ACTIVITY STRUCTURE **********");
    console.log("FULL ACTIVITY OBJECT KEYS:", Object.keys(activity));
    console.log("ACTIVITY TYPE:", activity.__typename);

    // Special check for formattedObjectives which might contain the evaluation criteria
    if (activity.formattedObjectives) {
      console.log("FORMATTED OBJECTIVES FOUND:", JSON.stringify(activity.formattedObjectives, null, 2));
    }

    // Check if there's a connection property that has evaluation data
    const connectionProps = Object.keys(activity).filter((key) => key.includes("Connection"));
    console.log("CONNECTION PROPERTIES:", connectionProps);
    connectionProps.forEach((prop) => {
      console.log(`EXAMINING CONNECTION: ${prop}`, JSON.stringify(activity[prop], null, 2));
    });

    console.log("OBJECTIVES STRUCTURE:", JSON.stringify(activity.objectives, null, 2));

    // For AITutors, also check these specific properties
    if (activity.__typename === "AITutor") {
      console.log("CHECKING AI TUTOR SPECIFIC PROPERTIES");
      const aiTutorProps = ["data", "formattedData", "objectiveData", "evalData", "criteriaData", "progressData"];
      aiTutorProps.forEach((prop) => {
        if (activity[prop]) {
          console.log(`FOUND PROPERTY ${prop}:`, JSON.stringify(activity[prop], null, 2));
        }
      });
    }

    // Log raw JSON to catch any potential nesting
    console.log("FULL ACTIVITY JSON:", JSON.stringify(activity, null, 2));

    // Dump absolutely ALL properties
    console.log("DUMPING ALL PROPERTIES AND NESTED OBJECTS FROM ACTIVITY:");
    function dumpObject(obj, path = "", level = 0) {
      if (!obj || typeof obj !== "object" || level > 5) return;

      for (const key in obj) {
        try {
          const value = obj[key];
          const currentPath = path ? `${path}.${key}` : key;

          console.log(`PROPERTY PATH: ${currentPath} => TYPE: ${typeof value}`);

          if (typeof value === "object" && value !== null) {
            if (Array.isArray(value)) {
              console.log(`  ARRAY LENGTH: ${value.length}`);
              if (value.length > 0) {
                console.log(`  FIRST ITEM TYPE: ${typeof value[0]}`);
                if (typeof value[0] === "object" && value[0] !== null) {
                  console.log(`  FIRST ITEM KEYS: ${Object.keys(value[0])}`);
                  // Look specifically for evaluation criteria
                  if (
                    value[0].evalCriteria ||
                    key === "evalCriteria" ||
                    currentPath.includes("evalCriteria") ||
                    currentPath.includes("objectives")
                  ) {
                    console.log(
                      `  !!! FOUND POTENTIAL EVAL CRITERIA at ${currentPath}:`,
                      JSON.stringify(value, null, 2).substring(0, 1000)
                    );
                  }
                }
              }
            } else {
              console.log(`  OBJECT KEYS: ${Object.keys(value)}`);
              // Look specifically for evaluation criteria
              if (
                key === "evalCriteria" ||
                currentPath.includes("evalCriteria") ||
                key === "objectives" ||
                currentPath.includes("objectives")
              ) {
                console.log(
                  `  !!! FOUND POTENTIAL EVAL CRITERIA or OBJECTIVES at ${currentPath}:`,
                  JSON.stringify(value, null, 2).substring(0, 1000)
                );
              }
            }

            dumpObject(value, currentPath, level + 1);
          } else if (
            typeof value === "string" &&
            (value.includes("criteria") || value.includes("objective") || value.includes("eval"))
          ) {
            console.log(`  STRING VALUE (SHORTENED): ${value.substring(0, 100)}`);
          }
        } catch (err) {
          console.log(`Error accessing property ${key}: ${err.message}`);
        }
      }
    }

    dumpObject(activity);

    // Process objectives and evaluation criteria
    let objectives = [];
    let evalCriteria = [];
    let criteriaFound = false;

    // Try multiple approaches to find the evaluation criteria

    // Check for formattedObjectives which are present when creating or editing activities
    // This is the primary source for evaluation criteria, coming from the UI state
    if (activity.formattedObjectives && Array.isArray(activity.formattedObjectives)) {
      criteriaFound = true;
      console.log("Found evaluation criteria in formattedObjectives");

      objectives = activity.formattedObjectives.map((obj) => obj.objective);

      evalCriteria = activity.formattedObjectives.map((obj, index) => {
        console.log(
          `Processing formattedObjective: ${obj.objective} with evalCriteria:`,
          JSON.stringify(obj.evalCriteria, null, 2)
        );
        return {
          id: `obj-${Math.random().toString(36).substring(2, 11)}`,
          objective: obj.objective,
          sequence: index + 1,
          evalCriteria: Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [],
          status: "notStarted",
        };
      });
    }

    // Also look for criteria in the newly generated case where they might be in activity.__generatedObjectives
    if (!criteriaFound && activity.__generatedObjectives && Array.isArray(activity.__generatedObjectives)) {
      criteriaFound = true;
      console.log("Found evaluation criteria in __generatedObjectives");

      objectives = activity.__generatedObjectives.map((obj) => obj.objective);

      evalCriteria = activity.__generatedObjectives.map((obj, index) => {
        return {
          id: `obj-${Math.random().toString(36).substring(2, 11)}`,
          objective: obj.objective,
          sequence: index + 1,
          evalCriteria: Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [],
          status: "notStarted",
        };
      });
    }

    // Check for raw objectives with embedded criteria
    if (!criteriaFound && activity.objectives && activity.objectives.length > 0) {
      // Extract simple objectives array for display
      objectives = activity.objectives.map((obj) => {
        return typeof obj === "string" ? obj : obj.objective;
      });

      // Check if we have direct evalCriteria on objectives
      const objWithCriteria = activity.objectives.find(
        (obj) => typeof obj === "object" && obj.evalCriteria && Array.isArray(obj.evalCriteria)
      );

      if (objWithCriteria) {
        criteriaFound = true;
        console.log("Found criteria directly in objectives:", objWithCriteria.evalCriteria);

        evalCriteria = activity.objectives.map((obj, index) => {
          const objective = typeof obj === "string" ? obj : obj.objective;
          const criteria = typeof obj === "object" && Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [];

          return {
            id: `obj-${Math.random().toString(36).substring(2, 11)}`,
            objective: objective,
            sequence: index + 1,
            evalCriteria: criteria,
            status: "notStarted",
          };
        });
      }
    }

    // If still no criteria, look deeper into the activity structure
    if (!criteriaFound) {
      // Look for them in any property that might contain them
      const deepSearch = (obj, path = "") => {
        if (!obj || typeof obj !== "object") return;

        // Check if this object has a structure that looks like it contains evaluation criteria
        if (Array.isArray(obj) && obj.length > 0 && obj[0] && obj[0].objective && Array.isArray(obj[0].evalCriteria)) {
          console.log(`FOUND CRITERIA at path ${path}:`, JSON.stringify(obj, null, 2));

          criteriaFound = true;
          objectives = obj.map((o) => o.objective);

          evalCriteria = obj.map((o, index) => {
            return {
              id: `obj-${Math.random().toString(36).substring(2, 11)}`,
              objective: o.objective,
              sequence: index + 1,
              evalCriteria: Array.isArray(o.evalCriteria) ? [...o.evalCriteria] : [],
              status: "notStarted",
            };
          });

          return;
        }

        // Continue searching recursively
        Object.keys(obj).forEach((key) => {
          deepSearch(obj[key], `${path}.${key}`);
        });
      };

      deepSearch(activity);
    }

    // If we still couldn't find criteria, create empty ones as a last resort
    if (!criteriaFound) {
      console.log("No evaluation criteria found, creating empty placeholders");

      evalCriteria = objectives.map((objective, index) => {
        return {
          id: `obj-${Math.random().toString(36).substring(2, 11)}`,
          objective: objective,
          sequence: index + 1,
          evalCriteria: [],
          status: "notStarted",
        };
      });
    }

    // Create a comprehensive clone of the activity
    const clonedActivity = {
      ...activity,
      title: `${activity.title} (Copy)`, // Add "(Copy)" to the title
      id: undefined, // Remove ID so a new activity will be created
      type: activityType, // Ensure the type is explicitly set

      // Include all necessary fields
      description: activity.description || "",
      objectives: objectives,
      files: activity.files || [],
      videoUrl: activity.videoUrl || "",
      dueDate: activity.dueDate || "",
      enableFileUpload: activity.enableFileUpload || false,
      contextText: activity.contextText || "",

      // Mark as a clone to help EditActivityModalContainer process it correctly
      _isClone: true,

      // Always include evalCriteria - preserve the structure from the original
      evalCriteria: evalCriteria,

      // Also include the formattedObjectives if they were found
      formattedObjectives: activity.formattedObjectives,

      // Include visualization data
      visualizationId: activity.visualization?.id || null,

      // Include videoPool with isTeacherAdded flag preserved
      videoPool: Array.isArray(activity.videoPool)
        ? activity.videoPool.map((video) => ({
            ...video,
            isTeacherAdded: video.isTeacherAdded || false,
          }))
        : [],

      // If this is a resource, ensure we handle name/title mapping
      name: activity.name || activity.title, // Some resources use name instead of title
    };

    console.log("CLONED ACTIVITY FINAL STATE:");
    console.log("- Type:", activityType);
    console.log("- Objectives:", objectives.length, "items");
    console.log("- Eval Criteria:", evalCriteria.length, "items");
    console.log(
      "- Criteria Content:",
      evalCriteria.map((ec) => ({
        objective: ec.objective,
        criteriaCount: ec.evalCriteria ? ec.evalCriteria.length : 0,
        hasCriteria: ec.evalCriteria && ec.evalCriteria.length > 0,
        firstCriteria:
          ec.evalCriteria && ec.evalCriteria.length > 0 ? ec.evalCriteria[0].substring(0, 30) + "..." : "EMPTY",
      }))
    );

    // Open the edit modal with the cloned data
    setEditingActivity(clonedActivity);
    setEditActivityModalVisible(true);
  };

  const handleItemToggle = (item) => {
    console.log("Toggle item:", item);
  };

  const handleSelectModule = (module) => {
    setSelectedModule(module);
    setSelectedActivity(null);
  };

  const handleGenerateCourse = async () => {
    setIsSubmitting(true);
    try {
      setLoadingGenerateCourse(true);
      const result = await generateCourseContentMutation({
        variables: {
          courseId: courseId,
        },
      });

      if (!result?.data?.generateCourseContent?.success) {
        throw new Error(result?.data?.generateCourseContent?.message || "Failed to generate course content");
      }
      setIsSubmitting(false);
    } catch (error) {
      setLoadingGenerateCourse(false);
      setIsSubmitting(false);
      Sentry.captureException(error);
      console.error("Error generating course content:", error);
    }
  };

  const handlePublishPress = async () => {
    setIsSubmitting(true);
    try {
      await updateCourseMutation({
        variables: {
          id: courseId,
          isDraft: false,
        },
      });
      setIsSubmitting(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error publishing course:", error);
      setIsSubmitting(false);
    }
  };

  const handleExitPress = () => {
    setLoadingGenerateCourse(false);
    navigation.navigate("Custom School Student");
  };

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  const handleDragEnd = async (data) => {
    const response = await updateResourceOrderMutation({
      variables: {
        input: {
          moduleId: currentModule.id,
          resourceOrder: data.map((item) => item.id),
        },
      },
    });
  };

  const handleToggleAssessment = async () => {
    try {
      setErrorUpdatingNotifications("");

      const response = await updateModuleMutation({
        variables: {
          id: currentModule.id,
          isAssessmentEnabled: !currentModule?.assessment?.isEnabled,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error toggling assessment:", error);
      setErrorUpdatingNotifications("Failed to update assessment");
    }
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      {/* Header */}
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <View style={styles.exitButtonContainer}>
            <CustomButton leftIcon={<ExitDoor />} onPress={handleExitPress} size="s" styleType="transparent" />
          </View>
          <CustomSpacing type="horizontal" size="m" />
          <CustomText text={data?.name} useTranslationText={false} weight="bold" size="m" />
          <CustomSpacing type="horizontal" size="m" />
          {isDraft ? (
            <RoundedLabel
              text="Draft"
              textStyle={{ fontSize: 14 }}
              style={styles.draftLabel}
              padding={[8, 6]}
              isRounded={true}
            />
          ) : (
            <RoundedLabel
              text="Published"
              style={styles.publisheLabel}
              textStyle={styles.publishedText}
              padding={[8, 6]}
              isRounded={true}
            />
          )}
        </View>
        <View style={styles.headerRight}>
          {data?.modules?.length == 0 && (
            <>
              <CustomButton
                size="s"
                text="Generate Course"
                styleType="primaryLight"
                textStyle={{ fontSize: 14, fontWeight: 700 }}
                onPress={handleGenerateCourse}
                disabled={isSubmitting}
                style={isSubmitting ? { backgroundColor: "#ccc" } : {}}
              />
              <CustomSpacing type="horizontal" size="m" />
            </>
          )}
          {isDraft && (
            <CustomButton
              size="s"
              text="Publish"
              styleType="primary"
              textStyle={{ fontSize: 14, fontWeight: 700 }}
              onPress={handlePublishPress}
              disabled={isSubmitting}
              style={isSubmitting ? { backgroundColor: "#ccc" } : {}}
            />
          )}
        </View>
      </View>

      {/* Main Content */}
      <DefaultLayout
        menuType="customCoursework"
        withPadding={false}
        onSelectModule={handleSelectModule}
        onSelectActivity={setSelectedActivity}
        selectedModule={selectedModule}
        selectedActivity={selectedActivity}
        onAddModule={handleOpenAddModule}>
        <View style={{ flex: 1 }}>
          {loading ? (
            <View style={styles.loaderContainer}>
              <ActivityIndicator size="large" color="#0000ff" />
            </View>
          ) : selectedActivity ? (
            <ActivityContainer
              activity={selectedActivity}
              onEdit={() => handleItemOptionsPress("edit", selectedActivity)}
              onDelete={() => handleItemOptionsPress("delete", selectedActivity)}
            />
          ) : currentModule ? (
            <ModuleContainer
              module={currentModule}
              selectedItemId={route.params?.itemId}
              onEditModule={handleEditModule}
              onDeleteModule={handleDeleteModule}
              onOpenAddActivity={handleOpenAddActivity}
              onItemOptionsPress={handleItemOptionsPress}
              onItemToggle={handleItemToggle}
              onDragEnd={handleDragEnd}
              onToggleAssessment={handleToggleAssessment}
              errorUpdatingAssessment={errorUpdatingNotifications}
            />
          ) : null}

          {/* Modals */}
          <DeleteResourceModalContainer
            visible={deleteResourceModalVisible}
            onClose={() => setDeleteResourceModalVisible(false)}
            onDeleteResource={handleConfirmDelete}
            type={deleteResourceType}
          />

          <EditActivityModalContainer
            visible={editActivityModalVisible}
            onClose={() => {
              setEditActivityModalVisible(false);
              setEditingActivity(null);
            }}
            onSave={handleSaveActivity}
            error={activityModalError}
            existingActivity={editingActivity}
            isSubmitting={isSubmitting}
          />

          <AddModuleModalContainer
            visible={
              modalState.state === CustomCourseworkModulesViewModalState.ADD_MODULE ||
              modalState.state === CustomCourseworkModulesViewModalState.EDIT_MODULE_NAME
            }
            setVisibility={setModalState}
            courseId={courseId}
            existingModule={editingModule}
          />

          <Modal
            animationType="fade"
            transparent={true}
            visible={loadingGenerateCourse}
            onRequestClose={() => setLoadingGenerateCourse(false)}>
            <View style={styles.modalOverlay}>
              <View style={styles.modalContent}>
                <ActivityIndicator size="large" color="#0000ff" />
                <CustomSpacing type="vertical" size="m" />
                <CustomText text="Generating Course..." size="m" weight="bold" />
                <CustomSpacing type="vertical" size="m" />
                <CustomText
                  text="This may take a few minutes. You can leave this page, and your course will be ready when you return."
                  size="m"
                  weight="bold"
                  style={{ textAlign: "center", maxWidth: 450 }}
                />
                <CustomSpacing type="vertical" size="m" />
                <CustomButton size="s" text="Back to Courses" styleType="primary" onPress={handleExitPress} />
              </View>
            </View>
          </Modal>
        </View>
      </DefaultLayout>
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
  },
  headerLeft: {
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  headerRight: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingRight: 20,
  },
  exitButtonContainer: {
    borderRightColor: "#D0D5DD",
    borderRightWidth: 1,
    paddingHorizontal: 10,
  },
  draftLabel: {
    backgroundColor: "#EAECF0",
    borderRadius: 360,
  },
  publisheLabel: {
    backgroundColor: "#66C61C",
    borderRadius: 360,
  },
  publishedText: {
    color: "#FFFFFF",
    fontSize: 14,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    maxWidth: 800,
    padding: 30,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CustomCourseworkModulesView;
