import { View, StyleSheet } from "react-native";
import { classCardColors, DEFAULT_CLASS_COLOR } from "../../../constants/classCardColors";
import ClassCardProgress from "../ClassCardProgress/ClassCardProgress";
import CustomText from "../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function ClassCardProgressList({
  courses = [],
  onPress,
  onHoverIn,
  onHoverOut,
  hasEdit,
  hasAnalytics,
  onEditPress,
  onAnalyticsPress,
  isNull,
}) {
  const { isMedium } = useResponsiveScreen();

  if (isNull) {
    return (
      <View style={styles.placeholderContainer}>
        <CustomText useTranslationText={false} style={styles.placeholderText} text={"No courses match this criteria"} />
      </View>
    );
  }

  return (
    <View role="grid" aria-label="Classes cards" style={[isMedium && styles.container]}>
      {courses.map((course, index) => {
        const color = classCardColors.find((color) => color.id === course.color)?.color ?? DEFAULT_CLASS_COLOR;

        return (
          <ClassCardProgress
            key={index}
            title={course?.name}
            isHovered={course?.isHovered}
            onPress={() => onPress(course?.id)}
            onHoverIn={() => onHoverIn(course?.id)}
            onHoverOut={() => onHoverOut(course?.id)}
            color={color}
            progress={course?.progress}
            hasEdit={hasEdit}
            isDraft={course?.isDraft}
            hasAnalytics={hasAnalytics}
            onEditPress={() => onEditPress(course?.id)}
            onAnalyticsPress={() => onAnalyticsPress(course?.id)}
            thumbnail={course?.thumbnail}
            generationStatus={course?.generationStatus}
            startDate={course?.startDate}
            disabled={course?.disabled}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
  },
  placeholderContainer: {
    flex: 1,
    margin: 40,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  placeholderText: {
    fontSize: 16,
    color: "#667085",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
  },
});

export default ClassCardProgressList;
