import React, { useState, useEffect, useMemo } from "react";
import { View, StyleSheet, TextInput, TouchableOpacity, ScrollView } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomModal from "../../common/general/CustomModal/CustomModal";

const SEARCH_TYPES = [
  { label: "All", value: "all" },
  { label: "Courses", value: "course" },
  { label: "Modules", value: "module" },
  { label: "Activities", value: "activity" },
];

const SearchSuggestion = ({ item, onSelect }) => (
  <TouchableOpacity onPress={() => onSelect(item)} style={styles.suggestionItem}>
    <CustomText text={`${item.type}: ${item.label}`} useTranslationText={false} size="s" />
  </TouchableOpacity>
);

const FilterDropdown = ({ label, options, value, onChange, disabled }) => (
  <View style={styles.filterDropdown}>
    <CustomText text={label} useTranslationText={false} size="s" style={styles.label} />
    <SelectDropdown
      data={options}
      defaultValue={value}
      onSelect={(selectedItem) => onChange(selectedItem.value)}
      buttonStyle={[styles.dropdown, disabled && styles.dropdownDisabled]}
      buttonTextStyle={styles.dropdownText}
      dropdownStyle={styles.dropdownList}
      rowStyle={styles.dropdownRow}
      rowTextStyle={styles.dropdownRowText}
      defaultButtonText="Select..."
      renderDropdownIcon={(isOpened) => <View style={[styles.dropdownIcon, isOpened && styles.dropdownIconUp]} />}
      dropdownIconPosition="right"
      disabled={disabled}
      renderCustomizedButtonChild={(selectedItem) => (
        <View style={styles.dropdownButton}>
          {selectedItem ? (
            <CustomText
              text={selectedItem.label}
              size="xs"
              style={styles.dropdownButtonText}
              useTranslationText={false}
            />
          ) : (
            <CustomText text="Select..." size="xs" style={styles.dropdownPlaceholder} useTranslationText={false} />
          )}
        </View>
      )}
      renderCustomizedRowChild={(item) => (
        <View style={styles.dropdownRow}>
          <CustomText text={item.label} size="xs" style={styles.dropdownRowText} useTranslationText={false} />
        </View>
      )}
    />
  </View>
);

const AnalyticsSearchBar = ({
  data,
  mergedData,
  onDrillDown,
  searchTypeDefault = "all",
  defaultSearchText = "",
  isLoading = false,
  error = null,
}) => {
  const [searchType, setSearchType] = useState(searchTypeDefault);
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    course: null,
    module: null,
    activity: null,
  });

  const searchableItems = useMemo(() => {
    const items = [];
    data.forEach((course) => {
      items.push({ type: "Course", label: course.label, data: course, level: "course" });
      course.details?.modules?.forEach((module) => {
        items.push({
          type: "Module",
          label: module.label,
          data: module,
          level: "module",
          parentCourse: course,
        });
        module.details?.activities?.forEach((activity) => {
          items.push({
            type: "Activity",
            label: activity.label,
            data: activity,
            level: "activity",
            parentModule: module,
            parentCourse: course,
          });
        });
      });
    });
    return items;
  }, [data]);

  useEffect(() => {
    if (!searchText) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }
    const filtered = searchableItems
      .filter((item) => {
        if (searchType !== "all" && item.type.toLowerCase() !== searchType) {
          return false;
        }
        return item.label.toLowerCase().includes(searchText.toLowerCase());
      })
      .slice(0, 5);

    console.log("Search results:", {
      searchText,
      searchType,
      resultsCount: filtered.length,
      results: filtered.map((f) => ({
        type: f.type,
        label: f.label,
      })),
    });
    setSuggestions(filtered);
    setShowSuggestions(true);
  }, [searchText, searchType, searchableItems]);

  // Updated handleSuggestionSelect uses mergedData from props
  const handleSuggestionSelect = (item) => {
    setShowSuggestions(false);
    setSearchText("");

    if (!mergedData) return;

    if (item.type === "Course") {
      const mergedItem = mergedData.find((course) => course.label === item.label);
      if (mergedItem) {
        onDrillDown({
          ...mergedItem,
          level: "course",
          details: { modules: mergedItem.details.modules },
        });
      }
    } else if (item.type === "Module") {
      const mergedCourse = mergedData.find((course) => course.label === item.parentCourse.label);
      if (mergedCourse && mergedCourse.details?.modules) {
        const mergedModule = mergedCourse.details.modules.find((module) => module.label === item.label);
        if (mergedModule) {
          // Drill down first to course then to module
          onDrillDown({
            ...mergedCourse,
            level: "course",
            details: { modules: mergedCourse.details.modules },
          });
          onDrillDown({
            ...mergedModule,
            level: "module",
            details: { activities: mergedModule.details.activities },
          });
        }
      }
    } else if (item.type === "Activity") {
      const mergedCourse = mergedData.find((course) => course.label === item.parentCourse.label);
      if (mergedCourse && mergedCourse.details?.modules) {
        const mergedModule = mergedCourse.details.modules.find((mod) => mod.label === item.parentModule.label);
        if (mergedModule && mergedModule.details?.activities) {
          const mergedActivity = mergedModule.details.activities.find((activity) => activity.label === item.label);
          if (mergedActivity) {
            // Drill down through course, then module, then activity
            onDrillDown({
              ...mergedCourse,
              level: "course",
              details: { modules: mergedCourse.details.modules },
            });
            onDrillDown({
              ...mergedModule,
              level: "module",
              details: { activities: mergedModule.details.activities },
            });
            onDrillDown({
              ...mergedActivity,
              level: "activity",
              details: { objectives: mergedActivity.details.objectives },
            });
          }
        }
      }
    }
  };

  const filterOptions = useMemo(() => {
    const options = {
      courses: data.map((course) => ({
        label: course.label,
        value: course,
      })),
      modules: selectedFilters.course
        ? selectedFilters.course.details?.modules?.map((module) => ({
            label: module.label,
            value: module,
          })) || []
        : [],
      activities: selectedFilters.module
        ? selectedFilters.module.details?.activities?.map((activity) => ({
            label: activity.label,
            value: activity,
          })) || []
        : [],
    };
    return options;
  }, [data, selectedFilters.course, selectedFilters.module]);

  const handleApplyFilter = () => {
    setShowFilterModal(false);
    if (selectedFilters.course) {
      onDrillDown({
        ...selectedFilters.course,
        level: "course",
        details: { modules: selectedFilters.course.details.modules },
      });
      if (selectedFilters.module) {
        onDrillDown({
          ...selectedFilters.module,
          level: "module",
          details: { activities: selectedFilters.module.details.activities },
        });
        if (selectedFilters.activity) {
          onDrillDown({
            ...selectedFilters.activity,
            level: "activity",
            details: { objectives: selectedFilters.activity.details.objectives },
          });
        }
      }
    }
  };

  const clearFilters = () => {
    setSelectedFilters({
      course: null,
      module: null,
      activity: null,
    });
  };

  return (
    <View style={styles.container}>
      <SelectDropdown
        data={SEARCH_TYPES}
        defaultValue={SEARCH_TYPES.find((t) => t.value === searchTypeDefault)}
        onSelect={(selectedItem) => setSearchType(selectedItem.value)}
        buttonStyle={styles.typeDropdown}
        buttonTextStyle={styles.dropdownText}
        dropdownStyle={styles.dropdownList}
        rowStyle={styles.dropdownRow}
        rowTextStyle={styles.dropdownRowText}
        defaultButtonText="All"
        buttonTextAfterSelection={(selectedItem) => selectedItem.label}
        rowTextForSelection={(item) => item.label}
        renderDropdownIcon={(isOpened) => <View style={[styles.dropdownIcon, isOpened && styles.dropdownIconUp]} />}
        dropdownIconPosition="right"
        renderCustomizedButtonChild={(selectedItem) => (
          <View style={styles.dropdownButton}>
            {selectedItem ? (
              <CustomText
                text={selectedItem.label}
                size="xs"
                style={styles.dropdownButtonText}
                useTranslationText={false}
              />
            ) : (
              <CustomText text="All" size="xs" style={styles.dropdownPlaceholder} useTranslationText={false} />
            )}
          </View>
        )}
        renderCustomizedRowChild={(item) => (
          <View style={styles.dropdownRow}>
            <CustomText text={item.label} size="xs" style={styles.dropdownRowText} useTranslationText={false} />
          </View>
        )}
      />

      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search courses, modules, activities..."
          value={searchText}
          onChangeText={setSearchText}
        />

        {showSuggestions && suggestions.length > 0 && (
          <ScrollView style={styles.suggestionsContainer}>
            {suggestions.map((item, index) => (
              <SearchSuggestion key={index} item={item} onSelect={handleSuggestionSelect} />
            ))}
          </ScrollView>
        )}
      </View>

      <CustomButton text="Filter" styleType="primary" size="s" onPress={() => setShowFilterModal(true)} />

      <CustomModal
        visible={showFilterModal}
        onRequestClose={() => setShowFilterModal(false)}
        title="Filter Analytics"
        showCloseButton>
        <View style={styles.modalContent}>
          <FilterDropdown
            label="Course"
            options={filterOptions.courses}
            value={selectedFilters.course?.label}
            onChange={(course) =>
              setSelectedFilters((prev) => ({
                ...prev,
                course,
                module: null,
                activity: null,
              }))
            }
          />

          <FilterDropdown
            label="Module"
            options={filterOptions.modules}
            value={selectedFilters.module?.label}
            onChange={(module) =>
              setSelectedFilters((prev) => ({
                ...prev,
                module,
                activity: null,
              }))
            }
            disabled={!selectedFilters.course}
          />

          <FilterDropdown
            label="Activity"
            options={filterOptions.activities}
            value={selectedFilters.activity?.label}
            onChange={(activity) =>
              setSelectedFilters((prev) => ({
                ...prev,
                activity,
              }))
            }
            disabled={!selectedFilters.module}
          />

          <View style={styles.modalButtons}>
            <CustomButton text="Clear" styleType="transparent" size="s" onPress={clearFilters} />
            <CustomButton text="Apply" styleType="primary" size="s" onPress={handleApplyFilter} />
          </View>
        </View>
      </CustomModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    backgroundColor: "#f3f4f6",
    borderRadius: 8,
    gap: 8,
    position: "relative",
    zIndex: 1000,
  },
  typeDropdown: {
    width: 120,
    height: 40,
    backgroundColor: "white",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    paddingHorizontal: 12,
  },
  dropdownDisabled: {
    backgroundColor: "#f3f4f6",
    borderColor: "#d1d5db",
  },
  dropdownText: {
    fontSize: 14,
    color: "#111827",
    textAlign: "left",
  },
  searchContainer: {
    flex: 1,
    position: "relative",
  },
  searchInput: {
    backgroundColor: "#fff",
    padding: 8,
    height: 40,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
  },
  suggestionsContainer: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    maxHeight: 200,
    borderRadius: 6,
    marginTop: 4,
    zIndex: 1001,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  suggestionItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#f3f4f6",
  },
  modalContent: {
    gap: 16,
    padding: 16,
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 8,
    marginTop: 16,
  },
  filterDropdown: {
    gap: 4,
  },
  label: {
    color: "#111827",
    marginBottom: 8,
    fontWeight: "500",
  },
  dropdown: {
    width: "100%",
    height: 60,
    backgroundColor: "white",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    paddingHorizontal: 12,
  },
  dropdownList: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    backgroundColor: "white",
  },
  dropdownRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    height: 40,
  },
  dropdownRowText: {
    fontSize: 14,
    color: "#111827",
  },
  dropdownIcon: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 5,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: "#6B7280",
  },
  dropdownIconUp: {
    transform: [{ rotate: "180deg" }],
  },
  dropdownButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  dropdownButtonText: {
    color: "#111827",
    fontSize: 14,
  },
  dropdownPlaceholder: {
    color: "#6B7280",
    fontSize: 14,
  },
});

export default AnalyticsSearchBar;
