import { useState, useEffect, useMemo } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import {
  useGetAllAiTutorsByCourseQuery,
  useGetEnrolledCoursesBySchoolQuery,
  useGetUserPermissionsQuery,
} from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import ClassCardProgressList from "../../../components/lms/ClassCardProgressList/ClassCardProgressList";
import { RolePermissions } from "../../../constants/rolesAndPermissions";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { shadowStyles } from "../../../styles/shadow";

function ClassCardProgressListContainer({ integrationId, activeTab, onEnrollCourses }) {
  const [currentCourses, setCurrentCourses] = useState([]);
  const navigation = useNavigation();
  const { data: userPermissionsData } = useGetUserPermissionsQuery();

  const rolePermissions = userPermissionsData?.getUserPermissions ?? [];

  const {
    data: courses,
    loading: coursesLoading,
    refetch: refetchCourses,
  } = useGetEnrolledCoursesBySchoolQuery({
    variables: {
      schoolId: integrationId,
    },
    skip: !integrationId,
  });

  useEffect(() => {
    if (courses) {
      setCurrentCourses(courses.getEnrolledCoursesBySchool.data);
    }
  }, [courses]);

  const { data: aiTutorsData, refetch: aiTutorsRefetch } = useGetAllAiTutorsByCourseQuery({
    variables: {
      courseIds: currentCourses?.map((course) => course.id),
    },
    skip: !currentCourses || currentCourses.length === 0,
  });

  const filteredCourses = useMemo(() => {
    if (activeTab === "all" || activeTab === "core") {
      return currentCourses;
    } else {
      return [];
      // return courses.filter((course) => course.type.toLowerCase() === activeTab);
    }
  }, [currentCourses, activeTab]);

  function calculateProgress(aiTutors) {
    if (!aiTutors || aiTutors.length === 0) return 0;

    let totalObjectives = 0;
    let completedObjectives = 0;

    aiTutors.forEach((aiTutor) => {
      const objectives = aiTutor.objectives;
      if (objectives && objectives.length > 0) {
        totalObjectives += objectives.length;
        completedObjectives += objectives.filter((objective) => objective.status === "completed").length;
      } else {
        totalObjectives += 4;
      }
    });

    return totalObjectives > 0 ? Math.round((completedObjectives / totalObjectives) * 100) : 0;
  }

  function formatCoursesData(courses, aiTutors) {
    return (courses ?? []).map((course) => {
      const filteredAiTutors = aiTutors?.filter((aiTutor) => aiTutor.courseId === course.id);
      const progress = calculateProgress(filteredAiTutors);
      const disabled = rolePermissions.includes(RolePermissions.editCourse)
        ? false
        : course.startDate && new Date(course.startDate) > new Date();
      return {
        ...course,
        progress,
        disabled,
      };
    });
  }

  useEffect(() => {
    if (!!courses && courses.getEnrolledCoursesBySchool.data?.length) {
      aiTutorsRefetch();
    }
  }, [courses, aiTutorsRefetch]);

  // Polling until all courses are generated or failed
  useEffect(() => {
    let pollingInterval;

    const checkGenerationStatus = () => {
      const hasGeneratingCourses = currentCourses.some(
        (course) => course.generationStatus !== "completed" && course.generationStatus !== "failed"
      );

      if (hasGeneratingCourses) {
        refetchCourses();
      } else if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };

    if (currentCourses.length > 0) {
      // Poll every 10 seconds if there are courses being generated
      pollingInterval = setInterval(checkGenerationStatus, 10000);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [currentCourses, refetchCourses]);

  const handlePress = (id) => {
    const course = currentCourses.filter((course) => course.id === id)[0];
    navigation.replace("Custom Course Student", {
      course_id: course.id,
      integration_id: integrationId,
    });
  };

  const handleOnHoverIn = (id) => {
    setCurrentCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: true,
          };
        }

        return course;
      })
    );
  };

  const handleOnHoverOut = (id) => {
    setCurrentCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: false,
          };
        }

        return course;
      })
    );
  };

  const handleEditPress = (id) => {
    navigation.navigate("Custom Coursework Modules", {
      courseId: id,
    });
  };

  const handleAnalyticsPress = () => {
    navigation.navigate("Analytics");
  };

  // Custom placeholder for no courses when enrollment is possible
  const NoCoursesPlaceholder = () => (
    <View style={[styles.container, shadowStyles.primary]}>
      <View style={styles.content}>
        <View style={styles.textContainer}>
          <CustomText
            text="No Courses Enrolled"
            size="xs"
            textType="display"
            style={styles.title}
            useTranslationText={false}
          />
          <CustomText
            text="You are not currently enrolled in any courses. Enroll in courses to start your learning journey."
            size="xs"
            style={styles.subtitle}
            useTranslationText={false}
          />
          <View style={styles.buttonContainer}>
            <CustomButton
              text="Enroll In Courses"
              styleType="primary"
              size="m"
              bold={true}
              onPress={onEnrollCourses}
              useTranslationText={false}
            />
          </View>
        </View>
      </View>
    </View>
  );

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {coursesLoading ? (
        <View style={{ gap: 20, marginLeft: 20, marginRight: 20, marginBottom: 20, justifyContent: "center" }}>
          {[...Array(3)].map((_, index) => (
            <Skeleton height={210} margin={10} borderRadius={30} key={index} />
          ))}
        </View>
      ) : (
        <>
          {filteredCourses && filteredCourses.length > 0 ? (
            aiTutorsData && (
              <ClassCardProgressList
                courses={formatCoursesData(filteredCourses, aiTutorsData?.getAllAITutorsByCourse.data)}
                onPress={handlePress}
                onHoverIn={handleOnHoverIn}
                onHoverOut={handleOnHoverOut}
                isLoading={coursesLoading}
                hasEdit={rolePermissions.includes(RolePermissions.editCourse)}
                hasAnalytics={rolePermissions.includes(RolePermissions.viewSchoolAnalytics)}
                onEditPress={handleEditPress}
                onAnalyticsPress={handleAnalyticsPress}
                isNull={false}
              />
            )
          ) : (
            <NoCoursesPlaceholder />
          )}
        </>
      )}
    </MotiView>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    marginTop: 40,
    marginBottom: 20,
    marginHorizontal: 10,
    padding: 36,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    textAlign: "center",
    marginBottom: 8,
  },
  subtitle: {
    textAlign: "center",
    maxWidth: 280,
    marginBottom: 24,
    color: "#667085",
  },
  buttonContainer: {
    marginTop: 16,
  },
});

export default ClassCardProgressListContainer;
