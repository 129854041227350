import { ListItem } from "@rneui/themed";
import { MotiView } from "moti";
import { useState } from "react";
import { View } from "react-native";

const CustomAccordion = ({ title, children, isExpanded = true }) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <View>
      <ListItem.Accordion
        content={title}
        isExpanded={expanded}
        onPress={toggleExpanded}
        role="button"
        aria-checked={expanded}
        aria-label={`Accordion, ${expanded ? "expanded" : "collapsed"}`}
        accessibilityHint="Double tap to toggle the accordion.">
        <MotiView
          from={{ opacity: 0, translateY: -20 }}
          animate={{ opacity: 1, translateY: 0 }}
          // @ts-expect-error - MotiView transition props have complex typing that's incompatible with simple objects
          transition={{ type: "timing", duration: 100 }}>
          {children}
        </MotiView>
      </ListItem.Accordion>
    </View>
  );
};

export default CustomAccordion;
