import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import Document from "../../svgs/CustomCoursework/Document.js";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { ExitCircle } from "../../svgs/common";
import { ActivityIndicator } from "react-native-paper";

function Attachment({ title, fileType, isLoading = false, showDeleteButton = false, onDeletePress }) {
  if (isLoading) showDeleteButton = false;

  return (
    <View style={styles.card}>
      {isLoading && <ActivityIndicator size={14} color="#344054" style={styles.activityIndicator} />}
      {showDeleteButton && (
        <CustomButton
          text=""
          onPress={onDeletePress}
          style={styles.deleteButton}
          leftIcon={<ExitCircle fill="#000" width="14" height="14" />}
          rightIcon={null}
          disabled={false}
          textProps={{}}
          textStyle={{}}
        />
      )}
      <View style={styles.iconBackground}>
        <Document fill="#fff" width="24" height="24" />
      </View>
      <View style={styles.textContainer}>
        <CustomText
          useTranslationText={false}
          text={title}
          textType="text"
          weight="medium"
          size="xs"
          numberOfLines={1}
          style={{}}
          ellipsizeMode="tail"
        />
        <CustomText
          useTranslationText={false}
          text={fileType}
          textType="text"
          weight="medium"
          size="xs"
          style={styles.fileType}
          numberOfLines={1}
          ellipsizeMode="tail"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    borderColor: "#EAECF0",
    borderWidth: 1,
    borderRadius: 16,
    backgroundColor: "#fff",
    flexDirection: "row",
    padding: 4,
    width: 226,
  },
  iconBackground: {
    backgroundColor: "#AF3D7C",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    borderRadius: 11,
  },
  textContainer: {
    paddingHorizontal: 8,
  },
  fileType: {
    color: "#98A2B3",
  },
  deleteButton: {
    position: "absolute",
    top: -2,
    right: -2,
    backgroundColor: "#000",
    width: 14,
    height: 14,
    borderRadius: 7,
    padding: 0,
  },
  activityIndicator: {
    position: "absolute",
    top: -4,
    right: -4,
    backgroundColor: "#e1e6ed",
    borderRadius: 9,
    padding: 2,
  },
});

export default Attachment;
