import React from "react";
import { Text, View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { useAppConfig } from "../../../../AppConfigProvider";

const RoundedLabel = ({
  text = "",
  styleType = "light",
  size = "m",
  leftIcon = undefined,
  rightIcon = undefined,
  style = {},
  textStyle = {},
  disabled = false,
  weight = "bold",
  isRounded = false,
  padding,
}) => {
  const { hp } = useResponsiveScreen();
  const { primaryColor } = useAppConfig();

  const getButtonStyle = buttonColors[styleType] || (() => ({}));
  const getButtonSize = buttonSizes(hp)[size] || buttonSizes(hp).m;
  const getButtonSpacing = buttonSpacing(leftIcon || rightIcon);
  const getTextStyle = textColors[styleType] || (() => ({}));
  const getTextSize = textSizes(hp)[size] || textSizes(hp).m;

  const combinedButtonStyle = [
    styles.button,
    getButtonStyle(primaryColor),
    getButtonSize,
    isRounded && { borderRadius: 360 },
    padding && { paddingHorizontal: padding[0], paddingVertical: padding[1] },
    getButtonSpacing,
    style,
  ];

  const combinedTextStyle = [styles.text, styles.weight[weight], getTextStyle(primaryColor), getTextSize, textStyle];

  return (
    <View style={combinedButtonStyle} disabled={disabled}>
      {leftIcon && <View style={styles.icon}>{leftIcon}</View>}
      <Text style={combinedTextStyle}>{text}</Text>
      {rightIcon && <View style={styles.icon}>{rightIcon}</View>}
    </View>
  );
};

export default RoundedLabel;

const styles = StyleSheet.create({
  /* eslint-disable react-native/no-unused-styles */
  button: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
  },
  text: {
    fontSize: 14,
  },
  weight: {
    light: { fontWeight: "300" },
    medium: { fontWeight: "500" },
    bold: { fontWeight: "600" },
    ultraBold: { fontWeight: "700" },
  },
});

const buttonColors = {
  light: () => ({ backgroundColor: "#fff", borderColor: "#fff" }),
  dark: () => ({ backgroundColor: "#000", borderColor: "#000" }),
  highlight: () => ({
    backgroundColor: "#E6EBFF",
    borderColor: "#E6EBFF",
    paddingVertical: 5,
    paddingHorizontal: 8,
    borderRadius: 50,
    fontSize: 12,
    fontWeight: "500",
  }),
  transparent: () => ({
    borderColor: "#3C3C3C",
  }),
  primary: (primaryColor) => ({
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  }),
  primaryLight: () => ({ backgroundColor: "#fff", borderColor: "#fff" }),
  primaryTransparent: (primaryColor) => ({
    borderColor: primaryColor,
  }),
};

const buttonSizes = (hp) => {
  return {
    xs: { padding: hp(0.5), borderRadius: hp(0.4) },
    s: { padding: hp(1), borderRadius: hp(0.75) },
    m: { padding: hp(1.4), borderRadius: hp(1) },
    l: { padding: hp(2), borderRadius: hp(1.15) },
    xl: { padding: hp(2.4), borderRadius: hp(1.5) },
    xxl: { padding: hp(2.8), borderRadius: hp(1.75) },
  };
};

const buttonSpacing = (icon) => ({
  justifyContent: icon ? "space-between" : "center",
});

const textColors = {
  light: () => ({ color: "#000" }),
  dark: () => ({ color: "#fff" }),
  highlight: (primaryColor) => ({ color: primaryColor }),
  transparent: () => ({ color: "#3C3C3C" }),
  primary: () => ({ color: "#fff" }),
  primaryLight: (primaryColor) => ({ color: primaryColor }),
  primaryTransparent: (primaryColor) => ({ color: primaryColor }),
};

const textSizes = (hp) => {
  return {
    xs: { fontSize: hp(1) },
    s: { fontSize: hp(1.15) },
    m: { fontSize: hp(1.25) },
    l: { fontSize: hp(1.5) },
    xl: { fontSize: hp(1.75) },
    xxl: { fontSize: hp(2.0) },
  };
};
