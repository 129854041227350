import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import StudentBubbleChartContainer from "../containers/analytics/StudentBubbleChartContainer/StudentBubbleChartContainer";
import BubbleChartContainer from "../containers/analytics/BubbleChartContainer/BubbleChartContainer";
import {
  useGetStudentsSummaryBySchoolQuery,
  useGetStudentDetailedAnalyticsQuery,
  useGetStudentLearningInsightsQuery,
  useGetStudentChatAnalyticsQuery,
  useSchoolsQuery,
} from "../graphql/generated/graphql";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import StudentAnalyticsSearchBar from "../components/analytics/StudentAnalyticsSearchBar/StudentAnalyticsSearchBar";
import StudentInsights from "../components/analytics/StudentInsights/StudentInsights";

const detailKeyMapping = {
  student: "courses",
  course: "modules",
  module: "activities",
  activity: "objectives",
};

const nextLevelMapping = {
  student: "course",
  course: "module",
  module: "activity",
  activity: "objective",
};

function computeRisk(item) {
  const c = item.confusion_points || 0;
  const d = item.distractions || 0;
  let risk = c + d;
  if (item.sentiment === "negative") risk += 2;
  return risk;
}

function computePerformance(item) {
  const s = item.success_points || 0;
  const a = item.active_interaction_count || 0;
  const q = item.questions_asked || 0;
  return s + a + 0.5 * q;
}

function mapDataWithConditionalAxes(data, level) {
  if (!data || data.length === 0) {
    console.warn(`No data available for mapping at level: ${level}`);
    return [];
  }
  if (level === "student") {
    // Map students – include a level property to use later in handleDrillDown
    return data.map((item, idx) => {
      const risk = computeRisk(item);
      const performance = computePerformance(item);
      const enrollment = item.enrollment || 1;
      return {
        x: risk,
        y: performance,
        size: Math.max(enrollment * 10, 10),
        color:
          item.sentiment === "positive"
            ? "#4ECDC4"
            : item.sentiment === "mixed"
              ? "#FFDD57"
              : item.sentiment === "negative"
                ? "#FF6B6B"
                : "#0084FF",
        label: item.label || `Student ${idx + 1}`,
        details: item.details || null,
        level: "student",
        id: item.id, // include id for later use
      };
    });
  } else {
    // For non-student levels, use other axes
    const successMinusConfusion = data.map((d) => (d.success_points || 0) - (d.confusion_points || 0));
    const engagementMinusDistraction = data.map(
      (d) => (d.questions_asked || 0) + (d.active_interaction_count || 0) - (d.distractions || 0)
    );
    const minX = Math.min(...successMinusConfusion);
    const maxX = Math.max(...successMinusConfusion);
    const rangeX = maxX - minX || 1;
    const minY = Math.min(...engagementMinusDistraction);
    const maxY = Math.max(...engagementMinusDistraction);
    const rangeY = maxY - minY || 1;
    const maxEnrollment = Math.max(...data.map((d) => d.enrollment || 1), 1);
    const scaleFactor = 10;
    return data.map((item, index) => {
      const sentiment = item.sentiment || "none";
      return {
        x:
          (((item.success_points || 0) - (item.confusion_points || 0) - minX) / rangeX) * 2 * scaleFactor - scaleFactor,
        y:
          (((item.questions_asked || 0) + (item.active_interaction_count || 0) - (item.distractions || 0) - minY) /
            rangeY) *
            2 *
            scaleFactor -
          scaleFactor,
        size: Math.max(((item.enrollment || 1) / maxEnrollment) * 30, 10),
        color:
          sentiment === "positive"
            ? "#4ECDC4"
            : sentiment === "mixed"
              ? "#FFDD57"
              : sentiment === "negative"
                ? "#FF6B6B"
                : "#0084FF",
        label: item.label || `Item ${index + 1}`,
        details: item.details || null,
        level,
        id: item.id,
      };
    });
  }
}

const StudentAnalyticsView = () => {
  const { data: schoolsData, loading: schoolDataLoading } = useSchoolsQuery();
  const schoolId = schoolsData?.getSchools[0]?.id;

  const { data: studentData, loading: studentDataLoading } = useGetStudentsSummaryBySchoolQuery({
    variables: { schoolId },
    skip: !schoolId,
  });

  // New state to hold the student selected for insights
  const [selectedStudent, setSelectedStudent] = useState(null);

  // States for chart view when not in insights mode
  const [currentLevel, setCurrentLevel] = useState("student");
  const [currentData, setCurrentData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([{ label: "Students", level: "student", data: [] }]);

  // Query for individual student analytics – only runs when a student is selected
  const { data: studentDetailedData, loading: studentDetailedLoading } = useGetStudentDetailedAnalyticsQuery({
    variables: { schoolId, studentId: selectedStudent?.id },
    skip: !selectedStudent || !schoolId,
  });

  // Get student learning insights - provides success_points_details, confusion_points_details, etc.
  const { data: studentInsightsData, loading: studentInsightsLoading } = useGetStudentLearningInsightsQuery({
    variables: { schoolId, studentId: selectedStudent?.id },
    skip: !selectedStudent || !schoolId,
  });

  // Get student chat analytics - provides all chat sessions information
  const { data: studentChatData, loading: studentChatLoading } = useGetStudentChatAnalyticsQuery({
    variables: { schoolId, studentId: selectedStudent?.id },
    skip: !selectedStudent || !schoolId,
  });

  useEffect(() => {
    if (studentData?.getStudentsSummaryBySchool?.data) {
      const mappedData = mapDataWithConditionalAxes(studentData.getStudentsSummaryBySchool.data, "student");
      setCurrentData(mappedData);
      setBreadcrumb([
        {
          label: "Students",
          level: "student",
          data: studentData.getStudentsSummaryBySchool.data,
        },
      ]);
    }
  }, [studentData]);

  const handleDrillDown = (clickedItem) => {
    // If we are at the student level, show insights for the clicked student
    if (currentLevel === "student") {
      setSelectedStudent(clickedItem);
      setBreadcrumb([
        {
          label: "Students",
          level: "student",
          data: studentData?.getStudentsSummaryBySchool?.data || [],
        },
        {
          label: clickedItem.label,
          level: "insights", // indicate that we are now viewing insights
          data: [clickedItem],
        },
      ]);
      return;
    }

    // For drilling down into further levels (if needed)
    const detailKey = detailKeyMapping[clickedItem.level];
    const nextLevel = nextLevelMapping[clickedItem.level];
    if (!detailKey || !nextLevel) {
      console.warn("No further levels to drill down");
      return;
    }
    const nextData = clickedItem.details?.[detailKey];
    if (!nextData || nextData.length === 0) {
      console.warn(`No data for ${detailKey}`, clickedItem.details);
      return;
    }
    setBreadcrumb((prev) => [...prev, { label: clickedItem.label, level: nextLevel, data: nextData }]);
    setCurrentLevel(nextLevel);
    setCurrentData(mapDataWithConditionalAxes(nextData, nextLevel));
  };

  // If a student is selected, render the StudentInsights view
  if (selectedStudent) {
    // Prepare combined data for StudentInsights
    const insightsData = {
      getStudentDetailedAnalytics: studentDetailedData?.getStudentDetailedAnalytics,
      getStudentLearningInsights: studentInsightsData?.getStudentLearningInsights,
      getStudentChatAnalytics: studentChatData?.getStudentChatAnalytics,
    };

    const isLoading = studentDetailedLoading || studentInsightsLoading || studentChatLoading;

    return (
      <NoChatBarLayout>
        <CustomText size="m" textType="display" text="Student Analytics" weight="ultraBold" />
        <CustomSpacing type="vertical" size="m" />
        <View style={styles.breadcrumb}>
          <CustomText
            useTranslationText={false}
            size="s"
            text={`Showing: ${selectedStudent.label} Insights`}
            style={styles.breadcrumbText}
          />
          <Text style={styles.backLink} onPress={() => setSelectedStudent(null)}>
            Back
          </Text>
        </View>
        {isLoading ? <Text>Loading Insights...</Text> : <StudentInsights data={insightsData} />}
      </NoChatBarLayout>
    );
  }

  // Otherwise, render the chart and accordion view for all students
  const ChartComponent = currentLevel === "student" ? StudentBubbleChartContainer : BubbleChartContainer;

  if (studentDataLoading || schoolDataLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <NoChatBarLayout>
      <CustomText size="m" textType="display" text="Student Analytics" weight="ultraBold" />
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.searchContainer}>
        <StudentAnalyticsSearchBar
          data={studentData?.getStudentsSummaryBySchool?.data || []}
          onDrillDown={handleDrillDown}
          isLoading={studentDataLoading}
        />
      </View>
      <View style={styles.breadcrumb}>
        <CustomText
          useTranslationText={false}
          size="s"
          text={
            breadcrumb.length === 1
              ? "Showing: All Students"
              : `Showing: Students ${breadcrumb
                  .slice(1)
                  .map((crumb) => `> ${crumb.label}`)
                  .join(" ")}`
          }
          style={styles.breadcrumbText}
        />
      </View>
      <CustomText
        size="xl"
        text="Breakdown & Stats"
        weight="bold"
        useTranslationText={false}
        style={{ paddingBottom: 20 }}
      />
      <ChartComponent data={currentData} onDrillDown={handleDrillDown} containerWidth={800} />
    </NoChatBarLayout>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    position: "relative",
    zIndex: 1000,
    marginBottom: 16,
  },
  breadcrumb: {
    flexDirection: "row",
    marginBottom: 10,
    position: "relative",
    zIndex: 1,
    alignItems: "center",
  },
  breadcrumbText: {
    color: "#6B7280",
    fontWeight: "500",
  },
  backLink: {
    marginLeft: 16,
    color: "#0084FF",
    textDecorationLine: "underline",
  },
});

export default StudentAnalyticsView;
