export const sortResourceItemsOfModules = (items, resourceOrder) => {
  if (!resourceOrder) {
    return items.sort((a, b) => {
      const dateA = new Date(a.lastModified);
      const dateB = new Date(b.lastModified);
      return dateB.getTime() - dateA.getTime();
    });
  }

  return items.sort((a, b) => {
    const indexA = resourceOrder.indexOf(a.id);
    const indexB = resourceOrder.indexOf(b.id);
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
};
