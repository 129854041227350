import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { z } from "zod";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { UpdateDegreeMutation } from "../../../graphql/generated/graphql";

const modals = {
  updateConfirmation: {
    title: "Success!",
    ariaLabel: "Degree Updated Successfully Modal",
    warningText: "The degree has been updated.",
    actionButtonText: "OK",
  },
  updateError: {
    title: "Error!",
    ariaLabel: "Degree Update Error Modal",
    warningText: "An error occurred while updating the degree. You may not have permission to update a degree.",
    actionButtonText: "CLOSE",
  },
};

export enum UpdateDegreeModalState {
  CLOSED = "CLOSED",
  UPDATE_CONFIRMATION = "UPDATE_CONFIRMATION",
  UPDATE_ERROR = "UPDATE_ERROR",
}

const degreeSchema = z.object({
  degreeName: z.string().min(1, "Degree name is required"),
});

type DegreeFormData = z.infer<typeof degreeSchema>;

interface UpdateDegreeModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (formData: DegreeFormData) => Promise<UpdateDegreeMutation>;
  currentDegreeName: string;
}

const UpdateDegreeModal: React.FC<UpdateDegreeModalProps> = ({ visible, onClose, onSubmit, currentDegreeName }) => {
  const { isMedium } = useResponsiveScreen();
  const [modalState, setModalState] = useState({
    state: UpdateDegreeModalState.CLOSED,
  });

  const [degreeName, setDegreeName] = useState(currentDegreeName);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<DegreeFormData>({
    resolver: zodResolver(degreeSchema),
    defaultValues: {
      degreeName: degreeName,
    },
  });

  useEffect(() => {
    if (currentDegreeName) {
      setDegreeName(currentDegreeName);
      reset({ degreeName: currentDegreeName });
    }
  }, [currentDegreeName]);

  const handleFormSubmit = React.useCallback(
    async (formData: DegreeFormData) => {
      try {
        const response = await onSubmit(formData);
        const result = response?.updateDegree;

        if (!result) {
          setModalState({ state: UpdateDegreeModalState.UPDATE_ERROR });
          return;
        }

        reset();
        onClose();
        if (result.success) {
          setModalState({ state: UpdateDegreeModalState.UPDATE_CONFIRMATION });
        } else {
          setModalState({ state: UpdateDegreeModalState.UPDATE_ERROR });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setModalState({ state: UpdateDegreeModalState.UPDATE_ERROR });
      }
    },
    [onSubmit, onClose]
  );

  return (
    <>
      <CustomModal
        visible={visible}
        onRequestClose={onClose}
        title="Update a Degree"
        exitFill="#6B7280"
        ariaLabel="Update degree modal"
        style={{ width: isMedium ? "50%" : "90%" }}
        onShow={() => {}}
        showCloseButton={true}>
        <View style={styles.container}>
          <View style={styles.formGroup}>
            <CustomText text="Degree Name" size="s" textType="text" style={{ color: "#182230" }} weight="ultraBold" />
            <CustomSpacing size="s" type="vertical" />
            <Controller
              control={control}
              name="degreeName"
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="Understanding Design Change Types"
                  style={styles.input}
                />
              )}
            />
            {errors?.degreeName?.message && (
              <CustomText size="xs" style={{ color: "#EF4444" }} text={errors.degreeName?.message} />
            )}
          </View>

          <View style={styles.buttonContainer}>
            <CustomButton
              text="Cancel"
              onPress={onClose}
              styleType="secondary"
              size="m"
              style={styles.cancelButton}
              textStyle={styles.cancelButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
            <CustomButton
              text="Update Degree"
              onPress={handleSubmit(handleFormSubmit)}
              styleType="primary"
              size="m"
              style={styles.updateButton}
              textStyle={styles.updatEButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
          </View>
        </View>
      </CustomModal>
      <NewCustomAxioActionModal
        visible={modalState.state === UpdateDegreeModalState.UPDATE_CONFIRMATION}
        onClose={() => setModalState({ state: UpdateDegreeModalState.CLOSED })}
        modalTexts={modals.updateConfirmation}
      />
      <NewCustomAxioActionModal
        visible={modalState.state === UpdateDegreeModalState.UPDATE_ERROR}
        onClose={() => setModalState({ state: UpdateDegreeModalState.CLOSED })}
        modalTexts={modals.updateError}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  formGroup: {
    marginBottom: 24,
  },
  input: {
    width: "100%",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    marginTop: 8,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  updateButton: {
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  updatEButtonText: {
    color: "#FFFFFF",
  },
});

export default UpdateDegreeModal;
