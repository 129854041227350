import React, { useState, useEffect } from "react";
import { View, StyleSheet, Modal } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomModal from "../../components/common/general/CustomModal/CustomModal";

const AssessmentInProgressModal = ({ visible, onConfirm }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      role="dialog"
      aria-modal="true"
      aria-label="Cursor Leaves Window Modal">
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <CustomText
            aria-level="1"
            text="Stay Focused on Your Assessment"
            size="xxl"
            weight="ultraBold"
            style={{ color: "#101828", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="l" />
          <CustomText
            text="Another assessment is already running. Please complete the current assessment before starting a new one."
            size="l"
            weight="normal"
            style={{ color: "#344054", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="l" />
          <CustomSpacing type="vertical" size="xxl" />
          <View style={styles.buttonBox}>
            <CustomButton
              aria-label="exit button"
              text="Got it"
              onPress={onConfirm}
              styleType="primary"
              style={styles.exitButton}
              textStyle={{ fontWeight: "700", fontSize: 18, fontFamily: "Inter" }}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    borderRadius: 20,
    padding: 40,
    height: "auto",
    maxWidth: 688,
    width: "auto",
    backgroundColor: "white",
  },
  exitButton: {
    backgroundColor: "#3E68FE",
    padding: 16,
    borderRadius: 8,
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 16,
  },
});

export default AssessmentInProgressModal;
