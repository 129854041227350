import React from "react";
import { Pressable, View } from "react-native";
import Placeholder from "../../general/Placeholder/Placeholder";
import AiTutorCardProgress from "../AiTutorCardProgress/AiTutorCardProgress";
import AxioResourceCard from "../../customCoursework/AxioResourceCard/AxioResourceCard";
import { MotiView } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";
import AssessIcon from "../../svgs/common/AssessIcon";
import Lock from "../../svgs/common/Lock";
import Tooltip from "../../common/tooltip/Tooltip";

function AiTutorCardProgressList({ items = [], onPress, onHoverIn, onHoverOut }) {
  const renderLockedAssessment = (index) => {
    return (
      <MotiView
        key="locked-assessment"
        from={{
          opacity: 0,
          translateY: 20,
          scale: 0.95,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={300 + (index + 1) * 300}
        transition={{ type: "timing", duration: 800 }}>
        <Tooltip content="Not Available Yet" offset={-100} horizontalOffset={-60}>
          <Pressable
            style={{
              backgroundColor: "#F2F4F7",
              borderColor: "#EAECF0",
              borderWidth: 2,
              marginVertical: 8,
              marginHorizontal: 1,
              paddingVertical: 16,
              paddingHorizontal: 24,
              borderRadius: 16,
              height: 75,
            }}>
            <View style={{ marginTop: 4, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <View style={{ flexDirection: "row" }}>
                <AssessIcon />
                <CustomText
                  text="Lesson Assessment"
                  size="m"
                  style={{
                    marginLeft: 8,
                    marginTop: 2,
                    fontWeight: "500",
                    color: "#667085",
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                  useTranslationText={false}
                />
              </View>
              <Lock style={{ marginLeft: 5 }} />
            </View>
          </Pressable>
        </Tooltip>
      </MotiView>
    );
  };

  const renderResourceCard = (item, index) => {
    if (item.resourceType === "axioResource" || item.resourceType === "assignment") {
      return (
        <MotiView
          key={item.id}
          from={{
            opacity: 0,
            translateY: 20,
            scale: 0.95,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={300 + index * 300}
          transition={{ type: "timing", duration: 800 }}>
          <AxioResourceCard
            title={item.details.title}
            description={item.details.description}
            videoUrl={item.details.videoUrl}
            files={item.files} // Add this line
            isHovered={item.isHovered}
            onPress={() => onPress(item.id)}
            onHoverIn={() => onHoverIn(item.id)}
            onHoverOut={() => onHoverOut(item.id)}
          />
        </MotiView>
      );
    }

    return (
      <MotiView
        key={item.id}
        from={{
          opacity: 0,
          translateY: 20,
          scale: 0.95,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={300 + index * 300}
        transition={{ type: "timing", duration: 800 }}>
        <AiTutorCardProgress
          title={item.title}
          isHovered={item.isHovered}
          isComplete={item.details?.isComplete}
          totalObjectives={item.details?.totalObjectives}
          completedObjectives={item.details?.completedObjectives}
          resourceDurationMinutes={item.details?.resourceDurationMinutes}
          onPress={() => onPress(item.id)}
          onHoverIn={() => onHoverIn(item.id)}
          onHoverOut={() => onHoverOut(item.id)}
        />
      </MotiView>
    );
  };

  return (
    <View>
      {items.length > 0 ? (
        <>{items.map(renderResourceCard)}</>
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Placeholder subtitle="Visit your Learning Management System to access these resources" />
        </View>
      )}
    </View>
  );
}

export default AiTutorCardProgressList;
