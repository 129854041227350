import React, { useState, useCallback } from "react";
import { View, StyleSheet, Platform, Image, ViewStyle, ImageStyle } from "react-native";
import type * as ExpoImagePicker from "expo-image-picker";
import CustomText from "../CustomText/CustomText";
import CustomButton from "../CustomButton/CustomButton";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

interface ValidationError {
  message: string;
  code: "size" | "type";
}

const validateFile = (file: File): ValidationError | null => {
  // For actual files
  if (file.size > MAX_FILE_SIZE) {
    return {
      message: "File size should not exceed 2MB",
      code: "size",
    };
  }

  if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
    return {
      message: "Only JPG and PNG files are allowed",
      code: "type",
    };
  }

  return null;
};

const validateBase64Size = (base64String: string): ValidationError | null => {
  // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
  const base64Data = base64String.split(",")[1];
  // Calculate size in bytes: base64 string length * 0.75 gives us the approximate byte size
  const size = Math.ceil(base64Data.length * 0.75);

  if (size > MAX_FILE_SIZE) {
    return {
      message: "File size should not exceed 2MB",
      code: "size",
    };
  }
  return null;
};

// Lazy load ImagePicker only when needed
let ImagePicker: typeof ExpoImagePicker | null = null;
const loadImagePicker = async () => {
  if (Platform.OS !== "web") {
    try {
      const module = await import("expo-image-picker");
      ImagePicker = module;
    } catch (error) {
      console.error("Failed to load expo-image-picker:", error);
    }
  }
};

interface ImageUploadProps {
  onChange: (value: string) => void;
  value?: string;
  containerStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  buttonStyle?: ViewStyle;
  placeholderContent?: React.ReactNode;
  aspectRatio?: [number, number];
  allowsEditing?: boolean;
  buttonText?: string;
  changeButtonText?: string;
  dropzoneText?: string;
  dropzoneSubtext?: string;
  onError?: (error: ValidationError) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onChange,
  value,
  containerStyle,
  imageStyle,
  buttonStyle,
  placeholderContent,
  aspectRatio = [16, 9],
  allowsEditing = true,
  buttonText = "Select Image",
  changeButtonText = "Change Image",
  dropzoneText = "Drag & drop or click to browse",
  dropzoneSubtext = "JPG or PNG format, Max file size: 2mb",
  onError,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleImageSelection = useCallback(async () => {
    if (Platform.OS !== "web") {
      try {
        await loadImagePicker();
        if (!ImagePicker) {
          console.error("ImagePicker not available");
          return;
        }

        // Request permissions
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          onError?.({ message: "Camera roll permission denied", code: "type" });
          return;
        }

        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing,
          aspect: aspectRatio,
          quality: 1,
          base64: true,
        });

        if (!result.canceled && result.assets[0]) {
          const asset = result.assets[0];
          if (asset.base64) {
            const base64Image = `data:image/jpeg;base64,${asset.base64}`;
            // Validate base64 size before setting
            const error = validateBase64Size(base64Image);
            if (error) {
              onError?.(error);
              return;
            }
            onChange(base64Image);
          }
        }
      } catch (error) {
        console.error("Error selecting image:", error);
        onError?.({ message: "Failed to select image", code: "type" });
      }
    }
  }, [onChange, allowsEditing, aspectRatio, onError]);

  const handleFileChange = useCallback(
    (file: File) => {
      const error = validateFile(file);
      if (error) {
        onError?.(error);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        // Validate base64 size before setting
        const sizeError = validateBase64Size(result);
        if (sizeError) {
          onError?.(sizeError);
          return;
        }
        onChange(result);
      };
      reader.readAsDataURL(file);
    },
    [onChange, onError]
  );

  if (Platform.OS !== "web") {
    return (
      <View style={[styles.mobileUploadContainer, containerStyle]}>
        {value ? (
          <Image source={{ uri: value }} style={[styles.mobilePreview, imageStyle]} resizeMode="cover" />
        ) : (
          placeholderContent
        )}
        <CustomButton
          text={value ? changeButtonText : buttonText}
          onPress={handleImageSelection}
          styleType="secondary"
          size="m"
          style={[styles.uploadButton, buttonStyle]}
          textStyle={styles.uploadButtonText}
          leftIcon={null}
          rightIcon={null}
          disabled={false}
          textProps={{}}
        />
      </View>
    );
  }

  const dropZoneStyle = {
    position: "relative",
    width: "100%",
    height: 120,
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: isDragging ? "#4F46E5" : "#D1D5DB",
    borderRadius: 6,
    backgroundColor: isDragging ? "#EEF2FF" : "#F9FAFB",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...containerStyle,
  };

  const imageContainerStyle = {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    ...imageStyle,
  };

  const imagePreviewStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div
      style={dropZoneStyle as React.CSSProperties}
      onClick={(e) => {
        if (e.target === e.currentTarget || (e.target as HTMLElement).closest('[data-dropzone-content="true"]')) {
          document.getElementById("file-input")?.click();
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer?.files?.[0];
        if (file) {
          handleFileChange(file);
        }
      }}>
      {value ? (
        <div style={imageContainerStyle as React.CSSProperties}>
          <img src={value} style={imagePreviewStyle as React.CSSProperties} alt="Image preview" />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          data-dropzone-content="true">
          {placeholderContent || (
            <>
              <CustomText text={dropzoneText} useTranslationText={false} size="xs" style={styles.dropZoneText} />
              <CustomText text={dropzoneSubtext} useTranslationText={false} size="xs" style={styles.dropZoneSubtext} />
            </>
          )}
        </div>
      )}
      <input
        id="file-input"
        type="file"
        accept={ACCEPTED_MIME_TYPES.join(",")}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            handleFileChange(file);
          }
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  mobileUploadContainer: {
    width: "100%",
    alignItems: "center",
    gap: 12,
  },
  mobilePreview: {
    width: "100%",
    height: 120,
    borderRadius: 6,
    backgroundColor: "#F9FAFB",
  },
  uploadButton: {
    width: "100%",
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  uploadButtonText: {
    color: "#111827",
  },
  dropZoneText: {
    color: "#111827",
    marginBottom: 4,
  },
  dropZoneSubtext: {
    color: "#6B7280",
  },
});

export default ImageUpload;
