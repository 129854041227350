import { useEffect, useState } from "react";
import { useGetFacultyByCourseIdQuery } from "../../../graphql/generated/graphql";
import SearchListModal from "../../../components/common/SearchListModal/SearchListModal";
import { CoursesTabModalState } from "./CoursesTabContainer";

const RemoveFacultyFromCourseListContainer = ({
  visible,
  onClose,
  onAction,
  courseId,
}: {
  visible: boolean;
  onClose: () => void;
  onAction: (id: string) => void;
  courseId: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: facultyData, refetch: facultyRefetch } = useGetFacultyByCourseIdQuery({
    variables: {
      courseId: courseId,
      query: searchQuery,
    },
  });

  useEffect(() => {
    facultyRefetch();
  }, [searchQuery, courseId]);

  const list =
    facultyData?.getFacultyByCourseId?.data.faculties.map((faculty) => ({
      id: faculty.id,
      name: faculty.name,
    })) || [];

  return (
    <SearchListModal<CoursesTabModalState>
      visible={visible}
      onClose={onClose}
      onAction={onAction}
      list={list}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      modalType={CoursesTabModalState.REMOVE_FACULTY}
    />
  );
};

export default RemoveFacultyFromCourseListContainer;
