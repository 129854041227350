import React from "react";
import { View, StyleSheet } from "react-native";
import AnalyticsAccordionList from "../../../components/analytics/AnalyticsAccordionList.js/AnalyticsAccordionList";

function AccordionAnalyticsContainer({
  data,
  onListDrillDown,
  breadcrumb,
  loadChildData,
  expandedItemIds,
  setExpandedItemIds,
  childrenData,
  setChildrenData,
  loadingChildren,
  setLoadingChildren,
}) {
  return (
    <View style={styles.container}>
      <AnalyticsAccordionList
        data={data}
        onListDrillDown={onListDrillDown}
        breadcrumb={breadcrumb}
        loadChildData={loadChildData}
        expandedItemIds={expandedItemIds}
        setExpandedItemIds={setExpandedItemIds}
        childrenData={childrenData}
        setChildrenData={setChildrenData}
        loadingChildren={loadingChildren}
        setLoadingChildren={setLoadingChildren}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
  },
});

export default AccordionAnalyticsContainer;
