import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M19.959 18.037h-5.256a.75.75 0 00-.75.75v1.125a3.377 3.377 0 106.755 0v-1.125a.75.75 0 00-.75-.75zm-.75 1.875a1.875 1.875 0 11-3.75 0v-.375h3.75v.375zm-9.006-4.125h-5.25a.75.75 0 00-.75.75v1.125a3.375 3.375 0 106.75 0v-1.125a.75.75 0 00-.75-.75zm-.75 1.875a1.875 1.875 0 11-3.75 0v-.375h3.75v.375zM7.578 2.287c-1.091 0-2.15.967-2.981 2.719-1.306 2.754-1.74 6.844.027 9a.75.75 0 00.581.274h4.74a.75.75 0 00.58-.274c1.768-2.156 1.334-6.249.028-9-.833-1.752-1.89-2.719-2.975-2.719zm1.983 10.5H5.589c-1.069-1.697-.676-4.94.365-7.135.606-1.282 1.265-1.865 1.624-1.865.36 0 1.015.583 1.622 1.865 1.037 2.194 1.43 5.438.361 7.135zm5.401 3.75h4.739a.75.75 0 00.581-.275c1.767-2.156 1.333-6.248.027-9-.831-1.758-1.89-2.725-2.98-2.725-1.092 0-2.147.967-2.98 2.719-1.305 2.754-1.74 6.844.028 9a.755.755 0 00.585.281zm.75-8.635c.606-1.282 1.265-1.865 1.616-1.865.352 0 1.015.583 1.621 1.865 1.04 2.195 1.434 5.437.366 7.135h-3.97c-1.068-1.697-.676-4.94.365-7.135h.002z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
