import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M22.534 18.207L14.335 3.969a2.322 2.322 0 00-4.003 0L2.134 18.207a2.204 2.204 0 000 2.223 2.284 2.284 0 002.001 1.143h16.397a2.283 2.283 0 002-1.143 2.204 2.204 0 00.002-2.223zm-1.3 1.473a.797.797 0 01-.702.393H4.135a.796.796 0 01-.701-.393.712.712 0 010-.724l8.198-14.238a.82.82 0 011.407 0l8.198 14.238a.713.713 0 01-.004.724zm-9.65-5.607v-3.75a.75.75 0 111.5 0v3.75a.75.75 0 11-1.5 0zm1.875 3.375a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
