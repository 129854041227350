import React, { useState } from "react";
import { View, StyleSheet, Pressable, Platform } from "react-native";
import { Menu } from "react-native-paper";
import CustomText from "../../common/general/CustomText/CustomText";
import { ThreeDot } from "../../svgs/common";
import { Sparkle, Link, Document, Project, Assessment } from "../../svgs/CustomCoursework";
import { Switch } from "@rneui/themed";

// const TYPE_CONFIG = {
//   "Interactive Learning Experience": {
//     Icon: Sparkle,
//     showToggle: false,
//     isToggled: null,
//   },
//   AITutor: {
//     Icon: Sparkle,
//     showToggle: false,
//     isToggled: null,
//   },
//   Resource: {
//     Icon: Link,
//     showToggle: false,
//     isToggled: null,
//   },
//   Assignment: {
//     Icon: Document,
//     showToggle: false,
//     isToggled: null,
//   },
//   Project: {
//     Icon: Project,
//     showToggle: false,
//     isToggled: null,
//   },
//   "Lesson Assessment": {
//     Icon: Assessment,
//     showToggle: true,
//     isToggled: false,
//   },
// };
// In ActivityCard.js - Update TYPE_CONFIG
const TYPE_CONFIG = {
  "Interactive Learning Experience": {
    Icon: Sparkle,
    showToggle: false,
    isToggled: null,
  },
  // Remove AITutor since we're mapping it to Interactive Learning Experience in the parent components
  Resource: {
    Icon: Link,
    showToggle: false,
    isToggled: null,
  },
  Assignment: {
    Icon: Document,
    showToggle: false,
    isToggled: null,
  },
  Project: {
    Icon: Project,
    showToggle: false,
    isToggled: null,
  },
  "Lesson Assessment": {
    Icon: Assessment,
    showToggle: true,
    isToggled: false,
  },
};

const DragHandle = () => (
  <>
    <View style={styles.dragDots}>
      <View style={styles.dot} />
      <View style={styles.dot} />
    </View>
    <View style={styles.dragDots}>
      <View style={styles.dot} />
      <View style={styles.dot} />
    </View>
  </>
);

const ActivityCard = ({ title, type, onOptionsPress, onToggle, style, onLongPress }) => {
  const config = TYPE_CONFIG[type] || { showToggle: false };
  const Icon = config.Icon;
  const [isToggled, setIsToggled] = useState(config.isToggled);

  const handleToggleChange = (value) => {
    setIsToggled(value);
    if (onToggle) {
      onToggle(value);
    }
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });

  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { pageX, pageY } = event.nativeEvent;
    setAnchorPosition({ x: pageX - 100, y: pageY });
    setMenuVisible(true);
  };

  const closeMenu = () => setMenuVisible(false);

  const handleOptionSelect = (action) => {
    console.log("ActivityCard handleOptionSelect:", action);
    if (onOptionsPress) {
      onOptionsPress(action);
    }
    closeMenu();
  };

  if (Platform.OS === "web") {
    window.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      true
    );
  }

  const handleDragStart = (e) => {
    e.preventDefault();
    onLongPress(e);
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.contentContainer}>
        <View style={styles.iconContainer}>{Icon && <Icon fill="#5B6780" width={20} height={20} />}</View>
        <View style={styles.textContainer}>
          <CustomText
            text={title}
            useTranslationText={false}
            weight="medium"
            style={styles.title}
            numberOfLines={1}
            ellipsizeMode="tail"
          />
          <CustomText
            text={type}
            useTranslationText={false}
            style={styles.subtitle}
            numberOfLines={1}
            ellipsizeMode="tail"
          />
        </View>
      </View>
      <View style={styles.actionsContainer}>
        {config.showToggle ? (
          <Switch
            value={isToggled}
            onValueChange={handleToggleChange}
            color="#3B82F6"
            trackColor={{ false: "#E5E7EB", true: "#3B82F6" }}
          />
        ) : (
          <>
            <Menu
              visible={menuVisible}
              onDismiss={closeMenu}
              anchor={{ x: anchorPosition.x, y: anchorPosition.y }}
              contentStyle={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}>
              {/* <Menu.Item onPress={() => handleOptionSelect("edit")} title="Edit" titleStyle={{ color: "#344054" }} /> */}
              {type == "Interactive Learning Experience" && (
                <Menu.Item
                  onPress={() => handleOptionSelect("clone")}
                  title="Clone"
                  titleStyle={{ color: "#344054" }}
                />
              )}
              <Menu.Item
                onPress={() => handleOptionSelect("delete")}
                title="Delete"
                titleStyle={{ color: "#344054" }}
              />
            </Menu>
            <Pressable onPress={(e) => openMenu(e)} style={styles.optionsButton}>
              <ThreeDot fill="#000" width={20} height={20} />
            </Pressable>
          </>
        )}
      </View>
      <Pressable onPointerDown={handleDragStart} style={styles.dragHandle}>
        <DragHandle />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E5E7EB",
    marginVertical: 4,
    width: "100%",
    position: "relative",
  },
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  iconContainer: {
    marginRight: 12,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    color: "#111827",
    marginBottom: 2,
  },
  subtitle: {
    fontSize: 12,
    color: "#6B7280",
  },
  actionsContainer: {
    marginLeft: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  optionsButton: {
    padding: 4,
    marginRight: 8,
  },
  dragHandle: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: "row",
    width: "20px",
    justifyContent: "center",
    gap: 2,
    alignItems: "center",
    marginLeft: 8,
    backgroundColor: "#ccc",
  },
  dragDots: {
    justifyContent: "center",
    height: 12,
    gap: 2,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    backgroundColor: "#9CA3AF",
    marginVertical: 1,
  },
});

export default ActivityCard;
