import React, { useRef } from "react";
import { StyleSheet, View } from "react-native";
import { Controller, Control } from "react-hook-form";
import { actions, RichEditor, RichToolbar } from "react-native-pell-rich-editor";
import { useTranslation } from "react-i18next";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";

interface AddCourseSecondStepProps {
  control: Control;
}

const AddCourseSecondStep: React.FC<AddCourseSecondStepProps> = ({ control }) => {
  const { t } = useTranslation();
  const refInstance = useRef<RichEditor | null>(null);

  return (
    <>
      <View style={styles.formGroup}>
        <CustomText
          text="Course Description"
          size="s"
          textType="text"
          style={{ color: "#182230" }}
          weight="ultraBold"
        />
        <CustomText
          text="This information appears on the course details page in the course catalogue."
          size="xs"
          style={styles.helperText}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              placeholder="e.g. Discover the world of Artificial Intelligence (AI) and its transformative impact on industries and everyday life. This course introduces the fundamental concepts of AI, including machine learning, natural language processing, robotics, and computer vision. Through hands-on activities and real-world examples, you'll gain practical knowledge of how AI systems are built, trained, and deployed."
              multiline
              numberOfLines={10}
              value={value}
              onChangeText={onChange}
              aria-label="Course description text input"
              style={styles.input}
              placeholderTextColor="#98A2B3"
            />
          )}
        />
      </View>
      <View style={styles.formGroup}>
        <CustomText
          text="What will the student learn in this course?"
          size="s"
          textType="text"
          style={{ color: "#182230" }}
          weight="ultraBold"
        />
        <CustomText
          text="This information appears on the course details page in the course catalogue and is best formatted by using bullets."
          size="xs"
          style={styles.helperText}
        />
        <Controller
          control={control}
          name="objectives"
          render={({ field: { onChange, value } }) => (
            <>
              <RichToolbar
                editor={refInstance}
                actions={[
                  actions.undo,
                  actions.redo,
                  actions.setBold,
                  actions.setItalic,
                  actions.setUnderline,
                  actions.setStrikethrough,
                  actions.insertOrderedList,
                  actions.insertBulletsList,
                  actions.insertLink,
                ]}
                style={styles.toolbar}
              />
              <View style={styles.editorContainer}>
                <RichEditor
                  ref={refInstance}
                  initialContentHTML={value}
                  onChange={(html) => onChange(html)}
                  style={styles.editor}
                  editorStyle={{
                    placeholderColor: "#98A2B3",
                    contentCSSText:
                      "font-size: 16px; font-family: 'Inter', sans-serif; font-weight: 400; color: #1F2937;",
                  }}
                  placeholder={t("text.Add a description")}
                  styleWithCSS={true}
                />
              </View>
            </>
          )}
        />
      </View>
      <View style={styles.formGroup}>
        <CustomText
          text="Who is this course for?"
          size="s"
          textType="text"
          style={{ color: "#182230" }}
          weight="ultraBold"
        />
        <CustomText
          text="This information appears on the course details page in the course catalogue."
          size="xs"
          style={styles.helperText}
        />
        <Controller
          control={control}
          name="targetAudience"
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              placeholder="e.g. Students interested in technology, data science, and future-focused careers. Perfect for beginners looking to explore AI without prior technical expertise."
              multiline
              numberOfLines={5}
              value={value}
              onChangeText={onChange}
              aria-label="Target audience text input"
              style={styles.input}
              placeholderTextColor="#98A2B3"
            />
          )}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  editorContainer: {
    flex: 1,
    marginBottom: 10,
    minHeight: 220,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: "#D1D5DB",
    borderEndEndRadius: 10,
    borderEndStartRadius: 10,
    overflow: "hidden",
  },
  editor: {
    flex: 1,
  },
  toolbar: {
    backgroundColor: "#F2F4F7",
    borderColor: "#D1D5DB",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  formGroup: {
    marginBottom: 24,
  },
  helperText: {
    color: "#6B7280",
    marginBottom: 8,
  },
  input: {
    width: "100%",
    fontSize: 16,
    borderRadius: 10,
    fontWeight: "400",
  },
});

export default AddCourseSecondStep;
