import React, { useState, useEffect } from "react";
import { View, FlatList, TextInput, TouchableOpacity } from "react-native";
import { Controller } from "react-hook-form";
import { Text, Card, Chip } from "@rneui/themed";
import { CloseIcon } from "../../../svgIcons";
import { useDebounce } from "use-debounce";

type Option = { id: string; name: string };

interface SearchSelectMultiProps {
  name: string;
  control: any;
  label: string;
  placeholder: string;
  fetchOptions: (query: string) => Promise<Option[]>;
  backgroundColor: string;
  loading?: boolean;
}

export const SearchSelectMulti: React.FC<SearchSelectMultiProps> = ({
  name,
  control,
  label,
  placeholder,
  fetchOptions,
  backgroundColor,
  loading,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [searchText, setSearchText] = useState("");

  const [debouncedText] = useDebounce(searchText, 300);

  useEffect(() => {
    const fetch = async () => {
      if (debouncedText === "") {
        setOptions([]);
      } else {
        const results = await fetchOptions(debouncedText);
        setOptions(results);
      }
    };

    if (debouncedText) {
      fetch();
    }
  }, [debouncedText, fetchOptions]);

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] } }) => (
        <View style={{ marginBottom: 20 }}>
          <Text style={{ marginBottom: 8, fontWeight: "bold" }}>{label}</Text>

          <View style={{ flexDirection: "row", flexWrap: "wrap", marginBottom: 8 }}>
            {value.map((selected: Option) => (
              <Chip
                key={selected.id}
                title={selected.name}
                containerStyle={{ margin: 4 }}
                icon={
                  <TouchableOpacity
                    style={{ marginLeft: 5 }}
                    onPress={() => onChange(value.filter((v: Option) => v.id !== selected.id))}>
                    <CloseIcon fill="#fff" props={{ style: { width: 15 } }} />
                  </TouchableOpacity>
                }
                iconPosition="right"
                buttonStyle={{
                  backgroundColor,
                  paddingRight: 8,
                  paddingLeft: 8,
                }}
              />
            ))}
          </View>

          <TextInput
            value={searchText}
            onChangeText={handleSearch}
            placeholder={placeholder}
            style={{
              borderWidth: 1,
              borderColor: "#ccc",
              borderRadius: 8,
              padding: 10,
              marginBottom: 10,
            }}
          />

          {loading && <Text>Loading...</Text>}

          {options.length > 0 && (
            <Card containerStyle={{ padding: 0 }}>
              <FlatList
                data={options}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => {
                  const isSelected = value.some((v: Option) => v.id === item.id);
                  const matchIndex = item.name.toLowerCase().indexOf(searchText.toLowerCase());

                  const before = item.name.slice(0, matchIndex);
                  const match = item.name.slice(matchIndex, matchIndex + searchText.length);
                  const after = item.name.slice(matchIndex + searchText.length);

                  return (
                    <TouchableOpacity
                      onPress={() => {
                        if (!isSelected) {
                          onChange([...value, item]);
                        }
                      }}
                      style={{
                        padding: 10,
                        backgroundColor: isSelected ? "#f5f5f5" : "white",
                      }}>
                      <Text style={{ opacity: isSelected ? 0.5 : 1, flexDirection: "row" }}>
                        {matchIndex >= 0 ? (
                          <>
                            <Text>{before}</Text>
                            <Text style={{ fontWeight: "bold", color: backgroundColor }}>{match}</Text>
                            <Text>{after}</Text>
                          </>
                        ) : (
                          item.name
                        )}
                        {isSelected ? " (already selected)" : ""}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </Card>
          )}
        </View>
      )}
    />
  );
};
