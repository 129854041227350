import React from "react";
import { StyleSheet, View, Pressable } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";

const TabNavigator = ({ tabData, activeTab, onTabPress }) => {
  return (
    <View style={styles.navBar}>
      {tabData.map((tab) => {
        const isActiveTab = activeTab === tab.key;
        return (
          <Pressable
            role="tab"
            key={tab.key}
            style={isActiveTab ? [styles.activeTab] : styles.tab}
            onPress={() => onTabPress(tab.key)}>
            <CustomText
              size="s"
              weight={isActiveTab ? "ultraBold" : "normal"}
              style={{ color: isActiveTab ? "#344054" : "#667085" }}
              text={tab.title}
            />
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  navBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 12,
  },
  tab: {
    padding: 10,
  },
  activeTab: {
    padding: 10,
    backgroundColor: "#EAECF0",
    borderRadius: 8,
  },
});

export default TabNavigator;
