import DegreeCardList from "../../components/degree/DegreeCardList";
import { MotiView } from "moti";
import React, { useEffect } from "react";
import { useGetDegreeBySchoolQuery, useEnrollUserInDegreeMutation } from "../../graphql/generated/graphql";
import { useAppConfig } from "../../AppConfigProvider";

function DegreeCardListContainer() {
  const appConfig = useAppConfig();
  const integrationId = appConfig.axioSchools?.[0] || null;

  const { data: degreesData, error } = useGetDegreeBySchoolQuery({
    variables: { schoolId: integrationId },
    skip: !integrationId,
  });

  const [enrollInDegree] = useEnrollUserInDegreeMutation();

  useEffect(() => {
    if (degreesData) {
      console.log("Fetched Degrees Data:", degreesData?.getDegreeBySchool?.data);
    }
    if (error) {
      console.error("Error fetching degrees:", error);
    }
  }, [degreesData, error]);

  const degrees =
    degreesData?.getDegreeBySchool?.data?.map((degree) => ({
      id: degree.id,
      title: degree.name,
      description: degree.description || "No description available",
    })) || [];

  const handleEnrollPress = async (degreeId) => {
    console.log("Enrolling in degree ID:", degreeId);
    try {
      const response = await enrollInDegree({
        variables: { degreeId },
      });

      if (response.data?.enrollInDegree?.success) {
        console.log(`Successfully enrolled in ${degreeId}`);
      } else {
        console.error("Enrollment failed:", response.data?.enrollInDegree?.message);
      }
    } catch (error) {
      console.error("Error enrolling in degree:", error);
    }
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      aria-live="polite">
      <DegreeCardList degrees={degrees} onPress={handleEnrollPress} />
    </MotiView>
  );
}

export default DegreeCardListContainer;
