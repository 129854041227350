import { useEffect, useState, useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { MotiView } from "moti";
import { useGetCourseByIdQuery, useGetFacultyByCourseIdQuery } from "../graphql/generated/graphql";
import TimeLeftSmall from "../components/svgs/common/TimeLeftSmall";
import CustomProgressBar from "../components/common/general/CustomProgressBar/CustomProgressBar";
import CoreTitle from "../components/svgs/common/CoreTitle";
import JourneyBreadcrumbs from "../components/journey/JourneyBreadcrumbs/JourneyBreadcrumbs";
import Skeleton from "react-loading-skeleton";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import AiTutorCardProgressListContainer from "../containers/school/AiTutorCardProgressListContainer/AiTutorCardProgressListContainer";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { shadowStyles } from "../styles/shadow";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

function CustomCourseStudentView() {
  const posthog = usePostHog();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Custom Course Student">>();

  const { course_id: courseId, integration_id: integrationId } = route.params;

  const [courseProgress, setCourseProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState("");

  const { data: course } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });

  const { data: facultyData } = useGetFacultyByCourseIdQuery({ variables: { courseId } });
  const hasFaculty = facultyData?.getFacultyByCourseId?.data?.faculties.length > 0;

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""}${remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""}`;
    } else {
      return `${minutes} min`;
    }
  };

  const handleFacultyViewPress = () => {
    navigation.navigate("Faculty", {
      courseName: course?.getCourseById.data[0].name,
      courseId: courseId,
      integration_id: integrationId,
    });
  };

  const handleProgressUpdate = useCallback(
    (progress, durationMinutes) => {
      setCourseProgress(progress);
      setTimeLeft(formatDuration(durationMinutes));
    },
    [formatDuration]
  );

  const handleBackPress = () => {
    navigation.navigate("Custom School Student", {
      integration_id: integrationId,
    });
  };

  return (
    <NoChatBarLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={800}
        transition={{ type: "timing", duration: 600 }}>
        <View style={styles.headerContainer}>
          <JourneyBreadcrumbs
            onPress={handleBackPress}
            firstItem="Courses"
            secondItem={course?.getCourseById.data[0].name}
          />
          <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <View style={styles.headerTitleRow}>
              <CoreTitle />
              <CustomText
                useTranslationText={false}
                text={course?.getCourseById.data[0].name}
                size="xxl"
                style={{ fontFamily: "AbrilFatface", fontSize: 46, lineHeight: 44, fontWeight: 800, marginLeft: 8 }}
              />
            </View>
            {hasFaculty && (
              <CustomButton
                text={"Course Instructor"}
                styleType="primaryLight"
                onPress={handleFacultyViewPress}
                leftIcon={null}
                size="s"
                aria-label="Course Instructor"
                style={shadowStyles.smallShadowButton}
              />
            )}
          </View>
          <CustomSpacing size={16} />
        </View>

        {timeLeft !== "" ? (
          <View style={[styles.courseProgress, { flex: 1, flexDirection: "row", alignItems: "center", gap: 16 }]}>
            <View style={{ maxWidth: 200, flex: 1 }}>
              <CustomProgressBar progress={courseProgress} color="#3E68FE" ariaLabel="Course Progress" />
            </View>
            <View style={styles.progressInfo}>
              <TimeLeftSmall size={16} color="#667085" />
              <CustomText text={`${timeLeft} left`} size="s" style={styles.timeText} useTranslationText={false} />
            </View>
          </View>
        ) : (
          <View style={[styles.courseProgress, { flex: 1, flexDirection: "row", alignItems: "center", gap: 16 }]}>
            <View style={{ maxWidth: 200, flex: 1 }}>
              <Skeleton height={8} width="100%" />
            </View>
            <View style={styles.progressInfo}>
              <Skeleton circle={true} height={16} width={16} />
              <Skeleton height={16} width={80} />
            </View>
          </View>
        )}
      </MotiView>
      <AiTutorCardProgressListContainer
        integrationId={integrationId}
        courseId={courseId}
        onProgressUpdate={handleProgressUpdate}
      />
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  headerTitleRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  courseProgress: {
    marginVertical: 12,
  },
  progressInfo: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  timeText: {
    color: "#667085",
  },
});

export default CustomCourseStudentView;
