import { Platform, Alert } from "react-native";
import * as Sharing from "expo-sharing";
import RNHTMLtoPDF from "react-native-html-to-pdf";

export async function exportConversationTranscript(data) {
  try {
    if (Platform.OS === "web") {
      const jsPDF = (await import("jspdf")).default;
      const doc = new jsPDF();

      doc.setFontSize(12);
      let yPos = 10;
      const pageHeight = 280;

      const checkPageOverflow = (doc, yPos) => {
        if (yPos >= pageHeight) {
          doc.addPage();
          return 10;
        }
        return yPos;
      };

      doc.setFontSize(18);
      doc.text("Conversation Transcript", 10, yPos);
      yPos += 10;

      data.forEach((course) => {
        doc.setFontSize(14);
        doc.text(`Course: ${course.name}`, 10, yPos);
        yPos += 10;
        yPos = checkPageOverflow(doc, yPos);

        course.modules.forEach((module) => {
          doc.setFontSize(12);
          doc.text(`Module: ${module.title}`, 10, yPos);
          yPos += 10;
          yPos = checkPageOverflow(doc, yPos);

          module?.aiTutors.forEach((aitutor) => {
            doc.setFontSize(10);
            doc.text(`Ai Tutor: ${aitutor.title}`, 10, yPos);
            yPos += 10;
            yPos = checkPageOverflow(doc, yPos);

            aitutor.hasChatChats?.[0]?.hasMessageMessages
              ?.slice()
              .reverse()
              .forEach((chat) => {
                doc.setFontSize(8);
                doc.text(`${chat.sender === "ai" ? "AI" : "Human"}:`, 10, yPos);
                yPos += 10;
                yPos = checkPageOverflow(doc, yPos);

                const wrappedText = doc.splitTextToSize(chat.content, 180);
                wrappedText.forEach((line) => {
                  doc.text(line, 10, yPos);
                  yPos += 5;
                  yPos = checkPageOverflow(doc, yPos);
                });
                yPos += 10;
              });
          });
        });
      });

      doc.save("conversation_transcript_export.pdf");
      Alert.alert("Export Successful", `File has been downloaded.`);
    } else {
      const coursesHtmlContent = data
        .map((course) => {
          let htmlContent = `
      <h1>${course.name}</h1>
    `;
          course.modules.forEach((module) => {
            htmlContent += `<br/><h3>Module: ${module.title}</h3>`;

            module?.aiTutors.forEach((aitutor) => {
              htmlContent += `<p><strong>Ai Tutor: ${aitutor.title}</strong></p>`;

              const reversedChats = [...(aitutor.hasChatChats?.[0]?.hasMessageMessages || [])].reverse();
              reversedChats.forEach((chat) => {
                htmlContent += `
            <p><strong>${chat.sender === "ai" ? "AI" : "Human"}:</strong></p>
            <p>${chat.content}</p>
          `;
              });
            });
          });

          return htmlContent;
        })
        .join("");

      const pdfFile = await RNHTMLtoPDF.convert({
        html: coursesHtmlContent,
        fileName: "conversation_transcript_file",
        base64: false,
      });

      if (await Sharing.isAvailableAsync()) {
        await Sharing.shareAsync(pdfFile.filePath, {
          mimeType: "application/pdf",
          dialogTitle: "Export Course",
          UTI: "com.adobe.pdf",
        });
      } else {
        Alert.alert("Sharing not available", "Cannot share files on this platform.");
      }
    }
  } catch (error) {
    console.error("Error exporting data:", error);
    Alert.alert("Export Failed", "An error occurred while exporting the data.");
  }
}
