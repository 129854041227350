import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { Assessment } from "../../svgs/CustomCoursework";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CheckedBadge from "../../svgs/common/CheckedBadge";

function AssessmentCard({ onButtonPress, isRunning }) {
  const isCompleted = true;
  const grade = 85;
  return (
    <View style={[styles.resourceItem, { borderColor: "#EAECF0" }]}>
      <View style={styles.resourceItemLeft}>
        <View style={styles.textContainer}>
          <Assessment fill="#101828" style={{ marginTop: "2px" }} />
          <CustomText text="Lesson Assessment" weight="bold" style={styles.resourceItemTitle} />
        </View>
      </View>
      {!isCompleted ? (
        <View style={styles.buttonsContainer}>
          <CustomButton
            styleType="primary"
            text={`${isRunning ? "Resume" : "Begin"} Assessment`}
            textStyle={styles.buttonText}
            onPress={onButtonPress}
            style={{ backgroundColor: "#3E68FE" }}
          />
        </View>
      ) : (
        <View style={styles.buttonsContainer}>
          <View style={styles.gradeContainer}>
            {!!grade && (
              <View style={styles.gradeTag}>
                <CustomText
                  text={`${grade}/100`}
                  useTranslationText={false}
                  size="s"
                  weight="ultraBold"
                  style={{ color: "#fff" }}
                />
              </View>
            )}
            <CheckedBadge />
          </View>
          {/* <CustomButton
            styleType="primary"
            text={"View Assessment"}
            textStyle={styles.buttonText}
            onPress={onButtonPress}
            style={{ backgroundColor: "#3E68FE" }}
          /> */}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  resourceItem: {
    marginVertical: 8,
    marginHorizontal: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 24,
    height: 75,
    borderWidth: 1,
    borderRadius: 16,
    outlineWidth: 0,
    outlineColor: "transparent",
    outlineStyle: "solid",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  resourceItemLeft: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 8,
  },
  resourceItemTitle: {
    marginBottom: 4,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 700,
  },
  gradeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  gradeTag: {
    padding: 8,
    backgroundColor: "#0084FF",
    borderRadius: 100,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
});

export default AssessmentCard;
