// Student Insights - Activity
import React from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { ThumbUp, Warning, Footprints } from "../../svgs/common";

const calculateEngagement = (active_interaction_count) => {
  if (active_interaction_count < 3) {
    return { level: "Low", color: "red" };
  } else if (active_interaction_count <= 6) {
    return { level: "Average", color: "#F79009" };
  } else {
    return { level: "High", color: "#4BA30D" };
  }
};

const formatSentiment = (sentiment) => {
  if (!sentiment) return { sentiment: "Neutral", color: "#F79009" };

  const s = sentiment.toLowerCase();
  if (s === "positive") {
    return { sentiment: "Positive", color: "#4BA30D" };
  } else if (s === "negative") {
    return { sentiment: "Negative", color: "red" };
  } else {
    return { sentiment: "Neutral", color: "#F79009" };
  }
};

const ActivityInsights = ({ activityData, parentActivity }) => {
  if (!parentActivity) {
    return (
      <View style={styles.loadingContainer}>
        <CustomText text="Activity data not available" useTranslationText={false} size="m" />
      </View>
    );
  }

  const averageTimeInMinutes =
    parentActivity && parentActivity.average_time_taken ? Math.ceil(parentActivity.average_time_taken / 60) : 0;

  const completionRate = parentActivity?.completionStats?.completed || 0;
  const formattedCompletionRate =
    typeof completionRate === "number"
      ? completionRate % 1 !== 0
        ? completionRate.toFixed(2)
        : completionRate
      : completionRate;

  const engagement = calculateEngagement(parentActivity.active_interaction_count || 0);
  const sentimentData = formatSentiment(parentActivity.sentiment);

  // Format objectives for the table
  const objectivesWithCompletion = activityData.map((objective) => ({
    ...objective,
    completionStats: objective.completionStats || { completed: 0, inProgress: 0, notStarted: 0 },
  }));

  return (
    <View>
      <View style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 48 }}>
        <View style={styles.topTile}>
          <CustomText style={styles.topText} text="avg time to completion" useTranslationText={false} />
          <CustomText
            style={{ fontSize: 36, color: "#101828" }}
            weight="ultraBold"
            text={`${averageTimeInMinutes} minutes`}
            useTranslationText={false}
          />
        </View>
        <View style={styles.topTile}>
          <CustomText style={styles.topText} text="completion rate" useTranslationText={false} />
          <CustomText
            useTranslationText={false}
            style={{ fontSize: 36, color: "#101828" }}
            weight="ultraBold"
            text={`${formattedCompletionRate}%`}
          />
        </View>
        <View style={styles.topTile}>
          <CustomText style={styles.topText} text="student engagement" useTranslationText={false} />
          <CustomText
            useTranslationText={false}
            text={engagement.level}
            weight="ultraBold"
            style={{ fontSize: 36, color: engagement.color }}
          />
        </View>
        <View style={styles.topTile}>
          <CustomText style={styles.topText} text="overall sentiment" useTranslationText={false} />
          <CustomText
            useTranslationText={false}
            weight="ultraBold"
            text={sentimentData.sentiment}
            style={{ fontSize: 36, color: sentimentData.color }}
          />
        </View>
      </View>

      <CustomText
        text="Key Insights"
        size="xl"
        weight="bold"
        style={{ paddingBottom: 24 }}
        useTranslationText={false}
      />
      <View style={styles.keyInsightsContainer}>
        {/* What Students Did Well */}
        <View style={styles.keyInsightsTile}>
          <View
            style={{
              backgroundColor: "#66C61C",
              width: 57,
              height: 57,
              borderRadius: 50,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <ThumbUp color="#FFFFFF" />
          </View>
          <CustomText
            text="What Students Did Well"
            size="xl"
            weight="bold"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
          {parentActivity?.success_points_details && parentActivity.success_points_details.length > 0 ? (
            parentActivity.success_points_details.slice(0, 2).map((detail, idx) => (
              <View
                key={idx}
                style={{
                  padding: 12,
                  borderRadius: 8,
                  backgroundColor: "#F9FAFB",
                  marginBottom: idx === 0 ? 8 : 0,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}>
                <CustomText text={detail} useTranslationText={false} />
              </View>
            ))
          ) : (
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                backgroundColor: "#F9FAFB",
                flexDirection: "row",
                flexWrap: "wrap",
              }}>
              <CustomText text="No Data Points Available" useTranslationText={false} />
            </View>
          )}
        </View>

        {/* Where Students Struggled */}
        <View style={styles.keyInsightsTile}>
          <View
            style={{
              backgroundColor: "#F79009",
              width: 57,
              height: 57,
              borderRadius: 50,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Warning />
          </View>
          <CustomText
            text="Where Students Struggled"
            size="xl"
            weight="bold"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
          {parentActivity?.confusion_points_details && parentActivity.confusion_points_details.length > 0 ? (
            parentActivity.confusion_points_details.slice(0, 2).map((detail, idx) => (
              <View
                key={idx}
                style={{
                  padding: 12,
                  borderRadius: 8,
                  backgroundColor: "#F9FAFB",
                  marginBottom: idx === 0 ? 8 : 0,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}>
                <CustomText text={detail} useTranslationText={false} />
              </View>
            ))
          ) : (
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                backgroundColor: "#F9FAFB",
                flexDirection: "row",
                flexWrap: "wrap",
              }}>
              <CustomText text="No Data Points Available" useTranslationText={false} />
            </View>
          )}
        </View>

        {/* Suggested Next Steps */}
        <View style={styles.keyInsightsTile}>
          <View
            style={{
              backgroundColor: "#8E55EA",
              width: 57,
              height: 57,
              borderRadius: 50,
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Footprints />
          </View>
          <CustomText
            text="Suggested Next Steps"
            size="xl"
            weight="bold"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
          {parentActivity?.further_recommendations && parentActivity.further_recommendations.length > 0 ? (
            parentActivity.further_recommendations.slice(0, 2).map((detail, idx) => (
              <View
                key={idx}
                style={{
                  padding: 12,
                  borderRadius: 8,
                  backgroundColor: "#F9FAFB",
                  marginBottom: idx === 0 ? 8 : 0,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}>
                <CustomText text={detail} useTranslationText={false} />
              </View>
            ))
          ) : (
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                backgroundColor: "#F9FAFB",
                flexDirection: "row",
                flexWrap: "wrap",
              }}>
              <CustomText text="No Data Points Available" useTranslationText={false} />
            </View>
          )}
        </View>
      </View>

      <CustomText
        text="Topic Completion Breakdown"
        size="xl"
        weight="bold"
        useTranslationText={false}
        style={{ paddingBottom: 8 }}
      />
      <CustomText
        text="These students encountered difficulties in this activity and may need extra guidance."
        size="m"
        style={{ paddingBottom: 24 }}
        useTranslationText={false}
      />
      <View style={styles.tableContainer}>
        <View style={styles.tableHeader}>
          <CustomText text="Topic" style={styles.headerText} weight="bold" useTranslationText={false} />
          <CustomText text="Percent Completed" style={styles.headerText} weight="bold" useTranslationText={false} />
        </View>
        {objectivesWithCompletion.map((objective, index) => {
          const rate = objective.completionStats.completed;
          const formattedRate = typeof rate === "number" ? (rate % 1 !== 0 ? rate.toFixed(2) : rate) : rate;
          return (
            <View key={index} style={styles.tableRow}>
              <CustomText text={objective.label} style={styles.cellText} useTranslationText={false} />
              <CustomText text={`${formattedRate}%`} style={styles.cellText} useTranslationText={false} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    padding: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  topTile: {
    flexDirection: "column",
    justifyContent: "flex-start",
    borderColor: "#EAECF0",
    borderWidth: 1,
    borderRadius: 20,
    padding: 20,
    width: "23%",
  },
  topText: {
    color: "#101828",
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 8,
  },
  keyInsightsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 48,
  },
  keyInsightsTile: {
    borderColor: "#EAECF0",
    borderWidth: 1,
    borderRadius: 20,
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "31%",
    padding: 20,
  },
  tableContainer: {
    padding: 20,
    paddingBottom: 48,
  },
  tableHeader: {
    flexDirection: "row",
    paddingVertical: 16,
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#EAECF0",
    backgroundColor: "#F9FAFB",
    alignItems: "center",
  },
  headerText: {
    flex: 1,
    fontSize: 14,
    fontWeight: 700,
    color: "#344054",
  },
  tableRow: {
    flexDirection: "row",
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#EAECF0",
    paddingLeft: 16,
  },
  cellText: {
    flex: 1,
    fontSize: 14,
    color: "#344054",
  },
});

export default ActivityInsights;
