import * as React from "react";
import Svg, { G, Path, Defs, LinearGradient, Stop, ClipPath } from "react-native-svg";
import { v4 as uuidv4 } from "uuid";

function JessupOnlineLogo(props) {
  const id1 = uuidv4();
  const id2 = uuidv4();
  const id3 = uuidv4();
  const id4 = uuidv4();
  const id5 = uuidv4();
  const id6 = uuidv4();
  const id7 = uuidv4();
  const id8 = uuidv4();
  const id9 = uuidv4();
  const id10 = uuidv4();

  return (
    <Svg width={210} height={32} viewBox="0 0 249 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={`url(#${id1})`}>
        <Path d="M240.234 36.972V.962H249V36.97h-8.766v.003z" fill={`url(#${id2})`} />
        <Path
          d="M209.272 22.936v-6.843h-5.603v6.843h-6.953v5.459h6.987v8.58h5.602v-8.58h4.59l2.173-5.46h-6.796z"
          fill={`url(#${id3})`}
        />
        <Path
          d="M222.248.962h-8.462l-5.046 12.015h3.626v6.841h2.091l3.409-9.241 4.484 12.359h-3.578l-2.178 5.458h7.56l3.394 8.578h9.682L222.248.962z"
          fill={`url(#${id4})`}
        />
        <Path
          d="M46.147 22.049h14.861v-6.912H46.147V8.378h15.722V1.113H37.81V36.83h24.97v-7.365H46.147V22.05z"
          fill={`url(#${id5})`}
        />
        <Path
          d="M77.689 8.05a4.47 4.47 0 011.592-.58c.59-.1 1.17-.15 1.744-.15 1.112 0 2.266.277 3.463.832 1.196.555 2.163 1.303 2.907 2.244l5.509-5.803c-1.584-1.444-3.43-2.54-5.536-3.278-2.107-.739-4.17-1.11-6.192-1.11-1.686 0-3.354.211-5.004.63-1.65.423-3.134 1.086-4.448 1.994a10.548 10.548 0 00-3.186 3.48c-.81 1.412-1.211 3.113-1.211 5.094 0 1.614.26 2.986.782 4.11.523 1.129 1.212 2.088 2.073 2.875a11.24 11.24 0 002.931 1.942c1.094.504 2.216.941 3.36 1.312 1.18.37 2.234.715 3.159 1.034.927.32 1.7.673 2.323 1.059.623.386 1.103.817 1.442 1.288.335.47.504 1.043.504 1.716 0 .705-.135 1.312-.404 1.815a3.266 3.266 0 01-1.088 1.21 4.726 4.726 0 01-1.593.657 8.546 8.546 0 01-1.87.202c-1.415 0-2.84-.362-4.27-1.085-1.433-.724-2.587-1.623-3.463-2.7l-5.66 5.752c1.583 1.646 3.572 2.916 5.965 3.809a21.15 21.15 0 007.43 1.336 18.52 18.52 0 005.105-.706c1.65-.47 3.115-1.194 4.396-2.169 1.282-.974 2.3-2.21 3.058-3.706.759-1.496 1.137-3.27 1.137-5.323 0-1.782-.354-3.27-1.061-4.463a10.16 10.16 0 00-2.68-3.001 14.191 14.191 0 00-3.59-1.943 115.898 115.898 0 00-3.793-1.336 50.892 50.892 0 01-2.275-.78 10.006 10.006 0 01-1.744-.833c-.49-.302-.858-.655-1.112-1.059-.254-.404-.377-.907-.377-1.514 0-.705.16-1.287.48-1.74a3.93 3.93 0 011.188-1.11l.009-.003z"
          fill={`url(#${id6})`}
        />
        <Path
          d="M107.56 8.05a4.466 4.466 0 011.592-.58c.589-.1 1.17-.15 1.744-.15 1.112 0 2.266.277 3.463.832 1.196.555 2.163 1.303 2.907 2.244l5.509-5.803c-1.584-1.444-3.43-2.54-5.536-3.278-2.107-.739-4.171-1.11-6.192-1.11-1.686 0-3.354.211-5.004.63-1.65.423-3.134 1.086-4.449 1.994a10.553 10.553 0 00-3.185 3.48c-.81 1.412-1.211 3.113-1.211 5.094 0 1.614.26 2.986.782 4.11.523 1.129 1.212 2.088 2.073 2.875a11.25 11.25 0 002.931 1.942c1.094.504 2.215.941 3.361 1.312 1.178.37 2.233.715 3.158 1.034.927.32 1.701.673 2.323 1.059.623.386 1.103.817 1.442 1.288.335.47.504 1.043.504 1.716 0 .705-.136 1.312-.404 1.815a3.269 3.269 0 01-1.088 1.21 4.73 4.73 0 01-1.593.657 8.542 8.542 0 01-1.87.202c-1.415 0-2.841-.362-4.27-1.085-1.433-.724-2.587-1.623-3.463-2.7l-5.66 5.752c1.583 1.646 3.572 2.916 5.965 3.809a21.149 21.149 0 007.431 1.336c1.752 0 3.454-.236 5.104-.706 1.649-.47 3.115-1.194 4.396-2.169 1.282-.974 2.3-2.21 3.058-3.706.759-1.496 1.137-3.27 1.137-5.323 0-1.782-.354-3.27-1.061-4.463a10.168 10.168 0 00-2.68-3.001 14.203 14.203 0 00-3.59-1.943 116.165 116.165 0 00-3.793-1.336 50.944 50.944 0 01-2.275-.78 10.018 10.018 0 01-1.744-.833c-.489-.302-.858-.655-1.112-1.059-.254-.404-.378-.907-.378-1.514 0-.705.161-1.287.481-1.74.32-.455.716-.823 1.187-1.11l.01-.003z"
          fill={`url(#${id7})`}
        />
        <Path
          d="M149.914 22.655c0 .977-.118 1.9-.354 2.775a6.506 6.506 0 01-1.163 2.346c-.541.69-1.239 1.237-2.097 1.64-.859.405-1.895.607-3.11.607-1.215 0-2.248-.202-3.109-.606-.859-.404-1.551-.95-2.073-1.64a7.116 7.116 0 01-1.164-2.347 9.89 9.89 0 01-.378-2.775V1.113h-8.591v22.245c0 2.153.354 4.11 1.061 5.878.707 1.764 1.719 3.278 3.034 4.539 1.314 1.26 2.913 2.244 4.802 2.95 1.885.705 4.01 1.058 6.367 1.058s4.439-.353 6.343-1.059c1.903-.705 3.529-1.689 4.877-2.95 1.348-1.26 2.384-2.774 3.109-4.538.726-1.764 1.088-3.725 1.088-5.878V1.113h-8.642V22.655z"
          fill={`url(#${id8})`}
        />
        <Path
          d="M191.588 6.94a9.065 9.065 0 00-2.983-3.381c-1.263-.857-2.746-1.481-4.448-1.867-1.701-.386-3.529-.58-5.484-.58h-13.142v35.715h8.642v-13.47h4.297a23.34 23.34 0 005.358-.605c1.719-.404 3.236-1.044 4.548-1.918 1.314-.875 2.357-2.027 3.133-3.456.774-1.43 1.164-3.185 1.164-5.272s-.363-3.776-1.088-5.17l.003.004zm-8.062 7.316a3.795 3.795 0 01-1.39 1.336 6.16 6.16 0 01-1.998.706c-.74.136-1.517.202-2.324.202h-3.638V8.076h3.793c.773 0 1.523.052 2.248.151.725.1 1.372.302 1.946.606a3.529 3.529 0 011.366 1.261c.335.54.504 1.228.504 2.069s-.169 1.538-.504 2.093h-.003z"
          fill={`url(#${id9})`}
        />
        <Path
          d="M22.002 22.622c0 .974-.118 1.897-.354 2.772a6.508 6.508 0 01-1.16 2.343c-.538.688-1.236 1.234-2.094 1.638-.858.404-1.895.603-3.103.603-1.21 0-2.246-.202-3.104-.603-.858-.404-1.547-.947-2.07-1.638a7.038 7.038 0 01-1.16-2.343 9.744 9.744 0 01-.32-1.698H.002C.043 25.7.39 27.538 1.055 29.2c.707 1.764 1.716 3.275 3.028 4.533 1.311 1.257 2.91 2.24 4.795 2.946 1.886.706 4.004 1.059 6.361 1.059s4.433-.353 6.337-1.059c1.9-.706 3.523-1.689 4.871-2.947a12.778 12.778 0 003.104-4.532c.722-1.765 1.085-3.719 1.085-5.87V1.11h-8.63v21.512h-.004z"
          fill={`url(#${id10})`}
        />
      </G>
      <Defs>
        <LinearGradient id={id2} x1={166.788} y1={59.9706} x2={265.888} y2={7.55573} gradientUnits="userSpaceOnUse">
          <Stop offset={0.28} stopColor="#5F475B" />
          <Stop offset={0.36} stopColor="#594C61" />
          <Stop offset={0.47} stopColor="#4B5B74" />
          <Stop offset={0.61} stopColor="#347392" />
          <Stop offset={0.76} stopColor="#1595BC" />
          <Stop offset={0.85} stopColor="#00ACD9" />
        </LinearGradient>
        <LinearGradient id={id3} x1={160.294} y1={50.8687} x2={260.644} y2={-2.2087} gradientUnits="userSpaceOnUse">
          <Stop offset={0.35} stopColor="#5F475B" />
          <Stop offset={0.38} stopColor="#46607A" />
          <Stop offset={0.44} stopColor="#2089AD" />
          <Stop offset={0.48} stopColor="#08A2CD" />
          <Stop offset={0.5} stopColor="#00ACD9" />
        </LinearGradient>
        <LinearGradient id={id4} x1={168.435} y1={49.0351} x2={263.993} y2={-1.50716} gradientUnits="userSpaceOnUse">
          <Stop offset={0.49} stopColor="#5F475B" />
          <Stop offset={0.53} stopColor="#426581" />
          <Stop offset={0.58} stopColor="#2583A6" />
          <Stop offset={0.63} stopColor="#1199C2" />
          <Stop offset={0.67} stopColor="#04A7D3" />
          <Stop offset={0.7} stopColor="#00ACD9" />
        </LinearGradient>
        <LinearGradient id={id5} x1={337.362} y1={158.798} x2={0.132612} y2={-5.85142} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <LinearGradient id={id6} x1={343.091} y1={147.057} x2={5.86505} y2={-17.5892} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <LinearGradient id={id7} x1={348.824} y1={135.319} x2={11.5975} y2={-29.327} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <LinearGradient id={id8} x1={356.136} y1={120.342} x2={18.9073} y2={-44.3038} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <LinearGradient id={id9} x1={361.316} y1={109.733} x2={24.0898} y2={-54.9165} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <LinearGradient id={id10} x1={331.602} y1={170.59} x2={-5.62401} y2={5.9437} gradientUnits="userSpaceOnUse">
          <Stop offset={0.48} stopColor="#5F475B" />
          <Stop offset={0.76} stopColor="#208AAF" />
          <Stop offset={0.92} stopColor="#01ACDA" />
        </LinearGradient>
        <ClipPath id={id1}>
          <Path fill="#fff" d="M0 0H249V38H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default JessupOnlineLogo;
