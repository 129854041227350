import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Degree({ fill = "#3E68FE", ...props }) {
  return (
    <Svg width={34} height={30} viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.167.5a3 3 0 00-3 3V20a3 3 0 003 3h10.5a1.5 1.5 0 100-3h-10.5V3.5h24v4.65a7.5 7.5 0 00-6 13.35v7.19a.75.75 0 001.28.53l2.69-2.69a.75.75 0 011.06 0l2.69 2.69a.75.75 0 001.28-.53V21.5a7.488 7.488 0 003-6 7.489 7.489 0 00-3-6v-6a3 3 0 00-3-3h-24zm22.5 10.5a4.502 4.502 0 010 9 4.5 4.5 0 010-9z"
        fill={fill}
      />
      <Path
        d="M7.667 6.5a1.5 1.5 0 100 3h9a1.5 1.5 0 100-3h-9zM6.167 14a1.5 1.5 0 011.5-1.5h4.5a1.5 1.5 0 110 3h-4.5a1.5 1.5 0 01-1.5-1.5z"
        fill={fill}
      />
    </Svg>
  );
}
export default Degree;
