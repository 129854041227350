import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
// import VideoPlay from "../../svgs/common/VideoPlay";

function AxioResourceCard({ title, description, videoUrl, files, isHovered, onPress, onHoverIn, onHoverOut }) {
  const getResourceTypeText = () => {
    const hasVideo = !!videoUrl;
    const hasFiles = files && files.length > 0;

    if (hasVideo && hasFiles) return "Files & Video";
    if (hasVideo) return "Video";
    if (hasFiles) return "Files";
    return null;
  };

  const resourceType = getResourceTypeText();

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      style={({ pressed }) => [
        styles.resourceItem,
        isHovered && styles.resourceItemHovered,
        pressed && styles.resourceItemPressed,
      ]}>
      <View style={styles.resourceItemLeft}>
        {/* <VideoPlay size={24} color="#667085" /> */}
        <View style={styles.textContainer}>
          <CustomText text={title} weight="bold" style={styles.resourceItemTitle} useTranslationText={false} />
          {resourceType && (
            <CustomText
              text={resourceType}
              style={styles.resourceItemDescription}
              useTranslationText={false}
              numberOfLines={1}
            />
          )}
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  resourceItem: {
    marginVertical: 8,
    marginHorizontal: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 24,
    height: 75,
    borderWidth: 1,
    borderColor: "#EAECF0",
    borderRadius: 16,
    outlineWidth: 0,
    outlineColor: "transparent",
    outlineStyle: "solid",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  resourceItemHovered: {
    outlineWidth: 2,
    outlineColor: "#D0D5DD",
    outlineStyle: "solid",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  resourceItemPressed: {
    backgroundColor: "#F5F5F5",
  },
  resourceItemLeft: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  textContainer: {
    flex: 1,
  },
  resourceItemTitle: {
    marginBottom: 4,
  },
  resourceItemDescription: {
    fontSize: 12,
    lineHeight: 16,
    color: "#667085",
    marginTop: 2,
  },
});

export default AxioResourceCard;
