import { useNavigation, useRoute } from "@react-navigation/native";
import {
  GetCourseByIdDocument,
  useCreateAiTutorMutation,
  useCreatePageMutation,
  useDeleteModuleCascadeMutation,
  useGetCourseByIdQuery,
  useUpdateModuleMutation,
} from "../../../graphql/generated/graphql";
import { useState } from "react";
import { Alert, Text, View } from "react-native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import Sentry from "../../../utils/sentry";
import CustomCourseWorkContentMenu from "../../../components/navigation/CustomCourseWorkContentMenu/CustomCourseWorkContentMenu";

function CustomCourseworkMenuContainer({
  onSelectModule,
  onSelectActivity,
  selectedModule,
  selectedActivity,
  onAddModule,
}) {
  const [editAiTutorOrPageModalVisible, setEditAiTutorOrPageModalVisible] = useState(null);
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = useState(false);
  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = useState(false);

  const navigation = useNavigation();

  const { dispatch } = useAppState();

  const route = useRoute();
  const courseId = route.params?.courseId;

  const { data: courseData, loading } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });
  // console.log("courseData here: ", JSON.stringify(courseData?.getCourseById?.data, null, 2));

  const data = courseData?.getCourseById?.data[0];

  const selectedCourse = courseData?.getCourseById?.data[0];

  const [deleteModuleMutation] = useDeleteModuleCascadeMutation({
    refetchQueries: [
      {
        query: GetCourseByIdDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [updateModuleMutation] = useUpdateModuleMutation({
    refetchQueries: [
      {
        query: GetCourseByIdDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [createAiTutorMutation] = useCreateAiTutorMutation({
    refetchQueries: [
      {
        query: GetCourseByIdDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [createPageMutation] = useCreatePageMutation({
    refetchQueries: [
      {
        query: GetCourseByIdDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  if (loading)
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );

  const handleItemPress = (name, moduleName, _, type) => {
    const module = selectedCourse.modules.find((mod) => mod.title === moduleName);
    if (!module) {
      console.error(`Module with name "${moduleName}" not found.`);
      return;
    }

    let aiTutor;
    let page;

    if (type === "AiTutor") {
      aiTutor = module.aiTutors.find((assign) => assign.title === name);
      if (!aiTutor) {
        console.error(`AiTutor with name "${name}" not found in module "${moduleName}".`);
        return;
      }
    } else {
      page = module.pages.find((p) => p.title === name);
      if (!page) {
        console.error(`Page with name "${name}" not found in module "${moduleName}".`);
        return;
      }
    }

    navigation.navigate("Custom Coursework Modules", {
      courseId: selectedCourse.id,
      moduleIndex: selectedCourse.modules.indexOf(module),
      ...(type === "AiTutor" && { aiTutorIndex: module.aiTutors.indexOf(aiTutor) }),
      ...(type === "Page" && { pageIndex: module.pages.indexOf(page) }),
    });
  };

  const handleRenameModule = async (_, newModuleName, order, moduleId) => {
    try {
      const response = await updateModuleMutation({
        variables: {
          id: moduleId,
          title: newModuleName,
          order: parseInt(order, 10),
        },
      });

      if (response?.data.updateModule.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setEditModuleNameModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to update module");
        Sentry.captureException(response?.data?.updateContentModuleName?.message);
        console.error("Failed to update module:", response?.data?.updateContentModuleName?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error("Error updating module name:", e);
    }
  };

  const handleDeleteModule = async (_, moduleId) => {
    try {
      const response = await deleteModuleMutation({
        variables: {
          id: moduleId,
        },
      });

      if (response?.data?.deleteModuleCascade?.success) {
        Alert.alert("Success", "Module deleted successfully");
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setDeleteModuleModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to delete module");
        Sentry.captureException(response.data?.deleteContentModule.message);
        Alert.alert("Error", response.data?.deleteContentModule.message || "Failed to delete module");
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", "An error occurred while deleting the module.");
    }
  };

  const handleAddAiTutorOrPage = async (type, html, newName, moduleName, moduleId, objectives) => {
    const cleanedHtml = html?.replace(/\n\s+/g, "").trim();
    const encodedHtml = encodeURIComponent(cleanedHtml);
    try {
      if (type === "Page") {
        await createPageMutation({
          variables: {
            title: newName,
            body: encodedHtml,
            parentModuleId: moduleId,
          },
        });
      } else {
        await createAiTutorMutation({
          variables: {
            title: newName,
            description: encodedHtml,
            parentModuleId: moduleId,
            objectives,
          },
        });
      }

      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: true },
      });
      setEditAiTutorOrPageModalVisible(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(`Error adding ${type}`, error);
    }
  };

  return (
    <>
      {selectedCourse ? (
        <CustomCourseWorkContentMenu
          data={data}
          onSelectModule={onSelectModule}
          onSelectActivity={onSelectActivity}
          selectedModule={selectedModule}
          selectedActivity={selectedActivity}
          onAddModule={onAddModule}
          onItemPress={handleItemPress}
          onRenameModule={handleRenameModule}
          onDeleteModule={handleDeleteModule}
          onAddAiTutorOrPage={handleAddAiTutorOrPage}
          editAiTutorOrPageModalState={[editAiTutorOrPageModalVisible, setEditAiTutorOrPageModalVisible]}
          deleteModuleModalState={[deleteModuleModalVisible, setDeleteModuleModalVisible]}
          editModuleNameModalState={[editModuleNameModalVisible, setEditModuleNameModalVisible]}
        />
      ) : (
        <View>
          <Text>No courses available</Text>
        </View>
      )}
    </>
  );
}

export default CustomCourseworkMenuContainer;
