import { StoragePathType, useGenerateUploadUrlMutation } from "../graphql/generated/graphql";
import * as DocumentPicker from "expo-document-picker";
import axios from "axios";
import { StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { Cloud } from "../components/svgs/CustomCoursework";

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_MIME_TYPES = ["application/pdf"];

type useFileUploadProps = {
  beforeUpload?: () => void;
  storagePath: StoragePathType;
  afterUpload?: (data: {
    id: string;
    fileName: string;
    fileURL: string;
    mimeType: string;
    size: number;
    assetName: string;
  }) => void;
  onError?: () => void;
};

const useFileUpload = ({
  beforeUpload = () => {},
  storagePath,
  afterUpload = ({ id, fileName, fileURL, mimeType, size, assetName }) => {},
  onError = () => {},
}: useFileUploadProps) => {
  const [generateUploadUrl] = useGenerateUploadUrlMutation();

  const handleFileUpload = async () => {
    await beforeUpload();
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: ACCEPTED_MIME_TYPES,
        copyToCacheDirectory: true,
      });

      if (result.canceled || !result.assets || !result.assets[0]) {
        return;
      }

      const { uri, mimeType, size, name } = result.assets[0];

      // Validate file size
      if (size > MAX_FILE_SIZE) {
        throw new Error(`File size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
      }

      // Validate mime type
      if (!ACCEPTED_MIME_TYPES.includes(mimeType)) {
        throw new Error(`Invalid file type. Only PDF files are accepted.`);
      }

      const data = await generateUploadUrl({
        variables: {
          fileName: name,
          storagePath: storagePath,
        },
      });
      console.log("data", data);

      const uploadData = data?.data?.generateUploadUrl?.data ?? [];
      const { id, fileName, fileURL } = uploadData[0] || {};

      if (!fileURL) throw new Error("Failed to get signed URL");

      // Upload file to GCP using signed URL
      const fileData = await fetch(uri);
      const blob = await fileData.blob();

      await axios.put(fileURL, blob, {
        headers: {
          "Content-Type": mimeType,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload progress: ${percentCompleted}%`);
        },
      });

      console.log("File uploaded successfully!");

      await afterUpload({ id, fileName, fileURL, mimeType, size, assetName: name });
    } catch (error) {
      console.error("Error uploading file:", error);
      onError();
      // You may want to show an alert here
    }
  };

  const renderFileUpload = () => (
    <TouchableOpacity onPress={handleFileUpload}>
      <View style={styles.fileUpload}>
        <Cloud />
        <CustomSpacing type="vertical" size="s" />
        <CustomText text="Drag & drop or browse" size="m" style={{ color: "#344054" }} />
        <CustomText text="Max file size: 2MB" size="xs" style={{ color: "#5177FE" }} />
      </View>
    </TouchableOpacity>
  );

  return { renderFileUpload, handleFileUpload };
};

export default useFileUpload;

const styles = StyleSheet.create({
  fileUpload: {
    borderWidth: 1,
    borderColor: "#6788FE",
    borderRadius: 8,
    borderStyle: "dashed",
    padding: 16,
    alignItems: "center",
    backgroundColor: "#F2F5FF",
  },
});
