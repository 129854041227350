import { Dimensions, View } from "react-native";

import { StyleSheet } from "react-native";
import WebView from "react-native-webview";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";
import { useEffect, useState } from "react";

const calculateHeight = (width) => {
  return (width * 9) / 16;
};

const SIDEBAR_WIDTH = 400;

const YoutubeVideoPlayer = ({ id }) => {
  const { isMedium } = useResponsiveScreen();
  const [height, setHeight] = useState(calculateHeight(Dimensions.get("window").width));

  useEffect(() => {
    const onChange = ({ window }) => {
      setHeight(calculateHeight(window.width - (!isMedium ? 0 : SIDEBAR_WIDTH)));
    };

    const subscription = Dimensions.addEventListener("change", onChange);

    return () => {
      subscription?.remove();
    };
  }, []);

  return (
    <View style={!isMedium ? styles.mobileVideoContainer : styles.videoContainer}>
      <WebView
        style={{ height: height, width: "100%", paddingHorizontal: 10 }}
        source={{
          uri: "https://www.youtube.com/embed/" + id,
        }}
      />
    </View>
  );
};

export default YoutubeVideoPlayer;

const styles = StyleSheet.create({
  videoContainer: {
    width: "48%",
    aspectRatio: 16 / 9,
    paddingHorizontal: 10,
  },
  mobileVideoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
});
