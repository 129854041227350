import React, { useRef, useEffect, useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { RouteProp, useFocusEffect, useRoute, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import Spline from "@splinetool/react-spline";
import Loader from "../components/common/status/Loader/Loader";
import "@livekit/components-styles";
import {
  LiveKitRoom,
  useVoiceAssistant,
  useAudioWaveform,
  useTrackTranscription,
  RoomAudioRenderer,
  VoiceAssistantControlBar,
} from "@livekit/components-react";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import BlankLayout from "../layouts/BlankLayout";
import AssessmentMenuContainer from "../containers/navigation/AssessmentMenuContainer/AssessmentMenuContainer";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import AssessmentBeginModal from "../containers/assessment/AssessmentBeginModal.js";
import { useGetAssessmentQuery, useGetCourseByIdQuery } from "../graphql/generated/graphql";
import useWebSocket from "../hooks/useWebSocket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AssessmentCompleteModal from "../containers/assessment/AssessmentCompleteModal";
import ExitBeforeCompletedModal from "../containers/assessment/ExitBeforeCompletedModal";
import CursorLeavesWindowModal from "../containers/assessment/CursorLeavesWindowModal";
import { actions, useAppState } from "../contexts/AppStateContext";
import AssessmentInProgressModal from "../containers/assessment/AssessmentInProgressModal";
import { Application } from "@splinetool/runtime";

const DEFAULT_ASSESSMENT_TIME_IN_MINUTES = 25;
const DEFAULT_QUESTIONS_COUNT = 12;

function SimpleVoiceAssistant() {
  const { state, audioTrack } = useVoiceAssistant();
  const { segments } = useTrackTranscription(audioTrack);

  return (
    <View style={styles.transcriptContainer}>
      <ScrollView style={styles.scrollView}>
        {segments.length > 0 && (
          <CustomText
            text={segments[segments.length - 1].text}
            size="xl"
            style={styles.transcriptText}
            useTranslationText={false}
          />
        )}
      </ScrollView>
    </View>
  );
}

function OrbController({ orb }) {
  const { audioTrack } = useVoiceAssistant();
  const { bars } = useAudioWaveform(audioTrack, {
    barCount: 20,
    updateInterval: 50,
    volMultiplier: 1,
  });

  // Compute an average amplitude from the waveform bars.
  const averageAmplitude = bars.length > 0 ? bars.reduce((acc, val) => acc + val, 0) / bars.length : 0;

  // Calculate the target scale from the average amplitude.
  const targetScale = 0.5 + averageAmplitude;

  // Use refs to store the target scale and the smoothed scale.
  const targetScaleRef = useRef(targetScale);
  const smoothedScaleRef = useRef(0.5);

  // Update the target scale ref when the target scale changes.
  useEffect(() => {
    targetScaleRef.current = targetScale;
  }, [targetScale]);

  // Use requestAnimationFrame to smoothly update the orb's scale every frame.
  useEffect(() => {
    let animationFrameId: number;
    const alpha = 0.2;

    const animate = () => {
      // Exponential smoothing: interpolate between the current scale and the target.
      smoothedScaleRef.current = alpha * targetScaleRef.current + (1 - alpha) * smoothedScaleRef.current;

      // Update the Spline object's scale.
      if (orb.current) {
        orb.current.scale.x = smoothedScaleRef.current;
        orb.current.scale.y = smoothedScaleRef.current;
        orb.current.scale.z = smoothedScaleRef.current;
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();
    return () => cancelAnimationFrame(animationFrameId);
  }, [orb]);

  return null;
}

function CustomCourseAssessmentStudentView() {
  const posthog = usePostHog();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Custom Course Assessment">>();
  const { assessment_id: assessmentId, course_id: courseId, integration_id: integrationId } = route.params;
  const { sendMessage } = useWebSocket();
  const { state, dispatch } = useAppState();

  const [beginModalVisible, setBeginModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [exitModalVisible, setExitModalVisible] = useState(false);
  const [assessmentInProgressModalVisible, setAssessmentInProgressModalVisible] = useState(false);
  const [cursorLeavesWindowModalVisible, setCursorLeavesWindowModalVisible] = useState(false);
  const [token, setToken] = useState("");
  const [orbLoading, setOrbLoading] = useState(true);
  const orb = useRef(null);

  const { refetch } = useGetCourseByIdQuery({
    variables: {
      id: courseId,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await axios.post(
        "/livekit/token",
        {
          assessmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setToken(data.data.token);
    };
    fetchData();
  }, []);

  const wsURL = "wss://axio-1b37rue0.livekit.cloud";

  const remainingTime = state?.assessment?.assessmentRemainingTime;
  const activeAssessmentId = state?.assessment?.assessmentId;
  const loadingAssessmentResponse = state?.assessment?.loading;
  const assessmentIsRunning =
    remainingTime && remainingTime !== "00:00" && !!activeAssessmentId && activeAssessmentId === assessmentId;

  const {
    data: assessment,
    loading: assessmentLoading,
    error: assessmentError,
  } = useGetAssessmentQuery({
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });
  const assessmentIsEnabled = assessment?.getAssessment?.data[0]?.isEnabled;

  const assessmentProperties = assessment?.getAssessment?.data[0]?.userHasAssessmentConnection?.edges?.[0]?.properties;
  const assessmentNotStarted = !assessmentProperties?.isCompleted && !assessmentProperties?.remainingTime;

  useEffect(() => {
    posthog?.capture("Assessment Page Visited");
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (assessmentLoading || loadingAssessmentResponse) {
        return;
      }

      if (assessmentNotStarted && !assessmentIsRunning && remainingTime !== "00:00") {
        setBeginModalVisible(true);
      }
    }, [assessmentNotStarted, assessmentId, assessmentIsRunning, assessmentLoading, loadingAssessmentResponse])
  );

  const handleVisibilityChange = useCallback(() => {
    if (!assessmentIsRunning) {
      return;
    }
    if (document.hidden) {
      setCursorLeavesWindowModalVisible(true);
    }
  }, [assessmentIsRunning]);

  const handleMouseLeave = useCallback(
    (event) => {
      if (!assessmentIsRunning) {
        return;
      }
      const { clientX, clientY } = event;

      const leftEdge = clientX <= 0;
      const rightEdge = clientX >= window.innerWidth;
      const topEdge = clientY <= 0;
      const bottomEdge = clientY >= window.innerHeight;

      if (leftEdge || rightEdge || topEdge || bottomEdge) {
        setCursorLeavesWindowModalVisible(true);
      }
    },
    [assessmentIsRunning]
  );

  useFocusEffect(
    useCallback(() => {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      document.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        document.removeEventListener("mouseleave", handleMouseLeave);
      };
    }, [assessmentIsRunning])
  );

  useEffect(() => {
    if (activeAssessmentId && activeAssessmentId !== assessmentId) {
      setBeginModalVisible(false);
      setAssessmentInProgressModalVisible(true);
    }
  }, [activeAssessmentId, assessmentId]);

  if (assessmentLoading) {
    return (
      <BlankLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </BlankLayout>
    );
  }
  if (assessmentError || !assessmentIsEnabled) {
    return (
      <BlankLayout>
        <View style={styles.loaderContainer}>
          {assessmentError ? (
            <ErrorLoader message="Something went wrong! Try reloading the page." />
          ) : (
            <ErrorLoader message="This assessment is not enabled." />
          )}
        </View>
      </BlankLayout>
    );
  }

  const handleExitPage = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const handleBeginAssessment = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: { token: token, assessmentId, action: "TIMER_START" },
    });
    setBeginModalVisible(false);
  };

  const handleCancelAssessmentBegin = () => {
    setBeginModalVisible(false);
    handleExitPage();
  };

  const handleConfirmCompleteModal = () => {
    setCompleteModalVisible(false);
    refetch();
    dispatch({
      type: actions.SET_ACTIVE_ASSESSMENT,
      payload: { assessmentId: null, assessmentRemainingTime: null },
    });
    handleExitPage();
  };

  const handleExitAssessment = async () => {
    const token = await AsyncStorage.getItem("token");
    setExitModalVisible(false);
    sendMessage({
      meta: { token: token, assessmentId, action: "TERMINATE_ASSESSMENT" },
    });
    refetch();
    handleExitPage();
  };

  const handleAssessmentInProgressModalClick = () => {
    setAssessmentInProgressModalVisible(false);
    handleExitPage();
  };

  const handleExitButtonPress = () => {
    if (assessmentIsRunning) {
      setExitModalVisible(true);
    } else {
      handleExitPage();
    }
  };

  const onLoad = (spline: Application) => {
    setOrbLoading(false);
    const obj = spline.findObjectById("02f9e61e-081c-484d-8258-c53281906a3f");
    orb.current = obj;
  };

  return (
    <BlankLayout>
      <View style={styles.pageContainer}>
        <AssessmentMenuContainer
          onComplete={() => setCompleteModalVisible(true)}
          handleExitPress={handleExitButtonPress}
          defaultTime={DEFAULT_ASSESSMENT_TIME_IN_MINUTES}
        />
        <View style={styles.contentContainer}>
          {token ? (
            // Place LiveKitRoom here so that voice assistant hooks are available.
            <LiveKitRoom style={styles.liveKitContainer} token={token} serverUrl={wsURL} connect={true} audio={true}>
              <View style={styles.orbContainer}>
                {orbLoading && <Loader />}
                <Spline scene="https://prod.spline.design/eGVArbV9JzcVNsir/scene.splinecode" onLoad={onLoad} />
                <OrbController orb={orb} />
              </View>
              <SimpleVoiceAssistant />
              <VoiceAssistantControlBar />
              <RoomAudioRenderer />
            </LiveKitRoom>
          ) : (
            <Loader />
          )}
        </View>
      </View>
      <AssessmentBeginModal
        visible={beginModalVisible}
        onCancel={handleCancelAssessmentBegin}
        onConfirm={handleBeginAssessment}
        defaultTime={DEFAULT_ASSESSMENT_TIME_IN_MINUTES}
        defaultQuestionsCount={DEFAULT_QUESTIONS_COUNT}
      />
      <AssessmentCompleteModal visible={completeModalVisible} onConfirm={handleConfirmCompleteModal} />
      <ExitBeforeCompletedModal
        visible={exitModalVisible}
        onConfirm={handleExitAssessment}
        onCancel={() => setExitModalVisible(false)}
      />
      <CursorLeavesWindowModal
        visible={cursorLeavesWindowModalVisible}
        onConfirm={() => setCursorLeavesWindowModalVisible(false)}
      />
      <AssessmentInProgressModal
        visible={assessmentInProgressModalVisible}
        onConfirm={handleAssessmentInProgressModalClick}
      />
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 20,
    backgroundColor: "#E1E5FA",
  },
  liveKitContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  transcriptContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  orbContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: 450,
  },
  transcriptText: {
    width: 425,
    textAlign: "center",
    color: "#667085",
  },
  scrollView: {
    height: 20,
  },
});

export default CustomCourseAssessmentStudentView;
