import { useEffect, useState } from "react";
import { GradingData } from "../../../views/TeacherGradingView";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import { Alert, StyleSheet, View } from "react-native";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomAccordion from "../../../components/common/general/CustomAccordion/CustomAccordion";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";

interface StudentResponse {
  name: string;
  attempt: number;
  dateTaken: string;
  totalScore: number;
  timeSpent: number;
  questions: {
    id: number;
    question: string;
    answer: string;
    score: number;
    maxScore: number;
  }[];
}

const AssessmentGradingModalContainer = ({
  isVisible,
  onClose,
  gradingData,
  onSave,
  isSubmitting,
}: {
  isVisible: boolean;
  onClose: () => void;
  gradingData: GradingData | null;
  onSave: (gradingData: GradingData, newScore: number) => Promise<void>;
  isSubmitting: boolean;
}) => {
  const [grade, setGrade] = useState<string>("");

  // TODO: Get Assessment Data and populate the modal content with the data.

  useEffect(() => {
    if (gradingData?.grade) {
      setGrade(gradingData.grade.toString());
    }
  }, [gradingData]);

  const handleSave = async () => {
    const newScore = Number(grade);

    if (newScore < 0 || newScore > 100) {
      Alert.alert("Invalid score", "Please enter a score between 0 and 100");
      return;
    }

    if (Number.isNaN(newScore)) {
      Alert.alert("Invalid score", "Please enter a valid number");
      return;
    }

    await onSave(gradingData, newScore);
  };

  // Mock data to match the design
  const studentResponse: StudentResponse = {
    name: gradingData?.userName || "",
    attempt: 1,
    dateTaken: gradingData?.created || "",
    totalScore: gradingData?.grade || 0,
    timeSpent: 23,
    questions: [
      {
        id: 1,
        question: "How do cellular processes for energy production impact ecosystems?",
        answer:
          "Cellular respiration and photosynthesis are key processes that drive energy flow through ecosystems... lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        score: 8,
        maxScore: 10,
      },
      {
        id: 2,
        question: "How do cellular processes for energy production impact ecosystems?",
        answer:
          "The efficiency of cellular energy production affects organism survival and ecosystem stability... lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        score: 8,
        maxScore: 10,
      },
      {
        id: 3,
        question: "How do cellular processes for energy production impact ecosystems?",
        answer:
          "Energy transfer between trophic levels is limited by cellular efficiency... lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        score: 8,
        maxScore: 10,
      },
    ],
  };

  return (
    <CustomModal
      visible={isVisible}
      onRequestClose={onClose}
      title="Assessment Responses"
      showCloseButton
      style={styles.modalContent}
      exitFill="#6B7280"
      onShow={() => {}}
      ariaLabel="Assessment Responses Modal">
      <CustomText
        text="Review the student's responses, how they were graded, and any manual adjustments made."
        size="m"
        style={{ color: "#6B7280", marginBottom: 24 }}
        useTranslationText={false}
      />

      <View style={styles.studentInfo}>
        <View style={styles.infoItem}>
          <CustomText text="Name:" size="s" weight="bold" useTranslationText={false} />
          <CustomText text={studentResponse.name} size="s" useTranslationText={false} />
        </View>
        <View style={styles.infoItem}>
          <CustomText text="Attempt:" size="s" weight="bold" useTranslationText={false} />
          <CustomText text={studentResponse.attempt.toString()} size="s" useTranslationText={false} />
        </View>
        <View style={styles.infoItem}>
          <CustomText text="Date Taken:" size="s" weight="bold" useTranslationText={false} />
          <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
            <CustomText
              text={new Date(studentResponse.dateTaken).toLocaleDateString()}
              size="s"
              useTranslationText={false}
            />
            <CustomText text="at" size="s" useTranslationText={false} />
            <CustomText
              text={new Date(studentResponse.dateTaken).toLocaleTimeString()}
              size="s"
              useTranslationText={false}
            />
          </View>
        </View>
        <View style={styles.infoItem}>
          <CustomText text="Total Score:" size="s" weight="bold" useTranslationText={false} />
          <CustomTextInput
            value={grade}
            onChangeText={setGrade}
            placeholder="Enter total score"
            style={{ backgroundColor: "white", borderRadius: 8, paddingHorizontal: 6, paddingVertical: 4, width: 60 }}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.infoItem}>
          <CustomText text="Time Spent:" size="s" weight="bold" useTranslationText={false} />
          <CustomText text={`${studentResponse.timeSpent} min`} size="s" useTranslationText={false} />
        </View>
      </View>

      <View style={styles.questionsContainer}>
        {studentResponse.questions.map((question, index) => (
          <View
            key={index}
            style={{ paddingLeft: 16, paddingRight: 32, borderWidth: 1, borderColor: "#EAECF0", borderRadius: 16 }}>
            <CustomAccordion
              isExpanded={false}
              title={
                <View style={styles.accordionHeader}>
                  <CustomText
                    text={`Question ${question.id}: ${question.question}`}
                    size="s"
                    useTranslationText={false}
                    style={styles.questionText}
                  />
                  <View style={styles.accordionActions}>
                    <View style={styles.scorePill}>
                      <CustomText text={`${question.score}/${question.maxScore}`} size="s" useTranslationText={false} />
                    </View>
                  </View>
                </View>
              }>
              <View style={styles.answerSection}>
                <CustomText text="Student Response:" size="s" weight="bold" useTranslationText={false} />
                <CustomText text={question.answer} size="s" useTranslationText={false} />
              </View>
            </CustomAccordion>
          </View>
        ))}
      </View>

      <View style={styles.modalFooter}>
        <View style={styles.footerButtons}>
          <CustomButton text="Cancel" styleType="secondary" onPress={onClose} useTranslationText={false} />
          <CustomButton
            text="Save"
            styleType="primary"
            onPress={handleSave}
            useTranslationText={false}
            disabled={isSubmitting}
          />
        </View>
      </View>
    </CustomModal>
  );
};

export default AssessmentGradingModalContainer;

const styles = StyleSheet.create({
  modalContent: {
    maxHeight: "90%",
  },
  studentInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 8,
    marginBottom: 24,
    gap: 12,
  },
  infoItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  scorePill: {
    backgroundColor: "#E6F4EA",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 16,
  },
  questionsContainer: {
    gap: 16,
    width: "100%",
  },
  accordionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  questionText: {
    flex: 1,
    paddingRight: 16,
  },
  accordionActions: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  answerSection: {
    gap: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  modalFooter: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 16,
    marginTop: 24,
    paddingTop: 16,
  },
  footerButtons: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 16,
  },
});
