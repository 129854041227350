import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

interface AnalyticsFeedbackCardProps {
  title: string;
  description?: string;
  highlights: string[];
  icon: React.ReactNode;
  iconColor: string;
}

const AnalyticsFeedbackCard = ({ title, description, highlights, icon, iconColor }: AnalyticsFeedbackCardProps) => {
  const limitedHighlights = highlights.slice(0, 2);

  return (
    <View style={styles.card}>
      <View
        style={[styles.iconContainer, { backgroundColor: iconColor, alignItems: "center", justifyContent: "center" }]}>
        {icon}
      </View>

      <CustomText useTranslationText={false} textType="text" text={title} size="xl" style={styles.title} />
      {description && (
        <CustomText useTranslationText={false} textType="text" text={description} size="s" style={styles.description} />
      )}
      <View style={styles.highlightsContainer}>
        {limitedHighlights.map((highlight, index) => {
          return (
            <View key={index} style={styles.highlightBox}>
              <CustomText useTranslationText={false} textType="text" text={highlight} size="s" style={styles.text} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default AnalyticsFeedbackCard;

const styles = StyleSheet.create({
  iconContainer: {
    width: 57.573,
    height: 57.573,
    borderRadius: 57.573 / 2,
    marginBottom: 12,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 20,
    flex: 1,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#EAECF0",
    backgroundColor: "#FFF",
    minWidth: 250,
    maxWidth: "33%",
  },
  title: {
    marginTop: 12,
  },
  text: {
    color: "#667085",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  description: {
    color: "#667085",
    flexWrap: "wrap",
    maxWidth: "100%",
    marginBottom: 20,
    marginTop: 20,
  },
  highlightsContainer: {
    width: "100%",
    gap: 8,
  },
  highlightBox: {
    backgroundColor: "#F2F4F7",
    padding: 10,
    borderRadius: 8,
  },
});
