import React, { useState, useEffect } from "react";
import { View, StyleSheet, Modal } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomModal from "../../components/common/general/CustomModal/CustomModal";
import { ScrollView } from "moti";

const AssessmentBeginModal = ({ visible, onCancel, onConfirm, defaultTime, defaultQuestionsCount }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      role="dialog"
      aria-modal="true"
      aria-label="Begin Assessment Modal">
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <CustomText
            aria-level="1"
            text="Ready to Begin? Here’s What to Know First"
            size="xxl"
            weight="ultraBold"
            style={{ color: "#101828", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="l" />
          <CustomText
            text="This is a timed assessment. Once you start, the timer will continue running until the assessment expires. Make sure you’re prepared before proceeding."
            size="l"
            weight="normal"
            style={{ color: "#344054", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="l" />
          <View style={styles.infoLine}>
            <View style={[styles.buttonBox, { gap: 4 }]}>
              <CustomText size="l" weight="normal" style={{ color: "#344054" }} text="Time" />
              <CustomText
                style={[styles.infoTag, { color: "#344054" }]}
                useTranslationText={false}
                text={`${defaultTime} MIN`}
              />
            </View>
            <View style={[styles.buttonBox, { gap: 4 }]}>
              <CustomText size="l" weight="normal" style={{ color: "#344054" }} text="Questions" />
              <CustomText
                style={[styles.infoTag, { color: "#344054" }]}
                useTranslationText={false}
                text={defaultQuestionsCount}
              />
            </View>
          </View>
          <CustomSpacing type="vertical" size="l" />
          <CustomSpacing type="vertical" size="l" />
          <View style={styles.bottomInfoContainer}>
            <CustomText
              size="l"
              weight="normal"
              style={{ color: "#344054" }}
              text="⏳ You must complete the test within the timeframe."
            />
            <CustomText
              size="l"
              weight="normal"
              style={{ color: "#344054" }}
              text="🖥 Use a desktop computer for best results."
            />
            <CustomText
              size="l"
              weight="normal"
              style={{ color: "#344054" }}
              text="🚫 Always keep your mouse inside the test window."
            />
            <CustomText size="l" weight="normal" style={{ color: "#344054" }} text="🔍 All work must be your own." />
          </View>
          <CustomSpacing type="vertical" size="xs" />
          <CustomSpacing type="vertical" size="xxl" />
          <View style={styles.buttonBox}>
            <CustomButton
              aria-label="Begin Assessment"
              text="Begin Assessment"
              onPress={onConfirm}
              styleType="primary"
              style={styles.beginButton}
              textStyle={{ fontWeight: "700", fontSize: 18, fontFamily: "Inter" }}
            />
            <CustomButton
              text="Cancel"
              textStyle={{ fontWeight: "700", fontSize: 18, fontFamily: "Inter", color: "#3E68FE" }}
              onPress={onCancel}
              styleType="primaryLight"
              style={styles.cancelButton}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    borderRadius: 40,
    padding: 40,
    height: "auto",
    maxWidth: 688,
    width: "auto",
    backgroundColor: "white",
    alignItems: "center",
  },
  infoTag: {
    backgroundColor: "#EAECF0",
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
  },
  infoLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 18,
  },
  bottomInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 10,
    padding: 20,
    borderRadius: 12,
    backgroundColor: "#F2F4F7",
    width: "100%",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 12,
  },
  beginButton: {
    backgroundColor: "#3E68FE",
    padding: 16,
    borderRadius: 8,
  },
  cancelButton: {
    backgroundColor: "#fff",
    borderRadius: 8,
    borderWidth: 2,
    padding: 16,
    borderColor: "#3E68FE",
  },
});

export default AssessmentBeginModal;
