/**
 * Extract the YouTube video ID from a URL
 * @param {string} url - The YouTube URL
 * @returns {string|null} - The video ID or null if the URL is invalid
 */
export const getYoutubeVideoId = (url) => {
  if (!url) return null;

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

/**
 * Generate a thumbnail URL for a YouTube video
 * @param {string} videoId - The YouTube video ID
 * @param {string} quality - The quality of the thumbnail (default, hqdefault, mqdefault, sddefault, maxresdefault)
 * @returns {string} - The thumbnail URL
 */
export const getYoutubeThumbnailUrl = (videoId, quality = "hqdefault") => {
  if (!videoId) return null;
  return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
};

/**
 * Generate an embed URL for a YouTube video
 * @param {string} videoId - The YouTube video ID
 * @returns {string} - The embed URL
 */
export const getYoutubeEmbedUrl = (videoId) => {
  if (!videoId) return null;
  return `https://www.youtube.com/embed/${videoId}`;
};
