import React, { useMemo } from "react";
import { FlatList, View, StyleSheet, Pressable } from "react-native";
import { MotiView } from "moti";

import DataItem from "../../DataItem/Task/DataItem";
import CustomText from "../../../common/general/CustomText/CustomText";

function AnalyticsItemList({ items, headerText, headerColor, onListItemPress }) {
  const flattenedItems = useMemo(() => {
    if (!items || !items.data || !Array.isArray(items.data.data)) {
      return [];
    }
    return items.data.data.map((item, index) => ({
      ...item,
      id: item.id || `${item.name}-${index}`,
    }));
  }, [items]);

  const renderItem = ({ item }) => (
    <Pressable
      onPress={() => {
        if (onListItemPress) {
          onListItemPress(item);
        }
      }}>
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 0.95 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        style={{ flex: 1, marginVertical: 4 }}
        delay={0}
        transition={{ type: "timing", duration: 800 }}>
        <DataItem
          title={item.name}
          avgTime={item.avgTime}
          successPoints={item.successPoints}
          confusionPoints={item.confusionPoints}
          questionsAsked={item.questionsAsked}
          activeInteractionCount={item.activeInteractionCount}
          distractions={item.distractions}
          enrollment={item.enrollment}
          sentiment={item.sentiment}
          completionStats={item.completionStats} // Add this line
        />
      </MotiView>
    </Pressable>
  );

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      data={flattenedItems}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      ListHeaderComponent={() => (
        <View style={[styles.headerContainer, { backgroundColor: headerColor || "#fdecec" }]}>
          <CustomText useTranslationText={false} text={headerText || "Header"} size="s" weight="bold" />
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginBottom: 8,
    borderRadius: 4,
  },
});

export default AnalyticsItemList;
