import { useState, useEffect } from "react";
import { Linking } from "react-native";
import { usePostHog } from "posthog-js/react";
import { useAuth } from "../../../hooks/useAuth";
import { useAppConfig } from "../../../AppConfigProvider";
import { useNavigation } from "@react-navigation/native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useSignupMutation, MeDocument } from "../../../graphql/generated/graphql";
import SignupForm from "../../../components/auth/SignupForm/SignupForm";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";
import Sentry from "../../../utils/sentry";

function SignupFormContainer() {
  const posthog = usePostHog();
  const { login } = useAuth();
  const appConfig = useAppConfig();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { dispatch } = useAppState();
  const [signupMutation] = useSignupMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [dailyEmailOptIn, setDailyEmailOptIn] = useState(true);
  const [gender, setGender] = useState("");
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const domainKey = appConfig.key;
  const termsOfServiceLink = appConfig.termsOfServiceLink;
  const isPasswordMatching = password === confirmPassword;
  const hasEmailVerification = appConfig.hasEmailVerification;

  useEffect(() => {
    posthog?.capture("Signup Page Visited");
  }, []);

  const handleNameChangeText = (text) => {
    setName(text);
    validateName(text);
  };

  const handleEmailChangeText = (text) => {
    setEmail(text);
    validateEmail(text);
  };

  const handlePasswordChangeText = (text) => {
    setPassword(text);
  };

  const handleConfirmPasswordChangeText = (text) => {
    setConfirmPassword(text);
  };

  const handleDatePickerChange = (date) => {
    setBirthday(date);
  };

  const handleGenderValueChange = (itemValue) => {
    setGender(itemValue);
  };

  const handleTermsAgreePress = () => {
    setHasAgreedToTerms((prev) => !prev);
  };

  const handleTermsPress = () => {
    Linking.openURL(termsOfServiceLink);
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setIsServerError(false);

    try {
      if (!isValidName) return;
      if (!isValidEmail) return;
      if (!isPasswordMatching) return;

      const age = calculateAge(birthday);

      if (age < 14) {
        setErrorMessage("You must be 14 years old or older to sign up.");
        return;
      }

      if (!gender) {
        setErrorMessage("Please select your gender.");
        return;
      }

      if (!hasAgreedToTerms) {
        setErrorMessage("You must agree to the Terms of Service.");
        return;
      }

      const isValidPassword = validatePassword(password);
      if (!isValidPassword) {
        setErrorMessage(
          "Password must be at least 10 characters long, contain at least 1 uppercase letter, and 1 number or special character."
        );
        return;
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      setIsLoading(true);

      const result = await signupMutation({
        variables: {
          email: email.toLowerCase(),
          name,
          password,
          gender,
          birthday: new Date(birthday).toISOString(),
          dailyEmailOptIn,
          language: "",
          timezone: timezone,
        },
      });

      if (result.data) {
        posthog?.identify(result?.data["signup"].user.id, {
          name: result?.data["signup"].user.name,
          age: age,
          gender: gender,
        });
        posthog?.capture("user signup", {
          distinctId: result?.data["signup"].user.id,
        });

        const token = result.data["signup"].token;
        await login(token);

        setErrorMessage("");

        // Route to email verificaiton
        dispatch({
          type: actions.SET_META,
          payload: { user: result.data["signup"].user, email: email.toLowerCase() },
        });

        if (hasEmailVerification) {
          navigation.navigate("Verify Email");
        } else {
          navigation.navigate("Onboarding");
        }
      }

      setIsLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      setIsServerError(true);
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const handleSignInPress = () => {
    navigation.navigate("Login");
  };

  const validateName = (name) => {
    const isValid = !name.includes(" ");
    setIsValidName(isValid);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(pattern.test(email));
  };

  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumberOrSpecial = /[\d!@#$%^&*()_+{}":;'<>?,./\\-]/.test(password);

    return password.length >= 10 && hasUppercase && hasNumberOrSpecial;
  };

  function calculateAge(birthdayISO) {
    const birthDate = new Date(birthdayISO);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const handleDailyEmailOptInPress = () => {
    setDailyEmailOptIn((prev) => !prev);
  };

  return (
    <SignupForm
      name={name}
      email={email}
      password={password}
      confirmPassword={confirmPassword}
      birthday={birthday}
      gender={gender}
      hasAgreedToTerms={hasAgreedToTerms}
      isValidName={isValidName}
      isValidEmail={isValidEmail}
      isPasswordMatching={isPasswordMatching}
      errorMessage={errorMessage}
      companionName={domainKey}
      dailyEmailOptIn={dailyEmailOptIn}
      onNameChangeText={handleNameChangeText}
      onEmailChangeText={handleEmailChangeText}
      onPasswordChangeText={handlePasswordChangeText}
      onConfirmPasswordChangeText={handleConfirmPasswordChangeText}
      onDatePickerChange={handleDatePickerChange}
      onGenderValueChange={handleGenderValueChange}
      onTermsAgreePress={handleTermsAgreePress}
      onDailyEmailOptInPress={handleDailyEmailOptInPress}
      onTermsPress={handleTermsPress}
      onSubmit={handleSubmit}
      onSignInPress={handleSignInPress}
      isLoading={isLoading}
      isServerError={isServerError}
    />
  );
}

export default SignupFormContainer;
