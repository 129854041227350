import React from "react";
import { DataTable } from "react-native-paper";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { FlatList, StyleSheet } from "react-native";
import { Checkbox } from "react-native-paper";
import { Degree } from "../../../graphql/generated/graphql";

export interface CourseDegreesTableProps {
  degrees: Pick<Degree, "id" | "name">[];
  setSelectedDegrees: (courseIds: string[]) => void;
  selectedDegrees: string[];
}

const CourseDegreesTable = ({ degrees, setSelectedDegrees, selectedDegrees }: CourseDegreesTableProps) => {
  const toggleSelection = (id: string) => {
    const newSelectedIds = new Set(selectedDegrees);
    if (newSelectedIds.has(id)) {
      newSelectedIds.delete(id);
    } else {
      newSelectedIds.add(id);
    }
    setSelectedDegrees(Array.from(newSelectedIds));
  };

  const toggleSelectAll = () => {
    if (selectedDegrees.length === degrees.length) {
      setSelectedDegrees([]);
    } else {
      setSelectedDegrees(degrees.map((s) => s.id));
    }
  };

  return (
    <>
      <DataTable>
        <DataTable.Header style={styles.tableHeader}>
          <DataTable.Cell style={styles.checkboxColumn} id="checkboxColumn">
            <Checkbox
              status={
                selectedDegrees.length === degrees.length && degrees.length > 0
                  ? "checked"
                  : selectedDegrees.length > 0
                    ? "indeterminate"
                    : "unchecked"
              }
              onPress={toggleSelectAll}
              theme={{ colors: { primary: "#3e68fe" } }}
            />
          </DataTable.Cell>
          <DataTable.Title style={styles.nameColumn}>
            <CustomText text="Degree Name" size="s" weight="medium" style={{}} />
          </DataTable.Title>
        </DataTable.Header>

        <FlatList
          data={degrees}
          renderItem={({ item: degree }) => (
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell style={styles.checkboxColumn}>
                <Checkbox
                  status={selectedDegrees.includes(degree.id) ? "checked" : "unchecked"}
                  onPress={() => toggleSelection(degree.id)}
                  theme={{ colors: { primary: "#3e68fe" } }}
                />
              </DataTable.Cell>
              <DataTable.Cell style={styles.nameColumn}>
                <CustomText text={degree.name} size="s" style={styles.degreeName} useTranslationText={false} />
              </DataTable.Cell>
            </DataTable.Row>
          )}
          keyExtractor={(item) => item.id}
        />
      </DataTable>
    </>
  );
};

const styles = StyleSheet.create({
  tableHeader: {
    minHeight: 48,
    height: 48,
  },
  checkboxColumn: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 48,
  },
  nameColumn: { flex: 4, minHeight: 48 },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    paddingVertical: 16,
    alignItems: "center",
    minHeight: 48,
  },
  degreeName: {
    color: "#111827",
    fontSize: 14,
    fontWeight: "500",
  },
});

export default CourseDegreesTable;
