import { StyleSheet, View } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { DashboardData } from "../../../views/TeacherGradingView";

const DashboardDataContainer = ({ dashboardData }: { dashboardData: DashboardData[] }) => {
  return (
    <View style={styles.container}>
      {dashboardData.map((data) => (
        <View style={styles.topTile} key={data.dataLabel}>
          <CustomText
            useTranslationText={false}
            style={{ fontSize: 36, color: "#101828", marginBottom: 16 }}
            weight="ultraBold"
            text={`${data.dataValue}`}
          />
          <CustomText style={styles.topText} text={data.dataLabel} useTranslationText={false} />
        </View>
      ))}
    </View>
  );
};

export default DashboardDataContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 48,
    gap: 24,
    width: "100%",
  },
  topTile: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    borderColor: "#EAECF0",
    borderWidth: 1,
    borderRadius: 20,
    padding: 20,
  },
  topText: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 8,
    color: "#667085",
  },
});
