import React, { useState, useRef, useEffect } from "react";
import { View, Text, StyleSheet, Pressable, Dimensions } from "react-native";
import { MotiView } from "moti";
import { Ionicons } from "@expo/vector-icons";
import WebView from "react-native-webview";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const VisualizationMessage = ({ message, onExpand, isWider = false, isFullScreen = false }) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(500);
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  // Use full width for visualizations
  const maxWidth = windowWidth - 20; // Just a small margin for safety

  // Use onExpand if provided, otherwise use the internal toggle
  const handleToggleExpand = () => {
    if (onExpand) {
      onExpand();
    } else {
      // Original expand behavior as fallback
      setExpanded(!expanded);
      if (!expanded) {
        setHeight(Math.round(windowHeight * 0.8));
      } else {
        setHeight(500);
      }
    }
  };

  // For the fullscreen modal version, use nearly the entire height
  const contentHeight = isFullScreen ? windowHeight * 0.8 : 450;

  // Create appropriate HTML content based on visualization type
  const getVisualizationHtml = () => {
    const { visualization } = message;
    const { type, content } = visualization || {};

    if (!type || !content) return "<div>Error loading visualization</div>";

    if (type === "static") {
      return `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <script src="https://cdn.jsdelivr.net/npm/mermaid@9.4.3/dist/mermaid.min.js"></script>
            <style>
              html, body {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EAECF0;
                font-family: Arial, sans-serif;
                overflow: hidden;
              }
              .mermaid-container {
                background-color: white;
                border-radius: 8px;
                padding: 16px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                width: 100%;
                max-width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: auto;
              }
              .mermaid {
                width: 100%;
                overflow: visible;
              }
              #error-message {
                color: #DC2626;
                font-size: 14px;
                text-align: center;
                display: none;
              }
            </style>
          </head>
          <body>
            <div class="mermaid-container">
              <div id="error-message">Error rendering diagram</div>
              <pre class="mermaid">
                ${content}
              </pre>
            </div>
            <script>
              // Initialize mermaid with specific configuration
              mermaid.initialize({
                startOnLoad: true,
                theme: 'default',
                securityLevel: 'loose',
                logLevel: 'error',
                flowchart: { htmlLabels: true, useMaxWidth: true },
                er: { useMaxWidth: true },
                sequence: { useMaxWidth: true },
                journey: { useMaxWidth: true }
              });
              
              // Handle mermaid errors
              mermaid.parseError = function(err, hash) {
                console.error('Mermaid parse error:', err);
                document.getElementById('error-message').style.display = 'block';
              };
              
              // Try to render diagram after a short delay
              setTimeout(function() {
                try {
                  mermaid.init(undefined, document.querySelectorAll('.mermaid'));
                } catch (e) {
                  console.error('Mermaid init error:', e);
                  document.getElementById('error-message').style.display = 'block';
                }
              }, 500);
            </script>
          </body>
        </html>
      `;
    } else if (type === "nonInteractive") {
      // For GSAP non-interactive visualizations
      return `
        <!DOCTYPE html>
        <html>
            <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Security-Policy" content="
                default-src 'self' data:;
                script-src 'self' https://cdn.jsdelivr.net https://cdnjs.cloudflare.com 'unsafe-inline';
                style-src 'self' 'unsafe-inline';
                img-src 'self' data:;
                connect-src 'none';">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/ScrollTrigger.min.js"></script>
            <style>
                html, body {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EAECF0;
                overflow: hidden;
                }
                #visualization-container {
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                width: 100%;
                max-width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                }
                /* Styles for GSAP reset button if used */
                .reset-button {
                  position: absolute;
                  bottom: 10px;
                  left: 50%;
                  transform: translateX(-50%);
                  background-color: #4D5E80;
                  color: white;
                  border: none;
                  border-radius: 4px;
                  padding: 8px 16px;
                  cursor: pointer;
                  font-weight: bold;
                  z-index: 10;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .reset-button:hover {
                  background-color: #3D4E70;
                }
                /* Ensure SVG elements are responsive */
                svg {
                  width: 100%;
                  height: auto;
                  display: block;
                }
            </style>
            </head>
            <body>
            <div id="visualization-container"></div>
            <script>
                try {
                ${content}
                } catch (e) {
                console.error('GSAP Error:', e);
                }
            </script>
            </body>
        </html>
      `;
    } else if (type === "interactive") {
      // For p5.js interactive visualizations
      return `
        <!DOCTYPE html>
        <html>
            <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="Content-Security-Policy" content="
                default-src 'self' data:;
                script-src 'self' https://cdn.jsdelivr.net 'unsafe-inline';
                style-src 'self' 'unsafe-inline';
                img-src 'self' data:;
                connect-src 'none';">
            <script src="https://cdn.jsdelivr.net/npm/p5@1.4.1/lib/p5.js"></script>
            <style>
                html, body {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EAECF0;
                overflow: hidden;
                }
                #visualization-container {
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                width: 100%;
                max-width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                }
            </style>
            </head>
            <body>
            <div id="visualization-container"></div>
            <script>
                try {
                ${content}
                } catch (e) {
                console.error('p5.js Error:', e);
                }
            </script>
            </body>
        </html>
      `;
    }
  };

  return (
    <View style={[styles.container, isWider && { width: "100%" }]}>
      <MotiView
        from={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "timing", duration: 400 }}
        style={[
          styles.visualizationContainer,
          {
            maxWidth: isFullScreen ? "100%" : maxWidth,
            height: isFullScreen ? "100%" : height,
            width: isFullScreen ? "100%" : "100%",
          },
        ]}>
        <View style={styles.header}>
          <Text style={styles.title}>
            {message.visualization?.type === "static"
              ? "Diagram"
              : message.visualization?.type === "interactive"
                ? "Interactive Simulation"
                : "Simulation"}
          </Text>
          <Pressable
            style={styles.expandButton}
            onPress={handleToggleExpand}
            accessibilityLabel={isFullScreen ? "Close" : "Expand visualization"}>
            <Ionicons name={isFullScreen ? "close-outline" : "expand-outline"} size={20} color="#4D5E80" />
          </Pressable>
        </View>

        <View style={[styles.webViewContainer, { height: contentHeight }]}>
          <WebView
            source={{ html: getVisualizationHtml() }}
            style={styles.webView}
            scrollEnabled={true}
            javaScriptEnabled={true}
            domStorageEnabled={false}
            allowFileAccess={false}
            allowUniversalAccessFromFileURLs={false}
            originWhitelist={["*"]}
            startInLoadingState={true}
            onError={(syntheticEvent) => {
              const { nativeEvent } = syntheticEvent;
              console.error("WebView error:", nativeEvent);
            }}
            onHttpError={(syntheticEvent) => {
              const { nativeEvent } = syntheticEvent;
              console.error("WebView HTTP error:", nativeEvent);
            }}
            renderLoading={() => (
              <View style={styles.loadingContainer}>
                <Text style={styles.loadingText}>Loading visualization...</Text>
              </View>
            )}
          />
        </View>
      </MotiView>
      <CustomSpacing type="vertical" size="m" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  visualizationContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    overflow: "hidden",
    width: "100%",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
    borderWidth: 1,
    borderColor: "#E5E7EB",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    backgroundColor: "#F9FAFB",
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    color: "#111827",
  },
  expandButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#F3F4F6",
    justifyContent: "center",
    alignItems: "center",
  },
  webViewContainer: {
    width: "100%",
  },
  webView: {
    flex: 1,
    backgroundColor: "#EAECF0",
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EAECF0",
  },
  loadingText: {
    fontSize: 14,
    color: "#4B5563",
  },
});

export default VisualizationMessage;
