import React from "react";
import { Modal, View, StyleSheet } from "react-native";
import CustomButton from "../CustomButton/CustomButton";
import CustomText from "../CustomText/CustomText";
import { ModalExit } from "../../../svgIcons";
import { shadowStyles } from "../../../../styles/shadow";

const CustomModal = ({
  visible,
  onRequestClose,
  children,
  style,
  showCloseButton = false,
  title = "",
  exitFill = "#000000",
  onShow = undefined,
  ariaLabel = "",
  translateTitle = true,
  ...props
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
      onShow={onShow}
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel ?? title}
      {...props}>
      <View style={styles.centeredView}>
        <View style={[styles.modalView, shadowStyles.modal, style]}>
          {(title || showCloseButton) && (
            <View style={styles.header}>
              <CustomText
                role="header"
                aria-level="1"
                text={title}
                size="s"
                textType="display"
                weight="ultraBold"
                useTranslationText={false}
              />
              {showCloseButton && (
                <CustomButton
                  styleType="transparent"
                  leftIcon={<ModalExit fill={exitFill} />}
                  onPress={onRequestClose}
                  aria-label="Close modal"
                />
              )}
            </View>
          )}
          {children}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    borderRadius: 20,
    padding: 20,
    height: "auto",
    maxWidth: 900,
    width: "auto",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 5,
  },
});

export default CustomModal;
