import React, { useEffect, useState } from "react";
import { View, StyleSheet, Image, Pressable, Platform, Alert } from "react-native";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RichEditor, RichToolbar, actions } from "react-native-pell-rich-editor";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import ImageUpload from "../../../components/common/general/ImageUpload/ImageUpload";
import {
  useMeQuery,
  useUpdateFacultyProfileMutation,
  useUploadFacultyProfileImageMutation,
} from "../../../graphql/generated/graphql";
import NotePencil from "../../../components/svgs/common/NotePencil";

const profileSchema = z.object({
  name: z.string().min(1, "Full name is required"),
  email: z.string().email("Invalid email address"),
  meetingLink: z.string().url("Invalid URL").optional().or(z.literal("")),
  biography: z.string().optional(),
  profilePicture: z.string().optional(),
});

type ProfileFormData = z.infer<typeof profileSchema>;

const FacultyProfileTabContainer = () => {
  const { data, refetch } = useMeQuery();
  const [uploadFacultyProfileImage] = useUploadFacultyProfileImageMutation();
  const [updateFacultyProfile] = useUpdateFacultyProfileMutation();
  const [uploadError, setUploadError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      name: "",
      email: "",
      meetingLink: "",
      biography: "",
      profilePicture: "",
    },
  });

  useEffect(() => {
    if (data?.me) {
      reset({
        name: data.me.name || "",
        email: data.me.email || "",
        meetingLink: data.me.meetingLink || "",
        biography: data.me.biography || "",
        profilePicture: data.me.profilePicture || "",
      });
    }
  }, [data?.me, reset]);

  const editorRef = React.useRef<RichEditor>(null);

  useEffect(() => {
    if (data?.me?.biography && editorRef.current) {
      editorRef.current.setContentHTML(data.me.biography);
    }
  }, [data?.me?.biography]);

  const handleSave = handleSubmit(async (formData) => {
    try {
      // First update the profile
      const response = await updateFacultyProfile({
        variables: {
          input: {
            name: formData.name,
            email: formData.email,
            meetingLink: formData.meetingLink || "",
            biography: formData.biography || "",
          },
        },
      });

      if (response.data?.updateFacultyProfile?.success) {
        // If profile update was successful and we have a new profile picture, upload it
        if (formData.profilePicture && formData.profilePicture !== data?.me?.profilePicture) {
          const base64Data = formData.profilePicture.split(",")[1];
          const contentType = formData.profilePicture.split(";")[0].split(":")[1];
          // Calculate size in bytes: base64 string length * (3/4) for actual bytes
          const size = Math.floor(base64Data.length * (3 / 4));

          await uploadFacultyProfileImage({
            variables: {
              input: {
                image: base64Data,
                contentType,
                size,
              },
            },
          });
        }

        refetch();
        Alert.alert("Success", "Profile updated successfully");
        reset(formData);
      }
    } catch (error) {
      console.error("Error saving profile:", error);
      Alert.alert("Error", "Failed to save profile changes. Please try again.");
    }
  });

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <CustomText text="Your Profile" size="l" weight="ultraBold" style={styles.title} />
        <CustomButton
          text="Save"
          styleType="primary"
          size="m"
          onPress={handleSave}
          leftIcon={null}
          rightIcon={null}
          style={{
            backgroundColor: !isDirty ? "#E5E7EB" : "#4F46E5",
            opacity: !isDirty ? 0.5 : 1,
          }}
          textStyle={{
            color: !isDirty ? "#6B7280" : "#FFFFFF",
          }}
          disabled={!isDirty}
          textProps={{}}
          useTranslationText={true}
        />
      </View>

      <CustomSpacing type="vertical" size="xl" />

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 40,
          padding: 40,
          borderWidth: 1,
          borderColor: "#E5E7EB",
          borderRadius: 12,
        }}>
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View style={styles.profilePictureSection}>
            <Controller
              control={control}
              name="profilePicture"
              render={({ field: { onChange, value } }) => (
                <ImageUpload
                  value={value}
                  onChange={onChange}
                  containerStyle={{
                    width: 160,
                    height: 160,
                    borderRadius: 80,
                    borderWidth: 1,
                    borderColor: "#E5E7EB",
                    backgroundColor: "#F3F4F6",
                    borderStyle: "solid",
                  }}
                  imageStyle={{
                    borderRadius: 80,
                    overflow: "hidden",
                  }}
                  aspectRatio={[1, 1]}
                  allowsEditing={true}
                  onError={(error) => setUploadError(error.message)}
                  placeholderContent={
                    <View style={styles.profilePicturePlaceholder}>
                      <CustomText
                        text={
                          data?.me?.name
                            ?.split(" ")
                            .map((n) => n[0])
                            .join("") || ""
                        }
                        size="l"
                        weight="ultraBold"
                        style={styles.placeholderText}
                        useTranslationText={false}
                      />
                    </View>
                  }
                />
              )}
            />
            <View style={styles.editOverlay}>
              <NotePencil width={16} height={16} />
            </View>
          </View>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Profile Photo" size="m" style={styles.profilePictureText} />
          <CustomText text="Recommended size: 500x500" size="xs" style={styles.helperText} />
          {uploadError && <CustomText text={uploadError} size="xs" style={styles.errorText} />}
        </View>
        <View style={styles.form}>
          <View style={styles.formGroup}>
            <CustomText text="Full Name" size="s" weight="ultraBold" style={{}} />
            <CustomSpacing type="vertical" size="s" />
            <Controller
              control={control}
              name="name"
              disabled={true}
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="Enter your full name"
                  style={{}}
                  error={errors.name?.message}
                  disabled={true}
                />
              )}
            />
          </View>

          <CustomSpacing type="vertical" size="l" />

          <View style={styles.formGroup}>
            <CustomText text="Email Address" size="s" weight="ultraBold" style={{}} />
            <CustomText
              text="This is the email address students can use to contact you directly."
              size="xs"
              style={styles.helperText}
            />
            <CustomSpacing type="vertical" size="s" />
            <Controller
              control={control}
              name="email"
              disabled={true}
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="Enter your email address"
                  style={{}}
                  error={errors.email?.message}
                  disabled={true}
                />
              )}
            />
          </View>

          <CustomSpacing type="vertical" size="l" />

          <View style={styles.formGroup}>
            <CustomText text="Calendly Scheduling Link" size="s" weight="ultraBold" style={{}} />
            <CustomText
              text="This link allows students to schedule time with you for one-on-one sessions."
              size="xs"
              style={styles.helperText}
            />
            <CustomSpacing type="vertical" size="s" />
            <Controller
              control={control}
              name="meetingLink"
              render={({ field: { onChange, value } }) => (
                <CustomTextInput
                  value={value}
                  onChangeText={onChange}
                  placeholder="e.g., https://calendly.com/yourname"
                  style={{}}
                  error={errors.meetingLink?.message}
                />
              )}
            />
          </View>

          <CustomSpacing type="vertical" size="l" />

          <View style={styles.formGroup}>
            <CustomText text="Bio" size="s" weight="ultraBold" style={{}} />
            <CustomText text="Tell your students a little bit about yourself" size="xs" style={styles.helperText} />
            <CustomSpacing type="vertical" size="s" />
            <Controller
              control={control}
              name="biography"
              render={({ field: { value } }) => (
                <View style={styles.richEditorContainer}>
                  <RichToolbar
                    editor={editorRef}
                    actions={[
                      actions.setBold,
                      actions.setItalic,
                      actions.setUnderline,
                      actions.heading1,
                      actions.heading2,
                      actions.insertBulletsList,
                      actions.insertOrderedList,
                      actions.insertLink,
                      actions.keyboard,
                      actions.setStrikethrough,
                      actions.alignLeft,
                      actions.alignCenter,
                      actions.alignRight,
                    ]}
                    style={styles.richToolbar}
                  />
                  <RichEditor
                    ref={editorRef}
                    style={styles.richEditor}
                    initialContentHTML={value}
                    onChange={(content) => setValue("biography", content, { shouldDirty: true })}
                    placeholder="Add a description"
                  />
                </View>
              )}
            />
            {errors.biography?.message && (
              <CustomText text={errors.biography.message} size="xs" style={styles.errorText} />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    color: "#111827",
  },
  form: {
    flex: 1,
  },
  formGroup: {
    width: "100%",
  },
  profilePictureText: {
    color: "#111827",
    fontSize: 16,
    fontWeight: "bold",
  },
  helperText: {
    color: "#6B7280",
    marginTop: 4,
  },
  richEditorContainer: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 8,
    overflow: "hidden",
  },
  richEditor: {
    minHeight: 200,
    maxHeight: 400,
  },
  richToolbar: {
    borderTopWidth: 1,
    borderTopColor: "#D1D5DB",
    backgroundColor: "#F9FAFB",
  },
  errorText: {
    color: "#EF4444",
    marginTop: 4,
  },
  profilePictureSection: {
    alignItems: "center",
    position: "relative",
  },
  profilePicturePlaceholder: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F3F4F6",
  },
  placeholderText: {
    color: "#6B7280",
    fontSize: 40,
  },
  editOverlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#FFFFFF",
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    borderWidth: 1,
    borderColor: "#E5E7EB",
  },
});

export default FacultyProfileTabContainer;
