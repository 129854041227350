import { useState, useEffect, useMemo } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { useAppState } from "../../../contexts/AppStateContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import VerifyEmailForm from "../../../components/auth/VerifyEmailForm/VerifyEmailForm";
import { sendEmailOTP, sendSMSOTP, verifyEmailOTP, verifySMSOTP } from "../../../services";
import { useNavigation } from "@react-navigation/native";
import { useAuth } from "../../../hooks/useAuth";
import { Platform } from "react-native";
import { announceForAccessibility } from "../../../utils/accessibility";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";

const sendEmailOTPHandler = async ({ email, setErrorMessage }) => {
  if (email === "") {
    setErrorMessage("Error sending code. Please try again.");
    return;
  }

  // Check if email is valid
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setErrorMessage("Error sending code. Please try again.");
    return;
  }

  try {
    const token = await AsyncStorage.getItem("token");

    await sendEmailOTP(email, token);
    setErrorMessage("");
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      setErrorMessage(error.response.data.error);
    } else {
      setErrorMessage("Error sending code. Please try again.");
    }
  }
};

const sendSMSOTPHandler = async ({ countryCode, phoneNumber, setErrorMessage }) => {
  if (phoneNumber === "") {
    setErrorMessage("Error sending code. Please try again.");
    return;
  }

  try {
    const token = await AsyncStorage.getItem("token");
    await sendSMSOTP(countryCode, phoneNumber, token);
    setErrorMessage("");
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      setErrorMessage(error.response.data.error);
    } else {
      setErrorMessage("Error sending code. Please try again.");
    }
  }
};

function VerifyEmailContainer() {
  const appConfig = useAppConfig();
  const { state } = useAppState();
  const { verifyOTPForSession, isAuthenticated, isEmailVerified, otpVerifiedForSession } = useAuth();
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [lastSentTime, setLastSentTime] = useState(null);

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const domainKey = appConfig.key;
  const isAxioOffering = appConfig.isAxioOffering;

  const user = useMemo(() => state?.meta?.user, [state]);

  useEffect(() => {
    if (Platform.OS === "ios" && errorMessage) {
      announceForAccessibility({ message: "Error alert" + errorMessage, queue: false });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    }

    if (isAuthenticated && isEmailVerified && otpVerifiedForSession) {
      navigation.reset({
        index: 0,
        routes: [isAxioOffering ? { name: "Custom School Student" } : { name: "My Day" }],
      });
    }

    const canResendOTP = !lastSentTime || Date.now() - lastSentTime > 60000;

    if (canResendOTP) {
      handleSendOTP();
      setLastSentTime(Date.now());
    }
  }, [user, lastSentTime]);

  const handleSendOTP = async () => {
    if (!isEmailVerified) {
      sendEmailOTPHandler({ email: user.email, setErrorMessage });
    } else if (user.otpPreference === "EMAIL") {
      await sendEmailOTPHandler({ email: user.email, setErrorMessage });
    } else if (user.otpPreference === "SMS") {
      await sendSMSOTPHandler({ countryCode: user.countryCode, phoneNumber: user.phoneNumber, setErrorMessage });
    }
  };

  const handleOTPChange = (text) => {
    setOtp(text);
  };

  const handleVerifyOTP = async () => {
    if (otp === "") {
      setErrorMessage("Field must be filled out");
      return;
    }

    // Check if code is valid
    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      setErrorMessage("Code must be 6 digits");
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");

      let newToken;
      if (user.otpPreference === "EMAIL" || user.otpPreference === "NONE") {
        const response = await verifyEmailOTP(user.email, otp, token);
        newToken = response.token;
      } else if (user.otpPreference === "SMS") {
        const response = await verifySMSOTP(user.countryCode, user.phoneNumber, otp, token);
        newToken = response.token;
      }

      setErrorMessage("");

      await verifyOTPForSession(newToken);

      if (!user.isOnboarded) {
        navigation.reset({
          index: 0,
          routes: [{ name: "Onboarding" }],
        });
      }

      navigation.reset({
        index: 0,
        routes: [isAxioOffering ? { name: "Custom School Student" } : { name: "My Day" }],
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Error sending code. Please try again.7");
      }
    }
  };

  const handleResendOTP = async () => {
    const canResendOTP = !lastSentTime || Date.now() - lastSentTime > 60000;

    if (!canResendOTP) {
      setErrorMessage("Please wait before requesting a new code.");
      return;
    }

    setErrorMessage("");
    await handleSendOTP();
    setLastSentTime(Date.now());
  };

  return (
    <VerifyEmailForm
      isAfterSignup={!isEmailVerified}
      otpPreference={user?.otpPreference || "EMAIL"}
      errorMessage={errorMessage}
      companionName={domainKey}
      otp={otp}
      onOTPChange={handleOTPChange}
      onVerifyOTP={handleVerifyOTP}
      onResendOTP={handleResendOTP}
    />
  );
}

export default VerifyEmailContainer;
