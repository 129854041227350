import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryScatter, VictoryAxis } from "victory-native";
import { View, Text, TouchableOpacity } from "react-native";

const BubbleChartContainer = ({ containerWidth = 800, data, onDrillDown, fixedDomain }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [viewMode, setViewMode] = useState("all");

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const domain = fixedDomain || { x: [-10, 10], y: [-10, 10] };

  const handleBubbleClick = (datum) => {
    if (onDrillDown) {
      onDrillDown(datum);
    }
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 16,
        borderColor: "#05060f1a",
        borderWidth: 1,
      }}>
      <View style={{ flexDirection: "row", gap: 24 }}>
        {/* Chart Section */}
        <View style={{ flex: 1, position: "relative" }}>
          {/* Quadrant backgrounds */}
          <View
            style={{
              position: "absolute",
              top: 50,
              left: 50,
              right: 50,
              bottom: 50,
              overflow: "hidden",
            }}>
            <View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: "50%",
                bottom: "50%",
                backgroundColor: "#fefce8",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                right: 0,
                bottom: "50%",
                backgroundColor: "#f0fdf4",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: "50%",
                bottom: 0,
                backgroundColor: "#fef2f2",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                right: 0,
                bottom: 0,
                backgroundColor: "#eff6ff",
              }}
            />
          </View>

          <VictoryChart
            width={containerWidth}
            height={600}
            domain={domain}
            padding={{ top: 50, bottom: 50, left: 50, right: 50 }}>
            <VictoryAxis
              crossAxis
              style={{
                axis: { stroke: "#E5E7EB", strokeWidth: 1 },
                grid: { stroke: "transparent" },
                tickLabels: { fontSize: 0 },
              }}
            />
            <VictoryAxis
              dependentAxis
              crossAxis
              style={{
                axis: { stroke: "#E5E7EB", strokeWidth: 1 },
                grid: { stroke: "transparent" },
                tickLabels: { fontSize: 0 },
              }}
            />
            <VictoryScatter
              data={filteredData?.filter((d) => !isNaN(d.x) && !isNaN(d.y)) || []}
              size={({ datum }) => datum.size}
              style={{
                data: {
                  fill: ({ datum }) => datum.color,
                  opacity: 0.85,
                  stroke: "#fff",
                  strokeWidth: 2,
                },
              }}
              labels={({ datum }) => datum.label}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onClick: (event, props) => {
                      const clickedData = props.datum;
                      handleBubbleClick(clickedData);
                      return [];
                    },
                  },
                },
              ]}
            />
          </VictoryChart>

          {/* Quadrant Labels */}
          <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }}>
            <Text
              style={{
                position: "absolute",
                top: 16,
                left: "50%",
                transform: [{ translateX: -30 }],
                color: "#4B5563",
              }}>
              Interesting
            </Text>
            <Text
              style={{
                position: "absolute",
                bottom: 16,
                left: "50%",
                transform: [{ translateX: -20 }],
                color: "#4B5563",
              }}>
              Boring
            </Text>
            <Text
              style={{
                position: "absolute",
                left: 16,
                top: "50%",
                transform: [{ translateY: -10 }],
                color: "#4B5563",
              }}>
              Confusing
            </Text>
            <Text
              style={{
                position: "absolute",
                right: 16,
                top: "50%",
                transform: [{ translateY: -10 }],
                color: "#4B5563",
              }}>
              Accessible
            </Text>
          </View>
        </View>

        {/* Legend Section */}
        <View
          style={{
            width: 200,
            paddingLeft: 16,
            height: 700,
            paddingVertical: 50,
            justifyContent: "space-between",
          }}>
          {/* View Mode Selection */}
          <View>
            <TouchableOpacity
              style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}
              onPress={() => setViewMode("risk")}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: "#D1D5DB",
                  marginRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {viewMode === "risk" && (
                  <View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#3B82F6" }} />
                )}
              </View>
              <Text>Show at Risk</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={() => setViewMode("all")}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: "#D1D5DB",
                  marginRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {viewMode === "all" && (
                  <View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#3B82F6" }} />
                )}
              </View>
              <Text>Show all</Text>
            </TouchableOpacity>
          </View>

          {/* Color Legend */}
          <View>
            <Text style={{ fontWeight: "700", marginBottom: 16 }}>Legend</Text>
            <View style={{ gap: 8 }}>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#85E139" }} />
                <Text>Positive Sentiment</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#0084FF" }} />
                <Text>Neutral Sentiment</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#F63D68" }} />
                <Text>Negative Sentiment</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default BubbleChartContainer;
