import * as React from "react";
import Svg, { Path } from "react-native-svg";

function BlackBulb(props) {
  return (
    <Svg width={17} height={21} viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12.75 20.252a.75.75 0 01-.75.75H4.5a.75.75 0 110-1.5H12a.75.75 0 01.75.75zm3.75-12a8.207 8.207 0 01-3.154 6.489 1.524 1.524 0 00-.596 1.199v.562a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 01-1.5-1.5v-.562a1.5 1.5 0 00-.584-1.187A8.212 8.212 0 010 8.298C-.024 3.83 3.587.11 8.051.002a8.25 8.25 0 018.45 8.25zm-1.5 0a6.75 6.75 0 00-6.913-6.75C4.431 1.59 1.48 4.632 1.5 8.29a6.717 6.717 0 002.591 5.278 3 3 0 011.16 2.373v.562h6v-.562a3.015 3.015 0 011.168-2.377A6.717 6.717 0 0015 8.253zm-1.51-.875a5.4 5.4 0 00-4.365-4.364.75.75 0 10-.25 1.48c1.554.26 2.872 1.579 3.135 3.135a.75.75 0 001.48-.251z"
        fill="#000000"
      />
    </Svg>
  );
}

export default BlackBulb;
