import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import Logo from "../../general/logos/Logo/Logo";
import MiniLogo from "../../general/logos/MiniLogo/MiniLogo";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import UserInitial from "../../general/UserInitial/UserInitial";
import { LogoutIcon } from "../../svgIcons";
import TopMenuItem from "../TopMenuItem/TopMenuItem.web";
import NotificationBell from "../../svgs/common/NotificationBell";

function TopMenu({
  menuItems,
  selectedItem,
  currentDomain,
  showMiniLogo,
  userInitial,
  isLTI,
  showFeedback,
  onItemPress,
  onFeedbackPress,
  onSettingsPress,
  onLogoutPress,
  onNotifcationsPress,
}) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();
  const isExpanded = isXLarge || !isMedium;
  const isMobile = !isMedium;

  const logoutTitle = isMedium ? "Logout" : "LOGOUT";

  return (
    <View style={[styles.mainMenu, isMobile && styles.mainMenuMobile]}>
      <View style={[styles.menu, isMobile && styles.menuMobile]}>
        <View>
          {isMedium && (
            <>
              <View style={isXLarge ? styles.topContainer : {}}>
                {isXLarge ? (
                  <Logo name={currentDomain} height={40} />
                ) : showMiniLogo ? (
                  <MiniLogo name={currentDomain} height={40} width={50} />
                ) : (
                  <View style={{ width: 80, height: 40 }}></View>
                )}
              </View>
            </>
          )}
        </View>
        <CustomSpacing type="horizontal" size="m" />
        <View role="menu">
          <View style={isMobile ? styles.verticalContainerColumn : styles.verticalContainerRow}>
            {menuItems.map((item, index) => (
              <TopMenuItem key={index} item={item} isSelected={selectedItem === item.name} onPress={onItemPress} />
            ))}
          </View>
        </View>
      </View>
      <View style={[styles.bottomContainer, isMedium ? {} : { padding: 15 }, isMobile && styles.bottomContainerMobile]}>
        {isExpanded && isMedium && (
          <>
            <CustomButton
              onPress={onNotifcationsPress}
              text={null}
              leftIcon={
                <View>
                  <NotificationBell />
                </View>
              }
              size={isMedium ? "m" : "s"}
              styleType="transparent"
              style={[isMedium ? styles.desktopSettingsButtons : {}, styles.settingsButtons]}
              textStyle={isMedium ? [{ fontSize: 14 }] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
              aria-label="Settings"
              accessibilityHint="Navigate to settings"
            />
            <CustomSpacing type="horizontal" size="s" />

            {showFeedback && (
              <>
                <CustomButton
                  onPress={onFeedbackPress}
                  text="Share Feedback"
                  styleType="primary"
                  size="s"
                  textStyle={styles.largeButtonText}
                />
                <CustomSpacing type="vertical" size="l" />
              </>
            )}
          </>
        )}

        <CustomButton
          onPress={onSettingsPress}
          text={null}
          leftIcon={
            <View>
              <UserInitial initial={userInitial} type={"menu"} />
            </View>
          }
          size={isMedium ? "m" : "s"}
          styleType="transparent"
          style={[isMedium ? styles.desktopSettingsButtons : {}, styles.settingsButtons]}
          textStyle={isMedium ? [{ fontSize: 14 }] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
          aria-label="Settings"
          accessibilityHint="Navigate to settings"
        />

        {!isLTI && !isMedium && (
          <CustomButton
            onPress={onLogoutPress}
            text={isExpanded ? logoutTitle : null}
            leftIcon={
              <View>
                <LogoutIcon />
              </View>
            }
            size={isMedium ? "m" : "s"}
            styleType="transparent"
            style={[isMedium ? styles.desktopSettingsButtons : {}, styles.settingsButtons]}
            textStyle={isMedium ? [{ fontSize: 14 }] : [styles.menuButtonText, { fontSize: hp(1.7) }]}
            aria-label="Logout"
            accessibilityHint="Logout from the application"
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainMenu: {
    paddingHorizontal: 20,
    backgroundColor: "#ffffff",
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: "#EAECF0",
    borderBottomWidth: 1,
  },
  mainMenuMobile: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  menu: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    alignItems: "center",
  },
  menuMobile: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bottomContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bottomContainerMobile: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  verticalContainerRow: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  verticalContainerColumn: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  topContainer: {
    paddingHorizontal: 6.39,
  },
});

export default TopMenu;
