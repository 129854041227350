import React, { useState, useEffect, useRef } from "react";
import { View, StyleSheet } from "react-native";
import TaskList from "../../../components/tasks/TaskList/TaskList";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import TaskDetailsModalContainer from "../TaskDetailsModalContainer/TaskDetailsModalContainer";
import TaskFormModalContainer from "../TaskFormModalContainer/TaskFormModalContainer";
import {
  useUpdateTodoMutation,
  TodosDocument,
  RecentTodosDocument,
  GetJourneyByIdDocument,
  GetJourneysDocument,
  useDeleteTodoMutation,
} from "../../../graphql/generated/graphql";
import { useNavigation } from "@react-navigation/native";
import TaskDeleteConfirmationModalContainer from "../TaskDeleteConfirmationModalContainer/TaskDeleteConfirmationModalContainer";
import DashboardTaskList from "../../../components/dashboard/DashboardTaskList/DashboardTaskList";
import Sentry from "../../../utils/sentry";
import { uniqBy } from "../../../utils/array";

function TaskListContainer({ tasks, journeyId, isJourneyView, isDashboard, onAddTask }) {
  const { dispatch } = useAppState();

  const navigation = useNavigation();

  const timeoutRef = useRef(null);

  const [currentTasks, setCurrentTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [deleteTodoMutation] = useDeleteTodoMutation({
    refetchQueries: [
      { query: TodosDocument },
      { query: RecentTodosDocument },
      { query: GetJourneyByIdDocument, variables: { journeyId } },
      { query: GetJourneysDocument },
    ],
  });

  const [updateTodoMutation] = useUpdateTodoMutation({
    refetchQueries: [
      { query: TodosDocument },
      { query: RecentTodosDocument },
      { query: GetJourneyByIdDocument, variables: { journeyId } },
      { query: GetJourneysDocument },
    ],
  });

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const extendedTasks = tasks.map((task) => ({ ...task, deleted: false, moved: null }));
    const updatedTasks = [...currentTasks, ...extendedTasks];
    const uniqueTasks = uniqBy(updatedTasks, (task) => task.id);
    setCurrentTasks(uniqueTasks);

    timeoutRef.current = setTimeout(() => {
      setCurrentTasks(extendedTasks);
    }, 2000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [tasks]);

  const selectedTask = tasks?.find((task) => task.id === selectedTaskId);

  const handleNavigation = (id) => {
    navigation.navigate("Journey", {
      id,
    });
  };

  const handleCheckboxToggle = async (taskId) => {
    try {
      const task = currentTasks?.find((task) => task.id === taskId);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const newValue = !task.completed;

      const variables = {
        id: taskId,
        name: task.name,
        timezone,
        completed_date: new Date(),
        completed: newValue,
        description: task.description,
        priority: task.priority,
        date: task.date,
        isRecurring: task.isRecurring,
        recurringFrequency: task.recurringFrequency,
        recurringDaily: task.recurringDaily,
        recurringDayOfWeek: task.recurringDayOfWeek,
        recurringDayOfMonth: task.recurringDayOfMonth,
        recurringTime: task.recurringTime,
        timeOfDay: task.timeOfDay,
        isPartOfJourney: task.isPartOfJourney,
        journeyId: task.isPartOfJourney ? task.journeyId : null, // Default value for journeyId CONNOR Im adding this //because in cases where tasks were not part of a journey - they could not be completed because of missing ID. //This fixes issue but will passing null break anything? I dont think so.
      };

      const result = await updateTodoMutation({ variables });

      // TODO replace with a toast
      const pointsReceived = result.data.updateTodo.pointsReceived;
      dispatch({
        type: actions.SET_META,
        payload: {
          pointsReceived: pointsReceived,
        },
      });

      const selectedTask = currentTasks?.find((task) => task.id === taskId);

      setCurrentTasks([
        ...currentTasks.filter((task) => task.id !== taskId),
        { ...selectedTask, moved: newValue ? "Completed" : "Active" },
      ]);

      // TODO trigger a loader here
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleTaskPress = (taskId) => {
    setSelectedTaskId(taskId);
    setTaskModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteTodoMutation({
        variables: { id: selectedTaskId },
      });

      const selectedTask = currentTasks?.find((task) => task.id === selectedTaskId);

      setCurrentTasks([
        ...currentTasks.filter((task) => task.id !== selectedTaskId),
        { ...selectedTask, deleted: true },
      ]);

      setDeleteModalVisible(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleMenuPress = (taskId) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setSelectedTaskId(taskId);
    setModalVisible(true);
    setTaskModalVisible(false);
  };

  const handleDeletePress = () => {
    setTaskModalVisible(false);
    setDeleteModalVisible(true);
  };

  const handleBackPress = () => {
    setTaskModalVisible(true);
    setDeleteModalVisible(false);
  };

  const handleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setTaskModalVisible(false);
    setModalVisible(false);
    setDeleteModalVisible(false);
  };

  const handleCompletePress = (taskId) => {
    handleCheckboxToggle(taskId);
    handleModalClose();
  };

  const handleOnHoverIn = (taskId) => {
    setCurrentTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            isHovered: true,
          };
        }

        return task;
      })
    );
  };

  const handleOnHoverOut = (taskId) => {
    setCurrentTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === taskId) {
          return {
            ...task,
            isHovered: false,
          };
        }

        return task;
      })
    );
  };

  return (
    <View style={styles.container}>
      {isDashboard ? (
        <DashboardTaskList
          tasks={currentTasks}
          onCheckboxToggle={handleCheckboxToggle}
          onJourneyPathPress={handleNavigation}
          isJourneyView={isJourneyView}
          onTaskPress={handleTaskPress}
          onAddTask={onAddTask}
        />
      ) : (
        <TaskList
          tasks={currentTasks}
          onCheckboxToggle={handleCheckboxToggle}
          onJourneyPathPress={handleNavigation}
          isJourneyView={isJourneyView}
          onTaskPress={handleTaskPress}
          onEdit={handleMenuPress}
          onHoverIn={handleOnHoverIn}
          onHoverOut={handleOnHoverOut}
        />
      )}
      <TaskFormModalContainer task={selectedTask} visible={modalVisible} onClose={handleModalClose} />
      <TaskDetailsModalContainer
        task={selectedTask}
        visible={taskModalVisible}
        onClose={handleModalClose}
        onEdit={handleMenuPress}
        onDelete={handleDeletePress}
        onComplete={handleCompletePress}
      />
      <TaskDeleteConfirmationModalContainer
        visible={deleteModalVisible}
        onClose={handleBackPress}
        onDelete={handleDelete}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default TaskListContainer;
