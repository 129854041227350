import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { MagnifyingGlass } from "../components/svgs/common";
import CustomText from "../components/common/general/CustomText/CustomText";
import { MotiView } from "moti";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import {
  GetAssessmentByFacultyDocument,
  GetUserSubmissionsByFacultyDocument,
  useGetAssessmentByFacultyQuery,
  useGetCoursesByFacultyQuery,
  useGetUserSubmissionsByFacultyQuery,
  useMeQuery,
  useUpdateAssessmentGradeMutation,
  useUpdateStudentAssignmentGradeMutation,
} from "../graphql/generated/graphql";
import DashboardDataContainer from "../containers/customCoursework/TeacherGradingUIComponents/DashboardDataContainer";
import GradingTableContainer from "../containers/customCoursework/TeacherGradingUIComponents/GradingsTableContainer";
import CustomMultiSelect from "../components/common/general/CustomMultiSelect/CustomMultiSelect";
import CustomTextInput from "../components/common/general/CustomTextInput/CustomTextInput";
import AssessmentGradingModal from "../containers/customCoursework/TeacherGradingUIComponents/AssessmentGradingModalContainer";
import AssignmentSubmissionGradingModal from "../containers/customCoursework/TeacherGradingUIComponents/AssignmentSubmissionGradingModalContainer";
import Loader from "../components/common/status/Loader/Loader";

enum ModalState {
  CLOSED = "CLOSED",
  VIEW_ASSESSMENT_RESPONSE = "VIEW_ASSESSMENT_RESPONSE",
  VIEW_ASSIGNMENT_RESPONSE = "VIEW_ASSIGNMENT_RESPONSE",
  EDIT_SCORE = "EDIT_SCORE",
}

const calculateEngagement = (active_interaction_count) => {
  if (active_interaction_count < 3) {
    return { level: "Low", color: "red" };
  } else if (active_interaction_count <= 6) {
    return { level: "Average", color: "#F79009" };
  } else {
    return { level: "High", color: "#4BA30D" };
  }
};

const formatSentiment = (sentiment) => {
  const s = sentiment.toLowerCase();
  if (s === "positive") {
    return { sentiment: "Positive", color: "#4BA30D" };
  } else if (s === "negative") {
    return { sentiment: "Negative", color: "red" };
  } else {
    return { sentiment: "Neutral", color: "#F79009" };
  }
};

export interface DashboardData {
  index: number;
  dataValue: string;
  dataLabel: string;
}

const initialDashboardData: DashboardData[] = [
  {
    index: 0,
    dataValue: "0",
    dataLabel: "Assignments & Assessments to Grade",
  },
  {
    index: 1,
    dataValue: "0",
    dataLabel: "Pending Retake Requests",
  },
  {
    index: 2,
    dataValue: "0",
    dataLabel: "New Submissions Today",
  },
];

export interface GradingData {
  created?: string;
  completedAt?: string;
  grade?: number;
  id?: string;
  isCompleted?: boolean;
  lastModified?: string;
  moduleName?: string;
  courseName?: string;
  userName?: string;
  hasTeacherGraded?: boolean;
  type?: string;
}

const TeacherGradingView = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [assessmentsPage, setAssessmentsPage] = useState(1);
  const [submissionsPage, setSubmissionsPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [modalState, setModalState] = React.useState<ModalState>(ModalState.CLOSED);
  const [selectedGradingData, setSelectedGradingData] = React.useState<GradingData | null>(null);
  const [dashboardData, setDashboardData] = React.useState<DashboardData[]>(initialDashboardData);
  const [selectedCourses, setSelectedCourses] = React.useState<string[]>([]);
  const [selectedModules, setSelectedModules] = React.useState<string[]>([]);

  const { data: meData } = useMeQuery();

  const [updateAssessmentGrade] = useUpdateAssessmentGradeMutation({
    refetchQueries: [
      {
        query: GetAssessmentByFacultyDocument,
        variables: {
          input: {
            facultyId: meData?.me?.id || "",
            query: searchQuery,
            page: assessmentsPage,
            pageSize: pageSize,
            courseIds: selectedCourses,
            moduleIds: selectedModules,
          },
        },
      },
    ],
  });
  const [updateStudentAssignmentGrade] = useUpdateStudentAssignmentGradeMutation({
    refetchQueries: [
      {
        query: GetUserSubmissionsByFacultyDocument,
        variables: {
          input: {
            facultyId: meData?.me?.id || "",
            query: searchQuery,
            page: submissionsPage,
            pageSize: pageSize,
            courseIds: selectedCourses,
            moduleIds: selectedModules,
          },
        },
      },
    ],
  });

  const { data: courseData, loading: courseLoading } = useGetCoursesByFacultyQuery({
    variables: {
      userId: meData?.me?.id,
    },
    skip: !meData?.me?.id,
  });

  const {
    data: assessmentData,
    refetch: refetchAssessmentData,
    loading: assessmentLoading,
  } = useGetAssessmentByFacultyQuery({
    variables: {
      input: {
        facultyId: meData?.me?.id || "",
        query: searchQuery,
        page: assessmentsPage,
        pageSize: pageSize,
        courseIds: selectedCourses,
        moduleIds: selectedModules,
      },
    },
    skip: !meData?.me?.id,
  });

  const {
    data: userSubmissionsData,
    refetch: refetchUserSubmissionsData,
    loading: userSubmissionsLoading,
  } = useGetUserSubmissionsByFacultyQuery({
    variables: {
      input: {
        facultyId: meData?.me?.id || "",
        query: searchQuery,
        page: submissionsPage,
        pageSize: pageSize,
        courseIds: selectedCourses,
        moduleIds: selectedModules,
      },
    },
    skip: !meData?.me?.id,
  });

  const filters = useMemo(() => {
    return {
      courses: courseData?.getCoursesByFaculty?.data?.map((course) => ({
        key: course.id,
        value: course.name,
      })),
      modules: courseData?.getCoursesByFaculty?.data
        ?.map((course) =>
          course.modules.map((module) => ({
            key: module.id,
            value: module.title,
          }))
        )
        .flat(),
    };
  }, [courseData]);

  useEffect(() => {
    setSelectedCourses(filters.courses?.map((course) => course.key));
    setSelectedModules(filters.modules?.map((module) => module.key));
  }, [courseData]);

  useEffect(() => {
    refetchAssessmentData();
    refetchUserSubmissionsData();
  }, [refetchAssessmentData, refetchUserSubmissionsData, meData]);

  const assessments = useMemo(
    () =>
      assessmentData?.getAssessmentByFaculty?.data?.assessments.map((assessment) => ({
        ...assessment,
        type: "Assessment",
      })),
    [assessmentData]
  );

  const submissions = useMemo(
    () =>
      userSubmissionsData?.getUserSubmissionsByFaculty?.data?.submissions.map((submission) => ({
        ...submission,
        type: "Assignment",
      })),
    [userSubmissionsData]
  );

  const handleViewResponse = (item: GradingData) => {
    setSelectedGradingData(item);
    if (item.type === "Assessment") {
      setModalState(ModalState.VIEW_ASSESSMENT_RESPONSE);
    } else {
      setModalState(ModalState.VIEW_ASSIGNMENT_RESPONSE);
    }
  };

  const handleSaveGrade = async (gradingData: GradingData, newScore: number, feedback?: string) => {
    setIsSubmitting(true);
    if (gradingData.type === "Assessment") {
      await updateAssessmentGrade({
        variables: {
          assessmentId: gradingData.id,
          grade: newScore,
        },
      });
    } else {
      await updateStudentAssignmentGrade({
        variables: {
          submissionId: gradingData.id,
          grade: newScore,
          feedback,
        },
      });
    }
    setModalState(ModalState.CLOSED);
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setModalState(ModalState.CLOSED);
    setSelectedGradingData(null);
  };

  const handleAssessmentsPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= assessments.length) {
      setAssessmentsPage(newPage);
    }
  };

  const handleSubmissionsPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= submissions.length) {
      setSubmissionsPage(newPage);
    }
  };

  useEffect(() => {
    setAssessmentsPage(1);
    setSubmissionsPage(1);
  }, [searchQuery]);

  useEffect(() => {
    refetchAssessmentData();
    refetchUserSubmissionsData();
  }, [searchQuery, assessmentsPage, submissionsPage, pageSize]);

  useEffect(() => {
    const pendingSubmissions = submissions?.filter((submission) => !submission.hasTeacherGraded);
    const pendingAssessments = assessments?.filter((assessment) => !assessment.hasTeacherGraded);

    const today = new Date();

    const newSubmissionsToday = submissions?.filter((submission) => {
      const submissionDate = new Date(submission.created);
      return submissionDate.toDateString() === today.toDateString();
    });

    const newAssessmentsToday = assessments?.filter((assessment) => {
      const assessmentDate = new Date(assessment.created);
      return assessmentDate.toDateString() === today.toDateString();
    });

    setDashboardData((prev) => [
      {
        ...prev[0],
        dataValue: (pendingSubmissions?.length + pendingAssessments?.length).toString(),
      },
      {
        ...prev[1],
      },
      {
        ...prev[2],
        dataValue: (newSubmissionsToday?.length + newAssessmentsToday?.length).toString(),
      },
    ]);
  }, [assessments, submissions]);

  return (
    <NoChatBarLayout>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        // @ts-expect-error - MotiView transition props have complex typing that's incompatible with simple objects
        transition={{ type: "timing", duration: 500 }}>
        <CustomText size="m" textType="display" text="Grading Center" weight="ultraBold" />
      </MotiView>
      <CustomSpacing type="vertical" size="xl" />
      <View style={styles.contentContainer}>
        <DashboardDataContainer dashboardData={dashboardData} />
        <View style={styles.filterContainer}>
          <View style={styles.searchContainer}>
            <MagnifyingGlass style={styles.searchIcon} />
            <CustomTextInput
              placeholder="Search by any value"
              value={searchQuery}
              onChangeText={setSearchQuery}
              style={[
                styles.searchInput,
                Platform.select({
                  web: {
                    outlineStyle: "none",
                    outlineWidth: 0,
                    WebkitAppearance: "none",
                  } as unknown,
                }),
              ]}
              placeholderTextColor="#9CA3AF"
              selectionColor="#6B7280"
              cursorColor="#6B7280"
            />
          </View>
          <View style={styles.dropdownFilterContainer}>
            <CustomMultiSelect
              data={filters.courses}
              selectedItems={selectedCourses}
              setSelectedItems={setSelectedCourses}
              label="Course"
            />
            <CustomMultiSelect
              data={filters.modules}
              selectedItems={selectedModules}
              setSelectedItems={setSelectedModules}
              label="Module"
            />
          </View>
        </View>
        <View style={styles.tableContainer}>
          <CustomText text="Assessments" style={styles.tableTitle} weight="bold" />
          <GradingTableContainer
            gradingData={assessments ?? []}
            page={assessmentsPage}
            pageSize={pageSize}
            handlePageChange={handleAssessmentsPageChange}
            totalCount={assessmentData?.getAssessmentByFaculty?.data?.totalCount}
            handleRowPress={handleViewResponse}
          />
        </View>
        <CustomSpacing type="vertical" size="xl" />
        <View style={styles.tableContainer}>
          <CustomText text="Assignment Submissions" style={styles.tableTitle} weight="bold" />
          <GradingTableContainer
            gradingData={submissions ?? []}
            page={submissionsPage}
            pageSize={pageSize}
            handlePageChange={handleSubmissionsPageChange}
            totalCount={userSubmissionsData?.getUserSubmissionsByFaculty?.data?.totalCount}
            handleRowPress={handleViewResponse}
          />
        </View>
      </View>
      <AssessmentGradingModal
        isVisible={modalState === ModalState.VIEW_ASSESSMENT_RESPONSE}
        onClose={handleCloseModal}
        gradingData={selectedGradingData}
        onSave={handleSaveGrade}
        isSubmitting={isSubmitting}
      />
      <AssignmentSubmissionGradingModal
        isVisible={modalState === ModalState.VIEW_ASSIGNMENT_RESPONSE}
        onClose={handleCloseModal}
        gradingData={selectedGradingData}
        onSave={handleSaveGrade}
        isSubmitting={isSubmitting}
      />
    </NoChatBarLayout>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    position: "relative",
    zIndex: 1,
  },
  filterContainer: {
    backgroundColor: "#f9fafb",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 16,
    zIndex: 50,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
    backgroundColor: "#FFF",
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
  dropdownFilterContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    position: "relative",
    zIndex: 50,
  },
  tableContainer: {
    marginTop: 12,
    paddingHorizontal: 12,
  },
  tableTitle: {
    fontSize: 16,
    color: "#344054",
  },
});

export default TeacherGradingView;
