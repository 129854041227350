import { useState, useEffect, useMemo, useContext } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useGetNotEnrolledCoursesBySchoolQuery } from "../graphql/generated/graphql";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import Loader from "../components/common/status/Loader/Loader";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { useAppConfig } from "../AppConfigProvider";
import CatalogueCard from "../components/customCoursework/CourseCatalogue/CatalogueCard";
import TabNavigator from "../components/customCoursework/CourseCatalogue/TabNavigator/TabNavigator";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import { CourseDetailsContext } from "../contexts/CourseDetailsContext";
import DropDownPicker from "react-native-dropdown-picker";

const tabs = [
  {
    key: "all",
    title: "All",
  },
  // {
  //   key: "general",
  //   title: "General",
  // },
  // {
  //   key: "core",
  //   title: "Core",
  // },
  // {
  //   key: "electives",
  //   title: "Electives",
  // },
];

const dropdownItems = [
  { label: "Price (Low to High)", value: "price-asc" },
  { label: "Price (High to Low)", value: "price-desc" },
  { label: "Name (A → Z)", value: "name-asc" },
  { label: "Name (Z → A)", value: "name-desc" },
];

function CourseCatalogueView() {
  const [activeTab, setActiveTab] = useState("all");
  const [sortBy, setSortBy] = useState("price-asc");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);
  const { setIsVisible, setCourseData, onEnroll, loading, courseData } = useContext(CourseDetailsContext);

  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const integrationId = appConfig.axioSchools[0];
  const { isMedium, isLarge } = useResponsiveScreen();

  const {
    data: courses,
    loading: coursesLoading,
    error: coursesError,
  } = useGetNotEnrolledCoursesBySchoolQuery({
    variables: {
      schoolId: integrationId,
    },
    skip: !integrationId,
  });

  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("Course Catalogue Page Visited");
  }, []);

  const handleDetailsPress = (course) => {
    setCourseData({
      id: course?.id,
      name: course?.name,
      description: course?.description,
      objectives: course?.objectives?.[0],
      targetAudience: course?.targetAudience,
      cost: course?.cost,
    });
    setIsVisible(true);
  };

  const handleEnrollPress = (courseId, hasCost) => {
    setSelectedCourse(courseId);
    onEnroll(courseId, hasCost);
  };

  const courseTypeMap = useMemo(() => {
    const map = new Map();

    map.set("all", courses?.getNotEnrolledCoursesBySchool?.data || []);

    courses?.getNotEnrolledCoursesBySchool?.data.forEach((item) => {
      if (!map.has(item.courseType?.toLowerCase())) {
        map.set(item.courseType?.toLowerCase(), []);
      }
      map.get(item.courseType?.toLowerCase())?.push(item);
    });

    return map;
  }, [courses?.getNotEnrolledCoursesBySchool?.data]);

  const filteredData = courseTypeMap.get(activeTab) || [];

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      switch (sortBy) {
        case "price-asc":
          return a.cost - b.cost;
        case "price-desc":
          return b.cost - a.cost;
        case "name-asc":
          return a.name.localeCompare(b.name);
        case "name-desc":
          return b.name.localeCompare(a.name);
        default:
          return 0;
      }
    });
  }, [filteredData, sortBy]);

  if (coursesLoading) {
    return (
      <NoChatBarLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </NoChatBarLayout>
    );
  }
  if (coursesError) {
    return (
      <NoChatBarLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </NoChatBarLayout>
    );
  }

  return (
    <NoChatBarLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
            <CustomText
              text={"Course Catalogue"}
              size={isMedium ? "m" : "xs"}
              textType="display"
              weight="ultraBold"
              role="heading"
              aria-level="1"
              style={{ letterSpacing: -0.72, color: "#101828" }}
            />
          </View>
          <CustomSpacing type="vertical" size="m" />
          <CustomText
            text="Discover courses tailored to your goals. Enroll in the classes that help you level up your skills."
            size="m"
            weight="normal"
            style={{ color: "#667085" }}
          />
        </View>
      </MotiView>
      <CustomSpacing type="vertical" size="xxl" />
      <CustomSpacing type="vertical" size="xs" />
      <View style={isLarge ? styles.filteringContainerMedium : styles.filteringContainer}>
        <TabNavigator
          tabData={tabs}
          activeTab={activeTab}
          onTabPress={(key) => {
            setActiveTab(key);
          }}
        />
        <View style={styles.sortByContainer}>
          <CustomText
            numberOfLines={1}
            ellipsizeMode="tail"
            size="s"
            weight="normal"
            style={{ color: "#667085", minWidth: 50 }}
            text="Sort by"
          />
          <DropDownPicker
            open={dropdownMenuVisible}
            value={sortBy}
            items={dropdownItems}
            setOpen={setDropdownMenuVisible}
            setValue={setSortBy}
            dropDownContainerStyle={styles.dropdownContainer}
            style={styles.dropdownField}
            textStyle={styles.dropdownText}
          />
        </View>
      </View>

      <CustomSpacing type="vertical" size="xl" />
      <View style={styles.divider} />
      <CustomSpacing type="vertical" size="xxl" />
      <CustomSpacing type="vertical" size="xs" />

      <View role="grid" aria-label="Course cards" style={isMedium ? styles.gridContainer : styles.flexContainer}>
        {sortedData.map((course, index) => (
          <CatalogueCard
            key={index}
            title={course?.name}
            cost={course?.cost}
            description={course?.description}
            imageUrl={course?.thumbnail}
            onEnrollPress={() => handleEnrollPress(course?.id, !!course?.cost)}
            onDetailsPress={() =>
              handleDetailsPress({
                ...course,
              })
            }
            isLoading={selectedCourse === course?.id && loading}
          />
        ))}
      </View>
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    rowGap: 28,
    columnGap: 16,
  },
  flexContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    rowGap: 28,
    columnGap: 16,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    height: 1,
    backgroundColor: "#EAECF0",
  },
  filteringContainerMedium: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 2000,
    rowGap: 0,
  },
  filteringContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    zIndex: 2000,
    rowGap: 12,
  },
  sortByContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: 8,
    zIndex: 2000,
  },
  dropdownField: {
    width: 164,
    borderColor: "#EAECF0",
    zIndex: 1000,
    elevation: 1000,
    height: 40,
  },
  dropdownContainer: {
    width: 164,
    borderColor: "#EAECF0",
    backgroundColor: "white",
  },
  dropdownText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "700",
    color: "#344054",
  },
});

export default CourseCatalogueView;
