import React, { useState } from "react";
import { View, StyleSheet, Platform, Linking, ScrollView } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import { EditTaskBlue, TrashCanBlue } from "../../components/svgs/common";
import { File } from "../../components/svgs/CustomCoursework";
import { WebView } from "react-native-webview";

function ActivityContainer({ activity, onEdit, onDelete }) {
  if (!activity) {
    return (
      <View style={styles.emptyContainer}>
        <CustomText text="No activity selected" style={styles.emptyText} />
      </View>
    );
  }

  const handleOpenURL = (url) => {
    if (Platform.OS === "web") {
      const win = window.open(url, "_blank");
      if (win) win.focus();
    } else {
      Linking.openURL(url).catch((err) => console.error("Failed to open URL:", err));
    }
  };

  const getVisualizationHtml = (visualization) => {
    // Simplify the meta tag a bit to avoid potential blocking issues.
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="Content-Security-Policy" content="default-src * 'unsafe-inline' 'unsafe-eval' data: gap:">
          <script src="https://cdn.jsdelivr.net/npm/p5@1.4.1/lib/p5.js"></script>
          <style>
            html, body {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #EAECF0;
              overflow: hidden;
            }
            #visualization-container {
              width: 100%;
              height: 100%;
            }
          </style>
        </head>
        <body>
          <div id="visualization-container"></div>
          <script>
            try {
              ${visualization.code}
            } catch (e) {
              console.error('p5.js Error:', e);
            }
          </script>
        </body>
      </html>
    `;
  };
  return (
    <View style={styles.container}>
      <View style={styles.activityHeader}>
        <View style={{ flexDirection: "column" }}>
          <CustomText text={activity.type} size="s" weight="medium" style={{ color: "#667085" }} />
          <CustomText
            text={activity.title}
            weight="bold"
            size="xl"
            useTranslationText={false}
            style={{ paddingBottom: 20 }}
          />
        </View>
        <View style={styles.buttonContainer}>
          {/* <CustomButton
            text="Edit"
            styleType="primaryLight"
            onPress={onEdit}
            size="s"
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            leftIcon={<EditTaskBlue width={16} height={16} style={{ marginRight: 4 }} />}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          /> */}
          <CustomSpacing type="horizontal" size="m" />
          <CustomButton
            text="Delete"
            styleType="primaryLight"
            onPress={onDelete}
            size="s"
            leftIcon={<TrashCanBlue width={16} height={16} />}
            style={{ borderColor: "#05060f1a", borderWidth: 1, height: 36 }}
            textStyle={{ fontSize: 14, fontWeight: 700 }}
          />
        </View>
      </View>

      <CustomSpacing type="vertical" size="m" />

      {/* Activity Instructions */}
      <CustomText text="Activity Instructions" weight="ultraBold" size="m" />
      <CustomSpacing type="vertical" size="xl" />
      <View style={{ flex: 1 }}>
        {/* <WebView source={{ html: activity?.description || "<p>No content available</p>" }} originWhitelist={["*"]} /> */}
        <CustomText
          text={activity?.description || "No content available"}
          size="m"
          style={styles.text}
          useTranslationText={false}
        />
      </View>

      <CustomSpacing type="vertical" size="xl" />

      {/* Only show Learning Objectives for non-Resource types */}
      {activity.type !== "Resource" && (
        <>
          <CustomText text="Learning Objectives" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="xl" />
          {activity.objectives?.length > 0 ? (
            activity.objectives.map((obj, index) => (
              <View key={obj.id} style={styles.objectiveContainer}>
                <CustomText
                  text={`${index + 1}. ${obj.objective}`}
                  size="m"
                  style={styles.text}
                  useTranslationText={false}
                />
                <CustomSpacing type="vertical" size="s" />
              </View>
            ))
          ) : (
            <CustomText
              text="No learning objectives available"
              size="m"
              style={styles.text}
              useTranslationText={false}
            />
          )}
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}

      {activity.objectives?.some((obj) => obj.videos?.length > 0) && (
        <>
          <CustomText text="Recommended Videos" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="xl" />

          {activity.objectives.map((obj) =>
            obj.videos?.length > 0 ? (
              <View key={obj.id} style={{ marginBottom: 32 }}>
                <CustomText
                  text={`🎯 ${obj.objective}`}
                  size="m"
                  weight="bold"
                  style={{ marginBottom: 12 }}
                  useTranslationText={false}
                />

                <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.videoRow}>
                  {obj.videos.map((video) => (
                    <View key={video.id} style={styles.videoCard}>
                      <CustomText
                        text={video.title}
                        size="s"
                        weight="medium"
                        style={{ marginBottom: 8, maxWidth: 300 }}
                        numberOfLines={2}
                        ellipsizeMode="tail"
                        useTranslationText={false}
                      />

                      {Platform.OS === "web" ? (
                        <WebView
                          style={styles.webview}
                          javaScriptEnabled
                          domStorageEnabled
                          source={{ uri: `https://www.youtube.com/embed/${video.videoId}` }}
                        />
                      ) : (
                        <CustomButton onPress={() => handleOpenURL(video.url)} style={styles.thumbnailButton}>
                          <img src={video.thumbnailUrl} alt={video.title} style={styles.thumbnailImage} />
                        </CustomButton>
                      )}
                    </View>
                  ))}
                </ScrollView>

                <CustomSpacing type="vertical" size="xl" />
              </View>
            ) : null
          )}
        </>
      )}

      {/* Interactive Visualization Section */}
      {activity.visualization && activity.visualization.code && (
        <View style={{}}>
          <CustomSpacing type="horizontal" size="m" />
          <CustomText text={"Interactive Visualization"} size="m" weight="ultraBold" />
          <CustomSpacing type="horizontal" size="xl" />
          <View style={styles.visualizationContainer}>
            <View style={styles.webViewContainer}>
              <WebView
                source={{ html: getVisualizationHtml(activity.visualization) }}
                originWhitelist={["*"]}
                style={styles.webView}
                javaScriptEnabled={true}
                domStorageEnabled={true}
                onError={(syntheticEvent) => {
                  const { nativeEvent } = syntheticEvent;
                  console.error("WebView error:", nativeEvent);
                }}
                onHttpError={(syntheticEvent) => {
                  const { nativeEvent } = syntheticEvent;
                  console.error("WebView HTTP error:", nativeEvent);
                }}
                startInLoadingState={true}
              />
            </View>
          </View>
        </View>
      )}

      {/* Files section */}
      {activity.type === "Resource" && activity.files?.length > 0 && (
        <>
          <CustomText text="Files" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="m" />
          {activity.files.map((file, index) => (
            <View key={index} style={styles.fileContainer}>
              <File width={16} height={16} style={{ paddingRight: 8 }} />
              <CustomText
                text={file.fileName}
                weight="bold"
                style={styles.fileText}
                useTranslationText={false}
                onPress={() => handleOpenURL(file.fileURL)}
              />
            </View>
          ))}
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}

      {/* Show Video URL for Resource type */}
      {activity.type === "Resource" && activity.videoUrl && (
        <>
          <CustomText text="Video URL" weight="ultraBold" size="m" />
          <CustomSpacing type="vertical" size="m" />
          <View>
            <CustomText
              text={activity.videoUrl}
              useTranslationText={false}
              style={styles.linkText}
              onPress={() => {
                if (Platform.OS === "web") {
                  const win = window.open(activity.videoUrl, "_blank");
                  if (win) win.focus();
                } else {
                  Linking.openURL(activity.videoUrl);
                }
              }}
            />
          </View>
          <CustomSpacing type="vertical" size="xl" />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  activityHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  text: {
    color: "#344054",
  },
  fileContainer: {
    padding: 10,
    backgroundColor: "#F9FAFB",
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 12,
  },
  fileText: {
    color: "#6B7280",
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
  },
  emptyText: {
    color: "#6B7280",
  },
  objectiveContainer: {
    paddingLeft: 8,
    marginBottom: 8,
  },
  linkText: {
    color: "blue",
    textDecorationLine: "underline",
    textAlign: "left",
    borderWidth: 0,
    // shadowColor: "transparent",
  },
  visualizationContainer: {
    marginTop: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
  },
  webViewContainer: {
    height: 700,
    width: "100%",
  },
  webView: {
    flex: 1,
    backgroundColor: "#EAECF0",
  },
  videoRow: {
    flexDirection: "row",
    gap: 16,
    paddingVertical: 4,
  },
  videoCard: {
    backgroundColor: "#FFFFFF",
    padding: 12,
    borderRadius: 12,
    borderColor: "#EAECF0",
    borderWidth: 1,
    marginRight: 16,
    width: 320,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.05,
    shadowRadius: 3,
    elevation: 1,
  },
  webview: {
    width: "100%",
    height: 180,
    borderRadius: 8,
    overflow: "hidden",
  },
  thumbnailImage: {
    width: "100%",
    height: 180,
    borderRadius: 8,
    objectFit: "cover",
  },
  thumbnailButton: {
    padding: 0,
    borderWidth: 0,
    backgroundColor: "transparent",
    width: "100%",
  },
});

export default ActivityContainer;
