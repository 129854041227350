import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { MotiView, AnimatePresence } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";
import ChatLoader from "../../common/status/ChatLoader/ChatLoader";

const ProcessingMessageBox = ({ message }) => {
  const [displayedMessage, setDisplayedMessage] = useState(message);

  useEffect(() => {
    if (message) {
      setDisplayedMessage(message);
    }
  }, [message]);

  return (
    <View style={styles.container}>
      {/* <View style={styles.containerBox}> */}
      <AnimatePresence>
        <MotiView
          key="loader"
          style={styles.loader}
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "timing", duration: 500 }}>
          <ChatLoader />
        </MotiView>
        {displayedMessage && (
          <MotiView
            key={displayedMessage}
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "timing", duration: 500 }}
            style={styles.messageContainer}>
            <CustomText useTranslationText={false} style={styles.messageText} size="s" text={displayedMessage} />
          </MotiView>
        )}
      </AnimatePresence>
      {/* </View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  loader: {
    position: "absolute",
    top: "50%", // Start at the middle
    transform: [{ translateY: -40 }], // Move up by 30px to sit above the text
  },
  messageContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  messageText: {
    textAlign: "center",
    color: "#333",
  },
});

export default ProcessingMessageBox;
