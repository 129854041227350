import React, { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Linking, Platform } from "react-native";
import WebView from "react-native-webview";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute, useFocusEffect, RouteProp } from "@react-navigation/native";
import FileCard from "../components/lms/FileCard/FileCard";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import Sentry from "../utils/sentry";
import { ExitDoor } from "../components/svgs/common";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import {
  FileStatus,
  StoragePathType,
  useGetAssignmentQuery,
  useGetUserSubmissionsQuery,
  useSubmitStudentAssignmentMutation,
  useGetCourseByIdQuery,
} from "../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import { useAppState } from "../contexts/AppStateContext";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import NewCustomAxioActionModal from "../containers/settings/UsersTabContainer/NewCustomAxioActionModal";
import useFileUpload from "../hooks/useFileUpload";
import { shadowStyles } from "../styles/shadow";

function CustomAssignmentStudentView() {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Custom Assignment Student">>();
  const { assignment_id: assignmentId, course_id: courseId, integration_id: integrationId } = route.params;

  const [submitStudentAssignment] = useSubmitStudentAssignmentMutation();
  const [submissionConfirmationModalVisible, setSubmissionConfirmationModalVisible] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const { dispatch } = useAppState();

  const { data: courseData } = useGetCourseByIdQuery({
    variables: { id: courseId },
  });

  const {
    data: assignmentData,
    loading: loadingAssignment,
    error: assignmentError,
  } = useGetAssignmentQuery({
    variables: { id: assignmentId },
  });

  const { data: userSubmissionsData, refetch: refetchUserSubmissions } = useGetUserSubmissionsQuery({
    variables: { assignmentId: assignmentId },
  });

  const [files, setFiles] = useState<
    Array<{
      id: string;
      fileName: string;
      fileURL: string;
      created: any;
      status: FileStatus;
    }>
  >([]);

  useEffect(() => {
    setFiles([]);
    refetchUserSubmissions();
  }, [refetchUserSubmissions, assignmentId]);

  useEffect(() => {
    if (userSubmissionsData?.getUserSubmissions?.data?.[0]?.files) {
      setFiles(userSubmissionsData?.getUserSubmissions?.data?.[0]?.files);
    }
  }, [userSubmissionsData]);

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: "SET_MEDIA",
        payload: {
          video: null,
          wiki: null,
        },
      });

      return () => {
        dispatch({
          type: "SET_MEDIA",
          payload: {
            video: null,
            wiki: null,
          },
        });
      };
    }, [dispatch])
  );

  const handleExitPress = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const extractYoutubeVideoId = (url) => {
    if (!url) return null;

    const patterns = [
      /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^&\n?#]+)/,
      /^[a-zA-Z0-9_-]{11}$/,
    ];

    for (const pattern of patterns) {
      const match = url?.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }
    return null;
  };

  const handleFileClick = async (file) => {
    try {
      handleOpenURL(file.fileURL);
    } catch (error) {
      console.error("Error handling file click:", error);
      handleOpenURL(file.fileURL);
    }
  };

  const handleBeforeUpload = () => {
    setPendingSubmission(true);
  };

  const handleAfterUpload = ({ id, fileName, fileURL }) => {
    setFiles((prev) => [...prev, { id, fileName, fileURL, created: new Date(), status: FileStatus.Pending }]);
    setPendingSubmission(false);
  };

  const handleFileUploadError = () => {
    setPendingSubmission(false);
  };

  const { renderFileUpload } = useFileUpload({
    storagePath: StoragePathType.ResourceDocument,
    beforeUpload: handleBeforeUpload,
    afterUpload: handleAfterUpload,
    onError: handleFileUploadError,
  });

  const handleSubmitAssignment = async () => {
    try {
      await submitStudentAssignment({
        variables: {
          assignmentId: assignmentId,
          files: files.map((file) => ({ fileId: file.id, fileName: file.fileName, fileURL: file.fileURL })),
        },
      });
      setSubmissionConfirmationModalVisible(true);
      setPendingSubmission(false);
    } catch (error) {
      console.error("Error submitting assignment:", error);
    }
  };

  const handleCloseSubmissionModal = () => {
    setSubmissionConfirmationModalVisible(false);
    refetchUserSubmissions();
  };

  const formatDate = (isoDateString) => {
    if (!isoDateString) return "";

    const date = new Date(isoDateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const renderSubmittedAssignments = () => {
    if (!files || files.length === 0) {
      return (
        <View style={styles.noSubmissionsContainer}>
          <CustomText
            text="Looks like you haven't submitted anything for this assignment."
            size="m"
            useTranslationText={false}
            style={{ justifyContent: "center", textAlign: "center" }}
          />
        </View>
      );
    }

    return (
      <View style={styles.filesContainer}>
        {files.map((file) => (
          <FileCard key={file.id} title={file.fileName} url={file.fileURL} onPress={() => handleFileClick(file)} />
        ))}
      </View>
    );
  };

  const renderSkeletonLoader = () => (
    <View style={styles.skeletonContainer}>
      <View style={styles.detailsContainer}>
        <View style={styles.headerContainer}>
          <Skeleton width={40} height={40} circle={true} />
          <CustomSpacing type="horizontal" size="m" />
          <Skeleton width="60%" height={24} />
        </View>
        <CustomSpacing type="vertical" size="m" />
        <CustomSpacing type="vertical" size="l" />
        <Skeleton width="30%" height={40} borderRadius={8} />
        <CustomSpacing type="vertical" size="l" />
        <Skeleton width="100%" height={200} borderRadius={12} />
      </View>
    </View>
  );

  const handleOpenURL = async (url) => {
    if (Platform.OS === "web") {
      try {
        const token = await AsyncStorage.getItem("token");
        const form = document.createElement("form");
        form.method = "POST";
        form.action = url;
        form.target = "_blank";

        const tokenInput = document.createElement("input");
        tokenInput.type = "hidden";
        tokenInput.name = "token";
        tokenInput.value = token;
        form.appendChild(tokenInput);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      } catch (err) {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      }
    } else {
      try {
        const token = await AsyncStorage.getItem("token");
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `token=${encodeURIComponent(token)}`,
        });
        const finalUrl = response.url;
        await Linking.openURL(finalUrl);
      } catch (err) {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      }
    }
  };

  const AssignmentYoutubePlayer = ({ videoId }) => {
    return (
      <View style={styles.fullWidthVideoContainer}>
        <WebView
          style={styles.webView}
          source={{
            uri: `https://www.youtube.com/embed/${videoId}`,
          }}
          javaScriptEnabled={true}
          allowsFullscreenVideo={true}
        />
      </View>
    );
  };

  if (assignmentError) {
    return (
      <View style={styles.errorContainer}>
        <CustomText text="Failed to load Assignment data." color="red" />
      </View>
    );
  }

  const renderMainContent = () => {
    if (loadingAssignment) {
      return renderSkeletonLoader();
    }

    return (
      <View style={styles.container}>
        <View style={styles.contentRow}>
          <View style={styles.leftColumn}>
            <CustomSpacing type="vertical" size="l" />

            {assignmentData?.getAssignment?.data?.[0]?.description && (
              <View style={styles.descriptionContainer}>
                <CustomText text="Instructions:" size="m" weight="ultraBold" useTranslationText={false} />
                <CustomSpacing type="vertical" size="s" />
                <CustomHtml html={assignmentData.getAssignment.data[0].description} />
              </View>
            )}

            {assignmentData?.getAssignment?.data?.[0]?.videoUrl && (
              <View style={styles.sectionContainer}>
                <CustomText text="Video" size="m" weight="ultraBold" useTranslationText={false} />
                <CustomSpacing type="vertical" size="s" />
                {extractYoutubeVideoId(assignmentData?.getAssignment?.data?.[0]?.videoUrl) ? (
                  <AssignmentYoutubePlayer
                    videoId={extractYoutubeVideoId(assignmentData?.getAssignment?.data?.[0]?.videoUrl)}
                  />
                ) : (
                  <CustomText
                    text={assignmentData?.getAssignment?.data?.[0]?.videoUrl}
                    useTranslationText={false}
                    style={styles.linkText}
                    onPress={() => handleOpenURL(assignmentData?.getAssignment?.data?.[0]?.videoUrl)}
                  />
                )}
              </View>
            )}
          </View>

          <View style={styles.rightColumn}>
            {assignmentData?.getAssignment?.data?.[0]?.files?.length > 0 && (
              <View style={styles.sectionContainer}>
                <CustomText text="Assignment Resources" size="m" weight="ultraBold" useTranslationText={false} />
                <CustomSpacing type="vertical" size="s" />
                <View style={styles.filesContainer}>
                  {assignmentData.getAssignment.data[0].files.map((file) => (
                    <FileCard
                      key={file.id}
                      title={file.fileName}
                      url={file.fileURL}
                      onPress={() => handleFileClick(file)}
                    />
                  ))}
                </View>
              </View>
            )}

            {assignmentData?.getAssignment?.data?.[0]?.enableStudentFileUpload && (
              <View style={styles.sectionContainer}>
                <CustomText text="Upload Assignment" size="m" weight="ultraBold" useTranslationText={false} />
                <CustomSpacing type="vertical" size="m" />
                <View style={styles.uploadContainer}>
                  {renderFileUpload()}
                  <CustomText
                    text="Max file size: 5mb"
                    size="s"
                    style={styles.maxFileSizeText}
                    useTranslationText={false}
                  />
                </View>

                <CustomSpacing type="vertical" size="m" />

                <CustomText
                  text="You can submit your assignment once. You cannot change your submission once submitted."
                  size="s"
                  style={styles.submissionInfoText}
                  useTranslationText={false}
                />

                <CustomSpacing type="vertical" size="m" />

                <CustomButton
                  disabled={pendingSubmission || userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted}
                  text="Submit Assignment"
                  size="m"
                  onPress={handleSubmitAssignment}
                  style={
                    pendingSubmission || userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted
                      ? styles.disabledButton
                      : styles.submitButton
                  }
                  textStyle={
                    pendingSubmission || userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted
                      ? { color: "#475467" }
                      : { color: "#FFFFFF" }
                  }
                />
              </View>
            )}

            <View style={styles.sectionContainer}>
              <CustomText text="Submitted Assignments" size="m" weight="ultraBold" useTranslationText={false} />
              <CustomSpacing type="vertical" size="m" />
              {renderSubmittedAssignments()}
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.pageContainer}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <View style={styles.exitButtonContainer}>
            <CustomButton
              leftIcon={<ExitDoor />}
              onPress={handleExitPress}
              size="s"
              styleType="transparent"
              text=""
              rightIcon={null}
            />
          </View>
          <CustomSpacing type="horizontal" size="m" />
          <View style={styles.titleContainer}>
            <CustomText
              text={assignmentData?.getAssignment?.data?.[0]?.name || "Loading Assignment..."}
              useTranslationText={false}
              weight="bold"
              size="m"
            />
            {assignmentData?.getAssignment?.data?.[0]?.due_date && (
              <RoundedLabel
                text={`Due ${formatDate(assignmentData?.getAssignment?.data?.[0]?.due_date)}`}
                style={styles.dueLabel}
                textStyle={styles.dueLabelText}
                padding={[8, 6]}
                isRounded={true}
              />
            )}
          </View>
        </View>
        <View style={styles.headerRight}></View>
      </View>

      {renderMainContent()}

      <NewCustomAxioActionModal
        visible={submissionConfirmationModalVisible}
        onClose={handleCloseSubmissionModal}
        modalTexts={{
          title: "Submission Confirmation",
          warningText: "You have successfully submitted your assignment.",
          ariaLabel: "Submission Confirmation",
          actionButtonText: "Close",
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
    backgroundColor: "#FFFFFF",
    ...shadowStyles.smallShadow,
  },
  headerLeft: {
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  headerRight: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingRight: 20,
  },
  exitButtonContainer: {
    borderRightColor: "#D0D5DD",
    borderRightWidth: 1,
    paddingHorizontal: 10,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  dueLabel: {
    backgroundColor: "#EAECF0",
    borderRadius: 360,
  },
  dueLabelText: {
    color: "#667085",
    fontSize: 12,
    fontWeight: "700",
  },
  container: {
    flex: 1,
    padding: 20,
    paddingHorizontal: 40,
  },
  contentRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  leftColumn: {
    width: "74%",
    paddingRight: 40,
    minWidth: 300,
  },
  rightColumn: {
    width: "25%",
    minWidth: 250,
  },
  descriptionContainer: {
    marginBottom: 20,
  },
  sectionContainer: {
    marginBottom: 30,
  },
  uploadContainer: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderStyle: "dashed",
    borderRadius: 6,
    padding: 8,
    marginBottom: 15,
    backgroundColor: "#F9FAFB",
    alignItems: "center",
    justifyContent: "center",
  },
  maxFileSizeText: {
    color: "#6B7280",
    marginTop: 10,
  },
  submissionInfoText: {
    color: "#6B7280",
  },
  submitButton: {
    backgroundColor: "#3E68FE",
    width: "100%",
  },
  disabledButton: {
    backgroundColor: "#EAECF0",
    width: "100%",
  },
  filesContainer: {
    marginBottom: 10,
    flexDirection: "column",
    gap: 10,
    width: "100%",
  },
  noSubmissionsContainer: {
    padding: 16,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    marginBottom: 20,
  },
  linkText: {
    color: "#3B82F6",
    textDecorationLine: "underline",
  },
  skeletonContainer: {
    flex: 1,
    padding: 20,
  },
  detailsContainer: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  fullWidthVideoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
    marginBottom: 20,
    overflow: "hidden",
    borderRadius: 8,
    ...shadowStyles.smallShadow,
  },
  webView: {
    width: "100%",
    height: "100%",
  },
});

export default CustomAssignmentStudentView;
