import React from "react";
import { View, StyleSheet, Platform, Pressable } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { LeftArrow, MagnifyingGlass } from "../../../components/svgs/common";
import CourseRosterTable from "./CourseRosterTable";
import { MinusCircleIcon, PlusCircle } from "../../../components/svgIcons";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import {
  Course,
  useGetStudentsByCourseIdQuery,
  useInviteUsersToEnrollInCourseMutation,
  useRemoveStudentsFromCourseMutation,
} from "../../../graphql/generated/graphql";
import Pagination from "../../../components/common/Pagination/Pagination";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import InviteStudentModalContainer from "./InviteStudentModalContainer";
import Pill from "../../../components/common/Pill/Pill";
import { CourseTypes } from "../CoursesTabContainer/CoursesTable";

const modals = {
  removeStudents: {
    title: "Remove Selected Students from the Course?",
    ariaLabel: "Remove Students From Course Modal",
    warningText:
      "This action cannot be undone. These students will lose access to course materials and activities immediately.",
    actionButtonText: "Remove Students",
  },
  removeConfirmation: {
    title: "Students Removed",
    ariaLabel: "Students Removed Modal",
    warningText: "The selected students have been removed from the course.",
    actionButtonText: "OK",
  },
};

export enum CourseRosterModalState {
  CLOSED = "CLOSED",
  INVITE_STUDENTS = "INVITE_STUDENTS",
  REMOVE_STUDENTS = "REMOVE_STUDENTS",
  REMOVE_CONFIRMATION = "REMOVE_CONFIRMATION",
}

interface CourseRosterTabContainerProps {
  customColor: string;
  course: Pick<Course, "id" | "name" | "courseType">;
  onBack: () => void;
}

const CourseRosterTabContainer: React.FC<CourseRosterTabContainerProps> = ({ customColor, course, onBack }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const courseType = CourseTypes[course.courseType] || CourseTypes.General;

  const [searchQuery, setSearchQuery] = useState("");
  const { isXLarge } = useResponsiveScreen();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [modalState, setModalState] = useState<{
    type: CourseRosterModalState;
  }>({
    type: CourseRosterModalState.CLOSED,
  });

  const { data: getStudentsByCourseIdData, refetch: getStudentsByCourseIdRefetch } = useGetStudentsByCourseIdQuery({
    variables: {
      courseId: course.id,
      query: searchQuery,
      page: page,
      pageSize: pageSize,
    },
    skip: !course.id,
  });

  const [inviteUsersToEnrollInCourse] = useInviteUsersToEnrollInCourseMutation();
  const [removeStudentsFromCourse] = useRemoveStudentsFromCourseMutation();

  const inviteStudents = async (students: { name: string; email: string }[]) => {
    setIsSubmitting(true);
    try {
      const result = await inviteUsersToEnrollInCourse({
        variables: {
          input: {
            courseId: course.id,
            users: students,
          },
        },
      });

      if (result.data?.inviteUsersToEnrollInCourse.success) {
        getStudentsByCourseIdRefetch();
        setIsSubmitting(false);
        return true;
      }
      setIsSubmitting(false);
      return false;
    } catch (error) {
      setIsSubmitting(false);
      return false;
    }
  };

  const handleRemoveStudents = async () => {
    if (!selectedStudents.length) return;

    try {
      await removeStudentsFromCourse({
        variables: {
          courseId: course.id,
          studentIds: selectedStudents,
        },
      });
      getStudentsByCourseIdRefetch();
      setSelectedStudents([]);
      setModalState({ type: CourseRosterModalState.REMOVE_CONFIRMATION });
    } catch (error) {
      console.error("Error removing students:", error);
    }
  };

  const handleModalClose = () => {
    setModalState({ type: CourseRosterModalState.CLOSED });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= (getStudentsByCourseIdData?.getStudentsByCourseId.count || 0)) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    getStudentsByCourseIdRefetch();
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <Pressable style={styles.backButton} onPress={onBack}>
            <LeftArrow />
            <CustomText text="Back" size="m" weight="semiBold" style={styles.backText} onPress={onBack} />
          </Pressable>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <Pill
                text={courseType.name}
                leftIcon={courseType.icon}
                backgroundColor={courseType.color}
                textColor={courseType.textColor}
              />
              <CustomText
                text={course.name}
                size="l"
                weight="bold"
                style={styles.headerText}
                useTranslationText={false}
              />
            </View>
            <NewCustomActionButton
              text="Invite Students"
              onPress={() => setModalState({ type: CourseRosterModalState.INVITE_STUDENTS })}
              leftIcon={<PlusCircle color={customColor} />}
            />
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Total Students" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${getStudentsByCourseIdData?.getStudentsByCourseId.count || 0})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
              {selectedStudents.length > 0 && (
                <CustomButton
                  text={`Remove Selected`}
                  styleType="danger"
                  onPress={() => setModalState({ type: CourseRosterModalState.REMOVE_STUDENTS })}
                  leftIcon={<MinusCircleIcon />}
                  rightIcon={null}
                  style={{}}
                  textStyle={{ color: "#fff", fontWeight: "bold" }}
                  disabled={false}
                  textProps={{}}
                />
              )}
            </View>
            <View style={styles.searchContainer}>
              <MagnifyingGlass style={styles.searchIcon} />
              <CustomTextInput
                placeholder="Search by any value"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={[
                  styles.searchInput,
                  Platform.select({
                    web: {
                      outlineStyle: "none",
                      outlineWidth: 0,
                      WebkitAppearance: "none",
                    } as unknown,
                  }),
                ]}
                placeholderTextColor="#9CA3AF"
                selectionColor="#6B7280"
                cursorColor="#6B7280"
              />
            </View>
          </View>
        </View>
        <CourseRosterTable
          students={getStudentsByCourseIdData?.getStudentsByCourseId.data || []}
          setSelectedStudents={setSelectedStudents}
          selectedStudents={selectedStudents}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil((getStudentsByCourseIdData?.getStudentsByCourseId.count || 0) / pageSize)}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.type === CourseRosterModalState.REMOVE_STUDENTS}
        onClose={handleModalClose}
        onAction={handleRemoveStudents}
        modalTexts={modals.removeStudents}
      />
      <NewCustomAxioActionModal
        visible={modalState.type === CourseRosterModalState.REMOVE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.removeConfirmation}
      />
      <InviteStudentModalContainer
        visible={modalState.type === CourseRosterModalState.INVITE_STUDENTS}
        onClose={handleModalClose}
        onSubmit={inviteStudents}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  backButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  backText: {
    color: "#000",
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  headerText: {
    color: "#000",
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  subHeaderText: {
    color: "#000",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});

export default CourseRosterTabContainer;
