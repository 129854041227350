import { StyleSheet } from "react-native";
import LottieView from "lottie-react-native";

function ChatLoader() {
  return (
    <LottieView
      autoPlay
      loop={true}
      style={styles.lottie}
      source={{ ...require("../../../../assets/chat_loader.json") }}
    />
  );
}

const styles = StyleSheet.create({
  lottie: {
    width: 150,
    height: 150,
  },
});

export default ChatLoader;
