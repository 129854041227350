import React from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import CustomText from "../../general/CustomText/CustomText";
import CustomButton from "../../general/CustomButton/CustomButton";

const LoadingOverlay = ({ visible, step, onCancel, isCancellable = true, message, progress }) => {
  if (!visible) return null;

  return (
    <View style={styles.overlay}>
      <View style={styles.content}>
        <ActivityIndicator size="large" color="#0066CC" />
        <CustomText
          text={message || "Generating content..."}
          style={styles.message}
          useTranslationText={false}
          size="m"
        />
        {step && (
          <CustomText text={`Step ${progress}: ${step}`} style={styles.stepText} size="s" useTranslationText={false} />
        )}
        {isCancellable && (
          <CustomButton text="Cancel" onPress={onCancel} styleType="text" style={styles.cancelButton} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  content: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  message: {
    marginTop: 16,
    textAlign: "center",
  },
  stepText: {
    marginTop: 8,
    color: "#666",
    textAlign: "center",
  },
  cancelButton: {
    marginTop: 16,
  },
});

export default LoadingOverlay;
