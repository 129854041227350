import { StyleSheet, View } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { DataTable } from "react-native-paper";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import Pagination from "../../../components/common/Pagination/Pagination";
import { GradingData } from "../../../views/TeacherGradingView";
import { Background } from "victory-native";

interface GradingTableContainerProps {
  gradingData: GradingData[];
  page: number;
  pageSize: number;
  handlePageChange: (page: number) => void;
  totalCount: number;
  handleRowPress: (item: GradingData) => void;
}

const GradingTableContainer = ({
  gradingData,
  page,
  pageSize,
  handlePageChange,
  totalCount,
  handleRowPress,
}: GradingTableContainerProps) => {
  // Format date to readable string
  const formatDate = (dateString?: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  };

  // Get status based on hasTeacherGraded
  const getStatus = (hasTeacherGraded?: boolean) => {
    return hasTeacherGraded ? "Completed" : "Needs Review";
  };

  // Get status style based on status
  const getStatusStyle = (hasTeacherGraded?: boolean) => {
    return {
      backgroundColor: hasTeacherGraded ? "#4BA30D" : "#F04438",
      fontWeight: "500",
    };
  };

  return (
    <View style={styles.container}>
      <View style={styles.tableContainer}>
        <DataTable>
          <DataTable.Header style={styles.tableHeader}>
            <DataTable.Title style={{ flex: 2 }}>
              <CustomText text="Student" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 2 }}>
              <CustomText text="Course" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1.5 }}>
              <CustomText text="Module" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1.5 }}>
              <CustomText text="Submitted on" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1.5 }}>
              <CustomText text="Status" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1 }}>
              <CustomText text="Grade" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
            <DataTable.Title style={{ flex: 1.5 }}>
              <CustomText text="Action" style={styles.headerText} weight="bold" useTranslationText={false} />
            </DataTable.Title>
          </DataTable.Header>

          {gradingData.length === 0 && (
            <DataTable.Row>
              <DataTable.Cell>
                <CustomText text="No data found" style={styles.cellText} useTranslationText={false} />
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {gradingData.map((item, index) => (
            <DataTable.Row key={index} style={styles.tableRow}>
              <DataTable.Cell style={{ flex: 2 }}>
                <CustomText text={item.userName || "-"} style={styles.cellText} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 2 }}>
                <CustomText text={item.courseName || "-"} style={styles.cellText} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>
                <CustomText text={item.moduleName || "-"} style={styles.cellText} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>
                <CustomText text={formatDate(item.created)} style={styles.cellText} useTranslationText={false} />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>
                <CustomText
                  text={getStatus(item.hasTeacherGraded)}
                  style={[
                    styles.cellText,
                    { color: "#F9FAFB", paddingHorizontal: 12, paddingVertical: 4, borderRadius: 16 },
                    getStatusStyle(item.hasTeacherGraded),
                  ]}
                  useTranslationText={false}
                />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1 }}>
                <CustomText
                  text={item.grade ? `${item.grade}/100` : "-"}
                  style={styles.cellText}
                  useTranslationText={false}
                />
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 1.5 }}>
                <View style={styles.buttonContainer}>
                  <CustomButton
                    text="Grade"
                    styleType="primary"
                    size="s"
                    onPress={() => handleRowPress(item)}
                    style={styles.actionButton}
                    useTranslationText={false}
                  />
                </View>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
        <Pagination page={page} handlePageChange={handlePageChange} totalPageCount={Math.ceil(totalCount / pageSize)} />
      </View>
    </View>
  );
};

export default GradingTableContainer;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 24,
    width: "100%",
  },
  tableContainer: {
    padding: 20,
    paddingBottom: 48,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: "#F9FAFB",
    borderBottomWidth: 1,
    borderBottomColor: "#EAECF0",
  },
  headerText: {
    fontSize: 14,
    fontWeight: 700,
    color: "#344054",
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: "#EAECF0",
  },
  cellText: {
    fontSize: 14,
    color: "#344054",
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
  },
  actionButton: {
    minWidth: 120,
    height: 36,
  },
});
