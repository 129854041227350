import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import AssessmentMenu from "../../../components/navigation/AssessmentMenu/AssessmentMenu";
import { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket from "../../../hooks/useWebSocket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useGetAssessmentQuery } from "../../../graphql/generated/graphql";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

function decrementTime(time) {
  const [minutes, seconds] = time.split(":").map(Number);
  const totalSeconds = minutes * 60 + seconds - 1;
  return formatTime(totalSeconds);
}

function AssessmentMenuContainer({ onComplete, handleExitPress, defaultTime }) {
  const { state, dispatch } = useAppState();
  const { sendMessage, socket } = useWebSocket();
  const navigation = useNavigation();
  const route = useRoute();
  const hasMounted = useRef(false);
  const assessmentId = route.params?.assessment_id;
  const courseId = route.params?.course_id;
  const integrationId = route.params?.integration_id;

  const remainingTime = state?.assessment?.assessmentRemainingTime;
  const activeAssessmentId = state?.assessment?.assessmentId;

  const [displayTime, setDisplayTime] = useState(remainingTime || `${defaultTime}:00`);

  const assessmentIsRunning = remainingTime && remainingTime !== "00:00" && !!activeAssessmentId;

  const { data: assessment } = useGetAssessmentQuery({
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });

  useEffect(() => {
    const loadData = async () => {
      const token = await AsyncStorage.getItem("token");
      dispatch({ type: actions.SET_ASSESSMENT_TIMER_LOADING, payload: true });
      sendMessage({
        meta: { token: token, action: "REQUEST_ASSESSMENT_TIME" },
      });
    };

    loadData();
  }, []);

  useFocusEffect(
    useCallback(() => {
      const loadData = async () => {
        const token = await AsyncStorage.getItem("token");
        dispatch({ type: actions.SET_ASSESSMENT_TIMER_LOADING, payload: true });
        sendMessage({
          meta: { token: token, action: "REQUEST_ASSESSMENT_TIME" },
        });
      };

      if (assessmentIsRunning) {
        loadData();
      }
    }, [assessmentIsRunning])
  );

  useFocusEffect(
    useCallback(() => {
      if (!remainingTime || activeAssessmentId !== assessmentId) {
        setDisplayTime(`${defaultTime}:00`);
        return;
      }

      let intervalId;

      setDisplayTime(remainingTime || `${defaultTime}:00`);

      intervalId = setInterval(() => {
        setDisplayTime((prev) => {
          if (prev === "00:00") {
            clearInterval(intervalId);
            return prev;
          }
          return decrementTime(prev);
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [assessmentId, remainingTime, activeAssessmentId])
  );

  const socketReadyState = socket && socket.readyState;

  useFocusEffect(
    useCallback(() => {
      if (!hasMounted.current) {
        hasMounted.current = true;
        return;
      }

      if (displayTime === "00:00") {
        onComplete();
      }
    }, [displayTime])
  );

  return (
    <AssessmentMenu
      handleExitPress={handleExitPress}
      remainingTime={displayTime}
      moduleName={assessment?.getAssessment?.data[0]?.moduleHasAssessment?.title ?? ""}
    />
  );
}

export default AssessmentMenuContainer;
