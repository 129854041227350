import SlideInMenu from "../../../components/navigation/SlideInMenu/SlideInMenu";
import { useProgressStats } from "../../../hooks/useProgressStats";
import { useAuth } from "../../../hooks/useAuth";
import { useAppConfig } from "../../../AppConfigProvider";

function SlideInMenuContainer({ courses, courseIds, visible, slideAnim, onClose, onSettingsPress }) {
  const { logout } = useAuth();
  const { progressStats, loading, degree } = useProgressStats(courses, courseIds);
  const appConfig = useAppConfig();
  const hasDegrees = appConfig.hasDegrees;

  const handleLogout = () => {
    logout();
  };

  return (
    <SlideInMenu
      visible={visible}
      slideAnim={slideAnim}
      onClose={onClose}
      hasDegrees={hasDegrees}
      onSettingsPress={onSettingsPress}
      progressStats={progressStats}
      degreeName={degree?.name}
      loading={loading}
      onLogout={handleLogout}
    />
  );
}
export default SlideInMenuContainer;
