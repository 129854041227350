import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props, fill = "#98A2B3") {
  return (
    <Svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.332 1.982l-.019 5.046v.022a1.25 1.25 0 00-.87.648l-2.896 5.199-.731-1.107a1.25 1.25 0 00-2.145.1L.235 16.434c-.684 1.277.24 2.822 1.69 2.822H18.13c1.463 0 2.387-1.573 1.673-2.85l-2.567-4.598a1.25 1.25 0 00-2.09-.143l-.787 1.045-2.795-5.013a1.25 1.25 0 00-.832-.64V5.382h3.507c.59 0 .92-.733.55-1.227l-.776-1.04.776-1.038c.37-.494.04-1.227-.55-1.227h-3.85c-.585 0-1.058.507-1.058 1.133zm1.325 2.065l.007-1.865h2.383l-.697.933.697.932h-2.39zm4.473 10.134l1.874 3.576h1.127a.417.417 0 00.364-.62l-2.38-4.262-.985 1.306zM3 17.757l1.753-3.343-.956-1.447-2.239 4.177a.417.417 0 00.367.613H3zm1.697-.006l3.146-6 1.536 1.031a1.25 1.25 0 001.43-.025l1.365-.988 3.134 5.982H4.696zm5.373-6.311l-1.527-1.026L9.7 8.528h.606l1.164 1.898-1.4 1.014z"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgComponent;
