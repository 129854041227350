export const decodeHTMLEntities = (text: string): string => {
  if (!text) return "";

  const entities = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
  };

  return text.replace(/&amp;|&lt;|&gt;|&quot;|&#39;/g, (match) => entities[match]);
};
