import React from "react";
import { View, StyleSheet, Platform, Pressable } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useEffect, useState } from "react";
import { LeftArrow, MagnifyingGlass } from "../../../components/svgs/common";
import { MinusCircleIcon, PlusCircle } from "../../../components/svgIcons";
import CustomTextInput from "../../../components/common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import {
  Degree,
  useGetCoursesByDegreeByQueryQuery,
  useAddCoursesToDegreeMutation,
  useRemoveCoursesFromDegreeMutation,
  GetDegreesBySchoolByQueryDocument,
  useSchoolsQuery,
  Course,
} from "../../../graphql/generated/graphql";
import Pagination from "../../../components/common/Pagination/Pagination";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { NewCustomActionButton } from "../../../components/common/general/CustomButton/NewCustomActionButton";
import { useAppConfig } from "../../../AppConfigProvider";
import DegreeCoursesTable from "./DegreeCoursesTable";
import AddCoursesModalContainer from "./AddCoursesModalContainer";

const modals = {
  removeCourses: {
    title: "Remove Selected Courses from the Degree?",
    ariaLabel: "Remove Courses From Degree Modal",
    warningText: "This action cannot be undone. These courses will be removed from the degree.",
    actionButtonText: "Remove Courses",
  },
  removeConfirmation: {
    title: "Courses Removed",
    ariaLabel: "Courses Removed Modal",
    warningText: "The selected courses have been removed from the degree.",
    actionButtonText: "OK",
  },
};

export enum DegreeCoursesModalState {
  CLOSED = "CLOSED",
  ADD_COURSES = "ADD_COURSES",
  REMOVE_COURSES = "REMOVE_COURSES",
  REMOVE_CONFIRMATION = "REMOVE_CONFIRMATION",
}

interface DegreeCoursesTabContainerProps {
  degree: Pick<Degree, "id" | "name"> & {
    coursesHasCourse: Array<Pick<Course, "id" | "name">>;
  };
  onBack: () => void;
}

const DegreeCoursesTabContainer: React.FC<DegreeCoursesTabContainerProps> = ({ degree, onBack }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [modalState, setModalState] = useState<{
    type: DegreeCoursesModalState;
  }>({
    type: DegreeCoursesModalState.CLOSED,
  });

  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  const { data: getCoursesByDegreeData, refetch: getCoursesByDegreeRefetch } = useGetCoursesByDegreeByQueryQuery({
    variables: {
      degreeId: degree.id,
      query: searchQuery,
      page: page,
      pageSize: pageSize,
    },
    skip: !degree.id,
  });

  const [addCoursesToDegree] = useAddCoursesToDegreeMutation();
  const [removeCoursesFromDegree] = useRemoveCoursesFromDegreeMutation();

  const { data: schoolsData } = useSchoolsQuery();

  const refetchQueries = [
    {
      query: GetDegreesBySchoolByQueryDocument,
      variables: {
        schoolId: schoolsData?.getSchools[0].id,
        query: "",
        page: 1,
        pageSize: 5,
      },
    },
  ];

  const addCourses = async (courseIds: Array<string>) => {
    try {
      const result = await addCoursesToDegree({
        variables: {
          input: {
            degreeId: degree.id,
            courseIds,
          },
        },
        refetchQueries,
      });

      if (result?.data.addCoursesToDegree.success) {
        getCoursesByDegreeRefetch();
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const handleRemoveCourses = async () => {
    if (!selectedCourses.length) return;

    try {
      await removeCoursesFromDegree({
        variables: {
          input: {
            degreeId: degree.id,
            courseIds: selectedCourses,
          },
        },
        refetchQueries,
      });
      getCoursesByDegreeRefetch();
      setSelectedCourses([]);
      setModalState({ type: DegreeCoursesModalState.REMOVE_CONFIRMATION });
    } catch (error) {
      console.error("Error removing students:", error);
    }
  };

  const handleModalClose = () => {
    setModalState({ type: DegreeCoursesModalState.CLOSED });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= (getCoursesByDegreeData?.getCoursesByDegreeByQuery.count || 0)) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    getCoursesByDegreeRefetch();
  }, [searchQuery, page, pageSize]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <Pressable style={styles.backButton} onPress={onBack}>
            <LeftArrow />
            <CustomText text="Back" size="m" weight="semiBold" style={styles.backText} onPress={onBack} />
          </Pressable>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText
                text={degree.name}
                size="l"
                weight="bold"
                style={styles.headerText}
                useTranslationText={false}
              />
            </View>
            <NewCustomActionButton
              text="Add Courses"
              onPress={() => setModalState({ type: DegreeCoursesModalState.ADD_COURSES })}
              leftIcon={<PlusCircle color={primaryColor} />}
            />
          </View>
          <View style={styles.headerRow}>
            <View style={styles.subHeaderContainer}>
              <CustomText text="Total Courses" size="m" weight="semiBold" style={styles.subHeaderText} />
              <CustomText
                text={`(${getCoursesByDegreeData?.getCoursesByDegreeByQuery.data.totalCount || 0})`}
                size="m"
                weight="semiBold"
                style={styles.subHeaderText}
                useTranslationText={false}
              />
              {selectedCourses.length > 0 && (
                <CustomButton
                  text={`Remove Selected`}
                  styleType="danger"
                  onPress={() => setModalState({ type: DegreeCoursesModalState.REMOVE_COURSES })}
                  leftIcon={<MinusCircleIcon />}
                  rightIcon={null}
                  style={{}}
                  textStyle={{ color: "#fff", fontWeight: "bold" }}
                  disabled={false}
                  textProps={{}}
                />
              )}
            </View>
            <View style={styles.searchContainer}>
              <MagnifyingGlass style={styles.searchIcon} />
              <CustomTextInput
                placeholder="Search by any value"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={[
                  styles.searchInput,
                  Platform.select({
                    web: {
                      outlineStyle: "none",
                      outlineWidth: 0,
                      WebkitAppearance: "none",
                    } as unknown,
                  }),
                ]}
                placeholderTextColor="#9CA3AF"
                selectionColor="#6B7280"
                cursorColor="#6B7280"
              />
            </View>
          </View>
        </View>
        <DegreeCoursesTable
          courses={getCoursesByDegreeData?.getCoursesByDegreeByQuery.data.courses || []}
          setSelectedCourses={setSelectedCourses}
          selectedCourses={selectedCourses}
        />
        <Pagination
          page={page}
          handlePageChange={handlePageChange}
          totalPageCount={Math.ceil(
            (getCoursesByDegreeData?.getCoursesByDegreeByQuery.data.totalCount || 0) / pageSize
          )}
        />
      </View>
      <NewCustomAxioActionModal
        visible={modalState.type === DegreeCoursesModalState.REMOVE_COURSES}
        onClose={handleModalClose}
        onAction={handleRemoveCourses}
        modalTexts={modals.removeCourses}
      />
      <NewCustomAxioActionModal
        visible={modalState.type === DegreeCoursesModalState.REMOVE_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.removeConfirmation}
      />
      <AddCoursesModalContainer
        visible={modalState.type === DegreeCoursesModalState.ADD_COURSES}
        onClose={handleModalClose}
        onSubmit={addCourses}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 8,
    flex: 1,
  },
  backButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  backText: {
    color: "#000",
  },
  header: {
    flexDirection: "column",
    width: "100%",
    marginBottom: 24,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  headerText: {
    color: "#000",
  },
  subHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  subHeaderText: {
    color: "#000",
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 6,
    padding: 4,
  },
  searchIcon: {
    marginRight: 8,
    color: "#3e68fe",
  },
  searchInput: {
    borderWidth: 0,
    backgroundColor: "transparent",
    width: 200,
    padding: 8,
    fontSize: 14,
    color: "#374151",
  },
});

export default DegreeCoursesTabContainer;
