import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AiTutor = {
  __typename?: "AITutor";
  chats?: Maybe<Array<Maybe<Chat>>>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  enableVideoInsight?: Maybe<Scalars["Boolean"]["output"]>;
  hasChapterPrerequisites: Array<Chapter>;
  hasChapterPrerequisitesAggregate?: Maybe<AiTutorChapterHasChapterPrerequisitesAggregationSelection>;
  hasChapterPrerequisitesConnection: AiTutorHasChapterPrerequisitesConnection;
  hasChapterSkills: Array<Chapter>;
  hasChapterSkillsAggregate?: Maybe<AiTutorChapterHasChapterSkillsAggregationSelection>;
  hasChapterSkillsConnection: AiTutorHasChapterSkillsConnection;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<AiTutorChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: AiTutorHasChatChatsConnection;
  hasSubchapterPrerequisites: Array<Subchapter>;
  hasSubchapterPrerequisitesAggregate?: Maybe<AiTutorSubchapterHasSubchapterPrerequisitesAggregationSelection>;
  hasSubchapterPrerequisitesConnection: AiTutorHasSubchapterPrerequisitesConnection;
  hasSubchapterSkills: Array<Subchapter>;
  hasSubchapterSkillsAggregate?: Maybe<AiTutorSubchapterHasSubchapterSkillsAggregationSelection>;
  hasSubchapterSkillsConnection: AiTutorHasSubchapterSkillsConnection;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  moduleHasAiTutors: Array<Module>;
  moduleHasAiTutorsAggregate?: Maybe<AiTutorModuleModuleHasAiTutorsAggregationSelection>;
  moduleHasAiTutorsConnection: AiTutorModuleHasAiTutorsConnection;
  objectives: Array<Objective>;
  objectivesAggregate?: Maybe<AiTutorObjectiveObjectivesAggregationSelection>;
  objectivesConnection: AiTutorObjectivesConnection;
  questionGenerationStatus?: Maybe<QuestionGenerationStatus>;
  targetAudience?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  visualization?: Maybe<Visualization>;
  visualizationAggregate?: Maybe<AiTutorVisualizationVisualizationAggregationSelection>;
  visualizationConnection: AiTutorVisualizationConnection;
};

export type AiTutorHasChapterPrerequisitesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterPrerequisitesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterPrerequisitesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectionSort>>;
  where?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasChapterSkillsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterSkillsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterSkillsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorHasChapterSkillsConnectionSort>>;
  where?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
};

export type AiTutorHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type AiTutorHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type AiTutorHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorHasChatChatsConnectionSort>>;
  where?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
};

export type AiTutorHasSubchapterPrerequisitesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubchapterOptions>;
  where?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterPrerequisitesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterPrerequisitesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectionSort>>;
  where?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasSubchapterSkillsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubchapterOptions>;
  where?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterSkillsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterSkillsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectionSort>>;
  where?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
};

export type AiTutorModuleHasAiTutorsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type AiTutorModuleHasAiTutorsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type AiTutorModuleHasAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectionSort>>;
  where?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
};

export type AiTutorObjectivesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AiTutorObjectivesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AiTutorObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorObjectivesConnectionSort>>;
  where?: InputMaybe<AiTutorObjectivesConnectionWhere>;
};

export type AiTutorVisualizationArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<VisualizationOptions>;
  where?: InputMaybe<VisualizationWhere>;
};

export type AiTutorVisualizationAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<VisualizationWhere>;
};

export type AiTutorVisualizationConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AiTutorVisualizationConnectionSort>>;
  where?: InputMaybe<AiTutorVisualizationConnectionWhere>;
};

export type AiTutorAggregateSelection = {
  __typename?: "AITutorAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type AiTutorAnalytics = {
  __typename?: "AITutorAnalytics";
  averageEngagementTime?: Maybe<Scalars["Float"]["output"]>;
  completionRate?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  totalSessions?: Maybe<Scalars["Int"]["output"]>;
};

export type AiTutorAnalyticsAggregateSelection = {
  __typename?: "AITutorAnalyticsAggregateSelection";
  averageEngagementTime: FloatAggregateSelection;
  completionRate: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  lastUpdated: DateTimeAggregateSelection;
  totalSessions: IntAggregateSelection;
};

export type AiTutorAnalyticsCreateInput = {
  averageEngagementTime?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["ID"]["input"];
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalSessions?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorAnalyticsEdge = {
  __typename?: "AITutorAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorAnalytics;
};

export type AiTutorAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorAnalyticsSort objects to sort AiTutorAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorAnalyticsSort>>;
};

export type AiTutorAnalyticsResponse = {
  __typename?: "AITutorAnalyticsResponse";
  data?: Maybe<AiTutorAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AiTutorAnalyticsResponseAggregateSelection = {
  __typename?: "AITutorAnalyticsResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AiTutorAnalyticsResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorAnalyticsResponseEdge = {
  __typename?: "AITutorAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorAnalyticsResponse;
};

export type AiTutorAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorAnalyticsResponseSort objects to sort AiTutorAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorAnalyticsResponseSort>>;
};

/** Fields to sort AiTutorAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorAnalyticsResponseSort object. */
export type AiTutorAnalyticsResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AiTutorAnalyticsResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<AiTutorAnalyticsResponseWhere>>;
  NOT?: InputMaybe<AiTutorAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<AiTutorAnalyticsResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Fields to sort AiTutorAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorAnalyticsSort object. */
export type AiTutorAnalyticsSort = {
  averageEngagementTime?: InputMaybe<SortDirection>;
  completionRate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  totalSessions?: InputMaybe<SortDirection>;
};

export type AiTutorAnalyticsUpdateInput = {
  averageEngagementTime?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalSessions?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorAnalyticsWhere = {
  AND?: InputMaybe<Array<AiTutorAnalyticsWhere>>;
  NOT?: InputMaybe<AiTutorAnalyticsWhere>;
  OR?: InputMaybe<Array<AiTutorAnalyticsWhere>>;
  averageEngagementTime?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_GT?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  averageEngagementTime_LT?: InputMaybe<Scalars["Float"]["input"]>;
  averageEngagementTime_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completionRate_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completionRate_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalSessions?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalSessions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalSessions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorChapterHasChapterPrerequisitesAggregationSelection = {
  __typename?: "AITutorChapterHasChapterPrerequisitesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorChapterHasChapterPrerequisitesNodeAggregateSelection>;
};

export type AiTutorChapterHasChapterPrerequisitesNodeAggregateSelection = {
  __typename?: "AITutorChapterHasChapterPrerequisitesNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type AiTutorChapterHasChapterSkillsAggregationSelection = {
  __typename?: "AITutorChapterHasChapterSkillsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorChapterHasChapterSkillsNodeAggregateSelection>;
};

export type AiTutorChapterHasChapterSkillsNodeAggregateSelection = {
  __typename?: "AITutorChapterHasChapterSkillsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type AiTutorChatHasChatChatsAggregationSelection = {
  __typename?: "AITutorChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<AiTutorChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<AiTutorChatHasChatChatsNodeAggregateSelection>;
};

export type AiTutorChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "AITutorChatHasChatChatsEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type AiTutorChatHasChatChatsNodeAggregateSelection = {
  __typename?: "AITutorChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type AiTutorConnectInput = {
  hasChapterPrerequisites?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<AiTutorHasChapterSkillsConnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AiTutorHasChatChatsConnectFieldInput>>;
  hasSubchapterPrerequisites?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectFieldInput>>;
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesConnectFieldInput>>;
  visualization?: InputMaybe<AiTutorVisualizationConnectFieldInput>;
};

export type AiTutorConnectOrCreateInput = {
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectOrCreateFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesConnectOrCreateFieldInput>>;
};

export type AiTutorConnectOrCreateWhere = {
  node: AiTutorUniqueWhere;
};

export type AiTutorConnectWhere = {
  node: AiTutorWhere;
};

export type AiTutorCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasChapterPrerequisites?: InputMaybe<AiTutorHasChapterPrerequisitesFieldInput>;
  hasChapterSkills?: InputMaybe<AiTutorHasChapterSkillsFieldInput>;
  hasChatChats?: InputMaybe<AiTutorHasChatChatsFieldInput>;
  hasSubchapterPrerequisites?: InputMaybe<AiTutorHasSubchapterPrerequisitesFieldInput>;
  hasSubchapterSkills?: InputMaybe<AiTutorHasSubchapterSkillsFieldInput>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAiTutors?: InputMaybe<AiTutorModuleHasAiTutorsFieldInput>;
  objectives?: InputMaybe<AiTutorObjectivesFieldInput>;
  questionGenerationStatus?: InputMaybe<QuestionGenerationStatus>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  visualization?: InputMaybe<AiTutorVisualizationFieldInput>;
};

export type AiTutorDeleteInput = {
  hasChapterPrerequisites?: InputMaybe<Array<AiTutorHasChapterPrerequisitesDeleteFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<AiTutorHasChapterSkillsDeleteFieldInput>>;
  hasChatChats?: InputMaybe<Array<AiTutorHasChatChatsDeleteFieldInput>>;
  hasSubchapterPrerequisites?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesDeleteFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<AiTutorHasSubchapterSkillsDeleteFieldInput>>;
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsDeleteFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesDeleteFieldInput>>;
  visualization?: InputMaybe<AiTutorVisualizationDeleteFieldInput>;
};

export type AiTutorDisconnectInput = {
  hasChapterPrerequisites?: InputMaybe<Array<AiTutorHasChapterPrerequisitesDisconnectFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<AiTutorHasChapterSkillsDisconnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AiTutorHasChatChatsDisconnectFieldInput>>;
  hasSubchapterPrerequisites?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesDisconnectFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<AiTutorHasSubchapterSkillsDisconnectFieldInput>>;
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsDisconnectFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesDisconnectFieldInput>>;
  visualization?: InputMaybe<AiTutorVisualizationDisconnectFieldInput>;
};

export type AiTutorEdge = {
  __typename?: "AITutorEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutor;
};

export type AiTutorEvalCriteriaResponse = {
  __typename?: "AITutorEvalCriteriaResponse";
  data?: Maybe<Array<Maybe<FormattedObjective>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AiTutorEvalCriteriaResponseAggregateSelection = {
  __typename?: "AITutorEvalCriteriaResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AiTutorEvalCriteriaResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorEvalCriteriaResponseEdge = {
  __typename?: "AITutorEvalCriteriaResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorEvalCriteriaResponse;
};

export type AiTutorEvalCriteriaResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorEvalCriteriaResponseSort objects to sort AiTutorEvalCriteriaResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorEvalCriteriaResponseSort>>;
};

/** Fields to sort AiTutorEvalCriteriaResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorEvalCriteriaResponseSort object. */
export type AiTutorEvalCriteriaResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AiTutorEvalCriteriaResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorEvalCriteriaResponseWhere = {
  AND?: InputMaybe<Array<AiTutorEvalCriteriaResponseWhere>>;
  NOT?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
  OR?: InputMaybe<Array<AiTutorEvalCriteriaResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorGeneratedResponse = {
  __typename?: "AITutorGeneratedResponse";
  data?: Maybe<GeneratedAiTutor>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AiTutorGeneratedResponseAggregateSelection = {
  __typename?: "AITutorGeneratedResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AiTutorGeneratedResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorGeneratedResponseEdge = {
  __typename?: "AITutorGeneratedResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorGeneratedResponse;
};

export type AiTutorGeneratedResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorGeneratedResponseSort objects to sort AiTutorGeneratedResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorGeneratedResponseSort>>;
};

/** Fields to sort AiTutorGeneratedResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorGeneratedResponseSort object. */
export type AiTutorGeneratedResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AiTutorGeneratedResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorGeneratedResponseWhere = {
  AND?: InputMaybe<Array<AiTutorGeneratedResponseWhere>>;
  NOT?: InputMaybe<AiTutorGeneratedResponseWhere>;
  OR?: InputMaybe<Array<AiTutorGeneratedResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorHasChapterPrerequisitesAggregateInput = {
  AND?: InputMaybe<Array<AiTutorHasChapterPrerequisitesAggregateInput>>;
  NOT?: InputMaybe<AiTutorHasChapterPrerequisitesAggregateInput>;
  OR?: InputMaybe<Array<AiTutorHasChapterPrerequisitesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorHasChapterPrerequisitesNodeAggregationWhereInput>;
};

export type AiTutorHasChapterPrerequisitesConnectFieldInput = {
  connect?: InputMaybe<Array<ChapterConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChapterConnectWhere>;
};

export type AiTutorHasChapterPrerequisitesConnection = {
  __typename?: "AITutorHasChapterPrerequisitesConnection";
  edges: Array<AiTutorHasChapterPrerequisitesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorHasChapterPrerequisitesConnectionSort = {
  node?: InputMaybe<ChapterSort>;
};

export type AiTutorHasChapterPrerequisitesConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectionWhere>>;
  NOT?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectionWhere>>;
  node?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterPrerequisitesCreateFieldInput = {
  node: ChapterCreateInput;
};

export type AiTutorHasChapterPrerequisitesDeleteFieldInput = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasChapterPrerequisitesDisconnectFieldInput = {
  disconnect?: InputMaybe<ChapterDisconnectInput>;
  where?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasChapterPrerequisitesFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChapterPrerequisitesCreateFieldInput>>;
};

export type AiTutorHasChapterPrerequisitesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorHasChapterPrerequisitesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorHasChapterPrerequisitesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorHasChapterPrerequisitesNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorHasChapterPrerequisitesRelationship = {
  __typename?: "AITutorHasChapterPrerequisitesRelationship";
  cursor: Scalars["String"]["output"];
  node: Chapter;
};

export type AiTutorHasChapterPrerequisitesUpdateConnectionInput = {
  node?: InputMaybe<ChapterUpdateInput>;
};

export type AiTutorHasChapterPrerequisitesUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChapterPrerequisitesConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChapterPrerequisitesCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorHasChapterPrerequisitesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorHasChapterPrerequisitesDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorHasChapterPrerequisitesUpdateConnectionInput>;
  where?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasChapterSkillsAggregateInput = {
  AND?: InputMaybe<Array<AiTutorHasChapterSkillsAggregateInput>>;
  NOT?: InputMaybe<AiTutorHasChapterSkillsAggregateInput>;
  OR?: InputMaybe<Array<AiTutorHasChapterSkillsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorHasChapterSkillsNodeAggregationWhereInput>;
};

export type AiTutorHasChapterSkillsConnectFieldInput = {
  connect?: InputMaybe<Array<ChapterConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChapterConnectWhere>;
};

export type AiTutorHasChapterSkillsConnection = {
  __typename?: "AITutorHasChapterSkillsConnection";
  edges: Array<AiTutorHasChapterSkillsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorHasChapterSkillsConnectionSort = {
  node?: InputMaybe<ChapterSort>;
};

export type AiTutorHasChapterSkillsConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorHasChapterSkillsConnectionWhere>>;
  NOT?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorHasChapterSkillsConnectionWhere>>;
  node?: InputMaybe<ChapterWhere>;
};

export type AiTutorHasChapterSkillsCreateFieldInput = {
  node: ChapterCreateInput;
};

export type AiTutorHasChapterSkillsDeleteFieldInput = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
};

export type AiTutorHasChapterSkillsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChapterDisconnectInput>;
  where?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
};

export type AiTutorHasChapterSkillsFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChapterSkillsCreateFieldInput>>;
};

export type AiTutorHasChapterSkillsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorHasChapterSkillsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorHasChapterSkillsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorHasChapterSkillsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorHasChapterSkillsRelationship = {
  __typename?: "AITutorHasChapterSkillsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chapter;
};

export type AiTutorHasChapterSkillsUpdateConnectionInput = {
  node?: InputMaybe<ChapterUpdateInput>;
};

export type AiTutorHasChapterSkillsUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChapterSkillsCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorHasChapterSkillsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorHasChapterSkillsDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorHasChapterSkillsUpdateConnectionInput>;
  where?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
};

export type AiTutorHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<AiTutorHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<AiTutorHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<AiTutorHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<AiTutorHasChatChatsNodeAggregationWhereInput>;
};

export type AiTutorHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type AiTutorHasChatChatsConnection = {
  __typename?: "AITutorHasChatChatsConnection";
  edges: Array<AiTutorHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type AiTutorHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type AiTutorHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type AiTutorHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
};

export type AiTutorHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
};

export type AiTutorHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChatChatsCreateFieldInput>>;
};

export type AiTutorHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorHasChatChatsRelationship = {
  __typename?: "AITutorHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type AiTutorHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type AiTutorHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
};

export type AiTutorHasSubchapterPrerequisitesAggregateInput = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesAggregateInput>>;
  NOT?: InputMaybe<AiTutorHasSubchapterPrerequisitesAggregateInput>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorHasSubchapterPrerequisitesNodeAggregationWhereInput>;
};

export type AiTutorHasSubchapterPrerequisitesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubchapterConnectWhere>;
};

export type AiTutorHasSubchapterPrerequisitesConnection = {
  __typename?: "AITutorHasSubchapterPrerequisitesConnection";
  edges: Array<AiTutorHasSubchapterPrerequisitesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorHasSubchapterPrerequisitesConnectionSort = {
  node?: InputMaybe<SubchapterSort>;
};

export type AiTutorHasSubchapterPrerequisitesConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectionWhere>>;
  NOT?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectionWhere>>;
  node?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterPrerequisitesCreateFieldInput = {
  node: SubchapterCreateInput;
};

export type AiTutorHasSubchapterPrerequisitesDeleteFieldInput = {
  where?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasSubchapterPrerequisitesDisconnectFieldInput = {
  where?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasSubchapterPrerequisitesFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesCreateFieldInput>>;
};

export type AiTutorHasSubchapterPrerequisitesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorHasSubchapterPrerequisitesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorHasSubchapterPrerequisitesRelationship = {
  __typename?: "AITutorHasSubchapterPrerequisitesRelationship";
  cursor: Scalars["String"]["output"];
  node: Subchapter;
};

export type AiTutorHasSubchapterPrerequisitesUpdateConnectionInput = {
  node?: InputMaybe<SubchapterUpdateInput>;
};

export type AiTutorHasSubchapterPrerequisitesUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorHasSubchapterPrerequisitesUpdateConnectionInput>;
  where?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
};

export type AiTutorHasSubchapterSkillsAggregateInput = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterSkillsAggregateInput>>;
  NOT?: InputMaybe<AiTutorHasSubchapterSkillsAggregateInput>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterSkillsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorHasSubchapterSkillsNodeAggregationWhereInput>;
};

export type AiTutorHasSubchapterSkillsConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubchapterConnectWhere>;
};

export type AiTutorHasSubchapterSkillsConnection = {
  __typename?: "AITutorHasSubchapterSkillsConnection";
  edges: Array<AiTutorHasSubchapterSkillsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorHasSubchapterSkillsConnectionSort = {
  node?: InputMaybe<SubchapterSort>;
};

export type AiTutorHasSubchapterSkillsConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectionWhere>>;
  NOT?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectionWhere>>;
  node?: InputMaybe<SubchapterWhere>;
};

export type AiTutorHasSubchapterSkillsCreateFieldInput = {
  node: SubchapterCreateInput;
};

export type AiTutorHasSubchapterSkillsDeleteFieldInput = {
  where?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
};

export type AiTutorHasSubchapterSkillsDisconnectFieldInput = {
  where?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
};

export type AiTutorHasSubchapterSkillsFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasSubchapterSkillsCreateFieldInput>>;
};

export type AiTutorHasSubchapterSkillsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorHasSubchapterSkillsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorHasSubchapterSkillsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorHasSubchapterSkillsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorHasSubchapterSkillsRelationship = {
  __typename?: "AITutorHasSubchapterSkillsRelationship";
  cursor: Scalars["String"]["output"];
  node: Subchapter;
};

export type AiTutorHasSubchapterSkillsUpdateConnectionInput = {
  node?: InputMaybe<SubchapterUpdateInput>;
};

export type AiTutorHasSubchapterSkillsUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorHasSubchapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<AiTutorHasSubchapterSkillsCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorHasSubchapterSkillsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorHasSubchapterSkillsDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorHasSubchapterSkillsUpdateConnectionInput>;
  where?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
};

export type AiTutorModuleHasAiTutorsAggregateInput = {
  AND?: InputMaybe<Array<AiTutorModuleHasAiTutorsAggregateInput>>;
  NOT?: InputMaybe<AiTutorModuleHasAiTutorsAggregateInput>;
  OR?: InputMaybe<Array<AiTutorModuleHasAiTutorsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorModuleHasAiTutorsNodeAggregationWhereInput>;
};

export type AiTutorModuleHasAiTutorsConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type AiTutorModuleHasAiTutorsConnectOrCreateFieldInput = {
  onCreate: AiTutorModuleHasAiTutorsConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type AiTutorModuleHasAiTutorsConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type AiTutorModuleHasAiTutorsConnection = {
  __typename?: "AITutorModuleHasAiTutorsConnection";
  edges: Array<AiTutorModuleHasAiTutorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorModuleHasAiTutorsConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type AiTutorModuleHasAiTutorsConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectionWhere>>;
  NOT?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type AiTutorModuleHasAiTutorsCreateFieldInput = {
  node: ModuleCreateInput;
};

export type AiTutorModuleHasAiTutorsDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
};

export type AiTutorModuleHasAiTutorsDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
};

export type AiTutorModuleHasAiTutorsFieldInput = {
  connect?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AiTutorModuleHasAiTutorsCreateFieldInput>>;
};

export type AiTutorModuleHasAiTutorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorModuleHasAiTutorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorModuleHasAiTutorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorModuleHasAiTutorsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorModuleHasAiTutorsRelationship = {
  __typename?: "AITutorModuleHasAiTutorsRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type AiTutorModuleHasAiTutorsUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type AiTutorModuleHasAiTutorsUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AiTutorModuleHasAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AiTutorModuleHasAiTutorsCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorModuleHasAiTutorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorModuleHasAiTutorsDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorModuleHasAiTutorsUpdateConnectionInput>;
  where?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
};

export type AiTutorModuleModuleHasAiTutorsAggregationSelection = {
  __typename?: "AITutorModuleModuleHasAiTutorsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorModuleModuleHasAiTutorsNodeAggregateSelection>;
};

export type AiTutorModuleModuleHasAiTutorsNodeAggregateSelection = {
  __typename?: "AITutorModuleModuleHasAiTutorsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type AiTutorObjectiveObjectivesAggregationSelection = {
  __typename?: "AITutorObjectiveObjectivesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorObjectiveObjectivesNodeAggregateSelection>;
};

export type AiTutorObjectiveObjectivesNodeAggregateSelection = {
  __typename?: "AITutorObjectiveObjectivesNodeAggregateSelection";
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type AiTutorObjectivesAggregateInput = {
  AND?: InputMaybe<Array<AiTutorObjectivesAggregateInput>>;
  NOT?: InputMaybe<AiTutorObjectivesAggregateInput>;
  OR?: InputMaybe<Array<AiTutorObjectivesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorObjectivesNodeAggregationWhereInput>;
};

export type AiTutorObjectivesConnectFieldInput = {
  connect?: InputMaybe<Array<ObjectiveConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ObjectiveConnectWhere>;
};

export type AiTutorObjectivesConnectOrCreateFieldInput = {
  onCreate: AiTutorObjectivesConnectOrCreateFieldInputOnCreate;
  where: ObjectiveConnectOrCreateWhere;
};

export type AiTutorObjectivesConnectOrCreateFieldInputOnCreate = {
  node: ObjectiveOnCreateInput;
};

export type AiTutorObjectivesConnection = {
  __typename?: "AITutorObjectivesConnection";
  edges: Array<AiTutorObjectivesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorObjectivesConnectionSort = {
  node?: InputMaybe<ObjectiveSort>;
};

export type AiTutorObjectivesConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorObjectivesConnectionWhere>>;
  NOT?: InputMaybe<AiTutorObjectivesConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorObjectivesConnectionWhere>>;
  node?: InputMaybe<ObjectiveWhere>;
};

export type AiTutorObjectivesCreateFieldInput = {
  node: ObjectiveCreateInput;
};

export type AiTutorObjectivesDeleteFieldInput = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<AiTutorObjectivesConnectionWhere>;
};

export type AiTutorObjectivesDisconnectFieldInput = {
  disconnect?: InputMaybe<ObjectiveDisconnectInput>;
  where?: InputMaybe<AiTutorObjectivesConnectionWhere>;
};

export type AiTutorObjectivesFieldInput = {
  connect?: InputMaybe<Array<AiTutorObjectivesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AiTutorObjectivesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AiTutorObjectivesCreateFieldInput>>;
};

export type AiTutorObjectivesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorObjectivesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorObjectivesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorObjectivesNodeAggregationWhereInput>>;
  objective_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sequence_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AiTutorObjectivesRelationship = {
  __typename?: "AITutorObjectivesRelationship";
  cursor: Scalars["String"]["output"];
  node: Objective;
};

export type AiTutorObjectivesUpdateConnectionInput = {
  node?: InputMaybe<ObjectiveUpdateInput>;
};

export type AiTutorObjectivesUpdateFieldInput = {
  connect?: InputMaybe<Array<AiTutorObjectivesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AiTutorObjectivesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AiTutorObjectivesCreateFieldInput>>;
  delete?: InputMaybe<Array<AiTutorObjectivesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AiTutorObjectivesDisconnectFieldInput>>;
  update?: InputMaybe<AiTutorObjectivesUpdateConnectionInput>;
  where?: InputMaybe<AiTutorObjectivesConnectionWhere>;
};

export type AiTutorOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionGenerationStatus?: InputMaybe<QuestionGenerationStatus>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type AiTutorOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorSort objects to sort AiTutors by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorSort>>;
};

export type AiTutorProgressAnalytics = {
  __typename?: "AITutorProgressAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  chatAnalytics?: Maybe<Array<Maybe<ChatAnalytics>>>;
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: AiTutorProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type AiTutorProgressAnalyticsAggregateSelection = {
  __typename?: "AITutorProgressAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type AiTutorProgressAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AiTutorProgressAnalyticsEdge = {
  __typename?: "AITutorProgressAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorProgressAnalytics;
};

export type AiTutorProgressAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorProgressAnalyticsSort objects to sort AiTutorProgressAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorProgressAnalyticsSort>>;
};

/** Fields to sort AiTutorProgressAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorProgressAnalyticsSort object. */
export type AiTutorProgressAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type AiTutorProgressAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AiTutorProgressAnalyticsWhere = {
  AND?: InputMaybe<Array<AiTutorProgressAnalyticsWhere>>;
  NOT?: InputMaybe<AiTutorProgressAnalyticsWhere>;
  OR?: InputMaybe<Array<AiTutorProgressAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AiTutorProgressDetails = {
  __typename?: "AITutorProgressDetails";
  objectives: Array<ObjectiveProgressAnalytics>;
};

export type AiTutorProgressDetailsAggregateSelection = {
  __typename?: "AITutorProgressDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type AiTutorProgressDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorProgressDetailsEdge = {
  __typename?: "AITutorProgressDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorProgressDetails;
};

export type AiTutorProgressDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorProgressDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorProgressDetailsWhere = {
  AND?: InputMaybe<Array<AiTutorProgressDetailsWhere>>;
  NOT?: InputMaybe<AiTutorProgressDetailsWhere>;
  OR?: InputMaybe<Array<AiTutorProgressDetailsWhere>>;
};

export type AiTutorRelationInput = {
  hasChapterPrerequisites?: InputMaybe<Array<AiTutorHasChapterPrerequisitesCreateFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<AiTutorHasChapterSkillsCreateFieldInput>>;
  hasChatChats?: InputMaybe<Array<AiTutorHasChatChatsCreateFieldInput>>;
  hasSubchapterPrerequisites?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesCreateFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<AiTutorHasSubchapterSkillsCreateFieldInput>>;
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsCreateFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesCreateFieldInput>>;
  visualization?: InputMaybe<AiTutorVisualizationCreateFieldInput>;
};

export type AiTutorResponse = {
  __typename?: "AITutorResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<FormattedAiTutor>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AiTutorResponseAggregateSelection = {
  __typename?: "AITutorResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AiTutorResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorResponseEdge = {
  __typename?: "AITutorResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AiTutorResponse;
};

export type AiTutorResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AITutorResponseSort objects to sort AiTutorResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AiTutorResponseSort>>;
};

/** Fields to sort AiTutorResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorResponseSort object. */
export type AiTutorResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AiTutorResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AiTutorResponseWhere = {
  AND?: InputMaybe<Array<AiTutorResponseWhere>>;
  NOT?: InputMaybe<AiTutorResponseWhere>;
  OR?: InputMaybe<Array<AiTutorResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Fields to sort AiTutors by. The order in which sorts are applied is not guaranteed when specifying many fields in one AITutorSort object. */
export type AiTutorSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  enableVideoInsight?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  questionGenerationStatus?: InputMaybe<SortDirection>;
  targetAudience?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type AiTutorSubchapterHasSubchapterPrerequisitesAggregationSelection = {
  __typename?: "AITutorSubchapterHasSubchapterPrerequisitesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorSubchapterHasSubchapterPrerequisitesNodeAggregateSelection>;
};

export type AiTutorSubchapterHasSubchapterPrerequisitesNodeAggregateSelection = {
  __typename?: "AITutorSubchapterHasSubchapterPrerequisitesNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type AiTutorSubchapterHasSubchapterSkillsAggregationSelection = {
  __typename?: "AITutorSubchapterHasSubchapterSkillsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorSubchapterHasSubchapterSkillsNodeAggregateSelection>;
};

export type AiTutorSubchapterHasSubchapterSkillsNodeAggregateSelection = {
  __typename?: "AITutorSubchapterHasSubchapterSkillsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type AiTutorUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AiTutorUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasChapterPrerequisites?: InputMaybe<Array<AiTutorHasChapterPrerequisitesUpdateFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<AiTutorHasChapterSkillsUpdateFieldInput>>;
  hasChatChats?: InputMaybe<Array<AiTutorHasChatChatsUpdateFieldInput>>;
  hasSubchapterPrerequisites?: InputMaybe<Array<AiTutorHasSubchapterPrerequisitesUpdateFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<AiTutorHasSubchapterSkillsUpdateFieldInput>>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAiTutors?: InputMaybe<Array<AiTutorModuleHasAiTutorsUpdateFieldInput>>;
  objectives?: InputMaybe<Array<AiTutorObjectivesUpdateFieldInput>>;
  questionGenerationStatus?: InputMaybe<QuestionGenerationStatus>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  visualization?: InputMaybe<AiTutorVisualizationUpdateFieldInput>;
};

export type AiTutorVisualizationAggregateInput = {
  AND?: InputMaybe<Array<AiTutorVisualizationAggregateInput>>;
  NOT?: InputMaybe<AiTutorVisualizationAggregateInput>;
  OR?: InputMaybe<Array<AiTutorVisualizationAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AiTutorVisualizationNodeAggregationWhereInput>;
};

export type AiTutorVisualizationConnectFieldInput = {
  connect?: InputMaybe<VisualizationConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<VisualizationConnectWhere>;
};

export type AiTutorVisualizationConnection = {
  __typename?: "AITutorVisualizationConnection";
  edges: Array<AiTutorVisualizationRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorVisualizationConnectionSort = {
  node?: InputMaybe<VisualizationSort>;
};

export type AiTutorVisualizationConnectionWhere = {
  AND?: InputMaybe<Array<AiTutorVisualizationConnectionWhere>>;
  NOT?: InputMaybe<AiTutorVisualizationConnectionWhere>;
  OR?: InputMaybe<Array<AiTutorVisualizationConnectionWhere>>;
  node?: InputMaybe<VisualizationWhere>;
};

export type AiTutorVisualizationCreateFieldInput = {
  node: VisualizationCreateInput;
};

export type AiTutorVisualizationDeleteFieldInput = {
  delete?: InputMaybe<VisualizationDeleteInput>;
  where?: InputMaybe<AiTutorVisualizationConnectionWhere>;
};

export type AiTutorVisualizationDisconnectFieldInput = {
  disconnect?: InputMaybe<VisualizationDisconnectInput>;
  where?: InputMaybe<AiTutorVisualizationConnectionWhere>;
};

export type AiTutorVisualizationFieldInput = {
  connect?: InputMaybe<AiTutorVisualizationConnectFieldInput>;
  create?: InputMaybe<AiTutorVisualizationCreateFieldInput>;
};

export type AiTutorVisualizationNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AiTutorVisualizationNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AiTutorVisualizationNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AiTutorVisualizationNodeAggregationWhereInput>>;
  code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AiTutorVisualizationRelationship = {
  __typename?: "AITutorVisualizationRelationship";
  cursor: Scalars["String"]["output"];
  node: Visualization;
};

export type AiTutorVisualizationUpdateConnectionInput = {
  node?: InputMaybe<VisualizationUpdateInput>;
};

export type AiTutorVisualizationUpdateFieldInput = {
  connect?: InputMaybe<AiTutorVisualizationConnectFieldInput>;
  create?: InputMaybe<AiTutorVisualizationCreateFieldInput>;
  delete?: InputMaybe<AiTutorVisualizationDeleteFieldInput>;
  disconnect?: InputMaybe<AiTutorVisualizationDisconnectFieldInput>;
  update?: InputMaybe<AiTutorVisualizationUpdateConnectionInput>;
  where?: InputMaybe<AiTutorVisualizationConnectionWhere>;
};

export type AiTutorVisualizationVisualizationAggregationSelection = {
  __typename?: "AITutorVisualizationVisualizationAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AiTutorVisualizationVisualizationNodeAggregateSelection>;
};

export type AiTutorVisualizationVisualizationNodeAggregateSelection = {
  __typename?: "AITutorVisualizationVisualizationNodeAggregateSelection";
  code: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  visualizationDescription: StringAggregateSelection;
  visualizationType: StringAggregateSelection;
};

export type AiTutorWhere = {
  AND?: InputMaybe<Array<AiTutorWhere>>;
  NOT?: InputMaybe<AiTutorWhere>;
  OR?: InputMaybe<Array<AiTutorWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasChapterPrerequisitesAggregate?: InputMaybe<AiTutorHasChapterPrerequisitesAggregateInput>;
  /** Return AITutors where all of the related AITutorHasChapterPrerequisitesConnections match this filter */
  hasChapterPrerequisitesConnection_ALL?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
  /** Return AITutors where none of the related AITutorHasChapterPrerequisitesConnections match this filter */
  hasChapterPrerequisitesConnection_NONE?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
  /** Return AITutors where one of the related AITutorHasChapterPrerequisitesConnections match this filter */
  hasChapterPrerequisitesConnection_SINGLE?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
  /** Return AITutors where some of the related AITutorHasChapterPrerequisitesConnections match this filter */
  hasChapterPrerequisitesConnection_SOME?: InputMaybe<AiTutorHasChapterPrerequisitesConnectionWhere>;
  /** Return AITutors where all of the related Chapters match this filter */
  hasChapterPrerequisites_ALL?: InputMaybe<ChapterWhere>;
  /** Return AITutors where none of the related Chapters match this filter */
  hasChapterPrerequisites_NONE?: InputMaybe<ChapterWhere>;
  /** Return AITutors where one of the related Chapters match this filter */
  hasChapterPrerequisites_SINGLE?: InputMaybe<ChapterWhere>;
  /** Return AITutors where some of the related Chapters match this filter */
  hasChapterPrerequisites_SOME?: InputMaybe<ChapterWhere>;
  hasChapterSkillsAggregate?: InputMaybe<AiTutorHasChapterSkillsAggregateInput>;
  /** Return AITutors where all of the related AITutorHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_ALL?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
  /** Return AITutors where none of the related AITutorHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_NONE?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
  /** Return AITutors where one of the related AITutorHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_SINGLE?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
  /** Return AITutors where some of the related AITutorHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_SOME?: InputMaybe<AiTutorHasChapterSkillsConnectionWhere>;
  /** Return AITutors where all of the related Chapters match this filter */
  hasChapterSkills_ALL?: InputMaybe<ChapterWhere>;
  /** Return AITutors where none of the related Chapters match this filter */
  hasChapterSkills_NONE?: InputMaybe<ChapterWhere>;
  /** Return AITutors where one of the related Chapters match this filter */
  hasChapterSkills_SINGLE?: InputMaybe<ChapterWhere>;
  /** Return AITutors where some of the related Chapters match this filter */
  hasChapterSkills_SOME?: InputMaybe<ChapterWhere>;
  hasChatChatsAggregate?: InputMaybe<AiTutorHasChatChatsAggregateInput>;
  /** Return AITutors where all of the related AITutorHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
  /** Return AITutors where none of the related AITutorHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
  /** Return AITutors where one of the related AITutorHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
  /** Return AITutors where some of the related AITutorHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<AiTutorHasChatChatsConnectionWhere>;
  /** Return AITutors where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return AITutors where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return AITutors where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return AITutors where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasSubchapterPrerequisitesAggregate?: InputMaybe<AiTutorHasSubchapterPrerequisitesAggregateInput>;
  /** Return AITutors where all of the related AITutorHasSubchapterPrerequisitesConnections match this filter */
  hasSubchapterPrerequisitesConnection_ALL?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
  /** Return AITutors where none of the related AITutorHasSubchapterPrerequisitesConnections match this filter */
  hasSubchapterPrerequisitesConnection_NONE?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
  /** Return AITutors where one of the related AITutorHasSubchapterPrerequisitesConnections match this filter */
  hasSubchapterPrerequisitesConnection_SINGLE?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
  /** Return AITutors where some of the related AITutorHasSubchapterPrerequisitesConnections match this filter */
  hasSubchapterPrerequisitesConnection_SOME?: InputMaybe<AiTutorHasSubchapterPrerequisitesConnectionWhere>;
  /** Return AITutors where all of the related Subchapters match this filter */
  hasSubchapterPrerequisites_ALL?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where none of the related Subchapters match this filter */
  hasSubchapterPrerequisites_NONE?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where one of the related Subchapters match this filter */
  hasSubchapterPrerequisites_SINGLE?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where some of the related Subchapters match this filter */
  hasSubchapterPrerequisites_SOME?: InputMaybe<SubchapterWhere>;
  hasSubchapterSkillsAggregate?: InputMaybe<AiTutorHasSubchapterSkillsAggregateInput>;
  /** Return AITutors where all of the related AITutorHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_ALL?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
  /** Return AITutors where none of the related AITutorHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_NONE?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
  /** Return AITutors where one of the related AITutorHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_SINGLE?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
  /** Return AITutors where some of the related AITutorHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_SOME?: InputMaybe<AiTutorHasSubchapterSkillsConnectionWhere>;
  /** Return AITutors where all of the related Subchapters match this filter */
  hasSubchapterSkills_ALL?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where none of the related Subchapters match this filter */
  hasSubchapterSkills_NONE?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where one of the related Subchapters match this filter */
  hasSubchapterSkills_SINGLE?: InputMaybe<SubchapterWhere>;
  /** Return AITutors where some of the related Subchapters match this filter */
  hasSubchapterSkills_SOME?: InputMaybe<SubchapterWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAiTutorsAggregate?: InputMaybe<AiTutorModuleHasAiTutorsAggregateInput>;
  /** Return AITutors where all of the related AITutorModuleHasAiTutorsConnections match this filter */
  moduleHasAiTutorsConnection_ALL?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
  /** Return AITutors where none of the related AITutorModuleHasAiTutorsConnections match this filter */
  moduleHasAiTutorsConnection_NONE?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
  /** Return AITutors where one of the related AITutorModuleHasAiTutorsConnections match this filter */
  moduleHasAiTutorsConnection_SINGLE?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
  /** Return AITutors where some of the related AITutorModuleHasAiTutorsConnections match this filter */
  moduleHasAiTutorsConnection_SOME?: InputMaybe<AiTutorModuleHasAiTutorsConnectionWhere>;
  /** Return AITutors where all of the related Modules match this filter */
  moduleHasAiTutors_ALL?: InputMaybe<ModuleWhere>;
  /** Return AITutors where none of the related Modules match this filter */
  moduleHasAiTutors_NONE?: InputMaybe<ModuleWhere>;
  /** Return AITutors where one of the related Modules match this filter */
  moduleHasAiTutors_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return AITutors where some of the related Modules match this filter */
  moduleHasAiTutors_SOME?: InputMaybe<ModuleWhere>;
  objectivesAggregate?: InputMaybe<AiTutorObjectivesAggregateInput>;
  /** Return AITutors where all of the related AITutorObjectivesConnections match this filter */
  objectivesConnection_ALL?: InputMaybe<AiTutorObjectivesConnectionWhere>;
  /** Return AITutors where none of the related AITutorObjectivesConnections match this filter */
  objectivesConnection_NONE?: InputMaybe<AiTutorObjectivesConnectionWhere>;
  /** Return AITutors where one of the related AITutorObjectivesConnections match this filter */
  objectivesConnection_SINGLE?: InputMaybe<AiTutorObjectivesConnectionWhere>;
  /** Return AITutors where some of the related AITutorObjectivesConnections match this filter */
  objectivesConnection_SOME?: InputMaybe<AiTutorObjectivesConnectionWhere>;
  /** Return AITutors where all of the related Objectives match this filter */
  objectives_ALL?: InputMaybe<ObjectiveWhere>;
  /** Return AITutors where none of the related Objectives match this filter */
  objectives_NONE?: InputMaybe<ObjectiveWhere>;
  /** Return AITutors where one of the related Objectives match this filter */
  objectives_SINGLE?: InputMaybe<ObjectiveWhere>;
  /** Return AITutors where some of the related Objectives match this filter */
  objectives_SOME?: InputMaybe<ObjectiveWhere>;
  questionGenerationStatus?: InputMaybe<QuestionGenerationStatus>;
  questionGenerationStatus_IN?: InputMaybe<Array<InputMaybe<QuestionGenerationStatus>>>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  targetAudience_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualization?: InputMaybe<VisualizationWhere>;
  visualizationAggregate?: InputMaybe<AiTutorVisualizationAggregateInput>;
  visualizationConnection?: InputMaybe<AiTutorVisualizationConnectionWhere>;
  visualizationConnection_NOT?: InputMaybe<AiTutorVisualizationConnectionWhere>;
  visualization_NOT?: InputMaybe<VisualizationWhere>;
};

export type Achievements = {
  __typename?: "Achievements";
  attributes: Array<Attribute>;
  attributesAggregate?: Maybe<AchievementsAttributeAttributesAggregationSelection>;
  attributesConnection: AchievementsAttributesConnection;
  created: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<AchievementsUserUsersAggregationSelection>;
  usersConnection: AchievementsUsersConnection;
};

export type AchievementsAttributesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AchievementsAttributesConnectionSort>>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AchievementsUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AchievementsUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AchievementsUsersConnectionSort>>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsAggregateSelection = {
  __typename?: "AchievementsAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type AchievementsAttributeAttributesAggregationSelection = {
  __typename?: "AchievementsAttributeAttributesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AchievementsAttributeAttributesNodeAggregateSelection>;
};

export type AchievementsAttributeAttributesNodeAggregateSelection = {
  __typename?: "AchievementsAttributeAttributesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type AchievementsAttributesAggregateInput = {
  AND?: InputMaybe<Array<AchievementsAttributesAggregateInput>>;
  NOT?: InputMaybe<AchievementsAttributesAggregateInput>;
  OR?: InputMaybe<Array<AchievementsAttributesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AchievementsAttributesNodeAggregationWhereInput>;
};

export type AchievementsAttributesConnectFieldInput = {
  connect?: InputMaybe<Array<AttributeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AttributeConnectWhere>;
};

export type AchievementsAttributesConnection = {
  __typename?: "AchievementsAttributesConnection";
  edges: Array<AchievementsAttributesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsAttributesConnectionSort = {
  node?: InputMaybe<AttributeSort>;
};

export type AchievementsAttributesConnectionWhere = {
  AND?: InputMaybe<Array<AchievementsAttributesConnectionWhere>>;
  NOT?: InputMaybe<AchievementsAttributesConnectionWhere>;
  OR?: InputMaybe<Array<AchievementsAttributesConnectionWhere>>;
  node?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesCreateFieldInput = {
  node: AttributeCreateInput;
};

export type AchievementsAttributesDeleteFieldInput = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsAttributesDisconnectFieldInput = {
  disconnect?: InputMaybe<AttributeDisconnectInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsAttributesFieldInput = {
  connect?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
};

export type AchievementsAttributesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AchievementsAttributesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AchievementsAttributesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AchievementsAttributesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AchievementsAttributesRelationship = {
  __typename?: "AchievementsAttributesRelationship";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type AchievementsAttributesUpdateConnectionInput = {
  node?: InputMaybe<AttributeUpdateInput>;
};

export type AchievementsAttributesUpdateFieldInput = {
  connect?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
  delete?: InputMaybe<Array<AchievementsAttributesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AchievementsAttributesDisconnectFieldInput>>;
  update?: InputMaybe<AchievementsAttributesUpdateConnectionInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsConnectInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
};

export type AchievementsConnectOrCreateInput = {
  users?: InputMaybe<Array<AchievementsUsersConnectOrCreateFieldInput>>;
};

export type AchievementsConnectWhere = {
  node: AchievementsWhere;
};

export type AchievementsConnection = {
  __typename?: "AchievementsConnection";
  edges: Array<AchievementsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsCreateInput = {
  attributes?: InputMaybe<AchievementsAttributesFieldInput>;
  created: Scalars["DateTime"]["input"];
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  users?: InputMaybe<AchievementsUsersFieldInput>;
};

export type AchievementsDeleteInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesDeleteFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersDeleteFieldInput>>;
};

export type AchievementsDisconnectInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesDisconnectFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersDisconnectFieldInput>>;
};

export type AchievementsEdge = {
  __typename?: "AchievementsEdge";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type AchievementsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AchievementsSort objects to sort Achievements by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AchievementsSort>>;
};

export type AchievementsRelationInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
};

/** Fields to sort Achievements by. The order in which sorts are applied is not guaranteed when specifying many fields in one AchievementsSort object. */
export type AchievementsSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type AchievementsUpdateInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<AchievementsUsersUpdateFieldInput>>;
};

export type AchievementsUserUsersAggregationSelection = {
  __typename?: "AchievementsUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AchievementsUserUsersNodeAggregateSelection>;
};

export type AchievementsUserUsersNodeAggregateSelection = {
  __typename?: "AchievementsUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type AchievementsUsersAggregateInput = {
  AND?: InputMaybe<Array<AchievementsUsersAggregateInput>>;
  NOT?: InputMaybe<AchievementsUsersAggregateInput>;
  OR?: InputMaybe<Array<AchievementsUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AchievementsUsersNodeAggregationWhereInput>;
};

export type AchievementsUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AchievementsUsersConnectOrCreateFieldInput = {
  onCreate: AchievementsUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type AchievementsUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type AchievementsUsersConnection = {
  __typename?: "AchievementsUsersConnection";
  edges: Array<AchievementsUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AchievementsUsersConnectionWhere = {
  AND?: InputMaybe<Array<AchievementsUsersConnectionWhere>>;
  NOT?: InputMaybe<AchievementsUsersConnectionWhere>;
  OR?: InputMaybe<Array<AchievementsUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AchievementsUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type AchievementsUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsUsersFieldInput = {
  connect?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AchievementsUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
};

export type AchievementsUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AchievementsUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AchievementsUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AchievementsUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AchievementsUsersRelationship = {
  __typename?: "AchievementsUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AchievementsUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AchievementsUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AchievementsUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<AchievementsUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AchievementsUsersDisconnectFieldInput>>;
  update?: InputMaybe<AchievementsUsersUpdateConnectionInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsWhere = {
  AND?: InputMaybe<Array<AchievementsWhere>>;
  NOT?: InputMaybe<AchievementsWhere>;
  OR?: InputMaybe<Array<AchievementsWhere>>;
  attributesAggregate?: InputMaybe<AchievementsAttributesAggregateInput>;
  /** Return Achievements where all of the related AchievementsAttributesConnections match this filter */
  attributesConnection_ALL?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where none of the related AchievementsAttributesConnections match this filter */
  attributesConnection_NONE?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where one of the related AchievementsAttributesConnections match this filter */
  attributesConnection_SINGLE?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where some of the related AchievementsAttributesConnections match this filter */
  attributesConnection_SOME?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where all of the related Attributes match this filter */
  attributes_ALL?: InputMaybe<AttributeWhere>;
  /** Return Achievements where none of the related Attributes match this filter */
  attributes_NONE?: InputMaybe<AttributeWhere>;
  /** Return Achievements where one of the related Attributes match this filter */
  attributes_SINGLE?: InputMaybe<AttributeWhere>;
  /** Return Achievements where some of the related Attributes match this filter */
  attributes_SOME?: InputMaybe<AttributeWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<AchievementsUsersAggregateInput>;
  /** Return Achievements where all of the related AchievementsUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where none of the related AchievementsUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where one of the related AchievementsUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where some of the related AchievementsUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Achievements where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Achievements where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Achievements where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type ActiveTrack = {
  __typename?: "ActiveTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type ActiveTrackAggregateSelection = {
  __typename?: "ActiveTrackAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentSubfield: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type ActiveTrackCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTrackEdge = {
  __typename?: "ActiveTrackEdge";
  cursor: Scalars["String"]["output"];
  node: ActiveTrack;
};

export type ActiveTrackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActiveTrackSort objects to sort ActiveTracks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActiveTrackSort>>;
};

/** Fields to sort ActiveTracks by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActiveTrackSort object. */
export type ActiveTrackSort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentSubfield?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type ActiveTrackUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTrackWhere = {
  AND?: InputMaybe<Array<ActiveTrackWhere>>;
  NOT?: InputMaybe<ActiveTrackWhere>;
  OR?: InputMaybe<Array<ActiveTrackWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTracksConnection = {
  __typename?: "ActiveTracksConnection";
  edges: Array<ActiveTrackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityAnalytics = {
  __typename?: "ActivityAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  confusion_points_details?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  details: ActivityAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  distractions_details?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  enrollment: Scalars["Int"]["output"];
  further_recommendations?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  success_points_details?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  summaries?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ActivityAnalyticsAggregateSelection = {
  __typename?: "ActivityAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  enrollment: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type ActivityAnalyticsConnection = {
  __typename?: "ActivityAnalyticsConnection";
  edges: Array<ActivityAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  confusion_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions: Scalars["Int"]["input"];
  distractions_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrollment: Scalars["Int"]["input"];
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  success_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summaries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ActivityAnalyticsDetails = {
  __typename?: "ActivityAnalyticsDetails";
  objectives: Array<ObjectiveAnalytics>;
};

export type ActivityAnalyticsDetailsAggregateSelection = {
  __typename?: "ActivityAnalyticsDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ActivityAnalyticsDetailsConnection = {
  __typename?: "ActivityAnalyticsDetailsConnection";
  edges: Array<ActivityAnalyticsDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityAnalyticsDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityAnalyticsDetailsEdge = {
  __typename?: "ActivityAnalyticsDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityAnalyticsDetails;
};

export type ActivityAnalyticsDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityAnalyticsDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityAnalyticsDetailsWhere = {
  AND?: InputMaybe<Array<ActivityAnalyticsDetailsWhere>>;
  NOT?: InputMaybe<ActivityAnalyticsDetailsWhere>;
  OR?: InputMaybe<Array<ActivityAnalyticsDetailsWhere>>;
};

export type ActivityAnalyticsEdge = {
  __typename?: "ActivityAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityAnalytics;
};

export type ActivityAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityAnalyticsSort objects to sort ActivityAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityAnalyticsSort>>;
};

export type ActivityAnalyticsResponse = {
  __typename?: "ActivityAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ActivityAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ActivityAnalyticsResponseAggregateSelection = {
  __typename?: "ActivityAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ActivityAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ActivityAnalyticsResponseEdge = {
  __typename?: "ActivityAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityAnalyticsResponse;
};

export type ActivityAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityAnalyticsResponseSort objects to sort ActivityAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityAnalyticsResponseSort>>;
};

/** Fields to sort ActivityAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityAnalyticsResponseSort object. */
export type ActivityAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ActivityAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<ActivityAnalyticsResponseWhere>>;
  NOT?: InputMaybe<ActivityAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<ActivityAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityAnalyticsResponsesConnection = {
  __typename?: "ActivityAnalyticsResponsesConnection";
  edges: Array<ActivityAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ActivityAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityAnalyticsSort object. */
export type ActivityAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enrollment?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type ActivityAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_details_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summaries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summaries_POP?: InputMaybe<Scalars["Int"]["input"]>;
  summaries_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ActivityAnalyticsWhere = {
  AND?: InputMaybe<Array<ActivityAnalyticsWhere>>;
  NOT?: InputMaybe<ActivityAnalyticsWhere>;
  OR?: InputMaybe<Array<ActivityAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  enrollment_LT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  summaries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summaries_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityCompletionDetails = {
  __typename?: "ActivityCompletionDetails";
  completionStats: CompletionStats;
  details: ActivityCompletionDetailsData;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type ActivityCompletionDetailsAggregateSelection = {
  __typename?: "ActivityCompletionDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type ActivityCompletionDetailsConnection = {
  __typename?: "ActivityCompletionDetailsConnection";
  edges: Array<ActivityCompletionDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type ActivityCompletionDetailsData = {
  __typename?: "ActivityCompletionDetailsData";
  objectives: Array<ObjectiveCompletionDetails>;
};

export type ActivityCompletionDetailsDataAggregateSelection = {
  __typename?: "ActivityCompletionDetailsDataAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsDataConnection = {
  __typename?: "ActivityCompletionDetailsDataConnection";
  edges: Array<ActivityCompletionDetailsDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsDataCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsDataEdge = {
  __typename?: "ActivityCompletionDetailsDataEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityCompletionDetailsData;
};

export type ActivityCompletionDetailsDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCompletionDetailsDataUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsDataWhere = {
  AND?: InputMaybe<Array<ActivityCompletionDetailsDataWhere>>;
  NOT?: InputMaybe<ActivityCompletionDetailsDataWhere>;
  OR?: InputMaybe<Array<ActivityCompletionDetailsDataWhere>>;
};

export type ActivityCompletionDetailsEdge = {
  __typename?: "ActivityCompletionDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityCompletionDetails;
};

export type ActivityCompletionDetailsFull = {
  __typename?: "ActivityCompletionDetailsFull";
  completionStats: CompletionStats;
  course_id?: Maybe<Scalars["ID"]["output"]>;
  course_name?: Maybe<Scalars["String"]["output"]>;
  details: ActivityCompletionDetailsObjectives;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  module_id?: Maybe<Scalars["ID"]["output"]>;
  module_name?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityCompletionDetailsFullAggregateSelection = {
  __typename?: "ActivityCompletionDetailsFullAggregateSelection";
  count: Scalars["Int"]["output"];
  course_id: IdAggregateSelection;
  course_name: StringAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  module_id: IdAggregateSelection;
  module_name: StringAggregateSelection;
};

export type ActivityCompletionDetailsFullCreateInput = {
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityCompletionDetailsFullEdge = {
  __typename?: "ActivityCompletionDetailsFullEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityCompletionDetailsFull;
};

export type ActivityCompletionDetailsFullOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityCompletionDetailsFullSort objects to sort ActivityCompletionDetailsFulls by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityCompletionDetailsFullSort>>;
};

/** Fields to sort ActivityCompletionDetailsFulls by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityCompletionDetailsFullSort object. */
export type ActivityCompletionDetailsFullSort = {
  course_id?: InputMaybe<SortDirection>;
  course_name?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  module_id?: InputMaybe<SortDirection>;
  module_name?: InputMaybe<SortDirection>;
};

export type ActivityCompletionDetailsFullUpdateInput = {
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityCompletionDetailsFullWhere = {
  AND?: InputMaybe<Array<ActivityCompletionDetailsFullWhere>>;
  NOT?: InputMaybe<ActivityCompletionDetailsFullWhere>;
  OR?: InputMaybe<Array<ActivityCompletionDetailsFullWhere>>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  course_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  module_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  module_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  module_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  module_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityCompletionDetailsFullsConnection = {
  __typename?: "ActivityCompletionDetailsFullsConnection";
  edges: Array<ActivityCompletionDetailsFullEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsObjectives = {
  __typename?: "ActivityCompletionDetailsObjectives";
  objectives: Array<ObjectiveCompletionDetails>;
};

export type ActivityCompletionDetailsObjectivesAggregateSelection = {
  __typename?: "ActivityCompletionDetailsObjectivesAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsObjectivesConnection = {
  __typename?: "ActivityCompletionDetailsObjectivesConnection";
  edges: Array<ActivityCompletionDetailsObjectivesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityCompletionDetailsObjectivesCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsObjectivesEdge = {
  __typename?: "ActivityCompletionDetailsObjectivesEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityCompletionDetailsObjectives;
};

export type ActivityCompletionDetailsObjectivesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCompletionDetailsObjectivesUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsObjectivesWhere = {
  AND?: InputMaybe<Array<ActivityCompletionDetailsObjectivesWhere>>;
  NOT?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
  OR?: InputMaybe<Array<ActivityCompletionDetailsObjectivesWhere>>;
};

export type ActivityCompletionDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityCompletionDetailsSort objects to sort ActivityCompletionDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityCompletionDetailsSort>>;
};

export type ActivityCompletionDetailsResponse = {
  __typename?: "ActivityCompletionDetailsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ActivityCompletionDetailsFull>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ActivityCompletionDetailsResponseAggregateSelection = {
  __typename?: "ActivityCompletionDetailsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ActivityCompletionDetailsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ActivityCompletionDetailsResponseEdge = {
  __typename?: "ActivityCompletionDetailsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityCompletionDetailsResponse;
};

export type ActivityCompletionDetailsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityCompletionDetailsResponseSort objects to sort ActivityCompletionDetailsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityCompletionDetailsResponseSort>>;
};

/** Fields to sort ActivityCompletionDetailsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityCompletionDetailsResponseSort object. */
export type ActivityCompletionDetailsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ActivityCompletionDetailsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsResponseWhere = {
  AND?: InputMaybe<Array<ActivityCompletionDetailsResponseWhere>>;
  NOT?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
  OR?: InputMaybe<Array<ActivityCompletionDetailsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCompletionDetailsResponsesConnection = {
  __typename?: "ActivityCompletionDetailsResponsesConnection";
  edges: Array<ActivityCompletionDetailsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ActivityCompletionDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityCompletionDetailsSort object. */
export type ActivityCompletionDetailsSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type ActivityCompletionDetailsUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityCompletionDetailsWhere = {
  AND?: InputMaybe<Array<ActivityCompletionDetailsWhere>>;
  NOT?: InputMaybe<ActivityCompletionDetailsWhere>;
  OR?: InputMaybe<Array<ActivityCompletionDetailsWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityInsights = {
  __typename?: "ActivityInsights";
  average_time_taken: Scalars["Float"]["output"];
  completion_rate: Scalars["Float"]["output"];
  confusion_points_details: Array<Scalars["String"]["output"]>;
  further_recommendations: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  overall_sentiment: Scalars["String"]["output"];
  student_engagement: Scalars["String"]["output"];
  success_points_details: Array<Scalars["String"]["output"]>;
};

export type ActivityInsightsAggregateSelection = {
  __typename?: "ActivityInsightsAggregateSelection";
  average_time_taken: FloatAggregateSelection;
  completion_rate: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  overall_sentiment: StringAggregateSelection;
  student_engagement: StringAggregateSelection;
};

export type ActivityInsightsConnection = {
  __typename?: "ActivityInsightsConnection";
  edges: Array<ActivityInsightsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityInsightsCreateInput = {
  average_time_taken: Scalars["Float"]["input"];
  completion_rate: Scalars["Float"]["input"];
  confusion_points_details: Array<Scalars["String"]["input"]>;
  further_recommendations: Array<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  overall_sentiment: Scalars["String"]["input"];
  student_engagement: Scalars["String"]["input"];
  success_points_details: Array<Scalars["String"]["input"]>;
};

export type ActivityInsightsEdge = {
  __typename?: "ActivityInsightsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityInsights;
};

export type ActivityInsightsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityInsightsSort objects to sort ActivityInsights by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityInsightsSort>>;
};

export type ActivityInsightsResponse = {
  __typename?: "ActivityInsightsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ActivityInsights>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ActivityInsightsResponseAggregateSelection = {
  __typename?: "ActivityInsightsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ActivityInsightsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ActivityInsightsResponseEdge = {
  __typename?: "ActivityInsightsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityInsightsResponse;
};

export type ActivityInsightsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityInsightsResponseSort objects to sort ActivityInsightsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityInsightsResponseSort>>;
};

/** Fields to sort ActivityInsightsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityInsightsResponseSort object. */
export type ActivityInsightsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ActivityInsightsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityInsightsResponseWhere = {
  AND?: InputMaybe<Array<ActivityInsightsResponseWhere>>;
  NOT?: InputMaybe<ActivityInsightsResponseWhere>;
  OR?: InputMaybe<Array<ActivityInsightsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityInsightsResponsesConnection = {
  __typename?: "ActivityInsightsResponsesConnection";
  edges: Array<ActivityInsightsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ActivityInsights by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityInsightsSort object. */
export type ActivityInsightsSort = {
  average_time_taken?: InputMaybe<SortDirection>;
  completion_rate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  overall_sentiment?: InputMaybe<SortDirection>;
  student_engagement?: InputMaybe<SortDirection>;
};

export type ActivityInsightsUpdateInput = {
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  confusion_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  overall_sentiment?: InputMaybe<Scalars["String"]["input"]>;
  student_engagement?: InputMaybe<Scalars["String"]["input"]>;
  success_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  success_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ActivityInsightsWhere = {
  AND?: InputMaybe<Array<ActivityInsightsWhere>>;
  NOT?: InputMaybe<ActivityInsightsWhere>;
  OR?: InputMaybe<Array<ActivityInsightsWhere>>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  completion_rate_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_rate_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  confusion_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  overall_sentiment?: InputMaybe<Scalars["String"]["input"]>;
  overall_sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  overall_sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  overall_sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  overall_sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_engagement?: InputMaybe<Scalars["String"]["input"]>;
  student_engagement_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  student_engagement_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_engagement_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  student_engagement_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  success_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityProgressAnalytics = {
  __typename?: "ActivityProgressAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  chatAnalytics?: Maybe<Array<Maybe<ChatAnalytics>>>;
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: ActivityProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type ActivityProgressAnalyticsAggregateSelection = {
  __typename?: "ActivityProgressAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ActivityProgressAnalyticsConnection = {
  __typename?: "ActivityProgressAnalyticsConnection";
  edges: Array<ActivityProgressAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityProgressAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ActivityProgressAnalyticsEdge = {
  __typename?: "ActivityProgressAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityProgressAnalytics;
};

export type ActivityProgressAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActivityProgressAnalyticsSort objects to sort ActivityProgressAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActivityProgressAnalyticsSort>>;
};

/** Fields to sort ActivityProgressAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActivityProgressAnalyticsSort object. */
export type ActivityProgressAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type ActivityProgressAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ActivityProgressAnalyticsWhere = {
  AND?: InputMaybe<Array<ActivityProgressAnalyticsWhere>>;
  NOT?: InputMaybe<ActivityProgressAnalyticsWhere>;
  OR?: InputMaybe<Array<ActivityProgressAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ActivityProgressDetails = {
  __typename?: "ActivityProgressDetails";
  objectives: Array<ObjectiveProgressAnalytics>;
};

export type ActivityProgressDetailsAggregateSelection = {
  __typename?: "ActivityProgressDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ActivityProgressDetailsConnection = {
  __typename?: "ActivityProgressDetailsConnection";
  edges: Array<ActivityProgressDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ActivityProgressDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityProgressDetailsEdge = {
  __typename?: "ActivityProgressDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ActivityProgressDetails;
};

export type ActivityProgressDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityProgressDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityProgressDetailsWhere = {
  AND?: InputMaybe<Array<ActivityProgressDetailsWhere>>;
  NOT?: InputMaybe<ActivityProgressDetailsWhere>;
  OR?: InputMaybe<Array<ActivityProgressDetailsWhere>>;
};

export type AddAssignmentResponse = {
  __typename?: "AddAssignmentResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type AddAssignmentResponseAggregateSelection = {
  __typename?: "AddAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AddAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type AddAssignmentResponseEdge = {
  __typename?: "AddAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AddAssignmentResponse;
};

export type AddAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AddAssignmentResponseSort objects to sort AddAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AddAssignmentResponseSort>>;
};

/** Fields to sort AddAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AddAssignmentResponseSort object. */
export type AddAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AddAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddAssignmentResponseWhere = {
  AND?: InputMaybe<Array<AddAssignmentResponseWhere>>;
  NOT?: InputMaybe<AddAssignmentResponseWhere>;
  OR?: InputMaybe<Array<AddAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddAssignmentResponsesConnection = {
  __typename?: "AddAssignmentResponsesConnection";
  edges: Array<AddAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AddContentModuleResponse = {
  __typename?: "AddContentModuleResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type AddContentModuleResponseAggregateSelection = {
  __typename?: "AddContentModuleResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AddContentModuleResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type AddContentModuleResponseEdge = {
  __typename?: "AddContentModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AddContentModuleResponse;
};

export type AddContentModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AddContentModuleResponseSort objects to sort AddContentModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AddContentModuleResponseSort>>;
};

/** Fields to sort AddContentModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AddContentModuleResponseSort object. */
export type AddContentModuleResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AddContentModuleResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddContentModuleResponseWhere = {
  AND?: InputMaybe<Array<AddContentModuleResponseWhere>>;
  NOT?: InputMaybe<AddContentModuleResponseWhere>;
  OR?: InputMaybe<Array<AddContentModuleResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddContentModuleResponsesConnection = {
  __typename?: "AddContentModuleResponsesConnection";
  edges: Array<AddContentModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AddCoursesToDegreeInput = {
  courseIds: Array<Scalars["ID"]["input"]>;
  degreeId: Scalars["ID"]["input"];
};

export type AddDegreesToCourseInput = {
  courseId: Scalars["ID"]["input"];
  degreeIds: Array<Scalars["ID"]["input"]>;
};

export type AiTutorAnalyticsConnection = {
  __typename?: "AiTutorAnalyticsConnection";
  edges: Array<AiTutorAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorAnalyticsResponsesConnection = {
  __typename?: "AiTutorAnalyticsResponsesConnection";
  edges: Array<AiTutorAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorEvalCriteriaResponsesConnection = {
  __typename?: "AiTutorEvalCriteriaResponsesConnection";
  edges: Array<AiTutorEvalCriteriaResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorGeneratedResponsesConnection = {
  __typename?: "AiTutorGeneratedResponsesConnection";
  edges: Array<AiTutorGeneratedResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorProgressAnalyticsConnection = {
  __typename?: "AiTutorProgressAnalyticsConnection";
  edges: Array<AiTutorProgressAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorProgressDetailsConnection = {
  __typename?: "AiTutorProgressDetailsConnection";
  edges: Array<AiTutorProgressDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorResponsesConnection = {
  __typename?: "AiTutorResponsesConnection";
  edges: Array<AiTutorResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AiTutorsConnection = {
  __typename?: "AiTutorsConnection";
  edges: Array<AiTutorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AnotherRelationshipField = {
  __typename?: "AnotherRelationshipField";
  anotherProperty?: Maybe<Scalars["String"]["output"]>;
  someProperty?: Maybe<Scalars["String"]["output"]>;
  testing?: Maybe<Testing>;
  testingAggregate?: Maybe<AnotherRelationshipFieldTestingTestingAggregationSelection>;
  testingConnection: AnotherRelationshipFieldTestingConnection;
};

export type AnotherRelationshipFieldTestingArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TestingOptions>;
  where?: InputMaybe<TestingWhere>;
};

export type AnotherRelationshipFieldTestingAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TestingWhere>;
};

export type AnotherRelationshipFieldTestingConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AnotherRelationshipFieldTestingConnectionSort>>;
  where?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
};

export type AnotherRelationshipFieldAggregateSelection = {
  __typename?: "AnotherRelationshipFieldAggregateSelection";
  anotherProperty: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  someProperty: StringAggregateSelection;
};

export type AnotherRelationshipFieldConnectInput = {
  testing?: InputMaybe<AnotherRelationshipFieldTestingConnectFieldInput>;
};

export type AnotherRelationshipFieldConnectWhere = {
  node: AnotherRelationshipFieldWhere;
};

export type AnotherRelationshipFieldCreateInput = {
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<AnotherRelationshipFieldTestingFieldInput>;
};

export type AnotherRelationshipFieldDeleteInput = {
  testing?: InputMaybe<AnotherRelationshipFieldTestingDeleteFieldInput>;
};

export type AnotherRelationshipFieldDisconnectInput = {
  testing?: InputMaybe<AnotherRelationshipFieldTestingDisconnectFieldInput>;
};

export type AnotherRelationshipFieldEdge = {
  __typename?: "AnotherRelationshipFieldEdge";
  cursor: Scalars["String"]["output"];
  node: AnotherRelationshipField;
};

export type AnotherRelationshipFieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AnotherRelationshipFieldSort objects to sort AnotherRelationshipFields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AnotherRelationshipFieldSort>>;
};

export type AnotherRelationshipFieldRelationInput = {
  testing?: InputMaybe<AnotherRelationshipFieldTestingCreateFieldInput>;
};

/** Fields to sort AnotherRelationshipFields by. The order in which sorts are applied is not guaranteed when specifying many fields in one AnotherRelationshipFieldSort object. */
export type AnotherRelationshipFieldSort = {
  anotherProperty?: InputMaybe<SortDirection>;
  someProperty?: InputMaybe<SortDirection>;
};

export type AnotherRelationshipFieldTestingAggregateInput = {
  AND?: InputMaybe<Array<AnotherRelationshipFieldTestingAggregateInput>>;
  NOT?: InputMaybe<AnotherRelationshipFieldTestingAggregateInput>;
  OR?: InputMaybe<Array<AnotherRelationshipFieldTestingAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<RelationEdgePropertiesAggregationWhereInput>;
  node?: InputMaybe<AnotherRelationshipFieldTestingNodeAggregationWhereInput>;
};

export type AnotherRelationshipFieldTestingConnectFieldInput = {
  connect?: InputMaybe<TestingConnectInput>;
  edge?: InputMaybe<RelationEdgePropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TestingConnectWhere>;
};

export type AnotherRelationshipFieldTestingConnection = {
  __typename?: "AnotherRelationshipFieldTestingConnection";
  edges: Array<AnotherRelationshipFieldTestingRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AnotherRelationshipFieldTestingConnectionSort = {
  edge?: InputMaybe<RelationEdgePropertiesSort>;
  node?: InputMaybe<TestingSort>;
};

export type AnotherRelationshipFieldTestingConnectionWhere = {
  AND?: InputMaybe<Array<AnotherRelationshipFieldTestingConnectionWhere>>;
  NOT?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
  OR?: InputMaybe<Array<AnotherRelationshipFieldTestingConnectionWhere>>;
  edge?: InputMaybe<RelationEdgePropertiesWhere>;
  node?: InputMaybe<TestingWhere>;
};

export type AnotherRelationshipFieldTestingCreateFieldInput = {
  edge?: InputMaybe<RelationEdgePropertiesCreateInput>;
  node: TestingCreateInput;
};

export type AnotherRelationshipFieldTestingDeleteFieldInput = {
  delete?: InputMaybe<TestingDeleteInput>;
  where?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
};

export type AnotherRelationshipFieldTestingDisconnectFieldInput = {
  disconnect?: InputMaybe<TestingDisconnectInput>;
  where?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
};

export type AnotherRelationshipFieldTestingFieldInput = {
  connect?: InputMaybe<AnotherRelationshipFieldTestingConnectFieldInput>;
  create?: InputMaybe<AnotherRelationshipFieldTestingCreateFieldInput>;
};

export type AnotherRelationshipFieldTestingNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AnotherRelationshipFieldTestingNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AnotherRelationshipFieldTestingNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AnotherRelationshipFieldTestingNodeAggregationWhereInput>>;
  fieldDateTime_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AnotherRelationshipFieldTestingRelationship = {
  __typename?: "AnotherRelationshipFieldTestingRelationship";
  cursor: Scalars["String"]["output"];
  node: Testing;
  properties: RelationEdgeProperties;
};

export type AnotherRelationshipFieldTestingTestingAggregationSelection = {
  __typename?: "AnotherRelationshipFieldTestingTestingAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<AnotherRelationshipFieldTestingTestingEdgeAggregateSelection>;
  node?: Maybe<AnotherRelationshipFieldTestingTestingNodeAggregateSelection>;
};

export type AnotherRelationshipFieldTestingTestingEdgeAggregateSelection = {
  __typename?: "AnotherRelationshipFieldTestingTestingEdgeAggregateSelection";
  anotherRelationshipFieldProperty: StringAggregateSelection;
  someRelationshipFieldProperty: StringAggregateSelection;
};

export type AnotherRelationshipFieldTestingTestingNodeAggregateSelection = {
  __typename?: "AnotherRelationshipFieldTestingTestingNodeAggregateSelection";
  fieldDateTime: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type AnotherRelationshipFieldTestingUpdateConnectionInput = {
  edge?: InputMaybe<RelationEdgePropertiesUpdateInput>;
  node?: InputMaybe<TestingUpdateInput>;
};

export type AnotherRelationshipFieldTestingUpdateFieldInput = {
  connect?: InputMaybe<AnotherRelationshipFieldTestingConnectFieldInput>;
  create?: InputMaybe<AnotherRelationshipFieldTestingCreateFieldInput>;
  delete?: InputMaybe<AnotherRelationshipFieldTestingDeleteFieldInput>;
  disconnect?: InputMaybe<AnotherRelationshipFieldTestingDisconnectFieldInput>;
  update?: InputMaybe<AnotherRelationshipFieldTestingUpdateConnectionInput>;
  where?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
};

export type AnotherRelationshipFieldUpdateInput = {
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<AnotherRelationshipFieldTestingUpdateFieldInput>;
};

export type AnotherRelationshipFieldWhere = {
  AND?: InputMaybe<Array<AnotherRelationshipFieldWhere>>;
  NOT?: InputMaybe<AnotherRelationshipFieldWhere>;
  OR?: InputMaybe<Array<AnotherRelationshipFieldWhere>>;
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  anotherProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  someProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<TestingWhere>;
  testingAggregate?: InputMaybe<AnotherRelationshipFieldTestingAggregateInput>;
  testingConnection?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
  testingConnection_NOT?: InputMaybe<AnotherRelationshipFieldTestingConnectionWhere>;
  testing_NOT?: InputMaybe<TestingWhere>;
};

export type AnotherRelationshipFieldsConnection = {
  __typename?: "AnotherRelationshipFieldsConnection";
  edges: Array<AnotherRelationshipFieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AspectDetailsType = {
  __typename?: "AspectDetailsType";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  communicationChannel?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  connectionStrength?: Maybe<Scalars["Int"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  deadline?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  frequencyOfInteraction?: Maybe<Scalars["String"]["output"]>;
  friendId?: Maybe<Scalars["Int"]["output"]>;
  friendPersonalityTraits?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  lastInteractionDate?: Maybe<Scalars["DateTime"]["output"]>;
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  relationshipType?: Maybe<Scalars["String"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sharedEventsAttended?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedGoals?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedInterests?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type AspectDetailsTypeAggregateSelection = {
  __typename?: "AspectDetailsTypeAggregateSelection";
  calories: FloatAggregateSelection;
  carbohydrates_grams: FloatAggregateSelection;
  category: StringAggregateSelection;
  communicationChannel: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  connectionStrength: IntAggregateSelection;
  contentType: StringAggregateSelection;
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  deadline: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  endDate: DateTimeAggregateSelection;
  fat_grams: FloatAggregateSelection;
  fiber_grams: FloatAggregateSelection;
  frequencyOfInteraction: StringAggregateSelection;
  friendId: IntAggregateSelection;
  id: IntAggregateSelection;
  importance: IntAggregateSelection;
  intensity: IntAggregateSelection;
  language: StringAggregateSelection;
  lastInteractionDate: DateTimeAggregateSelection;
  lastUpdated: DateTimeAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  level: StringAggregateSelection;
  name: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  priority: StringAggregateSelection;
  protein_grams: FloatAggregateSelection;
  relationshipType: StringAggregateSelection;
  sentiment: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sodium_mg: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  status: StringAggregateSelection;
  sugars_grams: FloatAggregateSelection;
  text: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timestamp: DateTimeAggregateSelection;
  totalCalories: FloatAggregateSelection;
  totalCaloriesBurned: FloatAggregateSelection;
  totalCarbs: FloatAggregateSelection;
  totalDurationMinutes: FloatAggregateSelection;
  totalFats: FloatAggregateSelection;
  totalProteins: FloatAggregateSelection;
  totalWorkouts: FloatAggregateSelection;
  trigger: StringAggregateSelection;
  type: StringAggregateSelection;
  value: IntAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type AspectDetailsTypeCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypeEdge = {
  __typename?: "AspectDetailsTypeEdge";
  cursor: Scalars["String"]["output"];
  node: AspectDetailsType;
};

export type AspectDetailsTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AspectDetailsTypeSort objects to sort AspectDetailsTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AspectDetailsTypeSort>>;
};

/** Fields to sort AspectDetailsTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one AspectDetailsTypeSort object. */
export type AspectDetailsTypeSort = {
  calories?: InputMaybe<SortDirection>;
  carbohydrates_grams?: InputMaybe<SortDirection>;
  category?: InputMaybe<SortDirection>;
  communicationChannel?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  connectionStrength?: InputMaybe<SortDirection>;
  contentType?: InputMaybe<SortDirection>;
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  deadline?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  fat_grams?: InputMaybe<SortDirection>;
  fiber_grams?: InputMaybe<SortDirection>;
  frequencyOfInteraction?: InputMaybe<SortDirection>;
  friendId?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  importance?: InputMaybe<SortDirection>;
  intensity?: InputMaybe<SortDirection>;
  language?: InputMaybe<SortDirection>;
  lastInteractionDate?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  learningDuration?: InputMaybe<SortDirection>;
  learningStyle?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  notificationPreferences?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  protein_grams?: InputMaybe<SortDirection>;
  relationshipType?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sodium_mg?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  sugars_grams?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
  totalCalories?: InputMaybe<SortDirection>;
  totalCaloriesBurned?: InputMaybe<SortDirection>;
  totalCarbs?: InputMaybe<SortDirection>;
  totalDurationMinutes?: InputMaybe<SortDirection>;
  totalFats?: InputMaybe<SortDirection>;
  totalProteins?: InputMaybe<SortDirection>;
  totalWorkouts?: InputMaybe<SortDirection>;
  trigger?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  value?: InputMaybe<SortDirection>;
  water_intake_oz?: InputMaybe<SortDirection>;
};

export type AspectDetailsTypeUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  friendPersonalityTraits_POP?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedEventsAttended_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedEventsAttended_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedGoals_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedInterests_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  value_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypeWhere = {
  AND?: InputMaybe<Array<AspectDetailsTypeWhere>>;
  NOT?: InputMaybe<AspectDetailsTypeWhere>;
  OR?: InputMaybe<Array<AspectDetailsTypeWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  carbohydrates_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  communicationChannel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  connectionStrength_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  contentType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  contentType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  contentType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contentType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  deadline_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fat_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fiber_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frequencyOfInteraction_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_GT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  friendId_LT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  friendPersonalityTraits_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  importance_LT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  intensity_LT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastInteractionDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastInteractionDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastInteractionDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastInteractionDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastInteractionDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  learningDuration_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStyle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  notificationPreferences_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  protein_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  relationshipType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedEventsAttended_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sodium_mg_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugars_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  timestamp_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCaloriesBurned_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCalories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCarbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalDurationMinutes_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalFats_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalProteins_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalWorkouts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  trigger_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trigger_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trigger_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trigger_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_GT?: InputMaybe<Scalars["Int"]["input"]>;
  value_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  value_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  value_LT?: InputMaybe<Scalars["Int"]["input"]>;
  value_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  water_intake_oz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypesConnection = {
  __typename?: "AspectDetailsTypesConnection";
  edges: Array<AspectDetailsTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Assessment = {
  __typename?: "Assessment";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  isEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  moduleHasAssessment: Module;
  moduleHasAssessmentAggregate?: Maybe<AssessmentModuleModuleHasAssessmentAggregationSelection>;
  moduleHasAssessmentConnection: AssessmentModuleHasAssessmentConnection;
  userHasAssessment: Array<User>;
  userHasAssessmentAggregate?: Maybe<AssessmentUserUserHasAssessmentAggregationSelection>;
  userHasAssessmentConnection: AssessmentUserHasAssessmentConnection;
};

export type AssessmentModuleHasAssessmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type AssessmentModuleHasAssessmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type AssessmentModuleHasAssessmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssessmentModuleHasAssessmentConnectionSort>>;
  where?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
};

export type AssessmentUserHasAssessmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AssessmentUserHasAssessmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AssessmentUserHasAssessmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssessmentUserHasAssessmentConnectionSort>>;
  where?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
};

export type AssessmentAggregateSelection = {
  __typename?: "AssessmentAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type AssessmentConnectInput = {
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentConnectFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentConnectFieldInput>>;
};

export type AssessmentConnectOrCreateInput = {
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentConnectOrCreateFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentConnectOrCreateFieldInput>>;
};

export type AssessmentConnectOrCreateWhere = {
  node: AssessmentUniqueWhere;
};

export type AssessmentConnectWhere = {
  node: AssessmentWhere;
};

export type AssessmentCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentFieldInput>;
  userHasAssessment?: InputMaybe<AssessmentUserHasAssessmentFieldInput>;
};

export type AssessmentDeleteInput = {
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentDeleteFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentDeleteFieldInput>>;
};

export type AssessmentDisconnectInput = {
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentDisconnectFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentDisconnectFieldInput>>;
};

export type AssessmentEdge = {
  __typename?: "AssessmentEdge";
  cursor: Scalars["String"]["output"];
  node: Assessment;
};

export type AssessmentListResponse = {
  __typename?: "AssessmentListResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<AssessmentListResponseData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AssessmentListResponseAggregateSelection = {
  __typename?: "AssessmentListResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AssessmentListResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentListResponseData = {
  __typename?: "AssessmentListResponseData";
  assessments?: Maybe<Array<Maybe<AssessmentListResponseFormatted>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type AssessmentListResponseDataAggregateSelection = {
  __typename?: "AssessmentListResponseDataAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCount: IntAggregateSelection;
};

export type AssessmentListResponseDataConnection = {
  __typename?: "AssessmentListResponseDataConnection";
  edges: Array<AssessmentListResponseDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssessmentListResponseDataCreateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentListResponseDataEdge = {
  __typename?: "AssessmentListResponseDataEdge";
  cursor: Scalars["String"]["output"];
  node: AssessmentListResponseData;
};

export type AssessmentListResponseDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssessmentListResponseDataSort objects to sort AssessmentListResponseData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssessmentListResponseDataSort>>;
};

/** Fields to sort AssessmentListResponseData by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssessmentListResponseDataSort object. */
export type AssessmentListResponseDataSort = {
  totalCount?: InputMaybe<SortDirection>;
};

export type AssessmentListResponseDataUpdateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentListResponseDataWhere = {
  AND?: InputMaybe<Array<AssessmentListResponseDataWhere>>;
  NOT?: InputMaybe<AssessmentListResponseDataWhere>;
  OR?: InputMaybe<Array<AssessmentListResponseDataWhere>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentListResponseEdge = {
  __typename?: "AssessmentListResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AssessmentListResponse;
};

export type AssessmentListResponseFormatted = {
  __typename?: "AssessmentListResponseFormatted";
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  courseName?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  grade?: Maybe<Scalars["Float"]["output"]>;
  hasTeacherGraded?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
  userName?: Maybe<Scalars["String"]["output"]>;
};

export type AssessmentListResponseFormattedAggregateSelection = {
  __typename?: "AssessmentListResponseFormattedAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  count: Scalars["Int"]["output"];
  courseName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  moduleName: StringAggregateSelection;
  userName: StringAggregateSelection;
};

export type AssessmentListResponseFormattedCreateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssessmentListResponseFormattedEdge = {
  __typename?: "AssessmentListResponseFormattedEdge";
  cursor: Scalars["String"]["output"];
  node: AssessmentListResponseFormatted;
};

export type AssessmentListResponseFormattedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssessmentListResponseFormattedSort objects to sort AssessmentListResponseFormatteds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssessmentListResponseFormattedSort>>;
};

/** Fields to sort AssessmentListResponseFormatteds by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssessmentListResponseFormattedSort object. */
export type AssessmentListResponseFormattedSort = {
  completedAt?: InputMaybe<SortDirection>;
  courseName?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  grade?: InputMaybe<SortDirection>;
  hasTeacherGraded?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isCompleted?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
  userName?: InputMaybe<SortDirection>;
};

export type AssessmentListResponseFormattedUpdateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grade_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssessmentListResponseFormattedWhere = {
  AND?: InputMaybe<Array<AssessmentListResponseFormattedWhere>>;
  NOT?: InputMaybe<AssessmentListResponseFormattedWhere>;
  OR?: InputMaybe<Array<AssessmentListResponseFormattedWhere>>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  completedAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  courseName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grade_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
  userName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  userName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssessmentListResponseFormattedsConnection = {
  __typename?: "AssessmentListResponseFormattedsConnection";
  edges: Array<AssessmentListResponseFormattedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssessmentListResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssessmentListResponseSort objects to sort AssessmentListResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssessmentListResponseSort>>;
};

/** Fields to sort AssessmentListResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssessmentListResponseSort object. */
export type AssessmentListResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AssessmentListResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentListResponseWhere = {
  AND?: InputMaybe<Array<AssessmentListResponseWhere>>;
  NOT?: InputMaybe<AssessmentListResponseWhere>;
  OR?: InputMaybe<Array<AssessmentListResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentListResponsesConnection = {
  __typename?: "AssessmentListResponsesConnection";
  edges: Array<AssessmentListResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssessmentModuleHasAssessmentAggregateInput = {
  AND?: InputMaybe<Array<AssessmentModuleHasAssessmentAggregateInput>>;
  NOT?: InputMaybe<AssessmentModuleHasAssessmentAggregateInput>;
  OR?: InputMaybe<Array<AssessmentModuleHasAssessmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssessmentModuleHasAssessmentNodeAggregationWhereInput>;
};

export type AssessmentModuleHasAssessmentConnectFieldInput = {
  connect?: InputMaybe<ModuleConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type AssessmentModuleHasAssessmentConnectOrCreateFieldInput = {
  onCreate: AssessmentModuleHasAssessmentConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type AssessmentModuleHasAssessmentConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type AssessmentModuleHasAssessmentConnection = {
  __typename?: "AssessmentModuleHasAssessmentConnection";
  edges: Array<AssessmentModuleHasAssessmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssessmentModuleHasAssessmentConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type AssessmentModuleHasAssessmentConnectionWhere = {
  AND?: InputMaybe<Array<AssessmentModuleHasAssessmentConnectionWhere>>;
  NOT?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
  OR?: InputMaybe<Array<AssessmentModuleHasAssessmentConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type AssessmentModuleHasAssessmentCreateFieldInput = {
  node: ModuleCreateInput;
};

export type AssessmentModuleHasAssessmentDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
};

export type AssessmentModuleHasAssessmentDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
};

export type AssessmentModuleHasAssessmentFieldInput = {
  connect?: InputMaybe<AssessmentModuleHasAssessmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<AssessmentModuleHasAssessmentConnectOrCreateFieldInput>;
  create?: InputMaybe<AssessmentModuleHasAssessmentCreateFieldInput>;
};

export type AssessmentModuleHasAssessmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssessmentModuleHasAssessmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssessmentModuleHasAssessmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssessmentModuleHasAssessmentNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentModuleHasAssessmentRelationship = {
  __typename?: "AssessmentModuleHasAssessmentRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type AssessmentModuleHasAssessmentUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type AssessmentModuleHasAssessmentUpdateFieldInput = {
  connect?: InputMaybe<AssessmentModuleHasAssessmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<AssessmentModuleHasAssessmentConnectOrCreateFieldInput>;
  create?: InputMaybe<AssessmentModuleHasAssessmentCreateFieldInput>;
  delete?: InputMaybe<AssessmentModuleHasAssessmentDeleteFieldInput>;
  disconnect?: InputMaybe<AssessmentModuleHasAssessmentDisconnectFieldInput>;
  update?: InputMaybe<AssessmentModuleHasAssessmentUpdateConnectionInput>;
  where?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
};

export type AssessmentModuleModuleHasAssessmentAggregationSelection = {
  __typename?: "AssessmentModuleModuleHasAssessmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssessmentModuleModuleHasAssessmentNodeAggregateSelection>;
};

export type AssessmentModuleModuleHasAssessmentNodeAggregateSelection = {
  __typename?: "AssessmentModuleModuleHasAssessmentNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type AssessmentOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AssessmentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssessmentSort objects to sort Assessments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssessmentSort>>;
};

export type AssessmentRelationInput = {
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentCreateFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentCreateFieldInput>>;
};

export type AssessmentResponse = {
  __typename?: "AssessmentResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Assessment>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AssessmentResponseAggregateSelection = {
  __typename?: "AssessmentResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AssessmentResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentResponseEdge = {
  __typename?: "AssessmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AssessmentResponse;
};

export type AssessmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssessmentResponseSort objects to sort AssessmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssessmentResponseSort>>;
};

/** Fields to sort AssessmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssessmentResponseSort object. */
export type AssessmentResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AssessmentResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentResponseWhere = {
  AND?: InputMaybe<Array<AssessmentResponseWhere>>;
  NOT?: InputMaybe<AssessmentResponseWhere>;
  OR?: InputMaybe<Array<AssessmentResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssessmentResponsesConnection = {
  __typename?: "AssessmentResponsesConnection";
  edges: Array<AssessmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Assessments by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssessmentSort object. */
export type AssessmentSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isEnabled?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
};

export type AssessmentUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssessmentUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAssessment?: InputMaybe<AssessmentModuleHasAssessmentUpdateFieldInput>;
  userHasAssessment?: InputMaybe<Array<AssessmentUserHasAssessmentUpdateFieldInput>>;
};

export type AssessmentUserHasAssessmentAggregateInput = {
  AND?: InputMaybe<Array<AssessmentUserHasAssessmentAggregateInput>>;
  NOT?: InputMaybe<AssessmentUserHasAssessmentAggregateInput>;
  OR?: InputMaybe<Array<AssessmentUserHasAssessmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasAssessmentPropertiesAggregationWhereInput>;
  node?: InputMaybe<AssessmentUserHasAssessmentNodeAggregationWhereInput>;
};

export type AssessmentUserHasAssessmentConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AssessmentUserHasAssessmentConnectOrCreateFieldInput = {
  onCreate: AssessmentUserHasAssessmentConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type AssessmentUserHasAssessmentConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type AssessmentUserHasAssessmentConnection = {
  __typename?: "AssessmentUserHasAssessmentConnection";
  edges: Array<AssessmentUserHasAssessmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssessmentUserHasAssessmentConnectionSort = {
  edge?: InputMaybe<HasAssessmentPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type AssessmentUserHasAssessmentConnectionWhere = {
  AND?: InputMaybe<Array<AssessmentUserHasAssessmentConnectionWhere>>;
  NOT?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
  OR?: InputMaybe<Array<AssessmentUserHasAssessmentConnectionWhere>>;
  edge?: InputMaybe<HasAssessmentPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type AssessmentUserHasAssessmentCreateFieldInput = {
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  node: UserCreateInput;
};

export type AssessmentUserHasAssessmentDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
};

export type AssessmentUserHasAssessmentDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
};

export type AssessmentUserHasAssessmentFieldInput = {
  connect?: InputMaybe<Array<AssessmentUserHasAssessmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentUserHasAssessmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssessmentUserHasAssessmentCreateFieldInput>>;
};

export type AssessmentUserHasAssessmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssessmentUserHasAssessmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssessmentUserHasAssessmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssessmentUserHasAssessmentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssessmentUserHasAssessmentRelationship = {
  __typename?: "AssessmentUserHasAssessmentRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasAssessmentProperties;
};

export type AssessmentUserHasAssessmentUpdateConnectionInput = {
  edge?: InputMaybe<HasAssessmentPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type AssessmentUserHasAssessmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssessmentUserHasAssessmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentUserHasAssessmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssessmentUserHasAssessmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssessmentUserHasAssessmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssessmentUserHasAssessmentDisconnectFieldInput>>;
  update?: InputMaybe<AssessmentUserHasAssessmentUpdateConnectionInput>;
  where?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
};

export type AssessmentUserUserHasAssessmentAggregationSelection = {
  __typename?: "AssessmentUserUserHasAssessmentAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<AssessmentUserUserHasAssessmentEdgeAggregateSelection>;
  node?: Maybe<AssessmentUserUserHasAssessmentNodeAggregateSelection>;
};

export type AssessmentUserUserHasAssessmentEdgeAggregateSelection = {
  __typename?: "AssessmentUserUserHasAssessmentEdgeAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  grade: FloatAggregateSelection;
  remainingTime: StringAggregateSelection;
};

export type AssessmentUserUserHasAssessmentNodeAggregateSelection = {
  __typename?: "AssessmentUserUserHasAssessmentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type AssessmentWhere = {
  AND?: InputMaybe<Array<AssessmentWhere>>;
  NOT?: InputMaybe<AssessmentWhere>;
  OR?: InputMaybe<Array<AssessmentWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasAssessment?: InputMaybe<ModuleWhere>;
  moduleHasAssessmentAggregate?: InputMaybe<AssessmentModuleHasAssessmentAggregateInput>;
  moduleHasAssessmentConnection?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
  moduleHasAssessmentConnection_NOT?: InputMaybe<AssessmentModuleHasAssessmentConnectionWhere>;
  moduleHasAssessment_NOT?: InputMaybe<ModuleWhere>;
  userHasAssessmentAggregate?: InputMaybe<AssessmentUserHasAssessmentAggregateInput>;
  /** Return Assessments where all of the related AssessmentUserHasAssessmentConnections match this filter */
  userHasAssessmentConnection_ALL?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
  /** Return Assessments where none of the related AssessmentUserHasAssessmentConnections match this filter */
  userHasAssessmentConnection_NONE?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
  /** Return Assessments where one of the related AssessmentUserHasAssessmentConnections match this filter */
  userHasAssessmentConnection_SINGLE?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
  /** Return Assessments where some of the related AssessmentUserHasAssessmentConnections match this filter */
  userHasAssessmentConnection_SOME?: InputMaybe<AssessmentUserHasAssessmentConnectionWhere>;
  /** Return Assessments where all of the related Users match this filter */
  userHasAssessment_ALL?: InputMaybe<UserWhere>;
  /** Return Assessments where none of the related Users match this filter */
  userHasAssessment_NONE?: InputMaybe<UserWhere>;
  /** Return Assessments where one of the related Users match this filter */
  userHasAssessment_SINGLE?: InputMaybe<UserWhere>;
  /** Return Assessments where some of the related Users match this filter */
  userHasAssessment_SOME?: InputMaybe<UserWhere>;
};

export type AssessmentsConnection = {
  __typename?: "AssessmentsConnection";
  edges: Array<AssessmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignedQuest = {
  __typename?: "AssignedQuest";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isComplete: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export type AssignedQuestAggregateSelection = {
  __typename?: "AssignedQuestAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  title: StringAggregateSelection;
};

export type AssignedQuestCreateInput = {
  description: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  isComplete: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type AssignedQuestEdge = {
  __typename?: "AssignedQuestEdge";
  cursor: Scalars["String"]["output"];
  node: AssignedQuest;
};

export type AssignedQuestOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignedQuestSort objects to sort AssignedQuests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignedQuestSort>>;
};

/** Fields to sort AssignedQuests by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignedQuestSort object. */
export type AssignedQuestSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isComplete?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type AssignedQuestUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignedQuestWhere = {
  AND?: InputMaybe<Array<AssignedQuestWhere>>;
  NOT?: InputMaybe<AssignedQuestWhere>;
  OR?: InputMaybe<Array<AssignedQuestWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignedQuestsConnection = {
  __typename?: "AssignedQuestsConnection";
  edges: Array<AssignedQuestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Assignment = {
  __typename?: "Assignment";
  _empty?: Maybe<Scalars["String"]["output"]>;
  allowed_attempts?: Maybe<Scalars["Float"]["output"]>;
  anonymize_students?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_grading?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_instructor_annotations?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  assignee_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assignee_mode?: Maybe<Scalars["String"]["output"]>;
  assignment_group_id?: Maybe<Scalars["Float"]["output"]>;
  assignment_name?: Maybe<Scalars["String"]["output"]>;
  automatic_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  can_duplicate?: Maybe<Scalars["Boolean"]["output"]>;
  category_id?: Maybe<Scalars["String"]["output"]>;
  courseCategoriesHasAssignment: Array<CourseCategory>;
  courseCategoriesHasAssignmentAggregate?: Maybe<AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection>;
  courseCategoriesHasAssignmentConnection: AssignmentCourseCategoriesHasAssignmentConnection;
  course_id?: Maybe<Scalars["Float"]["output"]>;
  coursesHasAssignment: Array<Course>;
  coursesHasAssignmentAggregate?: Maybe<AssignmentCourseCoursesHasAssignmentAggregationSelection>;
  coursesHasAssignmentConnection: AssignmentCoursesHasAssignmentConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  description_plaintext?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_allow_rating?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_assignment_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_attachments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_author_anonymous_id?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_avatar_image_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_display_name?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_html_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_can_group?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_can_lock?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_can_unpublish?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_comments_disabled?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_created_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_discussion_subentry_count?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_discussion_type?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_group_topic_children?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_html_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_is_announcement?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_is_section_specific?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_last_reply_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_locked?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_message?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_only_graders_can_rate?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_attach?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_delete?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_manage_assign_to?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_reply?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_update?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_pinned?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_podcast_has_student_posts?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_posted_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_published?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_read_state?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_sort_by_rating?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_subscribed?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_summary_enabled?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_title?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_topic_children?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_unread_count?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_user_can_see_posts?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_user_name?: Maybe<Scalars["String"]["output"]>;
  display_date?: Maybe<Scalars["String"]["output"]>;
  due_at?: Maybe<Scalars["String"]["output"]>;
  due_date?: Maybe<Scalars["DateTime"]["output"]>;
  due_date_required?: Maybe<Scalars["Boolean"]["output"]>;
  embedding?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  enableStudentFileUpload?: Maybe<Scalars["Boolean"]["output"]>;
  end_date?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_content_id?: Maybe<Scalars["Float"]["output"]>;
  external_tool_tag_attributes_content_type?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_external_data?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_new_tab?: Maybe<Scalars["Boolean"]["output"]>;
  external_tool_tag_attributes_resource_link_id?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_url?: Maybe<Scalars["String"]["output"]>;
  files: Array<File>;
  filesAggregate?: Maybe<AssignmentFileFilesAggregationSelection>;
  filesConnection: AssignmentFilesConnection;
  frozen_attributes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  grade_group_students_individually?: Maybe<Scalars["Boolean"]["output"]>;
  grade_points?: Maybe<Scalars["Float"]["output"]>;
  graded_submissions_exist?: Maybe<Scalars["Boolean"]["output"]>;
  grader_comments_visible_to_graders?: Maybe<Scalars["Boolean"]["output"]>;
  grader_count?: Maybe<Scalars["Float"]["output"]>;
  grader_names_visible_to_final_grader?: Maybe<Scalars["Boolean"]["output"]>;
  graders_anonymous_to_graders?: Maybe<Scalars["Boolean"]["output"]>;
  grading_type?: Maybe<Scalars["String"]["output"]>;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<AssignmentChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: AssignmentHasChatChatsConnection;
  hasObjectiveObjective: Array<Objective>;
  hasObjectiveObjectiveAggregate?: Maybe<AssignmentObjectiveHasObjectiveObjectiveAggregationSelection>;
  hasObjectiveObjectiveConnection: AssignmentHasObjectiveObjectiveConnection;
  has_overrides?: Maybe<Scalars["Boolean"]["output"]>;
  has_submitted_submissions?: Maybe<Scalars["Boolean"]["output"]>;
  hide_in_gradebook?: Maybe<Scalars["Boolean"]["output"]>;
  html?: Maybe<Scalars["String"]["output"]>;
  html_url?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  important_dates?: Maybe<Scalars["Boolean"]["output"]>;
  in_closed_grading_period?: Maybe<Scalars["Boolean"]["output"]>;
  intra_group_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  is_quiz_assignment?: Maybe<Scalars["Boolean"]["output"]>;
  is_quiz_lti_assignment?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  lti_context_id?: Maybe<Scalars["String"]["output"]>;
  max_attempts?: Maybe<Scalars["Float"]["output"]>;
  max_name_length?: Maybe<Scalars["Float"]["output"]>;
  moderated_grading?: Maybe<Scalars["Boolean"]["output"]>;
  moduleHasAssignments: Array<Module>;
  moduleHasAssignmentsAggregate?: Maybe<AssignmentModuleModuleHasAssignmentsAggregationSelection>;
  moduleHasAssignmentsConnection: AssignmentModuleHasAssignmentsConnection;
  muted?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  needs_grading_count?: Maybe<Scalars["Float"]["output"]>;
  objectives?: Maybe<Scalars["String"]["output"]>;
  omit_from_final_grade?: Maybe<Scalars["Boolean"]["output"]>;
  only_visible_to_overrides?: Maybe<Scalars["Boolean"]["output"]>;
  peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  points_possible?: Maybe<Scalars["Float"]["output"]>;
  position?: Maybe<Scalars["Float"]["output"]>;
  post_manually?: Maybe<Scalars["Boolean"]["output"]>;
  post_to_sis?: Maybe<Scalars["Boolean"]["output"]>;
  published?: Maybe<Scalars["Boolean"]["output"]>;
  require_lockdown_browser?: Maybe<Scalars["Boolean"]["output"]>;
  resource_metadata?: Maybe<Scalars["String"]["output"]>;
  restrict_quantitative_data?: Maybe<Scalars["Boolean"]["output"]>;
  section_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  secure_params?: Maybe<Scalars["String"]["output"]>;
  session_id?: Maybe<Scalars["String"]["output"]>;
  start_date?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  submissions: Array<Submission>;
  submissionsAggregate?: Maybe<AssignmentSubmissionSubmissionsAggregationSelection>;
  submissionsConnection: AssignmentSubmissionsConnection;
  submissions_download_url?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  unpublishable?: Maybe<Scalars["Boolean"]["output"]>;
  updated_at?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolAssignment: Array<User>;
  usersHasSchoolAssignmentAggregate?: Maybe<AssignmentUserUsersHasSchoolAssignmentAggregationSelection>;
  usersHasSchoolAssignmentConnection: AssignmentUsersHasSchoolAssignmentConnection;
  videoUrl?: Maybe<Scalars["String"]["output"]>;
  visible_to_everyone?: Maybe<Scalars["Boolean"]["output"]>;
  workflow_state?: Maybe<Scalars["String"]["output"]>;
};

export type AssignmentCourseCategoriesHasAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentFilesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type AssignmentFilesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type AssignmentFilesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentFilesConnectionSort>>;
  where?: InputMaybe<AssignmentFilesConnectionWhere>;
};

export type AssignmentHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentHasChatChatsConnectionSort>>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionSort>>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentModuleHasAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type AssignmentModuleHasAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type AssignmentModuleHasAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectionSort>>;
  where?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
};

export type AssignmentSubmissionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubmissionOptions>;
  where?: InputMaybe<SubmissionWhere>;
};

export type AssignmentSubmissionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubmissionWhere>;
};

export type AssignmentSubmissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentSubmissionsConnectionSort>>;
  where?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentAggregateSelection = {
  __typename?: "AssignmentAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type AssignmentAndSubmission = {
  __typename?: "AssignmentAndSubmission";
  assignment?: Maybe<Assignment>;
  submission?: Maybe<Submission>;
};

export type AssignmentAndSubmissionAggregateSelection = {
  __typename?: "AssignmentAndSubmissionAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type AssignmentAndSubmissionCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentAndSubmissionEdge = {
  __typename?: "AssignmentAndSubmissionEdge";
  cursor: Scalars["String"]["output"];
  node: AssignmentAndSubmission;
};

export type AssignmentAndSubmissionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentAndSubmissionResponse = {
  __typename?: "AssignmentAndSubmissionResponse";
  data?: Maybe<Array<Maybe<AssignmentAndSubmission>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AssignmentAndSubmissionResponseAggregateSelection = {
  __typename?: "AssignmentAndSubmissionResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AssignmentAndSubmissionResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentAndSubmissionResponseEdge = {
  __typename?: "AssignmentAndSubmissionResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AssignmentAndSubmissionResponse;
};

export type AssignmentAndSubmissionResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignmentAndSubmissionResponseSort objects to sort AssignmentAndSubmissionResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignmentAndSubmissionResponseSort>>;
};

/** Fields to sort AssignmentAndSubmissionResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignmentAndSubmissionResponseSort object. */
export type AssignmentAndSubmissionResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AssignmentAndSubmissionResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentAndSubmissionResponseWhere = {
  AND?: InputMaybe<Array<AssignmentAndSubmissionResponseWhere>>;
  NOT?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
  OR?: InputMaybe<Array<AssignmentAndSubmissionResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentAndSubmissionResponsesConnection = {
  __typename?: "AssignmentAndSubmissionResponsesConnection";
  edges: Array<AssignmentAndSubmissionResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentAndSubmissionUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentAndSubmissionWhere = {
  AND?: InputMaybe<Array<AssignmentAndSubmissionWhere>>;
  NOT?: InputMaybe<AssignmentAndSubmissionWhere>;
  OR?: InputMaybe<Array<AssignmentAndSubmissionWhere>>;
};

export type AssignmentAndSubmissionsConnection = {
  __typename?: "AssignmentAndSubmissionsConnection";
  edges: Array<AssignmentAndSubmissionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentChatHasChatChatsAggregationSelection = {
  __typename?: "AssignmentChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<AssignmentChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<AssignmentChatHasChatChatsNodeAggregateSelection>;
};

export type AssignmentChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "AssignmentChatHasChatChatsEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type AssignmentChatHasChatChatsNodeAggregateSelection = {
  __typename?: "AssignmentChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type AssignmentConnectInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  files?: InputMaybe<Array<AssignmentFilesConnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectFieldInput>>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsConnectFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
};

export type AssignmentConnectOrCreateInput = {
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectOrCreateFieldInput>>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectOrCreateFieldInput>>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsConnectOrCreateFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInput>>;
};

export type AssignmentConnectOrCreateWhere = {
  node: AssignmentUniqueWhere;
};

export type AssignmentConnectWhere = {
  node: AssignmentWhere;
};

export type AssignmentCourseCategoriesHasAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseCategoryConnectWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentConnection = {
  __typename?: "AssignmentCourseCategoriesHasAssignmentConnection";
  edges: Array<AssignmentCourseCategoriesHasAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentCourseCategoriesHasAssignmentConnectionSort = {
  node?: InputMaybe<CourseCategorySort>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionWhere>>;
  node?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentCreateFieldInput = {
  node: CourseCategoryCreateInput;
};

export type AssignmentCourseCategoriesHasAssignmentDeleteFieldInput = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseCategoryDisconnectInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
};

export type AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentCourseCategoriesHasAssignmentRelationship = {
  __typename?: "AssignmentCourseCategoriesHasAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type AssignmentCourseCategoriesHasAssignmentUpdateConnectionInput = {
  node?: InputMaybe<CourseCategoryUpdateInput>;
};

export type AssignmentCourseCategoriesHasAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentCourseCategoriesHasAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection = {
  __typename?: "AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection>;
};

export type AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type AssignmentCourseCoursesHasAssignmentAggregationSelection = {
  __typename?: "AssignmentCourseCoursesHasAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentCourseCoursesHasAssignmentNodeAggregateSelection>;
};

export type AssignmentCourseCoursesHasAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentCourseCoursesHasAssignmentNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type AssignmentCoursesHasAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>;
};

export type AssignmentCoursesHasAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type AssignmentCoursesHasAssignmentConnectOrCreateFieldInput = {
  onCreate: AssignmentCoursesHasAssignmentConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type AssignmentCoursesHasAssignmentConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type AssignmentCoursesHasAssignmentConnection = {
  __typename?: "AssignmentCoursesHasAssignmentConnection";
  edges: Array<AssignmentCoursesHasAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentCoursesHasAssignmentConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type AssignmentCoursesHasAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentCreateFieldInput = {
  node: CourseCreateInput;
};

export type AssignmentCoursesHasAssignmentDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
};

export type AssignmentCoursesHasAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentCoursesHasAssignmentRelationship = {
  __typename?: "AssignmentCoursesHasAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type AssignmentCoursesHasAssignmentUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type AssignmentCoursesHasAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentCoursesHasAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentCoursesHasAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentCoursesHasAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignment?: InputMaybe<AssignmentCourseCategoriesHasAssignmentFieldInput>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignment?: InputMaybe<AssignmentCoursesHasAssignmentFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  enableStudentFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<AssignmentFilesFieldInput>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  grade_points?: InputMaybe<Scalars["Float"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<AssignmentHasChatChatsFieldInput>;
  hasObjectiveObjective?: InputMaybe<AssignmentHasObjectiveObjectiveFieldInput>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasAssignments?: InputMaybe<AssignmentModuleHasAssignmentsFieldInput>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions?: InputMaybe<AssignmentSubmissionsFieldInput>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignment?: InputMaybe<AssignmentUsersHasSchoolAssignmentFieldInput>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentDeleteInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDeleteFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentDeleteFieldInput>>;
  files?: InputMaybe<Array<AssignmentFilesDeleteFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsDeleteFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDeleteFieldInput>>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsDeleteFieldInput>>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsDeleteFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDeleteFieldInput>>;
};

export type AssignmentDisconnectInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentDisconnectFieldInput>>;
  files?: InputMaybe<Array<AssignmentFilesDisconnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsDisconnectFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDisconnectFieldInput>>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsDisconnectFieldInput>>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsDisconnectFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDisconnectFieldInput>>;
};

export type AssignmentEdge = {
  __typename?: "AssignmentEdge";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type AssignmentFileFilesAggregationSelection = {
  __typename?: "AssignmentFileFilesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentFileFilesNodeAggregateSelection>;
};

export type AssignmentFileFilesNodeAggregateSelection = {
  __typename?: "AssignmentFileFilesNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type AssignmentFilesAggregateInput = {
  AND?: InputMaybe<Array<AssignmentFilesAggregateInput>>;
  NOT?: InputMaybe<AssignmentFilesAggregateInput>;
  OR?: InputMaybe<Array<AssignmentFilesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentFilesNodeAggregationWhereInput>;
};

export type AssignmentFilesConnectFieldInput = {
  connect?: InputMaybe<Array<FileConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type AssignmentFilesConnection = {
  __typename?: "AssignmentFilesConnection";
  edges: Array<AssignmentFilesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentFilesConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type AssignmentFilesConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentFilesConnectionWhere>>;
  NOT?: InputMaybe<AssignmentFilesConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentFilesConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type AssignmentFilesCreateFieldInput = {
  node: FileCreateInput;
};

export type AssignmentFilesDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<AssignmentFilesConnectionWhere>;
};

export type AssignmentFilesDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<AssignmentFilesConnectionWhere>;
};

export type AssignmentFilesFieldInput = {
  connect?: InputMaybe<Array<AssignmentFilesConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentFilesCreateFieldInput>>;
};

export type AssignmentFilesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentFilesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentFilesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentFilesNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentFilesRelationship = {
  __typename?: "AssignmentFilesRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type AssignmentFilesUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type AssignmentFilesUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentFilesConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentFilesCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentFilesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentFilesDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentFilesUpdateConnectionInput>;
  where?: InputMaybe<AssignmentFilesConnectionWhere>;
};

export type AssignmentHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<AssignmentHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<AssignmentHasChatChatsNodeAggregationWhereInput>;
};

export type AssignmentHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type AssignmentHasChatChatsConnection = {
  __typename?: "AssignmentHasChatChatsConnection";
  edges: Array<AssignmentHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type AssignmentHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type AssignmentHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
};

export type AssignmentHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentHasChatChatsRelationship = {
  __typename?: "AssignmentHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type AssignmentHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type AssignmentHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveAggregateInput = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveAggregateInput>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveAggregateInput>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>;
};

export type AssignmentHasObjectiveObjectiveConnectFieldInput = {
  connect?: InputMaybe<Array<ObjectiveConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ObjectiveConnectWhere>;
};

export type AssignmentHasObjectiveObjectiveConnectOrCreateFieldInput = {
  onCreate: AssignmentHasObjectiveObjectiveConnectOrCreateFieldInputOnCreate;
  where: ObjectiveConnectOrCreateWhere;
};

export type AssignmentHasObjectiveObjectiveConnectOrCreateFieldInputOnCreate = {
  node: ObjectiveOnCreateInput;
};

export type AssignmentHasObjectiveObjectiveConnection = {
  __typename?: "AssignmentHasObjectiveObjectiveConnection";
  edges: Array<AssignmentHasObjectiveObjectiveRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentHasObjectiveObjectiveConnectionSort = {
  node?: InputMaybe<ObjectiveSort>;
};

export type AssignmentHasObjectiveObjectiveConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionWhere>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionWhere>>;
  node?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveCreateFieldInput = {
  node: ObjectiveCreateInput;
};

export type AssignmentHasObjectiveObjectiveDeleteFieldInput = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveDisconnectFieldInput = {
  disconnect?: InputMaybe<ObjectiveDisconnectInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
};

export type AssignmentHasObjectiveObjectiveNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>>;
  objective_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sequence_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssignmentHasObjectiveObjectiveRelationship = {
  __typename?: "AssignmentHasObjectiveObjectiveRelationship";
  cursor: Scalars["String"]["output"];
  node: Objective;
};

export type AssignmentHasObjectiveObjectiveUpdateConnectionInput = {
  node?: InputMaybe<ObjectiveUpdateInput>;
};

export type AssignmentHasObjectiveObjectiveUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentHasObjectiveObjectiveUpdateConnectionInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentModuleHasAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<AssignmentModuleHasAssignmentsAggregateInput>>;
  NOT?: InputMaybe<AssignmentModuleHasAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<AssignmentModuleHasAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentModuleHasAssignmentsNodeAggregationWhereInput>;
};

export type AssignmentModuleHasAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type AssignmentModuleHasAssignmentsConnectOrCreateFieldInput = {
  onCreate: AssignmentModuleHasAssignmentsConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type AssignmentModuleHasAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type AssignmentModuleHasAssignmentsConnection = {
  __typename?: "AssignmentModuleHasAssignmentsConnection";
  edges: Array<AssignmentModuleHasAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentModuleHasAssignmentsConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type AssignmentModuleHasAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type AssignmentModuleHasAssignmentsCreateFieldInput = {
  node: ModuleCreateInput;
};

export type AssignmentModuleHasAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
};

export type AssignmentModuleHasAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
};

export type AssignmentModuleHasAssignmentsFieldInput = {
  connect?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentModuleHasAssignmentsCreateFieldInput>>;
};

export type AssignmentModuleHasAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentModuleHasAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentModuleHasAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentModuleHasAssignmentsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentModuleHasAssignmentsRelationship = {
  __typename?: "AssignmentModuleHasAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type AssignmentModuleHasAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type AssignmentModuleHasAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentModuleHasAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentModuleHasAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentModuleHasAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentModuleHasAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentModuleHasAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
};

export type AssignmentModuleModuleHasAssignmentsAggregationSelection = {
  __typename?: "AssignmentModuleModuleHasAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentModuleModuleHasAssignmentsNodeAggregateSelection>;
};

export type AssignmentModuleModuleHasAssignmentsNodeAggregateSelection = {
  __typename?: "AssignmentModuleModuleHasAssignmentsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type AssignmentObjectiveHasObjectiveObjectiveAggregationSelection = {
  __typename?: "AssignmentObjectiveHasObjectiveObjectiveAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection>;
};

export type AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection = {
  __typename?: "AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection";
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type AssignmentOnCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  enableStudentFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  grade_points?: InputMaybe<Scalars["Float"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignmentSort objects to sort Assignments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignmentSort>>;
};

export type AssignmentRelationInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
  files?: InputMaybe<Array<AssignmentFilesCreateFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsCreateFieldInput>>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsCreateFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
};

export type AssignmentResponse = {
  __typename?: "AssignmentResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Assignment>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AssignmentResponseAggregateSelection = {
  __typename?: "AssignmentResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AssignmentResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponseEdge = {
  __typename?: "AssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AssignmentResponse;
};

export type AssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignmentResponseSort objects to sort AssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignmentResponseSort>>;
};

/** Fields to sort AssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignmentResponseSort object. */
export type AssignmentResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AssignmentResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponseWhere = {
  AND?: InputMaybe<Array<AssignmentResponseWhere>>;
  NOT?: InputMaybe<AssignmentResponseWhere>;
  OR?: InputMaybe<Array<AssignmentResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponsesConnection = {
  __typename?: "AssignmentResponsesConnection";
  edges: Array<AssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Assignments by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignmentSort object. */
export type AssignmentSort = {
  _empty?: InputMaybe<SortDirection>;
  allowed_attempts?: InputMaybe<SortDirection>;
  anonymize_students?: InputMaybe<SortDirection>;
  anonymous_grading?: InputMaybe<SortDirection>;
  anonymous_instructor_annotations?: InputMaybe<SortDirection>;
  anonymous_peer_reviews?: InputMaybe<SortDirection>;
  assignee_mode?: InputMaybe<SortDirection>;
  assignment_group_id?: InputMaybe<SortDirection>;
  assignment_name?: InputMaybe<SortDirection>;
  automatic_peer_reviews?: InputMaybe<SortDirection>;
  body?: InputMaybe<SortDirection>;
  can_duplicate?: InputMaybe<SortDirection>;
  category_id?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  description_plaintext?: InputMaybe<SortDirection>;
  discussion_topic_allow_rating?: InputMaybe<SortDirection>;
  discussion_topic_assignment_id?: InputMaybe<SortDirection>;
  discussion_topic_author_anonymous_id?: InputMaybe<SortDirection>;
  discussion_topic_author_avatar_image_url?: InputMaybe<SortDirection>;
  discussion_topic_author_display_name?: InputMaybe<SortDirection>;
  discussion_topic_author_html_url?: InputMaybe<SortDirection>;
  discussion_topic_author_id?: InputMaybe<SortDirection>;
  discussion_topic_can_group?: InputMaybe<SortDirection>;
  discussion_topic_can_lock?: InputMaybe<SortDirection>;
  discussion_topic_can_unpublish?: InputMaybe<SortDirection>;
  discussion_topic_comments_disabled?: InputMaybe<SortDirection>;
  discussion_topic_created_at?: InputMaybe<SortDirection>;
  discussion_topic_discussion_subentry_count?: InputMaybe<SortDirection>;
  discussion_topic_discussion_type?: InputMaybe<SortDirection>;
  discussion_topic_html_url?: InputMaybe<SortDirection>;
  discussion_topic_id?: InputMaybe<SortDirection>;
  discussion_topic_is_announcement?: InputMaybe<SortDirection>;
  discussion_topic_is_section_specific?: InputMaybe<SortDirection>;
  discussion_topic_last_reply_at?: InputMaybe<SortDirection>;
  discussion_topic_locked?: InputMaybe<SortDirection>;
  discussion_topic_locked_for_user?: InputMaybe<SortDirection>;
  discussion_topic_message?: InputMaybe<SortDirection>;
  discussion_topic_only_graders_can_rate?: InputMaybe<SortDirection>;
  discussion_topic_permissions_attach?: InputMaybe<SortDirection>;
  discussion_topic_permissions_delete?: InputMaybe<SortDirection>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<SortDirection>;
  discussion_topic_permissions_reply?: InputMaybe<SortDirection>;
  discussion_topic_permissions_update?: InputMaybe<SortDirection>;
  discussion_topic_pinned?: InputMaybe<SortDirection>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<SortDirection>;
  discussion_topic_posted_at?: InputMaybe<SortDirection>;
  discussion_topic_published?: InputMaybe<SortDirection>;
  discussion_topic_read_state?: InputMaybe<SortDirection>;
  discussion_topic_sort_by_rating?: InputMaybe<SortDirection>;
  discussion_topic_subscribed?: InputMaybe<SortDirection>;
  discussion_topic_summary_enabled?: InputMaybe<SortDirection>;
  discussion_topic_title?: InputMaybe<SortDirection>;
  discussion_topic_unread_count?: InputMaybe<SortDirection>;
  discussion_topic_url?: InputMaybe<SortDirection>;
  discussion_topic_user_can_see_posts?: InputMaybe<SortDirection>;
  discussion_topic_user_name?: InputMaybe<SortDirection>;
  display_date?: InputMaybe<SortDirection>;
  due_at?: InputMaybe<SortDirection>;
  due_date?: InputMaybe<SortDirection>;
  due_date_required?: InputMaybe<SortDirection>;
  enableStudentFileUpload?: InputMaybe<SortDirection>;
  end_date?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_content_id?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_content_type?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_external_data?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_new_tab?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_url?: InputMaybe<SortDirection>;
  grade_group_students_individually?: InputMaybe<SortDirection>;
  grade_points?: InputMaybe<SortDirection>;
  graded_submissions_exist?: InputMaybe<SortDirection>;
  grader_comments_visible_to_graders?: InputMaybe<SortDirection>;
  grader_count?: InputMaybe<SortDirection>;
  grader_names_visible_to_final_grader?: InputMaybe<SortDirection>;
  graders_anonymous_to_graders?: InputMaybe<SortDirection>;
  grading_type?: InputMaybe<SortDirection>;
  has_overrides?: InputMaybe<SortDirection>;
  has_submitted_submissions?: InputMaybe<SortDirection>;
  hide_in_gradebook?: InputMaybe<SortDirection>;
  html?: InputMaybe<SortDirection>;
  html_url?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  important_dates?: InputMaybe<SortDirection>;
  in_closed_grading_period?: InputMaybe<SortDirection>;
  intra_group_peer_reviews?: InputMaybe<SortDirection>;
  is_quiz_assignment?: InputMaybe<SortDirection>;
  is_quiz_lti_assignment?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  locked_for_user?: InputMaybe<SortDirection>;
  lti_context_id?: InputMaybe<SortDirection>;
  max_attempts?: InputMaybe<SortDirection>;
  max_name_length?: InputMaybe<SortDirection>;
  moderated_grading?: InputMaybe<SortDirection>;
  muted?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  needs_grading_count?: InputMaybe<SortDirection>;
  objectives?: InputMaybe<SortDirection>;
  omit_from_final_grade?: InputMaybe<SortDirection>;
  only_visible_to_overrides?: InputMaybe<SortDirection>;
  peer_reviews?: InputMaybe<SortDirection>;
  points_possible?: InputMaybe<SortDirection>;
  position?: InputMaybe<SortDirection>;
  post_manually?: InputMaybe<SortDirection>;
  post_to_sis?: InputMaybe<SortDirection>;
  published?: InputMaybe<SortDirection>;
  require_lockdown_browser?: InputMaybe<SortDirection>;
  resource_metadata?: InputMaybe<SortDirection>;
  restrict_quantitative_data?: InputMaybe<SortDirection>;
  secure_params?: InputMaybe<SortDirection>;
  session_id?: InputMaybe<SortDirection>;
  start_date?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  submissions_download_url?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  unpublishable?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  videoUrl?: InputMaybe<SortDirection>;
  visible_to_everyone?: InputMaybe<SortDirection>;
  workflow_state?: InputMaybe<SortDirection>;
};

export type AssignmentSubmissionSubmissionsAggregationSelection = {
  __typename?: "AssignmentSubmissionSubmissionsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentSubmissionSubmissionsNodeAggregateSelection>;
};

export type AssignmentSubmissionSubmissionsNodeAggregateSelection = {
  __typename?: "AssignmentSubmissionSubmissionsNodeAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  created: DateTimeAggregateSelection;
  feedback: StringAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type AssignmentSubmissionsAggregateInput = {
  AND?: InputMaybe<Array<AssignmentSubmissionsAggregateInput>>;
  NOT?: InputMaybe<AssignmentSubmissionsAggregateInput>;
  OR?: InputMaybe<Array<AssignmentSubmissionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentSubmissionsNodeAggregationWhereInput>;
};

export type AssignmentSubmissionsConnectFieldInput = {
  connect?: InputMaybe<Array<SubmissionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubmissionConnectWhere>;
};

export type AssignmentSubmissionsConnectOrCreateFieldInput = {
  onCreate: AssignmentSubmissionsConnectOrCreateFieldInputOnCreate;
  where: SubmissionConnectOrCreateWhere;
};

export type AssignmentSubmissionsConnectOrCreateFieldInputOnCreate = {
  node: SubmissionOnCreateInput;
};

export type AssignmentSubmissionsConnection = {
  __typename?: "AssignmentSubmissionsConnection";
  edges: Array<AssignmentSubmissionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentSubmissionsConnectionSort = {
  node?: InputMaybe<SubmissionSort>;
};

export type AssignmentSubmissionsConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentSubmissionsConnectionWhere>>;
  NOT?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentSubmissionsConnectionWhere>>;
  node?: InputMaybe<SubmissionWhere>;
};

export type AssignmentSubmissionsCreateFieldInput = {
  node: SubmissionCreateInput;
};

export type AssignmentSubmissionsDeleteFieldInput = {
  delete?: InputMaybe<SubmissionDeleteInput>;
  where?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
};

export type AssignmentSubmissionsDisconnectFieldInput = {
  disconnect?: InputMaybe<SubmissionDisconnectInput>;
  where?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
};

export type AssignmentSubmissionsFieldInput = {
  connect?: InputMaybe<Array<AssignmentSubmissionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentSubmissionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentSubmissionsCreateFieldInput>>;
};

export type AssignmentSubmissionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentSubmissionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentSubmissionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentSubmissionsNodeAggregationWhereInput>>;
  completedAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AssignmentSubmissionsRelationship = {
  __typename?: "AssignmentSubmissionsRelationship";
  cursor: Scalars["String"]["output"];
  node: Submission;
};

export type AssignmentSubmissionsUpdateConnectionInput = {
  node?: InputMaybe<SubmissionUpdateInput>;
};

export type AssignmentSubmissionsUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentSubmissionsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentSubmissionsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentSubmissionsCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentSubmissionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentSubmissionsDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentSubmissionsUpdateConnectionInput>;
  where?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
};

export type AssignmentUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssignmentUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentUpdateFieldInput>>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_attachments_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_attachments_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_group_topic_children_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_group_topic_children_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_topic_children_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_topic_children_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  enableStudentFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<AssignmentFilesUpdateFieldInput>>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frozen_attributes_POP?: InputMaybe<Scalars["Int"]["input"]>;
  frozen_attributes_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  grade_points?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsUpdateFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveUpdateFieldInput>>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasAssignments?: InputMaybe<Array<AssignmentModuleHasAssignmentsUpdateFieldInput>>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  position_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  position_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  section_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  section_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions?: InputMaybe<Array<AssignmentSubmissionsUpdateFieldInput>>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentUpdateFieldInput>>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentUserUsersHasSchoolAssignmentAggregationSelection = {
  __typename?: "AssignmentUserUsersHasSchoolAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection>;
};

export type AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type AssignmentUsersHasSchoolAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>;
};

export type AssignmentUsersHasSchoolAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInput = {
  onCreate: AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type AssignmentUsersHasSchoolAssignmentConnection = {
  __typename?: "AssignmentUsersHasSchoolAssignmentConnection";
  edges: Array<AssignmentUsersHasSchoolAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentUsersHasSchoolAssignmentConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AssignmentUsersHasSchoolAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentCreateFieldInput = {
  node: UserCreateInput;
};

export type AssignmentUsersHasSchoolAssignmentDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
};

export type AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentUsersHasSchoolAssignmentRelationship = {
  __typename?: "AssignmentUsersHasSchoolAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AssignmentUsersHasSchoolAssignmentUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AssignmentUsersHasSchoolAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentUsersHasSchoolAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentWhere = {
  AND?: InputMaybe<Array<AssignmentWhere>>;
  NOT?: InputMaybe<AssignmentWhere>;
  OR?: InputMaybe<Array<AssignmentWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  allowed_attempts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  assignment_group_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignment_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  body_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  body_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  body_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  category_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignmentAggregate?: InputMaybe<AssignmentCourseCategoriesHasAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_ALL?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_NONE?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_SINGLE?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_SOME?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where all of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_ALL?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where none of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_NONE?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where one of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_SINGLE?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where some of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_SOME?: InputMaybe<CourseCategoryWhere>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  course_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignmentAggregate?: InputMaybe<AssignmentCoursesHasAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_ALL?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_NONE?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_SINGLE?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_SOME?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where all of the related Courses match this filter */
  coursesHasAssignment_ALL?: InputMaybe<CourseWhere>;
  /** Return Assignments where none of the related Courses match this filter */
  coursesHasAssignment_NONE?: InputMaybe<CourseWhere>;
  /** Return Assignments where one of the related Courses match this filter */
  coursesHasAssignment_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Assignments where some of the related Courses match this filter */
  coursesHasAssignment_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_plaintext_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_assignment_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_attachments_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_avatar_image_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_display_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_author_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_discussion_subentry_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_discussion_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_group_topic_children_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_last_reply_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_posted_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_read_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_topic_children_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_unread_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_user_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  display_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  display_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  display_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  display_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  due_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  due_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  due_date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  enableStudentFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  end_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  end_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  end_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  external_tool_tag_attributes_content_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_content_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_external_data_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_resource_link_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filesAggregate?: InputMaybe<AssignmentFilesAggregateInput>;
  /** Return Assignments where all of the related AssignmentFilesConnections match this filter */
  filesConnection_ALL?: InputMaybe<AssignmentFilesConnectionWhere>;
  /** Return Assignments where none of the related AssignmentFilesConnections match this filter */
  filesConnection_NONE?: InputMaybe<AssignmentFilesConnectionWhere>;
  /** Return Assignments where one of the related AssignmentFilesConnections match this filter */
  filesConnection_SINGLE?: InputMaybe<AssignmentFilesConnectionWhere>;
  /** Return Assignments where some of the related AssignmentFilesConnections match this filter */
  filesConnection_SOME?: InputMaybe<AssignmentFilesConnectionWhere>;
  /** Return Assignments where all of the related Files match this filter */
  files_ALL?: InputMaybe<FileWhere>;
  /** Return Assignments where none of the related Files match this filter */
  files_NONE?: InputMaybe<FileWhere>;
  /** Return Assignments where one of the related Files match this filter */
  files_SINGLE?: InputMaybe<FileWhere>;
  /** Return Assignments where some of the related Files match this filter */
  files_SOME?: InputMaybe<FileWhere>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frozen_attributes_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  grade_points?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grade_points_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grader_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grading_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<AssignmentHasChatChatsAggregateInput>;
  /** Return Assignments where all of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where none of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where one of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where some of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Assignments where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Assignments where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Assignments where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasObjectiveObjectiveAggregate?: InputMaybe<AssignmentHasObjectiveObjectiveAggregateInput>;
  /** Return Assignments where all of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_ALL?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where none of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_NONE?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where one of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_SINGLE?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where some of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_SOME?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where all of the related Objectives match this filter */
  hasObjectiveObjective_ALL?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where none of the related Objectives match this filter */
  hasObjectiveObjective_NONE?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where one of the related Objectives match this filter */
  hasObjectiveObjective_SINGLE?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where some of the related Objectives match this filter */
  hasObjectiveObjective_SOME?: InputMaybe<ObjectiveWhere>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lti_context_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  max_attempts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  max_name_length_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasAssignmentsAggregate?: InputMaybe<AssignmentModuleHasAssignmentsAggregateInput>;
  /** Return Assignments where all of the related AssignmentModuleHasAssignmentsConnections match this filter */
  moduleHasAssignmentsConnection_ALL?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
  /** Return Assignments where none of the related AssignmentModuleHasAssignmentsConnections match this filter */
  moduleHasAssignmentsConnection_NONE?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
  /** Return Assignments where one of the related AssignmentModuleHasAssignmentsConnections match this filter */
  moduleHasAssignmentsConnection_SINGLE?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
  /** Return Assignments where some of the related AssignmentModuleHasAssignmentsConnections match this filter */
  moduleHasAssignmentsConnection_SOME?: InputMaybe<AssignmentModuleHasAssignmentsConnectionWhere>;
  /** Return Assignments where all of the related Modules match this filter */
  moduleHasAssignments_ALL?: InputMaybe<ModuleWhere>;
  /** Return Assignments where none of the related Modules match this filter */
  moduleHasAssignments_NONE?: InputMaybe<ModuleWhere>;
  /** Return Assignments where one of the related Modules match this filter */
  moduleHasAssignments_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return Assignments where some of the related Modules match this filter */
  moduleHasAssignments_SOME?: InputMaybe<ModuleWhere>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  needs_grading_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  objectives_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objectives_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  points_possible_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  position_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  position_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resource_metadata_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  section_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  session_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  session_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  session_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  session_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  start_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  start_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  start_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  submissionsAggregate?: InputMaybe<AssignmentSubmissionsAggregateInput>;
  /** Return Assignments where all of the related AssignmentSubmissionsConnections match this filter */
  submissionsConnection_ALL?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
  /** Return Assignments where none of the related AssignmentSubmissionsConnections match this filter */
  submissionsConnection_NONE?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
  /** Return Assignments where one of the related AssignmentSubmissionsConnections match this filter */
  submissionsConnection_SINGLE?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
  /** Return Assignments where some of the related AssignmentSubmissionsConnections match this filter */
  submissionsConnection_SOME?: InputMaybe<AssignmentSubmissionsConnectionWhere>;
  /** Return Assignments where all of the related Submissions match this filter */
  submissions_ALL?: InputMaybe<SubmissionWhere>;
  /** Return Assignments where none of the related Submissions match this filter */
  submissions_NONE?: InputMaybe<SubmissionWhere>;
  /** Return Assignments where one of the related Submissions match this filter */
  submissions_SINGLE?: InputMaybe<SubmissionWhere>;
  /** Return Assignments where some of the related Submissions match this filter */
  submissions_SOME?: InputMaybe<SubmissionWhere>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  submissions_download_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignmentAggregate?: InputMaybe<AssignmentUsersHasSchoolAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_ALL?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_NONE?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_SINGLE?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_SOME?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where all of the related Users match this filter */
  usersHasSchoolAssignment_ALL?: InputMaybe<UserWhere>;
  /** Return Assignments where none of the related Users match this filter */
  usersHasSchoolAssignment_NONE?: InputMaybe<UserWhere>;
  /** Return Assignments where one of the related Users match this filter */
  usersHasSchoolAssignment_SINGLE?: InputMaybe<UserWhere>;
  /** Return Assignments where some of the related Users match this filter */
  usersHasSchoolAssignment_SOME?: InputMaybe<UserWhere>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  videoUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workflow_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentsConnection = {
  __typename?: "AssignmentsConnection";
  edges: Array<AssignmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Attribute = {
  __typename?: "Attribute";
  achievements: Array<Achievements>;
  achievementsAggregate?: Maybe<AttributeAchievementsAchievementsAggregationSelection>;
  achievementsConnection: AttributeAchievementsConnection;
  created: Scalars["DateTime"]["output"];
  embedding: Array<Maybe<Scalars["Float"]["output"]>>;
  id: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<AttributeUserUsersAggregationSelection>;
  usersConnection: AttributeUsersConnection;
};

export type AttributeAchievementsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AttributeAchievementsConnectionSort>>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AttributeUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AttributeUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AttributeUsersConnectionSort>>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeAchievementsAchievementsAggregationSelection = {
  __typename?: "AttributeAchievementsAchievementsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AttributeAchievementsAchievementsNodeAggregateSelection>;
};

export type AttributeAchievementsAchievementsNodeAggregateSelection = {
  __typename?: "AttributeAchievementsAchievementsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type AttributeAchievementsAggregateInput = {
  AND?: InputMaybe<Array<AttributeAchievementsAggregateInput>>;
  NOT?: InputMaybe<AttributeAchievementsAggregateInput>;
  OR?: InputMaybe<Array<AttributeAchievementsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AttributeAchievementsNodeAggregationWhereInput>;
};

export type AttributeAchievementsConnectFieldInput = {
  connect?: InputMaybe<Array<AchievementsConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AchievementsConnectWhere>;
};

export type AttributeAchievementsConnection = {
  __typename?: "AttributeAchievementsConnection";
  edges: Array<AttributeAchievementsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AttributeAchievementsConnectionSort = {
  node?: InputMaybe<AchievementsSort>;
};

export type AttributeAchievementsConnectionWhere = {
  AND?: InputMaybe<Array<AttributeAchievementsConnectionWhere>>;
  NOT?: InputMaybe<AttributeAchievementsConnectionWhere>;
  OR?: InputMaybe<Array<AttributeAchievementsConnectionWhere>>;
  node?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsCreateFieldInput = {
  node: AchievementsCreateInput;
};

export type AttributeAchievementsDeleteFieldInput = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAchievementsDisconnectFieldInput = {
  disconnect?: InputMaybe<AchievementsDisconnectInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAchievementsFieldInput = {
  connect?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
};

export type AttributeAchievementsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AttributeAchievementsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AttributeAchievementsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AttributeAchievementsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AttributeAchievementsRelationship = {
  __typename?: "AttributeAchievementsRelationship";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type AttributeAchievementsUpdateConnectionInput = {
  node?: InputMaybe<AchievementsUpdateInput>;
};

export type AttributeAchievementsUpdateFieldInput = {
  connect?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
  delete?: InputMaybe<Array<AttributeAchievementsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AttributeAchievementsDisconnectFieldInput>>;
  update?: InputMaybe<AttributeAchievementsUpdateConnectionInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAggregateSelection = {
  __typename?: "AttributeAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type AttributeConnectInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
};

export type AttributeConnectOrCreateInput = {
  users?: InputMaybe<Array<AttributeUsersConnectOrCreateFieldInput>>;
};

export type AttributeConnectWhere = {
  node: AttributeWhere;
};

export type AttributeCreateInput = {
  achievements?: InputMaybe<AttributeAchievementsFieldInput>;
  created: Scalars["DateTime"]["input"];
  embedding: Array<InputMaybe<Scalars["Float"]["input"]>>;
  id: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  users?: InputMaybe<AttributeUsersFieldInput>;
};

export type AttributeDeleteInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsDeleteFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersDeleteFieldInput>>;
};

export type AttributeDisconnectInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsDisconnectFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersDisconnectFieldInput>>;
};

export type AttributeEdge = {
  __typename?: "AttributeEdge";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type AttributeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AttributeSort objects to sort Attributes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AttributeSort>>;
};

export type AttributeRelationInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
};

/** Fields to sort Attributes by. The order in which sorts are applied is not guaranteed when specifying many fields in one AttributeSort object. */
export type AttributeSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type AttributeUpdateInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<AttributeUsersUpdateFieldInput>>;
};

export type AttributeUserUsersAggregationSelection = {
  __typename?: "AttributeUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AttributeUserUsersNodeAggregateSelection>;
};

export type AttributeUserUsersNodeAggregateSelection = {
  __typename?: "AttributeUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type AttributeUsersAggregateInput = {
  AND?: InputMaybe<Array<AttributeUsersAggregateInput>>;
  NOT?: InputMaybe<AttributeUsersAggregateInput>;
  OR?: InputMaybe<Array<AttributeUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AttributeUsersNodeAggregationWhereInput>;
};

export type AttributeUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AttributeUsersConnectOrCreateFieldInput = {
  onCreate: AttributeUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type AttributeUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type AttributeUsersConnection = {
  __typename?: "AttributeUsersConnection";
  edges: Array<AttributeUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AttributeUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AttributeUsersConnectionWhere = {
  AND?: InputMaybe<Array<AttributeUsersConnectionWhere>>;
  NOT?: InputMaybe<AttributeUsersConnectionWhere>;
  OR?: InputMaybe<Array<AttributeUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AttributeUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type AttributeUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeUsersFieldInput = {
  connect?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AttributeUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
};

export type AttributeUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AttributeUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AttributeUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AttributeUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AttributeUsersRelationship = {
  __typename?: "AttributeUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AttributeUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AttributeUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AttributeUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<AttributeUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AttributeUsersDisconnectFieldInput>>;
  update?: InputMaybe<AttributeUsersUpdateConnectionInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeWhere = {
  AND?: InputMaybe<Array<AttributeWhere>>;
  NOT?: InputMaybe<AttributeWhere>;
  OR?: InputMaybe<Array<AttributeWhere>>;
  achievementsAggregate?: InputMaybe<AttributeAchievementsAggregateInput>;
  /** Return Attributes where all of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_ALL?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where none of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_NONE?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where one of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_SINGLE?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where some of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_SOME?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where all of the related Achievements match this filter */
  achievements_ALL?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where none of the related Achievements match this filter */
  achievements_NONE?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where one of the related Achievements match this filter */
  achievements_SINGLE?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where some of the related Achievements match this filter */
  achievements_SOME?: InputMaybe<AchievementsWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<AttributeUsersAggregateInput>;
  /** Return Attributes where all of the related AttributeUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where none of the related AttributeUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where one of the related AttributeUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where some of the related AttributeUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Attributes where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Attributes where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Attributes where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type AttributesConnection = {
  __typename?: "AttributesConnection";
  edges: Array<AttributeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AudioToggleUserResponse = {
  __typename?: "AudioToggleUserResponse";
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AudioToggleUserResponseAggregateSelection = {
  __typename?: "AudioToggleUserResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type AudioToggleUserResponseCreateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponseEdge = {
  __typename?: "AudioToggleUserResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AudioToggleUserResponse;
};

export type AudioToggleUserResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AudioToggleUserResponseSort objects to sort AudioToggleUserResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AudioToggleUserResponseSort>>;
};

/** Fields to sort AudioToggleUserResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AudioToggleUserResponseSort object. */
export type AudioToggleUserResponseSort = {
  audioOn?: InputMaybe<SortDirection>;
};

export type AudioToggleUserResponseUpdateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponseWhere = {
  AND?: InputMaybe<Array<AudioToggleUserResponseWhere>>;
  NOT?: InputMaybe<AudioToggleUserResponseWhere>;
  OR?: InputMaybe<Array<AudioToggleUserResponseWhere>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponsesConnection = {
  __typename?: "AudioToggleUserResponsesConnection";
  edges: Array<AudioToggleUserResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadResponse = {
  __typename?: "AuthPayloadResponse";
  token: Scalars["String"]["output"];
  user?: Maybe<AuthPayloadUser>;
};

export type AuthPayloadResponseAggregateSelection = {
  __typename?: "AuthPayloadResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  token: StringAggregateSelection;
};

export type AuthPayloadResponseCreateInput = {
  token: Scalars["String"]["input"];
};

export type AuthPayloadResponseEdge = {
  __typename?: "AuthPayloadResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadResponse;
};

export type AuthPayloadResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadResponseSort objects to sort AuthPayloadResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadResponseSort>>;
};

/** Fields to sort AuthPayloadResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadResponseSort object. */
export type AuthPayloadResponseSort = {
  token?: InputMaybe<SortDirection>;
};

export type AuthPayloadResponseUpdateInput = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadResponseWhere = {
  AND?: InputMaybe<Array<AuthPayloadResponseWhere>>;
  NOT?: InputMaybe<AuthPayloadResponseWhere>;
  OR?: InputMaybe<Array<AuthPayloadResponseWhere>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadResponsesConnection = {
  __typename?: "AuthPayloadResponsesConnection";
  edges: Array<AuthPayloadResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadUser = {
  __typename?: "AuthPayloadUser";
  birthday?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  googleAuthTokens?: Maybe<GoogleAuthTokens>;
  id?: Maybe<Scalars["String"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferences?: Maybe<AuthPayloadUserPreferences>;
};

export type AuthPayloadUserAggregateSelection = {
  __typename?: "AuthPayloadUserAggregateSelection";
  birthday: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  id: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
};

export type AuthPayloadUserCreateInput = {
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserEdge = {
  __typename?: "AuthPayloadUserEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadUser;
};

export type AuthPayloadUserOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadUserSort objects to sort AuthPayloadUsers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadUserSort>>;
};

export type AuthPayloadUserPreferences = {
  __typename?: "AuthPayloadUserPreferences";
  language?: Maybe<Scalars["String"]["output"]>;
};

export type AuthPayloadUserPreferencesAggregateSelection = {
  __typename?: "AuthPayloadUserPreferencesAggregateSelection";
  count: Scalars["Int"]["output"];
  language: StringAggregateSelection;
};

export type AuthPayloadUserPreferencesConnection = {
  __typename?: "AuthPayloadUserPreferencesConnection";
  edges: Array<AuthPayloadUserPreferencesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadUserPreferencesCreateInput = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserPreferencesEdge = {
  __typename?: "AuthPayloadUserPreferencesEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadUserPreferences;
};

export type AuthPayloadUserPreferencesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadUserPreferencesSort objects to sort AuthPayloadUserPreferences by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadUserPreferencesSort>>;
};

/** Fields to sort AuthPayloadUserPreferences by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadUserPreferencesSort object. */
export type AuthPayloadUserPreferencesSort = {
  language?: InputMaybe<SortDirection>;
};

export type AuthPayloadUserPreferencesUpdateInput = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserPreferencesWhere = {
  AND?: InputMaybe<Array<AuthPayloadUserPreferencesWhere>>;
  NOT?: InputMaybe<AuthPayloadUserPreferencesWhere>;
  OR?: InputMaybe<Array<AuthPayloadUserPreferencesWhere>>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/** Fields to sort AuthPayloadUsers by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadUserSort object. */
export type AuthPayloadUserSort = {
  birthday?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  password?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
};

export type AuthPayloadUserUpdateInput = {
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserWhere = {
  AND?: InputMaybe<Array<AuthPayloadUserWhere>>;
  NOT?: InputMaybe<AuthPayloadUserWhere>;
  OR?: InputMaybe<Array<AuthPayloadUserWhere>>;
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  birthday_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  birthday_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  birthday_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  birthday_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  password_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  password_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  password_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUsersConnection = {
  __typename?: "AuthPayloadUsersConnection";
  edges: Array<AuthPayloadUserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AxioResource = {
  __typename?: "AxioResource";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  files: Array<File>;
  filesAggregate?: Maybe<AxioResourceFileFilesAggregationSelection>;
  filesConnection: AxioResourceFilesConnection;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  module: Module;
  moduleAggregate?: Maybe<AxioResourceModuleModuleAggregationSelection>;
  moduleConnection: AxioResourceModuleConnection;
  moduleId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  videoUrl?: Maybe<Scalars["String"]["output"]>;
};

export type AxioResourceFilesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type AxioResourceFilesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type AxioResourceFilesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AxioResourceFilesConnectionSort>>;
  where?: InputMaybe<AxioResourceFilesConnectionWhere>;
};

export type AxioResourceModuleArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type AxioResourceModuleAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type AxioResourceModuleConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AxioResourceModuleConnectionSort>>;
  where?: InputMaybe<AxioResourceModuleConnectionWhere>;
};

export type AxioResourceAggregateSelection = {
  __typename?: "AxioResourceAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  moduleId: IdAggregateSelection;
  name: StringAggregateSelection;
  order: IntAggregateSelection;
  videoUrl: StringAggregateSelection;
};

export type AxioResourceConnectInput = {
  files?: InputMaybe<Array<AxioResourceFilesConnectFieldInput>>;
  module?: InputMaybe<AxioResourceModuleConnectFieldInput>;
};

export type AxioResourceConnectOrCreateInput = {
  module?: InputMaybe<AxioResourceModuleConnectOrCreateFieldInput>;
};

export type AxioResourceConnectOrCreateWhere = {
  node: AxioResourceUniqueWhere;
};

export type AxioResourceConnectWhere = {
  node: AxioResourceWhere;
};

export type AxioResourceCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<AxioResourceFilesFieldInput>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module?: InputMaybe<AxioResourceModuleFieldInput>;
  moduleId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AxioResourceDeleteInput = {
  files?: InputMaybe<Array<AxioResourceFilesDeleteFieldInput>>;
  module?: InputMaybe<AxioResourceModuleDeleteFieldInput>;
};

export type AxioResourceDisconnectInput = {
  files?: InputMaybe<Array<AxioResourceFilesDisconnectFieldInput>>;
  module?: InputMaybe<AxioResourceModuleDisconnectFieldInput>;
};

export type AxioResourceEdge = {
  __typename?: "AxioResourceEdge";
  cursor: Scalars["String"]["output"];
  node: AxioResource;
};

export type AxioResourceFileFilesAggregationSelection = {
  __typename?: "AxioResourceFileFilesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AxioResourceFileFilesNodeAggregateSelection>;
};

export type AxioResourceFileFilesNodeAggregateSelection = {
  __typename?: "AxioResourceFileFilesNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type AxioResourceFilesAggregateInput = {
  AND?: InputMaybe<Array<AxioResourceFilesAggregateInput>>;
  NOT?: InputMaybe<AxioResourceFilesAggregateInput>;
  OR?: InputMaybe<Array<AxioResourceFilesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AxioResourceFilesNodeAggregationWhereInput>;
};

export type AxioResourceFilesConnectFieldInput = {
  connect?: InputMaybe<Array<FileConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type AxioResourceFilesConnection = {
  __typename?: "AxioResourceFilesConnection";
  edges: Array<AxioResourceFilesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AxioResourceFilesConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type AxioResourceFilesConnectionWhere = {
  AND?: InputMaybe<Array<AxioResourceFilesConnectionWhere>>;
  NOT?: InputMaybe<AxioResourceFilesConnectionWhere>;
  OR?: InputMaybe<Array<AxioResourceFilesConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type AxioResourceFilesCreateFieldInput = {
  node: FileCreateInput;
};

export type AxioResourceFilesDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<AxioResourceFilesConnectionWhere>;
};

export type AxioResourceFilesDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<AxioResourceFilesConnectionWhere>;
};

export type AxioResourceFilesFieldInput = {
  connect?: InputMaybe<Array<AxioResourceFilesConnectFieldInput>>;
  create?: InputMaybe<Array<AxioResourceFilesCreateFieldInput>>;
};

export type AxioResourceFilesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AxioResourceFilesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AxioResourceFilesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AxioResourceFilesNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AxioResourceFilesRelationship = {
  __typename?: "AxioResourceFilesRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type AxioResourceFilesUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type AxioResourceFilesUpdateFieldInput = {
  connect?: InputMaybe<Array<AxioResourceFilesConnectFieldInput>>;
  create?: InputMaybe<Array<AxioResourceFilesCreateFieldInput>>;
  delete?: InputMaybe<Array<AxioResourceFilesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AxioResourceFilesDisconnectFieldInput>>;
  update?: InputMaybe<AxioResourceFilesUpdateConnectionInput>;
  where?: InputMaybe<AxioResourceFilesConnectionWhere>;
};

export type AxioResourceModuleAggregateInput = {
  AND?: InputMaybe<Array<AxioResourceModuleAggregateInput>>;
  NOT?: InputMaybe<AxioResourceModuleAggregateInput>;
  OR?: InputMaybe<Array<AxioResourceModuleAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AxioResourceModuleNodeAggregationWhereInput>;
};

export type AxioResourceModuleConnectFieldInput = {
  connect?: InputMaybe<ModuleConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type AxioResourceModuleConnectOrCreateFieldInput = {
  onCreate: AxioResourceModuleConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type AxioResourceModuleConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type AxioResourceModuleConnection = {
  __typename?: "AxioResourceModuleConnection";
  edges: Array<AxioResourceModuleRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AxioResourceModuleConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type AxioResourceModuleConnectionWhere = {
  AND?: InputMaybe<Array<AxioResourceModuleConnectionWhere>>;
  NOT?: InputMaybe<AxioResourceModuleConnectionWhere>;
  OR?: InputMaybe<Array<AxioResourceModuleConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type AxioResourceModuleCreateFieldInput = {
  node: ModuleCreateInput;
};

export type AxioResourceModuleDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<AxioResourceModuleConnectionWhere>;
};

export type AxioResourceModuleDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<AxioResourceModuleConnectionWhere>;
};

export type AxioResourceModuleFieldInput = {
  connect?: InputMaybe<AxioResourceModuleConnectFieldInput>;
  connectOrCreate?: InputMaybe<AxioResourceModuleConnectOrCreateFieldInput>;
  create?: InputMaybe<AxioResourceModuleCreateFieldInput>;
};

export type AxioResourceModuleModuleAggregationSelection = {
  __typename?: "AxioResourceModuleModuleAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AxioResourceModuleModuleNodeAggregateSelection>;
};

export type AxioResourceModuleModuleNodeAggregateSelection = {
  __typename?: "AxioResourceModuleModuleNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type AxioResourceModuleNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AxioResourceModuleNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AxioResourceModuleNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AxioResourceModuleNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AxioResourceModuleRelationship = {
  __typename?: "AxioResourceModuleRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type AxioResourceModuleUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type AxioResourceModuleUpdateFieldInput = {
  connect?: InputMaybe<AxioResourceModuleConnectFieldInput>;
  connectOrCreate?: InputMaybe<AxioResourceModuleConnectOrCreateFieldInput>;
  create?: InputMaybe<AxioResourceModuleCreateFieldInput>;
  delete?: InputMaybe<AxioResourceModuleDeleteFieldInput>;
  disconnect?: InputMaybe<AxioResourceModuleDisconnectFieldInput>;
  update?: InputMaybe<AxioResourceModuleUpdateConnectionInput>;
  where?: InputMaybe<AxioResourceModuleConnectionWhere>;
};

export type AxioResourceOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AxioResourceOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AxioResourceSort objects to sort AxioResources by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AxioResourceSort>>;
};

export type AxioResourceRelationInput = {
  files?: InputMaybe<Array<AxioResourceFilesCreateFieldInput>>;
  module?: InputMaybe<AxioResourceModuleCreateFieldInput>;
};

export type AxioResourceResponse = {
  __typename?: "AxioResourceResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<AxioResource>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type AxioResourceResponseAggregateSelection = {
  __typename?: "AxioResourceResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AxioResourceResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type AxioResourceResponseEdge = {
  __typename?: "AxioResourceResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AxioResourceResponse;
};

export type AxioResourceResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AxioResourceResponseSort objects to sort AxioResourceResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AxioResourceResponseSort>>;
};

/** Fields to sort AxioResourceResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AxioResourceResponseSort object. */
export type AxioResourceResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AxioResourceResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AxioResourceResponseWhere = {
  AND?: InputMaybe<Array<AxioResourceResponseWhere>>;
  NOT?: InputMaybe<AxioResourceResponseWhere>;
  OR?: InputMaybe<Array<AxioResourceResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AxioResourceResponsesConnection = {
  __typename?: "AxioResourceResponsesConnection";
  edges: Array<AxioResourceResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort AxioResources by. The order in which sorts are applied is not guaranteed when specifying many fields in one AxioResourceSort object. */
export type AxioResourceSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  moduleId?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  order?: InputMaybe<SortDirection>;
  videoUrl?: InputMaybe<SortDirection>;
};

export type AxioResourceUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AxioResourceUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<AxioResourceFilesUpdateFieldInput>>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module?: InputMaybe<AxioResourceModuleUpdateFieldInput>;
  moduleId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  order_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  order_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AxioResourceWhere = {
  AND?: InputMaybe<Array<AxioResourceWhere>>;
  NOT?: InputMaybe<AxioResourceWhere>;
  OR?: InputMaybe<Array<AxioResourceWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filesAggregate?: InputMaybe<AxioResourceFilesAggregateInput>;
  /** Return AxioResources where all of the related AxioResourceFilesConnections match this filter */
  filesConnection_ALL?: InputMaybe<AxioResourceFilesConnectionWhere>;
  /** Return AxioResources where none of the related AxioResourceFilesConnections match this filter */
  filesConnection_NONE?: InputMaybe<AxioResourceFilesConnectionWhere>;
  /** Return AxioResources where one of the related AxioResourceFilesConnections match this filter */
  filesConnection_SINGLE?: InputMaybe<AxioResourceFilesConnectionWhere>;
  /** Return AxioResources where some of the related AxioResourceFilesConnections match this filter */
  filesConnection_SOME?: InputMaybe<AxioResourceFilesConnectionWhere>;
  /** Return AxioResources where all of the related Files match this filter */
  files_ALL?: InputMaybe<FileWhere>;
  /** Return AxioResources where none of the related Files match this filter */
  files_NONE?: InputMaybe<FileWhere>;
  /** Return AxioResources where one of the related Files match this filter */
  files_SINGLE?: InputMaybe<FileWhere>;
  /** Return AxioResources where some of the related Files match this filter */
  files_SOME?: InputMaybe<FileWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module?: InputMaybe<ModuleWhere>;
  moduleAggregate?: InputMaybe<AxioResourceModuleAggregateInput>;
  moduleConnection?: InputMaybe<AxioResourceModuleConnectionWhere>;
  moduleConnection_NOT?: InputMaybe<AxioResourceModuleConnectionWhere>;
  moduleId?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  moduleId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_NOT?: InputMaybe<ModuleWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  order_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  order_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  videoUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AxioResourcesConnection = {
  __typename?: "AxioResourcesConnection";
  edges: Array<AxioResourceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type BigIntAggregateSelection = {
  __typename?: "BigIntAggregateSelection";
  average?: Maybe<Scalars["BigInt"]["output"]>;
  max?: Maybe<Scalars["BigInt"]["output"]>;
  min?: Maybe<Scalars["BigInt"]["output"]>;
  sum?: Maybe<Scalars["BigInt"]["output"]>;
};

export type Career = {
  __typename?: "Career";
  brief_description?: Maybe<Scalars["String"]["output"]>;
  cons?: Maybe<Scalars["String"]["output"]>;
  core_skills?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  growth?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  path?: Maybe<Scalars["JSON"]["output"]>;
  pros?: Maybe<Scalars["String"]["output"]>;
  salary?: Maybe<Scalars["String"]["output"]>;
  skills?: Maybe<Scalars["String"]["output"]>;
  trends?: Maybe<Scalars["JSON"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CareerAggregateSelection = {
  __typename?: "CareerAggregateSelection";
  brief_description: StringAggregateSelection;
  cons: StringAggregateSelection;
  core_skills: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  growth: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  pros: StringAggregateSelection;
  salary: StringAggregateSelection;
  skills: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type CareerCreateInput = {
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerEdge = {
  __typename?: "CareerEdge";
  cursor: Scalars["String"]["output"];
  node: Career;
};

export type CareerHeader = {
  __typename?: "CareerHeader";
  careerGoal?: Maybe<Scalars["String"]["output"]>;
  careerId?: Maybe<Scalars["String"]["output"]>;
  careerName?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
};

export type CareerHeaderAggregateSelection = {
  __typename?: "CareerHeaderAggregateSelection";
  careerGoal: StringAggregateSelection;
  careerId: StringAggregateSelection;
  careerName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  jobTitle: StringAggregateSelection;
};

export type CareerHeaderCreateInput = {
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeaderEdge = {
  __typename?: "CareerHeaderEdge";
  cursor: Scalars["String"]["output"];
  node: CareerHeader;
};

export type CareerHeaderOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerHeaderSort objects to sort CareerHeaders by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerHeaderSort>>;
};

/** Fields to sort CareerHeaders by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerHeaderSort object. */
export type CareerHeaderSort = {
  careerGoal?: InputMaybe<SortDirection>;
  careerId?: InputMaybe<SortDirection>;
  careerName?: InputMaybe<SortDirection>;
  jobTitle?: InputMaybe<SortDirection>;
};

export type CareerHeaderUpdateInput = {
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeaderWhere = {
  AND?: InputMaybe<Array<CareerHeaderWhere>>;
  NOT?: InputMaybe<CareerHeaderWhere>;
  OR?: InputMaybe<Array<CareerHeaderWhere>>;
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerGoal_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  careerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  jobTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeadersConnection = {
  __typename?: "CareerHeadersConnection";
  edges: Array<CareerHeaderEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CareerOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerSort objects to sort Careers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerSort>>;
};

/** Fields to sort Careers by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerSort object. */
export type CareerSort = {
  brief_description?: InputMaybe<SortDirection>;
  cons?: InputMaybe<SortDirection>;
  core_skills?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  growth?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  path?: InputMaybe<SortDirection>;
  pros?: InputMaybe<SortDirection>;
  salary?: InputMaybe<SortDirection>;
  skills?: InputMaybe<SortDirection>;
  trends?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type CareerTopicResponse = {
  __typename?: "CareerTopicResponse";
  careerName: Scalars["String"]["output"];
  relationshipIndex: Scalars["Int"]["output"];
  topicName: Scalars["String"]["output"];
};

export type CareerTopicResponseAggregateSelection = {
  __typename?: "CareerTopicResponseAggregateSelection";
  careerName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  relationshipIndex: IntAggregateSelection;
  topicName: StringAggregateSelection;
};

export type CareerTopicResponseCreateInput = {
  careerName: Scalars["String"]["input"];
  relationshipIndex: Scalars["Int"]["input"];
  topicName: Scalars["String"]["input"];
};

export type CareerTopicResponseEdge = {
  __typename?: "CareerTopicResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CareerTopicResponse;
};

export type CareerTopicResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerTopicResponseSort objects to sort CareerTopicResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerTopicResponseSort>>;
};

/** Fields to sort CareerTopicResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerTopicResponseSort object. */
export type CareerTopicResponseSort = {
  careerName?: InputMaybe<SortDirection>;
  relationshipIndex?: InputMaybe<SortDirection>;
  topicName?: InputMaybe<SortDirection>;
};

export type CareerTopicResponseUpdateInput = {
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  relationshipIndex?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  topicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerTopicResponseWhere = {
  AND?: InputMaybe<Array<CareerTopicResponseWhere>>;
  NOT?: InputMaybe<CareerTopicResponseWhere>;
  OR?: InputMaybe<Array<CareerTopicResponseWhere>>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  careerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  careerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  relationshipIndex?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_GT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  relationshipIndex_LT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  topicName?: InputMaybe<Scalars["String"]["input"]>;
  topicName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  topicName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  topicName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  topicName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerTopicResponsesConnection = {
  __typename?: "CareerTopicResponsesConnection";
  edges: Array<CareerTopicResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CareerUpdateInput = {
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerWhere = {
  AND?: InputMaybe<Array<CareerWhere>>;
  NOT?: InputMaybe<CareerWhere>;
  OR?: InputMaybe<Array<CareerWhere>>;
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  brief_description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  cons_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  cons_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  cons_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cons_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  core_skills_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  growth_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  growth_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  growth_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  growth_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  path_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  pros_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  pros_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pros_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pros_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  salary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  salary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  salary_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  salary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  skills_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  skills_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  skills_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skills_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  trends_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareersConnection = {
  __typename?: "CareersConnection";
  edges: Array<CareerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Challenge = {
  __typename?: "Challenge";
  category?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type ChallengeAggregateSelection = {
  __typename?: "ChallengeAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  endDate: DateTimeAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
  status: StringAggregateSelection;
};

export type ChallengeCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengeEdge = {
  __typename?: "ChallengeEdge";
  cursor: Scalars["String"]["output"];
  node: Challenge;
};

export type ChallengeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChallengeSort objects to sort Challenges by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChallengeSort>>;
};

/** Fields to sort Challenges by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChallengeSort object. */
export type ChallengeSort = {
  category?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type ChallengeUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengeWhere = {
  AND?: InputMaybe<Array<ChallengeWhere>>;
  NOT?: InputMaybe<ChallengeWhere>;
  OR?: InputMaybe<Array<ChallengeWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengesConnection = {
  __typename?: "ChallengesConnection";
  edges: Array<ChallengeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Chapter = {
  __typename?: "Chapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
  topicsHasChapter: Array<Topic>;
  topicsHasChapterAggregate?: Maybe<ChapterTopicTopicsHasChapterAggregationSelection>;
  topicsHasChapterConnection: ChapterTopicsHasChapterConnection;
  usersHasKnowledge: Array<User>;
  usersHasKnowledgeAggregate?: Maybe<ChapterUserUsersHasKnowledgeAggregationSelection>;
  usersHasKnowledgeConnection: ChapterUsersHasKnowledgeConnection;
};

export type ChapterTopicsHasChapterArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TopicOptions>;
  where?: InputMaybe<TopicWhere>;
};

export type ChapterTopicsHasChapterAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TopicWhere>;
};

export type ChapterTopicsHasChapterConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChapterTopicsHasChapterConnectionSort>>;
  where?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
};

export type ChapterUsersHasKnowledgeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ChapterUsersHasKnowledgeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ChapterUsersHasKnowledgeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectionSort>>;
  where?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
};

export type ChapterAggregateSelection = {
  __typename?: "ChapterAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type ChapterConnectInput = {
  topicsHasChapter?: InputMaybe<Array<ChapterTopicsHasChapterConnectFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectFieldInput>>;
};

export type ChapterConnectOrCreateInput = {
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectOrCreateFieldInput>>;
};

export type ChapterConnectWhere = {
  node: ChapterWhere;
};

export type ChapterCreateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  topicsHasChapter?: InputMaybe<ChapterTopicsHasChapterFieldInput>;
  usersHasKnowledge?: InputMaybe<ChapterUsersHasKnowledgeFieldInput>;
};

export type ChapterDeleteInput = {
  topicsHasChapter?: InputMaybe<Array<ChapterTopicsHasChapterDeleteFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeDeleteFieldInput>>;
};

export type ChapterDisconnectInput = {
  topicsHasChapter?: InputMaybe<Array<ChapterTopicsHasChapterDisconnectFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeDisconnectFieldInput>>;
};

export type ChapterEdge = {
  __typename?: "ChapterEdge";
  cursor: Scalars["String"]["output"];
  node: Chapter;
};

export type ChapterOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChapterSort objects to sort Chapters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChapterSort>>;
};

export type ChapterRelationInput = {
  topicsHasChapter?: InputMaybe<Array<ChapterTopicsHasChapterCreateFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeCreateFieldInput>>;
};

/** Fields to sort Chapters by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChapterSort object. */
export type ChapterSort = {
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type ChapterTopicTopicsHasChapterAggregationSelection = {
  __typename?: "ChapterTopicTopicsHasChapterAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChapterTopicTopicsHasChapterEdgeAggregateSelection>;
  node?: Maybe<ChapterTopicTopicsHasChapterNodeAggregateSelection>;
};

export type ChapterTopicTopicsHasChapterEdgeAggregateSelection = {
  __typename?: "ChapterTopicTopicsHasChapterEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type ChapterTopicTopicsHasChapterNodeAggregateSelection = {
  __typename?: "ChapterTopicTopicsHasChapterNodeAggregateSelection";
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type ChapterTopicsHasChapterAggregateInput = {
  AND?: InputMaybe<Array<ChapterTopicsHasChapterAggregateInput>>;
  NOT?: InputMaybe<ChapterTopicsHasChapterAggregateInput>;
  OR?: InputMaybe<Array<ChapterTopicsHasChapterAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChapterPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChapterTopicsHasChapterNodeAggregationWhereInput>;
};

export type ChapterTopicsHasChapterConnectFieldInput = {
  connect?: InputMaybe<Array<TopicConnectInput>>;
  edge: HasChapterPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TopicConnectWhere>;
};

export type ChapterTopicsHasChapterConnection = {
  __typename?: "ChapterTopicsHasChapterConnection";
  edges: Array<ChapterTopicsHasChapterRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChapterTopicsHasChapterConnectionSort = {
  edge?: InputMaybe<HasChapterPropertiesSort>;
  node?: InputMaybe<TopicSort>;
};

export type ChapterTopicsHasChapterConnectionWhere = {
  AND?: InputMaybe<Array<ChapterTopicsHasChapterConnectionWhere>>;
  NOT?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
  OR?: InputMaybe<Array<ChapterTopicsHasChapterConnectionWhere>>;
  edge?: InputMaybe<HasChapterPropertiesWhere>;
  node?: InputMaybe<TopicWhere>;
};

export type ChapterTopicsHasChapterCreateFieldInput = {
  edge: HasChapterPropertiesCreateInput;
  node: TopicCreateInput;
};

export type ChapterTopicsHasChapterDeleteFieldInput = {
  delete?: InputMaybe<TopicDeleteInput>;
  where?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
};

export type ChapterTopicsHasChapterDisconnectFieldInput = {
  disconnect?: InputMaybe<TopicDisconnectInput>;
  where?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
};

export type ChapterTopicsHasChapterFieldInput = {
  connect?: InputMaybe<Array<ChapterTopicsHasChapterConnectFieldInput>>;
  create?: InputMaybe<Array<ChapterTopicsHasChapterCreateFieldInput>>;
};

export type ChapterTopicsHasChapterNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChapterTopicsHasChapterNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChapterTopicsHasChapterNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChapterTopicsHasChapterNodeAggregationWhereInput>>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  mastery_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChapterTopicsHasChapterRelationship = {
  __typename?: "ChapterTopicsHasChapterRelationship";
  cursor: Scalars["String"]["output"];
  node: Topic;
  properties: HasChapterProperties;
};

export type ChapterTopicsHasChapterUpdateConnectionInput = {
  edge?: InputMaybe<HasChapterPropertiesUpdateInput>;
  node?: InputMaybe<TopicUpdateInput>;
};

export type ChapterTopicsHasChapterUpdateFieldInput = {
  connect?: InputMaybe<Array<ChapterTopicsHasChapterConnectFieldInput>>;
  create?: InputMaybe<Array<ChapterTopicsHasChapterCreateFieldInput>>;
  delete?: InputMaybe<Array<ChapterTopicsHasChapterDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChapterTopicsHasChapterDisconnectFieldInput>>;
  update?: InputMaybe<ChapterTopicsHasChapterUpdateConnectionInput>;
  where?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
};

export type ChapterUpdateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  topicsHasChapter?: InputMaybe<Array<ChapterTopicsHasChapterUpdateFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<ChapterUsersHasKnowledgeUpdateFieldInput>>;
};

export type ChapterUserUsersHasKnowledgeAggregationSelection = {
  __typename?: "ChapterUserUsersHasKnowledgeAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChapterUserUsersHasKnowledgeEdgeAggregateSelection>;
  node?: Maybe<ChapterUserUsersHasKnowledgeNodeAggregateSelection>;
};

export type ChapterUserUsersHasKnowledgeEdgeAggregateSelection = {
  __typename?: "ChapterUserUsersHasKnowledgeEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  total_mastery: FloatAggregateSelection;
};

export type ChapterUserUsersHasKnowledgeNodeAggregateSelection = {
  __typename?: "ChapterUserUsersHasKnowledgeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type ChapterUsersHasKnowledgeAggregateInput = {
  AND?: InputMaybe<Array<ChapterUsersHasKnowledgeAggregateInput>>;
  NOT?: InputMaybe<ChapterUsersHasKnowledgeAggregateInput>;
  OR?: InputMaybe<Array<ChapterUsersHasKnowledgeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasKnowledgePropertiesAggregationWhereInput>;
  node?: InputMaybe<ChapterUsersHasKnowledgeNodeAggregationWhereInput>;
};

export type ChapterUsersHasKnowledgeConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasKnowledgePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ChapterUsersHasKnowledgeConnectOrCreateFieldInput = {
  onCreate: ChapterUsersHasKnowledgeConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type ChapterUsersHasKnowledgeConnectOrCreateFieldInputOnCreate = {
  edge: HasKnowledgePropertiesCreateInput;
  node: UserOnCreateInput;
};

export type ChapterUsersHasKnowledgeConnection = {
  __typename?: "ChapterUsersHasKnowledgeConnection";
  edges: Array<ChapterUsersHasKnowledgeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChapterUsersHasKnowledgeConnectionSort = {
  edge?: InputMaybe<HasKnowledgePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ChapterUsersHasKnowledgeConnectionWhere = {
  AND?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectionWhere>>;
  NOT?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
  OR?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectionWhere>>;
  edge?: InputMaybe<HasKnowledgePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ChapterUsersHasKnowledgeCreateFieldInput = {
  edge: HasKnowledgePropertiesCreateInput;
  node: UserCreateInput;
};

export type ChapterUsersHasKnowledgeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
};

export type ChapterUsersHasKnowledgeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
};

export type ChapterUsersHasKnowledgeFieldInput = {
  connect?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChapterUsersHasKnowledgeCreateFieldInput>>;
};

export type ChapterUsersHasKnowledgeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChapterUsersHasKnowledgeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChapterUsersHasKnowledgeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChapterUsersHasKnowledgeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChapterUsersHasKnowledgeRelationship = {
  __typename?: "ChapterUsersHasKnowledgeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasKnowledgeProperties;
};

export type ChapterUsersHasKnowledgeUpdateConnectionInput = {
  edge?: InputMaybe<HasKnowledgePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ChapterUsersHasKnowledgeUpdateFieldInput = {
  connect?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChapterUsersHasKnowledgeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChapterUsersHasKnowledgeCreateFieldInput>>;
  delete?: InputMaybe<Array<ChapterUsersHasKnowledgeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChapterUsersHasKnowledgeDisconnectFieldInput>>;
  update?: InputMaybe<ChapterUsersHasKnowledgeUpdateConnectionInput>;
  where?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
};

export type ChapterWhere = {
  AND?: InputMaybe<Array<ChapterWhere>>;
  NOT?: InputMaybe<ChapterWhere>;
  OR?: InputMaybe<Array<ChapterWhere>>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  topicsHasChapterAggregate?: InputMaybe<ChapterTopicsHasChapterAggregateInput>;
  /** Return Chapters where all of the related ChapterTopicsHasChapterConnections match this filter */
  topicsHasChapterConnection_ALL?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
  /** Return Chapters where none of the related ChapterTopicsHasChapterConnections match this filter */
  topicsHasChapterConnection_NONE?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
  /** Return Chapters where one of the related ChapterTopicsHasChapterConnections match this filter */
  topicsHasChapterConnection_SINGLE?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
  /** Return Chapters where some of the related ChapterTopicsHasChapterConnections match this filter */
  topicsHasChapterConnection_SOME?: InputMaybe<ChapterTopicsHasChapterConnectionWhere>;
  /** Return Chapters where all of the related Topics match this filter */
  topicsHasChapter_ALL?: InputMaybe<TopicWhere>;
  /** Return Chapters where none of the related Topics match this filter */
  topicsHasChapter_NONE?: InputMaybe<TopicWhere>;
  /** Return Chapters where one of the related Topics match this filter */
  topicsHasChapter_SINGLE?: InputMaybe<TopicWhere>;
  /** Return Chapters where some of the related Topics match this filter */
  topicsHasChapter_SOME?: InputMaybe<TopicWhere>;
  usersHasKnowledgeAggregate?: InputMaybe<ChapterUsersHasKnowledgeAggregateInput>;
  /** Return Chapters where all of the related ChapterUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_ALL?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
  /** Return Chapters where none of the related ChapterUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_NONE?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
  /** Return Chapters where one of the related ChapterUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_SINGLE?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
  /** Return Chapters where some of the related ChapterUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_SOME?: InputMaybe<ChapterUsersHasKnowledgeConnectionWhere>;
  /** Return Chapters where all of the related Users match this filter */
  usersHasKnowledge_ALL?: InputMaybe<UserWhere>;
  /** Return Chapters where none of the related Users match this filter */
  usersHasKnowledge_NONE?: InputMaybe<UserWhere>;
  /** Return Chapters where one of the related Users match this filter */
  usersHasKnowledge_SINGLE?: InputMaybe<UserWhere>;
  /** Return Chapters where some of the related Users match this filter */
  usersHasKnowledge_SOME?: InputMaybe<UserWhere>;
};

export type ChaptersConnection = {
  __typename?: "ChaptersConnection";
  edges: Array<ChapterEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Chat = {
  __typename?: "Chat";
  aiTutorHasChat: Array<AiTutor>;
  aiTutorHasChatAggregate?: Maybe<ChatAiTutorAiTutorHasChatAggregationSelection>;
  aiTutorHasChatConnection: ChatAiTutorHasChatConnection;
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  assignmentsHasChat: Array<Assignment>;
  assignmentsHasChatAggregate?: Maybe<ChatAssignmentAssignmentsHasChatAggregationSelection>;
  assignmentsHasChatConnection: ChatAssignmentsHasChatConnection;
  context?: Maybe<Scalars["String"]["output"]>;
  conversation: Array<Message>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  hasMessageMessages: Array<Message>;
  hasMessageMessagesAggregate?: Maybe<ChatMessageHasMessageMessagesAggregationSelection>;
  hasMessageMessagesConnection: ChatHasMessageMessagesConnection;
  id: Scalars["ID"]["output"];
  journeysHasChat: Array<Journey>;
  journeysHasChatAggregate?: Maybe<ChatJourneyJourneysHasChatAggregationSelection>;
  journeysHasChatConnection: ChatJourneysHasChatConnection;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  learningObjectivesHasChat: Array<LearningObjective>;
  learningObjectivesHasChatAggregate?: Maybe<ChatLearningObjectiveLearningObjectivesHasChatAggregationSelection>;
  learningObjectivesHasChatConnection: ChatLearningObjectivesHasChatConnection;
  name: Scalars["String"]["output"];
  objectId?: Maybe<Scalars["ID"]["output"]>;
  params?: Maybe<Scalars["JSON"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasChat: Array<User>;
  usersHasChatAggregate?: Maybe<ChatUserUsersHasChatAggregationSelection>;
  usersHasChatConnection: ChatUsersHasChatConnection;
};

export type ChatAiTutorHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AiTutorOptions>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ChatAiTutorHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ChatAiTutorHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatAiTutorHasChatConnectionSort>>;
  where?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
};

export type ChatAssignmentsHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ChatAssignmentsHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ChatAssignmentsHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatAssignmentsHasChatConnectionSort>>;
  where?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
};

export type ChatHasMessageMessagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatHasMessageMessagesConnectionSort>>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatJourneysHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatJourneysHasChatConnectionSort>>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatLearningObjectivesHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<LearningObjectiveOptions>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type ChatLearningObjectivesHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type ChatLearningObjectivesHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectionSort>>;
  where?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
};

export type ChatUsersHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatUsersHasChatConnectionSort>>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatAiTutorAiTutorHasChatAggregationSelection = {
  __typename?: "ChatAITutorAiTutorHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatAiTutorAiTutorHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatAiTutorAiTutorHasChatNodeAggregateSelection>;
};

export type ChatAiTutorAiTutorHasChatEdgeAggregateSelection = {
  __typename?: "ChatAITutorAiTutorHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatAiTutorAiTutorHasChatNodeAggregateSelection = {
  __typename?: "ChatAITutorAiTutorHasChatNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type ChatAggregateSelection = {
  __typename?: "ChatAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type ChatAiTutorHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatAiTutorHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatAiTutorHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatAiTutorHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatAiTutorHasChatNodeAggregationWhereInput>;
};

export type ChatAiTutorHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<AiTutorConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AiTutorConnectWhere>;
};

export type ChatAiTutorHasChatConnectOrCreateFieldInput = {
  onCreate: ChatAiTutorHasChatConnectOrCreateFieldInputOnCreate;
  where: AiTutorConnectOrCreateWhere;
};

export type ChatAiTutorHasChatConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: AiTutorOnCreateInput;
};

export type ChatAiTutorHasChatConnection = {
  __typename?: "ChatAiTutorHasChatConnection";
  edges: Array<ChatAiTutorHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatAiTutorHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<AiTutorSort>;
};

export type ChatAiTutorHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatAiTutorHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatAiTutorHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<AiTutorWhere>;
};

export type ChatAiTutorHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: AiTutorCreateInput;
};

export type ChatAiTutorHasChatDeleteFieldInput = {
  delete?: InputMaybe<AiTutorDeleteInput>;
  where?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
};

export type ChatAiTutorHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<AiTutorDisconnectInput>;
  where?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
};

export type ChatAiTutorHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatAiTutorHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatAiTutorHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatAiTutorHasChatCreateFieldInput>>;
};

export type ChatAiTutorHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatAiTutorHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatAiTutorHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatAiTutorHasChatNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatAiTutorHasChatRelationship = {
  __typename?: "ChatAiTutorHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: AiTutor;
  properties: HasChatProperties;
};

export type ChatAiTutorHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<AiTutorUpdateInput>;
};

export type ChatAiTutorHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatAiTutorHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatAiTutorHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatAiTutorHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatAiTutorHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatAiTutorHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatAiTutorHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
};

export type ChatAnalytics = {
  __typename?: "ChatAnalytics";
  ai_messages_count: Scalars["Int"]["output"];
  average_ai_response_time: Scalars["Float"]["output"];
  average_student_question_complexity?: Maybe<Scalars["Float"]["output"]>;
  average_student_response_time: Scalars["Float"]["output"];
  confusion_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  distractions: Scalars["Int"]["output"];
  further_recommendations: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  level?: Maybe<Scalars["String"]["output"]>;
  num_messages: Scalars["Int"]["output"];
  originality_analysis?: Maybe<Scalars["String"]["output"]>;
  questions_asked: Scalars["Int"]["output"];
  sentiment_analysis?: Maybe<Scalars["String"]["output"]>;
  student_messages_count: Scalars["Int"]["output"];
  success_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  summary: Scalars["String"]["output"];
  total_time_taken: Scalars["Float"]["output"];
};

export type ChatAnalyticsAggregateSelection = {
  __typename?: "ChatAnalyticsAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_question_complexity: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  level: StringAggregateSelection;
  num_messages: IntAggregateSelection;
  originality_analysis: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment_analysis: StringAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatAnalyticsConnection = {
  __typename?: "ChatAnalyticsConnection";
  edges: Array<ChatAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatAnalyticsCreateInput = {
  ai_messages_count: Scalars["Int"]["input"];
  average_ai_response_time: Scalars["Float"]["input"];
  average_student_question_complexity?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time: Scalars["Float"]["input"];
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions: Scalars["Int"]["input"];
  further_recommendations: Array<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages: Scalars["Int"]["input"];
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked: Scalars["Int"]["input"];
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count: Scalars["Int"]["input"];
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary: Scalars["String"]["input"];
  total_time_taken: Scalars["Float"]["input"];
};

export type ChatAnalyticsEdge = {
  __typename?: "ChatAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ChatAnalytics;
};

export type ChatAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChatAnalyticsSort objects to sort ChatAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChatAnalyticsSort>>;
};

/** Fields to sort ChatAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChatAnalyticsSort object. */
export type ChatAnalyticsSort = {
  ai_messages_count?: InputMaybe<SortDirection>;
  average_ai_response_time?: InputMaybe<SortDirection>;
  average_student_question_complexity?: InputMaybe<SortDirection>;
  average_student_response_time?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  num_messages?: InputMaybe<SortDirection>;
  originality_analysis?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment_analysis?: InputMaybe<SortDirection>;
  student_messages_count?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type ChatAnalyticsUpdateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChatAnalyticsWhere = {
  AND?: InputMaybe<Array<ChatAnalyticsWhere>>;
  NOT?: InputMaybe<ChatAnalyticsWhere>;
  OR?: InputMaybe<Array<ChatAnalyticsWhere>>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ai_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  average_ai_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_student_question_complexity_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  average_student_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  num_messages_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  student_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChatAssignmentAssignmentsHasChatAggregationSelection = {
  __typename?: "ChatAssignmentAssignmentsHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatAssignmentAssignmentsHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatAssignmentAssignmentsHasChatNodeAggregateSelection>;
};

export type ChatAssignmentAssignmentsHasChatEdgeAggregateSelection = {
  __typename?: "ChatAssignmentAssignmentsHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatAssignmentAssignmentsHasChatNodeAggregateSelection = {
  __typename?: "ChatAssignmentAssignmentsHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ChatAssignmentsHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatAssignmentsHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatAssignmentsHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatAssignmentsHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatAssignmentsHasChatNodeAggregationWhereInput>;
};

export type ChatAssignmentsHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type ChatAssignmentsHasChatConnectOrCreateFieldInput = {
  onCreate: ChatAssignmentsHasChatConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type ChatAssignmentsHasChatConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: AssignmentOnCreateInput;
};

export type ChatAssignmentsHasChatConnection = {
  __typename?: "ChatAssignmentsHasChatConnection";
  edges: Array<ChatAssignmentsHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatAssignmentsHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<AssignmentSort>;
};

export type ChatAssignmentsHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatAssignmentsHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatAssignmentsHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<AssignmentWhere>;
};

export type ChatAssignmentsHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: AssignmentCreateInput;
};

export type ChatAssignmentsHasChatDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
};

export type ChatAssignmentsHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
};

export type ChatAssignmentsHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatAssignmentsHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatAssignmentsHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatAssignmentsHasChatCreateFieldInput>>;
};

export type ChatAssignmentsHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatAssignmentsHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatAssignmentsHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatAssignmentsHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatAssignmentsHasChatRelationship = {
  __typename?: "ChatAssignmentsHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
  properties: HasChatProperties;
};

export type ChatAssignmentsHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type ChatAssignmentsHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatAssignmentsHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatAssignmentsHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatAssignmentsHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatAssignmentsHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatAssignmentsHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatAssignmentsHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
};

export type ChatAttachmentResponse = {
  __typename?: "ChatAttachmentResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<File>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ChatAttachmentResponseAggregateSelection = {
  __typename?: "ChatAttachmentResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ChatAttachmentResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ChatAttachmentResponseEdge = {
  __typename?: "ChatAttachmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ChatAttachmentResponse;
};

export type ChatAttachmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChatAttachmentResponseSort objects to sort ChatAttachmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChatAttachmentResponseSort>>;
};

/** Fields to sort ChatAttachmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChatAttachmentResponseSort object. */
export type ChatAttachmentResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ChatAttachmentResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChatAttachmentResponseWhere = {
  AND?: InputMaybe<Array<ChatAttachmentResponseWhere>>;
  NOT?: InputMaybe<ChatAttachmentResponseWhere>;
  OR?: InputMaybe<Array<ChatAttachmentResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChatAttachmentResponsesConnection = {
  __typename?: "ChatAttachmentResponsesConnection";
  edges: Array<ChatAttachmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatConnectInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatConnectFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatConnectFieldInput>>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  learningObjectivesHasChat?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
};

export type ChatConnectOrCreateInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatConnectOrCreateFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatConnectOrCreateFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatConnectOrCreateFieldInput>>;
};

export type ChatConnectWhere = {
  node: ChatWhere;
};

export type ChatCreateInput = {
  aiTutorHasChat?: InputMaybe<ChatAiTutorHasChatFieldInput>;
  assignmentsHasChat?: InputMaybe<ChatAssignmentsHasChatFieldInput>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasMessageMessages?: InputMaybe<ChatHasMessageMessagesFieldInput>;
  id: Scalars["ID"]["input"];
  journeysHasChat?: InputMaybe<ChatJourneysHasChatFieldInput>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningObjectivesHasChat?: InputMaybe<ChatLearningObjectivesHasChatFieldInput>;
  name: Scalars["String"]["input"];
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasChat?: InputMaybe<ChatUsersHasChatFieldInput>;
};

export type ChatDeleteInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatDeleteFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatDeleteFieldInput>>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesDeleteFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatDeleteFieldInput>>;
  learningObjectivesHasChat?: InputMaybe<Array<ChatLearningObjectivesHasChatDeleteFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatDeleteFieldInput>>;
};

export type ChatDisconnectInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatDisconnectFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatDisconnectFieldInput>>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesDisconnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatDisconnectFieldInput>>;
  learningObjectivesHasChat?: InputMaybe<Array<ChatLearningObjectivesHasChatDisconnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatDisconnectFieldInput>>;
};

export type ChatEdge = {
  __typename?: "ChatEdge";
  cursor: Scalars["String"]["output"];
  node: Chat;
};

export type ChatHasMessageMessagesAggregateInput = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesAggregateInput>>;
  NOT?: InputMaybe<ChatHasMessageMessagesAggregateInput>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatHasMessageMessagesNodeAggregationWhereInput>;
};

export type ChatHasMessageMessagesConnectFieldInput = {
  connect?: InputMaybe<Array<MessageConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type ChatHasMessageMessagesConnection = {
  __typename?: "ChatHasMessageMessagesConnection";
  edges: Array<ChatHasMessageMessagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatHasMessageMessagesConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<MessageSort>;
};

export type ChatHasMessageMessagesConnectionWhere = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesConnectionWhere>>;
  NOT?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: MessageCreateInput;
};

export type ChatHasMessageMessagesDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatHasMessageMessagesDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatHasMessageMessagesFieldInput = {
  connect?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
};

export type ChatHasMessageMessagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatHasMessageMessagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesNodeAggregationWhereInput>>;
  aspectDetails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatHasMessageMessagesRelationship = {
  __typename?: "ChatHasMessageMessagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
  properties: HasMessageProperties;
};

export type ChatHasMessageMessagesUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<MessageUpdateInput>;
};

export type ChatHasMessageMessagesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatHasMessageMessagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatHasMessageMessagesDisconnectFieldInput>>;
  update?: InputMaybe<ChatHasMessageMessagesUpdateConnectionInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatJourneyJourneysHasChatAggregationSelection = {
  __typename?: "ChatJourneyJourneysHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatJourneyJourneysHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatJourneyJourneysHasChatNodeAggregateSelection>;
};

export type ChatJourneyJourneysHasChatEdgeAggregateSelection = {
  __typename?: "ChatJourneyJourneysHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatJourneyJourneysHasChatNodeAggregateSelection = {
  __typename?: "ChatJourneyJourneysHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type ChatJourneysHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatJourneysHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatJourneysHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatJourneysHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatJourneysHasChatNodeAggregationWhereInput>;
};

export type ChatJourneysHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type ChatJourneysHasChatConnection = {
  __typename?: "ChatJourneysHasChatConnection";
  edges: Array<ChatJourneysHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatJourneysHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type ChatJourneysHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatJourneysHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatJourneysHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type ChatJourneysHasChatDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatJourneysHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatJourneysHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
};

export type ChatJourneysHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatJourneysHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatJourneysHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatJourneysHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatJourneysHasChatRelationship = {
  __typename?: "ChatJourneysHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasChatProperties;
};

export type ChatJourneysHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type ChatJourneysHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatJourneysHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatJourneysHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatJourneysHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatLearningObjectiveLearningObjectivesHasChatAggregationSelection = {
  __typename?: "ChatLearningObjectiveLearningObjectivesHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatLearningObjectiveLearningObjectivesHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatLearningObjectiveLearningObjectivesHasChatNodeAggregateSelection>;
};

export type ChatLearningObjectiveLearningObjectivesHasChatEdgeAggregateSelection = {
  __typename?: "ChatLearningObjectiveLearningObjectivesHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatLearningObjectiveLearningObjectivesHasChatNodeAggregateSelection = {
  __typename?: "ChatLearningObjectiveLearningObjectivesHasChatNodeAggregateSelection";
  completed: IntAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  index: IntAggregateSelection;
  title: StringAggregateSelection;
  total_mastery: IntAggregateSelection;
};

export type ChatLearningObjectivesHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatLearningObjectivesHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatLearningObjectivesHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatLearningObjectivesHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatLearningObjectivesHasChatNodeAggregationWhereInput>;
};

export type ChatLearningObjectivesHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<LearningObjectiveConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<LearningObjectiveConnectWhere>;
};

export type ChatLearningObjectivesHasChatConnection = {
  __typename?: "ChatLearningObjectivesHasChatConnection";
  edges: Array<ChatLearningObjectivesHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatLearningObjectivesHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<LearningObjectiveSort>;
};

export type ChatLearningObjectivesHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<LearningObjectiveWhere>;
};

export type ChatLearningObjectivesHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: LearningObjectiveCreateInput;
};

export type ChatLearningObjectivesHasChatDeleteFieldInput = {
  delete?: InputMaybe<LearningObjectiveDeleteInput>;
  where?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
};

export type ChatLearningObjectivesHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<LearningObjectiveDisconnectInput>;
  where?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
};

export type ChatLearningObjectivesHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatLearningObjectivesHasChatCreateFieldInput>>;
};

export type ChatLearningObjectivesHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatLearningObjectivesHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatLearningObjectivesHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatLearningObjectivesHasChatNodeAggregationWhereInput>>;
  completed_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completed_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completed_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completed_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completed_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completed_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completed_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatLearningObjectivesHasChatRelationship = {
  __typename?: "ChatLearningObjectivesHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: LearningObjective;
  properties: HasChatProperties;
};

export type ChatLearningObjectivesHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<LearningObjectiveUpdateInput>;
};

export type ChatLearningObjectivesHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatLearningObjectivesHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatLearningObjectivesHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatLearningObjectivesHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatLearningObjectivesHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatLearningObjectivesHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
};

export type ChatMessageHasMessageMessagesAggregationSelection = {
  __typename?: "ChatMessageHasMessageMessagesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatMessageHasMessageMessagesEdgeAggregateSelection>;
  node?: Maybe<ChatMessageHasMessageMessagesNodeAggregateSelection>;
};

export type ChatMessageHasMessageMessagesEdgeAggregateSelection = {
  __typename?: "ChatMessageHasMessageMessagesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type ChatMessageHasMessageMessagesNodeAggregateSelection = {
  __typename?: "ChatMessageHasMessageMessagesNodeAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type ChatOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChatSort objects to sort Chats by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChatSort>>;
};

export type ChatRelationInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatCreateFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatCreateFieldInput>>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
  learningObjectivesHasChat?: InputMaybe<Array<ChatLearningObjectivesHasChatCreateFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
};

export type ChatResponse = {
  __typename?: "ChatResponse";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  context?: Maybe<Scalars["String"]["output"]>;
  conversation: Array<MessageResponse>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  hasMessageMessages: Array<Message>;
  hasMessageMessagesAggregate?: Maybe<ChatResponseMessageHasMessageMessagesAggregationSelection>;
  hasMessageMessagesConnection: ChatResponseHasMessageMessagesConnection;
  id: Scalars["ID"]["output"];
  journeysHasChat: Array<Journey>;
  journeysHasChatAggregate?: Maybe<ChatResponseJourneyJourneysHasChatAggregationSelection>;
  journeysHasChatConnection: ChatResponseJourneysHasChatConnection;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  objectId?: Maybe<Scalars["ID"]["output"]>;
  params?: Maybe<Scalars["JSON"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["ID"]["output"];
  usersHasChat: Array<User>;
  usersHasChatAggregate?: Maybe<ChatResponseUserUsersHasChatAggregationSelection>;
  usersHasChatConnection: ChatResponseUsersHasChatConnection;
};

export type ChatResponseHasMessageMessagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatResponseHasMessageMessagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatResponseHasMessageMessagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectionSort>>;
  where?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
};

export type ChatResponseJourneysHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatResponseJourneysHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatResponseJourneysHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatResponseJourneysHasChatConnectionSort>>;
  where?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
};

export type ChatResponseUsersHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ChatResponseUsersHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ChatResponseUsersHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatResponseUsersHasChatConnectionSort>>;
  where?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
};

export type ChatResponseAggregateSelection = {
  __typename?: "ChatResponseAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type ChatResponseConnectInput = {
  hasMessageMessages?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatResponseJourneysHasChatConnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatConnectFieldInput>>;
};

export type ChatResponseConnectOrCreateInput = {
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatConnectOrCreateFieldInput>>;
};

export type ChatResponseCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessages?: InputMaybe<ChatResponseHasMessageMessagesFieldInput>;
  id: Scalars["ID"]["input"];
  journeysHasChat?: InputMaybe<ChatResponseJourneysHasChatFieldInput>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
  usersHasChat?: InputMaybe<ChatResponseUsersHasChatFieldInput>;
};

export type ChatResponseDeleteInput = {
  hasMessageMessages?: InputMaybe<Array<ChatResponseHasMessageMessagesDeleteFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatResponseJourneysHasChatDeleteFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatDeleteFieldInput>>;
};

export type ChatResponseDisconnectInput = {
  hasMessageMessages?: InputMaybe<Array<ChatResponseHasMessageMessagesDisconnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatResponseJourneysHasChatDisconnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatDisconnectFieldInput>>;
};

export type ChatResponseEdge = {
  __typename?: "ChatResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ChatResponse;
};

export type ChatResponseHasMessageMessagesAggregateInput = {
  AND?: InputMaybe<Array<ChatResponseHasMessageMessagesAggregateInput>>;
  NOT?: InputMaybe<ChatResponseHasMessageMessagesAggregateInput>;
  OR?: InputMaybe<Array<ChatResponseHasMessageMessagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatResponseHasMessageMessagesNodeAggregationWhereInput>;
};

export type ChatResponseHasMessageMessagesConnectFieldInput = {
  connect?: InputMaybe<Array<MessageConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type ChatResponseHasMessageMessagesConnection = {
  __typename?: "ChatResponseHasMessageMessagesConnection";
  edges: Array<ChatResponseHasMessageMessagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatResponseHasMessageMessagesConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<MessageSort>;
};

export type ChatResponseHasMessageMessagesConnectionWhere = {
  AND?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectionWhere>>;
  NOT?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
  OR?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<MessageWhere>;
};

export type ChatResponseHasMessageMessagesCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: MessageCreateInput;
};

export type ChatResponseHasMessageMessagesDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
};

export type ChatResponseHasMessageMessagesDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
};

export type ChatResponseHasMessageMessagesFieldInput = {
  connect?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatResponseHasMessageMessagesCreateFieldInput>>;
};

export type ChatResponseHasMessageMessagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatResponseHasMessageMessagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatResponseHasMessageMessagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatResponseHasMessageMessagesNodeAggregationWhereInput>>;
  aspectDetails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatResponseHasMessageMessagesRelationship = {
  __typename?: "ChatResponseHasMessageMessagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
  properties: HasMessageProperties;
};

export type ChatResponseHasMessageMessagesUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<MessageUpdateInput>;
};

export type ChatResponseHasMessageMessagesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatResponseHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatResponseHasMessageMessagesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatResponseHasMessageMessagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatResponseHasMessageMessagesDisconnectFieldInput>>;
  update?: InputMaybe<ChatResponseHasMessageMessagesUpdateConnectionInput>;
  where?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
};

export type ChatResponseJourneyJourneysHasChatAggregationSelection = {
  __typename?: "ChatResponseJourneyJourneysHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatResponseJourneyJourneysHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatResponseJourneyJourneysHasChatNodeAggregateSelection>;
};

export type ChatResponseJourneyJourneysHasChatEdgeAggregateSelection = {
  __typename?: "ChatResponseJourneyJourneysHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatResponseJourneyJourneysHasChatNodeAggregateSelection = {
  __typename?: "ChatResponseJourneyJourneysHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type ChatResponseJourneysHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatResponseJourneysHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatResponseJourneysHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatResponseJourneysHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatResponseJourneysHasChatNodeAggregationWhereInput>;
};

export type ChatResponseJourneysHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type ChatResponseJourneysHasChatConnection = {
  __typename?: "ChatResponseJourneysHasChatConnection";
  edges: Array<ChatResponseJourneysHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatResponseJourneysHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type ChatResponseJourneysHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatResponseJourneysHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatResponseJourneysHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type ChatResponseJourneysHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type ChatResponseJourneysHasChatDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
};

export type ChatResponseJourneysHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
};

export type ChatResponseJourneysHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatResponseJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatResponseJourneysHasChatCreateFieldInput>>;
};

export type ChatResponseJourneysHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatResponseJourneysHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatResponseJourneysHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatResponseJourneysHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatResponseJourneysHasChatRelationship = {
  __typename?: "ChatResponseJourneysHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasChatProperties;
};

export type ChatResponseJourneysHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type ChatResponseJourneysHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatResponseJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatResponseJourneysHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatResponseJourneysHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatResponseJourneysHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatResponseJourneysHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
};

export type ChatResponseMessageHasMessageMessagesAggregationSelection = {
  __typename?: "ChatResponseMessageHasMessageMessagesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatResponseMessageHasMessageMessagesEdgeAggregateSelection>;
  node?: Maybe<ChatResponseMessageHasMessageMessagesNodeAggregateSelection>;
};

export type ChatResponseMessageHasMessageMessagesEdgeAggregateSelection = {
  __typename?: "ChatResponseMessageHasMessageMessagesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type ChatResponseMessageHasMessageMessagesNodeAggregateSelection = {
  __typename?: "ChatResponseMessageHasMessageMessagesNodeAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type ChatResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChatResponseSort objects to sort ChatResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChatResponseSort>>;
};

export type ChatResponseRelationInput = {
  hasMessageMessages?: InputMaybe<Array<ChatResponseHasMessageMessagesCreateFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatResponseJourneysHasChatCreateFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatCreateFieldInput>>;
};

/** Fields to sort ChatResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChatResponseSort object. */
export type ChatResponseSort = {
  context?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  objectId?: InputMaybe<SortDirection>;
  params?: InputMaybe<SortDirection>;
  timezone?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type ChatResponseUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessages?: InputMaybe<Array<ChatResponseHasMessageMessagesUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChat?: InputMaybe<Array<ChatResponseJourneysHasChatUpdateFieldInput>>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  usersHasChat?: InputMaybe<Array<ChatResponseUsersHasChatUpdateFieldInput>>;
};

export type ChatResponseUserUsersHasChatAggregationSelection = {
  __typename?: "ChatResponseUserUsersHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatResponseUserUsersHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatResponseUserUsersHasChatNodeAggregateSelection>;
};

export type ChatResponseUserUsersHasChatEdgeAggregateSelection = {
  __typename?: "ChatResponseUserUsersHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatResponseUserUsersHasChatNodeAggregateSelection = {
  __typename?: "ChatResponseUserUsersHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type ChatResponseUsersHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatResponseUsersHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatResponseUsersHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatResponseUsersHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatResponseUsersHasChatNodeAggregationWhereInput>;
};

export type ChatResponseUsersHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ChatResponseUsersHasChatConnectOrCreateFieldInput = {
  onCreate: ChatResponseUsersHasChatConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type ChatResponseUsersHasChatConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type ChatResponseUsersHasChatConnection = {
  __typename?: "ChatResponseUsersHasChatConnection";
  edges: Array<ChatResponseUsersHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatResponseUsersHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ChatResponseUsersHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatResponseUsersHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatResponseUsersHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ChatResponseUsersHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: UserCreateInput;
};

export type ChatResponseUsersHasChatDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
};

export type ChatResponseUsersHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
};

export type ChatResponseUsersHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatResponseUsersHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatResponseUsersHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatResponseUsersHasChatCreateFieldInput>>;
};

export type ChatResponseUsersHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatResponseUsersHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatResponseUsersHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatResponseUsersHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatResponseUsersHasChatRelationship = {
  __typename?: "ChatResponseUsersHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasChatProperties;
};

export type ChatResponseUsersHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ChatResponseUsersHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatResponseUsersHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatResponseUsersHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatResponseUsersHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatResponseUsersHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatResponseUsersHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatResponseUsersHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
};

export type ChatResponseWhere = {
  AND?: InputMaybe<Array<ChatResponseWhere>>;
  NOT?: InputMaybe<ChatResponseWhere>;
  OR?: InputMaybe<Array<ChatResponseWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessagesAggregate?: InputMaybe<ChatResponseHasMessageMessagesAggregateInput>;
  /** Return ChatResponses where all of the related ChatResponseHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_ALL?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
  /** Return ChatResponses where none of the related ChatResponseHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_NONE?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
  /** Return ChatResponses where one of the related ChatResponseHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SINGLE?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
  /** Return ChatResponses where some of the related ChatResponseHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SOME?: InputMaybe<ChatResponseHasMessageMessagesConnectionWhere>;
  /** Return ChatResponses where all of the related Messages match this filter */
  hasMessageMessages_ALL?: InputMaybe<MessageWhere>;
  /** Return ChatResponses where none of the related Messages match this filter */
  hasMessageMessages_NONE?: InputMaybe<MessageWhere>;
  /** Return ChatResponses where one of the related Messages match this filter */
  hasMessageMessages_SINGLE?: InputMaybe<MessageWhere>;
  /** Return ChatResponses where some of the related Messages match this filter */
  hasMessageMessages_SOME?: InputMaybe<MessageWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChatAggregate?: InputMaybe<ChatResponseJourneysHasChatAggregateInput>;
  /** Return ChatResponses where all of the related ChatResponseJourneysHasChatConnections match this filter */
  journeysHasChatConnection_ALL?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
  /** Return ChatResponses where none of the related ChatResponseJourneysHasChatConnections match this filter */
  journeysHasChatConnection_NONE?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
  /** Return ChatResponses where one of the related ChatResponseJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SINGLE?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
  /** Return ChatResponses where some of the related ChatResponseJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SOME?: InputMaybe<ChatResponseJourneysHasChatConnectionWhere>;
  /** Return ChatResponses where all of the related Journeys match this filter */
  journeysHasChat_ALL?: InputMaybe<JourneyWhere>;
  /** Return ChatResponses where none of the related Journeys match this filter */
  journeysHasChat_NONE?: InputMaybe<JourneyWhere>;
  /** Return ChatResponses where one of the related Journeys match this filter */
  journeysHasChat_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return ChatResponses where some of the related Journeys match this filter */
  journeysHasChat_SOME?: InputMaybe<JourneyWhere>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  objectId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  params_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  timezone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timezone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timezone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  userId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  userId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  userId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  userId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  usersHasChatAggregate?: InputMaybe<ChatResponseUsersHasChatAggregateInput>;
  /** Return ChatResponses where all of the related ChatResponseUsersHasChatConnections match this filter */
  usersHasChatConnection_ALL?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
  /** Return ChatResponses where none of the related ChatResponseUsersHasChatConnections match this filter */
  usersHasChatConnection_NONE?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
  /** Return ChatResponses where one of the related ChatResponseUsersHasChatConnections match this filter */
  usersHasChatConnection_SINGLE?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
  /** Return ChatResponses where some of the related ChatResponseUsersHasChatConnections match this filter */
  usersHasChatConnection_SOME?: InputMaybe<ChatResponseUsersHasChatConnectionWhere>;
  /** Return ChatResponses where all of the related Users match this filter */
  usersHasChat_ALL?: InputMaybe<UserWhere>;
  /** Return ChatResponses where none of the related Users match this filter */
  usersHasChat_NONE?: InputMaybe<UserWhere>;
  /** Return ChatResponses where one of the related Users match this filter */
  usersHasChat_SINGLE?: InputMaybe<UserWhere>;
  /** Return ChatResponses where some of the related Users match this filter */
  usersHasChat_SOME?: InputMaybe<UserWhere>;
};

export type ChatResponsesConnection = {
  __typename?: "ChatResponsesConnection";
  edges: Array<ChatResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Chats by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChatSort object. */
export type ChatSort = {
  context?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  objectId?: InputMaybe<SortDirection>;
  params?: InputMaybe<SortDirection>;
  timezone?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type ChatUpdateInput = {
  aiTutorHasChat?: InputMaybe<Array<ChatAiTutorHasChatUpdateFieldInput>>;
  assignmentsHasChat?: InputMaybe<Array<ChatAssignmentsHasChatUpdateFieldInput>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatUpdateFieldInput>>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningObjectivesHasChat?: InputMaybe<Array<ChatLearningObjectivesHasChatUpdateFieldInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatUpdateFieldInput>>;
};

export type ChatUserUsersHasChatAggregationSelection = {
  __typename?: "ChatUserUsersHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatUserUsersHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatUserUsersHasChatNodeAggregateSelection>;
};

export type ChatUserUsersHasChatEdgeAggregateSelection = {
  __typename?: "ChatUserUsersHasChatEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ChatUserUsersHasChatNodeAggregateSelection = {
  __typename?: "ChatUserUsersHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type ChatUsersHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatUsersHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatUsersHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatUsersHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatUsersHasChatNodeAggregationWhereInput>;
};

export type ChatUsersHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ChatUsersHasChatConnectOrCreateFieldInput = {
  onCreate: ChatUsersHasChatConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type ChatUsersHasChatConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type ChatUsersHasChatConnection = {
  __typename?: "ChatUsersHasChatConnection";
  edges: Array<ChatUsersHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatUsersHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ChatUsersHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatUsersHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatUsersHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: UserCreateInput;
};

export type ChatUsersHasChatDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatUsersHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatUsersHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatUsersHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
};

export type ChatUsersHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatUsersHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatUsersHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatUsersHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatUsersHasChatRelationship = {
  __typename?: "ChatUsersHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasChatProperties;
};

export type ChatUsersHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ChatUsersHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ChatUsersHasChatConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatUsersHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatUsersHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatUsersHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatWhere = {
  AND?: InputMaybe<Array<ChatWhere>>;
  NOT?: InputMaybe<ChatWhere>;
  OR?: InputMaybe<Array<ChatWhere>>;
  aiTutorHasChatAggregate?: InputMaybe<ChatAiTutorHasChatAggregateInput>;
  /** Return Chats where all of the related ChatAiTutorHasChatConnections match this filter */
  aiTutorHasChatConnection_ALL?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatAiTutorHasChatConnections match this filter */
  aiTutorHasChatConnection_NONE?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatAiTutorHasChatConnections match this filter */
  aiTutorHasChatConnection_SINGLE?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatAiTutorHasChatConnections match this filter */
  aiTutorHasChatConnection_SOME?: InputMaybe<ChatAiTutorHasChatConnectionWhere>;
  /** Return Chats where all of the related AITutors match this filter */
  aiTutorHasChat_ALL?: InputMaybe<AiTutorWhere>;
  /** Return Chats where none of the related AITutors match this filter */
  aiTutorHasChat_NONE?: InputMaybe<AiTutorWhere>;
  /** Return Chats where one of the related AITutors match this filter */
  aiTutorHasChat_SINGLE?: InputMaybe<AiTutorWhere>;
  /** Return Chats where some of the related AITutors match this filter */
  aiTutorHasChat_SOME?: InputMaybe<AiTutorWhere>;
  assignmentsHasChatAggregate?: InputMaybe<ChatAssignmentsHasChatAggregateInput>;
  /** Return Chats where all of the related ChatAssignmentsHasChatConnections match this filter */
  assignmentsHasChatConnection_ALL?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatAssignmentsHasChatConnections match this filter */
  assignmentsHasChatConnection_NONE?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatAssignmentsHasChatConnections match this filter */
  assignmentsHasChatConnection_SINGLE?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatAssignmentsHasChatConnections match this filter */
  assignmentsHasChatConnection_SOME?: InputMaybe<ChatAssignmentsHasChatConnectionWhere>;
  /** Return Chats where all of the related Assignments match this filter */
  assignmentsHasChat_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Chats where none of the related Assignments match this filter */
  assignmentsHasChat_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Chats where one of the related Assignments match this filter */
  assignmentsHasChat_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Chats where some of the related Assignments match this filter */
  assignmentsHasChat_SOME?: InputMaybe<AssignmentWhere>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasMessageMessagesAggregate?: InputMaybe<ChatHasMessageMessagesAggregateInput>;
  /** Return Chats where all of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_ALL?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where none of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_NONE?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where one of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SINGLE?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where some of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SOME?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where all of the related Messages match this filter */
  hasMessageMessages_ALL?: InputMaybe<MessageWhere>;
  /** Return Chats where none of the related Messages match this filter */
  hasMessageMessages_NONE?: InputMaybe<MessageWhere>;
  /** Return Chats where one of the related Messages match this filter */
  hasMessageMessages_SINGLE?: InputMaybe<MessageWhere>;
  /** Return Chats where some of the related Messages match this filter */
  hasMessageMessages_SOME?: InputMaybe<MessageWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChatAggregate?: InputMaybe<ChatJourneysHasChatAggregateInput>;
  /** Return Chats where all of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_ALL?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_NONE?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SINGLE?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SOME?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where all of the related Journeys match this filter */
  journeysHasChat_ALL?: InputMaybe<JourneyWhere>;
  /** Return Chats where none of the related Journeys match this filter */
  journeysHasChat_NONE?: InputMaybe<JourneyWhere>;
  /** Return Chats where one of the related Journeys match this filter */
  journeysHasChat_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Chats where some of the related Journeys match this filter */
  journeysHasChat_SOME?: InputMaybe<JourneyWhere>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningObjectivesHasChatAggregate?: InputMaybe<ChatLearningObjectivesHasChatAggregateInput>;
  /** Return Chats where all of the related ChatLearningObjectivesHasChatConnections match this filter */
  learningObjectivesHasChatConnection_ALL?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatLearningObjectivesHasChatConnections match this filter */
  learningObjectivesHasChatConnection_NONE?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatLearningObjectivesHasChatConnections match this filter */
  learningObjectivesHasChatConnection_SINGLE?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatLearningObjectivesHasChatConnections match this filter */
  learningObjectivesHasChatConnection_SOME?: InputMaybe<ChatLearningObjectivesHasChatConnectionWhere>;
  /** Return Chats where all of the related LearningObjectives match this filter */
  learningObjectivesHasChat_ALL?: InputMaybe<LearningObjectiveWhere>;
  /** Return Chats where none of the related LearningObjectives match this filter */
  learningObjectivesHasChat_NONE?: InputMaybe<LearningObjectiveWhere>;
  /** Return Chats where one of the related LearningObjectives match this filter */
  learningObjectivesHasChat_SINGLE?: InputMaybe<LearningObjectiveWhere>;
  /** Return Chats where some of the related LearningObjectives match this filter */
  learningObjectivesHasChat_SOME?: InputMaybe<LearningObjectiveWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  objectId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  params_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  timezone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timezone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timezone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasChatAggregate?: InputMaybe<ChatUsersHasChatAggregateInput>;
  /** Return Chats where all of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_ALL?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_NONE?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_SINGLE?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_SOME?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where all of the related Users match this filter */
  usersHasChat_ALL?: InputMaybe<UserWhere>;
  /** Return Chats where none of the related Users match this filter */
  usersHasChat_NONE?: InputMaybe<UserWhere>;
  /** Return Chats where one of the related Users match this filter */
  usersHasChat_SINGLE?: InputMaybe<UserWhere>;
  /** Return Chats where some of the related Users match this filter */
  usersHasChat_SOME?: InputMaybe<UserWhere>;
};

export type ChatsConnection = {
  __typename?: "ChatsConnection";
  edges: Array<ChatEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChildInfo = {
  __typename?: "ChildInfo";
  completedTracks?: Maybe<Scalars["Float"]["output"]>;
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  totalTracks?: Maybe<Scalars["Float"]["output"]>;
};

export type ChildInfoAggregateSelection = {
  __typename?: "ChildInfoAggregateSelection";
  completedTracks: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
  name: StringAggregateSelection;
  totalTracks: FloatAggregateSelection;
};

export type ChildInfoCreateInput = {
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  label: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfoEdge = {
  __typename?: "ChildInfoEdge";
  cursor: Scalars["String"]["output"];
  node: ChildInfo;
};

export type ChildInfoOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChildInfoSort objects to sort ChildInfos by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChildInfoSort>>;
};

/** Fields to sort ChildInfos by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChildInfoSort object. */
export type ChildInfoSort = {
  completedTracks?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTracks?: InputMaybe<SortDirection>;
};

export type ChildInfoUpdateInput = {
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfoWhere = {
  AND?: InputMaybe<Array<ChildInfoWhere>>;
  NOT?: InputMaybe<ChildInfoWhere>;
  OR?: InputMaybe<Array<ChildInfoWhere>>;
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completedTracks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalTracks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfosConnection = {
  __typename?: "ChildInfosConnection";
  edges: Array<ChildInfoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionActivityAnalytics = {
  __typename?: "CompletionActivityAnalytics";
  completionStats: CompletionStats;
  details: CompletionActivityDetails;
  label: Scalars["String"]["output"];
};

export type CompletionActivityAnalyticsAggregateSelection = {
  __typename?: "CompletionActivityAnalyticsAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
};

export type CompletionActivityAnalyticsConnection = {
  __typename?: "CompletionActivityAnalyticsConnection";
  edges: Array<CompletionActivityAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionActivityAnalyticsCreateInput = {
  label: Scalars["String"]["input"];
};

export type CompletionActivityAnalyticsEdge = {
  __typename?: "CompletionActivityAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionActivityAnalytics;
};

export type CompletionActivityAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionActivityAnalyticsSort objects to sort CompletionActivityAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionActivityAnalyticsSort>>;
};

/** Fields to sort CompletionActivityAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionActivityAnalyticsSort object. */
export type CompletionActivityAnalyticsSort = {
  label?: InputMaybe<SortDirection>;
};

export type CompletionActivityAnalyticsUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionActivityAnalyticsWhere = {
  AND?: InputMaybe<Array<CompletionActivityAnalyticsWhere>>;
  NOT?: InputMaybe<CompletionActivityAnalyticsWhere>;
  OR?: InputMaybe<Array<CompletionActivityAnalyticsWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionActivityDetails = {
  __typename?: "CompletionActivityDetails";
  objectives: Array<CompletionObjectiveAnalytics>;
};

export type CompletionActivityDetailsAggregateSelection = {
  __typename?: "CompletionActivityDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CompletionActivityDetailsConnection = {
  __typename?: "CompletionActivityDetailsConnection";
  edges: Array<CompletionActivityDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionActivityDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionActivityDetailsEdge = {
  __typename?: "CompletionActivityDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionActivityDetails;
};

export type CompletionActivityDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompletionActivityDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionActivityDetailsWhere = {
  AND?: InputMaybe<Array<CompletionActivityDetailsWhere>>;
  NOT?: InputMaybe<CompletionActivityDetailsWhere>;
  OR?: InputMaybe<Array<CompletionActivityDetailsWhere>>;
};

export type CompletionAnalytics = {
  __typename?: "CompletionAnalytics";
  completionStats: CompletionStats;
  details: CompletionCourseDetails;
  label: Scalars["String"]["output"];
};

export type CompletionAnalyticsAggregateSelection = {
  __typename?: "CompletionAnalyticsAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
};

export type CompletionAnalyticsConnection = {
  __typename?: "CompletionAnalyticsConnection";
  edges: Array<CompletionAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionAnalyticsCreateInput = {
  label: Scalars["String"]["input"];
};

export type CompletionAnalyticsEdge = {
  __typename?: "CompletionAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionAnalytics;
};

export type CompletionAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionAnalyticsSort objects to sort CompletionAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionAnalyticsSort>>;
};

export type CompletionAnalyticsResponse = {
  __typename?: "CompletionAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<CompletionAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CompletionAnalyticsResponseAggregateSelection = {
  __typename?: "CompletionAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CompletionAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CompletionAnalyticsResponseEdge = {
  __typename?: "CompletionAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionAnalyticsResponse;
};

export type CompletionAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionAnalyticsResponseSort objects to sort CompletionAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionAnalyticsResponseSort>>;
};

/** Fields to sort CompletionAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionAnalyticsResponseSort object. */
export type CompletionAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CompletionAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<CompletionAnalyticsResponseWhere>>;
  NOT?: InputMaybe<CompletionAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<CompletionAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionAnalyticsResponsesConnection = {
  __typename?: "CompletionAnalyticsResponsesConnection";
  edges: Array<CompletionAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort CompletionAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionAnalyticsSort object. */
export type CompletionAnalyticsSort = {
  label?: InputMaybe<SortDirection>;
};

export type CompletionAnalyticsUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionAnalyticsWhere = {
  AND?: InputMaybe<Array<CompletionAnalyticsWhere>>;
  NOT?: InputMaybe<CompletionAnalyticsWhere>;
  OR?: InputMaybe<Array<CompletionAnalyticsWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionCourseDetails = {
  __typename?: "CompletionCourseDetails";
  modules: Array<CompletionModuleAnalytics>;
};

export type CompletionCourseDetailsAggregateSelection = {
  __typename?: "CompletionCourseDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CompletionCourseDetailsConnection = {
  __typename?: "CompletionCourseDetailsConnection";
  edges: Array<CompletionCourseDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionCourseDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionCourseDetailsEdge = {
  __typename?: "CompletionCourseDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionCourseDetails;
};

export type CompletionCourseDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompletionCourseDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionCourseDetailsWhere = {
  AND?: InputMaybe<Array<CompletionCourseDetailsWhere>>;
  NOT?: InputMaybe<CompletionCourseDetailsWhere>;
  OR?: InputMaybe<Array<CompletionCourseDetailsWhere>>;
};

export type CompletionModuleAnalytics = {
  __typename?: "CompletionModuleAnalytics";
  completionStats: CompletionStats;
  details: CompletionModuleDetails;
  label: Scalars["String"]["output"];
};

export type CompletionModuleAnalyticsAggregateSelection = {
  __typename?: "CompletionModuleAnalyticsAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
};

export type CompletionModuleAnalyticsConnection = {
  __typename?: "CompletionModuleAnalyticsConnection";
  edges: Array<CompletionModuleAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionModuleAnalyticsCreateInput = {
  label: Scalars["String"]["input"];
};

export type CompletionModuleAnalyticsEdge = {
  __typename?: "CompletionModuleAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionModuleAnalytics;
};

export type CompletionModuleAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionModuleAnalyticsSort objects to sort CompletionModuleAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionModuleAnalyticsSort>>;
};

/** Fields to sort CompletionModuleAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionModuleAnalyticsSort object. */
export type CompletionModuleAnalyticsSort = {
  label?: InputMaybe<SortDirection>;
};

export type CompletionModuleAnalyticsUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionModuleAnalyticsWhere = {
  AND?: InputMaybe<Array<CompletionModuleAnalyticsWhere>>;
  NOT?: InputMaybe<CompletionModuleAnalyticsWhere>;
  OR?: InputMaybe<Array<CompletionModuleAnalyticsWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionModuleDetails = {
  __typename?: "CompletionModuleDetails";
  activities: Array<CompletionActivityAnalytics>;
};

export type CompletionModuleDetailsAggregateSelection = {
  __typename?: "CompletionModuleDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CompletionModuleDetailsConnection = {
  __typename?: "CompletionModuleDetailsConnection";
  edges: Array<CompletionModuleDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionModuleDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionModuleDetailsEdge = {
  __typename?: "CompletionModuleDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionModuleDetails;
};

export type CompletionModuleDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CompletionModuleDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CompletionModuleDetailsWhere = {
  AND?: InputMaybe<Array<CompletionModuleDetailsWhere>>;
  NOT?: InputMaybe<CompletionModuleDetailsWhere>;
  OR?: InputMaybe<Array<CompletionModuleDetailsWhere>>;
};

export type CompletionObjectiveAnalytics = {
  __typename?: "CompletionObjectiveAnalytics";
  completionStats: CompletionStats;
  label: Scalars["String"]["output"];
};

export type CompletionObjectiveAnalyticsAggregateSelection = {
  __typename?: "CompletionObjectiveAnalyticsAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
};

export type CompletionObjectiveAnalyticsConnection = {
  __typename?: "CompletionObjectiveAnalyticsConnection";
  edges: Array<CompletionObjectiveAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionObjectiveAnalyticsCreateInput = {
  label: Scalars["String"]["input"];
};

export type CompletionObjectiveAnalyticsEdge = {
  __typename?: "CompletionObjectiveAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionObjectiveAnalytics;
};

export type CompletionObjectiveAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionObjectiveAnalyticsSort objects to sort CompletionObjectiveAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionObjectiveAnalyticsSort>>;
};

/** Fields to sort CompletionObjectiveAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionObjectiveAnalyticsSort object. */
export type CompletionObjectiveAnalyticsSort = {
  label?: InputMaybe<SortDirection>;
};

export type CompletionObjectiveAnalyticsUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionObjectiveAnalyticsWhere = {
  AND?: InputMaybe<Array<CompletionObjectiveAnalyticsWhere>>;
  NOT?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
  OR?: InputMaybe<Array<CompletionObjectiveAnalyticsWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CompletionStats = {
  __typename?: "CompletionStats";
  completed: Scalars["Float"]["output"];
  inProgress: Scalars["Float"]["output"];
  notStarted: Scalars["Float"]["output"];
};

export type CompletionStatsAggregateSelection = {
  __typename?: "CompletionStatsAggregateSelection";
  completed: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  inProgress: FloatAggregateSelection;
  notStarted: FloatAggregateSelection;
};

export type CompletionStatsConnection = {
  __typename?: "CompletionStatsConnection";
  edges: Array<CompletionStatsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CompletionStatsCreateInput = {
  completed: Scalars["Float"]["input"];
  inProgress: Scalars["Float"]["input"];
  notStarted: Scalars["Float"]["input"];
};

export type CompletionStatsEdge = {
  __typename?: "CompletionStatsEdge";
  cursor: Scalars["String"]["output"];
  node: CompletionStats;
};

export type CompletionStatsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CompletionStatsSort objects to sort CompletionStats by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CompletionStatsSort>>;
};

/** Fields to sort CompletionStats by. The order in which sorts are applied is not guaranteed when specifying many fields in one CompletionStatsSort object. */
export type CompletionStatsSort = {
  completed?: InputMaybe<SortDirection>;
  inProgress?: InputMaybe<SortDirection>;
  notStarted?: InputMaybe<SortDirection>;
};

export type CompletionStatsUpdateInput = {
  completed?: InputMaybe<Scalars["Float"]["input"]>;
  completed_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completed_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completed_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completed_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CompletionStatsWhere = {
  AND?: InputMaybe<Array<CompletionStatsWhere>>;
  NOT?: InputMaybe<CompletionStatsWhere>;
  OR?: InputMaybe<Array<CompletionStatsWhere>>;
  completed?: InputMaybe<Scalars["Float"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completed_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  completed_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  inProgress_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inProgress_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_GT?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  notStarted_LT?: InputMaybe<Scalars["Float"]["input"]>;
  notStarted_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CoreValue = {
  __typename?: "CoreValue";
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CoreValueAggregateSelection = {
  __typename?: "CoreValueAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  importance: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type CoreValueCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValueEdge = {
  __typename?: "CoreValueEdge";
  cursor: Scalars["String"]["output"];
  node: CoreValue;
};

export type CoreValueOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CoreValueSort objects to sort CoreValues by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CoreValueSort>>;
};

/** Fields to sort CoreValues by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoreValueSort object. */
export type CoreValueSort = {
  id?: InputMaybe<SortDirection>;
  importance?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type CoreValueUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValueWhere = {
  AND?: InputMaybe<Array<CoreValueWhere>>;
  NOT?: InputMaybe<CoreValueWhere>;
  OR?: InputMaybe<Array<CoreValueWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  importance_LT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValuesConnection = {
  __typename?: "CoreValuesConnection";
  edges: Array<CoreValueEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Course = {
  __typename?: "Course";
  account_id?: Maybe<Scalars["Float"]["output"]>;
  apply_assignment_group_weights?: Maybe<Scalars["Boolean"]["output"]>;
  blueprint?: Maybe<Scalars["Boolean"]["output"]>;
  calendar_ics?: Maybe<Scalars["String"]["output"]>;
  cost?: Maybe<Scalars["Float"]["output"]>;
  courseId?: Maybe<Scalars["String"]["output"]>;
  courseType?: Maybe<Scalars["String"]["output"]>;
  course_code?: Maybe<Scalars["String"]["output"]>;
  course_id?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  default_view?: Maybe<Scalars["String"]["output"]>;
  degreesHasCourse: Array<Degree>;
  degreesHasCourseAggregate?: Maybe<CourseDegreeDegreesHasCourseAggregationSelection>;
  degreesHasCourseConnection: CourseDegreesHasCourseConnection;
  description?: Maybe<Scalars["String"]["output"]>;
  end_at?: Maybe<Scalars["String"]["output"]>;
  enrolledStudents: Array<User>;
  enrolledStudentsAggregate?: Maybe<CourseUserEnrolledStudentsAggregationSelection>;
  enrolledStudentsConnection: CourseEnrolledStudentsConnection;
  enrollment_term_id?: Maybe<Scalars["Float"]["output"]>;
  generationStatus?: Maybe<CourseGenerationStatus>;
  grading_standard_id?: Maybe<Scalars["Float"]["output"]>;
  hasAssignmentAssignments: Array<Assignment>;
  hasAssignmentAssignmentsAggregate?: Maybe<CourseAssignmentHasAssignmentAssignmentsAggregationSelection>;
  hasAssignmentAssignmentsConnection: CourseHasAssignmentAssignmentsConnection;
  hasCourseCategoriesCourseCategories: Array<CourseCategory>;
  hasCourseCategoriesCourseCategoriesAggregate?: Maybe<CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection>;
  hasCourseCategoriesCourseCategoriesConnection: CourseHasCourseCategoriesCourseCategoriesConnection;
  hasPagePages: Array<Page>;
  hasPagePagesAggregate?: Maybe<CoursePageHasPagePagesAggregationSelection>;
  hasPagePagesConnection: CourseHasPagePagesConnection;
  hide_final_grades?: Maybe<Scalars["Boolean"]["output"]>;
  homeroom_course?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  integration_id?: Maybe<Scalars["String"]["output"]>;
  isCustomCourse?: Maybe<Scalars["Boolean"]["output"]>;
  isDraft?: Maybe<Scalars["Boolean"]["output"]>;
  is_elective?: Maybe<Scalars["Boolean"]["output"]>;
  is_public?: Maybe<Scalars["Boolean"]["output"]>;
  is_public_to_auth_users?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  learningStandards?: Maybe<Scalars["String"]["output"]>;
  lengthInWeeks?: Maybe<Scalars["Float"]["output"]>;
  license?: Maybe<Scalars["String"]["output"]>;
  lti_id?: Maybe<Scalars["String"]["output"]>;
  modules: Array<Module>;
  modulesAggregate?: Maybe<CourseModuleModulesAggregationSelection>;
  modulesConnection: CourseModulesConnection;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Scalars["String"]["output"]>>;
  original_name?: Maybe<Scalars["String"]["output"]>;
  period_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  periods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  public_syllabus?: Maybe<Scalars["Boolean"]["output"]>;
  public_syllabus_to_auth?: Maybe<Scalars["Boolean"]["output"]>;
  restrict_enrollments_to_course_dates?: Maybe<Scalars["Boolean"]["output"]>;
  root_account_id?: Maybe<Scalars["Float"]["output"]>;
  schoolsHasCourse: Array<School>;
  schoolsHasCourseAggregate?: Maybe<CourseSchoolSchoolsHasCourseAggregationSelection>;
  schoolsHasCourseConnection: CourseSchoolsHasCourseConnection;
  sis_course_id?: Maybe<Scalars["String"]["output"]>;
  sis_import_id?: Maybe<Scalars["Float"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  start_at?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  storage_quota_mb?: Maybe<Scalars["Float"]["output"]>;
  stripePriceId?: Maybe<Scalars["String"]["output"]>;
  stripeProductId?: Maybe<Scalars["String"]["output"]>;
  targetAudience?: Maybe<Scalars["String"]["output"]>;
  teachingFaculty: Array<User>;
  teachingFacultyAggregate?: Maybe<CourseUserTeachingFacultyAggregationSelection>;
  teachingFacultyConnection: CourseTeachingFacultyConnection;
  template?: Maybe<Scalars["Boolean"]["output"]>;
  thumbnail?: Maybe<Scalars["String"]["output"]>;
  time_zone?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolCourse: Array<User>;
  usersHasSchoolCourseAggregate?: Maybe<CourseUserUsersHasSchoolCourseAggregationSelection>;
  usersHasSchoolCourseConnection: CourseUsersHasSchoolCourseConnection;
  usersHaveLearningPayment: Array<LearningPayment>;
  usersHaveLearningPaymentAggregate?: Maybe<CourseLearningPaymentUsersHaveLearningPaymentAggregationSelection>;
  usersHaveLearningPaymentConnection: CourseUsersHaveLearningPaymentConnection;
  uuid?: Maybe<Scalars["String"]["output"]>;
  workflow_state?: Maybe<Scalars["String"]["output"]>;
};

export type CourseDegreesHasCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DegreeOptions>;
  where?: InputMaybe<DegreeWhere>;
};

export type CourseDegreesHasCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DegreeWhere>;
};

export type CourseDegreesHasCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseDegreesHasCourseConnectionSort>>;
  where?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
};

export type CourseEnrolledStudentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type CourseEnrolledStudentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type CourseEnrolledStudentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseEnrolledStudentsConnectionSort>>;
  where?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
};

export type CourseHasAssignmentAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionSort>>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionSort>>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasPagePagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasPagePagesConnectionSort>>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseModulesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type CourseModulesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type CourseModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseModulesConnectionSort>>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseSchoolsHasCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseSchoolsHasCourseConnectionSort>>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseTeachingFacultyArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type CourseTeachingFacultyAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type CourseTeachingFacultyConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseTeachingFacultyConnectionSort>>;
  where?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
};

export type CourseUsersHasSchoolCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionSort>>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHaveLearningPaymentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<LearningPaymentOptions>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type CourseUsersHaveLearningPaymentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type CourseUsersHaveLearningPaymentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectionSort>>;
  where?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
};

export type CourseAggregateSelection = {
  __typename?: "CourseAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseAnalytics = {
  __typename?: "CourseAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: CourseAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  enrollment: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
};

export type CourseAnalyticsAggregateSelection = {
  __typename?: "CourseAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  enrollment: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type CourseAnalyticsConnection = {
  __typename?: "CourseAnalyticsConnection";
  edges: Array<CourseAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  enrollment: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
};

export type CourseAnalyticsDetails = {
  __typename?: "CourseAnalyticsDetails";
  modules: Array<ModuleAnalytics>;
};

export type CourseAnalyticsDetailsAggregateSelection = {
  __typename?: "CourseAnalyticsDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CourseAnalyticsDetailsConnection = {
  __typename?: "CourseAnalyticsDetailsConnection";
  edges: Array<CourseAnalyticsDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseAnalyticsDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsDetailsEdge = {
  __typename?: "CourseAnalyticsDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseAnalyticsDetails;
};

export type CourseAnalyticsDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseAnalyticsDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsDetailsWhere = {
  AND?: InputMaybe<Array<CourseAnalyticsDetailsWhere>>;
  NOT?: InputMaybe<CourseAnalyticsDetailsWhere>;
  OR?: InputMaybe<Array<CourseAnalyticsDetailsWhere>>;
};

export type CourseAnalyticsEdge = {
  __typename?: "CourseAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseAnalytics;
};

export type CourseAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseAnalyticsSort objects to sort CourseAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseAnalyticsSort>>;
};

export type CourseAnalyticsResponse = {
  __typename?: "CourseAnalyticsResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<CourseAnalytics>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CourseAnalyticsResponseAggregateSelection = {
  __typename?: "CourseAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseAnalyticsResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsResponseEdge = {
  __typename?: "CourseAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseAnalyticsResponse;
};

export type CourseAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseAnalyticsResponseSort objects to sort CourseAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseAnalyticsResponseSort>>;
};

/** Fields to sort CourseAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseAnalyticsResponseSort object. */
export type CourseAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<CourseAnalyticsResponseWhere>>;
  NOT?: InputMaybe<CourseAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<CourseAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsResponsesConnection = {
  __typename?: "CourseAnalyticsResponsesConnection";
  edges: Array<CourseAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort CourseAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseAnalyticsSort object. */
export type CourseAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enrollment?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type CourseAnalyticsSummaryResponse = {
  __typename?: "CourseAnalyticsSummaryResponse";
  count: Scalars["Int"]["output"];
  data: Array<CourseSummary>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseAnalyticsSummaryResponseAggregateSelection = {
  __typename?: "CourseAnalyticsSummaryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseAnalyticsSummaryResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseAnalyticsSummaryResponseEdge = {
  __typename?: "CourseAnalyticsSummaryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseAnalyticsSummaryResponse;
};

export type CourseAnalyticsSummaryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseAnalyticsSummaryResponseSort objects to sort CourseAnalyticsSummaryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseAnalyticsSummaryResponseSort>>;
};

/** Fields to sort CourseAnalyticsSummaryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseAnalyticsSummaryResponseSort object. */
export type CourseAnalyticsSummaryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseAnalyticsSummaryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsSummaryResponseWhere = {
  AND?: InputMaybe<Array<CourseAnalyticsSummaryResponseWhere>>;
  NOT?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
  OR?: InputMaybe<Array<CourseAnalyticsSummaryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseAnalyticsSummaryResponsesConnection = {
  __typename?: "CourseAnalyticsSummaryResponsesConnection";
  edges: Array<CourseAnalyticsSummaryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseAnalyticsWhere = {
  AND?: InputMaybe<Array<CourseAnalyticsWhere>>;
  NOT?: InputMaybe<CourseAnalyticsWhere>;
  OR?: InputMaybe<Array<CourseAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  enrollment_LT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseAssignmentHasAssignmentAssignmentsAggregationSelection = {
  __typename?: "CourseAssignmentHasAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection>;
};

export type CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoriesConnection = {
  __typename?: "CourseCategoriesConnection";
  edges: Array<CourseCategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategory = {
  __typename?: "CourseCategory";
  coursesHasCourseCategories: Array<Course>;
  coursesHasCourseCategoriesAggregate?: Maybe<CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection>;
  coursesHasCourseCategoriesConnection: CourseCategoryCoursesHasCourseCategoriesConnection;
  created: Scalars["DateTime"]["output"];
  hasAssignmentAssignments: Array<Assignment>;
  hasAssignmentAssignmentsAggregate?: Maybe<CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection>;
  hasAssignmentAssignmentsConnection: CourseCategoryHasAssignmentAssignmentsConnection;
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
};

export type CourseCategoryCoursesHasCourseCategoriesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionSort>>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionSort>>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryAggregateSelection = {
  __typename?: "CourseCategoryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection = {
  __typename?: "CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection>;
};

export type CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoryConnectInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
};

export type CourseCategoryConnectOrCreateInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
};

export type CourseCategoryConnectWhere = {
  node: CourseCategoryWhere;
};

export type CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection = {
  __typename?: "CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection>;
};

export type CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection = {
  __typename?: "CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoryCoursesHasCourseCategoriesAggregateInput = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesAggregateInput>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesAggregateInput>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput = {
  onCreate: CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type CourseCategoryCoursesHasCourseCategoriesConnection = {
  __typename?: "CourseCategoryCoursesHasCourseCategoriesConnection";
  edges: Array<CourseCategoryCoursesHasCourseCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesCreateFieldInput = {
  node: CourseCreateInput;
};

export type CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
};

export type CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseCategoryCoursesHasCourseCategoriesRelationship = {
  __typename?: "CourseCategoryCoursesHasCourseCategoriesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type CourseCategoryCoursesHasCourseCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type CourseCategoryCoursesHasCourseCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesUpdateConnectionInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCreateInput = {
  coursesHasCourseCategories?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesFieldInput>;
  created: Scalars["DateTime"]["input"];
  hasAssignmentAssignments?: InputMaybe<CourseCategoryHasAssignmentAssignmentsFieldInput>;
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  title: Scalars["String"]["input"];
};

export type CourseCategoryDeleteInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDeleteFieldInput>>;
};

export type CourseCategoryDisconnectInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput>>;
};

export type CourseCategoryEdge = {
  __typename?: "CourseCategoryEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type CourseCategoryHasAssignmentAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsAggregateInput>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput = {
  onCreate: CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type CourseCategoryHasAssignmentAssignmentsConnection = {
  __typename?: "CourseCategoryHasAssignmentAssignmentsConnection";
  edges: Array<CourseCategoryHasAssignmentAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategoryHasAssignmentAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type CourseCategoryHasAssignmentAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
};

export type CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseCategoryHasAssignmentAssignmentsRelationship = {
  __typename?: "CourseCategoryHasAssignmentAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type CourseCategoryHasAssignmentAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type CourseCategoryHasAssignmentAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<CourseCategoryHasAssignmentAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCategorySort objects to sort CourseCategories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCategorySort>>;
};

export type CourseCategoryRelationInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
};

/** Fields to sort CourseCategories by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCategorySort object. */
export type CourseCategorySort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type CourseCategoryUpdateInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsUpdateFieldInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCategoryWhere = {
  AND?: InputMaybe<Array<CourseCategoryWhere>>;
  NOT?: InputMaybe<CourseCategoryWhere>;
  OR?: InputMaybe<Array<CourseCategoryWhere>>;
  coursesHasCourseCategoriesAggregate?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesAggregateInput>;
  /** Return CourseCategories where all of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_ALL?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where none of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_NONE?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where one of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_SINGLE?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where some of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_SOME?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where all of the related Courses match this filter */
  coursesHasCourseCategories_ALL?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where none of the related Courses match this filter */
  coursesHasCourseCategories_NONE?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where one of the related Courses match this filter */
  coursesHasCourseCategories_SINGLE?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where some of the related Courses match this filter */
  coursesHasCourseCategories_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasAssignmentAssignmentsAggregate?: InputMaybe<CourseCategoryHasAssignmentAssignmentsAggregateInput>;
  /** Return CourseCategories where all of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_ALL?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where none of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_NONE?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where one of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SINGLE?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where some of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SOME?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where all of the related Assignments match this filter */
  hasAssignmentAssignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where none of the related Assignments match this filter */
  hasAssignmentAssignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where one of the related Assignments match this filter */
  hasAssignmentAssignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where some of the related Assignments match this filter */
  hasAssignmentAssignments_SOME?: InputMaybe<AssignmentWhere>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCompletionDetails = {
  __typename?: "CourseCompletionDetails";
  completionStats: CompletionStats;
  details: CourseCompletionDetailsData;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type CourseCompletionDetailsAggregateSelection = {
  __typename?: "CourseCompletionDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type CourseCompletionDetailsConnection = {
  __typename?: "CourseCompletionDetailsConnection";
  edges: Array<CourseCompletionDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCompletionDetailsCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type CourseCompletionDetailsData = {
  __typename?: "CourseCompletionDetailsData";
  modules: Array<ModuleCompletionDetails>;
};

export type CourseCompletionDetailsDataAggregateSelection = {
  __typename?: "CourseCompletionDetailsDataAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CourseCompletionDetailsDataConnection = {
  __typename?: "CourseCompletionDetailsDataConnection";
  edges: Array<CourseCompletionDetailsDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCompletionDetailsDataCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionDetailsDataEdge = {
  __typename?: "CourseCompletionDetailsDataEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCompletionDetailsData;
};

export type CourseCompletionDetailsDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseCompletionDetailsDataUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionDetailsDataWhere = {
  AND?: InputMaybe<Array<CourseCompletionDetailsDataWhere>>;
  NOT?: InputMaybe<CourseCompletionDetailsDataWhere>;
  OR?: InputMaybe<Array<CourseCompletionDetailsDataWhere>>;
};

export type CourseCompletionDetailsEdge = {
  __typename?: "CourseCompletionDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCompletionDetails;
};

export type CourseCompletionDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCompletionDetailsSort objects to sort CourseCompletionDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCompletionDetailsSort>>;
};

export type CourseCompletionDetailsResponse = {
  __typename?: "CourseCompletionDetailsResponse";
  count: Scalars["Int"]["output"];
  data: Array<CourseCompletionDetails>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseCompletionDetailsResponseAggregateSelection = {
  __typename?: "CourseCompletionDetailsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseCompletionDetailsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseCompletionDetailsResponseEdge = {
  __typename?: "CourseCompletionDetailsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCompletionDetailsResponse;
};

export type CourseCompletionDetailsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCompletionDetailsResponseSort objects to sort CourseCompletionDetailsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCompletionDetailsResponseSort>>;
};

/** Fields to sort CourseCompletionDetailsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCompletionDetailsResponseSort object. */
export type CourseCompletionDetailsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseCompletionDetailsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionDetailsResponseWhere = {
  AND?: InputMaybe<Array<CourseCompletionDetailsResponseWhere>>;
  NOT?: InputMaybe<CourseCompletionDetailsResponseWhere>;
  OR?: InputMaybe<Array<CourseCompletionDetailsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionDetailsResponsesConnection = {
  __typename?: "CourseCompletionDetailsResponsesConnection";
  edges: Array<CourseCompletionDetailsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort CourseCompletionDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCompletionDetailsSort object. */
export type CourseCompletionDetailsSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type CourseCompletionDetailsUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCompletionDetailsWhere = {
  AND?: InputMaybe<Array<CourseCompletionDetailsWhere>>;
  NOT?: InputMaybe<CourseCompletionDetailsWhere>;
  OR?: InputMaybe<Array<CourseCompletionDetailsWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCompletionSummariesConnection = {
  __typename?: "CourseCompletionSummariesConnection";
  edges: Array<CourseCompletionSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCompletionSummary = {
  __typename?: "CourseCompletionSummary";
  completionStats: CompletionStats;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type CourseCompletionSummaryAggregateSelection = {
  __typename?: "CourseCompletionSummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type CourseCompletionSummaryCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type CourseCompletionSummaryEdge = {
  __typename?: "CourseCompletionSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCompletionSummary;
};

export type CourseCompletionSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCompletionSummarySort objects to sort CourseCompletionSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCompletionSummarySort>>;
};

export type CourseCompletionSummaryResponse = {
  __typename?: "CourseCompletionSummaryResponse";
  count: Scalars["Int"]["output"];
  data: Array<CourseCompletionSummary>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseCompletionSummaryResponseAggregateSelection = {
  __typename?: "CourseCompletionSummaryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseCompletionSummaryResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseCompletionSummaryResponseEdge = {
  __typename?: "CourseCompletionSummaryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCompletionSummaryResponse;
};

export type CourseCompletionSummaryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCompletionSummaryResponseSort objects to sort CourseCompletionSummaryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCompletionSummaryResponseSort>>;
};

/** Fields to sort CourseCompletionSummaryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCompletionSummaryResponseSort object. */
export type CourseCompletionSummaryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseCompletionSummaryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionSummaryResponseWhere = {
  AND?: InputMaybe<Array<CourseCompletionSummaryResponseWhere>>;
  NOT?: InputMaybe<CourseCompletionSummaryResponseWhere>;
  OR?: InputMaybe<Array<CourseCompletionSummaryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCompletionSummaryResponsesConnection = {
  __typename?: "CourseCompletionSummaryResponsesConnection";
  edges: Array<CourseCompletionSummaryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort CourseCompletionSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCompletionSummarySort object. */
export type CourseCompletionSummarySort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type CourseCompletionSummaryUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCompletionSummaryWhere = {
  AND?: InputMaybe<Array<CourseCompletionSummaryWhere>>;
  NOT?: InputMaybe<CourseCompletionSummaryWhere>;
  OR?: InputMaybe<Array<CourseCompletionSummaryWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseConnectInput = {
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseConnectFieldInput>>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsConnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyConnectFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectFieldInput>>;
};

export type CourseConnectOrCreateInput = {
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseConnectOrCreateFieldInput>>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsConnectOrCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseConnectOrCreateFieldInput>>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyConnectOrCreateFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectOrCreateFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectOrCreateFieldInput>>;
};

export type CourseConnectOrCreateWhere = {
  node: CourseUniqueWhere;
};

export type CourseConnectWhere = {
  node: CourseWhere;
};

export type CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection = {
  __typename?: "CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection>;
};

export type CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection = {
  __typename?: "CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseCreateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseType?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  degreesHasCourse?: InputMaybe<CourseDegreesHasCourseFieldInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrolledStudents?: InputMaybe<CourseEnrolledStudentsFieldInput>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<CourseHasAssignmentAssignmentsFieldInput>;
  hasCourseCategoriesCourseCategories?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesFieldInput>;
  hasPagePages?: InputMaybe<CourseHasPagePagesFieldInput>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  isCustomCourse?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_elective?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  modules?: InputMaybe<CourseModulesFieldInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourse?: InputMaybe<CourseSchoolsHasCourseFieldInput>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  teachingFaculty?: InputMaybe<CourseTeachingFacultyFieldInput>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourse?: InputMaybe<CourseUsersHasSchoolCourseFieldInput>;
  usersHaveLearningPayment?: InputMaybe<CourseUsersHaveLearningPaymentFieldInput>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCreationResponse = {
  __typename?: "CourseCreationResponse";
  data?: Maybe<Curriculum>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseCreationResponseAggregateSelection = {
  __typename?: "CourseCreationResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type CourseCreationResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseCreationResponseEdge = {
  __typename?: "CourseCreationResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCreationResponse;
};

export type CourseCreationResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCreationResponseSort objects to sort CourseCreationResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCreationResponseSort>>;
};

/** Fields to sort CourseCreationResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCreationResponseSort object. */
export type CourseCreationResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseCreationResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCreationResponseWhere = {
  AND?: InputMaybe<Array<CourseCreationResponseWhere>>;
  NOT?: InputMaybe<CourseCreationResponseWhere>;
  OR?: InputMaybe<Array<CourseCreationResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCreationResponsesConnection = {
  __typename?: "CourseCreationResponsesConnection";
  edges: Array<CourseCreationResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseDegreeDegreesHasCourseAggregationSelection = {
  __typename?: "CourseDegreeDegreesHasCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseDegreeDegreesHasCourseNodeAggregateSelection>;
};

export type CourseDegreeDegreesHasCourseNodeAggregateSelection = {
  __typename?: "CourseDegreeDegreesHasCourseNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type CourseDegreesHasCourseAggregateInput = {
  AND?: InputMaybe<Array<CourseDegreesHasCourseAggregateInput>>;
  NOT?: InputMaybe<CourseDegreesHasCourseAggregateInput>;
  OR?: InputMaybe<Array<CourseDegreesHasCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseDegreesHasCourseNodeAggregationWhereInput>;
};

export type CourseDegreesHasCourseConnectFieldInput = {
  connect?: InputMaybe<Array<DegreeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DegreeConnectWhere>;
};

export type CourseDegreesHasCourseConnectOrCreateFieldInput = {
  onCreate: CourseDegreesHasCourseConnectOrCreateFieldInputOnCreate;
  where: DegreeConnectOrCreateWhere;
};

export type CourseDegreesHasCourseConnectOrCreateFieldInputOnCreate = {
  node: DegreeOnCreateInput;
};

export type CourseDegreesHasCourseConnection = {
  __typename?: "CourseDegreesHasCourseConnection";
  edges: Array<CourseDegreesHasCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseDegreesHasCourseConnectionSort = {
  node?: InputMaybe<DegreeSort>;
};

export type CourseDegreesHasCourseConnectionWhere = {
  AND?: InputMaybe<Array<CourseDegreesHasCourseConnectionWhere>>;
  NOT?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
  OR?: InputMaybe<Array<CourseDegreesHasCourseConnectionWhere>>;
  node?: InputMaybe<DegreeWhere>;
};

export type CourseDegreesHasCourseCreateFieldInput = {
  node: DegreeCreateInput;
};

export type CourseDegreesHasCourseDeleteFieldInput = {
  delete?: InputMaybe<DegreeDeleteInput>;
  where?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
};

export type CourseDegreesHasCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<DegreeDisconnectInput>;
  where?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
};

export type CourseDegreesHasCourseFieldInput = {
  connect?: InputMaybe<Array<CourseDegreesHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseDegreesHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseDegreesHasCourseCreateFieldInput>>;
};

export type CourseDegreesHasCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseDegreesHasCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseDegreesHasCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseDegreesHasCourseNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseDegreesHasCourseRelationship = {
  __typename?: "CourseDegreesHasCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: Degree;
};

export type CourseDegreesHasCourseUpdateConnectionInput = {
  node?: InputMaybe<DegreeUpdateInput>;
};

export type CourseDegreesHasCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseDegreesHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseDegreesHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseDegreesHasCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseDegreesHasCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseDegreesHasCourseDisconnectFieldInput>>;
  update?: InputMaybe<CourseDegreesHasCourseUpdateConnectionInput>;
  where?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
};

export type CourseDeleteInput = {
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseDeleteFieldInput>>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsDeleteFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsDeleteFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesDeleteFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesDeleteFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseDeleteFieldInput>>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyDeleteFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseDeleteFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentDeleteFieldInput>>;
};

export type CourseDetailedAnalytics = {
  __typename?: "CourseDetailedAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: CourseAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  enrollment: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
};

export type CourseDetailedAnalyticsAggregateSelection = {
  __typename?: "CourseDetailedAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  enrollment: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type CourseDetailedAnalyticsConnection = {
  __typename?: "CourseDetailedAnalyticsConnection";
  edges: Array<CourseDetailedAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseDetailedAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  enrollment: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
};

export type CourseDetailedAnalyticsEdge = {
  __typename?: "CourseDetailedAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseDetailedAnalytics;
};

export type CourseDetailedAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseDetailedAnalyticsSort objects to sort CourseDetailedAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseDetailedAnalyticsSort>>;
};

/** Fields to sort CourseDetailedAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseDetailedAnalyticsSort object. */
export type CourseDetailedAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enrollment?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type CourseDetailedAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseDetailedAnalyticsWhere = {
  AND?: InputMaybe<Array<CourseDetailedAnalyticsWhere>>;
  NOT?: InputMaybe<CourseDetailedAnalyticsWhere>;
  OR?: InputMaybe<Array<CourseDetailedAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  enrollment_LT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseDetailedResponse = {
  __typename?: "CourseDetailedResponse";
  count: Scalars["Int"]["output"];
  data: Array<CourseDetailedAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseDetailedResponseAggregateSelection = {
  __typename?: "CourseDetailedResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseDetailedResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseDetailedResponseEdge = {
  __typename?: "CourseDetailedResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseDetailedResponse;
};

export type CourseDetailedResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseDetailedResponseSort objects to sort CourseDetailedResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseDetailedResponseSort>>;
};

/** Fields to sort CourseDetailedResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseDetailedResponseSort object. */
export type CourseDetailedResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseDetailedResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseDetailedResponseWhere = {
  AND?: InputMaybe<Array<CourseDetailedResponseWhere>>;
  NOT?: InputMaybe<CourseDetailedResponseWhere>;
  OR?: InputMaybe<Array<CourseDetailedResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseDetailedResponsesConnection = {
  __typename?: "CourseDetailedResponsesConnection";
  edges: Array<CourseDetailedResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseDisconnectInput = {
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseDisconnectFieldInput>>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsDisconnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsDisconnectFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<
    Array<CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput>
  >;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesDisconnectFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesDisconnectFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseDisconnectFieldInput>>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyDisconnectFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseDisconnectFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentDisconnectFieldInput>>;
};

export type CourseEdge = {
  __typename?: "CourseEdge";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type CourseEnrolledStudentsAggregateInput = {
  AND?: InputMaybe<Array<CourseEnrolledStudentsAggregateInput>>;
  NOT?: InputMaybe<CourseEnrolledStudentsAggregateInput>;
  OR?: InputMaybe<Array<CourseEnrolledStudentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInPropertiesAggregationWhereInput>;
  node?: InputMaybe<CourseEnrolledStudentsNodeAggregationWhereInput>;
};

export type CourseEnrolledStudentsConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: EnrolledInPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type CourseEnrolledStudentsConnectOrCreateFieldInput = {
  onCreate: CourseEnrolledStudentsConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CourseEnrolledStudentsConnectOrCreateFieldInputOnCreate = {
  edge: EnrolledInPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type CourseEnrolledStudentsConnection = {
  __typename?: "CourseEnrolledStudentsConnection";
  edges: Array<CourseEnrolledStudentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseEnrolledStudentsConnectionSort = {
  edge?: InputMaybe<EnrolledInPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type CourseEnrolledStudentsConnectionWhere = {
  AND?: InputMaybe<Array<CourseEnrolledStudentsConnectionWhere>>;
  NOT?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
  OR?: InputMaybe<Array<CourseEnrolledStudentsConnectionWhere>>;
  edge?: InputMaybe<EnrolledInPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type CourseEnrolledStudentsCreateFieldInput = {
  edge: EnrolledInPropertiesCreateInput;
  node: UserCreateInput;
};

export type CourseEnrolledStudentsDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
};

export type CourseEnrolledStudentsDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
};

export type CourseEnrolledStudentsFieldInput = {
  connect?: InputMaybe<Array<CourseEnrolledStudentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseEnrolledStudentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseEnrolledStudentsCreateFieldInput>>;
};

export type CourseEnrolledStudentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseEnrolledStudentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseEnrolledStudentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseEnrolledStudentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseEnrolledStudentsRelationship = {
  __typename?: "CourseEnrolledStudentsRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: EnrolledInProperties;
};

export type CourseEnrolledStudentsUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type CourseEnrolledStudentsUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseEnrolledStudentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseEnrolledStudentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseEnrolledStudentsCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseEnrolledStudentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseEnrolledStudentsDisconnectFieldInput>>;
  update?: InputMaybe<CourseEnrolledStudentsUpdateConnectionInput>;
  where?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
};

export enum CourseGenerationStatus {
  Completed = "completed",
  Failed = "failed",
  GeneratingActivities = "generating_activities",
  GeneratingModules = "generating_modules",
  GeneratingStructure = "generating_structure",
  Processing = "processing",
}

export type CourseHasAssignmentAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsAggregateInput>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>;
};

export type CourseHasAssignmentAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type CourseHasAssignmentAssignmentsConnectOrCreateFieldInput = {
  onCreate: CourseHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type CourseHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type CourseHasAssignmentAssignmentsConnection = {
  __typename?: "CourseHasAssignmentAssignmentsConnection";
  edges: Array<CourseHasAssignmentAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasAssignmentAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type CourseHasAssignmentAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type CourseHasAssignmentAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasAssignmentAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasAssignmentAssignmentsFieldInput = {
  connect?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
};

export type CourseHasAssignmentAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasAssignmentAssignmentsRelationship = {
  __typename?: "CourseHasAssignmentAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type CourseHasAssignmentAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type CourseHasAssignmentAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasAssignmentAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasAssignmentAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasAssignmentAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesAggregateInput = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesAggregateInput>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesAggregateInput>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseCategoryConnectWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnection = {
  __typename?: "CourseHasCourseCategoriesCourseCategoriesConnection";
  edges: Array<CourseHasCourseCategoriesCourseCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionSort = {
  node?: InputMaybe<CourseCategorySort>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>>;
  node?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesCreateFieldInput = {
  node: CourseCategoryCreateInput;
};

export type CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseCategoryDisconnectInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesFieldInput = {
  connect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  create?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
};

export type CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasCourseCategoriesCourseCategoriesRelationship = {
  __typename?: "CourseHasCourseCategoriesCourseCategoriesRelationship";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type CourseHasCourseCategoriesCourseCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CourseCategoryUpdateInput>;
};

export type CourseHasCourseCategoriesCourseCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  create?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesUpdateConnectionInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasPagePagesAggregateInput = {
  AND?: InputMaybe<Array<CourseHasPagePagesAggregateInput>>;
  NOT?: InputMaybe<CourseHasPagePagesAggregateInput>;
  OR?: InputMaybe<Array<CourseHasPagePagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasPagePagesNodeAggregationWhereInput>;
};

export type CourseHasPagePagesConnectFieldInput = {
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PageConnectWhere>;
};

export type CourseHasPagePagesConnectOrCreateFieldInput = {
  onCreate: CourseHasPagePagesConnectOrCreateFieldInputOnCreate;
  where: PageConnectOrCreateWhere;
};

export type CourseHasPagePagesConnectOrCreateFieldInputOnCreate = {
  node: PageOnCreateInput;
};

export type CourseHasPagePagesConnection = {
  __typename?: "CourseHasPagePagesConnection";
  edges: Array<CourseHasPagePagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasPagePagesConnectionSort = {
  node?: InputMaybe<PageSort>;
};

export type CourseHasPagePagesConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasPagePagesConnectionWhere>>;
  NOT?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasPagePagesConnectionWhere>>;
  node?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesCreateFieldInput = {
  node: PageCreateInput;
};

export type CourseHasPagePagesDeleteFieldInput = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseHasPagePagesDisconnectFieldInput = {
  disconnect?: InputMaybe<PageDisconnectInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseHasPagePagesFieldInput = {
  connect?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
};

export type CourseHasPagePagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasPagePagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasPagePagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasPagePagesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasPagePagesRelationship = {
  __typename?: "CourseHasPagePagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Page;
};

export type CourseHasPagePagesUpdateConnectionInput = {
  node?: InputMaybe<PageUpdateInput>;
};

export type CourseHasPagePagesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasPagePagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasPagePagesDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasPagePagesUpdateConnectionInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseHierarchyResponse = {
  __typename?: "CourseHierarchyResponse";
  count: Scalars["Int"]["output"];
  data: Array<HierarchyCourse>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseHierarchyResponseAggregateSelection = {
  __typename?: "CourseHierarchyResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseHierarchyResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseHierarchyResponseEdge = {
  __typename?: "CourseHierarchyResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseHierarchyResponse;
};

export type CourseHierarchyResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseHierarchyResponseSort objects to sort CourseHierarchyResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseHierarchyResponseSort>>;
};

/** Fields to sort CourseHierarchyResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseHierarchyResponseSort object. */
export type CourseHierarchyResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseHierarchyResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseHierarchyResponseWhere = {
  AND?: InputMaybe<Array<CourseHierarchyResponseWhere>>;
  NOT?: InputMaybe<CourseHierarchyResponseWhere>;
  OR?: InputMaybe<Array<CourseHierarchyResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseHierarchyResponsesConnection = {
  __typename?: "CourseHierarchyResponsesConnection";
  edges: Array<CourseHierarchyResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseLearningPaymentUsersHaveLearningPaymentAggregationSelection = {
  __typename?: "CourseLearningPaymentUsersHaveLearningPaymentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseLearningPaymentUsersHaveLearningPaymentNodeAggregateSelection>;
};

export type CourseLearningPaymentUsersHaveLearningPaymentNodeAggregateSelection = {
  __typename?: "CourseLearningPaymentUsersHaveLearningPaymentNodeAggregateSelection";
  amount: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type CourseModuleModulesAggregationSelection = {
  __typename?: "CourseModuleModulesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseModuleModulesNodeAggregateSelection>;
};

export type CourseModuleModulesNodeAggregateSelection = {
  __typename?: "CourseModuleModulesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseModulesAggregateInput = {
  AND?: InputMaybe<Array<CourseModulesAggregateInput>>;
  NOT?: InputMaybe<CourseModulesAggregateInput>;
  OR?: InputMaybe<Array<CourseModulesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseModulesNodeAggregationWhereInput>;
};

export type CourseModulesConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type CourseModulesConnectOrCreateFieldInput = {
  onCreate: CourseModulesConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type CourseModulesConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type CourseModulesConnection = {
  __typename?: "CourseModulesConnection";
  edges: Array<CourseModulesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseModulesConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type CourseModulesConnectionWhere = {
  AND?: InputMaybe<Array<CourseModulesConnectionWhere>>;
  NOT?: InputMaybe<CourseModulesConnectionWhere>;
  OR?: InputMaybe<Array<CourseModulesConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type CourseModulesCreateFieldInput = {
  node: ModuleCreateInput;
};

export type CourseModulesDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseModulesDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseModulesFieldInput = {
  connect?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
};

export type CourseModulesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseModulesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseModulesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseModulesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseModulesRelationship = {
  __typename?: "CourseModulesRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type CourseModulesUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type CourseModulesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseModulesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseModulesDisconnectFieldInput>>;
  update?: InputMaybe<CourseModulesUpdateConnectionInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseOnCreateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseType?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  isCustomCourse?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_elective?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseSort objects to sort Courses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseSort>>;
};

export type CoursePageHasPagePagesAggregationSelection = {
  __typename?: "CoursePageHasPagePagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CoursePageHasPagePagesNodeAggregateSelection>;
};

export type CoursePageHasPagePagesNodeAggregateSelection = {
  __typename?: "CoursePageHasPagePagesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type CourseProgressAnalytics = {
  __typename?: "CourseProgressAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: CourseProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  student_id?: Maybe<Scalars["ID"]["output"]>;
  student_name?: Maybe<Scalars["String"]["output"]>;
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type CourseProgressAnalyticsAggregateSelection = {
  __typename?: "CourseProgressAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  student_id: IdAggregateSelection;
  student_name: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type CourseProgressAnalyticsConnection = {
  __typename?: "CourseProgressAnalyticsConnection";
  edges: Array<CourseProgressAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseProgressAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CourseProgressAnalyticsEdge = {
  __typename?: "CourseProgressAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseProgressAnalytics;
};

export type CourseProgressAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseProgressAnalyticsSort objects to sort CourseProgressAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseProgressAnalyticsSort>>;
};

/** Fields to sort CourseProgressAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseProgressAnalyticsSort object. */
export type CourseProgressAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  student_id?: InputMaybe<SortDirection>;
  student_name?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type CourseProgressAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CourseProgressAnalyticsWhere = {
  AND?: InputMaybe<Array<CourseProgressAnalyticsWhere>>;
  NOT?: InputMaybe<CourseProgressAnalyticsWhere>;
  OR?: InputMaybe<Array<CourseProgressAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  student_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  student_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  student_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  student_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CourseProgressDetails = {
  __typename?: "CourseProgressDetails";
  modules: Array<ModuleProgressAnalytics>;
};

export type CourseProgressDetailsAggregateSelection = {
  __typename?: "CourseProgressDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type CourseProgressDetailsConnection = {
  __typename?: "CourseProgressDetailsConnection";
  edges: Array<CourseProgressDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseProgressDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseProgressDetailsEdge = {
  __typename?: "CourseProgressDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: CourseProgressDetails;
};

export type CourseProgressDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseProgressDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseProgressDetailsWhere = {
  AND?: InputMaybe<Array<CourseProgressDetailsWhere>>;
  NOT?: InputMaybe<CourseProgressDetailsWhere>;
  OR?: InputMaybe<Array<CourseProgressDetailsWhere>>;
};

export type CourseRelationInput = {
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseCreateFieldInput>>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyCreateFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentCreateFieldInput>>;
};

export type CourseResponse = {
  __typename?: "CourseResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Course>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CourseResponseAggregateSelection = {
  __typename?: "CourseResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponseEdge = {
  __typename?: "CourseResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseResponse;
};

export type CourseResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseResponseSort objects to sort CourseResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseResponseSort>>;
};

/** Fields to sort CourseResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseResponseSort object. */
export type CourseResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponseWhere = {
  AND?: InputMaybe<Array<CourseResponseWhere>>;
  NOT?: InputMaybe<CourseResponseWhere>;
  OR?: InputMaybe<Array<CourseResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponsesConnection = {
  __typename?: "CourseResponsesConnection";
  edges: Array<CourseResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseRosterData = {
  __typename?: "CourseRosterData";
  email: Scalars["String"]["output"];
  enrollmentDate: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  pendingCourseEnrollmentInvite?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CourseRosterDataAggregateSelection = {
  __typename?: "CourseRosterDataAggregateSelection";
  count: Scalars["Int"]["output"];
  email: StringAggregateSelection;
  enrollmentDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
};

export type CourseRosterDataConnection = {
  __typename?: "CourseRosterDataConnection";
  edges: Array<CourseRosterDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseRosterDataCreateInput = {
  email: Scalars["String"]["input"];
  enrollmentDate: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterDataEdge = {
  __typename?: "CourseRosterDataEdge";
  cursor: Scalars["String"]["output"];
  node: CourseRosterData;
};

export type CourseRosterDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseRosterDataSort objects to sort CourseRosterData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseRosterDataSort>>;
};

/** Fields to sort CourseRosterData by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseRosterDataSort object. */
export type CourseRosterDataSort = {
  email?: InputMaybe<SortDirection>;
  enrollmentDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  pendingCourseEnrollmentInvite?: InputMaybe<SortDirection>;
};

export type CourseRosterDataUpdateInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrollmentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterDataWhere = {
  AND?: InputMaybe<Array<CourseRosterDataWhere>>;
  NOT?: InputMaybe<CourseRosterDataWhere>;
  OR?: InputMaybe<Array<CourseRosterDataWhere>>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrollmentDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  enrollmentDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  enrollmentDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  enrollmentDate_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  enrollmentDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  enrollmentDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterServiceResult = {
  __typename?: "CourseRosterServiceResult";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<CourseRosterData>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CourseRosterServiceResultAggregateSelection = {
  __typename?: "CourseRosterServiceResultAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseRosterServiceResultCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterServiceResultEdge = {
  __typename?: "CourseRosterServiceResultEdge";
  cursor: Scalars["String"]["output"];
  node: CourseRosterServiceResult;
};

export type CourseRosterServiceResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseRosterServiceResultSort objects to sort CourseRosterServiceResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseRosterServiceResultSort>>;
};

/** Fields to sort CourseRosterServiceResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseRosterServiceResultSort object. */
export type CourseRosterServiceResultSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseRosterServiceResultUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterServiceResultWhere = {
  AND?: InputMaybe<Array<CourseRosterServiceResultWhere>>;
  NOT?: InputMaybe<CourseRosterServiceResultWhere>;
  OR?: InputMaybe<Array<CourseRosterServiceResultWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseRosterServiceResultsConnection = {
  __typename?: "CourseRosterServiceResultsConnection";
  edges: Array<CourseRosterServiceResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseSchoolSchoolsHasCourseAggregationSelection = {
  __typename?: "CourseSchoolSchoolsHasCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseSchoolSchoolsHasCourseNodeAggregateSelection>;
};

export type CourseSchoolSchoolsHasCourseNodeAggregateSelection = {
  __typename?: "CourseSchoolSchoolsHasCourseNodeAggregateSelection";
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type CourseSchoolsHasCourseAggregateInput = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseAggregateInput>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseAggregateInput>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseSchoolsHasCourseNodeAggregationWhereInput>;
};

export type CourseSchoolsHasCourseConnectFieldInput = {
  connect?: InputMaybe<Array<SchoolConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SchoolConnectWhere>;
};

export type CourseSchoolsHasCourseConnectOrCreateFieldInput = {
  onCreate: CourseSchoolsHasCourseConnectOrCreateFieldInputOnCreate;
  where: SchoolConnectOrCreateWhere;
};

export type CourseSchoolsHasCourseConnectOrCreateFieldInputOnCreate = {
  node: SchoolOnCreateInput;
};

export type CourseSchoolsHasCourseConnection = {
  __typename?: "CourseSchoolsHasCourseConnection";
  edges: Array<CourseSchoolsHasCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseSchoolsHasCourseConnectionSort = {
  node?: InputMaybe<SchoolSort>;
};

export type CourseSchoolsHasCourseConnectionWhere = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseConnectionWhere>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseConnectionWhere>>;
  node?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseCreateFieldInput = {
  node: SchoolCreateInput;
};

export type CourseSchoolsHasCourseDeleteFieldInput = {
  delete?: InputMaybe<SchoolDeleteInput>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseSchoolsHasCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<SchoolDisconnectInput>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseSchoolsHasCourseFieldInput = {
  connect?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseSchoolsHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
};

export type CourseSchoolsHasCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseNodeAggregationWhereInput>>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseSchoolsHasCourseRelationship = {
  __typename?: "CourseSchoolsHasCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: School;
};

export type CourseSchoolsHasCourseUpdateConnectionInput = {
  node?: InputMaybe<SchoolUpdateInput>;
};

export type CourseSchoolsHasCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseSchoolsHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseSchoolsHasCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseSchoolsHasCourseDisconnectFieldInput>>;
  update?: InputMaybe<CourseSchoolsHasCourseUpdateConnectionInput>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

/** Fields to sort Courses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseSort object. */
export type CourseSort = {
  account_id?: InputMaybe<SortDirection>;
  apply_assignment_group_weights?: InputMaybe<SortDirection>;
  blueprint?: InputMaybe<SortDirection>;
  calendar_ics?: InputMaybe<SortDirection>;
  cost?: InputMaybe<SortDirection>;
  courseId?: InputMaybe<SortDirection>;
  courseType?: InputMaybe<SortDirection>;
  course_code?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  default_view?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  end_at?: InputMaybe<SortDirection>;
  enrollment_term_id?: InputMaybe<SortDirection>;
  generationStatus?: InputMaybe<SortDirection>;
  grading_standard_id?: InputMaybe<SortDirection>;
  hide_final_grades?: InputMaybe<SortDirection>;
  homeroom_course?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  integration_id?: InputMaybe<SortDirection>;
  isCustomCourse?: InputMaybe<SortDirection>;
  isDraft?: InputMaybe<SortDirection>;
  is_elective?: InputMaybe<SortDirection>;
  is_public?: InputMaybe<SortDirection>;
  is_public_to_auth_users?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  learningStandards?: InputMaybe<SortDirection>;
  lengthInWeeks?: InputMaybe<SortDirection>;
  license?: InputMaybe<SortDirection>;
  lti_id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  original_name?: InputMaybe<SortDirection>;
  public_syllabus?: InputMaybe<SortDirection>;
  public_syllabus_to_auth?: InputMaybe<SortDirection>;
  restrict_enrollments_to_course_dates?: InputMaybe<SortDirection>;
  root_account_id?: InputMaybe<SortDirection>;
  sis_course_id?: InputMaybe<SortDirection>;
  sis_import_id?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  start_at?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  storage_quota_mb?: InputMaybe<SortDirection>;
  stripePriceId?: InputMaybe<SortDirection>;
  stripeProductId?: InputMaybe<SortDirection>;
  targetAudience?: InputMaybe<SortDirection>;
  template?: InputMaybe<SortDirection>;
  thumbnail?: InputMaybe<SortDirection>;
  time_zone?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  uuid?: InputMaybe<SortDirection>;
  workflow_state?: InputMaybe<SortDirection>;
};

export type CourseSummariesConnection = {
  __typename?: "CourseSummariesConnection";
  edges: Array<CourseSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseSummary = {
  __typename?: "CourseSummary";
  active_interaction_count: Scalars["Int"]["output"];
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  distractions: Scalars["Int"]["output"];
  enrollment: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
};

export type CourseSummaryAggregateSelection = {
  __typename?: "CourseSummaryAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  enrollment: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type CourseSummaryCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  enrollment: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
};

export type CourseSummaryEdge = {
  __typename?: "CourseSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: CourseSummary;
};

export type CourseSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseSummarySort objects to sort CourseSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseSummarySort>>;
};

/** Fields to sort CourseSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseSummarySort object. */
export type CourseSummarySort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enrollment?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type CourseSummaryUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseSummaryWhere = {
  AND?: InputMaybe<Array<CourseSummaryWhere>>;
  NOT?: InputMaybe<CourseSummaryWhere>;
  OR?: InputMaybe<Array<CourseSummaryWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  enrollment_LT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseTeachingFacultyAggregateInput = {
  AND?: InputMaybe<Array<CourseTeachingFacultyAggregateInput>>;
  NOT?: InputMaybe<CourseTeachingFacultyAggregateInput>;
  OR?: InputMaybe<Array<CourseTeachingFacultyAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseTeachingFacultyNodeAggregationWhereInput>;
};

export type CourseTeachingFacultyConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type CourseTeachingFacultyConnectOrCreateFieldInput = {
  onCreate: CourseTeachingFacultyConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CourseTeachingFacultyConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type CourseTeachingFacultyConnection = {
  __typename?: "CourseTeachingFacultyConnection";
  edges: Array<CourseTeachingFacultyRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseTeachingFacultyConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CourseTeachingFacultyConnectionWhere = {
  AND?: InputMaybe<Array<CourseTeachingFacultyConnectionWhere>>;
  NOT?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
  OR?: InputMaybe<Array<CourseTeachingFacultyConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CourseTeachingFacultyCreateFieldInput = {
  node: UserCreateInput;
};

export type CourseTeachingFacultyDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
};

export type CourseTeachingFacultyDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
};

export type CourseTeachingFacultyFieldInput = {
  connect?: InputMaybe<Array<CourseTeachingFacultyConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseTeachingFacultyConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseTeachingFacultyCreateFieldInput>>;
};

export type CourseTeachingFacultyNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseTeachingFacultyNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseTeachingFacultyNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseTeachingFacultyNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseTeachingFacultyRelationship = {
  __typename?: "CourseTeachingFacultyRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type CourseTeachingFacultyUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CourseTeachingFacultyUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseTeachingFacultyConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseTeachingFacultyConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseTeachingFacultyCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseTeachingFacultyDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseTeachingFacultyDisconnectFieldInput>>;
  update?: InputMaybe<CourseTeachingFacultyUpdateConnectionInput>;
  where?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
};

export type CourseUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CourseUpdateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  cost_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  cost_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseType?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  degreesHasCourse?: InputMaybe<Array<CourseDegreesHasCourseUpdateFieldInput>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrolledStudents?: InputMaybe<Array<CourseEnrolledStudentsUpdateFieldInput>>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsUpdateFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesUpdateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesUpdateFieldInput>>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  isCustomCourse?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_elective?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  modules?: InputMaybe<Array<CourseModulesUpdateFieldInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  period_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  period_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods_POP?: InputMaybe<Scalars["Int"]["input"]>;
  periods_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseUpdateFieldInput>>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  teachingFaculty?: InputMaybe<Array<CourseTeachingFacultyUpdateFieldInput>>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseUpdateFieldInput>>;
  usersHaveLearningPayment?: InputMaybe<Array<CourseUsersHaveLearningPaymentUpdateFieldInput>>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseUserEnrolledStudentsAggregationSelection = {
  __typename?: "CourseUserEnrolledStudentsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<CourseUserEnrolledStudentsEdgeAggregateSelection>;
  node?: Maybe<CourseUserEnrolledStudentsNodeAggregateSelection>;
};

export type CourseUserEnrolledStudentsEdgeAggregateSelection = {
  __typename?: "CourseUserEnrolledStudentsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type CourseUserEnrolledStudentsNodeAggregateSelection = {
  __typename?: "CourseUserEnrolledStudentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type CourseUserTeachingFacultyAggregationSelection = {
  __typename?: "CourseUserTeachingFacultyAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseUserTeachingFacultyNodeAggregateSelection>;
};

export type CourseUserTeachingFacultyNodeAggregateSelection = {
  __typename?: "CourseUserTeachingFacultyNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type CourseUserUsersHasSchoolCourseAggregationSelection = {
  __typename?: "CourseUserUsersHasSchoolCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseUserUsersHasSchoolCourseNodeAggregateSelection>;
};

export type CourseUserUsersHasSchoolCourseNodeAggregateSelection = {
  __typename?: "CourseUserUsersHasSchoolCourseNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type CourseUsersHasSchoolCourseAggregateInput = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseAggregateInput>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseAggregateInput>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseUsersHasSchoolCourseNodeAggregationWhereInput>;
};

export type CourseUsersHasSchoolCourseConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type CourseUsersHasSchoolCourseConnectOrCreateFieldInput = {
  onCreate: CourseUsersHasSchoolCourseConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type CourseUsersHasSchoolCourseConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type CourseUsersHasSchoolCourseConnection = {
  __typename?: "CourseUsersHasSchoolCourseConnection";
  edges: Array<CourseUsersHasSchoolCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseUsersHasSchoolCourseConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CourseUsersHasSchoolCourseConnectionWhere = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionWhere>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseCreateFieldInput = {
  node: UserCreateInput;
};

export type CourseUsersHasSchoolCourseDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHasSchoolCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHasSchoolCourseFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
};

export type CourseUsersHasSchoolCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseUsersHasSchoolCourseRelationship = {
  __typename?: "CourseUsersHasSchoolCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type CourseUsersHasSchoolCourseUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CourseUsersHasSchoolCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseUsersHasSchoolCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseUsersHasSchoolCourseDisconnectFieldInput>>;
  update?: InputMaybe<CourseUsersHasSchoolCourseUpdateConnectionInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHaveLearningPaymentAggregateInput = {
  AND?: InputMaybe<Array<CourseUsersHaveLearningPaymentAggregateInput>>;
  NOT?: InputMaybe<CourseUsersHaveLearningPaymentAggregateInput>;
  OR?: InputMaybe<Array<CourseUsersHaveLearningPaymentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseUsersHaveLearningPaymentNodeAggregationWhereInput>;
};

export type CourseUsersHaveLearningPaymentConnectFieldInput = {
  connect?: InputMaybe<Array<LearningPaymentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<LearningPaymentConnectWhere>;
};

export type CourseUsersHaveLearningPaymentConnectOrCreateFieldInput = {
  onCreate: CourseUsersHaveLearningPaymentConnectOrCreateFieldInputOnCreate;
  where: LearningPaymentConnectOrCreateWhere;
};

export type CourseUsersHaveLearningPaymentConnectOrCreateFieldInputOnCreate = {
  node: LearningPaymentOnCreateInput;
};

export type CourseUsersHaveLearningPaymentConnection = {
  __typename?: "CourseUsersHaveLearningPaymentConnection";
  edges: Array<CourseUsersHaveLearningPaymentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseUsersHaveLearningPaymentConnectionSort = {
  node?: InputMaybe<LearningPaymentSort>;
};

export type CourseUsersHaveLearningPaymentConnectionWhere = {
  AND?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectionWhere>>;
  NOT?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
  OR?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectionWhere>>;
  node?: InputMaybe<LearningPaymentWhere>;
};

export type CourseUsersHaveLearningPaymentCreateFieldInput = {
  node: LearningPaymentCreateInput;
};

export type CourseUsersHaveLearningPaymentDeleteFieldInput = {
  delete?: InputMaybe<LearningPaymentDeleteInput>;
  where?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
};

export type CourseUsersHaveLearningPaymentDisconnectFieldInput = {
  disconnect?: InputMaybe<LearningPaymentDisconnectInput>;
  where?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
};

export type CourseUsersHaveLearningPaymentFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHaveLearningPaymentCreateFieldInput>>;
};

export type CourseUsersHaveLearningPaymentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseUsersHaveLearningPaymentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseUsersHaveLearningPaymentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseUsersHaveLearningPaymentNodeAggregationWhereInput>>;
  amount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CourseUsersHaveLearningPaymentRelationship = {
  __typename?: "CourseUsersHaveLearningPaymentRelationship";
  cursor: Scalars["String"]["output"];
  node: LearningPayment;
};

export type CourseUsersHaveLearningPaymentUpdateConnectionInput = {
  node?: InputMaybe<LearningPaymentUpdateInput>;
};

export type CourseUsersHaveLearningPaymentUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseUsersHaveLearningPaymentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHaveLearningPaymentCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseUsersHaveLearningPaymentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseUsersHaveLearningPaymentDisconnectFieldInput>>;
  update?: InputMaybe<CourseUsersHaveLearningPaymentUpdateConnectionInput>;
  where?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
};

export type CourseWhere = {
  AND?: InputMaybe<Array<CourseWhere>>;
  NOT?: InputMaybe<CourseWhere>;
  OR?: InputMaybe<Array<CourseWhere>>;
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  account_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  calendar_ics_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  cost_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  cost_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseType?: InputMaybe<Scalars["String"]["input"]>;
  courseType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_code_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_code_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_code_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_code_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  default_view_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  default_view_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  default_view_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  default_view_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  degreesHasCourseAggregate?: InputMaybe<CourseDegreesHasCourseAggregateInput>;
  /** Return Courses where all of the related CourseDegreesHasCourseConnections match this filter */
  degreesHasCourseConnection_ALL?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
  /** Return Courses where none of the related CourseDegreesHasCourseConnections match this filter */
  degreesHasCourseConnection_NONE?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
  /** Return Courses where one of the related CourseDegreesHasCourseConnections match this filter */
  degreesHasCourseConnection_SINGLE?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
  /** Return Courses where some of the related CourseDegreesHasCourseConnections match this filter */
  degreesHasCourseConnection_SOME?: InputMaybe<CourseDegreesHasCourseConnectionWhere>;
  /** Return Courses where all of the related Degrees match this filter */
  degreesHasCourse_ALL?: InputMaybe<DegreeWhere>;
  /** Return Courses where none of the related Degrees match this filter */
  degreesHasCourse_NONE?: InputMaybe<DegreeWhere>;
  /** Return Courses where one of the related Degrees match this filter */
  degreesHasCourse_SINGLE?: InputMaybe<DegreeWhere>;
  /** Return Courses where some of the related Degrees match this filter */
  degreesHasCourse_SOME?: InputMaybe<DegreeWhere>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  end_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  end_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  end_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrolledStudentsAggregate?: InputMaybe<CourseEnrolledStudentsAggregateInput>;
  /** Return Courses where all of the related CourseEnrolledStudentsConnections match this filter */
  enrolledStudentsConnection_ALL?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
  /** Return Courses where none of the related CourseEnrolledStudentsConnections match this filter */
  enrolledStudentsConnection_NONE?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
  /** Return Courses where one of the related CourseEnrolledStudentsConnections match this filter */
  enrolledStudentsConnection_SINGLE?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
  /** Return Courses where some of the related CourseEnrolledStudentsConnections match this filter */
  enrolledStudentsConnection_SOME?: InputMaybe<CourseEnrolledStudentsConnectionWhere>;
  /** Return Courses where all of the related Users match this filter */
  enrolledStudents_ALL?: InputMaybe<UserWhere>;
  /** Return Courses where none of the related Users match this filter */
  enrolledStudents_NONE?: InputMaybe<UserWhere>;
  /** Return Courses where one of the related Users match this filter */
  enrolledStudents_SINGLE?: InputMaybe<UserWhere>;
  /** Return Courses where some of the related Users match this filter */
  enrolledStudents_SOME?: InputMaybe<UserWhere>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  enrollment_term_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  generationStatus_IN?: InputMaybe<Array<InputMaybe<CourseGenerationStatus>>>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grading_standard_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignmentsAggregate?: InputMaybe<CourseHasAssignmentAssignmentsAggregateInput>;
  /** Return Courses where all of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_ALL?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where none of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_NONE?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where one of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SINGLE?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where some of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SOME?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where all of the related Assignments match this filter */
  hasAssignmentAssignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Courses where none of the related Assignments match this filter */
  hasAssignmentAssignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Courses where one of the related Assignments match this filter */
  hasAssignmentAssignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Courses where some of the related Assignments match this filter */
  hasAssignmentAssignments_SOME?: InputMaybe<AssignmentWhere>;
  hasCourseCategoriesCourseCategoriesAggregate?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesAggregateInput>;
  /** Return Courses where all of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_ALL?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where none of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_NONE?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where one of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_SINGLE?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where some of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_SOME?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where all of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_ALL?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where none of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_NONE?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where one of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_SINGLE?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where some of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_SOME?: InputMaybe<CourseCategoryWhere>;
  hasPagePagesAggregate?: InputMaybe<CourseHasPagePagesAggregateInput>;
  /** Return Courses where all of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_ALL?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where none of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_NONE?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where one of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_SINGLE?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where some of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_SOME?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where all of the related Pages match this filter */
  hasPagePages_ALL?: InputMaybe<PageWhere>;
  /** Return Courses where none of the related Pages match this filter */
  hasPagePages_NONE?: InputMaybe<PageWhere>;
  /** Return Courses where one of the related Pages match this filter */
  hasPagePages_SINGLE?: InputMaybe<PageWhere>;
  /** Return Courses where some of the related Pages match this filter */
  hasPagePages_SOME?: InputMaybe<PageWhere>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integration_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isCustomCourse?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_elective?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStandards_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  lengthInWeeks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  license_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  license_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  license_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  license_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lti_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modulesAggregate?: InputMaybe<CourseModulesAggregateInput>;
  /** Return Courses where all of the related CourseModulesConnections match this filter */
  modulesConnection_ALL?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where none of the related CourseModulesConnections match this filter */
  modulesConnection_NONE?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where one of the related CourseModulesConnections match this filter */
  modulesConnection_SINGLE?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where some of the related CourseModulesConnections match this filter */
  modulesConnection_SOME?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where all of the related Modules match this filter */
  modules_ALL?: InputMaybe<ModuleWhere>;
  /** Return Courses where none of the related Modules match this filter */
  modules_NONE?: InputMaybe<ModuleWhere>;
  /** Return Courses where one of the related Modules match this filter */
  modules_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return Courses where some of the related Modules match this filter */
  modules_SOME?: InputMaybe<ModuleWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  original_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  original_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  original_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  original_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  period_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  root_account_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourseAggregate?: InputMaybe<CourseSchoolsHasCourseAggregateInput>;
  /** Return Courses where all of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_ALL?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where none of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_NONE?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where one of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_SINGLE?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where some of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_SOME?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where all of the related Schools match this filter */
  schoolsHasCourse_ALL?: InputMaybe<SchoolWhere>;
  /** Return Courses where none of the related Schools match this filter */
  schoolsHasCourse_NONE?: InputMaybe<SchoolWhere>;
  /** Return Courses where one of the related Schools match this filter */
  schoolsHasCourse_SINGLE?: InputMaybe<SchoolWhere>;
  /** Return Courses where some of the related Schools match this filter */
  schoolsHasCourse_SOME?: InputMaybe<SchoolWhere>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sis_course_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sis_import_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  start_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  start_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  start_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  storage_quota_mb_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId?: InputMaybe<Scalars["String"]["input"]>;
  stripePriceId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripePriceId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripePriceId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripePriceId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeProductId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeProductId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  targetAudience_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teachingFacultyAggregate?: InputMaybe<CourseTeachingFacultyAggregateInput>;
  /** Return Courses where all of the related CourseTeachingFacultyConnections match this filter */
  teachingFacultyConnection_ALL?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
  /** Return Courses where none of the related CourseTeachingFacultyConnections match this filter */
  teachingFacultyConnection_NONE?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
  /** Return Courses where one of the related CourseTeachingFacultyConnections match this filter */
  teachingFacultyConnection_SINGLE?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
  /** Return Courses where some of the related CourseTeachingFacultyConnections match this filter */
  teachingFacultyConnection_SOME?: InputMaybe<CourseTeachingFacultyConnectionWhere>;
  /** Return Courses where all of the related Users match this filter */
  teachingFaculty_ALL?: InputMaybe<UserWhere>;
  /** Return Courses where none of the related Users match this filter */
  teachingFaculty_NONE?: InputMaybe<UserWhere>;
  /** Return Courses where one of the related Users match this filter */
  teachingFaculty_SINGLE?: InputMaybe<UserWhere>;
  /** Return Courses where some of the related Users match this filter */
  teachingFaculty_SOME?: InputMaybe<UserWhere>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  thumbnail?: InputMaybe<Scalars["String"]["input"]>;
  thumbnail_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  thumbnail_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  thumbnail_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  thumbnail_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  time_zone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourseAggregate?: InputMaybe<CourseUsersHasSchoolCourseAggregateInput>;
  /** Return Courses where all of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_ALL?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where none of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_NONE?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where one of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_SINGLE?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where some of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_SOME?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where all of the related Users match this filter */
  usersHasSchoolCourse_ALL?: InputMaybe<UserWhere>;
  /** Return Courses where none of the related Users match this filter */
  usersHasSchoolCourse_NONE?: InputMaybe<UserWhere>;
  /** Return Courses where one of the related Users match this filter */
  usersHasSchoolCourse_SINGLE?: InputMaybe<UserWhere>;
  /** Return Courses where some of the related Users match this filter */
  usersHasSchoolCourse_SOME?: InputMaybe<UserWhere>;
  usersHaveLearningPaymentAggregate?: InputMaybe<CourseUsersHaveLearningPaymentAggregateInput>;
  /** Return Courses where all of the related CourseUsersHaveLearningPaymentConnections match this filter */
  usersHaveLearningPaymentConnection_ALL?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
  /** Return Courses where none of the related CourseUsersHaveLearningPaymentConnections match this filter */
  usersHaveLearningPaymentConnection_NONE?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
  /** Return Courses where one of the related CourseUsersHaveLearningPaymentConnections match this filter */
  usersHaveLearningPaymentConnection_SINGLE?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
  /** Return Courses where some of the related CourseUsersHaveLearningPaymentConnections match this filter */
  usersHaveLearningPaymentConnection_SOME?: InputMaybe<CourseUsersHaveLearningPaymentConnectionWhere>;
  /** Return Courses where all of the related LearningPayments match this filter */
  usersHaveLearningPayment_ALL?: InputMaybe<LearningPaymentWhere>;
  /** Return Courses where none of the related LearningPayments match this filter */
  usersHaveLearningPayment_NONE?: InputMaybe<LearningPaymentWhere>;
  /** Return Courses where one of the related LearningPayments match this filter */
  usersHaveLearningPayment_SINGLE?: InputMaybe<LearningPaymentWhere>;
  /** Return Courses where some of the related LearningPayments match this filter */
  usersHaveLearningPayment_SOME?: InputMaybe<LearningPaymentWhere>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  uuid_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  uuid_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  uuid_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuid_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workflow_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoursesByQueryData = {
  __typename?: "CoursesByQueryData";
  courses?: Maybe<Array<Maybe<Course>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type CoursesByQueryDataAggregateSelection = {
  __typename?: "CoursesByQueryDataAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCount: IntAggregateSelection;
};

export type CoursesByQueryDataConnection = {
  __typename?: "CoursesByQueryDataConnection";
  edges: Array<CoursesByQueryDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CoursesByQueryDataCreateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CoursesByQueryDataEdge = {
  __typename?: "CoursesByQueryDataEdge";
  cursor: Scalars["String"]["output"];
  node: CoursesByQueryData;
};

export type CoursesByQueryDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CoursesByQueryDataSort objects to sort CoursesByQueryData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CoursesByQueryDataSort>>;
};

/** Fields to sort CoursesByQueryData by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoursesByQueryDataSort object. */
export type CoursesByQueryDataSort = {
  totalCount?: InputMaybe<SortDirection>;
};

export type CoursesByQueryDataUpdateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CoursesByQueryDataWhere = {
  AND?: InputMaybe<Array<CoursesByQueryDataWhere>>;
  NOT?: InputMaybe<CoursesByQueryDataWhere>;
  OR?: InputMaybe<Array<CoursesByQueryDataWhere>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CoursesByQueryResponse = {
  __typename?: "CoursesByQueryResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<CoursesByQueryData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CoursesByQueryResponseAggregateSelection = {
  __typename?: "CoursesByQueryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CoursesByQueryResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CoursesByQueryResponseEdge = {
  __typename?: "CoursesByQueryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CoursesByQueryResponse;
};

export type CoursesByQueryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CoursesByQueryResponseSort objects to sort CoursesByQueryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CoursesByQueryResponseSort>>;
};

/** Fields to sort CoursesByQueryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoursesByQueryResponseSort object. */
export type CoursesByQueryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CoursesByQueryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CoursesByQueryResponseWhere = {
  AND?: InputMaybe<Array<CoursesByQueryResponseWhere>>;
  NOT?: InputMaybe<CoursesByQueryResponseWhere>;
  OR?: InputMaybe<Array<CoursesByQueryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CoursesByQueryResponsesConnection = {
  __typename?: "CoursesByQueryResponsesConnection";
  edges: Array<CoursesByQueryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CoursesConnection = {
  __typename?: "CoursesConnection";
  edges: Array<CourseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CreateAiTutorInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<ObjectiveInput>>>;
  parentModuleId: Scalars["ID"]["input"];
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  videoPool?: InputMaybe<Array<InputMaybe<VideoDataInput>>>;
  visualizationId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateAchievementsMutationResponse = {
  __typename?: "CreateAchievementsMutationResponse";
  achievements: Array<Achievements>;
  info: CreateInfo;
};

export type CreateActiveTracksMutationResponse = {
  __typename?: "CreateActiveTracksMutationResponse";
  activeTracks: Array<ActiveTrack>;
  info: CreateInfo;
};

export type CreateActivityAnalyticsDetailsMutationResponse = {
  __typename?: "CreateActivityAnalyticsDetailsMutationResponse";
  activityAnalyticsDetails: Array<ActivityAnalyticsDetails>;
  info: CreateInfo;
};

export type CreateActivityAnalyticsMutationResponse = {
  __typename?: "CreateActivityAnalyticsMutationResponse";
  activityAnalytics: Array<ActivityAnalytics>;
  info: CreateInfo;
};

export type CreateActivityAnalyticsResponsesMutationResponse = {
  __typename?: "CreateActivityAnalyticsResponsesMutationResponse";
  activityAnalyticsResponses: Array<ActivityAnalyticsResponse>;
  info: CreateInfo;
};

export type CreateActivityCompletionDetailsDataMutationResponse = {
  __typename?: "CreateActivityCompletionDetailsDataMutationResponse";
  activityCompletionDetailsData: Array<ActivityCompletionDetailsData>;
  info: CreateInfo;
};

export type CreateActivityCompletionDetailsFullsMutationResponse = {
  __typename?: "CreateActivityCompletionDetailsFullsMutationResponse";
  activityCompletionDetailsFulls: Array<ActivityCompletionDetailsFull>;
  info: CreateInfo;
};

export type CreateActivityCompletionDetailsMutationResponse = {
  __typename?: "CreateActivityCompletionDetailsMutationResponse";
  activityCompletionDetails: Array<ActivityCompletionDetails>;
  info: CreateInfo;
};

export type CreateActivityCompletionDetailsObjectivesMutationResponse = {
  __typename?: "CreateActivityCompletionDetailsObjectivesMutationResponse";
  activityCompletionDetailsObjectives: Array<ActivityCompletionDetailsObjectives>;
  info: CreateInfo;
};

export type CreateActivityCompletionDetailsResponsesMutationResponse = {
  __typename?: "CreateActivityCompletionDetailsResponsesMutationResponse";
  activityCompletionDetailsResponses: Array<ActivityCompletionDetailsResponse>;
  info: CreateInfo;
};

export type CreateActivityInsightsMutationResponse = {
  __typename?: "CreateActivityInsightsMutationResponse";
  activityInsights: Array<ActivityInsights>;
  info: CreateInfo;
};

export type CreateActivityInsightsResponsesMutationResponse = {
  __typename?: "CreateActivityInsightsResponsesMutationResponse";
  activityInsightsResponses: Array<ActivityInsightsResponse>;
  info: CreateInfo;
};

export type CreateActivityProgressAnalyticsMutationResponse = {
  __typename?: "CreateActivityProgressAnalyticsMutationResponse";
  activityProgressAnalytics: Array<ActivityProgressAnalytics>;
  info: CreateInfo;
};

export type CreateActivityProgressDetailsMutationResponse = {
  __typename?: "CreateActivityProgressDetailsMutationResponse";
  activityProgressDetails: Array<ActivityProgressDetails>;
  info: CreateInfo;
};

export type CreateAddAssignmentResponsesMutationResponse = {
  __typename?: "CreateAddAssignmentResponsesMutationResponse";
  addAssignmentResponses: Array<AddAssignmentResponse>;
  info: CreateInfo;
};

export type CreateAddContentModuleResponsesMutationResponse = {
  __typename?: "CreateAddContentModuleResponsesMutationResponse";
  addContentModuleResponses: Array<AddContentModuleResponse>;
  info: CreateInfo;
};

export type CreateAiTutorAnalyticsMutationResponse = {
  __typename?: "CreateAiTutorAnalyticsMutationResponse";
  aiTutorAnalytics: Array<AiTutorAnalytics>;
  info: CreateInfo;
};

export type CreateAiTutorAnalyticsResponsesMutationResponse = {
  __typename?: "CreateAiTutorAnalyticsResponsesMutationResponse";
  aiTutorAnalyticsResponses: Array<AiTutorAnalyticsResponse>;
  info: CreateInfo;
};

export type CreateAiTutorEvalCriteriaResponsesMutationResponse = {
  __typename?: "CreateAiTutorEvalCriteriaResponsesMutationResponse";
  aiTutorEvalCriteriaResponses: Array<AiTutorEvalCriteriaResponse>;
  info: CreateInfo;
};

export type CreateAiTutorGeneratedResponsesMutationResponse = {
  __typename?: "CreateAiTutorGeneratedResponsesMutationResponse";
  aiTutorGeneratedResponses: Array<AiTutorGeneratedResponse>;
  info: CreateInfo;
};

export type CreateAiTutorProgressAnalyticsMutationResponse = {
  __typename?: "CreateAiTutorProgressAnalyticsMutationResponse";
  aiTutorProgressAnalytics: Array<AiTutorProgressAnalytics>;
  info: CreateInfo;
};

export type CreateAiTutorProgressDetailsMutationResponse = {
  __typename?: "CreateAiTutorProgressDetailsMutationResponse";
  aiTutorProgressDetails: Array<AiTutorProgressDetails>;
  info: CreateInfo;
};

export type CreateAiTutorResponsesMutationResponse = {
  __typename?: "CreateAiTutorResponsesMutationResponse";
  aiTutorResponses: Array<AiTutorResponse>;
  info: CreateInfo;
};

export type CreateAiTutorsMutationResponse = {
  __typename?: "CreateAiTutorsMutationResponse";
  aiTutors: Array<AiTutor>;
  info: CreateInfo;
};

export type CreateAnotherRelationshipFieldsMutationResponse = {
  __typename?: "CreateAnotherRelationshipFieldsMutationResponse";
  anotherRelationshipFields: Array<AnotherRelationshipField>;
  info: CreateInfo;
};

export type CreateAspectDetailsTypesMutationResponse = {
  __typename?: "CreateAspectDetailsTypesMutationResponse";
  aspectDetailsTypes: Array<AspectDetailsType>;
  info: CreateInfo;
};

export type CreateAssessmentListResponseDataMutationResponse = {
  __typename?: "CreateAssessmentListResponseDataMutationResponse";
  assessmentListResponseData: Array<AssessmentListResponseData>;
  info: CreateInfo;
};

export type CreateAssessmentListResponseFormattedsMutationResponse = {
  __typename?: "CreateAssessmentListResponseFormattedsMutationResponse";
  assessmentListResponseFormatteds: Array<AssessmentListResponseFormatted>;
  info: CreateInfo;
};

export type CreateAssessmentListResponsesMutationResponse = {
  __typename?: "CreateAssessmentListResponsesMutationResponse";
  assessmentListResponses: Array<AssessmentListResponse>;
  info: CreateInfo;
};

export type CreateAssessmentResponsesMutationResponse = {
  __typename?: "CreateAssessmentResponsesMutationResponse";
  assessmentResponses: Array<AssessmentResponse>;
  info: CreateInfo;
};

export type CreateAssessmentsMutationResponse = {
  __typename?: "CreateAssessmentsMutationResponse";
  assessments: Array<Assessment>;
  info: CreateInfo;
};

export type CreateAssignedQuestsMutationResponse = {
  __typename?: "CreateAssignedQuestsMutationResponse";
  assignedQuests: Array<AssignedQuest>;
  info: CreateInfo;
};

export type CreateAssignmentAndSubmissionResponsesMutationResponse = {
  __typename?: "CreateAssignmentAndSubmissionResponsesMutationResponse";
  assignmentAndSubmissionResponses: Array<AssignmentAndSubmissionResponse>;
  info: CreateInfo;
};

export type CreateAssignmentAndSubmissionsMutationResponse = {
  __typename?: "CreateAssignmentAndSubmissionsMutationResponse";
  assignmentAndSubmissions: Array<AssignmentAndSubmission>;
  info: CreateInfo;
};

export type CreateAssignmentInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate: Scalars["String"]["input"];
  enableStudentFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>;
  files?: InputMaybe<Array<FileInput>>;
  moduleId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateAssignmentResponsesMutationResponse = {
  __typename?: "CreateAssignmentResponsesMutationResponse";
  assignmentResponses: Array<AssignmentResponse>;
  info: CreateInfo;
};

export type CreateAssignmentsMutationResponse = {
  __typename?: "CreateAssignmentsMutationResponse";
  assignments: Array<Assignment>;
  info: CreateInfo;
};

export type CreateAttributesMutationResponse = {
  __typename?: "CreateAttributesMutationResponse";
  attributes: Array<Attribute>;
  info: CreateInfo;
};

export type CreateAudioToggleUserResponsesMutationResponse = {
  __typename?: "CreateAudioToggleUserResponsesMutationResponse";
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  info: CreateInfo;
};

export type CreateAuthPayloadResponsesMutationResponse = {
  __typename?: "CreateAuthPayloadResponsesMutationResponse";
  authPayloadResponses: Array<AuthPayloadResponse>;
  info: CreateInfo;
};

export type CreateAuthPayloadUserPreferencesMutationResponse = {
  __typename?: "CreateAuthPayloadUserPreferencesMutationResponse";
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  info: CreateInfo;
};

export type CreateAuthPayloadUsersMutationResponse = {
  __typename?: "CreateAuthPayloadUsersMutationResponse";
  authPayloadUsers: Array<AuthPayloadUser>;
  info: CreateInfo;
};

export type CreateAxioResourceInput = {
  description: Scalars["String"]["input"];
  files?: InputMaybe<Array<FileInput>>;
  moduleId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateAxioResourceResponsesMutationResponse = {
  __typename?: "CreateAxioResourceResponsesMutationResponse";
  axioResourceResponses: Array<AxioResourceResponse>;
  info: CreateInfo;
};

export type CreateAxioResourcesMutationResponse = {
  __typename?: "CreateAxioResourcesMutationResponse";
  axioResources: Array<AxioResource>;
  info: CreateInfo;
};

export type CreateCareerHeadersMutationResponse = {
  __typename?: "CreateCareerHeadersMutationResponse";
  careerHeaders: Array<CareerHeader>;
  info: CreateInfo;
};

export type CreateCareerTopicResponsesMutationResponse = {
  __typename?: "CreateCareerTopicResponsesMutationResponse";
  careerTopicResponses: Array<CareerTopicResponse>;
  info: CreateInfo;
};

export type CreateCareersMutationResponse = {
  __typename?: "CreateCareersMutationResponse";
  careers: Array<Career>;
  info: CreateInfo;
};

export type CreateChallengesMutationResponse = {
  __typename?: "CreateChallengesMutationResponse";
  challenges: Array<Challenge>;
  info: CreateInfo;
};

export type CreateChaptersMutationResponse = {
  __typename?: "CreateChaptersMutationResponse";
  chapters: Array<Chapter>;
  info: CreateInfo;
};

export type CreateChatAnalyticsMutationResponse = {
  __typename?: "CreateChatAnalyticsMutationResponse";
  chatAnalytics: Array<ChatAnalytics>;
  info: CreateInfo;
};

export type CreateChatAttachmentResponsesMutationResponse = {
  __typename?: "CreateChatAttachmentResponsesMutationResponse";
  chatAttachmentResponses: Array<ChatAttachmentResponse>;
  info: CreateInfo;
};

export type CreateChatResponsesMutationResponse = {
  __typename?: "CreateChatResponsesMutationResponse";
  chatResponses: Array<ChatResponse>;
  info: CreateInfo;
};

export type CreateChatsMutationResponse = {
  __typename?: "CreateChatsMutationResponse";
  chats: Array<Chat>;
  info: CreateInfo;
};

export type CreateChildInfosMutationResponse = {
  __typename?: "CreateChildInfosMutationResponse";
  childInfos: Array<ChildInfo>;
  info: CreateInfo;
};

export type CreateCompletionActivityAnalyticsMutationResponse = {
  __typename?: "CreateCompletionActivityAnalyticsMutationResponse";
  completionActivityAnalytics: Array<CompletionActivityAnalytics>;
  info: CreateInfo;
};

export type CreateCompletionActivityDetailsMutationResponse = {
  __typename?: "CreateCompletionActivityDetailsMutationResponse";
  completionActivityDetails: Array<CompletionActivityDetails>;
  info: CreateInfo;
};

export type CreateCompletionAnalyticsMutationResponse = {
  __typename?: "CreateCompletionAnalyticsMutationResponse";
  completionAnalytics: Array<CompletionAnalytics>;
  info: CreateInfo;
};

export type CreateCompletionAnalyticsResponsesMutationResponse = {
  __typename?: "CreateCompletionAnalyticsResponsesMutationResponse";
  completionAnalyticsResponses: Array<CompletionAnalyticsResponse>;
  info: CreateInfo;
};

export type CreateCompletionCourseDetailsMutationResponse = {
  __typename?: "CreateCompletionCourseDetailsMutationResponse";
  completionCourseDetails: Array<CompletionCourseDetails>;
  info: CreateInfo;
};

export type CreateCompletionModuleAnalyticsMutationResponse = {
  __typename?: "CreateCompletionModuleAnalyticsMutationResponse";
  completionModuleAnalytics: Array<CompletionModuleAnalytics>;
  info: CreateInfo;
};

export type CreateCompletionModuleDetailsMutationResponse = {
  __typename?: "CreateCompletionModuleDetailsMutationResponse";
  completionModuleDetails: Array<CompletionModuleDetails>;
  info: CreateInfo;
};

export type CreateCompletionObjectiveAnalyticsMutationResponse = {
  __typename?: "CreateCompletionObjectiveAnalyticsMutationResponse";
  completionObjectiveAnalytics: Array<CompletionObjectiveAnalytics>;
  info: CreateInfo;
};

export type CreateCompletionStatsMutationResponse = {
  __typename?: "CreateCompletionStatsMutationResponse";
  completionStats: Array<CompletionStats>;
  info: CreateInfo;
};

export type CreateCoreValuesMutationResponse = {
  __typename?: "CreateCoreValuesMutationResponse";
  coreValues: Array<CoreValue>;
  info: CreateInfo;
};

export type CreateCourseAnalyticsDetailsMutationResponse = {
  __typename?: "CreateCourseAnalyticsDetailsMutationResponse";
  courseAnalyticsDetails: Array<CourseAnalyticsDetails>;
  info: CreateInfo;
};

export type CreateCourseAnalyticsMutationResponse = {
  __typename?: "CreateCourseAnalyticsMutationResponse";
  courseAnalytics: Array<CourseAnalytics>;
  info: CreateInfo;
};

export type CreateCourseAnalyticsResponsesMutationResponse = {
  __typename?: "CreateCourseAnalyticsResponsesMutationResponse";
  courseAnalyticsResponses: Array<CourseAnalyticsResponse>;
  info: CreateInfo;
};

export type CreateCourseAnalyticsSummaryResponsesMutationResponse = {
  __typename?: "CreateCourseAnalyticsSummaryResponsesMutationResponse";
  courseAnalyticsSummaryResponses: Array<CourseAnalyticsSummaryResponse>;
  info: CreateInfo;
};

export type CreateCourseCategoriesMutationResponse = {
  __typename?: "CreateCourseCategoriesMutationResponse";
  courseCategories: Array<CourseCategory>;
  info: CreateInfo;
};

export type CreateCourseCompletionDetailsDataMutationResponse = {
  __typename?: "CreateCourseCompletionDetailsDataMutationResponse";
  courseCompletionDetailsData: Array<CourseCompletionDetailsData>;
  info: CreateInfo;
};

export type CreateCourseCompletionDetailsMutationResponse = {
  __typename?: "CreateCourseCompletionDetailsMutationResponse";
  courseCompletionDetails: Array<CourseCompletionDetails>;
  info: CreateInfo;
};

export type CreateCourseCompletionDetailsResponsesMutationResponse = {
  __typename?: "CreateCourseCompletionDetailsResponsesMutationResponse";
  courseCompletionDetailsResponses: Array<CourseCompletionDetailsResponse>;
  info: CreateInfo;
};

export type CreateCourseCompletionSummariesMutationResponse = {
  __typename?: "CreateCourseCompletionSummariesMutationResponse";
  courseCompletionSummaries: Array<CourseCompletionSummary>;
  info: CreateInfo;
};

export type CreateCourseCompletionSummaryResponsesMutationResponse = {
  __typename?: "CreateCourseCompletionSummaryResponsesMutationResponse";
  courseCompletionSummaryResponses: Array<CourseCompletionSummaryResponse>;
  info: CreateInfo;
};

export type CreateCourseCreationResponsesMutationResponse = {
  __typename?: "CreateCourseCreationResponsesMutationResponse";
  courseCreationResponses: Array<CourseCreationResponse>;
  info: CreateInfo;
};

export type CreateCourseDetailedAnalyticsMutationResponse = {
  __typename?: "CreateCourseDetailedAnalyticsMutationResponse";
  courseDetailedAnalytics: Array<CourseDetailedAnalytics>;
  info: CreateInfo;
};

export type CreateCourseDetailedResponsesMutationResponse = {
  __typename?: "CreateCourseDetailedResponsesMutationResponse";
  courseDetailedResponses: Array<CourseDetailedResponse>;
  info: CreateInfo;
};

export type CreateCourseHierarchyResponsesMutationResponse = {
  __typename?: "CreateCourseHierarchyResponsesMutationResponse";
  courseHierarchyResponses: Array<CourseHierarchyResponse>;
  info: CreateInfo;
};

export type CreateCourseInput = {
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  degreeId?: InputMaybe<Scalars["ID"]["input"]>;
  is_elective?: InputMaybe<Scalars["Boolean"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parentSchoolId: Scalars["String"]["input"];
};

export type CreateCoursePlaceholderInput = {
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  courseType: Scalars["String"]["input"];
  degreeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["ID"]["input"];
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCourseProgressAnalyticsMutationResponse = {
  __typename?: "CreateCourseProgressAnalyticsMutationResponse";
  courseProgressAnalytics: Array<CourseProgressAnalytics>;
  info: CreateInfo;
};

export type CreateCourseProgressDetailsMutationResponse = {
  __typename?: "CreateCourseProgressDetailsMutationResponse";
  courseProgressDetails: Array<CourseProgressDetails>;
  info: CreateInfo;
};

export type CreateCourseResponsesMutationResponse = {
  __typename?: "CreateCourseResponsesMutationResponse";
  courseResponses: Array<CourseResponse>;
  info: CreateInfo;
};

export type CreateCourseRosterDataMutationResponse = {
  __typename?: "CreateCourseRosterDataMutationResponse";
  courseRosterData: Array<CourseRosterData>;
  info: CreateInfo;
};

export type CreateCourseRosterServiceResultsMutationResponse = {
  __typename?: "CreateCourseRosterServiceResultsMutationResponse";
  courseRosterServiceResults: Array<CourseRosterServiceResult>;
  info: CreateInfo;
};

export type CreateCourseSummariesMutationResponse = {
  __typename?: "CreateCourseSummariesMutationResponse";
  courseSummaries: Array<CourseSummary>;
  info: CreateInfo;
};

export type CreateCoursesByQueryDataMutationResponse = {
  __typename?: "CreateCoursesByQueryDataMutationResponse";
  coursesByQueryData: Array<CoursesByQueryData>;
  info: CreateInfo;
};

export type CreateCoursesByQueryResponsesMutationResponse = {
  __typename?: "CreateCoursesByQueryResponsesMutationResponse";
  coursesByQueryResponses: Array<CoursesByQueryResponse>;
  info: CreateInfo;
};

export type CreateCoursesMutationResponse = {
  __typename?: "CreateCoursesMutationResponse";
  courses: Array<Course>;
  info: CreateInfo;
};

export type CreateCurriculaMutationResponse = {
  __typename?: "CreateCurriculaMutationResponse";
  curricula: Array<Curriculum>;
  info: CreateInfo;
};

export type CreateDailyCompletedTodosCountsMutationResponse = {
  __typename?: "CreateDailyCompletedTodosCountsMutationResponse";
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  info: CreateInfo;
};

export type CreateDailyExerciseTotalsMutationResponse = {
  __typename?: "CreateDailyExerciseTotalsMutationResponse";
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  info: CreateInfo;
};

export type CreateDailyNutritionTotalsMutationResponse = {
  __typename?: "CreateDailyNutritionTotalsMutationResponse";
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  info: CreateInfo;
};

export type CreateDailySummariesMutationResponse = {
  __typename?: "CreateDailySummariesMutationResponse";
  dailySummaries: Array<DailySummary>;
  info: CreateInfo;
};

export type CreateDegreeInput = {
  name: Scalars["String"]["input"];
  schoolId: Scalars["ID"]["input"];
};

export type CreateDegreeResponsesMutationResponse = {
  __typename?: "CreateDegreeResponsesMutationResponse";
  degreeResponses: Array<DegreeResponse>;
  info: CreateInfo;
};

export type CreateDegreesBySchoolByQueryDataMutationResponse = {
  __typename?: "CreateDegreesBySchoolByQueryDataMutationResponse";
  degreesBySchoolByQueryData: Array<DegreesBySchoolByQueryData>;
  info: CreateInfo;
};

export type CreateDegreesBySchoolByQueryResponsesMutationResponse = {
  __typename?: "CreateDegreesBySchoolByQueryResponsesMutationResponse";
  degreesBySchoolByQueryResponses: Array<DegreesBySchoolByQueryResponse>;
  info: CreateInfo;
};

export type CreateDegreesMutationResponse = {
  __typename?: "CreateDegreesMutationResponse";
  degrees: Array<Degree>;
  info: CreateInfo;
};

export type CreateDeleteContentAssignmentResponsesMutationResponse = {
  __typename?: "CreateDeleteContentAssignmentResponsesMutationResponse";
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  info: CreateInfo;
};

export type CreateDeleteContentAssignmentVariablesMutationResponse = {
  __typename?: "CreateDeleteContentAssignmentVariablesMutationResponse";
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  info: CreateInfo;
};

export type CreateDeleteContentModuleResponsesMutationResponse = {
  __typename?: "CreateDeleteContentModuleResponsesMutationResponse";
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  info: CreateInfo;
};

export type CreateDeleteContentModuleVariablesMutationResponse = {
  __typename?: "CreateDeleteContentModuleVariablesMutationResponse";
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  info: CreateInfo;
};

export type CreateDeleteCourseResponsesMutationResponse = {
  __typename?: "CreateDeleteCourseResponsesMutationResponse";
  deleteCourseResponses: Array<DeleteCourseResponse>;
  info: CreateInfo;
};

export type CreateDeviceSessionsMutationResponse = {
  __typename?: "CreateDeviceSessionsMutationResponse";
  deviceSessions: Array<DeviceSession>;
  info: CreateInfo;
};

export type CreateEmotionalStatesMutationResponse = {
  __typename?: "CreateEmotionalStatesMutationResponse";
  emotionalStates: Array<EmotionalState>;
  info: CreateInfo;
};

export type CreateEventsMutationResponse = {
  __typename?: "CreateEventsMutationResponse";
  events: Array<Event>;
  info: CreateInfo;
};

export type CreateExportCsvResponsesMutationResponse = {
  __typename?: "CreateExportCsvResponsesMutationResponse";
  exportCsvResponses: Array<ExportCsvResponse>;
  info: CreateInfo;
};

export type CreateFacultyDataCoursesMutationResponse = {
  __typename?: "CreateFacultyDataCoursesMutationResponse";
  facultyDataCourses: Array<FacultyDataCourse>;
  info: CreateInfo;
};

export type CreateFacultyDataRolesMutationResponse = {
  __typename?: "CreateFacultyDataRolesMutationResponse";
  facultyDataRoles: Array<FacultyDataRole>;
  info: CreateInfo;
};

export type CreateFacultyDataSessionDeviceSessionsMutationResponse = {
  __typename?: "CreateFacultyDataSessionDeviceSessionsMutationResponse";
  facultyDataSessionDeviceSessions: Array<FacultyDataSessionDeviceSession>;
  info: CreateInfo;
};

export type CreateFacultyQueryResponseDataMutationResponse = {
  __typename?: "CreateFacultyQueryResponseDataMutationResponse";
  facultyQueryResponseData: Array<FacultyQueryResponseData>;
  info: CreateInfo;
};

export type CreateFacultyQueryResponseWithCountDataMutationResponse = {
  __typename?: "CreateFacultyQueryResponseWithCountDataMutationResponse";
  facultyQueryResponseWithCountData: Array<FacultyQueryResponseWithCountData>;
  info: CreateInfo;
};

export type CreateFacultyQueryResponsesMutationResponse = {
  __typename?: "CreateFacultyQueryResponsesMutationResponse";
  facultyQueryResponses: Array<FacultyQueryResponse>;
  info: CreateInfo;
};

export type CreateFeedbacksMutationResponse = {
  __typename?: "CreateFeedbacksMutationResponse";
  feedbacks: Array<Feedback>;
  info: CreateInfo;
};

export type CreateFieldsMutationResponse = {
  __typename?: "CreateFieldsMutationResponse";
  fields: Array<Field>;
  info: CreateInfo;
};

export type CreateFileResponsesMutationResponse = {
  __typename?: "CreateFileResponsesMutationResponse";
  fileResponses: Array<FileResponse>;
  info: CreateInfo;
};

export type CreateFileUploadResponsesMutationResponse = {
  __typename?: "CreateFileUploadResponsesMutationResponse";
  fileUploadResponses: Array<FileUploadResponse>;
  info: CreateInfo;
};

export type CreateFilesMutationResponse = {
  __typename?: "CreateFilesMutationResponse";
  files: Array<File>;
  info: CreateInfo;
};

export type CreateFoodItemsMutationResponse = {
  __typename?: "CreateFoodItemsMutationResponse";
  foodItems: Array<FoodItem>;
  info: CreateInfo;
};

export type CreateFoodsMutationResponse = {
  __typename?: "CreateFoodsMutationResponse";
  foods: Array<Food>;
  info: CreateInfo;
};

export type CreateFormattedAiTutorsMutationResponse = {
  __typename?: "CreateFormattedAiTutorsMutationResponse";
  formattedAiTutors: Array<FormattedAiTutor>;
  info: CreateInfo;
};

export type CreateFormattedChatsMutationResponse = {
  __typename?: "CreateFormattedChatsMutationResponse";
  formattedChats: Array<FormattedChat>;
  info: CreateInfo;
};

export type CreateFormattedObjectivesMutationResponse = {
  __typename?: "CreateFormattedObjectivesMutationResponse";
  formattedObjectives: Array<FormattedObjective>;
  info: CreateInfo;
};

export type CreateFormattedVisualizationsMutationResponse = {
  __typename?: "CreateFormattedVisualizationsMutationResponse";
  formattedVisualizations: Array<FormattedVisualization>;
  info: CreateInfo;
};

export type CreateGamesMutationResponse = {
  __typename?: "CreateGamesMutationResponse";
  games: Array<Game>;
  info: CreateInfo;
};

export type CreateGenerateCourseContentDataMutationResponse = {
  __typename?: "CreateGenerateCourseContentDataMutationResponse";
  generateCourseContentData: Array<GenerateCourseContentData>;
  info: CreateInfo;
};

export type CreateGenerateCourseContentResponsesMutationResponse = {
  __typename?: "CreateGenerateCourseContentResponsesMutationResponse";
  generateCourseContentResponses: Array<GenerateCourseContentResponse>;
  info: CreateInfo;
};

export type CreateGeneratedAiTutorsMutationResponse = {
  __typename?: "CreateGeneratedAiTutorsMutationResponse";
  generatedAiTutors: Array<GeneratedAiTutor>;
  info: CreateInfo;
};

export type CreateGeneratedObjectivesMutationResponse = {
  __typename?: "CreateGeneratedObjectivesMutationResponse";
  generatedObjectives: Array<GeneratedObjective>;
  info: CreateInfo;
};

export type CreateGeneratedsMutationResponse = {
  __typename?: "CreateGeneratedsMutationResponse";
  generateds: Array<Generated>;
  info: CreateInfo;
};

export type CreateGetAssignmentObjectiveResponsesMutationResponse = {
  __typename?: "CreateGetAssignmentObjectiveResponsesMutationResponse";
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  info: CreateInfo;
};

export type CreateGetAssignmentResponsesMutationResponse = {
  __typename?: "CreateGetAssignmentResponsesMutationResponse";
  getAssignmentResponses: Array<GetAssignmentResponse>;
  info: CreateInfo;
};

export type CreateGetJourneyByIdResponsesMutationResponse = {
  __typename?: "CreateGetJourneyByIdResponsesMutationResponse";
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  info: CreateInfo;
};

export type CreateGetJourneysResponsesMutationResponse = {
  __typename?: "CreateGetJourneysResponsesMutationResponse";
  getJourneysResponses: Array<GetJourneysResponse>;
  info: CreateInfo;
};

export type CreateGoalsMutationResponse = {
  __typename?: "CreateGoalsMutationResponse";
  goals: Array<Goal>;
  info: CreateInfo;
};

export type CreateGoogleAuthTokensMutationResponse = {
  __typename?: "CreateGoogleAuthTokensMutationResponse";
  googleAuthTokens: Array<GoogleAuthTokens>;
  info: CreateInfo;
};

export type CreateHierarchiesMutationResponse = {
  __typename?: "CreateHierarchiesMutationResponse";
  hierarchies: Array<Hierarchy>;
  info: CreateInfo;
};

export type CreateHierarchyActivitiesMutationResponse = {
  __typename?: "CreateHierarchyActivitiesMutationResponse";
  hierarchyActivities: Array<HierarchyActivity>;
  info: CreateInfo;
};

export type CreateHierarchyActivityDetailsMutationResponse = {
  __typename?: "CreateHierarchyActivityDetailsMutationResponse";
  hierarchyActivityDetails: Array<HierarchyActivityDetails>;
  info: CreateInfo;
};

export type CreateHierarchyCourseDetailsMutationResponse = {
  __typename?: "CreateHierarchyCourseDetailsMutationResponse";
  hierarchyCourseDetails: Array<HierarchyCourseDetails>;
  info: CreateInfo;
};

export type CreateHierarchyCoursesMutationResponse = {
  __typename?: "CreateHierarchyCoursesMutationResponse";
  hierarchyCourses: Array<HierarchyCourse>;
  info: CreateInfo;
};

export type CreateHierarchyModuleDetailsMutationResponse = {
  __typename?: "CreateHierarchyModuleDetailsMutationResponse";
  hierarchyModuleDetails: Array<HierarchyModuleDetails>;
  info: CreateInfo;
};

export type CreateHierarchyModulesMutationResponse = {
  __typename?: "CreateHierarchyModulesMutationResponse";
  hierarchyModules: Array<HierarchyModule>;
  info: CreateInfo;
};

export type CreateHierarchyNodesMutationResponse = {
  __typename?: "CreateHierarchyNodesMutationResponse";
  hierarchyNodes: Array<HierarchyNode>;
  info: CreateInfo;
};

export type CreateHierarchyObjectivesMutationResponse = {
  __typename?: "CreateHierarchyObjectivesMutationResponse";
  hierarchyObjectives: Array<HierarchyObjective>;
  info: CreateInfo;
};

export type CreateHobbiesMutationResponse = {
  __typename?: "CreateHobbiesMutationResponse";
  hobbies: Array<Hobby>;
  info: CreateInfo;
};

/** Information about the number of nodes and relationships created during a create mutation */
export type CreateInfo = {
  __typename?: "CreateInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesCreated: Scalars["Int"]["output"];
  relationshipsCreated: Scalars["Int"]["output"];
};

export type CreateInterestsMutationResponse = {
  __typename?: "CreateInterestsMutationResponse";
  info: CreateInfo;
  interests: Array<Interest>;
};

export type CreateInviteCountResponsesMutationResponse = {
  __typename?: "CreateInviteCountResponsesMutationResponse";
  info: CreateInfo;
  inviteCountResponses: Array<InviteCountResponse>;
};

export type CreateInviteUsersToEnrollInCourseResponsesMutationResponse = {
  __typename?: "CreateInviteUsersToEnrollInCourseResponsesMutationResponse";
  info: CreateInfo;
  inviteUsersToEnrollInCourseResponses: Array<InviteUsersToEnrollInCourseResponse>;
};

export type CreateInviteUsersToEnrollInDegreeResponsesMutationResponse = {
  __typename?: "CreateInviteUsersToEnrollInDegreeResponsesMutationResponse";
  info: CreateInfo;
  inviteUsersToEnrollInDegreeResponses: Array<InviteUsersToEnrollInDegreeResponse>;
};

export type CreateInvitedUsersMutationResponse = {
  __typename?: "CreateInvitedUsersMutationResponse";
  info: CreateInfo;
  invitedUsers: Array<InvitedUsers>;
};

export type CreateJourneysMutationResponse = {
  __typename?: "CreateJourneysMutationResponse";
  info: CreateInfo;
  journeys: Array<Journey>;
};

export type CreateLearningCheckoutSessionsMutationResponse = {
  __typename?: "CreateLearningCheckoutSessionsMutationResponse";
  info: CreateInfo;
  learningCheckoutSessions: Array<LearningCheckoutSession>;
};

export type CreateLearningNodesMutationResponse = {
  __typename?: "CreateLearningNodesMutationResponse";
  info: CreateInfo;
  learningNodes: Array<LearningNode>;
};

export type CreateLearningObjectiveResponsesMutationResponse = {
  __typename?: "CreateLearningObjectiveResponsesMutationResponse";
  info: CreateInfo;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
};

export type CreateLearningObjectivesMutationResponse = {
  __typename?: "CreateLearningObjectivesMutationResponse";
  info: CreateInfo;
  learningObjectives: Array<LearningObjective>;
};

export type CreateLearningPaymentsMutationResponse = {
  __typename?: "CreateLearningPaymentsMutationResponse";
  info: CreateInfo;
  learningPayments: Array<LearningPayment>;
};

export type CreateLearningSummariesMutationResponse = {
  __typename?: "CreateLearningSummariesMutationResponse";
  info: CreateInfo;
  learningSummaries: Array<LearningSummary>;
};

export type CreateLearningTreesMutationResponse = {
  __typename?: "CreateLearningTreesMutationResponse";
  info: CreateInfo;
  learningTrees: Array<LearningTree>;
};

export type CreateLessonsMutationResponse = {
  __typename?: "CreateLessonsMutationResponse";
  info: CreateInfo;
  lessons: Array<Lesson>;
};

export type CreateLevelDetailsMutationResponse = {
  __typename?: "CreateLevelDetailsMutationResponse";
  info: CreateInfo;
  levelDetails: Array<LevelDetails>;
};

export type CreateLifeAspirationsMutationResponse = {
  __typename?: "CreateLifeAspirationsMutationResponse";
  info: CreateInfo;
  lifeAspirations: Array<LifeAspiration>;
};

export type CreateMasteryResultsMutationResponse = {
  __typename?: "CreateMasteryResultsMutationResponse";
  info: CreateInfo;
  masteryResults: Array<MasteryResult>;
};

export type CreateMeResponsesMutationResponse = {
  __typename?: "CreateMeResponsesMutationResponse";
  info: CreateInfo;
  meResponses: Array<MeResponse>;
};

export type CreateMessageResponsesMutationResponse = {
  __typename?: "CreateMessageResponsesMutationResponse";
  info: CreateInfo;
  messageResponses: Array<MessageResponse>;
};

export type CreateMessagesMutationResponse = {
  __typename?: "CreateMessagesMutationResponse";
  info: CreateInfo;
  messages: Array<Message>;
};

export type CreateModuleAnalyticsDetailsMutationResponse = {
  __typename?: "CreateModuleAnalyticsDetailsMutationResponse";
  info: CreateInfo;
  moduleAnalyticsDetails: Array<ModuleAnalyticsDetails>;
};

export type CreateModuleAnalyticsMutationResponse = {
  __typename?: "CreateModuleAnalyticsMutationResponse";
  info: CreateInfo;
  moduleAnalytics: Array<ModuleAnalytics>;
};

export type CreateModuleAnalyticsResponsesMutationResponse = {
  __typename?: "CreateModuleAnalyticsResponsesMutationResponse";
  info: CreateInfo;
  moduleAnalyticsResponses: Array<ModuleAnalyticsResponse>;
};

export type CreateModuleCompletionDetailsActivitiesMutationResponse = {
  __typename?: "CreateModuleCompletionDetailsActivitiesMutationResponse";
  info: CreateInfo;
  moduleCompletionDetailsActivities: Array<ModuleCompletionDetailsActivities>;
};

export type CreateModuleCompletionDetailsDataMutationResponse = {
  __typename?: "CreateModuleCompletionDetailsDataMutationResponse";
  info: CreateInfo;
  moduleCompletionDetailsData: Array<ModuleCompletionDetailsData>;
};

export type CreateModuleCompletionDetailsFullsMutationResponse = {
  __typename?: "CreateModuleCompletionDetailsFullsMutationResponse";
  info: CreateInfo;
  moduleCompletionDetailsFulls: Array<ModuleCompletionDetailsFull>;
};

export type CreateModuleCompletionDetailsMutationResponse = {
  __typename?: "CreateModuleCompletionDetailsMutationResponse";
  info: CreateInfo;
  moduleCompletionDetails: Array<ModuleCompletionDetails>;
};

export type CreateModuleCompletionDetailsResponsesMutationResponse = {
  __typename?: "CreateModuleCompletionDetailsResponsesMutationResponse";
  info: CreateInfo;
  moduleCompletionDetailsResponses: Array<ModuleCompletionDetailsResponse>;
};

export type CreateModuleInput = {
  order: Scalars["Float"]["input"];
  parentCourseId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateModuleProgressAnalyticsMutationResponse = {
  __typename?: "CreateModuleProgressAnalyticsMutationResponse";
  info: CreateInfo;
  moduleProgressAnalytics: Array<ModuleProgressAnalytics>;
};

export type CreateModuleProgressDetailsMutationResponse = {
  __typename?: "CreateModuleProgressDetailsMutationResponse";
  info: CreateInfo;
  moduleProgressDetails: Array<ModuleProgressDetails>;
};

export type CreateModuleResponsesMutationResponse = {
  __typename?: "CreateModuleResponsesMutationResponse";
  info: CreateInfo;
  moduleResponses: Array<ModuleResponse>;
};

export type CreateModulesMutationResponse = {
  __typename?: "CreateModulesMutationResponse";
  info: CreateInfo;
  modules: Array<Module>;
};

export type CreateMonthlySummariesMutationResponse = {
  __typename?: "CreateMonthlySummariesMutationResponse";
  info: CreateInfo;
  monthlySummaries: Array<MonthlySummary>;
};

export type CreateNodeIdentitiesMutationResponse = {
  __typename?: "CreateNodeIdentitiesMutationResponse";
  info: CreateInfo;
  nodeIdentities: Array<NodeIdentity>;
};

export type CreateNodePropertiesMutationResponse = {
  __typename?: "CreateNodePropertiesMutationResponse";
  info: CreateInfo;
  nodeProperties: Array<NodeProperties>;
};

export type CreateNutritionsMutationResponse = {
  __typename?: "CreateNutritionsMutationResponse";
  info: CreateInfo;
  nutritions: Array<Nutrition>;
};

export type CreateOAuthAccessTokensMutationResponse = {
  __typename?: "CreateOAuthAccessTokensMutationResponse";
  info: CreateInfo;
  oAuthAccessTokens: Array<OAuthAccessToken>;
};

export type CreateOAuthClientsMutationResponse = {
  __typename?: "CreateOAuthClientsMutationResponse";
  info: CreateInfo;
  oAuthClients: Array<OAuthClient>;
};

export type CreateObjectiveAnalyticsMutationResponse = {
  __typename?: "CreateObjectiveAnalyticsMutationResponse";
  info: CreateInfo;
  objectiveAnalytics: Array<ObjectiveAnalytics>;
};

export type CreateObjectiveAnalyticsResponsesMutationResponse = {
  __typename?: "CreateObjectiveAnalyticsResponsesMutationResponse";
  info: CreateInfo;
  objectiveAnalyticsResponses: Array<ObjectiveAnalyticsResponse>;
};

export type CreateObjectiveCompletionDetailsFullsMutationResponse = {
  __typename?: "CreateObjectiveCompletionDetailsFullsMutationResponse";
  info: CreateInfo;
  objectiveCompletionDetailsFulls: Array<ObjectiveCompletionDetailsFull>;
};

export type CreateObjectiveCompletionDetailsMutationResponse = {
  __typename?: "CreateObjectiveCompletionDetailsMutationResponse";
  info: CreateInfo;
  objectiveCompletionDetails: Array<ObjectiveCompletionDetails>;
};

export type CreateObjectiveCompletionDetailsResponsesMutationResponse = {
  __typename?: "CreateObjectiveCompletionDetailsResponsesMutationResponse";
  info: CreateInfo;
  objectiveCompletionDetailsResponses: Array<ObjectiveCompletionDetailsResponse>;
};

export type CreateObjectiveProgressAnalyticsMutationResponse = {
  __typename?: "CreateObjectiveProgressAnalyticsMutationResponse";
  info: CreateInfo;
  objectiveProgressAnalytics: Array<ObjectiveProgressAnalytics>;
};

export type CreateObjectivesMutationResponse = {
  __typename?: "CreateObjectivesMutationResponse";
  info: CreateInfo;
  objectives: Array<Objective>;
};

export type CreatePageInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  parentModuleId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreatePageResponsesMutationResponse = {
  __typename?: "CreatePageResponsesMutationResponse";
  info: CreateInfo;
  pageResponses: Array<PageResponse>;
};

export type CreatePagesMutationResponse = {
  __typename?: "CreatePagesMutationResponse";
  info: CreateInfo;
  pages: Array<Page>;
};

export type CreateParametersTypesMutationResponse = {
  __typename?: "CreateParametersTypesMutationResponse";
  info: CreateInfo;
  parametersTypes: Array<ParametersType>;
};

export type CreateParentRefsMutationResponse = {
  __typename?: "CreateParentRefsMutationResponse";
  info: CreateInfo;
  parentRefs: Array<ParentRef>;
};

export type CreatePaymentsMutationResponse = {
  __typename?: "CreatePaymentsMutationResponse";
  info: CreateInfo;
  payments: Array<Payment>;
};

export type CreatePermissionsMutationResponse = {
  __typename?: "CreatePermissionsMutationResponse";
  info: CreateInfo;
  permissions: Array<Permission>;
};

export type CreatePersonalityTraitsMutationResponse = {
  __typename?: "CreatePersonalityTraitsMutationResponse";
  info: CreateInfo;
  personalityTraits: Array<PersonalityTrait>;
};

export type CreatePreferencesMutationResponse = {
  __typename?: "CreatePreferencesMutationResponse";
  info: CreateInfo;
  preferences: Array<Preferences>;
};

export type CreatePrerequisitesMutationResponse = {
  __typename?: "CreatePrerequisitesMutationResponse";
  info: CreateInfo;
  prerequisites: Array<Prerequisite>;
};

export type CreateProgressDetailsMutationResponse = {
  __typename?: "CreateProgressDetailsMutationResponse";
  info: CreateInfo;
  progressDetails: Array<ProgressDetails>;
};

export type CreateProgressesMutationResponse = {
  __typename?: "CreateProgressesMutationResponse";
  info: CreateInfo;
  progresses: Array<Progress>;
};

export type CreateQuarterlySummariesMutationResponse = {
  __typename?: "CreateQuarterlySummariesMutationResponse";
  info: CreateInfo;
  quarterlySummaries: Array<QuarterlySummary>;
};

export type CreateRateLimitResultsMutationResponse = {
  __typename?: "CreateRateLimitResultsMutationResponse";
  info: CreateInfo;
  rateLimitResults: Array<RateLimitResult>;
};

export type CreateRecentCategoriesMutationResponse = {
  __typename?: "CreateRecentCategoriesMutationResponse";
  info: CreateInfo;
  recentCategories: Array<RecentCategory>;
};

export type CreateRecentTracksMutationResponse = {
  __typename?: "CreateRecentTracksMutationResponse";
  info: CreateInfo;
  recentTracks: Array<RecentTrack>;
};

export type CreateRecomendedsMutationResponse = {
  __typename?: "CreateRecomendedsMutationResponse";
  info: CreateInfo;
  recomendeds: Array<Recomended>;
};

export type CreateReflectionsMutationResponse = {
  __typename?: "CreateReflectionsMutationResponse";
  info: CreateInfo;
  reflections: Array<Reflection>;
};

export type CreateRefreshQuestResponsesMutationResponse = {
  __typename?: "CreateRefreshQuestResponsesMutationResponse";
  info: CreateInfo;
  refreshQuestResponses: Array<RefreshQuestResponse>;
};

export type CreateRelationshipFieldsMutationResponse = {
  __typename?: "CreateRelationshipFieldsMutationResponse";
  info: CreateInfo;
  relationshipFields: Array<RelationshipField>;
};

export type CreateResponseTypesMutationResponse = {
  __typename?: "CreateResponseTypesMutationResponse";
  info: CreateInfo;
  responseTypes: Array<ResponseType>;
};

export type CreateRolesMutationResponse = {
  __typename?: "CreateRolesMutationResponse";
  info: CreateInfo;
  roles: Array<Role>;
};

export type CreateSchoolsMutationResponse = {
  __typename?: "CreateSchoolsMutationResponse";
  info: CreateInfo;
  schools: Array<School>;
};

export type CreateSessionResponsesMutationResponse = {
  __typename?: "CreateSessionResponsesMutationResponse";
  info: CreateInfo;
  sessionResponses: Array<SessionResponse>;
};

export type CreateStudentActivityProgressDataMutationResponse = {
  __typename?: "CreateStudentActivityProgressDataMutationResponse";
  info: CreateInfo;
  studentActivityProgressData: Array<StudentActivityProgressData>;
};

export type CreateStudentActivityProgressResponsesMutationResponse = {
  __typename?: "CreateStudentActivityProgressResponsesMutationResponse";
  info: CreateInfo;
  studentActivityProgressResponses: Array<StudentActivityProgressResponse>;
};

export type CreateStudentAnalyticsDetailsMutationResponse = {
  __typename?: "CreateStudentAnalyticsDetailsMutationResponse";
  info: CreateInfo;
  studentAnalyticsDetails: Array<StudentAnalyticsDetails>;
};

export type CreateStudentAnalyticsMutationResponse = {
  __typename?: "CreateStudentAnalyticsMutationResponse";
  info: CreateInfo;
  studentAnalytics: Array<StudentAnalytics>;
};

export type CreateStudentAnalyticsResponsesMutationResponse = {
  __typename?: "CreateStudentAnalyticsResponsesMutationResponse";
  info: CreateInfo;
  studentAnalyticsResponses: Array<StudentAnalyticsResponse>;
};

export type CreateStudentAnalyticsSummaryResponsesMutationResponse = {
  __typename?: "CreateStudentAnalyticsSummaryResponsesMutationResponse";
  info: CreateInfo;
  studentAnalyticsSummaryResponses: Array<StudentAnalyticsSummaryResponse>;
};

export type CreateStudentChatAnalyticsMutationResponse = {
  __typename?: "CreateStudentChatAnalyticsMutationResponse";
  info: CreateInfo;
  studentChatAnalytics: Array<StudentChatAnalytics>;
};

export type CreateStudentChatAnalyticsResponsesMutationResponse = {
  __typename?: "CreateStudentChatAnalyticsResponsesMutationResponse";
  info: CreateInfo;
  studentChatAnalyticsResponses: Array<StudentChatAnalyticsResponse>;
};

export type CreateStudentCourseProgressResponsesMutationResponse = {
  __typename?: "CreateStudentCourseProgressResponsesMutationResponse";
  info: CreateInfo;
  studentCourseProgressResponses: Array<StudentCourseProgressResponse>;
};

export type CreateStudentDetailedAnalyticsMutationResponse = {
  __typename?: "CreateStudentDetailedAnalyticsMutationResponse";
  info: CreateInfo;
  studentDetailedAnalytics: Array<StudentDetailedAnalytics>;
};

export type CreateStudentDetailedAnalyticsResponsesMutationResponse = {
  __typename?: "CreateStudentDetailedAnalyticsResponsesMutationResponse";
  info: CreateInfo;
  studentDetailedAnalyticsResponses: Array<StudentDetailedAnalyticsResponse>;
};

export type CreateStudentLearningInsightsMutationResponse = {
  __typename?: "CreateStudentLearningInsightsMutationResponse";
  info: CreateInfo;
  studentLearningInsights: Array<StudentLearningInsights>;
};

export type CreateStudentLearningInsightsResponsesMutationResponse = {
  __typename?: "CreateStudentLearningInsightsResponsesMutationResponse";
  info: CreateInfo;
  studentLearningInsightsResponses: Array<StudentLearningInsightsResponse>;
};

export type CreateStudentModuleProgressDataMutationResponse = {
  __typename?: "CreateStudentModuleProgressDataMutationResponse";
  info: CreateInfo;
  studentModuleProgressData: Array<StudentModuleProgressData>;
};

export type CreateStudentModuleProgressResponsesMutationResponse = {
  __typename?: "CreateStudentModuleProgressResponsesMutationResponse";
  info: CreateInfo;
  studentModuleProgressResponses: Array<StudentModuleProgressResponse>;
};

export type CreateStudentSummariesMutationResponse = {
  __typename?: "CreateStudentSummariesMutationResponse";
  info: CreateInfo;
  studentSummaries: Array<StudentSummary>;
};

export type CreateSubchapterDetailsMutationResponse = {
  __typename?: "CreateSubchapterDetailsMutationResponse";
  info: CreateInfo;
  subchapterDetails: Array<SubchapterDetails>;
};

export type CreateSubchaptersMutationResponse = {
  __typename?: "CreateSubchaptersMutationResponse";
  info: CreateInfo;
  subchapters: Array<Subchapter>;
};

export type CreateSubfieldsMutationResponse = {
  __typename?: "CreateSubfieldsMutationResponse";
  info: CreateInfo;
  subfields: Array<Subfield>;
};

export type CreateSubmissionListResponseDataMutationResponse = {
  __typename?: "CreateSubmissionListResponseDataMutationResponse";
  info: CreateInfo;
  submissionListResponseData: Array<SubmissionListResponseData>;
};

export type CreateSubmissionListResponseFormattedsMutationResponse = {
  __typename?: "CreateSubmissionListResponseFormattedsMutationResponse";
  info: CreateInfo;
  submissionListResponseFormatteds: Array<SubmissionListResponseFormatted>;
};

export type CreateSubmissionListResponsesMutationResponse = {
  __typename?: "CreateSubmissionListResponsesMutationResponse";
  info: CreateInfo;
  submissionListResponses: Array<SubmissionListResponse>;
};

export type CreateSubmissionResponsesMutationResponse = {
  __typename?: "CreateSubmissionResponsesMutationResponse";
  info: CreateInfo;
  submissionResponses: Array<SubmissionResponse>;
};

export type CreateSubmissionsMutationResponse = {
  __typename?: "CreateSubmissionsMutationResponse";
  info: CreateInfo;
  submissions: Array<Submission>;
};

export type CreateSuggestVideoInsightsResponsesMutationResponse = {
  __typename?: "CreateSuggestVideoInsightsResponsesMutationResponse";
  info: CreateInfo;
  suggestVideoInsightsResponses: Array<SuggestVideoInsightsResponse>;
};

export type CreateSuggestionsMutationResponse = {
  __typename?: "CreateSuggestionsMutationResponse";
  info: CreateInfo;
  suggestions: Array<Suggestion>;
};

export type CreateTenantResponsesMutationResponse = {
  __typename?: "CreateTenantResponsesMutationResponse";
  info: CreateInfo;
  tenantResponses: Array<TenantResponse>;
};

export type CreateTenantSchemaResponsesMutationResponse = {
  __typename?: "CreateTenantSchemaResponsesMutationResponse";
  info: CreateInfo;
  tenantSchemaResponses: Array<TenantSchemaResponse>;
};

export type CreateTenantsMutationResponse = {
  __typename?: "CreateTenantsMutationResponse";
  info: CreateInfo;
  tenants: Array<Tenant>;
};

export type CreateTestingsMutationResponse = {
  __typename?: "CreateTestingsMutationResponse";
  info: CreateInfo;
  testings: Array<Testing>;
};

export type CreateTodosMutationResponse = {
  __typename?: "CreateTodosMutationResponse";
  info: CreateInfo;
  todos: Array<Todo>;
};

export type CreateTopicsMutationResponse = {
  __typename?: "CreateTopicsMutationResponse";
  info: CreateInfo;
  topics: Array<Topic>;
};

export type CreateTrackOverviewsMutationResponse = {
  __typename?: "CreateTrackOverviewsMutationResponse";
  info: CreateInfo;
  trackOverviews: Array<TrackOverview>;
};

export type CreateTransactionByDatesMutationResponse = {
  __typename?: "CreateTransactionByDatesMutationResponse";
  info: CreateInfo;
  transactionByDates: Array<TransactionByDate>;
};

export type CreateTransactionsMutationResponse = {
  __typename?: "CreateTransactionsMutationResponse";
  info: CreateInfo;
  transactions: Array<Transaction>;
};

export type CreateTranscriptSegmentsMutationResponse = {
  __typename?: "CreateTranscriptSegmentsMutationResponse";
  info: CreateInfo;
  transcriptSegments: Array<TranscriptSegment>;
};

export type CreateUpdateAssignmentResponsesMutationResponse = {
  __typename?: "CreateUpdateAssignmentResponsesMutationResponse";
  info: CreateInfo;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
};

export type CreateUpdateContentModuleNameResponsesMutationResponse = {
  __typename?: "CreateUpdateContentModuleNameResponsesMutationResponse";
  info: CreateInfo;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
};

export type CreateUpdateUserModalOnboardingResponsesMutationResponse = {
  __typename?: "CreateUpdateUserModalOnboardingResponsesMutationResponse";
  info: CreateInfo;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
};

export type CreateUploadCourseThumbnailResponsesMutationResponse = {
  __typename?: "CreateUploadCourseThumbnailResponsesMutationResponse";
  info: CreateInfo;
  uploadCourseThumbnailResponses: Array<UploadCourseThumbnailResponse>;
};

export type CreateUploadDocumentsResponsesMutationResponse = {
  __typename?: "CreateUploadDocumentsResponsesMutationResponse";
  info: CreateInfo;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
};

export type CreateUploadDocumentsToBucketResponsesMutationResponse = {
  __typename?: "CreateUploadDocumentsToBucketResponsesMutationResponse";
  info: CreateInfo;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
};

export type CreateUploadFacultyProfileImageResponsesMutationResponse = {
  __typename?: "CreateUploadFacultyProfileImageResponsesMutationResponse";
  info: CreateInfo;
  uploadFacultyProfileImageResponses: Array<UploadFacultyProfileImageResponse>;
};

export type CreateUserCareerLoadingResponsesMutationResponse = {
  __typename?: "CreateUserCareerLoadingResponsesMutationResponse";
  info: CreateInfo;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
};

export type CreateUserCourseLoadingResponsesMutationResponse = {
  __typename?: "CreateUserCourseLoadingResponsesMutationResponse";
  info: CreateInfo;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
};

export type CreateUserMasteriesMutationResponse = {
  __typename?: "CreateUserMasteriesMutationResponse";
  info: CreateInfo;
  userMasteries: Array<UserMastery>;
};

export type CreateUserProgressesMutationResponse = {
  __typename?: "CreateUserProgressesMutationResponse";
  info: CreateInfo;
  userProgresses: Array<UserProgress>;
};

export type CreateUsersMutationResponse = {
  __typename?: "CreateUsersMutationResponse";
  info: CreateInfo;
  users: Array<User>;
};

export type CreateValidityResponsesMutationResponse = {
  __typename?: "CreateValidityResponsesMutationResponse";
  info: CreateInfo;
  validityResponses: Array<ValidityResponse>;
};

export type CreateVideoPoolItemsMutationResponse = {
  __typename?: "CreateVideoPoolItemsMutationResponse";
  info: CreateInfo;
  videoPoolItems: Array<VideoPoolItem>;
};

export type CreateVideoServiceResultsMutationResponse = {
  __typename?: "CreateVideoServiceResultsMutationResponse";
  info: CreateInfo;
  videoServiceResults: Array<VideoServiceResult>;
};

export type CreateVideosMutationResponse = {
  __typename?: "CreateVideosMutationResponse";
  info: CreateInfo;
  videos: Array<Video>;
};

export type CreateVisualizationResponsesMutationResponse = {
  __typename?: "CreateVisualizationResponsesMutationResponse";
  info: CreateInfo;
  visualizationResponses: Array<VisualizationResponse>;
};

export type CreateVisualizationsMutationResponse = {
  __typename?: "CreateVisualizationsMutationResponse";
  info: CreateInfo;
  visualizations: Array<Visualization>;
};

export type CreateWaterIntakeSummariesMutationResponse = {
  __typename?: "CreateWaterIntakeSummariesMutationResponse";
  info: CreateInfo;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
};

export type CreateWaterIntakesMutationResponse = {
  __typename?: "CreateWaterIntakesMutationResponse";
  info: CreateInfo;
  waterIntakes: Array<WaterIntake>;
};

export type CreateWebhooksMutationResponse = {
  __typename?: "CreateWebhooksMutationResponse";
  info: CreateInfo;
  webhooks: Array<Webhook>;
};

export type CreateWeeklySummariesMutationResponse = {
  __typename?: "CreateWeeklySummariesMutationResponse";
  info: CreateInfo;
  weeklySummaries: Array<WeeklySummary>;
};

export type CreateWhitelistedEmailsMutationResponse = {
  __typename?: "CreateWhitelistedEmailsMutationResponse";
  info: CreateInfo;
  whitelistedEmails: Array<WhitelistedEmail>;
};

export type CreateWorkoutsMutationResponse = {
  __typename?: "CreateWorkoutsMutationResponse";
  info: CreateInfo;
  workouts: Array<Workout>;
};

export type CreateYearlySummariesMutationResponse = {
  __typename?: "CreateYearlySummariesMutationResponse";
  info: CreateInfo;
  yearlySummaries: Array<YearlySummary>;
};

export type CurriculaConnection = {
  __typename?: "CurriculaConnection";
  edges: Array<CurriculumEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Curriculum = {
  __typename?: "Curriculum";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  modules: Array<Module>;
  name: Scalars["String"]["output"];
  objectives: Array<Scalars["String"]["output"]>;
  standard: Scalars["String"]["output"];
  weeks: Scalars["Int"]["output"];
};

export type CurriculumAggregateSelection = {
  __typename?: "CurriculumAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  standard: StringAggregateSelection;
  weeks: IntAggregateSelection;
};

export type CurriculumCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  objectives: Array<Scalars["String"]["input"]>;
  standard: Scalars["String"]["input"];
  weeks: Scalars["Int"]["input"];
};

export type CurriculumEdge = {
  __typename?: "CurriculumEdge";
  cursor: Scalars["String"]["output"];
  node: Curriculum;
};

export type CurriculumOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CurriculumSort objects to sort Curricula by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CurriculumSort>>;
};

/** Fields to sort Curricula by. The order in which sorts are applied is not guaranteed when specifying many fields in one CurriculumSort object. */
export type CurriculumSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  standard?: InputMaybe<SortDirection>;
  weeks?: InputMaybe<SortDirection>;
};

export type CurriculumUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  weeks?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CurriculumWhere = {
  AND?: InputMaybe<Array<CurriculumWhere>>;
  NOT?: InputMaybe<CurriculumWhere>;
  OR?: InputMaybe<Array<CurriculumWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  standard_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  standard_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  standard_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  standard_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  weeks?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  weeks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCount = {
  __typename?: "DailyCompletedTodosCount";
  completedTodosCount: Scalars["Int"]["output"];
};

export type DailyCompletedTodosCountAggregateSelection = {
  __typename?: "DailyCompletedTodosCountAggregateSelection";
  completedTodosCount: IntAggregateSelection;
  count: Scalars["Int"]["output"];
};

export type DailyCompletedTodosCountCreateInput = {
  completedTodosCount: Scalars["Int"]["input"];
};

export type DailyCompletedTodosCountEdge = {
  __typename?: "DailyCompletedTodosCountEdge";
  cursor: Scalars["String"]["output"];
  node: DailyCompletedTodosCount;
};

export type DailyCompletedTodosCountOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyCompletedTodosCountSort objects to sort DailyCompletedTodosCounts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyCompletedTodosCountSort>>;
};

/** Fields to sort DailyCompletedTodosCounts by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyCompletedTodosCountSort object. */
export type DailyCompletedTodosCountSort = {
  completedTodosCount?: InputMaybe<SortDirection>;
};

export type DailyCompletedTodosCountUpdateInput = {
  completedTodosCount?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCountWhere = {
  AND?: InputMaybe<Array<DailyCompletedTodosCountWhere>>;
  NOT?: InputMaybe<DailyCompletedTodosCountWhere>;
  OR?: InputMaybe<Array<DailyCompletedTodosCountWhere>>;
  completedTodosCount?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  completedTodosCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCountsConnection = {
  __typename?: "DailyCompletedTodosCountsConnection";
  edges: Array<DailyCompletedTodosCountEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyExerciseTotals = {
  __typename?: "DailyExerciseTotals";
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  workoutsList?: Maybe<Array<Maybe<Workout>>>;
};

export type DailyExerciseTotalsAggregateSelection = {
  __typename?: "DailyExerciseTotalsAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCaloriesBurned: FloatAggregateSelection;
  totalDurationMinutes: FloatAggregateSelection;
  totalWorkouts: FloatAggregateSelection;
};

export type DailyExerciseTotalsConnection = {
  __typename?: "DailyExerciseTotalsConnection";
  edges: Array<DailyExerciseTotalsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyExerciseTotalsCreateInput = {
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyExerciseTotalsEdge = {
  __typename?: "DailyExerciseTotalsEdge";
  cursor: Scalars["String"]["output"];
  node: DailyExerciseTotals;
};

export type DailyExerciseTotalsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyExerciseTotalsSort objects to sort DailyExerciseTotals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyExerciseTotalsSort>>;
};

/** Fields to sort DailyExerciseTotals by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyExerciseTotalsSort object. */
export type DailyExerciseTotalsSort = {
  totalCaloriesBurned?: InputMaybe<SortDirection>;
  totalDurationMinutes?: InputMaybe<SortDirection>;
  totalWorkouts?: InputMaybe<SortDirection>;
};

export type DailyExerciseTotalsUpdateInput = {
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyExerciseTotalsWhere = {
  AND?: InputMaybe<Array<DailyExerciseTotalsWhere>>;
  NOT?: InputMaybe<DailyExerciseTotalsWhere>;
  OR?: InputMaybe<Array<DailyExerciseTotalsWhere>>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCaloriesBurned_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalDurationMinutes_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalWorkouts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotals = {
  __typename?: "DailyNutritionTotals";
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
};

export type DailyNutritionTotalsAggregateSelection = {
  __typename?: "DailyNutritionTotalsAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCalories: FloatAggregateSelection;
  totalCarbs: FloatAggregateSelection;
  totalFats: FloatAggregateSelection;
  totalProteins: FloatAggregateSelection;
};

export type DailyNutritionTotalsConnection = {
  __typename?: "DailyNutritionTotalsConnection";
  edges: Array<DailyNutritionTotalsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyNutritionTotalsCreateInput = {
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotalsEdge = {
  __typename?: "DailyNutritionTotalsEdge";
  cursor: Scalars["String"]["output"];
  node: DailyNutritionTotals;
};

export type DailyNutritionTotalsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyNutritionTotalsSort objects to sort DailyNutritionTotals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyNutritionTotalsSort>>;
};

/** Fields to sort DailyNutritionTotals by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyNutritionTotalsSort object. */
export type DailyNutritionTotalsSort = {
  totalCalories?: InputMaybe<SortDirection>;
  totalCarbs?: InputMaybe<SortDirection>;
  totalFats?: InputMaybe<SortDirection>;
  totalProteins?: InputMaybe<SortDirection>;
};

export type DailyNutritionTotalsUpdateInput = {
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotalsWhere = {
  AND?: InputMaybe<Array<DailyNutritionTotalsWhere>>;
  NOT?: InputMaybe<DailyNutritionTotalsWhere>;
  OR?: InputMaybe<Array<DailyNutritionTotalsWhere>>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCalories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCarbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalFats_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalProteins_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailySummariesConnection = {
  __typename?: "DailySummariesConnection";
  edges: Array<DailySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailySummary = {
  __typename?: "DailySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
  usersHasSummary: Array<User>;
  usersHasSummaryAggregate?: Maybe<DailySummaryUserUsersHasSummaryAggregationSelection>;
  usersHasSummaryConnection: DailySummaryUsersHasSummaryConnection;
};

export type DailySummaryUsersHasSummaryArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionSort>>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryAggregateSelection = {
  __typename?: "DailySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type DailySummaryConnectInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
};

export type DailySummaryConnectOrCreateInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
};

export type DailySummaryConnectWhere = {
  node: DailySummaryWhere;
};

export type DailySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
  usersHasSummary?: InputMaybe<DailySummaryUsersHasSummaryFieldInput>;
};

export type DailySummaryDeleteInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryDeleteFieldInput>>;
};

export type DailySummaryDisconnectInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryDisconnectFieldInput>>;
};

export type DailySummaryEdge = {
  __typename?: "DailySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: DailySummary;
};

export type DailySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailySummarySort objects to sort DailySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailySummarySort>>;
};

export type DailySummaryRelationInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
};

/** Fields to sort DailySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailySummarySort object. */
export type DailySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type DailySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryUpdateFieldInput>>;
};

export type DailySummaryUserUsersHasSummaryAggregationSelection = {
  __typename?: "DailySummaryUserUsersHasSummaryAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<DailySummaryUserUsersHasSummaryNodeAggregateSelection>;
};

export type DailySummaryUserUsersHasSummaryNodeAggregateSelection = {
  __typename?: "DailySummaryUserUsersHasSummaryNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type DailySummaryUsersHasSummaryAggregateInput = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryAggregateInput>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryAggregateInput>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<DailySummaryUsersHasSummaryNodeAggregationWhereInput>;
};

export type DailySummaryUsersHasSummaryConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type DailySummaryUsersHasSummaryConnectOrCreateFieldInput = {
  onCreate: DailySummaryUsersHasSummaryConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type DailySummaryUsersHasSummaryConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type DailySummaryUsersHasSummaryConnection = {
  __typename?: "DailySummaryUsersHasSummaryConnection";
  edges: Array<DailySummaryUsersHasSummaryRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailySummaryUsersHasSummaryConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type DailySummaryUsersHasSummaryConnectionWhere = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionWhere>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryCreateFieldInput = {
  node: UserCreateInput;
};

export type DailySummaryUsersHasSummaryDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryUsersHasSummaryDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryUsersHasSummaryFieldInput = {
  connect?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
};

export type DailySummaryUsersHasSummaryNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailySummaryUsersHasSummaryRelationship = {
  __typename?: "DailySummaryUsersHasSummaryRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type DailySummaryUsersHasSummaryUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type DailySummaryUsersHasSummaryUpdateFieldInput = {
  connect?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
  delete?: InputMaybe<Array<DailySummaryUsersHasSummaryDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DailySummaryUsersHasSummaryDisconnectFieldInput>>;
  update?: InputMaybe<DailySummaryUsersHasSummaryUpdateConnectionInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryWhere = {
  AND?: InputMaybe<Array<DailySummaryWhere>>;
  NOT?: InputMaybe<DailySummaryWhere>;
  OR?: InputMaybe<Array<DailySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummaryAggregate?: InputMaybe<DailySummaryUsersHasSummaryAggregateInput>;
  /** Return DailySummaries where all of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_ALL?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where none of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_NONE?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where one of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SINGLE?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where some of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SOME?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where all of the related Users match this filter */
  usersHasSummary_ALL?: InputMaybe<UserWhere>;
  /** Return DailySummaries where none of the related Users match this filter */
  usersHasSummary_NONE?: InputMaybe<UserWhere>;
  /** Return DailySummaries where one of the related Users match this filter */
  usersHasSummary_SINGLE?: InputMaybe<UserWhere>;
  /** Return DailySummaries where some of the related Users match this filter */
  usersHasSummary_SOME?: InputMaybe<UserWhere>;
};

export type DateTimeAggregateSelection = {
  __typename?: "DateTimeAggregateSelection";
  max?: Maybe<Scalars["DateTime"]["output"]>;
  min?: Maybe<Scalars["DateTime"]["output"]>;
};

export type Degree = {
  __typename?: "Degree";
  coursesHasCourse: Array<Course>;
  coursesHasCourseAggregate?: Maybe<DegreeCourseCoursesHasCourseAggregationSelection>;
  coursesHasCourseConnection: DegreeCoursesHasCourseConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  schoolsHasDegree: Array<School>;
  schoolsHasDegreeAggregate?: Maybe<DegreeSchoolSchoolsHasDegreeAggregationSelection>;
  schoolsHasDegreeConnection: DegreeSchoolsHasDegreeConnection;
  usersEnrolledIn: Array<User>;
  usersEnrolledInAggregate?: Maybe<DegreeUserUsersEnrolledInAggregationSelection>;
  usersEnrolledInConnection: DegreeUsersEnrolledInConnection;
};

export type DegreeCoursesHasCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type DegreeCoursesHasCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type DegreeCoursesHasCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DegreeCoursesHasCourseConnectionSort>>;
  where?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
};

export type DegreeSchoolsHasDegreeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type DegreeSchoolsHasDegreeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SchoolWhere>;
};

export type DegreeSchoolsHasDegreeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectionSort>>;
  where?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
};

export type DegreeUsersEnrolledInArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type DegreeUsersEnrolledInAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type DegreeUsersEnrolledInConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DegreeUsersEnrolledInConnectionSort>>;
  where?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
};

export type DegreeAggregateSelection = {
  __typename?: "DegreeAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type DegreeConnectInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseConnectFieldInput>>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInConnectFieldInput>>;
};

export type DegreeConnectOrCreateInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseConnectOrCreateFieldInput>>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectOrCreateFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInConnectOrCreateFieldInput>>;
};

export type DegreeConnectOrCreateWhere = {
  node: DegreeUniqueWhere;
};

export type DegreeConnectWhere = {
  node: DegreeWhere;
};

export type DegreeCourseCoursesHasCourseAggregationSelection = {
  __typename?: "DegreeCourseCoursesHasCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<DegreeCourseCoursesHasCourseNodeAggregateSelection>;
};

export type DegreeCourseCoursesHasCourseNodeAggregateSelection = {
  __typename?: "DegreeCourseCoursesHasCourseNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type DegreeCoursesHasCourseAggregateInput = {
  AND?: InputMaybe<Array<DegreeCoursesHasCourseAggregateInput>>;
  NOT?: InputMaybe<DegreeCoursesHasCourseAggregateInput>;
  OR?: InputMaybe<Array<DegreeCoursesHasCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<DegreeCoursesHasCourseNodeAggregationWhereInput>;
};

export type DegreeCoursesHasCourseConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type DegreeCoursesHasCourseConnectOrCreateFieldInput = {
  onCreate: DegreeCoursesHasCourseConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type DegreeCoursesHasCourseConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type DegreeCoursesHasCourseConnection = {
  __typename?: "DegreeCoursesHasCourseConnection";
  edges: Array<DegreeCoursesHasCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreeCoursesHasCourseConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type DegreeCoursesHasCourseConnectionWhere = {
  AND?: InputMaybe<Array<DegreeCoursesHasCourseConnectionWhere>>;
  NOT?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
  OR?: InputMaybe<Array<DegreeCoursesHasCourseConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type DegreeCoursesHasCourseCreateFieldInput = {
  node: CourseCreateInput;
};

export type DegreeCoursesHasCourseDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
};

export type DegreeCoursesHasCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
};

export type DegreeCoursesHasCourseFieldInput = {
  connect?: InputMaybe<Array<DegreeCoursesHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeCoursesHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeCoursesHasCourseCreateFieldInput>>;
};

export type DegreeCoursesHasCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DegreeCoursesHasCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DegreeCoursesHasCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DegreeCoursesHasCourseNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreeCoursesHasCourseRelationship = {
  __typename?: "DegreeCoursesHasCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type DegreeCoursesHasCourseUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type DegreeCoursesHasCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<DegreeCoursesHasCourseConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeCoursesHasCourseConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeCoursesHasCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<DegreeCoursesHasCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DegreeCoursesHasCourseDisconnectFieldInput>>;
  update?: InputMaybe<DegreeCoursesHasCourseUpdateConnectionInput>;
  where?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
};

export type DegreeCreateInput = {
  coursesHasCourse?: InputMaybe<DegreeCoursesHasCourseFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  schoolsHasDegree?: InputMaybe<DegreeSchoolsHasDegreeFieldInput>;
  usersEnrolledIn?: InputMaybe<DegreeUsersEnrolledInFieldInput>;
};

export type DegreeDeleteInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseDeleteFieldInput>>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeDeleteFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInDeleteFieldInput>>;
};

export type DegreeDisconnectInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseDisconnectFieldInput>>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeDisconnectFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInDisconnectFieldInput>>;
};

export type DegreeEdge = {
  __typename?: "DegreeEdge";
  cursor: Scalars["String"]["output"];
  node: Degree;
};

export type DegreeOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
};

export type DegreeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DegreeSort objects to sort Degrees by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DegreeSort>>;
};

export type DegreeRelationInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseCreateFieldInput>>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeCreateFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInCreateFieldInput>>;
};

export type DegreeResponse = {
  __typename?: "DegreeResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Degree>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DegreeResponseAggregateSelection = {
  __typename?: "DegreeResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type DegreeResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreeResponseEdge = {
  __typename?: "DegreeResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DegreeResponse;
};

export type DegreeResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DegreeResponseSort objects to sort DegreeResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DegreeResponseSort>>;
};

/** Fields to sort DegreeResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DegreeResponseSort object. */
export type DegreeResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DegreeResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreeResponseWhere = {
  AND?: InputMaybe<Array<DegreeResponseWhere>>;
  NOT?: InputMaybe<DegreeResponseWhere>;
  OR?: InputMaybe<Array<DegreeResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreeResponsesConnection = {
  __typename?: "DegreeResponsesConnection";
  edges: Array<DegreeResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreeSchoolSchoolsHasDegreeAggregationSelection = {
  __typename?: "DegreeSchoolSchoolsHasDegreeAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<DegreeSchoolSchoolsHasDegreeNodeAggregateSelection>;
};

export type DegreeSchoolSchoolsHasDegreeNodeAggregateSelection = {
  __typename?: "DegreeSchoolSchoolsHasDegreeNodeAggregateSelection";
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type DegreeSchoolsHasDegreeAggregateInput = {
  AND?: InputMaybe<Array<DegreeSchoolsHasDegreeAggregateInput>>;
  NOT?: InputMaybe<DegreeSchoolsHasDegreeAggregateInput>;
  OR?: InputMaybe<Array<DegreeSchoolsHasDegreeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<DegreeSchoolsHasDegreeNodeAggregationWhereInput>;
};

export type DegreeSchoolsHasDegreeConnectFieldInput = {
  connect?: InputMaybe<Array<SchoolConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SchoolConnectWhere>;
};

export type DegreeSchoolsHasDegreeConnectOrCreateFieldInput = {
  onCreate: DegreeSchoolsHasDegreeConnectOrCreateFieldInputOnCreate;
  where: SchoolConnectOrCreateWhere;
};

export type DegreeSchoolsHasDegreeConnectOrCreateFieldInputOnCreate = {
  node: SchoolOnCreateInput;
};

export type DegreeSchoolsHasDegreeConnection = {
  __typename?: "DegreeSchoolsHasDegreeConnection";
  edges: Array<DegreeSchoolsHasDegreeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreeSchoolsHasDegreeConnectionSort = {
  node?: InputMaybe<SchoolSort>;
};

export type DegreeSchoolsHasDegreeConnectionWhere = {
  AND?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectionWhere>>;
  NOT?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
  OR?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectionWhere>>;
  node?: InputMaybe<SchoolWhere>;
};

export type DegreeSchoolsHasDegreeCreateFieldInput = {
  node: SchoolCreateInput;
};

export type DegreeSchoolsHasDegreeDeleteFieldInput = {
  delete?: InputMaybe<SchoolDeleteInput>;
  where?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
};

export type DegreeSchoolsHasDegreeDisconnectFieldInput = {
  disconnect?: InputMaybe<SchoolDisconnectInput>;
  where?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
};

export type DegreeSchoolsHasDegreeFieldInput = {
  connect?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeSchoolsHasDegreeCreateFieldInput>>;
};

export type DegreeSchoolsHasDegreeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DegreeSchoolsHasDegreeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DegreeSchoolsHasDegreeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DegreeSchoolsHasDegreeNodeAggregationWhereInput>>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreeSchoolsHasDegreeRelationship = {
  __typename?: "DegreeSchoolsHasDegreeRelationship";
  cursor: Scalars["String"]["output"];
  node: School;
};

export type DegreeSchoolsHasDegreeUpdateConnectionInput = {
  node?: InputMaybe<SchoolUpdateInput>;
};

export type DegreeSchoolsHasDegreeUpdateFieldInput = {
  connect?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeSchoolsHasDegreeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeSchoolsHasDegreeCreateFieldInput>>;
  delete?: InputMaybe<Array<DegreeSchoolsHasDegreeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DegreeSchoolsHasDegreeDisconnectFieldInput>>;
  update?: InputMaybe<DegreeSchoolsHasDegreeUpdateConnectionInput>;
  where?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
};

/** Fields to sort Degrees by. The order in which sorts are applied is not guaranteed when specifying many fields in one DegreeSort object. */
export type DegreeSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type DegreeUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DegreeUpdateInput = {
  coursesHasCourse?: InputMaybe<Array<DegreeCoursesHasCourseUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  schoolsHasDegree?: InputMaybe<Array<DegreeSchoolsHasDegreeUpdateFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<DegreeUsersEnrolledInUpdateFieldInput>>;
};

export type DegreeUserUsersEnrolledInAggregationSelection = {
  __typename?: "DegreeUserUsersEnrolledInAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<DegreeUserUsersEnrolledInEdgeAggregateSelection>;
  node?: Maybe<DegreeUserUsersEnrolledInNodeAggregateSelection>;
};

export type DegreeUserUsersEnrolledInEdgeAggregateSelection = {
  __typename?: "DegreeUserUsersEnrolledInEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type DegreeUserUsersEnrolledInNodeAggregateSelection = {
  __typename?: "DegreeUserUsersEnrolledInNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type DegreeUsersEnrolledInAggregateInput = {
  AND?: InputMaybe<Array<DegreeUsersEnrolledInAggregateInput>>;
  NOT?: InputMaybe<DegreeUsersEnrolledInAggregateInput>;
  OR?: InputMaybe<Array<DegreeUsersEnrolledInAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInDegreePropertiesAggregationWhereInput>;
  node?: InputMaybe<DegreeUsersEnrolledInNodeAggregationWhereInput>;
};

export type DegreeUsersEnrolledInConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: EnrolledInDegreePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type DegreeUsersEnrolledInConnectOrCreateFieldInput = {
  onCreate: DegreeUsersEnrolledInConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type DegreeUsersEnrolledInConnectOrCreateFieldInputOnCreate = {
  edge: EnrolledInDegreePropertiesCreateInput;
  node: UserOnCreateInput;
};

export type DegreeUsersEnrolledInConnection = {
  __typename?: "DegreeUsersEnrolledInConnection";
  edges: Array<DegreeUsersEnrolledInRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreeUsersEnrolledInConnectionSort = {
  edge?: InputMaybe<EnrolledInDegreePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type DegreeUsersEnrolledInConnectionWhere = {
  AND?: InputMaybe<Array<DegreeUsersEnrolledInConnectionWhere>>;
  NOT?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
  OR?: InputMaybe<Array<DegreeUsersEnrolledInConnectionWhere>>;
  edge?: InputMaybe<EnrolledInDegreePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type DegreeUsersEnrolledInCreateFieldInput = {
  edge: EnrolledInDegreePropertiesCreateInput;
  node: UserCreateInput;
};

export type DegreeUsersEnrolledInDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
};

export type DegreeUsersEnrolledInDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
};

export type DegreeUsersEnrolledInFieldInput = {
  connect?: InputMaybe<Array<DegreeUsersEnrolledInConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeUsersEnrolledInConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeUsersEnrolledInCreateFieldInput>>;
};

export type DegreeUsersEnrolledInNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DegreeUsersEnrolledInNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DegreeUsersEnrolledInNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DegreeUsersEnrolledInNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreeUsersEnrolledInRelationship = {
  __typename?: "DegreeUsersEnrolledInRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: EnrolledInDegreeProperties;
};

export type DegreeUsersEnrolledInUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInDegreePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type DegreeUsersEnrolledInUpdateFieldInput = {
  connect?: InputMaybe<Array<DegreeUsersEnrolledInConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DegreeUsersEnrolledInConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DegreeUsersEnrolledInCreateFieldInput>>;
  delete?: InputMaybe<Array<DegreeUsersEnrolledInDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DegreeUsersEnrolledInDisconnectFieldInput>>;
  update?: InputMaybe<DegreeUsersEnrolledInUpdateConnectionInput>;
  where?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
};

export type DegreeWhere = {
  AND?: InputMaybe<Array<DegreeWhere>>;
  NOT?: InputMaybe<DegreeWhere>;
  OR?: InputMaybe<Array<DegreeWhere>>;
  coursesHasCourseAggregate?: InputMaybe<DegreeCoursesHasCourseAggregateInput>;
  /** Return Degrees where all of the related DegreeCoursesHasCourseConnections match this filter */
  coursesHasCourseConnection_ALL?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
  /** Return Degrees where none of the related DegreeCoursesHasCourseConnections match this filter */
  coursesHasCourseConnection_NONE?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
  /** Return Degrees where one of the related DegreeCoursesHasCourseConnections match this filter */
  coursesHasCourseConnection_SINGLE?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
  /** Return Degrees where some of the related DegreeCoursesHasCourseConnections match this filter */
  coursesHasCourseConnection_SOME?: InputMaybe<DegreeCoursesHasCourseConnectionWhere>;
  /** Return Degrees where all of the related Courses match this filter */
  coursesHasCourse_ALL?: InputMaybe<CourseWhere>;
  /** Return Degrees where none of the related Courses match this filter */
  coursesHasCourse_NONE?: InputMaybe<CourseWhere>;
  /** Return Degrees where one of the related Courses match this filter */
  coursesHasCourse_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Degrees where some of the related Courses match this filter */
  coursesHasCourse_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  schoolsHasDegreeAggregate?: InputMaybe<DegreeSchoolsHasDegreeAggregateInput>;
  /** Return Degrees where all of the related DegreeSchoolsHasDegreeConnections match this filter */
  schoolsHasDegreeConnection_ALL?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
  /** Return Degrees where none of the related DegreeSchoolsHasDegreeConnections match this filter */
  schoolsHasDegreeConnection_NONE?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
  /** Return Degrees where one of the related DegreeSchoolsHasDegreeConnections match this filter */
  schoolsHasDegreeConnection_SINGLE?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
  /** Return Degrees where some of the related DegreeSchoolsHasDegreeConnections match this filter */
  schoolsHasDegreeConnection_SOME?: InputMaybe<DegreeSchoolsHasDegreeConnectionWhere>;
  /** Return Degrees where all of the related Schools match this filter */
  schoolsHasDegree_ALL?: InputMaybe<SchoolWhere>;
  /** Return Degrees where none of the related Schools match this filter */
  schoolsHasDegree_NONE?: InputMaybe<SchoolWhere>;
  /** Return Degrees where one of the related Schools match this filter */
  schoolsHasDegree_SINGLE?: InputMaybe<SchoolWhere>;
  /** Return Degrees where some of the related Schools match this filter */
  schoolsHasDegree_SOME?: InputMaybe<SchoolWhere>;
  usersEnrolledInAggregate?: InputMaybe<DegreeUsersEnrolledInAggregateInput>;
  /** Return Degrees where all of the related DegreeUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_ALL?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
  /** Return Degrees where none of the related DegreeUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_NONE?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
  /** Return Degrees where one of the related DegreeUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_SINGLE?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
  /** Return Degrees where some of the related DegreeUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_SOME?: InputMaybe<DegreeUsersEnrolledInConnectionWhere>;
  /** Return Degrees where all of the related Users match this filter */
  usersEnrolledIn_ALL?: InputMaybe<UserWhere>;
  /** Return Degrees where none of the related Users match this filter */
  usersEnrolledIn_NONE?: InputMaybe<UserWhere>;
  /** Return Degrees where one of the related Users match this filter */
  usersEnrolledIn_SINGLE?: InputMaybe<UserWhere>;
  /** Return Degrees where some of the related Users match this filter */
  usersEnrolledIn_SOME?: InputMaybe<UserWhere>;
};

export type DegreesBySchoolByQueryData = {
  __typename?: "DegreesBySchoolByQueryData";
  degrees?: Maybe<Array<Maybe<Degree>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type DegreesBySchoolByQueryDataAggregateSelection = {
  __typename?: "DegreesBySchoolByQueryDataAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCount: IntAggregateSelection;
};

export type DegreesBySchoolByQueryDataConnection = {
  __typename?: "DegreesBySchoolByQueryDataConnection";
  edges: Array<DegreesBySchoolByQueryDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreesBySchoolByQueryDataCreateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreesBySchoolByQueryDataEdge = {
  __typename?: "DegreesBySchoolByQueryDataEdge";
  cursor: Scalars["String"]["output"];
  node: DegreesBySchoolByQueryData;
};

export type DegreesBySchoolByQueryDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DegreesBySchoolByQueryDataSort objects to sort DegreesBySchoolByQueryData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DegreesBySchoolByQueryDataSort>>;
};

/** Fields to sort DegreesBySchoolByQueryData by. The order in which sorts are applied is not guaranteed when specifying many fields in one DegreesBySchoolByQueryDataSort object. */
export type DegreesBySchoolByQueryDataSort = {
  totalCount?: InputMaybe<SortDirection>;
};

export type DegreesBySchoolByQueryDataUpdateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreesBySchoolByQueryDataWhere = {
  AND?: InputMaybe<Array<DegreesBySchoolByQueryDataWhere>>;
  NOT?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
  OR?: InputMaybe<Array<DegreesBySchoolByQueryDataWhere>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DegreesBySchoolByQueryResponse = {
  __typename?: "DegreesBySchoolByQueryResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<DegreesBySchoolByQueryData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DegreesBySchoolByQueryResponseAggregateSelection = {
  __typename?: "DegreesBySchoolByQueryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type DegreesBySchoolByQueryResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreesBySchoolByQueryResponseEdge = {
  __typename?: "DegreesBySchoolByQueryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DegreesBySchoolByQueryResponse;
};

export type DegreesBySchoolByQueryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DegreesBySchoolByQueryResponseSort objects to sort DegreesBySchoolByQueryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DegreesBySchoolByQueryResponseSort>>;
};

/** Fields to sort DegreesBySchoolByQueryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DegreesBySchoolByQueryResponseSort object. */
export type DegreesBySchoolByQueryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DegreesBySchoolByQueryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreesBySchoolByQueryResponseWhere = {
  AND?: InputMaybe<Array<DegreesBySchoolByQueryResponseWhere>>;
  NOT?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
  OR?: InputMaybe<Array<DegreesBySchoolByQueryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DegreesBySchoolByQueryResponsesConnection = {
  __typename?: "DegreesBySchoolByQueryResponsesConnection";
  edges: Array<DegreesBySchoolByQueryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DegreesConnection = {
  __typename?: "DegreesConnection";
  edges: Array<DegreeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentAssignmentResponse = {
  __typename?: "DeleteContentAssignmentResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteContentAssignmentResponseAggregateSelection = {
  __typename?: "DeleteContentAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteContentAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type DeleteContentAssignmentResponseEdge = {
  __typename?: "DeleteContentAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentAssignmentResponse;
};

export type DeleteContentAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentAssignmentResponseSort objects to sort DeleteContentAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentAssignmentResponseSort>>;
};

/** Fields to sort DeleteContentAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentAssignmentResponseSort object. */
export type DeleteContentAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteContentAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentAssignmentResponseWhere = {
  AND?: InputMaybe<Array<DeleteContentAssignmentResponseWhere>>;
  NOT?: InputMaybe<DeleteContentAssignmentResponseWhere>;
  OR?: InputMaybe<Array<DeleteContentAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentAssignmentResponsesConnection = {
  __typename?: "DeleteContentAssignmentResponsesConnection";
  edges: Array<DeleteContentAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentAssignmentVariables = {
  __typename?: "DeleteContentAssignmentVariables";
  assignmentName?: Maybe<Scalars["String"]["output"]>;
  courseId?: Maybe<Scalars["String"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteContentAssignmentVariablesAggregateSelection = {
  __typename?: "DeleteContentAssignmentVariablesAggregateSelection";
  assignmentName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  moduleName: StringAggregateSelection;
};

export type DeleteContentAssignmentVariablesConnection = {
  __typename?: "DeleteContentAssignmentVariablesConnection";
  edges: Array<DeleteContentAssignmentVariablesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentAssignmentVariablesCreateInput = {
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentAssignmentVariablesEdge = {
  __typename?: "DeleteContentAssignmentVariablesEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentAssignmentVariables;
};

export type DeleteContentAssignmentVariablesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentAssignmentVariablesSort objects to sort DeleteContentAssignmentVariables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentAssignmentVariablesSort>>;
};

/** Fields to sort DeleteContentAssignmentVariables by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentAssignmentVariablesSort object. */
export type DeleteContentAssignmentVariablesSort = {
  assignmentName?: InputMaybe<SortDirection>;
  courseId?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
};

export type DeleteContentAssignmentVariablesUpdateInput = {
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentAssignmentVariablesWhere = {
  AND?: InputMaybe<Array<DeleteContentAssignmentVariablesWhere>>;
  NOT?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
  OR?: InputMaybe<Array<DeleteContentAssignmentVariablesWhere>>;
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignmentName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleResponse = {
  __typename?: "DeleteContentModuleResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteContentModuleResponseAggregateSelection = {
  __typename?: "DeleteContentModuleResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteContentModuleResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type DeleteContentModuleResponseEdge = {
  __typename?: "DeleteContentModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentModuleResponse;
};

export type DeleteContentModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentModuleResponseSort objects to sort DeleteContentModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentModuleResponseSort>>;
};

/** Fields to sort DeleteContentModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentModuleResponseSort object. */
export type DeleteContentModuleResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteContentModuleResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentModuleResponseWhere = {
  AND?: InputMaybe<Array<DeleteContentModuleResponseWhere>>;
  NOT?: InputMaybe<DeleteContentModuleResponseWhere>;
  OR?: InputMaybe<Array<DeleteContentModuleResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentModuleResponsesConnection = {
  __typename?: "DeleteContentModuleResponsesConnection";
  edges: Array<DeleteContentModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentModuleVariables = {
  __typename?: "DeleteContentModuleVariables";
  courseId?: Maybe<Scalars["String"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteContentModuleVariablesAggregateSelection = {
  __typename?: "DeleteContentModuleVariablesAggregateSelection";
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  moduleName: StringAggregateSelection;
};

export type DeleteContentModuleVariablesConnection = {
  __typename?: "DeleteContentModuleVariablesConnection";
  edges: Array<DeleteContentModuleVariablesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentModuleVariablesCreateInput = {
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleVariablesEdge = {
  __typename?: "DeleteContentModuleVariablesEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentModuleVariables;
};

export type DeleteContentModuleVariablesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentModuleVariablesSort objects to sort DeleteContentModuleVariables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentModuleVariablesSort>>;
};

/** Fields to sort DeleteContentModuleVariables by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentModuleVariablesSort object. */
export type DeleteContentModuleVariablesSort = {
  courseId?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
};

export type DeleteContentModuleVariablesUpdateInput = {
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleVariablesWhere = {
  AND?: InputMaybe<Array<DeleteContentModuleVariablesWhere>>;
  NOT?: InputMaybe<DeleteContentModuleVariablesWhere>;
  OR?: InputMaybe<Array<DeleteContentModuleVariablesWhere>>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteCourseResponse = {
  __typename?: "DeleteCourseResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type DeleteCourseResponseAggregateSelection = {
  __typename?: "DeleteCourseResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteCourseResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type DeleteCourseResponseEdge = {
  __typename?: "DeleteCourseResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteCourseResponse;
};

export type DeleteCourseResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteCourseResponseSort objects to sort DeleteCourseResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteCourseResponseSort>>;
};

/** Fields to sort DeleteCourseResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteCourseResponseSort object. */
export type DeleteCourseResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteCourseResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteCourseResponseWhere = {
  AND?: InputMaybe<Array<DeleteCourseResponseWhere>>;
  NOT?: InputMaybe<DeleteCourseResponseWhere>;
  OR?: InputMaybe<Array<DeleteCourseResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteCourseResponsesConnection = {
  __typename?: "DeleteCourseResponsesConnection";
  edges: Array<DeleteCourseResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Information about the number of nodes and relationships deleted during a delete mutation */
export type DeleteInfo = {
  __typename?: "DeleteInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesDeleted: Scalars["Int"]["output"];
  relationshipsDeleted: Scalars["Int"]["output"];
};

export type DeviceSession = {
  __typename?: "DeviceSession";
  id: Scalars["String"]["output"];
  usersHasSession: Array<User>;
  usersHasSessionAggregate?: Maybe<DeviceSessionUserUsersHasSessionAggregationSelection>;
  usersHasSessionConnection: DeviceSessionUsersHasSessionConnection;
};

export type DeviceSessionUsersHasSessionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionSort>>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionAggregateSelection = {
  __typename?: "DeviceSessionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: StringAggregateSelection;
};

export type DeviceSessionConnectInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
};

export type DeviceSessionConnectOrCreateInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectOrCreateFieldInput>>;
};

export type DeviceSessionConnectWhere = {
  node: DeviceSessionWhere;
};

export type DeviceSessionCreateInput = {
  id: Scalars["String"]["input"];
  usersHasSession?: InputMaybe<DeviceSessionUsersHasSessionFieldInput>;
};

export type DeviceSessionDeleteInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionDeleteFieldInput>>;
};

export type DeviceSessionDisconnectInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionDisconnectFieldInput>>;
};

export type DeviceSessionEdge = {
  __typename?: "DeviceSessionEdge";
  cursor: Scalars["String"]["output"];
  node: DeviceSession;
};

export type DeviceSessionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeviceSessionSort objects to sort DeviceSessions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeviceSessionSort>>;
};

export type DeviceSessionRelationInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
};

/** Fields to sort DeviceSessions by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceSessionSort object. */
export type DeviceSessionSort = {
  id?: InputMaybe<SortDirection>;
};

export type DeviceSessionUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionUpdateFieldInput>>;
};

export type DeviceSessionUserUsersHasSessionAggregationSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<DeviceSessionUserUsersHasSessionEdgeAggregateSelection>;
  node?: Maybe<DeviceSessionUserUsersHasSessionNodeAggregateSelection>;
};

export type DeviceSessionUserUsersHasSessionEdgeAggregateSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionEdgeAggregateSelection";
  createdAt: DateTimeAggregateSelection;
  expiresAt: DateTimeAggregateSelection;
};

export type DeviceSessionUserUsersHasSessionNodeAggregateSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type DeviceSessionUsersHasSessionAggregateInput = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionAggregateInput>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionAggregateInput>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  node?: InputMaybe<DeviceSessionUsersHasSessionNodeAggregationWhereInput>;
};

export type DeviceSessionUsersHasSessionConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasSessionPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type DeviceSessionUsersHasSessionConnectOrCreateFieldInput = {
  onCreate: DeviceSessionUsersHasSessionConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type DeviceSessionUsersHasSessionConnectOrCreateFieldInputOnCreate = {
  edge: HasSessionPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type DeviceSessionUsersHasSessionConnection = {
  __typename?: "DeviceSessionUsersHasSessionConnection";
  edges: Array<DeviceSessionUsersHasSessionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeviceSessionUsersHasSessionConnectionSort = {
  edge?: InputMaybe<HasSessionPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type DeviceSessionUsersHasSessionConnectionWhere = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionWhere>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionWhere>>;
  edge?: InputMaybe<HasSessionPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionCreateFieldInput = {
  edge: HasSessionPropertiesCreateInput;
  node: UserCreateInput;
};

export type DeviceSessionUsersHasSessionDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionUsersHasSessionDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionUsersHasSessionFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
};

export type DeviceSessionUsersHasSessionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DeviceSessionUsersHasSessionRelationship = {
  __typename?: "DeviceSessionUsersHasSessionRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasSessionProperties;
};

export type DeviceSessionUsersHasSessionUpdateConnectionInput = {
  edge?: InputMaybe<HasSessionPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type DeviceSessionUsersHasSessionUpdateFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
  delete?: InputMaybe<Array<DeviceSessionUsersHasSessionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DeviceSessionUsersHasSessionDisconnectFieldInput>>;
  update?: InputMaybe<DeviceSessionUsersHasSessionUpdateConnectionInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionWhere = {
  AND?: InputMaybe<Array<DeviceSessionWhere>>;
  NOT?: InputMaybe<DeviceSessionWhere>;
  OR?: InputMaybe<Array<DeviceSessionWhere>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSessionAggregate?: InputMaybe<DeviceSessionUsersHasSessionAggregateInput>;
  /** Return DeviceSessions where all of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_ALL?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where none of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_NONE?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where one of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_SINGLE?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where some of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_SOME?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where all of the related Users match this filter */
  usersHasSession_ALL?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where none of the related Users match this filter */
  usersHasSession_NONE?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where one of the related Users match this filter */
  usersHasSession_SINGLE?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where some of the related Users match this filter */
  usersHasSession_SOME?: InputMaybe<UserWhere>;
};

export type DeviceSessionsConnection = {
  __typename?: "DeviceSessionsConnection";
  edges: Array<DeviceSessionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type EmotionalState = {
  __typename?: "EmotionalState";
  id?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["DateTime"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
};

export type EmotionalStateAggregateSelection = {
  __typename?: "EmotionalStateAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  intensity: IntAggregateSelection;
  name: StringAggregateSelection;
  timestamp: DateTimeAggregateSelection;
  trigger: StringAggregateSelection;
};

export type EmotionalStateCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStateEdge = {
  __typename?: "EmotionalStateEdge";
  cursor: Scalars["String"]["output"];
  node: EmotionalState;
};

export type EmotionalStateOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more EmotionalStateSort objects to sort EmotionalStates by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EmotionalStateSort>>;
};

/** Fields to sort EmotionalStates by. The order in which sorts are applied is not guaranteed when specifying many fields in one EmotionalStateSort object. */
export type EmotionalStateSort = {
  id?: InputMaybe<SortDirection>;
  intensity?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
  trigger?: InputMaybe<SortDirection>;
};

export type EmotionalStateUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStateWhere = {
  AND?: InputMaybe<Array<EmotionalStateWhere>>;
  NOT?: InputMaybe<EmotionalStateWhere>;
  OR?: InputMaybe<Array<EmotionalStateWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  intensity_LT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  timestamp_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  timestamp_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  trigger_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trigger_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trigger_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trigger_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStatesConnection = {
  __typename?: "EmotionalStatesConnection";
  edges: Array<EmotionalStateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/**
 * The edge properties for the following fields:
 * * User.enrolledDegrees
 * * Degree.usersEnrolledIn
 */
export type EnrolledInDegreeProperties = {
  __typename?: "EnrolledInDegreeProperties";
  created: Scalars["DateTime"]["output"];
  invitationToken?: Maybe<Scalars["String"]["output"]>;
  invitationTokenExpiry?: Maybe<Scalars["DateTime"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  pendingDegreeEnrollmentInvite?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EnrolledInDegreePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<EnrolledInDegreePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<EnrolledInDegreePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<EnrolledInDegreePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EnrolledInDegreePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  pendingDegreeEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnrolledInDegreePropertiesSort = {
  created?: InputMaybe<SortDirection>;
  invitationToken?: InputMaybe<SortDirection>;
  invitationTokenExpiry?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  pendingDegreeEnrollmentInvite?: InputMaybe<SortDirection>;
};

export type EnrolledInDegreePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  pendingDegreeEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnrolledInDegreePropertiesWhere = {
  AND?: InputMaybe<Array<EnrolledInDegreePropertiesWhere>>;
  NOT?: InputMaybe<EnrolledInDegreePropertiesWhere>;
  OR?: InputMaybe<Array<EnrolledInDegreePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  invitationTokenExpiry_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  invitationToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  pendingDegreeEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.enrolledInTopics
 * * User.enrolledCourses
 * * Topic.usersEnrolledIn
 * * Course.enrolledStudents
 */
export type EnrolledInProperties = {
  __typename?: "EnrolledInProperties";
  created: Scalars["DateTime"]["output"];
  invitationToken?: Maybe<Scalars["String"]["output"]>;
  invitationTokenExpiry?: Maybe<Scalars["DateTime"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  pendingCourseEnrollmentInvite?: Maybe<Scalars["Boolean"]["output"]>;
};

export type EnrolledInPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<EnrolledInPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<EnrolledInPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<EnrolledInPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type EnrolledInPropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnrolledInPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  invitationToken?: InputMaybe<SortDirection>;
  invitationTokenExpiry?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  pendingCourseEnrollmentInvite?: InputMaybe<SortDirection>;
};

export type EnrolledInPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type EnrolledInPropertiesWhere = {
  AND?: InputMaybe<Array<EnrolledInPropertiesWhere>>;
  NOT?: InputMaybe<EnrolledInPropertiesWhere>;
  OR?: InputMaybe<Array<EnrolledInPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  invitationTokenExpiry_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  invitationToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Event = {
  __typename?: "Event";
  date?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type EventAggregateSelection = {
  __typename?: "EventAggregateSelection";
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type EventCreateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventEdge = {
  __typename?: "EventEdge";
  cursor: Scalars["String"]["output"];
  node: Event;
};

export type EventOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more EventSort objects to sort Events by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EventSort>>;
};

/** Fields to sort Events by. The order in which sorts are applied is not guaranteed when specifying many fields in one EventSort object. */
export type EventSort = {
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type EventUpdateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventWhere = {
  AND?: InputMaybe<Array<EventWhere>>;
  NOT?: InputMaybe<EventWhere>;
  OR?: InputMaybe<Array<EventWhere>>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventsConnection = {
  __typename?: "EventsConnection";
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExportCsvResponse = {
  __typename?: "ExportCsvResponse";
  csvData?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ExportCsvResponseAggregateSelection = {
  __typename?: "ExportCsvResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  csvData: StringAggregateSelection;
  message: StringAggregateSelection;
};

export type ExportCsvResponseCreateInput = {
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ExportCsvResponseEdge = {
  __typename?: "ExportCsvResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ExportCsvResponse;
};

export type ExportCsvResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ExportCsvResponseSort objects to sort ExportCsvResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ExportCsvResponseSort>>;
};

/** Fields to sort ExportCsvResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ExportCsvResponseSort object. */
export type ExportCsvResponseSort = {
  csvData?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ExportCsvResponseUpdateInput = {
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExportCsvResponseWhere = {
  AND?: InputMaybe<Array<ExportCsvResponseWhere>>;
  NOT?: InputMaybe<ExportCsvResponseWhere>;
  OR?: InputMaybe<Array<ExportCsvResponseWhere>>;
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  csvData_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  csvData_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  csvData_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  csvData_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExportCsvResponsesConnection = {
  __typename?: "ExportCsvResponsesConnection";
  edges: Array<ExportCsvResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyDataCourse = {
  __typename?: "FacultyDataCourse";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type FacultyDataCourseAggregateSelection = {
  __typename?: "FacultyDataCourseAggregateSelection";
  count: Scalars["Int"]["output"];
  id: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type FacultyDataCourseCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataCourseEdge = {
  __typename?: "FacultyDataCourseEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyDataCourse;
};

export type FacultyDataCourseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyDataCourseSort objects to sort FacultyDataCourses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyDataCourseSort>>;
};

/** Fields to sort FacultyDataCourses by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyDataCourseSort object. */
export type FacultyDataCourseSort = {
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type FacultyDataCourseUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataCourseWhere = {
  AND?: InputMaybe<Array<FacultyDataCourseWhere>>;
  NOT?: InputMaybe<FacultyDataCourseWhere>;
  OR?: InputMaybe<Array<FacultyDataCourseWhere>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataCoursesConnection = {
  __typename?: "FacultyDataCoursesConnection";
  edges: Array<FacultyDataCourseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyDataRole = {
  __typename?: "FacultyDataRole";
  name?: Maybe<Scalars["String"]["output"]>;
};

export type FacultyDataRoleAggregateSelection = {
  __typename?: "FacultyDataRoleAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type FacultyDataRoleCreateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataRoleEdge = {
  __typename?: "FacultyDataRoleEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyDataRole;
};

export type FacultyDataRoleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyDataRoleSort objects to sort FacultyDataRoles by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyDataRoleSort>>;
};

/** Fields to sort FacultyDataRoles by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyDataRoleSort object. */
export type FacultyDataRoleSort = {
  name?: InputMaybe<SortDirection>;
};

export type FacultyDataRoleUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataRoleWhere = {
  AND?: InputMaybe<Array<FacultyDataRoleWhere>>;
  NOT?: InputMaybe<FacultyDataRoleWhere>;
  OR?: InputMaybe<Array<FacultyDataRoleWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataRolesConnection = {
  __typename?: "FacultyDataRolesConnection";
  edges: Array<FacultyDataRoleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyDataSessionDeviceSession = {
  __typename?: "FacultyDataSessionDeviceSession";
  id?: Maybe<Scalars["String"]["output"]>;
};

export type FacultyDataSessionDeviceSessionAggregateSelection = {
  __typename?: "FacultyDataSessionDeviceSessionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: StringAggregateSelection;
};

export type FacultyDataSessionDeviceSessionCreateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataSessionDeviceSessionEdge = {
  __typename?: "FacultyDataSessionDeviceSessionEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyDataSessionDeviceSession;
};

export type FacultyDataSessionDeviceSessionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyDataSessionDeviceSessionSort objects to sort FacultyDataSessionDeviceSessions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyDataSessionDeviceSessionSort>>;
};

/** Fields to sort FacultyDataSessionDeviceSessions by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyDataSessionDeviceSessionSort object. */
export type FacultyDataSessionDeviceSessionSort = {
  id?: InputMaybe<SortDirection>;
};

export type FacultyDataSessionDeviceSessionUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataSessionDeviceSessionWhere = {
  AND?: InputMaybe<Array<FacultyDataSessionDeviceSessionWhere>>;
  NOT?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
  OR?: InputMaybe<Array<FacultyDataSessionDeviceSessionWhere>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyDataSessionDeviceSessionsConnection = {
  __typename?: "FacultyDataSessionDeviceSessionsConnection";
  edges: Array<FacultyDataSessionDeviceSessionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyQueryResponse = {
  __typename?: "FacultyQueryResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<FacultyQueryResponseWithCountData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type FacultyQueryResponseAggregateSelection = {
  __typename?: "FacultyQueryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type FacultyQueryResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FacultyQueryResponseData = {
  __typename?: "FacultyQueryResponseData";
  biography?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  hasRoleRoles: Array<FacultyDataRole>;
  hasSessionDeviceSessions: Array<FacultyDataSessionDeviceSession>;
  id?: Maybe<Scalars["String"]["output"]>;
  meetingLink?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  pendingFacultyInvite?: Maybe<Scalars["Boolean"]["output"]>;
  profilePicture?: Maybe<Scalars["String"]["output"]>;
  teachingCourses: Array<FacultyDataCourse>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type FacultyQueryResponseDataAggregateSelection = {
  __typename?: "FacultyQueryResponseDataAggregateSelection";
  biography: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  email: StringAggregateSelection;
  id: StringAggregateSelection;
  meetingLink: StringAggregateSelection;
  name: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type FacultyQueryResponseDataConnection = {
  __typename?: "FacultyQueryResponseDataConnection";
  edges: Array<FacultyQueryResponseDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyQueryResponseDataCreateInput = {
  biography?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyQueryResponseDataEdge = {
  __typename?: "FacultyQueryResponseDataEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyQueryResponseData;
};

export type FacultyQueryResponseDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyQueryResponseDataSort objects to sort FacultyQueryResponseData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyQueryResponseDataSort>>;
};

/** Fields to sort FacultyQueryResponseData by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyQueryResponseDataSort object. */
export type FacultyQueryResponseDataSort = {
  biography?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  meetingLink?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  pendingFacultyInvite?: InputMaybe<SortDirection>;
  profilePicture?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type FacultyQueryResponseDataUpdateInput = {
  biography?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyQueryResponseDataWhere = {
  AND?: InputMaybe<Array<FacultyQueryResponseDataWhere>>;
  NOT?: InputMaybe<FacultyQueryResponseDataWhere>;
  OR?: InputMaybe<Array<FacultyQueryResponseDataWhere>>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  biography_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  biography_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  biography_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  biography_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meetingLink_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  profilePicture_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  username_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  username_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FacultyQueryResponseEdge = {
  __typename?: "FacultyQueryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyQueryResponse;
};

export type FacultyQueryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyQueryResponseSort objects to sort FacultyQueryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyQueryResponseSort>>;
};

/** Fields to sort FacultyQueryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyQueryResponseSort object. */
export type FacultyQueryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type FacultyQueryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FacultyQueryResponseWhere = {
  AND?: InputMaybe<Array<FacultyQueryResponseWhere>>;
  NOT?: InputMaybe<FacultyQueryResponseWhere>;
  OR?: InputMaybe<Array<FacultyQueryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FacultyQueryResponseWithCountData = {
  __typename?: "FacultyQueryResponseWithCountData";
  faculties: Array<FacultyQueryResponseData>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type FacultyQueryResponseWithCountDataAggregateSelection = {
  __typename?: "FacultyQueryResponseWithCountDataAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCount: IntAggregateSelection;
};

export type FacultyQueryResponseWithCountDataConnection = {
  __typename?: "FacultyQueryResponseWithCountDataConnection";
  edges: Array<FacultyQueryResponseWithCountDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FacultyQueryResponseWithCountDataCreateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FacultyQueryResponseWithCountDataEdge = {
  __typename?: "FacultyQueryResponseWithCountDataEdge";
  cursor: Scalars["String"]["output"];
  node: FacultyQueryResponseWithCountData;
};

export type FacultyQueryResponseWithCountDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FacultyQueryResponseWithCountDataSort objects to sort FacultyQueryResponseWithCountData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FacultyQueryResponseWithCountDataSort>>;
};

/** Fields to sort FacultyQueryResponseWithCountData by. The order in which sorts are applied is not guaranteed when specifying many fields in one FacultyQueryResponseWithCountDataSort object. */
export type FacultyQueryResponseWithCountDataSort = {
  totalCount?: InputMaybe<SortDirection>;
};

export type FacultyQueryResponseWithCountDataUpdateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FacultyQueryResponseWithCountDataWhere = {
  AND?: InputMaybe<Array<FacultyQueryResponseWithCountDataWhere>>;
  NOT?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
  OR?: InputMaybe<Array<FacultyQueryResponseWithCountDataWhere>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FacultyQueryResponsesConnection = {
  __typename?: "FacultyQueryResponsesConnection";
  edges: Array<FacultyQueryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Feedback = {
  __typename?: "Feedback";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackAggregateSelection = {
  __typename?: "FeedbackAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  id: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type FeedbackCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbackEdge = {
  __typename?: "FeedbackEdge";
  cursor: Scalars["String"]["output"];
  node: Feedback;
};

export type FeedbackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FeedbackSort objects to sort Feedbacks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FeedbackSort>>;
};

/** Fields to sort Feedbacks by. The order in which sorts are applied is not guaranteed when specifying many fields in one FeedbackSort object. */
export type FeedbackSort = {
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type FeedbackUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbackWhere = {
  AND?: InputMaybe<Array<FeedbackWhere>>;
  NOT?: InputMaybe<FeedbackWhere>;
  OR?: InputMaybe<Array<FeedbackWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbacksConnection = {
  __typename?: "FeedbacksConnection";
  edges: Array<FeedbackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Field = {
  __typename?: "Field";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type FieldAggregateSelection = {
  __typename?: "FieldAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type FieldCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type FieldEdge = {
  __typename?: "FieldEdge";
  cursor: Scalars["String"]["output"];
  node: Field;
};

export type FieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FieldSort objects to sort Fields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FieldSort>>;
};

/** Fields to sort Fields by. The order in which sorts are applied is not guaranteed when specifying many fields in one FieldSort object. */
export type FieldSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type FieldUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FieldWhere = {
  AND?: InputMaybe<Array<FieldWhere>>;
  NOT?: InputMaybe<FieldWhere>;
  OR?: InputMaybe<Array<FieldWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FieldsConnection = {
  __typename?: "FieldsConnection";
  edges: Array<FieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type File = {
  __typename?: "File";
  assignmentActivity?: Maybe<Assignment>;
  assignmentActivityAggregate?: Maybe<FileAssignmentAssignmentActivityAggregationSelection>;
  assignmentActivityConnection: FileAssignmentActivityConnection;
  bucketName: Scalars["String"]["output"];
  created: Scalars["DateTime"]["output"];
  fileName: Scalars["String"]["output"];
  filePath: Scalars["String"]["output"];
  fileURL: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  message?: Maybe<Message>;
  messageAggregate?: Maybe<FileMessageMessageAggregationSelection>;
  messageConnection: FileMessageConnection;
  resourceActivity?: Maybe<AxioResource>;
  resourceActivityAggregate?: Maybe<FileAxioResourceResourceActivityAggregationSelection>;
  resourceActivityConnection: FileResourceActivityConnection;
  status: FileStatus;
  submission?: Maybe<Submission>;
  submissionAggregate?: Maybe<FileSubmissionSubmissionAggregationSelection>;
  submissionConnection: FileSubmissionConnection;
  user?: Maybe<User>;
  userAggregate?: Maybe<FileUserUserAggregationSelection>;
  userConnection: FileUserConnection;
};

export type FileAssignmentActivityArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type FileAssignmentActivityAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type FileAssignmentActivityConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FileAssignmentActivityConnectionSort>>;
  where?: InputMaybe<FileAssignmentActivityConnectionWhere>;
};

export type FileMessageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type FileMessageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type FileMessageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FileMessageConnectionSort>>;
  where?: InputMaybe<FileMessageConnectionWhere>;
};

export type FileResourceActivityArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AxioResourceOptions>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type FileResourceActivityAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type FileResourceActivityConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FileResourceActivityConnectionSort>>;
  where?: InputMaybe<FileResourceActivityConnectionWhere>;
};

export type FileSubmissionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubmissionOptions>;
  where?: InputMaybe<SubmissionWhere>;
};

export type FileSubmissionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubmissionWhere>;
};

export type FileSubmissionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FileSubmissionConnectionSort>>;
  where?: InputMaybe<FileSubmissionConnectionWhere>;
};

export type FileUserArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type FileUserAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type FileUserConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FileUserConnectionSort>>;
  where?: InputMaybe<FileUserConnectionWhere>;
};

export type FileAggregateSelection = {
  __typename?: "FileAggregateSelection";
  bucketName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type FileAssignmentActivityAggregateInput = {
  AND?: InputMaybe<Array<FileAssignmentActivityAggregateInput>>;
  NOT?: InputMaybe<FileAssignmentActivityAggregateInput>;
  OR?: InputMaybe<Array<FileAssignmentActivityAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<FileAssignmentActivityNodeAggregationWhereInput>;
};

export type FileAssignmentActivityConnectFieldInput = {
  connect?: InputMaybe<AssignmentConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type FileAssignmentActivityConnectOrCreateFieldInput = {
  onCreate: FileAssignmentActivityConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type FileAssignmentActivityConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type FileAssignmentActivityConnection = {
  __typename?: "FileAssignmentActivityConnection";
  edges: Array<FileAssignmentActivityRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileAssignmentActivityConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type FileAssignmentActivityConnectionWhere = {
  AND?: InputMaybe<Array<FileAssignmentActivityConnectionWhere>>;
  NOT?: InputMaybe<FileAssignmentActivityConnectionWhere>;
  OR?: InputMaybe<Array<FileAssignmentActivityConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type FileAssignmentActivityCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type FileAssignmentActivityDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<FileAssignmentActivityConnectionWhere>;
};

export type FileAssignmentActivityDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<FileAssignmentActivityConnectionWhere>;
};

export type FileAssignmentActivityFieldInput = {
  connect?: InputMaybe<FileAssignmentActivityConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileAssignmentActivityConnectOrCreateFieldInput>;
  create?: InputMaybe<FileAssignmentActivityCreateFieldInput>;
};

export type FileAssignmentActivityNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FileAssignmentActivityNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FileAssignmentActivityNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FileAssignmentActivityNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FileAssignmentActivityRelationship = {
  __typename?: "FileAssignmentActivityRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type FileAssignmentActivityUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type FileAssignmentActivityUpdateFieldInput = {
  connect?: InputMaybe<FileAssignmentActivityConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileAssignmentActivityConnectOrCreateFieldInput>;
  create?: InputMaybe<FileAssignmentActivityCreateFieldInput>;
  delete?: InputMaybe<FileAssignmentActivityDeleteFieldInput>;
  disconnect?: InputMaybe<FileAssignmentActivityDisconnectFieldInput>;
  update?: InputMaybe<FileAssignmentActivityUpdateConnectionInput>;
  where?: InputMaybe<FileAssignmentActivityConnectionWhere>;
};

export type FileAssignmentAssignmentActivityAggregationSelection = {
  __typename?: "FileAssignmentAssignmentActivityAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<FileAssignmentAssignmentActivityNodeAggregateSelection>;
};

export type FileAssignmentAssignmentActivityNodeAggregateSelection = {
  __typename?: "FileAssignmentAssignmentActivityNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type FileAxioResourceResourceActivityAggregationSelection = {
  __typename?: "FileAxioResourceResourceActivityAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<FileAxioResourceResourceActivityNodeAggregateSelection>;
};

export type FileAxioResourceResourceActivityNodeAggregateSelection = {
  __typename?: "FileAxioResourceResourceActivityNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  moduleId: IdAggregateSelection;
  name: StringAggregateSelection;
  order: IntAggregateSelection;
  videoUrl: StringAggregateSelection;
};

export type FileConnectInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityConnectFieldInput>;
  message?: InputMaybe<FileMessageConnectFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityConnectFieldInput>;
  submission?: InputMaybe<FileSubmissionConnectFieldInput>;
  user?: InputMaybe<FileUserConnectFieldInput>;
};

export type FileConnectOrCreateInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityConnectOrCreateFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityConnectOrCreateFieldInput>;
  submission?: InputMaybe<FileSubmissionConnectOrCreateFieldInput>;
  user?: InputMaybe<FileUserConnectOrCreateFieldInput>;
};

export type FileConnectWhere = {
  node: FileWhere;
};

export type FileCreateInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityFieldInput>;
  bucketName: Scalars["String"]["input"];
  created: Scalars["DateTime"]["input"];
  fileName: Scalars["String"]["input"];
  filePath: Scalars["String"]["input"];
  fileURL: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  message?: InputMaybe<FileMessageFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityFieldInput>;
  status: FileStatus;
  submission?: InputMaybe<FileSubmissionFieldInput>;
  user?: InputMaybe<FileUserFieldInput>;
};

export type FileDeleteInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityDeleteFieldInput>;
  message?: InputMaybe<FileMessageDeleteFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityDeleteFieldInput>;
  submission?: InputMaybe<FileSubmissionDeleteFieldInput>;
  user?: InputMaybe<FileUserDeleteFieldInput>;
};

export type FileDisconnectInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityDisconnectFieldInput>;
  message?: InputMaybe<FileMessageDisconnectFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityDisconnectFieldInput>;
  submission?: InputMaybe<FileSubmissionDisconnectFieldInput>;
  user?: InputMaybe<FileUserDisconnectFieldInput>;
};

export type FileEdge = {
  __typename?: "FileEdge";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type FileInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  fileData?: InputMaybe<Scalars["String"]["input"]>;
  fileId: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
  fileURL?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FileMessageAggregateInput = {
  AND?: InputMaybe<Array<FileMessageAggregateInput>>;
  NOT?: InputMaybe<FileMessageAggregateInput>;
  OR?: InputMaybe<Array<FileMessageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<FileMessageNodeAggregationWhereInput>;
};

export type FileMessageConnectFieldInput = {
  connect?: InputMaybe<MessageConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type FileMessageConnection = {
  __typename?: "FileMessageConnection";
  edges: Array<FileMessageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileMessageConnectionSort = {
  node?: InputMaybe<MessageSort>;
};

export type FileMessageConnectionWhere = {
  AND?: InputMaybe<Array<FileMessageConnectionWhere>>;
  NOT?: InputMaybe<FileMessageConnectionWhere>;
  OR?: InputMaybe<Array<FileMessageConnectionWhere>>;
  node?: InputMaybe<MessageWhere>;
};

export type FileMessageCreateFieldInput = {
  node: MessageCreateInput;
};

export type FileMessageDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<FileMessageConnectionWhere>;
};

export type FileMessageDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<FileMessageConnectionWhere>;
};

export type FileMessageFieldInput = {
  connect?: InputMaybe<FileMessageConnectFieldInput>;
  create?: InputMaybe<FileMessageCreateFieldInput>;
};

export type FileMessageMessageAggregationSelection = {
  __typename?: "FileMessageMessageAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<FileMessageMessageNodeAggregateSelection>;
};

export type FileMessageMessageNodeAggregateSelection = {
  __typename?: "FileMessageMessageNodeAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type FileMessageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FileMessageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FileMessageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FileMessageNodeAggregationWhereInput>>;
  aspectDetails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FileMessageRelationship = {
  __typename?: "FileMessageRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type FileMessageUpdateConnectionInput = {
  node?: InputMaybe<MessageUpdateInput>;
};

export type FileMessageUpdateFieldInput = {
  connect?: InputMaybe<FileMessageConnectFieldInput>;
  create?: InputMaybe<FileMessageCreateFieldInput>;
  delete?: InputMaybe<FileMessageDeleteFieldInput>;
  disconnect?: InputMaybe<FileMessageDisconnectFieldInput>;
  update?: InputMaybe<FileMessageUpdateConnectionInput>;
  where?: InputMaybe<FileMessageConnectionWhere>;
};

export type FileOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FileSort objects to sort Files by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FileSort>>;
};

export type FileRelationInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityCreateFieldInput>;
  message?: InputMaybe<FileMessageCreateFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityCreateFieldInput>;
  submission?: InputMaybe<FileSubmissionCreateFieldInput>;
  user?: InputMaybe<FileUserCreateFieldInput>;
};

export type FileResourceActivityAggregateInput = {
  AND?: InputMaybe<Array<FileResourceActivityAggregateInput>>;
  NOT?: InputMaybe<FileResourceActivityAggregateInput>;
  OR?: InputMaybe<Array<FileResourceActivityAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<FileResourceActivityNodeAggregationWhereInput>;
};

export type FileResourceActivityConnectFieldInput = {
  connect?: InputMaybe<AxioResourceConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AxioResourceConnectWhere>;
};

export type FileResourceActivityConnectOrCreateFieldInput = {
  onCreate: FileResourceActivityConnectOrCreateFieldInputOnCreate;
  where: AxioResourceConnectOrCreateWhere;
};

export type FileResourceActivityConnectOrCreateFieldInputOnCreate = {
  node: AxioResourceOnCreateInput;
};

export type FileResourceActivityConnection = {
  __typename?: "FileResourceActivityConnection";
  edges: Array<FileResourceActivityRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileResourceActivityConnectionSort = {
  node?: InputMaybe<AxioResourceSort>;
};

export type FileResourceActivityConnectionWhere = {
  AND?: InputMaybe<Array<FileResourceActivityConnectionWhere>>;
  NOT?: InputMaybe<FileResourceActivityConnectionWhere>;
  OR?: InputMaybe<Array<FileResourceActivityConnectionWhere>>;
  node?: InputMaybe<AxioResourceWhere>;
};

export type FileResourceActivityCreateFieldInput = {
  node: AxioResourceCreateInput;
};

export type FileResourceActivityDeleteFieldInput = {
  delete?: InputMaybe<AxioResourceDeleteInput>;
  where?: InputMaybe<FileResourceActivityConnectionWhere>;
};

export type FileResourceActivityDisconnectFieldInput = {
  disconnect?: InputMaybe<AxioResourceDisconnectInput>;
  where?: InputMaybe<FileResourceActivityConnectionWhere>;
};

export type FileResourceActivityFieldInput = {
  connect?: InputMaybe<FileResourceActivityConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileResourceActivityConnectOrCreateFieldInput>;
  create?: InputMaybe<FileResourceActivityCreateFieldInput>;
};

export type FileResourceActivityNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FileResourceActivityNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FileResourceActivityNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FileResourceActivityNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FileResourceActivityRelationship = {
  __typename?: "FileResourceActivityRelationship";
  cursor: Scalars["String"]["output"];
  node: AxioResource;
};

export type FileResourceActivityUpdateConnectionInput = {
  node?: InputMaybe<AxioResourceUpdateInput>;
};

export type FileResourceActivityUpdateFieldInput = {
  connect?: InputMaybe<FileResourceActivityConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileResourceActivityConnectOrCreateFieldInput>;
  create?: InputMaybe<FileResourceActivityCreateFieldInput>;
  delete?: InputMaybe<FileResourceActivityDeleteFieldInput>;
  disconnect?: InputMaybe<FileResourceActivityDisconnectFieldInput>;
  update?: InputMaybe<FileResourceActivityUpdateConnectionInput>;
  where?: InputMaybe<FileResourceActivityConnectionWhere>;
};

export type FileResponse = {
  __typename?: "FileResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<File>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type FileResponseAggregateSelection = {
  __typename?: "FileResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type FileResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type FileResponseEdge = {
  __typename?: "FileResponseEdge";
  cursor: Scalars["String"]["output"];
  node: FileResponse;
};

export type FileResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FileResponseSort objects to sort FileResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FileResponseSort>>;
};

/** Fields to sort FileResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one FileResponseSort object. */
export type FileResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type FileResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FileResponseWhere = {
  AND?: InputMaybe<Array<FileResponseWhere>>;
  NOT?: InputMaybe<FileResponseWhere>;
  OR?: InputMaybe<Array<FileResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FileResponsesConnection = {
  __typename?: "FileResponsesConnection";
  edges: Array<FileResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Files by. The order in which sorts are applied is not guaranteed when specifying many fields in one FileSort object. */
export type FileSort = {
  bucketName?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  fileName?: InputMaybe<SortDirection>;
  filePath?: InputMaybe<SortDirection>;
  fileURL?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export enum FileStatus {
  Completed = "completed",
  Pending = "pending",
  Processing = "processing",
  Rejected = "rejected",
  Uploaded = "uploaded",
}

export type FileSubmissionAggregateInput = {
  AND?: InputMaybe<Array<FileSubmissionAggregateInput>>;
  NOT?: InputMaybe<FileSubmissionAggregateInput>;
  OR?: InputMaybe<Array<FileSubmissionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<FileSubmissionNodeAggregationWhereInput>;
};

export type FileSubmissionConnectFieldInput = {
  connect?: InputMaybe<SubmissionConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubmissionConnectWhere>;
};

export type FileSubmissionConnectOrCreateFieldInput = {
  onCreate: FileSubmissionConnectOrCreateFieldInputOnCreate;
  where: SubmissionConnectOrCreateWhere;
};

export type FileSubmissionConnectOrCreateFieldInputOnCreate = {
  node: SubmissionOnCreateInput;
};

export type FileSubmissionConnection = {
  __typename?: "FileSubmissionConnection";
  edges: Array<FileSubmissionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileSubmissionConnectionSort = {
  node?: InputMaybe<SubmissionSort>;
};

export type FileSubmissionConnectionWhere = {
  AND?: InputMaybe<Array<FileSubmissionConnectionWhere>>;
  NOT?: InputMaybe<FileSubmissionConnectionWhere>;
  OR?: InputMaybe<Array<FileSubmissionConnectionWhere>>;
  node?: InputMaybe<SubmissionWhere>;
};

export type FileSubmissionCreateFieldInput = {
  node: SubmissionCreateInput;
};

export type FileSubmissionDeleteFieldInput = {
  delete?: InputMaybe<SubmissionDeleteInput>;
  where?: InputMaybe<FileSubmissionConnectionWhere>;
};

export type FileSubmissionDisconnectFieldInput = {
  disconnect?: InputMaybe<SubmissionDisconnectInput>;
  where?: InputMaybe<FileSubmissionConnectionWhere>;
};

export type FileSubmissionFieldInput = {
  connect?: InputMaybe<FileSubmissionConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileSubmissionConnectOrCreateFieldInput>;
  create?: InputMaybe<FileSubmissionCreateFieldInput>;
};

export type FileSubmissionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FileSubmissionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FileSubmissionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FileSubmissionNodeAggregationWhereInput>>;
  completedAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FileSubmissionRelationship = {
  __typename?: "FileSubmissionRelationship";
  cursor: Scalars["String"]["output"];
  node: Submission;
};

export type FileSubmissionSubmissionAggregationSelection = {
  __typename?: "FileSubmissionSubmissionAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<FileSubmissionSubmissionNodeAggregateSelection>;
};

export type FileSubmissionSubmissionNodeAggregateSelection = {
  __typename?: "FileSubmissionSubmissionNodeAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  created: DateTimeAggregateSelection;
  feedback: StringAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type FileSubmissionUpdateConnectionInput = {
  node?: InputMaybe<SubmissionUpdateInput>;
};

export type FileSubmissionUpdateFieldInput = {
  connect?: InputMaybe<FileSubmissionConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileSubmissionConnectOrCreateFieldInput>;
  create?: InputMaybe<FileSubmissionCreateFieldInput>;
  delete?: InputMaybe<FileSubmissionDeleteFieldInput>;
  disconnect?: InputMaybe<FileSubmissionDisconnectFieldInput>;
  update?: InputMaybe<FileSubmissionUpdateConnectionInput>;
  where?: InputMaybe<FileSubmissionConnectionWhere>;
};

export type FileUpdateInput = {
  assignmentActivity?: InputMaybe<FileAssignmentActivityUpdateFieldInput>;
  bucketName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  filePath?: InputMaybe<Scalars["String"]["input"]>;
  fileURL?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<FileMessageUpdateFieldInput>;
  resourceActivity?: InputMaybe<FileResourceActivityUpdateFieldInput>;
  status?: InputMaybe<FileStatus>;
  submission?: InputMaybe<FileSubmissionUpdateFieldInput>;
  user?: InputMaybe<FileUserUpdateFieldInput>;
};

export type FileUploadResponse = {
  __typename?: "FileUploadResponse";
  data?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type FileUploadResponseAggregateSelection = {
  __typename?: "FileUploadResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type FileUploadResponseCreateInput = {
  data?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type FileUploadResponseEdge = {
  __typename?: "FileUploadResponseEdge";
  cursor: Scalars["String"]["output"];
  node: FileUploadResponse;
};

export type FileUploadResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FileUploadResponseSort objects to sort FileUploadResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FileUploadResponseSort>>;
};

/** Fields to sort FileUploadResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one FileUploadResponseSort object. */
export type FileUploadResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type FileUploadResponseUpdateInput = {
  data?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  data_POP?: InputMaybe<Scalars["Int"]["input"]>;
  data_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FileUploadResponseWhere = {
  AND?: InputMaybe<Array<FileUploadResponseWhere>>;
  NOT?: InputMaybe<FileUploadResponseWhere>;
  OR?: InputMaybe<Array<FileUploadResponseWhere>>;
  data?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  data_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FileUploadResponsesConnection = {
  __typename?: "FileUploadResponsesConnection";
  edges: Array<FileUploadResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileUserAggregateInput = {
  AND?: InputMaybe<Array<FileUserAggregateInput>>;
  NOT?: InputMaybe<FileUserAggregateInput>;
  OR?: InputMaybe<Array<FileUserAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<FileUserNodeAggregationWhereInput>;
};

export type FileUserConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type FileUserConnectOrCreateFieldInput = {
  onCreate: FileUserConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type FileUserConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type FileUserConnection = {
  __typename?: "FileUserConnection";
  edges: Array<FileUserRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileUserConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type FileUserConnectionWhere = {
  AND?: InputMaybe<Array<FileUserConnectionWhere>>;
  NOT?: InputMaybe<FileUserConnectionWhere>;
  OR?: InputMaybe<Array<FileUserConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type FileUserCreateFieldInput = {
  node: UserCreateInput;
};

export type FileUserDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FileUserConnectionWhere>;
};

export type FileUserDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FileUserConnectionWhere>;
};

export type FileUserFieldInput = {
  connect?: InputMaybe<FileUserConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileUserConnectOrCreateFieldInput>;
  create?: InputMaybe<FileUserCreateFieldInput>;
};

export type FileUserNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FileUserNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FileUserNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FileUserNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FileUserRelationship = {
  __typename?: "FileUserRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type FileUserUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type FileUserUpdateFieldInput = {
  connect?: InputMaybe<FileUserConnectFieldInput>;
  connectOrCreate?: InputMaybe<FileUserConnectOrCreateFieldInput>;
  create?: InputMaybe<FileUserCreateFieldInput>;
  delete?: InputMaybe<FileUserDeleteFieldInput>;
  disconnect?: InputMaybe<FileUserDisconnectFieldInput>;
  update?: InputMaybe<FileUserUpdateConnectionInput>;
  where?: InputMaybe<FileUserConnectionWhere>;
};

export type FileUserUserAggregationSelection = {
  __typename?: "FileUserUserAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<FileUserUserNodeAggregateSelection>;
};

export type FileUserUserNodeAggregateSelection = {
  __typename?: "FileUserUserNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type FileWhere = {
  AND?: InputMaybe<Array<FileWhere>>;
  NOT?: InputMaybe<FileWhere>;
  OR?: InputMaybe<Array<FileWhere>>;
  assignmentActivity?: InputMaybe<AssignmentWhere>;
  assignmentActivityAggregate?: InputMaybe<FileAssignmentActivityAggregateInput>;
  assignmentActivityConnection?: InputMaybe<FileAssignmentActivityConnectionWhere>;
  assignmentActivityConnection_NOT?: InputMaybe<FileAssignmentActivityConnectionWhere>;
  assignmentActivity_NOT?: InputMaybe<AssignmentWhere>;
  bucketName?: InputMaybe<Scalars["String"]["input"]>;
  bucketName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  bucketName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  bucketName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  bucketName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  fileName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fileName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fileName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fileName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filePath?: InputMaybe<Scalars["String"]["input"]>;
  filePath_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  filePath_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filePath_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  filePath_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fileURL?: InputMaybe<Scalars["String"]["input"]>;
  fileURL_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fileURL_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fileURL_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fileURL_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<MessageWhere>;
  messageAggregate?: InputMaybe<FileMessageAggregateInput>;
  messageConnection?: InputMaybe<FileMessageConnectionWhere>;
  messageConnection_NOT?: InputMaybe<FileMessageConnectionWhere>;
  message_NOT?: InputMaybe<MessageWhere>;
  resourceActivity?: InputMaybe<AxioResourceWhere>;
  resourceActivityAggregate?: InputMaybe<FileResourceActivityAggregateInput>;
  resourceActivityConnection?: InputMaybe<FileResourceActivityConnectionWhere>;
  resourceActivityConnection_NOT?: InputMaybe<FileResourceActivityConnectionWhere>;
  resourceActivity_NOT?: InputMaybe<AxioResourceWhere>;
  status?: InputMaybe<FileStatus>;
  status_IN?: InputMaybe<Array<FileStatus>>;
  submission?: InputMaybe<SubmissionWhere>;
  submissionAggregate?: InputMaybe<FileSubmissionAggregateInput>;
  submissionConnection?: InputMaybe<FileSubmissionConnectionWhere>;
  submissionConnection_NOT?: InputMaybe<FileSubmissionConnectionWhere>;
  submission_NOT?: InputMaybe<SubmissionWhere>;
  user?: InputMaybe<UserWhere>;
  userAggregate?: InputMaybe<FileUserAggregateInput>;
  userConnection?: InputMaybe<FileUserConnectionWhere>;
  userConnection_NOT?: InputMaybe<FileUserConnectionWhere>;
  user_NOT?: InputMaybe<UserWhere>;
};

export type FilesConnection = {
  __typename?: "FilesConnection";
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FloatAggregateSelection = {
  __typename?: "FloatAggregateSelection";
  average?: Maybe<Scalars["Float"]["output"]>;
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
  sum?: Maybe<Scalars["Float"]["output"]>;
};

export type Food = {
  __typename?: "Food";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
};

export type FoodAggregateSelection = {
  __typename?: "FoodAggregateSelection";
  calories: FloatAggregateSelection;
  carbohydrates_grams: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  fat_grams: FloatAggregateSelection;
  fiber_grams: FloatAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  protein_grams: FloatAggregateSelection;
  serving_size: StringAggregateSelection;
  sodium_mg: FloatAggregateSelection;
  sugars_grams: FloatAggregateSelection;
};

export type FoodCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodEdge = {
  __typename?: "FoodEdge";
  cursor: Scalars["String"]["output"];
  node: Food;
};

export type FoodItem = {
  __typename?: "FoodItem";
  calories?: Maybe<Scalars["Float"]["output"]>;
  created: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sugar_grams?: Maybe<Scalars["Float"]["output"]>;
  usersHasFoodItem: Array<User>;
  usersHasFoodItemAggregate?: Maybe<FoodItemUserUsersHasFoodItemAggregationSelection>;
  usersHasFoodItemConnection: FoodItemUsersHasFoodItemConnection;
  usersundefined: Array<User>;
  usersundefinedAggregate?: Maybe<FoodItemUserUsersundefinedAggregationSelection>;
  usersundefinedConnection: FoodItemUsersundefinedConnection;
};

export type FoodItemUsersHasFoodItemArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionSort>>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersundefinedArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FoodItemUsersundefinedConnectionSort>>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemAggregateSelection = {
  __typename?: "FoodItemAggregateSelection";
  calories: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type FoodItemConnectInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
};

export type FoodItemConnectOrCreateInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectOrCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedConnectOrCreateFieldInput>>;
};

export type FoodItemConnectWhere = {
  node: FoodItemWhere;
};

export type FoodItemCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  created: Scalars["DateTime"]["input"];
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItem?: InputMaybe<FoodItemUsersHasFoodItemFieldInput>;
  usersundefined?: InputMaybe<FoodItemUsersundefinedFieldInput>;
};

export type FoodItemDeleteInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemDeleteFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedDeleteFieldInput>>;
};

export type FoodItemDisconnectInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemDisconnectFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedDisconnectFieldInput>>;
};

export type FoodItemEdge = {
  __typename?: "FoodItemEdge";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
};

export type FoodItemOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FoodItemSort objects to sort FoodItems by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FoodItemSort>>;
};

export type FoodItemRelationInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
};

/** Fields to sort FoodItems by. The order in which sorts are applied is not guaranteed when specifying many fields in one FoodItemSort object. */
export type FoodItemSort = {
  calories?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sugar_grams?: InputMaybe<SortDirection>;
};

export type FoodItemUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemUpdateFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedUpdateFieldInput>>;
};

export type FoodItemUserUsersHasFoodItemAggregationSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<FoodItemUserUsersHasFoodItemEdgeAggregateSelection>;
  node?: Maybe<FoodItemUserUsersHasFoodItemNodeAggregateSelection>;
};

export type FoodItemUserUsersHasFoodItemEdgeAggregateSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemEdgeAggregateSelection";
  consumption_date: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  meal: StringAggregateSelection;
  meal_date: StringAggregateSelection;
  meal_time: StringAggregateSelection;
  meal_type: StringAggregateSelection;
  portion: FloatAggregateSelection;
  quality: BigIntAggregateSelection;
  quantity: FloatAggregateSelection;
  strength: BigIntAggregateSelection;
  time: StringAggregateSelection;
};

export type FoodItemUserUsersHasFoodItemNodeAggregateSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type FoodItemUserUsersundefinedAggregationSelection = {
  __typename?: "FoodItemUserUsersundefinedAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<FoodItemUserUsersundefinedEdgeAggregateSelection>;
  node?: Maybe<FoodItemUserUsersundefinedNodeAggregateSelection>;
};

export type FoodItemUserUsersundefinedEdgeAggregateSelection = {
  __typename?: "FoodItemUserUsersundefinedEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type FoodItemUserUsersundefinedNodeAggregateSelection = {
  __typename?: "FoodItemUserUsersundefinedNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type FoodItemUsersHasFoodItemAggregateInput = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemAggregateInput>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemAggregateInput>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  node?: InputMaybe<FoodItemUsersHasFoodItemNodeAggregationWhereInput>;
};

export type FoodItemUsersHasFoodItemConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasFoodItemPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type FoodItemUsersHasFoodItemConnectOrCreateFieldInput = {
  onCreate: FoodItemUsersHasFoodItemConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type FoodItemUsersHasFoodItemConnectOrCreateFieldInputOnCreate = {
  edge: HasFoodItemPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type FoodItemUsersHasFoodItemConnection = {
  __typename?: "FoodItemUsersHasFoodItemConnection";
  edges: Array<FoodItemUsersHasFoodItemRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FoodItemUsersHasFoodItemConnectionSort = {
  edge?: InputMaybe<HasFoodItemPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type FoodItemUsersHasFoodItemConnectionWhere = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionWhere>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionWhere>>;
  edge?: InputMaybe<HasFoodItemPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemCreateFieldInput = {
  edge: HasFoodItemPropertiesCreateInput;
  node: UserCreateInput;
};

export type FoodItemUsersHasFoodItemDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersHasFoodItemDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersHasFoodItemFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
};

export type FoodItemUsersHasFoodItemNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FoodItemUsersHasFoodItemRelationship = {
  __typename?: "FoodItemUsersHasFoodItemRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasFoodItemProperties;
};

export type FoodItemUsersHasFoodItemUpdateConnectionInput = {
  edge?: InputMaybe<HasFoodItemPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type FoodItemUsersHasFoodItemUpdateFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
  delete?: InputMaybe<Array<FoodItemUsersHasFoodItemDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<FoodItemUsersHasFoodItemDisconnectFieldInput>>;
  update?: InputMaybe<FoodItemUsersHasFoodItemUpdateConnectionInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersundefinedAggregateInput = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedAggregateInput>>;
  NOT?: InputMaybe<FoodItemUsersundefinedAggregateInput>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<FoodItemUsersundefinedNodeAggregationWhereInput>;
};

export type FoodItemUsersundefinedConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type FoodItemUsersundefinedConnectOrCreateFieldInput = {
  onCreate: FoodItemUsersundefinedConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type FoodItemUsersundefinedConnectOrCreateFieldInputOnCreate = {
  edge: UndefinedPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type FoodItemUsersundefinedConnection = {
  __typename?: "FoodItemUsersundefinedConnection";
  edges: Array<FoodItemUsersundefinedRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FoodItemUsersundefinedConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type FoodItemUsersundefinedConnectionWhere = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedConnectionWhere>>;
  NOT?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: UserCreateInput;
};

export type FoodItemUsersundefinedDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemUsersundefinedDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemUsersundefinedFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<FoodItemUsersundefinedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
};

export type FoodItemUsersundefinedNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FoodItemUsersundefinedNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FoodItemUsersundefinedRelationship = {
  __typename?: "FoodItemUsersundefinedRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UndefinedProperties;
};

export type FoodItemUsersundefinedUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type FoodItemUsersundefinedUpdateFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<FoodItemUsersundefinedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
  delete?: InputMaybe<Array<FoodItemUsersundefinedDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<FoodItemUsersundefinedDisconnectFieldInput>>;
  update?: InputMaybe<FoodItemUsersundefinedUpdateConnectionInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemWhere = {
  AND?: InputMaybe<Array<FoodItemWhere>>;
  NOT?: InputMaybe<FoodItemWhere>;
  OR?: InputMaybe<Array<FoodItemWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugar_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItemAggregate?: InputMaybe<FoodItemUsersHasFoodItemAggregateInput>;
  /** Return FoodItems where all of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_ALL?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where none of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_NONE?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where one of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_SINGLE?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where some of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_SOME?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where all of the related Users match this filter */
  usersHasFoodItem_ALL?: InputMaybe<UserWhere>;
  /** Return FoodItems where none of the related Users match this filter */
  usersHasFoodItem_NONE?: InputMaybe<UserWhere>;
  /** Return FoodItems where one of the related Users match this filter */
  usersHasFoodItem_SINGLE?: InputMaybe<UserWhere>;
  /** Return FoodItems where some of the related Users match this filter */
  usersHasFoodItem_SOME?: InputMaybe<UserWhere>;
  usersundefinedAggregate?: InputMaybe<FoodItemUsersundefinedAggregateInput>;
  /** Return FoodItems where all of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_ALL?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where none of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_NONE?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where one of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_SINGLE?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where some of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_SOME?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where all of the related Users match this filter */
  usersundefined_ALL?: InputMaybe<UserWhere>;
  /** Return FoodItems where none of the related Users match this filter */
  usersundefined_NONE?: InputMaybe<UserWhere>;
  /** Return FoodItems where one of the related Users match this filter */
  usersundefined_SINGLE?: InputMaybe<UserWhere>;
  /** Return FoodItems where some of the related Users match this filter */
  usersundefined_SOME?: InputMaybe<UserWhere>;
};

export type FoodItemsConnection = {
  __typename?: "FoodItemsConnection";
  edges: Array<FoodItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Foods by. The order in which sorts are applied is not guaranteed when specifying many fields in one FoodSort object. */
export type FoodSort = {
  calories?: InputMaybe<SortDirection>;
  carbohydrates_grams?: InputMaybe<SortDirection>;
  fat_grams?: InputMaybe<SortDirection>;
  fiber_grams?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  protein_grams?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sodium_mg?: InputMaybe<SortDirection>;
  sugars_grams?: InputMaybe<SortDirection>;
};

export type FoodUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodWhere = {
  AND?: InputMaybe<Array<FoodWhere>>;
  NOT?: InputMaybe<FoodWhere>;
  OR?: InputMaybe<Array<FoodWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  carbohydrates_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fat_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fiber_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  protein_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sodium_mg_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugars_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodsConnection = {
  __typename?: "FoodsConnection";
  edges: Array<FoodEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FormattedAiTutor = {
  __typename?: "FormattedAITutor";
  courseId?: Maybe<Scalars["ID"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  enableVideoInsight?: Maybe<Scalars["Boolean"]["output"]>;
  hasChatChats?: Maybe<Array<Maybe<FormattedChat>>>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  objectives?: Maybe<Array<Maybe<FormattedObjective>>>;
  targetAudience?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  visualization?: Maybe<Visualization>;
};

export type FormattedAiTutorAggregateSelection = {
  __typename?: "FormattedAITutorAggregateSelection";
  count: Scalars["Int"]["output"];
  courseId: IdAggregateSelection;
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type FormattedAiTutorCreateInput = {
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type FormattedAiTutorEdge = {
  __typename?: "FormattedAITutorEdge";
  cursor: Scalars["String"]["output"];
  node: FormattedAiTutor;
};

export type FormattedAiTutorOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FormattedAITutorSort objects to sort FormattedAiTutors by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FormattedAiTutorSort>>;
};

/** Fields to sort FormattedAiTutors by. The order in which sorts are applied is not guaranteed when specifying many fields in one FormattedAITutorSort object. */
export type FormattedAiTutorSort = {
  courseId?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  enableVideoInsight?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  targetAudience?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type FormattedAiTutorUpdateInput = {
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedAiTutorWhere = {
  AND?: InputMaybe<Array<FormattedAiTutorWhere>>;
  NOT?: InputMaybe<FormattedAiTutorWhere>;
  OR?: InputMaybe<Array<FormattedAiTutorWhere>>;
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  targetAudience_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedAiTutorsConnection = {
  __typename?: "FormattedAiTutorsConnection";
  edges: Array<FormattedAiTutorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FormattedChat = {
  __typename?: "FormattedChat";
  id: Scalars["ID"]["output"];
};

export type FormattedChatAggregateSelection = {
  __typename?: "FormattedChatAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
};

export type FormattedChatCreateInput = {
  id: Scalars["ID"]["input"];
};

export type FormattedChatEdge = {
  __typename?: "FormattedChatEdge";
  cursor: Scalars["String"]["output"];
  node: FormattedChat;
};

export type FormattedChatOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FormattedChatSort objects to sort FormattedChats by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FormattedChatSort>>;
};

/** Fields to sort FormattedChats by. The order in which sorts are applied is not guaranteed when specifying many fields in one FormattedChatSort object. */
export type FormattedChatSort = {
  id?: InputMaybe<SortDirection>;
};

export type FormattedChatUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FormattedChatWhere = {
  AND?: InputMaybe<Array<FormattedChatWhere>>;
  NOT?: InputMaybe<FormattedChatWhere>;
  OR?: InputMaybe<Array<FormattedChatWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FormattedChatsConnection = {
  __typename?: "FormattedChatsConnection";
  edges: Array<FormattedChatEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FormattedObjective = {
  __typename?: "FormattedObjective";
  evalCriteria?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["ID"]["output"]>;
  objective: Scalars["String"]["output"];
  sequence?: Maybe<Scalars["Float"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type FormattedObjectiveAggregateSelection = {
  __typename?: "FormattedObjectiveAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
  status: StringAggregateSelection;
};

export type FormattedObjectiveCreateInput = {
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objective: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedObjectiveEdge = {
  __typename?: "FormattedObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: FormattedObjective;
};

export type FormattedObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FormattedObjectiveSort objects to sort FormattedObjectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FormattedObjectiveSort>>;
};

/** Fields to sort FormattedObjectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one FormattedObjectiveSort object. */
export type FormattedObjectiveSort = {
  id?: InputMaybe<SortDirection>;
  objective?: InputMaybe<SortDirection>;
  sequence?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type FormattedObjectiveUpdateInput = {
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  evalCriteria_POP?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedObjectiveWhere = {
  AND?: InputMaybe<Array<FormattedObjectiveWhere>>;
  NOT?: InputMaybe<FormattedObjectiveWhere>;
  OR?: InputMaybe<Array<FormattedObjectiveWhere>>;
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  evalCriteria_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sequence_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedObjectivesConnection = {
  __typename?: "FormattedObjectivesConnection";
  edges: Array<FormattedObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FormattedVisualization = {
  __typename?: "FormattedVisualization";
  code?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  status: VisualizationStatus;
  visualizationDescription?: Maybe<Scalars["String"]["output"]>;
  visualizationType?: Maybe<Scalars["String"]["output"]>;
};

export type FormattedVisualizationAggregateSelection = {
  __typename?: "FormattedVisualizationAggregateSelection";
  code: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  visualizationDescription: StringAggregateSelection;
  visualizationType: StringAggregateSelection;
};

export type FormattedVisualizationCreateInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  status: VisualizationStatus;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedVisualizationEdge = {
  __typename?: "FormattedVisualizationEdge";
  cursor: Scalars["String"]["output"];
  node: FormattedVisualization;
};

export type FormattedVisualizationOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FormattedVisualizationSort objects to sort FormattedVisualizations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FormattedVisualizationSort>>;
};

/** Fields to sort FormattedVisualizations by. The order in which sorts are applied is not guaranteed when specifying many fields in one FormattedVisualizationSort object. */
export type FormattedVisualizationSort = {
  code?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  visualizationDescription?: InputMaybe<SortDirection>;
  visualizationType?: InputMaybe<SortDirection>;
};

export type FormattedVisualizationUpdateInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<VisualizationStatus>;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedVisualizationWhere = {
  AND?: InputMaybe<Array<FormattedVisualizationWhere>>;
  NOT?: InputMaybe<FormattedVisualizationWhere>;
  OR?: InputMaybe<Array<FormattedVisualizationWhere>>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  code_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  code_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  code_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  code_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<VisualizationStatus>;
  status_IN?: InputMaybe<Array<VisualizationStatus>>;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  visualizationDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  visualizationType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormattedVisualizationsConnection = {
  __typename?: "FormattedVisualizationsConnection";
  edges: Array<FormattedVisualizationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Game = {
  __typename?: "Game";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  chatId?: Maybe<Scalars["ID"]["output"]>;
  dailyCalorieCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyCalorieGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  refreshCounter?: Maybe<Scalars["Int"]["output"]>;
};

export type GameAggregateSelection = {
  __typename?: "GameAggregateSelection";
  chatId: IdAggregateSelection;
  count: Scalars["Int"]["output"];
  dailyCalorieCompletedForToday: IntAggregateSelection;
  dailyCalorieGoalForToday: IntAggregateSelection;
  dailyLearningModulesCompletedForToday: IntAggregateSelection;
  dailyLearningModulesGoalForToday: IntAggregateSelection;
  dailyTaskCompletedForToday: IntAggregateSelection;
  dailyTaskGoalForToday: IntAggregateSelection;
  id: IdAggregateSelection;
  refreshCounter: IntAggregateSelection;
};

export type GameCreateInput = {
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GameEdge = {
  __typename?: "GameEdge";
  cursor: Scalars["String"]["output"];
  node: Game;
};

export type GameOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GameSort objects to sort Games by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GameSort>>;
};

/** Fields to sort Games by. The order in which sorts are applied is not guaranteed when specifying many fields in one GameSort object. */
export type GameSort = {
  chatId?: InputMaybe<SortDirection>;
  dailyCalorieCompletedForToday?: InputMaybe<SortDirection>;
  dailyCalorieGoalForToday?: InputMaybe<SortDirection>;
  dailyLearningModulesCompletedForToday?: InputMaybe<SortDirection>;
  dailyLearningModulesGoalForToday?: InputMaybe<SortDirection>;
  dailyTaskCompletedForToday?: InputMaybe<SortDirection>;
  dailyTaskGoalForToday?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  refreshCounter?: InputMaybe<SortDirection>;
};

export type GameUpdateInput = {
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GameWhere = {
  AND?: InputMaybe<Array<GameWhere>>;
  NOT?: InputMaybe<GameWhere>;
  OR?: InputMaybe<Array<GameWhere>>;
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  chatId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyCalorieCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyCalorieGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyLearningModulesCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyLearningModulesGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyTaskCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyTaskGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_GT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  refreshCounter_LT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GamesConnection = {
  __typename?: "GamesConnection";
  edges: Array<GameEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GenerateCourseContentData = {
  __typename?: "GenerateCourseContentData";
  generationStatus?: Maybe<CourseGenerationStatus>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type GenerateCourseContentDataAggregateSelection = {
  __typename?: "GenerateCourseContentDataAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  name: StringAggregateSelection;
};

export type GenerateCourseContentDataConnection = {
  __typename?: "GenerateCourseContentDataConnection";
  edges: Array<GenerateCourseContentDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GenerateCourseContentDataCreateInput = {
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type GenerateCourseContentDataEdge = {
  __typename?: "GenerateCourseContentDataEdge";
  cursor: Scalars["String"]["output"];
  node: GenerateCourseContentData;
};

export type GenerateCourseContentDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GenerateCourseContentDataSort objects to sort GenerateCourseContentData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GenerateCourseContentDataSort>>;
};

/** Fields to sort GenerateCourseContentData by. The order in which sorts are applied is not guaranteed when specifying many fields in one GenerateCourseContentDataSort object. */
export type GenerateCourseContentDataSort = {
  generationStatus?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type GenerateCourseContentDataUpdateInput = {
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenerateCourseContentDataWhere = {
  AND?: InputMaybe<Array<GenerateCourseContentDataWhere>>;
  NOT?: InputMaybe<GenerateCourseContentDataWhere>;
  OR?: InputMaybe<Array<GenerateCourseContentDataWhere>>;
  generationStatus?: InputMaybe<CourseGenerationStatus>;
  generationStatus_IN?: InputMaybe<Array<InputMaybe<CourseGenerationStatus>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenerateCourseContentResponse = {
  __typename?: "GenerateCourseContentResponse";
  count: Scalars["Int"]["output"];
  data?: Maybe<GenerateCourseContentData>;
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type GenerateCourseContentResponseAggregateSelection = {
  __typename?: "GenerateCourseContentResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type GenerateCourseContentResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type GenerateCourseContentResponseEdge = {
  __typename?: "GenerateCourseContentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GenerateCourseContentResponse;
};

export type GenerateCourseContentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GenerateCourseContentResponseSort objects to sort GenerateCourseContentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GenerateCourseContentResponseSort>>;
};

/** Fields to sort GenerateCourseContentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GenerateCourseContentResponseSort object. */
export type GenerateCourseContentResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type GenerateCourseContentResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GenerateCourseContentResponseWhere = {
  AND?: InputMaybe<Array<GenerateCourseContentResponseWhere>>;
  NOT?: InputMaybe<GenerateCourseContentResponseWhere>;
  OR?: InputMaybe<Array<GenerateCourseContentResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GenerateCourseContentResponsesConnection = {
  __typename?: "GenerateCourseContentResponsesConnection";
  edges: Array<GenerateCourseContentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GenerateEvalCriteriaInput = {
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveInput>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type Generated = {
  __typename?: "Generated";
  data?: Maybe<Array<Maybe<Subfield>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type GeneratedAggregateSelection = {
  __typename?: "GeneratedAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type GeneratedAiTutor = {
  __typename?: "GeneratedAiTutor";
  description: Scalars["String"]["output"];
  objectives?: Maybe<Array<GeneratedObjective>>;
};

export type GeneratedAiTutorAggregateSelection = {
  __typename?: "GeneratedAiTutorAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
};

export type GeneratedAiTutorCreateInput = {
  description: Scalars["String"]["input"];
};

export type GeneratedAiTutorEdge = {
  __typename?: "GeneratedAiTutorEdge";
  cursor: Scalars["String"]["output"];
  node: GeneratedAiTutor;
};

export type GeneratedAiTutorOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GeneratedAiTutorSort objects to sort GeneratedAiTutors by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GeneratedAiTutorSort>>;
};

/** Fields to sort GeneratedAiTutors by. The order in which sorts are applied is not guaranteed when specifying many fields in one GeneratedAiTutorSort object. */
export type GeneratedAiTutorSort = {
  description?: InputMaybe<SortDirection>;
};

export type GeneratedAiTutorUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type GeneratedAiTutorWhere = {
  AND?: InputMaybe<Array<GeneratedAiTutorWhere>>;
  NOT?: InputMaybe<GeneratedAiTutorWhere>;
  OR?: InputMaybe<Array<GeneratedAiTutorWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GeneratedAiTutorsConnection = {
  __typename?: "GeneratedAiTutorsConnection";
  edges: Array<GeneratedAiTutorEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GeneratedCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type GeneratedEdge = {
  __typename?: "GeneratedEdge";
  cursor: Scalars["String"]["output"];
  node: Generated;
};

export type GeneratedObjective = {
  __typename?: "GeneratedObjective";
  objective: Scalars["String"]["output"];
  sequence?: Maybe<Scalars["Float"]["output"]>;
};

export type GeneratedObjectiveAggregateSelection = {
  __typename?: "GeneratedObjectiveAggregateSelection";
  count: Scalars["Int"]["output"];
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type GeneratedObjectiveCreateInput = {
  objective: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
};

export type GeneratedObjectiveEdge = {
  __typename?: "GeneratedObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: GeneratedObjective;
};

export type GeneratedObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GeneratedObjectiveSort objects to sort GeneratedObjectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GeneratedObjectiveSort>>;
};

/** Fields to sort GeneratedObjectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one GeneratedObjectiveSort object. */
export type GeneratedObjectiveSort = {
  objective?: InputMaybe<SortDirection>;
  sequence?: InputMaybe<SortDirection>;
};

export type GeneratedObjectiveUpdateInput = {
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type GeneratedObjectiveWhere = {
  AND?: InputMaybe<Array<GeneratedObjectiveWhere>>;
  NOT?: InputMaybe<GeneratedObjectiveWhere>;
  OR?: InputMaybe<Array<GeneratedObjectiveWhere>>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sequence_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type GeneratedObjectivesConnection = {
  __typename?: "GeneratedObjectivesConnection";
  edges: Array<GeneratedObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GeneratedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GeneratedSort objects to sort Generateds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GeneratedSort>>;
};

/** Fields to sort Generateds by. The order in which sorts are applied is not guaranteed when specifying many fields in one GeneratedSort object. */
export type GeneratedSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type GeneratedUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GeneratedWhere = {
  AND?: InputMaybe<Array<GeneratedWhere>>;
  NOT?: InputMaybe<GeneratedWhere>;
  OR?: InputMaybe<Array<GeneratedWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GeneratedsConnection = {
  __typename?: "GeneratedsConnection";
  edges: Array<GeneratedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetAssessmentByFacultyInput = {
  courseIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  facultyId: Scalars["ID"]["input"];
  moduleIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponse = {
  __typename?: "GetAssignmentObjectiveResponse";
  id: Scalars["ID"]["output"];
  objective?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type GetAssignmentObjectiveResponseAggregateSelection = {
  __typename?: "GetAssignmentObjectiveResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  status: StringAggregateSelection;
};

export type GetAssignmentObjectiveResponseCreateInput = {
  id: Scalars["ID"]["input"];
  objective?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponseEdge = {
  __typename?: "GetAssignmentObjectiveResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetAssignmentObjectiveResponse;
};

export type GetAssignmentObjectiveResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetAssignmentObjectiveResponseSort objects to sort GetAssignmentObjectiveResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetAssignmentObjectiveResponseSort>>;
};

/** Fields to sort GetAssignmentObjectiveResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetAssignmentObjectiveResponseSort object. */
export type GetAssignmentObjectiveResponseSort = {
  id?: InputMaybe<SortDirection>;
  objective?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type GetAssignmentObjectiveResponseUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponseWhere = {
  AND?: InputMaybe<Array<GetAssignmentObjectiveResponseWhere>>;
  NOT?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
  OR?: InputMaybe<Array<GetAssignmentObjectiveResponseWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponsesConnection = {
  __typename?: "GetAssignmentObjectiveResponsesConnection";
  edges: Array<GetAssignmentObjectiveResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetAssignmentResponse = {
  __typename?: "GetAssignmentResponse";
  courseId?: Maybe<Scalars["ID"]["output"]>;
  createdDate?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  gradePoints?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  objectives?: Maybe<Array<Maybe<GetAssignmentObjectiveResponse>>>;
  pointsPossible?: Maybe<Scalars["Float"]["output"]>;
  title: Scalars["String"]["output"];
  updatedDate?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetAssignmentResponseAggregateSelection = {
  __typename?: "GetAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  courseId: IdAggregateSelection;
  createdDate: StringAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  gradePoints: FloatAggregateSelection;
  id: IdAggregateSelection;
  pointsPossible: FloatAggregateSelection;
  title: StringAggregateSelection;
  updatedDate: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type GetAssignmentResponseCreateInput = {
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  createdDate?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  gradePoints?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["ID"]["input"];
  pointsPossible?: InputMaybe<Scalars["Float"]["input"]>;
  title: Scalars["String"]["input"];
  updatedDate?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponseEdge = {
  __typename?: "GetAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetAssignmentResponse;
};

export type GetAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetAssignmentResponseSort objects to sort GetAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetAssignmentResponseSort>>;
};

/** Fields to sort GetAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetAssignmentResponseSort object. */
export type GetAssignmentResponseSort = {
  courseId?: InputMaybe<SortDirection>;
  createdDate?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  gradePoints?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  pointsPossible?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updatedDate?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetAssignmentResponseUpdateInput = {
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  createdDate?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  gradePoints?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  pointsPossible?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedDate?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponseWhere = {
  AND?: InputMaybe<Array<GetAssignmentResponseWhere>>;
  NOT?: InputMaybe<GetAssignmentResponseWhere>;
  OR?: InputMaybe<Array<GetAssignmentResponseWhere>>;
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  createdDate?: InputMaybe<Scalars["String"]["input"]>;
  createdDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  createdDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  createdDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  createdDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dueDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gradePoints?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  gradePoints_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gradePoints_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  pointsPossible?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_GT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  pointsPossible_LT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsPossible_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updatedDate?: InputMaybe<Scalars["String"]["input"]>;
  updatedDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updatedDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updatedDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updatedDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponsesConnection = {
  __typename?: "GetAssignmentResponsesConnection";
  edges: Array<GetAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetJourneyByIdResponse = {
  __typename?: "GetJourneyByIdResponse";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  tasks?: Maybe<Array<Maybe<Todo>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetJourneyByIdResponseAggregateSelection = {
  __typename?: "GetJourneyByIdResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type GetJourneyByIdResponseCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponseEdge = {
  __typename?: "GetJourneyByIdResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetJourneyByIdResponse;
};

export type GetJourneyByIdResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetJourneyByIdResponseSort objects to sort GetJourneyByIdResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetJourneyByIdResponseSort>>;
};

/** Fields to sort GetJourneyByIdResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetJourneyByIdResponseSort object. */
export type GetJourneyByIdResponseSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetJourneyByIdResponseUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponseWhere = {
  AND?: InputMaybe<Array<GetJourneyByIdResponseWhere>>;
  NOT?: InputMaybe<GetJourneyByIdResponseWhere>;
  OR?: InputMaybe<Array<GetJourneyByIdResponseWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  dueDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponsesConnection = {
  __typename?: "GetJourneyByIdResponsesConnection";
  edges: Array<GetJourneyByIdResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetJourneysResponse = {
  __typename?: "GetJourneysResponse";
  completedTasks?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  totalTasks?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetJourneysResponseAggregateSelection = {
  __typename?: "GetJourneysResponseAggregateSelection";
  completedTasks: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type GetJourneysResponseCreateInput = {
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponseEdge = {
  __typename?: "GetJourneysResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetJourneysResponse;
};

export type GetJourneysResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetJourneysResponseSort objects to sort GetJourneysResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetJourneysResponseSort>>;
};

/** Fields to sort GetJourneysResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetJourneysResponseSort object. */
export type GetJourneysResponseSort = {
  completedTasks?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTasks?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetJourneysResponseUpdateInput = {
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponseWhere = {
  AND?: InputMaybe<Array<GetJourneysResponseWhere>>;
  NOT?: InputMaybe<GetJourneysResponseWhere>;
  OR?: InputMaybe<Array<GetJourneysResponseWhere>>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  dueDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponsesConnection = {
  __typename?: "GetJourneysResponsesConnection";
  edges: Array<GetJourneysResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetUserSubmissionsByFacultyInput = {
  courseIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  facultyId: Scalars["ID"]["input"];
  moduleIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type Goal = {
  __typename?: "Goal";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  deadline?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type GoalAggregateSelection = {
  __typename?: "GoalAggregateSelection";
  category: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  deadline: DateTimeAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  priority: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
  type: StringAggregateSelection;
};

export type GoalCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalEdge = {
  __typename?: "GoalEdge";
  cursor: Scalars["String"]["output"];
  node: Goal;
};

export type GoalOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GoalSort objects to sort Goals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GoalSort>>;
};

/** Fields to sort Goals by. The order in which sorts are applied is not guaranteed when specifying many fields in one GoalSort object. */
export type GoalSort = {
  category?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  deadline?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type GoalUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalWhere = {
  AND?: InputMaybe<Array<GoalWhere>>;
  NOT?: InputMaybe<GoalWhere>;
  OR?: InputMaybe<Array<GoalWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  deadline_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  deadline_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalsConnection = {
  __typename?: "GoalsConnection";
  edges: Array<GoalEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GoogleAuthTokens = {
  __typename?: "GoogleAuthTokens";
  access_token?: Maybe<Scalars["String"]["output"]>;
  expiry_date?: Maybe<Scalars["Float"]["output"]>;
  refresh_token?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
  token_type?: Maybe<Scalars["String"]["output"]>;
};

export type GoogleAuthTokensAggregateSelection = {
  __typename?: "GoogleAuthTokensAggregateSelection";
  access_token: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  expiry_date: FloatAggregateSelection;
  refresh_token: StringAggregateSelection;
  scope: StringAggregateSelection;
  token_type: StringAggregateSelection;
};

export type GoogleAuthTokensConnection = {
  __typename?: "GoogleAuthTokensConnection";
  edges: Array<GoogleAuthTokensEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GoogleAuthTokensCreateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleAuthTokensEdge = {
  __typename?: "GoogleAuthTokensEdge";
  cursor: Scalars["String"]["output"];
  node: GoogleAuthTokens;
};

export type GoogleAuthTokensOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GoogleAuthTokensSort objects to sort GoogleAuthTokens by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GoogleAuthTokensSort>>;
};

/** Fields to sort GoogleAuthTokens by. The order in which sorts are applied is not guaranteed when specifying many fields in one GoogleAuthTokensSort object. */
export type GoogleAuthTokensSort = {
  access_token?: InputMaybe<SortDirection>;
  expiry_date?: InputMaybe<SortDirection>;
  refresh_token?: InputMaybe<SortDirection>;
  scope?: InputMaybe<SortDirection>;
  token_type?: InputMaybe<SortDirection>;
};

export type GoogleAuthTokensUpdateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleAuthTokensWhere = {
  AND?: InputMaybe<Array<GoogleAuthTokensWhere>>;
  NOT?: InputMaybe<GoogleAuthTokensWhere>;
  OR?: InputMaybe<Array<GoogleAuthTokensWhere>>;
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  access_token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  access_token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  access_token_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  access_token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_GT?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  expiry_date_LT?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  refresh_token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  scope_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  scope_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  scope_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
  token_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  token_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  token_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.assessments
 * * Assessment.userHasAssessment
 */
export type HasAssessmentProperties = {
  __typename?: "HasAssessmentProperties";
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  grade?: Maybe<Scalars["Float"]["output"]>;
  hasTeacherGraded?: Maybe<Scalars["Boolean"]["output"]>;
  isCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  remainingTime?: Maybe<Scalars["String"]["output"]>;
};

export type HasAssessmentPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasAssessmentPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasAssessmentPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasAssessmentPropertiesAggregationWhereInput>>;
  completedAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  remainingTime_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  remainingTime_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HasAssessmentPropertiesCreateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  remainingTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasAssessmentPropertiesSort = {
  completedAt?: InputMaybe<SortDirection>;
  grade?: InputMaybe<SortDirection>;
  hasTeacherGraded?: InputMaybe<SortDirection>;
  isCompleted?: InputMaybe<SortDirection>;
  remainingTime?: InputMaybe<SortDirection>;
};

export type HasAssessmentPropertiesUpdateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grade_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  remainingTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasAssessmentPropertiesWhere = {
  AND?: InputMaybe<Array<HasAssessmentPropertiesWhere>>;
  NOT?: InputMaybe<HasAssessmentPropertiesWhere>;
  OR?: InputMaybe<Array<HasAssessmentPropertiesWhere>>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  completedAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grade_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  remainingTime?: InputMaybe<Scalars["String"]["input"]>;
  remainingTime_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  remainingTime_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  remainingTime_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  remainingTime_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Topic.chapters
 * * Chapter.topicsHasChapter
 */
export type HasChapterProperties = {
  __typename?: "HasChapterProperties";
  created: Scalars["DateTime"]["output"];
  last_modified: Scalars["DateTime"]["output"];
};

export type HasChapterPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasChapterPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasChapterPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasChapterPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasChapterPropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  last_modified: Scalars["DateTime"]["input"];
};

export type HasChapterPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
};

export type HasChapterPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasChapterPropertiesWhere = {
  AND?: InputMaybe<Array<HasChapterPropertiesWhere>>;
  NOT?: InputMaybe<HasChapterPropertiesWhere>;
  OR?: InputMaybe<Array<HasChapterPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasChatChats
 * * Journey.hasChatChats
 * * Assignment.hasChatChats
 * * LearningObjective.hasChatChats
 * * Chat.usersHasChat
 * * Chat.journeysHasChat
 * * Chat.assignmentsHasChat
 * * Chat.aiTutorHasChat
 * * Chat.learningObjectivesHasChat
 * * ChatResponse.usersHasChat
 * * ChatResponse.journeysHasChat
 * * AITutor.hasChatChats
 */
export type HasChatProperties = {
  __typename?: "HasChatProperties";
  ai_messages_count?: Maybe<Scalars["Int"]["output"]>;
  average_ai_response_time?: Maybe<Scalars["Float"]["output"]>;
  average_student_question_complexity?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  average_student_response_time?: Maybe<Scalars["Float"]["output"]>;
  confusion_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  distractions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  further_recommendations?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  level?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  num_messages?: Maybe<Scalars["Int"]["output"]>;
  originality_analysis?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  questions_asked?: Maybe<Scalars["Int"]["output"]>;
  sentiment_analysis?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  student_messages_count?: Maybe<Scalars["Int"]["output"]>;
  success_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  summary?: Maybe<Scalars["String"]["output"]>;
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type HasChatPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasChatPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasChatPropertiesAggregationWhereInput>>;
  ai_messages_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  num_messages_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasChatPropertiesCreateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasChatPropertiesSort = {
  ai_messages_count?: InputMaybe<SortDirection>;
  average_ai_response_time?: InputMaybe<SortDirection>;
  average_student_question_complexity?: InputMaybe<SortDirection>;
  average_student_response_time?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  further_recommendations?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  num_messages?: InputMaybe<SortDirection>;
  originality_analysis?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment_analysis?: InputMaybe<SortDirection>;
  student_messages_count?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type HasChatPropertiesUpdateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_question_complexity_POP?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_POP?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_POP?: InputMaybe<Scalars["Int"]["input"]>;
  level_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_POP?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasChatPropertiesWhere = {
  AND?: InputMaybe<Array<HasChatPropertiesWhere>>;
  NOT?: InputMaybe<HasChatPropertiesWhere>;
  OR?: InputMaybe<Array<HasChatPropertiesWhere>>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  ai_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_ai_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_question_complexity_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_student_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  num_messages_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  student_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasFoodItemFoodItems
 * * FoodItem.usersHasFoodItem
 */
export type HasFoodItemProperties = {
  __typename?: "HasFoodItemProperties";
  consumption_date?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["String"]["output"]>;
  isArchived: Scalars["Boolean"]["output"];
  isHealthyOption?: Maybe<Scalars["Boolean"]["output"]>;
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  meal?: Maybe<Scalars["String"]["output"]>;
  meal_date?: Maybe<Scalars["String"]["output"]>;
  meal_time?: Maybe<Scalars["String"]["output"]>;
  meal_type?: Maybe<Scalars["String"]["output"]>;
  portion?: Maybe<Scalars["Float"]["output"]>;
  quality: Scalars["BigInt"]["output"];
  quantity?: Maybe<Scalars["Float"]["output"]>;
  strength: Scalars["BigInt"]["output"];
  time?: Maybe<Scalars["String"]["output"]>;
};

export type HasFoodItemPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasFoodItemPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasFoodItemPropertiesAggregationWhereInput>>;
  consumption_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  portion_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  time_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HasFoodItemPropertiesCreateInput = {
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived: Scalars["Boolean"]["input"];
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  quality: Scalars["BigInt"]["input"];
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  strength: Scalars["BigInt"]["input"];
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasFoodItemPropertiesSort = {
  consumption_date?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isHealthyOption?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  meal?: InputMaybe<SortDirection>;
  meal_date?: InputMaybe<SortDirection>;
  meal_time?: InputMaybe<SortDirection>;
  meal_type?: InputMaybe<SortDirection>;
  portion?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  quantity?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
  time?: InputMaybe<SortDirection>;
};

export type HasFoodItemPropertiesUpdateInput = {
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  portion_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  portion_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasFoodItemPropertiesWhere = {
  AND?: InputMaybe<Array<HasFoodItemPropertiesWhere>>;
  NOT?: InputMaybe<HasFoodItemPropertiesWhere>;
  OR?: InputMaybe<Array<HasFoodItemPropertiesWhere>>;
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  consumption_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_time_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  portion_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  portion_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  quantity_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  time?: InputMaybe<Scalars["String"]["input"]>;
  time_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  time_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  time_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  time_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasJourneyJourneys
 * * Journey.usersHasJourney
 */
export type HasJourneyProperties = {
  __typename?: "HasJourneyProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasJourneyPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasJourneyPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasJourneyPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasJourneyPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasJourneyPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasJourneyPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
};

export type HasJourneyPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasJourneyPropertiesWhere = {
  AND?: InputMaybe<Array<HasJourneyPropertiesWhere>>;
  NOT?: InputMaybe<HasJourneyPropertiesWhere>;
  OR?: InputMaybe<Array<HasJourneyPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasKnowledgeTopics
 * * User.hasKnowledgeChapters
 * * Topic.usersHasKnowledge
 * * Chapter.usersHasKnowledge
 */
export type HasKnowledgeProperties = {
  __typename?: "HasKnowledgeProperties";
  created: Scalars["DateTime"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  total_mastery: Scalars["Float"]["output"];
};

export type HasKnowledgePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasKnowledgePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasKnowledgePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasKnowledgePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  total_mastery_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasKnowledgePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  total_mastery: Scalars["Float"]["input"];
};

export type HasKnowledgePropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  total_mastery?: InputMaybe<SortDirection>;
};

export type HasKnowledgePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasKnowledgePropertiesWhere = {
  AND?: InputMaybe<Array<HasKnowledgePropertiesWhere>>;
  NOT?: InputMaybe<HasKnowledgePropertiesWhere>;
  OR?: InputMaybe<Array<HasKnowledgePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  total_mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Message.chatsHasMessage
 * * MessageResponse.chatsHasMessage
 * * Chat.hasMessageMessages
 * * ChatResponse.hasMessageMessages
 */
export type HasMessageProperties = {
  __typename?: "HasMessageProperties";
  created: Scalars["DateTime"]["output"];
};

export type HasMessagePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasMessagePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasMessagePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasMessagePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
};

export type HasMessagePropertiesSort = {
  created?: InputMaybe<SortDirection>;
};

export type HasMessagePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasMessagePropertiesWhere = {
  AND?: InputMaybe<Array<HasMessagePropertiesWhere>>;
  NOT?: InputMaybe<HasMessagePropertiesWhere>;
  OR?: InputMaybe<Array<HasMessagePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasPaymentPayments
 * * Payment.usersHasPayment
 */
export type HasPaymentProperties = {
  __typename?: "HasPaymentProperties";
  integrationId?: Maybe<Scalars["String"]["output"]>;
  isPro: Scalars["Boolean"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasPaymentPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasPaymentPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasPaymentPropertiesAggregationWhereInput>>;
  integrationId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesCreateInput = {
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  isPro: Scalars["Boolean"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesSort = {
  integrationId?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  stripeCustomerId?: InputMaybe<SortDirection>;
  stripeSubscriptionId?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
};

export type HasPaymentPropertiesUpdateInput = {
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesWhere = {
  AND?: InputMaybe<Array<HasPaymentPropertiesWhere>>;
  NOT?: InputMaybe<HasPaymentPropertiesWhere>;
  OR?: InputMaybe<Array<HasPaymentPropertiesWhere>>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integrationId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeCustomerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Objective.userHasProgress
 */
export type HasProgressProperties = {
  __typename?: "HasProgressProperties";
  ai_messages_count?: Maybe<Scalars["Int"]["output"]>;
  average_ai_response_time?: Maybe<Scalars["Float"]["output"]>;
  average_student_question_complexity?: Maybe<Scalars["String"]["output"]>;
  average_student_response_time?: Maybe<Scalars["Float"]["output"]>;
  confusion_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  distractions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  enableVideoInsight?: Maybe<Scalars["Boolean"]["output"]>;
  further_recommendations?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  level?: Maybe<Scalars["String"]["output"]>;
  num_messages?: Maybe<Scalars["Int"]["output"]>;
  originality_analysis?: Maybe<Scalars["String"]["output"]>;
  questions_asked?: Maybe<Scalars["Int"]["output"]>;
  sentiment_analysis?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  student_messages_count?: Maybe<Scalars["Int"]["output"]>;
  success_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  summary?: Maybe<Scalars["String"]["output"]>;
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type HasProgressPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasProgressPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasProgressPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasProgressPropertiesAggregationWhereInput>>;
  ai_messages_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  ai_messages_count_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_question_complexity_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_student_response_time_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  level_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  level_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  num_messages_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  originality_analysis_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  originality_analysis_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  originality_analysis_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  originality_analysis_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  originality_analysis_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  questions_asked_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sentiment_analysis_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sentiment_analysis_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sentiment_analysis_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sentiment_analysis_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sentiment_analysis_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  status_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  student_messages_count_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasProgressPropertiesCreateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasProgressPropertiesSort = {
  ai_messages_count?: InputMaybe<SortDirection>;
  average_ai_response_time?: InputMaybe<SortDirection>;
  average_student_question_complexity?: InputMaybe<SortDirection>;
  average_student_response_time?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enableVideoInsight?: InputMaybe<SortDirection>;
  further_recommendations?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  num_messages?: InputMaybe<SortDirection>;
  originality_analysis?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment_analysis?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  student_messages_count?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type HasProgressPropertiesUpdateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_POP?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasProgressPropertiesWhere = {
  AND?: InputMaybe<Array<HasProgressPropertiesWhere>>;
  NOT?: InputMaybe<HasProgressPropertiesWhere>;
  OR?: InputMaybe<Array<HasProgressPropertiesWhere>>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  ai_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_ai_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_question_complexity_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_student_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  num_messages_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  student_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.schools
 */
export type HasSchoolProperties = {
  __typename?: "HasSchoolProperties";
  access_token?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  domain?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  profile_id?: Maybe<Scalars["String"]["output"]>;
  vendor?: Maybe<Scalars["String"]["output"]>;
};

export type HasSchoolPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasSchoolPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasSchoolPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasSchoolPropertiesAggregationWhereInput>>;
  access_token_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  access_token_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  access_token_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  access_token_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  access_token_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  access_token_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  access_token_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  domain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  profile_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profile_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profile_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profile_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profile_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profile_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profile_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  vendor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  vendor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  vendor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  vendor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  vendor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  vendor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HasSchoolPropertiesCreateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  profile_id?: InputMaybe<Scalars["String"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasSchoolPropertiesSort = {
  access_token?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  domain?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  profile_id?: InputMaybe<SortDirection>;
  vendor?: InputMaybe<SortDirection>;
};

export type HasSchoolPropertiesUpdateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  profile_id?: InputMaybe<Scalars["String"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasSchoolPropertiesWhere = {
  AND?: InputMaybe<Array<HasSchoolPropertiesWhere>>;
  NOT?: InputMaybe<HasSchoolPropertiesWhere>;
  OR?: InputMaybe<Array<HasSchoolPropertiesWhere>>;
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  access_token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  access_token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  access_token_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  access_token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  domain_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  domain_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  domain_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  domain_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  profile_id?: InputMaybe<Scalars["String"]["input"]>;
  profile_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  profile_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profile_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  profile_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  vendor?: InputMaybe<Scalars["String"]["input"]>;
  vendor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  vendor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  vendor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  vendor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasSessionDeviceSessions
 * * DeviceSession.usersHasSession
 */
export type HasSessionProperties = {
  __typename?: "HasSessionProperties";
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  isActive: Scalars["Boolean"]["output"];
};

export type HasSessionPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasSessionPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasSessionPropertiesAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasSessionPropertiesCreateInput = {
  createdAt: Scalars["DateTime"]["input"];
  expiresAt: Scalars["DateTime"]["input"];
  isActive: Scalars["Boolean"]["input"];
};

export type HasSessionPropertiesSort = {
  createdAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  isActive?: InputMaybe<SortDirection>;
};

export type HasSessionPropertiesUpdateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HasSessionPropertiesWhere = {
  AND?: InputMaybe<Array<HasSessionPropertiesWhere>>;
  NOT?: InputMaybe<HasSessionPropertiesWhere>;
  OR?: InputMaybe<Array<HasSessionPropertiesWhere>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  createdAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  expiresAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Journey.hasTaskTodos
 * * Todo.journeysHasTask
 */
export type HasTaskProperties = {
  __typename?: "HasTaskProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasTaskPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasTaskPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasTaskPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
};

export type HasTaskPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesWhere = {
  AND?: InputMaybe<Array<HasTaskPropertiesWhere>>;
  NOT?: InputMaybe<HasTaskPropertiesWhere>;
  OR?: InputMaybe<Array<HasTaskPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.todos
 * * Todo.usersHasTodo
 */
export type HasTodoProperties = {
  __typename?: "HasTodoProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  isArchived?: Maybe<Scalars["Boolean"]["output"]>;
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  isRelative?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  quality?: Maybe<Scalars["BigInt"]["output"]>;
  strength?: Maybe<Scalars["BigInt"]["output"]>;
};

export type HasTodoPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasTodoPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasTodoPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  isRelative?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type HasTodoPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesWhere = {
  AND?: InputMaybe<Array<HasTodoPropertiesWhere>>;
  NOT?: InputMaybe<HasTodoPropertiesWhere>;
  OR?: InputMaybe<Array<HasTodoPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<InputMaybe<Scalars["BigInt"]["input"]>>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<InputMaybe<Scalars["BigInt"]["input"]>>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Objective.videos
 * * Video.objectiveHasVideos
 */
export type HasVideoProperties = {
  __typename?: "HasVideoProperties";
  relevanceScore: Scalars["Float"]["output"];
  timestamp: Scalars["Float"]["output"];
};

export type HasVideoPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasVideoPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasVideoPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasVideoPropertiesAggregationWhereInput>>;
  relevanceScore_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasVideoPropertiesCreateInput = {
  relevanceScore: Scalars["Float"]["input"];
  timestamp: Scalars["Float"]["input"];
};

export type HasVideoPropertiesSort = {
  relevanceScore?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
};

export type HasVideoPropertiesUpdateInput = {
  relevanceScore?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HasVideoPropertiesWhere = {
  AND?: InputMaybe<Array<HasVideoPropertiesWhere>>;
  NOT?: InputMaybe<HasVideoPropertiesWhere>;
  OR?: InputMaybe<Array<HasVideoPropertiesWhere>>;
  relevanceScore?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_GT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  relevanceScore_LT?: InputMaybe<Scalars["Float"]["input"]>;
  relevanceScore_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  timestamp_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timestamp_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasWaterIntakeWaterIntakes
 * * WaterIntake.usersHasWaterIntake
 */
export type HasWaterIntakeProperties = {
  __typename?: "HasWaterIntakeProperties";
  created: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  quality: Scalars["BigInt"]["output"];
  strength: Scalars["BigInt"]["output"];
};

export type HasWaterIntakePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasWaterIntakePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasWaterIntakePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasWaterIntakePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  quality: Scalars["BigInt"]["input"];
  strength: Scalars["BigInt"]["input"];
};

export type HasWaterIntakePropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type HasWaterIntakePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasWaterIntakePropertiesWhere = {
  AND?: InputMaybe<Array<HasWaterIntakePropertiesWhere>>;
  NOT?: InputMaybe<HasWaterIntakePropertiesWhere>;
  OR?: InputMaybe<Array<HasWaterIntakePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HierarchiesConnection = {
  __typename?: "HierarchiesConnection";
  edges: Array<HierarchyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Hierarchy = {
  __typename?: "Hierarchy";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  isRelevant: Scalars["Boolean"]["output"];
  mastery: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  parentName: Scalars["String"]["output"];
};

export type HierarchyActivitiesConnection = {
  __typename?: "HierarchyActivitiesConnection";
  edges: Array<HierarchyActivityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyActivity = {
  __typename?: "HierarchyActivity";
  details?: Maybe<HierarchyActivityDetails>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
  parentCourse?: Maybe<ParentRef>;
  parentModule?: Maybe<ParentRef>;
};

export type HierarchyActivityAggregateSelection = {
  __typename?: "HierarchyActivityAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  level: StringAggregateSelection;
};

export type HierarchyActivityCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
};

export type HierarchyActivityDetails = {
  __typename?: "HierarchyActivityDetails";
  objectives: Array<HierarchyObjective>;
};

export type HierarchyActivityDetailsAggregateSelection = {
  __typename?: "HierarchyActivityDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type HierarchyActivityDetailsConnection = {
  __typename?: "HierarchyActivityDetailsConnection";
  edges: Array<HierarchyActivityDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyActivityDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyActivityDetailsEdge = {
  __typename?: "HierarchyActivityDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyActivityDetails;
};

export type HierarchyActivityDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HierarchyActivityDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyActivityDetailsWhere = {
  AND?: InputMaybe<Array<HierarchyActivityDetailsWhere>>;
  NOT?: InputMaybe<HierarchyActivityDetailsWhere>;
  OR?: InputMaybe<Array<HierarchyActivityDetailsWhere>>;
};

export type HierarchyActivityEdge = {
  __typename?: "HierarchyActivityEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyActivity;
};

export type HierarchyActivityOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyActivitySort objects to sort HierarchyActivities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyActivitySort>>;
};

/** Fields to sort HierarchyActivities by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyActivitySort object. */
export type HierarchyActivitySort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
};

export type HierarchyActivityUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyActivityWhere = {
  AND?: InputMaybe<Array<HierarchyActivityWhere>>;
  NOT?: InputMaybe<HierarchyActivityWhere>;
  OR?: InputMaybe<Array<HierarchyActivityWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyAggregateSelection = {
  __typename?: "HierarchyAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  index: IntAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentName: StringAggregateSelection;
};

export type HierarchyCourse = {
  __typename?: "HierarchyCourse";
  details?: Maybe<HierarchyCourseDetails>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
};

export type HierarchyCourseAggregateSelection = {
  __typename?: "HierarchyCourseAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  level: StringAggregateSelection;
};

export type HierarchyCourseCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
};

export type HierarchyCourseDetails = {
  __typename?: "HierarchyCourseDetails";
  modules: Array<HierarchyModule>;
};

export type HierarchyCourseDetailsAggregateSelection = {
  __typename?: "HierarchyCourseDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type HierarchyCourseDetailsConnection = {
  __typename?: "HierarchyCourseDetailsConnection";
  edges: Array<HierarchyCourseDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyCourseDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyCourseDetailsEdge = {
  __typename?: "HierarchyCourseDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyCourseDetails;
};

export type HierarchyCourseDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HierarchyCourseDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyCourseDetailsWhere = {
  AND?: InputMaybe<Array<HierarchyCourseDetailsWhere>>;
  NOT?: InputMaybe<HierarchyCourseDetailsWhere>;
  OR?: InputMaybe<Array<HierarchyCourseDetailsWhere>>;
};

export type HierarchyCourseEdge = {
  __typename?: "HierarchyCourseEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyCourse;
};

export type HierarchyCourseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyCourseSort objects to sort HierarchyCourses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyCourseSort>>;
};

/** Fields to sort HierarchyCourses by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyCourseSort object. */
export type HierarchyCourseSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
};

export type HierarchyCourseUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyCourseWhere = {
  AND?: InputMaybe<Array<HierarchyCourseWhere>>;
  NOT?: InputMaybe<HierarchyCourseWhere>;
  OR?: InputMaybe<Array<HierarchyCourseWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyCoursesConnection = {
  __typename?: "HierarchyCoursesConnection";
  edges: Array<HierarchyCourseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant: Scalars["Boolean"]["input"];
  mastery: Scalars["Float"]["input"];
  name: Scalars["String"]["input"];
  parentName: Scalars["String"]["input"];
};

export type HierarchyEdge = {
  __typename?: "HierarchyEdge";
  cursor: Scalars["String"]["output"];
  node: Hierarchy;
};

export type HierarchyModule = {
  __typename?: "HierarchyModule";
  details?: Maybe<HierarchyModuleDetails>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
  parentCourse?: Maybe<ParentRef>;
};

export type HierarchyModuleAggregateSelection = {
  __typename?: "HierarchyModuleAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  level: StringAggregateSelection;
};

export type HierarchyModuleCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
};

export type HierarchyModuleDetails = {
  __typename?: "HierarchyModuleDetails";
  activities: Array<HierarchyActivity>;
};

export type HierarchyModuleDetailsAggregateSelection = {
  __typename?: "HierarchyModuleDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type HierarchyModuleDetailsConnection = {
  __typename?: "HierarchyModuleDetailsConnection";
  edges: Array<HierarchyModuleDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyModuleDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyModuleDetailsEdge = {
  __typename?: "HierarchyModuleDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyModuleDetails;
};

export type HierarchyModuleDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HierarchyModuleDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HierarchyModuleDetailsWhere = {
  AND?: InputMaybe<Array<HierarchyModuleDetailsWhere>>;
  NOT?: InputMaybe<HierarchyModuleDetailsWhere>;
  OR?: InputMaybe<Array<HierarchyModuleDetailsWhere>>;
};

export type HierarchyModuleEdge = {
  __typename?: "HierarchyModuleEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyModule;
};

export type HierarchyModuleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyModuleSort objects to sort HierarchyModules by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyModuleSort>>;
};

/** Fields to sort HierarchyModules by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyModuleSort object. */
export type HierarchyModuleSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
};

export type HierarchyModuleUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyModuleWhere = {
  AND?: InputMaybe<Array<HierarchyModuleWhere>>;
  NOT?: InputMaybe<HierarchyModuleWhere>;
  OR?: InputMaybe<Array<HierarchyModuleWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyModulesConnection = {
  __typename?: "HierarchyModulesConnection";
  edges: Array<HierarchyModuleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyNode = {
  __typename?: "HierarchyNode";
  label: Scalars["String"]["output"];
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type HierarchyNodeAggregateSelection = {
  __typename?: "HierarchyNodeAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type HierarchyNodeCreateInput = {
  label: Scalars["String"]["input"];
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type HierarchyNodeEdge = {
  __typename?: "HierarchyNodeEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyNode;
};

export type HierarchyNodeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyNodeSort objects to sort HierarchyNodes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyNodeSort>>;
};

/** Fields to sort HierarchyNodes by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyNodeSort object. */
export type HierarchyNodeSort = {
  label?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type HierarchyNodeUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyNodeWhere = {
  AND?: InputMaybe<Array<HierarchyNodeWhere>>;
  NOT?: InputMaybe<HierarchyNodeWhere>;
  OR?: InputMaybe<Array<HierarchyNodeWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyNodesConnection = {
  __typename?: "HierarchyNodesConnection";
  edges: Array<HierarchyNodeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyObjective = {
  __typename?: "HierarchyObjective";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  level: Scalars["String"]["output"];
  parentActivity?: Maybe<ParentRef>;
  parentCourse?: Maybe<ParentRef>;
  parentModule?: Maybe<ParentRef>;
};

export type HierarchyObjectiveAggregateSelection = {
  __typename?: "HierarchyObjectiveAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  level: StringAggregateSelection;
};

export type HierarchyObjectiveCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
};

export type HierarchyObjectiveEdge = {
  __typename?: "HierarchyObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyObjective;
};

export type HierarchyObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyObjectiveSort objects to sort HierarchyObjectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyObjectiveSort>>;
};

/** Fields to sort HierarchyObjectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyObjectiveSort object. */
export type HierarchyObjectiveSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
};

export type HierarchyObjectiveUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyObjectiveWhere = {
  AND?: InputMaybe<Array<HierarchyObjectiveWhere>>;
  NOT?: InputMaybe<HierarchyObjectiveWhere>;
  OR?: InputMaybe<Array<HierarchyObjectiveWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyObjectivesConnection = {
  __typename?: "HierarchyObjectivesConnection";
  edges: Array<HierarchyObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchySort objects to sort Hierarchies by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchySort>>;
};

/** Fields to sort Hierarchies by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchySort object. */
export type HierarchySort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentName?: InputMaybe<SortDirection>;
};

export type HierarchyUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentName?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyWhere = {
  AND?: InputMaybe<Array<HierarchyWhere>>;
  NOT?: InputMaybe<HierarchyWhere>;
  OR?: InputMaybe<Array<HierarchyWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentName?: InputMaybe<Scalars["String"]["input"]>;
  parentName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parentName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HobbiesConnection = {
  __typename?: "HobbiesConnection";
  edges: Array<HobbyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Hobby = {
  __typename?: "Hobby";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HobbyAggregateSelection = {
  __typename?: "HobbyAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  lastUpdated: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
};

export type HobbyCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HobbyEdge = {
  __typename?: "HobbyEdge";
  cursor: Scalars["String"]["output"];
  node: Hobby;
};

export type HobbyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HobbySort objects to sort Hobbies by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HobbySort>>;
};

/** Fields to sort Hobbies by. The order in which sorts are applied is not guaranteed when specifying many fields in one HobbySort object. */
export type HobbySort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type HobbyUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HobbyWhere = {
  AND?: InputMaybe<Array<HobbyWhere>>;
  NOT?: InputMaybe<HobbyWhere>;
  OR?: InputMaybe<Array<HobbyWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type IdAggregateSelection = {
  __typename?: "IDAggregateSelection";
  longest?: Maybe<Scalars["ID"]["output"]>;
  shortest?: Maybe<Scalars["ID"]["output"]>;
};

export type IntAggregateSelection = {
  __typename?: "IntAggregateSelection";
  average?: Maybe<Scalars["Float"]["output"]>;
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
  sum?: Maybe<Scalars["Int"]["output"]>;
};

export type Interest = {
  __typename?: "Interest";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type InterestAggregateSelection = {
  __typename?: "InterestAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  lastUpdated: DateTimeAggregateSelection;
  level: StringAggregateSelection;
  name: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
};

export type InterestCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InterestEdge = {
  __typename?: "InterestEdge";
  cursor: Scalars["String"]["output"];
  node: Interest;
};

export type InterestOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InterestSort objects to sort Interests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InterestSort>>;
};

/** Fields to sort Interests by. The order in which sorts are applied is not guaranteed when specifying many fields in one InterestSort object. */
export type InterestSort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type InterestUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InterestWhere = {
  AND?: InputMaybe<Array<InterestWhere>>;
  NOT?: InputMaybe<InterestWhere>;
  OR?: InputMaybe<Array<InterestWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InterestsConnection = {
  __typename?: "InterestsConnection";
  edges: Array<InterestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InviteCountResponse = {
  __typename?: "InviteCountResponse";
  count: Scalars["Int"]["output"];
};

export type InviteCountResponseAggregateSelection = {
  __typename?: "InviteCountResponseAggregateSelection";
  count: IntAggregateSelection;
};

export type InviteCountResponseCreateInput = {
  count: Scalars["Int"]["input"];
};

export type InviteCountResponseEdge = {
  __typename?: "InviteCountResponseEdge";
  cursor: Scalars["String"]["output"];
  node: InviteCountResponse;
};

export type InviteCountResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InviteCountResponseSort objects to sort InviteCountResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InviteCountResponseSort>>;
};

/** Fields to sort InviteCountResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one InviteCountResponseSort object. */
export type InviteCountResponseSort = {
  count?: InputMaybe<SortDirection>;
};

export type InviteCountResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InviteCountResponseWhere = {
  AND?: InputMaybe<Array<InviteCountResponseWhere>>;
  NOT?: InputMaybe<InviteCountResponseWhere>;
  OR?: InputMaybe<Array<InviteCountResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InviteCountResponsesConnection = {
  __typename?: "InviteCountResponsesConnection";
  edges: Array<InviteCountResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InviteStudentUsers = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type InviteUserToSchoolInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  role: Scalars["String"]["input"];
};

export type InviteUsersToEnrollInCourseInput = {
  courseId: Scalars["ID"]["input"];
  users: Array<InviteStudentUsers>;
};

export type InviteUsersToEnrollInCourseResponse = {
  __typename?: "InviteUsersToEnrollInCourseResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<InvitedUsers>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type InviteUsersToEnrollInCourseResponseAggregateSelection = {
  __typename?: "InviteUsersToEnrollInCourseResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type InviteUsersToEnrollInCourseResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInCourseResponseEdge = {
  __typename?: "InviteUsersToEnrollInCourseResponseEdge";
  cursor: Scalars["String"]["output"];
  node: InviteUsersToEnrollInCourseResponse;
};

export type InviteUsersToEnrollInCourseResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InviteUsersToEnrollInCourseResponseSort objects to sort InviteUsersToEnrollInCourseResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InviteUsersToEnrollInCourseResponseSort>>;
};

/** Fields to sort InviteUsersToEnrollInCourseResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one InviteUsersToEnrollInCourseResponseSort object. */
export type InviteUsersToEnrollInCourseResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type InviteUsersToEnrollInCourseResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInCourseResponseWhere = {
  AND?: InputMaybe<Array<InviteUsersToEnrollInCourseResponseWhere>>;
  NOT?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
  OR?: InputMaybe<Array<InviteUsersToEnrollInCourseResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInCourseResponsesConnection = {
  __typename?: "InviteUsersToEnrollInCourseResponsesConnection";
  edges: Array<InviteUsersToEnrollInCourseResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InviteUsersToEnrollInDegreeInput = {
  degreeId: Scalars["ID"]["input"];
  users: Array<InviteStudentUsers>;
};

export type InviteUsersToEnrollInDegreeResponse = {
  __typename?: "InviteUsersToEnrollInDegreeResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<InvitedUsers>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type InviteUsersToEnrollInDegreeResponseAggregateSelection = {
  __typename?: "InviteUsersToEnrollInDegreeResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type InviteUsersToEnrollInDegreeResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInDegreeResponseEdge = {
  __typename?: "InviteUsersToEnrollInDegreeResponseEdge";
  cursor: Scalars["String"]["output"];
  node: InviteUsersToEnrollInDegreeResponse;
};

export type InviteUsersToEnrollInDegreeResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InviteUsersToEnrollInDegreeResponseSort objects to sort InviteUsersToEnrollInDegreeResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InviteUsersToEnrollInDegreeResponseSort>>;
};

/** Fields to sort InviteUsersToEnrollInDegreeResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one InviteUsersToEnrollInDegreeResponseSort object. */
export type InviteUsersToEnrollInDegreeResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type InviteUsersToEnrollInDegreeResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInDegreeResponseWhere = {
  AND?: InputMaybe<Array<InviteUsersToEnrollInDegreeResponseWhere>>;
  NOT?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
  OR?: InputMaybe<Array<InviteUsersToEnrollInDegreeResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InviteUsersToEnrollInDegreeResponsesConnection = {
  __typename?: "InviteUsersToEnrollInDegreeResponsesConnection";
  edges: Array<InviteUsersToEnrollInDegreeResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InvitedUsers = {
  __typename?: "InvitedUsers";
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type InvitedUsersAggregateSelection = {
  __typename?: "InvitedUsersAggregateSelection";
  count: Scalars["Int"]["output"];
  email: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type InvitedUsersConnection = {
  __typename?: "InvitedUsersConnection";
  edges: Array<InvitedUsersEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InvitedUsersCreateInput = {
  email: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type InvitedUsersEdge = {
  __typename?: "InvitedUsersEdge";
  cursor: Scalars["String"]["output"];
  node: InvitedUsers;
};

export type InvitedUsersOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InvitedUsersSort objects to sort InvitedUsers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InvitedUsersSort>>;
};

/** Fields to sort InvitedUsers by. The order in which sorts are applied is not guaranteed when specifying many fields in one InvitedUsersSort object. */
export type InvitedUsersSort = {
  email?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type InvitedUsersUpdateInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InvitedUsersWhere = {
  AND?: InputMaybe<Array<InvitedUsersWhere>>;
  NOT?: InputMaybe<InvitedUsersWhere>;
  OR?: InputMaybe<Array<InvitedUsersWhere>>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Journey = {
  __typename?: "Journey";
  _empty?: Maybe<Scalars["String"]["output"]>;
  completed: Scalars["Boolean"]["output"];
  completedTasks?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<JourneyChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: JourneyHasChatChatsConnection;
  hasTaskTodos: Array<Todo>;
  hasTaskTodosAggregate?: Maybe<JourneyTodoHasTaskTodosAggregationSelection>;
  hasTaskTodosConnection: JourneyHasTaskTodosConnection;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  tasks?: Maybe<Array<Maybe<Todo>>>;
  totalTasks?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasJourney: Array<User>;
  usersHasJourneyAggregate?: Maybe<JourneyUserUsersHasJourneyAggregationSelection>;
  usersHasJourneyConnection: JourneyUsersHasJourneyConnection;
};

export type JourneyHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyHasChatChatsConnectionSort>>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasTaskTodosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TodoOptions>;
  where?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyHasTaskTodosConnectionSort>>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyUsersHasJourneyArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyUsersHasJourneyConnectionSort>>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyAggregateSelection = {
  __typename?: "JourneyAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type JourneyChatHasChatChatsAggregationSelection = {
  __typename?: "JourneyChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<JourneyChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<JourneyChatHasChatChatsNodeAggregateSelection>;
};

export type JourneyChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "JourneyChatHasChatChatsEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type JourneyChatHasChatChatsNodeAggregateSelection = {
  __typename?: "JourneyChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type JourneyConnectInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
};

export type JourneyConnectOrCreateInput = {
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyConnectOrCreateFieldInput>>;
};

export type JourneyConnectWhere = {
  node: JourneyWhere;
};

export type JourneyCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  completed: Scalars["Boolean"]["input"];
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasChatChats?: InputMaybe<JourneyHasChatChatsFieldInput>;
  hasTaskTodos?: InputMaybe<JourneyHasTaskTodosFieldInput>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourney?: InputMaybe<JourneyUsersHasJourneyFieldInput>;
};

export type JourneyDeleteInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsDeleteFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosDeleteFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyDeleteFieldInput>>;
};

export type JourneyDisconnectInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsDisconnectFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosDisconnectFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyDisconnectFieldInput>>;
};

export type JourneyEdge = {
  __typename?: "JourneyEdge";
  cursor: Scalars["String"]["output"];
  node: Journey;
};

export type JourneyHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<JourneyHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<JourneyHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<JourneyHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<JourneyHasChatChatsNodeAggregationWhereInput>;
};

export type JourneyHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type JourneyHasChatChatsConnection = {
  __typename?: "JourneyHasChatChatsConnection";
  edges: Array<JourneyHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type JourneyHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<JourneyHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<JourneyHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type JourneyHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
};

export type JourneyHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyHasChatChatsRelationship = {
  __typename?: "JourneyHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type JourneyHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type JourneyHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<JourneyHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasTaskTodosAggregateInput = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosAggregateInput>>;
  NOT?: InputMaybe<JourneyHasTaskTodosAggregateInput>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  node?: InputMaybe<JourneyHasTaskTodosNodeAggregationWhereInput>;
};

export type JourneyHasTaskTodosConnectFieldInput = {
  connect?: InputMaybe<Array<TodoConnectInput>>;
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TodoConnectWhere>;
};

export type JourneyHasTaskTodosConnection = {
  __typename?: "JourneyHasTaskTodosConnection";
  edges: Array<JourneyHasTaskTodosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyHasTaskTodosConnectionSort = {
  edge?: InputMaybe<HasTaskPropertiesSort>;
  node?: InputMaybe<TodoSort>;
};

export type JourneyHasTaskTodosConnectionWhere = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosConnectionWhere>>;
  NOT?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosConnectionWhere>>;
  edge?: InputMaybe<HasTaskPropertiesWhere>;
  node?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosCreateFieldInput = {
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  node: TodoCreateInput;
};

export type JourneyHasTaskTodosDeleteFieldInput = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyHasTaskTodosDisconnectFieldInput = {
  disconnect?: InputMaybe<TodoDisconnectInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyHasTaskTodosFieldInput = {
  connect?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
};

export type JourneyHasTaskTodosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyHasTaskTodosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosNodeAggregationWhereInput>>;
  completed_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyHasTaskTodosRelationship = {
  __typename?: "JourneyHasTaskTodosRelationship";
  cursor: Scalars["String"]["output"];
  node: Todo;
  properties: HasTaskProperties;
};

export type JourneyHasTaskTodosUpdateConnectionInput = {
  edge?: InputMaybe<HasTaskPropertiesUpdateInput>;
  node?: InputMaybe<TodoUpdateInput>;
};

export type JourneyHasTaskTodosUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyHasTaskTodosDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyHasTaskTodosDisconnectFieldInput>>;
  update?: InputMaybe<JourneyHasTaskTodosUpdateConnectionInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more JourneySort objects to sort Journeys by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<JourneySort>>;
};

export type JourneyRelationInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
};

/** Fields to sort Journeys by. The order in which sorts are applied is not guaranteed when specifying many fields in one JourneySort object. */
export type JourneySort = {
  _empty?: InputMaybe<SortDirection>;
  completed?: InputMaybe<SortDirection>;
  completedTasks?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTasks?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type JourneyTodoHasTaskTodosAggregationSelection = {
  __typename?: "JourneyTodoHasTaskTodosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<JourneyTodoHasTaskTodosEdgeAggregateSelection>;
  node?: Maybe<JourneyTodoHasTaskTodosNodeAggregateSelection>;
};

export type JourneyTodoHasTaskTodosEdgeAggregateSelection = {
  __typename?: "JourneyTodoHasTaskTodosEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type JourneyTodoHasTaskTodosNodeAggregateSelection = {
  __typename?: "JourneyTodoHasTaskTodosNodeAggregateSelection";
  completed_date: DateTimeAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type JourneyUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsUpdateFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyUpdateFieldInput>>;
};

export type JourneyUserUsersHasJourneyAggregationSelection = {
  __typename?: "JourneyUserUsersHasJourneyAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<JourneyUserUsersHasJourneyEdgeAggregateSelection>;
  node?: Maybe<JourneyUserUsersHasJourneyNodeAggregateSelection>;
};

export type JourneyUserUsersHasJourneyEdgeAggregateSelection = {
  __typename?: "JourneyUserUsersHasJourneyEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type JourneyUserUsersHasJourneyNodeAggregateSelection = {
  __typename?: "JourneyUserUsersHasJourneyNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type JourneyUsersHasJourneyAggregateInput = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyAggregateInput>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyAggregateInput>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasJourneyPropertiesAggregationWhereInput>;
  node?: InputMaybe<JourneyUsersHasJourneyNodeAggregationWhereInput>;
};

export type JourneyUsersHasJourneyConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type JourneyUsersHasJourneyConnectOrCreateFieldInput = {
  onCreate: JourneyUsersHasJourneyConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type JourneyUsersHasJourneyConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type JourneyUsersHasJourneyConnection = {
  __typename?: "JourneyUsersHasJourneyConnection";
  edges: Array<JourneyUsersHasJourneyRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyUsersHasJourneyConnectionSort = {
  edge?: InputMaybe<HasJourneyPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type JourneyUsersHasJourneyConnectionWhere = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyConnectionWhere>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyConnectionWhere>>;
  edge?: InputMaybe<HasJourneyPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyCreateFieldInput = {
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  node: UserCreateInput;
};

export type JourneyUsersHasJourneyDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyUsersHasJourneyDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyUsersHasJourneyFieldInput = {
  connect?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<JourneyUsersHasJourneyConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
};

export type JourneyUsersHasJourneyNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyUsersHasJourneyRelationship = {
  __typename?: "JourneyUsersHasJourneyRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasJourneyProperties;
};

export type JourneyUsersHasJourneyUpdateConnectionInput = {
  edge?: InputMaybe<HasJourneyPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type JourneyUsersHasJourneyUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<JourneyUsersHasJourneyConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyUsersHasJourneyDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyUsersHasJourneyDisconnectFieldInput>>;
  update?: InputMaybe<JourneyUsersHasJourneyUpdateConnectionInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyWhere = {
  AND?: InputMaybe<Array<JourneyWhere>>;
  NOT?: InputMaybe<JourneyWhere>;
  OR?: InputMaybe<Array<JourneyWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  dueDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<JourneyHasChatChatsAggregateInput>;
  /** Return Journeys where all of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where none of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where one of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where some of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Journeys where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Journeys where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Journeys where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasTaskTodosAggregate?: InputMaybe<JourneyHasTaskTodosAggregateInput>;
  /** Return Journeys where all of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_ALL?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where none of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_NONE?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where one of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_SINGLE?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where some of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_SOME?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where all of the related Todos match this filter */
  hasTaskTodos_ALL?: InputMaybe<TodoWhere>;
  /** Return Journeys where none of the related Todos match this filter */
  hasTaskTodos_NONE?: InputMaybe<TodoWhere>;
  /** Return Journeys where one of the related Todos match this filter */
  hasTaskTodos_SINGLE?: InputMaybe<TodoWhere>;
  /** Return Journeys where some of the related Todos match this filter */
  hasTaskTodos_SOME?: InputMaybe<TodoWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourneyAggregate?: InputMaybe<JourneyUsersHasJourneyAggregateInput>;
  /** Return Journeys where all of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_ALL?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where none of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_NONE?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where one of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_SINGLE?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where some of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_SOME?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where all of the related Users match this filter */
  usersHasJourney_ALL?: InputMaybe<UserWhere>;
  /** Return Journeys where none of the related Users match this filter */
  usersHasJourney_NONE?: InputMaybe<UserWhere>;
  /** Return Journeys where one of the related Users match this filter */
  usersHasJourney_SINGLE?: InputMaybe<UserWhere>;
  /** Return Journeys where some of the related Users match this filter */
  usersHasJourney_SOME?: InputMaybe<UserWhere>;
};

export type JourneysConnection = {
  __typename?: "JourneysConnection";
  edges: Array<JourneyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningCheckoutSession = {
  __typename?: "LearningCheckoutSession";
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type LearningCheckoutSessionAggregateSelection = {
  __typename?: "LearningCheckoutSessionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  url: StringAggregateSelection;
};

export type LearningCheckoutSessionCreateInput = {
  url: Scalars["String"]["input"];
};

export type LearningCheckoutSessionEdge = {
  __typename?: "LearningCheckoutSessionEdge";
  cursor: Scalars["String"]["output"];
  node: LearningCheckoutSession;
};

export type LearningCheckoutSessionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningCheckoutSessionSort objects to sort LearningCheckoutSessions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningCheckoutSessionSort>>;
};

/** Fields to sort LearningCheckoutSessions by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningCheckoutSessionSort object. */
export type LearningCheckoutSessionSort = {
  id?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type LearningCheckoutSessionUpdateInput = {
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningCheckoutSessionWhere = {
  AND?: InputMaybe<Array<LearningCheckoutSessionWhere>>;
  NOT?: InputMaybe<LearningCheckoutSessionWhere>;
  OR?: InputMaybe<Array<LearningCheckoutSessionWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningCheckoutSessionsConnection = {
  __typename?: "LearningCheckoutSessionsConnection";
  edges: Array<LearningCheckoutSessionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningNode = {
  __typename?: "LearningNode";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  unitsCount?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningNodeAggregateSelection = {
  __typename?: "LearningNodeAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  imageUrl: StringAggregateSelection;
  label: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  unitsCount: IntAggregateSelection;
};

export type LearningNodeCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodeEdge = {
  __typename?: "LearningNodeEdge";
  cursor: Scalars["String"]["output"];
  node: LearningNode;
};

export type LearningNodeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningNodeSort objects to sort LearningNodes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningNodeSort>>;
};

/** Fields to sort LearningNodes by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningNodeSort object. */
export type LearningNodeSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  unitsCount?: InputMaybe<SortDirection>;
};

export type LearningNodeUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodeWhere = {
  AND?: InputMaybe<Array<LearningNodeWhere>>;
  NOT?: InputMaybe<LearningNodeWhere>;
  OR?: InputMaybe<Array<LearningNodeWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  unitsCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodesConnection = {
  __typename?: "LearningNodesConnection";
  edges: Array<LearningNodeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningObjective = {
  __typename?: "LearningObjective";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<LearningObjectiveChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: LearningObjectiveHasChatChatsConnection;
  id: Scalars["ID"]["output"];
  index?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  total_mastery?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningObjectiveHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type LearningObjectiveHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type LearningObjectiveHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectionSort>>;
  where?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
};

export type LearningObjectiveAggregateSelection = {
  __typename?: "LearningObjectiveAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  index: IntAggregateSelection;
  title: StringAggregateSelection;
  total_mastery: IntAggregateSelection;
};

export type LearningObjectiveChatHasChatChatsAggregationSelection = {
  __typename?: "LearningObjectiveChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<LearningObjectiveChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<LearningObjectiveChatHasChatChatsNodeAggregateSelection>;
};

export type LearningObjectiveChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "LearningObjectiveChatHasChatChatsEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type LearningObjectiveChatHasChatChatsNodeAggregateSelection = {
  __typename?: "LearningObjectiveChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type LearningObjectiveConnectInput = {
  hasChatChats?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectFieldInput>>;
};

export type LearningObjectiveConnectWhere = {
  node: LearningObjectiveWhere;
};

export type LearningObjectiveCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description: Scalars["String"]["input"];
  hasChatChats?: InputMaybe<LearningObjectiveHasChatChatsFieldInput>;
  id: Scalars["ID"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectiveDeleteInput = {
  hasChatChats?: InputMaybe<Array<LearningObjectiveHasChatChatsDeleteFieldInput>>;
};

export type LearningObjectiveDisconnectInput = {
  hasChatChats?: InputMaybe<Array<LearningObjectiveHasChatChatsDisconnectFieldInput>>;
};

export type LearningObjectiveEdge = {
  __typename?: "LearningObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: LearningObjective;
};

export type LearningObjectiveHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<LearningObjectiveHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<LearningObjectiveHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<LearningObjectiveHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<LearningObjectiveHasChatChatsNodeAggregationWhereInput>;
};

export type LearningObjectiveHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type LearningObjectiveHasChatChatsConnection = {
  __typename?: "LearningObjectiveHasChatChatsConnection";
  edges: Array<LearningObjectiveHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningObjectiveHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type LearningObjectiveHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type LearningObjectiveHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type LearningObjectiveHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
};

export type LearningObjectiveHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
};

export type LearningObjectiveHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<LearningObjectiveHasChatChatsCreateFieldInput>>;
};

export type LearningObjectiveHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<LearningObjectiveHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<LearningObjectiveHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<LearningObjectiveHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectiveHasChatChatsRelationship = {
  __typename?: "LearningObjectiveHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type LearningObjectiveHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type LearningObjectiveHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<LearningObjectiveHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<LearningObjectiveHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<LearningObjectiveHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<LearningObjectiveHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<LearningObjectiveHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
};

export type LearningObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningObjectiveSort objects to sort LearningObjectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningObjectiveSort>>;
};

export type LearningObjectiveRelationInput = {
  hasChatChats?: InputMaybe<Array<LearningObjectiveHasChatChatsCreateFieldInput>>;
};

export type LearningObjectiveResponse = {
  __typename?: "LearningObjectiveResponse";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LearningObjectiveResponseAggregateSelection = {
  __typename?: "LearningObjectiveResponseAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  message: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type LearningObjectiveResponseCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponseEdge = {
  __typename?: "LearningObjectiveResponseEdge";
  cursor: Scalars["String"]["output"];
  node: LearningObjectiveResponse;
};

export type LearningObjectiveResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningObjectiveResponseSort objects to sort LearningObjectiveResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningObjectiveResponseSort>>;
};

/** Fields to sort LearningObjectiveResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningObjectiveResponseSort object. */
export type LearningObjectiveResponseSort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type LearningObjectiveResponseUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponseWhere = {
  AND?: InputMaybe<Array<LearningObjectiveResponseWhere>>;
  NOT?: InputMaybe<LearningObjectiveResponseWhere>;
  OR?: InputMaybe<Array<LearningObjectiveResponseWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponsesConnection = {
  __typename?: "LearningObjectiveResponsesConnection";
  edges: Array<LearningObjectiveResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort LearningObjectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningObjectiveSort object. */
export type LearningObjectiveSort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  total_mastery?: InputMaybe<SortDirection>;
};

export type LearningObjectiveUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<Array<LearningObjectiveHasChatChatsUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectiveWhere = {
  AND?: InputMaybe<Array<LearningObjectiveWhere>>;
  NOT?: InputMaybe<LearningObjectiveWhere>;
  OR?: InputMaybe<Array<LearningObjectiveWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<LearningObjectiveHasChatChatsAggregateInput>;
  /** Return LearningObjectives where all of the related LearningObjectiveHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
  /** Return LearningObjectives where none of the related LearningObjectiveHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
  /** Return LearningObjectives where one of the related LearningObjectiveHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
  /** Return LearningObjectives where some of the related LearningObjectiveHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<LearningObjectiveHasChatChatsConnectionWhere>;
  /** Return LearningObjectives where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return LearningObjectives where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return LearningObjectives where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return LearningObjectives where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  total_mastery_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectivesConnection = {
  __typename?: "LearningObjectivesConnection";
  edges: Array<LearningObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningPayment = {
  __typename?: "LearningPayment";
  amount: Scalars["Float"]["output"];
  course: Course;
  courseAggregate?: Maybe<LearningPaymentCourseCourseAggregationSelection>;
  courseConnection: LearningPaymentCourseConnection;
  created: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<LearningPaymentUserUsersAggregationSelection>;
  usersConnection: LearningPaymentUsersConnection;
};

export type LearningPaymentCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type LearningPaymentCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type LearningPaymentCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<LearningPaymentCourseConnectionSort>>;
  where?: InputMaybe<LearningPaymentCourseConnectionWhere>;
};

export type LearningPaymentUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type LearningPaymentUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type LearningPaymentUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<LearningPaymentUsersConnectionSort>>;
  where?: InputMaybe<LearningPaymentUsersConnectionWhere>;
};

export type LearningPaymentAggregateSelection = {
  __typename?: "LearningPaymentAggregateSelection";
  amount: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type LearningPaymentConnectInput = {
  course?: InputMaybe<LearningPaymentCourseConnectFieldInput>;
  users?: InputMaybe<Array<LearningPaymentUsersConnectFieldInput>>;
};

export type LearningPaymentConnectOrCreateInput = {
  course?: InputMaybe<LearningPaymentCourseConnectOrCreateFieldInput>;
  users?: InputMaybe<Array<LearningPaymentUsersConnectOrCreateFieldInput>>;
};

export type LearningPaymentConnectOrCreateWhere = {
  node: LearningPaymentUniqueWhere;
};

export type LearningPaymentConnectWhere = {
  node: LearningPaymentWhere;
};

export type LearningPaymentCourseAggregateInput = {
  AND?: InputMaybe<Array<LearningPaymentCourseAggregateInput>>;
  NOT?: InputMaybe<LearningPaymentCourseAggregateInput>;
  OR?: InputMaybe<Array<LearningPaymentCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<LearningPaymentCourseNodeAggregationWhereInput>;
};

export type LearningPaymentCourseConnectFieldInput = {
  connect?: InputMaybe<CourseConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type LearningPaymentCourseConnectOrCreateFieldInput = {
  onCreate: LearningPaymentCourseConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type LearningPaymentCourseConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type LearningPaymentCourseConnection = {
  __typename?: "LearningPaymentCourseConnection";
  edges: Array<LearningPaymentCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningPaymentCourseConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type LearningPaymentCourseConnectionWhere = {
  AND?: InputMaybe<Array<LearningPaymentCourseConnectionWhere>>;
  NOT?: InputMaybe<LearningPaymentCourseConnectionWhere>;
  OR?: InputMaybe<Array<LearningPaymentCourseConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type LearningPaymentCourseCourseAggregationSelection = {
  __typename?: "LearningPaymentCourseCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<LearningPaymentCourseCourseNodeAggregateSelection>;
};

export type LearningPaymentCourseCourseNodeAggregateSelection = {
  __typename?: "LearningPaymentCourseCourseNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type LearningPaymentCourseCreateFieldInput = {
  node: CourseCreateInput;
};

export type LearningPaymentCourseDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<LearningPaymentCourseConnectionWhere>;
};

export type LearningPaymentCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<LearningPaymentCourseConnectionWhere>;
};

export type LearningPaymentCourseFieldInput = {
  connect?: InputMaybe<LearningPaymentCourseConnectFieldInput>;
  connectOrCreate?: InputMaybe<LearningPaymentCourseConnectOrCreateFieldInput>;
  create?: InputMaybe<LearningPaymentCourseCreateFieldInput>;
};

export type LearningPaymentCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<LearningPaymentCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<LearningPaymentCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<LearningPaymentCourseNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningPaymentCourseRelationship = {
  __typename?: "LearningPaymentCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type LearningPaymentCourseUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type LearningPaymentCourseUpdateFieldInput = {
  connect?: InputMaybe<LearningPaymentCourseConnectFieldInput>;
  connectOrCreate?: InputMaybe<LearningPaymentCourseConnectOrCreateFieldInput>;
  create?: InputMaybe<LearningPaymentCourseCreateFieldInput>;
  delete?: InputMaybe<LearningPaymentCourseDeleteFieldInput>;
  disconnect?: InputMaybe<LearningPaymentCourseDisconnectFieldInput>;
  update?: InputMaybe<LearningPaymentCourseUpdateConnectionInput>;
  where?: InputMaybe<LearningPaymentCourseConnectionWhere>;
};

export type LearningPaymentCreateInput = {
  amount: Scalars["Float"]["input"];
  course?: InputMaybe<LearningPaymentCourseFieldInput>;
  created: Scalars["DateTime"]["input"];
  users?: InputMaybe<LearningPaymentUsersFieldInput>;
};

export type LearningPaymentDeleteInput = {
  course?: InputMaybe<LearningPaymentCourseDeleteFieldInput>;
  users?: InputMaybe<Array<LearningPaymentUsersDeleteFieldInput>>;
};

export type LearningPaymentDisconnectInput = {
  course?: InputMaybe<LearningPaymentCourseDisconnectFieldInput>;
  users?: InputMaybe<Array<LearningPaymentUsersDisconnectFieldInput>>;
};

export type LearningPaymentEdge = {
  __typename?: "LearningPaymentEdge";
  cursor: Scalars["String"]["output"];
  node: LearningPayment;
};

export type LearningPaymentOnCreateInput = {
  amount: Scalars["Float"]["input"];
  created: Scalars["DateTime"]["input"];
};

export type LearningPaymentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningPaymentSort objects to sort LearningPayments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningPaymentSort>>;
};

export type LearningPaymentRelationInput = {
  course?: InputMaybe<LearningPaymentCourseCreateFieldInput>;
  users?: InputMaybe<Array<LearningPaymentUsersCreateFieldInput>>;
};

/** Fields to sort LearningPayments by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningPaymentSort object. */
export type LearningPaymentSort = {
  amount?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type LearningPaymentUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LearningPaymentUpdateInput = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  amount_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  amount_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  course?: InputMaybe<LearningPaymentCourseUpdateFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<Array<LearningPaymentUsersUpdateFieldInput>>;
};

export type LearningPaymentUserUsersAggregationSelection = {
  __typename?: "LearningPaymentUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<LearningPaymentUserUsersNodeAggregateSelection>;
};

export type LearningPaymentUserUsersNodeAggregateSelection = {
  __typename?: "LearningPaymentUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type LearningPaymentUsersAggregateInput = {
  AND?: InputMaybe<Array<LearningPaymentUsersAggregateInput>>;
  NOT?: InputMaybe<LearningPaymentUsersAggregateInput>;
  OR?: InputMaybe<Array<LearningPaymentUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<LearningPaymentUsersNodeAggregationWhereInput>;
};

export type LearningPaymentUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type LearningPaymentUsersConnectOrCreateFieldInput = {
  onCreate: LearningPaymentUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type LearningPaymentUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type LearningPaymentUsersConnection = {
  __typename?: "LearningPaymentUsersConnection";
  edges: Array<LearningPaymentUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningPaymentUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type LearningPaymentUsersConnectionWhere = {
  AND?: InputMaybe<Array<LearningPaymentUsersConnectionWhere>>;
  NOT?: InputMaybe<LearningPaymentUsersConnectionWhere>;
  OR?: InputMaybe<Array<LearningPaymentUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type LearningPaymentUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type LearningPaymentUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<LearningPaymentUsersConnectionWhere>;
};

export type LearningPaymentUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<LearningPaymentUsersConnectionWhere>;
};

export type LearningPaymentUsersFieldInput = {
  connect?: InputMaybe<Array<LearningPaymentUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<LearningPaymentUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<LearningPaymentUsersCreateFieldInput>>;
};

export type LearningPaymentUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<LearningPaymentUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<LearningPaymentUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<LearningPaymentUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningPaymentUsersRelationship = {
  __typename?: "LearningPaymentUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type LearningPaymentUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type LearningPaymentUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<LearningPaymentUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<LearningPaymentUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<LearningPaymentUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<LearningPaymentUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<LearningPaymentUsersDisconnectFieldInput>>;
  update?: InputMaybe<LearningPaymentUsersUpdateConnectionInput>;
  where?: InputMaybe<LearningPaymentUsersConnectionWhere>;
};

export type LearningPaymentWhere = {
  AND?: InputMaybe<Array<LearningPaymentWhere>>;
  NOT?: InputMaybe<LearningPaymentWhere>;
  OR?: InputMaybe<Array<LearningPaymentWhere>>;
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  amount_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  amount_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course?: InputMaybe<CourseWhere>;
  courseAggregate?: InputMaybe<LearningPaymentCourseAggregateInput>;
  courseConnection?: InputMaybe<LearningPaymentCourseConnectionWhere>;
  courseConnection_NOT?: InputMaybe<LearningPaymentCourseConnectionWhere>;
  course_NOT?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  usersAggregate?: InputMaybe<LearningPaymentUsersAggregateInput>;
  /** Return LearningPayments where all of the related LearningPaymentUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<LearningPaymentUsersConnectionWhere>;
  /** Return LearningPayments where none of the related LearningPaymentUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<LearningPaymentUsersConnectionWhere>;
  /** Return LearningPayments where one of the related LearningPaymentUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<LearningPaymentUsersConnectionWhere>;
  /** Return LearningPayments where some of the related LearningPaymentUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<LearningPaymentUsersConnectionWhere>;
  /** Return LearningPayments where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return LearningPayments where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return LearningPayments where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return LearningPayments where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type LearningPaymentsConnection = {
  __typename?: "LearningPaymentsConnection";
  edges: Array<LearningPaymentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningSummariesConnection = {
  __typename?: "LearningSummariesConnection";
  edges: Array<LearningSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningSummary = {
  __typename?: "LearningSummary";
  subchaptersProgressed?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningSummaryAggregateSelection = {
  __typename?: "LearningSummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  subchaptersProgressed: IntAggregateSelection;
};

export type LearningSummaryCreateInput = {
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningSummaryEdge = {
  __typename?: "LearningSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: LearningSummary;
};

export type LearningSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningSummarySort objects to sort LearningSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningSummarySort>>;
};

/** Fields to sort LearningSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningSummarySort object. */
export type LearningSummarySort = {
  subchaptersProgressed?: InputMaybe<SortDirection>;
};

export type LearningSummaryUpdateInput = {
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningSummaryWhere = {
  AND?: InputMaybe<Array<LearningSummaryWhere>>;
  NOT?: InputMaybe<LearningSummaryWhere>;
  OR?: InputMaybe<Array<LearningSummaryWhere>>;
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  subchaptersProgressed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningTree = {
  __typename?: "LearningTree";
  ChapterName?: Maybe<Scalars["String"]["output"]>;
  FieldName?: Maybe<Scalars["String"]["output"]>;
  SubchapterName?: Maybe<Scalars["String"]["output"]>;
  SubfieldName?: Maybe<Scalars["String"]["output"]>;
  TopicName?: Maybe<Scalars["String"]["output"]>;
};

export type LearningTreeAggregateSelection = {
  __typename?: "LearningTreeAggregateSelection";
  ChapterName: StringAggregateSelection;
  FieldName: StringAggregateSelection;
  SubchapterName: StringAggregateSelection;
  SubfieldName: StringAggregateSelection;
  TopicName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
};

export type LearningTreeCreateInput = {
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreeEdge = {
  __typename?: "LearningTreeEdge";
  cursor: Scalars["String"]["output"];
  node: LearningTree;
};

export type LearningTreeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningTreeSort objects to sort LearningTrees by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningTreeSort>>;
};

/** Fields to sort LearningTrees by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningTreeSort object. */
export type LearningTreeSort = {
  ChapterName?: InputMaybe<SortDirection>;
  FieldName?: InputMaybe<SortDirection>;
  SubchapterName?: InputMaybe<SortDirection>;
  SubfieldName?: InputMaybe<SortDirection>;
  TopicName?: InputMaybe<SortDirection>;
};

export type LearningTreeUpdateInput = {
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreeWhere = {
  AND?: InputMaybe<Array<LearningTreeWhere>>;
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ChapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  FieldName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  NOT?: InputMaybe<LearningTreeWhere>;
  OR?: InputMaybe<Array<LearningTreeWhere>>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  SubchapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  SubfieldName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  TopicName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreesConnection = {
  __typename?: "LearningTreesConnection";
  edges: Array<LearningTreeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Lesson = {
  __typename?: "Lesson";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LessonAggregateSelection = {
  __typename?: "LessonAggregateSelection";
  category: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  difficultyLevel: StringAggregateSelection;
  endDate: DateTimeAggregateSelection;
  format: StringAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
};

export type LessonCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LessonEdge = {
  __typename?: "LessonEdge";
  cursor: Scalars["String"]["output"];
  node: Lesson;
};

export type LessonOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LessonSort objects to sort Lessons by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LessonSort>>;
};

/** Fields to sort Lessons by. The order in which sorts are applied is not guaranteed when specifying many fields in one LessonSort object. */
export type LessonSort = {
  category?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  difficultyLevel?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  format?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type LessonUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LessonWhere = {
  AND?: InputMaybe<Array<LessonWhere>>;
  NOT?: InputMaybe<LessonWhere>;
  OR?: InputMaybe<Array<LessonWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  difficultyLevel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  format_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  format_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  format_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  format_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LessonsConnection = {
  __typename?: "LessonsConnection";
  edges: Array<LessonEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LevelDetails = {
  __typename?: "LevelDetails";
  currentXP: Scalars["Int"]["output"];
  level: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  progressPercentage: Scalars["Int"]["output"];
  startingXPForCurrentLevel: Scalars["Int"]["output"];
  xpToNextLevel: Scalars["Int"]["output"];
};

export type LevelDetailsAggregateSelection = {
  __typename?: "LevelDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  currentXP: IntAggregateSelection;
  level: IntAggregateSelection;
  name: StringAggregateSelection;
  progressPercentage: IntAggregateSelection;
  startingXPForCurrentLevel: IntAggregateSelection;
  xpToNextLevel: IntAggregateSelection;
};

export type LevelDetailsConnection = {
  __typename?: "LevelDetailsConnection";
  edges: Array<LevelDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LevelDetailsCreateInput = {
  currentXP: Scalars["Int"]["input"];
  level: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage: Scalars["Int"]["input"];
  startingXPForCurrentLevel: Scalars["Int"]["input"];
  xpToNextLevel: Scalars["Int"]["input"];
};

export type LevelDetailsEdge = {
  __typename?: "LevelDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: LevelDetails;
};

export type LevelDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LevelDetailsSort objects to sort LevelDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LevelDetailsSort>>;
};

/** Fields to sort LevelDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one LevelDetailsSort object. */
export type LevelDetailsSort = {
  currentXP?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  progressPercentage?: InputMaybe<SortDirection>;
  startingXPForCurrentLevel?: InputMaybe<SortDirection>;
  xpToNextLevel?: InputMaybe<SortDirection>;
};

export type LevelDetailsUpdateInput = {
  currentXP?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  level_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LevelDetailsWhere = {
  AND?: InputMaybe<Array<LevelDetailsWhere>>;
  NOT?: InputMaybe<LevelDetailsWhere>;
  OR?: InputMaybe<Array<LevelDetailsWhere>>;
  currentXP?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_GT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  currentXP_LT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  level_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  level_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_GT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  progressPercentage_LT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_GT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  startingXPForCurrentLevel_LT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_GT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  xpToNextLevel_LT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LifeAspiration = {
  __typename?: "LifeAspiration";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type LifeAspirationAggregateSelection = {
  __typename?: "LifeAspirationAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  lastUpdated: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  startDate: DateTimeAggregateSelection;
  status: StringAggregateSelection;
};

export type LifeAspirationCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationEdge = {
  __typename?: "LifeAspirationEdge";
  cursor: Scalars["String"]["output"];
  node: LifeAspiration;
};

export type LifeAspirationOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LifeAspirationSort objects to sort LifeAspirations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LifeAspirationSort>>;
};

/** Fields to sort LifeAspirations by. The order in which sorts are applied is not guaranteed when specifying many fields in one LifeAspirationSort object. */
export type LifeAspirationSort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type LifeAspirationUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationWhere = {
  AND?: InputMaybe<Array<LifeAspirationWhere>>;
  NOT?: InputMaybe<LifeAspirationWhere>;
  OR?: InputMaybe<Array<LifeAspirationWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationsConnection = {
  __typename?: "LifeAspirationsConnection";
  edges: Array<LifeAspirationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MasteryResult = {
  __typename?: "MasteryResult";
  learningObjectiveId?: Maybe<Scalars["ID"]["output"]>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export type MasteryResultAggregateSelection = {
  __typename?: "MasteryResultAggregateSelection";
  count: Scalars["Int"]["output"];
  learningObjectiveId: IdAggregateSelection;
  totalMastery: FloatAggregateSelection;
  userId: IdAggregateSelection;
};

export type MasteryResultCreateInput = {
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultEdge = {
  __typename?: "MasteryResultEdge";
  cursor: Scalars["String"]["output"];
  node: MasteryResult;
};

export type MasteryResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MasteryResultSort objects to sort MasteryResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MasteryResultSort>>;
};

/** Fields to sort MasteryResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one MasteryResultSort object. */
export type MasteryResultSort = {
  learningObjectiveId?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type MasteryResultUpdateInput = {
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultWhere = {
  AND?: InputMaybe<Array<MasteryResultWhere>>;
  NOT?: InputMaybe<MasteryResultWhere>;
  OR?: InputMaybe<Array<MasteryResultWhere>>;
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  learningObjectiveId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  userId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  userId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  userId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  userId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultsConnection = {
  __typename?: "MasteryResultsConnection";
  edges: Array<MasteryResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MeResponse = {
  __typename?: "MeResponse";
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
  biography?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currentWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  enrolledTracks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  goalWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  googleCalendarAuthToken?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isCareerLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isCourseLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  meetingLink?: Maybe<Scalars["String"]["output"]>;
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  profilePicture?: Maybe<Scalars["String"]["output"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type MeResponseAggregateSelection = {
  __typename?: "MeResponseAggregateSelection";
  biography: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type MeResponseCreateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponseEdge = {
  __typename?: "MeResponseEdge";
  cursor: Scalars["String"]["output"];
  node: MeResponse;
};

export type MeResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MeResponseSort objects to sort MeResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MeResponseSort>>;
};

/** Fields to sort MeResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one MeResponseSort object. */
export type MeResponseSort = {
  audioOn?: InputMaybe<SortDirection>;
  biography?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  currentWeightLbs?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  goalWeightLbs?: InputMaybe<SortDirection>;
  googleCalendarAuthToken?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isCareerLoading?: InputMaybe<SortDirection>;
  isCourseLoading?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  meetingLink?: InputMaybe<SortDirection>;
  modalOnboarding?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  profilePicture?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type MeResponseUpdateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_POP?: InputMaybe<Scalars["Int"]["input"]>;
  enrolledTracks_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponseWhere = {
  AND?: InputMaybe<Array<MeResponseWhere>>;
  NOT?: InputMaybe<MeResponseWhere>;
  OR?: InputMaybe<Array<MeResponseWhere>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  biography_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  biography_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  biography_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  biography_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  currentWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  goalWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleCalendarAuthToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meetingLink_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  profilePicture_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  username_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  username_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponsesConnection = {
  __typename?: "MeResponsesConnection";
  edges: Array<MeResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Message = {
  __typename?: "Message";
  aspectDetails?: Maybe<Scalars["String"]["output"]>;
  chatsHasMessage: Array<Chat>;
  chatsHasMessageAggregate?: Maybe<MessageChatChatsHasMessageAggregationSelection>;
  chatsHasMessageConnection: MessageChatsHasMessageConnection;
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  emotion?: Maybe<Scalars["String"]["output"]>;
  file?: Maybe<File>;
  fileAggregate?: Maybe<MessageFileFileAggregationSelection>;
  fileConnection: MessageFileConnection;
  flagged?: Maybe<Scalars["Boolean"]["output"]>;
  harassment?: Maybe<Scalars["Boolean"]["output"]>;
  harassment_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  hate?: Maybe<Scalars["Boolean"]["output"]>;
  hate_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  index?: Maybe<Scalars["Int"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  nodeId?: Maybe<Scalars["String"]["output"]>;
  parameters?: Maybe<Scalars["String"]["output"]>;
  self_harm?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_instructions?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_intent?: Maybe<Scalars["Boolean"]["output"]>;
  sender?: Maybe<Scalars["String"]["output"]>;
  sexual?: Maybe<Scalars["Boolean"]["output"]>;
  sexual_minors?: Maybe<Scalars["Boolean"]["output"]>;
  snackType?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  video?: Maybe<Video>;
  videoAggregate?: Maybe<MessageVideoVideoAggregationSelection>;
  videoConnection: MessageVideoConnection;
  violence?: Maybe<Scalars["Boolean"]["output"]>;
  violence_graphic?: Maybe<Scalars["Boolean"]["output"]>;
  visualization?: Maybe<Visualization>;
  visualizationAggregate?: Maybe<MessageVisualizationVisualizationAggregationSelection>;
  visualizationConnection: MessageVisualizationConnection;
};

export type MessageChatsHasMessageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageChatsHasMessageConnectionSort>>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageFileArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type MessageFileAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type MessageFileConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageFileConnectionSort>>;
  where?: InputMaybe<MessageFileConnectionWhere>;
};

export type MessageVideoArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<VideoOptions>;
  where?: InputMaybe<VideoWhere>;
};

export type MessageVideoAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<VideoWhere>;
};

export type MessageVideoConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageVideoConnectionSort>>;
  where?: InputMaybe<MessageVideoConnectionWhere>;
};

export type MessageVisualizationArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<VisualizationOptions>;
  where?: InputMaybe<VisualizationWhere>;
};

export type MessageVisualizationAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<VisualizationWhere>;
};

export type MessageVisualizationConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageVisualizationConnectionSort>>;
  where?: InputMaybe<MessageVisualizationConnectionWhere>;
};

export type MessageAggregateSelection = {
  __typename?: "MessageAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type MessageChatChatsHasMessageAggregationSelection = {
  __typename?: "MessageChatChatsHasMessageAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<MessageChatChatsHasMessageEdgeAggregateSelection>;
  node?: Maybe<MessageChatChatsHasMessageNodeAggregateSelection>;
};

export type MessageChatChatsHasMessageEdgeAggregateSelection = {
  __typename?: "MessageChatChatsHasMessageEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type MessageChatChatsHasMessageNodeAggregateSelection = {
  __typename?: "MessageChatChatsHasMessageNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type MessageChatsHasMessageAggregateInput = {
  AND?: InputMaybe<Array<MessageChatsHasMessageAggregateInput>>;
  NOT?: InputMaybe<MessageChatsHasMessageAggregateInput>;
  OR?: InputMaybe<Array<MessageChatsHasMessageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<MessageChatsHasMessageNodeAggregationWhereInput>;
};

export type MessageChatsHasMessageConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type MessageChatsHasMessageConnection = {
  __typename?: "MessageChatsHasMessageConnection";
  edges: Array<MessageChatsHasMessageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageChatsHasMessageConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type MessageChatsHasMessageConnectionWhere = {
  AND?: InputMaybe<Array<MessageChatsHasMessageConnectionWhere>>;
  NOT?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  OR?: InputMaybe<Array<MessageChatsHasMessageConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: ChatCreateInput;
};

export type MessageChatsHasMessageDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageChatsHasMessageDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageChatsHasMessageFieldInput = {
  connect?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
};

export type MessageChatsHasMessageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageChatsHasMessageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageChatsHasMessageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageChatsHasMessageNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageChatsHasMessageRelationship = {
  __typename?: "MessageChatsHasMessageRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasMessageProperties;
};

export type MessageChatsHasMessageUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type MessageChatsHasMessageUpdateFieldInput = {
  connect?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
  delete?: InputMaybe<Array<MessageChatsHasMessageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<MessageChatsHasMessageDisconnectFieldInput>>;
  update?: InputMaybe<MessageChatsHasMessageUpdateConnectionInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageConnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
  file?: InputMaybe<MessageFileConnectFieldInput>;
  video?: InputMaybe<MessageVideoConnectFieldInput>;
  visualization?: InputMaybe<MessageVisualizationConnectFieldInput>;
};

export type MessageConnectOrCreateInput = {
  video?: InputMaybe<MessageVideoConnectOrCreateFieldInput>;
};

export type MessageConnectWhere = {
  node: MessageWhere;
};

export type MessageCreateInput = {
  aspectDetails?: InputMaybe<Scalars["String"]["input"]>;
  chatsHasMessage?: InputMaybe<MessageChatsHasMessageFieldInput>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<MessageFileFieldInput>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  video?: InputMaybe<MessageVideoFieldInput>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  visualization?: InputMaybe<MessageVisualizationFieldInput>;
};

export type MessageDeleteInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageDeleteFieldInput>>;
  file?: InputMaybe<MessageFileDeleteFieldInput>;
  video?: InputMaybe<MessageVideoDeleteFieldInput>;
  visualization?: InputMaybe<MessageVisualizationDeleteFieldInput>;
};

export type MessageDisconnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageDisconnectFieldInput>>;
  file?: InputMaybe<MessageFileDisconnectFieldInput>;
  video?: InputMaybe<MessageVideoDisconnectFieldInput>;
  visualization?: InputMaybe<MessageVisualizationDisconnectFieldInput>;
};

export type MessageEdge = {
  __typename?: "MessageEdge";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type MessageFileAggregateInput = {
  AND?: InputMaybe<Array<MessageFileAggregateInput>>;
  NOT?: InputMaybe<MessageFileAggregateInput>;
  OR?: InputMaybe<Array<MessageFileAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<MessageFileNodeAggregationWhereInput>;
};

export type MessageFileConnectFieldInput = {
  connect?: InputMaybe<FileConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type MessageFileConnection = {
  __typename?: "MessageFileConnection";
  edges: Array<MessageFileRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageFileConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type MessageFileConnectionWhere = {
  AND?: InputMaybe<Array<MessageFileConnectionWhere>>;
  NOT?: InputMaybe<MessageFileConnectionWhere>;
  OR?: InputMaybe<Array<MessageFileConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type MessageFileCreateFieldInput = {
  node: FileCreateInput;
};

export type MessageFileDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<MessageFileConnectionWhere>;
};

export type MessageFileDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<MessageFileConnectionWhere>;
};

export type MessageFileFieldInput = {
  connect?: InputMaybe<MessageFileConnectFieldInput>;
  create?: InputMaybe<MessageFileCreateFieldInput>;
};

export type MessageFileFileAggregationSelection = {
  __typename?: "MessageFileFileAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<MessageFileFileNodeAggregateSelection>;
};

export type MessageFileFileNodeAggregateSelection = {
  __typename?: "MessageFileFileNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type MessageFileNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageFileNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageFileNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageFileNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageFileRelationship = {
  __typename?: "MessageFileRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type MessageFileUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type MessageFileUpdateFieldInput = {
  connect?: InputMaybe<MessageFileConnectFieldInput>;
  create?: InputMaybe<MessageFileCreateFieldInput>;
  delete?: InputMaybe<MessageFileDeleteFieldInput>;
  disconnect?: InputMaybe<MessageFileDisconnectFieldInput>;
  update?: InputMaybe<MessageFileUpdateConnectionInput>;
  where?: InputMaybe<MessageFileConnectionWhere>;
};

export type MessageOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MessageSort objects to sort Messages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MessageSort>>;
};

export type MessageRelationInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
  file?: InputMaybe<MessageFileCreateFieldInput>;
  video?: InputMaybe<MessageVideoCreateFieldInput>;
  visualization?: InputMaybe<MessageVisualizationCreateFieldInput>;
};

export type MessageResponse = {
  __typename?: "MessageResponse";
  aspectDetails?: Maybe<AspectDetailsType>;
  chatsHasMessage: Array<Chat>;
  chatsHasMessageAggregate?: Maybe<MessageResponseChatChatsHasMessageAggregationSelection>;
  chatsHasMessageConnection: MessageResponseChatsHasMessageConnection;
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  emotion?: Maybe<Scalars["String"]["output"]>;
  file?: Maybe<File>;
  fileAggregate?: Maybe<MessageResponseFileFileAggregationSelection>;
  fileConnection: MessageResponseFileConnection;
  flagged?: Maybe<Scalars["Boolean"]["output"]>;
  harassment?: Maybe<Scalars["Boolean"]["output"]>;
  harassment_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  hate?: Maybe<Scalars["Boolean"]["output"]>;
  hate_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  index?: Maybe<Scalars["Int"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  nodeId?: Maybe<Scalars["String"]["output"]>;
  parameters?: Maybe<ParametersType>;
  self_harm?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_instructions?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_intent?: Maybe<Scalars["Boolean"]["output"]>;
  sender?: Maybe<Scalars["String"]["output"]>;
  sexual?: Maybe<Scalars["Boolean"]["output"]>;
  sexual_minors?: Maybe<Scalars["Boolean"]["output"]>;
  snackType?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  violence?: Maybe<Scalars["Boolean"]["output"]>;
  violence_graphic?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MessageResponseChatsHasMessageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageResponseChatsHasMessageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageResponseChatsHasMessageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageResponseChatsHasMessageConnectionSort>>;
  where?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
};

export type MessageResponseFileArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type MessageResponseFileAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type MessageResponseFileConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageResponseFileConnectionSort>>;
  where?: InputMaybe<MessageResponseFileConnectionWhere>;
};

export type MessageResponseAggregateSelection = {
  __typename?: "MessageResponseAggregateSelection";
  content: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type MessageResponseChatChatsHasMessageAggregationSelection = {
  __typename?: "MessageResponseChatChatsHasMessageAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<MessageResponseChatChatsHasMessageEdgeAggregateSelection>;
  node?: Maybe<MessageResponseChatChatsHasMessageNodeAggregateSelection>;
};

export type MessageResponseChatChatsHasMessageEdgeAggregateSelection = {
  __typename?: "MessageResponseChatChatsHasMessageEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type MessageResponseChatChatsHasMessageNodeAggregateSelection = {
  __typename?: "MessageResponseChatChatsHasMessageNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type MessageResponseChatsHasMessageAggregateInput = {
  AND?: InputMaybe<Array<MessageResponseChatsHasMessageAggregateInput>>;
  NOT?: InputMaybe<MessageResponseChatsHasMessageAggregateInput>;
  OR?: InputMaybe<Array<MessageResponseChatsHasMessageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<MessageResponseChatsHasMessageNodeAggregationWhereInput>;
};

export type MessageResponseChatsHasMessageConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type MessageResponseChatsHasMessageConnection = {
  __typename?: "MessageResponseChatsHasMessageConnection";
  edges: Array<MessageResponseChatsHasMessageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageResponseChatsHasMessageConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type MessageResponseChatsHasMessageConnectionWhere = {
  AND?: InputMaybe<Array<MessageResponseChatsHasMessageConnectionWhere>>;
  NOT?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
  OR?: InputMaybe<Array<MessageResponseChatsHasMessageConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type MessageResponseChatsHasMessageCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: ChatCreateInput;
};

export type MessageResponseChatsHasMessageDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
};

export type MessageResponseChatsHasMessageDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
};

export type MessageResponseChatsHasMessageFieldInput = {
  connect?: InputMaybe<Array<MessageResponseChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageResponseChatsHasMessageCreateFieldInput>>;
};

export type MessageResponseChatsHasMessageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageResponseChatsHasMessageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageResponseChatsHasMessageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageResponseChatsHasMessageNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageResponseChatsHasMessageRelationship = {
  __typename?: "MessageResponseChatsHasMessageRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasMessageProperties;
};

export type MessageResponseChatsHasMessageUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type MessageResponseChatsHasMessageUpdateFieldInput = {
  connect?: InputMaybe<Array<MessageResponseChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageResponseChatsHasMessageCreateFieldInput>>;
  delete?: InputMaybe<Array<MessageResponseChatsHasMessageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<MessageResponseChatsHasMessageDisconnectFieldInput>>;
  update?: InputMaybe<MessageResponseChatsHasMessageUpdateConnectionInput>;
  where?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
};

export type MessageResponseConnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageResponseChatsHasMessageConnectFieldInput>>;
  file?: InputMaybe<MessageResponseFileConnectFieldInput>;
};

export type MessageResponseCreateInput = {
  chatsHasMessage?: InputMaybe<MessageResponseChatsHasMessageFieldInput>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<MessageResponseFileFieldInput>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageResponseDeleteInput = {
  chatsHasMessage?: InputMaybe<Array<MessageResponseChatsHasMessageDeleteFieldInput>>;
  file?: InputMaybe<MessageResponseFileDeleteFieldInput>;
};

export type MessageResponseDisconnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageResponseChatsHasMessageDisconnectFieldInput>>;
  file?: InputMaybe<MessageResponseFileDisconnectFieldInput>;
};

export type MessageResponseEdge = {
  __typename?: "MessageResponseEdge";
  cursor: Scalars["String"]["output"];
  node: MessageResponse;
};

export type MessageResponseFileAggregateInput = {
  AND?: InputMaybe<Array<MessageResponseFileAggregateInput>>;
  NOT?: InputMaybe<MessageResponseFileAggregateInput>;
  OR?: InputMaybe<Array<MessageResponseFileAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<MessageResponseFileNodeAggregationWhereInput>;
};

export type MessageResponseFileConnectFieldInput = {
  connect?: InputMaybe<FileConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type MessageResponseFileConnection = {
  __typename?: "MessageResponseFileConnection";
  edges: Array<MessageResponseFileRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageResponseFileConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type MessageResponseFileConnectionWhere = {
  AND?: InputMaybe<Array<MessageResponseFileConnectionWhere>>;
  NOT?: InputMaybe<MessageResponseFileConnectionWhere>;
  OR?: InputMaybe<Array<MessageResponseFileConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type MessageResponseFileCreateFieldInput = {
  node: FileCreateInput;
};

export type MessageResponseFileDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<MessageResponseFileConnectionWhere>;
};

export type MessageResponseFileDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<MessageResponseFileConnectionWhere>;
};

export type MessageResponseFileFieldInput = {
  connect?: InputMaybe<MessageResponseFileConnectFieldInput>;
  create?: InputMaybe<MessageResponseFileCreateFieldInput>;
};

export type MessageResponseFileFileAggregationSelection = {
  __typename?: "MessageResponseFileFileAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<MessageResponseFileFileNodeAggregateSelection>;
};

export type MessageResponseFileFileNodeAggregateSelection = {
  __typename?: "MessageResponseFileFileNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type MessageResponseFileNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageResponseFileNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageResponseFileNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageResponseFileNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageResponseFileRelationship = {
  __typename?: "MessageResponseFileRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type MessageResponseFileUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type MessageResponseFileUpdateFieldInput = {
  connect?: InputMaybe<MessageResponseFileConnectFieldInput>;
  create?: InputMaybe<MessageResponseFileCreateFieldInput>;
  delete?: InputMaybe<MessageResponseFileDeleteFieldInput>;
  disconnect?: InputMaybe<MessageResponseFileDisconnectFieldInput>;
  update?: InputMaybe<MessageResponseFileUpdateConnectionInput>;
  where?: InputMaybe<MessageResponseFileConnectionWhere>;
};

export type MessageResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MessageResponseSort objects to sort MessageResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MessageResponseSort>>;
};

export type MessageResponseRelationInput = {
  chatsHasMessage?: InputMaybe<Array<MessageResponseChatsHasMessageCreateFieldInput>>;
  file?: InputMaybe<MessageResponseFileCreateFieldInput>;
};

/** Fields to sort MessageResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one MessageResponseSort object. */
export type MessageResponseSort = {
  content?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  emotion?: InputMaybe<SortDirection>;
  flagged?: InputMaybe<SortDirection>;
  harassment?: InputMaybe<SortDirection>;
  harassment_threatening?: InputMaybe<SortDirection>;
  hate?: InputMaybe<SortDirection>;
  hate_threatening?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  nodeId?: InputMaybe<SortDirection>;
  self_harm?: InputMaybe<SortDirection>;
  self_harm_instructions?: InputMaybe<SortDirection>;
  self_harm_intent?: InputMaybe<SortDirection>;
  sender?: InputMaybe<SortDirection>;
  sexual?: InputMaybe<SortDirection>;
  sexual_minors?: InputMaybe<SortDirection>;
  snackType?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  violence?: InputMaybe<SortDirection>;
  violence_graphic?: InputMaybe<SortDirection>;
};

export type MessageResponseUpdateInput = {
  chatsHasMessage?: InputMaybe<Array<MessageResponseChatsHasMessageUpdateFieldInput>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<MessageResponseFileUpdateFieldInput>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageResponseWhere = {
  AND?: InputMaybe<Array<MessageResponseWhere>>;
  NOT?: InputMaybe<MessageResponseWhere>;
  OR?: InputMaybe<Array<MessageResponseWhere>>;
  chatsHasMessageAggregate?: InputMaybe<MessageResponseChatsHasMessageAggregateInput>;
  /** Return MessageResponses where all of the related MessageResponseChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_ALL?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
  /** Return MessageResponses where none of the related MessageResponseChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_NONE?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
  /** Return MessageResponses where one of the related MessageResponseChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SINGLE?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
  /** Return MessageResponses where some of the related MessageResponseChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SOME?: InputMaybe<MessageResponseChatsHasMessageConnectionWhere>;
  /** Return MessageResponses where all of the related Chats match this filter */
  chatsHasMessage_ALL?: InputMaybe<ChatWhere>;
  /** Return MessageResponses where none of the related Chats match this filter */
  chatsHasMessage_NONE?: InputMaybe<ChatWhere>;
  /** Return MessageResponses where one of the related Chats match this filter */
  chatsHasMessage_SINGLE?: InputMaybe<ChatWhere>;
  /** Return MessageResponses where some of the related Chats match this filter */
  chatsHasMessage_SOME?: InputMaybe<ChatWhere>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  content_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  content_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  content_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  emotion_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emotion_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emotion_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emotion_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<FileWhere>;
  fileAggregate?: InputMaybe<MessageResponseFileAggregateInput>;
  fileConnection?: InputMaybe<MessageResponseFileConnectionWhere>;
  fileConnection_NOT?: InputMaybe<MessageResponseFileConnectionWhere>;
  file_NOT?: InputMaybe<FileWhere>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  nodeId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  snackType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  snackType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  snackType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  snackType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageResponsesConnection = {
  __typename?: "MessageResponsesConnection";
  edges: Array<MessageResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Messages by. The order in which sorts are applied is not guaranteed when specifying many fields in one MessageSort object. */
export type MessageSort = {
  aspectDetails?: InputMaybe<SortDirection>;
  content?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  emotion?: InputMaybe<SortDirection>;
  flagged?: InputMaybe<SortDirection>;
  harassment?: InputMaybe<SortDirection>;
  harassment_threatening?: InputMaybe<SortDirection>;
  hate?: InputMaybe<SortDirection>;
  hate_threatening?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  nodeId?: InputMaybe<SortDirection>;
  parameters?: InputMaybe<SortDirection>;
  self_harm?: InputMaybe<SortDirection>;
  self_harm_instructions?: InputMaybe<SortDirection>;
  self_harm_intent?: InputMaybe<SortDirection>;
  sender?: InputMaybe<SortDirection>;
  sexual?: InputMaybe<SortDirection>;
  sexual_minors?: InputMaybe<SortDirection>;
  snackType?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  violence?: InputMaybe<SortDirection>;
  violence_graphic?: InputMaybe<SortDirection>;
};

export type MessageUpdateInput = {
  aspectDetails?: InputMaybe<Scalars["String"]["input"]>;
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageUpdateFieldInput>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<MessageFileUpdateFieldInput>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  video?: InputMaybe<MessageVideoUpdateFieldInput>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  visualization?: InputMaybe<MessageVisualizationUpdateFieldInput>;
};

export type MessageVideoAggregateInput = {
  AND?: InputMaybe<Array<MessageVideoAggregateInput>>;
  NOT?: InputMaybe<MessageVideoAggregateInput>;
  OR?: InputMaybe<Array<MessageVideoAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<MessageVideoNodeAggregationWhereInput>;
};

export type MessageVideoConnectFieldInput = {
  connect?: InputMaybe<VideoConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<VideoConnectWhere>;
};

export type MessageVideoConnectOrCreateFieldInput = {
  onCreate: MessageVideoConnectOrCreateFieldInputOnCreate;
  where: VideoConnectOrCreateWhere;
};

export type MessageVideoConnectOrCreateFieldInputOnCreate = {
  node: VideoOnCreateInput;
};

export type MessageVideoConnection = {
  __typename?: "MessageVideoConnection";
  edges: Array<MessageVideoRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageVideoConnectionSort = {
  node?: InputMaybe<VideoSort>;
};

export type MessageVideoConnectionWhere = {
  AND?: InputMaybe<Array<MessageVideoConnectionWhere>>;
  NOT?: InputMaybe<MessageVideoConnectionWhere>;
  OR?: InputMaybe<Array<MessageVideoConnectionWhere>>;
  node?: InputMaybe<VideoWhere>;
};

export type MessageVideoCreateFieldInput = {
  node: VideoCreateInput;
};

export type MessageVideoDeleteFieldInput = {
  delete?: InputMaybe<VideoDeleteInput>;
  where?: InputMaybe<MessageVideoConnectionWhere>;
};

export type MessageVideoDisconnectFieldInput = {
  disconnect?: InputMaybe<VideoDisconnectInput>;
  where?: InputMaybe<MessageVideoConnectionWhere>;
};

export type MessageVideoFieldInput = {
  connect?: InputMaybe<MessageVideoConnectFieldInput>;
  connectOrCreate?: InputMaybe<MessageVideoConnectOrCreateFieldInput>;
  create?: InputMaybe<MessageVideoCreateFieldInput>;
};

export type MessageVideoNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageVideoNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageVideoNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageVideoNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageVideoRelationship = {
  __typename?: "MessageVideoRelationship";
  cursor: Scalars["String"]["output"];
  node: Video;
};

export type MessageVideoUpdateConnectionInput = {
  node?: InputMaybe<VideoUpdateInput>;
};

export type MessageVideoUpdateFieldInput = {
  connect?: InputMaybe<MessageVideoConnectFieldInput>;
  connectOrCreate?: InputMaybe<MessageVideoConnectOrCreateFieldInput>;
  create?: InputMaybe<MessageVideoCreateFieldInput>;
  delete?: InputMaybe<MessageVideoDeleteFieldInput>;
  disconnect?: InputMaybe<MessageVideoDisconnectFieldInput>;
  update?: InputMaybe<MessageVideoUpdateConnectionInput>;
  where?: InputMaybe<MessageVideoConnectionWhere>;
};

export type MessageVideoVideoAggregationSelection = {
  __typename?: "MessageVideoVideoAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<MessageVideoVideoNodeAggregateSelection>;
};

export type MessageVideoVideoNodeAggregateSelection = {
  __typename?: "MessageVideoVideoNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  fullTranscript: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  thumbnailUrl: StringAggregateSelection;
  title: StringAggregateSelection;
  url: StringAggregateSelection;
  videoId: StringAggregateSelection;
};

export type MessageVisualizationAggregateInput = {
  AND?: InputMaybe<Array<MessageVisualizationAggregateInput>>;
  NOT?: InputMaybe<MessageVisualizationAggregateInput>;
  OR?: InputMaybe<Array<MessageVisualizationAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<MessageVisualizationNodeAggregationWhereInput>;
};

export type MessageVisualizationConnectFieldInput = {
  connect?: InputMaybe<VisualizationConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<VisualizationConnectWhere>;
};

export type MessageVisualizationConnection = {
  __typename?: "MessageVisualizationConnection";
  edges: Array<MessageVisualizationRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageVisualizationConnectionSort = {
  node?: InputMaybe<VisualizationSort>;
};

export type MessageVisualizationConnectionWhere = {
  AND?: InputMaybe<Array<MessageVisualizationConnectionWhere>>;
  NOT?: InputMaybe<MessageVisualizationConnectionWhere>;
  OR?: InputMaybe<Array<MessageVisualizationConnectionWhere>>;
  node?: InputMaybe<VisualizationWhere>;
};

export type MessageVisualizationCreateFieldInput = {
  node: VisualizationCreateInput;
};

export type MessageVisualizationDeleteFieldInput = {
  delete?: InputMaybe<VisualizationDeleteInput>;
  where?: InputMaybe<MessageVisualizationConnectionWhere>;
};

export type MessageVisualizationDisconnectFieldInput = {
  disconnect?: InputMaybe<VisualizationDisconnectInput>;
  where?: InputMaybe<MessageVisualizationConnectionWhere>;
};

export type MessageVisualizationFieldInput = {
  connect?: InputMaybe<MessageVisualizationConnectFieldInput>;
  create?: InputMaybe<MessageVisualizationCreateFieldInput>;
};

export type MessageVisualizationNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageVisualizationNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageVisualizationNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageVisualizationNodeAggregationWhereInput>>;
  code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  visualizationType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  visualizationType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageVisualizationRelationship = {
  __typename?: "MessageVisualizationRelationship";
  cursor: Scalars["String"]["output"];
  node: Visualization;
};

export type MessageVisualizationUpdateConnectionInput = {
  node?: InputMaybe<VisualizationUpdateInput>;
};

export type MessageVisualizationUpdateFieldInput = {
  connect?: InputMaybe<MessageVisualizationConnectFieldInput>;
  create?: InputMaybe<MessageVisualizationCreateFieldInput>;
  delete?: InputMaybe<MessageVisualizationDeleteFieldInput>;
  disconnect?: InputMaybe<MessageVisualizationDisconnectFieldInput>;
  update?: InputMaybe<MessageVisualizationUpdateConnectionInput>;
  where?: InputMaybe<MessageVisualizationConnectionWhere>;
};

export type MessageVisualizationVisualizationAggregationSelection = {
  __typename?: "MessageVisualizationVisualizationAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<MessageVisualizationVisualizationNodeAggregateSelection>;
};

export type MessageVisualizationVisualizationNodeAggregateSelection = {
  __typename?: "MessageVisualizationVisualizationNodeAggregateSelection";
  code: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  visualizationDescription: StringAggregateSelection;
  visualizationType: StringAggregateSelection;
};

export type MessageWhere = {
  AND?: InputMaybe<Array<MessageWhere>>;
  NOT?: InputMaybe<MessageWhere>;
  OR?: InputMaybe<Array<MessageWhere>>;
  aspectDetails?: InputMaybe<Scalars["String"]["input"]>;
  aspectDetails_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  aspectDetails_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  aspectDetails_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  aspectDetails_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  chatsHasMessageAggregate?: InputMaybe<MessageChatsHasMessageAggregateInput>;
  /** Return Messages where all of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_ALL?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where none of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_NONE?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where one of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SINGLE?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where some of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SOME?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where all of the related Chats match this filter */
  chatsHasMessage_ALL?: InputMaybe<ChatWhere>;
  /** Return Messages where none of the related Chats match this filter */
  chatsHasMessage_NONE?: InputMaybe<ChatWhere>;
  /** Return Messages where one of the related Chats match this filter */
  chatsHasMessage_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Messages where some of the related Chats match this filter */
  chatsHasMessage_SOME?: InputMaybe<ChatWhere>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  content_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  content_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  content_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  emotion_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emotion_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emotion_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emotion_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  file?: InputMaybe<FileWhere>;
  fileAggregate?: InputMaybe<MessageFileAggregateInput>;
  fileConnection?: InputMaybe<MessageFileConnectionWhere>;
  fileConnection_NOT?: InputMaybe<MessageFileConnectionWhere>;
  file_NOT?: InputMaybe<FileWhere>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  nodeId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<Scalars["String"]["input"]>;
  parameters_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parameters_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parameters_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parameters_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  snackType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  snackType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  snackType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  snackType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  video?: InputMaybe<VideoWhere>;
  videoAggregate?: InputMaybe<MessageVideoAggregateInput>;
  videoConnection?: InputMaybe<MessageVideoConnectionWhere>;
  videoConnection_NOT?: InputMaybe<MessageVideoConnectionWhere>;
  video_NOT?: InputMaybe<VideoWhere>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  visualization?: InputMaybe<VisualizationWhere>;
  visualizationAggregate?: InputMaybe<MessageVisualizationAggregateInput>;
  visualizationConnection?: InputMaybe<MessageVisualizationConnectionWhere>;
  visualizationConnection_NOT?: InputMaybe<MessageVisualizationConnectionWhere>;
  visualization_NOT?: InputMaybe<VisualizationWhere>;
};

export type MessagesConnection = {
  __typename?: "MessagesConnection";
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Module = {
  __typename?: "Module";
  aiTutors: Array<AiTutor>;
  aiTutorsAggregate?: Maybe<ModuleAiTutorAiTutorsAggregationSelection>;
  aiTutorsConnection: ModuleAiTutorsConnection;
  assessment?: Maybe<Assessment>;
  assessmentAggregate?: Maybe<ModuleAssessmentAssessmentAggregationSelection>;
  assessmentConnection: ModuleAssessmentConnection;
  assignments: Array<Assignment>;
  assignmentsAggregate?: Maybe<ModuleAssignmentAssignmentsAggregationSelection>;
  assignmentsConnection: ModuleAssignmentsConnection;
  axioResources: Array<AxioResource>;
  axioResourcesAggregate?: Maybe<ModuleAxioResourceAxioResourcesAggregationSelection>;
  axioResourcesConnection: ModuleAxioResourcesConnection;
  courseHasModules: Array<Course>;
  courseHasModulesAggregate?: Maybe<ModuleCourseCourseHasModulesAggregationSelection>;
  courseHasModulesConnection: ModuleCourseHasModulesConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  module_name?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Float"]["output"]>;
  pages: Array<Page>;
  pagesAggregate?: Maybe<ModulePagePagesAggregationSelection>;
  pagesConnection: ModulePagesConnection;
  resourceOrder?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ModuleAiTutorsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AiTutorOptions>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ModuleAiTutorsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ModuleAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleAiTutorsConnectionSort>>;
  where?: InputMaybe<ModuleAiTutorsConnectionWhere>;
};

export type ModuleAssessmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssessmentOptions>;
  where?: InputMaybe<AssessmentWhere>;
};

export type ModuleAssessmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssessmentWhere>;
};

export type ModuleAssessmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleAssessmentConnectionSort>>;
  where?: InputMaybe<ModuleAssessmentConnectionWhere>;
};

export type ModuleAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ModuleAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ModuleAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleAssignmentsConnectionSort>>;
  where?: InputMaybe<ModuleAssignmentsConnectionWhere>;
};

export type ModuleAxioResourcesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AxioResourceOptions>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type ModuleAxioResourcesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type ModuleAxioResourcesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleAxioResourcesConnectionSort>>;
  where?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
};

export type ModuleCourseHasModulesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleCourseHasModulesConnectionSort>>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModulePagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type ModulePagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PageWhere>;
};

export type ModulePagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModulePagesConnectionSort>>;
  where?: InputMaybe<ModulePagesConnectionWhere>;
};

export type ModuleAiTutorAiTutorsAggregationSelection = {
  __typename?: "ModuleAITutorAiTutorsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleAiTutorAiTutorsNodeAggregateSelection>;
};

export type ModuleAiTutorAiTutorsNodeAggregateSelection = {
  __typename?: "ModuleAITutorAiTutorsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type ModuleAggregateSelection = {
  __typename?: "ModuleAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type ModuleAiTutorsAggregateInput = {
  AND?: InputMaybe<Array<ModuleAiTutorsAggregateInput>>;
  NOT?: InputMaybe<ModuleAiTutorsAggregateInput>;
  OR?: InputMaybe<Array<ModuleAiTutorsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleAiTutorsNodeAggregationWhereInput>;
};

export type ModuleAiTutorsConnectFieldInput = {
  connect?: InputMaybe<Array<AiTutorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AiTutorConnectWhere>;
};

export type ModuleAiTutorsConnectOrCreateFieldInput = {
  onCreate: ModuleAiTutorsConnectOrCreateFieldInputOnCreate;
  where: AiTutorConnectOrCreateWhere;
};

export type ModuleAiTutorsConnectOrCreateFieldInputOnCreate = {
  node: AiTutorOnCreateInput;
};

export type ModuleAiTutorsConnection = {
  __typename?: "ModuleAiTutorsConnection";
  edges: Array<ModuleAiTutorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAiTutorsConnectionSort = {
  node?: InputMaybe<AiTutorSort>;
};

export type ModuleAiTutorsConnectionWhere = {
  AND?: InputMaybe<Array<ModuleAiTutorsConnectionWhere>>;
  NOT?: InputMaybe<ModuleAiTutorsConnectionWhere>;
  OR?: InputMaybe<Array<ModuleAiTutorsConnectionWhere>>;
  node?: InputMaybe<AiTutorWhere>;
};

export type ModuleAiTutorsCreateFieldInput = {
  node: AiTutorCreateInput;
};

export type ModuleAiTutorsDeleteFieldInput = {
  delete?: InputMaybe<AiTutorDeleteInput>;
  where?: InputMaybe<ModuleAiTutorsConnectionWhere>;
};

export type ModuleAiTutorsDisconnectFieldInput = {
  disconnect?: InputMaybe<AiTutorDisconnectInput>;
  where?: InputMaybe<ModuleAiTutorsConnectionWhere>;
};

export type ModuleAiTutorsFieldInput = {
  connect?: InputMaybe<Array<ModuleAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAiTutorsCreateFieldInput>>;
};

export type ModuleAiTutorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleAiTutorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleAiTutorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleAiTutorsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAiTutorsRelationship = {
  __typename?: "ModuleAiTutorsRelationship";
  cursor: Scalars["String"]["output"];
  node: AiTutor;
};

export type ModuleAiTutorsUpdateConnectionInput = {
  node?: InputMaybe<AiTutorUpdateInput>;
};

export type ModuleAiTutorsUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAiTutorsCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleAiTutorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleAiTutorsDisconnectFieldInput>>;
  update?: InputMaybe<ModuleAiTutorsUpdateConnectionInput>;
  where?: InputMaybe<ModuleAiTutorsConnectionWhere>;
};

export type ModuleAnalytics = {
  __typename?: "ModuleAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: ModuleAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  enrollment: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
};

export type ModuleAnalyticsAggregateSelection = {
  __typename?: "ModuleAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  enrollment: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type ModuleAnalyticsConnection = {
  __typename?: "ModuleAnalyticsConnection";
  edges: Array<ModuleAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  enrollment: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
};

export type ModuleAnalyticsDetails = {
  __typename?: "ModuleAnalyticsDetails";
  activities: Array<ActivityAnalytics>;
};

export type ModuleAnalyticsDetailsAggregateSelection = {
  __typename?: "ModuleAnalyticsDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ModuleAnalyticsDetailsConnection = {
  __typename?: "ModuleAnalyticsDetailsConnection";
  edges: Array<ModuleAnalyticsDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAnalyticsDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleAnalyticsDetailsEdge = {
  __typename?: "ModuleAnalyticsDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleAnalyticsDetails;
};

export type ModuleAnalyticsDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAnalyticsDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleAnalyticsDetailsWhere = {
  AND?: InputMaybe<Array<ModuleAnalyticsDetailsWhere>>;
  NOT?: InputMaybe<ModuleAnalyticsDetailsWhere>;
  OR?: InputMaybe<Array<ModuleAnalyticsDetailsWhere>>;
};

export type ModuleAnalyticsEdge = {
  __typename?: "ModuleAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleAnalytics;
};

export type ModuleAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleAnalyticsSort objects to sort ModuleAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleAnalyticsSort>>;
};

export type ModuleAnalyticsResponse = {
  __typename?: "ModuleAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ModuleAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ModuleAnalyticsResponseAggregateSelection = {
  __typename?: "ModuleAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ModuleAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ModuleAnalyticsResponseEdge = {
  __typename?: "ModuleAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleAnalyticsResponse;
};

export type ModuleAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleAnalyticsResponseSort objects to sort ModuleAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleAnalyticsResponseSort>>;
};

/** Fields to sort ModuleAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleAnalyticsResponseSort object. */
export type ModuleAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ModuleAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<ModuleAnalyticsResponseWhere>>;
  NOT?: InputMaybe<ModuleAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<ModuleAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleAnalyticsResponsesConnection = {
  __typename?: "ModuleAnalyticsResponsesConnection";
  edges: Array<ModuleAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ModuleAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleAnalyticsSort object. */
export type ModuleAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  enrollment?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type ModuleAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAnalyticsWhere = {
  AND?: InputMaybe<Array<ModuleAnalyticsWhere>>;
  NOT?: InputMaybe<ModuleAnalyticsWhere>;
  OR?: InputMaybe<Array<ModuleAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  enrollment_LT?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAssessmentAggregateInput = {
  AND?: InputMaybe<Array<ModuleAssessmentAggregateInput>>;
  NOT?: InputMaybe<ModuleAssessmentAggregateInput>;
  OR?: InputMaybe<Array<ModuleAssessmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleAssessmentNodeAggregationWhereInput>;
};

export type ModuleAssessmentAssessmentAggregationSelection = {
  __typename?: "ModuleAssessmentAssessmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleAssessmentAssessmentNodeAggregateSelection>;
};

export type ModuleAssessmentAssessmentNodeAggregateSelection = {
  __typename?: "ModuleAssessmentAssessmentNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type ModuleAssessmentConnectFieldInput = {
  connect?: InputMaybe<AssessmentConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssessmentConnectWhere>;
};

export type ModuleAssessmentConnectOrCreateFieldInput = {
  onCreate: ModuleAssessmentConnectOrCreateFieldInputOnCreate;
  where: AssessmentConnectOrCreateWhere;
};

export type ModuleAssessmentConnectOrCreateFieldInputOnCreate = {
  node: AssessmentOnCreateInput;
};

export type ModuleAssessmentConnection = {
  __typename?: "ModuleAssessmentConnection";
  edges: Array<ModuleAssessmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAssessmentConnectionSort = {
  node?: InputMaybe<AssessmentSort>;
};

export type ModuleAssessmentConnectionWhere = {
  AND?: InputMaybe<Array<ModuleAssessmentConnectionWhere>>;
  NOT?: InputMaybe<ModuleAssessmentConnectionWhere>;
  OR?: InputMaybe<Array<ModuleAssessmentConnectionWhere>>;
  node?: InputMaybe<AssessmentWhere>;
};

export type ModuleAssessmentCreateFieldInput = {
  node: AssessmentCreateInput;
};

export type ModuleAssessmentDeleteFieldInput = {
  delete?: InputMaybe<AssessmentDeleteInput>;
  where?: InputMaybe<ModuleAssessmentConnectionWhere>;
};

export type ModuleAssessmentDisconnectFieldInput = {
  disconnect?: InputMaybe<AssessmentDisconnectInput>;
  where?: InputMaybe<ModuleAssessmentConnectionWhere>;
};

export type ModuleAssessmentFieldInput = {
  connect?: InputMaybe<ModuleAssessmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<ModuleAssessmentConnectOrCreateFieldInput>;
  create?: InputMaybe<ModuleAssessmentCreateFieldInput>;
};

export type ModuleAssessmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleAssessmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleAssessmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleAssessmentNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ModuleAssessmentRelationship = {
  __typename?: "ModuleAssessmentRelationship";
  cursor: Scalars["String"]["output"];
  node: Assessment;
};

export type ModuleAssessmentUpdateConnectionInput = {
  node?: InputMaybe<AssessmentUpdateInput>;
};

export type ModuleAssessmentUpdateFieldInput = {
  connect?: InputMaybe<ModuleAssessmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<ModuleAssessmentConnectOrCreateFieldInput>;
  create?: InputMaybe<ModuleAssessmentCreateFieldInput>;
  delete?: InputMaybe<ModuleAssessmentDeleteFieldInput>;
  disconnect?: InputMaybe<ModuleAssessmentDisconnectFieldInput>;
  update?: InputMaybe<ModuleAssessmentUpdateConnectionInput>;
  where?: InputMaybe<ModuleAssessmentConnectionWhere>;
};

export type ModuleAssignmentAssignmentsAggregationSelection = {
  __typename?: "ModuleAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleAssignmentAssignmentsNodeAggregateSelection>;
};

export type ModuleAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "ModuleAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ModuleAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<ModuleAssignmentsAggregateInput>>;
  NOT?: InputMaybe<ModuleAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<ModuleAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleAssignmentsNodeAggregationWhereInput>;
};

export type ModuleAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type ModuleAssignmentsConnectOrCreateFieldInput = {
  onCreate: ModuleAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type ModuleAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type ModuleAssignmentsConnection = {
  __typename?: "ModuleAssignmentsConnection";
  edges: Array<ModuleAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type ModuleAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<ModuleAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<ModuleAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<ModuleAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type ModuleAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type ModuleAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<ModuleAssignmentsConnectionWhere>;
};

export type ModuleAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<ModuleAssignmentsConnectionWhere>;
};

export type ModuleAssignmentsFieldInput = {
  connect?: InputMaybe<Array<ModuleAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAssignmentsCreateFieldInput>>;
};

export type ModuleAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAssignmentsRelationship = {
  __typename?: "ModuleAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type ModuleAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type ModuleAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<ModuleAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<ModuleAssignmentsConnectionWhere>;
};

export type ModuleAxioResourceAxioResourcesAggregationSelection = {
  __typename?: "ModuleAxioResourceAxioResourcesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleAxioResourceAxioResourcesNodeAggregateSelection>;
};

export type ModuleAxioResourceAxioResourcesNodeAggregateSelection = {
  __typename?: "ModuleAxioResourceAxioResourcesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  moduleId: IdAggregateSelection;
  name: StringAggregateSelection;
  order: IntAggregateSelection;
  videoUrl: StringAggregateSelection;
};

export type ModuleAxioResourcesAggregateInput = {
  AND?: InputMaybe<Array<ModuleAxioResourcesAggregateInput>>;
  NOT?: InputMaybe<ModuleAxioResourcesAggregateInput>;
  OR?: InputMaybe<Array<ModuleAxioResourcesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleAxioResourcesNodeAggregationWhereInput>;
};

export type ModuleAxioResourcesConnectFieldInput = {
  connect?: InputMaybe<Array<AxioResourceConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AxioResourceConnectWhere>;
};

export type ModuleAxioResourcesConnectOrCreateFieldInput = {
  onCreate: ModuleAxioResourcesConnectOrCreateFieldInputOnCreate;
  where: AxioResourceConnectOrCreateWhere;
};

export type ModuleAxioResourcesConnectOrCreateFieldInputOnCreate = {
  node: AxioResourceOnCreateInput;
};

export type ModuleAxioResourcesConnection = {
  __typename?: "ModuleAxioResourcesConnection";
  edges: Array<ModuleAxioResourcesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleAxioResourcesConnectionSort = {
  node?: InputMaybe<AxioResourceSort>;
};

export type ModuleAxioResourcesConnectionWhere = {
  AND?: InputMaybe<Array<ModuleAxioResourcesConnectionWhere>>;
  NOT?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
  OR?: InputMaybe<Array<ModuleAxioResourcesConnectionWhere>>;
  node?: InputMaybe<AxioResourceWhere>;
};

export type ModuleAxioResourcesCreateFieldInput = {
  node: AxioResourceCreateInput;
};

export type ModuleAxioResourcesDeleteFieldInput = {
  delete?: InputMaybe<AxioResourceDeleteInput>;
  where?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
};

export type ModuleAxioResourcesDisconnectFieldInput = {
  disconnect?: InputMaybe<AxioResourceDisconnectInput>;
  where?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
};

export type ModuleAxioResourcesFieldInput = {
  connect?: InputMaybe<Array<ModuleAxioResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAxioResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAxioResourcesCreateFieldInput>>;
};

export type ModuleAxioResourcesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleAxioResourcesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleAxioResourcesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleAxioResourcesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleAxioResourcesRelationship = {
  __typename?: "ModuleAxioResourcesRelationship";
  cursor: Scalars["String"]["output"];
  node: AxioResource;
};

export type ModuleAxioResourcesUpdateConnectionInput = {
  node?: InputMaybe<AxioResourceUpdateInput>;
};

export type ModuleAxioResourcesUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleAxioResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleAxioResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleAxioResourcesCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleAxioResourcesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleAxioResourcesDisconnectFieldInput>>;
  update?: InputMaybe<ModuleAxioResourcesUpdateConnectionInput>;
  where?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
};

export type ModuleCompletionDetails = {
  __typename?: "ModuleCompletionDetails";
  completionStats: CompletionStats;
  details: ModuleCompletionDetailsData;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type ModuleCompletionDetailsActivities = {
  __typename?: "ModuleCompletionDetailsActivities";
  activities: Array<ActivityCompletionDetails>;
};

export type ModuleCompletionDetailsActivitiesAggregateSelection = {
  __typename?: "ModuleCompletionDetailsActivitiesAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsActivitiesConnection = {
  __typename?: "ModuleCompletionDetailsActivitiesConnection";
  edges: Array<ModuleCompletionDetailsActivitiesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsActivitiesCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsActivitiesEdge = {
  __typename?: "ModuleCompletionDetailsActivitiesEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleCompletionDetailsActivities;
};

export type ModuleCompletionDetailsActivitiesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleCompletionDetailsActivitiesUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsActivitiesWhere = {
  AND?: InputMaybe<Array<ModuleCompletionDetailsActivitiesWhere>>;
  NOT?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
  OR?: InputMaybe<Array<ModuleCompletionDetailsActivitiesWhere>>;
};

export type ModuleCompletionDetailsAggregateSelection = {
  __typename?: "ModuleCompletionDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type ModuleCompletionDetailsConnection = {
  __typename?: "ModuleCompletionDetailsConnection";
  edges: Array<ModuleCompletionDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type ModuleCompletionDetailsData = {
  __typename?: "ModuleCompletionDetailsData";
  activities: Array<ActivityCompletionDetails>;
};

export type ModuleCompletionDetailsDataAggregateSelection = {
  __typename?: "ModuleCompletionDetailsDataAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsDataConnection = {
  __typename?: "ModuleCompletionDetailsDataConnection";
  edges: Array<ModuleCompletionDetailsDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsDataCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsDataEdge = {
  __typename?: "ModuleCompletionDetailsDataEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleCompletionDetailsData;
};

export type ModuleCompletionDetailsDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleCompletionDetailsDataUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsDataWhere = {
  AND?: InputMaybe<Array<ModuleCompletionDetailsDataWhere>>;
  NOT?: InputMaybe<ModuleCompletionDetailsDataWhere>;
  OR?: InputMaybe<Array<ModuleCompletionDetailsDataWhere>>;
};

export type ModuleCompletionDetailsEdge = {
  __typename?: "ModuleCompletionDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleCompletionDetails;
};

export type ModuleCompletionDetailsFull = {
  __typename?: "ModuleCompletionDetailsFull";
  completionStats: CompletionStats;
  course_id: Scalars["ID"]["output"];
  course_name: Scalars["String"]["output"];
  details: ModuleCompletionDetailsActivities;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type ModuleCompletionDetailsFullAggregateSelection = {
  __typename?: "ModuleCompletionDetailsFullAggregateSelection";
  count: Scalars["Int"]["output"];
  course_id: IdAggregateSelection;
  course_name: StringAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type ModuleCompletionDetailsFullCreateInput = {
  course_id: Scalars["ID"]["input"];
  course_name: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type ModuleCompletionDetailsFullEdge = {
  __typename?: "ModuleCompletionDetailsFullEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleCompletionDetailsFull;
};

export type ModuleCompletionDetailsFullOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleCompletionDetailsFullSort objects to sort ModuleCompletionDetailsFulls by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleCompletionDetailsFullSort>>;
};

/** Fields to sort ModuleCompletionDetailsFulls by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleCompletionDetailsFullSort object. */
export type ModuleCompletionDetailsFullSort = {
  course_id?: InputMaybe<SortDirection>;
  course_name?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type ModuleCompletionDetailsFullUpdateInput = {
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleCompletionDetailsFullWhere = {
  AND?: InputMaybe<Array<ModuleCompletionDetailsFullWhere>>;
  NOT?: InputMaybe<ModuleCompletionDetailsFullWhere>;
  OR?: InputMaybe<Array<ModuleCompletionDetailsFullWhere>>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  course_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  course_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleCompletionDetailsFullsConnection = {
  __typename?: "ModuleCompletionDetailsFullsConnection";
  edges: Array<ModuleCompletionDetailsFullEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCompletionDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleCompletionDetailsSort objects to sort ModuleCompletionDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleCompletionDetailsSort>>;
};

export type ModuleCompletionDetailsResponse = {
  __typename?: "ModuleCompletionDetailsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ModuleCompletionDetailsFull>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ModuleCompletionDetailsResponseAggregateSelection = {
  __typename?: "ModuleCompletionDetailsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ModuleCompletionDetailsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ModuleCompletionDetailsResponseEdge = {
  __typename?: "ModuleCompletionDetailsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleCompletionDetailsResponse;
};

export type ModuleCompletionDetailsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleCompletionDetailsResponseSort objects to sort ModuleCompletionDetailsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleCompletionDetailsResponseSort>>;
};

/** Fields to sort ModuleCompletionDetailsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleCompletionDetailsResponseSort object. */
export type ModuleCompletionDetailsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ModuleCompletionDetailsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsResponseWhere = {
  AND?: InputMaybe<Array<ModuleCompletionDetailsResponseWhere>>;
  NOT?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
  OR?: InputMaybe<Array<ModuleCompletionDetailsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleCompletionDetailsResponsesConnection = {
  __typename?: "ModuleCompletionDetailsResponsesConnection";
  edges: Array<ModuleCompletionDetailsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ModuleCompletionDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleCompletionDetailsSort object. */
export type ModuleCompletionDetailsSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type ModuleCompletionDetailsUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleCompletionDetailsWhere = {
  AND?: InputMaybe<Array<ModuleCompletionDetailsWhere>>;
  NOT?: InputMaybe<ModuleCompletionDetailsWhere>;
  OR?: InputMaybe<Array<ModuleCompletionDetailsWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleConnectInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsConnectFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentConnectFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsConnectFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesConnectFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  pages?: InputMaybe<Array<ModulePagesConnectFieldInput>>;
};

export type ModuleConnectOrCreateInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsConnectOrCreateFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentConnectOrCreateFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsConnectOrCreateFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesConnectOrCreateFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  pages?: InputMaybe<Array<ModulePagesConnectOrCreateFieldInput>>;
};

export type ModuleConnectOrCreateWhere = {
  node: ModuleUniqueWhere;
};

export type ModuleConnectWhere = {
  node: ModuleWhere;
};

export type ModuleCourseCourseHasModulesAggregationSelection = {
  __typename?: "ModuleCourseCourseHasModulesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleCourseCourseHasModulesNodeAggregateSelection>;
};

export type ModuleCourseCourseHasModulesNodeAggregateSelection = {
  __typename?: "ModuleCourseCourseHasModulesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ModuleCourseHasModulesAggregateInput = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesAggregateInput>>;
  NOT?: InputMaybe<ModuleCourseHasModulesAggregateInput>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleCourseHasModulesNodeAggregationWhereInput>;
};

export type ModuleCourseHasModulesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type ModuleCourseHasModulesConnectOrCreateFieldInput = {
  onCreate: ModuleCourseHasModulesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type ModuleCourseHasModulesConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type ModuleCourseHasModulesConnection = {
  __typename?: "ModuleCourseHasModulesConnection";
  edges: Array<ModuleCourseHasModulesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCourseHasModulesConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type ModuleCourseHasModulesConnectionWhere = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesConnectionWhere>>;
  NOT?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesCreateFieldInput = {
  node: CourseCreateInput;
};

export type ModuleCourseHasModulesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCourseHasModulesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCourseHasModulesFieldInput = {
  connect?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
};

export type ModuleCourseHasModulesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleCourseHasModulesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleCourseHasModulesRelationship = {
  __typename?: "ModuleCourseHasModulesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type ModuleCourseHasModulesUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type ModuleCourseHasModulesUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleCourseHasModulesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleCourseHasModulesDisconnectFieldInput>>;
  update?: InputMaybe<ModuleCourseHasModulesUpdateConnectionInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCreateInput = {
  aiTutors?: InputMaybe<ModuleAiTutorsFieldInput>;
  assessment?: InputMaybe<ModuleAssessmentFieldInput>;
  assignments?: InputMaybe<ModuleAssignmentsFieldInput>;
  axioResources?: InputMaybe<ModuleAxioResourcesFieldInput>;
  courseHasModules?: InputMaybe<ModuleCourseHasModulesFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  pages?: InputMaybe<ModulePagesFieldInput>;
  resourceOrder?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleDeleteInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsDeleteFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentDeleteFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsDeleteFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesDeleteFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesDeleteFieldInput>>;
  pages?: InputMaybe<Array<ModulePagesDeleteFieldInput>>;
};

export type ModuleDisconnectInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsDisconnectFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentDisconnectFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsDisconnectFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesDisconnectFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesDisconnectFieldInput>>;
  pages?: InputMaybe<Array<ModulePagesDisconnectFieldInput>>;
};

export type ModuleEdge = {
  __typename?: "ModuleEdge";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type ModuleOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  resourceOrder?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleSort objects to sort Modules by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleSort>>;
};

export type ModulePagePagesAggregationSelection = {
  __typename?: "ModulePagePagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModulePagePagesNodeAggregateSelection>;
};

export type ModulePagePagesNodeAggregateSelection = {
  __typename?: "ModulePagePagesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type ModulePagesAggregateInput = {
  AND?: InputMaybe<Array<ModulePagesAggregateInput>>;
  NOT?: InputMaybe<ModulePagesAggregateInput>;
  OR?: InputMaybe<Array<ModulePagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModulePagesNodeAggregationWhereInput>;
};

export type ModulePagesConnectFieldInput = {
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PageConnectWhere>;
};

export type ModulePagesConnectOrCreateFieldInput = {
  onCreate: ModulePagesConnectOrCreateFieldInputOnCreate;
  where: PageConnectOrCreateWhere;
};

export type ModulePagesConnectOrCreateFieldInputOnCreate = {
  node: PageOnCreateInput;
};

export type ModulePagesConnection = {
  __typename?: "ModulePagesConnection";
  edges: Array<ModulePagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModulePagesConnectionSort = {
  node?: InputMaybe<PageSort>;
};

export type ModulePagesConnectionWhere = {
  AND?: InputMaybe<Array<ModulePagesConnectionWhere>>;
  NOT?: InputMaybe<ModulePagesConnectionWhere>;
  OR?: InputMaybe<Array<ModulePagesConnectionWhere>>;
  node?: InputMaybe<PageWhere>;
};

export type ModulePagesCreateFieldInput = {
  node: PageCreateInput;
};

export type ModulePagesDeleteFieldInput = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<ModulePagesConnectionWhere>;
};

export type ModulePagesDisconnectFieldInput = {
  disconnect?: InputMaybe<PageDisconnectInput>;
  where?: InputMaybe<ModulePagesConnectionWhere>;
};

export type ModulePagesFieldInput = {
  connect?: InputMaybe<Array<ModulePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModulePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModulePagesCreateFieldInput>>;
};

export type ModulePagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModulePagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModulePagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModulePagesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModulePagesRelationship = {
  __typename?: "ModulePagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Page;
};

export type ModulePagesUpdateConnectionInput = {
  node?: InputMaybe<PageUpdateInput>;
};

export type ModulePagesUpdateFieldInput = {
  connect?: InputMaybe<Array<ModulePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModulePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModulePagesCreateFieldInput>>;
  delete?: InputMaybe<Array<ModulePagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModulePagesDisconnectFieldInput>>;
  update?: InputMaybe<ModulePagesUpdateConnectionInput>;
  where?: InputMaybe<ModulePagesConnectionWhere>;
};

export type ModuleProgressAnalytics = {
  __typename?: "ModuleProgressAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: ModuleProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type ModuleProgressAnalyticsAggregateSelection = {
  __typename?: "ModuleProgressAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ModuleProgressAnalyticsConnection = {
  __typename?: "ModuleProgressAnalyticsConnection";
  edges: Array<ModuleProgressAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleProgressAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ModuleProgressAnalyticsEdge = {
  __typename?: "ModuleProgressAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleProgressAnalytics;
};

export type ModuleProgressAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleProgressAnalyticsSort objects to sort ModuleProgressAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleProgressAnalyticsSort>>;
};

/** Fields to sort ModuleProgressAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleProgressAnalyticsSort object. */
export type ModuleProgressAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type ModuleProgressAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ModuleProgressAnalyticsWhere = {
  AND?: InputMaybe<Array<ModuleProgressAnalyticsWhere>>;
  NOT?: InputMaybe<ModuleProgressAnalyticsWhere>;
  OR?: InputMaybe<Array<ModuleProgressAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ModuleProgressDetails = {
  __typename?: "ModuleProgressDetails";
  activities: Array<ActivityProgressAnalytics>;
  aiTutors?: Maybe<Array<Maybe<AiTutorProgressAnalytics>>>;
};

export type ModuleProgressDetailsAggregateSelection = {
  __typename?: "ModuleProgressDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ModuleProgressDetailsConnection = {
  __typename?: "ModuleProgressDetailsConnection";
  edges: Array<ModuleProgressDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleProgressDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleProgressDetailsEdge = {
  __typename?: "ModuleProgressDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleProgressDetails;
};

export type ModuleProgressDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleProgressDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleProgressDetailsWhere = {
  AND?: InputMaybe<Array<ModuleProgressDetailsWhere>>;
  NOT?: InputMaybe<ModuleProgressDetailsWhere>;
  OR?: InputMaybe<Array<ModuleProgressDetailsWhere>>;
};

export type ModuleRelationInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsCreateFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentCreateFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsCreateFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesCreateFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
  pages?: InputMaybe<Array<ModulePagesCreateFieldInput>>;
};

export type ModuleResponse = {
  __typename?: "ModuleResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Module>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ModuleResponseAggregateSelection = {
  __typename?: "ModuleResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ModuleResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponseEdge = {
  __typename?: "ModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleResponse;
};

export type ModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleResponseSort objects to sort ModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleResponseSort>>;
};

/** Fields to sort ModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleResponseSort object. */
export type ModuleResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ModuleResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponseWhere = {
  AND?: InputMaybe<Array<ModuleResponseWhere>>;
  NOT?: InputMaybe<ModuleResponseWhere>;
  OR?: InputMaybe<Array<ModuleResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponsesConnection = {
  __typename?: "ModuleResponsesConnection";
  edges: Array<ModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Modules by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleSort object. */
export type ModuleSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  module_name?: InputMaybe<SortDirection>;
  order?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type ModuleUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ModuleUpdateInput = {
  aiTutors?: InputMaybe<Array<ModuleAiTutorsUpdateFieldInput>>;
  assessment?: InputMaybe<ModuleAssessmentUpdateFieldInput>;
  assignments?: InputMaybe<Array<ModuleAssignmentsUpdateFieldInput>>;
  axioResources?: InputMaybe<Array<ModuleAxioResourcesUpdateFieldInput>>;
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  order_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  order_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  pages?: InputMaybe<Array<ModulePagesUpdateFieldInput>>;
  resourceOrder?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resourceOrder_POP?: InputMaybe<Scalars["Int"]["input"]>;
  resourceOrder_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleWhere = {
  AND?: InputMaybe<Array<ModuleWhere>>;
  NOT?: InputMaybe<ModuleWhere>;
  OR?: InputMaybe<Array<ModuleWhere>>;
  aiTutorsAggregate?: InputMaybe<ModuleAiTutorsAggregateInput>;
  /** Return Modules where all of the related ModuleAiTutorsConnections match this filter */
  aiTutorsConnection_ALL?: InputMaybe<ModuleAiTutorsConnectionWhere>;
  /** Return Modules where none of the related ModuleAiTutorsConnections match this filter */
  aiTutorsConnection_NONE?: InputMaybe<ModuleAiTutorsConnectionWhere>;
  /** Return Modules where one of the related ModuleAiTutorsConnections match this filter */
  aiTutorsConnection_SINGLE?: InputMaybe<ModuleAiTutorsConnectionWhere>;
  /** Return Modules where some of the related ModuleAiTutorsConnections match this filter */
  aiTutorsConnection_SOME?: InputMaybe<ModuleAiTutorsConnectionWhere>;
  /** Return Modules where all of the related AITutors match this filter */
  aiTutors_ALL?: InputMaybe<AiTutorWhere>;
  /** Return Modules where none of the related AITutors match this filter */
  aiTutors_NONE?: InputMaybe<AiTutorWhere>;
  /** Return Modules where one of the related AITutors match this filter */
  aiTutors_SINGLE?: InputMaybe<AiTutorWhere>;
  /** Return Modules where some of the related AITutors match this filter */
  aiTutors_SOME?: InputMaybe<AiTutorWhere>;
  assessment?: InputMaybe<AssessmentWhere>;
  assessmentAggregate?: InputMaybe<ModuleAssessmentAggregateInput>;
  assessmentConnection?: InputMaybe<ModuleAssessmentConnectionWhere>;
  assessmentConnection_NOT?: InputMaybe<ModuleAssessmentConnectionWhere>;
  assessment_NOT?: InputMaybe<AssessmentWhere>;
  assignmentsAggregate?: InputMaybe<ModuleAssignmentsAggregateInput>;
  /** Return Modules where all of the related ModuleAssignmentsConnections match this filter */
  assignmentsConnection_ALL?: InputMaybe<ModuleAssignmentsConnectionWhere>;
  /** Return Modules where none of the related ModuleAssignmentsConnections match this filter */
  assignmentsConnection_NONE?: InputMaybe<ModuleAssignmentsConnectionWhere>;
  /** Return Modules where one of the related ModuleAssignmentsConnections match this filter */
  assignmentsConnection_SINGLE?: InputMaybe<ModuleAssignmentsConnectionWhere>;
  /** Return Modules where some of the related ModuleAssignmentsConnections match this filter */
  assignmentsConnection_SOME?: InputMaybe<ModuleAssignmentsConnectionWhere>;
  /** Return Modules where all of the related Assignments match this filter */
  assignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Modules where none of the related Assignments match this filter */
  assignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Modules where one of the related Assignments match this filter */
  assignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Modules where some of the related Assignments match this filter */
  assignments_SOME?: InputMaybe<AssignmentWhere>;
  axioResourcesAggregate?: InputMaybe<ModuleAxioResourcesAggregateInput>;
  /** Return Modules where all of the related ModuleAxioResourcesConnections match this filter */
  axioResourcesConnection_ALL?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
  /** Return Modules where none of the related ModuleAxioResourcesConnections match this filter */
  axioResourcesConnection_NONE?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
  /** Return Modules where one of the related ModuleAxioResourcesConnections match this filter */
  axioResourcesConnection_SINGLE?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
  /** Return Modules where some of the related ModuleAxioResourcesConnections match this filter */
  axioResourcesConnection_SOME?: InputMaybe<ModuleAxioResourcesConnectionWhere>;
  /** Return Modules where all of the related AxioResources match this filter */
  axioResources_ALL?: InputMaybe<AxioResourceWhere>;
  /** Return Modules where none of the related AxioResources match this filter */
  axioResources_NONE?: InputMaybe<AxioResourceWhere>;
  /** Return Modules where one of the related AxioResources match this filter */
  axioResources_SINGLE?: InputMaybe<AxioResourceWhere>;
  /** Return Modules where some of the related AxioResources match this filter */
  axioResources_SOME?: InputMaybe<AxioResourceWhere>;
  courseHasModulesAggregate?: InputMaybe<ModuleCourseHasModulesAggregateInput>;
  /** Return Modules where all of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_ALL?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where none of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_NONE?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where one of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_SINGLE?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where some of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_SOME?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where all of the related Courses match this filter */
  courseHasModules_ALL?: InputMaybe<CourseWhere>;
  /** Return Modules where none of the related Courses match this filter */
  courseHasModules_NONE?: InputMaybe<CourseWhere>;
  /** Return Modules where one of the related Courses match this filter */
  courseHasModules_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Modules where some of the related Courses match this filter */
  courseHasModules_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  module_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  module_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  module_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  order_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  order_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  pagesAggregate?: InputMaybe<ModulePagesAggregateInput>;
  /** Return Modules where all of the related ModulePagesConnections match this filter */
  pagesConnection_ALL?: InputMaybe<ModulePagesConnectionWhere>;
  /** Return Modules where none of the related ModulePagesConnections match this filter */
  pagesConnection_NONE?: InputMaybe<ModulePagesConnectionWhere>;
  /** Return Modules where one of the related ModulePagesConnections match this filter */
  pagesConnection_SINGLE?: InputMaybe<ModulePagesConnectionWhere>;
  /** Return Modules where some of the related ModulePagesConnections match this filter */
  pagesConnection_SOME?: InputMaybe<ModulePagesConnectionWhere>;
  /** Return Modules where all of the related Pages match this filter */
  pages_ALL?: InputMaybe<PageWhere>;
  /** Return Modules where none of the related Pages match this filter */
  pages_NONE?: InputMaybe<PageWhere>;
  /** Return Modules where one of the related Pages match this filter */
  pages_SINGLE?: InputMaybe<PageWhere>;
  /** Return Modules where some of the related Pages match this filter */
  pages_SOME?: InputMaybe<PageWhere>;
  resourceOrder?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resourceOrder_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModulesConnection = {
  __typename?: "ModulesConnection";
  edges: Array<ModuleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MonthlySummariesConnection = {
  __typename?: "MonthlySummariesConnection";
  edges: Array<MonthlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MonthlySummary = {
  __typename?: "MonthlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type MonthlySummaryAggregateSelection = {
  __typename?: "MonthlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type MonthlySummaryConnectWhere = {
  node: MonthlySummaryWhere;
};

export type MonthlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type MonthlySummaryEdge = {
  __typename?: "MonthlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: MonthlySummary;
};

export type MonthlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MonthlySummarySort objects to sort MonthlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MonthlySummarySort>>;
};

/** Fields to sort MonthlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one MonthlySummarySort object. */
export type MonthlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type MonthlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type MonthlySummaryWhere = {
  AND?: InputMaybe<Array<MonthlySummaryWhere>>;
  NOT?: InputMaybe<MonthlySummaryWhere>;
  OR?: InputMaybe<Array<MonthlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["String"]["output"]>;
  addAssignment: AddAssignmentResponse;
  addContentModule: AddContentModuleResponse;
  addCoursesToDegree?: Maybe<DegreeResponse>;
  addDegreesToCourse?: Maybe<CourseResponse>;
  addFood?: Maybe<Food>;
  addNutrition?: Maybe<Nutrition>;
  addToWhitelist: WhitelistedEmail;
  addTodo?: Maybe<Todo>;
  assignCourseToFaculty?: Maybe<ResponseType>;
  assignRoleToUserForTenant?: Maybe<ResponseType>;
  audioToggleUser?: Maybe<AudioToggleUserResponse>;
  bulkCreateTenants?: Maybe<TenantResponse>;
  careerIsLoading: UserCareerLoadingResponse;
  careerSubmitForm: Scalars["Boolean"]["output"];
  careerTopicRelationship: CareerTopicResponse;
  completeJourney?: Maybe<ResponseType>;
  completePendingInvite?: Maybe<ResponseType>;
  courseCreation?: Maybe<CourseCreationResponse>;
  courseIsLoading: UserCourseLoadingResponse;
  createAITutor?: Maybe<AiTutorResponse>;
  createAchievements: CreateAchievementsMutationResponse;
  createActiveTracks: CreateActiveTracksMutationResponse;
  createActivityAnalytics: CreateActivityAnalyticsMutationResponse;
  createActivityAnalyticsDetails: CreateActivityAnalyticsDetailsMutationResponse;
  createActivityAnalyticsResponses: CreateActivityAnalyticsResponsesMutationResponse;
  createActivityCompletionDetails: CreateActivityCompletionDetailsMutationResponse;
  createActivityCompletionDetailsData: CreateActivityCompletionDetailsDataMutationResponse;
  createActivityCompletionDetailsFulls: CreateActivityCompletionDetailsFullsMutationResponse;
  createActivityCompletionDetailsObjectives: CreateActivityCompletionDetailsObjectivesMutationResponse;
  createActivityCompletionDetailsResponses: CreateActivityCompletionDetailsResponsesMutationResponse;
  createActivityInsights: CreateActivityInsightsMutationResponse;
  createActivityInsightsResponses: CreateActivityInsightsResponsesMutationResponse;
  createActivityProgressAnalytics: CreateActivityProgressAnalyticsMutationResponse;
  createActivityProgressDetails: CreateActivityProgressDetailsMutationResponse;
  createAddAssignmentResponses: CreateAddAssignmentResponsesMutationResponse;
  createAddContentModuleResponses: CreateAddContentModuleResponsesMutationResponse;
  createAiTutorAnalytics: CreateAiTutorAnalyticsMutationResponse;
  createAiTutorAnalyticsResponses: CreateAiTutorAnalyticsResponsesMutationResponse;
  createAiTutorEvalCriteriaResponses: CreateAiTutorEvalCriteriaResponsesMutationResponse;
  createAiTutorGeneratedResponses: CreateAiTutorGeneratedResponsesMutationResponse;
  createAiTutorProgressAnalytics: CreateAiTutorProgressAnalyticsMutationResponse;
  createAiTutorProgressDetails: CreateAiTutorProgressDetailsMutationResponse;
  createAiTutorResponses: CreateAiTutorResponsesMutationResponse;
  createAiTutors: CreateAiTutorsMutationResponse;
  createAnotherRelationshipFields: CreateAnotherRelationshipFieldsMutationResponse;
  createAspectDetailsTypes: CreateAspectDetailsTypesMutationResponse;
  createAssessmentListResponseData: CreateAssessmentListResponseDataMutationResponse;
  createAssessmentListResponseFormatteds: CreateAssessmentListResponseFormattedsMutationResponse;
  createAssessmentListResponses: CreateAssessmentListResponsesMutationResponse;
  createAssessmentResponses: CreateAssessmentResponsesMutationResponse;
  createAssessments: CreateAssessmentsMutationResponse;
  createAssignedQuests: CreateAssignedQuestsMutationResponse;
  createAssignment?: Maybe<AssignmentResponse>;
  createAssignmentAndSubmissionResponses: CreateAssignmentAndSubmissionResponsesMutationResponse;
  createAssignmentAndSubmissions: CreateAssignmentAndSubmissionsMutationResponse;
  createAssignmentResponses: CreateAssignmentResponsesMutationResponse;
  createAssignments: CreateAssignmentsMutationResponse;
  createAttributes: CreateAttributesMutationResponse;
  createAudioToggleUserResponses: CreateAudioToggleUserResponsesMutationResponse;
  createAuthPayloadResponses: CreateAuthPayloadResponsesMutationResponse;
  createAuthPayloadUserPreferences: CreateAuthPayloadUserPreferencesMutationResponse;
  createAuthPayloadUsers: CreateAuthPayloadUsersMutationResponse;
  createAxioResource: AxioResourceResponse;
  createAxioResourceResponses: CreateAxioResourceResponsesMutationResponse;
  createAxioResources: CreateAxioResourcesMutationResponse;
  createCareerHeaders: CreateCareerHeadersMutationResponse;
  createCareerTopicResponses: CreateCareerTopicResponsesMutationResponse;
  createCareers: CreateCareersMutationResponse;
  createChallenges: CreateChallengesMutationResponse;
  createChapters: CreateChaptersMutationResponse;
  createChatAnalytics: CreateChatAnalyticsMutationResponse;
  createChatAttachmentResponses: CreateChatAttachmentResponsesMutationResponse;
  createChatResponses: CreateChatResponsesMutationResponse;
  createChats: CreateChatsMutationResponse;
  createCheckoutSession?: Maybe<ResponseType>;
  createChildInfos: CreateChildInfosMutationResponse;
  createCompletionActivityAnalytics: CreateCompletionActivityAnalyticsMutationResponse;
  createCompletionActivityDetails: CreateCompletionActivityDetailsMutationResponse;
  createCompletionAnalytics: CreateCompletionAnalyticsMutationResponse;
  createCompletionAnalyticsResponses: CreateCompletionAnalyticsResponsesMutationResponse;
  createCompletionCourseDetails: CreateCompletionCourseDetailsMutationResponse;
  createCompletionModuleAnalytics: CreateCompletionModuleAnalyticsMutationResponse;
  createCompletionModuleDetails: CreateCompletionModuleDetailsMutationResponse;
  createCompletionObjectiveAnalytics: CreateCompletionObjectiveAnalyticsMutationResponse;
  createCompletionStats: CreateCompletionStatsMutationResponse;
  createCoreValues: CreateCoreValuesMutationResponse;
  createCourse?: Maybe<CourseResponse>;
  createCourseAnalytics: CreateCourseAnalyticsMutationResponse;
  createCourseAnalyticsDetails: CreateCourseAnalyticsDetailsMutationResponse;
  createCourseAnalyticsResponses: CreateCourseAnalyticsResponsesMutationResponse;
  createCourseAnalyticsSummaryResponses: CreateCourseAnalyticsSummaryResponsesMutationResponse;
  createCourseCategories: CreateCourseCategoriesMutationResponse;
  createCourseCompletionDetails: CreateCourseCompletionDetailsMutationResponse;
  createCourseCompletionDetailsData: CreateCourseCompletionDetailsDataMutationResponse;
  createCourseCompletionDetailsResponses: CreateCourseCompletionDetailsResponsesMutationResponse;
  createCourseCompletionSummaries: CreateCourseCompletionSummariesMutationResponse;
  createCourseCompletionSummaryResponses: CreateCourseCompletionSummaryResponsesMutationResponse;
  createCourseCreationResponses: CreateCourseCreationResponsesMutationResponse;
  createCourseDetailedAnalytics: CreateCourseDetailedAnalyticsMutationResponse;
  createCourseDetailedResponses: CreateCourseDetailedResponsesMutationResponse;
  createCourseHierarchyResponses: CreateCourseHierarchyResponsesMutationResponse;
  createCoursePlaceholder?: Maybe<CourseResponse>;
  createCourseProgressAnalytics: CreateCourseProgressAnalyticsMutationResponse;
  createCourseProgressDetails: CreateCourseProgressDetailsMutationResponse;
  createCourseResponses: CreateCourseResponsesMutationResponse;
  createCourseRosterData: CreateCourseRosterDataMutationResponse;
  createCourseRosterServiceResults: CreateCourseRosterServiceResultsMutationResponse;
  createCourseSummaries: CreateCourseSummariesMutationResponse;
  createCourses: CreateCoursesMutationResponse;
  createCoursesByQueryData: CreateCoursesByQueryDataMutationResponse;
  createCoursesByQueryResponses: CreateCoursesByQueryResponsesMutationResponse;
  createCurricula: CreateCurriculaMutationResponse;
  createDailyCompletedTodosCounts: CreateDailyCompletedTodosCountsMutationResponse;
  createDailyExerciseTotals: CreateDailyExerciseTotalsMutationResponse;
  createDailyNutritionTotals: CreateDailyNutritionTotalsMutationResponse;
  createDailySummaries: CreateDailySummariesMutationResponse;
  createDegree?: Maybe<DegreeResponse>;
  createDegreeResponses: CreateDegreeResponsesMutationResponse;
  createDegrees: CreateDegreesMutationResponse;
  createDegreesBySchoolByQueryData: CreateDegreesBySchoolByQueryDataMutationResponse;
  createDegreesBySchoolByQueryResponses: CreateDegreesBySchoolByQueryResponsesMutationResponse;
  createDeleteContentAssignmentResponses: CreateDeleteContentAssignmentResponsesMutationResponse;
  createDeleteContentAssignmentVariables: CreateDeleteContentAssignmentVariablesMutationResponse;
  createDeleteContentModuleResponses: CreateDeleteContentModuleResponsesMutationResponse;
  createDeleteContentModuleVariables: CreateDeleteContentModuleVariablesMutationResponse;
  createDeleteCourseResponses: CreateDeleteCourseResponsesMutationResponse;
  createDeviceSessions: CreateDeviceSessionsMutationResponse;
  createEducationChildren: Generated;
  createEmotionalStates: CreateEmotionalStatesMutationResponse;
  createEvents: CreateEventsMutationResponse;
  createExportCsvResponses: CreateExportCsvResponsesMutationResponse;
  createFacultyDataCourses: CreateFacultyDataCoursesMutationResponse;
  createFacultyDataRoles: CreateFacultyDataRolesMutationResponse;
  createFacultyDataSessionDeviceSessions: CreateFacultyDataSessionDeviceSessionsMutationResponse;
  createFacultyQueryResponseData: CreateFacultyQueryResponseDataMutationResponse;
  createFacultyQueryResponseWithCountData: CreateFacultyQueryResponseWithCountDataMutationResponse;
  createFacultyQueryResponses: CreateFacultyQueryResponsesMutationResponse;
  createFeedback?: Maybe<Feedback>;
  createFeedbacks: CreateFeedbacksMutationResponse;
  createFields: CreateFieldsMutationResponse;
  createFileResponses: CreateFileResponsesMutationResponse;
  createFileUploadResponses: CreateFileUploadResponsesMutationResponse;
  createFiles: CreateFilesMutationResponse;
  createFoodItems: CreateFoodItemsMutationResponse;
  createFoods: CreateFoodsMutationResponse;
  createFormattedAiTutors: CreateFormattedAiTutorsMutationResponse;
  createFormattedChats: CreateFormattedChatsMutationResponse;
  createFormattedObjectives: CreateFormattedObjectivesMutationResponse;
  createFormattedVisualizations: CreateFormattedVisualizationsMutationResponse;
  createGames: CreateGamesMutationResponse;
  createGenerateCourseContentData: CreateGenerateCourseContentDataMutationResponse;
  createGenerateCourseContentResponses: CreateGenerateCourseContentResponsesMutationResponse;
  createGeneratedAiTutors: CreateGeneratedAiTutorsMutationResponse;
  createGeneratedObjectives: CreateGeneratedObjectivesMutationResponse;
  createGenerateds: CreateGeneratedsMutationResponse;
  createGetAssignmentObjectiveResponses: CreateGetAssignmentObjectiveResponsesMutationResponse;
  createGetAssignmentResponses: CreateGetAssignmentResponsesMutationResponse;
  createGetJourneyByIdResponses: CreateGetJourneyByIdResponsesMutationResponse;
  createGetJourneysResponses: CreateGetJourneysResponsesMutationResponse;
  createGoal?: Maybe<Goal>;
  createGoals: CreateGoalsMutationResponse;
  createGoogleAuthTokens: CreateGoogleAuthTokensMutationResponse;
  createHierarchies: CreateHierarchiesMutationResponse;
  createHierarchyActivities: CreateHierarchyActivitiesMutationResponse;
  createHierarchyActivityDetails: CreateHierarchyActivityDetailsMutationResponse;
  createHierarchyCourseDetails: CreateHierarchyCourseDetailsMutationResponse;
  createHierarchyCourses: CreateHierarchyCoursesMutationResponse;
  createHierarchyModuleDetails: CreateHierarchyModuleDetailsMutationResponse;
  createHierarchyModules: CreateHierarchyModulesMutationResponse;
  createHierarchyNodes: CreateHierarchyNodesMutationResponse;
  createHierarchyObjectives: CreateHierarchyObjectivesMutationResponse;
  createHobbies: CreateHobbiesMutationResponse;
  createInterest?: Maybe<Interest>;
  createInterests: CreateInterestsMutationResponse;
  createInviteCountResponses: CreateInviteCountResponsesMutationResponse;
  createInviteUsersToEnrollInCourseResponses: CreateInviteUsersToEnrollInCourseResponsesMutationResponse;
  createInviteUsersToEnrollInDegreeResponses: CreateInviteUsersToEnrollInDegreeResponsesMutationResponse;
  createInvitedUsers: CreateInvitedUsersMutationResponse;
  createJourneys: CreateJourneysMutationResponse;
  createLearningCheckoutSessions: CreateLearningCheckoutSessionsMutationResponse;
  createLearningNodes: CreateLearningNodesMutationResponse;
  createLearningObjectiveResponses: CreateLearningObjectiveResponsesMutationResponse;
  createLearningObjectives: CreateLearningObjectivesMutationResponse;
  createLearningPayments: CreateLearningPaymentsMutationResponse;
  createLearningSummaries: CreateLearningSummariesMutationResponse;
  createLearningTrees: CreateLearningTreesMutationResponse;
  createLesson?: Maybe<Lesson>;
  createLessons: CreateLessonsMutationResponse;
  createLevelDetails: CreateLevelDetailsMutationResponse;
  createLifeAspirations: CreateLifeAspirationsMutationResponse;
  createMasteryResults: CreateMasteryResultsMutationResponse;
  createMeResponses: CreateMeResponsesMutationResponse;
  createMessageResponses: CreateMessageResponsesMutationResponse;
  createMessages: CreateMessagesMutationResponse;
  createModule?: Maybe<ModuleResponse>;
  createModuleAnalytics: CreateModuleAnalyticsMutationResponse;
  createModuleAnalyticsDetails: CreateModuleAnalyticsDetailsMutationResponse;
  createModuleAnalyticsResponses: CreateModuleAnalyticsResponsesMutationResponse;
  createModuleCompletionDetails: CreateModuleCompletionDetailsMutationResponse;
  createModuleCompletionDetailsActivities: CreateModuleCompletionDetailsActivitiesMutationResponse;
  createModuleCompletionDetailsData: CreateModuleCompletionDetailsDataMutationResponse;
  createModuleCompletionDetailsFulls: CreateModuleCompletionDetailsFullsMutationResponse;
  createModuleCompletionDetailsResponses: CreateModuleCompletionDetailsResponsesMutationResponse;
  createModuleProgressAnalytics: CreateModuleProgressAnalyticsMutationResponse;
  createModuleProgressDetails: CreateModuleProgressDetailsMutationResponse;
  createModuleResponses: CreateModuleResponsesMutationResponse;
  createModules: CreateModulesMutationResponse;
  createMonthlySummaries: CreateMonthlySummariesMutationResponse;
  createNodeIdentities: CreateNodeIdentitiesMutationResponse;
  createNodeProperties: CreateNodePropertiesMutationResponse;
  createNutritions: CreateNutritionsMutationResponse;
  createOAuthAccessTokens: CreateOAuthAccessTokensMutationResponse;
  createOAuthClients: CreateOAuthClientsMutationResponse;
  createObjectiveAnalytics: CreateObjectiveAnalyticsMutationResponse;
  createObjectiveAnalyticsResponses: CreateObjectiveAnalyticsResponsesMutationResponse;
  createObjectiveCompletionDetails: CreateObjectiveCompletionDetailsMutationResponse;
  createObjectiveCompletionDetailsFulls: CreateObjectiveCompletionDetailsFullsMutationResponse;
  createObjectiveCompletionDetailsResponses: CreateObjectiveCompletionDetailsResponsesMutationResponse;
  createObjectiveProgressAnalytics: CreateObjectiveProgressAnalyticsMutationResponse;
  createObjectives: CreateObjectivesMutationResponse;
  createPage?: Maybe<PageResponse>;
  createPageResponses: CreatePageResponsesMutationResponse;
  createPages: CreatePagesMutationResponse;
  createParametersTypes: CreateParametersTypesMutationResponse;
  createParentRefs: CreateParentRefsMutationResponse;
  createPayments: CreatePaymentsMutationResponse;
  createPermissions: CreatePermissionsMutationResponse;
  createPersonalityTraits: CreatePersonalityTraitsMutationResponse;
  createPreference?: Maybe<Preferences>;
  createPreferences: CreatePreferencesMutationResponse;
  createPrerequisites: CreatePrerequisitesMutationResponse;
  createProgress?: Maybe<Progress>;
  createProgressDetails: CreateProgressDetailsMutationResponse;
  createProgresses: CreateProgressesMutationResponse;
  createQuarterlySummaries: CreateQuarterlySummariesMutationResponse;
  createRateLimitResults: CreateRateLimitResultsMutationResponse;
  createRecentCategories: CreateRecentCategoriesMutationResponse;
  createRecentTracks: CreateRecentTracksMutationResponse;
  createRecomendeds: CreateRecomendedsMutationResponse;
  createReflections: CreateReflectionsMutationResponse;
  createRefreshQuestResponses: CreateRefreshQuestResponsesMutationResponse;
  createRelationshipFields: CreateRelationshipFieldsMutationResponse;
  createResponseTypes: CreateResponseTypesMutationResponse;
  createRoles: CreateRolesMutationResponse;
  createSchools: CreateSchoolsMutationResponse;
  createSessionResponses: CreateSessionResponsesMutationResponse;
  createStudentActivityProgressData: CreateStudentActivityProgressDataMutationResponse;
  createStudentActivityProgressResponses: CreateStudentActivityProgressResponsesMutationResponse;
  createStudentAnalytics: CreateStudentAnalyticsMutationResponse;
  createStudentAnalyticsDetails: CreateStudentAnalyticsDetailsMutationResponse;
  createStudentAnalyticsResponses: CreateStudentAnalyticsResponsesMutationResponse;
  createStudentAnalyticsSummaryResponses: CreateStudentAnalyticsSummaryResponsesMutationResponse;
  createStudentChatAnalytics: CreateStudentChatAnalyticsMutationResponse;
  createStudentChatAnalyticsResponses: CreateStudentChatAnalyticsResponsesMutationResponse;
  createStudentCourseProgressResponses: CreateStudentCourseProgressResponsesMutationResponse;
  createStudentDetailedAnalytics: CreateStudentDetailedAnalyticsMutationResponse;
  createStudentDetailedAnalyticsResponses: CreateStudentDetailedAnalyticsResponsesMutationResponse;
  createStudentLearningInsights: CreateStudentLearningInsightsMutationResponse;
  createStudentLearningInsightsResponses: CreateStudentLearningInsightsResponsesMutationResponse;
  createStudentModuleProgressData: CreateStudentModuleProgressDataMutationResponse;
  createStudentModuleProgressResponses: CreateStudentModuleProgressResponsesMutationResponse;
  createStudentSummaries: CreateStudentSummariesMutationResponse;
  createSubchapterDetails: CreateSubchapterDetailsMutationResponse;
  createSubchapters: CreateSubchaptersMutationResponse;
  createSubfields: CreateSubfieldsMutationResponse;
  createSubmissionListResponseData: CreateSubmissionListResponseDataMutationResponse;
  createSubmissionListResponseFormatteds: CreateSubmissionListResponseFormattedsMutationResponse;
  createSubmissionListResponses: CreateSubmissionListResponsesMutationResponse;
  createSubmissionResponses: CreateSubmissionResponsesMutationResponse;
  createSubmissions: CreateSubmissionsMutationResponse;
  createSuggestVideoInsightsResponses: CreateSuggestVideoInsightsResponsesMutationResponse;
  createSuggestions: CreateSuggestionsMutationResponse;
  createTenant?: Maybe<TenantResponse>;
  createTenantResponses: CreateTenantResponsesMutationResponse;
  createTenantSchemaResponses: CreateTenantSchemaResponsesMutationResponse;
  createTenants: CreateTenantsMutationResponse;
  createTestings: CreateTestingsMutationResponse;
  createTodos: CreateTodosMutationResponse;
  createTopics: CreateTopicsMutationResponse;
  createTrackOverviews: CreateTrackOverviewsMutationResponse;
  createTransactionByDates: CreateTransactionByDatesMutationResponse;
  createTransactions: CreateTransactionsMutationResponse;
  createTranscriptSegments: CreateTranscriptSegmentsMutationResponse;
  createUpdateAssignmentResponses: CreateUpdateAssignmentResponsesMutationResponse;
  createUpdateContentModuleNameResponses: CreateUpdateContentModuleNameResponsesMutationResponse;
  createUpdateUserModalOnboardingResponses: CreateUpdateUserModalOnboardingResponsesMutationResponse;
  createUploadCourseThumbnailResponses: CreateUploadCourseThumbnailResponsesMutationResponse;
  createUploadDocumentsResponses: CreateUploadDocumentsResponsesMutationResponse;
  createUploadDocumentsToBucketResponses: CreateUploadDocumentsToBucketResponsesMutationResponse;
  createUploadFacultyProfileImageResponses: CreateUploadFacultyProfileImageResponsesMutationResponse;
  createUserCareerLoadingResponses: CreateUserCareerLoadingResponsesMutationResponse;
  createUserCourseLoadingResponses: CreateUserCourseLoadingResponsesMutationResponse;
  createUserMasteries: CreateUserMasteriesMutationResponse;
  createUserProgresses: CreateUserProgressesMutationResponse;
  createUsers: CreateUsersMutationResponse;
  createValidityResponses: CreateValidityResponsesMutationResponse;
  createVideoPool: VideoServiceResult;
  createVideoPoolItems: CreateVideoPoolItemsMutationResponse;
  createVideoServiceResults: CreateVideoServiceResultsMutationResponse;
  createVideos: CreateVideosMutationResponse;
  createVisualizationFromContent?: Maybe<VisualizationResponse>;
  createVisualizationResponses: CreateVisualizationResponsesMutationResponse;
  createVisualizations: CreateVisualizationsMutationResponse;
  createWaterIntakeSummaries: CreateWaterIntakeSummariesMutationResponse;
  createWaterIntakes: CreateWaterIntakesMutationResponse;
  createWebhooks: CreateWebhooksMutationResponse;
  createWeeklySummaries: CreateWeeklySummariesMutationResponse;
  createWhitelistedEmails: CreateWhitelistedEmailsMutationResponse;
  createWorkouts: CreateWorkoutsMutationResponse;
  createYearlySummaries: CreateYearlySummariesMutationResponse;
  deleteAITutor?: Maybe<AiTutorResponse>;
  deleteAccount?: Maybe<ResponseType>;
  deleteAchievements: DeleteInfo;
  deleteActiveTracks: DeleteInfo;
  deleteActivityAnalytics: DeleteInfo;
  deleteActivityAnalyticsDetails: DeleteInfo;
  deleteActivityAnalyticsResponses: DeleteInfo;
  deleteActivityCompletionDetails: DeleteInfo;
  deleteActivityCompletionDetailsData: DeleteInfo;
  deleteActivityCompletionDetailsFulls: DeleteInfo;
  deleteActivityCompletionDetailsObjectives: DeleteInfo;
  deleteActivityCompletionDetailsResponses: DeleteInfo;
  deleteActivityInsights: DeleteInfo;
  deleteActivityInsightsResponses: DeleteInfo;
  deleteActivityProgressAnalytics: DeleteInfo;
  deleteActivityProgressDetails: DeleteInfo;
  deleteAddAssignmentResponses: DeleteInfo;
  deleteAddContentModuleResponses: DeleteInfo;
  deleteAiTutorAnalytics: DeleteInfo;
  deleteAiTutorAnalyticsResponses: DeleteInfo;
  deleteAiTutorEvalCriteriaResponses: DeleteInfo;
  deleteAiTutorGeneratedResponses: DeleteInfo;
  deleteAiTutorProgressAnalytics: DeleteInfo;
  deleteAiTutorProgressDetails: DeleteInfo;
  deleteAiTutorResponses: DeleteInfo;
  deleteAiTutors: DeleteInfo;
  deleteAnotherRelationshipFields: DeleteInfo;
  deleteAspect: ResponseType;
  deleteAspectDetailsTypes: DeleteInfo;
  deleteAssessmentListResponseData: DeleteInfo;
  deleteAssessmentListResponseFormatteds: DeleteInfo;
  deleteAssessmentListResponses: DeleteInfo;
  deleteAssessmentResponses: DeleteInfo;
  deleteAssessments: DeleteInfo;
  deleteAssignedQuests: DeleteInfo;
  deleteAssignment?: Maybe<AssignmentResponse>;
  deleteAssignmentAndSubmissionResponses: DeleteInfo;
  deleteAssignmentAndSubmissions: DeleteInfo;
  deleteAssignmentResponses: DeleteInfo;
  deleteAssignments: DeleteInfo;
  deleteAttributes: DeleteInfo;
  deleteAudioToggleUserResponses: DeleteInfo;
  deleteAuthPayloadResponses: DeleteInfo;
  deleteAuthPayloadUserPreferences: DeleteInfo;
  deleteAuthPayloadUsers: DeleteInfo;
  deleteAxioResource: AxioResourceResponse;
  deleteAxioResourceResponses: DeleteInfo;
  deleteAxioResources: DeleteInfo;
  deleteCareerHeaders: DeleteInfo;
  deleteCareerTopicResponses: DeleteInfo;
  deleteCareers: DeleteInfo;
  deleteChallenges: DeleteInfo;
  deleteChapters: DeleteInfo;
  deleteChatAnalytics: DeleteInfo;
  deleteChatAttachmentResponses: DeleteInfo;
  deleteChatResponses: DeleteInfo;
  deleteChats: DeleteInfo;
  deleteChildInfos: DeleteInfo;
  deleteCompletionActivityAnalytics: DeleteInfo;
  deleteCompletionActivityDetails: DeleteInfo;
  deleteCompletionAnalytics: DeleteInfo;
  deleteCompletionAnalyticsResponses: DeleteInfo;
  deleteCompletionCourseDetails: DeleteInfo;
  deleteCompletionModuleAnalytics: DeleteInfo;
  deleteCompletionModuleDetails: DeleteInfo;
  deleteCompletionObjectiveAnalytics: DeleteInfo;
  deleteCompletionStats: DeleteInfo;
  deleteContentAssignment: DeleteContentAssignmentResponse;
  deleteContentModule: DeleteContentModuleResponse;
  deleteCoreValues: DeleteInfo;
  deleteCourse: DeleteCourseResponse;
  deleteCourseAnalytics: DeleteInfo;
  deleteCourseAnalyticsDetails: DeleteInfo;
  deleteCourseAnalyticsResponses: DeleteInfo;
  deleteCourseAnalyticsSummaryResponses: DeleteInfo;
  deleteCourseCascade?: Maybe<CourseResponse>;
  deleteCourseCategories: DeleteInfo;
  deleteCourseCompletionDetails: DeleteInfo;
  deleteCourseCompletionDetailsData: DeleteInfo;
  deleteCourseCompletionDetailsResponses: DeleteInfo;
  deleteCourseCompletionSummaries: DeleteInfo;
  deleteCourseCompletionSummaryResponses: DeleteInfo;
  deleteCourseCreationResponses: DeleteInfo;
  deleteCourseDetailedAnalytics: DeleteInfo;
  deleteCourseDetailedResponses: DeleteInfo;
  deleteCourseHierarchyResponses: DeleteInfo;
  deleteCourseProgressAnalytics: DeleteInfo;
  deleteCourseProgressDetails: DeleteInfo;
  deleteCourseResponses: DeleteInfo;
  deleteCourseRosterData: DeleteInfo;
  deleteCourseRosterServiceResults: DeleteInfo;
  deleteCourseSummaries: DeleteInfo;
  deleteCourses: DeleteInfo;
  deleteCoursesByQueryData: DeleteInfo;
  deleteCoursesByQueryResponses: DeleteInfo;
  deleteCurricula: DeleteInfo;
  deleteDailyCompletedTodosCounts: DeleteInfo;
  deleteDailyExerciseTotals: DeleteInfo;
  deleteDailyNutritionTotals: DeleteInfo;
  deleteDailySummaries: DeleteInfo;
  deleteDegree?: Maybe<DegreeResponse>;
  deleteDegreeResponses: DeleteInfo;
  deleteDegrees: DeleteInfo;
  deleteDegreesBySchoolByQueryData: DeleteInfo;
  deleteDegreesBySchoolByQueryResponses: DeleteInfo;
  deleteDeleteContentAssignmentResponses: DeleteInfo;
  deleteDeleteContentAssignmentVariables: DeleteInfo;
  deleteDeleteContentModuleResponses: DeleteInfo;
  deleteDeleteContentModuleVariables: DeleteInfo;
  deleteDeleteCourseResponses: DeleteInfo;
  deleteDeviceSessions: DeleteInfo;
  deleteEmotionalStates: DeleteInfo;
  deleteEvents: DeleteInfo;
  deleteExportCsvResponses: DeleteInfo;
  deleteFacultyDataCourses: DeleteInfo;
  deleteFacultyDataRoles: DeleteInfo;
  deleteFacultyDataSessionDeviceSessions: DeleteInfo;
  deleteFacultyQueryResponseData: DeleteInfo;
  deleteFacultyQueryResponseWithCountData: DeleteInfo;
  deleteFacultyQueryResponses: DeleteInfo;
  deleteFeedbacks: DeleteInfo;
  deleteFields: DeleteInfo;
  deleteFileResponses: DeleteInfo;
  deleteFileUploadResponses: DeleteInfo;
  deleteFiles: DeleteInfo;
  deleteFood?: Maybe<Food>;
  deleteFoodItems: DeleteInfo;
  deleteFoods: DeleteInfo;
  deleteFormattedAiTutors: DeleteInfo;
  deleteFormattedChats: DeleteInfo;
  deleteFormattedObjectives: DeleteInfo;
  deleteFormattedVisualizations: DeleteInfo;
  deleteGames: DeleteInfo;
  deleteGenerateCourseContentData: DeleteInfo;
  deleteGenerateCourseContentResponses: DeleteInfo;
  deleteGeneratedAiTutors: DeleteInfo;
  deleteGeneratedObjectives: DeleteInfo;
  deleteGenerateds: DeleteInfo;
  deleteGetAssignmentObjectiveResponses: DeleteInfo;
  deleteGetAssignmentResponses: DeleteInfo;
  deleteGetJourneyByIdResponses: DeleteInfo;
  deleteGetJourneysResponses: DeleteInfo;
  deleteGoal?: Maybe<Scalars["Boolean"]["output"]>;
  deleteGoals: DeleteInfo;
  deleteGoogleAuthTokens: DeleteInfo;
  deleteHierarchies: DeleteInfo;
  deleteHierarchyActivities: DeleteInfo;
  deleteHierarchyActivityDetails: DeleteInfo;
  deleteHierarchyCourseDetails: DeleteInfo;
  deleteHierarchyCourses: DeleteInfo;
  deleteHierarchyModuleDetails: DeleteInfo;
  deleteHierarchyModules: DeleteInfo;
  deleteHierarchyNodes: DeleteInfo;
  deleteHierarchyObjectives: DeleteInfo;
  deleteHobbies: DeleteInfo;
  deleteInterest?: Maybe<Scalars["Boolean"]["output"]>;
  deleteInterests: DeleteInfo;
  deleteInviteCountResponses: DeleteInfo;
  deleteInviteUsersToEnrollInCourseResponses: DeleteInfo;
  deleteInviteUsersToEnrollInDegreeResponses: DeleteInfo;
  deleteInvitedUsers: DeleteInfo;
  deleteJourney?: Maybe<ResponseType>;
  deleteJourneys: DeleteInfo;
  deleteLearningCheckoutSessions: DeleteInfo;
  deleteLearningNodes: DeleteInfo;
  deleteLearningObjectiveResponses: DeleteInfo;
  deleteLearningObjectives: DeleteInfo;
  deleteLearningPayments: DeleteInfo;
  deleteLearningSummaries: DeleteInfo;
  deleteLearningTrees: DeleteInfo;
  deleteLesson?: Maybe<Scalars["Boolean"]["output"]>;
  deleteLessons: DeleteInfo;
  deleteLevelDetails: DeleteInfo;
  deleteLifeAspirations: DeleteInfo;
  deleteLoaderMessages: Scalars["Boolean"]["output"];
  deleteMasteryResults: DeleteInfo;
  deleteMeResponses: DeleteInfo;
  deleteMessage?: Maybe<Message>;
  deleteMessageResponses: DeleteInfo;
  deleteMessages: DeleteInfo;
  deleteModuleAnalytics: DeleteInfo;
  deleteModuleAnalyticsDetails: DeleteInfo;
  deleteModuleAnalyticsResponses: DeleteInfo;
  deleteModuleCascade?: Maybe<ModuleResponse>;
  deleteModuleCompletionDetails: DeleteInfo;
  deleteModuleCompletionDetailsActivities: DeleteInfo;
  deleteModuleCompletionDetailsData: DeleteInfo;
  deleteModuleCompletionDetailsFulls: DeleteInfo;
  deleteModuleCompletionDetailsResponses: DeleteInfo;
  deleteModuleProgressAnalytics: DeleteInfo;
  deleteModuleProgressDetails: DeleteInfo;
  deleteModuleResponses: DeleteInfo;
  deleteModules: DeleteInfo;
  deleteMonthlySummaries: DeleteInfo;
  deleteNodeIdentities: DeleteInfo;
  deleteNodeProperties: DeleteInfo;
  deleteNutritions: DeleteInfo;
  deleteOAuthAccessTokens: DeleteInfo;
  deleteOAuthClients: DeleteInfo;
  deleteObjectiveAnalytics: DeleteInfo;
  deleteObjectiveAnalyticsResponses: DeleteInfo;
  deleteObjectiveCompletionDetails: DeleteInfo;
  deleteObjectiveCompletionDetailsFulls: DeleteInfo;
  deleteObjectiveCompletionDetailsResponses: DeleteInfo;
  deleteObjectiveProgressAnalytics: DeleteInfo;
  deleteObjectives: DeleteInfo;
  deletePage?: Maybe<PageResponse>;
  deletePageResponses: DeleteInfo;
  deletePages: DeleteInfo;
  deleteParametersTypes: DeleteInfo;
  deleteParentRefs: DeleteInfo;
  deletePayments: DeleteInfo;
  deletePermissions: DeleteInfo;
  deletePersonalityTraits: DeleteInfo;
  deletePreference?: Maybe<Scalars["Boolean"]["output"]>;
  deletePreferences: DeleteInfo;
  deletePrerequisites: DeleteInfo;
  deleteProgress?: Maybe<Scalars["Boolean"]["output"]>;
  deleteProgressDetails: DeleteInfo;
  deleteProgresses: DeleteInfo;
  deleteQuarterlySummaries: DeleteInfo;
  deleteRateLimitResults: DeleteInfo;
  deleteRecentCategories: DeleteInfo;
  deleteRecentTracks: DeleteInfo;
  deleteRecomendeds: DeleteInfo;
  deleteReflections: DeleteInfo;
  deleteRefreshQuestResponses: DeleteInfo;
  deleteRelationshipFields: DeleteInfo;
  deleteResponseTypes: DeleteInfo;
  deleteRoles: DeleteInfo;
  deleteSchools: DeleteInfo;
  deleteSessionResponses: DeleteInfo;
  deleteStudentActivityProgressData: DeleteInfo;
  deleteStudentActivityProgressResponses: DeleteInfo;
  deleteStudentAnalytics: DeleteInfo;
  deleteStudentAnalyticsDetails: DeleteInfo;
  deleteStudentAnalyticsResponses: DeleteInfo;
  deleteStudentAnalyticsSummaryResponses: DeleteInfo;
  deleteStudentChatAnalytics: DeleteInfo;
  deleteStudentChatAnalyticsResponses: DeleteInfo;
  deleteStudentCourseProgressResponses: DeleteInfo;
  deleteStudentDetailedAnalytics: DeleteInfo;
  deleteStudentDetailedAnalyticsResponses: DeleteInfo;
  deleteStudentLearningInsights: DeleteInfo;
  deleteStudentLearningInsightsResponses: DeleteInfo;
  deleteStudentModuleProgressData: DeleteInfo;
  deleteStudentModuleProgressResponses: DeleteInfo;
  deleteStudentSummaries: DeleteInfo;
  deleteSubchapterDetails: DeleteInfo;
  deleteSubchapters: DeleteInfo;
  deleteSubfields: DeleteInfo;
  deleteSubmissionListResponseData: DeleteInfo;
  deleteSubmissionListResponseFormatteds: DeleteInfo;
  deleteSubmissionListResponses: DeleteInfo;
  deleteSubmissionResponses: DeleteInfo;
  deleteSubmissions: DeleteInfo;
  deleteSuggestVideoInsightsResponses: DeleteInfo;
  deleteSuggestions: DeleteInfo;
  deleteTenant?: Maybe<TenantResponse>;
  deleteTenantResponses: DeleteInfo;
  deleteTenantSchemaResponses: DeleteInfo;
  deleteTenants: DeleteInfo;
  deleteTestings: DeleteInfo;
  deleteTodo?: Maybe<ResponseType>;
  deleteTodos: DeleteInfo;
  deleteTopics: DeleteInfo;
  deleteTrackOverviews: DeleteInfo;
  deleteTransactionByDates: DeleteInfo;
  deleteTransactions: DeleteInfo;
  deleteTranscriptSegments: DeleteInfo;
  deleteUpdateAssignmentResponses: DeleteInfo;
  deleteUpdateContentModuleNameResponses: DeleteInfo;
  deleteUpdateUserModalOnboardingResponses: DeleteInfo;
  deleteUploadCourseThumbnailResponses: DeleteInfo;
  deleteUploadDocumentsResponses: DeleteInfo;
  deleteUploadDocumentsToBucketResponses: DeleteInfo;
  deleteUploadFacultyProfileImageResponses: DeleteInfo;
  deleteUserCareerLoadingResponses: DeleteInfo;
  deleteUserCourseLoadingResponses: DeleteInfo;
  deleteUserMasteries: DeleteInfo;
  deleteUserProgresses: DeleteInfo;
  deleteUsers: DeleteInfo;
  deleteValidityResponses: DeleteInfo;
  deleteVideoPoolItems: DeleteInfo;
  deleteVideoServiceResults: DeleteInfo;
  deleteVideos: DeleteInfo;
  deleteVisualization?: Maybe<VisualizationResponse>;
  deleteVisualizationResponses: DeleteInfo;
  deleteVisualizations: DeleteInfo;
  deleteWaterIntakeSummaries: DeleteInfo;
  deleteWaterIntakes: DeleteInfo;
  deleteWebhooks: DeleteInfo;
  deleteWeeklySummaries: DeleteInfo;
  deleteWhitelistedEmails: DeleteInfo;
  deleteWorkouts: DeleteInfo;
  deleteYearlySummaries: DeleteInfo;
  dropUserFromCourse?: Maybe<ResponseType>;
  editAITutor?: Maybe<AiTutorResponse>;
  enrollInDegree?: Maybe<DegreeResponse>;
  enrollUserInCourse?: Maybe<ResponseType>;
  enrollUserInTrack: ResponseType;
  generateAiTutorFromTitle?: Maybe<AiTutorGeneratedResponse>;
  generateCourseContent?: Maybe<GenerateCourseContentResponse>;
  generateEvalCriteria?: Maybe<AiTutorEvalCriteriaResponse>;
  generateSuggestedCareers?: Maybe<Career>;
  generateSuggestedDegrees: DegreeResponse;
  generateUploadUrl: FileResponse;
  generateYoutubeKeywords?: Maybe<Scalars["String"]["output"]>;
  inviteUserToSchool?: Maybe<ResponseType>;
  inviteUsersToEnrollInCourse?: Maybe<InviteUsersToEnrollInCourseResponse>;
  inviteUsersToEnrollInDegree?: Maybe<InviteUsersToEnrollInDegreeResponse>;
  journeySubmitForm?: Maybe<ResponseType>;
  login?: Maybe<AuthPayloadResponse>;
  logout: ResponseType;
  markQuestComplete?: Maybe<Chat>;
  processAttachment: ChatAttachmentResponse;
  processVideoPool: VideoServiceResult;
  refreshQuest?: Maybe<RefreshQuestResponse>;
  removeCourseAssignmentFromFaculty?: Maybe<ResponseType>;
  removeCoursesFromDegree?: Maybe<DegreeResponse>;
  removeDegreesFromCourse?: Maybe<CourseResponse>;
  removeFromWhitelist: Scalars["Boolean"]["output"];
  removeRoleFromUserForTenant?: Maybe<ResponseType>;
  removeStudentsFromCourse?: Maybe<CourseRosterServiceResult>;
  resetAccount?: Maybe<ResponseType>;
  resetPassword?: Maybe<ResponseType>;
  revokeFaculty?: Maybe<ResponseType>;
  setArchiveStatus: ResponseType;
  setCareerPathActive: Scalars["Boolean"]["output"];
  setKnowledgeRelevance: ResponseType;
  setOnboardingStatus: ResponseType;
  signup?: Maybe<AuthPayloadResponse>;
  submitStudentAssignment?: Maybe<SubmissionResponse>;
  updateAchievements: UpdateAchievementsMutationResponse;
  updateActiveTracks: UpdateActiveTracksMutationResponse;
  updateActivityAnalytics: UpdateActivityAnalyticsMutationResponse;
  updateActivityAnalyticsDetails: UpdateActivityAnalyticsDetailsMutationResponse;
  updateActivityAnalyticsResponses: UpdateActivityAnalyticsResponsesMutationResponse;
  updateActivityCompletionDetails: UpdateActivityCompletionDetailsMutationResponse;
  updateActivityCompletionDetailsData: UpdateActivityCompletionDetailsDataMutationResponse;
  updateActivityCompletionDetailsFulls: UpdateActivityCompletionDetailsFullsMutationResponse;
  updateActivityCompletionDetailsObjectives: UpdateActivityCompletionDetailsObjectivesMutationResponse;
  updateActivityCompletionDetailsResponses: UpdateActivityCompletionDetailsResponsesMutationResponse;
  updateActivityInsights: UpdateActivityInsightsMutationResponse;
  updateActivityInsightsResponses: UpdateActivityInsightsResponsesMutationResponse;
  updateActivityProgressAnalytics: UpdateActivityProgressAnalyticsMutationResponse;
  updateActivityProgressDetails: UpdateActivityProgressDetailsMutationResponse;
  updateAddAssignmentResponses: UpdateAddAssignmentResponsesMutationResponse;
  updateAddContentModuleResponses: UpdateAddContentModuleResponsesMutationResponse;
  updateAiTutorAnalytics: UpdateAiTutorAnalyticsMutationResponse;
  updateAiTutorAnalyticsResponses: UpdateAiTutorAnalyticsResponsesMutationResponse;
  updateAiTutorEvalCriteriaResponses: UpdateAiTutorEvalCriteriaResponsesMutationResponse;
  updateAiTutorGeneratedResponses: UpdateAiTutorGeneratedResponsesMutationResponse;
  updateAiTutorProgressAnalytics: UpdateAiTutorProgressAnalyticsMutationResponse;
  updateAiTutorProgressDetails: UpdateAiTutorProgressDetailsMutationResponse;
  updateAiTutorResponses: UpdateAiTutorResponsesMutationResponse;
  updateAiTutors: UpdateAiTutorsMutationResponse;
  updateAnotherRelationshipFields: UpdateAnotherRelationshipFieldsMutationResponse;
  updateAspectDetailsTypes: UpdateAspectDetailsTypesMutationResponse;
  updateAssessmentGrade?: Maybe<AssessmentResponse>;
  updateAssessmentListResponseData: UpdateAssessmentListResponseDataMutationResponse;
  updateAssessmentListResponseFormatteds: UpdateAssessmentListResponseFormattedsMutationResponse;
  updateAssessmentListResponses: UpdateAssessmentListResponsesMutationResponse;
  updateAssessmentResponses: UpdateAssessmentResponsesMutationResponse;
  updateAssessments: UpdateAssessmentsMutationResponse;
  updateAssignedQuests: UpdateAssignedQuestsMutationResponse;
  updateAssignment: UpdateAssignmentResponse;
  updateAssignmentAndSubmissionResponses: UpdateAssignmentAndSubmissionResponsesMutationResponse;
  updateAssignmentAndSubmissions: UpdateAssignmentAndSubmissionsMutationResponse;
  updateAssignmentResponses: UpdateAssignmentResponsesMutationResponse;
  updateAssignments: UpdateAssignmentsMutationResponse;
  updateAttributes: UpdateAttributesMutationResponse;
  updateAudioToggleUserResponses: UpdateAudioToggleUserResponsesMutationResponse;
  updateAuthPayloadResponses: UpdateAuthPayloadResponsesMutationResponse;
  updateAuthPayloadUserPreferences: UpdateAuthPayloadUserPreferencesMutationResponse;
  updateAuthPayloadUsers: UpdateAuthPayloadUsersMutationResponse;
  updateAxioResource: AxioResourceResponse;
  updateAxioResourceResponses: UpdateAxioResourceResponsesMutationResponse;
  updateAxioResources: UpdateAxioResourcesMutationResponse;
  updateCareerHeaders: UpdateCareerHeadersMutationResponse;
  updateCareerTopicResponses: UpdateCareerTopicResponsesMutationResponse;
  updateCareers: UpdateCareersMutationResponse;
  updateChallenges: UpdateChallengesMutationResponse;
  updateChapters: UpdateChaptersMutationResponse;
  updateChatAnalytics: UpdateChatAnalyticsMutationResponse;
  updateChatAttachmentResponses: UpdateChatAttachmentResponsesMutationResponse;
  updateChatResponses: UpdateChatResponsesMutationResponse;
  updateChats: UpdateChatsMutationResponse;
  updateChildInfos: UpdateChildInfosMutationResponse;
  updateCompletionActivityAnalytics: UpdateCompletionActivityAnalyticsMutationResponse;
  updateCompletionActivityDetails: UpdateCompletionActivityDetailsMutationResponse;
  updateCompletionAnalytics: UpdateCompletionAnalyticsMutationResponse;
  updateCompletionAnalyticsResponses: UpdateCompletionAnalyticsResponsesMutationResponse;
  updateCompletionCourseDetails: UpdateCompletionCourseDetailsMutationResponse;
  updateCompletionModuleAnalytics: UpdateCompletionModuleAnalyticsMutationResponse;
  updateCompletionModuleDetails: UpdateCompletionModuleDetailsMutationResponse;
  updateCompletionObjectiveAnalytics: UpdateCompletionObjectiveAnalyticsMutationResponse;
  updateCompletionStats: UpdateCompletionStatsMutationResponse;
  updateContentModuleName: UpdateContentModuleNameResponse;
  updateCoreValues: UpdateCoreValuesMutationResponse;
  updateCourse?: Maybe<CourseResponse>;
  updateCourseAnalytics: UpdateCourseAnalyticsMutationResponse;
  updateCourseAnalyticsDetails: UpdateCourseAnalyticsDetailsMutationResponse;
  updateCourseAnalyticsResponses: UpdateCourseAnalyticsResponsesMutationResponse;
  updateCourseAnalyticsSummaryResponses: UpdateCourseAnalyticsSummaryResponsesMutationResponse;
  updateCourseCategories: UpdateCourseCategoriesMutationResponse;
  updateCourseCompletionDetails: UpdateCourseCompletionDetailsMutationResponse;
  updateCourseCompletionDetailsData: UpdateCourseCompletionDetailsDataMutationResponse;
  updateCourseCompletionDetailsResponses: UpdateCourseCompletionDetailsResponsesMutationResponse;
  updateCourseCompletionSummaries: UpdateCourseCompletionSummariesMutationResponse;
  updateCourseCompletionSummaryResponses: UpdateCourseCompletionSummaryResponsesMutationResponse;
  updateCourseCreationResponses: UpdateCourseCreationResponsesMutationResponse;
  updateCourseDetailedAnalytics: UpdateCourseDetailedAnalyticsMutationResponse;
  updateCourseDetailedResponses: UpdateCourseDetailedResponsesMutationResponse;
  updateCourseHierarchyResponses: UpdateCourseHierarchyResponsesMutationResponse;
  updateCourseProgressAnalytics: UpdateCourseProgressAnalyticsMutationResponse;
  updateCourseProgressDetails: UpdateCourseProgressDetailsMutationResponse;
  updateCourseResponses: UpdateCourseResponsesMutationResponse;
  updateCourseRosterData: UpdateCourseRosterDataMutationResponse;
  updateCourseRosterServiceResults: UpdateCourseRosterServiceResultsMutationResponse;
  updateCourseSummaries: UpdateCourseSummariesMutationResponse;
  updateCourses: UpdateCoursesMutationResponse;
  updateCoursesByQueryData: UpdateCoursesByQueryDataMutationResponse;
  updateCoursesByQueryResponses: UpdateCoursesByQueryResponsesMutationResponse;
  updateCurricula: UpdateCurriculaMutationResponse;
  updateDailyCompletedTodosCounts: UpdateDailyCompletedTodosCountsMutationResponse;
  updateDailyExerciseTotals: UpdateDailyExerciseTotalsMutationResponse;
  updateDailyNutritionTotals: UpdateDailyNutritionTotalsMutationResponse;
  updateDailySummaries: UpdateDailySummariesMutationResponse;
  updateDegree?: Maybe<DegreeResponse>;
  updateDegreeResponses: UpdateDegreeResponsesMutationResponse;
  updateDegrees: UpdateDegreesMutationResponse;
  updateDegreesBySchoolByQueryData: UpdateDegreesBySchoolByQueryDataMutationResponse;
  updateDegreesBySchoolByQueryResponses: UpdateDegreesBySchoolByQueryResponsesMutationResponse;
  updateDeleteContentAssignmentResponses: UpdateDeleteContentAssignmentResponsesMutationResponse;
  updateDeleteContentAssignmentVariables: UpdateDeleteContentAssignmentVariablesMutationResponse;
  updateDeleteContentModuleResponses: UpdateDeleteContentModuleResponsesMutationResponse;
  updateDeleteContentModuleVariables: UpdateDeleteContentModuleVariablesMutationResponse;
  updateDeleteCourseResponses: UpdateDeleteCourseResponsesMutationResponse;
  updateDeviceSessions: UpdateDeviceSessionsMutationResponse;
  updateEmotionalStates: UpdateEmotionalStatesMutationResponse;
  updateEvents: UpdateEventsMutationResponse;
  updateExportCsvResponses: UpdateExportCsvResponsesMutationResponse;
  updateFacultyDataCourses: UpdateFacultyDataCoursesMutationResponse;
  updateFacultyDataRoles: UpdateFacultyDataRolesMutationResponse;
  updateFacultyDataSessionDeviceSessions: UpdateFacultyDataSessionDeviceSessionsMutationResponse;
  updateFacultyProfile?: Maybe<ResponseType>;
  updateFacultyQueryResponseData: UpdateFacultyQueryResponseDataMutationResponse;
  updateFacultyQueryResponseWithCountData: UpdateFacultyQueryResponseWithCountDataMutationResponse;
  updateFacultyQueryResponses: UpdateFacultyQueryResponsesMutationResponse;
  updateFeedback?: Maybe<Feedback>;
  updateFeedbacks: UpdateFeedbacksMutationResponse;
  updateFields: UpdateFieldsMutationResponse;
  updateFileResponses: UpdateFileResponsesMutationResponse;
  updateFileUploadResponses: UpdateFileUploadResponsesMutationResponse;
  updateFiles: UpdateFilesMutationResponse;
  updateFood?: Maybe<Food>;
  updateFoodItems: UpdateFoodItemsMutationResponse;
  updateFoods: UpdateFoodsMutationResponse;
  updateFormattedAiTutors: UpdateFormattedAiTutorsMutationResponse;
  updateFormattedChats: UpdateFormattedChatsMutationResponse;
  updateFormattedObjectives: UpdateFormattedObjectivesMutationResponse;
  updateFormattedVisualizations: UpdateFormattedVisualizationsMutationResponse;
  updateGames: UpdateGamesMutationResponse;
  updateGenerateCourseContentData: UpdateGenerateCourseContentDataMutationResponse;
  updateGenerateCourseContentResponses: UpdateGenerateCourseContentResponsesMutationResponse;
  updateGeneratedAiTutors: UpdateGeneratedAiTutorsMutationResponse;
  updateGeneratedObjectives: UpdateGeneratedObjectivesMutationResponse;
  updateGenerateds: UpdateGeneratedsMutationResponse;
  updateGetAssignmentObjectiveResponses: UpdateGetAssignmentObjectiveResponsesMutationResponse;
  updateGetAssignmentResponses: UpdateGetAssignmentResponsesMutationResponse;
  updateGetJourneyByIdResponses: UpdateGetJourneyByIdResponsesMutationResponse;
  updateGetJourneysResponses: UpdateGetJourneysResponsesMutationResponse;
  updateGoal?: Maybe<Goal>;
  updateGoals: UpdateGoalsMutationResponse;
  updateGoogleAuthTokens: UpdateGoogleAuthTokensMutationResponse;
  updateHierarchies: UpdateHierarchiesMutationResponse;
  updateHierarchyActivities: UpdateHierarchyActivitiesMutationResponse;
  updateHierarchyActivityDetails: UpdateHierarchyActivityDetailsMutationResponse;
  updateHierarchyCourseDetails: UpdateHierarchyCourseDetailsMutationResponse;
  updateHierarchyCourses: UpdateHierarchyCoursesMutationResponse;
  updateHierarchyModuleDetails: UpdateHierarchyModuleDetailsMutationResponse;
  updateHierarchyModules: UpdateHierarchyModulesMutationResponse;
  updateHierarchyNodes: UpdateHierarchyNodesMutationResponse;
  updateHierarchyObjectives: UpdateHierarchyObjectivesMutationResponse;
  updateHobbies: UpdateHobbiesMutationResponse;
  updateInterest?: Maybe<Interest>;
  updateInterests: UpdateInterestsMutationResponse;
  updateInviteCountResponses: UpdateInviteCountResponsesMutationResponse;
  updateInviteUsersToEnrollInCourseResponses: UpdateInviteUsersToEnrollInCourseResponsesMutationResponse;
  updateInviteUsersToEnrollInDegreeResponses: UpdateInviteUsersToEnrollInDegreeResponsesMutationResponse;
  updateInvitedUsers: UpdateInvitedUsersMutationResponse;
  updateJourneys: UpdateJourneysMutationResponse;
  updateLearningCheckoutSessions: UpdateLearningCheckoutSessionsMutationResponse;
  updateLearningNodes: UpdateLearningNodesMutationResponse;
  updateLearningObjectiveCompletion: ResponseType;
  updateLearningObjectiveResponses: UpdateLearningObjectiveResponsesMutationResponse;
  updateLearningObjectives: UpdateLearningObjectivesMutationResponse;
  updateLearningPayments: UpdateLearningPaymentsMutationResponse;
  updateLearningSummaries: UpdateLearningSummariesMutationResponse;
  updateLearningTrees: UpdateLearningTreesMutationResponse;
  updateLesson?: Maybe<Lesson>;
  updateLessons: UpdateLessonsMutationResponse;
  updateLevelDetails: UpdateLevelDetailsMutationResponse;
  updateLifeAspirations: UpdateLifeAspirationsMutationResponse;
  updateMasteryResults: UpdateMasteryResultsMutationResponse;
  updateMeResponses: UpdateMeResponsesMutationResponse;
  updateMessageResponses: UpdateMessageResponsesMutationResponse;
  updateMessages: UpdateMessagesMutationResponse;
  updateModule?: Maybe<ModuleResponse>;
  updateModuleAnalytics: UpdateModuleAnalyticsMutationResponse;
  updateModuleAnalyticsDetails: UpdateModuleAnalyticsDetailsMutationResponse;
  updateModuleAnalyticsResponses: UpdateModuleAnalyticsResponsesMutationResponse;
  updateModuleCompletionDetails: UpdateModuleCompletionDetailsMutationResponse;
  updateModuleCompletionDetailsActivities: UpdateModuleCompletionDetailsActivitiesMutationResponse;
  updateModuleCompletionDetailsData: UpdateModuleCompletionDetailsDataMutationResponse;
  updateModuleCompletionDetailsFulls: UpdateModuleCompletionDetailsFullsMutationResponse;
  updateModuleCompletionDetailsResponses: UpdateModuleCompletionDetailsResponsesMutationResponse;
  updateModuleProgressAnalytics: UpdateModuleProgressAnalyticsMutationResponse;
  updateModuleProgressDetails: UpdateModuleProgressDetailsMutationResponse;
  updateModuleResponses: UpdateModuleResponsesMutationResponse;
  updateModules: UpdateModulesMutationResponse;
  updateMonthlySummaries: UpdateMonthlySummariesMutationResponse;
  updateNodeIdentities: UpdateNodeIdentitiesMutationResponse;
  updateNodeProperties: UpdateNodePropertiesMutationResponse;
  updateNotifications?: Maybe<ResponseType>;
  updateNutritions: UpdateNutritionsMutationResponse;
  updateOAuthAccessTokens: UpdateOAuthAccessTokensMutationResponse;
  updateOAuthClients: UpdateOAuthClientsMutationResponse;
  updateObjectiveAnalytics: UpdateObjectiveAnalyticsMutationResponse;
  updateObjectiveAnalyticsResponses: UpdateObjectiveAnalyticsResponsesMutationResponse;
  updateObjectiveCompletionDetails: UpdateObjectiveCompletionDetailsMutationResponse;
  updateObjectiveCompletionDetailsFulls: UpdateObjectiveCompletionDetailsFullsMutationResponse;
  updateObjectiveCompletionDetailsResponses: UpdateObjectiveCompletionDetailsResponsesMutationResponse;
  updateObjectiveProgressAnalytics: UpdateObjectiveProgressAnalyticsMutationResponse;
  updateObjectives: UpdateObjectivesMutationResponse;
  updatePage?: Maybe<PageResponse>;
  updatePageResponses: UpdatePageResponsesMutationResponse;
  updatePages: UpdatePagesMutationResponse;
  updateParametersTypes: UpdateParametersTypesMutationResponse;
  updateParentRefs: UpdateParentRefsMutationResponse;
  updatePayments: UpdatePaymentsMutationResponse;
  updatePermissions: UpdatePermissionsMutationResponse;
  updatePersonalityTraits: UpdatePersonalityTraitsMutationResponse;
  updatePreference?: Maybe<Preferences>;
  updatePreferences: UpdatePreferencesMutationResponse;
  updatePrerequisites: UpdatePrerequisitesMutationResponse;
  updateProgress?: Maybe<Progress>;
  updateProgressDetails: UpdateProgressDetailsMutationResponse;
  updateProgresses: UpdateProgressesMutationResponse;
  updateQuarterlySummaries: UpdateQuarterlySummariesMutationResponse;
  updateRateLimitResults: UpdateRateLimitResultsMutationResponse;
  updateRecentCategories: UpdateRecentCategoriesMutationResponse;
  updateRecentTracks: UpdateRecentTracksMutationResponse;
  updateRecomendeds: UpdateRecomendedsMutationResponse;
  updateReflections: UpdateReflectionsMutationResponse;
  updateRefreshQuestResponses: UpdateRefreshQuestResponsesMutationResponse;
  updateRelationshipFields: UpdateRelationshipFieldsMutationResponse;
  updateResourceOrder?: Maybe<ModuleResponse>;
  updateResponseTypes: UpdateResponseTypesMutationResponse;
  updateRoles: UpdateRolesMutationResponse;
  updateSchools: UpdateSchoolsMutationResponse;
  updateSessionResponses: UpdateSessionResponsesMutationResponse;
  updateStudentActivityProgressData: UpdateStudentActivityProgressDataMutationResponse;
  updateStudentActivityProgressResponses: UpdateStudentActivityProgressResponsesMutationResponse;
  updateStudentAnalytics: UpdateStudentAnalyticsMutationResponse;
  updateStudentAnalyticsDetails: UpdateStudentAnalyticsDetailsMutationResponse;
  updateStudentAnalyticsResponses: UpdateStudentAnalyticsResponsesMutationResponse;
  updateStudentAnalyticsSummaryResponses: UpdateStudentAnalyticsSummaryResponsesMutationResponse;
  updateStudentAssignmentGrade?: Maybe<SubmissionResponse>;
  updateStudentChatAnalytics: UpdateStudentChatAnalyticsMutationResponse;
  updateStudentChatAnalyticsResponses: UpdateStudentChatAnalyticsResponsesMutationResponse;
  updateStudentCourseProgressResponses: UpdateStudentCourseProgressResponsesMutationResponse;
  updateStudentDetailedAnalytics: UpdateStudentDetailedAnalyticsMutationResponse;
  updateStudentDetailedAnalyticsResponses: UpdateStudentDetailedAnalyticsResponsesMutationResponse;
  updateStudentLearningInsights: UpdateStudentLearningInsightsMutationResponse;
  updateStudentLearningInsightsResponses: UpdateStudentLearningInsightsResponsesMutationResponse;
  updateStudentModuleProgressData: UpdateStudentModuleProgressDataMutationResponse;
  updateStudentModuleProgressResponses: UpdateStudentModuleProgressResponsesMutationResponse;
  updateStudentSummaries: UpdateStudentSummariesMutationResponse;
  updateSubchapterDetails: UpdateSubchapterDetailsMutationResponse;
  updateSubchapters: UpdateSubchaptersMutationResponse;
  updateSubfields: UpdateSubfieldsMutationResponse;
  updateSubmissionListResponseData: UpdateSubmissionListResponseDataMutationResponse;
  updateSubmissionListResponseFormatteds: UpdateSubmissionListResponseFormattedsMutationResponse;
  updateSubmissionListResponses: UpdateSubmissionListResponsesMutationResponse;
  updateSubmissionResponses: UpdateSubmissionResponsesMutationResponse;
  updateSubmissions: UpdateSubmissionsMutationResponse;
  updateSuggestVideoInsightsResponses: UpdateSuggestVideoInsightsResponsesMutationResponse;
  updateSuggestions: UpdateSuggestionsMutationResponse;
  updateTenant?: Maybe<TenantResponse>;
  updateTenantResponses: UpdateTenantResponsesMutationResponse;
  updateTenantSchemaResponses: UpdateTenantSchemaResponsesMutationResponse;
  updateTenants: UpdateTenantsMutationResponse;
  updateTestings: UpdateTestingsMutationResponse;
  updateTodo?: Maybe<Todo>;
  updateTodos: UpdateTodosMutationResponse;
  updateTopic: ResponseType;
  updateTopics: UpdateTopicsMutationResponse;
  updateTrackOverviews: UpdateTrackOverviewsMutationResponse;
  updateTransactionByDates: UpdateTransactionByDatesMutationResponse;
  updateTransactions: UpdateTransactionsMutationResponse;
  updateTranscriptSegments: UpdateTranscriptSegmentsMutationResponse;
  updateUpdateAssignmentResponses: UpdateUpdateAssignmentResponsesMutationResponse;
  updateUpdateContentModuleNameResponses: UpdateUpdateContentModuleNameResponsesMutationResponse;
  updateUpdateUserModalOnboardingResponses: UpdateUpdateUserModalOnboardingResponsesMutationResponse;
  updateUploadCourseThumbnailResponses: UpdateUploadCourseThumbnailResponsesMutationResponse;
  updateUploadDocumentsResponses: UpdateUploadDocumentsResponsesMutationResponse;
  updateUploadDocumentsToBucketResponses: UpdateUploadDocumentsToBucketResponsesMutationResponse;
  updateUploadFacultyProfileImageResponses: UpdateUploadFacultyProfileImageResponsesMutationResponse;
  updateUserCareerLoadingResponses: UpdateUserCareerLoadingResponsesMutationResponse;
  updateUserCourseLoadingResponses: UpdateUserCourseLoadingResponsesMutationResponse;
  updateUserMasteries: UpdateUserMasteriesMutationResponse;
  updateUserModalOnboarding?: Maybe<UpdateUserModalOnboardingResponse>;
  updateUserProgresses: UpdateUserProgressesMutationResponse;
  updateUsers: UpdateUsersMutationResponse;
  updateValidityResponses: UpdateValidityResponsesMutationResponse;
  updateVideoPoolItems: UpdateVideoPoolItemsMutationResponse;
  updateVideoServiceResults: UpdateVideoServiceResultsMutationResponse;
  updateVideos: UpdateVideosMutationResponse;
  updateVisualization?: Maybe<VisualizationResponse>;
  updateVisualizationResponses: UpdateVisualizationResponsesMutationResponse;
  updateVisualizations: UpdateVisualizationsMutationResponse;
  updateWaterIntakeSummaries: UpdateWaterIntakeSummariesMutationResponse;
  updateWaterIntakes: UpdateWaterIntakesMutationResponse;
  updateWebhooks: UpdateWebhooksMutationResponse;
  updateWeeklySummaries: UpdateWeeklySummariesMutationResponse;
  updateWhitelistedEmails: UpdateWhitelistedEmailsMutationResponse;
  updateWorkouts: UpdateWorkoutsMutationResponse;
  updateYearlySummaries: UpdateYearlySummariesMutationResponse;
  uploadCourseThumbnail?: Maybe<UploadCourseThumbnailResponse>;
  uploadDocuments?: Maybe<UploadDocumentsResponse>;
  uploadDocumentsToBucket?: Maybe<UploadDocumentsToBucketResponse>;
  uploadFacultyProfileImage?: Maybe<UploadFacultyProfileImageResponse>;
};

export type MutationAddAssignmentArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
};

export type MutationAddContentModuleArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationAddCoursesToDegreeArgs = {
  input: AddCoursesToDegreeInput;
};

export type MutationAddDegreesToCourseArgs = {
  input: AddDegreesToCourseInput;
};

export type MutationAddFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nutritionId?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationAddNutritionArgs = {
  date: Scalars["String"]["input"];
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationAddToWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationAddTodoArgs = {
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAssignCourseToFacultyArgs = {
  courseId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationAssignRoleToUserForTenantArgs = {
  roleId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type MutationAudioToggleUserArgs = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationBulkCreateTenantsArgs = {
  tenants: Array<TenantInput>;
};

export type MutationCareerIsLoadingArgs = {
  isLoading: Scalars["Boolean"]["input"];
};

export type MutationCareerSubmitFormArgs = {
  careerGoal: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  currentOccupation: Scalars["String"]["input"];
};

export type MutationCareerTopicRelationshipArgs = {
  careerName: Scalars["String"]["input"];
  index: Scalars["Int"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationCompleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationCompletePendingInviteArgs = {
  newPassword: Scalars["String"]["input"];
  service: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationCourseCreationArgs = {
  objectives: Array<Scalars["String"]["input"]>;
  standard: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  urls: Array<Scalars["String"]["input"]>;
  weeks: Scalars["Int"]["input"];
};

export type MutationCourseIsLoadingArgs = {
  isLoading: Scalars["Boolean"]["input"];
};

export type MutationCreateAiTutorArgs = {
  aiTutor: CreateAiTutorInput;
};

export type MutationCreateAchievementsArgs = {
  input: Array<AchievementsCreateInput>;
};

export type MutationCreateActiveTracksArgs = {
  input: Array<ActiveTrackCreateInput>;
};

export type MutationCreateActivityAnalyticsArgs = {
  input: Array<ActivityAnalyticsCreateInput>;
};

export type MutationCreateActivityAnalyticsDetailsArgs = {
  input: Array<ActivityAnalyticsDetailsCreateInput>;
};

export type MutationCreateActivityAnalyticsResponsesArgs = {
  input: Array<ActivityAnalyticsResponseCreateInput>;
};

export type MutationCreateActivityCompletionDetailsArgs = {
  input: Array<ActivityCompletionDetailsCreateInput>;
};

export type MutationCreateActivityCompletionDetailsDataArgs = {
  input: Array<ActivityCompletionDetailsDataCreateInput>;
};

export type MutationCreateActivityCompletionDetailsFullsArgs = {
  input: Array<ActivityCompletionDetailsFullCreateInput>;
};

export type MutationCreateActivityCompletionDetailsObjectivesArgs = {
  input: Array<ActivityCompletionDetailsObjectivesCreateInput>;
};

export type MutationCreateActivityCompletionDetailsResponsesArgs = {
  input: Array<ActivityCompletionDetailsResponseCreateInput>;
};

export type MutationCreateActivityInsightsArgs = {
  input: Array<ActivityInsightsCreateInput>;
};

export type MutationCreateActivityInsightsResponsesArgs = {
  input: Array<ActivityInsightsResponseCreateInput>;
};

export type MutationCreateActivityProgressAnalyticsArgs = {
  input: Array<ActivityProgressAnalyticsCreateInput>;
};

export type MutationCreateActivityProgressDetailsArgs = {
  input: Array<ActivityProgressDetailsCreateInput>;
};

export type MutationCreateAddAssignmentResponsesArgs = {
  input: Array<AddAssignmentResponseCreateInput>;
};

export type MutationCreateAddContentModuleResponsesArgs = {
  input: Array<AddContentModuleResponseCreateInput>;
};

export type MutationCreateAiTutorAnalyticsArgs = {
  input: Array<AiTutorAnalyticsCreateInput>;
};

export type MutationCreateAiTutorAnalyticsResponsesArgs = {
  input: Array<AiTutorAnalyticsResponseCreateInput>;
};

export type MutationCreateAiTutorEvalCriteriaResponsesArgs = {
  input: Array<AiTutorEvalCriteriaResponseCreateInput>;
};

export type MutationCreateAiTutorGeneratedResponsesArgs = {
  input: Array<AiTutorGeneratedResponseCreateInput>;
};

export type MutationCreateAiTutorProgressAnalyticsArgs = {
  input: Array<AiTutorProgressAnalyticsCreateInput>;
};

export type MutationCreateAiTutorProgressDetailsArgs = {
  input: Array<AiTutorProgressDetailsCreateInput>;
};

export type MutationCreateAiTutorResponsesArgs = {
  input: Array<AiTutorResponseCreateInput>;
};

export type MutationCreateAiTutorsArgs = {
  input: Array<AiTutorCreateInput>;
};

export type MutationCreateAnotherRelationshipFieldsArgs = {
  input: Array<AnotherRelationshipFieldCreateInput>;
};

export type MutationCreateAspectDetailsTypesArgs = {
  input: Array<AspectDetailsTypeCreateInput>;
};

export type MutationCreateAssessmentListResponseDataArgs = {
  input: Array<AssessmentListResponseDataCreateInput>;
};

export type MutationCreateAssessmentListResponseFormattedsArgs = {
  input: Array<AssessmentListResponseFormattedCreateInput>;
};

export type MutationCreateAssessmentListResponsesArgs = {
  input: Array<AssessmentListResponseCreateInput>;
};

export type MutationCreateAssessmentResponsesArgs = {
  input: Array<AssessmentResponseCreateInput>;
};

export type MutationCreateAssessmentsArgs = {
  input: Array<AssessmentCreateInput>;
};

export type MutationCreateAssignedQuestsArgs = {
  input: Array<AssignedQuestCreateInput>;
};

export type MutationCreateAssignmentArgs = {
  assignment: CreateAssignmentInput;
};

export type MutationCreateAssignmentAndSubmissionResponsesArgs = {
  input: Array<AssignmentAndSubmissionResponseCreateInput>;
};

export type MutationCreateAssignmentAndSubmissionsArgs = {
  input: Array<AssignmentAndSubmissionCreateInput>;
};

export type MutationCreateAssignmentResponsesArgs = {
  input: Array<AssignmentResponseCreateInput>;
};

export type MutationCreateAssignmentsArgs = {
  input: Array<AssignmentCreateInput>;
};

export type MutationCreateAttributesArgs = {
  input: Array<AttributeCreateInput>;
};

export type MutationCreateAudioToggleUserResponsesArgs = {
  input: Array<AudioToggleUserResponseCreateInput>;
};

export type MutationCreateAuthPayloadResponsesArgs = {
  input: Array<AuthPayloadResponseCreateInput>;
};

export type MutationCreateAuthPayloadUserPreferencesArgs = {
  input: Array<AuthPayloadUserPreferencesCreateInput>;
};

export type MutationCreateAuthPayloadUsersArgs = {
  input: Array<AuthPayloadUserCreateInput>;
};

export type MutationCreateAxioResourceArgs = {
  resource: CreateAxioResourceInput;
};

export type MutationCreateAxioResourceResponsesArgs = {
  input: Array<AxioResourceResponseCreateInput>;
};

export type MutationCreateAxioResourcesArgs = {
  input: Array<AxioResourceCreateInput>;
};

export type MutationCreateCareerHeadersArgs = {
  input: Array<CareerHeaderCreateInput>;
};

export type MutationCreateCareerTopicResponsesArgs = {
  input: Array<CareerTopicResponseCreateInput>;
};

export type MutationCreateCareersArgs = {
  input: Array<CareerCreateInput>;
};

export type MutationCreateChallengesArgs = {
  input: Array<ChallengeCreateInput>;
};

export type MutationCreateChaptersArgs = {
  input: Array<ChapterCreateInput>;
};

export type MutationCreateChatAnalyticsArgs = {
  input: Array<ChatAnalyticsCreateInput>;
};

export type MutationCreateChatAttachmentResponsesArgs = {
  input: Array<ChatAttachmentResponseCreateInput>;
};

export type MutationCreateChatResponsesArgs = {
  input: Array<ChatResponseCreateInput>;
};

export type MutationCreateChatsArgs = {
  input: Array<ChatCreateInput>;
};

export type MutationCreateCheckoutSessionArgs = {
  courseId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateChildInfosArgs = {
  input: Array<ChildInfoCreateInput>;
};

export type MutationCreateCompletionActivityAnalyticsArgs = {
  input: Array<CompletionActivityAnalyticsCreateInput>;
};

export type MutationCreateCompletionActivityDetailsArgs = {
  input: Array<CompletionActivityDetailsCreateInput>;
};

export type MutationCreateCompletionAnalyticsArgs = {
  input: Array<CompletionAnalyticsCreateInput>;
};

export type MutationCreateCompletionAnalyticsResponsesArgs = {
  input: Array<CompletionAnalyticsResponseCreateInput>;
};

export type MutationCreateCompletionCourseDetailsArgs = {
  input: Array<CompletionCourseDetailsCreateInput>;
};

export type MutationCreateCompletionModuleAnalyticsArgs = {
  input: Array<CompletionModuleAnalyticsCreateInput>;
};

export type MutationCreateCompletionModuleDetailsArgs = {
  input: Array<CompletionModuleDetailsCreateInput>;
};

export type MutationCreateCompletionObjectiveAnalyticsArgs = {
  input: Array<CompletionObjectiveAnalyticsCreateInput>;
};

export type MutationCreateCompletionStatsArgs = {
  input: Array<CompletionStatsCreateInput>;
};

export type MutationCreateCoreValuesArgs = {
  input: Array<CoreValueCreateInput>;
};

export type MutationCreateCourseArgs = {
  course: CreateCourseInput;
};

export type MutationCreateCourseAnalyticsArgs = {
  input: Array<CourseAnalyticsCreateInput>;
};

export type MutationCreateCourseAnalyticsDetailsArgs = {
  input: Array<CourseAnalyticsDetailsCreateInput>;
};

export type MutationCreateCourseAnalyticsResponsesArgs = {
  input: Array<CourseAnalyticsResponseCreateInput>;
};

export type MutationCreateCourseAnalyticsSummaryResponsesArgs = {
  input: Array<CourseAnalyticsSummaryResponseCreateInput>;
};

export type MutationCreateCourseCategoriesArgs = {
  input: Array<CourseCategoryCreateInput>;
};

export type MutationCreateCourseCompletionDetailsArgs = {
  input: Array<CourseCompletionDetailsCreateInput>;
};

export type MutationCreateCourseCompletionDetailsDataArgs = {
  input: Array<CourseCompletionDetailsDataCreateInput>;
};

export type MutationCreateCourseCompletionDetailsResponsesArgs = {
  input: Array<CourseCompletionDetailsResponseCreateInput>;
};

export type MutationCreateCourseCompletionSummariesArgs = {
  input: Array<CourseCompletionSummaryCreateInput>;
};

export type MutationCreateCourseCompletionSummaryResponsesArgs = {
  input: Array<CourseCompletionSummaryResponseCreateInput>;
};

export type MutationCreateCourseCreationResponsesArgs = {
  input: Array<CourseCreationResponseCreateInput>;
};

export type MutationCreateCourseDetailedAnalyticsArgs = {
  input: Array<CourseDetailedAnalyticsCreateInput>;
};

export type MutationCreateCourseDetailedResponsesArgs = {
  input: Array<CourseDetailedResponseCreateInput>;
};

export type MutationCreateCourseHierarchyResponsesArgs = {
  input: Array<CourseHierarchyResponseCreateInput>;
};

export type MutationCreateCoursePlaceholderArgs = {
  course?: InputMaybe<CreateCoursePlaceholderInput>;
};

export type MutationCreateCourseProgressAnalyticsArgs = {
  input: Array<CourseProgressAnalyticsCreateInput>;
};

export type MutationCreateCourseProgressDetailsArgs = {
  input: Array<CourseProgressDetailsCreateInput>;
};

export type MutationCreateCourseResponsesArgs = {
  input: Array<CourseResponseCreateInput>;
};

export type MutationCreateCourseRosterDataArgs = {
  input: Array<CourseRosterDataCreateInput>;
};

export type MutationCreateCourseRosterServiceResultsArgs = {
  input: Array<CourseRosterServiceResultCreateInput>;
};

export type MutationCreateCourseSummariesArgs = {
  input: Array<CourseSummaryCreateInput>;
};

export type MutationCreateCoursesArgs = {
  input: Array<CourseCreateInput>;
};

export type MutationCreateCoursesByQueryDataArgs = {
  input: Array<CoursesByQueryDataCreateInput>;
};

export type MutationCreateCoursesByQueryResponsesArgs = {
  input: Array<CoursesByQueryResponseCreateInput>;
};

export type MutationCreateCurriculaArgs = {
  input: Array<CurriculumCreateInput>;
};

export type MutationCreateDailyCompletedTodosCountsArgs = {
  input: Array<DailyCompletedTodosCountCreateInput>;
};

export type MutationCreateDailyExerciseTotalsArgs = {
  input: Array<DailyExerciseTotalsCreateInput>;
};

export type MutationCreateDailyNutritionTotalsArgs = {
  input: Array<DailyNutritionTotalsCreateInput>;
};

export type MutationCreateDailySummariesArgs = {
  input: Array<DailySummaryCreateInput>;
};

export type MutationCreateDegreeArgs = {
  degree: CreateDegreeInput;
};

export type MutationCreateDegreeResponsesArgs = {
  input: Array<DegreeResponseCreateInput>;
};

export type MutationCreateDegreesArgs = {
  input: Array<DegreeCreateInput>;
};

export type MutationCreateDegreesBySchoolByQueryDataArgs = {
  input: Array<DegreesBySchoolByQueryDataCreateInput>;
};

export type MutationCreateDegreesBySchoolByQueryResponsesArgs = {
  input: Array<DegreesBySchoolByQueryResponseCreateInput>;
};

export type MutationCreateDeleteContentAssignmentResponsesArgs = {
  input: Array<DeleteContentAssignmentResponseCreateInput>;
};

export type MutationCreateDeleteContentAssignmentVariablesArgs = {
  input: Array<DeleteContentAssignmentVariablesCreateInput>;
};

export type MutationCreateDeleteContentModuleResponsesArgs = {
  input: Array<DeleteContentModuleResponseCreateInput>;
};

export type MutationCreateDeleteContentModuleVariablesArgs = {
  input: Array<DeleteContentModuleVariablesCreateInput>;
};

export type MutationCreateDeleteCourseResponsesArgs = {
  input: Array<DeleteCourseResponseCreateInput>;
};

export type MutationCreateDeviceSessionsArgs = {
  input: Array<DeviceSessionCreateInput>;
};

export type MutationCreateEducationChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type MutationCreateEmotionalStatesArgs = {
  input: Array<EmotionalStateCreateInput>;
};

export type MutationCreateEventsArgs = {
  input: Array<EventCreateInput>;
};

export type MutationCreateExportCsvResponsesArgs = {
  input: Array<ExportCsvResponseCreateInput>;
};

export type MutationCreateFacultyDataCoursesArgs = {
  input: Array<FacultyDataCourseCreateInput>;
};

export type MutationCreateFacultyDataRolesArgs = {
  input: Array<FacultyDataRoleCreateInput>;
};

export type MutationCreateFacultyDataSessionDeviceSessionsArgs = {
  input: Array<FacultyDataSessionDeviceSessionCreateInput>;
};

export type MutationCreateFacultyQueryResponseDataArgs = {
  input: Array<FacultyQueryResponseDataCreateInput>;
};

export type MutationCreateFacultyQueryResponseWithCountDataArgs = {
  input: Array<FacultyQueryResponseWithCountDataCreateInput>;
};

export type MutationCreateFacultyQueryResponsesArgs = {
  input: Array<FacultyQueryResponseCreateInput>;
};

export type MutationCreateFeedbackArgs = {
  context: Scalars["String"]["input"];
  date: Scalars["DateTime"]["input"];
  sentiment: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateFeedbacksArgs = {
  input: Array<FeedbackCreateInput>;
};

export type MutationCreateFieldsArgs = {
  input: Array<FieldCreateInput>;
};

export type MutationCreateFileResponsesArgs = {
  input: Array<FileResponseCreateInput>;
};

export type MutationCreateFileUploadResponsesArgs = {
  input: Array<FileUploadResponseCreateInput>;
};

export type MutationCreateFilesArgs = {
  input: Array<FileCreateInput>;
};

export type MutationCreateFoodItemsArgs = {
  input: Array<FoodItemCreateInput>;
};

export type MutationCreateFoodsArgs = {
  input: Array<FoodCreateInput>;
};

export type MutationCreateFormattedAiTutorsArgs = {
  input: Array<FormattedAiTutorCreateInput>;
};

export type MutationCreateFormattedChatsArgs = {
  input: Array<FormattedChatCreateInput>;
};

export type MutationCreateFormattedObjectivesArgs = {
  input: Array<FormattedObjectiveCreateInput>;
};

export type MutationCreateFormattedVisualizationsArgs = {
  input: Array<FormattedVisualizationCreateInput>;
};

export type MutationCreateGamesArgs = {
  input: Array<GameCreateInput>;
};

export type MutationCreateGenerateCourseContentDataArgs = {
  input: Array<GenerateCourseContentDataCreateInput>;
};

export type MutationCreateGenerateCourseContentResponsesArgs = {
  input: Array<GenerateCourseContentResponseCreateInput>;
};

export type MutationCreateGeneratedAiTutorsArgs = {
  input: Array<GeneratedAiTutorCreateInput>;
};

export type MutationCreateGeneratedObjectivesArgs = {
  input: Array<GeneratedObjectiveCreateInput>;
};

export type MutationCreateGeneratedsArgs = {
  input: Array<GeneratedCreateInput>;
};

export type MutationCreateGetAssignmentObjectiveResponsesArgs = {
  input: Array<GetAssignmentObjectiveResponseCreateInput>;
};

export type MutationCreateGetAssignmentResponsesArgs = {
  input: Array<GetAssignmentResponseCreateInput>;
};

export type MutationCreateGetJourneyByIdResponsesArgs = {
  input: Array<GetJourneyByIdResponseCreateInput>;
};

export type MutationCreateGetJourneysResponsesArgs = {
  input: Array<GetJourneysResponseCreateInput>;
};

export type MutationCreateGoalArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  deadline: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  priority: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateGoalsArgs = {
  input: Array<GoalCreateInput>;
};

export type MutationCreateGoogleAuthTokensArgs = {
  input: Array<GoogleAuthTokensCreateInput>;
};

export type MutationCreateHierarchiesArgs = {
  input: Array<HierarchyCreateInput>;
};

export type MutationCreateHierarchyActivitiesArgs = {
  input: Array<HierarchyActivityCreateInput>;
};

export type MutationCreateHierarchyActivityDetailsArgs = {
  input: Array<HierarchyActivityDetailsCreateInput>;
};

export type MutationCreateHierarchyCourseDetailsArgs = {
  input: Array<HierarchyCourseDetailsCreateInput>;
};

export type MutationCreateHierarchyCoursesArgs = {
  input: Array<HierarchyCourseCreateInput>;
};

export type MutationCreateHierarchyModuleDetailsArgs = {
  input: Array<HierarchyModuleDetailsCreateInput>;
};

export type MutationCreateHierarchyModulesArgs = {
  input: Array<HierarchyModuleCreateInput>;
};

export type MutationCreateHierarchyNodesArgs = {
  input: Array<HierarchyNodeCreateInput>;
};

export type MutationCreateHierarchyObjectivesArgs = {
  input: Array<HierarchyObjectiveCreateInput>;
};

export type MutationCreateHobbiesArgs = {
  input: Array<HobbyCreateInput>;
};

export type MutationCreateInterestArgs = {
  category: Scalars["String"]["input"];
  lastUpdated: Scalars["DateTime"]["input"];
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  user: UserInput;
};

export type MutationCreateInterestsArgs = {
  input: Array<InterestCreateInput>;
};

export type MutationCreateInviteCountResponsesArgs = {
  input: Array<InviteCountResponseCreateInput>;
};

export type MutationCreateInviteUsersToEnrollInCourseResponsesArgs = {
  input: Array<InviteUsersToEnrollInCourseResponseCreateInput>;
};

export type MutationCreateInviteUsersToEnrollInDegreeResponsesArgs = {
  input: Array<InviteUsersToEnrollInDegreeResponseCreateInput>;
};

export type MutationCreateInvitedUsersArgs = {
  input: Array<InvitedUsersCreateInput>;
};

export type MutationCreateJourneysArgs = {
  input: Array<JourneyCreateInput>;
};

export type MutationCreateLearningCheckoutSessionsArgs = {
  input: Array<LearningCheckoutSessionCreateInput>;
};

export type MutationCreateLearningNodesArgs = {
  input: Array<LearningNodeCreateInput>;
};

export type MutationCreateLearningObjectiveResponsesArgs = {
  input: Array<LearningObjectiveResponseCreateInput>;
};

export type MutationCreateLearningObjectivesArgs = {
  input: Array<LearningObjectiveCreateInput>;
};

export type MutationCreateLearningPaymentsArgs = {
  input: Array<LearningPaymentCreateInput>;
};

export type MutationCreateLearningSummariesArgs = {
  input: Array<LearningSummaryCreateInput>;
};

export type MutationCreateLearningTreesArgs = {
  input: Array<LearningTreeCreateInput>;
};

export type MutationCreateLessonArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  difficultyLevel: Scalars["String"]["input"];
  endDate: Scalars["DateTime"]["input"];
  format: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  user: UserInput;
};

export type MutationCreateLessonsArgs = {
  input: Array<LessonCreateInput>;
};

export type MutationCreateLevelDetailsArgs = {
  input: Array<LevelDetailsCreateInput>;
};

export type MutationCreateLifeAspirationsArgs = {
  input: Array<LifeAspirationCreateInput>;
};

export type MutationCreateMasteryResultsArgs = {
  input: Array<MasteryResultCreateInput>;
};

export type MutationCreateMeResponsesArgs = {
  input: Array<MeResponseCreateInput>;
};

export type MutationCreateMessageResponsesArgs = {
  input: Array<MessageResponseCreateInput>;
};

export type MutationCreateMessagesArgs = {
  input: Array<MessageCreateInput>;
};

export type MutationCreateModuleArgs = {
  module: CreateModuleInput;
};

export type MutationCreateModuleAnalyticsArgs = {
  input: Array<ModuleAnalyticsCreateInput>;
};

export type MutationCreateModuleAnalyticsDetailsArgs = {
  input: Array<ModuleAnalyticsDetailsCreateInput>;
};

export type MutationCreateModuleAnalyticsResponsesArgs = {
  input: Array<ModuleAnalyticsResponseCreateInput>;
};

export type MutationCreateModuleCompletionDetailsArgs = {
  input: Array<ModuleCompletionDetailsCreateInput>;
};

export type MutationCreateModuleCompletionDetailsActivitiesArgs = {
  input: Array<ModuleCompletionDetailsActivitiesCreateInput>;
};

export type MutationCreateModuleCompletionDetailsDataArgs = {
  input: Array<ModuleCompletionDetailsDataCreateInput>;
};

export type MutationCreateModuleCompletionDetailsFullsArgs = {
  input: Array<ModuleCompletionDetailsFullCreateInput>;
};

export type MutationCreateModuleCompletionDetailsResponsesArgs = {
  input: Array<ModuleCompletionDetailsResponseCreateInput>;
};

export type MutationCreateModuleProgressAnalyticsArgs = {
  input: Array<ModuleProgressAnalyticsCreateInput>;
};

export type MutationCreateModuleProgressDetailsArgs = {
  input: Array<ModuleProgressDetailsCreateInput>;
};

export type MutationCreateModuleResponsesArgs = {
  input: Array<ModuleResponseCreateInput>;
};

export type MutationCreateModulesArgs = {
  input: Array<ModuleCreateInput>;
};

export type MutationCreateMonthlySummariesArgs = {
  input: Array<MonthlySummaryCreateInput>;
};

export type MutationCreateNodeIdentitiesArgs = {
  input: Array<NodeIdentityCreateInput>;
};

export type MutationCreateNodePropertiesArgs = {
  input: Array<NodePropertiesCreateInput>;
};

export type MutationCreateNutritionsArgs = {
  input: Array<NutritionCreateInput>;
};

export type MutationCreateOAuthAccessTokensArgs = {
  input: Array<OAuthAccessTokenCreateInput>;
};

export type MutationCreateOAuthClientsArgs = {
  input: Array<OAuthClientCreateInput>;
};

export type MutationCreateObjectiveAnalyticsArgs = {
  input: Array<ObjectiveAnalyticsCreateInput>;
};

export type MutationCreateObjectiveAnalyticsResponsesArgs = {
  input: Array<ObjectiveAnalyticsResponseCreateInput>;
};

export type MutationCreateObjectiveCompletionDetailsArgs = {
  input: Array<ObjectiveCompletionDetailsCreateInput>;
};

export type MutationCreateObjectiveCompletionDetailsFullsArgs = {
  input: Array<ObjectiveCompletionDetailsFullCreateInput>;
};

export type MutationCreateObjectiveCompletionDetailsResponsesArgs = {
  input: Array<ObjectiveCompletionDetailsResponseCreateInput>;
};

export type MutationCreateObjectiveProgressAnalyticsArgs = {
  input: Array<ObjectiveProgressAnalyticsCreateInput>;
};

export type MutationCreateObjectivesArgs = {
  input: Array<ObjectiveCreateInput>;
};

export type MutationCreatePageArgs = {
  page: CreatePageInput;
};

export type MutationCreatePageResponsesArgs = {
  input: Array<PageResponseCreateInput>;
};

export type MutationCreatePagesArgs = {
  input: Array<PageCreateInput>;
};

export type MutationCreateParametersTypesArgs = {
  input: Array<ParametersTypeCreateInput>;
};

export type MutationCreateParentRefsArgs = {
  input: Array<ParentRefCreateInput>;
};

export type MutationCreatePaymentsArgs = {
  input: Array<PaymentCreateInput>;
};

export type MutationCreatePermissionsArgs = {
  input: Array<PermissionCreateInput>;
};

export type MutationCreatePersonalityTraitsArgs = {
  input: Array<PersonalityTraitCreateInput>;
};

export type MutationCreatePreferenceArgs = {
  contentType: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  learningDuration: Scalars["Int"]["input"];
  learningStyle: Scalars["String"]["input"];
  notificationPreferences: Scalars["String"]["input"];
  timeOfDay: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreatePreferencesArgs = {
  input: Array<PreferencesCreateInput>;
};

export type MutationCreatePrerequisitesArgs = {
  input: Array<PrerequisiteCreateInput>;
};

export type MutationCreateProgressArgs = {
  date: Scalars["DateTime"]["input"];
  description: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateProgressDetailsArgs = {
  input: Array<ProgressDetailsCreateInput>;
};

export type MutationCreateProgressesArgs = {
  input: Array<ProgressCreateInput>;
};

export type MutationCreateQuarterlySummariesArgs = {
  input: Array<QuarterlySummaryCreateInput>;
};

export type MutationCreateRateLimitResultsArgs = {
  input: Array<RateLimitResultCreateInput>;
};

export type MutationCreateRecentCategoriesArgs = {
  input: Array<RecentCategoryCreateInput>;
};

export type MutationCreateRecentTracksArgs = {
  input: Array<RecentTrackCreateInput>;
};

export type MutationCreateRecomendedsArgs = {
  input: Array<RecomendedCreateInput>;
};

export type MutationCreateReflectionsArgs = {
  input: Array<ReflectionCreateInput>;
};

export type MutationCreateRefreshQuestResponsesArgs = {
  input: Array<RefreshQuestResponseCreateInput>;
};

export type MutationCreateRelationshipFieldsArgs = {
  input: Array<RelationshipFieldCreateInput>;
};

export type MutationCreateResponseTypesArgs = {
  input: Array<ResponseTypeCreateInput>;
};

export type MutationCreateRolesArgs = {
  input: Array<RoleCreateInput>;
};

export type MutationCreateSchoolsArgs = {
  input: Array<SchoolCreateInput>;
};

export type MutationCreateSessionResponsesArgs = {
  input: Array<SessionResponseCreateInput>;
};

export type MutationCreateStudentActivityProgressDataArgs = {
  input: Array<StudentActivityProgressDataCreateInput>;
};

export type MutationCreateStudentActivityProgressResponsesArgs = {
  input: Array<StudentActivityProgressResponseCreateInput>;
};

export type MutationCreateStudentAnalyticsArgs = {
  input: Array<StudentAnalyticsCreateInput>;
};

export type MutationCreateStudentAnalyticsDetailsArgs = {
  input: Array<StudentAnalyticsDetailsCreateInput>;
};

export type MutationCreateStudentAnalyticsResponsesArgs = {
  input: Array<StudentAnalyticsResponseCreateInput>;
};

export type MutationCreateStudentAnalyticsSummaryResponsesArgs = {
  input: Array<StudentAnalyticsSummaryResponseCreateInput>;
};

export type MutationCreateStudentChatAnalyticsArgs = {
  input: Array<StudentChatAnalyticsCreateInput>;
};

export type MutationCreateStudentChatAnalyticsResponsesArgs = {
  input: Array<StudentChatAnalyticsResponseCreateInput>;
};

export type MutationCreateStudentCourseProgressResponsesArgs = {
  input: Array<StudentCourseProgressResponseCreateInput>;
};

export type MutationCreateStudentDetailedAnalyticsArgs = {
  input: Array<StudentDetailedAnalyticsCreateInput>;
};

export type MutationCreateStudentDetailedAnalyticsResponsesArgs = {
  input: Array<StudentDetailedAnalyticsResponseCreateInput>;
};

export type MutationCreateStudentLearningInsightsArgs = {
  input: Array<StudentLearningInsightsCreateInput>;
};

export type MutationCreateStudentLearningInsightsResponsesArgs = {
  input: Array<StudentLearningInsightsResponseCreateInput>;
};

export type MutationCreateStudentModuleProgressDataArgs = {
  input: Array<StudentModuleProgressDataCreateInput>;
};

export type MutationCreateStudentModuleProgressResponsesArgs = {
  input: Array<StudentModuleProgressResponseCreateInput>;
};

export type MutationCreateStudentSummariesArgs = {
  input: Array<StudentSummaryCreateInput>;
};

export type MutationCreateSubchapterDetailsArgs = {
  input: Array<SubchapterDetailsCreateInput>;
};

export type MutationCreateSubchaptersArgs = {
  input: Array<SubchapterCreateInput>;
};

export type MutationCreateSubfieldsArgs = {
  input: Array<SubfieldCreateInput>;
};

export type MutationCreateSubmissionListResponseDataArgs = {
  input: Array<SubmissionListResponseDataCreateInput>;
};

export type MutationCreateSubmissionListResponseFormattedsArgs = {
  input: Array<SubmissionListResponseFormattedCreateInput>;
};

export type MutationCreateSubmissionListResponsesArgs = {
  input: Array<SubmissionListResponseCreateInput>;
};

export type MutationCreateSubmissionResponsesArgs = {
  input: Array<SubmissionResponseCreateInput>;
};

export type MutationCreateSubmissionsArgs = {
  input: Array<SubmissionCreateInput>;
};

export type MutationCreateSuggestVideoInsightsResponsesArgs = {
  input: Array<SuggestVideoInsightsResponseCreateInput>;
};

export type MutationCreateSuggestionsArgs = {
  input: Array<SuggestionCreateInput>;
};

export type MutationCreateTenantArgs = {
  tenant: TenantInput;
};

export type MutationCreateTenantResponsesArgs = {
  input: Array<TenantResponseCreateInput>;
};

export type MutationCreateTenantSchemaResponsesArgs = {
  input: Array<TenantSchemaResponseCreateInput>;
};

export type MutationCreateTenantsArgs = {
  input: Array<TenantCreateInput>;
};

export type MutationCreateTestingsArgs = {
  input: Array<TestingCreateInput>;
};

export type MutationCreateTodosArgs = {
  input: Array<TodoCreateInput>;
};

export type MutationCreateTopicsArgs = {
  input: Array<TopicCreateInput>;
};

export type MutationCreateTrackOverviewsArgs = {
  input: Array<TrackOverviewCreateInput>;
};

export type MutationCreateTransactionByDatesArgs = {
  input: Array<TransactionByDateCreateInput>;
};

export type MutationCreateTransactionsArgs = {
  input: Array<TransactionCreateInput>;
};

export type MutationCreateTranscriptSegmentsArgs = {
  input: Array<TranscriptSegmentCreateInput>;
};

export type MutationCreateUpdateAssignmentResponsesArgs = {
  input: Array<UpdateAssignmentResponseCreateInput>;
};

export type MutationCreateUpdateContentModuleNameResponsesArgs = {
  input: Array<UpdateContentModuleNameResponseCreateInput>;
};

export type MutationCreateUpdateUserModalOnboardingResponsesArgs = {
  input: Array<UpdateUserModalOnboardingResponseCreateInput>;
};

export type MutationCreateUploadCourseThumbnailResponsesArgs = {
  input: Array<UploadCourseThumbnailResponseCreateInput>;
};

export type MutationCreateUploadDocumentsResponsesArgs = {
  input: Array<UploadDocumentsResponseCreateInput>;
};

export type MutationCreateUploadDocumentsToBucketResponsesArgs = {
  input: Array<UploadDocumentsToBucketResponseCreateInput>;
};

export type MutationCreateUploadFacultyProfileImageResponsesArgs = {
  input: Array<UploadFacultyProfileImageResponseCreateInput>;
};

export type MutationCreateUserCareerLoadingResponsesArgs = {
  input: Array<UserCareerLoadingResponseCreateInput>;
};

export type MutationCreateUserCourseLoadingResponsesArgs = {
  input: Array<UserCourseLoadingResponseCreateInput>;
};

export type MutationCreateUserMasteriesArgs = {
  input: Array<UserMasteryCreateInput>;
};

export type MutationCreateUserProgressesArgs = {
  input: Array<UserProgressCreateInput>;
};

export type MutationCreateUsersArgs = {
  input: Array<UserCreateInput>;
};

export type MutationCreateValidityResponsesArgs = {
  input: Array<ValidityResponseCreateInput>;
};

export type MutationCreateVideoPoolArgs = {
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveForVideoInput>;
  title: Scalars["String"]["input"];
};

export type MutationCreateVideoPoolItemsArgs = {
  input: Array<VideoPoolItemCreateInput>;
};

export type MutationCreateVideoServiceResultsArgs = {
  input: Array<VideoServiceResultCreateInput>;
};

export type MutationCreateVideosArgs = {
  input: Array<VideoCreateInput>;
};

export type MutationCreateVisualizationFromContentArgs = {
  content: VisualizationContentInput;
};

export type MutationCreateVisualizationResponsesArgs = {
  input: Array<VisualizationResponseCreateInput>;
};

export type MutationCreateVisualizationsArgs = {
  input: Array<VisualizationCreateInput>;
};

export type MutationCreateWaterIntakeSummariesArgs = {
  input: Array<WaterIntakeSummaryCreateInput>;
};

export type MutationCreateWaterIntakesArgs = {
  input: Array<WaterIntakeCreateInput>;
};

export type MutationCreateWebhooksArgs = {
  input: Array<WebhookCreateInput>;
};

export type MutationCreateWeeklySummariesArgs = {
  input: Array<WeeklySummaryCreateInput>;
};

export type MutationCreateWhitelistedEmailsArgs = {
  input: Array<WhitelistedEmailCreateInput>;
};

export type MutationCreateWorkoutsArgs = {
  input: Array<WorkoutCreateInput>;
};

export type MutationCreateYearlySummariesArgs = {
  input: Array<YearlySummaryCreateInput>;
};

export type MutationDeleteAiTutorArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAchievementsArgs = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<AchievementsWhere>;
};

export type MutationDeleteActiveTracksArgs = {
  where?: InputMaybe<ActiveTrackWhere>;
};

export type MutationDeleteActivityAnalyticsArgs = {
  where?: InputMaybe<ActivityAnalyticsWhere>;
};

export type MutationDeleteActivityAnalyticsDetailsArgs = {
  where?: InputMaybe<ActivityAnalyticsDetailsWhere>;
};

export type MutationDeleteActivityAnalyticsResponsesArgs = {
  where?: InputMaybe<ActivityAnalyticsResponseWhere>;
};

export type MutationDeleteActivityCompletionDetailsArgs = {
  where?: InputMaybe<ActivityCompletionDetailsWhere>;
};

export type MutationDeleteActivityCompletionDetailsDataArgs = {
  where?: InputMaybe<ActivityCompletionDetailsDataWhere>;
};

export type MutationDeleteActivityCompletionDetailsFullsArgs = {
  where?: InputMaybe<ActivityCompletionDetailsFullWhere>;
};

export type MutationDeleteActivityCompletionDetailsObjectivesArgs = {
  where?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
};

export type MutationDeleteActivityCompletionDetailsResponsesArgs = {
  where?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
};

export type MutationDeleteActivityInsightsArgs = {
  where?: InputMaybe<ActivityInsightsWhere>;
};

export type MutationDeleteActivityInsightsResponsesArgs = {
  where?: InputMaybe<ActivityInsightsResponseWhere>;
};

export type MutationDeleteActivityProgressAnalyticsArgs = {
  where?: InputMaybe<ActivityProgressAnalyticsWhere>;
};

export type MutationDeleteActivityProgressDetailsArgs = {
  where?: InputMaybe<ActivityProgressDetailsWhere>;
};

export type MutationDeleteAddAssignmentResponsesArgs = {
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type MutationDeleteAddContentModuleResponsesArgs = {
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type MutationDeleteAiTutorAnalyticsArgs = {
  where?: InputMaybe<AiTutorAnalyticsWhere>;
};

export type MutationDeleteAiTutorAnalyticsResponsesArgs = {
  where?: InputMaybe<AiTutorAnalyticsResponseWhere>;
};

export type MutationDeleteAiTutorEvalCriteriaResponsesArgs = {
  where?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
};

export type MutationDeleteAiTutorGeneratedResponsesArgs = {
  where?: InputMaybe<AiTutorGeneratedResponseWhere>;
};

export type MutationDeleteAiTutorProgressAnalyticsArgs = {
  where?: InputMaybe<AiTutorProgressAnalyticsWhere>;
};

export type MutationDeleteAiTutorProgressDetailsArgs = {
  where?: InputMaybe<AiTutorProgressDetailsWhere>;
};

export type MutationDeleteAiTutorResponsesArgs = {
  where?: InputMaybe<AiTutorResponseWhere>;
};

export type MutationDeleteAiTutorsArgs = {
  delete?: InputMaybe<AiTutorDeleteInput>;
  where?: InputMaybe<AiTutorWhere>;
};

export type MutationDeleteAnotherRelationshipFieldsArgs = {
  delete?: InputMaybe<AnotherRelationshipFieldDeleteInput>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type MutationDeleteAspectArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type MutationDeleteAspectDetailsTypesArgs = {
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type MutationDeleteAssessmentListResponseDataArgs = {
  where?: InputMaybe<AssessmentListResponseDataWhere>;
};

export type MutationDeleteAssessmentListResponseFormattedsArgs = {
  where?: InputMaybe<AssessmentListResponseFormattedWhere>;
};

export type MutationDeleteAssessmentListResponsesArgs = {
  where?: InputMaybe<AssessmentListResponseWhere>;
};

export type MutationDeleteAssessmentResponsesArgs = {
  where?: InputMaybe<AssessmentResponseWhere>;
};

export type MutationDeleteAssessmentsArgs = {
  delete?: InputMaybe<AssessmentDeleteInput>;
  where?: InputMaybe<AssessmentWhere>;
};

export type MutationDeleteAssignedQuestsArgs = {
  where?: InputMaybe<AssignedQuestWhere>;
};

export type MutationDeleteAssignmentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAssignmentAndSubmissionResponsesArgs = {
  where?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
};

export type MutationDeleteAssignmentAndSubmissionsArgs = {
  where?: InputMaybe<AssignmentAndSubmissionWhere>;
};

export type MutationDeleteAssignmentResponsesArgs = {
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type MutationDeleteAssignmentsArgs = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<AssignmentWhere>;
};

export type MutationDeleteAttributesArgs = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<AttributeWhere>;
};

export type MutationDeleteAudioToggleUserResponsesArgs = {
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type MutationDeleteAuthPayloadResponsesArgs = {
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type MutationDeleteAuthPayloadUserPreferencesArgs = {
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type MutationDeleteAuthPayloadUsersArgs = {
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type MutationDeleteAxioResourceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAxioResourceResponsesArgs = {
  where?: InputMaybe<AxioResourceResponseWhere>;
};

export type MutationDeleteAxioResourcesArgs = {
  delete?: InputMaybe<AxioResourceDeleteInput>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type MutationDeleteCareerHeadersArgs = {
  where?: InputMaybe<CareerHeaderWhere>;
};

export type MutationDeleteCareerTopicResponsesArgs = {
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type MutationDeleteCareersArgs = {
  where?: InputMaybe<CareerWhere>;
};

export type MutationDeleteChallengesArgs = {
  where?: InputMaybe<ChallengeWhere>;
};

export type MutationDeleteChaptersArgs = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<ChapterWhere>;
};

export type MutationDeleteChatAnalyticsArgs = {
  where?: InputMaybe<ChatAnalyticsWhere>;
};

export type MutationDeleteChatAttachmentResponsesArgs = {
  where?: InputMaybe<ChatAttachmentResponseWhere>;
};

export type MutationDeleteChatResponsesArgs = {
  delete?: InputMaybe<ChatResponseDeleteInput>;
  where?: InputMaybe<ChatResponseWhere>;
};

export type MutationDeleteChatsArgs = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<ChatWhere>;
};

export type MutationDeleteChildInfosArgs = {
  where?: InputMaybe<ChildInfoWhere>;
};

export type MutationDeleteCompletionActivityAnalyticsArgs = {
  where?: InputMaybe<CompletionActivityAnalyticsWhere>;
};

export type MutationDeleteCompletionActivityDetailsArgs = {
  where?: InputMaybe<CompletionActivityDetailsWhere>;
};

export type MutationDeleteCompletionAnalyticsArgs = {
  where?: InputMaybe<CompletionAnalyticsWhere>;
};

export type MutationDeleteCompletionAnalyticsResponsesArgs = {
  where?: InputMaybe<CompletionAnalyticsResponseWhere>;
};

export type MutationDeleteCompletionCourseDetailsArgs = {
  where?: InputMaybe<CompletionCourseDetailsWhere>;
};

export type MutationDeleteCompletionModuleAnalyticsArgs = {
  where?: InputMaybe<CompletionModuleAnalyticsWhere>;
};

export type MutationDeleteCompletionModuleDetailsArgs = {
  where?: InputMaybe<CompletionModuleDetailsWhere>;
};

export type MutationDeleteCompletionObjectiveAnalyticsArgs = {
  where?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
};

export type MutationDeleteCompletionStatsArgs = {
  where?: InputMaybe<CompletionStatsWhere>;
};

export type MutationDeleteContentAssignmentArgs = {
  assignmentName: Scalars["String"]["input"];
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationDeleteContentModuleArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationDeleteCoreValuesArgs = {
  where?: InputMaybe<CoreValueWhere>;
};

export type MutationDeleteCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type MutationDeleteCourseAnalyticsArgs = {
  where?: InputMaybe<CourseAnalyticsWhere>;
};

export type MutationDeleteCourseAnalyticsDetailsArgs = {
  where?: InputMaybe<CourseAnalyticsDetailsWhere>;
};

export type MutationDeleteCourseAnalyticsResponsesArgs = {
  where?: InputMaybe<CourseAnalyticsResponseWhere>;
};

export type MutationDeleteCourseAnalyticsSummaryResponsesArgs = {
  where?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
};

export type MutationDeleteCourseCascadeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCourseCategoriesArgs = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type MutationDeleteCourseCompletionDetailsArgs = {
  where?: InputMaybe<CourseCompletionDetailsWhere>;
};

export type MutationDeleteCourseCompletionDetailsDataArgs = {
  where?: InputMaybe<CourseCompletionDetailsDataWhere>;
};

export type MutationDeleteCourseCompletionDetailsResponsesArgs = {
  where?: InputMaybe<CourseCompletionDetailsResponseWhere>;
};

export type MutationDeleteCourseCompletionSummariesArgs = {
  where?: InputMaybe<CourseCompletionSummaryWhere>;
};

export type MutationDeleteCourseCompletionSummaryResponsesArgs = {
  where?: InputMaybe<CourseCompletionSummaryResponseWhere>;
};

export type MutationDeleteCourseCreationResponsesArgs = {
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type MutationDeleteCourseDetailedAnalyticsArgs = {
  where?: InputMaybe<CourseDetailedAnalyticsWhere>;
};

export type MutationDeleteCourseDetailedResponsesArgs = {
  where?: InputMaybe<CourseDetailedResponseWhere>;
};

export type MutationDeleteCourseHierarchyResponsesArgs = {
  where?: InputMaybe<CourseHierarchyResponseWhere>;
};

export type MutationDeleteCourseProgressAnalyticsArgs = {
  where?: InputMaybe<CourseProgressAnalyticsWhere>;
};

export type MutationDeleteCourseProgressDetailsArgs = {
  where?: InputMaybe<CourseProgressDetailsWhere>;
};

export type MutationDeleteCourseResponsesArgs = {
  where?: InputMaybe<CourseResponseWhere>;
};

export type MutationDeleteCourseRosterDataArgs = {
  where?: InputMaybe<CourseRosterDataWhere>;
};

export type MutationDeleteCourseRosterServiceResultsArgs = {
  where?: InputMaybe<CourseRosterServiceResultWhere>;
};

export type MutationDeleteCourseSummariesArgs = {
  where?: InputMaybe<CourseSummaryWhere>;
};

export type MutationDeleteCoursesArgs = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<CourseWhere>;
};

export type MutationDeleteCoursesByQueryDataArgs = {
  where?: InputMaybe<CoursesByQueryDataWhere>;
};

export type MutationDeleteCoursesByQueryResponsesArgs = {
  where?: InputMaybe<CoursesByQueryResponseWhere>;
};

export type MutationDeleteCurriculaArgs = {
  where?: InputMaybe<CurriculumWhere>;
};

export type MutationDeleteDailyCompletedTodosCountsArgs = {
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type MutationDeleteDailyExerciseTotalsArgs = {
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type MutationDeleteDailyNutritionTotalsArgs = {
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type MutationDeleteDailySummariesArgs = {
  delete?: InputMaybe<DailySummaryDeleteInput>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type MutationDeleteDegreeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDegreeResponsesArgs = {
  where?: InputMaybe<DegreeResponseWhere>;
};

export type MutationDeleteDegreesArgs = {
  delete?: InputMaybe<DegreeDeleteInput>;
  where?: InputMaybe<DegreeWhere>;
};

export type MutationDeleteDegreesBySchoolByQueryDataArgs = {
  where?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
};

export type MutationDeleteDegreesBySchoolByQueryResponsesArgs = {
  where?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
};

export type MutationDeleteDeleteContentAssignmentResponsesArgs = {
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type MutationDeleteDeleteContentAssignmentVariablesArgs = {
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type MutationDeleteDeleteContentModuleResponsesArgs = {
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type MutationDeleteDeleteContentModuleVariablesArgs = {
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type MutationDeleteDeleteCourseResponsesArgs = {
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type MutationDeleteDeviceSessionsArgs = {
  delete?: InputMaybe<DeviceSessionDeleteInput>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type MutationDeleteEmotionalStatesArgs = {
  where?: InputMaybe<EmotionalStateWhere>;
};

export type MutationDeleteEventsArgs = {
  where?: InputMaybe<EventWhere>;
};

export type MutationDeleteExportCsvResponsesArgs = {
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type MutationDeleteFacultyDataCoursesArgs = {
  where?: InputMaybe<FacultyDataCourseWhere>;
};

export type MutationDeleteFacultyDataRolesArgs = {
  where?: InputMaybe<FacultyDataRoleWhere>;
};

export type MutationDeleteFacultyDataSessionDeviceSessionsArgs = {
  where?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
};

export type MutationDeleteFacultyQueryResponseDataArgs = {
  where?: InputMaybe<FacultyQueryResponseDataWhere>;
};

export type MutationDeleteFacultyQueryResponseWithCountDataArgs = {
  where?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
};

export type MutationDeleteFacultyQueryResponsesArgs = {
  where?: InputMaybe<FacultyQueryResponseWhere>;
};

export type MutationDeleteFeedbacksArgs = {
  where?: InputMaybe<FeedbackWhere>;
};

export type MutationDeleteFieldsArgs = {
  where?: InputMaybe<FieldWhere>;
};

export type MutationDeleteFileResponsesArgs = {
  where?: InputMaybe<FileResponseWhere>;
};

export type MutationDeleteFileUploadResponsesArgs = {
  where?: InputMaybe<FileUploadResponseWhere>;
};

export type MutationDeleteFilesArgs = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<FileWhere>;
};

export type MutationDeleteFoodArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteFoodItemsArgs = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<FoodItemWhere>;
};

export type MutationDeleteFoodsArgs = {
  where?: InputMaybe<FoodWhere>;
};

export type MutationDeleteFormattedAiTutorsArgs = {
  where?: InputMaybe<FormattedAiTutorWhere>;
};

export type MutationDeleteFormattedChatsArgs = {
  where?: InputMaybe<FormattedChatWhere>;
};

export type MutationDeleteFormattedObjectivesArgs = {
  where?: InputMaybe<FormattedObjectiveWhere>;
};

export type MutationDeleteFormattedVisualizationsArgs = {
  where?: InputMaybe<FormattedVisualizationWhere>;
};

export type MutationDeleteGamesArgs = {
  where?: InputMaybe<GameWhere>;
};

export type MutationDeleteGenerateCourseContentDataArgs = {
  where?: InputMaybe<GenerateCourseContentDataWhere>;
};

export type MutationDeleteGenerateCourseContentResponsesArgs = {
  where?: InputMaybe<GenerateCourseContentResponseWhere>;
};

export type MutationDeleteGeneratedAiTutorsArgs = {
  where?: InputMaybe<GeneratedAiTutorWhere>;
};

export type MutationDeleteGeneratedObjectivesArgs = {
  where?: InputMaybe<GeneratedObjectiveWhere>;
};

export type MutationDeleteGeneratedsArgs = {
  where?: InputMaybe<GeneratedWhere>;
};

export type MutationDeleteGetAssignmentObjectiveResponsesArgs = {
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type MutationDeleteGetAssignmentResponsesArgs = {
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type MutationDeleteGetJourneyByIdResponsesArgs = {
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type MutationDeleteGetJourneysResponsesArgs = {
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type MutationDeleteGoalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGoalsArgs = {
  where?: InputMaybe<GoalWhere>;
};

export type MutationDeleteGoogleAuthTokensArgs = {
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type MutationDeleteHierarchiesArgs = {
  where?: InputMaybe<HierarchyWhere>;
};

export type MutationDeleteHierarchyActivitiesArgs = {
  where?: InputMaybe<HierarchyActivityWhere>;
};

export type MutationDeleteHierarchyActivityDetailsArgs = {
  where?: InputMaybe<HierarchyActivityDetailsWhere>;
};

export type MutationDeleteHierarchyCourseDetailsArgs = {
  where?: InputMaybe<HierarchyCourseDetailsWhere>;
};

export type MutationDeleteHierarchyCoursesArgs = {
  where?: InputMaybe<HierarchyCourseWhere>;
};

export type MutationDeleteHierarchyModuleDetailsArgs = {
  where?: InputMaybe<HierarchyModuleDetailsWhere>;
};

export type MutationDeleteHierarchyModulesArgs = {
  where?: InputMaybe<HierarchyModuleWhere>;
};

export type MutationDeleteHierarchyNodesArgs = {
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type MutationDeleteHierarchyObjectivesArgs = {
  where?: InputMaybe<HierarchyObjectiveWhere>;
};

export type MutationDeleteHobbiesArgs = {
  where?: InputMaybe<HobbyWhere>;
};

export type MutationDeleteInterestArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInterestsArgs = {
  where?: InputMaybe<InterestWhere>;
};

export type MutationDeleteInviteCountResponsesArgs = {
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type MutationDeleteInviteUsersToEnrollInCourseResponsesArgs = {
  where?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
};

export type MutationDeleteInviteUsersToEnrollInDegreeResponsesArgs = {
  where?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
};

export type MutationDeleteInvitedUsersArgs = {
  where?: InputMaybe<InvitedUsersWhere>;
};

export type MutationDeleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationDeleteJourneysArgs = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<JourneyWhere>;
};

export type MutationDeleteLearningCheckoutSessionsArgs = {
  where?: InputMaybe<LearningCheckoutSessionWhere>;
};

export type MutationDeleteLearningNodesArgs = {
  where?: InputMaybe<LearningNodeWhere>;
};

export type MutationDeleteLearningObjectiveResponsesArgs = {
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type MutationDeleteLearningObjectivesArgs = {
  delete?: InputMaybe<LearningObjectiveDeleteInput>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type MutationDeleteLearningPaymentsArgs = {
  delete?: InputMaybe<LearningPaymentDeleteInput>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type MutationDeleteLearningSummariesArgs = {
  where?: InputMaybe<LearningSummaryWhere>;
};

export type MutationDeleteLearningTreesArgs = {
  where?: InputMaybe<LearningTreeWhere>;
};

export type MutationDeleteLessonArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLessonsArgs = {
  where?: InputMaybe<LessonWhere>;
};

export type MutationDeleteLevelDetailsArgs = {
  where?: InputMaybe<LevelDetailsWhere>;
};

export type MutationDeleteLifeAspirationsArgs = {
  where?: InputMaybe<LifeAspirationWhere>;
};

export type MutationDeleteMasteryResultsArgs = {
  where?: InputMaybe<MasteryResultWhere>;
};

export type MutationDeleteMeResponsesArgs = {
  where?: InputMaybe<MeResponseWhere>;
};

export type MutationDeleteMessageArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteMessageResponsesArgs = {
  delete?: InputMaybe<MessageResponseDeleteInput>;
  where?: InputMaybe<MessageResponseWhere>;
};

export type MutationDeleteMessagesArgs = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<MessageWhere>;
};

export type MutationDeleteModuleAnalyticsArgs = {
  where?: InputMaybe<ModuleAnalyticsWhere>;
};

export type MutationDeleteModuleAnalyticsDetailsArgs = {
  where?: InputMaybe<ModuleAnalyticsDetailsWhere>;
};

export type MutationDeleteModuleAnalyticsResponsesArgs = {
  where?: InputMaybe<ModuleAnalyticsResponseWhere>;
};

export type MutationDeleteModuleCascadeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteModuleCompletionDetailsArgs = {
  where?: InputMaybe<ModuleCompletionDetailsWhere>;
};

export type MutationDeleteModuleCompletionDetailsActivitiesArgs = {
  where?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
};

export type MutationDeleteModuleCompletionDetailsDataArgs = {
  where?: InputMaybe<ModuleCompletionDetailsDataWhere>;
};

export type MutationDeleteModuleCompletionDetailsFullsArgs = {
  where?: InputMaybe<ModuleCompletionDetailsFullWhere>;
};

export type MutationDeleteModuleCompletionDetailsResponsesArgs = {
  where?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
};

export type MutationDeleteModuleProgressAnalyticsArgs = {
  where?: InputMaybe<ModuleProgressAnalyticsWhere>;
};

export type MutationDeleteModuleProgressDetailsArgs = {
  where?: InputMaybe<ModuleProgressDetailsWhere>;
};

export type MutationDeleteModuleResponsesArgs = {
  where?: InputMaybe<ModuleResponseWhere>;
};

export type MutationDeleteModulesArgs = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<ModuleWhere>;
};

export type MutationDeleteMonthlySummariesArgs = {
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type MutationDeleteNodeIdentitiesArgs = {
  where?: InputMaybe<NodeIdentityWhere>;
};

export type MutationDeleteNodePropertiesArgs = {
  where?: InputMaybe<NodePropertiesWhere>;
};

export type MutationDeleteNutritionsArgs = {
  where?: InputMaybe<NutritionWhere>;
};

export type MutationDeleteOAuthAccessTokensArgs = {
  delete?: InputMaybe<OAuthAccessTokenDeleteInput>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type MutationDeleteOAuthClientsArgs = {
  delete?: InputMaybe<OAuthClientDeleteInput>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type MutationDeleteObjectiveAnalyticsArgs = {
  where?: InputMaybe<ObjectiveAnalyticsWhere>;
};

export type MutationDeleteObjectiveAnalyticsResponsesArgs = {
  where?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
};

export type MutationDeleteObjectiveCompletionDetailsArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsWhere>;
};

export type MutationDeleteObjectiveCompletionDetailsFullsArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
};

export type MutationDeleteObjectiveCompletionDetailsResponsesArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
};

export type MutationDeleteObjectiveProgressAnalyticsArgs = {
  where?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
};

export type MutationDeleteObjectivesArgs = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type MutationDeletePageArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePageResponsesArgs = {
  where?: InputMaybe<PageResponseWhere>;
};

export type MutationDeletePagesArgs = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<PageWhere>;
};

export type MutationDeleteParametersTypesArgs = {
  where?: InputMaybe<ParametersTypeWhere>;
};

export type MutationDeleteParentRefsArgs = {
  where?: InputMaybe<ParentRefWhere>;
};

export type MutationDeletePaymentsArgs = {
  delete?: InputMaybe<PaymentDeleteInput>;
  where?: InputMaybe<PaymentWhere>;
};

export type MutationDeletePermissionsArgs = {
  delete?: InputMaybe<PermissionDeleteInput>;
  where?: InputMaybe<PermissionWhere>;
};

export type MutationDeletePersonalityTraitsArgs = {
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type MutationDeletePreferenceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePreferencesArgs = {
  delete?: InputMaybe<PreferencesDeleteInput>;
  where?: InputMaybe<PreferencesWhere>;
};

export type MutationDeletePrerequisitesArgs = {
  where?: InputMaybe<PrerequisiteWhere>;
};

export type MutationDeleteProgressArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteProgressDetailsArgs = {
  where?: InputMaybe<ProgressDetailsWhere>;
};

export type MutationDeleteProgressesArgs = {
  where?: InputMaybe<ProgressWhere>;
};

export type MutationDeleteQuarterlySummariesArgs = {
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type MutationDeleteRateLimitResultsArgs = {
  where?: InputMaybe<RateLimitResultWhere>;
};

export type MutationDeleteRecentCategoriesArgs = {
  where?: InputMaybe<RecentCategoryWhere>;
};

export type MutationDeleteRecentTracksArgs = {
  where?: InputMaybe<RecentTrackWhere>;
};

export type MutationDeleteRecomendedsArgs = {
  where?: InputMaybe<RecomendedWhere>;
};

export type MutationDeleteReflectionsArgs = {
  where?: InputMaybe<ReflectionWhere>;
};

export type MutationDeleteRefreshQuestResponsesArgs = {
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type MutationDeleteRelationshipFieldsArgs = {
  delete?: InputMaybe<RelationshipFieldDeleteInput>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type MutationDeleteResponseTypesArgs = {
  where?: InputMaybe<ResponseTypeWhere>;
};

export type MutationDeleteRolesArgs = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<RoleWhere>;
};

export type MutationDeleteSchoolsArgs = {
  delete?: InputMaybe<SchoolDeleteInput>;
  where?: InputMaybe<SchoolWhere>;
};

export type MutationDeleteSessionResponsesArgs = {
  where?: InputMaybe<SessionResponseWhere>;
};

export type MutationDeleteStudentActivityProgressDataArgs = {
  where?: InputMaybe<StudentActivityProgressDataWhere>;
};

export type MutationDeleteStudentActivityProgressResponsesArgs = {
  where?: InputMaybe<StudentActivityProgressResponseWhere>;
};

export type MutationDeleteStudentAnalyticsArgs = {
  where?: InputMaybe<StudentAnalyticsWhere>;
};

export type MutationDeleteStudentAnalyticsDetailsArgs = {
  where?: InputMaybe<StudentAnalyticsDetailsWhere>;
};

export type MutationDeleteStudentAnalyticsResponsesArgs = {
  where?: InputMaybe<StudentAnalyticsResponseWhere>;
};

export type MutationDeleteStudentAnalyticsSummaryResponsesArgs = {
  where?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
};

export type MutationDeleteStudentChatAnalyticsArgs = {
  where?: InputMaybe<StudentChatAnalyticsWhere>;
};

export type MutationDeleteStudentChatAnalyticsResponsesArgs = {
  where?: InputMaybe<StudentChatAnalyticsResponseWhere>;
};

export type MutationDeleteStudentCourseProgressResponsesArgs = {
  where?: InputMaybe<StudentCourseProgressResponseWhere>;
};

export type MutationDeleteStudentDetailedAnalyticsArgs = {
  where?: InputMaybe<StudentDetailedAnalyticsWhere>;
};

export type MutationDeleteStudentDetailedAnalyticsResponsesArgs = {
  where?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
};

export type MutationDeleteStudentLearningInsightsArgs = {
  where?: InputMaybe<StudentLearningInsightsWhere>;
};

export type MutationDeleteStudentLearningInsightsResponsesArgs = {
  where?: InputMaybe<StudentLearningInsightsResponseWhere>;
};

export type MutationDeleteStudentModuleProgressDataArgs = {
  where?: InputMaybe<StudentModuleProgressDataWhere>;
};

export type MutationDeleteStudentModuleProgressResponsesArgs = {
  where?: InputMaybe<StudentModuleProgressResponseWhere>;
};

export type MutationDeleteStudentSummariesArgs = {
  where?: InputMaybe<StudentSummaryWhere>;
};

export type MutationDeleteSubchapterDetailsArgs = {
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type MutationDeleteSubchaptersArgs = {
  where?: InputMaybe<SubchapterWhere>;
};

export type MutationDeleteSubfieldsArgs = {
  where?: InputMaybe<SubfieldWhere>;
};

export type MutationDeleteSubmissionListResponseDataArgs = {
  where?: InputMaybe<SubmissionListResponseDataWhere>;
};

export type MutationDeleteSubmissionListResponseFormattedsArgs = {
  where?: InputMaybe<SubmissionListResponseFormattedWhere>;
};

export type MutationDeleteSubmissionListResponsesArgs = {
  where?: InputMaybe<SubmissionListResponseWhere>;
};

export type MutationDeleteSubmissionResponsesArgs = {
  where?: InputMaybe<SubmissionResponseWhere>;
};

export type MutationDeleteSubmissionsArgs = {
  delete?: InputMaybe<SubmissionDeleteInput>;
  where?: InputMaybe<SubmissionWhere>;
};

export type MutationDeleteSuggestVideoInsightsResponsesArgs = {
  where?: InputMaybe<SuggestVideoInsightsResponseWhere>;
};

export type MutationDeleteSuggestionsArgs = {
  delete?: InputMaybe<SuggestionDeleteInput>;
  where?: InputMaybe<SuggestionWhere>;
};

export type MutationDeleteTenantArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteTenantResponsesArgs = {
  where?: InputMaybe<TenantResponseWhere>;
};

export type MutationDeleteTenantSchemaResponsesArgs = {
  where?: InputMaybe<TenantSchemaResponseWhere>;
};

export type MutationDeleteTenantsArgs = {
  delete?: InputMaybe<TenantDeleteInput>;
  where?: InputMaybe<TenantWhere>;
};

export type MutationDeleteTestingsArgs = {
  delete?: InputMaybe<TestingDeleteInput>;
  where?: InputMaybe<TestingWhere>;
};

export type MutationDeleteTodoArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteTodosArgs = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<TodoWhere>;
};

export type MutationDeleteTopicsArgs = {
  delete?: InputMaybe<TopicDeleteInput>;
  where?: InputMaybe<TopicWhere>;
};

export type MutationDeleteTrackOverviewsArgs = {
  where?: InputMaybe<TrackOverviewWhere>;
};

export type MutationDeleteTransactionByDatesArgs = {
  where?: InputMaybe<TransactionByDateWhere>;
};

export type MutationDeleteTransactionsArgs = {
  where?: InputMaybe<TransactionWhere>;
};

export type MutationDeleteTranscriptSegmentsArgs = {
  where?: InputMaybe<TranscriptSegmentWhere>;
};

export type MutationDeleteUpdateAssignmentResponsesArgs = {
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type MutationDeleteUpdateContentModuleNameResponsesArgs = {
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type MutationDeleteUpdateUserModalOnboardingResponsesArgs = {
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type MutationDeleteUploadCourseThumbnailResponsesArgs = {
  where?: InputMaybe<UploadCourseThumbnailResponseWhere>;
};

export type MutationDeleteUploadDocumentsResponsesArgs = {
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type MutationDeleteUploadDocumentsToBucketResponsesArgs = {
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type MutationDeleteUploadFacultyProfileImageResponsesArgs = {
  where?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
};

export type MutationDeleteUserCareerLoadingResponsesArgs = {
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type MutationDeleteUserCourseLoadingResponsesArgs = {
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type MutationDeleteUserMasteriesArgs = {
  where?: InputMaybe<UserMasteryWhere>;
};

export type MutationDeleteUserProgressesArgs = {
  where?: InputMaybe<UserProgressWhere>;
};

export type MutationDeleteUsersArgs = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<UserWhere>;
};

export type MutationDeleteValidityResponsesArgs = {
  where?: InputMaybe<ValidityResponseWhere>;
};

export type MutationDeleteVideoPoolItemsArgs = {
  where?: InputMaybe<VideoPoolItemWhere>;
};

export type MutationDeleteVideoServiceResultsArgs = {
  where?: InputMaybe<VideoServiceResultWhere>;
};

export type MutationDeleteVideosArgs = {
  delete?: InputMaybe<VideoDeleteInput>;
  where?: InputMaybe<VideoWhere>;
};

export type MutationDeleteVisualizationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteVisualizationResponsesArgs = {
  where?: InputMaybe<VisualizationResponseWhere>;
};

export type MutationDeleteVisualizationsArgs = {
  delete?: InputMaybe<VisualizationDeleteInput>;
  where?: InputMaybe<VisualizationWhere>;
};

export type MutationDeleteWaterIntakeSummariesArgs = {
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type MutationDeleteWaterIntakesArgs = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type MutationDeleteWebhooksArgs = {
  delete?: InputMaybe<WebhookDeleteInput>;
  where?: InputMaybe<WebhookWhere>;
};

export type MutationDeleteWeeklySummariesArgs = {
  delete?: InputMaybe<WeeklySummaryDeleteInput>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type MutationDeleteWhitelistedEmailsArgs = {
  delete?: InputMaybe<WhitelistedEmailDeleteInput>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type MutationDeleteWorkoutsArgs = {
  where?: InputMaybe<WorkoutWhere>;
};

export type MutationDeleteYearlySummariesArgs = {
  where?: InputMaybe<YearlySummaryWhere>;
};

export type MutationDropUserFromCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type MutationEditAiTutorArgs = {
  aiTutor: UpdateAiTutorInput;
  id: Scalars["ID"]["input"];
};

export type MutationEnrollInDegreeArgs = {
  degreeId: Scalars["ID"]["input"];
};

export type MutationEnrollUserInCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type MutationEnrollUserInTrackArgs = {
  topicName: Scalars["String"]["input"];
};

export type MutationGenerateAiTutorFromTitleArgs = {
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type MutationGenerateCourseContentArgs = {
  courseId: Scalars["ID"]["input"];
};

export type MutationGenerateEvalCriteriaArgs = {
  input: GenerateEvalCriteriaInput;
};

export type MutationGenerateUploadUrlArgs = {
  fileName: Scalars["String"]["input"];
  storagePath: StoragePathType;
};

export type MutationGenerateYoutubeKeywordsArgs = {
  description: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type MutationInviteUserToSchoolArgs = {
  input: InviteUserToSchoolInput;
};

export type MutationInviteUsersToEnrollInCourseArgs = {
  input: InviteUsersToEnrollInCourseInput;
};

export type MutationInviteUsersToEnrollInDegreeArgs = {
  input: InviteUsersToEnrollInDegreeInput;
};

export type MutationJourneySubmitFormArgs = {
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationMarkQuestCompleteArgs = {
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
};

export type MutationProcessAttachmentArgs = {
  fileId: Scalars["String"]["input"];
};

export type MutationProcessVideoPoolArgs = {
  videoData: Array<VideoData>;
};

export type MutationRefreshQuestArgs = {
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationRemoveCourseAssignmentFromFacultyArgs = {
  courseId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationRemoveCoursesFromDegreeArgs = {
  input: RemoveCoursesFromDegreeInput;
};

export type MutationRemoveDegreesFromCourseArgs = {
  input: RemoveDegreesFromCourseInput;
};

export type MutationRemoveFromWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRemoveRoleFromUserForTenantArgs = {
  roleId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type MutationRemoveStudentsFromCourseArgs = {
  courseId: Scalars["ID"]["input"];
  studentIds: Array<Scalars["ID"]["input"]>;
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationRevokeFacultyArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationSetArchiveStatusArgs = {
  isArchived: Scalars["Boolean"]["input"];
  nodeId: Scalars["ID"]["input"];
};

export type MutationSetCareerPathActiveArgs = {
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSetKnowledgeRelevanceArgs = {
  isRelevant: Scalars["Boolean"]["input"];
  nodeLabel: Scalars["String"]["input"];
  nodeName: Scalars["String"]["input"];
};

export type MutationSetOnboardingStatusArgs = {
  isOnboarded: Scalars["Boolean"]["input"];
};

export type MutationSignupArgs = {
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationSubmitStudentAssignmentArgs = {
  assignmentId: Scalars["ID"]["input"];
  files?: InputMaybe<Array<FileInput>>;
};

export type MutationUpdateAchievementsArgs = {
  update?: InputMaybe<AchievementsUpdateInput>;
  where?: InputMaybe<AchievementsWhere>;
};

export type MutationUpdateActiveTracksArgs = {
  update?: InputMaybe<ActiveTrackUpdateInput>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type MutationUpdateActivityAnalyticsArgs = {
  update?: InputMaybe<ActivityAnalyticsUpdateInput>;
  where?: InputMaybe<ActivityAnalyticsWhere>;
};

export type MutationUpdateActivityAnalyticsDetailsArgs = {
  update?: InputMaybe<ActivityAnalyticsDetailsUpdateInput>;
  where?: InputMaybe<ActivityAnalyticsDetailsWhere>;
};

export type MutationUpdateActivityAnalyticsResponsesArgs = {
  update?: InputMaybe<ActivityAnalyticsResponseUpdateInput>;
  where?: InputMaybe<ActivityAnalyticsResponseWhere>;
};

export type MutationUpdateActivityCompletionDetailsArgs = {
  update?: InputMaybe<ActivityCompletionDetailsUpdateInput>;
  where?: InputMaybe<ActivityCompletionDetailsWhere>;
};

export type MutationUpdateActivityCompletionDetailsDataArgs = {
  update?: InputMaybe<ActivityCompletionDetailsDataUpdateInput>;
  where?: InputMaybe<ActivityCompletionDetailsDataWhere>;
};

export type MutationUpdateActivityCompletionDetailsFullsArgs = {
  update?: InputMaybe<ActivityCompletionDetailsFullUpdateInput>;
  where?: InputMaybe<ActivityCompletionDetailsFullWhere>;
};

export type MutationUpdateActivityCompletionDetailsObjectivesArgs = {
  update?: InputMaybe<ActivityCompletionDetailsObjectivesUpdateInput>;
  where?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
};

export type MutationUpdateActivityCompletionDetailsResponsesArgs = {
  update?: InputMaybe<ActivityCompletionDetailsResponseUpdateInput>;
  where?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
};

export type MutationUpdateActivityInsightsArgs = {
  update?: InputMaybe<ActivityInsightsUpdateInput>;
  where?: InputMaybe<ActivityInsightsWhere>;
};

export type MutationUpdateActivityInsightsResponsesArgs = {
  update?: InputMaybe<ActivityInsightsResponseUpdateInput>;
  where?: InputMaybe<ActivityInsightsResponseWhere>;
};

export type MutationUpdateActivityProgressAnalyticsArgs = {
  update?: InputMaybe<ActivityProgressAnalyticsUpdateInput>;
  where?: InputMaybe<ActivityProgressAnalyticsWhere>;
};

export type MutationUpdateActivityProgressDetailsArgs = {
  update?: InputMaybe<ActivityProgressDetailsUpdateInput>;
  where?: InputMaybe<ActivityProgressDetailsWhere>;
};

export type MutationUpdateAddAssignmentResponsesArgs = {
  update?: InputMaybe<AddAssignmentResponseUpdateInput>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type MutationUpdateAddContentModuleResponsesArgs = {
  update?: InputMaybe<AddContentModuleResponseUpdateInput>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type MutationUpdateAiTutorAnalyticsArgs = {
  update?: InputMaybe<AiTutorAnalyticsUpdateInput>;
  where?: InputMaybe<AiTutorAnalyticsWhere>;
};

export type MutationUpdateAiTutorAnalyticsResponsesArgs = {
  update?: InputMaybe<AiTutorAnalyticsResponseUpdateInput>;
  where?: InputMaybe<AiTutorAnalyticsResponseWhere>;
};

export type MutationUpdateAiTutorEvalCriteriaResponsesArgs = {
  update?: InputMaybe<AiTutorEvalCriteriaResponseUpdateInput>;
  where?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
};

export type MutationUpdateAiTutorGeneratedResponsesArgs = {
  update?: InputMaybe<AiTutorGeneratedResponseUpdateInput>;
  where?: InputMaybe<AiTutorGeneratedResponseWhere>;
};

export type MutationUpdateAiTutorProgressAnalyticsArgs = {
  update?: InputMaybe<AiTutorProgressAnalyticsUpdateInput>;
  where?: InputMaybe<AiTutorProgressAnalyticsWhere>;
};

export type MutationUpdateAiTutorProgressDetailsArgs = {
  update?: InputMaybe<AiTutorProgressDetailsUpdateInput>;
  where?: InputMaybe<AiTutorProgressDetailsWhere>;
};

export type MutationUpdateAiTutorResponsesArgs = {
  update?: InputMaybe<AiTutorResponseUpdateInput>;
  where?: InputMaybe<AiTutorResponseWhere>;
};

export type MutationUpdateAiTutorsArgs = {
  update?: InputMaybe<AiTutorUpdateInput>;
  where?: InputMaybe<AiTutorWhere>;
};

export type MutationUpdateAnotherRelationshipFieldsArgs = {
  update?: InputMaybe<AnotherRelationshipFieldUpdateInput>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type MutationUpdateAspectDetailsTypesArgs = {
  update?: InputMaybe<AspectDetailsTypeUpdateInput>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type MutationUpdateAssessmentGradeArgs = {
  assessmentId: Scalars["ID"]["input"];
  grade?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateAssessmentListResponseDataArgs = {
  update?: InputMaybe<AssessmentListResponseDataUpdateInput>;
  where?: InputMaybe<AssessmentListResponseDataWhere>;
};

export type MutationUpdateAssessmentListResponseFormattedsArgs = {
  update?: InputMaybe<AssessmentListResponseFormattedUpdateInput>;
  where?: InputMaybe<AssessmentListResponseFormattedWhere>;
};

export type MutationUpdateAssessmentListResponsesArgs = {
  update?: InputMaybe<AssessmentListResponseUpdateInput>;
  where?: InputMaybe<AssessmentListResponseWhere>;
};

export type MutationUpdateAssessmentResponsesArgs = {
  update?: InputMaybe<AssessmentResponseUpdateInput>;
  where?: InputMaybe<AssessmentResponseWhere>;
};

export type MutationUpdateAssessmentsArgs = {
  update?: InputMaybe<AssessmentUpdateInput>;
  where?: InputMaybe<AssessmentWhere>;
};

export type MutationUpdateAssignedQuestsArgs = {
  update?: InputMaybe<AssignedQuestUpdateInput>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type MutationUpdateAssignmentArgs = {
  courseId: Scalars["ID"]["input"];
  currentAssignmentName: Scalars["String"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
};

export type MutationUpdateAssignmentAndSubmissionResponsesArgs = {
  update?: InputMaybe<AssignmentAndSubmissionResponseUpdateInput>;
  where?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
};

export type MutationUpdateAssignmentAndSubmissionsArgs = {
  update?: InputMaybe<AssignmentAndSubmissionUpdateInput>;
  where?: InputMaybe<AssignmentAndSubmissionWhere>;
};

export type MutationUpdateAssignmentResponsesArgs = {
  update?: InputMaybe<AssignmentResponseUpdateInput>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type MutationUpdateAssignmentsArgs = {
  update?: InputMaybe<AssignmentUpdateInput>;
  where?: InputMaybe<AssignmentWhere>;
};

export type MutationUpdateAttributesArgs = {
  update?: InputMaybe<AttributeUpdateInput>;
  where?: InputMaybe<AttributeWhere>;
};

export type MutationUpdateAudioToggleUserResponsesArgs = {
  update?: InputMaybe<AudioToggleUserResponseUpdateInput>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type MutationUpdateAuthPayloadResponsesArgs = {
  update?: InputMaybe<AuthPayloadResponseUpdateInput>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type MutationUpdateAuthPayloadUserPreferencesArgs = {
  update?: InputMaybe<AuthPayloadUserPreferencesUpdateInput>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type MutationUpdateAuthPayloadUsersArgs = {
  update?: InputMaybe<AuthPayloadUserUpdateInput>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type MutationUpdateAxioResourceArgs = {
  id: Scalars["ID"]["input"];
  resource: UpdateAxioResourceInput;
};

export type MutationUpdateAxioResourceResponsesArgs = {
  update?: InputMaybe<AxioResourceResponseUpdateInput>;
  where?: InputMaybe<AxioResourceResponseWhere>;
};

export type MutationUpdateAxioResourcesArgs = {
  update?: InputMaybe<AxioResourceUpdateInput>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type MutationUpdateCareerHeadersArgs = {
  update?: InputMaybe<CareerHeaderUpdateInput>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type MutationUpdateCareerTopicResponsesArgs = {
  update?: InputMaybe<CareerTopicResponseUpdateInput>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type MutationUpdateCareersArgs = {
  update?: InputMaybe<CareerUpdateInput>;
  where?: InputMaybe<CareerWhere>;
};

export type MutationUpdateChallengesArgs = {
  update?: InputMaybe<ChallengeUpdateInput>;
  where?: InputMaybe<ChallengeWhere>;
};

export type MutationUpdateChaptersArgs = {
  update?: InputMaybe<ChapterUpdateInput>;
  where?: InputMaybe<ChapterWhere>;
};

export type MutationUpdateChatAnalyticsArgs = {
  update?: InputMaybe<ChatAnalyticsUpdateInput>;
  where?: InputMaybe<ChatAnalyticsWhere>;
};

export type MutationUpdateChatAttachmentResponsesArgs = {
  update?: InputMaybe<ChatAttachmentResponseUpdateInput>;
  where?: InputMaybe<ChatAttachmentResponseWhere>;
};

export type MutationUpdateChatResponsesArgs = {
  update?: InputMaybe<ChatResponseUpdateInput>;
  where?: InputMaybe<ChatResponseWhere>;
};

export type MutationUpdateChatsArgs = {
  update?: InputMaybe<ChatUpdateInput>;
  where?: InputMaybe<ChatWhere>;
};

export type MutationUpdateChildInfosArgs = {
  update?: InputMaybe<ChildInfoUpdateInput>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type MutationUpdateCompletionActivityAnalyticsArgs = {
  update?: InputMaybe<CompletionActivityAnalyticsUpdateInput>;
  where?: InputMaybe<CompletionActivityAnalyticsWhere>;
};

export type MutationUpdateCompletionActivityDetailsArgs = {
  update?: InputMaybe<CompletionActivityDetailsUpdateInput>;
  where?: InputMaybe<CompletionActivityDetailsWhere>;
};

export type MutationUpdateCompletionAnalyticsArgs = {
  update?: InputMaybe<CompletionAnalyticsUpdateInput>;
  where?: InputMaybe<CompletionAnalyticsWhere>;
};

export type MutationUpdateCompletionAnalyticsResponsesArgs = {
  update?: InputMaybe<CompletionAnalyticsResponseUpdateInput>;
  where?: InputMaybe<CompletionAnalyticsResponseWhere>;
};

export type MutationUpdateCompletionCourseDetailsArgs = {
  update?: InputMaybe<CompletionCourseDetailsUpdateInput>;
  where?: InputMaybe<CompletionCourseDetailsWhere>;
};

export type MutationUpdateCompletionModuleAnalyticsArgs = {
  update?: InputMaybe<CompletionModuleAnalyticsUpdateInput>;
  where?: InputMaybe<CompletionModuleAnalyticsWhere>;
};

export type MutationUpdateCompletionModuleDetailsArgs = {
  update?: InputMaybe<CompletionModuleDetailsUpdateInput>;
  where?: InputMaybe<CompletionModuleDetailsWhere>;
};

export type MutationUpdateCompletionObjectiveAnalyticsArgs = {
  update?: InputMaybe<CompletionObjectiveAnalyticsUpdateInput>;
  where?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
};

export type MutationUpdateCompletionStatsArgs = {
  update?: InputMaybe<CompletionStatsUpdateInput>;
  where?: InputMaybe<CompletionStatsWhere>;
};

export type MutationUpdateContentModuleNameArgs = {
  courseId: Scalars["ID"]["input"];
  newModuleName: Scalars["String"]["input"];
  oldModuleName: Scalars["String"]["input"];
};

export type MutationUpdateCoreValuesArgs = {
  update?: InputMaybe<CoreValueUpdateInput>;
  where?: InputMaybe<CoreValueWhere>;
};

export type MutationUpdateCourseArgs = {
  course: UpdateCourseInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCourseAnalyticsArgs = {
  update?: InputMaybe<CourseAnalyticsUpdateInput>;
  where?: InputMaybe<CourseAnalyticsWhere>;
};

export type MutationUpdateCourseAnalyticsDetailsArgs = {
  update?: InputMaybe<CourseAnalyticsDetailsUpdateInput>;
  where?: InputMaybe<CourseAnalyticsDetailsWhere>;
};

export type MutationUpdateCourseAnalyticsResponsesArgs = {
  update?: InputMaybe<CourseAnalyticsResponseUpdateInput>;
  where?: InputMaybe<CourseAnalyticsResponseWhere>;
};

export type MutationUpdateCourseAnalyticsSummaryResponsesArgs = {
  update?: InputMaybe<CourseAnalyticsSummaryResponseUpdateInput>;
  where?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
};

export type MutationUpdateCourseCategoriesArgs = {
  update?: InputMaybe<CourseCategoryUpdateInput>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type MutationUpdateCourseCompletionDetailsArgs = {
  update?: InputMaybe<CourseCompletionDetailsUpdateInput>;
  where?: InputMaybe<CourseCompletionDetailsWhere>;
};

export type MutationUpdateCourseCompletionDetailsDataArgs = {
  update?: InputMaybe<CourseCompletionDetailsDataUpdateInput>;
  where?: InputMaybe<CourseCompletionDetailsDataWhere>;
};

export type MutationUpdateCourseCompletionDetailsResponsesArgs = {
  update?: InputMaybe<CourseCompletionDetailsResponseUpdateInput>;
  where?: InputMaybe<CourseCompletionDetailsResponseWhere>;
};

export type MutationUpdateCourseCompletionSummariesArgs = {
  update?: InputMaybe<CourseCompletionSummaryUpdateInput>;
  where?: InputMaybe<CourseCompletionSummaryWhere>;
};

export type MutationUpdateCourseCompletionSummaryResponsesArgs = {
  update?: InputMaybe<CourseCompletionSummaryResponseUpdateInput>;
  where?: InputMaybe<CourseCompletionSummaryResponseWhere>;
};

export type MutationUpdateCourseCreationResponsesArgs = {
  update?: InputMaybe<CourseCreationResponseUpdateInput>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type MutationUpdateCourseDetailedAnalyticsArgs = {
  update?: InputMaybe<CourseDetailedAnalyticsUpdateInput>;
  where?: InputMaybe<CourseDetailedAnalyticsWhere>;
};

export type MutationUpdateCourseDetailedResponsesArgs = {
  update?: InputMaybe<CourseDetailedResponseUpdateInput>;
  where?: InputMaybe<CourseDetailedResponseWhere>;
};

export type MutationUpdateCourseHierarchyResponsesArgs = {
  update?: InputMaybe<CourseHierarchyResponseUpdateInput>;
  where?: InputMaybe<CourseHierarchyResponseWhere>;
};

export type MutationUpdateCourseProgressAnalyticsArgs = {
  update?: InputMaybe<CourseProgressAnalyticsUpdateInput>;
  where?: InputMaybe<CourseProgressAnalyticsWhere>;
};

export type MutationUpdateCourseProgressDetailsArgs = {
  update?: InputMaybe<CourseProgressDetailsUpdateInput>;
  where?: InputMaybe<CourseProgressDetailsWhere>;
};

export type MutationUpdateCourseResponsesArgs = {
  update?: InputMaybe<CourseResponseUpdateInput>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type MutationUpdateCourseRosterDataArgs = {
  update?: InputMaybe<CourseRosterDataUpdateInput>;
  where?: InputMaybe<CourseRosterDataWhere>;
};

export type MutationUpdateCourseRosterServiceResultsArgs = {
  update?: InputMaybe<CourseRosterServiceResultUpdateInput>;
  where?: InputMaybe<CourseRosterServiceResultWhere>;
};

export type MutationUpdateCourseSummariesArgs = {
  update?: InputMaybe<CourseSummaryUpdateInput>;
  where?: InputMaybe<CourseSummaryWhere>;
};

export type MutationUpdateCoursesArgs = {
  update?: InputMaybe<CourseUpdateInput>;
  where?: InputMaybe<CourseWhere>;
};

export type MutationUpdateCoursesByQueryDataArgs = {
  update?: InputMaybe<CoursesByQueryDataUpdateInput>;
  where?: InputMaybe<CoursesByQueryDataWhere>;
};

export type MutationUpdateCoursesByQueryResponsesArgs = {
  update?: InputMaybe<CoursesByQueryResponseUpdateInput>;
  where?: InputMaybe<CoursesByQueryResponseWhere>;
};

export type MutationUpdateCurriculaArgs = {
  update?: InputMaybe<CurriculumUpdateInput>;
  where?: InputMaybe<CurriculumWhere>;
};

export type MutationUpdateDailyCompletedTodosCountsArgs = {
  update?: InputMaybe<DailyCompletedTodosCountUpdateInput>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type MutationUpdateDailyExerciseTotalsArgs = {
  update?: InputMaybe<DailyExerciseTotalsUpdateInput>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type MutationUpdateDailyNutritionTotalsArgs = {
  update?: InputMaybe<DailyNutritionTotalsUpdateInput>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type MutationUpdateDailySummariesArgs = {
  update?: InputMaybe<DailySummaryUpdateInput>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type MutationUpdateDegreeArgs = {
  degree: UpdateDegreeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateDegreeResponsesArgs = {
  update?: InputMaybe<DegreeResponseUpdateInput>;
  where?: InputMaybe<DegreeResponseWhere>;
};

export type MutationUpdateDegreesArgs = {
  update?: InputMaybe<DegreeUpdateInput>;
  where?: InputMaybe<DegreeWhere>;
};

export type MutationUpdateDegreesBySchoolByQueryDataArgs = {
  update?: InputMaybe<DegreesBySchoolByQueryDataUpdateInput>;
  where?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
};

export type MutationUpdateDegreesBySchoolByQueryResponsesArgs = {
  update?: InputMaybe<DegreesBySchoolByQueryResponseUpdateInput>;
  where?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
};

export type MutationUpdateDeleteContentAssignmentResponsesArgs = {
  update?: InputMaybe<DeleteContentAssignmentResponseUpdateInput>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type MutationUpdateDeleteContentAssignmentVariablesArgs = {
  update?: InputMaybe<DeleteContentAssignmentVariablesUpdateInput>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type MutationUpdateDeleteContentModuleResponsesArgs = {
  update?: InputMaybe<DeleteContentModuleResponseUpdateInput>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type MutationUpdateDeleteContentModuleVariablesArgs = {
  update?: InputMaybe<DeleteContentModuleVariablesUpdateInput>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type MutationUpdateDeleteCourseResponsesArgs = {
  update?: InputMaybe<DeleteCourseResponseUpdateInput>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type MutationUpdateDeviceSessionsArgs = {
  update?: InputMaybe<DeviceSessionUpdateInput>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type MutationUpdateEmotionalStatesArgs = {
  update?: InputMaybe<EmotionalStateUpdateInput>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type MutationUpdateEventsArgs = {
  update?: InputMaybe<EventUpdateInput>;
  where?: InputMaybe<EventWhere>;
};

export type MutationUpdateExportCsvResponsesArgs = {
  update?: InputMaybe<ExportCsvResponseUpdateInput>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type MutationUpdateFacultyDataCoursesArgs = {
  update?: InputMaybe<FacultyDataCourseUpdateInput>;
  where?: InputMaybe<FacultyDataCourseWhere>;
};

export type MutationUpdateFacultyDataRolesArgs = {
  update?: InputMaybe<FacultyDataRoleUpdateInput>;
  where?: InputMaybe<FacultyDataRoleWhere>;
};

export type MutationUpdateFacultyDataSessionDeviceSessionsArgs = {
  update?: InputMaybe<FacultyDataSessionDeviceSessionUpdateInput>;
  where?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
};

export type MutationUpdateFacultyProfileArgs = {
  input: UpdateFacultyProfileInput;
};

export type MutationUpdateFacultyQueryResponseDataArgs = {
  update?: InputMaybe<FacultyQueryResponseDataUpdateInput>;
  where?: InputMaybe<FacultyQueryResponseDataWhere>;
};

export type MutationUpdateFacultyQueryResponseWithCountDataArgs = {
  update?: InputMaybe<FacultyQueryResponseWithCountDataUpdateInput>;
  where?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
};

export type MutationUpdateFacultyQueryResponsesArgs = {
  update?: InputMaybe<FacultyQueryResponseUpdateInput>;
  where?: InputMaybe<FacultyQueryResponseWhere>;
};

export type MutationUpdateFeedbackArgs = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateFeedbacksArgs = {
  update?: InputMaybe<FeedbackUpdateInput>;
  where?: InputMaybe<FeedbackWhere>;
};

export type MutationUpdateFieldsArgs = {
  update?: InputMaybe<FieldUpdateInput>;
  where?: InputMaybe<FieldWhere>;
};

export type MutationUpdateFileResponsesArgs = {
  update?: InputMaybe<FileResponseUpdateInput>;
  where?: InputMaybe<FileResponseWhere>;
};

export type MutationUpdateFileUploadResponsesArgs = {
  update?: InputMaybe<FileUploadResponseUpdateInput>;
  where?: InputMaybe<FileUploadResponseWhere>;
};

export type MutationUpdateFilesArgs = {
  update?: InputMaybe<FileUpdateInput>;
  where?: InputMaybe<FileWhere>;
};

export type MutationUpdateFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateFoodItemsArgs = {
  update?: InputMaybe<FoodItemUpdateInput>;
  where?: InputMaybe<FoodItemWhere>;
};

export type MutationUpdateFoodsArgs = {
  update?: InputMaybe<FoodUpdateInput>;
  where?: InputMaybe<FoodWhere>;
};

export type MutationUpdateFormattedAiTutorsArgs = {
  update?: InputMaybe<FormattedAiTutorUpdateInput>;
  where?: InputMaybe<FormattedAiTutorWhere>;
};

export type MutationUpdateFormattedChatsArgs = {
  update?: InputMaybe<FormattedChatUpdateInput>;
  where?: InputMaybe<FormattedChatWhere>;
};

export type MutationUpdateFormattedObjectivesArgs = {
  update?: InputMaybe<FormattedObjectiveUpdateInput>;
  where?: InputMaybe<FormattedObjectiveWhere>;
};

export type MutationUpdateFormattedVisualizationsArgs = {
  update?: InputMaybe<FormattedVisualizationUpdateInput>;
  where?: InputMaybe<FormattedVisualizationWhere>;
};

export type MutationUpdateGamesArgs = {
  update?: InputMaybe<GameUpdateInput>;
  where?: InputMaybe<GameWhere>;
};

export type MutationUpdateGenerateCourseContentDataArgs = {
  update?: InputMaybe<GenerateCourseContentDataUpdateInput>;
  where?: InputMaybe<GenerateCourseContentDataWhere>;
};

export type MutationUpdateGenerateCourseContentResponsesArgs = {
  update?: InputMaybe<GenerateCourseContentResponseUpdateInput>;
  where?: InputMaybe<GenerateCourseContentResponseWhere>;
};

export type MutationUpdateGeneratedAiTutorsArgs = {
  update?: InputMaybe<GeneratedAiTutorUpdateInput>;
  where?: InputMaybe<GeneratedAiTutorWhere>;
};

export type MutationUpdateGeneratedObjectivesArgs = {
  update?: InputMaybe<GeneratedObjectiveUpdateInput>;
  where?: InputMaybe<GeneratedObjectiveWhere>;
};

export type MutationUpdateGeneratedsArgs = {
  update?: InputMaybe<GeneratedUpdateInput>;
  where?: InputMaybe<GeneratedWhere>;
};

export type MutationUpdateGetAssignmentObjectiveResponsesArgs = {
  update?: InputMaybe<GetAssignmentObjectiveResponseUpdateInput>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type MutationUpdateGetAssignmentResponsesArgs = {
  update?: InputMaybe<GetAssignmentResponseUpdateInput>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type MutationUpdateGetJourneyByIdResponsesArgs = {
  update?: InputMaybe<GetJourneyByIdResponseUpdateInput>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type MutationUpdateGetJourneysResponsesArgs = {
  update?: InputMaybe<GetJourneysResponseUpdateInput>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type MutationUpdateGoalArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateGoalsArgs = {
  update?: InputMaybe<GoalUpdateInput>;
  where?: InputMaybe<GoalWhere>;
};

export type MutationUpdateGoogleAuthTokensArgs = {
  update?: InputMaybe<GoogleAuthTokensUpdateInput>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type MutationUpdateHierarchiesArgs = {
  update?: InputMaybe<HierarchyUpdateInput>;
  where?: InputMaybe<HierarchyWhere>;
};

export type MutationUpdateHierarchyActivitiesArgs = {
  update?: InputMaybe<HierarchyActivityUpdateInput>;
  where?: InputMaybe<HierarchyActivityWhere>;
};

export type MutationUpdateHierarchyActivityDetailsArgs = {
  update?: InputMaybe<HierarchyActivityDetailsUpdateInput>;
  where?: InputMaybe<HierarchyActivityDetailsWhere>;
};

export type MutationUpdateHierarchyCourseDetailsArgs = {
  update?: InputMaybe<HierarchyCourseDetailsUpdateInput>;
  where?: InputMaybe<HierarchyCourseDetailsWhere>;
};

export type MutationUpdateHierarchyCoursesArgs = {
  update?: InputMaybe<HierarchyCourseUpdateInput>;
  where?: InputMaybe<HierarchyCourseWhere>;
};

export type MutationUpdateHierarchyModuleDetailsArgs = {
  update?: InputMaybe<HierarchyModuleDetailsUpdateInput>;
  where?: InputMaybe<HierarchyModuleDetailsWhere>;
};

export type MutationUpdateHierarchyModulesArgs = {
  update?: InputMaybe<HierarchyModuleUpdateInput>;
  where?: InputMaybe<HierarchyModuleWhere>;
};

export type MutationUpdateHierarchyNodesArgs = {
  update?: InputMaybe<HierarchyNodeUpdateInput>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type MutationUpdateHierarchyObjectivesArgs = {
  update?: InputMaybe<HierarchyObjectiveUpdateInput>;
  where?: InputMaybe<HierarchyObjectiveWhere>;
};

export type MutationUpdateHobbiesArgs = {
  update?: InputMaybe<HobbyUpdateInput>;
  where?: InputMaybe<HobbyWhere>;
};

export type MutationUpdateInterestArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastUpdated?: InputMaybe<Scalars["DateTime"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserInput;
};

export type MutationUpdateInterestsArgs = {
  update?: InputMaybe<InterestUpdateInput>;
  where?: InputMaybe<InterestWhere>;
};

export type MutationUpdateInviteCountResponsesArgs = {
  update?: InputMaybe<InviteCountResponseUpdateInput>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type MutationUpdateInviteUsersToEnrollInCourseResponsesArgs = {
  update?: InputMaybe<InviteUsersToEnrollInCourseResponseUpdateInput>;
  where?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
};

export type MutationUpdateInviteUsersToEnrollInDegreeResponsesArgs = {
  update?: InputMaybe<InviteUsersToEnrollInDegreeResponseUpdateInput>;
  where?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
};

export type MutationUpdateInvitedUsersArgs = {
  update?: InputMaybe<InvitedUsersUpdateInput>;
  where?: InputMaybe<InvitedUsersWhere>;
};

export type MutationUpdateJourneysArgs = {
  update?: InputMaybe<JourneyUpdateInput>;
  where?: InputMaybe<JourneyWhere>;
};

export type MutationUpdateLearningCheckoutSessionsArgs = {
  update?: InputMaybe<LearningCheckoutSessionUpdateInput>;
  where?: InputMaybe<LearningCheckoutSessionWhere>;
};

export type MutationUpdateLearningNodesArgs = {
  update?: InputMaybe<LearningNodeUpdateInput>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type MutationUpdateLearningObjectiveCompletionArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationUpdateLearningObjectiveResponsesArgs = {
  update?: InputMaybe<LearningObjectiveResponseUpdateInput>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type MutationUpdateLearningObjectivesArgs = {
  update?: InputMaybe<LearningObjectiveUpdateInput>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type MutationUpdateLearningPaymentsArgs = {
  update?: InputMaybe<LearningPaymentUpdateInput>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type MutationUpdateLearningSummariesArgs = {
  update?: InputMaybe<LearningSummaryUpdateInput>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type MutationUpdateLearningTreesArgs = {
  update?: InputMaybe<LearningTreeUpdateInput>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type MutationUpdateLessonArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  user: UserInput;
};

export type MutationUpdateLessonsArgs = {
  update?: InputMaybe<LessonUpdateInput>;
  where?: InputMaybe<LessonWhere>;
};

export type MutationUpdateLevelDetailsArgs = {
  update?: InputMaybe<LevelDetailsUpdateInput>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type MutationUpdateLifeAspirationsArgs = {
  update?: InputMaybe<LifeAspirationUpdateInput>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type MutationUpdateMasteryResultsArgs = {
  update?: InputMaybe<MasteryResultUpdateInput>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type MutationUpdateMeResponsesArgs = {
  update?: InputMaybe<MeResponseUpdateInput>;
  where?: InputMaybe<MeResponseWhere>;
};

export type MutationUpdateMessageResponsesArgs = {
  update?: InputMaybe<MessageResponseUpdateInput>;
  where?: InputMaybe<MessageResponseWhere>;
};

export type MutationUpdateMessagesArgs = {
  update?: InputMaybe<MessageUpdateInput>;
  where?: InputMaybe<MessageWhere>;
};

export type MutationUpdateModuleArgs = {
  id: Scalars["ID"]["input"];
  module: UpdateModuleInput;
};

export type MutationUpdateModuleAnalyticsArgs = {
  update?: InputMaybe<ModuleAnalyticsUpdateInput>;
  where?: InputMaybe<ModuleAnalyticsWhere>;
};

export type MutationUpdateModuleAnalyticsDetailsArgs = {
  update?: InputMaybe<ModuleAnalyticsDetailsUpdateInput>;
  where?: InputMaybe<ModuleAnalyticsDetailsWhere>;
};

export type MutationUpdateModuleAnalyticsResponsesArgs = {
  update?: InputMaybe<ModuleAnalyticsResponseUpdateInput>;
  where?: InputMaybe<ModuleAnalyticsResponseWhere>;
};

export type MutationUpdateModuleCompletionDetailsArgs = {
  update?: InputMaybe<ModuleCompletionDetailsUpdateInput>;
  where?: InputMaybe<ModuleCompletionDetailsWhere>;
};

export type MutationUpdateModuleCompletionDetailsActivitiesArgs = {
  update?: InputMaybe<ModuleCompletionDetailsActivitiesUpdateInput>;
  where?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
};

export type MutationUpdateModuleCompletionDetailsDataArgs = {
  update?: InputMaybe<ModuleCompletionDetailsDataUpdateInput>;
  where?: InputMaybe<ModuleCompletionDetailsDataWhere>;
};

export type MutationUpdateModuleCompletionDetailsFullsArgs = {
  update?: InputMaybe<ModuleCompletionDetailsFullUpdateInput>;
  where?: InputMaybe<ModuleCompletionDetailsFullWhere>;
};

export type MutationUpdateModuleCompletionDetailsResponsesArgs = {
  update?: InputMaybe<ModuleCompletionDetailsResponseUpdateInput>;
  where?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
};

export type MutationUpdateModuleProgressAnalyticsArgs = {
  update?: InputMaybe<ModuleProgressAnalyticsUpdateInput>;
  where?: InputMaybe<ModuleProgressAnalyticsWhere>;
};

export type MutationUpdateModuleProgressDetailsArgs = {
  update?: InputMaybe<ModuleProgressDetailsUpdateInput>;
  where?: InputMaybe<ModuleProgressDetailsWhere>;
};

export type MutationUpdateModuleResponsesArgs = {
  update?: InputMaybe<ModuleResponseUpdateInput>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type MutationUpdateModulesArgs = {
  update?: InputMaybe<ModuleUpdateInput>;
  where?: InputMaybe<ModuleWhere>;
};

export type MutationUpdateMonthlySummariesArgs = {
  update?: InputMaybe<MonthlySummaryUpdateInput>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type MutationUpdateNodeIdentitiesArgs = {
  update?: InputMaybe<NodeIdentityUpdateInput>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type MutationUpdateNodePropertiesArgs = {
  update?: InputMaybe<NodePropertiesUpdateInput>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type MutationUpdateNotificationsArgs = {
  dailyEmailOptIn: Scalars["Boolean"]["input"];
};

export type MutationUpdateNutritionsArgs = {
  update?: InputMaybe<NutritionUpdateInput>;
  where?: InputMaybe<NutritionWhere>;
};

export type MutationUpdateOAuthAccessTokensArgs = {
  update?: InputMaybe<OAuthAccessTokenUpdateInput>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type MutationUpdateOAuthClientsArgs = {
  update?: InputMaybe<OAuthClientUpdateInput>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type MutationUpdateObjectiveAnalyticsArgs = {
  update?: InputMaybe<ObjectiveAnalyticsUpdateInput>;
  where?: InputMaybe<ObjectiveAnalyticsWhere>;
};

export type MutationUpdateObjectiveAnalyticsResponsesArgs = {
  update?: InputMaybe<ObjectiveAnalyticsResponseUpdateInput>;
  where?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
};

export type MutationUpdateObjectiveCompletionDetailsArgs = {
  update?: InputMaybe<ObjectiveCompletionDetailsUpdateInput>;
  where?: InputMaybe<ObjectiveCompletionDetailsWhere>;
};

export type MutationUpdateObjectiveCompletionDetailsFullsArgs = {
  update?: InputMaybe<ObjectiveCompletionDetailsFullUpdateInput>;
  where?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
};

export type MutationUpdateObjectiveCompletionDetailsResponsesArgs = {
  update?: InputMaybe<ObjectiveCompletionDetailsResponseUpdateInput>;
  where?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
};

export type MutationUpdateObjectiveProgressAnalyticsArgs = {
  update?: InputMaybe<ObjectiveProgressAnalyticsUpdateInput>;
  where?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
};

export type MutationUpdateObjectivesArgs = {
  update?: InputMaybe<ObjectiveUpdateInput>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type MutationUpdatePageArgs = {
  id: Scalars["ID"]["input"];
  page: UpdatePageInput;
};

export type MutationUpdatePageResponsesArgs = {
  update?: InputMaybe<PageResponseUpdateInput>;
  where?: InputMaybe<PageResponseWhere>;
};

export type MutationUpdatePagesArgs = {
  update?: InputMaybe<PageUpdateInput>;
  where?: InputMaybe<PageWhere>;
};

export type MutationUpdateParametersTypesArgs = {
  update?: InputMaybe<ParametersTypeUpdateInput>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type MutationUpdateParentRefsArgs = {
  update?: InputMaybe<ParentRefUpdateInput>;
  where?: InputMaybe<ParentRefWhere>;
};

export type MutationUpdatePaymentsArgs = {
  update?: InputMaybe<PaymentUpdateInput>;
  where?: InputMaybe<PaymentWhere>;
};

export type MutationUpdatePermissionsArgs = {
  update?: InputMaybe<PermissionUpdateInput>;
  where?: InputMaybe<PermissionWhere>;
};

export type MutationUpdatePersonalityTraitsArgs = {
  update?: InputMaybe<PersonalityTraitUpdateInput>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type MutationUpdatePreferenceArgs = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  language?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdatePreferencesArgs = {
  update?: InputMaybe<PreferencesUpdateInput>;
  where?: InputMaybe<PreferencesWhere>;
};

export type MutationUpdatePrerequisitesArgs = {
  update?: InputMaybe<PrerequisiteUpdateInput>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type MutationUpdateProgressArgs = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateProgressDetailsArgs = {
  update?: InputMaybe<ProgressDetailsUpdateInput>;
  where?: InputMaybe<ProgressDetailsWhere>;
};

export type MutationUpdateProgressesArgs = {
  update?: InputMaybe<ProgressUpdateInput>;
  where?: InputMaybe<ProgressWhere>;
};

export type MutationUpdateQuarterlySummariesArgs = {
  update?: InputMaybe<QuarterlySummaryUpdateInput>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type MutationUpdateRateLimitResultsArgs = {
  update?: InputMaybe<RateLimitResultUpdateInput>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type MutationUpdateRecentCategoriesArgs = {
  update?: InputMaybe<RecentCategoryUpdateInput>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type MutationUpdateRecentTracksArgs = {
  update?: InputMaybe<RecentTrackUpdateInput>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type MutationUpdateRecomendedsArgs = {
  update?: InputMaybe<RecomendedUpdateInput>;
  where?: InputMaybe<RecomendedWhere>;
};

export type MutationUpdateReflectionsArgs = {
  update?: InputMaybe<ReflectionUpdateInput>;
  where?: InputMaybe<ReflectionWhere>;
};

export type MutationUpdateRefreshQuestResponsesArgs = {
  update?: InputMaybe<RefreshQuestResponseUpdateInput>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type MutationUpdateRelationshipFieldsArgs = {
  update?: InputMaybe<RelationshipFieldUpdateInput>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type MutationUpdateResourceOrderArgs = {
  input: UpdateResourceOrderInput;
};

export type MutationUpdateResponseTypesArgs = {
  update?: InputMaybe<ResponseTypeUpdateInput>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type MutationUpdateRolesArgs = {
  update?: InputMaybe<RoleUpdateInput>;
  where?: InputMaybe<RoleWhere>;
};

export type MutationUpdateSchoolsArgs = {
  update?: InputMaybe<SchoolUpdateInput>;
  where?: InputMaybe<SchoolWhere>;
};

export type MutationUpdateSessionResponsesArgs = {
  update?: InputMaybe<SessionResponseUpdateInput>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type MutationUpdateStudentActivityProgressDataArgs = {
  update?: InputMaybe<StudentActivityProgressDataUpdateInput>;
  where?: InputMaybe<StudentActivityProgressDataWhere>;
};

export type MutationUpdateStudentActivityProgressResponsesArgs = {
  update?: InputMaybe<StudentActivityProgressResponseUpdateInput>;
  where?: InputMaybe<StudentActivityProgressResponseWhere>;
};

export type MutationUpdateStudentAnalyticsArgs = {
  update?: InputMaybe<StudentAnalyticsUpdateInput>;
  where?: InputMaybe<StudentAnalyticsWhere>;
};

export type MutationUpdateStudentAnalyticsDetailsArgs = {
  update?: InputMaybe<StudentAnalyticsDetailsUpdateInput>;
  where?: InputMaybe<StudentAnalyticsDetailsWhere>;
};

export type MutationUpdateStudentAnalyticsResponsesArgs = {
  update?: InputMaybe<StudentAnalyticsResponseUpdateInput>;
  where?: InputMaybe<StudentAnalyticsResponseWhere>;
};

export type MutationUpdateStudentAnalyticsSummaryResponsesArgs = {
  update?: InputMaybe<StudentAnalyticsSummaryResponseUpdateInput>;
  where?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
};

export type MutationUpdateStudentAssignmentGradeArgs = {
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  submissionId: Scalars["ID"]["input"];
};

export type MutationUpdateStudentChatAnalyticsArgs = {
  update?: InputMaybe<StudentChatAnalyticsUpdateInput>;
  where?: InputMaybe<StudentChatAnalyticsWhere>;
};

export type MutationUpdateStudentChatAnalyticsResponsesArgs = {
  update?: InputMaybe<StudentChatAnalyticsResponseUpdateInput>;
  where?: InputMaybe<StudentChatAnalyticsResponseWhere>;
};

export type MutationUpdateStudentCourseProgressResponsesArgs = {
  update?: InputMaybe<StudentCourseProgressResponseUpdateInput>;
  where?: InputMaybe<StudentCourseProgressResponseWhere>;
};

export type MutationUpdateStudentDetailedAnalyticsArgs = {
  update?: InputMaybe<StudentDetailedAnalyticsUpdateInput>;
  where?: InputMaybe<StudentDetailedAnalyticsWhere>;
};

export type MutationUpdateStudentDetailedAnalyticsResponsesArgs = {
  update?: InputMaybe<StudentDetailedAnalyticsResponseUpdateInput>;
  where?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
};

export type MutationUpdateStudentLearningInsightsArgs = {
  update?: InputMaybe<StudentLearningInsightsUpdateInput>;
  where?: InputMaybe<StudentLearningInsightsWhere>;
};

export type MutationUpdateStudentLearningInsightsResponsesArgs = {
  update?: InputMaybe<StudentLearningInsightsResponseUpdateInput>;
  where?: InputMaybe<StudentLearningInsightsResponseWhere>;
};

export type MutationUpdateStudentModuleProgressDataArgs = {
  update?: InputMaybe<StudentModuleProgressDataUpdateInput>;
  where?: InputMaybe<StudentModuleProgressDataWhere>;
};

export type MutationUpdateStudentModuleProgressResponsesArgs = {
  update?: InputMaybe<StudentModuleProgressResponseUpdateInput>;
  where?: InputMaybe<StudentModuleProgressResponseWhere>;
};

export type MutationUpdateStudentSummariesArgs = {
  update?: InputMaybe<StudentSummaryUpdateInput>;
  where?: InputMaybe<StudentSummaryWhere>;
};

export type MutationUpdateSubchapterDetailsArgs = {
  update?: InputMaybe<SubchapterDetailsUpdateInput>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type MutationUpdateSubchaptersArgs = {
  update?: InputMaybe<SubchapterUpdateInput>;
  where?: InputMaybe<SubchapterWhere>;
};

export type MutationUpdateSubfieldsArgs = {
  update?: InputMaybe<SubfieldUpdateInput>;
  where?: InputMaybe<SubfieldWhere>;
};

export type MutationUpdateSubmissionListResponseDataArgs = {
  update?: InputMaybe<SubmissionListResponseDataUpdateInput>;
  where?: InputMaybe<SubmissionListResponseDataWhere>;
};

export type MutationUpdateSubmissionListResponseFormattedsArgs = {
  update?: InputMaybe<SubmissionListResponseFormattedUpdateInput>;
  where?: InputMaybe<SubmissionListResponseFormattedWhere>;
};

export type MutationUpdateSubmissionListResponsesArgs = {
  update?: InputMaybe<SubmissionListResponseUpdateInput>;
  where?: InputMaybe<SubmissionListResponseWhere>;
};

export type MutationUpdateSubmissionResponsesArgs = {
  update?: InputMaybe<SubmissionResponseUpdateInput>;
  where?: InputMaybe<SubmissionResponseWhere>;
};

export type MutationUpdateSubmissionsArgs = {
  update?: InputMaybe<SubmissionUpdateInput>;
  where?: InputMaybe<SubmissionWhere>;
};

export type MutationUpdateSuggestVideoInsightsResponsesArgs = {
  update?: InputMaybe<SuggestVideoInsightsResponseUpdateInput>;
  where?: InputMaybe<SuggestVideoInsightsResponseWhere>;
};

export type MutationUpdateSuggestionsArgs = {
  update?: InputMaybe<SuggestionUpdateInput>;
  where?: InputMaybe<SuggestionWhere>;
};

export type MutationUpdateTenantArgs = {
  id: Scalars["ID"]["input"];
  tenant: TenantInput;
};

export type MutationUpdateTenantResponsesArgs = {
  update?: InputMaybe<TenantResponseUpdateInput>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type MutationUpdateTenantSchemaResponsesArgs = {
  update?: InputMaybe<TenantSchemaResponseUpdateInput>;
  where?: InputMaybe<TenantSchemaResponseWhere>;
};

export type MutationUpdateTenantsArgs = {
  update?: InputMaybe<TenantUpdateInput>;
  where?: InputMaybe<TenantWhere>;
};

export type MutationUpdateTestingsArgs = {
  update?: InputMaybe<TestingUpdateInput>;
  where?: InputMaybe<TestingWhere>;
};

export type MutationUpdateTodoArgs = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type MutationUpdateTodosArgs = {
  update?: InputMaybe<TodoUpdateInput>;
  where?: InputMaybe<TodoWhere>;
};

export type MutationUpdateTopicArgs = {
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationUpdateTopicsArgs = {
  update?: InputMaybe<TopicUpdateInput>;
  where?: InputMaybe<TopicWhere>;
};

export type MutationUpdateTrackOverviewsArgs = {
  update?: InputMaybe<TrackOverviewUpdateInput>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type MutationUpdateTransactionByDatesArgs = {
  update?: InputMaybe<TransactionByDateUpdateInput>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type MutationUpdateTransactionsArgs = {
  update?: InputMaybe<TransactionUpdateInput>;
  where?: InputMaybe<TransactionWhere>;
};

export type MutationUpdateTranscriptSegmentsArgs = {
  update?: InputMaybe<TranscriptSegmentUpdateInput>;
  where?: InputMaybe<TranscriptSegmentWhere>;
};

export type MutationUpdateUpdateAssignmentResponsesArgs = {
  update?: InputMaybe<UpdateAssignmentResponseUpdateInput>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type MutationUpdateUpdateContentModuleNameResponsesArgs = {
  update?: InputMaybe<UpdateContentModuleNameResponseUpdateInput>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type MutationUpdateUpdateUserModalOnboardingResponsesArgs = {
  update?: InputMaybe<UpdateUserModalOnboardingResponseUpdateInput>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type MutationUpdateUploadCourseThumbnailResponsesArgs = {
  update?: InputMaybe<UploadCourseThumbnailResponseUpdateInput>;
  where?: InputMaybe<UploadCourseThumbnailResponseWhere>;
};

export type MutationUpdateUploadDocumentsResponsesArgs = {
  update?: InputMaybe<UploadDocumentsResponseUpdateInput>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type MutationUpdateUploadDocumentsToBucketResponsesArgs = {
  update?: InputMaybe<UploadDocumentsToBucketResponseUpdateInput>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type MutationUpdateUploadFacultyProfileImageResponsesArgs = {
  update?: InputMaybe<UploadFacultyProfileImageResponseUpdateInput>;
  where?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
};

export type MutationUpdateUserCareerLoadingResponsesArgs = {
  update?: InputMaybe<UserCareerLoadingResponseUpdateInput>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type MutationUpdateUserCourseLoadingResponsesArgs = {
  update?: InputMaybe<UserCourseLoadingResponseUpdateInput>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type MutationUpdateUserMasteriesArgs = {
  update?: InputMaybe<UserMasteryUpdateInput>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type MutationUpdateUserModalOnboardingArgs = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateUserProgressesArgs = {
  update?: InputMaybe<UserProgressUpdateInput>;
  where?: InputMaybe<UserProgressWhere>;
};

export type MutationUpdateUsersArgs = {
  update?: InputMaybe<UserUpdateInput>;
  where?: InputMaybe<UserWhere>;
};

export type MutationUpdateValidityResponsesArgs = {
  update?: InputMaybe<ValidityResponseUpdateInput>;
  where?: InputMaybe<ValidityResponseWhere>;
};

export type MutationUpdateVideoPoolItemsArgs = {
  update?: InputMaybe<VideoPoolItemUpdateInput>;
  where?: InputMaybe<VideoPoolItemWhere>;
};

export type MutationUpdateVideoServiceResultsArgs = {
  update?: InputMaybe<VideoServiceResultUpdateInput>;
  where?: InputMaybe<VideoServiceResultWhere>;
};

export type MutationUpdateVideosArgs = {
  update?: InputMaybe<VideoUpdateInput>;
  where?: InputMaybe<VideoWhere>;
};

export type MutationUpdateVisualizationArgs = {
  content: VisualizationContentInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateVisualizationResponsesArgs = {
  update?: InputMaybe<VisualizationResponseUpdateInput>;
  where?: InputMaybe<VisualizationResponseWhere>;
};

export type MutationUpdateVisualizationsArgs = {
  update?: InputMaybe<VisualizationUpdateInput>;
  where?: InputMaybe<VisualizationWhere>;
};

export type MutationUpdateWaterIntakeSummariesArgs = {
  update?: InputMaybe<WaterIntakeSummaryUpdateInput>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type MutationUpdateWaterIntakesArgs = {
  update?: InputMaybe<WaterIntakeUpdateInput>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type MutationUpdateWebhooksArgs = {
  update?: InputMaybe<WebhookUpdateInput>;
  where?: InputMaybe<WebhookWhere>;
};

export type MutationUpdateWeeklySummariesArgs = {
  update?: InputMaybe<WeeklySummaryUpdateInput>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type MutationUpdateWhitelistedEmailsArgs = {
  update?: InputMaybe<WhitelistedEmailUpdateInput>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type MutationUpdateWorkoutsArgs = {
  update?: InputMaybe<WorkoutUpdateInput>;
  where?: InputMaybe<WorkoutWhere>;
};

export type MutationUpdateYearlySummariesArgs = {
  update?: InputMaybe<YearlySummaryUpdateInput>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type MutationUploadCourseThumbnailArgs = {
  input: UploadCourseThumbnailInput;
};

export type MutationUploadDocumentsArgs = {
  files: Array<Scalars["Upload"]["input"]>;
};

export type MutationUploadDocumentsToBucketArgs = {
  files: Array<Scalars["Upload"]["input"]>;
};

export type MutationUploadFacultyProfileImageArgs = {
  input: UploadFacultyProfileImageInput;
};

export type NodeIdentitiesConnection = {
  __typename?: "NodeIdentitiesConnection";
  edges: Array<NodeIdentityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type NodeIdentity = {
  __typename?: "NodeIdentity";
  high: Scalars["Int"]["output"];
  low: Scalars["Int"]["output"];
};

export type NodeIdentityAggregateSelection = {
  __typename?: "NodeIdentityAggregateSelection";
  count: Scalars["Int"]["output"];
  high: IntAggregateSelection;
  low: IntAggregateSelection;
};

export type NodeIdentityCreateInput = {
  high: Scalars["Int"]["input"];
  low: Scalars["Int"]["input"];
};

export type NodeIdentityEdge = {
  __typename?: "NodeIdentityEdge";
  cursor: Scalars["String"]["output"];
  node: NodeIdentity;
};

export type NodeIdentityOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NodeIdentitySort objects to sort NodeIdentities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NodeIdentitySort>>;
};

/** Fields to sort NodeIdentities by. The order in which sorts are applied is not guaranteed when specifying many fields in one NodeIdentitySort object. */
export type NodeIdentitySort = {
  high?: InputMaybe<SortDirection>;
  low?: InputMaybe<SortDirection>;
};

export type NodeIdentityUpdateInput = {
  high?: InputMaybe<Scalars["Int"]["input"]>;
  high_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  high_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  low?: InputMaybe<Scalars["Int"]["input"]>;
  low_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  low_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NodeIdentityWhere = {
  AND?: InputMaybe<Array<NodeIdentityWhere>>;
  NOT?: InputMaybe<NodeIdentityWhere>;
  OR?: InputMaybe<Array<NodeIdentityWhere>>;
  high?: InputMaybe<Scalars["Int"]["input"]>;
  high_GT?: InputMaybe<Scalars["Int"]["input"]>;
  high_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  high_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  high_LT?: InputMaybe<Scalars["Int"]["input"]>;
  high_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  low?: InputMaybe<Scalars["Int"]["input"]>;
  low_GT?: InputMaybe<Scalars["Int"]["input"]>;
  low_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  low_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  low_LT?: InputMaybe<Scalars["Int"]["input"]>;
  low_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum NodeLabel {
  Chapter = "Chapter",
  Field = "Field",
  Subchapter = "Subchapter",
  Subfield = "Subfield",
  Topic = "Topic",
}

export type NodeProperties = {
  __typename?: "NodeProperties";
  name: Scalars["String"]["output"];
};

export type NodePropertiesAggregateSelection = {
  __typename?: "NodePropertiesAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type NodePropertiesConnection = {
  __typename?: "NodePropertiesConnection";
  edges: Array<NodePropertiesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type NodePropertiesCreateInput = {
  name: Scalars["String"]["input"];
};

export type NodePropertiesEdge = {
  __typename?: "NodePropertiesEdge";
  cursor: Scalars["String"]["output"];
  node: NodeProperties;
};

export type NodePropertiesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NodePropertiesSort objects to sort NodeProperties by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NodePropertiesSort>>;
};

/** Fields to sort NodeProperties by. The order in which sorts are applied is not guaranteed when specifying many fields in one NodePropertiesSort object. */
export type NodePropertiesSort = {
  name?: InputMaybe<SortDirection>;
};

export type NodePropertiesUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type NodePropertiesWhere = {
  AND?: InputMaybe<Array<NodePropertiesWhere>>;
  NOT?: InputMaybe<NodePropertiesWhere>;
  OR?: InputMaybe<Array<NodePropertiesWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Nutrition = {
  __typename?: "Nutrition";
  date: Scalars["DateTime"]["output"];
  foods?: Maybe<Array<Maybe<Food>>>;
  id: Scalars["ID"]["output"];
  water_intake_ml?: Maybe<Scalars["Int"]["output"]>;
};

export type NutritionAggregateSelection = {
  __typename?: "NutritionAggregateSelection";
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  water_intake_ml: IntAggregateSelection;
};

export type NutritionCreateInput = {
  date: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionEdge = {
  __typename?: "NutritionEdge";
  cursor: Scalars["String"]["output"];
  node: Nutrition;
};

export type NutritionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NutritionSort objects to sort Nutritions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NutritionSort>>;
};

/** Fields to sort Nutritions by. The order in which sorts are applied is not guaranteed when specifying many fields in one NutritionSort object. */
export type NutritionSort = {
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  water_intake_ml?: InputMaybe<SortDirection>;
};

export type NutritionUpdateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionWhere = {
  AND?: InputMaybe<Array<NutritionWhere>>;
  NOT?: InputMaybe<NutritionWhere>;
  OR?: InputMaybe<Array<NutritionWhere>>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_GT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  water_intake_ml_LT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionsConnection = {
  __typename?: "NutritionsConnection";
  edges: Array<NutritionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OAuthAccessToken = {
  __typename?: "OAuthAccessToken";
  accessToken: Scalars["String"]["output"];
  client: OAuthClient;
  clientAggregate?: Maybe<OAuthAccessTokenOAuthClientClientAggregationSelection>;
  clientConnection: OAuthAccessTokenClientConnection;
  expiresAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  refreshTokenExpiresAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type OAuthAccessTokenClientArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<OAuthClientOptions>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type OAuthAccessTokenClientAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type OAuthAccessTokenClientConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<OAuthAccessTokenClientConnectionSort>>;
  where?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
};

export type OAuthAccessTokenAggregateSelection = {
  __typename?: "OAuthAccessTokenAggregateSelection";
  accessToken: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  expiresAt: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  refreshToken: StringAggregateSelection;
  refreshTokenExpiresAt: DateTimeAggregateSelection;
};

export type OAuthAccessTokenClientAggregateInput = {
  AND?: InputMaybe<Array<OAuthAccessTokenClientAggregateInput>>;
  NOT?: InputMaybe<OAuthAccessTokenClientAggregateInput>;
  OR?: InputMaybe<Array<OAuthAccessTokenClientAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<OAuthAccessTokenClientNodeAggregationWhereInput>;
};

export type OAuthAccessTokenClientConnectFieldInput = {
  connect?: InputMaybe<OAuthClientConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<OAuthClientConnectWhere>;
};

export type OAuthAccessTokenClientConnectOrCreateFieldInput = {
  onCreate: OAuthAccessTokenClientConnectOrCreateFieldInputOnCreate;
  where: OAuthClientConnectOrCreateWhere;
};

export type OAuthAccessTokenClientConnectOrCreateFieldInputOnCreate = {
  node: OAuthClientOnCreateInput;
};

export type OAuthAccessTokenClientConnection = {
  __typename?: "OAuthAccessTokenClientConnection";
  edges: Array<OAuthAccessTokenClientRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OAuthAccessTokenClientConnectionSort = {
  node?: InputMaybe<OAuthClientSort>;
};

export type OAuthAccessTokenClientConnectionWhere = {
  AND?: InputMaybe<Array<OAuthAccessTokenClientConnectionWhere>>;
  NOT?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
  OR?: InputMaybe<Array<OAuthAccessTokenClientConnectionWhere>>;
  node?: InputMaybe<OAuthClientWhere>;
};

export type OAuthAccessTokenClientCreateFieldInput = {
  node: OAuthClientCreateInput;
};

export type OAuthAccessTokenClientDeleteFieldInput = {
  delete?: InputMaybe<OAuthClientDeleteInput>;
  where?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
};

export type OAuthAccessTokenClientDisconnectFieldInput = {
  disconnect?: InputMaybe<OAuthClientDisconnectInput>;
  where?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
};

export type OAuthAccessTokenClientFieldInput = {
  connect?: InputMaybe<OAuthAccessTokenClientConnectFieldInput>;
  connectOrCreate?: InputMaybe<OAuthAccessTokenClientConnectOrCreateFieldInput>;
  create?: InputMaybe<OAuthAccessTokenClientCreateFieldInput>;
};

export type OAuthAccessTokenClientNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OAuthAccessTokenClientNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OAuthAccessTokenClientNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OAuthAccessTokenClientNodeAggregationWhereInput>>;
  clientId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  clientId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  clientId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  clientId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  clientId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  clientId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  clientId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  clientSecret_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  clientSecret_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  clientSecret_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  clientSecret_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  clientSecret_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  clientSecret_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OAuthAccessTokenClientRelationship = {
  __typename?: "OAuthAccessTokenClientRelationship";
  cursor: Scalars["String"]["output"];
  node: OAuthClient;
};

export type OAuthAccessTokenClientUpdateConnectionInput = {
  node?: InputMaybe<OAuthClientUpdateInput>;
};

export type OAuthAccessTokenClientUpdateFieldInput = {
  connect?: InputMaybe<OAuthAccessTokenClientConnectFieldInput>;
  connectOrCreate?: InputMaybe<OAuthAccessTokenClientConnectOrCreateFieldInput>;
  create?: InputMaybe<OAuthAccessTokenClientCreateFieldInput>;
  delete?: InputMaybe<OAuthAccessTokenClientDeleteFieldInput>;
  disconnect?: InputMaybe<OAuthAccessTokenClientDisconnectFieldInput>;
  update?: InputMaybe<OAuthAccessTokenClientUpdateConnectionInput>;
  where?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
};

export type OAuthAccessTokenConnectInput = {
  client?: InputMaybe<OAuthAccessTokenClientConnectFieldInput>;
};

export type OAuthAccessTokenConnectOrCreateInput = {
  client?: InputMaybe<OAuthAccessTokenClientConnectOrCreateFieldInput>;
};

export type OAuthAccessTokenConnectOrCreateWhere = {
  node: OAuthAccessTokenUniqueWhere;
};

export type OAuthAccessTokenConnectWhere = {
  node: OAuthAccessTokenWhere;
};

export type OAuthAccessTokenCreateInput = {
  accessToken: Scalars["String"]["input"];
  client?: InputMaybe<OAuthAccessTokenClientFieldInput>;
  expiresAt: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  refreshTokenExpiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OAuthAccessTokenDeleteInput = {
  client?: InputMaybe<OAuthAccessTokenClientDeleteFieldInput>;
};

export type OAuthAccessTokenDisconnectInput = {
  client?: InputMaybe<OAuthAccessTokenClientDisconnectFieldInput>;
};

export type OAuthAccessTokenEdge = {
  __typename?: "OAuthAccessTokenEdge";
  cursor: Scalars["String"]["output"];
  node: OAuthAccessToken;
};

export type OAuthAccessTokenOAuthClientClientAggregationSelection = {
  __typename?: "OAuthAccessTokenOAuthClientClientAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<OAuthAccessTokenOAuthClientClientNodeAggregateSelection>;
};

export type OAuthAccessTokenOAuthClientClientNodeAggregateSelection = {
  __typename?: "OAuthAccessTokenOAuthClientClientNodeAggregateSelection";
  clientId: StringAggregateSelection;
  clientSecret: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type OAuthAccessTokenOnCreateInput = {
  accessToken: Scalars["String"]["input"];
  expiresAt: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  refreshTokenExpiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OAuthAccessTokenOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more OAuthAccessTokenSort objects to sort OAuthAccessTokens by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<OAuthAccessTokenSort>>;
};

export type OAuthAccessTokenRelationInput = {
  client?: InputMaybe<OAuthAccessTokenClientCreateFieldInput>;
};

/** Fields to sort OAuthAccessTokens by. The order in which sorts are applied is not guaranteed when specifying many fields in one OAuthAccessTokenSort object. */
export type OAuthAccessTokenSort = {
  accessToken?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  refreshToken?: InputMaybe<SortDirection>;
  refreshTokenExpiresAt?: InputMaybe<SortDirection>;
};

export type OAuthAccessTokenUniqueWhere = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type OAuthAccessTokenUpdateInput = {
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<OAuthAccessTokenClientUpdateFieldInput>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  refreshTokenExpiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OAuthAccessTokenWhere = {
  AND?: InputMaybe<Array<OAuthAccessTokenWhere>>;
  NOT?: InputMaybe<OAuthAccessTokenWhere>;
  OR?: InputMaybe<Array<OAuthAccessTokenWhere>>;
  accessToken?: InputMaybe<Scalars["String"]["input"]>;
  accessToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  accessToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  accessToken_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  accessToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<OAuthClientWhere>;
  clientAggregate?: InputMaybe<OAuthAccessTokenClientAggregateInput>;
  clientConnection?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
  clientConnection_NOT?: InputMaybe<OAuthAccessTokenClientConnectionWhere>;
  client_NOT?: InputMaybe<OAuthClientWhere>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  expiresAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  refreshTokenExpiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  refreshTokenExpiresAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  refreshToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  refreshToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  refreshToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type OAuthAccessTokensConnection = {
  __typename?: "OAuthAccessTokensConnection";
  edges: Array<OAuthAccessTokenEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OAuthClient = {
  __typename?: "OAuthClient";
  accessTokens: Array<OAuthAccessToken>;
  accessTokensAggregate?: Maybe<OAuthClientOAuthAccessTokenAccessTokensAggregationSelection>;
  accessTokensConnection: OAuthClientAccessTokensConnection;
  clientId: Scalars["String"]["output"];
  clientSecret: Scalars["String"]["output"];
  created: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
};

export type OAuthClientAccessTokensArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<OAuthAccessTokenOptions>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type OAuthClientAccessTokensAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type OAuthClientAccessTokensConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<OAuthClientAccessTokensConnectionSort>>;
  where?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
};

export type OAuthClientAccessTokensAggregateInput = {
  AND?: InputMaybe<Array<OAuthClientAccessTokensAggregateInput>>;
  NOT?: InputMaybe<OAuthClientAccessTokensAggregateInput>;
  OR?: InputMaybe<Array<OAuthClientAccessTokensAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<OAuthClientAccessTokensNodeAggregationWhereInput>;
};

export type OAuthClientAccessTokensConnectFieldInput = {
  connect?: InputMaybe<Array<OAuthAccessTokenConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<OAuthAccessTokenConnectWhere>;
};

export type OAuthClientAccessTokensConnectOrCreateFieldInput = {
  onCreate: OAuthClientAccessTokensConnectOrCreateFieldInputOnCreate;
  where: OAuthAccessTokenConnectOrCreateWhere;
};

export type OAuthClientAccessTokensConnectOrCreateFieldInputOnCreate = {
  node: OAuthAccessTokenOnCreateInput;
};

export type OAuthClientAccessTokensConnection = {
  __typename?: "OAuthClientAccessTokensConnection";
  edges: Array<OAuthClientAccessTokensRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type OAuthClientAccessTokensConnectionSort = {
  node?: InputMaybe<OAuthAccessTokenSort>;
};

export type OAuthClientAccessTokensConnectionWhere = {
  AND?: InputMaybe<Array<OAuthClientAccessTokensConnectionWhere>>;
  NOT?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
  OR?: InputMaybe<Array<OAuthClientAccessTokensConnectionWhere>>;
  node?: InputMaybe<OAuthAccessTokenWhere>;
};

export type OAuthClientAccessTokensCreateFieldInput = {
  node: OAuthAccessTokenCreateInput;
};

export type OAuthClientAccessTokensDeleteFieldInput = {
  delete?: InputMaybe<OAuthAccessTokenDeleteInput>;
  where?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
};

export type OAuthClientAccessTokensDisconnectFieldInput = {
  disconnect?: InputMaybe<OAuthAccessTokenDisconnectInput>;
  where?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
};

export type OAuthClientAccessTokensFieldInput = {
  connect?: InputMaybe<Array<OAuthClientAccessTokensConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OAuthClientAccessTokensConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OAuthClientAccessTokensCreateFieldInput>>;
};

export type OAuthClientAccessTokensNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<OAuthClientAccessTokensNodeAggregationWhereInput>>;
  NOT?: InputMaybe<OAuthClientAccessTokensNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<OAuthClientAccessTokensNodeAggregationWhereInput>>;
  accessToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  accessToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  accessToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  accessToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  accessToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  accessToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  accessToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  expiresAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshTokenExpiresAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  refreshToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  refreshToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  refreshToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  refreshToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  refreshToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  refreshToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type OAuthClientAccessTokensRelationship = {
  __typename?: "OAuthClientAccessTokensRelationship";
  cursor: Scalars["String"]["output"];
  node: OAuthAccessToken;
};

export type OAuthClientAccessTokensUpdateConnectionInput = {
  node?: InputMaybe<OAuthAccessTokenUpdateInput>;
};

export type OAuthClientAccessTokensUpdateFieldInput = {
  connect?: InputMaybe<Array<OAuthClientAccessTokensConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<OAuthClientAccessTokensConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<OAuthClientAccessTokensCreateFieldInput>>;
  delete?: InputMaybe<Array<OAuthClientAccessTokensDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<OAuthClientAccessTokensDisconnectFieldInput>>;
  update?: InputMaybe<OAuthClientAccessTokensUpdateConnectionInput>;
  where?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
};

export type OAuthClientAggregateSelection = {
  __typename?: "OAuthClientAggregateSelection";
  clientId: StringAggregateSelection;
  clientSecret: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type OAuthClientConnectInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensConnectFieldInput>>;
};

export type OAuthClientConnectOrCreateInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensConnectOrCreateFieldInput>>;
};

export type OAuthClientConnectOrCreateWhere = {
  node: OAuthClientUniqueWhere;
};

export type OAuthClientConnectWhere = {
  node: OAuthClientWhere;
};

export type OAuthClientCreateInput = {
  accessTokens?: InputMaybe<OAuthClientAccessTokensFieldInput>;
  clientId: Scalars["String"]["input"];
  clientSecret: Scalars["String"]["input"];
  created: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
};

export type OAuthClientDeleteInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensDeleteFieldInput>>;
};

export type OAuthClientDisconnectInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensDisconnectFieldInput>>;
};

export type OAuthClientEdge = {
  __typename?: "OAuthClientEdge";
  cursor: Scalars["String"]["output"];
  node: OAuthClient;
};

export type OAuthClientOAuthAccessTokenAccessTokensAggregationSelection = {
  __typename?: "OAuthClientOAuthAccessTokenAccessTokensAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<OAuthClientOAuthAccessTokenAccessTokensNodeAggregateSelection>;
};

export type OAuthClientOAuthAccessTokenAccessTokensNodeAggregateSelection = {
  __typename?: "OAuthClientOAuthAccessTokenAccessTokensNodeAggregateSelection";
  accessToken: StringAggregateSelection;
  expiresAt: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  refreshToken: StringAggregateSelection;
  refreshTokenExpiresAt: DateTimeAggregateSelection;
};

export type OAuthClientOnCreateInput = {
  clientId: Scalars["String"]["input"];
  clientSecret: Scalars["String"]["input"];
  created: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
};

export type OAuthClientOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more OAuthClientSort objects to sort OAuthClients by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<OAuthClientSort>>;
};

export type OAuthClientRelationInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensCreateFieldInput>>;
};

/** Fields to sort OAuthClients by. The order in which sorts are applied is not guaranteed when specifying many fields in one OAuthClientSort object. */
export type OAuthClientSort = {
  clientId?: InputMaybe<SortDirection>;
  clientSecret?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type OAuthClientUniqueWhere = {
  clientId?: InputMaybe<Scalars["String"]["input"]>;
};

export type OAuthClientUpdateInput = {
  accessTokens?: InputMaybe<Array<OAuthClientAccessTokensUpdateFieldInput>>;
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type OAuthClientWhere = {
  AND?: InputMaybe<Array<OAuthClientWhere>>;
  NOT?: InputMaybe<OAuthClientWhere>;
  OR?: InputMaybe<Array<OAuthClientWhere>>;
  accessTokensAggregate?: InputMaybe<OAuthClientAccessTokensAggregateInput>;
  /** Return OAuthClients where all of the related OAuthClientAccessTokensConnections match this filter */
  accessTokensConnection_ALL?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
  /** Return OAuthClients where none of the related OAuthClientAccessTokensConnections match this filter */
  accessTokensConnection_NONE?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
  /** Return OAuthClients where one of the related OAuthClientAccessTokensConnections match this filter */
  accessTokensConnection_SINGLE?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
  /** Return OAuthClients where some of the related OAuthClientAccessTokensConnections match this filter */
  accessTokensConnection_SOME?: InputMaybe<OAuthClientAccessTokensConnectionWhere>;
  /** Return OAuthClients where all of the related OAuthAccessTokens match this filter */
  accessTokens_ALL?: InputMaybe<OAuthAccessTokenWhere>;
  /** Return OAuthClients where none of the related OAuthAccessTokens match this filter */
  accessTokens_NONE?: InputMaybe<OAuthAccessTokenWhere>;
  /** Return OAuthClients where one of the related OAuthAccessTokens match this filter */
  accessTokens_SINGLE?: InputMaybe<OAuthAccessTokenWhere>;
  /** Return OAuthClients where some of the related OAuthAccessTokens match this filter */
  accessTokens_SOME?: InputMaybe<OAuthAccessTokenWhere>;
  clientId?: InputMaybe<Scalars["String"]["input"]>;
  clientId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  clientId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  clientId_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clientId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  clientSecret_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  clientSecret_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type OAuthClientsConnection = {
  __typename?: "OAuthClientsConnection";
  edges: Array<OAuthClientEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Objective = {
  __typename?: "Objective";
  aiTutors: Array<AiTutor>;
  aiTutorsAggregate?: Maybe<ObjectiveAiTutorAiTutorsAggregationSelection>;
  aiTutorsConnection: ObjectiveAiTutorsConnection;
  assignments: Array<Assignment>;
  assignmentsAggregate?: Maybe<ObjectiveAssignmentAssignmentsAggregationSelection>;
  assignmentsConnection: ObjectiveAssignmentsConnection;
  evalCriteria?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id: Scalars["ID"]["output"];
  objective?: Maybe<Scalars["String"]["output"]>;
  sequence?: Maybe<Scalars["Float"]["output"]>;
  userHasProgress: Array<User>;
  userHasProgressAggregate?: Maybe<ObjectiveUserUserHasProgressAggregationSelection>;
  userHasProgressConnection: ObjectiveUserHasProgressConnection;
  videos: Array<Video>;
  videosAggregate?: Maybe<ObjectiveVideoVideosAggregationSelection>;
  videosConnection: ObjectiveVideosConnection;
};

export type ObjectiveAiTutorsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AiTutorOptions>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ObjectiveAiTutorsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AiTutorWhere>;
};

export type ObjectiveAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ObjectiveAiTutorsConnectionSort>>;
  where?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
};

export type ObjectiveAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ObjectiveAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ObjectiveAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ObjectiveAssignmentsConnectionSort>>;
  where?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
};

export type ObjectiveUserHasProgressArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ObjectiveUserHasProgressConnectionSort>>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveVideosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<VideoOptions>;
  where?: InputMaybe<VideoWhere>;
};

export type ObjectiveVideosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<VideoWhere>;
};

export type ObjectiveVideosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ObjectiveVideosConnectionSort>>;
  where?: InputMaybe<ObjectiveVideosConnectionWhere>;
};

export type ObjectiveAiTutorAiTutorsAggregationSelection = {
  __typename?: "ObjectiveAITutorAiTutorsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ObjectiveAiTutorAiTutorsNodeAggregateSelection>;
};

export type ObjectiveAiTutorAiTutorsNodeAggregateSelection = {
  __typename?: "ObjectiveAITutorAiTutorsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type ObjectiveAggregateSelection = {
  __typename?: "ObjectiveAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type ObjectiveAiTutorsAggregateInput = {
  AND?: InputMaybe<Array<ObjectiveAiTutorsAggregateInput>>;
  NOT?: InputMaybe<ObjectiveAiTutorsAggregateInput>;
  OR?: InputMaybe<Array<ObjectiveAiTutorsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ObjectiveAiTutorsNodeAggregationWhereInput>;
};

export type ObjectiveAiTutorsConnectFieldInput = {
  connect?: InputMaybe<Array<AiTutorConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AiTutorConnectWhere>;
};

export type ObjectiveAiTutorsConnectOrCreateFieldInput = {
  onCreate: ObjectiveAiTutorsConnectOrCreateFieldInputOnCreate;
  where: AiTutorConnectOrCreateWhere;
};

export type ObjectiveAiTutorsConnectOrCreateFieldInputOnCreate = {
  node: AiTutorOnCreateInput;
};

export type ObjectiveAiTutorsConnection = {
  __typename?: "ObjectiveAiTutorsConnection";
  edges: Array<ObjectiveAiTutorsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveAiTutorsConnectionSort = {
  node?: InputMaybe<AiTutorSort>;
};

export type ObjectiveAiTutorsConnectionWhere = {
  AND?: InputMaybe<Array<ObjectiveAiTutorsConnectionWhere>>;
  NOT?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
  OR?: InputMaybe<Array<ObjectiveAiTutorsConnectionWhere>>;
  node?: InputMaybe<AiTutorWhere>;
};

export type ObjectiveAiTutorsCreateFieldInput = {
  node: AiTutorCreateInput;
};

export type ObjectiveAiTutorsDeleteFieldInput = {
  delete?: InputMaybe<AiTutorDeleteInput>;
  where?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
};

export type ObjectiveAiTutorsDisconnectFieldInput = {
  disconnect?: InputMaybe<AiTutorDisconnectInput>;
  where?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
};

export type ObjectiveAiTutorsFieldInput = {
  connect?: InputMaybe<Array<ObjectiveAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveAiTutorsCreateFieldInput>>;
};

export type ObjectiveAiTutorsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ObjectiveAiTutorsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ObjectiveAiTutorsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ObjectiveAiTutorsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveAiTutorsRelationship = {
  __typename?: "ObjectiveAiTutorsRelationship";
  cursor: Scalars["String"]["output"];
  node: AiTutor;
};

export type ObjectiveAiTutorsUpdateConnectionInput = {
  node?: InputMaybe<AiTutorUpdateInput>;
};

export type ObjectiveAiTutorsUpdateFieldInput = {
  connect?: InputMaybe<Array<ObjectiveAiTutorsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveAiTutorsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveAiTutorsCreateFieldInput>>;
  delete?: InputMaybe<Array<ObjectiveAiTutorsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ObjectiveAiTutorsDisconnectFieldInput>>;
  update?: InputMaybe<ObjectiveAiTutorsUpdateConnectionInput>;
  where?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
};

export type ObjectiveAnalytics = {
  __typename?: "ObjectiveAnalytics";
  average_time_taken?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
};

export type ObjectiveAnalyticsAggregateSelection = {
  __typename?: "ObjectiveAnalyticsAggregateSelection";
  average_time_taken: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type ObjectiveAnalyticsConnection = {
  __typename?: "ObjectiveAnalyticsConnection";
  edges: Array<ObjectiveAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveAnalyticsCreateInput = {
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
};

export type ObjectiveAnalyticsEdge = {
  __typename?: "ObjectiveAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveAnalytics;
};

export type ObjectiveAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveAnalyticsSort objects to sort ObjectiveAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveAnalyticsSort>>;
};

export type ObjectiveAnalyticsResponse = {
  __typename?: "ObjectiveAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ObjectiveAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ObjectiveAnalyticsResponseAggregateSelection = {
  __typename?: "ObjectiveAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ObjectiveAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ObjectiveAnalyticsResponseEdge = {
  __typename?: "ObjectiveAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveAnalyticsResponse;
};

export type ObjectiveAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveAnalyticsResponseSort objects to sort ObjectiveAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveAnalyticsResponseSort>>;
};

/** Fields to sort ObjectiveAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveAnalyticsResponseSort object. */
export type ObjectiveAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ObjectiveAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ObjectiveAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<ObjectiveAnalyticsResponseWhere>>;
  NOT?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<ObjectiveAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ObjectiveAnalyticsResponsesConnection = {
  __typename?: "ObjectiveAnalyticsResponsesConnection";
  edges: Array<ObjectiveAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ObjectiveAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveAnalyticsSort object. */
export type ObjectiveAnalyticsSort = {
  average_time_taken?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type ObjectiveAnalyticsUpdateInput = {
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveAnalyticsWhere = {
  AND?: InputMaybe<Array<ObjectiveAnalyticsWhere>>;
  NOT?: InputMaybe<ObjectiveAnalyticsWhere>;
  OR?: InputMaybe<Array<ObjectiveAnalyticsWhere>>;
  average_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveAssignmentAssignmentsAggregationSelection = {
  __typename?: "ObjectiveAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ObjectiveAssignmentAssignmentsNodeAggregateSelection>;
};

export type ObjectiveAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "ObjectiveAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ObjectiveAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<ObjectiveAssignmentsAggregateInput>>;
  NOT?: InputMaybe<ObjectiveAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<ObjectiveAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ObjectiveAssignmentsNodeAggregationWhereInput>;
};

export type ObjectiveAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type ObjectiveAssignmentsConnectOrCreateFieldInput = {
  onCreate: ObjectiveAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type ObjectiveAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type ObjectiveAssignmentsConnection = {
  __typename?: "ObjectiveAssignmentsConnection";
  edges: Array<ObjectiveAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type ObjectiveAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<ObjectiveAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<ObjectiveAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type ObjectiveAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type ObjectiveAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
};

export type ObjectiveAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
};

export type ObjectiveAssignmentsFieldInput = {
  connect?: InputMaybe<Array<ObjectiveAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveAssignmentsCreateFieldInput>>;
};

export type ObjectiveAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ObjectiveAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ObjectiveAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ObjectiveAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveAssignmentsRelationship = {
  __typename?: "ObjectiveAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type ObjectiveAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type ObjectiveAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<ObjectiveAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<ObjectiveAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ObjectiveAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<ObjectiveAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
};

export type ObjectiveCompletionDetails = {
  __typename?: "ObjectiveCompletionDetails";
  completionStats: CompletionStats;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type ObjectiveCompletionDetailsAggregateSelection = {
  __typename?: "ObjectiveCompletionDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type ObjectiveCompletionDetailsConnection = {
  __typename?: "ObjectiveCompletionDetailsConnection";
  edges: Array<ObjectiveCompletionDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveCompletionDetailsCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type ObjectiveCompletionDetailsEdge = {
  __typename?: "ObjectiveCompletionDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveCompletionDetails;
};

export type ObjectiveCompletionDetailsFull = {
  __typename?: "ObjectiveCompletionDetailsFull";
  aiTutor_id?: Maybe<Scalars["ID"]["output"]>;
  aiTutor_name?: Maybe<Scalars["String"]["output"]>;
  completionStats: CompletionStats;
  course_id?: Maybe<Scalars["ID"]["output"]>;
  course_name?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  module_id?: Maybe<Scalars["ID"]["output"]>;
  module_name?: Maybe<Scalars["String"]["output"]>;
};

export type ObjectiveCompletionDetailsFullAggregateSelection = {
  __typename?: "ObjectiveCompletionDetailsFullAggregateSelection";
  aiTutor_id: IdAggregateSelection;
  aiTutor_name: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  course_id: IdAggregateSelection;
  course_name: StringAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  module_id: IdAggregateSelection;
  module_name: StringAggregateSelection;
};

export type ObjectiveCompletionDetailsFullCreateInput = {
  aiTutor_id?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_name?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ObjectiveCompletionDetailsFullEdge = {
  __typename?: "ObjectiveCompletionDetailsFullEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveCompletionDetailsFull;
};

export type ObjectiveCompletionDetailsFullOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveCompletionDetailsFullSort objects to sort ObjectiveCompletionDetailsFulls by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveCompletionDetailsFullSort>>;
};

/** Fields to sort ObjectiveCompletionDetailsFulls by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveCompletionDetailsFullSort object. */
export type ObjectiveCompletionDetailsFullSort = {
  aiTutor_id?: InputMaybe<SortDirection>;
  aiTutor_name?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  course_name?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  module_id?: InputMaybe<SortDirection>;
  module_name?: InputMaybe<SortDirection>;
};

export type ObjectiveCompletionDetailsFullUpdateInput = {
  aiTutor_id?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_name?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ObjectiveCompletionDetailsFullWhere = {
  AND?: InputMaybe<Array<ObjectiveCompletionDetailsFullWhere>>;
  NOT?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
  OR?: InputMaybe<Array<ObjectiveCompletionDetailsFullWhere>>;
  aiTutor_id?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  aiTutor_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  aiTutor_name?: InputMaybe<Scalars["String"]["input"]>;
  aiTutor_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  aiTutor_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  aiTutor_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  aiTutor_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  course_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  module_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  module_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  module_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  module_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ObjectiveCompletionDetailsFullsConnection = {
  __typename?: "ObjectiveCompletionDetailsFullsConnection";
  edges: Array<ObjectiveCompletionDetailsFullEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveCompletionDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveCompletionDetailsSort objects to sort ObjectiveCompletionDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveCompletionDetailsSort>>;
};

export type ObjectiveCompletionDetailsResponse = {
  __typename?: "ObjectiveCompletionDetailsResponse";
  count: Scalars["Int"]["output"];
  data: Array<ObjectiveCompletionDetailsFull>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ObjectiveCompletionDetailsResponseAggregateSelection = {
  __typename?: "ObjectiveCompletionDetailsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ObjectiveCompletionDetailsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ObjectiveCompletionDetailsResponseEdge = {
  __typename?: "ObjectiveCompletionDetailsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveCompletionDetailsResponse;
};

export type ObjectiveCompletionDetailsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveCompletionDetailsResponseSort objects to sort ObjectiveCompletionDetailsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveCompletionDetailsResponseSort>>;
};

/** Fields to sort ObjectiveCompletionDetailsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveCompletionDetailsResponseSort object. */
export type ObjectiveCompletionDetailsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ObjectiveCompletionDetailsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ObjectiveCompletionDetailsResponseWhere = {
  AND?: InputMaybe<Array<ObjectiveCompletionDetailsResponseWhere>>;
  NOT?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
  OR?: InputMaybe<Array<ObjectiveCompletionDetailsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ObjectiveCompletionDetailsResponsesConnection = {
  __typename?: "ObjectiveCompletionDetailsResponsesConnection";
  edges: Array<ObjectiveCompletionDetailsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort ObjectiveCompletionDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveCompletionDetailsSort object. */
export type ObjectiveCompletionDetailsSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type ObjectiveCompletionDetailsUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ObjectiveCompletionDetailsWhere = {
  AND?: InputMaybe<Array<ObjectiveCompletionDetailsWhere>>;
  NOT?: InputMaybe<ObjectiveCompletionDetailsWhere>;
  OR?: InputMaybe<Array<ObjectiveCompletionDetailsWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ObjectiveConnectInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsConnectFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsConnectFieldInput>>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosConnectFieldInput>>;
};

export type ObjectiveConnectOrCreateInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsConnectOrCreateFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsConnectOrCreateFieldInput>>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressConnectOrCreateFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosConnectOrCreateFieldInput>>;
};

export type ObjectiveConnectOrCreateWhere = {
  node: ObjectiveUniqueWhere;
};

export type ObjectiveConnectWhere = {
  node: ObjectiveWhere;
};

export type ObjectiveCreateInput = {
  aiTutors?: InputMaybe<ObjectiveAiTutorsFieldInput>;
  assignments?: InputMaybe<ObjectiveAssignmentsFieldInput>;
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgress?: InputMaybe<ObjectiveUserHasProgressFieldInput>;
  videos?: InputMaybe<ObjectiveVideosFieldInput>;
};

export type ObjectiveDeleteInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsDeleteFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsDeleteFieldInput>>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressDeleteFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosDeleteFieldInput>>;
};

export type ObjectiveDisconnectInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsDisconnectFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsDisconnectFieldInput>>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressDisconnectFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosDisconnectFieldInput>>;
};

export type ObjectiveEdge = {
  __typename?: "ObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: Objective;
};

export type ObjectiveForVideoInput = {
  objective: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ObjectiveInput = {
  evalCriteria?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objective: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ObjectiveOnCreateInput = {
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveSort objects to sort Objectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveSort>>;
};

export type ObjectiveProgressAnalytics = {
  __typename?: "ObjectiveProgressAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  confusion_points: Scalars["Int"]["output"];
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  progress_details?: Maybe<ProgressDetails>;
  questions_asked: Scalars["Int"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
  success_points: Scalars["Int"]["output"];
};

export type ObjectiveProgressAnalyticsAggregateSelection = {
  __typename?: "ObjectiveProgressAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  status: StringAggregateSelection;
  success_points: IntAggregateSelection;
};

export type ObjectiveProgressAnalyticsConnection = {
  __typename?: "ObjectiveProgressAnalyticsConnection";
  edges: Array<ObjectiveProgressAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveProgressAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  success_points: Scalars["Int"]["input"];
};

export type ObjectiveProgressAnalyticsEdge = {
  __typename?: "ObjectiveProgressAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: ObjectiveProgressAnalytics;
};

export type ObjectiveProgressAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveProgressAnalyticsSort objects to sort ObjectiveProgressAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveProgressAnalyticsSort>>;
};

/** Fields to sort ObjectiveProgressAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveProgressAnalyticsSort object. */
export type ObjectiveProgressAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
};

export type ObjectiveProgressAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveProgressAnalyticsWhere = {
  AND?: InputMaybe<Array<ObjectiveProgressAnalyticsWhere>>;
  NOT?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
  OR?: InputMaybe<Array<ObjectiveProgressAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveRelationInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsCreateFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsCreateFieldInput>>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosCreateFieldInput>>;
};

/** Fields to sort Objectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveSort object. */
export type ObjectiveSort = {
  id?: InputMaybe<SortDirection>;
  objective?: InputMaybe<SortDirection>;
  sequence?: InputMaybe<SortDirection>;
};

export type ObjectiveUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ObjectiveUpdateInput = {
  aiTutors?: InputMaybe<Array<ObjectiveAiTutorsUpdateFieldInput>>;
  assignments?: InputMaybe<Array<ObjectiveAssignmentsUpdateFieldInput>>;
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  evalCriteria_POP?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressUpdateFieldInput>>;
  videos?: InputMaybe<Array<ObjectiveVideosUpdateFieldInput>>;
};

export type ObjectiveUserHasProgressAggregateInput = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressAggregateInput>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressAggregateInput>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasProgressPropertiesAggregationWhereInput>;
  node?: InputMaybe<ObjectiveUserHasProgressNodeAggregationWhereInput>;
};

export type ObjectiveUserHasProgressConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasProgressPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ObjectiveUserHasProgressConnectOrCreateFieldInput = {
  onCreate: ObjectiveUserHasProgressConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type ObjectiveUserHasProgressConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasProgressPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type ObjectiveUserHasProgressConnection = {
  __typename?: "ObjectiveUserHasProgressConnection";
  edges: Array<ObjectiveUserHasProgressRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveUserHasProgressConnectionSort = {
  edge?: InputMaybe<HasProgressPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ObjectiveUserHasProgressConnectionWhere = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressConnectionWhere>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressConnectionWhere>>;
  edge?: InputMaybe<HasProgressPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressCreateFieldInput = {
  edge?: InputMaybe<HasProgressPropertiesCreateInput>;
  node: UserCreateInput;
};

export type ObjectiveUserHasProgressDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserHasProgressDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserHasProgressFieldInput = {
  connect?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveUserHasProgressConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
};

export type ObjectiveUserHasProgressNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveUserHasProgressRelationship = {
  __typename?: "ObjectiveUserHasProgressRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasProgressProperties;
};

export type ObjectiveUserHasProgressUpdateConnectionInput = {
  edge?: InputMaybe<HasProgressPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ObjectiveUserHasProgressUpdateFieldInput = {
  connect?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveUserHasProgressConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
  delete?: InputMaybe<Array<ObjectiveUserHasProgressDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ObjectiveUserHasProgressDisconnectFieldInput>>;
  update?: InputMaybe<ObjectiveUserHasProgressUpdateConnectionInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserUserHasProgressAggregationSelection = {
  __typename?: "ObjectiveUserUserHasProgressAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ObjectiveUserUserHasProgressEdgeAggregateSelection>;
  node?: Maybe<ObjectiveUserUserHasProgressNodeAggregateSelection>;
};

export type ObjectiveUserUserHasProgressEdgeAggregateSelection = {
  __typename?: "ObjectiveUserUserHasProgressEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_question_complexity: StringAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  level: StringAggregateSelection;
  num_messages: IntAggregateSelection;
  originality_analysis: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment_analysis: StringAggregateSelection;
  status: StringAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ObjectiveUserUserHasProgressNodeAggregateSelection = {
  __typename?: "ObjectiveUserUserHasProgressNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type ObjectiveVideoVideosAggregationSelection = {
  __typename?: "ObjectiveVideoVideosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ObjectiveVideoVideosEdgeAggregateSelection>;
  node?: Maybe<ObjectiveVideoVideosNodeAggregateSelection>;
};

export type ObjectiveVideoVideosEdgeAggregateSelection = {
  __typename?: "ObjectiveVideoVideosEdgeAggregateSelection";
  relevanceScore: FloatAggregateSelection;
  timestamp: FloatAggregateSelection;
};

export type ObjectiveVideoVideosNodeAggregateSelection = {
  __typename?: "ObjectiveVideoVideosNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  fullTranscript: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  thumbnailUrl: StringAggregateSelection;
  title: StringAggregateSelection;
  url: StringAggregateSelection;
  videoId: StringAggregateSelection;
};

export type ObjectiveVideosAggregateInput = {
  AND?: InputMaybe<Array<ObjectiveVideosAggregateInput>>;
  NOT?: InputMaybe<ObjectiveVideosAggregateInput>;
  OR?: InputMaybe<Array<ObjectiveVideosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasVideoPropertiesAggregationWhereInput>;
  node?: InputMaybe<ObjectiveVideosNodeAggregationWhereInput>;
};

export type ObjectiveVideosConnectFieldInput = {
  connect?: InputMaybe<Array<VideoConnectInput>>;
  edge: HasVideoPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<VideoConnectWhere>;
};

export type ObjectiveVideosConnectOrCreateFieldInput = {
  onCreate: ObjectiveVideosConnectOrCreateFieldInputOnCreate;
  where: VideoConnectOrCreateWhere;
};

export type ObjectiveVideosConnectOrCreateFieldInputOnCreate = {
  edge: HasVideoPropertiesCreateInput;
  node: VideoOnCreateInput;
};

export type ObjectiveVideosConnection = {
  __typename?: "ObjectiveVideosConnection";
  edges: Array<ObjectiveVideosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveVideosConnectionSort = {
  edge?: InputMaybe<HasVideoPropertiesSort>;
  node?: InputMaybe<VideoSort>;
};

export type ObjectiveVideosConnectionWhere = {
  AND?: InputMaybe<Array<ObjectiveVideosConnectionWhere>>;
  NOT?: InputMaybe<ObjectiveVideosConnectionWhere>;
  OR?: InputMaybe<Array<ObjectiveVideosConnectionWhere>>;
  edge?: InputMaybe<HasVideoPropertiesWhere>;
  node?: InputMaybe<VideoWhere>;
};

export type ObjectiveVideosCreateFieldInput = {
  edge: HasVideoPropertiesCreateInput;
  node: VideoCreateInput;
};

export type ObjectiveVideosDeleteFieldInput = {
  delete?: InputMaybe<VideoDeleteInput>;
  where?: InputMaybe<ObjectiveVideosConnectionWhere>;
};

export type ObjectiveVideosDisconnectFieldInput = {
  disconnect?: InputMaybe<VideoDisconnectInput>;
  where?: InputMaybe<ObjectiveVideosConnectionWhere>;
};

export type ObjectiveVideosFieldInput = {
  connect?: InputMaybe<Array<ObjectiveVideosConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveVideosConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveVideosCreateFieldInput>>;
};

export type ObjectiveVideosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ObjectiveVideosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ObjectiveVideosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ObjectiveVideosNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullTranscript_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullTranscript_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnailUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveVideosRelationship = {
  __typename?: "ObjectiveVideosRelationship";
  cursor: Scalars["String"]["output"];
  node: Video;
  properties: HasVideoProperties;
};

export type ObjectiveVideosUpdateConnectionInput = {
  edge?: InputMaybe<HasVideoPropertiesUpdateInput>;
  node?: InputMaybe<VideoUpdateInput>;
};

export type ObjectiveVideosUpdateFieldInput = {
  connect?: InputMaybe<Array<ObjectiveVideosConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ObjectiveVideosConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ObjectiveVideosCreateFieldInput>>;
  delete?: InputMaybe<Array<ObjectiveVideosDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ObjectiveVideosDisconnectFieldInput>>;
  update?: InputMaybe<ObjectiveVideosUpdateConnectionInput>;
  where?: InputMaybe<ObjectiveVideosConnectionWhere>;
};

export type ObjectiveWhere = {
  AND?: InputMaybe<Array<ObjectiveWhere>>;
  NOT?: InputMaybe<ObjectiveWhere>;
  OR?: InputMaybe<Array<ObjectiveWhere>>;
  aiTutorsAggregate?: InputMaybe<ObjectiveAiTutorsAggregateInput>;
  /** Return Objectives where all of the related ObjectiveAiTutorsConnections match this filter */
  aiTutorsConnection_ALL?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
  /** Return Objectives where none of the related ObjectiveAiTutorsConnections match this filter */
  aiTutorsConnection_NONE?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
  /** Return Objectives where one of the related ObjectiveAiTutorsConnections match this filter */
  aiTutorsConnection_SINGLE?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
  /** Return Objectives where some of the related ObjectiveAiTutorsConnections match this filter */
  aiTutorsConnection_SOME?: InputMaybe<ObjectiveAiTutorsConnectionWhere>;
  /** Return Objectives where all of the related AITutors match this filter */
  aiTutors_ALL?: InputMaybe<AiTutorWhere>;
  /** Return Objectives where none of the related AITutors match this filter */
  aiTutors_NONE?: InputMaybe<AiTutorWhere>;
  /** Return Objectives where one of the related AITutors match this filter */
  aiTutors_SINGLE?: InputMaybe<AiTutorWhere>;
  /** Return Objectives where some of the related AITutors match this filter */
  aiTutors_SOME?: InputMaybe<AiTutorWhere>;
  assignmentsAggregate?: InputMaybe<ObjectiveAssignmentsAggregateInput>;
  /** Return Objectives where all of the related ObjectiveAssignmentsConnections match this filter */
  assignmentsConnection_ALL?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
  /** Return Objectives where none of the related ObjectiveAssignmentsConnections match this filter */
  assignmentsConnection_NONE?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
  /** Return Objectives where one of the related ObjectiveAssignmentsConnections match this filter */
  assignmentsConnection_SINGLE?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
  /** Return Objectives where some of the related ObjectiveAssignmentsConnections match this filter */
  assignmentsConnection_SOME?: InputMaybe<ObjectiveAssignmentsConnectionWhere>;
  /** Return Objectives where all of the related Assignments match this filter */
  assignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Objectives where none of the related Assignments match this filter */
  assignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Objectives where one of the related Assignments match this filter */
  assignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Objectives where some of the related Assignments match this filter */
  assignments_SOME?: InputMaybe<AssignmentWhere>;
  evalCriteria?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  evalCriteria_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sequence_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgressAggregate?: InputMaybe<ObjectiveUserHasProgressAggregateInput>;
  /** Return Objectives where all of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_ALL?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where none of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_NONE?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where one of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_SINGLE?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where some of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_SOME?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where all of the related Users match this filter */
  userHasProgress_ALL?: InputMaybe<UserWhere>;
  /** Return Objectives where none of the related Users match this filter */
  userHasProgress_NONE?: InputMaybe<UserWhere>;
  /** Return Objectives where one of the related Users match this filter */
  userHasProgress_SINGLE?: InputMaybe<UserWhere>;
  /** Return Objectives where some of the related Users match this filter */
  userHasProgress_SOME?: InputMaybe<UserWhere>;
  videosAggregate?: InputMaybe<ObjectiveVideosAggregateInput>;
  /** Return Objectives where all of the related ObjectiveVideosConnections match this filter */
  videosConnection_ALL?: InputMaybe<ObjectiveVideosConnectionWhere>;
  /** Return Objectives where none of the related ObjectiveVideosConnections match this filter */
  videosConnection_NONE?: InputMaybe<ObjectiveVideosConnectionWhere>;
  /** Return Objectives where one of the related ObjectiveVideosConnections match this filter */
  videosConnection_SINGLE?: InputMaybe<ObjectiveVideosConnectionWhere>;
  /** Return Objectives where some of the related ObjectiveVideosConnections match this filter */
  videosConnection_SOME?: InputMaybe<ObjectiveVideosConnectionWhere>;
  /** Return Objectives where all of the related Videos match this filter */
  videos_ALL?: InputMaybe<VideoWhere>;
  /** Return Objectives where none of the related Videos match this filter */
  videos_NONE?: InputMaybe<VideoWhere>;
  /** Return Objectives where one of the related Videos match this filter */
  videos_SINGLE?: InputMaybe<VideoWhere>;
  /** Return Objectives where some of the related Videos match this filter */
  videos_SOME?: InputMaybe<VideoWhere>;
};

export type ObjectivesConnection = {
  __typename?: "ObjectivesConnection";
  edges: Array<ObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Page = {
  __typename?: "Page";
  _empty?: Maybe<Scalars["String"]["output"]>;
  assignee_mode?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  coursesHasPage: Array<Course>;
  coursesHasPageAggregate?: Maybe<PageCourseCoursesHasPageAggregationSelection>;
  coursesHasPageConnection: PageCoursesHasPageConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  editing_roles?: Maybe<Scalars["String"]["output"]>;
  embedding?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  front_page?: Maybe<Scalars["Boolean"]["output"]>;
  hide_from_students?: Maybe<Scalars["Boolean"]["output"]>;
  html_url?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  last_edited_by_anonymous_id?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_avatar_image_url?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_display_name?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_html_url?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_id?: Maybe<Scalars["Float"]["output"]>;
  locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  moduleHasPages: Array<Module>;
  moduleHasPagesAggregate?: Maybe<PageModuleModuleHasPagesAggregationSelection>;
  moduleHasPagesConnection: PageModuleHasPagesConnection;
  page_id?: Maybe<Scalars["Float"]["output"]>;
  published?: Maybe<Scalars["Boolean"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  updated_at?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolPage: Array<User>;
  usersHasSchoolPageAggregate?: Maybe<PageUserUsersHasSchoolPageAggregationSelection>;
  usersHasSchoolPageConnection: PageUsersHasSchoolPageConnection;
};

export type PageCoursesHasPageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageCoursesHasPageConnectionSort>>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageModuleHasPagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type PageModuleHasPagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type PageModuleHasPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageModuleHasPagesConnectionSort>>;
  where?: InputMaybe<PageModuleHasPagesConnectionWhere>;
};

export type PageUsersHasSchoolPageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageUsersHasSchoolPageConnectionSort>>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageAggregateSelection = {
  __typename?: "PageAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type PageConnectInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesConnectFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
};

export type PageConnectOrCreateInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesConnectOrCreateFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageConnectOrCreateFieldInput>>;
};

export type PageConnectOrCreateWhere = {
  node: PageUniqueWhere;
};

export type PageConnectWhere = {
  node: PageWhere;
};

export type PageCourseCoursesHasPageAggregationSelection = {
  __typename?: "PageCourseCoursesHasPageAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageCourseCoursesHasPageNodeAggregateSelection>;
};

export type PageCourseCoursesHasPageNodeAggregateSelection = {
  __typename?: "PageCourseCoursesHasPageNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type PageCoursesHasPageAggregateInput = {
  AND?: InputMaybe<Array<PageCoursesHasPageAggregateInput>>;
  NOT?: InputMaybe<PageCoursesHasPageAggregateInput>;
  OR?: InputMaybe<Array<PageCoursesHasPageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageCoursesHasPageNodeAggregationWhereInput>;
};

export type PageCoursesHasPageConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type PageCoursesHasPageConnectOrCreateFieldInput = {
  onCreate: PageCoursesHasPageConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type PageCoursesHasPageConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type PageCoursesHasPageConnection = {
  __typename?: "PageCoursesHasPageConnection";
  edges: Array<PageCoursesHasPageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageCoursesHasPageConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type PageCoursesHasPageConnectionWhere = {
  AND?: InputMaybe<Array<PageCoursesHasPageConnectionWhere>>;
  NOT?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  OR?: InputMaybe<Array<PageCoursesHasPageConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageCreateFieldInput = {
  node: CourseCreateInput;
};

export type PageCoursesHasPageDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCoursesHasPageDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCoursesHasPageFieldInput = {
  connect?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
};

export type PageCoursesHasPageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageCoursesHasPageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageCoursesHasPageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageCoursesHasPageNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageCoursesHasPageRelationship = {
  __typename?: "PageCoursesHasPageRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type PageCoursesHasPageUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type PageCoursesHasPageUpdateFieldInput = {
  connect?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
  delete?: InputMaybe<Array<PageCoursesHasPageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageCoursesHasPageDisconnectFieldInput>>;
  update?: InputMaybe<PageCoursesHasPageUpdateConnectionInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPage?: InputMaybe<PageCoursesHasPageFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasPages?: InputMaybe<PageModuleHasPagesFieldInput>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPage?: InputMaybe<PageUsersHasSchoolPageFieldInput>;
};

export type PageDeleteInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageDeleteFieldInput>>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesDeleteFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageDeleteFieldInput>>;
};

export type PageDisconnectInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageDisconnectFieldInput>>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesDisconnectFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageDisconnectFieldInput>>;
};

export type PageEdge = {
  __typename?: "PageEdge";
  cursor: Scalars["String"]["output"];
  node: Page;
};

/** Pagination information (Relay) */
export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PageModuleHasPagesAggregateInput = {
  AND?: InputMaybe<Array<PageModuleHasPagesAggregateInput>>;
  NOT?: InputMaybe<PageModuleHasPagesAggregateInput>;
  OR?: InputMaybe<Array<PageModuleHasPagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageModuleHasPagesNodeAggregationWhereInput>;
};

export type PageModuleHasPagesConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type PageModuleHasPagesConnectOrCreateFieldInput = {
  onCreate: PageModuleHasPagesConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type PageModuleHasPagesConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type PageModuleHasPagesConnection = {
  __typename?: "PageModuleHasPagesConnection";
  edges: Array<PageModuleHasPagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageModuleHasPagesConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type PageModuleHasPagesConnectionWhere = {
  AND?: InputMaybe<Array<PageModuleHasPagesConnectionWhere>>;
  NOT?: InputMaybe<PageModuleHasPagesConnectionWhere>;
  OR?: InputMaybe<Array<PageModuleHasPagesConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type PageModuleHasPagesCreateFieldInput = {
  node: ModuleCreateInput;
};

export type PageModuleHasPagesDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<PageModuleHasPagesConnectionWhere>;
};

export type PageModuleHasPagesDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<PageModuleHasPagesConnectionWhere>;
};

export type PageModuleHasPagesFieldInput = {
  connect?: InputMaybe<Array<PageModuleHasPagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageModuleHasPagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageModuleHasPagesCreateFieldInput>>;
};

export type PageModuleHasPagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageModuleHasPagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageModuleHasPagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageModuleHasPagesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageModuleHasPagesRelationship = {
  __typename?: "PageModuleHasPagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type PageModuleHasPagesUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type PageModuleHasPagesUpdateFieldInput = {
  connect?: InputMaybe<Array<PageModuleHasPagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageModuleHasPagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageModuleHasPagesCreateFieldInput>>;
  delete?: InputMaybe<Array<PageModuleHasPagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageModuleHasPagesDisconnectFieldInput>>;
  update?: InputMaybe<PageModuleHasPagesUpdateConnectionInput>;
  where?: InputMaybe<PageModuleHasPagesConnectionWhere>;
};

export type PageModuleModuleHasPagesAggregationSelection = {
  __typename?: "PageModuleModuleHasPagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageModuleModuleHasPagesNodeAggregateSelection>;
};

export type PageModuleModuleHasPagesNodeAggregateSelection = {
  __typename?: "PageModuleModuleHasPagesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type PageOnCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PageSort objects to sort Pages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PageSort>>;
};

export type PageRelationInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesCreateFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
};

export type PageResponse = {
  __typename?: "PageResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Page>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PageResponseAggregateSelection = {
  __typename?: "PageResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type PageResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponseEdge = {
  __typename?: "PageResponseEdge";
  cursor: Scalars["String"]["output"];
  node: PageResponse;
};

export type PageResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PageResponseSort objects to sort PageResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PageResponseSort>>;
};

/** Fields to sort PageResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one PageResponseSort object. */
export type PageResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type PageResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponseWhere = {
  AND?: InputMaybe<Array<PageResponseWhere>>;
  NOT?: InputMaybe<PageResponseWhere>;
  OR?: InputMaybe<Array<PageResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponsesConnection = {
  __typename?: "PageResponsesConnection";
  edges: Array<PageResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Pages by. The order in which sorts are applied is not guaranteed when specifying many fields in one PageSort object. */
export type PageSort = {
  _empty?: InputMaybe<SortDirection>;
  assignee_mode?: InputMaybe<SortDirection>;
  body?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  editing_roles?: InputMaybe<SortDirection>;
  front_page?: InputMaybe<SortDirection>;
  hide_from_students?: InputMaybe<SortDirection>;
  html_url?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  last_edited_by_anonymous_id?: InputMaybe<SortDirection>;
  last_edited_by_avatar_image_url?: InputMaybe<SortDirection>;
  last_edited_by_display_name?: InputMaybe<SortDirection>;
  last_edited_by_html_url?: InputMaybe<SortDirection>;
  last_edited_by_id?: InputMaybe<SortDirection>;
  locked_for_user?: InputMaybe<SortDirection>;
  page_id?: InputMaybe<SortDirection>;
  published?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type PageUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PageUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasPages?: InputMaybe<Array<PageModuleHasPagesUpdateFieldInput>>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageUpdateFieldInput>>;
};

export type PageUserUsersHasSchoolPageAggregationSelection = {
  __typename?: "PageUserUsersHasSchoolPageAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageUserUsersHasSchoolPageNodeAggregateSelection>;
};

export type PageUserUsersHasSchoolPageNodeAggregateSelection = {
  __typename?: "PageUserUsersHasSchoolPageNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type PageUsersHasSchoolPageAggregateInput = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageAggregateInput>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageAggregateInput>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageUsersHasSchoolPageNodeAggregationWhereInput>;
};

export type PageUsersHasSchoolPageConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PageUsersHasSchoolPageConnectOrCreateFieldInput = {
  onCreate: PageUsersHasSchoolPageConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type PageUsersHasSchoolPageConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type PageUsersHasSchoolPageConnection = {
  __typename?: "PageUsersHasSchoolPageConnection";
  edges: Array<PageUsersHasSchoolPageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageUsersHasSchoolPageConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type PageUsersHasSchoolPageConnectionWhere = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageConnectionWhere>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageCreateFieldInput = {
  node: UserCreateInput;
};

export type PageUsersHasSchoolPageDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageUsersHasSchoolPageDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageUsersHasSchoolPageFieldInput = {
  connect?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageUsersHasSchoolPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
};

export type PageUsersHasSchoolPageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageUsersHasSchoolPageRelationship = {
  __typename?: "PageUsersHasSchoolPageRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type PageUsersHasSchoolPageUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type PageUsersHasSchoolPageUpdateFieldInput = {
  connect?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageUsersHasSchoolPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
  delete?: InputMaybe<Array<PageUsersHasSchoolPageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageUsersHasSchoolPageDisconnectFieldInput>>;
  update?: InputMaybe<PageUsersHasSchoolPageUpdateConnectionInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageWhere = {
  AND?: InputMaybe<Array<PageWhere>>;
  NOT?: InputMaybe<PageWhere>;
  OR?: InputMaybe<Array<PageWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  body_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  body_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  body_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPageAggregate?: InputMaybe<PageCoursesHasPageAggregateInput>;
  /** Return Pages where all of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_ALL?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where none of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_NONE?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where one of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_SINGLE?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where some of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_SOME?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where all of the related Courses match this filter */
  coursesHasPage_ALL?: InputMaybe<CourseWhere>;
  /** Return Pages where none of the related Courses match this filter */
  coursesHasPage_NONE?: InputMaybe<CourseWhere>;
  /** Return Pages where one of the related Courses match this filter */
  coursesHasPage_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Pages where some of the related Courses match this filter */
  coursesHasPage_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  editing_roles_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_anonymous_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_avatar_image_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_display_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  last_edited_by_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  moduleHasPagesAggregate?: InputMaybe<PageModuleHasPagesAggregateInput>;
  /** Return Pages where all of the related PageModuleHasPagesConnections match this filter */
  moduleHasPagesConnection_ALL?: InputMaybe<PageModuleHasPagesConnectionWhere>;
  /** Return Pages where none of the related PageModuleHasPagesConnections match this filter */
  moduleHasPagesConnection_NONE?: InputMaybe<PageModuleHasPagesConnectionWhere>;
  /** Return Pages where one of the related PageModuleHasPagesConnections match this filter */
  moduleHasPagesConnection_SINGLE?: InputMaybe<PageModuleHasPagesConnectionWhere>;
  /** Return Pages where some of the related PageModuleHasPagesConnections match this filter */
  moduleHasPagesConnection_SOME?: InputMaybe<PageModuleHasPagesConnectionWhere>;
  /** Return Pages where all of the related Modules match this filter */
  moduleHasPages_ALL?: InputMaybe<ModuleWhere>;
  /** Return Pages where none of the related Modules match this filter */
  moduleHasPages_NONE?: InputMaybe<ModuleWhere>;
  /** Return Pages where one of the related Modules match this filter */
  moduleHasPages_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return Pages where some of the related Modules match this filter */
  moduleHasPages_SOME?: InputMaybe<ModuleWhere>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  page_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPageAggregate?: InputMaybe<PageUsersHasSchoolPageAggregateInput>;
  /** Return Pages where all of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_ALL?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where none of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_NONE?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where one of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_SINGLE?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where some of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_SOME?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where all of the related Users match this filter */
  usersHasSchoolPage_ALL?: InputMaybe<UserWhere>;
  /** Return Pages where none of the related Users match this filter */
  usersHasSchoolPage_NONE?: InputMaybe<UserWhere>;
  /** Return Pages where one of the related Users match this filter */
  usersHasSchoolPage_SINGLE?: InputMaybe<UserWhere>;
  /** Return Pages where some of the related Users match this filter */
  usersHasSchoolPage_SOME?: InputMaybe<UserWhere>;
};

export type PagesConnection = {
  __typename?: "PagesConnection";
  edges: Array<PageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ParametersType = {
  __typename?: "ParametersType";
  lastGeneratedLabel?: Maybe<Scalars["String"]["output"]>;
  lastGeneratedTopic?: Maybe<Scalars["String"]["output"]>;
  questContext?: Maybe<Scalars["String"]["output"]>;
  userResponse?: Maybe<Scalars["String"]["output"]>;
};

export type ParametersTypeAggregateSelection = {
  __typename?: "ParametersTypeAggregateSelection";
  count: Scalars["Int"]["output"];
  lastGeneratedLabel: StringAggregateSelection;
  lastGeneratedTopic: StringAggregateSelection;
  questContext: StringAggregateSelection;
  userResponse: StringAggregateSelection;
};

export type ParametersTypeCreateInput = {
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypeEdge = {
  __typename?: "ParametersTypeEdge";
  cursor: Scalars["String"]["output"];
  node: ParametersType;
};

export type ParametersTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ParametersTypeSort objects to sort ParametersTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ParametersTypeSort>>;
};

/** Fields to sort ParametersTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ParametersTypeSort object. */
export type ParametersTypeSort = {
  lastGeneratedLabel?: InputMaybe<SortDirection>;
  lastGeneratedTopic?: InputMaybe<SortDirection>;
  questContext?: InputMaybe<SortDirection>;
  userResponse?: InputMaybe<SortDirection>;
};

export type ParametersTypeUpdateInput = {
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypeWhere = {
  AND?: InputMaybe<Array<ParametersTypeWhere>>;
  NOT?: InputMaybe<ParametersTypeWhere>;
  OR?: InputMaybe<Array<ParametersTypeWhere>>;
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastGeneratedLabel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastGeneratedTopic_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  questContext_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  questContext_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questContext_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  questContext_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userResponse_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypesConnection = {
  __typename?: "ParametersTypesConnection";
  edges: Array<ParametersTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ParentRef = {
  __typename?: "ParentRef";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
};

export type ParentRefAggregateSelection = {
  __typename?: "ParentRefAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
};

export type ParentRefCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
};

export type ParentRefEdge = {
  __typename?: "ParentRefEdge";
  cursor: Scalars["String"]["output"];
  node: ParentRef;
};

export type ParentRefOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ParentRefSort objects to sort ParentRefs by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ParentRefSort>>;
};

/** Fields to sort ParentRefs by. The order in which sorts are applied is not guaranteed when specifying many fields in one ParentRefSort object. */
export type ParentRefSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
};

export type ParentRefUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParentRefWhere = {
  AND?: InputMaybe<Array<ParentRefWhere>>;
  NOT?: InputMaybe<ParentRefWhere>;
  OR?: InputMaybe<Array<ParentRefWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParentRefsConnection = {
  __typename?: "ParentRefsConnection";
  edges: Array<ParentRefEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Payment = {
  __typename?: "Payment";
  domain: Scalars["String"]["output"];
  usersHasPayment: Array<User>;
  usersHasPaymentAggregate?: Maybe<PaymentUserUsersHasPaymentAggregationSelection>;
  usersHasPaymentConnection: PaymentUsersHasPaymentConnection;
};

export type PaymentUsersHasPaymentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PaymentUsersHasPaymentConnectionSort>>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentAggregateSelection = {
  __typename?: "PaymentAggregateSelection";
  count: Scalars["Int"]["output"];
  domain: StringAggregateSelection;
};

export type PaymentConnectInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
};

export type PaymentConnectOrCreateInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentConnectOrCreateFieldInput>>;
};

export type PaymentConnectWhere = {
  node: PaymentWhere;
};

export type PaymentCreateInput = {
  domain: Scalars["String"]["input"];
  usersHasPayment?: InputMaybe<PaymentUsersHasPaymentFieldInput>;
};

export type PaymentDeleteInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentDeleteFieldInput>>;
};

export type PaymentDisconnectInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentDisconnectFieldInput>>;
};

export type PaymentEdge = {
  __typename?: "PaymentEdge";
  cursor: Scalars["String"]["output"];
  node: Payment;
};

export type PaymentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PaymentSort objects to sort Payments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PaymentSort>>;
};

export type PaymentRelationInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
};

/** Fields to sort Payments by. The order in which sorts are applied is not guaranteed when specifying many fields in one PaymentSort object. */
export type PaymentSort = {
  domain?: InputMaybe<SortDirection>;
};

export type PaymentUpdateInput = {
  domain?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentUpdateFieldInput>>;
};

export type PaymentUserUsersHasPaymentAggregationSelection = {
  __typename?: "PaymentUserUsersHasPaymentAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<PaymentUserUsersHasPaymentEdgeAggregateSelection>;
  node?: Maybe<PaymentUserUsersHasPaymentNodeAggregateSelection>;
};

export type PaymentUserUsersHasPaymentEdgeAggregateSelection = {
  __typename?: "PaymentUserUsersHasPaymentEdgeAggregateSelection";
  integrationId: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
};

export type PaymentUserUsersHasPaymentNodeAggregateSelection = {
  __typename?: "PaymentUserUsersHasPaymentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type PaymentUsersHasPaymentAggregateInput = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentAggregateInput>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentAggregateInput>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  node?: InputMaybe<PaymentUsersHasPaymentNodeAggregationWhereInput>;
};

export type PaymentUsersHasPaymentConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasPaymentPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PaymentUsersHasPaymentConnectOrCreateFieldInput = {
  onCreate: PaymentUsersHasPaymentConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type PaymentUsersHasPaymentConnectOrCreateFieldInputOnCreate = {
  edge: HasPaymentPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type PaymentUsersHasPaymentConnection = {
  __typename?: "PaymentUsersHasPaymentConnection";
  edges: Array<PaymentUsersHasPaymentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaymentUsersHasPaymentConnectionSort = {
  edge?: InputMaybe<HasPaymentPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type PaymentUsersHasPaymentConnectionWhere = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentConnectionWhere>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentConnectionWhere>>;
  edge?: InputMaybe<HasPaymentPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentCreateFieldInput = {
  edge: HasPaymentPropertiesCreateInput;
  node: UserCreateInput;
};

export type PaymentUsersHasPaymentDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentUsersHasPaymentDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentUsersHasPaymentFieldInput = {
  connect?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentUsersHasPaymentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
};

export type PaymentUsersHasPaymentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaymentUsersHasPaymentRelationship = {
  __typename?: "PaymentUsersHasPaymentRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasPaymentProperties;
};

export type PaymentUsersHasPaymentUpdateConnectionInput = {
  edge?: InputMaybe<HasPaymentPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type PaymentUsersHasPaymentUpdateFieldInput = {
  connect?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PaymentUsersHasPaymentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
  delete?: InputMaybe<Array<PaymentUsersHasPaymentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PaymentUsersHasPaymentDisconnectFieldInput>>;
  update?: InputMaybe<PaymentUsersHasPaymentUpdateConnectionInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentWhere = {
  AND?: InputMaybe<Array<PaymentWhere>>;
  NOT?: InputMaybe<PaymentWhere>;
  OR?: InputMaybe<Array<PaymentWhere>>;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  domain_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  domain_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  domain_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  domain_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPaymentAggregate?: InputMaybe<PaymentUsersHasPaymentAggregateInput>;
  /** Return Payments where all of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_ALL?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where none of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_NONE?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where one of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_SINGLE?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where some of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_SOME?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where all of the related Users match this filter */
  usersHasPayment_ALL?: InputMaybe<UserWhere>;
  /** Return Payments where none of the related Users match this filter */
  usersHasPayment_NONE?: InputMaybe<UserWhere>;
  /** Return Payments where one of the related Users match this filter */
  usersHasPayment_SINGLE?: InputMaybe<UserWhere>;
  /** Return Payments where some of the related Users match this filter */
  usersHasPayment_SOME?: InputMaybe<UserWhere>;
};

export type PaymentsConnection = {
  __typename?: "PaymentsConnection";
  edges: Array<PaymentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Permission = {
  __typename?: "Permission";
  name: Scalars["String"]["output"];
  rolesHasPermission: Array<Role>;
  rolesHasPermissionAggregate?: Maybe<PermissionRoleRolesHasPermissionAggregationSelection>;
  rolesHasPermissionConnection: PermissionRolesHasPermissionConnection;
};

export type PermissionRolesHasPermissionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PermissionRolesHasPermissionConnectionSort>>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionAggregateSelection = {
  __typename?: "PermissionAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type PermissionConnectInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
};

export type PermissionConnectWhere = {
  node: PermissionWhere;
};

export type PermissionCreateInput = {
  name: Scalars["String"]["input"];
  rolesHasPermission?: InputMaybe<PermissionRolesHasPermissionFieldInput>;
};

export type PermissionDeleteInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionDeleteFieldInput>>;
};

export type PermissionDisconnectInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionDisconnectFieldInput>>;
};

export type PermissionEdge = {
  __typename?: "PermissionEdge";
  cursor: Scalars["String"]["output"];
  node: Permission;
};

export type PermissionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PermissionSort objects to sort Permissions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PermissionSort>>;
};

export type PermissionRelationInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
};

export type PermissionRoleRolesHasPermissionAggregationSelection = {
  __typename?: "PermissionRoleRolesHasPermissionAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PermissionRoleRolesHasPermissionNodeAggregateSelection>;
};

export type PermissionRoleRolesHasPermissionNodeAggregateSelection = {
  __typename?: "PermissionRoleRolesHasPermissionNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type PermissionRolesHasPermissionAggregateInput = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionAggregateInput>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionAggregateInput>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PermissionRolesHasPermissionNodeAggregationWhereInput>;
};

export type PermissionRolesHasPermissionConnectFieldInput = {
  connect?: InputMaybe<Array<RoleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<RoleConnectWhere>;
};

export type PermissionRolesHasPermissionConnection = {
  __typename?: "PermissionRolesHasPermissionConnection";
  edges: Array<PermissionRolesHasPermissionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PermissionRolesHasPermissionConnectionSort = {
  node?: InputMaybe<RoleSort>;
};

export type PermissionRolesHasPermissionConnectionWhere = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionConnectionWhere>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionConnectionWhere>>;
  node?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionCreateFieldInput = {
  node: RoleCreateInput;
};

export type PermissionRolesHasPermissionDeleteFieldInput = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionRolesHasPermissionDisconnectFieldInput = {
  disconnect?: InputMaybe<RoleDisconnectInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionRolesHasPermissionFieldInput = {
  connect?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
  create?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
};

export type PermissionRolesHasPermissionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PermissionRolesHasPermissionRelationship = {
  __typename?: "PermissionRolesHasPermissionRelationship";
  cursor: Scalars["String"]["output"];
  node: Role;
};

export type PermissionRolesHasPermissionUpdateConnectionInput = {
  node?: InputMaybe<RoleUpdateInput>;
};

export type PermissionRolesHasPermissionUpdateFieldInput = {
  connect?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
  create?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
  delete?: InputMaybe<Array<PermissionRolesHasPermissionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PermissionRolesHasPermissionDisconnectFieldInput>>;
  update?: InputMaybe<PermissionRolesHasPermissionUpdateConnectionInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

/** Fields to sort Permissions by. The order in which sorts are applied is not guaranteed when specifying many fields in one PermissionSort object. */
export type PermissionSort = {
  name?: InputMaybe<SortDirection>;
};

export type PermissionUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionUpdateFieldInput>>;
};

export type PermissionWhere = {
  AND?: InputMaybe<Array<PermissionWhere>>;
  NOT?: InputMaybe<PermissionWhere>;
  OR?: InputMaybe<Array<PermissionWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  rolesHasPermissionAggregate?: InputMaybe<PermissionRolesHasPermissionAggregateInput>;
  /** Return Permissions where all of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_ALL?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where none of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_NONE?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where one of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_SINGLE?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where some of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_SOME?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where all of the related Roles match this filter */
  rolesHasPermission_ALL?: InputMaybe<RoleWhere>;
  /** Return Permissions where none of the related Roles match this filter */
  rolesHasPermission_NONE?: InputMaybe<RoleWhere>;
  /** Return Permissions where one of the related Roles match this filter */
  rolesHasPermission_SINGLE?: InputMaybe<RoleWhere>;
  /** Return Permissions where some of the related Roles match this filter */
  rolesHasPermission_SOME?: InputMaybe<RoleWhere>;
};

export type PermissionsConnection = {
  __typename?: "PermissionsConnection";
  edges: Array<PermissionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PersonalityTrait = {
  __typename?: "PersonalityTrait";
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type PersonalityTraitAggregateSelection = {
  __typename?: "PersonalityTraitAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  value: IntAggregateSelection;
};

export type PersonalityTraitCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitEdge = {
  __typename?: "PersonalityTraitEdge";
  cursor: Scalars["String"]["output"];
  node: PersonalityTrait;
};

export type PersonalityTraitOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PersonalityTraitSort objects to sort PersonalityTraits by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PersonalityTraitSort>>;
};

/** Fields to sort PersonalityTraits by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonalityTraitSort object. */
export type PersonalityTraitSort = {
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  value?: InputMaybe<SortDirection>;
};

export type PersonalityTraitUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  value_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitWhere = {
  AND?: InputMaybe<Array<PersonalityTraitWhere>>;
  NOT?: InputMaybe<PersonalityTraitWhere>;
  OR?: InputMaybe<Array<PersonalityTraitWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_GT?: InputMaybe<Scalars["Int"]["input"]>;
  value_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  value_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  value_LT?: InputMaybe<Scalars["Int"]["input"]>;
  value_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitsConnection = {
  __typename?: "PersonalityTraitsConnection";
  edges: Array<PersonalityTraitEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Preferences = {
  __typename?: "Preferences";
  contentType?: Maybe<Scalars["String"]["output"]>;
  language: Scalars["String"]["output"];
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  usersHasPreferences: Array<User>;
  usersHasPreferencesAggregate?: Maybe<PreferencesUserUsersHasPreferencesAggregationSelection>;
  usersHasPreferencesConnection: PreferencesUsersHasPreferencesConnection;
};

export type PreferencesUsersHasPreferencesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionSort>>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesAggregateSelection = {
  __typename?: "PreferencesAggregateSelection";
  contentType: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  language: StringAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
};

export type PreferencesConnectInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
};

export type PreferencesConnectOrCreateInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectOrCreateFieldInput>>;
};

export type PreferencesConnectWhere = {
  node: PreferencesWhere;
};

export type PreferencesConnection = {
  __typename?: "PreferencesConnection";
  edges: Array<PreferencesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PreferencesCreateInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferences?: InputMaybe<PreferencesUsersHasPreferencesFieldInput>;
};

export type PreferencesDeleteInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesDeleteFieldInput>>;
};

export type PreferencesDisconnectInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesDisconnectFieldInput>>;
};

export type PreferencesEdge = {
  __typename?: "PreferencesEdge";
  cursor: Scalars["String"]["output"];
  node: Preferences;
};

export type PreferencesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PreferencesSort objects to sort Preferences by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PreferencesSort>>;
};

export type PreferencesRelationInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
};

/** Fields to sort Preferences by. The order in which sorts are applied is not guaranteed when specifying many fields in one PreferencesSort object. */
export type PreferencesSort = {
  contentType?: InputMaybe<SortDirection>;
  language?: InputMaybe<SortDirection>;
  learningDuration?: InputMaybe<SortDirection>;
  learningStyle?: InputMaybe<SortDirection>;
  notificationPreferences?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
};

export type PreferencesUpdateInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesUpdateFieldInput>>;
};

export type PreferencesUserUsersHasPreferencesAggregationSelection = {
  __typename?: "PreferencesUserUsersHasPreferencesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PreferencesUserUsersHasPreferencesNodeAggregateSelection>;
};

export type PreferencesUserUsersHasPreferencesNodeAggregateSelection = {
  __typename?: "PreferencesUserUsersHasPreferencesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type PreferencesUsersHasPreferencesAggregateInput = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesAggregateInput>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesAggregateInput>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PreferencesUsersHasPreferencesNodeAggregationWhereInput>;
};

export type PreferencesUsersHasPreferencesConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PreferencesUsersHasPreferencesConnectOrCreateFieldInput = {
  onCreate: PreferencesUsersHasPreferencesConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type PreferencesUsersHasPreferencesConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type PreferencesUsersHasPreferencesConnection = {
  __typename?: "PreferencesUsersHasPreferencesConnection";
  edges: Array<PreferencesUsersHasPreferencesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PreferencesUsersHasPreferencesConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type PreferencesUsersHasPreferencesConnectionWhere = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionWhere>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesCreateFieldInput = {
  node: UserCreateInput;
};

export type PreferencesUsersHasPreferencesDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesUsersHasPreferencesDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesUsersHasPreferencesFieldInput = {
  connect?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
};

export type PreferencesUsersHasPreferencesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PreferencesUsersHasPreferencesRelationship = {
  __typename?: "PreferencesUsersHasPreferencesRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type PreferencesUsersHasPreferencesUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type PreferencesUsersHasPreferencesUpdateFieldInput = {
  connect?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
  delete?: InputMaybe<Array<PreferencesUsersHasPreferencesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PreferencesUsersHasPreferencesDisconnectFieldInput>>;
  update?: InputMaybe<PreferencesUsersHasPreferencesUpdateConnectionInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesWhere = {
  AND?: InputMaybe<Array<PreferencesWhere>>;
  NOT?: InputMaybe<PreferencesWhere>;
  OR?: InputMaybe<Array<PreferencesWhere>>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  contentType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  contentType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  contentType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contentType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  learningDuration_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStyle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  notificationPreferences_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferencesAggregate?: InputMaybe<PreferencesUsersHasPreferencesAggregateInput>;
  /** Return Preferences where all of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_ALL?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where none of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_NONE?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where one of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_SINGLE?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where some of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_SOME?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where all of the related Users match this filter */
  usersHasPreferences_ALL?: InputMaybe<UserWhere>;
  /** Return Preferences where none of the related Users match this filter */
  usersHasPreferences_NONE?: InputMaybe<UserWhere>;
  /** Return Preferences where one of the related Users match this filter */
  usersHasPreferences_SINGLE?: InputMaybe<UserWhere>;
  /** Return Preferences where some of the related Users match this filter */
  usersHasPreferences_SOME?: InputMaybe<UserWhere>;
};

export type Prerequisite = {
  __typename?: "Prerequisite";
  field?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subfield?: Maybe<Scalars["String"]["output"]>;
};

export type PrerequisiteAggregateSelection = {
  __typename?: "PrerequisiteAggregateSelection";
  count: Scalars["Int"]["output"];
  field: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  subfield: StringAggregateSelection;
};

export type PrerequisiteCreateInput = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisiteEdge = {
  __typename?: "PrerequisiteEdge";
  cursor: Scalars["String"]["output"];
  node: Prerequisite;
};

export type PrerequisiteOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PrerequisiteSort objects to sort Prerequisites by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PrerequisiteSort>>;
};

/** Fields to sort Prerequisites by. The order in which sorts are applied is not guaranteed when specifying many fields in one PrerequisiteSort object. */
export type PrerequisiteSort = {
  field?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  subfield?: InputMaybe<SortDirection>;
};

export type PrerequisiteUpdateInput = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisiteWhere = {
  AND?: InputMaybe<Array<PrerequisiteWhere>>;
  NOT?: InputMaybe<PrerequisiteWhere>;
  OR?: InputMaybe<Array<PrerequisiteWhere>>;
  field?: InputMaybe<Scalars["String"]["input"]>;
  field_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  field_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  field_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  field_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
  subfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  subfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  subfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  subfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisitesConnection = {
  __typename?: "PrerequisitesConnection";
  edges: Array<PrerequisiteEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Progress = {
  __typename?: "Progress";
  date?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ProgressAggregateSelection = {
  __typename?: "ProgressAggregateSelection";
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  level: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type ProgressConnectWhere = {
  node: ProgressWhere;
};

export type ProgressCreateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressDetails = {
  __typename?: "ProgressDetails";
  ai_messages_count?: Maybe<Scalars["Int"]["output"]>;
  average_ai_response_time?: Maybe<Scalars["Float"]["output"]>;
  average_student_question_complexity?: Maybe<Scalars["String"]["output"]>;
  average_student_response_time?: Maybe<Scalars["Float"]["output"]>;
  confusion_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  distractions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  further_recommendations?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  level?: Maybe<Scalars["String"]["output"]>;
  num_messages?: Maybe<Scalars["Int"]["output"]>;
  originality_analysis?: Maybe<Scalars["String"]["output"]>;
  questions_asked?: Maybe<Scalars["Int"]["output"]>;
  sentiment_analysis?: Maybe<Scalars["String"]["output"]>;
  student_messages_count?: Maybe<Scalars["Int"]["output"]>;
  success_points?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  summary?: Maybe<Scalars["String"]["output"]>;
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type ProgressDetailsAggregateSelection = {
  __typename?: "ProgressDetailsAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_question_complexity: StringAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  level: StringAggregateSelection;
  num_messages: IntAggregateSelection;
  originality_analysis: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment_analysis: StringAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type ProgressDetailsConnection = {
  __typename?: "ProgressDetailsConnection";
  edges: Array<ProgressDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProgressDetailsCreateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProgressDetailsEdge = {
  __typename?: "ProgressDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: ProgressDetails;
};

export type ProgressDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ProgressDetailsSort objects to sort ProgressDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ProgressDetailsSort>>;
};

/** Fields to sort ProgressDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProgressDetailsSort object. */
export type ProgressDetailsSort = {
  ai_messages_count?: InputMaybe<SortDirection>;
  average_ai_response_time?: InputMaybe<SortDirection>;
  average_student_question_complexity?: InputMaybe<SortDirection>;
  average_student_response_time?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  num_messages?: InputMaybe<SortDirection>;
  originality_analysis?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment_analysis?: InputMaybe<SortDirection>;
  student_messages_count?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type ProgressDetailsUpdateInput = {
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_POP?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProgressDetailsWhere = {
  AND?: InputMaybe<Array<ProgressDetailsWhere>>;
  NOT?: InputMaybe<ProgressDetailsWhere>;
  OR?: InputMaybe<Array<ProgressDetailsWhere>>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  ai_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_ai_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_question_complexity?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  average_student_question_complexity_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  average_student_question_complexity_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  average_student_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  confusion_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  distractions_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  further_recommendations?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  num_messages_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  student_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  success_points_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProgressEdge = {
  __typename?: "ProgressEdge";
  cursor: Scalars["String"]["output"];
  node: Progress;
};

export type ProgressOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ProgressSort objects to sort Progresses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ProgressSort>>;
};

/** Fields to sort Progresses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProgressSort object. */
export type ProgressSort = {
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type ProgressUpdateInput = {
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressWhere = {
  AND?: InputMaybe<Array<ProgressWhere>>;
  NOT?: InputMaybe<ProgressWhere>;
  OR?: InputMaybe<Array<ProgressWhere>>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressesConnection = {
  __typename?: "ProgressesConnection";
  edges: Array<ProgressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type QuarterlySummariesConnection = {
  __typename?: "QuarterlySummariesConnection";
  edges: Array<QuarterlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type QuarterlySummary = {
  __typename?: "QuarterlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type QuarterlySummaryAggregateSelection = {
  __typename?: "QuarterlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type QuarterlySummaryConnectWhere = {
  node: QuarterlySummaryWhere;
};

export type QuarterlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type QuarterlySummaryEdge = {
  __typename?: "QuarterlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: QuarterlySummary;
};

export type QuarterlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more QuarterlySummarySort objects to sort QuarterlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<QuarterlySummarySort>>;
};

/** Fields to sort QuarterlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one QuarterlySummarySort object. */
export type QuarterlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type QuarterlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuarterlySummaryWhere = {
  AND?: InputMaybe<Array<QuarterlySummaryWhere>>;
  NOT?: InputMaybe<QuarterlySummaryWhere>;
  OR?: InputMaybe<Array<QuarterlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["String"]["output"]>;
  achievements: Array<Achievements>;
  achievementsAggregate: AchievementsAggregateSelection;
  achievementsConnection: AchievementsConnection;
  activeTracks: Array<ActiveTrack>;
  activeTracksAggregate: ActiveTrackAggregateSelection;
  activeTracksConnection: ActiveTracksConnection;
  activityAnalytics: Array<ActivityAnalytics>;
  activityAnalyticsAggregate: ActivityAnalyticsAggregateSelection;
  activityAnalyticsConnection: ActivityAnalyticsConnection;
  activityAnalyticsDetails: Array<ActivityAnalyticsDetails>;
  activityAnalyticsDetailsAggregate: ActivityAnalyticsDetailsAggregateSelection;
  activityAnalyticsDetailsConnection: ActivityAnalyticsDetailsConnection;
  activityAnalyticsResponses: Array<ActivityAnalyticsResponse>;
  activityAnalyticsResponsesAggregate: ActivityAnalyticsResponseAggregateSelection;
  activityAnalyticsResponsesConnection: ActivityAnalyticsResponsesConnection;
  activityCompletionDetails: Array<ActivityCompletionDetails>;
  activityCompletionDetailsAggregate: ActivityCompletionDetailsAggregateSelection;
  activityCompletionDetailsConnection: ActivityCompletionDetailsConnection;
  activityCompletionDetailsData: Array<ActivityCompletionDetailsData>;
  activityCompletionDetailsDataAggregate: ActivityCompletionDetailsDataAggregateSelection;
  activityCompletionDetailsDataConnection: ActivityCompletionDetailsDataConnection;
  activityCompletionDetailsFulls: Array<ActivityCompletionDetailsFull>;
  activityCompletionDetailsFullsAggregate: ActivityCompletionDetailsFullAggregateSelection;
  activityCompletionDetailsFullsConnection: ActivityCompletionDetailsFullsConnection;
  activityCompletionDetailsObjectives: Array<ActivityCompletionDetailsObjectives>;
  activityCompletionDetailsObjectivesAggregate: ActivityCompletionDetailsObjectivesAggregateSelection;
  activityCompletionDetailsObjectivesConnection: ActivityCompletionDetailsObjectivesConnection;
  activityCompletionDetailsResponses: Array<ActivityCompletionDetailsResponse>;
  activityCompletionDetailsResponsesAggregate: ActivityCompletionDetailsResponseAggregateSelection;
  activityCompletionDetailsResponsesConnection: ActivityCompletionDetailsResponsesConnection;
  activityInsights: Array<ActivityInsights>;
  activityInsightsAggregate: ActivityInsightsAggregateSelection;
  activityInsightsConnection: ActivityInsightsConnection;
  activityInsightsResponses: Array<ActivityInsightsResponse>;
  activityInsightsResponsesAggregate: ActivityInsightsResponseAggregateSelection;
  activityInsightsResponsesConnection: ActivityInsightsResponsesConnection;
  activityProgressAnalytics: Array<ActivityProgressAnalytics>;
  activityProgressAnalyticsAggregate: ActivityProgressAnalyticsAggregateSelection;
  activityProgressAnalyticsConnection: ActivityProgressAnalyticsConnection;
  activityProgressDetails: Array<ActivityProgressDetails>;
  activityProgressDetailsAggregate: ActivityProgressDetailsAggregateSelection;
  activityProgressDetailsConnection: ActivityProgressDetailsConnection;
  addAssignmentResponses: Array<AddAssignmentResponse>;
  addAssignmentResponsesAggregate: AddAssignmentResponseAggregateSelection;
  addAssignmentResponsesConnection: AddAssignmentResponsesConnection;
  addContentModuleResponses: Array<AddContentModuleResponse>;
  addContentModuleResponsesAggregate: AddContentModuleResponseAggregateSelection;
  addContentModuleResponsesConnection: AddContentModuleResponsesConnection;
  aiTutorAnalytics: Array<AiTutorAnalytics>;
  aiTutorAnalyticsAggregate: AiTutorAnalyticsAggregateSelection;
  aiTutorAnalyticsConnection: AiTutorAnalyticsConnection;
  aiTutorAnalyticsResponses: Array<AiTutorAnalyticsResponse>;
  aiTutorAnalyticsResponsesAggregate: AiTutorAnalyticsResponseAggregateSelection;
  aiTutorAnalyticsResponsesConnection: AiTutorAnalyticsResponsesConnection;
  aiTutorEvalCriteriaResponses: Array<AiTutorEvalCriteriaResponse>;
  aiTutorEvalCriteriaResponsesAggregate: AiTutorEvalCriteriaResponseAggregateSelection;
  aiTutorEvalCriteriaResponsesConnection: AiTutorEvalCriteriaResponsesConnection;
  aiTutorGeneratedResponses: Array<AiTutorGeneratedResponse>;
  aiTutorGeneratedResponsesAggregate: AiTutorGeneratedResponseAggregateSelection;
  aiTutorGeneratedResponsesConnection: AiTutorGeneratedResponsesConnection;
  aiTutorProgressAnalytics: Array<AiTutorProgressAnalytics>;
  aiTutorProgressAnalyticsAggregate: AiTutorProgressAnalyticsAggregateSelection;
  aiTutorProgressAnalyticsConnection: AiTutorProgressAnalyticsConnection;
  aiTutorProgressDetails: Array<AiTutorProgressDetails>;
  aiTutorProgressDetailsAggregate: AiTutorProgressDetailsAggregateSelection;
  aiTutorProgressDetailsConnection: AiTutorProgressDetailsConnection;
  aiTutorResponses: Array<AiTutorResponse>;
  aiTutorResponsesAggregate: AiTutorResponseAggregateSelection;
  aiTutorResponsesConnection: AiTutorResponsesConnection;
  aiTutors: Array<AiTutor>;
  aiTutorsAggregate: AiTutorAggregateSelection;
  aiTutorsConnection: AiTutorsConnection;
  anotherRelationshipFields: Array<AnotherRelationshipField>;
  anotherRelationshipFieldsAggregate: AnotherRelationshipFieldAggregateSelection;
  anotherRelationshipFieldsConnection: AnotherRelationshipFieldsConnection;
  aspectDetailsTypes: Array<AspectDetailsType>;
  aspectDetailsTypesAggregate: AspectDetailsTypeAggregateSelection;
  aspectDetailsTypesConnection: AspectDetailsTypesConnection;
  assessmentListResponseData: Array<AssessmentListResponseData>;
  assessmentListResponseDataAggregate: AssessmentListResponseDataAggregateSelection;
  assessmentListResponseDataConnection: AssessmentListResponseDataConnection;
  assessmentListResponseFormatteds: Array<AssessmentListResponseFormatted>;
  assessmentListResponseFormattedsAggregate: AssessmentListResponseFormattedAggregateSelection;
  assessmentListResponseFormattedsConnection: AssessmentListResponseFormattedsConnection;
  assessmentListResponses: Array<AssessmentListResponse>;
  assessmentListResponsesAggregate: AssessmentListResponseAggregateSelection;
  assessmentListResponsesConnection: AssessmentListResponsesConnection;
  assessmentResponses: Array<AssessmentResponse>;
  assessmentResponsesAggregate: AssessmentResponseAggregateSelection;
  assessmentResponsesConnection: AssessmentResponsesConnection;
  assessments: Array<Assessment>;
  assessmentsAggregate: AssessmentAggregateSelection;
  assessmentsConnection: AssessmentsConnection;
  assignedQuests: Array<AssignedQuest>;
  assignedQuestsAggregate: AssignedQuestAggregateSelection;
  assignedQuestsConnection: AssignedQuestsConnection;
  assignmentAndSubmissionResponses: Array<AssignmentAndSubmissionResponse>;
  assignmentAndSubmissionResponsesAggregate: AssignmentAndSubmissionResponseAggregateSelection;
  assignmentAndSubmissionResponsesConnection: AssignmentAndSubmissionResponsesConnection;
  assignmentAndSubmissions: Array<AssignmentAndSubmission>;
  assignmentAndSubmissionsAggregate: AssignmentAndSubmissionAggregateSelection;
  assignmentAndSubmissionsConnection: AssignmentAndSubmissionsConnection;
  assignmentResponses: Array<AssignmentResponse>;
  assignmentResponsesAggregate: AssignmentResponseAggregateSelection;
  assignmentResponsesConnection: AssignmentResponsesConnection;
  assignments: Array<Assignment>;
  assignmentsAggregate: AssignmentAggregateSelection;
  assignmentsConnection: AssignmentsConnection;
  attributes: Array<Attribute>;
  attributesAggregate: AttributeAggregateSelection;
  attributesConnection: AttributesConnection;
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  audioToggleUserResponsesAggregate: AudioToggleUserResponseAggregateSelection;
  audioToggleUserResponsesConnection: AudioToggleUserResponsesConnection;
  authPayloadResponses: Array<AuthPayloadResponse>;
  authPayloadResponsesAggregate: AuthPayloadResponseAggregateSelection;
  authPayloadResponsesConnection: AuthPayloadResponsesConnection;
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  authPayloadUserPreferencesAggregate: AuthPayloadUserPreferencesAggregateSelection;
  authPayloadUserPreferencesConnection: AuthPayloadUserPreferencesConnection;
  authPayloadUsers: Array<AuthPayloadUser>;
  authPayloadUsersAggregate: AuthPayloadUserAggregateSelection;
  authPayloadUsersConnection: AuthPayloadUsersConnection;
  axioResourceResponses: Array<AxioResourceResponse>;
  axioResourceResponsesAggregate: AxioResourceResponseAggregateSelection;
  axioResourceResponsesConnection: AxioResourceResponsesConnection;
  axioResources: Array<AxioResource>;
  axioResourcesAggregate: AxioResourceAggregateSelection;
  axioResourcesConnection: AxioResourcesConnection;
  careerHeaders: Array<CareerHeader>;
  careerHeadersAggregate: CareerHeaderAggregateSelection;
  careerHeadersConnection: CareerHeadersConnection;
  careerTopicResponses: Array<CareerTopicResponse>;
  careerTopicResponsesAggregate: CareerTopicResponseAggregateSelection;
  careerTopicResponsesConnection: CareerTopicResponsesConnection;
  careers: Array<Career>;
  careersAggregate: CareerAggregateSelection;
  careersConnection: CareersConnection;
  challenges: Array<Challenge>;
  challengesAggregate: ChallengeAggregateSelection;
  challengesConnection: ChallengesConnection;
  chapters: Array<Chapter>;
  chaptersAggregate: ChapterAggregateSelection;
  chaptersConnection: ChaptersConnection;
  chatAnalytics: Array<ChatAnalytics>;
  chatAnalyticsAggregate: ChatAnalyticsAggregateSelection;
  chatAnalyticsConnection: ChatAnalyticsConnection;
  chatAttachmentResponses: Array<ChatAttachmentResponse>;
  chatAttachmentResponsesAggregate: ChatAttachmentResponseAggregateSelection;
  chatAttachmentResponsesConnection: ChatAttachmentResponsesConnection;
  chatResponses: Array<ChatResponse>;
  chatResponsesAggregate: ChatResponseAggregateSelection;
  chatResponsesConnection: ChatResponsesConnection;
  chats: Array<Chat>;
  chatsAggregate: ChatAggregateSelection;
  chatsConnection: ChatsConnection;
  childInfos: Array<ChildInfo>;
  childInfosAggregate: ChildInfoAggregateSelection;
  childInfosConnection: ChildInfosConnection;
  completionActivityAnalytics: Array<CompletionActivityAnalytics>;
  completionActivityAnalyticsAggregate: CompletionActivityAnalyticsAggregateSelection;
  completionActivityAnalyticsConnection: CompletionActivityAnalyticsConnection;
  completionActivityDetails: Array<CompletionActivityDetails>;
  completionActivityDetailsAggregate: CompletionActivityDetailsAggregateSelection;
  completionActivityDetailsConnection: CompletionActivityDetailsConnection;
  completionAnalytics: Array<CompletionAnalytics>;
  completionAnalyticsAggregate: CompletionAnalyticsAggregateSelection;
  completionAnalyticsConnection: CompletionAnalyticsConnection;
  completionAnalyticsResponses: Array<CompletionAnalyticsResponse>;
  completionAnalyticsResponsesAggregate: CompletionAnalyticsResponseAggregateSelection;
  completionAnalyticsResponsesConnection: CompletionAnalyticsResponsesConnection;
  completionCourseDetails: Array<CompletionCourseDetails>;
  completionCourseDetailsAggregate: CompletionCourseDetailsAggregateSelection;
  completionCourseDetailsConnection: CompletionCourseDetailsConnection;
  completionModuleAnalytics: Array<CompletionModuleAnalytics>;
  completionModuleAnalyticsAggregate: CompletionModuleAnalyticsAggregateSelection;
  completionModuleAnalyticsConnection: CompletionModuleAnalyticsConnection;
  completionModuleDetails: Array<CompletionModuleDetails>;
  completionModuleDetailsAggregate: CompletionModuleDetailsAggregateSelection;
  completionModuleDetailsConnection: CompletionModuleDetailsConnection;
  completionObjectiveAnalytics: Array<CompletionObjectiveAnalytics>;
  completionObjectiveAnalyticsAggregate: CompletionObjectiveAnalyticsAggregateSelection;
  completionObjectiveAnalyticsConnection: CompletionObjectiveAnalyticsConnection;
  completionStats: Array<CompletionStats>;
  completionStatsAggregate: CompletionStatsAggregateSelection;
  completionStatsConnection: CompletionStatsConnection;
  coreValues: Array<CoreValue>;
  coreValuesAggregate: CoreValueAggregateSelection;
  coreValuesConnection: CoreValuesConnection;
  courseAnalytics: Array<CourseAnalytics>;
  courseAnalyticsAggregate: CourseAnalyticsAggregateSelection;
  courseAnalyticsConnection: CourseAnalyticsConnection;
  courseAnalyticsDetails: Array<CourseAnalyticsDetails>;
  courseAnalyticsDetailsAggregate: CourseAnalyticsDetailsAggregateSelection;
  courseAnalyticsDetailsConnection: CourseAnalyticsDetailsConnection;
  courseAnalyticsResponses: Array<CourseAnalyticsResponse>;
  courseAnalyticsResponsesAggregate: CourseAnalyticsResponseAggregateSelection;
  courseAnalyticsResponsesConnection: CourseAnalyticsResponsesConnection;
  courseAnalyticsSummaryResponses: Array<CourseAnalyticsSummaryResponse>;
  courseAnalyticsSummaryResponsesAggregate: CourseAnalyticsSummaryResponseAggregateSelection;
  courseAnalyticsSummaryResponsesConnection: CourseAnalyticsSummaryResponsesConnection;
  courseCategories: Array<CourseCategory>;
  courseCategoriesAggregate: CourseCategoryAggregateSelection;
  courseCategoriesConnection: CourseCategoriesConnection;
  courseCompletionDetails: Array<CourseCompletionDetails>;
  courseCompletionDetailsAggregate: CourseCompletionDetailsAggregateSelection;
  courseCompletionDetailsConnection: CourseCompletionDetailsConnection;
  courseCompletionDetailsData: Array<CourseCompletionDetailsData>;
  courseCompletionDetailsDataAggregate: CourseCompletionDetailsDataAggregateSelection;
  courseCompletionDetailsDataConnection: CourseCompletionDetailsDataConnection;
  courseCompletionDetailsResponses: Array<CourseCompletionDetailsResponse>;
  courseCompletionDetailsResponsesAggregate: CourseCompletionDetailsResponseAggregateSelection;
  courseCompletionDetailsResponsesConnection: CourseCompletionDetailsResponsesConnection;
  courseCompletionSummaries: Array<CourseCompletionSummary>;
  courseCompletionSummariesAggregate: CourseCompletionSummaryAggregateSelection;
  courseCompletionSummariesConnection: CourseCompletionSummariesConnection;
  courseCompletionSummaryResponses: Array<CourseCompletionSummaryResponse>;
  courseCompletionSummaryResponsesAggregate: CourseCompletionSummaryResponseAggregateSelection;
  courseCompletionSummaryResponsesConnection: CourseCompletionSummaryResponsesConnection;
  courseCreationResponses: Array<CourseCreationResponse>;
  courseCreationResponsesAggregate: CourseCreationResponseAggregateSelection;
  courseCreationResponsesConnection: CourseCreationResponsesConnection;
  courseDetailedAnalytics: Array<CourseDetailedAnalytics>;
  courseDetailedAnalyticsAggregate: CourseDetailedAnalyticsAggregateSelection;
  courseDetailedAnalyticsConnection: CourseDetailedAnalyticsConnection;
  courseDetailedResponses: Array<CourseDetailedResponse>;
  courseDetailedResponsesAggregate: CourseDetailedResponseAggregateSelection;
  courseDetailedResponsesConnection: CourseDetailedResponsesConnection;
  courseHierarchyResponses: Array<CourseHierarchyResponse>;
  courseHierarchyResponsesAggregate: CourseHierarchyResponseAggregateSelection;
  courseHierarchyResponsesConnection: CourseHierarchyResponsesConnection;
  courseProgressAnalytics: Array<CourseProgressAnalytics>;
  courseProgressAnalyticsAggregate: CourseProgressAnalyticsAggregateSelection;
  courseProgressAnalyticsConnection: CourseProgressAnalyticsConnection;
  courseProgressDetails: Array<CourseProgressDetails>;
  courseProgressDetailsAggregate: CourseProgressDetailsAggregateSelection;
  courseProgressDetailsConnection: CourseProgressDetailsConnection;
  courseResponses: Array<CourseResponse>;
  courseResponsesAggregate: CourseResponseAggregateSelection;
  courseResponsesConnection: CourseResponsesConnection;
  courseRosterData: Array<CourseRosterData>;
  courseRosterDataAggregate: CourseRosterDataAggregateSelection;
  courseRosterDataConnection: CourseRosterDataConnection;
  courseRosterServiceResults: Array<CourseRosterServiceResult>;
  courseRosterServiceResultsAggregate: CourseRosterServiceResultAggregateSelection;
  courseRosterServiceResultsConnection: CourseRosterServiceResultsConnection;
  courseSummaries: Array<CourseSummary>;
  courseSummariesAggregate: CourseSummaryAggregateSelection;
  courseSummariesConnection: CourseSummariesConnection;
  courses: Array<Course>;
  coursesAggregate: CourseAggregateSelection;
  coursesByQueryData: Array<CoursesByQueryData>;
  coursesByQueryDataAggregate: CoursesByQueryDataAggregateSelection;
  coursesByQueryDataConnection: CoursesByQueryDataConnection;
  coursesByQueryResponses: Array<CoursesByQueryResponse>;
  coursesByQueryResponsesAggregate: CoursesByQueryResponseAggregateSelection;
  coursesByQueryResponsesConnection: CoursesByQueryResponsesConnection;
  coursesConnection: CoursesConnection;
  curricula: Array<Curriculum>;
  curriculaAggregate: CurriculumAggregateSelection;
  curriculaConnection: CurriculaConnection;
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  dailyCompletedTodosCountsAggregate: DailyCompletedTodosCountAggregateSelection;
  dailyCompletedTodosCountsConnection: DailyCompletedTodosCountsConnection;
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  dailyExerciseTotalsAggregate: DailyExerciseTotalsAggregateSelection;
  dailyExerciseTotalsConnection: DailyExerciseTotalsConnection;
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  dailyNutritionTotalsAggregate: DailyNutritionTotalsAggregateSelection;
  dailyNutritionTotalsConnection: DailyNutritionTotalsConnection;
  dailySummaries: Array<DailySummary>;
  dailySummariesAggregate: DailySummaryAggregateSelection;
  dailySummariesConnection: DailySummariesConnection;
  degreeResponses: Array<DegreeResponse>;
  degreeResponsesAggregate: DegreeResponseAggregateSelection;
  degreeResponsesConnection: DegreeResponsesConnection;
  degrees: Array<Degree>;
  degreesAggregate: DegreeAggregateSelection;
  degreesBySchoolByQueryData: Array<DegreesBySchoolByQueryData>;
  degreesBySchoolByQueryDataAggregate: DegreesBySchoolByQueryDataAggregateSelection;
  degreesBySchoolByQueryDataConnection: DegreesBySchoolByQueryDataConnection;
  degreesBySchoolByQueryResponses: Array<DegreesBySchoolByQueryResponse>;
  degreesBySchoolByQueryResponsesAggregate: DegreesBySchoolByQueryResponseAggregateSelection;
  degreesBySchoolByQueryResponsesConnection: DegreesBySchoolByQueryResponsesConnection;
  degreesConnection: DegreesConnection;
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  deleteContentAssignmentResponsesAggregate: DeleteContentAssignmentResponseAggregateSelection;
  deleteContentAssignmentResponsesConnection: DeleteContentAssignmentResponsesConnection;
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  deleteContentAssignmentVariablesAggregate: DeleteContentAssignmentVariablesAggregateSelection;
  deleteContentAssignmentVariablesConnection: DeleteContentAssignmentVariablesConnection;
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  deleteContentModuleResponsesAggregate: DeleteContentModuleResponseAggregateSelection;
  deleteContentModuleResponsesConnection: DeleteContentModuleResponsesConnection;
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  deleteContentModuleVariablesAggregate: DeleteContentModuleVariablesAggregateSelection;
  deleteContentModuleVariablesConnection: DeleteContentModuleVariablesConnection;
  deleteCourseResponses: Array<DeleteCourseResponse>;
  deleteCourseResponsesAggregate: DeleteCourseResponseAggregateSelection;
  deleteCourseResponsesConnection: DeleteCourseResponsesConnection;
  deviceSessions: Array<DeviceSession>;
  deviceSessionsAggregate: DeviceSessionAggregateSelection;
  deviceSessionsConnection: DeviceSessionsConnection;
  emotionalStates: Array<EmotionalState>;
  emotionalStatesAggregate: EmotionalStateAggregateSelection;
  emotionalStatesConnection: EmotionalStatesConnection;
  events: Array<Event>;
  eventsAggregate: EventAggregateSelection;
  eventsConnection: EventsConnection;
  exportCsv: ExportCsvResponse;
  exportCsvResponses: Array<ExportCsvResponse>;
  exportCsvResponsesAggregate: ExportCsvResponseAggregateSelection;
  exportCsvResponsesConnection: ExportCsvResponsesConnection;
  facultyDataCourses: Array<FacultyDataCourse>;
  facultyDataCoursesAggregate: FacultyDataCourseAggregateSelection;
  facultyDataCoursesConnection: FacultyDataCoursesConnection;
  facultyDataRoles: Array<FacultyDataRole>;
  facultyDataRolesAggregate: FacultyDataRoleAggregateSelection;
  facultyDataRolesConnection: FacultyDataRolesConnection;
  facultyDataSessionDeviceSessions: Array<FacultyDataSessionDeviceSession>;
  facultyDataSessionDeviceSessionsAggregate: FacultyDataSessionDeviceSessionAggregateSelection;
  facultyDataSessionDeviceSessionsConnection: FacultyDataSessionDeviceSessionsConnection;
  facultyQueryResponseData: Array<FacultyQueryResponseData>;
  facultyQueryResponseDataAggregate: FacultyQueryResponseDataAggregateSelection;
  facultyQueryResponseDataConnection: FacultyQueryResponseDataConnection;
  facultyQueryResponseWithCountData: Array<FacultyQueryResponseWithCountData>;
  facultyQueryResponseWithCountDataAggregate: FacultyQueryResponseWithCountDataAggregateSelection;
  facultyQueryResponseWithCountDataConnection: FacultyQueryResponseWithCountDataConnection;
  facultyQueryResponses: Array<FacultyQueryResponse>;
  facultyQueryResponsesAggregate: FacultyQueryResponseAggregateSelection;
  facultyQueryResponsesConnection: FacultyQueryResponsesConnection;
  feedbacks: Array<Feedback>;
  feedbacksAggregate: FeedbackAggregateSelection;
  feedbacksConnection: FeedbacksConnection;
  fields: Array<Field>;
  fieldsAggregate: FieldAggregateSelection;
  fieldsConnection: FieldsConnection;
  fileResponses: Array<FileResponse>;
  fileResponsesAggregate: FileResponseAggregateSelection;
  fileResponsesConnection: FileResponsesConnection;
  fileUploadResponses: Array<FileUploadResponse>;
  fileUploadResponsesAggregate: FileUploadResponseAggregateSelection;
  fileUploadResponsesConnection: FileUploadResponsesConnection;
  files: Array<File>;
  filesAggregate: FileAggregateSelection;
  filesConnection: FilesConnection;
  foodItems: Array<FoodItem>;
  foodItemsAggregate: FoodItemAggregateSelection;
  foodItemsConnection: FoodItemsConnection;
  foods?: Maybe<Array<Maybe<Food>>>;
  foodsAggregate: FoodAggregateSelection;
  foodsConnection: FoodsConnection;
  formattedAiTutors: Array<FormattedAiTutor>;
  formattedAiTutorsAggregate: FormattedAiTutorAggregateSelection;
  formattedAiTutorsConnection: FormattedAiTutorsConnection;
  formattedChats: Array<FormattedChat>;
  formattedChatsAggregate: FormattedChatAggregateSelection;
  formattedChatsConnection: FormattedChatsConnection;
  formattedObjectives: Array<FormattedObjective>;
  formattedObjectivesAggregate: FormattedObjectiveAggregateSelection;
  formattedObjectivesConnection: FormattedObjectivesConnection;
  formattedVisualizations: Array<FormattedVisualization>;
  formattedVisualizationsAggregate: FormattedVisualizationAggregateSelection;
  formattedVisualizationsConnection: FormattedVisualizationsConnection;
  games: Array<Game>;
  gamesAggregate: GameAggregateSelection;
  gamesConnection: GamesConnection;
  generateCourseContentData: Array<GenerateCourseContentData>;
  generateCourseContentDataAggregate: GenerateCourseContentDataAggregateSelection;
  generateCourseContentDataConnection: GenerateCourseContentDataConnection;
  generateCourseContentResponses: Array<GenerateCourseContentResponse>;
  generateCourseContentResponsesAggregate: GenerateCourseContentResponseAggregateSelection;
  generateCourseContentResponsesConnection: GenerateCourseContentResponsesConnection;
  generatedAiTutors: Array<GeneratedAiTutor>;
  generatedAiTutorsAggregate: GeneratedAiTutorAggregateSelection;
  generatedAiTutorsConnection: GeneratedAiTutorsConnection;
  generatedObjectives: Array<GeneratedObjective>;
  generatedObjectivesAggregate: GeneratedObjectiveAggregateSelection;
  generatedObjectivesConnection: GeneratedObjectivesConnection;
  generateds: Array<Generated>;
  generatedsAggregate: GeneratedAggregateSelection;
  generatedsConnection: GeneratedsConnection;
  getAITutor?: Maybe<AiTutorResponse>;
  getAITutorAnalytics?: Maybe<AiTutorAnalyticsResponse>;
  getActiveTracks?: Maybe<Array<Maybe<ActiveTrack>>>;
  getActivityAnalyticsByModule?: Maybe<ActivityAnalyticsResponse>;
  getActivityCompletionDetails: ActivityCompletionDetailsResponse;
  getActivityInsightsData: ActivityInsightsResponse;
  getAllAITutorsByCourse?: Maybe<AiTutorResponse>;
  getAllAxioResourcesByCourse: AxioResourceResponse;
  getAllCoursesBySchool?: Maybe<CourseResponse>;
  getAllModulesByCourse?: Maybe<ModuleResponse>;
  getAllTenants?: Maybe<TenantResponse>;
  getAssessment?: Maybe<AssessmentResponse>;
  getAssessmentByFaculty?: Maybe<AssessmentListResponse>;
  getAssignment?: Maybe<AssignmentResponse>;
  getAssignmentAndSubmissionBySubmissionId?: Maybe<AssignmentAndSubmissionResponse>;
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  getAssignmentObjectiveResponsesAggregate: GetAssignmentObjectiveResponseAggregateSelection;
  getAssignmentObjectiveResponsesConnection: GetAssignmentObjectiveResponsesConnection;
  getAssignmentResponses: Array<GetAssignmentResponse>;
  getAssignmentResponsesAggregate: GetAssignmentResponseAggregateSelection;
  getAssignmentResponsesConnection: GetAssignmentResponsesConnection;
  getAssignments?: Maybe<Array<Maybe<GetAssignmentResponse>>>;
  getAxioResource: AxioResourceResponse;
  getCareerById?: Maybe<Career>;
  getCareerHeader: CareerHeader;
  getCareerTopics?: Maybe<Array<Maybe<Topic>>>;
  getCareers?: Maybe<Array<Maybe<Career>>>;
  getChapters?: Maybe<Array<Maybe<Subfield>>>;
  getChatByContextAndParams?: Maybe<ChatResponse>;
  getChatById?: Maybe<ChatResponse>;
  getChatByObjectId?: Maybe<ChatResponse>;
  getChatForDate?: Maybe<ChatResponse>;
  getChats?: Maybe<Array<Maybe<Chat>>>;
  getCompletionAnalyticsBySchool: CompletionAnalyticsResponse;
  getContentById?: Maybe<Curriculum>;
  getCourseAnalyticsById?: Maybe<CourseDetailedResponse>;
  getCourseById?: Maybe<CourseResponse>;
  getCourseByName?: Maybe<CourseResponse>;
  getCourseCompletionDetails: CourseCompletionDetailsResponse;
  getCourseCompletionSummaryBySchool?: Maybe<CourseCompletionSummaryResponse>;
  getCourseHierarchy?: Maybe<CourseHierarchyResponse>;
  getCoursesAnalyticsBySchool?: Maybe<CourseAnalyticsResponse>;
  getCoursesByDegreeByQuery?: Maybe<CoursesByQueryResponse>;
  getCoursesByFaculty?: Maybe<CourseResponse>;
  getCoursesBySchoolByQuery?: Maybe<CoursesByQueryResponse>;
  getCoursesForTranscriptGeneration?: Maybe<CourseResponse>;
  getCoursesSummaryBySchool?: Maybe<CourseAnalyticsSummaryResponse>;
  getDailyCompletedTodos?: Maybe<DailyCompletedTodosCount>;
  getDailyExerciseTotalsByDate?: Maybe<DailyExerciseTotals>;
  getDailyLimit?: Maybe<RateLimitResult>;
  getDailyNutritionTotalsByDate?: Maybe<DailyNutritionTotals>;
  getDegree?: Maybe<DegreeResponse>;
  getDegreeByName?: Maybe<DegreeResponse>;
  getDegreeBySchool?: Maybe<DegreeResponse>;
  getDegreeForUser?: Maybe<DegreeResponse>;
  getDegreesByCourseByQuery?: Maybe<DegreesBySchoolByQueryResponse>;
  getDegreesBySchoolByQuery?: Maybe<DegreesBySchoolByQueryResponse>;
  getEnrolledCoursesBySchool?: Maybe<CourseResponse>;
  getFacultyByCourseId?: Maybe<FacultyQueryResponse>;
  getFacultyByNameOrEmail?: Maybe<FacultyQueryResponse>;
  getFacultyForSchool?: Maybe<FacultyQueryResponse>;
  getFields: Array<Maybe<Field>>;
  getFile: FileResponse;
  getFirstSubchapterByTopicName: SubchapterDetails;
  getFoodsByDate?: Maybe<Array<Maybe<Food>>>;
  getGeneratedContent?: Maybe<Array<Maybe<Curriculum>>>;
  getIsInvitationTokenValid?: Maybe<ValidityResponse>;
  getJourneyById?: Maybe<GetJourneyByIdResponse>;
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  getJourneyByIdResponsesAggregate: GetJourneyByIdResponseAggregateSelection;
  getJourneyByIdResponsesConnection: GetJourneyByIdResponsesConnection;
  getJourneys?: Maybe<Array<Maybe<GetJourneysResponse>>>;
  getJourneysResponses: Array<GetJourneysResponse>;
  getJourneysResponsesAggregate: GetJourneysResponseAggregateSelection;
  getJourneysResponsesConnection: GetJourneysResponsesConnection;
  getLearnedSubchaptersByDate?: Maybe<LearningSummary>;
  getLearningObjectiveById: LearningObjectiveResponse;
  getLearningObjectivesBySubchapter?: Maybe<Array<Maybe<LearningObjective>>>;
  getLearningTree?: Maybe<LearningTree>;
  getLevelDetails?: Maybe<LevelDetails>;
  getModule?: Maybe<ModuleResponse>;
  getModuleAnalyticsByCourse?: Maybe<ModuleAnalyticsResponse>;
  getModuleCompletionDetails: ModuleCompletionDetailsResponse;
  getMostRelevantVideo: VideoServiceResult;
  getNode?: Maybe<LearningNode>;
  getNodeChildren: Array<Maybe<LearningNode>>;
  getNodeParents: Array<Maybe<LearningNode>>;
  getNotEnrolledCoursesBySchool?: Maybe<CourseResponse>;
  getObjectiveAnalyticsByActivity?: Maybe<ObjectiveAnalyticsResponse>;
  getObjectiveCompletionDetails: ObjectiveCompletionDetailsResponse;
  getPage?: Maybe<PageResponse>;
  getRecentTracks?: Maybe<Array<Maybe<RecentTrack>>>;
  getSchools?: Maybe<Array<Maybe<School>>>;
  getSiblingSubchapters?: Maybe<Array<Maybe<HierarchyNode>>>;
  getStudentActivityProgress?: Maybe<StudentActivityProgressResponse>;
  getStudentAnalyticsBySchool: StudentAnalyticsResponse;
  getStudentAnalyticsForStudent: StudentAnalyticsResponse;
  getStudentChatAnalytics: StudentChatAnalyticsResponse;
  getStudentCourseProgress?: Maybe<StudentCourseProgressResponse>;
  getStudentDetailedAnalytics?: Maybe<StudentDetailedAnalyticsResponse>;
  getStudentLearningInsights: StudentLearningInsightsResponse;
  getStudentModuleProgress?: Maybe<StudentModuleProgressResponse>;
  getStudentsByCourseId?: Maybe<CourseRosterServiceResult>;
  getStudentsSummaryBySchool?: Maybe<StudentAnalyticsSummaryResponse>;
  getSubchapterById?: Maybe<SubchapterDetails>;
  getSubchapterPrerequisites?: Maybe<Array<Maybe<HierarchyNode>>>;
  getSubchapters?: Maybe<Array<Maybe<Subfield>>>;
  getSubfields?: Maybe<Array<Maybe<Subfield>>>;
  getSuggestions?: Maybe<Array<Maybe<Suggestion>>>;
  getTableOfContents?: Maybe<Array<Maybe<Hierarchy>>>;
  getTenant?: Maybe<TenantResponse>;
  getTenantSchema?: Maybe<TenantSchemaResponse>;
  getTodayChat?: Maybe<ChatResponse>;
  getTodayGame?: Maybe<Game>;
  getTopics?: Maybe<Array<Maybe<Subfield>>>;
  getTrackOverview?: Maybe<TrackOverview>;
  getTransactionHistory?: Maybe<Array<Maybe<Transaction>>>;
  getTransactionHistoryForDate: Array<TransactionByDate>;
  getUserDailySummaries?: Maybe<Array<Maybe<DailySummary>>>;
  getUserJournalEntry?: Maybe<DailySummary>;
  getUserLearningObjectiveMastery?: Maybe<MasteryResult>;
  getUserMastery: Array<UserMastery>;
  getUserOverallProgress: Array<UserProgress>;
  getUserPermissions: Array<Scalars["String"]["output"]>;
  getUserProgress: Array<UserProgress>;
  getUserSubmissions?: Maybe<SubmissionResponse>;
  getUserSubmissionsByFaculty?: Maybe<SubmissionListResponse>;
  getUsersForSchool?: Maybe<FacultyQueryResponse>;
  getVisualization?: Maybe<VisualizationResponse>;
  getVisualizationsForTutor?: Maybe<VisualizationResponse>;
  getWaterIntakeByDate?: Maybe<WaterIntakeSummary>;
  goals: Array<Goal>;
  goalsAggregate: GoalAggregateSelection;
  goalsConnection: GoalsConnection;
  googleAuthTokens: Array<GoogleAuthTokens>;
  googleAuthTokensAggregate: GoogleAuthTokensAggregateSelection;
  googleAuthTokensConnection: GoogleAuthTokensConnection;
  hierarchies: Array<Hierarchy>;
  hierarchiesAggregate: HierarchyAggregateSelection;
  hierarchiesConnection: HierarchiesConnection;
  hierarchyActivities: Array<HierarchyActivity>;
  hierarchyActivitiesAggregate: HierarchyActivityAggregateSelection;
  hierarchyActivitiesConnection: HierarchyActivitiesConnection;
  hierarchyActivityDetails: Array<HierarchyActivityDetails>;
  hierarchyActivityDetailsAggregate: HierarchyActivityDetailsAggregateSelection;
  hierarchyActivityDetailsConnection: HierarchyActivityDetailsConnection;
  hierarchyCourseDetails: Array<HierarchyCourseDetails>;
  hierarchyCourseDetailsAggregate: HierarchyCourseDetailsAggregateSelection;
  hierarchyCourseDetailsConnection: HierarchyCourseDetailsConnection;
  hierarchyCourses: Array<HierarchyCourse>;
  hierarchyCoursesAggregate: HierarchyCourseAggregateSelection;
  hierarchyCoursesConnection: HierarchyCoursesConnection;
  hierarchyModuleDetails: Array<HierarchyModuleDetails>;
  hierarchyModuleDetailsAggregate: HierarchyModuleDetailsAggregateSelection;
  hierarchyModuleDetailsConnection: HierarchyModuleDetailsConnection;
  hierarchyModules: Array<HierarchyModule>;
  hierarchyModulesAggregate: HierarchyModuleAggregateSelection;
  hierarchyModulesConnection: HierarchyModulesConnection;
  hierarchyNodes: Array<HierarchyNode>;
  hierarchyNodesAggregate: HierarchyNodeAggregateSelection;
  hierarchyNodesConnection: HierarchyNodesConnection;
  hierarchyObjectives: Array<HierarchyObjective>;
  hierarchyObjectivesAggregate: HierarchyObjectiveAggregateSelection;
  hierarchyObjectivesConnection: HierarchyObjectivesConnection;
  hobbies: Array<Hobby>;
  hobbiesAggregate: HobbyAggregateSelection;
  hobbiesConnection: HobbiesConnection;
  interests: Array<Interest>;
  interestsAggregate: InterestAggregateSelection;
  interestsConnection: InterestsConnection;
  inviteCountResponses: Array<InviteCountResponse>;
  inviteCountResponsesAggregate: InviteCountResponseAggregateSelection;
  inviteCountResponsesConnection: InviteCountResponsesConnection;
  inviteUsersToEnrollInCourseResponses: Array<InviteUsersToEnrollInCourseResponse>;
  inviteUsersToEnrollInCourseResponsesAggregate: InviteUsersToEnrollInCourseResponseAggregateSelection;
  inviteUsersToEnrollInCourseResponsesConnection: InviteUsersToEnrollInCourseResponsesConnection;
  inviteUsersToEnrollInDegreeResponses: Array<InviteUsersToEnrollInDegreeResponse>;
  inviteUsersToEnrollInDegreeResponsesAggregate: InviteUsersToEnrollInDegreeResponseAggregateSelection;
  inviteUsersToEnrollInDegreeResponsesConnection: InviteUsersToEnrollInDegreeResponsesConnection;
  invitedUsers: Array<InvitedUsers>;
  invitedUsersAggregate: InvitedUsersAggregateSelection;
  invitedUsersConnection: InvitedUsersConnection;
  journeys: Array<Journey>;
  journeysAggregate: JourneyAggregateSelection;
  journeysConnection: JourneysConnection;
  learningCheckoutSessions: Array<LearningCheckoutSession>;
  learningCheckoutSessionsAggregate: LearningCheckoutSessionAggregateSelection;
  learningCheckoutSessionsConnection: LearningCheckoutSessionsConnection;
  learningNodes: Array<LearningNode>;
  learningNodesAggregate: LearningNodeAggregateSelection;
  learningNodesConnection: LearningNodesConnection;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
  learningObjectiveResponsesAggregate: LearningObjectiveResponseAggregateSelection;
  learningObjectiveResponsesConnection: LearningObjectiveResponsesConnection;
  learningObjectives: Array<LearningObjective>;
  learningObjectivesAggregate: LearningObjectiveAggregateSelection;
  learningObjectivesConnection: LearningObjectivesConnection;
  learningPayments: Array<LearningPayment>;
  learningPaymentsAggregate: LearningPaymentAggregateSelection;
  learningPaymentsConnection: LearningPaymentsConnection;
  learningSummaries: Array<LearningSummary>;
  learningSummariesAggregate: LearningSummaryAggregateSelection;
  learningSummariesConnection: LearningSummariesConnection;
  learningTrees: Array<LearningTree>;
  learningTreesAggregate: LearningTreeAggregateSelection;
  learningTreesConnection: LearningTreesConnection;
  lessons: Array<Lesson>;
  lessonsAggregate: LessonAggregateSelection;
  lessonsConnection: LessonsConnection;
  levelDetails: Array<LevelDetails>;
  levelDetailsAggregate: LevelDetailsAggregateSelection;
  levelDetailsConnection: LevelDetailsConnection;
  lifeAspirations: Array<LifeAspiration>;
  lifeAspirationsAggregate: LifeAspirationAggregateSelection;
  lifeAspirationsConnection: LifeAspirationsConnection;
  masteryResults: Array<MasteryResult>;
  masteryResultsAggregate: MasteryResultAggregateSelection;
  masteryResultsConnection: MasteryResultsConnection;
  me?: Maybe<MeResponse>;
  meResponses: Array<MeResponse>;
  meResponsesAggregate: MeResponseAggregateSelection;
  meResponsesConnection: MeResponsesConnection;
  messageResponses: Array<MessageResponse>;
  messageResponsesAggregate: MessageResponseAggregateSelection;
  messageResponsesConnection: MessageResponsesConnection;
  messages: Array<Message>;
  messagesAggregate: MessageAggregateSelection;
  messagesConnection: MessagesConnection;
  moduleAnalytics: Array<ModuleAnalytics>;
  moduleAnalyticsAggregate: ModuleAnalyticsAggregateSelection;
  moduleAnalyticsConnection: ModuleAnalyticsConnection;
  moduleAnalyticsDetails: Array<ModuleAnalyticsDetails>;
  moduleAnalyticsDetailsAggregate: ModuleAnalyticsDetailsAggregateSelection;
  moduleAnalyticsDetailsConnection: ModuleAnalyticsDetailsConnection;
  moduleAnalyticsResponses: Array<ModuleAnalyticsResponse>;
  moduleAnalyticsResponsesAggregate: ModuleAnalyticsResponseAggregateSelection;
  moduleAnalyticsResponsesConnection: ModuleAnalyticsResponsesConnection;
  moduleCompletionDetails: Array<ModuleCompletionDetails>;
  moduleCompletionDetailsActivities: Array<ModuleCompletionDetailsActivities>;
  moduleCompletionDetailsActivitiesAggregate: ModuleCompletionDetailsActivitiesAggregateSelection;
  moduleCompletionDetailsActivitiesConnection: ModuleCompletionDetailsActivitiesConnection;
  moduleCompletionDetailsAggregate: ModuleCompletionDetailsAggregateSelection;
  moduleCompletionDetailsConnection: ModuleCompletionDetailsConnection;
  moduleCompletionDetailsData: Array<ModuleCompletionDetailsData>;
  moduleCompletionDetailsDataAggregate: ModuleCompletionDetailsDataAggregateSelection;
  moduleCompletionDetailsDataConnection: ModuleCompletionDetailsDataConnection;
  moduleCompletionDetailsFulls: Array<ModuleCompletionDetailsFull>;
  moduleCompletionDetailsFullsAggregate: ModuleCompletionDetailsFullAggregateSelection;
  moduleCompletionDetailsFullsConnection: ModuleCompletionDetailsFullsConnection;
  moduleCompletionDetailsResponses: Array<ModuleCompletionDetailsResponse>;
  moduleCompletionDetailsResponsesAggregate: ModuleCompletionDetailsResponseAggregateSelection;
  moduleCompletionDetailsResponsesConnection: ModuleCompletionDetailsResponsesConnection;
  moduleProgressAnalytics: Array<ModuleProgressAnalytics>;
  moduleProgressAnalyticsAggregate: ModuleProgressAnalyticsAggregateSelection;
  moduleProgressAnalyticsConnection: ModuleProgressAnalyticsConnection;
  moduleProgressDetails: Array<ModuleProgressDetails>;
  moduleProgressDetailsAggregate: ModuleProgressDetailsAggregateSelection;
  moduleProgressDetailsConnection: ModuleProgressDetailsConnection;
  moduleResponses: Array<ModuleResponse>;
  moduleResponsesAggregate: ModuleResponseAggregateSelection;
  moduleResponsesConnection: ModuleResponsesConnection;
  modules: Array<Module>;
  modulesAggregate: ModuleAggregateSelection;
  modulesConnection: ModulesConnection;
  monthlySummaries: Array<MonthlySummary>;
  monthlySummariesAggregate: MonthlySummaryAggregateSelection;
  monthlySummariesConnection: MonthlySummariesConnection;
  nodeIdentities: Array<NodeIdentity>;
  nodeIdentitiesAggregate: NodeIdentityAggregateSelection;
  nodeIdentitiesConnection: NodeIdentitiesConnection;
  nodeProperties: Array<NodeProperties>;
  nodePropertiesAggregate: NodePropertiesAggregateSelection;
  nodePropertiesConnection: NodePropertiesConnection;
  nutrition?: Maybe<Array<Maybe<Nutrition>>>;
  nutritionByDate?: Maybe<Array<Maybe<Nutrition>>>;
  nutritions: Array<Nutrition>;
  nutritionsAggregate: NutritionAggregateSelection;
  nutritionsConnection: NutritionsConnection;
  oAuthAccessTokens: Array<OAuthAccessToken>;
  oAuthAccessTokensAggregate: OAuthAccessTokenAggregateSelection;
  oAuthAccessTokensConnection: OAuthAccessTokensConnection;
  oAuthClients: Array<OAuthClient>;
  oAuthClientsAggregate: OAuthClientAggregateSelection;
  oAuthClientsConnection: OAuthClientsConnection;
  objectiveAnalytics: Array<ObjectiveAnalytics>;
  objectiveAnalyticsAggregate: ObjectiveAnalyticsAggregateSelection;
  objectiveAnalyticsConnection: ObjectiveAnalyticsConnection;
  objectiveAnalyticsResponses: Array<ObjectiveAnalyticsResponse>;
  objectiveAnalyticsResponsesAggregate: ObjectiveAnalyticsResponseAggregateSelection;
  objectiveAnalyticsResponsesConnection: ObjectiveAnalyticsResponsesConnection;
  objectiveCompletionDetails: Array<ObjectiveCompletionDetails>;
  objectiveCompletionDetailsAggregate: ObjectiveCompletionDetailsAggregateSelection;
  objectiveCompletionDetailsConnection: ObjectiveCompletionDetailsConnection;
  objectiveCompletionDetailsFulls: Array<ObjectiveCompletionDetailsFull>;
  objectiveCompletionDetailsFullsAggregate: ObjectiveCompletionDetailsFullAggregateSelection;
  objectiveCompletionDetailsFullsConnection: ObjectiveCompletionDetailsFullsConnection;
  objectiveCompletionDetailsResponses: Array<ObjectiveCompletionDetailsResponse>;
  objectiveCompletionDetailsResponsesAggregate: ObjectiveCompletionDetailsResponseAggregateSelection;
  objectiveCompletionDetailsResponsesConnection: ObjectiveCompletionDetailsResponsesConnection;
  objectiveProgressAnalytics: Array<ObjectiveProgressAnalytics>;
  objectiveProgressAnalyticsAggregate: ObjectiveProgressAnalyticsAggregateSelection;
  objectiveProgressAnalyticsConnection: ObjectiveProgressAnalyticsConnection;
  objectives: Array<Objective>;
  objectivesAggregate: ObjectiveAggregateSelection;
  objectivesConnection: ObjectivesConnection;
  pageResponses: Array<PageResponse>;
  pageResponsesAggregate: PageResponseAggregateSelection;
  pageResponsesConnection: PageResponsesConnection;
  pages: Array<Page>;
  pagesAggregate: PageAggregateSelection;
  pagesConnection: PagesConnection;
  parametersTypes: Array<ParametersType>;
  parametersTypesAggregate: ParametersTypeAggregateSelection;
  parametersTypesConnection: ParametersTypesConnection;
  parentRefs: Array<ParentRef>;
  parentRefsAggregate: ParentRefAggregateSelection;
  parentRefsConnection: ParentRefsConnection;
  payments: Array<Payment>;
  paymentsAggregate: PaymentAggregateSelection;
  paymentsConnection: PaymentsConnection;
  permissions: Array<Permission>;
  permissionsAggregate: PermissionAggregateSelection;
  permissionsConnection: PermissionsConnection;
  personalityTraits: Array<PersonalityTrait>;
  personalityTraitsAggregate: PersonalityTraitAggregateSelection;
  personalityTraitsConnection: PersonalityTraitsConnection;
  preferences: Array<Preferences>;
  preferencesAggregate: PreferencesAggregateSelection;
  preferencesConnection: PreferencesConnection;
  prerequisites: Array<Prerequisite>;
  prerequisitesAggregate: PrerequisiteAggregateSelection;
  prerequisitesConnection: PrerequisitesConnection;
  progressDetails: Array<ProgressDetails>;
  progressDetailsAggregate: ProgressDetailsAggregateSelection;
  progressDetailsConnection: ProgressDetailsConnection;
  progresses: Array<Progress>;
  progressesAggregate: ProgressAggregateSelection;
  progressesConnection: ProgressesConnection;
  quarterlySummaries: Array<QuarterlySummary>;
  quarterlySummariesAggregate: QuarterlySummaryAggregateSelection;
  quarterlySummariesConnection: QuarterlySummariesConnection;
  rateLimitResults: Array<RateLimitResult>;
  rateLimitResultsAggregate: RateLimitResultAggregateSelection;
  rateLimitResultsConnection: RateLimitResultsConnection;
  recentCategories: Array<RecentCategory>;
  recentCategoriesAggregate: RecentCategoryAggregateSelection;
  recentCategoriesConnection: RecentCategoriesConnection;
  recentTodos?: Maybe<Array<Maybe<Todo>>>;
  recentTracks: Array<RecentTrack>;
  recentTracksAggregate: RecentTrackAggregateSelection;
  recentTracksConnection: RecentTracksConnection;
  recomendeds: Array<Recomended>;
  recomendedsAggregate: RecomendedAggregateSelection;
  recomendedsConnection: RecomendedsConnection;
  reflections: Array<Reflection>;
  reflectionsAggregate: ReflectionAggregateSelection;
  reflectionsConnection: ReflectionsConnection;
  refreshQuestResponses: Array<RefreshQuestResponse>;
  refreshQuestResponsesAggregate: RefreshQuestResponseAggregateSelection;
  refreshQuestResponsesConnection: RefreshQuestResponsesConnection;
  relationshipFields: Array<RelationshipField>;
  relationshipFieldsAggregate: RelationshipFieldAggregateSelection;
  relationshipFieldsConnection: RelationshipFieldsConnection;
  responseTypes: Array<ResponseType>;
  responseTypesAggregate: ResponseTypeAggregateSelection;
  responseTypesConnection: ResponseTypesConnection;
  roles: Array<Role>;
  rolesAggregate: RoleAggregateSelection;
  rolesConnection: RolesConnection;
  schools: Array<School>;
  schoolsAggregate: SchoolAggregateSelection;
  schoolsConnection: SchoolsConnection;
  sessionResponses: Array<SessionResponse>;
  sessionResponsesAggregate: SessionResponseAggregateSelection;
  sessionResponsesConnection: SessionResponsesConnection;
  studentActivityProgressData: Array<StudentActivityProgressData>;
  studentActivityProgressDataAggregate: StudentActivityProgressDataAggregateSelection;
  studentActivityProgressDataConnection: StudentActivityProgressDataConnection;
  studentActivityProgressResponses: Array<StudentActivityProgressResponse>;
  studentActivityProgressResponsesAggregate: StudentActivityProgressResponseAggregateSelection;
  studentActivityProgressResponsesConnection: StudentActivityProgressResponsesConnection;
  studentAnalytics: Array<StudentAnalytics>;
  studentAnalyticsAggregate: StudentAnalyticsAggregateSelection;
  studentAnalyticsConnection: StudentAnalyticsConnection;
  studentAnalyticsDetails: Array<StudentAnalyticsDetails>;
  studentAnalyticsDetailsAggregate: StudentAnalyticsDetailsAggregateSelection;
  studentAnalyticsDetailsConnection: StudentAnalyticsDetailsConnection;
  studentAnalyticsResponses: Array<StudentAnalyticsResponse>;
  studentAnalyticsResponsesAggregate: StudentAnalyticsResponseAggregateSelection;
  studentAnalyticsResponsesConnection: StudentAnalyticsResponsesConnection;
  studentAnalyticsSummaryResponses: Array<StudentAnalyticsSummaryResponse>;
  studentAnalyticsSummaryResponsesAggregate: StudentAnalyticsSummaryResponseAggregateSelection;
  studentAnalyticsSummaryResponsesConnection: StudentAnalyticsSummaryResponsesConnection;
  studentChatAnalytics: Array<StudentChatAnalytics>;
  studentChatAnalyticsAggregate: StudentChatAnalyticsAggregateSelection;
  studentChatAnalyticsConnection: StudentChatAnalyticsConnection;
  studentChatAnalyticsResponses: Array<StudentChatAnalyticsResponse>;
  studentChatAnalyticsResponsesAggregate: StudentChatAnalyticsResponseAggregateSelection;
  studentChatAnalyticsResponsesConnection: StudentChatAnalyticsResponsesConnection;
  studentCourseProgressResponses: Array<StudentCourseProgressResponse>;
  studentCourseProgressResponsesAggregate: StudentCourseProgressResponseAggregateSelection;
  studentCourseProgressResponsesConnection: StudentCourseProgressResponsesConnection;
  studentDetailedAnalytics: Array<StudentDetailedAnalytics>;
  studentDetailedAnalyticsAggregate: StudentDetailedAnalyticsAggregateSelection;
  studentDetailedAnalyticsConnection: StudentDetailedAnalyticsConnection;
  studentDetailedAnalyticsResponses: Array<StudentDetailedAnalyticsResponse>;
  studentDetailedAnalyticsResponsesAggregate: StudentDetailedAnalyticsResponseAggregateSelection;
  studentDetailedAnalyticsResponsesConnection: StudentDetailedAnalyticsResponsesConnection;
  studentLearningInsights: Array<StudentLearningInsights>;
  studentLearningInsightsAggregate: StudentLearningInsightsAggregateSelection;
  studentLearningInsightsConnection: StudentLearningInsightsConnection;
  studentLearningInsightsResponses: Array<StudentLearningInsightsResponse>;
  studentLearningInsightsResponsesAggregate: StudentLearningInsightsResponseAggregateSelection;
  studentLearningInsightsResponsesConnection: StudentLearningInsightsResponsesConnection;
  studentModuleProgressData: Array<StudentModuleProgressData>;
  studentModuleProgressDataAggregate: StudentModuleProgressDataAggregateSelection;
  studentModuleProgressDataConnection: StudentModuleProgressDataConnection;
  studentModuleProgressResponses: Array<StudentModuleProgressResponse>;
  studentModuleProgressResponsesAggregate: StudentModuleProgressResponseAggregateSelection;
  studentModuleProgressResponsesConnection: StudentModuleProgressResponsesConnection;
  studentSummaries: Array<StudentSummary>;
  studentSummariesAggregate: StudentSummaryAggregateSelection;
  studentSummariesConnection: StudentSummariesConnection;
  subchapterDetails: Array<SubchapterDetails>;
  subchapterDetailsAggregate: SubchapterDetailsAggregateSelection;
  subchapterDetailsConnection: SubchapterDetailsConnection;
  subchapters: Array<Subchapter>;
  subchaptersAggregate: SubchapterAggregateSelection;
  subchaptersConnection: SubchaptersConnection;
  subfields: Array<Subfield>;
  subfieldsAggregate: SubfieldAggregateSelection;
  subfieldsConnection: SubfieldsConnection;
  submissionListResponseData: Array<SubmissionListResponseData>;
  submissionListResponseDataAggregate: SubmissionListResponseDataAggregateSelection;
  submissionListResponseDataConnection: SubmissionListResponseDataConnection;
  submissionListResponseFormatteds: Array<SubmissionListResponseFormatted>;
  submissionListResponseFormattedsAggregate: SubmissionListResponseFormattedAggregateSelection;
  submissionListResponseFormattedsConnection: SubmissionListResponseFormattedsConnection;
  submissionListResponses: Array<SubmissionListResponse>;
  submissionListResponsesAggregate: SubmissionListResponseAggregateSelection;
  submissionListResponsesConnection: SubmissionListResponsesConnection;
  submissionResponses: Array<SubmissionResponse>;
  submissionResponsesAggregate: SubmissionResponseAggregateSelection;
  submissionResponsesConnection: SubmissionResponsesConnection;
  submissions: Array<Submission>;
  submissionsAggregate: SubmissionAggregateSelection;
  submissionsConnection: SubmissionsConnection;
  suggestVideoInsights?: Maybe<SuggestVideoInsightsResponse>;
  suggestVideoInsightsResponses: Array<SuggestVideoInsightsResponse>;
  suggestVideoInsightsResponsesAggregate: SuggestVideoInsightsResponseAggregateSelection;
  suggestVideoInsightsResponsesConnection: SuggestVideoInsightsResponsesConnection;
  suggestions: Array<Suggestion>;
  suggestionsAggregate: SuggestionAggregateSelection;
  suggestionsConnection: SuggestionsConnection;
  tenantResponses: Array<TenantResponse>;
  tenantResponsesAggregate: TenantResponseAggregateSelection;
  tenantResponsesConnection: TenantResponsesConnection;
  tenantSchemaResponses: Array<TenantSchemaResponse>;
  tenantSchemaResponsesAggregate: TenantSchemaResponseAggregateSelection;
  tenantSchemaResponsesConnection: TenantSchemaResponsesConnection;
  tenants: Array<Tenant>;
  tenantsAggregate: TenantAggregateSelection;
  tenantsConnection: TenantsConnection;
  testings: Array<Testing>;
  testingsAggregate: TestingAggregateSelection;
  testingsConnection: TestingsConnection;
  todos?: Maybe<Array<Maybe<Todo>>>;
  todosAggregate: TodoAggregateSelection;
  todosConnection: TodosConnection;
  topics: Array<Topic>;
  topicsAggregate: TopicAggregateSelection;
  topicsConnection: TopicsConnection;
  trackOverviews: Array<TrackOverview>;
  trackOverviewsAggregate: TrackOverviewAggregateSelection;
  trackOverviewsConnection: TrackOverviewsConnection;
  transactionByDates: Array<TransactionByDate>;
  transactionByDatesAggregate: TransactionByDateAggregateSelection;
  transactionByDatesConnection: TransactionByDatesConnection;
  transactions: Array<Transaction>;
  transactionsAggregate: TransactionAggregateSelection;
  transactionsConnection: TransactionsConnection;
  transcriptSegments: Array<TranscriptSegment>;
  transcriptSegmentsAggregate: TranscriptSegmentAggregateSelection;
  transcriptSegmentsConnection: TranscriptSegmentsConnection;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
  updateAssignmentResponsesAggregate: UpdateAssignmentResponseAggregateSelection;
  updateAssignmentResponsesConnection: UpdateAssignmentResponsesConnection;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
  updateContentModuleNameResponsesAggregate: UpdateContentModuleNameResponseAggregateSelection;
  updateContentModuleNameResponsesConnection: UpdateContentModuleNameResponsesConnection;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
  updateUserModalOnboardingResponsesAggregate: UpdateUserModalOnboardingResponseAggregateSelection;
  updateUserModalOnboardingResponsesConnection: UpdateUserModalOnboardingResponsesConnection;
  uploadCourseThumbnailResponses: Array<UploadCourseThumbnailResponse>;
  uploadCourseThumbnailResponsesAggregate: UploadCourseThumbnailResponseAggregateSelection;
  uploadCourseThumbnailResponsesConnection: UploadCourseThumbnailResponsesConnection;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
  uploadDocumentsResponsesAggregate: UploadDocumentsResponseAggregateSelection;
  uploadDocumentsResponsesConnection: UploadDocumentsResponsesConnection;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
  uploadDocumentsToBucketResponsesAggregate: UploadDocumentsToBucketResponseAggregateSelection;
  uploadDocumentsToBucketResponsesConnection: UploadDocumentsToBucketResponsesConnection;
  uploadFacultyProfileImageResponses: Array<UploadFacultyProfileImageResponse>;
  uploadFacultyProfileImageResponsesAggregate: UploadFacultyProfileImageResponseAggregateSelection;
  uploadFacultyProfileImageResponsesConnection: UploadFacultyProfileImageResponsesConnection;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
  userCareerLoadingResponsesAggregate: UserCareerLoadingResponseAggregateSelection;
  userCareerLoadingResponsesConnection: UserCareerLoadingResponsesConnection;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
  userCourseLoadingResponsesAggregate: UserCourseLoadingResponseAggregateSelection;
  userCourseLoadingResponsesConnection: UserCourseLoadingResponsesConnection;
  userInviteCount?: Maybe<InviteCountResponse>;
  userMasteries: Array<UserMastery>;
  userMasteriesAggregate: UserMasteryAggregateSelection;
  userMasteriesConnection: UserMasteriesConnection;
  userProgresses: Array<UserProgress>;
  userProgressesAggregate: UserProgressAggregateSelection;
  userProgressesConnection: UserProgressesConnection;
  users: Array<User>;
  usersAggregate: UserAggregateSelection;
  usersConnection: UsersConnection;
  validateSession: SessionResponse;
  validityResponses: Array<ValidityResponse>;
  validityResponsesAggregate: ValidityResponseAggregateSelection;
  validityResponsesConnection: ValidityResponsesConnection;
  videoPoolItems: Array<VideoPoolItem>;
  videoPoolItemsAggregate: VideoPoolItemAggregateSelection;
  videoPoolItemsConnection: VideoPoolItemsConnection;
  videoServiceResults: Array<VideoServiceResult>;
  videoServiceResultsAggregate: VideoServiceResultAggregateSelection;
  videoServiceResultsConnection: VideoServiceResultsConnection;
  videos: Array<Video>;
  videosAggregate: VideoAggregateSelection;
  videosConnection: VideosConnection;
  visualizationResponses: Array<VisualizationResponse>;
  visualizationResponsesAggregate: VisualizationResponseAggregateSelection;
  visualizationResponsesConnection: VisualizationResponsesConnection;
  visualizations: Array<Visualization>;
  visualizationsAggregate: VisualizationAggregateSelection;
  visualizationsConnection: VisualizationsConnection;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
  waterIntakeSummariesAggregate: WaterIntakeSummaryAggregateSelection;
  waterIntakeSummariesConnection: WaterIntakeSummariesConnection;
  waterIntakes: Array<WaterIntake>;
  waterIntakesAggregate: WaterIntakeAggregateSelection;
  waterIntakesConnection: WaterIntakesConnection;
  webhooks: Array<Webhook>;
  webhooksAggregate: WebhookAggregateSelection;
  webhooksConnection: WebhooksConnection;
  weeklySummaries: Array<WeeklySummary>;
  weeklySummariesAggregate: WeeklySummaryAggregateSelection;
  weeklySummariesConnection: WeeklySummariesConnection;
  whitelistedEmails: Array<WhitelistedEmail>;
  whitelistedEmailsAggregate: WhitelistedEmailAggregateSelection;
  whitelistedEmailsConnection: WhitelistedEmailsConnection;
  workouts: Array<Workout>;
  workoutsAggregate: WorkoutAggregateSelection;
  workoutsConnection: WorkoutsConnection;
  yearlySummaries: Array<YearlySummary>;
  yearlySummariesAggregate: YearlySummaryAggregateSelection;
  yearlySummariesConnection: YearlySummariesConnection;
};

export type QueryAchievementsArgs = {
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryAchievementsAggregateArgs = {
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AchievementsSort>>>;
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryActiveTracksArgs = {
  options?: InputMaybe<ActiveTrackOptions>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryActiveTracksAggregateArgs = {
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryActiveTracksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActiveTrackSort>>>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryActivityAnalyticsArgs = {
  options?: InputMaybe<ActivityAnalyticsOptions>;
  where?: InputMaybe<ActivityAnalyticsWhere>;
};

export type QueryActivityAnalyticsAggregateArgs = {
  where?: InputMaybe<ActivityAnalyticsWhere>;
};

export type QueryActivityAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityAnalyticsSort>>>;
  where?: InputMaybe<ActivityAnalyticsWhere>;
};

export type QueryActivityAnalyticsDetailsArgs = {
  options?: InputMaybe<ActivityAnalyticsDetailsOptions>;
  where?: InputMaybe<ActivityAnalyticsDetailsWhere>;
};

export type QueryActivityAnalyticsDetailsAggregateArgs = {
  where?: InputMaybe<ActivityAnalyticsDetailsWhere>;
};

export type QueryActivityAnalyticsDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityAnalyticsDetailsWhere>;
};

export type QueryActivityAnalyticsResponsesArgs = {
  options?: InputMaybe<ActivityAnalyticsResponseOptions>;
  where?: InputMaybe<ActivityAnalyticsResponseWhere>;
};

export type QueryActivityAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<ActivityAnalyticsResponseWhere>;
};

export type QueryActivityAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityAnalyticsResponseSort>>>;
  where?: InputMaybe<ActivityAnalyticsResponseWhere>;
};

export type QueryActivityCompletionDetailsArgs = {
  options?: InputMaybe<ActivityCompletionDetailsOptions>;
  where?: InputMaybe<ActivityCompletionDetailsWhere>;
};

export type QueryActivityCompletionDetailsAggregateArgs = {
  where?: InputMaybe<ActivityCompletionDetailsWhere>;
};

export type QueryActivityCompletionDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityCompletionDetailsSort>>>;
  where?: InputMaybe<ActivityCompletionDetailsWhere>;
};

export type QueryActivityCompletionDetailsDataArgs = {
  options?: InputMaybe<ActivityCompletionDetailsDataOptions>;
  where?: InputMaybe<ActivityCompletionDetailsDataWhere>;
};

export type QueryActivityCompletionDetailsDataAggregateArgs = {
  where?: InputMaybe<ActivityCompletionDetailsDataWhere>;
};

export type QueryActivityCompletionDetailsDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityCompletionDetailsDataWhere>;
};

export type QueryActivityCompletionDetailsFullsArgs = {
  options?: InputMaybe<ActivityCompletionDetailsFullOptions>;
  where?: InputMaybe<ActivityCompletionDetailsFullWhere>;
};

export type QueryActivityCompletionDetailsFullsAggregateArgs = {
  where?: InputMaybe<ActivityCompletionDetailsFullWhere>;
};

export type QueryActivityCompletionDetailsFullsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityCompletionDetailsFullSort>>>;
  where?: InputMaybe<ActivityCompletionDetailsFullWhere>;
};

export type QueryActivityCompletionDetailsObjectivesArgs = {
  options?: InputMaybe<ActivityCompletionDetailsObjectivesOptions>;
  where?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
};

export type QueryActivityCompletionDetailsObjectivesAggregateArgs = {
  where?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
};

export type QueryActivityCompletionDetailsObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityCompletionDetailsObjectivesWhere>;
};

export type QueryActivityCompletionDetailsResponsesArgs = {
  options?: InputMaybe<ActivityCompletionDetailsResponseOptions>;
  where?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
};

export type QueryActivityCompletionDetailsResponsesAggregateArgs = {
  where?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
};

export type QueryActivityCompletionDetailsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityCompletionDetailsResponseSort>>>;
  where?: InputMaybe<ActivityCompletionDetailsResponseWhere>;
};

export type QueryActivityInsightsArgs = {
  options?: InputMaybe<ActivityInsightsOptions>;
  where?: InputMaybe<ActivityInsightsWhere>;
};

export type QueryActivityInsightsAggregateArgs = {
  where?: InputMaybe<ActivityInsightsWhere>;
};

export type QueryActivityInsightsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityInsightsSort>>>;
  where?: InputMaybe<ActivityInsightsWhere>;
};

export type QueryActivityInsightsResponsesArgs = {
  options?: InputMaybe<ActivityInsightsResponseOptions>;
  where?: InputMaybe<ActivityInsightsResponseWhere>;
};

export type QueryActivityInsightsResponsesAggregateArgs = {
  where?: InputMaybe<ActivityInsightsResponseWhere>;
};

export type QueryActivityInsightsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityInsightsResponseSort>>>;
  where?: InputMaybe<ActivityInsightsResponseWhere>;
};

export type QueryActivityProgressAnalyticsArgs = {
  options?: InputMaybe<ActivityProgressAnalyticsOptions>;
  where?: InputMaybe<ActivityProgressAnalyticsWhere>;
};

export type QueryActivityProgressAnalyticsAggregateArgs = {
  where?: InputMaybe<ActivityProgressAnalyticsWhere>;
};

export type QueryActivityProgressAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActivityProgressAnalyticsSort>>>;
  where?: InputMaybe<ActivityProgressAnalyticsWhere>;
};

export type QueryActivityProgressDetailsArgs = {
  options?: InputMaybe<ActivityProgressDetailsOptions>;
  where?: InputMaybe<ActivityProgressDetailsWhere>;
};

export type QueryActivityProgressDetailsAggregateArgs = {
  where?: InputMaybe<ActivityProgressDetailsWhere>;
};

export type QueryActivityProgressDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityProgressDetailsWhere>;
};

export type QueryAddAssignmentResponsesArgs = {
  options?: InputMaybe<AddAssignmentResponseOptions>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AddAssignmentResponseSort>>>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddContentModuleResponsesArgs = {
  options?: InputMaybe<AddContentModuleResponseOptions>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAddContentModuleResponsesAggregateArgs = {
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAddContentModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AddContentModuleResponseSort>>>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAiTutorAnalyticsArgs = {
  options?: InputMaybe<AiTutorAnalyticsOptions>;
  where?: InputMaybe<AiTutorAnalyticsWhere>;
};

export type QueryAiTutorAnalyticsAggregateArgs = {
  where?: InputMaybe<AiTutorAnalyticsWhere>;
};

export type QueryAiTutorAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorAnalyticsSort>>>;
  where?: InputMaybe<AiTutorAnalyticsWhere>;
};

export type QueryAiTutorAnalyticsResponsesArgs = {
  options?: InputMaybe<AiTutorAnalyticsResponseOptions>;
  where?: InputMaybe<AiTutorAnalyticsResponseWhere>;
};

export type QueryAiTutorAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<AiTutorAnalyticsResponseWhere>;
};

export type QueryAiTutorAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorAnalyticsResponseSort>>>;
  where?: InputMaybe<AiTutorAnalyticsResponseWhere>;
};

export type QueryAiTutorEvalCriteriaResponsesArgs = {
  options?: InputMaybe<AiTutorEvalCriteriaResponseOptions>;
  where?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
};

export type QueryAiTutorEvalCriteriaResponsesAggregateArgs = {
  where?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
};

export type QueryAiTutorEvalCriteriaResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorEvalCriteriaResponseSort>>>;
  where?: InputMaybe<AiTutorEvalCriteriaResponseWhere>;
};

export type QueryAiTutorGeneratedResponsesArgs = {
  options?: InputMaybe<AiTutorGeneratedResponseOptions>;
  where?: InputMaybe<AiTutorGeneratedResponseWhere>;
};

export type QueryAiTutorGeneratedResponsesAggregateArgs = {
  where?: InputMaybe<AiTutorGeneratedResponseWhere>;
};

export type QueryAiTutorGeneratedResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorGeneratedResponseSort>>>;
  where?: InputMaybe<AiTutorGeneratedResponseWhere>;
};

export type QueryAiTutorProgressAnalyticsArgs = {
  options?: InputMaybe<AiTutorProgressAnalyticsOptions>;
  where?: InputMaybe<AiTutorProgressAnalyticsWhere>;
};

export type QueryAiTutorProgressAnalyticsAggregateArgs = {
  where?: InputMaybe<AiTutorProgressAnalyticsWhere>;
};

export type QueryAiTutorProgressAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorProgressAnalyticsSort>>>;
  where?: InputMaybe<AiTutorProgressAnalyticsWhere>;
};

export type QueryAiTutorProgressDetailsArgs = {
  options?: InputMaybe<AiTutorProgressDetailsOptions>;
  where?: InputMaybe<AiTutorProgressDetailsWhere>;
};

export type QueryAiTutorProgressDetailsAggregateArgs = {
  where?: InputMaybe<AiTutorProgressDetailsWhere>;
};

export type QueryAiTutorProgressDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AiTutorProgressDetailsWhere>;
};

export type QueryAiTutorResponsesArgs = {
  options?: InputMaybe<AiTutorResponseOptions>;
  where?: InputMaybe<AiTutorResponseWhere>;
};

export type QueryAiTutorResponsesAggregateArgs = {
  where?: InputMaybe<AiTutorResponseWhere>;
};

export type QueryAiTutorResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorResponseSort>>>;
  where?: InputMaybe<AiTutorResponseWhere>;
};

export type QueryAiTutorsArgs = {
  options?: InputMaybe<AiTutorOptions>;
  where?: InputMaybe<AiTutorWhere>;
};

export type QueryAiTutorsAggregateArgs = {
  where?: InputMaybe<AiTutorWhere>;
};

export type QueryAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AiTutorSort>>>;
  where?: InputMaybe<AiTutorWhere>;
};

export type QueryAnotherRelationshipFieldsArgs = {
  options?: InputMaybe<AnotherRelationshipFieldOptions>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type QueryAnotherRelationshipFieldsAggregateArgs = {
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type QueryAnotherRelationshipFieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AnotherRelationshipFieldSort>>>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type QueryAspectDetailsTypesArgs = {
  options?: InputMaybe<AspectDetailsTypeOptions>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAspectDetailsTypesAggregateArgs = {
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAspectDetailsTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AspectDetailsTypeSort>>>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAssessmentListResponseDataArgs = {
  options?: InputMaybe<AssessmentListResponseDataOptions>;
  where?: InputMaybe<AssessmentListResponseDataWhere>;
};

export type QueryAssessmentListResponseDataAggregateArgs = {
  where?: InputMaybe<AssessmentListResponseDataWhere>;
};

export type QueryAssessmentListResponseDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssessmentListResponseDataSort>>>;
  where?: InputMaybe<AssessmentListResponseDataWhere>;
};

export type QueryAssessmentListResponseFormattedsArgs = {
  options?: InputMaybe<AssessmentListResponseFormattedOptions>;
  where?: InputMaybe<AssessmentListResponseFormattedWhere>;
};

export type QueryAssessmentListResponseFormattedsAggregateArgs = {
  where?: InputMaybe<AssessmentListResponseFormattedWhere>;
};

export type QueryAssessmentListResponseFormattedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssessmentListResponseFormattedSort>>>;
  where?: InputMaybe<AssessmentListResponseFormattedWhere>;
};

export type QueryAssessmentListResponsesArgs = {
  options?: InputMaybe<AssessmentListResponseOptions>;
  where?: InputMaybe<AssessmentListResponseWhere>;
};

export type QueryAssessmentListResponsesAggregateArgs = {
  where?: InputMaybe<AssessmentListResponseWhere>;
};

export type QueryAssessmentListResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssessmentListResponseSort>>>;
  where?: InputMaybe<AssessmentListResponseWhere>;
};

export type QueryAssessmentResponsesArgs = {
  options?: InputMaybe<AssessmentResponseOptions>;
  where?: InputMaybe<AssessmentResponseWhere>;
};

export type QueryAssessmentResponsesAggregateArgs = {
  where?: InputMaybe<AssessmentResponseWhere>;
};

export type QueryAssessmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssessmentResponseSort>>>;
  where?: InputMaybe<AssessmentResponseWhere>;
};

export type QueryAssessmentsArgs = {
  options?: InputMaybe<AssessmentOptions>;
  where?: InputMaybe<AssessmentWhere>;
};

export type QueryAssessmentsAggregateArgs = {
  where?: InputMaybe<AssessmentWhere>;
};

export type QueryAssessmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssessmentSort>>>;
  where?: InputMaybe<AssessmentWhere>;
};

export type QueryAssignedQuestsArgs = {
  options?: InputMaybe<AssignedQuestOptions>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignedQuestsAggregateArgs = {
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignedQuestsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignedQuestSort>>>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignmentAndSubmissionResponsesArgs = {
  options?: InputMaybe<AssignmentAndSubmissionResponseOptions>;
  where?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
};

export type QueryAssignmentAndSubmissionResponsesAggregateArgs = {
  where?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
};

export type QueryAssignmentAndSubmissionResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignmentAndSubmissionResponseSort>>>;
  where?: InputMaybe<AssignmentAndSubmissionResponseWhere>;
};

export type QueryAssignmentAndSubmissionsArgs = {
  options?: InputMaybe<AssignmentAndSubmissionOptions>;
  where?: InputMaybe<AssignmentAndSubmissionWhere>;
};

export type QueryAssignmentAndSubmissionsAggregateArgs = {
  where?: InputMaybe<AssignmentAndSubmissionWhere>;
};

export type QueryAssignmentAndSubmissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssignmentAndSubmissionWhere>;
};

export type QueryAssignmentResponsesArgs = {
  options?: InputMaybe<AssignmentResponseOptions>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignmentResponseSort>>>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentsArgs = {
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAssignmentsAggregateArgs = {
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignmentSort>>>;
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAttributesArgs = {
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAttributesAggregateArgs = {
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AttributeSort>>>;
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAudioToggleUserResponsesArgs = {
  options?: InputMaybe<AudioToggleUserResponseOptions>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAudioToggleUserResponsesAggregateArgs = {
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAudioToggleUserResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AudioToggleUserResponseSort>>>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAuthPayloadResponsesArgs = {
  options?: InputMaybe<AuthPayloadResponseOptions>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadResponsesAggregateArgs = {
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadResponseSort>>>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadUserPreferencesArgs = {
  options?: InputMaybe<AuthPayloadUserPreferencesOptions>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUserPreferencesAggregateArgs = {
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUserPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadUserPreferencesSort>>>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUsersArgs = {
  options?: InputMaybe<AuthPayloadUserOptions>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryAuthPayloadUsersAggregateArgs = {
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryAuthPayloadUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadUserSort>>>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryAxioResourceResponsesArgs = {
  options?: InputMaybe<AxioResourceResponseOptions>;
  where?: InputMaybe<AxioResourceResponseWhere>;
};

export type QueryAxioResourceResponsesAggregateArgs = {
  where?: InputMaybe<AxioResourceResponseWhere>;
};

export type QueryAxioResourceResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AxioResourceResponseSort>>>;
  where?: InputMaybe<AxioResourceResponseWhere>;
};

export type QueryAxioResourcesArgs = {
  options?: InputMaybe<AxioResourceOptions>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type QueryAxioResourcesAggregateArgs = {
  where?: InputMaybe<AxioResourceWhere>;
};

export type QueryAxioResourcesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AxioResourceSort>>>;
  where?: InputMaybe<AxioResourceWhere>;
};

export type QueryCareerHeadersArgs = {
  options?: InputMaybe<CareerHeaderOptions>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerHeadersAggregateArgs = {
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerHeadersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerHeaderSort>>>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerTopicResponsesArgs = {
  options?: InputMaybe<CareerTopicResponseOptions>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareerTopicResponsesAggregateArgs = {
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareerTopicResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerTopicResponseSort>>>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareersArgs = {
  options?: InputMaybe<CareerOptions>;
  where?: InputMaybe<CareerWhere>;
};

export type QueryCareersAggregateArgs = {
  where?: InputMaybe<CareerWhere>;
};

export type QueryCareersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerSort>>>;
  where?: InputMaybe<CareerWhere>;
};

export type QueryChallengesArgs = {
  options?: InputMaybe<ChallengeOptions>;
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChallengesAggregateArgs = {
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChallengesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChallengeSort>>>;
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChaptersArgs = {
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChaptersAggregateArgs = {
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChapterSort>>>;
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChatAnalyticsArgs = {
  options?: InputMaybe<ChatAnalyticsOptions>;
  where?: InputMaybe<ChatAnalyticsWhere>;
};

export type QueryChatAnalyticsAggregateArgs = {
  where?: InputMaybe<ChatAnalyticsWhere>;
};

export type QueryChatAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChatAnalyticsSort>>>;
  where?: InputMaybe<ChatAnalyticsWhere>;
};

export type QueryChatAttachmentResponsesArgs = {
  options?: InputMaybe<ChatAttachmentResponseOptions>;
  where?: InputMaybe<ChatAttachmentResponseWhere>;
};

export type QueryChatAttachmentResponsesAggregateArgs = {
  where?: InputMaybe<ChatAttachmentResponseWhere>;
};

export type QueryChatAttachmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChatAttachmentResponseSort>>>;
  where?: InputMaybe<ChatAttachmentResponseWhere>;
};

export type QueryChatResponsesArgs = {
  options?: InputMaybe<ChatResponseOptions>;
  where?: InputMaybe<ChatResponseWhere>;
};

export type QueryChatResponsesAggregateArgs = {
  where?: InputMaybe<ChatResponseWhere>;
};

export type QueryChatResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChatResponseSort>>>;
  where?: InputMaybe<ChatResponseWhere>;
};

export type QueryChatsArgs = {
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type QueryChatsAggregateArgs = {
  where?: InputMaybe<ChatWhere>;
};

export type QueryChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChatSort>>>;
  where?: InputMaybe<ChatWhere>;
};

export type QueryChildInfosArgs = {
  options?: InputMaybe<ChildInfoOptions>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryChildInfosAggregateArgs = {
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryChildInfosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChildInfoSort>>>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryCompletionActivityAnalyticsArgs = {
  options?: InputMaybe<CompletionActivityAnalyticsOptions>;
  where?: InputMaybe<CompletionActivityAnalyticsWhere>;
};

export type QueryCompletionActivityAnalyticsAggregateArgs = {
  where?: InputMaybe<CompletionActivityAnalyticsWhere>;
};

export type QueryCompletionActivityAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionActivityAnalyticsSort>>>;
  where?: InputMaybe<CompletionActivityAnalyticsWhere>;
};

export type QueryCompletionActivityDetailsArgs = {
  options?: InputMaybe<CompletionActivityDetailsOptions>;
  where?: InputMaybe<CompletionActivityDetailsWhere>;
};

export type QueryCompletionActivityDetailsAggregateArgs = {
  where?: InputMaybe<CompletionActivityDetailsWhere>;
};

export type QueryCompletionActivityDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CompletionActivityDetailsWhere>;
};

export type QueryCompletionAnalyticsArgs = {
  options?: InputMaybe<CompletionAnalyticsOptions>;
  where?: InputMaybe<CompletionAnalyticsWhere>;
};

export type QueryCompletionAnalyticsAggregateArgs = {
  where?: InputMaybe<CompletionAnalyticsWhere>;
};

export type QueryCompletionAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionAnalyticsSort>>>;
  where?: InputMaybe<CompletionAnalyticsWhere>;
};

export type QueryCompletionAnalyticsResponsesArgs = {
  options?: InputMaybe<CompletionAnalyticsResponseOptions>;
  where?: InputMaybe<CompletionAnalyticsResponseWhere>;
};

export type QueryCompletionAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<CompletionAnalyticsResponseWhere>;
};

export type QueryCompletionAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionAnalyticsResponseSort>>>;
  where?: InputMaybe<CompletionAnalyticsResponseWhere>;
};

export type QueryCompletionCourseDetailsArgs = {
  options?: InputMaybe<CompletionCourseDetailsOptions>;
  where?: InputMaybe<CompletionCourseDetailsWhere>;
};

export type QueryCompletionCourseDetailsAggregateArgs = {
  where?: InputMaybe<CompletionCourseDetailsWhere>;
};

export type QueryCompletionCourseDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CompletionCourseDetailsWhere>;
};

export type QueryCompletionModuleAnalyticsArgs = {
  options?: InputMaybe<CompletionModuleAnalyticsOptions>;
  where?: InputMaybe<CompletionModuleAnalyticsWhere>;
};

export type QueryCompletionModuleAnalyticsAggregateArgs = {
  where?: InputMaybe<CompletionModuleAnalyticsWhere>;
};

export type QueryCompletionModuleAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionModuleAnalyticsSort>>>;
  where?: InputMaybe<CompletionModuleAnalyticsWhere>;
};

export type QueryCompletionModuleDetailsArgs = {
  options?: InputMaybe<CompletionModuleDetailsOptions>;
  where?: InputMaybe<CompletionModuleDetailsWhere>;
};

export type QueryCompletionModuleDetailsAggregateArgs = {
  where?: InputMaybe<CompletionModuleDetailsWhere>;
};

export type QueryCompletionModuleDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CompletionModuleDetailsWhere>;
};

export type QueryCompletionObjectiveAnalyticsArgs = {
  options?: InputMaybe<CompletionObjectiveAnalyticsOptions>;
  where?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
};

export type QueryCompletionObjectiveAnalyticsAggregateArgs = {
  where?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
};

export type QueryCompletionObjectiveAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionObjectiveAnalyticsSort>>>;
  where?: InputMaybe<CompletionObjectiveAnalyticsWhere>;
};

export type QueryCompletionStatsArgs = {
  options?: InputMaybe<CompletionStatsOptions>;
  where?: InputMaybe<CompletionStatsWhere>;
};

export type QueryCompletionStatsAggregateArgs = {
  where?: InputMaybe<CompletionStatsWhere>;
};

export type QueryCompletionStatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CompletionStatsSort>>>;
  where?: InputMaybe<CompletionStatsWhere>;
};

export type QueryCoreValuesArgs = {
  options?: InputMaybe<CoreValueOptions>;
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCoreValuesAggregateArgs = {
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCoreValuesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CoreValueSort>>>;
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCourseAnalyticsArgs = {
  options?: InputMaybe<CourseAnalyticsOptions>;
  where?: InputMaybe<CourseAnalyticsWhere>;
};

export type QueryCourseAnalyticsAggregateArgs = {
  where?: InputMaybe<CourseAnalyticsWhere>;
};

export type QueryCourseAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseAnalyticsSort>>>;
  where?: InputMaybe<CourseAnalyticsWhere>;
};

export type QueryCourseAnalyticsDetailsArgs = {
  options?: InputMaybe<CourseAnalyticsDetailsOptions>;
  where?: InputMaybe<CourseAnalyticsDetailsWhere>;
};

export type QueryCourseAnalyticsDetailsAggregateArgs = {
  where?: InputMaybe<CourseAnalyticsDetailsWhere>;
};

export type QueryCourseAnalyticsDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CourseAnalyticsDetailsWhere>;
};

export type QueryCourseAnalyticsResponsesArgs = {
  options?: InputMaybe<CourseAnalyticsResponseOptions>;
  where?: InputMaybe<CourseAnalyticsResponseWhere>;
};

export type QueryCourseAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<CourseAnalyticsResponseWhere>;
};

export type QueryCourseAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseAnalyticsResponseSort>>>;
  where?: InputMaybe<CourseAnalyticsResponseWhere>;
};

export type QueryCourseAnalyticsSummaryResponsesArgs = {
  options?: InputMaybe<CourseAnalyticsSummaryResponseOptions>;
  where?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
};

export type QueryCourseAnalyticsSummaryResponsesAggregateArgs = {
  where?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
};

export type QueryCourseAnalyticsSummaryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseAnalyticsSummaryResponseSort>>>;
  where?: InputMaybe<CourseAnalyticsSummaryResponseWhere>;
};

export type QueryCourseCategoriesArgs = {
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCategoriesAggregateArgs = {
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCategorySort>>>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCompletionDetailsArgs = {
  options?: InputMaybe<CourseCompletionDetailsOptions>;
  where?: InputMaybe<CourseCompletionDetailsWhere>;
};

export type QueryCourseCompletionDetailsAggregateArgs = {
  where?: InputMaybe<CourseCompletionDetailsWhere>;
};

export type QueryCourseCompletionDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCompletionDetailsSort>>>;
  where?: InputMaybe<CourseCompletionDetailsWhere>;
};

export type QueryCourseCompletionDetailsDataArgs = {
  options?: InputMaybe<CourseCompletionDetailsDataOptions>;
  where?: InputMaybe<CourseCompletionDetailsDataWhere>;
};

export type QueryCourseCompletionDetailsDataAggregateArgs = {
  where?: InputMaybe<CourseCompletionDetailsDataWhere>;
};

export type QueryCourseCompletionDetailsDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CourseCompletionDetailsDataWhere>;
};

export type QueryCourseCompletionDetailsResponsesArgs = {
  options?: InputMaybe<CourseCompletionDetailsResponseOptions>;
  where?: InputMaybe<CourseCompletionDetailsResponseWhere>;
};

export type QueryCourseCompletionDetailsResponsesAggregateArgs = {
  where?: InputMaybe<CourseCompletionDetailsResponseWhere>;
};

export type QueryCourseCompletionDetailsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCompletionDetailsResponseSort>>>;
  where?: InputMaybe<CourseCompletionDetailsResponseWhere>;
};

export type QueryCourseCompletionSummariesArgs = {
  options?: InputMaybe<CourseCompletionSummaryOptions>;
  where?: InputMaybe<CourseCompletionSummaryWhere>;
};

export type QueryCourseCompletionSummariesAggregateArgs = {
  where?: InputMaybe<CourseCompletionSummaryWhere>;
};

export type QueryCourseCompletionSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCompletionSummarySort>>>;
  where?: InputMaybe<CourseCompletionSummaryWhere>;
};

export type QueryCourseCompletionSummaryResponsesArgs = {
  options?: InputMaybe<CourseCompletionSummaryResponseOptions>;
  where?: InputMaybe<CourseCompletionSummaryResponseWhere>;
};

export type QueryCourseCompletionSummaryResponsesAggregateArgs = {
  where?: InputMaybe<CourseCompletionSummaryResponseWhere>;
};

export type QueryCourseCompletionSummaryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCompletionSummaryResponseSort>>>;
  where?: InputMaybe<CourseCompletionSummaryResponseWhere>;
};

export type QueryCourseCreationResponsesArgs = {
  options?: InputMaybe<CourseCreationResponseOptions>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseCreationResponsesAggregateArgs = {
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseCreationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCreationResponseSort>>>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseDetailedAnalyticsArgs = {
  options?: InputMaybe<CourseDetailedAnalyticsOptions>;
  where?: InputMaybe<CourseDetailedAnalyticsWhere>;
};

export type QueryCourseDetailedAnalyticsAggregateArgs = {
  where?: InputMaybe<CourseDetailedAnalyticsWhere>;
};

export type QueryCourseDetailedAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseDetailedAnalyticsSort>>>;
  where?: InputMaybe<CourseDetailedAnalyticsWhere>;
};

export type QueryCourseDetailedResponsesArgs = {
  options?: InputMaybe<CourseDetailedResponseOptions>;
  where?: InputMaybe<CourseDetailedResponseWhere>;
};

export type QueryCourseDetailedResponsesAggregateArgs = {
  where?: InputMaybe<CourseDetailedResponseWhere>;
};

export type QueryCourseDetailedResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseDetailedResponseSort>>>;
  where?: InputMaybe<CourseDetailedResponseWhere>;
};

export type QueryCourseHierarchyResponsesArgs = {
  options?: InputMaybe<CourseHierarchyResponseOptions>;
  where?: InputMaybe<CourseHierarchyResponseWhere>;
};

export type QueryCourseHierarchyResponsesAggregateArgs = {
  where?: InputMaybe<CourseHierarchyResponseWhere>;
};

export type QueryCourseHierarchyResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseHierarchyResponseSort>>>;
  where?: InputMaybe<CourseHierarchyResponseWhere>;
};

export type QueryCourseProgressAnalyticsArgs = {
  options?: InputMaybe<CourseProgressAnalyticsOptions>;
  where?: InputMaybe<CourseProgressAnalyticsWhere>;
};

export type QueryCourseProgressAnalyticsAggregateArgs = {
  where?: InputMaybe<CourseProgressAnalyticsWhere>;
};

export type QueryCourseProgressAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseProgressAnalyticsSort>>>;
  where?: InputMaybe<CourseProgressAnalyticsWhere>;
};

export type QueryCourseProgressDetailsArgs = {
  options?: InputMaybe<CourseProgressDetailsOptions>;
  where?: InputMaybe<CourseProgressDetailsWhere>;
};

export type QueryCourseProgressDetailsAggregateArgs = {
  where?: InputMaybe<CourseProgressDetailsWhere>;
};

export type QueryCourseProgressDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CourseProgressDetailsWhere>;
};

export type QueryCourseResponsesArgs = {
  options?: InputMaybe<CourseResponseOptions>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCourseResponsesAggregateArgs = {
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCourseResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseResponseSort>>>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCourseRosterDataArgs = {
  options?: InputMaybe<CourseRosterDataOptions>;
  where?: InputMaybe<CourseRosterDataWhere>;
};

export type QueryCourseRosterDataAggregateArgs = {
  where?: InputMaybe<CourseRosterDataWhere>;
};

export type QueryCourseRosterDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseRosterDataSort>>>;
  where?: InputMaybe<CourseRosterDataWhere>;
};

export type QueryCourseRosterServiceResultsArgs = {
  options?: InputMaybe<CourseRosterServiceResultOptions>;
  where?: InputMaybe<CourseRosterServiceResultWhere>;
};

export type QueryCourseRosterServiceResultsAggregateArgs = {
  where?: InputMaybe<CourseRosterServiceResultWhere>;
};

export type QueryCourseRosterServiceResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseRosterServiceResultSort>>>;
  where?: InputMaybe<CourseRosterServiceResultWhere>;
};

export type QueryCourseSummariesArgs = {
  options?: InputMaybe<CourseSummaryOptions>;
  where?: InputMaybe<CourseSummaryWhere>;
};

export type QueryCourseSummariesAggregateArgs = {
  where?: InputMaybe<CourseSummaryWhere>;
};

export type QueryCourseSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseSummarySort>>>;
  where?: InputMaybe<CourseSummaryWhere>;
};

export type QueryCoursesArgs = {
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type QueryCoursesAggregateArgs = {
  where?: InputMaybe<CourseWhere>;
};

export type QueryCoursesByQueryDataArgs = {
  options?: InputMaybe<CoursesByQueryDataOptions>;
  where?: InputMaybe<CoursesByQueryDataWhere>;
};

export type QueryCoursesByQueryDataAggregateArgs = {
  where?: InputMaybe<CoursesByQueryDataWhere>;
};

export type QueryCoursesByQueryDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CoursesByQueryDataSort>>>;
  where?: InputMaybe<CoursesByQueryDataWhere>;
};

export type QueryCoursesByQueryResponsesArgs = {
  options?: InputMaybe<CoursesByQueryResponseOptions>;
  where?: InputMaybe<CoursesByQueryResponseWhere>;
};

export type QueryCoursesByQueryResponsesAggregateArgs = {
  where?: InputMaybe<CoursesByQueryResponseWhere>;
};

export type QueryCoursesByQueryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CoursesByQueryResponseSort>>>;
  where?: InputMaybe<CoursesByQueryResponseWhere>;
};

export type QueryCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseSort>>>;
  where?: InputMaybe<CourseWhere>;
};

export type QueryCurriculaArgs = {
  options?: InputMaybe<CurriculumOptions>;
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryCurriculaAggregateArgs = {
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryCurriculaConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CurriculumSort>>>;
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryDailyCompletedTodosCountsArgs = {
  options?: InputMaybe<DailyCompletedTodosCountOptions>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyCompletedTodosCountsAggregateArgs = {
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyCompletedTodosCountsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyCompletedTodosCountSort>>>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyExerciseTotalsArgs = {
  options?: InputMaybe<DailyExerciseTotalsOptions>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyExerciseTotalsAggregateArgs = {
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyExerciseTotalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyExerciseTotalsSort>>>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyNutritionTotalsArgs = {
  options?: InputMaybe<DailyNutritionTotalsOptions>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailyNutritionTotalsAggregateArgs = {
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailyNutritionTotalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyNutritionTotalsSort>>>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailySummariesArgs = {
  options?: InputMaybe<DailySummaryOptions>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDailySummariesAggregateArgs = {
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDailySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailySummarySort>>>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDegreeResponsesArgs = {
  options?: InputMaybe<DegreeResponseOptions>;
  where?: InputMaybe<DegreeResponseWhere>;
};

export type QueryDegreeResponsesAggregateArgs = {
  where?: InputMaybe<DegreeResponseWhere>;
};

export type QueryDegreeResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DegreeResponseSort>>>;
  where?: InputMaybe<DegreeResponseWhere>;
};

export type QueryDegreesArgs = {
  options?: InputMaybe<DegreeOptions>;
  where?: InputMaybe<DegreeWhere>;
};

export type QueryDegreesAggregateArgs = {
  where?: InputMaybe<DegreeWhere>;
};

export type QueryDegreesBySchoolByQueryDataArgs = {
  options?: InputMaybe<DegreesBySchoolByQueryDataOptions>;
  where?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
};

export type QueryDegreesBySchoolByQueryDataAggregateArgs = {
  where?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
};

export type QueryDegreesBySchoolByQueryDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DegreesBySchoolByQueryDataSort>>>;
  where?: InputMaybe<DegreesBySchoolByQueryDataWhere>;
};

export type QueryDegreesBySchoolByQueryResponsesArgs = {
  options?: InputMaybe<DegreesBySchoolByQueryResponseOptions>;
  where?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
};

export type QueryDegreesBySchoolByQueryResponsesAggregateArgs = {
  where?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
};

export type QueryDegreesBySchoolByQueryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DegreesBySchoolByQueryResponseSort>>>;
  where?: InputMaybe<DegreesBySchoolByQueryResponseWhere>;
};

export type QueryDegreesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DegreeSort>>>;
  where?: InputMaybe<DegreeWhere>;
};

export type QueryDeleteContentAssignmentResponsesArgs = {
  options?: InputMaybe<DeleteContentAssignmentResponseOptions>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentAssignmentResponseSort>>>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentVariablesArgs = {
  options?: InputMaybe<DeleteContentAssignmentVariablesOptions>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentAssignmentVariablesAggregateArgs = {
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentAssignmentVariablesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentAssignmentVariablesSort>>>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentModuleResponsesArgs = {
  options?: InputMaybe<DeleteContentModuleResponseOptions>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleResponsesAggregateArgs = {
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentModuleResponseSort>>>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleVariablesArgs = {
  options?: InputMaybe<DeleteContentModuleVariablesOptions>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteContentModuleVariablesAggregateArgs = {
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteContentModuleVariablesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentModuleVariablesSort>>>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteCourseResponsesArgs = {
  options?: InputMaybe<DeleteCourseResponseOptions>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeleteCourseResponsesAggregateArgs = {
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeleteCourseResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteCourseResponseSort>>>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeviceSessionsArgs = {
  options?: InputMaybe<DeviceSessionOptions>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryDeviceSessionsAggregateArgs = {
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryDeviceSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeviceSessionSort>>>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryEmotionalStatesArgs = {
  options?: InputMaybe<EmotionalStateOptions>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEmotionalStatesAggregateArgs = {
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEmotionalStatesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<EmotionalStateSort>>>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEventsArgs = {
  options?: InputMaybe<EventOptions>;
  where?: InputMaybe<EventWhere>;
};

export type QueryEventsAggregateArgs = {
  where?: InputMaybe<EventWhere>;
};

export type QueryEventsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<EventSort>>>;
  where?: InputMaybe<EventWhere>;
};

export type QueryExportCsvResponsesArgs = {
  options?: InputMaybe<ExportCsvResponseOptions>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryExportCsvResponsesAggregateArgs = {
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryExportCsvResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ExportCsvResponseSort>>>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryFacultyDataCoursesArgs = {
  options?: InputMaybe<FacultyDataCourseOptions>;
  where?: InputMaybe<FacultyDataCourseWhere>;
};

export type QueryFacultyDataCoursesAggregateArgs = {
  where?: InputMaybe<FacultyDataCourseWhere>;
};

export type QueryFacultyDataCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyDataCourseSort>>>;
  where?: InputMaybe<FacultyDataCourseWhere>;
};

export type QueryFacultyDataRolesArgs = {
  options?: InputMaybe<FacultyDataRoleOptions>;
  where?: InputMaybe<FacultyDataRoleWhere>;
};

export type QueryFacultyDataRolesAggregateArgs = {
  where?: InputMaybe<FacultyDataRoleWhere>;
};

export type QueryFacultyDataRolesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyDataRoleSort>>>;
  where?: InputMaybe<FacultyDataRoleWhere>;
};

export type QueryFacultyDataSessionDeviceSessionsArgs = {
  options?: InputMaybe<FacultyDataSessionDeviceSessionOptions>;
  where?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
};

export type QueryFacultyDataSessionDeviceSessionsAggregateArgs = {
  where?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
};

export type QueryFacultyDataSessionDeviceSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyDataSessionDeviceSessionSort>>>;
  where?: InputMaybe<FacultyDataSessionDeviceSessionWhere>;
};

export type QueryFacultyQueryResponseDataArgs = {
  options?: InputMaybe<FacultyQueryResponseDataOptions>;
  where?: InputMaybe<FacultyQueryResponseDataWhere>;
};

export type QueryFacultyQueryResponseDataAggregateArgs = {
  where?: InputMaybe<FacultyQueryResponseDataWhere>;
};

export type QueryFacultyQueryResponseDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyQueryResponseDataSort>>>;
  where?: InputMaybe<FacultyQueryResponseDataWhere>;
};

export type QueryFacultyQueryResponseWithCountDataArgs = {
  options?: InputMaybe<FacultyQueryResponseWithCountDataOptions>;
  where?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
};

export type QueryFacultyQueryResponseWithCountDataAggregateArgs = {
  where?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
};

export type QueryFacultyQueryResponseWithCountDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyQueryResponseWithCountDataSort>>>;
  where?: InputMaybe<FacultyQueryResponseWithCountDataWhere>;
};

export type QueryFacultyQueryResponsesArgs = {
  options?: InputMaybe<FacultyQueryResponseOptions>;
  where?: InputMaybe<FacultyQueryResponseWhere>;
};

export type QueryFacultyQueryResponsesAggregateArgs = {
  where?: InputMaybe<FacultyQueryResponseWhere>;
};

export type QueryFacultyQueryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FacultyQueryResponseSort>>>;
  where?: InputMaybe<FacultyQueryResponseWhere>;
};

export type QueryFeedbacksArgs = {
  options?: InputMaybe<FeedbackOptions>;
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFeedbacksAggregateArgs = {
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFeedbacksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FeedbackSort>>>;
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFieldsArgs = {
  options?: InputMaybe<FieldOptions>;
  where?: InputMaybe<FieldWhere>;
};

export type QueryFieldsAggregateArgs = {
  where?: InputMaybe<FieldWhere>;
};

export type QueryFieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FieldSort>>>;
  where?: InputMaybe<FieldWhere>;
};

export type QueryFileResponsesArgs = {
  options?: InputMaybe<FileResponseOptions>;
  where?: InputMaybe<FileResponseWhere>;
};

export type QueryFileResponsesAggregateArgs = {
  where?: InputMaybe<FileResponseWhere>;
};

export type QueryFileResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FileResponseSort>>>;
  where?: InputMaybe<FileResponseWhere>;
};

export type QueryFileUploadResponsesArgs = {
  options?: InputMaybe<FileUploadResponseOptions>;
  where?: InputMaybe<FileUploadResponseWhere>;
};

export type QueryFileUploadResponsesAggregateArgs = {
  where?: InputMaybe<FileUploadResponseWhere>;
};

export type QueryFileUploadResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FileUploadResponseSort>>>;
  where?: InputMaybe<FileUploadResponseWhere>;
};

export type QueryFilesArgs = {
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type QueryFilesAggregateArgs = {
  where?: InputMaybe<FileWhere>;
};

export type QueryFilesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FileSort>>>;
  where?: InputMaybe<FileWhere>;
};

export type QueryFoodItemsArgs = {
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodItemsAggregateArgs = {
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FoodItemSort>>>;
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodsArgs = {
  nutritionId: Scalars["String"]["input"];
};

export type QueryFoodsAggregateArgs = {
  where?: InputMaybe<FoodWhere>;
};

export type QueryFoodsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FoodSort>>>;
  where?: InputMaybe<FoodWhere>;
};

export type QueryFormattedAiTutorsArgs = {
  options?: InputMaybe<FormattedAiTutorOptions>;
  where?: InputMaybe<FormattedAiTutorWhere>;
};

export type QueryFormattedAiTutorsAggregateArgs = {
  where?: InputMaybe<FormattedAiTutorWhere>;
};

export type QueryFormattedAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FormattedAiTutorSort>>>;
  where?: InputMaybe<FormattedAiTutorWhere>;
};

export type QueryFormattedChatsArgs = {
  options?: InputMaybe<FormattedChatOptions>;
  where?: InputMaybe<FormattedChatWhere>;
};

export type QueryFormattedChatsAggregateArgs = {
  where?: InputMaybe<FormattedChatWhere>;
};

export type QueryFormattedChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FormattedChatSort>>>;
  where?: InputMaybe<FormattedChatWhere>;
};

export type QueryFormattedObjectivesArgs = {
  options?: InputMaybe<FormattedObjectiveOptions>;
  where?: InputMaybe<FormattedObjectiveWhere>;
};

export type QueryFormattedObjectivesAggregateArgs = {
  where?: InputMaybe<FormattedObjectiveWhere>;
};

export type QueryFormattedObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FormattedObjectiveSort>>>;
  where?: InputMaybe<FormattedObjectiveWhere>;
};

export type QueryFormattedVisualizationsArgs = {
  options?: InputMaybe<FormattedVisualizationOptions>;
  where?: InputMaybe<FormattedVisualizationWhere>;
};

export type QueryFormattedVisualizationsAggregateArgs = {
  where?: InputMaybe<FormattedVisualizationWhere>;
};

export type QueryFormattedVisualizationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FormattedVisualizationSort>>>;
  where?: InputMaybe<FormattedVisualizationWhere>;
};

export type QueryGamesArgs = {
  options?: InputMaybe<GameOptions>;
  where?: InputMaybe<GameWhere>;
};

export type QueryGamesAggregateArgs = {
  where?: InputMaybe<GameWhere>;
};

export type QueryGamesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GameSort>>>;
  where?: InputMaybe<GameWhere>;
};

export type QueryGenerateCourseContentDataArgs = {
  options?: InputMaybe<GenerateCourseContentDataOptions>;
  where?: InputMaybe<GenerateCourseContentDataWhere>;
};

export type QueryGenerateCourseContentDataAggregateArgs = {
  where?: InputMaybe<GenerateCourseContentDataWhere>;
};

export type QueryGenerateCourseContentDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GenerateCourseContentDataSort>>>;
  where?: InputMaybe<GenerateCourseContentDataWhere>;
};

export type QueryGenerateCourseContentResponsesArgs = {
  options?: InputMaybe<GenerateCourseContentResponseOptions>;
  where?: InputMaybe<GenerateCourseContentResponseWhere>;
};

export type QueryGenerateCourseContentResponsesAggregateArgs = {
  where?: InputMaybe<GenerateCourseContentResponseWhere>;
};

export type QueryGenerateCourseContentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GenerateCourseContentResponseSort>>>;
  where?: InputMaybe<GenerateCourseContentResponseWhere>;
};

export type QueryGeneratedAiTutorsArgs = {
  options?: InputMaybe<GeneratedAiTutorOptions>;
  where?: InputMaybe<GeneratedAiTutorWhere>;
};

export type QueryGeneratedAiTutorsAggregateArgs = {
  where?: InputMaybe<GeneratedAiTutorWhere>;
};

export type QueryGeneratedAiTutorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GeneratedAiTutorSort>>>;
  where?: InputMaybe<GeneratedAiTutorWhere>;
};

export type QueryGeneratedObjectivesArgs = {
  options?: InputMaybe<GeneratedObjectiveOptions>;
  where?: InputMaybe<GeneratedObjectiveWhere>;
};

export type QueryGeneratedObjectivesAggregateArgs = {
  where?: InputMaybe<GeneratedObjectiveWhere>;
};

export type QueryGeneratedObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GeneratedObjectiveSort>>>;
  where?: InputMaybe<GeneratedObjectiveWhere>;
};

export type QueryGeneratedsArgs = {
  options?: InputMaybe<GeneratedOptions>;
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGeneratedsAggregateArgs = {
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGeneratedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GeneratedSort>>>;
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGetAiTutorArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetAiTutorAnalyticsArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetActivityAnalyticsByModuleArgs = {
  moduleId: Scalars["ID"]["input"];
};

export type QueryGetActivityCompletionDetailsArgs = {
  aiTutorId: Scalars["ID"]["input"];
};

export type QueryGetActivityInsightsDataArgs = {
  aiTutorId: Scalars["ID"]["input"];
};

export type QueryGetAllAiTutorsByCourseArgs = {
  courseIds: Array<Scalars["ID"]["input"]>;
};

export type QueryGetAllAxioResourcesByCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetAllCoursesBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetAllModulesByCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetAssessmentArgs = {
  assessmentId: Scalars["ID"]["input"];
};

export type QueryGetAssessmentByFacultyArgs = {
  input: GetAssessmentByFacultyInput;
};

export type QueryGetAssignmentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetAssignmentAndSubmissionBySubmissionIdArgs = {
  submissionId: Scalars["ID"]["input"];
};

export type QueryGetAssignmentObjectiveResponsesArgs = {
  options?: InputMaybe<GetAssignmentObjectiveResponseOptions>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentObjectiveResponsesAggregateArgs = {
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentObjectiveResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetAssignmentObjectiveResponseSort>>>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentResponsesArgs = {
  options?: InputMaybe<GetAssignmentResponseOptions>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetAssignmentResponseSort>>>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetAxioResourceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCareerByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCareerTopicsArgs = {
  careerId: Scalars["ID"]["input"];
};

export type QueryGetChaptersArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetChatByContextAndParamsArgs = {
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type QueryGetChatByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetChatByObjectIdArgs = {
  context: Scalars["String"]["input"];
  objectId: Scalars["ID"]["input"];
};

export type QueryGetChatForDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetCompletionAnalyticsBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetContentByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCourseAnalyticsByIdArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetCourseByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCourseByNameArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetCourseCompletionDetailsArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetCourseCompletionSummaryBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetCourseHierarchyArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetCoursesAnalyticsBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetCoursesByDegreeByQueryArgs = {
  degreeId: Scalars["ID"]["input"];
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetCoursesByFacultyArgs = {
  query?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
};

export type QueryGetCoursesBySchoolByQueryArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetCoursesForTranscriptGenerationArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetCoursesSummaryBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetDailyCompletedTodosArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyExerciseTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyNutritionTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDegreeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetDegreeByNameArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetDegreeBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetDegreesByCourseByQueryArgs = {
  courseId: Scalars["ID"]["input"];
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetDegreesBySchoolByQueryArgs = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetEnrolledCoursesBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetFacultyByCourseIdArgs = {
  courseId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFacultyByNameOrEmailArgs = {
  nameOrEmail: Scalars["String"]["input"];
  page: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetFacultyForSchoolArgs = {
  page: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetFileArgs = {
  fileId: Scalars["ID"]["input"];
};

export type QueryGetFirstSubchapterByTopicNameArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetFoodsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetIsInvitationTokenValidArgs = {
  service: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type QueryGetJourneyByIdArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type QueryGetJourneyByIdResponsesArgs = {
  options?: InputMaybe<GetJourneyByIdResponseOptions>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneyByIdResponsesAggregateArgs = {
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneyByIdResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetJourneyByIdResponseSort>>>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneysResponsesArgs = {
  options?: InputMaybe<GetJourneysResponseOptions>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetJourneysResponsesAggregateArgs = {
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetJourneysResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetJourneysResponseSort>>>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetLearnedSubchaptersByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetLearningObjectiveByIdArgs = {
  loId: Scalars["ID"]["input"];
};

export type QueryGetLearningObjectivesBySubchapterArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetLearningTreeArgs = {
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryGetModuleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetModuleAnalyticsByCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetModuleCompletionDetailsArgs = {
  moduleId: Scalars["ID"]["input"];
};

export type QueryGetMostRelevantVideoArgs = {
  conversationContext: Scalars["String"]["input"];
  domain: Scalars["String"]["input"];
  objectiveId: Scalars["ID"]["input"];
};

export type QueryGetNodeArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeParentsArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNotEnrolledCoursesBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetObjectiveAnalyticsByActivityArgs = {
  aiTutorId: Scalars["ID"]["input"];
};

export type QueryGetObjectiveCompletionDetailsArgs = {
  objectiveId: Scalars["ID"]["input"];
};

export type QueryGetPageArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetRecentTracksArgs = {
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSiblingSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetStudentActivityProgressArgs = {
  aiTutorId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentAnalyticsBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetStudentAnalyticsForStudentArgs = {
  schoolId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentChatAnalyticsArgs = {
  schoolId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentCourseProgressArgs = {
  courseId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentDetailedAnalyticsArgs = {
  schoolId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentLearningInsightsArgs = {
  schoolId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentModuleProgressArgs = {
  moduleId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
};

export type QueryGetStudentsByCourseIdArgs = {
  courseId: Scalars["ID"]["input"];
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetStudentsSummaryBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetSubchapterByIdArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetSubchapterPrerequisitesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
};

export type QueryGetSubfieldsArgs = {
  fieldName: Scalars["String"]["input"];
};

export type QueryGetTableOfContentsArgs = {
  hierarchyLabel: Scalars["String"]["input"];
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetTenantArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetTodayChatArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTodayGameArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTopicsArgs = {
  subfieldName: Scalars["String"]["input"];
};

export type QueryGetTrackOverviewArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetTransactionHistoryArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetTransactionHistoryForDateArgs = {
  date: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserDailySummariesArgs = {
  timeRange: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserJournalEntryArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserLearningObjectiveMasteryArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetUserMasteryArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserProgressArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
};

export type QueryGetUserSubmissionsArgs = {
  assignmentId: Scalars["ID"]["input"];
};

export type QueryGetUserSubmissionsByFacultyArgs = {
  input: GetUserSubmissionsByFacultyInput;
};

export type QueryGetUsersForSchoolArgs = {
  page: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetVisualizationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetVisualizationsForTutorArgs = {
  tutorId: Scalars["ID"]["input"];
};

export type QueryGetWaterIntakeByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGoalsArgs = {
  options?: InputMaybe<GoalOptions>;
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoalsAggregateArgs = {
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GoalSort>>>;
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoogleAuthTokensArgs = {
  options?: InputMaybe<GoogleAuthTokensOptions>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryGoogleAuthTokensAggregateArgs = {
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryGoogleAuthTokensConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GoogleAuthTokensSort>>>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryHierarchiesArgs = {
  options?: InputMaybe<HierarchyOptions>;
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchiesAggregateArgs = {
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchySort>>>;
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchyActivitiesArgs = {
  options?: InputMaybe<HierarchyActivityOptions>;
  where?: InputMaybe<HierarchyActivityWhere>;
};

export type QueryHierarchyActivitiesAggregateArgs = {
  where?: InputMaybe<HierarchyActivityWhere>;
};

export type QueryHierarchyActivitiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyActivitySort>>>;
  where?: InputMaybe<HierarchyActivityWhere>;
};

export type QueryHierarchyActivityDetailsArgs = {
  options?: InputMaybe<HierarchyActivityDetailsOptions>;
  where?: InputMaybe<HierarchyActivityDetailsWhere>;
};

export type QueryHierarchyActivityDetailsAggregateArgs = {
  where?: InputMaybe<HierarchyActivityDetailsWhere>;
};

export type QueryHierarchyActivityDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HierarchyActivityDetailsWhere>;
};

export type QueryHierarchyCourseDetailsArgs = {
  options?: InputMaybe<HierarchyCourseDetailsOptions>;
  where?: InputMaybe<HierarchyCourseDetailsWhere>;
};

export type QueryHierarchyCourseDetailsAggregateArgs = {
  where?: InputMaybe<HierarchyCourseDetailsWhere>;
};

export type QueryHierarchyCourseDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HierarchyCourseDetailsWhere>;
};

export type QueryHierarchyCoursesArgs = {
  options?: InputMaybe<HierarchyCourseOptions>;
  where?: InputMaybe<HierarchyCourseWhere>;
};

export type QueryHierarchyCoursesAggregateArgs = {
  where?: InputMaybe<HierarchyCourseWhere>;
};

export type QueryHierarchyCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyCourseSort>>>;
  where?: InputMaybe<HierarchyCourseWhere>;
};

export type QueryHierarchyModuleDetailsArgs = {
  options?: InputMaybe<HierarchyModuleDetailsOptions>;
  where?: InputMaybe<HierarchyModuleDetailsWhere>;
};

export type QueryHierarchyModuleDetailsAggregateArgs = {
  where?: InputMaybe<HierarchyModuleDetailsWhere>;
};

export type QueryHierarchyModuleDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HierarchyModuleDetailsWhere>;
};

export type QueryHierarchyModulesArgs = {
  options?: InputMaybe<HierarchyModuleOptions>;
  where?: InputMaybe<HierarchyModuleWhere>;
};

export type QueryHierarchyModulesAggregateArgs = {
  where?: InputMaybe<HierarchyModuleWhere>;
};

export type QueryHierarchyModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyModuleSort>>>;
  where?: InputMaybe<HierarchyModuleWhere>;
};

export type QueryHierarchyNodesArgs = {
  options?: InputMaybe<HierarchyNodeOptions>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHierarchyNodesAggregateArgs = {
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHierarchyNodesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyNodeSort>>>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHierarchyObjectivesArgs = {
  options?: InputMaybe<HierarchyObjectiveOptions>;
  where?: InputMaybe<HierarchyObjectiveWhere>;
};

export type QueryHierarchyObjectivesAggregateArgs = {
  where?: InputMaybe<HierarchyObjectiveWhere>;
};

export type QueryHierarchyObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyObjectiveSort>>>;
  where?: InputMaybe<HierarchyObjectiveWhere>;
};

export type QueryHobbiesArgs = {
  options?: InputMaybe<HobbyOptions>;
  where?: InputMaybe<HobbyWhere>;
};

export type QueryHobbiesAggregateArgs = {
  where?: InputMaybe<HobbyWhere>;
};

export type QueryHobbiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HobbySort>>>;
  where?: InputMaybe<HobbyWhere>;
};

export type QueryInterestsArgs = {
  options?: InputMaybe<InterestOptions>;
  where?: InputMaybe<InterestWhere>;
};

export type QueryInterestsAggregateArgs = {
  where?: InputMaybe<InterestWhere>;
};

export type QueryInterestsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InterestSort>>>;
  where?: InputMaybe<InterestWhere>;
};

export type QueryInviteCountResponsesArgs = {
  options?: InputMaybe<InviteCountResponseOptions>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryInviteCountResponsesAggregateArgs = {
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryInviteCountResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InviteCountResponseSort>>>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryInviteUsersToEnrollInCourseResponsesArgs = {
  options?: InputMaybe<InviteUsersToEnrollInCourseResponseOptions>;
  where?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
};

export type QueryInviteUsersToEnrollInCourseResponsesAggregateArgs = {
  where?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
};

export type QueryInviteUsersToEnrollInCourseResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InviteUsersToEnrollInCourseResponseSort>>>;
  where?: InputMaybe<InviteUsersToEnrollInCourseResponseWhere>;
};

export type QueryInviteUsersToEnrollInDegreeResponsesArgs = {
  options?: InputMaybe<InviteUsersToEnrollInDegreeResponseOptions>;
  where?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
};

export type QueryInviteUsersToEnrollInDegreeResponsesAggregateArgs = {
  where?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
};

export type QueryInviteUsersToEnrollInDegreeResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InviteUsersToEnrollInDegreeResponseSort>>>;
  where?: InputMaybe<InviteUsersToEnrollInDegreeResponseWhere>;
};

export type QueryInvitedUsersArgs = {
  options?: InputMaybe<InvitedUsersOptions>;
  where?: InputMaybe<InvitedUsersWhere>;
};

export type QueryInvitedUsersAggregateArgs = {
  where?: InputMaybe<InvitedUsersWhere>;
};

export type QueryInvitedUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InvitedUsersSort>>>;
  where?: InputMaybe<InvitedUsersWhere>;
};

export type QueryJourneysArgs = {
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type QueryJourneysAggregateArgs = {
  where?: InputMaybe<JourneyWhere>;
};

export type QueryJourneysConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<JourneySort>>>;
  where?: InputMaybe<JourneyWhere>;
};

export type QueryLearningCheckoutSessionsArgs = {
  options?: InputMaybe<LearningCheckoutSessionOptions>;
  where?: InputMaybe<LearningCheckoutSessionWhere>;
};

export type QueryLearningCheckoutSessionsAggregateArgs = {
  where?: InputMaybe<LearningCheckoutSessionWhere>;
};

export type QueryLearningCheckoutSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningCheckoutSessionSort>>>;
  where?: InputMaybe<LearningCheckoutSessionWhere>;
};

export type QueryLearningNodesArgs = {
  options?: InputMaybe<LearningNodeOptions>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningNodesAggregateArgs = {
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningNodesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningNodeSort>>>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningObjectiveResponsesArgs = {
  options?: InputMaybe<LearningObjectiveResponseOptions>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectiveResponsesAggregateArgs = {
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectiveResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningObjectiveResponseSort>>>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectivesArgs = {
  options?: InputMaybe<LearningObjectiveOptions>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningObjectivesAggregateArgs = {
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningObjectiveSort>>>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningPaymentsArgs = {
  options?: InputMaybe<LearningPaymentOptions>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type QueryLearningPaymentsAggregateArgs = {
  where?: InputMaybe<LearningPaymentWhere>;
};

export type QueryLearningPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningPaymentSort>>>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type QueryLearningSummariesArgs = {
  options?: InputMaybe<LearningSummaryOptions>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningSummariesAggregateArgs = {
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningSummarySort>>>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningTreesArgs = {
  options?: InputMaybe<LearningTreeOptions>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLearningTreesAggregateArgs = {
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLearningTreesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningTreeSort>>>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLessonsArgs = {
  options?: InputMaybe<LessonOptions>;
  where?: InputMaybe<LessonWhere>;
};

export type QueryLessonsAggregateArgs = {
  where?: InputMaybe<LessonWhere>;
};

export type QueryLessonsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LessonSort>>>;
  where?: InputMaybe<LessonWhere>;
};

export type QueryLevelDetailsArgs = {
  options?: InputMaybe<LevelDetailsOptions>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLevelDetailsAggregateArgs = {
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLevelDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LevelDetailsSort>>>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLifeAspirationsArgs = {
  options?: InputMaybe<LifeAspirationOptions>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryLifeAspirationsAggregateArgs = {
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryLifeAspirationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LifeAspirationSort>>>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryMasteryResultsArgs = {
  options?: InputMaybe<MasteryResultOptions>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMasteryResultsAggregateArgs = {
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMasteryResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MasteryResultSort>>>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMeResponsesArgs = {
  options?: InputMaybe<MeResponseOptions>;
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMeResponsesAggregateArgs = {
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMeResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MeResponseSort>>>;
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMessageResponsesArgs = {
  options?: InputMaybe<MessageResponseOptions>;
  where?: InputMaybe<MessageResponseWhere>;
};

export type QueryMessageResponsesAggregateArgs = {
  where?: InputMaybe<MessageResponseWhere>;
};

export type QueryMessageResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MessageResponseSort>>>;
  where?: InputMaybe<MessageResponseWhere>;
};

export type QueryMessagesArgs = {
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type QueryMessagesAggregateArgs = {
  where?: InputMaybe<MessageWhere>;
};

export type QueryMessagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MessageSort>>>;
  where?: InputMaybe<MessageWhere>;
};

export type QueryModuleAnalyticsArgs = {
  options?: InputMaybe<ModuleAnalyticsOptions>;
  where?: InputMaybe<ModuleAnalyticsWhere>;
};

export type QueryModuleAnalyticsAggregateArgs = {
  where?: InputMaybe<ModuleAnalyticsWhere>;
};

export type QueryModuleAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleAnalyticsSort>>>;
  where?: InputMaybe<ModuleAnalyticsWhere>;
};

export type QueryModuleAnalyticsDetailsArgs = {
  options?: InputMaybe<ModuleAnalyticsDetailsOptions>;
  where?: InputMaybe<ModuleAnalyticsDetailsWhere>;
};

export type QueryModuleAnalyticsDetailsAggregateArgs = {
  where?: InputMaybe<ModuleAnalyticsDetailsWhere>;
};

export type QueryModuleAnalyticsDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ModuleAnalyticsDetailsWhere>;
};

export type QueryModuleAnalyticsResponsesArgs = {
  options?: InputMaybe<ModuleAnalyticsResponseOptions>;
  where?: InputMaybe<ModuleAnalyticsResponseWhere>;
};

export type QueryModuleAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<ModuleAnalyticsResponseWhere>;
};

export type QueryModuleAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleAnalyticsResponseSort>>>;
  where?: InputMaybe<ModuleAnalyticsResponseWhere>;
};

export type QueryModuleCompletionDetailsArgs = {
  options?: InputMaybe<ModuleCompletionDetailsOptions>;
  where?: InputMaybe<ModuleCompletionDetailsWhere>;
};

export type QueryModuleCompletionDetailsActivitiesArgs = {
  options?: InputMaybe<ModuleCompletionDetailsActivitiesOptions>;
  where?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
};

export type QueryModuleCompletionDetailsActivitiesAggregateArgs = {
  where?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
};

export type QueryModuleCompletionDetailsActivitiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ModuleCompletionDetailsActivitiesWhere>;
};

export type QueryModuleCompletionDetailsAggregateArgs = {
  where?: InputMaybe<ModuleCompletionDetailsWhere>;
};

export type QueryModuleCompletionDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleCompletionDetailsSort>>>;
  where?: InputMaybe<ModuleCompletionDetailsWhere>;
};

export type QueryModuleCompletionDetailsDataArgs = {
  options?: InputMaybe<ModuleCompletionDetailsDataOptions>;
  where?: InputMaybe<ModuleCompletionDetailsDataWhere>;
};

export type QueryModuleCompletionDetailsDataAggregateArgs = {
  where?: InputMaybe<ModuleCompletionDetailsDataWhere>;
};

export type QueryModuleCompletionDetailsDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ModuleCompletionDetailsDataWhere>;
};

export type QueryModuleCompletionDetailsFullsArgs = {
  options?: InputMaybe<ModuleCompletionDetailsFullOptions>;
  where?: InputMaybe<ModuleCompletionDetailsFullWhere>;
};

export type QueryModuleCompletionDetailsFullsAggregateArgs = {
  where?: InputMaybe<ModuleCompletionDetailsFullWhere>;
};

export type QueryModuleCompletionDetailsFullsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleCompletionDetailsFullSort>>>;
  where?: InputMaybe<ModuleCompletionDetailsFullWhere>;
};

export type QueryModuleCompletionDetailsResponsesArgs = {
  options?: InputMaybe<ModuleCompletionDetailsResponseOptions>;
  where?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
};

export type QueryModuleCompletionDetailsResponsesAggregateArgs = {
  where?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
};

export type QueryModuleCompletionDetailsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleCompletionDetailsResponseSort>>>;
  where?: InputMaybe<ModuleCompletionDetailsResponseWhere>;
};

export type QueryModuleProgressAnalyticsArgs = {
  options?: InputMaybe<ModuleProgressAnalyticsOptions>;
  where?: InputMaybe<ModuleProgressAnalyticsWhere>;
};

export type QueryModuleProgressAnalyticsAggregateArgs = {
  where?: InputMaybe<ModuleProgressAnalyticsWhere>;
};

export type QueryModuleProgressAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleProgressAnalyticsSort>>>;
  where?: InputMaybe<ModuleProgressAnalyticsWhere>;
};

export type QueryModuleProgressDetailsArgs = {
  options?: InputMaybe<ModuleProgressDetailsOptions>;
  where?: InputMaybe<ModuleProgressDetailsWhere>;
};

export type QueryModuleProgressDetailsAggregateArgs = {
  where?: InputMaybe<ModuleProgressDetailsWhere>;
};

export type QueryModuleProgressDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ModuleProgressDetailsWhere>;
};

export type QueryModuleResponsesArgs = {
  options?: InputMaybe<ModuleResponseOptions>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModuleResponsesAggregateArgs = {
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleResponseSort>>>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModulesArgs = {
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type QueryModulesAggregateArgs = {
  where?: InputMaybe<ModuleWhere>;
};

export type QueryModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleSort>>>;
  where?: InputMaybe<ModuleWhere>;
};

export type QueryMonthlySummariesArgs = {
  options?: InputMaybe<MonthlySummaryOptions>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryMonthlySummariesAggregateArgs = {
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryMonthlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MonthlySummarySort>>>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryNodeIdentitiesArgs = {
  options?: InputMaybe<NodeIdentityOptions>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodeIdentitiesAggregateArgs = {
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodeIdentitiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NodeIdentitySort>>>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodePropertiesArgs = {
  options?: InputMaybe<NodePropertiesOptions>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNodePropertiesAggregateArgs = {
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNodePropertiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NodePropertiesSort>>>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNutritionByDateArgs = {
  date: Scalars["String"]["input"];
};

export type QueryNutritionsArgs = {
  options?: InputMaybe<NutritionOptions>;
  where?: InputMaybe<NutritionWhere>;
};

export type QueryNutritionsAggregateArgs = {
  where?: InputMaybe<NutritionWhere>;
};

export type QueryNutritionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NutritionSort>>>;
  where?: InputMaybe<NutritionWhere>;
};

export type QueryOAuthAccessTokensArgs = {
  options?: InputMaybe<OAuthAccessTokenOptions>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type QueryOAuthAccessTokensAggregateArgs = {
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type QueryOAuthAccessTokensConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<OAuthAccessTokenSort>>>;
  where?: InputMaybe<OAuthAccessTokenWhere>;
};

export type QueryOAuthClientsArgs = {
  options?: InputMaybe<OAuthClientOptions>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type QueryOAuthClientsAggregateArgs = {
  where?: InputMaybe<OAuthClientWhere>;
};

export type QueryOAuthClientsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<OAuthClientSort>>>;
  where?: InputMaybe<OAuthClientWhere>;
};

export type QueryObjectiveAnalyticsArgs = {
  options?: InputMaybe<ObjectiveAnalyticsOptions>;
  where?: InputMaybe<ObjectiveAnalyticsWhere>;
};

export type QueryObjectiveAnalyticsAggregateArgs = {
  where?: InputMaybe<ObjectiveAnalyticsWhere>;
};

export type QueryObjectiveAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveAnalyticsSort>>>;
  where?: InputMaybe<ObjectiveAnalyticsWhere>;
};

export type QueryObjectiveAnalyticsResponsesArgs = {
  options?: InputMaybe<ObjectiveAnalyticsResponseOptions>;
  where?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
};

export type QueryObjectiveAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
};

export type QueryObjectiveAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveAnalyticsResponseSort>>>;
  where?: InputMaybe<ObjectiveAnalyticsResponseWhere>;
};

export type QueryObjectiveCompletionDetailsArgs = {
  options?: InputMaybe<ObjectiveCompletionDetailsOptions>;
  where?: InputMaybe<ObjectiveCompletionDetailsWhere>;
};

export type QueryObjectiveCompletionDetailsAggregateArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsWhere>;
};

export type QueryObjectiveCompletionDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveCompletionDetailsSort>>>;
  where?: InputMaybe<ObjectiveCompletionDetailsWhere>;
};

export type QueryObjectiveCompletionDetailsFullsArgs = {
  options?: InputMaybe<ObjectiveCompletionDetailsFullOptions>;
  where?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
};

export type QueryObjectiveCompletionDetailsFullsAggregateArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
};

export type QueryObjectiveCompletionDetailsFullsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveCompletionDetailsFullSort>>>;
  where?: InputMaybe<ObjectiveCompletionDetailsFullWhere>;
};

export type QueryObjectiveCompletionDetailsResponsesArgs = {
  options?: InputMaybe<ObjectiveCompletionDetailsResponseOptions>;
  where?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
};

export type QueryObjectiveCompletionDetailsResponsesAggregateArgs = {
  where?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
};

export type QueryObjectiveCompletionDetailsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveCompletionDetailsResponseSort>>>;
  where?: InputMaybe<ObjectiveCompletionDetailsResponseWhere>;
};

export type QueryObjectiveProgressAnalyticsArgs = {
  options?: InputMaybe<ObjectiveProgressAnalyticsOptions>;
  where?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
};

export type QueryObjectiveProgressAnalyticsAggregateArgs = {
  where?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
};

export type QueryObjectiveProgressAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveProgressAnalyticsSort>>>;
  where?: InputMaybe<ObjectiveProgressAnalyticsWhere>;
};

export type QueryObjectivesArgs = {
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryObjectivesAggregateArgs = {
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveSort>>>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryPageResponsesArgs = {
  options?: InputMaybe<PageResponseOptions>;
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPageResponsesAggregateArgs = {
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPageResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PageResponseSort>>>;
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPagesArgs = {
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type QueryPagesAggregateArgs = {
  where?: InputMaybe<PageWhere>;
};

export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PageSort>>>;
  where?: InputMaybe<PageWhere>;
};

export type QueryParametersTypesArgs = {
  options?: InputMaybe<ParametersTypeOptions>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryParametersTypesAggregateArgs = {
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryParametersTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ParametersTypeSort>>>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryParentRefsArgs = {
  options?: InputMaybe<ParentRefOptions>;
  where?: InputMaybe<ParentRefWhere>;
};

export type QueryParentRefsAggregateArgs = {
  where?: InputMaybe<ParentRefWhere>;
};

export type QueryParentRefsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ParentRefSort>>>;
  where?: InputMaybe<ParentRefWhere>;
};

export type QueryPaymentsArgs = {
  options?: InputMaybe<PaymentOptions>;
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPaymentsAggregateArgs = {
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PaymentSort>>>;
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPermissionsArgs = {
  options?: InputMaybe<PermissionOptions>;
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPermissionsAggregateArgs = {
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPermissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PermissionSort>>>;
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPersonalityTraitsArgs = {
  options?: InputMaybe<PersonalityTraitOptions>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPersonalityTraitsAggregateArgs = {
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPersonalityTraitsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PersonalityTraitSort>>>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPreferencesArgs = {
  options?: InputMaybe<PreferencesOptions>;
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPreferencesAggregateArgs = {
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PreferencesSort>>>;
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPrerequisitesArgs = {
  options?: InputMaybe<PrerequisiteOptions>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryPrerequisitesAggregateArgs = {
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryPrerequisitesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PrerequisiteSort>>>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryProgressDetailsArgs = {
  options?: InputMaybe<ProgressDetailsOptions>;
  where?: InputMaybe<ProgressDetailsWhere>;
};

export type QueryProgressDetailsAggregateArgs = {
  where?: InputMaybe<ProgressDetailsWhere>;
};

export type QueryProgressDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ProgressDetailsSort>>>;
  where?: InputMaybe<ProgressDetailsWhere>;
};

export type QueryProgressesArgs = {
  options?: InputMaybe<ProgressOptions>;
  where?: InputMaybe<ProgressWhere>;
};

export type QueryProgressesAggregateArgs = {
  where?: InputMaybe<ProgressWhere>;
};

export type QueryProgressesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ProgressSort>>>;
  where?: InputMaybe<ProgressWhere>;
};

export type QueryQuarterlySummariesArgs = {
  options?: InputMaybe<QuarterlySummaryOptions>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryQuarterlySummariesAggregateArgs = {
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryQuarterlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<QuarterlySummarySort>>>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryRateLimitResultsArgs = {
  options?: InputMaybe<RateLimitResultOptions>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRateLimitResultsAggregateArgs = {
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRateLimitResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RateLimitResultSort>>>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRecentCategoriesArgs = {
  options?: InputMaybe<RecentCategoryOptions>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentCategoriesAggregateArgs = {
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecentCategorySort>>>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentTracksArgs = {
  options?: InputMaybe<RecentTrackOptions>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecentTracksAggregateArgs = {
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecentTracksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecentTrackSort>>>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecomendedsArgs = {
  options?: InputMaybe<RecomendedOptions>;
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryRecomendedsAggregateArgs = {
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryRecomendedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecomendedSort>>>;
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryReflectionsArgs = {
  options?: InputMaybe<ReflectionOptions>;
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryReflectionsAggregateArgs = {
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryReflectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ReflectionSort>>>;
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryRefreshQuestResponsesArgs = {
  options?: InputMaybe<RefreshQuestResponseOptions>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryRefreshQuestResponsesAggregateArgs = {
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryRefreshQuestResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RefreshQuestResponseSort>>>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryRelationshipFieldsArgs = {
  options?: InputMaybe<RelationshipFieldOptions>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type QueryRelationshipFieldsAggregateArgs = {
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type QueryRelationshipFieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RelationshipFieldSort>>>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type QueryResponseTypesArgs = {
  options?: InputMaybe<ResponseTypeOptions>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryResponseTypesAggregateArgs = {
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryResponseTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ResponseTypeSort>>>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryRolesArgs = {
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type QueryRolesAggregateArgs = {
  where?: InputMaybe<RoleWhere>;
};

export type QueryRolesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RoleSort>>>;
  where?: InputMaybe<RoleWhere>;
};

export type QuerySchoolsArgs = {
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySchoolsAggregateArgs = {
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySchoolsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SchoolSort>>>;
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySessionResponsesArgs = {
  options?: InputMaybe<SessionResponseOptions>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type QuerySessionResponsesAggregateArgs = {
  where?: InputMaybe<SessionResponseWhere>;
};

export type QuerySessionResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SessionResponseSort>>>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type QueryStudentActivityProgressDataArgs = {
  options?: InputMaybe<StudentActivityProgressDataOptions>;
  where?: InputMaybe<StudentActivityProgressDataWhere>;
};

export type QueryStudentActivityProgressDataAggregateArgs = {
  where?: InputMaybe<StudentActivityProgressDataWhere>;
};

export type QueryStudentActivityProgressDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentActivityProgressDataSort>>>;
  where?: InputMaybe<StudentActivityProgressDataWhere>;
};

export type QueryStudentActivityProgressResponsesArgs = {
  options?: InputMaybe<StudentActivityProgressResponseOptions>;
  where?: InputMaybe<StudentActivityProgressResponseWhere>;
};

export type QueryStudentActivityProgressResponsesAggregateArgs = {
  where?: InputMaybe<StudentActivityProgressResponseWhere>;
};

export type QueryStudentActivityProgressResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentActivityProgressResponseSort>>>;
  where?: InputMaybe<StudentActivityProgressResponseWhere>;
};

export type QueryStudentAnalyticsArgs = {
  options?: InputMaybe<StudentAnalyticsOptions>;
  where?: InputMaybe<StudentAnalyticsWhere>;
};

export type QueryStudentAnalyticsAggregateArgs = {
  where?: InputMaybe<StudentAnalyticsWhere>;
};

export type QueryStudentAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentAnalyticsSort>>>;
  where?: InputMaybe<StudentAnalyticsWhere>;
};

export type QueryStudentAnalyticsDetailsArgs = {
  options?: InputMaybe<StudentAnalyticsDetailsOptions>;
  where?: InputMaybe<StudentAnalyticsDetailsWhere>;
};

export type QueryStudentAnalyticsDetailsAggregateArgs = {
  where?: InputMaybe<StudentAnalyticsDetailsWhere>;
};

export type QueryStudentAnalyticsDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentAnalyticsDetailsWhere>;
};

export type QueryStudentAnalyticsResponsesArgs = {
  options?: InputMaybe<StudentAnalyticsResponseOptions>;
  where?: InputMaybe<StudentAnalyticsResponseWhere>;
};

export type QueryStudentAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<StudentAnalyticsResponseWhere>;
};

export type QueryStudentAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentAnalyticsResponseSort>>>;
  where?: InputMaybe<StudentAnalyticsResponseWhere>;
};

export type QueryStudentAnalyticsSummaryResponsesArgs = {
  options?: InputMaybe<StudentAnalyticsSummaryResponseOptions>;
  where?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
};

export type QueryStudentAnalyticsSummaryResponsesAggregateArgs = {
  where?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
};

export type QueryStudentAnalyticsSummaryResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentAnalyticsSummaryResponseSort>>>;
  where?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
};

export type QueryStudentChatAnalyticsArgs = {
  options?: InputMaybe<StudentChatAnalyticsOptions>;
  where?: InputMaybe<StudentChatAnalyticsWhere>;
};

export type QueryStudentChatAnalyticsAggregateArgs = {
  where?: InputMaybe<StudentChatAnalyticsWhere>;
};

export type QueryStudentChatAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentChatAnalyticsSort>>>;
  where?: InputMaybe<StudentChatAnalyticsWhere>;
};

export type QueryStudentChatAnalyticsResponsesArgs = {
  options?: InputMaybe<StudentChatAnalyticsResponseOptions>;
  where?: InputMaybe<StudentChatAnalyticsResponseWhere>;
};

export type QueryStudentChatAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<StudentChatAnalyticsResponseWhere>;
};

export type QueryStudentChatAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentChatAnalyticsResponseSort>>>;
  where?: InputMaybe<StudentChatAnalyticsResponseWhere>;
};

export type QueryStudentCourseProgressResponsesArgs = {
  options?: InputMaybe<StudentCourseProgressResponseOptions>;
  where?: InputMaybe<StudentCourseProgressResponseWhere>;
};

export type QueryStudentCourseProgressResponsesAggregateArgs = {
  where?: InputMaybe<StudentCourseProgressResponseWhere>;
};

export type QueryStudentCourseProgressResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentCourseProgressResponseSort>>>;
  where?: InputMaybe<StudentCourseProgressResponseWhere>;
};

export type QueryStudentDetailedAnalyticsArgs = {
  options?: InputMaybe<StudentDetailedAnalyticsOptions>;
  where?: InputMaybe<StudentDetailedAnalyticsWhere>;
};

export type QueryStudentDetailedAnalyticsAggregateArgs = {
  where?: InputMaybe<StudentDetailedAnalyticsWhere>;
};

export type QueryStudentDetailedAnalyticsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentDetailedAnalyticsSort>>>;
  where?: InputMaybe<StudentDetailedAnalyticsWhere>;
};

export type QueryStudentDetailedAnalyticsResponsesArgs = {
  options?: InputMaybe<StudentDetailedAnalyticsResponseOptions>;
  where?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
};

export type QueryStudentDetailedAnalyticsResponsesAggregateArgs = {
  where?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
};

export type QueryStudentDetailedAnalyticsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentDetailedAnalyticsResponseSort>>>;
  where?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
};

export type QueryStudentLearningInsightsArgs = {
  options?: InputMaybe<StudentLearningInsightsOptions>;
  where?: InputMaybe<StudentLearningInsightsWhere>;
};

export type QueryStudentLearningInsightsAggregateArgs = {
  where?: InputMaybe<StudentLearningInsightsWhere>;
};

export type QueryStudentLearningInsightsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentLearningInsightsSort>>>;
  where?: InputMaybe<StudentLearningInsightsWhere>;
};

export type QueryStudentLearningInsightsResponsesArgs = {
  options?: InputMaybe<StudentLearningInsightsResponseOptions>;
  where?: InputMaybe<StudentLearningInsightsResponseWhere>;
};

export type QueryStudentLearningInsightsResponsesAggregateArgs = {
  where?: InputMaybe<StudentLearningInsightsResponseWhere>;
};

export type QueryStudentLearningInsightsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentLearningInsightsResponseSort>>>;
  where?: InputMaybe<StudentLearningInsightsResponseWhere>;
};

export type QueryStudentModuleProgressDataArgs = {
  options?: InputMaybe<StudentModuleProgressDataOptions>;
  where?: InputMaybe<StudentModuleProgressDataWhere>;
};

export type QueryStudentModuleProgressDataAggregateArgs = {
  where?: InputMaybe<StudentModuleProgressDataWhere>;
};

export type QueryStudentModuleProgressDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentModuleProgressDataSort>>>;
  where?: InputMaybe<StudentModuleProgressDataWhere>;
};

export type QueryStudentModuleProgressResponsesArgs = {
  options?: InputMaybe<StudentModuleProgressResponseOptions>;
  where?: InputMaybe<StudentModuleProgressResponseWhere>;
};

export type QueryStudentModuleProgressResponsesAggregateArgs = {
  where?: InputMaybe<StudentModuleProgressResponseWhere>;
};

export type QueryStudentModuleProgressResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentModuleProgressResponseSort>>>;
  where?: InputMaybe<StudentModuleProgressResponseWhere>;
};

export type QueryStudentSummariesArgs = {
  options?: InputMaybe<StudentSummaryOptions>;
  where?: InputMaybe<StudentSummaryWhere>;
};

export type QueryStudentSummariesAggregateArgs = {
  where?: InputMaybe<StudentSummaryWhere>;
};

export type QueryStudentSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<StudentSummarySort>>>;
  where?: InputMaybe<StudentSummaryWhere>;
};

export type QuerySubchapterDetailsArgs = {
  options?: InputMaybe<SubchapterDetailsOptions>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchapterDetailsAggregateArgs = {
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchapterDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubchapterDetailsSort>>>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchaptersArgs = {
  options?: InputMaybe<SubchapterOptions>;
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubchaptersAggregateArgs = {
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubchaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubchapterSort>>>;
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubfieldsArgs = {
  options?: InputMaybe<SubfieldOptions>;
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySubfieldsAggregateArgs = {
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySubfieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubfieldSort>>>;
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySubmissionListResponseDataArgs = {
  options?: InputMaybe<SubmissionListResponseDataOptions>;
  where?: InputMaybe<SubmissionListResponseDataWhere>;
};

export type QuerySubmissionListResponseDataAggregateArgs = {
  where?: InputMaybe<SubmissionListResponseDataWhere>;
};

export type QuerySubmissionListResponseDataConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubmissionListResponseDataSort>>>;
  where?: InputMaybe<SubmissionListResponseDataWhere>;
};

export type QuerySubmissionListResponseFormattedsArgs = {
  options?: InputMaybe<SubmissionListResponseFormattedOptions>;
  where?: InputMaybe<SubmissionListResponseFormattedWhere>;
};

export type QuerySubmissionListResponseFormattedsAggregateArgs = {
  where?: InputMaybe<SubmissionListResponseFormattedWhere>;
};

export type QuerySubmissionListResponseFormattedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubmissionListResponseFormattedSort>>>;
  where?: InputMaybe<SubmissionListResponseFormattedWhere>;
};

export type QuerySubmissionListResponsesArgs = {
  options?: InputMaybe<SubmissionListResponseOptions>;
  where?: InputMaybe<SubmissionListResponseWhere>;
};

export type QuerySubmissionListResponsesAggregateArgs = {
  where?: InputMaybe<SubmissionListResponseWhere>;
};

export type QuerySubmissionListResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubmissionListResponseSort>>>;
  where?: InputMaybe<SubmissionListResponseWhere>;
};

export type QuerySubmissionResponsesArgs = {
  options?: InputMaybe<SubmissionResponseOptions>;
  where?: InputMaybe<SubmissionResponseWhere>;
};

export type QuerySubmissionResponsesAggregateArgs = {
  where?: InputMaybe<SubmissionResponseWhere>;
};

export type QuerySubmissionResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubmissionResponseSort>>>;
  where?: InputMaybe<SubmissionResponseWhere>;
};

export type QuerySubmissionsArgs = {
  options?: InputMaybe<SubmissionOptions>;
  where?: InputMaybe<SubmissionWhere>;
};

export type QuerySubmissionsAggregateArgs = {
  where?: InputMaybe<SubmissionWhere>;
};

export type QuerySubmissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubmissionSort>>>;
  where?: InputMaybe<SubmissionWhere>;
};

export type QuerySuggestVideoInsightsArgs = {
  queryData: SuggestVideoInsightsInput;
};

export type QuerySuggestVideoInsightsResponsesArgs = {
  options?: InputMaybe<SuggestVideoInsightsResponseOptions>;
  where?: InputMaybe<SuggestVideoInsightsResponseWhere>;
};

export type QuerySuggestVideoInsightsResponsesAggregateArgs = {
  where?: InputMaybe<SuggestVideoInsightsResponseWhere>;
};

export type QuerySuggestVideoInsightsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SuggestVideoInsightsResponseSort>>>;
  where?: InputMaybe<SuggestVideoInsightsResponseWhere>;
};

export type QuerySuggestionsArgs = {
  options?: InputMaybe<SuggestionOptions>;
  where?: InputMaybe<SuggestionWhere>;
};

export type QuerySuggestionsAggregateArgs = {
  where?: InputMaybe<SuggestionWhere>;
};

export type QuerySuggestionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SuggestionSort>>>;
  where?: InputMaybe<SuggestionWhere>;
};

export type QueryTenantResponsesArgs = {
  options?: InputMaybe<TenantResponseOptions>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantResponsesAggregateArgs = {
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TenantResponseSort>>>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantSchemaResponsesArgs = {
  options?: InputMaybe<TenantSchemaResponseOptions>;
  where?: InputMaybe<TenantSchemaResponseWhere>;
};

export type QueryTenantSchemaResponsesAggregateArgs = {
  where?: InputMaybe<TenantSchemaResponseWhere>;
};

export type QueryTenantSchemaResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TenantSchemaResponseSort>>>;
  where?: InputMaybe<TenantSchemaResponseWhere>;
};

export type QueryTenantsArgs = {
  options?: InputMaybe<TenantOptions>;
  where?: InputMaybe<TenantWhere>;
};

export type QueryTenantsAggregateArgs = {
  where?: InputMaybe<TenantWhere>;
};

export type QueryTenantsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TenantSort>>>;
  where?: InputMaybe<TenantWhere>;
};

export type QueryTestingsArgs = {
  options?: InputMaybe<TestingOptions>;
  where?: InputMaybe<TestingWhere>;
};

export type QueryTestingsAggregateArgs = {
  where?: InputMaybe<TestingWhere>;
};

export type QueryTestingsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TestingSort>>>;
  where?: InputMaybe<TestingWhere>;
};

export type QueryTodosAggregateArgs = {
  where?: InputMaybe<TodoWhere>;
};

export type QueryTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TodoSort>>>;
  where?: InputMaybe<TodoWhere>;
};

export type QueryTopicsArgs = {
  options?: InputMaybe<TopicOptions>;
  where?: InputMaybe<TopicWhere>;
};

export type QueryTopicsAggregateArgs = {
  where?: InputMaybe<TopicWhere>;
};

export type QueryTopicsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TopicSort>>>;
  where?: InputMaybe<TopicWhere>;
};

export type QueryTrackOverviewsArgs = {
  options?: InputMaybe<TrackOverviewOptions>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTrackOverviewsAggregateArgs = {
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTrackOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TrackOverviewSort>>>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTransactionByDatesArgs = {
  options?: InputMaybe<TransactionByDateOptions>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionByDatesAggregateArgs = {
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionByDatesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TransactionByDateSort>>>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionsArgs = {
  options?: InputMaybe<TransactionOptions>;
  where?: InputMaybe<TransactionWhere>;
};

export type QueryTransactionsAggregateArgs = {
  where?: InputMaybe<TransactionWhere>;
};

export type QueryTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TransactionSort>>>;
  where?: InputMaybe<TransactionWhere>;
};

export type QueryTranscriptSegmentsArgs = {
  options?: InputMaybe<TranscriptSegmentOptions>;
  where?: InputMaybe<TranscriptSegmentWhere>;
};

export type QueryTranscriptSegmentsAggregateArgs = {
  where?: InputMaybe<TranscriptSegmentWhere>;
};

export type QueryTranscriptSegmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TranscriptSegmentSort>>>;
  where?: InputMaybe<TranscriptSegmentWhere>;
};

export type QueryUpdateAssignmentResponsesArgs = {
  options?: InputMaybe<UpdateAssignmentResponseOptions>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateAssignmentResponseSort>>>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesArgs = {
  options?: InputMaybe<UpdateContentModuleNameResponseOptions>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesAggregateArgs = {
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateContentModuleNameResponseSort>>>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesArgs = {
  options?: InputMaybe<UpdateUserModalOnboardingResponseOptions>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesAggregateArgs = {
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateUserModalOnboardingResponseSort>>>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUploadCourseThumbnailResponsesArgs = {
  options?: InputMaybe<UploadCourseThumbnailResponseOptions>;
  where?: InputMaybe<UploadCourseThumbnailResponseWhere>;
};

export type QueryUploadCourseThumbnailResponsesAggregateArgs = {
  where?: InputMaybe<UploadCourseThumbnailResponseWhere>;
};

export type QueryUploadCourseThumbnailResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadCourseThumbnailResponseSort>>>;
  where?: InputMaybe<UploadCourseThumbnailResponseWhere>;
};

export type QueryUploadDocumentsResponsesArgs = {
  options?: InputMaybe<UploadDocumentsResponseOptions>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsResponsesAggregateArgs = {
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadDocumentsResponseSort>>>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesArgs = {
  options?: InputMaybe<UploadDocumentsToBucketResponseOptions>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesAggregateArgs = {
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadDocumentsToBucketResponseSort>>>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUploadFacultyProfileImageResponsesArgs = {
  options?: InputMaybe<UploadFacultyProfileImageResponseOptions>;
  where?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
};

export type QueryUploadFacultyProfileImageResponsesAggregateArgs = {
  where?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
};

export type QueryUploadFacultyProfileImageResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadFacultyProfileImageResponseSort>>>;
  where?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
};

export type QueryUserCareerLoadingResponsesArgs = {
  options?: InputMaybe<UserCareerLoadingResponseOptions>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCareerLoadingResponsesAggregateArgs = {
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCareerLoadingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserCareerLoadingResponseSort>>>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesArgs = {
  options?: InputMaybe<UserCourseLoadingResponseOptions>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesAggregateArgs = {
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserCourseLoadingResponseSort>>>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserMasteriesArgs = {
  options?: InputMaybe<UserMasteryOptions>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserMasteriesAggregateArgs = {
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserMasteriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserMasterySort>>>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserProgressesArgs = {
  options?: InputMaybe<UserProgressOptions>;
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUserProgressesAggregateArgs = {
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUserProgressesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserProgressSort>>>;
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUsersArgs = {
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type QueryUsersAggregateArgs = {
  where?: InputMaybe<UserWhere>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserSort>>>;
  where?: InputMaybe<UserWhere>;
};

export type QueryValidityResponsesArgs = {
  options?: InputMaybe<ValidityResponseOptions>;
  where?: InputMaybe<ValidityResponseWhere>;
};

export type QueryValidityResponsesAggregateArgs = {
  where?: InputMaybe<ValidityResponseWhere>;
};

export type QueryValidityResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ValidityResponseSort>>>;
  where?: InputMaybe<ValidityResponseWhere>;
};

export type QueryVideoPoolItemsArgs = {
  options?: InputMaybe<VideoPoolItemOptions>;
  where?: InputMaybe<VideoPoolItemWhere>;
};

export type QueryVideoPoolItemsAggregateArgs = {
  where?: InputMaybe<VideoPoolItemWhere>;
};

export type QueryVideoPoolItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<VideoPoolItemSort>>>;
  where?: InputMaybe<VideoPoolItemWhere>;
};

export type QueryVideoServiceResultsArgs = {
  options?: InputMaybe<VideoServiceResultOptions>;
  where?: InputMaybe<VideoServiceResultWhere>;
};

export type QueryVideoServiceResultsAggregateArgs = {
  where?: InputMaybe<VideoServiceResultWhere>;
};

export type QueryVideoServiceResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<VideoServiceResultSort>>>;
  where?: InputMaybe<VideoServiceResultWhere>;
};

export type QueryVideosArgs = {
  options?: InputMaybe<VideoOptions>;
  where?: InputMaybe<VideoWhere>;
};

export type QueryVideosAggregateArgs = {
  where?: InputMaybe<VideoWhere>;
};

export type QueryVideosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<VideoSort>>>;
  where?: InputMaybe<VideoWhere>;
};

export type QueryVisualizationResponsesArgs = {
  options?: InputMaybe<VisualizationResponseOptions>;
  where?: InputMaybe<VisualizationResponseWhere>;
};

export type QueryVisualizationResponsesAggregateArgs = {
  where?: InputMaybe<VisualizationResponseWhere>;
};

export type QueryVisualizationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<VisualizationResponseSort>>>;
  where?: InputMaybe<VisualizationResponseWhere>;
};

export type QueryVisualizationsArgs = {
  options?: InputMaybe<VisualizationOptions>;
  where?: InputMaybe<VisualizationWhere>;
};

export type QueryVisualizationsAggregateArgs = {
  where?: InputMaybe<VisualizationWhere>;
};

export type QueryVisualizationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<VisualizationSort>>>;
  where?: InputMaybe<VisualizationWhere>;
};

export type QueryWaterIntakeSummariesArgs = {
  options?: InputMaybe<WaterIntakeSummaryOptions>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakeSummariesAggregateArgs = {
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakeSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WaterIntakeSummarySort>>>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakesArgs = {
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWaterIntakesAggregateArgs = {
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WaterIntakeSort>>>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWebhooksArgs = {
  options?: InputMaybe<WebhookOptions>;
  where?: InputMaybe<WebhookWhere>;
};

export type QueryWebhooksAggregateArgs = {
  where?: InputMaybe<WebhookWhere>;
};

export type QueryWebhooksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WebhookSort>>>;
  where?: InputMaybe<WebhookWhere>;
};

export type QueryWeeklySummariesArgs = {
  options?: InputMaybe<WeeklySummaryOptions>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWeeklySummariesAggregateArgs = {
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWeeklySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WeeklySummarySort>>>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWhitelistedEmailsArgs = {
  options?: InputMaybe<WhitelistedEmailOptions>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWhitelistedEmailsAggregateArgs = {
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWhitelistedEmailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WhitelistedEmailSort>>>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWorkoutsArgs = {
  options?: InputMaybe<WorkoutOptions>;
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryWorkoutsAggregateArgs = {
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryWorkoutsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WorkoutSort>>>;
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryYearlySummariesArgs = {
  options?: InputMaybe<YearlySummaryOptions>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type QueryYearlySummariesAggregateArgs = {
  where?: InputMaybe<YearlySummaryWhere>;
};

export type QueryYearlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<YearlySummarySort>>>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export enum QuestionGenerationStatus {
  Completed = "completed",
  Failed = "failed",
  Processing = "processing",
}

export type RateLimitResult = {
  __typename?: "RateLimitResult";
  dailyRequests?: Maybe<Scalars["Int"]["output"]>;
  result?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RateLimitResultAggregateSelection = {
  __typename?: "RateLimitResultAggregateSelection";
  count: Scalars["Int"]["output"];
  dailyRequests: IntAggregateSelection;
};

export type RateLimitResultCreateInput = {
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultEdge = {
  __typename?: "RateLimitResultEdge";
  cursor: Scalars["String"]["output"];
  node: RateLimitResult;
};

export type RateLimitResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RateLimitResultSort objects to sort RateLimitResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RateLimitResultSort>>;
};

/** Fields to sort RateLimitResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one RateLimitResultSort object. */
export type RateLimitResultSort = {
  dailyRequests?: InputMaybe<SortDirection>;
  result?: InputMaybe<SortDirection>;
};

export type RateLimitResultUpdateInput = {
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultWhere = {
  AND?: InputMaybe<Array<RateLimitResultWhere>>;
  NOT?: InputMaybe<RateLimitResultWhere>;
  OR?: InputMaybe<Array<RateLimitResultWhere>>;
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyRequests_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultsConnection = {
  __typename?: "RateLimitResultsConnection";
  edges: Array<RateLimitResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RecentCategoriesConnection = {
  __typename?: "RecentCategoriesConnection";
  edges: Array<RecentCategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RecentCategory = {
  __typename?: "RecentCategory";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type RecentCategoryAggregateSelection = {
  __typename?: "RecentCategoryAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type RecentCategoryCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentCategoryEdge = {
  __typename?: "RecentCategoryEdge";
  cursor: Scalars["String"]["output"];
  node: RecentCategory;
};

export type RecentCategoryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecentCategorySort objects to sort RecentCategories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecentCategorySort>>;
};

/** Fields to sort RecentCategories by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecentCategorySort object. */
export type RecentCategorySort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type RecentCategoryUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentCategoryWhere = {
  AND?: InputMaybe<Array<RecentCategoryWhere>>;
  NOT?: InputMaybe<RecentCategoryWhere>;
  OR?: InputMaybe<Array<RecentCategoryWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrack = {
  __typename?: "RecentTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type RecentTrackAggregateSelection = {
  __typename?: "RecentTrackAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentSubfield: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type RecentTrackCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrackEdge = {
  __typename?: "RecentTrackEdge";
  cursor: Scalars["String"]["output"];
  node: RecentTrack;
};

export type RecentTrackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecentTrackSort objects to sort RecentTracks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecentTrackSort>>;
};

/** Fields to sort RecentTracks by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecentTrackSort object. */
export type RecentTrackSort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentSubfield?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type RecentTrackUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrackWhere = {
  AND?: InputMaybe<Array<RecentTrackWhere>>;
  NOT?: InputMaybe<RecentTrackWhere>;
  OR?: InputMaybe<Array<RecentTrackWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTracksConnection = {
  __typename?: "RecentTracksConnection";
  edges: Array<RecentTrackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Recomended = {
  __typename?: "Recomended";
  chapterName?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type RecomendedAggregateSelection = {
  __typename?: "RecomendedAggregateSelection";
  chapterName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type RecomendedCreateInput = {
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedEdge = {
  __typename?: "RecomendedEdge";
  cursor: Scalars["String"]["output"];
  node: Recomended;
};

export type RecomendedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecomendedSort objects to sort Recomendeds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecomendedSort>>;
};

/** Fields to sort Recomendeds by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecomendedSort object. */
export type RecomendedSort = {
  chapterName?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type RecomendedUpdateInput = {
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedWhere = {
  AND?: InputMaybe<Array<RecomendedWhere>>;
  NOT?: InputMaybe<RecomendedWhere>;
  OR?: InputMaybe<Array<RecomendedWhere>>;
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  chapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedsConnection = {
  __typename?: "RecomendedsConnection";
  edges: Array<RecomendedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Reflection = {
  __typename?: "Reflection";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ReflectionAggregateSelection = {
  __typename?: "ReflectionAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  date: DateTimeAggregateSelection;
  id: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type ReflectionCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionEdge = {
  __typename?: "ReflectionEdge";
  cursor: Scalars["String"]["output"];
  node: Reflection;
};

export type ReflectionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ReflectionSort objects to sort Reflections by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ReflectionSort>>;
};

/** Fields to sort Reflections by. The order in which sorts are applied is not guaranteed when specifying many fields in one ReflectionSort object. */
export type ReflectionSort = {
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type ReflectionUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionWhere = {
  AND?: InputMaybe<Array<ReflectionWhere>>;
  NOT?: InputMaybe<ReflectionWhere>;
  OR?: InputMaybe<Array<ReflectionWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionsConnection = {
  __typename?: "ReflectionsConnection";
  edges: Array<ReflectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RefreshQuestResponse = {
  __typename?: "RefreshQuestResponse";
  message: Scalars["String"]["output"];
  quests: Array<AssignedQuest>;
  success: Scalars["Boolean"]["output"];
};

export type RefreshQuestResponseAggregateSelection = {
  __typename?: "RefreshQuestResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type RefreshQuestResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type RefreshQuestResponseEdge = {
  __typename?: "RefreshQuestResponseEdge";
  cursor: Scalars["String"]["output"];
  node: RefreshQuestResponse;
};

export type RefreshQuestResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RefreshQuestResponseSort objects to sort RefreshQuestResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RefreshQuestResponseSort>>;
};

/** Fields to sort RefreshQuestResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one RefreshQuestResponseSort object. */
export type RefreshQuestResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type RefreshQuestResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RefreshQuestResponseWhere = {
  AND?: InputMaybe<Array<RefreshQuestResponseWhere>>;
  NOT?: InputMaybe<RefreshQuestResponseWhere>;
  OR?: InputMaybe<Array<RefreshQuestResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RefreshQuestResponsesConnection = {
  __typename?: "RefreshQuestResponsesConnection";
  edges: Array<RefreshQuestResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/**
 * The edge properties for the following fields:
 * * Testing.anotherRelationshipField
 * * AnotherRelationshipField.testing
 */
export type RelationEdgeProperties = {
  __typename?: "RelationEdgeProperties";
  anotherRelationshipFieldProperty?: Maybe<Scalars["String"]["output"]>;
  someRelationshipFieldProperty?: Maybe<Scalars["String"]["output"]>;
};

export type RelationEdgePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<RelationEdgePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<RelationEdgePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<RelationEdgePropertiesAggregationWhereInput>>;
  anotherRelationshipFieldProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  anotherRelationshipFieldProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherRelationshipFieldProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherRelationshipFieldProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherRelationshipFieldProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherRelationshipFieldProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherRelationshipFieldProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  someRelationshipFieldProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  someRelationshipFieldProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  someRelationshipFieldProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  someRelationshipFieldProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  someRelationshipFieldProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someRelationshipFieldProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RelationEdgePropertiesCreateInput = {
  anotherRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
};

export type RelationEdgePropertiesSort = {
  anotherRelationshipFieldProperty?: InputMaybe<SortDirection>;
  someRelationshipFieldProperty?: InputMaybe<SortDirection>;
};

export type RelationEdgePropertiesUpdateInput = {
  anotherRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
};

export type RelationEdgePropertiesWhere = {
  AND?: InputMaybe<Array<RelationEdgePropertiesWhere>>;
  NOT?: InputMaybe<RelationEdgePropertiesWhere>;
  OR?: InputMaybe<Array<RelationEdgePropertiesWhere>>;
  anotherRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
  anotherRelationshipFieldProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  anotherRelationshipFieldProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  anotherRelationshipFieldProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  anotherRelationshipFieldProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someRelationshipFieldProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  someRelationshipFieldProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type RelationshipField = {
  __typename?: "RelationshipField";
  anotherProperty?: Maybe<Scalars["String"]["output"]>;
  someProperty?: Maybe<Scalars["String"]["output"]>;
  testing?: Maybe<Testing>;
  testingAggregate?: Maybe<RelationshipFieldTestingTestingAggregationSelection>;
  testingConnection: RelationshipFieldTestingConnection;
};

export type RelationshipFieldTestingArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TestingOptions>;
  where?: InputMaybe<TestingWhere>;
};

export type RelationshipFieldTestingAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TestingWhere>;
};

export type RelationshipFieldTestingConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<RelationshipFieldTestingConnectionSort>>;
  where?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
};

export type RelationshipFieldAggregateSelection = {
  __typename?: "RelationshipFieldAggregateSelection";
  anotherProperty: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  someProperty: StringAggregateSelection;
};

export type RelationshipFieldConnectInput = {
  testing?: InputMaybe<RelationshipFieldTestingConnectFieldInput>;
};

export type RelationshipFieldConnectWhere = {
  node: RelationshipFieldWhere;
};

export type RelationshipFieldCreateInput = {
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<RelationshipFieldTestingFieldInput>;
};

export type RelationshipFieldDeleteInput = {
  testing?: InputMaybe<RelationshipFieldTestingDeleteFieldInput>;
};

export type RelationshipFieldDisconnectInput = {
  testing?: InputMaybe<RelationshipFieldTestingDisconnectFieldInput>;
};

export type RelationshipFieldEdge = {
  __typename?: "RelationshipFieldEdge";
  cursor: Scalars["String"]["output"];
  node: RelationshipField;
};

export type RelationshipFieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RelationshipFieldSort objects to sort RelationshipFields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RelationshipFieldSort>>;
};

export type RelationshipFieldRelationInput = {
  testing?: InputMaybe<RelationshipFieldTestingCreateFieldInput>;
};

/** Fields to sort RelationshipFields by. The order in which sorts are applied is not guaranteed when specifying many fields in one RelationshipFieldSort object. */
export type RelationshipFieldSort = {
  anotherProperty?: InputMaybe<SortDirection>;
  someProperty?: InputMaybe<SortDirection>;
};

export type RelationshipFieldTestingAggregateInput = {
  AND?: InputMaybe<Array<RelationshipFieldTestingAggregateInput>>;
  NOT?: InputMaybe<RelationshipFieldTestingAggregateInput>;
  OR?: InputMaybe<Array<RelationshipFieldTestingAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<RelationshipFieldTestingNodeAggregationWhereInput>;
};

export type RelationshipFieldTestingConnectFieldInput = {
  connect?: InputMaybe<TestingConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TestingConnectWhere>;
};

export type RelationshipFieldTestingConnection = {
  __typename?: "RelationshipFieldTestingConnection";
  edges: Array<RelationshipFieldTestingRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RelationshipFieldTestingConnectionSort = {
  node?: InputMaybe<TestingSort>;
};

export type RelationshipFieldTestingConnectionWhere = {
  AND?: InputMaybe<Array<RelationshipFieldTestingConnectionWhere>>;
  NOT?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
  OR?: InputMaybe<Array<RelationshipFieldTestingConnectionWhere>>;
  node?: InputMaybe<TestingWhere>;
};

export type RelationshipFieldTestingCreateFieldInput = {
  node: TestingCreateInput;
};

export type RelationshipFieldTestingDeleteFieldInput = {
  delete?: InputMaybe<TestingDeleteInput>;
  where?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
};

export type RelationshipFieldTestingDisconnectFieldInput = {
  disconnect?: InputMaybe<TestingDisconnectInput>;
  where?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
};

export type RelationshipFieldTestingFieldInput = {
  connect?: InputMaybe<RelationshipFieldTestingConnectFieldInput>;
  create?: InputMaybe<RelationshipFieldTestingCreateFieldInput>;
};

export type RelationshipFieldTestingNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<RelationshipFieldTestingNodeAggregationWhereInput>>;
  NOT?: InputMaybe<RelationshipFieldTestingNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<RelationshipFieldTestingNodeAggregationWhereInput>>;
  fieldDateTime_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RelationshipFieldTestingRelationship = {
  __typename?: "RelationshipFieldTestingRelationship";
  cursor: Scalars["String"]["output"];
  node: Testing;
};

export type RelationshipFieldTestingTestingAggregationSelection = {
  __typename?: "RelationshipFieldTestingTestingAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<RelationshipFieldTestingTestingNodeAggregateSelection>;
};

export type RelationshipFieldTestingTestingNodeAggregateSelection = {
  __typename?: "RelationshipFieldTestingTestingNodeAggregateSelection";
  fieldDateTime: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type RelationshipFieldTestingUpdateConnectionInput = {
  node?: InputMaybe<TestingUpdateInput>;
};

export type RelationshipFieldTestingUpdateFieldInput = {
  connect?: InputMaybe<RelationshipFieldTestingConnectFieldInput>;
  create?: InputMaybe<RelationshipFieldTestingCreateFieldInput>;
  delete?: InputMaybe<RelationshipFieldTestingDeleteFieldInput>;
  disconnect?: InputMaybe<RelationshipFieldTestingDisconnectFieldInput>;
  update?: InputMaybe<RelationshipFieldTestingUpdateConnectionInput>;
  where?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
};

export type RelationshipFieldUpdateInput = {
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<RelationshipFieldTestingUpdateFieldInput>;
};

export type RelationshipFieldWhere = {
  AND?: InputMaybe<Array<RelationshipFieldWhere>>;
  NOT?: InputMaybe<RelationshipFieldWhere>;
  OR?: InputMaybe<Array<RelationshipFieldWhere>>;
  anotherProperty?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  anotherProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  anotherProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someProperty?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  someProperty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  someProperty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  testing?: InputMaybe<TestingWhere>;
  testingAggregate?: InputMaybe<RelationshipFieldTestingAggregateInput>;
  testingConnection?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
  testingConnection_NOT?: InputMaybe<RelationshipFieldTestingConnectionWhere>;
  testing_NOT?: InputMaybe<TestingWhere>;
};

export type RelationshipFieldsConnection = {
  __typename?: "RelationshipFieldsConnection";
  edges: Array<RelationshipFieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RemoveCoursesFromDegreeInput = {
  courseIds: Array<Scalars["ID"]["input"]>;
  degreeId: Scalars["ID"]["input"];
};

export type RemoveDegreesFromCourseInput = {
  courseId: Scalars["ID"]["input"];
  degreeIds: Array<Scalars["ID"]["input"]>;
};

export type ResponseType = {
  __typename?: "ResponseType";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type ResponseTypeAggregateSelection = {
  __typename?: "ResponseTypeAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type ResponseTypeCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResponseTypeEdge = {
  __typename?: "ResponseTypeEdge";
  cursor: Scalars["String"]["output"];
  node: ResponseType;
};

export type ResponseTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ResponseTypeSort objects to sort ResponseTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ResponseTypeSort>>;
};

/** Fields to sort ResponseTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ResponseTypeSort object. */
export type ResponseTypeSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type ResponseTypeUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResponseTypeWhere = {
  AND?: InputMaybe<Array<ResponseTypeWhere>>;
  NOT?: InputMaybe<ResponseTypeWhere>;
  OR?: InputMaybe<Array<ResponseTypeWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResponseTypesConnection = {
  __typename?: "ResponseTypesConnection";
  edges: Array<ResponseTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Role = {
  __typename?: "Role";
  name: Scalars["String"]["output"];
  permissions: Array<Permission>;
  permissionsAggregate?: Maybe<RolePermissionPermissionsAggregationSelection>;
  permissionsConnection: RolePermissionsConnection;
  usersHasRole: Array<User>;
  usersHasRoleAggregate?: Maybe<RoleUserUsersHasRoleAggregationSelection>;
  usersHasRoleConnection: RoleUsersHasRoleConnection;
};

export type RolePermissionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PermissionOptions>;
  where?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<RolePermissionsConnectionSort>>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RoleUsersHasRoleArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<RoleUsersHasRoleConnectionSort>>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleAggregateSelection = {
  __typename?: "RoleAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type RoleConnectInput = {
  permissions?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
};

export type RoleConnectOrCreateInput = {
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleConnectOrCreateFieldInput>>;
};

export type RoleConnectWhere = {
  node: RoleWhere;
};

export type RoleCreateInput = {
  name: Scalars["String"]["input"];
  permissions?: InputMaybe<RolePermissionsFieldInput>;
  usersHasRole?: InputMaybe<RoleUsersHasRoleFieldInput>;
};

export type RoleDeleteInput = {
  permissions?: InputMaybe<Array<RolePermissionsDeleteFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleDeleteFieldInput>>;
};

export type RoleDisconnectInput = {
  permissions?: InputMaybe<Array<RolePermissionsDisconnectFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleDisconnectFieldInput>>;
};

export type RoleEdge = {
  __typename?: "RoleEdge";
  cursor: Scalars["String"]["output"];
  node: Role;
};

export type RoleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RoleSort objects to sort Roles by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RoleSort>>;
};

export type RolePermissionPermissionsAggregationSelection = {
  __typename?: "RolePermissionPermissionsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<RolePermissionPermissionsNodeAggregateSelection>;
};

export type RolePermissionPermissionsNodeAggregateSelection = {
  __typename?: "RolePermissionPermissionsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type RolePermissionsAggregateInput = {
  AND?: InputMaybe<Array<RolePermissionsAggregateInput>>;
  NOT?: InputMaybe<RolePermissionsAggregateInput>;
  OR?: InputMaybe<Array<RolePermissionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<RolePermissionsNodeAggregationWhereInput>;
};

export type RolePermissionsConnectFieldInput = {
  connect?: InputMaybe<Array<PermissionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PermissionConnectWhere>;
};

export type RolePermissionsConnection = {
  __typename?: "RolePermissionsConnection";
  edges: Array<RolePermissionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RolePermissionsConnectionSort = {
  node?: InputMaybe<PermissionSort>;
};

export type RolePermissionsConnectionWhere = {
  AND?: InputMaybe<Array<RolePermissionsConnectionWhere>>;
  NOT?: InputMaybe<RolePermissionsConnectionWhere>;
  OR?: InputMaybe<Array<RolePermissionsConnectionWhere>>;
  node?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsCreateFieldInput = {
  node: PermissionCreateInput;
};

export type RolePermissionsDeleteFieldInput = {
  delete?: InputMaybe<PermissionDeleteInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RolePermissionsDisconnectFieldInput = {
  disconnect?: InputMaybe<PermissionDisconnectInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RolePermissionsFieldInput = {
  connect?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
};

export type RolePermissionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<RolePermissionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<RolePermissionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<RolePermissionsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RolePermissionsRelationship = {
  __typename?: "RolePermissionsRelationship";
  cursor: Scalars["String"]["output"];
  node: Permission;
};

export type RolePermissionsUpdateConnectionInput = {
  node?: InputMaybe<PermissionUpdateInput>;
};

export type RolePermissionsUpdateFieldInput = {
  connect?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
  delete?: InputMaybe<Array<RolePermissionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<RolePermissionsDisconnectFieldInput>>;
  update?: InputMaybe<RolePermissionsUpdateConnectionInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RoleRelationInput = {
  permissions?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
};

/** Fields to sort Roles by. The order in which sorts are applied is not guaranteed when specifying many fields in one RoleSort object. */
export type RoleSort = {
  name?: InputMaybe<SortDirection>;
};

export type RoleUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<Array<RolePermissionsUpdateFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleUpdateFieldInput>>;
};

export type RoleUserUsersHasRoleAggregationSelection = {
  __typename?: "RoleUserUsersHasRoleAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<RoleUserUsersHasRoleEdgeAggregateSelection>;
  node?: Maybe<RoleUserUsersHasRoleNodeAggregateSelection>;
};

export type RoleUserUsersHasRoleEdgeAggregateSelection = {
  __typename?: "RoleUserUsersHasRoleEdgeAggregateSelection";
  tenantId: IdAggregateSelection;
};

export type RoleUserUsersHasRoleNodeAggregateSelection = {
  __typename?: "RoleUserUsersHasRoleNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type RoleUsersHasRoleAggregateInput = {
  AND?: InputMaybe<Array<RoleUsersHasRoleAggregateInput>>;
  NOT?: InputMaybe<RoleUsersHasRoleAggregateInput>;
  OR?: InputMaybe<Array<RoleUsersHasRoleAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  node?: InputMaybe<RoleUsersHasRoleNodeAggregationWhereInput>;
};

export type RoleUsersHasRoleConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UserRolePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type RoleUsersHasRoleConnectOrCreateFieldInput = {
  onCreate: RoleUsersHasRoleConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type RoleUsersHasRoleConnectOrCreateFieldInputOnCreate = {
  edge: UserRolePropertiesCreateInput;
  node: UserOnCreateInput;
};

export type RoleUsersHasRoleConnection = {
  __typename?: "RoleUsersHasRoleConnection";
  edges: Array<RoleUsersHasRoleRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RoleUsersHasRoleConnectionSort = {
  edge?: InputMaybe<UserRolePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type RoleUsersHasRoleConnectionWhere = {
  AND?: InputMaybe<Array<RoleUsersHasRoleConnectionWhere>>;
  NOT?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  OR?: InputMaybe<Array<RoleUsersHasRoleConnectionWhere>>;
  edge?: InputMaybe<UserRolePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleCreateFieldInput = {
  edge: UserRolePropertiesCreateInput;
  node: UserCreateInput;
};

export type RoleUsersHasRoleDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleUsersHasRoleDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleUsersHasRoleFieldInput = {
  connect?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<RoleUsersHasRoleConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
};

export type RoleUsersHasRoleNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<RoleUsersHasRoleNodeAggregationWhereInput>>;
  NOT?: InputMaybe<RoleUsersHasRoleNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<RoleUsersHasRoleNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RoleUsersHasRoleRelationship = {
  __typename?: "RoleUsersHasRoleRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UserRoleProperties;
};

export type RoleUsersHasRoleUpdateConnectionInput = {
  edge?: InputMaybe<UserRolePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type RoleUsersHasRoleUpdateFieldInput = {
  connect?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<RoleUsersHasRoleConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
  delete?: InputMaybe<Array<RoleUsersHasRoleDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<RoleUsersHasRoleDisconnectFieldInput>>;
  update?: InputMaybe<RoleUsersHasRoleUpdateConnectionInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleWhere = {
  AND?: InputMaybe<Array<RoleWhere>>;
  NOT?: InputMaybe<RoleWhere>;
  OR?: InputMaybe<Array<RoleWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  permissionsAggregate?: InputMaybe<RolePermissionsAggregateInput>;
  /** Return Roles where all of the related RolePermissionsConnections match this filter */
  permissionsConnection_ALL?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where none of the related RolePermissionsConnections match this filter */
  permissionsConnection_NONE?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where one of the related RolePermissionsConnections match this filter */
  permissionsConnection_SINGLE?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where some of the related RolePermissionsConnections match this filter */
  permissionsConnection_SOME?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where all of the related Permissions match this filter */
  permissions_ALL?: InputMaybe<PermissionWhere>;
  /** Return Roles where none of the related Permissions match this filter */
  permissions_NONE?: InputMaybe<PermissionWhere>;
  /** Return Roles where one of the related Permissions match this filter */
  permissions_SINGLE?: InputMaybe<PermissionWhere>;
  /** Return Roles where some of the related Permissions match this filter */
  permissions_SOME?: InputMaybe<PermissionWhere>;
  usersHasRoleAggregate?: InputMaybe<RoleUsersHasRoleAggregateInput>;
  /** Return Roles where all of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_ALL?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where none of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_NONE?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where one of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_SINGLE?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where some of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_SOME?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where all of the related Users match this filter */
  usersHasRole_ALL?: InputMaybe<UserWhere>;
  /** Return Roles where none of the related Users match this filter */
  usersHasRole_NONE?: InputMaybe<UserWhere>;
  /** Return Roles where one of the related Users match this filter */
  usersHasRole_SINGLE?: InputMaybe<UserWhere>;
  /** Return Roles where some of the related Users match this filter */
  usersHasRole_SOME?: InputMaybe<UserWhere>;
};

export type RolesConnection = {
  __typename?: "RolesConnection";
  edges: Array<RoleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type School = {
  __typename?: "School";
  degreesHasDegree: Array<Degree>;
  degreesHasDegreeAggregate?: Maybe<SchoolDegreeDegreesHasDegreeAggregationSelection>;
  degreesHasDegreeConnection: SchoolDegreesHasDegreeConnection;
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  providerName?: Maybe<Scalars["String"]["output"]>;
  teamName?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
  usersAggregate?: Maybe<SchoolUserUsersAggregationSelection>;
  usersConnection: SchoolUsersConnection;
};

export type SchoolDegreesHasDegreeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DegreeOptions>;
  where?: InputMaybe<DegreeWhere>;
};

export type SchoolDegreesHasDegreeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DegreeWhere>;
};

export type SchoolDegreesHasDegreeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SchoolDegreesHasDegreeConnectionSort>>;
  where?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
};

export type SchoolUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type SchoolUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type SchoolUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SchoolUsersConnectionSort>>;
  where?: InputMaybe<SchoolUsersConnectionWhere>;
};

export type SchoolAggregateSelection = {
  __typename?: "SchoolAggregateSelection";
  count: Scalars["Int"]["output"];
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type SchoolConnectInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeConnectFieldInput>>;
  users?: InputMaybe<Array<SchoolUsersConnectFieldInput>>;
};

export type SchoolConnectOrCreateInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeConnectOrCreateFieldInput>>;
  users?: InputMaybe<Array<SchoolUsersConnectOrCreateFieldInput>>;
};

export type SchoolConnectOrCreateWhere = {
  node: SchoolUniqueWhere;
};

export type SchoolConnectWhere = {
  node: SchoolWhere;
};

export type SchoolCreateInput = {
  degreesHasDegree?: InputMaybe<SchoolDegreesHasDegreeFieldInput>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<SchoolUsersFieldInput>;
};

export type SchoolDegreeDegreesHasDegreeAggregationSelection = {
  __typename?: "SchoolDegreeDegreesHasDegreeAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SchoolDegreeDegreesHasDegreeNodeAggregateSelection>;
};

export type SchoolDegreeDegreesHasDegreeNodeAggregateSelection = {
  __typename?: "SchoolDegreeDegreesHasDegreeNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type SchoolDegreesHasDegreeAggregateInput = {
  AND?: InputMaybe<Array<SchoolDegreesHasDegreeAggregateInput>>;
  NOT?: InputMaybe<SchoolDegreesHasDegreeAggregateInput>;
  OR?: InputMaybe<Array<SchoolDegreesHasDegreeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SchoolDegreesHasDegreeNodeAggregationWhereInput>;
};

export type SchoolDegreesHasDegreeConnectFieldInput = {
  connect?: InputMaybe<Array<DegreeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DegreeConnectWhere>;
};

export type SchoolDegreesHasDegreeConnectOrCreateFieldInput = {
  onCreate: SchoolDegreesHasDegreeConnectOrCreateFieldInputOnCreate;
  where: DegreeConnectOrCreateWhere;
};

export type SchoolDegreesHasDegreeConnectOrCreateFieldInputOnCreate = {
  node: DegreeOnCreateInput;
};

export type SchoolDegreesHasDegreeConnection = {
  __typename?: "SchoolDegreesHasDegreeConnection";
  edges: Array<SchoolDegreesHasDegreeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SchoolDegreesHasDegreeConnectionSort = {
  node?: InputMaybe<DegreeSort>;
};

export type SchoolDegreesHasDegreeConnectionWhere = {
  AND?: InputMaybe<Array<SchoolDegreesHasDegreeConnectionWhere>>;
  NOT?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
  OR?: InputMaybe<Array<SchoolDegreesHasDegreeConnectionWhere>>;
  node?: InputMaybe<DegreeWhere>;
};

export type SchoolDegreesHasDegreeCreateFieldInput = {
  node: DegreeCreateInput;
};

export type SchoolDegreesHasDegreeDeleteFieldInput = {
  delete?: InputMaybe<DegreeDeleteInput>;
  where?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
};

export type SchoolDegreesHasDegreeDisconnectFieldInput = {
  disconnect?: InputMaybe<DegreeDisconnectInput>;
  where?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
};

export type SchoolDegreesHasDegreeFieldInput = {
  connect?: InputMaybe<Array<SchoolDegreesHasDegreeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolDegreesHasDegreeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SchoolDegreesHasDegreeCreateFieldInput>>;
};

export type SchoolDegreesHasDegreeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SchoolDegreesHasDegreeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SchoolDegreesHasDegreeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SchoolDegreesHasDegreeNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SchoolDegreesHasDegreeRelationship = {
  __typename?: "SchoolDegreesHasDegreeRelationship";
  cursor: Scalars["String"]["output"];
  node: Degree;
};

export type SchoolDegreesHasDegreeUpdateConnectionInput = {
  node?: InputMaybe<DegreeUpdateInput>;
};

export type SchoolDegreesHasDegreeUpdateFieldInput = {
  connect?: InputMaybe<Array<SchoolDegreesHasDegreeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolDegreesHasDegreeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SchoolDegreesHasDegreeCreateFieldInput>>;
  delete?: InputMaybe<Array<SchoolDegreesHasDegreeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SchoolDegreesHasDegreeDisconnectFieldInput>>;
  update?: InputMaybe<SchoolDegreesHasDegreeUpdateConnectionInput>;
  where?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
};

export type SchoolDeleteInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeDeleteFieldInput>>;
  users?: InputMaybe<Array<SchoolUsersDeleteFieldInput>>;
};

export type SchoolDisconnectInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeDisconnectFieldInput>>;
  users?: InputMaybe<Array<SchoolUsersDisconnectFieldInput>>;
};

export type SchoolEdge = {
  __typename?: "SchoolEdge";
  cursor: Scalars["String"]["output"];
  node: School;
};

export type SchoolOnCreateInput = {
  icon?: InputMaybe<Scalars["String"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SchoolOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SchoolSort objects to sort Schools by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SchoolSort>>;
};

export type SchoolRelationInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeCreateFieldInput>>;
  users?: InputMaybe<Array<SchoolUsersCreateFieldInput>>;
};

/** Fields to sort Schools by. The order in which sorts are applied is not guaranteed when specifying many fields in one SchoolSort object. */
export type SchoolSort = {
  icon?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  providerName?: InputMaybe<SortDirection>;
  teamName?: InputMaybe<SortDirection>;
};

export type SchoolUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SchoolUpdateInput = {
  degreesHasDegree?: InputMaybe<Array<SchoolDegreesHasDegreeUpdateFieldInput>>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<SchoolUsersUpdateFieldInput>>;
};

export type SchoolUserUsersAggregationSelection = {
  __typename?: "SchoolUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SchoolUserUsersNodeAggregateSelection>;
};

export type SchoolUserUsersNodeAggregateSelection = {
  __typename?: "SchoolUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type SchoolUsersAggregateInput = {
  AND?: InputMaybe<Array<SchoolUsersAggregateInput>>;
  NOT?: InputMaybe<SchoolUsersAggregateInput>;
  OR?: InputMaybe<Array<SchoolUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SchoolUsersNodeAggregationWhereInput>;
};

export type SchoolUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type SchoolUsersConnectOrCreateFieldInput = {
  onCreate: SchoolUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type SchoolUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type SchoolUsersConnection = {
  __typename?: "SchoolUsersConnection";
  edges: Array<SchoolUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SchoolUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type SchoolUsersConnectionWhere = {
  AND?: InputMaybe<Array<SchoolUsersConnectionWhere>>;
  NOT?: InputMaybe<SchoolUsersConnectionWhere>;
  OR?: InputMaybe<Array<SchoolUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type SchoolUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type SchoolUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<SchoolUsersConnectionWhere>;
};

export type SchoolUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<SchoolUsersConnectionWhere>;
};

export type SchoolUsersFieldInput = {
  connect?: InputMaybe<Array<SchoolUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SchoolUsersCreateFieldInput>>;
};

export type SchoolUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SchoolUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SchoolUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SchoolUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SchoolUsersRelationship = {
  __typename?: "SchoolUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type SchoolUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type SchoolUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<SchoolUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SchoolUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<SchoolUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SchoolUsersDisconnectFieldInput>>;
  update?: InputMaybe<SchoolUsersUpdateConnectionInput>;
  where?: InputMaybe<SchoolUsersConnectionWhere>;
};

export type SchoolWhere = {
  AND?: InputMaybe<Array<SchoolWhere>>;
  NOT?: InputMaybe<SchoolWhere>;
  OR?: InputMaybe<Array<SchoolWhere>>;
  degreesHasDegreeAggregate?: InputMaybe<SchoolDegreesHasDegreeAggregateInput>;
  /** Return Schools where all of the related SchoolDegreesHasDegreeConnections match this filter */
  degreesHasDegreeConnection_ALL?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
  /** Return Schools where none of the related SchoolDegreesHasDegreeConnections match this filter */
  degreesHasDegreeConnection_NONE?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
  /** Return Schools where one of the related SchoolDegreesHasDegreeConnections match this filter */
  degreesHasDegreeConnection_SINGLE?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
  /** Return Schools where some of the related SchoolDegreesHasDegreeConnections match this filter */
  degreesHasDegreeConnection_SOME?: InputMaybe<SchoolDegreesHasDegreeConnectionWhere>;
  /** Return Schools where all of the related Degrees match this filter */
  degreesHasDegree_ALL?: InputMaybe<DegreeWhere>;
  /** Return Schools where none of the related Degrees match this filter */
  degreesHasDegree_NONE?: InputMaybe<DegreeWhere>;
  /** Return Schools where one of the related Degrees match this filter */
  degreesHasDegree_SINGLE?: InputMaybe<DegreeWhere>;
  /** Return Schools where some of the related Degrees match this filter */
  degreesHasDegree_SOME?: InputMaybe<DegreeWhere>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  icon_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  icon_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  icon_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  icon_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  providerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  providerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  providerName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  providerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
  teamName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  teamName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teamName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  teamName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<SchoolUsersAggregateInput>;
  /** Return Schools where all of the related SchoolUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<SchoolUsersConnectionWhere>;
  /** Return Schools where none of the related SchoolUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<SchoolUsersConnectionWhere>;
  /** Return Schools where one of the related SchoolUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<SchoolUsersConnectionWhere>;
  /** Return Schools where some of the related SchoolUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<SchoolUsersConnectionWhere>;
  /** Return Schools where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Schools where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Schools where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Schools where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type SchoolsConnection = {
  __typename?: "SchoolsConnection";
  edges: Array<SchoolEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SessionResponse = {
  __typename?: "SessionResponse";
  isValid: Scalars["Boolean"]["output"];
};

export type SessionResponseAggregateSelection = {
  __typename?: "SessionResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type SessionResponseCreateInput = {
  isValid: Scalars["Boolean"]["input"];
};

export type SessionResponseEdge = {
  __typename?: "SessionResponseEdge";
  cursor: Scalars["String"]["output"];
  node: SessionResponse;
};

export type SessionResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SessionResponseSort objects to sort SessionResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SessionResponseSort>>;
};

/** Fields to sort SessionResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SessionResponseSort object. */
export type SessionResponseSort = {
  isValid?: InputMaybe<SortDirection>;
};

export type SessionResponseUpdateInput = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SessionResponseWhere = {
  AND?: InputMaybe<Array<SessionResponseWhere>>;
  NOT?: InputMaybe<SessionResponseWhere>;
  OR?: InputMaybe<Array<SessionResponseWhere>>;
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SessionResponsesConnection = {
  __typename?: "SessionResponsesConnection";
  edges: Array<SessionResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An enum for sorting in either ascending or descending order. */
export enum SortDirection {
  /** Sort by field values in ascending order. */
  Asc = "ASC",
  /** Sort by field values in descending order. */
  Desc = "DESC",
}

export enum StoragePathType {
  ChatAttachment = "CHAT_ATTACHMENT",
  ResourceDocument = "RESOURCE_DOCUMENT",
}

export type StringAggregateSelection = {
  __typename?: "StringAggregateSelection";
  longest?: Maybe<Scalars["String"]["output"]>;
  shortest?: Maybe<Scalars["String"]["output"]>;
};

export type StudentActivityProgressData = {
  __typename?: "StudentActivityProgressData";
  active_interaction_count: Scalars["Int"]["output"];
  chatAnalytics?: Maybe<Array<Maybe<ChatAnalytics>>>;
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  course_id?: Maybe<Scalars["ID"]["output"]>;
  course_name?: Maybe<Scalars["String"]["output"]>;
  details: ActivityProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  module_id?: Maybe<Scalars["ID"]["output"]>;
  module_name?: Maybe<Scalars["String"]["output"]>;
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  student_id: Scalars["ID"]["output"];
  student_name: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentActivityProgressDataAggregateSelection = {
  __typename?: "StudentActivityProgressDataAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  course_id: IdAggregateSelection;
  course_name: StringAggregateSelection;
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  module_id: IdAggregateSelection;
  module_name: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  student_id: IdAggregateSelection;
  student_name: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentActivityProgressDataConnection = {
  __typename?: "StudentActivityProgressDataConnection";
  edges: Array<StudentActivityProgressDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentActivityProgressDataCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  student_id: Scalars["ID"]["input"];
  student_name: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentActivityProgressDataEdge = {
  __typename?: "StudentActivityProgressDataEdge";
  cursor: Scalars["String"]["output"];
  node: StudentActivityProgressData;
};

export type StudentActivityProgressDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentActivityProgressDataSort objects to sort StudentActivityProgressData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentActivityProgressDataSort>>;
};

/** Fields to sort StudentActivityProgressData by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentActivityProgressDataSort object. */
export type StudentActivityProgressDataSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  course_name?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  module_id?: InputMaybe<SortDirection>;
  module_name?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  student_id?: InputMaybe<SortDirection>;
  student_name?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentActivityProgressDataUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentActivityProgressDataWhere = {
  AND?: InputMaybe<Array<StudentActivityProgressDataWhere>>;
  NOT?: InputMaybe<StudentActivityProgressDataWhere>;
  OR?: InputMaybe<Array<StudentActivityProgressDataWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  course_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_id?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  module_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  module_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  module_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  module_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  student_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  student_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  student_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  student_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentActivityProgressResponse = {
  __typename?: "StudentActivityProgressResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentActivityProgressData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentActivityProgressResponseAggregateSelection = {
  __typename?: "StudentActivityProgressResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentActivityProgressResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentActivityProgressResponseEdge = {
  __typename?: "StudentActivityProgressResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentActivityProgressResponse;
};

export type StudentActivityProgressResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentActivityProgressResponseSort objects to sort StudentActivityProgressResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentActivityProgressResponseSort>>;
};

/** Fields to sort StudentActivityProgressResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentActivityProgressResponseSort object. */
export type StudentActivityProgressResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentActivityProgressResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentActivityProgressResponseWhere = {
  AND?: InputMaybe<Array<StudentActivityProgressResponseWhere>>;
  NOT?: InputMaybe<StudentActivityProgressResponseWhere>;
  OR?: InputMaybe<Array<StudentActivityProgressResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentActivityProgressResponsesConnection = {
  __typename?: "StudentActivityProgressResponsesConnection";
  edges: Array<StudentActivityProgressResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentAnalytics = {
  __typename?: "StudentAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: StudentAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentAnalyticsAggregateSelection = {
  __typename?: "StudentAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentAnalyticsConnection = {
  __typename?: "StudentAnalyticsConnection";
  edges: Array<StudentAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentAnalyticsDetails = {
  __typename?: "StudentAnalyticsDetails";
  courses: Array<CourseProgressAnalytics>;
};

export type StudentAnalyticsDetailsAggregateSelection = {
  __typename?: "StudentAnalyticsDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type StudentAnalyticsDetailsConnection = {
  __typename?: "StudentAnalyticsDetailsConnection";
  edges: Array<StudentAnalyticsDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentAnalyticsDetailsCreateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsDetailsEdge = {
  __typename?: "StudentAnalyticsDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: StudentAnalyticsDetails;
};

export type StudentAnalyticsDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentAnalyticsDetailsUpdateInput = {
  /** Appears because this input type would be empty otherwise because this type is composed of just generated and/or relationship properties. See https://neo4j.com/docs/graphql-manual/current/troubleshooting/faqs/ */
  _emptyInput?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsDetailsWhere = {
  AND?: InputMaybe<Array<StudentAnalyticsDetailsWhere>>;
  NOT?: InputMaybe<StudentAnalyticsDetailsWhere>;
  OR?: InputMaybe<Array<StudentAnalyticsDetailsWhere>>;
};

export type StudentAnalyticsEdge = {
  __typename?: "StudentAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: StudentAnalytics;
};

export type StudentAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentAnalyticsSort objects to sort StudentAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentAnalyticsSort>>;
};

export type StudentAnalyticsResponse = {
  __typename?: "StudentAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentAnalyticsResponseAggregateSelection = {
  __typename?: "StudentAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentAnalyticsResponseEdge = {
  __typename?: "StudentAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentAnalyticsResponse;
};

export type StudentAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentAnalyticsResponseSort objects to sort StudentAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentAnalyticsResponseSort>>;
};

/** Fields to sort StudentAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentAnalyticsResponseSort object. */
export type StudentAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<StudentAnalyticsResponseWhere>>;
  NOT?: InputMaybe<StudentAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<StudentAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsResponsesConnection = {
  __typename?: "StudentAnalyticsResponsesConnection";
  edges: Array<StudentAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort StudentAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentAnalyticsSort object. */
export type StudentAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentAnalyticsSummaryResponse = {
  __typename?: "StudentAnalyticsSummaryResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentSummary>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentAnalyticsSummaryResponseAggregateSelection = {
  __typename?: "StudentAnalyticsSummaryResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentAnalyticsSummaryResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentAnalyticsSummaryResponseEdge = {
  __typename?: "StudentAnalyticsSummaryResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentAnalyticsSummaryResponse;
};

export type StudentAnalyticsSummaryResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentAnalyticsSummaryResponseSort objects to sort StudentAnalyticsSummaryResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentAnalyticsSummaryResponseSort>>;
};

/** Fields to sort StudentAnalyticsSummaryResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentAnalyticsSummaryResponseSort object. */
export type StudentAnalyticsSummaryResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentAnalyticsSummaryResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsSummaryResponseWhere = {
  AND?: InputMaybe<Array<StudentAnalyticsSummaryResponseWhere>>;
  NOT?: InputMaybe<StudentAnalyticsSummaryResponseWhere>;
  OR?: InputMaybe<Array<StudentAnalyticsSummaryResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentAnalyticsSummaryResponsesConnection = {
  __typename?: "StudentAnalyticsSummaryResponsesConnection";
  edges: Array<StudentAnalyticsSummaryResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentAnalyticsWhere = {
  AND?: InputMaybe<Array<StudentAnalyticsWhere>>;
  NOT?: InputMaybe<StudentAnalyticsWhere>;
  OR?: InputMaybe<Array<StudentAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentChatAnalytics = {
  __typename?: "StudentChatAnalytics";
  activityId: Scalars["ID"]["output"];
  activityName: Scalars["String"]["output"];
  ai_messages_count: Scalars["Int"]["output"];
  average_ai_response_time: Scalars["Float"]["output"];
  average_student_response_time: Scalars["Float"]["output"];
  courseId: Scalars["ID"]["output"];
  courseName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  moduleId: Scalars["ID"]["output"];
  moduleName: Scalars["String"]["output"];
  num_messages: Scalars["Int"]["output"];
  originality_analysis?: Maybe<Scalars["String"]["output"]>;
  sentiment_analysis?: Maybe<Scalars["String"]["output"]>;
  student_messages_count: Scalars["Int"]["output"];
  total_time_taken: Scalars["Float"]["output"];
};

export type StudentChatAnalyticsAggregateSelection = {
  __typename?: "StudentChatAnalyticsAggregateSelection";
  activityId: IdAggregateSelection;
  activityName: StringAggregateSelection;
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  courseId: IdAggregateSelection;
  courseName: StringAggregateSelection;
  id: IdAggregateSelection;
  moduleId: IdAggregateSelection;
  moduleName: StringAggregateSelection;
  num_messages: IntAggregateSelection;
  originality_analysis: StringAggregateSelection;
  sentiment_analysis: StringAggregateSelection;
  student_messages_count: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentChatAnalyticsConnection = {
  __typename?: "StudentChatAnalyticsConnection";
  edges: Array<StudentChatAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentChatAnalyticsCreateInput = {
  activityId: Scalars["ID"]["input"];
  activityName: Scalars["String"]["input"];
  ai_messages_count: Scalars["Int"]["input"];
  average_ai_response_time: Scalars["Float"]["input"];
  average_student_response_time: Scalars["Float"]["input"];
  courseId: Scalars["ID"]["input"];
  courseName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  moduleId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  num_messages: Scalars["Int"]["input"];
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count: Scalars["Int"]["input"];
  total_time_taken: Scalars["Float"]["input"];
};

export type StudentChatAnalyticsEdge = {
  __typename?: "StudentChatAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: StudentChatAnalytics;
};

export type StudentChatAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentChatAnalyticsSort objects to sort StudentChatAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentChatAnalyticsSort>>;
};

export type StudentChatAnalyticsResponse = {
  __typename?: "StudentChatAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentChatAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentChatAnalyticsResponseAggregateSelection = {
  __typename?: "StudentChatAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentChatAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentChatAnalyticsResponseEdge = {
  __typename?: "StudentChatAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentChatAnalyticsResponse;
};

export type StudentChatAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentChatAnalyticsResponseSort objects to sort StudentChatAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentChatAnalyticsResponseSort>>;
};

/** Fields to sort StudentChatAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentChatAnalyticsResponseSort object. */
export type StudentChatAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentChatAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentChatAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<StudentChatAnalyticsResponseWhere>>;
  NOT?: InputMaybe<StudentChatAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<StudentChatAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentChatAnalyticsResponsesConnection = {
  __typename?: "StudentChatAnalyticsResponsesConnection";
  edges: Array<StudentChatAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort StudentChatAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentChatAnalyticsSort object. */
export type StudentChatAnalyticsSort = {
  activityId?: InputMaybe<SortDirection>;
  activityName?: InputMaybe<SortDirection>;
  ai_messages_count?: InputMaybe<SortDirection>;
  average_ai_response_time?: InputMaybe<SortDirection>;
  average_student_response_time?: InputMaybe<SortDirection>;
  courseId?: InputMaybe<SortDirection>;
  courseName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  moduleId?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
  num_messages?: InputMaybe<SortDirection>;
  originality_analysis?: InputMaybe<SortDirection>;
  sentiment_analysis?: InputMaybe<SortDirection>;
  student_messages_count?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentChatAnalyticsUpdateInput = {
  activityId?: InputMaybe<Scalars["ID"]["input"]>;
  activityName?: InputMaybe<Scalars["String"]["input"]>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId?: InputMaybe<Scalars["ID"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentChatAnalyticsWhere = {
  AND?: InputMaybe<Array<StudentChatAnalyticsWhere>>;
  NOT?: InputMaybe<StudentChatAnalyticsWhere>;
  OR?: InputMaybe<Array<StudentChatAnalyticsWhere>>;
  activityId?: InputMaybe<Scalars["ID"]["input"]>;
  activityId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  activityId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  activityId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  activityId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  activityName?: InputMaybe<Scalars["String"]["input"]>;
  activityName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  activityName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  activityName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  activityName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  ai_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  ai_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  ai_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  average_ai_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  average_ai_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_ai_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  average_student_response_time_LT?: InputMaybe<Scalars["Float"]["input"]>;
  average_student_response_time_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  courseId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  courseName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  courseName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  moduleId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  moduleId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  num_messages?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  num_messages_LT?: InputMaybe<Scalars["Int"]["input"]>;
  num_messages_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  originality_analysis?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  originality_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  originality_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_analysis_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_analysis_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_messages_count?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  student_messages_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  student_messages_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentCourseProgressResponse = {
  __typename?: "StudentCourseProgressResponse";
  count: Scalars["Int"]["output"];
  data: Array<CourseProgressAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentCourseProgressResponseAggregateSelection = {
  __typename?: "StudentCourseProgressResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentCourseProgressResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentCourseProgressResponseEdge = {
  __typename?: "StudentCourseProgressResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentCourseProgressResponse;
};

export type StudentCourseProgressResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentCourseProgressResponseSort objects to sort StudentCourseProgressResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentCourseProgressResponseSort>>;
};

/** Fields to sort StudentCourseProgressResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentCourseProgressResponseSort object. */
export type StudentCourseProgressResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentCourseProgressResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentCourseProgressResponseWhere = {
  AND?: InputMaybe<Array<StudentCourseProgressResponseWhere>>;
  NOT?: InputMaybe<StudentCourseProgressResponseWhere>;
  OR?: InputMaybe<Array<StudentCourseProgressResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentCourseProgressResponsesConnection = {
  __typename?: "StudentCourseProgressResponsesConnection";
  edges: Array<StudentCourseProgressResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentDetailedAnalytics = {
  __typename?: "StudentDetailedAnalytics";
  active_interaction_count: Scalars["Int"]["output"];
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  details: StudentAnalyticsDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentDetailedAnalyticsAggregateSelection = {
  __typename?: "StudentDetailedAnalyticsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentDetailedAnalyticsConnection = {
  __typename?: "StudentDetailedAnalyticsConnection";
  edges: Array<StudentDetailedAnalyticsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentDetailedAnalyticsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentDetailedAnalyticsEdge = {
  __typename?: "StudentDetailedAnalyticsEdge";
  cursor: Scalars["String"]["output"];
  node: StudentDetailedAnalytics;
};

export type StudentDetailedAnalyticsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentDetailedAnalyticsSort objects to sort StudentDetailedAnalytics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentDetailedAnalyticsSort>>;
};

export type StudentDetailedAnalyticsResponse = {
  __typename?: "StudentDetailedAnalyticsResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentDetailedAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentDetailedAnalyticsResponseAggregateSelection = {
  __typename?: "StudentDetailedAnalyticsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentDetailedAnalyticsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentDetailedAnalyticsResponseEdge = {
  __typename?: "StudentDetailedAnalyticsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentDetailedAnalyticsResponse;
};

export type StudentDetailedAnalyticsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentDetailedAnalyticsResponseSort objects to sort StudentDetailedAnalyticsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentDetailedAnalyticsResponseSort>>;
};

/** Fields to sort StudentDetailedAnalyticsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentDetailedAnalyticsResponseSort object. */
export type StudentDetailedAnalyticsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentDetailedAnalyticsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentDetailedAnalyticsResponseWhere = {
  AND?: InputMaybe<Array<StudentDetailedAnalyticsResponseWhere>>;
  NOT?: InputMaybe<StudentDetailedAnalyticsResponseWhere>;
  OR?: InputMaybe<Array<StudentDetailedAnalyticsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentDetailedAnalyticsResponsesConnection = {
  __typename?: "StudentDetailedAnalyticsResponsesConnection";
  edges: Array<StudentDetailedAnalyticsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort StudentDetailedAnalytics by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentDetailedAnalyticsSort object. */
export type StudentDetailedAnalyticsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentDetailedAnalyticsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentDetailedAnalyticsWhere = {
  AND?: InputMaybe<Array<StudentDetailedAnalyticsWhere>>;
  NOT?: InputMaybe<StudentDetailedAnalyticsWhere>;
  OR?: InputMaybe<Array<StudentDetailedAnalyticsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentLearningInsights = {
  __typename?: "StudentLearningInsights";
  active_interaction_count: Scalars["Int"]["output"];
  confusion_points_details: Array<Scalars["String"]["output"]>;
  further_recommendations: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  success_points_details: Array<Scalars["String"]["output"]>;
  total_sessions: Scalars["Int"]["output"];
  total_time_taken: Scalars["Float"]["output"];
};

export type StudentLearningInsightsAggregateSelection = {
  __typename?: "StudentLearningInsightsAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  total_sessions: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentLearningInsightsConnection = {
  __typename?: "StudentLearningInsightsConnection";
  edges: Array<StudentLearningInsightsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentLearningInsightsCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  confusion_points_details: Array<Scalars["String"]["input"]>;
  further_recommendations: Array<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  success_points_details: Array<Scalars["String"]["input"]>;
  total_sessions: Scalars["Int"]["input"];
  total_time_taken: Scalars["Float"]["input"];
};

export type StudentLearningInsightsEdge = {
  __typename?: "StudentLearningInsightsEdge";
  cursor: Scalars["String"]["output"];
  node: StudentLearningInsights;
};

export type StudentLearningInsightsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentLearningInsightsSort objects to sort StudentLearningInsights by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentLearningInsightsSort>>;
};

export type StudentLearningInsightsResponse = {
  __typename?: "StudentLearningInsightsResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentLearningInsights>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentLearningInsightsResponseAggregateSelection = {
  __typename?: "StudentLearningInsightsResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentLearningInsightsResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentLearningInsightsResponseEdge = {
  __typename?: "StudentLearningInsightsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentLearningInsightsResponse;
};

export type StudentLearningInsightsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentLearningInsightsResponseSort objects to sort StudentLearningInsightsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentLearningInsightsResponseSort>>;
};

/** Fields to sort StudentLearningInsightsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentLearningInsightsResponseSort object. */
export type StudentLearningInsightsResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentLearningInsightsResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentLearningInsightsResponseWhere = {
  AND?: InputMaybe<Array<StudentLearningInsightsResponseWhere>>;
  NOT?: InputMaybe<StudentLearningInsightsResponseWhere>;
  OR?: InputMaybe<Array<StudentLearningInsightsResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentLearningInsightsResponsesConnection = {
  __typename?: "StudentLearningInsightsResponsesConnection";
  edges: Array<StudentLearningInsightsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort StudentLearningInsights by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentLearningInsightsSort object. */
export type StudentLearningInsightsSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  total_sessions?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentLearningInsightsUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  confusion_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_POP?: InputMaybe<Scalars["Int"]["input"]>;
  further_recommendations_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  success_points_details_POP?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  total_sessions?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentLearningInsightsWhere = {
  AND?: InputMaybe<Array<StudentLearningInsightsWhere>>;
  NOT?: InputMaybe<StudentLearningInsightsWhere>;
  OR?: InputMaybe<Array<StudentLearningInsightsWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  confusion_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  further_recommendations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  further_recommendations_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_details?: InputMaybe<Array<Scalars["String"]["input"]>>;
  success_points_details_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  total_sessions?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  total_sessions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_sessions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentModuleProgressData = {
  __typename?: "StudentModuleProgressData";
  active_interaction_count: Scalars["Int"]["output"];
  completion_percentage?: Maybe<Scalars["Float"]["output"]>;
  confusion_points: Scalars["Int"]["output"];
  course_id?: Maybe<Scalars["ID"]["output"]>;
  course_name?: Maybe<Scalars["String"]["output"]>;
  details: ModuleProgressDetails;
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  student_id: Scalars["ID"]["output"];
  student_name: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentModuleProgressDataAggregateSelection = {
  __typename?: "StudentModuleProgressDataAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  completion_percentage: FloatAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  course_id: IdAggregateSelection;
  course_name: StringAggregateSelection;
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  student_id: IdAggregateSelection;
  student_name: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentModuleProgressDataConnection = {
  __typename?: "StudentModuleProgressDataConnection";
  edges: Array<StudentModuleProgressDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentModuleProgressDataCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points: Scalars["Int"]["input"];
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  student_id: Scalars["ID"]["input"];
  student_name: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentModuleProgressDataEdge = {
  __typename?: "StudentModuleProgressDataEdge";
  cursor: Scalars["String"]["output"];
  node: StudentModuleProgressData;
};

export type StudentModuleProgressDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentModuleProgressDataSort objects to sort StudentModuleProgressData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentModuleProgressDataSort>>;
};

/** Fields to sort StudentModuleProgressData by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentModuleProgressDataSort object. */
export type StudentModuleProgressDataSort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  completion_percentage?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  course_name?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  student_id?: InputMaybe<SortDirection>;
  student_name?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentModuleProgressDataUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentModuleProgressDataWhere = {
  AND?: InputMaybe<Array<StudentModuleProgressDataWhere>>;
  NOT?: InputMaybe<StudentModuleProgressDataWhere>;
  OR?: InputMaybe<Array<StudentModuleProgressDataWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completion_percentage?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completion_percentage_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completion_percentage_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  course_name?: InputMaybe<Scalars["String"]["input"]>;
  course_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_id?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  student_id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  student_name?: InputMaybe<Scalars["String"]["input"]>;
  student_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  student_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  student_name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  student_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentModuleProgressResponse = {
  __typename?: "StudentModuleProgressResponse";
  count: Scalars["Int"]["output"];
  data: Array<StudentModuleProgressData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentModuleProgressResponseAggregateSelection = {
  __typename?: "StudentModuleProgressResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type StudentModuleProgressResponseCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type StudentModuleProgressResponseEdge = {
  __typename?: "StudentModuleProgressResponseEdge";
  cursor: Scalars["String"]["output"];
  node: StudentModuleProgressResponse;
};

export type StudentModuleProgressResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentModuleProgressResponseSort objects to sort StudentModuleProgressResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentModuleProgressResponseSort>>;
};

/** Fields to sort StudentModuleProgressResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentModuleProgressResponseSort object. */
export type StudentModuleProgressResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type StudentModuleProgressResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentModuleProgressResponseWhere = {
  AND?: InputMaybe<Array<StudentModuleProgressResponseWhere>>;
  NOT?: InputMaybe<StudentModuleProgressResponseWhere>;
  OR?: InputMaybe<Array<StudentModuleProgressResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentModuleProgressResponsesConnection = {
  __typename?: "StudentModuleProgressResponsesConnection";
  edges: Array<StudentModuleProgressResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentSummariesConnection = {
  __typename?: "StudentSummariesConnection";
  edges: Array<StudentSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type StudentSummary = {
  __typename?: "StudentSummary";
  active_interaction_count: Scalars["Int"]["output"];
  confusion_points: Scalars["Int"]["output"];
  distractions: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  questions_asked: Scalars["Int"]["output"];
  sentiment: Scalars["String"]["output"];
  success_points: Scalars["Int"]["output"];
  total_time_taken?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentSummaryAggregateSelection = {
  __typename?: "StudentSummaryAggregateSelection";
  active_interaction_count: IntAggregateSelection;
  confusion_points: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  distractions: IntAggregateSelection;
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  questions_asked: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  success_points: IntAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type StudentSummaryCreateInput = {
  active_interaction_count: Scalars["Int"]["input"];
  confusion_points: Scalars["Int"]["input"];
  distractions: Scalars["Int"]["input"];
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  questions_asked: Scalars["Int"]["input"];
  sentiment: Scalars["String"]["input"];
  success_points: Scalars["Int"]["input"];
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentSummaryEdge = {
  __typename?: "StudentSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: StudentSummary;
};

export type StudentSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more StudentSummarySort objects to sort StudentSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<StudentSummarySort>>;
};

/** Fields to sort StudentSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudentSummarySort object. */
export type StudentSummarySort = {
  active_interaction_count?: InputMaybe<SortDirection>;
  confusion_points?: InputMaybe<SortDirection>;
  distractions?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  questions_asked?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  success_points?: InputMaybe<SortDirection>;
  total_time_taken?: InputMaybe<SortDirection>;
};

export type StudentSummaryUpdateInput = {
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type StudentSummaryWhere = {
  AND?: InputMaybe<Array<StudentSummaryWhere>>;
  NOT?: InputMaybe<StudentSummaryWhere>;
  OR?: InputMaybe<Array<StudentSummaryWhere>>;
  active_interaction_count?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  active_interaction_count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  active_interaction_count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  confusion_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  confusion_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  distractions_LT?: InputMaybe<Scalars["Int"]["input"]>;
  distractions_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questions_asked?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  questions_asked_LT?: InputMaybe<Scalars["Int"]["input"]>;
  questions_asked_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success_points?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  success_points_LT?: InputMaybe<Scalars["Int"]["input"]>;
  success_points_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_time_taken?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  total_time_taken_LT?: InputMaybe<Scalars["Float"]["input"]>;
  total_time_taken_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Subchapter = {
  __typename?: "Subchapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SubchapterAggregateSelection = {
  __typename?: "SubchapterAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type SubchapterConnectWhere = {
  node: SubchapterWhere;
};

export type SubchapterCreateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type SubchapterDetails = {
  __typename?: "SubchapterDetails";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<LearningObjective>>>;
  success: Scalars["Boolean"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type SubchapterDetailsAggregateSelection = {
  __typename?: "SubchapterDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  message: StringAggregateSelection;
  name: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type SubchapterDetailsConnection = {
  __typename?: "SubchapterDetailsConnection";
  edges: Array<SubchapterDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubchapterDetailsCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterDetailsEdge = {
  __typename?: "SubchapterDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: SubchapterDetails;
};

export type SubchapterDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubchapterDetailsSort objects to sort SubchapterDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubchapterDetailsSort>>;
};

/** Fields to sort SubchapterDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubchapterDetailsSort object. */
export type SubchapterDetailsSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type SubchapterDetailsUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterDetailsWhere = {
  AND?: InputMaybe<Array<SubchapterDetailsWhere>>;
  NOT?: InputMaybe<SubchapterDetailsWhere>;
  OR?: InputMaybe<Array<SubchapterDetailsWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterEdge = {
  __typename?: "SubchapterEdge";
  cursor: Scalars["String"]["output"];
  node: Subchapter;
};

export type SubchapterOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubchapterSort objects to sort Subchapters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubchapterSort>>;
};

/** Fields to sort Subchapters by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubchapterSort object. */
export type SubchapterSort = {
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SubchapterUpdateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubchapterWhere = {
  AND?: InputMaybe<Array<SubchapterWhere>>;
  NOT?: InputMaybe<SubchapterWhere>;
  OR?: InputMaybe<Array<SubchapterWhere>>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubchaptersConnection = {
  __typename?: "SubchaptersConnection";
  edges: Array<SubchapterEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Subfield = {
  __typename?: "Subfield";
  description?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SubfieldAggregateSelection = {
  __typename?: "SubfieldAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type SubfieldCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type SubfieldEdge = {
  __typename?: "SubfieldEdge";
  cursor: Scalars["String"]["output"];
  node: Subfield;
};

export type SubfieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubfieldSort objects to sort Subfields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubfieldSort>>;
};

/** Fields to sort Subfields by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubfieldSort object. */
export type SubfieldSort = {
  description?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SubfieldUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubfieldWhere = {
  AND?: InputMaybe<Array<SubfieldWhere>>;
  NOT?: InputMaybe<SubfieldWhere>;
  OR?: InputMaybe<Array<SubfieldWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubfieldsConnection = {
  __typename?: "SubfieldsConnection";
  edges: Array<SubfieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Submission = {
  __typename?: "Submission";
  assignment: Assignment;
  assignmentAggregate?: Maybe<SubmissionAssignmentAssignmentAggregationSelection>;
  assignmentConnection: SubmissionAssignmentConnection;
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  feedback?: Maybe<Scalars["String"]["output"]>;
  files: Array<File>;
  filesAggregate?: Maybe<SubmissionFileFilesAggregationSelection>;
  filesConnection: SubmissionFilesConnection;
  grade?: Maybe<Scalars["Float"]["output"]>;
  hasTeacherGraded?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  isCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  user: User;
  userAggregate?: Maybe<SubmissionUserUserAggregationSelection>;
  userConnection: SubmissionUserConnection;
};

export type SubmissionAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type SubmissionAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type SubmissionAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SubmissionAssignmentConnectionSort>>;
  where?: InputMaybe<SubmissionAssignmentConnectionWhere>;
};

export type SubmissionFilesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type SubmissionFilesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type SubmissionFilesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SubmissionFilesConnectionSort>>;
  where?: InputMaybe<SubmissionFilesConnectionWhere>;
};

export type SubmissionUserArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type SubmissionUserAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type SubmissionUserConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SubmissionUserConnectionSort>>;
  where?: InputMaybe<SubmissionUserConnectionWhere>;
};

export type SubmissionAggregateSelection = {
  __typename?: "SubmissionAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  feedback: StringAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type SubmissionAssignmentAggregateInput = {
  AND?: InputMaybe<Array<SubmissionAssignmentAggregateInput>>;
  NOT?: InputMaybe<SubmissionAssignmentAggregateInput>;
  OR?: InputMaybe<Array<SubmissionAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SubmissionAssignmentNodeAggregationWhereInput>;
};

export type SubmissionAssignmentAssignmentAggregationSelection = {
  __typename?: "SubmissionAssignmentAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SubmissionAssignmentAssignmentNodeAggregateSelection>;
};

export type SubmissionAssignmentAssignmentNodeAggregateSelection = {
  __typename?: "SubmissionAssignmentAssignmentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type SubmissionAssignmentConnectFieldInput = {
  connect?: InputMaybe<AssignmentConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type SubmissionAssignmentConnectOrCreateFieldInput = {
  onCreate: SubmissionAssignmentConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type SubmissionAssignmentConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type SubmissionAssignmentConnection = {
  __typename?: "SubmissionAssignmentConnection";
  edges: Array<SubmissionAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionAssignmentConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type SubmissionAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<SubmissionAssignmentConnectionWhere>>;
  NOT?: InputMaybe<SubmissionAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<SubmissionAssignmentConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type SubmissionAssignmentCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type SubmissionAssignmentDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<SubmissionAssignmentConnectionWhere>;
};

export type SubmissionAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<SubmissionAssignmentConnectionWhere>;
};

export type SubmissionAssignmentFieldInput = {
  connect?: InputMaybe<SubmissionAssignmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<SubmissionAssignmentConnectOrCreateFieldInput>;
  create?: InputMaybe<SubmissionAssignmentCreateFieldInput>;
};

export type SubmissionAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SubmissionAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SubmissionAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SubmissionAssignmentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionAssignmentRelationship = {
  __typename?: "SubmissionAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type SubmissionAssignmentUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type SubmissionAssignmentUpdateFieldInput = {
  connect?: InputMaybe<SubmissionAssignmentConnectFieldInput>;
  connectOrCreate?: InputMaybe<SubmissionAssignmentConnectOrCreateFieldInput>;
  create?: InputMaybe<SubmissionAssignmentCreateFieldInput>;
  delete?: InputMaybe<SubmissionAssignmentDeleteFieldInput>;
  disconnect?: InputMaybe<SubmissionAssignmentDisconnectFieldInput>;
  update?: InputMaybe<SubmissionAssignmentUpdateConnectionInput>;
  where?: InputMaybe<SubmissionAssignmentConnectionWhere>;
};

export type SubmissionConnectInput = {
  assignment?: InputMaybe<SubmissionAssignmentConnectFieldInput>;
  files?: InputMaybe<Array<SubmissionFilesConnectFieldInput>>;
  user?: InputMaybe<SubmissionUserConnectFieldInput>;
};

export type SubmissionConnectOrCreateInput = {
  assignment?: InputMaybe<SubmissionAssignmentConnectOrCreateFieldInput>;
  user?: InputMaybe<SubmissionUserConnectOrCreateFieldInput>;
};

export type SubmissionConnectOrCreateWhere = {
  node: SubmissionUniqueWhere;
};

export type SubmissionConnectWhere = {
  node: SubmissionWhere;
};

export type SubmissionCreateInput = {
  assignment?: InputMaybe<SubmissionAssignmentFieldInput>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<SubmissionFilesFieldInput>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<SubmissionUserFieldInput>;
};

export type SubmissionDeleteInput = {
  assignment?: InputMaybe<SubmissionAssignmentDeleteFieldInput>;
  files?: InputMaybe<Array<SubmissionFilesDeleteFieldInput>>;
  user?: InputMaybe<SubmissionUserDeleteFieldInput>;
};

export type SubmissionDisconnectInput = {
  assignment?: InputMaybe<SubmissionAssignmentDisconnectFieldInput>;
  files?: InputMaybe<Array<SubmissionFilesDisconnectFieldInput>>;
  user?: InputMaybe<SubmissionUserDisconnectFieldInput>;
};

export type SubmissionEdge = {
  __typename?: "SubmissionEdge";
  cursor: Scalars["String"]["output"];
  node: Submission;
};

export type SubmissionFileFilesAggregationSelection = {
  __typename?: "SubmissionFileFilesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SubmissionFileFilesNodeAggregateSelection>;
};

export type SubmissionFileFilesNodeAggregateSelection = {
  __typename?: "SubmissionFileFilesNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type SubmissionFilesAggregateInput = {
  AND?: InputMaybe<Array<SubmissionFilesAggregateInput>>;
  NOT?: InputMaybe<SubmissionFilesAggregateInput>;
  OR?: InputMaybe<Array<SubmissionFilesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SubmissionFilesNodeAggregationWhereInput>;
};

export type SubmissionFilesConnectFieldInput = {
  connect?: InputMaybe<Array<FileConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type SubmissionFilesConnection = {
  __typename?: "SubmissionFilesConnection";
  edges: Array<SubmissionFilesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionFilesConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type SubmissionFilesConnectionWhere = {
  AND?: InputMaybe<Array<SubmissionFilesConnectionWhere>>;
  NOT?: InputMaybe<SubmissionFilesConnectionWhere>;
  OR?: InputMaybe<Array<SubmissionFilesConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type SubmissionFilesCreateFieldInput = {
  node: FileCreateInput;
};

export type SubmissionFilesDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<SubmissionFilesConnectionWhere>;
};

export type SubmissionFilesDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<SubmissionFilesConnectionWhere>;
};

export type SubmissionFilesFieldInput = {
  connect?: InputMaybe<Array<SubmissionFilesConnectFieldInput>>;
  create?: InputMaybe<Array<SubmissionFilesCreateFieldInput>>;
};

export type SubmissionFilesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SubmissionFilesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SubmissionFilesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SubmissionFilesNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionFilesRelationship = {
  __typename?: "SubmissionFilesRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type SubmissionFilesUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type SubmissionFilesUpdateFieldInput = {
  connect?: InputMaybe<Array<SubmissionFilesConnectFieldInput>>;
  create?: InputMaybe<Array<SubmissionFilesCreateFieldInput>>;
  delete?: InputMaybe<Array<SubmissionFilesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SubmissionFilesDisconnectFieldInput>>;
  update?: InputMaybe<SubmissionFilesUpdateConnectionInput>;
  where?: InputMaybe<SubmissionFilesConnectionWhere>;
};

export type SubmissionListResponse = {
  __typename?: "SubmissionListResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<SubmissionListResponseData>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SubmissionListResponseAggregateSelection = {
  __typename?: "SubmissionListResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type SubmissionListResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionListResponseData = {
  __typename?: "SubmissionListResponseData";
  submissions?: Maybe<Array<Maybe<SubmissionListResponseFormatted>>>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type SubmissionListResponseDataAggregateSelection = {
  __typename?: "SubmissionListResponseDataAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCount: IntAggregateSelection;
};

export type SubmissionListResponseDataConnection = {
  __typename?: "SubmissionListResponseDataConnection";
  edges: Array<SubmissionListResponseDataEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionListResponseDataCreateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionListResponseDataEdge = {
  __typename?: "SubmissionListResponseDataEdge";
  cursor: Scalars["String"]["output"];
  node: SubmissionListResponseData;
};

export type SubmissionListResponseDataOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubmissionListResponseDataSort objects to sort SubmissionListResponseData by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubmissionListResponseDataSort>>;
};

/** Fields to sort SubmissionListResponseData by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubmissionListResponseDataSort object. */
export type SubmissionListResponseDataSort = {
  totalCount?: InputMaybe<SortDirection>;
};

export type SubmissionListResponseDataUpdateInput = {
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionListResponseDataWhere = {
  AND?: InputMaybe<Array<SubmissionListResponseDataWhere>>;
  NOT?: InputMaybe<SubmissionListResponseDataWhere>;
  OR?: InputMaybe<Array<SubmissionListResponseDataWhere>>;
  totalCount?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionListResponseEdge = {
  __typename?: "SubmissionListResponseEdge";
  cursor: Scalars["String"]["output"];
  node: SubmissionListResponse;
};

export type SubmissionListResponseFormatted = {
  __typename?: "SubmissionListResponseFormatted";
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  courseName?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  grade?: Maybe<Scalars["Float"]["output"]>;
  hasTeacherGraded?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isCompleted?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
  userName?: Maybe<Scalars["String"]["output"]>;
};

export type SubmissionListResponseFormattedAggregateSelection = {
  __typename?: "SubmissionListResponseFormattedAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  count: Scalars["Int"]["output"];
  courseName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  moduleName: StringAggregateSelection;
  userName: StringAggregateSelection;
};

export type SubmissionListResponseFormattedCreateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubmissionListResponseFormattedEdge = {
  __typename?: "SubmissionListResponseFormattedEdge";
  cursor: Scalars["String"]["output"];
  node: SubmissionListResponseFormatted;
};

export type SubmissionListResponseFormattedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubmissionListResponseFormattedSort objects to sort SubmissionListResponseFormatteds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubmissionListResponseFormattedSort>>;
};

/** Fields to sort SubmissionListResponseFormatteds by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubmissionListResponseFormattedSort object. */
export type SubmissionListResponseFormattedSort = {
  completedAt?: InputMaybe<SortDirection>;
  courseName?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  grade?: InputMaybe<SortDirection>;
  hasTeacherGraded?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isCompleted?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
  userName?: InputMaybe<SortDirection>;
};

export type SubmissionListResponseFormattedUpdateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grade_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubmissionListResponseFormattedWhere = {
  AND?: InputMaybe<Array<SubmissionListResponseFormattedWhere>>;
  NOT?: InputMaybe<SubmissionListResponseFormattedWhere>;
  OR?: InputMaybe<Array<SubmissionListResponseFormattedWhere>>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  completedAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  courseName?: InputMaybe<Scalars["String"]["input"]>;
  courseName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grade_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
  userName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  userName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubmissionListResponseFormattedsConnection = {
  __typename?: "SubmissionListResponseFormattedsConnection";
  edges: Array<SubmissionListResponseFormattedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionListResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubmissionListResponseSort objects to sort SubmissionListResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubmissionListResponseSort>>;
};

/** Fields to sort SubmissionListResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubmissionListResponseSort object. */
export type SubmissionListResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type SubmissionListResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionListResponseWhere = {
  AND?: InputMaybe<Array<SubmissionListResponseWhere>>;
  NOT?: InputMaybe<SubmissionListResponseWhere>;
  OR?: InputMaybe<Array<SubmissionListResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionListResponsesConnection = {
  __typename?: "SubmissionListResponsesConnection";
  edges: Array<SubmissionListResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionOnCreateInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SubmissionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubmissionSort objects to sort Submissions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubmissionSort>>;
};

export type SubmissionRelationInput = {
  assignment?: InputMaybe<SubmissionAssignmentCreateFieldInput>;
  files?: InputMaybe<Array<SubmissionFilesCreateFieldInput>>;
  user?: InputMaybe<SubmissionUserCreateFieldInput>;
};

export type SubmissionResponse = {
  __typename?: "SubmissionResponse";
  data?: Maybe<Array<Maybe<Submission>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SubmissionResponseAggregateSelection = {
  __typename?: "SubmissionResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type SubmissionResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionResponseEdge = {
  __typename?: "SubmissionResponseEdge";
  cursor: Scalars["String"]["output"];
  node: SubmissionResponse;
};

export type SubmissionResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubmissionResponseSort objects to sort SubmissionResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubmissionResponseSort>>;
};

/** Fields to sort SubmissionResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubmissionResponseSort object. */
export type SubmissionResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type SubmissionResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionResponseWhere = {
  AND?: InputMaybe<Array<SubmissionResponseWhere>>;
  NOT?: InputMaybe<SubmissionResponseWhere>;
  OR?: InputMaybe<Array<SubmissionResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SubmissionResponsesConnection = {
  __typename?: "SubmissionResponsesConnection";
  edges: Array<SubmissionResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Submissions by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubmissionSort object. */
export type SubmissionSort = {
  completedAt?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  feedback?: InputMaybe<SortDirection>;
  grade?: InputMaybe<SortDirection>;
  hasTeacherGraded?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isCompleted?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
};

export type SubmissionUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SubmissionUpdateInput = {
  assignment?: InputMaybe<SubmissionAssignmentUpdateFieldInput>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<SubmissionFilesUpdateFieldInput>>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grade_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<SubmissionUserUpdateFieldInput>;
};

export type SubmissionUserAggregateInput = {
  AND?: InputMaybe<Array<SubmissionUserAggregateInput>>;
  NOT?: InputMaybe<SubmissionUserAggregateInput>;
  OR?: InputMaybe<Array<SubmissionUserAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SubmissionUserNodeAggregationWhereInput>;
};

export type SubmissionUserConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type SubmissionUserConnectOrCreateFieldInput = {
  onCreate: SubmissionUserConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type SubmissionUserConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type SubmissionUserConnection = {
  __typename?: "SubmissionUserConnection";
  edges: Array<SubmissionUserRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubmissionUserConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type SubmissionUserConnectionWhere = {
  AND?: InputMaybe<Array<SubmissionUserConnectionWhere>>;
  NOT?: InputMaybe<SubmissionUserConnectionWhere>;
  OR?: InputMaybe<Array<SubmissionUserConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type SubmissionUserCreateFieldInput = {
  node: UserCreateInput;
};

export type SubmissionUserDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<SubmissionUserConnectionWhere>;
};

export type SubmissionUserDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<SubmissionUserConnectionWhere>;
};

export type SubmissionUserFieldInput = {
  connect?: InputMaybe<SubmissionUserConnectFieldInput>;
  connectOrCreate?: InputMaybe<SubmissionUserConnectOrCreateFieldInput>;
  create?: InputMaybe<SubmissionUserCreateFieldInput>;
};

export type SubmissionUserNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SubmissionUserNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SubmissionUserNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SubmissionUserNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubmissionUserRelationship = {
  __typename?: "SubmissionUserRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type SubmissionUserUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type SubmissionUserUpdateFieldInput = {
  connect?: InputMaybe<SubmissionUserConnectFieldInput>;
  connectOrCreate?: InputMaybe<SubmissionUserConnectOrCreateFieldInput>;
  create?: InputMaybe<SubmissionUserCreateFieldInput>;
  delete?: InputMaybe<SubmissionUserDeleteFieldInput>;
  disconnect?: InputMaybe<SubmissionUserDisconnectFieldInput>;
  update?: InputMaybe<SubmissionUserUpdateConnectionInput>;
  where?: InputMaybe<SubmissionUserConnectionWhere>;
};

export type SubmissionUserUserAggregationSelection = {
  __typename?: "SubmissionUserUserAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SubmissionUserUserNodeAggregateSelection>;
};

export type SubmissionUserUserNodeAggregateSelection = {
  __typename?: "SubmissionUserUserNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type SubmissionWhere = {
  AND?: InputMaybe<Array<SubmissionWhere>>;
  NOT?: InputMaybe<SubmissionWhere>;
  OR?: InputMaybe<Array<SubmissionWhere>>;
  assignment?: InputMaybe<AssignmentWhere>;
  assignmentAggregate?: InputMaybe<SubmissionAssignmentAggregateInput>;
  assignmentConnection?: InputMaybe<SubmissionAssignmentConnectionWhere>;
  assignmentConnection_NOT?: InputMaybe<SubmissionAssignmentConnectionWhere>;
  assignment_NOT?: InputMaybe<AssignmentWhere>;
  completedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  completedAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  feedback_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  feedback_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  feedback_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  feedback_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filesAggregate?: InputMaybe<SubmissionFilesAggregateInput>;
  /** Return Submissions where all of the related SubmissionFilesConnections match this filter */
  filesConnection_ALL?: InputMaybe<SubmissionFilesConnectionWhere>;
  /** Return Submissions where none of the related SubmissionFilesConnections match this filter */
  filesConnection_NONE?: InputMaybe<SubmissionFilesConnectionWhere>;
  /** Return Submissions where one of the related SubmissionFilesConnections match this filter */
  filesConnection_SINGLE?: InputMaybe<SubmissionFilesConnectionWhere>;
  /** Return Submissions where some of the related SubmissionFilesConnections match this filter */
  filesConnection_SOME?: InputMaybe<SubmissionFilesConnectionWhere>;
  /** Return Submissions where all of the related Files match this filter */
  files_ALL?: InputMaybe<FileWhere>;
  /** Return Submissions where none of the related Files match this filter */
  files_NONE?: InputMaybe<FileWhere>;
  /** Return Submissions where one of the related Files match this filter */
  files_SINGLE?: InputMaybe<FileWhere>;
  /** Return Submissions where some of the related Files match this filter */
  files_SOME?: InputMaybe<FileWhere>;
  grade?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grade_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasTeacherGraded?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isCompleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<UserWhere>;
  userAggregate?: InputMaybe<SubmissionUserAggregateInput>;
  userConnection?: InputMaybe<SubmissionUserConnectionWhere>;
  userConnection_NOT?: InputMaybe<SubmissionUserConnectionWhere>;
  user_NOT?: InputMaybe<UserWhere>;
};

export type SubmissionsConnection = {
  __typename?: "SubmissionsConnection";
  edges: Array<SubmissionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]["output"]>;
};

export type SuggestVideoInsightsInput = {
  classId: Scalars["String"]["input"];
  contentId: Scalars["String"]["input"];
  contentType: Scalars["String"]["input"];
  integrationId: Scalars["String"]["input"];
};

export type SuggestVideoInsightsResponse = {
  __typename?: "SuggestVideoInsightsResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SuggestVideoInsightsResponseAggregateSelection = {
  __typename?: "SuggestVideoInsightsResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type SuggestVideoInsightsResponseCreateInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SuggestVideoInsightsResponseEdge = {
  __typename?: "SuggestVideoInsightsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: SuggestVideoInsightsResponse;
};

export type SuggestVideoInsightsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SuggestVideoInsightsResponseSort objects to sort SuggestVideoInsightsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SuggestVideoInsightsResponseSort>>;
};

/** Fields to sort SuggestVideoInsightsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SuggestVideoInsightsResponseSort object. */
export type SuggestVideoInsightsResponseSort = {
  data?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type SuggestVideoInsightsResponseUpdateInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SuggestVideoInsightsResponseWhere = {
  AND?: InputMaybe<Array<SuggestVideoInsightsResponseWhere>>;
  NOT?: InputMaybe<SuggestVideoInsightsResponseWhere>;
  OR?: InputMaybe<Array<SuggestVideoInsightsResponseWhere>>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  data_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SuggestVideoInsightsResponsesConnection = {
  __typename?: "SuggestVideoInsightsResponsesConnection";
  edges: Array<SuggestVideoInsightsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Suggestion = {
  __typename?: "Suggestion";
  assignmentId?: Maybe<Scalars["String"]["output"]>;
  classId?: Maybe<Scalars["String"]["output"]>;
  content: Scalars["String"]["output"];
  created: Scalars["DateTime"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  integrationId?: Maybe<Scalars["String"]["output"]>;
  journeyId?: Maybe<Scalars["String"]["output"]>;
  trackName?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  usersHasSuggestion: Array<User>;
  usersHasSuggestionAggregate?: Maybe<SuggestionUserUsersHasSuggestionAggregationSelection>;
  usersHasSuggestionConnection: SuggestionUsersHasSuggestionConnection;
};

export type SuggestionUsersHasSuggestionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionSort>>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionAggregateSelection = {
  __typename?: "SuggestionAggregateSelection";
  assignmentId: StringAggregateSelection;
  classId: StringAggregateSelection;
  content: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  integrationId: StringAggregateSelection;
  journeyId: StringAggregateSelection;
  trackName: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type SuggestionConnectInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
};

export type SuggestionConnectOrCreateInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectOrCreateFieldInput>>;
};

export type SuggestionConnectWhere = {
  node: SuggestionWhere;
};

export type SuggestionCreateInput = {
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  content: Scalars["String"]["input"];
  created: Scalars["DateTime"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  usersHasSuggestion?: InputMaybe<SuggestionUsersHasSuggestionFieldInput>;
};

export type SuggestionDeleteInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionDeleteFieldInput>>;
};

export type SuggestionDisconnectInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionDisconnectFieldInput>>;
};

export type SuggestionEdge = {
  __typename?: "SuggestionEdge";
  cursor: Scalars["String"]["output"];
  node: Suggestion;
};

export type SuggestionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SuggestionSort objects to sort Suggestions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SuggestionSort>>;
};

export type SuggestionRelationInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
};

/** Fields to sort Suggestions by. The order in which sorts are applied is not guaranteed when specifying many fields in one SuggestionSort object. */
export type SuggestionSort = {
  assignmentId?: InputMaybe<SortDirection>;
  classId?: InputMaybe<SortDirection>;
  content?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  integrationId?: InputMaybe<SortDirection>;
  journeyId?: InputMaybe<SortDirection>;
  trackName?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SuggestionUpdateInput = {
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionUpdateFieldInput>>;
};

export type SuggestionUserUsersHasSuggestionAggregationSelection = {
  __typename?: "SuggestionUserUsersHasSuggestionAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SuggestionUserUsersHasSuggestionNodeAggregateSelection>;
};

export type SuggestionUserUsersHasSuggestionNodeAggregateSelection = {
  __typename?: "SuggestionUserUsersHasSuggestionNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type SuggestionUsersHasSuggestionAggregateInput = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionAggregateInput>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionAggregateInput>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SuggestionUsersHasSuggestionNodeAggregationWhereInput>;
};

export type SuggestionUsersHasSuggestionConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type SuggestionUsersHasSuggestionConnectOrCreateFieldInput = {
  onCreate: SuggestionUsersHasSuggestionConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type SuggestionUsersHasSuggestionConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type SuggestionUsersHasSuggestionConnection = {
  __typename?: "SuggestionUsersHasSuggestionConnection";
  edges: Array<SuggestionUsersHasSuggestionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SuggestionUsersHasSuggestionConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type SuggestionUsersHasSuggestionConnectionWhere = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionWhere>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionCreateFieldInput = {
  node: UserCreateInput;
};

export type SuggestionUsersHasSuggestionDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionUsersHasSuggestionDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionUsersHasSuggestionFieldInput = {
  connect?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
};

export type SuggestionUsersHasSuggestionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SuggestionUsersHasSuggestionRelationship = {
  __typename?: "SuggestionUsersHasSuggestionRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type SuggestionUsersHasSuggestionUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type SuggestionUsersHasSuggestionUpdateFieldInput = {
  connect?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
  delete?: InputMaybe<Array<SuggestionUsersHasSuggestionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SuggestionUsersHasSuggestionDisconnectFieldInput>>;
  update?: InputMaybe<SuggestionUsersHasSuggestionUpdateConnectionInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionWhere = {
  AND?: InputMaybe<Array<SuggestionWhere>>;
  NOT?: InputMaybe<SuggestionWhere>;
  OR?: InputMaybe<Array<SuggestionWhere>>;
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignmentId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  classId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  classId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  classId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  classId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  content_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  content_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integrationId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  journeyId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  trackName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trackName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trackName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trackName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSuggestionAggregate?: InputMaybe<SuggestionUsersHasSuggestionAggregateInput>;
  /** Return Suggestions where all of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_ALL?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where none of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_NONE?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where one of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_SINGLE?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where some of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_SOME?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where all of the related Users match this filter */
  usersHasSuggestion_ALL?: InputMaybe<UserWhere>;
  /** Return Suggestions where none of the related Users match this filter */
  usersHasSuggestion_NONE?: InputMaybe<UserWhere>;
  /** Return Suggestions where one of the related Users match this filter */
  usersHasSuggestion_SINGLE?: InputMaybe<UserWhere>;
  /** Return Suggestions where some of the related Users match this filter */
  usersHasSuggestion_SOME?: InputMaybe<UserWhere>;
};

export type SuggestionsConnection = {
  __typename?: "SuggestionsConnection";
  edges: Array<SuggestionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Tenant = {
  __typename?: "Tenant";
  additionalScope?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  allowFormSignup?: Maybe<Scalars["Boolean"]["output"]>;
  autoEnrollCourses?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  axioSchools?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  bugReportEmails?: Maybe<Scalars["String"]["output"]>;
  companionName?: Maybe<Scalars["String"]["output"]>;
  connectURL?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  customNews?: Maybe<Scalars["Boolean"]["output"]>;
  disableWebsearchWhenRAG?: Maybe<Scalars["Boolean"]["output"]>;
  emailBackgroundColor?: Maybe<Scalars["String"]["output"]>;
  emailFooterColor?: Maybe<Scalars["String"]["output"]>;
  emailRadius?: Maybe<Scalars["String"]["output"]>;
  emulationStatement?: Maybe<Scalars["String"]["output"]>;
  externalSendGridAppInviteTemplateId?: Maybe<Scalars["String"]["output"]>;
  externalSendGridDailyEmailTemplateId?: Maybe<Scalars["String"]["output"]>;
  externalSendGridFromEmail?: Maybe<Scalars["String"]["output"]>;
  externalSendGridFromName?: Maybe<Scalars["String"]["output"]>;
  externalSendGridTwilioServiceSID?: Maybe<Scalars["String"]["output"]>;
  gameMenuBg?: Maybe<Scalars["String"]["output"]>;
  gameMenuCardBg?: Maybe<Scalars["String"]["output"]>;
  gameMenuCardButton?: Maybe<Scalars["String"]["output"]>;
  gameMenuCardButtonText?: Maybe<Scalars["String"]["output"]>;
  gameMenuCardRefresh?: Maybe<Scalars["String"]["output"]>;
  gameMenuProgress?: Maybe<Scalars["String"]["output"]>;
  gameMenuRefresh?: Maybe<Scalars["String"]["output"]>;
  gameMenuRefreshText?: Maybe<Scalars["String"]["output"]>;
  gameMenuTrack?: Maybe<Scalars["String"]["output"]>;
  generalPrompt?: Maybe<Scalars["String"]["output"]>;
  hasCourseEnrollment?: Maybe<Scalars["Boolean"]["output"]>;
  hasDegrees?: Maybe<Scalars["Boolean"]["output"]>;
  hasEmailVerification?: Maybe<Scalars["Boolean"]["output"]>;
  hasProFlow?: Maybe<Scalars["Boolean"]["output"]>;
  hasTrial?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  isAnalyticsEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isAxioOffering?: Maybe<Scalars["Boolean"]["output"]>;
  isDailyEventEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isFreemium?: Maybe<Scalars["Boolean"]["output"]>;
  isLMS?: Maybe<Scalars["Boolean"]["output"]>;
  isLTI?: Maybe<Scalars["Boolean"]["output"]>;
  isPrivateLabelIcon?: Maybe<Scalars["Boolean"]["output"]>;
  isSuggestedInsights?: Maybe<Scalars["Boolean"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  learningEmulationStatement?: Maybe<Scalars["String"]["output"]>;
  learningFieldColor?: Maybe<Scalars["String"]["output"]>;
  learningSubFieldColor?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  metaTagsAppleTouchIcon?: Maybe<Scalars["String"]["output"]>;
  metaTagsDescription?: Maybe<Scalars["String"]["output"]>;
  metaTagsThemeColor?: Maybe<Scalars["String"]["output"]>;
  metaTagsTitle?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterCard?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterDescription?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterImage?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterSite?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterTitle?: Maybe<Scalars["String"]["output"]>;
  moderationHarassment?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHarassmentThreatening?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHate?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHateThreatening?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarm?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarmInstructions?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarmIntent?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSexual?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSexualMinors?: Maybe<Scalars["Boolean"]["output"]>;
  moderationViolence?: Maybe<Scalars["Boolean"]["output"]>;
  moderationViolenceGraphic?: Maybe<Scalars["Boolean"]["output"]>;
  newsSearchTerms?: Maybe<Scalars["String"]["output"]>;
  newsSectionTitle?: Maybe<Scalars["String"]["output"]>;
  onboardingPrompt?: Maybe<Scalars["String"]["output"]>;
  primaryColor?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  showFeedback?: Maybe<Scalars["Boolean"]["output"]>;
  showInviteFriends?: Maybe<Scalars["Boolean"]["output"]>;
  showJobsTab?: Maybe<Scalars["Boolean"]["output"]>;
  showMiniLogo?: Maybe<Scalars["Boolean"]["output"]>;
  showNews?: Maybe<Scalars["Boolean"]["output"]>;
  showObjectivesTab?: Maybe<Scalars["Boolean"]["output"]>;
  stripeSecretKey?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  stripeTrialDays?: Maybe<Scalars["Int"]["output"]>;
  tenantDomain?: Maybe<Scalars["String"]["output"]>;
  tenantName?: Maybe<Scalars["String"]["output"]>;
  termsOfServiceLink?: Maybe<Scalars["String"]["output"]>;
  upgradeModalBody?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
  usersAggregate?: Maybe<TenantUserUsersAggregationSelection>;
  usersConnection: TenantUsersConnection;
  webhook?: Maybe<Webhook>;
  webhookAggregate?: Maybe<TenantWebhookWebhookAggregationSelection>;
  webhookConnection: TenantWebhookConnection;
};

export type TenantUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TenantUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TenantUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TenantUsersConnectionSort>>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantWebhookArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WebhookOptions>;
  where?: InputMaybe<WebhookWhere>;
};

export type TenantWebhookAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WebhookWhere>;
};

export type TenantWebhookConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TenantWebhookConnectionSort>>;
  where?: InputMaybe<TenantWebhookConnectionWhere>;
};

export type TenantAggregateSelection = {
  __typename?: "TenantAggregateSelection";
  bugReportEmails: StringAggregateSelection;
  companionName: StringAggregateSelection;
  connectURL: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  emailBackgroundColor: StringAggregateSelection;
  emailFooterColor: StringAggregateSelection;
  emailRadius: StringAggregateSelection;
  emulationStatement: StringAggregateSelection;
  externalSendGridAppInviteTemplateId: StringAggregateSelection;
  externalSendGridDailyEmailTemplateId: StringAggregateSelection;
  externalSendGridFromEmail: StringAggregateSelection;
  externalSendGridFromName: StringAggregateSelection;
  externalSendGridTwilioServiceSID: StringAggregateSelection;
  gameMenuBg: StringAggregateSelection;
  gameMenuCardBg: StringAggregateSelection;
  gameMenuCardButton: StringAggregateSelection;
  gameMenuCardButtonText: StringAggregateSelection;
  gameMenuCardRefresh: StringAggregateSelection;
  gameMenuProgress: StringAggregateSelection;
  gameMenuRefresh: StringAggregateSelection;
  gameMenuRefreshText: StringAggregateSelection;
  gameMenuTrack: StringAggregateSelection;
  generalPrompt: StringAggregateSelection;
  id: IdAggregateSelection;
  key: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningEmulationStatement: StringAggregateSelection;
  learningFieldColor: StringAggregateSelection;
  learningSubFieldColor: StringAggregateSelection;
  logo: StringAggregateSelection;
  metaTagsAppleTouchIcon: StringAggregateSelection;
  metaTagsDescription: StringAggregateSelection;
  metaTagsThemeColor: StringAggregateSelection;
  metaTagsTitle: StringAggregateSelection;
  metaTagsTwitterCard: StringAggregateSelection;
  metaTagsTwitterDescription: StringAggregateSelection;
  metaTagsTwitterImage: StringAggregateSelection;
  metaTagsTwitterSite: StringAggregateSelection;
  metaTagsTwitterTitle: StringAggregateSelection;
  newsSearchTerms: StringAggregateSelection;
  newsSectionTitle: StringAggregateSelection;
  onboardingPrompt: StringAggregateSelection;
  primaryColor: StringAggregateSelection;
  stripeSecretKey: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  stripeTrialDays: IntAggregateSelection;
  tenantDomain: StringAggregateSelection;
  tenantName: StringAggregateSelection;
  termsOfServiceLink: StringAggregateSelection;
  upgradeModalBody: StringAggregateSelection;
};

export type TenantConnectInput = {
  users?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
  webhook?: InputMaybe<TenantWebhookConnectFieldInput>;
};

export type TenantConnectOrCreateInput = {
  users?: InputMaybe<Array<TenantUsersConnectOrCreateFieldInput>>;
};

export type TenantConnectWhere = {
  node: TenantWhere;
};

export type TenantCreateInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  allowFormSignup?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoEnrollCourses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  axioSchools?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emailBackgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  hasCourseEnrollment?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDegrees?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isAnalyticsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAxioOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDailyEventEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeSecretKey?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  tenantName?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<TenantUsersFieldInput>;
  webhook?: InputMaybe<TenantWebhookFieldInput>;
};

export type TenantDeleteInput = {
  users?: InputMaybe<Array<TenantUsersDeleteFieldInput>>;
  webhook?: InputMaybe<TenantWebhookDeleteFieldInput>;
};

export type TenantDisconnectInput = {
  users?: InputMaybe<Array<TenantUsersDisconnectFieldInput>>;
  webhook?: InputMaybe<TenantWebhookDisconnectFieldInput>;
};

export type TenantEdge = {
  __typename?: "TenantEdge";
  cursor: Scalars["String"]["output"];
  node: Tenant;
};

export type TenantInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  allowFormSignup?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoEnrollCourses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  axioSchools?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  hasCourseEnrollment?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDegrees?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isAxioOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeSecretKey?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  tenantName?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
};

export type TenantOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TenantSort objects to sort Tenants by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TenantSort>>;
};

export type TenantRelationInput = {
  users?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
  webhook?: InputMaybe<TenantWebhookCreateFieldInput>;
};

export type TenantResponse = {
  __typename?: "TenantResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Tenant>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TenantResponseAggregateSelection = {
  __typename?: "TenantResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type TenantResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponseEdge = {
  __typename?: "TenantResponseEdge";
  cursor: Scalars["String"]["output"];
  node: TenantResponse;
};

export type TenantResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TenantResponseSort objects to sort TenantResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TenantResponseSort>>;
};

/** Fields to sort TenantResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one TenantResponseSort object. */
export type TenantResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type TenantResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponseWhere = {
  AND?: InputMaybe<Array<TenantResponseWhere>>;
  NOT?: InputMaybe<TenantResponseWhere>;
  OR?: InputMaybe<Array<TenantResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponsesConnection = {
  __typename?: "TenantResponsesConnection";
  edges: Array<TenantResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TenantSchemaResponse = {
  __typename?: "TenantSchemaResponse";
  data?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TenantSchemaResponseAggregateSelection = {
  __typename?: "TenantSchemaResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  data: StringAggregateSelection;
  message: StringAggregateSelection;
};

export type TenantSchemaResponseCreateInput = {
  data?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantSchemaResponseEdge = {
  __typename?: "TenantSchemaResponseEdge";
  cursor: Scalars["String"]["output"];
  node: TenantSchemaResponse;
};

export type TenantSchemaResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TenantSchemaResponseSort objects to sort TenantSchemaResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TenantSchemaResponseSort>>;
};

/** Fields to sort TenantSchemaResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one TenantSchemaResponseSort object. */
export type TenantSchemaResponseSort = {
  data?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type TenantSchemaResponseUpdateInput = {
  data?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantSchemaResponseWhere = {
  AND?: InputMaybe<Array<TenantSchemaResponseWhere>>;
  NOT?: InputMaybe<TenantSchemaResponseWhere>;
  OR?: InputMaybe<Array<TenantSchemaResponseWhere>>;
  data?: InputMaybe<Scalars["String"]["input"]>;
  data_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  data_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  data_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  data_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantSchemaResponsesConnection = {
  __typename?: "TenantSchemaResponsesConnection";
  edges: Array<TenantSchemaResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Tenants by. The order in which sorts are applied is not guaranteed when specifying many fields in one TenantSort object. */
export type TenantSort = {
  allowFormSignup?: InputMaybe<SortDirection>;
  bugReportEmails?: InputMaybe<SortDirection>;
  companionName?: InputMaybe<SortDirection>;
  connectURL?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  customNews?: InputMaybe<SortDirection>;
  disableWebsearchWhenRAG?: InputMaybe<SortDirection>;
  emailBackgroundColor?: InputMaybe<SortDirection>;
  emailFooterColor?: InputMaybe<SortDirection>;
  emailRadius?: InputMaybe<SortDirection>;
  emulationStatement?: InputMaybe<SortDirection>;
  externalSendGridAppInviteTemplateId?: InputMaybe<SortDirection>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<SortDirection>;
  externalSendGridFromEmail?: InputMaybe<SortDirection>;
  externalSendGridFromName?: InputMaybe<SortDirection>;
  externalSendGridTwilioServiceSID?: InputMaybe<SortDirection>;
  gameMenuBg?: InputMaybe<SortDirection>;
  gameMenuCardBg?: InputMaybe<SortDirection>;
  gameMenuCardButton?: InputMaybe<SortDirection>;
  gameMenuCardButtonText?: InputMaybe<SortDirection>;
  gameMenuCardRefresh?: InputMaybe<SortDirection>;
  gameMenuProgress?: InputMaybe<SortDirection>;
  gameMenuRefresh?: InputMaybe<SortDirection>;
  gameMenuRefreshText?: InputMaybe<SortDirection>;
  gameMenuTrack?: InputMaybe<SortDirection>;
  generalPrompt?: InputMaybe<SortDirection>;
  hasCourseEnrollment?: InputMaybe<SortDirection>;
  hasDegrees?: InputMaybe<SortDirection>;
  hasEmailVerification?: InputMaybe<SortDirection>;
  hasProFlow?: InputMaybe<SortDirection>;
  hasTrial?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isAnalyticsEnabled?: InputMaybe<SortDirection>;
  isAxioOffering?: InputMaybe<SortDirection>;
  isDailyEventEnabled?: InputMaybe<SortDirection>;
  isFreemium?: InputMaybe<SortDirection>;
  isLMS?: InputMaybe<SortDirection>;
  isLTI?: InputMaybe<SortDirection>;
  isPrivateLabelIcon?: InputMaybe<SortDirection>;
  isSuggestedInsights?: InputMaybe<SortDirection>;
  key?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  learningEmulationStatement?: InputMaybe<SortDirection>;
  learningFieldColor?: InputMaybe<SortDirection>;
  learningSubFieldColor?: InputMaybe<SortDirection>;
  logo?: InputMaybe<SortDirection>;
  metaTagsAppleTouchIcon?: InputMaybe<SortDirection>;
  metaTagsDescription?: InputMaybe<SortDirection>;
  metaTagsThemeColor?: InputMaybe<SortDirection>;
  metaTagsTitle?: InputMaybe<SortDirection>;
  metaTagsTwitterCard?: InputMaybe<SortDirection>;
  metaTagsTwitterDescription?: InputMaybe<SortDirection>;
  metaTagsTwitterImage?: InputMaybe<SortDirection>;
  metaTagsTwitterSite?: InputMaybe<SortDirection>;
  metaTagsTwitterTitle?: InputMaybe<SortDirection>;
  moderationHarassment?: InputMaybe<SortDirection>;
  moderationHarassmentThreatening?: InputMaybe<SortDirection>;
  moderationHate?: InputMaybe<SortDirection>;
  moderationHateThreatening?: InputMaybe<SortDirection>;
  moderationSelfHarm?: InputMaybe<SortDirection>;
  moderationSelfHarmInstructions?: InputMaybe<SortDirection>;
  moderationSelfHarmIntent?: InputMaybe<SortDirection>;
  moderationSexual?: InputMaybe<SortDirection>;
  moderationSexualMinors?: InputMaybe<SortDirection>;
  moderationViolence?: InputMaybe<SortDirection>;
  moderationViolenceGraphic?: InputMaybe<SortDirection>;
  newsSearchTerms?: InputMaybe<SortDirection>;
  newsSectionTitle?: InputMaybe<SortDirection>;
  onboardingPrompt?: InputMaybe<SortDirection>;
  primaryColor?: InputMaybe<SortDirection>;
  showFeedback?: InputMaybe<SortDirection>;
  showInviteFriends?: InputMaybe<SortDirection>;
  showJobsTab?: InputMaybe<SortDirection>;
  showMiniLogo?: InputMaybe<SortDirection>;
  showNews?: InputMaybe<SortDirection>;
  showObjectivesTab?: InputMaybe<SortDirection>;
  stripeSecretKey?: InputMaybe<SortDirection>;
  stripeSubscriptionId?: InputMaybe<SortDirection>;
  stripeTrialDays?: InputMaybe<SortDirection>;
  tenantDomain?: InputMaybe<SortDirection>;
  tenantName?: InputMaybe<SortDirection>;
  termsOfServiceLink?: InputMaybe<SortDirection>;
  upgradeModalBody?: InputMaybe<SortDirection>;
};

export type TenantUpdateInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  additionalScope_POP?: InputMaybe<Scalars["Int"]["input"]>;
  additionalScope_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  allowFormSignup?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoEnrollCourses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  autoEnrollCourses_POP?: InputMaybe<Scalars["Int"]["input"]>;
  autoEnrollCourses_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  axioSchools?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  axioSchools_POP?: InputMaybe<Scalars["Int"]["input"]>;
  axioSchools_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emailBackgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  hasCourseEnrollment?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDegrees?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isAnalyticsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAxioOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDailyEventEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  scope_POP?: InputMaybe<Scalars["Int"]["input"]>;
  scope_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeSecretKey?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  tenantName?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<TenantUsersUpdateFieldInput>>;
  webhook?: InputMaybe<TenantWebhookUpdateFieldInput>;
};

export type TenantUserUsersAggregationSelection = {
  __typename?: "TenantUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<TenantUserUsersNodeAggregateSelection>;
};

export type TenantUserUsersNodeAggregateSelection = {
  __typename?: "TenantUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type TenantUsersAggregateInput = {
  AND?: InputMaybe<Array<TenantUsersAggregateInput>>;
  NOT?: InputMaybe<TenantUsersAggregateInput>;
  OR?: InputMaybe<Array<TenantUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<TenantUsersNodeAggregationWhereInput>;
};

export type TenantUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TenantUsersConnectOrCreateFieldInput = {
  onCreate: TenantUsersConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type TenantUsersConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type TenantUsersConnection = {
  __typename?: "TenantUsersConnection";
  edges: Array<TenantUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TenantUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type TenantUsersConnectionWhere = {
  AND?: InputMaybe<Array<TenantUsersConnectionWhere>>;
  NOT?: InputMaybe<TenantUsersConnectionWhere>;
  OR?: InputMaybe<Array<TenantUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type TenantUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type TenantUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantUsersFieldInput = {
  connect?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TenantUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
};

export type TenantUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TenantUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TenantUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TenantUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TenantUsersRelationship = {
  __typename?: "TenantUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type TenantUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type TenantUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TenantUsersConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<TenantUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TenantUsersDisconnectFieldInput>>;
  update?: InputMaybe<TenantUsersUpdateConnectionInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantWebhookAggregateInput = {
  AND?: InputMaybe<Array<TenantWebhookAggregateInput>>;
  NOT?: InputMaybe<TenantWebhookAggregateInput>;
  OR?: InputMaybe<Array<TenantWebhookAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<TenantWebhookNodeAggregationWhereInput>;
};

export type TenantWebhookConnectFieldInput = {
  connect?: InputMaybe<WebhookConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WebhookConnectWhere>;
};

export type TenantWebhookConnection = {
  __typename?: "TenantWebhookConnection";
  edges: Array<TenantWebhookRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TenantWebhookConnectionSort = {
  node?: InputMaybe<WebhookSort>;
};

export type TenantWebhookConnectionWhere = {
  AND?: InputMaybe<Array<TenantWebhookConnectionWhere>>;
  NOT?: InputMaybe<TenantWebhookConnectionWhere>;
  OR?: InputMaybe<Array<TenantWebhookConnectionWhere>>;
  node?: InputMaybe<WebhookWhere>;
};

export type TenantWebhookCreateFieldInput = {
  node: WebhookCreateInput;
};

export type TenantWebhookDeleteFieldInput = {
  delete?: InputMaybe<WebhookDeleteInput>;
  where?: InputMaybe<TenantWebhookConnectionWhere>;
};

export type TenantWebhookDisconnectFieldInput = {
  disconnect?: InputMaybe<WebhookDisconnectInput>;
  where?: InputMaybe<TenantWebhookConnectionWhere>;
};

export type TenantWebhookFieldInput = {
  connect?: InputMaybe<TenantWebhookConnectFieldInput>;
  create?: InputMaybe<TenantWebhookCreateFieldInput>;
};

export type TenantWebhookNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TenantWebhookNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TenantWebhookNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TenantWebhookNodeAggregationWhereInput>>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TenantWebhookRelationship = {
  __typename?: "TenantWebhookRelationship";
  cursor: Scalars["String"]["output"];
  node: Webhook;
};

export type TenantWebhookUpdateConnectionInput = {
  node?: InputMaybe<WebhookUpdateInput>;
};

export type TenantWebhookUpdateFieldInput = {
  connect?: InputMaybe<TenantWebhookConnectFieldInput>;
  create?: InputMaybe<TenantWebhookCreateFieldInput>;
  delete?: InputMaybe<TenantWebhookDeleteFieldInput>;
  disconnect?: InputMaybe<TenantWebhookDisconnectFieldInput>;
  update?: InputMaybe<TenantWebhookUpdateConnectionInput>;
  where?: InputMaybe<TenantWebhookConnectionWhere>;
};

export type TenantWebhookWebhookAggregationSelection = {
  __typename?: "TenantWebhookWebhookAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<TenantWebhookWebhookNodeAggregateSelection>;
};

export type TenantWebhookWebhookNodeAggregateSelection = {
  __typename?: "TenantWebhookWebhookNodeAggregateSelection";
  id: IdAggregateSelection;
  url: StringAggregateSelection;
};

export type TenantWhere = {
  AND?: InputMaybe<Array<TenantWhere>>;
  NOT?: InputMaybe<TenantWhere>;
  OR?: InputMaybe<Array<TenantWhere>>;
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  additionalScope_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  allowFormSignup?: InputMaybe<Scalars["Boolean"]["input"]>;
  autoEnrollCourses?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  autoEnrollCourses_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  axioSchools?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  axioSchools_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  companionName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  companionName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  companionName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  companionName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  connectURL_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emailBackgroundColor?: InputMaybe<Scalars["String"]["input"]>;
  emailBackgroundColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emailBackgroundColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emailBackgroundColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emailBackgroundColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emailFooterColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emailFooterColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emailRadius_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emailRadius_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emulationStatement_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridAppInviteTemplateId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridDailyEmailTemplateId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridFromEmail_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridFromName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridTwilioServiceSID_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuBg_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuCardBg_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuCardButtonText_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuCardButton_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuCardRefresh_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuProgress_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuRefreshText_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuRefresh_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gameMenuTrack_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  generalPrompt_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasCourseEnrollment?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDegrees?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasProFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isAnalyticsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAxioOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDailyEventEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  key_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  key_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  key_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  key_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningEmulationStatement_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningFieldColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningSubFieldColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  logo_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  logo_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  logo_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  logo_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsAppleTouchIcon_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsThemeColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterCard_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterImage_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterSite_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  newsSearchTerms_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  newsSectionTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  onboardingPrompt_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  primaryColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  scope_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  stripeSecretKey?: InputMaybe<Scalars["String"]["input"]>;
  stripeSecretKey_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSecretKey_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSecretKey_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSecretKey_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  stripeTrialDays_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tenantDomain_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  tenantName?: InputMaybe<Scalars["String"]["input"]>;
  tenantName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  tenantName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  tenantName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  tenantName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  termsOfServiceLink_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  upgradeModalBody_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<TenantUsersAggregateInput>;
  /** Return Tenants where all of the related TenantUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where none of the related TenantUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where one of the related TenantUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where some of the related TenantUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Tenants where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Tenants where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Tenants where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
  webhook?: InputMaybe<WebhookWhere>;
  webhookAggregate?: InputMaybe<TenantWebhookAggregateInput>;
  webhookConnection?: InputMaybe<TenantWebhookConnectionWhere>;
  webhookConnection_NOT?: InputMaybe<TenantWebhookConnectionWhere>;
  webhook_NOT?: InputMaybe<WebhookWhere>;
};

export type TenantsConnection = {
  __typename?: "TenantsConnection";
  edges: Array<TenantEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Testing = {
  __typename?: "Testing";
  anotherRelationshipField?: Maybe<AnotherRelationshipField>;
  anotherRelationshipFieldAggregate?: Maybe<TestingAnotherRelationshipFieldAnotherRelationshipFieldAggregationSelection>;
  anotherRelationshipFieldConnection: TestingAnotherRelationshipFieldConnection;
  fieldDate?: Maybe<Scalars["Date"]["output"]>;
  fieldDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  relationshipField?: Maybe<RelationshipField>;
  relationshipFieldAggregate?: Maybe<TestingRelationshipFieldRelationshipFieldAggregationSelection>;
  relationshipFieldConnection: TestingRelationshipFieldConnection;
};

export type TestingAnotherRelationshipFieldArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AnotherRelationshipFieldOptions>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type TestingAnotherRelationshipFieldAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type TestingAnotherRelationshipFieldConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TestingAnotherRelationshipFieldConnectionSort>>;
  where?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
};

export type TestingRelationshipFieldArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<RelationshipFieldOptions>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type TestingRelationshipFieldAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<RelationshipFieldWhere>;
};

export type TestingRelationshipFieldConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TestingRelationshipFieldConnectionSort>>;
  where?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
};

export type TestingAggregateSelection = {
  __typename?: "TestingAggregateSelection";
  count: Scalars["Int"]["output"];
  fieldDateTime: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type TestingAnotherRelationshipFieldAggregateInput = {
  AND?: InputMaybe<Array<TestingAnotherRelationshipFieldAggregateInput>>;
  NOT?: InputMaybe<TestingAnotherRelationshipFieldAggregateInput>;
  OR?: InputMaybe<Array<TestingAnotherRelationshipFieldAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<RelationEdgePropertiesAggregationWhereInput>;
  node?: InputMaybe<TestingAnotherRelationshipFieldNodeAggregationWhereInput>;
};

export type TestingAnotherRelationshipFieldAnotherRelationshipFieldAggregationSelection = {
  __typename?: "TestingAnotherRelationshipFieldAnotherRelationshipFieldAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TestingAnotherRelationshipFieldAnotherRelationshipFieldEdgeAggregateSelection>;
  node?: Maybe<TestingAnotherRelationshipFieldAnotherRelationshipFieldNodeAggregateSelection>;
};

export type TestingAnotherRelationshipFieldAnotherRelationshipFieldEdgeAggregateSelection = {
  __typename?: "TestingAnotherRelationshipFieldAnotherRelationshipFieldEdgeAggregateSelection";
  anotherRelationshipFieldProperty: StringAggregateSelection;
  someRelationshipFieldProperty: StringAggregateSelection;
};

export type TestingAnotherRelationshipFieldAnotherRelationshipFieldNodeAggregateSelection = {
  __typename?: "TestingAnotherRelationshipFieldAnotherRelationshipFieldNodeAggregateSelection";
  anotherProperty: StringAggregateSelection;
  someProperty: StringAggregateSelection;
};

export type TestingAnotherRelationshipFieldConnectFieldInput = {
  connect?: InputMaybe<AnotherRelationshipFieldConnectInput>;
  edge?: InputMaybe<RelationEdgePropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AnotherRelationshipFieldConnectWhere>;
};

export type TestingAnotherRelationshipFieldConnection = {
  __typename?: "TestingAnotherRelationshipFieldConnection";
  edges: Array<TestingAnotherRelationshipFieldRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TestingAnotherRelationshipFieldConnectionSort = {
  edge?: InputMaybe<RelationEdgePropertiesSort>;
  node?: InputMaybe<AnotherRelationshipFieldSort>;
};

export type TestingAnotherRelationshipFieldConnectionWhere = {
  AND?: InputMaybe<Array<TestingAnotherRelationshipFieldConnectionWhere>>;
  NOT?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
  OR?: InputMaybe<Array<TestingAnotherRelationshipFieldConnectionWhere>>;
  edge?: InputMaybe<RelationEdgePropertiesWhere>;
  node?: InputMaybe<AnotherRelationshipFieldWhere>;
};

export type TestingAnotherRelationshipFieldCreateFieldInput = {
  edge?: InputMaybe<RelationEdgePropertiesCreateInput>;
  node: AnotherRelationshipFieldCreateInput;
};

export type TestingAnotherRelationshipFieldDeleteFieldInput = {
  delete?: InputMaybe<AnotherRelationshipFieldDeleteInput>;
  where?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
};

export type TestingAnotherRelationshipFieldDisconnectFieldInput = {
  disconnect?: InputMaybe<AnotherRelationshipFieldDisconnectInput>;
  where?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
};

export type TestingAnotherRelationshipFieldFieldInput = {
  connect?: InputMaybe<TestingAnotherRelationshipFieldConnectFieldInput>;
  create?: InputMaybe<TestingAnotherRelationshipFieldCreateFieldInput>;
};

export type TestingAnotherRelationshipFieldNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TestingAnotherRelationshipFieldNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TestingAnotherRelationshipFieldNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TestingAnotherRelationshipFieldNodeAggregationWhereInput>>;
  anotherProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TestingAnotherRelationshipFieldRelationship = {
  __typename?: "TestingAnotherRelationshipFieldRelationship";
  cursor: Scalars["String"]["output"];
  node: AnotherRelationshipField;
  properties: RelationEdgeProperties;
};

export type TestingAnotherRelationshipFieldUpdateConnectionInput = {
  edge?: InputMaybe<RelationEdgePropertiesUpdateInput>;
  node?: InputMaybe<AnotherRelationshipFieldUpdateInput>;
};

export type TestingAnotherRelationshipFieldUpdateFieldInput = {
  connect?: InputMaybe<TestingAnotherRelationshipFieldConnectFieldInput>;
  create?: InputMaybe<TestingAnotherRelationshipFieldCreateFieldInput>;
  delete?: InputMaybe<TestingAnotherRelationshipFieldDeleteFieldInput>;
  disconnect?: InputMaybe<TestingAnotherRelationshipFieldDisconnectFieldInput>;
  update?: InputMaybe<TestingAnotherRelationshipFieldUpdateConnectionInput>;
  where?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
};

export type TestingConnectInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldConnectFieldInput>;
  relationshipField?: InputMaybe<TestingRelationshipFieldConnectFieldInput>;
};

export type TestingConnectWhere = {
  node: TestingWhere;
};

export type TestingCreateInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldFieldInput>;
  fieldDate?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDateTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  relationshipField?: InputMaybe<TestingRelationshipFieldFieldInput>;
};

export type TestingDeleteInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldDeleteFieldInput>;
  relationshipField?: InputMaybe<TestingRelationshipFieldDeleteFieldInput>;
};

export type TestingDisconnectInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldDisconnectFieldInput>;
  relationshipField?: InputMaybe<TestingRelationshipFieldDisconnectFieldInput>;
};

export type TestingEdge = {
  __typename?: "TestingEdge";
  cursor: Scalars["String"]["output"];
  node: Testing;
};

export type TestingOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TestingSort objects to sort Testings by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TestingSort>>;
};

export type TestingRelationInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldCreateFieldInput>;
  relationshipField?: InputMaybe<TestingRelationshipFieldCreateFieldInput>;
};

export type TestingRelationshipFieldAggregateInput = {
  AND?: InputMaybe<Array<TestingRelationshipFieldAggregateInput>>;
  NOT?: InputMaybe<TestingRelationshipFieldAggregateInput>;
  OR?: InputMaybe<Array<TestingRelationshipFieldAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<TestingRelationshipFieldNodeAggregationWhereInput>;
};

export type TestingRelationshipFieldConnectFieldInput = {
  connect?: InputMaybe<RelationshipFieldConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<RelationshipFieldConnectWhere>;
};

export type TestingRelationshipFieldConnection = {
  __typename?: "TestingRelationshipFieldConnection";
  edges: Array<TestingRelationshipFieldRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TestingRelationshipFieldConnectionSort = {
  node?: InputMaybe<RelationshipFieldSort>;
};

export type TestingRelationshipFieldConnectionWhere = {
  AND?: InputMaybe<Array<TestingRelationshipFieldConnectionWhere>>;
  NOT?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
  OR?: InputMaybe<Array<TestingRelationshipFieldConnectionWhere>>;
  node?: InputMaybe<RelationshipFieldWhere>;
};

export type TestingRelationshipFieldCreateFieldInput = {
  node: RelationshipFieldCreateInput;
};

export type TestingRelationshipFieldDeleteFieldInput = {
  delete?: InputMaybe<RelationshipFieldDeleteInput>;
  where?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
};

export type TestingRelationshipFieldDisconnectFieldInput = {
  disconnect?: InputMaybe<RelationshipFieldDisconnectInput>;
  where?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
};

export type TestingRelationshipFieldFieldInput = {
  connect?: InputMaybe<TestingRelationshipFieldConnectFieldInput>;
  create?: InputMaybe<TestingRelationshipFieldCreateFieldInput>;
};

export type TestingRelationshipFieldNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TestingRelationshipFieldNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TestingRelationshipFieldNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TestingRelationshipFieldNodeAggregationWhereInput>>;
  anotherProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  anotherProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  anotherProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  someProperty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  someProperty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TestingRelationshipFieldRelationship = {
  __typename?: "TestingRelationshipFieldRelationship";
  cursor: Scalars["String"]["output"];
  node: RelationshipField;
};

export type TestingRelationshipFieldRelationshipFieldAggregationSelection = {
  __typename?: "TestingRelationshipFieldRelationshipFieldAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<TestingRelationshipFieldRelationshipFieldNodeAggregateSelection>;
};

export type TestingRelationshipFieldRelationshipFieldNodeAggregateSelection = {
  __typename?: "TestingRelationshipFieldRelationshipFieldNodeAggregateSelection";
  anotherProperty: StringAggregateSelection;
  someProperty: StringAggregateSelection;
};

export type TestingRelationshipFieldUpdateConnectionInput = {
  node?: InputMaybe<RelationshipFieldUpdateInput>;
};

export type TestingRelationshipFieldUpdateFieldInput = {
  connect?: InputMaybe<TestingRelationshipFieldConnectFieldInput>;
  create?: InputMaybe<TestingRelationshipFieldCreateFieldInput>;
  delete?: InputMaybe<TestingRelationshipFieldDeleteFieldInput>;
  disconnect?: InputMaybe<TestingRelationshipFieldDisconnectFieldInput>;
  update?: InputMaybe<TestingRelationshipFieldUpdateConnectionInput>;
  where?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
};

/** Fields to sort Testings by. The order in which sorts are applied is not guaranteed when specifying many fields in one TestingSort object. */
export type TestingSort = {
  fieldDate?: InputMaybe<SortDirection>;
  fieldDateTime?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
};

export type TestingUpdateInput = {
  anotherRelationshipField?: InputMaybe<TestingAnotherRelationshipFieldUpdateFieldInput>;
  fieldDate?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDateTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  relationshipField?: InputMaybe<TestingRelationshipFieldUpdateFieldInput>;
};

export type TestingWhere = {
  AND?: InputMaybe<Array<TestingWhere>>;
  NOT?: InputMaybe<TestingWhere>;
  OR?: InputMaybe<Array<TestingWhere>>;
  anotherRelationshipField?: InputMaybe<AnotherRelationshipFieldWhere>;
  anotherRelationshipFieldAggregate?: InputMaybe<TestingAnotherRelationshipFieldAggregateInput>;
  anotherRelationshipFieldConnection?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
  anotherRelationshipFieldConnection_NOT?: InputMaybe<TestingAnotherRelationshipFieldConnectionWhere>;
  anotherRelationshipField_NOT?: InputMaybe<AnotherRelationshipFieldWhere>;
  fieldDate?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDateTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  fieldDateTime_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDateTime_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fieldDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  fieldDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  fieldDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  relationshipField?: InputMaybe<RelationshipFieldWhere>;
  relationshipFieldAggregate?: InputMaybe<TestingRelationshipFieldAggregateInput>;
  relationshipFieldConnection?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
  relationshipFieldConnection_NOT?: InputMaybe<TestingRelationshipFieldConnectionWhere>;
  relationshipField_NOT?: InputMaybe<RelationshipFieldWhere>;
};

export type TestingsConnection = {
  __typename?: "TestingsConnection";
  edges: Array<TestingEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Todo = {
  __typename?: "Todo";
  achievements?: Maybe<Array<Maybe<Achievements>>>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  completed: Scalars["Boolean"]["output"];
  completed_date?: Maybe<Scalars["DateTime"]["output"]>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  date?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  feedback?: Maybe<Feedback>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  isArchived?: Maybe<Scalars["Boolean"]["output"]>;
  isPartOfJourney?: Maybe<Scalars["Boolean"]["output"]>;
  isRecurring?: Maybe<Scalars["Boolean"]["output"]>;
  journeyId?: Maybe<Scalars["ID"]["output"]>;
  journeysHasTask: Array<Journey>;
  journeysHasTaskAggregate?: Maybe<TodoJourneyJourneysHasTaskAggregationSelection>;
  journeysHasTaskConnection: TodoJourneysHasTaskConnection;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  name: Scalars["String"]["output"];
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  pointsReceived?: Maybe<Scalars["Int"]["output"]>;
  preferences?: Maybe<Preferences>;
  priority?: Maybe<Scalars["String"]["output"]>;
  progress?: Maybe<Progress>;
  recurringDaily?: Maybe<Scalars["Boolean"]["output"]>;
  recurringDayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  recurringDayOfWeek?: Maybe<Scalars["String"]["output"]>;
  recurringFrequency?: Maybe<Scalars["String"]["output"]>;
  recurringTime?: Maybe<Scalars["String"]["output"]>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  todos: Array<Todo>;
  user: User;
  usersHasTodo: Array<User>;
  usersHasTodoAggregate?: Maybe<TodoUserUsersHasTodoAggregationSelection>;
  usersHasTodoConnection: TodoUsersHasTodoConnection;
};

export type TodoJourneysHasTaskArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TodoJourneysHasTaskConnectionSort>>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoUsersHasTodoArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TodoUsersHasTodoConnectionSort>>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoAggregateSelection = {
  __typename?: "TodoAggregateSelection";
  completed_date: DateTimeAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type TodoConnectInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
};

export type TodoConnectOrCreateInput = {
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoConnectOrCreateFieldInput>>;
};

export type TodoConnectWhere = {
  node: TodoWhere;
};

export type TodoCreateInput = {
  completed: Scalars["Boolean"]["input"];
  completed_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTask?: InputMaybe<TodoJourneysHasTaskFieldInput>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name: Scalars["String"]["input"];
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodo?: InputMaybe<TodoUsersHasTodoFieldInput>;
};

export type TodoDeleteInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskDeleteFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoDeleteFieldInput>>;
};

export type TodoDisconnectInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskDisconnectFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoDisconnectFieldInput>>;
};

export type TodoEdge = {
  __typename?: "TodoEdge";
  cursor: Scalars["String"]["output"];
  node: Todo;
};

export type TodoJourneyJourneysHasTaskAggregationSelection = {
  __typename?: "TodoJourneyJourneysHasTaskAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TodoJourneyJourneysHasTaskEdgeAggregateSelection>;
  node?: Maybe<TodoJourneyJourneysHasTaskNodeAggregateSelection>;
};

export type TodoJourneyJourneysHasTaskEdgeAggregateSelection = {
  __typename?: "TodoJourneyJourneysHasTaskEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type TodoJourneyJourneysHasTaskNodeAggregateSelection = {
  __typename?: "TodoJourneyJourneysHasTaskNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type TodoJourneysHasTaskAggregateInput = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskAggregateInput>>;
  NOT?: InputMaybe<TodoJourneysHasTaskAggregateInput>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  node?: InputMaybe<TodoJourneysHasTaskNodeAggregationWhereInput>;
};

export type TodoJourneysHasTaskConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type TodoJourneysHasTaskConnection = {
  __typename?: "TodoJourneysHasTaskConnection";
  edges: Array<TodoJourneysHasTaskRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TodoJourneysHasTaskConnectionSort = {
  edge?: InputMaybe<HasTaskPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type TodoJourneysHasTaskConnectionWhere = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskConnectionWhere>>;
  NOT?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskConnectionWhere>>;
  edge?: InputMaybe<HasTaskPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskCreateFieldInput = {
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type TodoJourneysHasTaskDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoJourneysHasTaskDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoJourneysHasTaskFieldInput = {
  connect?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  create?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
};

export type TodoJourneysHasTaskNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TodoJourneysHasTaskNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TodoJourneysHasTaskRelationship = {
  __typename?: "TodoJourneysHasTaskRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasTaskProperties;
};

export type TodoJourneysHasTaskUpdateConnectionInput = {
  edge?: InputMaybe<HasTaskPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type TodoJourneysHasTaskUpdateFieldInput = {
  connect?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  create?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
  delete?: InputMaybe<Array<TodoJourneysHasTaskDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TodoJourneysHasTaskDisconnectFieldInput>>;
  update?: InputMaybe<TodoJourneysHasTaskUpdateConnectionInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TodoSort objects to sort Todos by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TodoSort>>;
};

export type TodoRelationInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
};

/** Fields to sort Todos by. The order in which sorts are applied is not guaranteed when specifying many fields in one TodoSort object. */
export type TodoSort = {
  completed?: InputMaybe<SortDirection>;
  completed_date?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPartOfJourney?: InputMaybe<SortDirection>;
  isRecurring?: InputMaybe<SortDirection>;
  journeyId?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  pointsReceived?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  recurringDaily?: InputMaybe<SortDirection>;
  recurringDayOfMonth?: InputMaybe<SortDirection>;
  recurringDayOfWeek?: InputMaybe<SortDirection>;
  recurringFrequency?: InputMaybe<SortDirection>;
  recurringTime?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
  timezone?: InputMaybe<SortDirection>;
};

export type TodoUpdateInput = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskUpdateFieldInput>>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoUpdateFieldInput>>;
};

export type TodoUserUsersHasTodoAggregationSelection = {
  __typename?: "TodoUserUsersHasTodoAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TodoUserUsersHasTodoEdgeAggregateSelection>;
  node?: Maybe<TodoUserUsersHasTodoNodeAggregateSelection>;
};

export type TodoUserUsersHasTodoEdgeAggregateSelection = {
  __typename?: "TodoUserUsersHasTodoEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type TodoUserUsersHasTodoNodeAggregateSelection = {
  __typename?: "TodoUserUsersHasTodoNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type TodoUsersHasTodoAggregateInput = {
  AND?: InputMaybe<Array<TodoUsersHasTodoAggregateInput>>;
  NOT?: InputMaybe<TodoUsersHasTodoAggregateInput>;
  OR?: InputMaybe<Array<TodoUsersHasTodoAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  node?: InputMaybe<TodoUsersHasTodoNodeAggregationWhereInput>;
};

export type TodoUsersHasTodoConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TodoUsersHasTodoConnectOrCreateFieldInput = {
  onCreate: TodoUsersHasTodoConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type TodoUsersHasTodoConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  node: UserOnCreateInput;
};

export type TodoUsersHasTodoConnection = {
  __typename?: "TodoUsersHasTodoConnection";
  edges: Array<TodoUsersHasTodoRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TodoUsersHasTodoConnectionSort = {
  edge?: InputMaybe<HasTodoPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type TodoUsersHasTodoConnectionWhere = {
  AND?: InputMaybe<Array<TodoUsersHasTodoConnectionWhere>>;
  NOT?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  OR?: InputMaybe<Array<TodoUsersHasTodoConnectionWhere>>;
  edge?: InputMaybe<HasTodoPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoCreateFieldInput = {
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  node: UserCreateInput;
};

export type TodoUsersHasTodoDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoUsersHasTodoDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoUsersHasTodoFieldInput = {
  connect?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TodoUsersHasTodoConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
};

export type TodoUsersHasTodoNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TodoUsersHasTodoNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TodoUsersHasTodoNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TodoUsersHasTodoNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TodoUsersHasTodoRelationship = {
  __typename?: "TodoUsersHasTodoRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasTodoProperties;
};

export type TodoUsersHasTodoUpdateConnectionInput = {
  edge?: InputMaybe<HasTodoPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type TodoUsersHasTodoUpdateFieldInput = {
  connect?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TodoUsersHasTodoConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
  delete?: InputMaybe<Array<TodoUsersHasTodoDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TodoUsersHasTodoDisconnectFieldInput>>;
  update?: InputMaybe<TodoUsersHasTodoUpdateConnectionInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoWhere = {
  AND?: InputMaybe<Array<TodoWhere>>;
  NOT?: InputMaybe<TodoWhere>;
  OR?: InputMaybe<Array<TodoWhere>>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  completed_date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  journeyId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTaskAggregate?: InputMaybe<TodoJourneysHasTaskAggregateInput>;
  /** Return Todos where all of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_ALL?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where none of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_NONE?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where one of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_SINGLE?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where some of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_SOME?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where all of the related Journeys match this filter */
  journeysHasTask_ALL?: InputMaybe<JourneyWhere>;
  /** Return Todos where none of the related Journeys match this filter */
  journeysHasTask_NONE?: InputMaybe<JourneyWhere>;
  /** Return Todos where one of the related Journeys match this filter */
  journeysHasTask_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Todos where some of the related Journeys match this filter */
  journeysHasTask_SOME?: InputMaybe<JourneyWhere>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  pointsReceived_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  recurringDayOfMonth_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringDayOfWeek_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringFrequency_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringTime_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  timezone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timezone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timezone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodoAggregate?: InputMaybe<TodoUsersHasTodoAggregateInput>;
  /** Return Todos where all of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_ALL?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where none of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_NONE?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where one of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_SINGLE?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where some of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_SOME?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where all of the related Users match this filter */
  usersHasTodo_ALL?: InputMaybe<UserWhere>;
  /** Return Todos where none of the related Users match this filter */
  usersHasTodo_NONE?: InputMaybe<UserWhere>;
  /** Return Todos where one of the related Users match this filter */
  usersHasTodo_SINGLE?: InputMaybe<UserWhere>;
  /** Return Todos where some of the related Users match this filter */
  usersHasTodo_SOME?: InputMaybe<UserWhere>;
};

export type TodosConnection = {
  __typename?: "TodosConnection";
  edges: Array<TodoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Topic = {
  __typename?: "Topic";
  chapters: Array<Chapter>;
  chaptersAggregate?: Maybe<TopicChapterChaptersAggregationSelection>;
  chaptersConnection: TopicChaptersConnection;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  usersEnrolledIn: Array<User>;
  usersEnrolledInAggregate?: Maybe<TopicUserUsersEnrolledInAggregationSelection>;
  usersEnrolledInConnection: TopicUsersEnrolledInConnection;
  usersHasKnowledge: Array<User>;
  usersHasKnowledgeAggregate?: Maybe<TopicUserUsersHasKnowledgeAggregationSelection>;
  usersHasKnowledgeConnection: TopicUsersHasKnowledgeConnection;
};

export type TopicChaptersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type TopicChaptersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChapterWhere>;
};

export type TopicChaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TopicChaptersConnectionSort>>;
  where?: InputMaybe<TopicChaptersConnectionWhere>;
};

export type TopicUsersEnrolledInArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TopicUsersEnrolledInAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TopicUsersEnrolledInConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TopicUsersEnrolledInConnectionSort>>;
  where?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
};

export type TopicUsersHasKnowledgeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TopicUsersHasKnowledgeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TopicUsersHasKnowledgeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TopicUsersHasKnowledgeConnectionSort>>;
  where?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
};

export type TopicAggregateSelection = {
  __typename?: "TopicAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type TopicChapterChaptersAggregationSelection = {
  __typename?: "TopicChapterChaptersAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TopicChapterChaptersEdgeAggregateSelection>;
  node?: Maybe<TopicChapterChaptersNodeAggregateSelection>;
};

export type TopicChapterChaptersEdgeAggregateSelection = {
  __typename?: "TopicChapterChaptersEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type TopicChapterChaptersNodeAggregateSelection = {
  __typename?: "TopicChapterChaptersNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type TopicChaptersAggregateInput = {
  AND?: InputMaybe<Array<TopicChaptersAggregateInput>>;
  NOT?: InputMaybe<TopicChaptersAggregateInput>;
  OR?: InputMaybe<Array<TopicChaptersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChapterPropertiesAggregationWhereInput>;
  node?: InputMaybe<TopicChaptersNodeAggregationWhereInput>;
};

export type TopicChaptersConnectFieldInput = {
  connect?: InputMaybe<Array<ChapterConnectInput>>;
  edge: HasChapterPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChapterConnectWhere>;
};

export type TopicChaptersConnection = {
  __typename?: "TopicChaptersConnection";
  edges: Array<TopicChaptersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TopicChaptersConnectionSort = {
  edge?: InputMaybe<HasChapterPropertiesSort>;
  node?: InputMaybe<ChapterSort>;
};

export type TopicChaptersConnectionWhere = {
  AND?: InputMaybe<Array<TopicChaptersConnectionWhere>>;
  NOT?: InputMaybe<TopicChaptersConnectionWhere>;
  OR?: InputMaybe<Array<TopicChaptersConnectionWhere>>;
  edge?: InputMaybe<HasChapterPropertiesWhere>;
  node?: InputMaybe<ChapterWhere>;
};

export type TopicChaptersCreateFieldInput = {
  edge: HasChapterPropertiesCreateInput;
  node: ChapterCreateInput;
};

export type TopicChaptersDeleteFieldInput = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<TopicChaptersConnectionWhere>;
};

export type TopicChaptersDisconnectFieldInput = {
  disconnect?: InputMaybe<ChapterDisconnectInput>;
  where?: InputMaybe<TopicChaptersConnectionWhere>;
};

export type TopicChaptersFieldInput = {
  connect?: InputMaybe<Array<TopicChaptersConnectFieldInput>>;
  create?: InputMaybe<Array<TopicChaptersCreateFieldInput>>;
};

export type TopicChaptersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TopicChaptersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TopicChaptersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TopicChaptersNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicChaptersRelationship = {
  __typename?: "TopicChaptersRelationship";
  cursor: Scalars["String"]["output"];
  node: Chapter;
  properties: HasChapterProperties;
};

export type TopicChaptersUpdateConnectionInput = {
  edge?: InputMaybe<HasChapterPropertiesUpdateInput>;
  node?: InputMaybe<ChapterUpdateInput>;
};

export type TopicChaptersUpdateFieldInput = {
  connect?: InputMaybe<Array<TopicChaptersConnectFieldInput>>;
  create?: InputMaybe<Array<TopicChaptersCreateFieldInput>>;
  delete?: InputMaybe<Array<TopicChaptersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TopicChaptersDisconnectFieldInput>>;
  update?: InputMaybe<TopicChaptersUpdateConnectionInput>;
  where?: InputMaybe<TopicChaptersConnectionWhere>;
};

export type TopicConnectInput = {
  chapters?: InputMaybe<Array<TopicChaptersConnectFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInConnectFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeConnectFieldInput>>;
};

export type TopicConnectOrCreateInput = {
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInConnectOrCreateFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeConnectOrCreateFieldInput>>;
};

export type TopicConnectWhere = {
  node: TopicWhere;
};

export type TopicCreateInput = {
  chapters?: InputMaybe<TopicChaptersFieldInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  usersEnrolledIn?: InputMaybe<TopicUsersEnrolledInFieldInput>;
  usersHasKnowledge?: InputMaybe<TopicUsersHasKnowledgeFieldInput>;
};

export type TopicDeleteInput = {
  chapters?: InputMaybe<Array<TopicChaptersDeleteFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInDeleteFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeDeleteFieldInput>>;
};

export type TopicDisconnectInput = {
  chapters?: InputMaybe<Array<TopicChaptersDisconnectFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInDisconnectFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeDisconnectFieldInput>>;
};

export type TopicEdge = {
  __typename?: "TopicEdge";
  cursor: Scalars["String"]["output"];
  node: Topic;
};

export type TopicOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TopicSort objects to sort Topics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TopicSort>>;
};

export type TopicRelationInput = {
  chapters?: InputMaybe<Array<TopicChaptersCreateFieldInput>>;
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInCreateFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeCreateFieldInput>>;
};

/** Fields to sort Topics by. The order in which sorts are applied is not guaranteed when specifying many fields in one TopicSort object. */
export type TopicSort = {
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type TopicUpdateInput = {
  chapters?: InputMaybe<Array<TopicChaptersUpdateFieldInput>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  usersEnrolledIn?: InputMaybe<Array<TopicUsersEnrolledInUpdateFieldInput>>;
  usersHasKnowledge?: InputMaybe<Array<TopicUsersHasKnowledgeUpdateFieldInput>>;
};

export type TopicUserUsersEnrolledInAggregationSelection = {
  __typename?: "TopicUserUsersEnrolledInAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TopicUserUsersEnrolledInEdgeAggregateSelection>;
  node?: Maybe<TopicUserUsersEnrolledInNodeAggregateSelection>;
};

export type TopicUserUsersEnrolledInEdgeAggregateSelection = {
  __typename?: "TopicUserUsersEnrolledInEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type TopicUserUsersEnrolledInNodeAggregateSelection = {
  __typename?: "TopicUserUsersEnrolledInNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type TopicUserUsersHasKnowledgeAggregationSelection = {
  __typename?: "TopicUserUsersHasKnowledgeAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TopicUserUsersHasKnowledgeEdgeAggregateSelection>;
  node?: Maybe<TopicUserUsersHasKnowledgeNodeAggregateSelection>;
};

export type TopicUserUsersHasKnowledgeEdgeAggregateSelection = {
  __typename?: "TopicUserUsersHasKnowledgeEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  total_mastery: FloatAggregateSelection;
};

export type TopicUserUsersHasKnowledgeNodeAggregateSelection = {
  __typename?: "TopicUserUsersHasKnowledgeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type TopicUsersEnrolledInAggregateInput = {
  AND?: InputMaybe<Array<TopicUsersEnrolledInAggregateInput>>;
  NOT?: InputMaybe<TopicUsersEnrolledInAggregateInput>;
  OR?: InputMaybe<Array<TopicUsersEnrolledInAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInPropertiesAggregationWhereInput>;
  node?: InputMaybe<TopicUsersEnrolledInNodeAggregationWhereInput>;
};

export type TopicUsersEnrolledInConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: EnrolledInPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TopicUsersEnrolledInConnectOrCreateFieldInput = {
  onCreate: TopicUsersEnrolledInConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type TopicUsersEnrolledInConnectOrCreateFieldInputOnCreate = {
  edge: EnrolledInPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type TopicUsersEnrolledInConnection = {
  __typename?: "TopicUsersEnrolledInConnection";
  edges: Array<TopicUsersEnrolledInRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TopicUsersEnrolledInConnectionSort = {
  edge?: InputMaybe<EnrolledInPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type TopicUsersEnrolledInConnectionWhere = {
  AND?: InputMaybe<Array<TopicUsersEnrolledInConnectionWhere>>;
  NOT?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
  OR?: InputMaybe<Array<TopicUsersEnrolledInConnectionWhere>>;
  edge?: InputMaybe<EnrolledInPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type TopicUsersEnrolledInCreateFieldInput = {
  edge: EnrolledInPropertiesCreateInput;
  node: UserCreateInput;
};

export type TopicUsersEnrolledInDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
};

export type TopicUsersEnrolledInDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
};

export type TopicUsersEnrolledInFieldInput = {
  connect?: InputMaybe<Array<TopicUsersEnrolledInConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TopicUsersEnrolledInConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TopicUsersEnrolledInCreateFieldInput>>;
};

export type TopicUsersEnrolledInNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TopicUsersEnrolledInNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TopicUsersEnrolledInNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TopicUsersEnrolledInNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicUsersEnrolledInRelationship = {
  __typename?: "TopicUsersEnrolledInRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: EnrolledInProperties;
};

export type TopicUsersEnrolledInUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type TopicUsersEnrolledInUpdateFieldInput = {
  connect?: InputMaybe<Array<TopicUsersEnrolledInConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TopicUsersEnrolledInConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TopicUsersEnrolledInCreateFieldInput>>;
  delete?: InputMaybe<Array<TopicUsersEnrolledInDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TopicUsersEnrolledInDisconnectFieldInput>>;
  update?: InputMaybe<TopicUsersEnrolledInUpdateConnectionInput>;
  where?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
};

export type TopicUsersHasKnowledgeAggregateInput = {
  AND?: InputMaybe<Array<TopicUsersHasKnowledgeAggregateInput>>;
  NOT?: InputMaybe<TopicUsersHasKnowledgeAggregateInput>;
  OR?: InputMaybe<Array<TopicUsersHasKnowledgeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasKnowledgePropertiesAggregationWhereInput>;
  node?: InputMaybe<TopicUsersHasKnowledgeNodeAggregationWhereInput>;
};

export type TopicUsersHasKnowledgeConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasKnowledgePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TopicUsersHasKnowledgeConnectOrCreateFieldInput = {
  onCreate: TopicUsersHasKnowledgeConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type TopicUsersHasKnowledgeConnectOrCreateFieldInputOnCreate = {
  edge: HasKnowledgePropertiesCreateInput;
  node: UserOnCreateInput;
};

export type TopicUsersHasKnowledgeConnection = {
  __typename?: "TopicUsersHasKnowledgeConnection";
  edges: Array<TopicUsersHasKnowledgeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TopicUsersHasKnowledgeConnectionSort = {
  edge?: InputMaybe<HasKnowledgePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type TopicUsersHasKnowledgeConnectionWhere = {
  AND?: InputMaybe<Array<TopicUsersHasKnowledgeConnectionWhere>>;
  NOT?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
  OR?: InputMaybe<Array<TopicUsersHasKnowledgeConnectionWhere>>;
  edge?: InputMaybe<HasKnowledgePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type TopicUsersHasKnowledgeCreateFieldInput = {
  edge: HasKnowledgePropertiesCreateInput;
  node: UserCreateInput;
};

export type TopicUsersHasKnowledgeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
};

export type TopicUsersHasKnowledgeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
};

export type TopicUsersHasKnowledgeFieldInput = {
  connect?: InputMaybe<Array<TopicUsersHasKnowledgeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TopicUsersHasKnowledgeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TopicUsersHasKnowledgeCreateFieldInput>>;
};

export type TopicUsersHasKnowledgeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TopicUsersHasKnowledgeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TopicUsersHasKnowledgeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TopicUsersHasKnowledgeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TopicUsersHasKnowledgeRelationship = {
  __typename?: "TopicUsersHasKnowledgeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasKnowledgeProperties;
};

export type TopicUsersHasKnowledgeUpdateConnectionInput = {
  edge?: InputMaybe<HasKnowledgePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type TopicUsersHasKnowledgeUpdateFieldInput = {
  connect?: InputMaybe<Array<TopicUsersHasKnowledgeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<TopicUsersHasKnowledgeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<TopicUsersHasKnowledgeCreateFieldInput>>;
  delete?: InputMaybe<Array<TopicUsersHasKnowledgeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TopicUsersHasKnowledgeDisconnectFieldInput>>;
  update?: InputMaybe<TopicUsersHasKnowledgeUpdateConnectionInput>;
  where?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
};

export type TopicWhere = {
  AND?: InputMaybe<Array<TopicWhere>>;
  NOT?: InputMaybe<TopicWhere>;
  OR?: InputMaybe<Array<TopicWhere>>;
  chaptersAggregate?: InputMaybe<TopicChaptersAggregateInput>;
  /** Return Topics where all of the related TopicChaptersConnections match this filter */
  chaptersConnection_ALL?: InputMaybe<TopicChaptersConnectionWhere>;
  /** Return Topics where none of the related TopicChaptersConnections match this filter */
  chaptersConnection_NONE?: InputMaybe<TopicChaptersConnectionWhere>;
  /** Return Topics where one of the related TopicChaptersConnections match this filter */
  chaptersConnection_SINGLE?: InputMaybe<TopicChaptersConnectionWhere>;
  /** Return Topics where some of the related TopicChaptersConnections match this filter */
  chaptersConnection_SOME?: InputMaybe<TopicChaptersConnectionWhere>;
  /** Return Topics where all of the related Chapters match this filter */
  chapters_ALL?: InputMaybe<ChapterWhere>;
  /** Return Topics where none of the related Chapters match this filter */
  chapters_NONE?: InputMaybe<ChapterWhere>;
  /** Return Topics where one of the related Chapters match this filter */
  chapters_SINGLE?: InputMaybe<ChapterWhere>;
  /** Return Topics where some of the related Chapters match this filter */
  chapters_SOME?: InputMaybe<ChapterWhere>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersEnrolledInAggregate?: InputMaybe<TopicUsersEnrolledInAggregateInput>;
  /** Return Topics where all of the related TopicUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_ALL?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
  /** Return Topics where none of the related TopicUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_NONE?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
  /** Return Topics where one of the related TopicUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_SINGLE?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
  /** Return Topics where some of the related TopicUsersEnrolledInConnections match this filter */
  usersEnrolledInConnection_SOME?: InputMaybe<TopicUsersEnrolledInConnectionWhere>;
  /** Return Topics where all of the related Users match this filter */
  usersEnrolledIn_ALL?: InputMaybe<UserWhere>;
  /** Return Topics where none of the related Users match this filter */
  usersEnrolledIn_NONE?: InputMaybe<UserWhere>;
  /** Return Topics where one of the related Users match this filter */
  usersEnrolledIn_SINGLE?: InputMaybe<UserWhere>;
  /** Return Topics where some of the related Users match this filter */
  usersEnrolledIn_SOME?: InputMaybe<UserWhere>;
  usersHasKnowledgeAggregate?: InputMaybe<TopicUsersHasKnowledgeAggregateInput>;
  /** Return Topics where all of the related TopicUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_ALL?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
  /** Return Topics where none of the related TopicUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_NONE?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
  /** Return Topics where one of the related TopicUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_SINGLE?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
  /** Return Topics where some of the related TopicUsersHasKnowledgeConnections match this filter */
  usersHasKnowledgeConnection_SOME?: InputMaybe<TopicUsersHasKnowledgeConnectionWhere>;
  /** Return Topics where all of the related Users match this filter */
  usersHasKnowledge_ALL?: InputMaybe<UserWhere>;
  /** Return Topics where none of the related Users match this filter */
  usersHasKnowledge_NONE?: InputMaybe<UserWhere>;
  /** Return Topics where one of the related Users match this filter */
  usersHasKnowledge_SINGLE?: InputMaybe<UserWhere>;
  /** Return Topics where some of the related Users match this filter */
  usersHasKnowledge_SOME?: InputMaybe<UserWhere>;
};

export type TopicsConnection = {
  __typename?: "TopicsConnection";
  edges: Array<TopicEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TrackOverview = {
  __typename?: "TrackOverview";
  description?: Maybe<Scalars["String"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirementsTitle?: Maybe<Scalars["String"]["output"]>;
  prerequisites?: Maybe<Array<Maybe<Prerequisite>>>;
  skillRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type TrackOverviewAggregateSelection = {
  __typename?: "TrackOverviewAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
  otherRequirementsTitle: StringAggregateSelection;
};

export type TrackOverviewCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type TrackOverviewEdge = {
  __typename?: "TrackOverviewEdge";
  cursor: Scalars["String"]["output"];
  node: TrackOverview;
};

export type TrackOverviewOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TrackOverviewSort objects to sort TrackOverviews by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TrackOverviewSort>>;
};

/** Fields to sort TrackOverviews by. The order in which sorts are applied is not guaranteed when specifying many fields in one TrackOverviewSort object. */
export type TrackOverviewSort = {
  description?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otherRequirementsTitle?: InputMaybe<SortDirection>;
};

export type TrackOverviewUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements_POP?: InputMaybe<Scalars["Int"]["input"]>;
  otherRequirements_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements_POP?: InputMaybe<Scalars["Int"]["input"]>;
  skillRequirements_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type TrackOverviewWhere = {
  AND?: InputMaybe<Array<TrackOverviewWhere>>;
  NOT?: InputMaybe<TrackOverviewWhere>;
  OR?: InputMaybe<Array<TrackOverviewWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrackOverviewsConnection = {
  __typename?: "TrackOverviewsConnection";
  edges: Array<TrackOverviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Transaction = {
  __typename?: "Transaction";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export type TransactionAggregateSelection = {
  __typename?: "TransactionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  modified: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type TransactionByDate = {
  __typename?: "TransactionByDate";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export type TransactionByDateAggregateSelection = {
  __typename?: "TransactionByDateAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  modified: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type TransactionByDateCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  modified: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  transactionType: TransactionType;
};

export type TransactionByDateEdge = {
  __typename?: "TransactionByDateEdge";
  cursor: Scalars["String"]["output"];
  node: TransactionByDate;
};

export type TransactionByDateOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TransactionByDateSort objects to sort TransactionByDates by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TransactionByDateSort>>;
};

/** Fields to sort TransactionByDates by. The order in which sorts are applied is not guaranteed when specifying many fields in one TransactionByDateSort object. */
export type TransactionByDateSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  transactionType?: InputMaybe<SortDirection>;
};

export type TransactionByDateUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionByDateWhere = {
  AND?: InputMaybe<Array<TransactionByDateWhere>>;
  NOT?: InputMaybe<TransactionByDateWhere>;
  OR?: InputMaybe<Array<TransactionByDateWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  modified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  modified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_IN?: InputMaybe<Array<TransactionType>>;
};

export type TransactionByDatesConnection = {
  __typename?: "TransactionByDatesConnection";
  edges: Array<TransactionByDateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TransactionCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  modified: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  transactionType: TransactionType;
};

export type TransactionEdge = {
  __typename?: "TransactionEdge";
  cursor: Scalars["String"]["output"];
  node: Transaction;
};

export type TransactionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TransactionSort objects to sort Transactions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TransactionSort>>;
};

/** Fields to sort Transactions by. The order in which sorts are applied is not guaranteed when specifying many fields in one TransactionSort object. */
export type TransactionSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  transactionType?: InputMaybe<SortDirection>;
};

export enum TransactionType {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export type TransactionUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionWhere = {
  AND?: InputMaybe<Array<TransactionWhere>>;
  NOT?: InputMaybe<TransactionWhere>;
  OR?: InputMaybe<Array<TransactionWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  modified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  modified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_IN?: InputMaybe<Array<TransactionType>>;
};

export type TransactionsConnection = {
  __typename?: "TransactionsConnection";
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TranscriptSegment = {
  __typename?: "TranscriptSegment";
  duration: Scalars["Float"]["output"];
  start: Scalars["Float"]["output"];
  text: Scalars["String"]["output"];
};

export type TranscriptSegmentAggregateSelection = {
  __typename?: "TranscriptSegmentAggregateSelection";
  count: Scalars["Int"]["output"];
  duration: FloatAggregateSelection;
  start: FloatAggregateSelection;
  text: StringAggregateSelection;
};

export type TranscriptSegmentCreateInput = {
  duration: Scalars["Float"]["input"];
  start: Scalars["Float"]["input"];
  text: Scalars["String"]["input"];
};

export type TranscriptSegmentEdge = {
  __typename?: "TranscriptSegmentEdge";
  cursor: Scalars["String"]["output"];
  node: TranscriptSegment;
};

export type TranscriptSegmentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TranscriptSegmentSort objects to sort TranscriptSegments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TranscriptSegmentSort>>;
};

/** Fields to sort TranscriptSegments by. The order in which sorts are applied is not guaranteed when specifying many fields in one TranscriptSegmentSort object. */
export type TranscriptSegmentSort = {
  duration?: InputMaybe<SortDirection>;
  start?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type TranscriptSegmentUpdateInput = {
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  duration_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  duration_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  duration_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  duration_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  start?: InputMaybe<Scalars["Float"]["input"]>;
  start_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  start_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  start_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  start_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type TranscriptSegmentWhere = {
  AND?: InputMaybe<Array<TranscriptSegmentWhere>>;
  NOT?: InputMaybe<TranscriptSegmentWhere>;
  OR?: InputMaybe<Array<TranscriptSegmentWhere>>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  duration_GT?: InputMaybe<Scalars["Float"]["input"]>;
  duration_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  duration_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  duration_LT?: InputMaybe<Scalars["Float"]["input"]>;
  duration_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start?: InputMaybe<Scalars["Float"]["input"]>;
  start_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  start_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type TranscriptSegmentsConnection = {
  __typename?: "TranscriptSegmentsConnection";
  edges: Array<TranscriptSegmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/**
 * The edge properties for the following fields:
 * * User.undefinedFoodItems
 * * User.undefinedWaterIntakes
 * * FoodItem.usersundefined
 * * WaterIntake.usersundefined
 */
export type UndefinedProperties = {
  __typename?: "UndefinedProperties";
  created: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  quality: Scalars["BigInt"]["output"];
  strength: Scalars["BigInt"]["output"];
};

export type UndefinedPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<UndefinedPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<UndefinedPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UndefinedPropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  quality: Scalars["BigInt"]["input"];
  strength: Scalars["BigInt"]["input"];
};

export type UndefinedPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type UndefinedPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UndefinedPropertiesWhere = {
  AND?: InputMaybe<Array<UndefinedPropertiesWhere>>;
  NOT?: InputMaybe<UndefinedPropertiesWhere>;
  OR?: InputMaybe<Array<UndefinedPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UpdateAiTutorInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<ObjectiveInput>>>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAchievementsMutationResponse = {
  __typename?: "UpdateAchievementsMutationResponse";
  achievements: Array<Achievements>;
  info: UpdateInfo;
};

export type UpdateActiveTracksMutationResponse = {
  __typename?: "UpdateActiveTracksMutationResponse";
  activeTracks: Array<ActiveTrack>;
  info: UpdateInfo;
};

export type UpdateActivityAnalyticsDetailsMutationResponse = {
  __typename?: "UpdateActivityAnalyticsDetailsMutationResponse";
  activityAnalyticsDetails: Array<ActivityAnalyticsDetails>;
  info: UpdateInfo;
};

export type UpdateActivityAnalyticsMutationResponse = {
  __typename?: "UpdateActivityAnalyticsMutationResponse";
  activityAnalytics: Array<ActivityAnalytics>;
  info: UpdateInfo;
};

export type UpdateActivityAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateActivityAnalyticsResponsesMutationResponse";
  activityAnalyticsResponses: Array<ActivityAnalyticsResponse>;
  info: UpdateInfo;
};

export type UpdateActivityCompletionDetailsDataMutationResponse = {
  __typename?: "UpdateActivityCompletionDetailsDataMutationResponse";
  activityCompletionDetailsData: Array<ActivityCompletionDetailsData>;
  info: UpdateInfo;
};

export type UpdateActivityCompletionDetailsFullsMutationResponse = {
  __typename?: "UpdateActivityCompletionDetailsFullsMutationResponse";
  activityCompletionDetailsFulls: Array<ActivityCompletionDetailsFull>;
  info: UpdateInfo;
};

export type UpdateActivityCompletionDetailsMutationResponse = {
  __typename?: "UpdateActivityCompletionDetailsMutationResponse";
  activityCompletionDetails: Array<ActivityCompletionDetails>;
  info: UpdateInfo;
};

export type UpdateActivityCompletionDetailsObjectivesMutationResponse = {
  __typename?: "UpdateActivityCompletionDetailsObjectivesMutationResponse";
  activityCompletionDetailsObjectives: Array<ActivityCompletionDetailsObjectives>;
  info: UpdateInfo;
};

export type UpdateActivityCompletionDetailsResponsesMutationResponse = {
  __typename?: "UpdateActivityCompletionDetailsResponsesMutationResponse";
  activityCompletionDetailsResponses: Array<ActivityCompletionDetailsResponse>;
  info: UpdateInfo;
};

export type UpdateActivityInsightsMutationResponse = {
  __typename?: "UpdateActivityInsightsMutationResponse";
  activityInsights: Array<ActivityInsights>;
  info: UpdateInfo;
};

export type UpdateActivityInsightsResponsesMutationResponse = {
  __typename?: "UpdateActivityInsightsResponsesMutationResponse";
  activityInsightsResponses: Array<ActivityInsightsResponse>;
  info: UpdateInfo;
};

export type UpdateActivityProgressAnalyticsMutationResponse = {
  __typename?: "UpdateActivityProgressAnalyticsMutationResponse";
  activityProgressAnalytics: Array<ActivityProgressAnalytics>;
  info: UpdateInfo;
};

export type UpdateActivityProgressDetailsMutationResponse = {
  __typename?: "UpdateActivityProgressDetailsMutationResponse";
  activityProgressDetails: Array<ActivityProgressDetails>;
  info: UpdateInfo;
};

export type UpdateAddAssignmentResponsesMutationResponse = {
  __typename?: "UpdateAddAssignmentResponsesMutationResponse";
  addAssignmentResponses: Array<AddAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateAddContentModuleResponsesMutationResponse = {
  __typename?: "UpdateAddContentModuleResponsesMutationResponse";
  addContentModuleResponses: Array<AddContentModuleResponse>;
  info: UpdateInfo;
};

export type UpdateAiTutorAnalyticsMutationResponse = {
  __typename?: "UpdateAiTutorAnalyticsMutationResponse";
  aiTutorAnalytics: Array<AiTutorAnalytics>;
  info: UpdateInfo;
};

export type UpdateAiTutorAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateAiTutorAnalyticsResponsesMutationResponse";
  aiTutorAnalyticsResponses: Array<AiTutorAnalyticsResponse>;
  info: UpdateInfo;
};

export type UpdateAiTutorEvalCriteriaResponsesMutationResponse = {
  __typename?: "UpdateAiTutorEvalCriteriaResponsesMutationResponse";
  aiTutorEvalCriteriaResponses: Array<AiTutorEvalCriteriaResponse>;
  info: UpdateInfo;
};

export type UpdateAiTutorGeneratedResponsesMutationResponse = {
  __typename?: "UpdateAiTutorGeneratedResponsesMutationResponse";
  aiTutorGeneratedResponses: Array<AiTutorGeneratedResponse>;
  info: UpdateInfo;
};

export type UpdateAiTutorProgressAnalyticsMutationResponse = {
  __typename?: "UpdateAiTutorProgressAnalyticsMutationResponse";
  aiTutorProgressAnalytics: Array<AiTutorProgressAnalytics>;
  info: UpdateInfo;
};

export type UpdateAiTutorProgressDetailsMutationResponse = {
  __typename?: "UpdateAiTutorProgressDetailsMutationResponse";
  aiTutorProgressDetails: Array<AiTutorProgressDetails>;
  info: UpdateInfo;
};

export type UpdateAiTutorResponsesMutationResponse = {
  __typename?: "UpdateAiTutorResponsesMutationResponse";
  aiTutorResponses: Array<AiTutorResponse>;
  info: UpdateInfo;
};

export type UpdateAiTutorsMutationResponse = {
  __typename?: "UpdateAiTutorsMutationResponse";
  aiTutors: Array<AiTutor>;
  info: UpdateInfo;
};

export type UpdateAnotherRelationshipFieldsMutationResponse = {
  __typename?: "UpdateAnotherRelationshipFieldsMutationResponse";
  anotherRelationshipFields: Array<AnotherRelationshipField>;
  info: UpdateInfo;
};

export type UpdateAspectDetailsTypesMutationResponse = {
  __typename?: "UpdateAspectDetailsTypesMutationResponse";
  aspectDetailsTypes: Array<AspectDetailsType>;
  info: UpdateInfo;
};

export type UpdateAssessmentListResponseDataMutationResponse = {
  __typename?: "UpdateAssessmentListResponseDataMutationResponse";
  assessmentListResponseData: Array<AssessmentListResponseData>;
  info: UpdateInfo;
};

export type UpdateAssessmentListResponseFormattedsMutationResponse = {
  __typename?: "UpdateAssessmentListResponseFormattedsMutationResponse";
  assessmentListResponseFormatteds: Array<AssessmentListResponseFormatted>;
  info: UpdateInfo;
};

export type UpdateAssessmentListResponsesMutationResponse = {
  __typename?: "UpdateAssessmentListResponsesMutationResponse";
  assessmentListResponses: Array<AssessmentListResponse>;
  info: UpdateInfo;
};

export type UpdateAssessmentResponsesMutationResponse = {
  __typename?: "UpdateAssessmentResponsesMutationResponse";
  assessmentResponses: Array<AssessmentResponse>;
  info: UpdateInfo;
};

export type UpdateAssessmentsMutationResponse = {
  __typename?: "UpdateAssessmentsMutationResponse";
  assessments: Array<Assessment>;
  info: UpdateInfo;
};

export type UpdateAssignedQuestsMutationResponse = {
  __typename?: "UpdateAssignedQuestsMutationResponse";
  assignedQuests: Array<AssignedQuest>;
  info: UpdateInfo;
};

export type UpdateAssignmentAndSubmissionResponsesMutationResponse = {
  __typename?: "UpdateAssignmentAndSubmissionResponsesMutationResponse";
  assignmentAndSubmissionResponses: Array<AssignmentAndSubmissionResponse>;
  info: UpdateInfo;
};

export type UpdateAssignmentAndSubmissionsMutationResponse = {
  __typename?: "UpdateAssignmentAndSubmissionsMutationResponse";
  assignmentAndSubmissions: Array<AssignmentAndSubmission>;
  info: UpdateInfo;
};

export type UpdateAssignmentResponse = {
  __typename?: "UpdateAssignmentResponse";
  data?: Maybe<Assignment>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateAssignmentResponseAggregateSelection = {
  __typename?: "UpdateAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UpdateAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UpdateAssignmentResponseEdge = {
  __typename?: "UpdateAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateAssignmentResponse;
};

export type UpdateAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateAssignmentResponseSort objects to sort UpdateAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateAssignmentResponseSort>>;
};

/** Fields to sort UpdateAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateAssignmentResponseSort object. */
export type UpdateAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UpdateAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateAssignmentResponseWhere = {
  AND?: InputMaybe<Array<UpdateAssignmentResponseWhere>>;
  NOT?: InputMaybe<UpdateAssignmentResponseWhere>;
  OR?: InputMaybe<Array<UpdateAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateAssignmentResponsesConnection = {
  __typename?: "UpdateAssignmentResponsesConnection";
  edges: Array<UpdateAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateAssignmentResponsesMutationResponse = {
  __typename?: "UpdateAssignmentResponsesMutationResponse";
  assignmentResponses: Array<AssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateAssignmentsMutationResponse = {
  __typename?: "UpdateAssignmentsMutationResponse";
  assignments: Array<Assignment>;
  info: UpdateInfo;
};

export type UpdateAttributesMutationResponse = {
  __typename?: "UpdateAttributesMutationResponse";
  attributes: Array<Attribute>;
  info: UpdateInfo;
};

export type UpdateAudioToggleUserResponsesMutationResponse = {
  __typename?: "UpdateAudioToggleUserResponsesMutationResponse";
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadResponsesMutationResponse = {
  __typename?: "UpdateAuthPayloadResponsesMutationResponse";
  authPayloadResponses: Array<AuthPayloadResponse>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadUserPreferencesMutationResponse = {
  __typename?: "UpdateAuthPayloadUserPreferencesMutationResponse";
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadUsersMutationResponse = {
  __typename?: "UpdateAuthPayloadUsersMutationResponse";
  authPayloadUsers: Array<AuthPayloadUser>;
  info: UpdateInfo;
};

export type UpdateAxioResourceInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<FileInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAxioResourceResponsesMutationResponse = {
  __typename?: "UpdateAxioResourceResponsesMutationResponse";
  axioResourceResponses: Array<AxioResourceResponse>;
  info: UpdateInfo;
};

export type UpdateAxioResourcesMutationResponse = {
  __typename?: "UpdateAxioResourcesMutationResponse";
  axioResources: Array<AxioResource>;
  info: UpdateInfo;
};

export type UpdateCareerHeadersMutationResponse = {
  __typename?: "UpdateCareerHeadersMutationResponse";
  careerHeaders: Array<CareerHeader>;
  info: UpdateInfo;
};

export type UpdateCareerTopicResponsesMutationResponse = {
  __typename?: "UpdateCareerTopicResponsesMutationResponse";
  careerTopicResponses: Array<CareerTopicResponse>;
  info: UpdateInfo;
};

export type UpdateCareersMutationResponse = {
  __typename?: "UpdateCareersMutationResponse";
  careers: Array<Career>;
  info: UpdateInfo;
};

export type UpdateChallengesMutationResponse = {
  __typename?: "UpdateChallengesMutationResponse";
  challenges: Array<Challenge>;
  info: UpdateInfo;
};

export type UpdateChaptersMutationResponse = {
  __typename?: "UpdateChaptersMutationResponse";
  chapters: Array<Chapter>;
  info: UpdateInfo;
};

export type UpdateChatAnalyticsMutationResponse = {
  __typename?: "UpdateChatAnalyticsMutationResponse";
  chatAnalytics: Array<ChatAnalytics>;
  info: UpdateInfo;
};

export type UpdateChatAttachmentResponsesMutationResponse = {
  __typename?: "UpdateChatAttachmentResponsesMutationResponse";
  chatAttachmentResponses: Array<ChatAttachmentResponse>;
  info: UpdateInfo;
};

export type UpdateChatResponsesMutationResponse = {
  __typename?: "UpdateChatResponsesMutationResponse";
  chatResponses: Array<ChatResponse>;
  info: UpdateInfo;
};

export type UpdateChatsMutationResponse = {
  __typename?: "UpdateChatsMutationResponse";
  chats: Array<Chat>;
  info: UpdateInfo;
};

export type UpdateChildInfosMutationResponse = {
  __typename?: "UpdateChildInfosMutationResponse";
  childInfos: Array<ChildInfo>;
  info: UpdateInfo;
};

export type UpdateCompletionActivityAnalyticsMutationResponse = {
  __typename?: "UpdateCompletionActivityAnalyticsMutationResponse";
  completionActivityAnalytics: Array<CompletionActivityAnalytics>;
  info: UpdateInfo;
};

export type UpdateCompletionActivityDetailsMutationResponse = {
  __typename?: "UpdateCompletionActivityDetailsMutationResponse";
  completionActivityDetails: Array<CompletionActivityDetails>;
  info: UpdateInfo;
};

export type UpdateCompletionAnalyticsMutationResponse = {
  __typename?: "UpdateCompletionAnalyticsMutationResponse";
  completionAnalytics: Array<CompletionAnalytics>;
  info: UpdateInfo;
};

export type UpdateCompletionAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateCompletionAnalyticsResponsesMutationResponse";
  completionAnalyticsResponses: Array<CompletionAnalyticsResponse>;
  info: UpdateInfo;
};

export type UpdateCompletionCourseDetailsMutationResponse = {
  __typename?: "UpdateCompletionCourseDetailsMutationResponse";
  completionCourseDetails: Array<CompletionCourseDetails>;
  info: UpdateInfo;
};

export type UpdateCompletionModuleAnalyticsMutationResponse = {
  __typename?: "UpdateCompletionModuleAnalyticsMutationResponse";
  completionModuleAnalytics: Array<CompletionModuleAnalytics>;
  info: UpdateInfo;
};

export type UpdateCompletionModuleDetailsMutationResponse = {
  __typename?: "UpdateCompletionModuleDetailsMutationResponse";
  completionModuleDetails: Array<CompletionModuleDetails>;
  info: UpdateInfo;
};

export type UpdateCompletionObjectiveAnalyticsMutationResponse = {
  __typename?: "UpdateCompletionObjectiveAnalyticsMutationResponse";
  completionObjectiveAnalytics: Array<CompletionObjectiveAnalytics>;
  info: UpdateInfo;
};

export type UpdateCompletionStatsMutationResponse = {
  __typename?: "UpdateCompletionStatsMutationResponse";
  completionStats: Array<CompletionStats>;
  info: UpdateInfo;
};

export type UpdateContentModuleNameResponse = {
  __typename?: "UpdateContentModuleNameResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateContentModuleNameResponseAggregateSelection = {
  __typename?: "UpdateContentModuleNameResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UpdateContentModuleNameResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UpdateContentModuleNameResponseEdge = {
  __typename?: "UpdateContentModuleNameResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateContentModuleNameResponse;
};

export type UpdateContentModuleNameResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateContentModuleNameResponseSort objects to sort UpdateContentModuleNameResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateContentModuleNameResponseSort>>;
};

/** Fields to sort UpdateContentModuleNameResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateContentModuleNameResponseSort object. */
export type UpdateContentModuleNameResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UpdateContentModuleNameResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateContentModuleNameResponseWhere = {
  AND?: InputMaybe<Array<UpdateContentModuleNameResponseWhere>>;
  NOT?: InputMaybe<UpdateContentModuleNameResponseWhere>;
  OR?: InputMaybe<Array<UpdateContentModuleNameResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateContentModuleNameResponsesConnection = {
  __typename?: "UpdateContentModuleNameResponsesConnection";
  edges: Array<UpdateContentModuleNameResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateCoreValuesMutationResponse = {
  __typename?: "UpdateCoreValuesMutationResponse";
  coreValues: Array<CoreValue>;
  info: UpdateInfo;
};

export type UpdateCourseAnalyticsDetailsMutationResponse = {
  __typename?: "UpdateCourseAnalyticsDetailsMutationResponse";
  courseAnalyticsDetails: Array<CourseAnalyticsDetails>;
  info: UpdateInfo;
};

export type UpdateCourseAnalyticsMutationResponse = {
  __typename?: "UpdateCourseAnalyticsMutationResponse";
  courseAnalytics: Array<CourseAnalytics>;
  info: UpdateInfo;
};

export type UpdateCourseAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateCourseAnalyticsResponsesMutationResponse";
  courseAnalyticsResponses: Array<CourseAnalyticsResponse>;
  info: UpdateInfo;
};

export type UpdateCourseAnalyticsSummaryResponsesMutationResponse = {
  __typename?: "UpdateCourseAnalyticsSummaryResponsesMutationResponse";
  courseAnalyticsSummaryResponses: Array<CourseAnalyticsSummaryResponse>;
  info: UpdateInfo;
};

export type UpdateCourseCategoriesMutationResponse = {
  __typename?: "UpdateCourseCategoriesMutationResponse";
  courseCategories: Array<CourseCategory>;
  info: UpdateInfo;
};

export type UpdateCourseCompletionDetailsDataMutationResponse = {
  __typename?: "UpdateCourseCompletionDetailsDataMutationResponse";
  courseCompletionDetailsData: Array<CourseCompletionDetailsData>;
  info: UpdateInfo;
};

export type UpdateCourseCompletionDetailsMutationResponse = {
  __typename?: "UpdateCourseCompletionDetailsMutationResponse";
  courseCompletionDetails: Array<CourseCompletionDetails>;
  info: UpdateInfo;
};

export type UpdateCourseCompletionDetailsResponsesMutationResponse = {
  __typename?: "UpdateCourseCompletionDetailsResponsesMutationResponse";
  courseCompletionDetailsResponses: Array<CourseCompletionDetailsResponse>;
  info: UpdateInfo;
};

export type UpdateCourseCompletionSummariesMutationResponse = {
  __typename?: "UpdateCourseCompletionSummariesMutationResponse";
  courseCompletionSummaries: Array<CourseCompletionSummary>;
  info: UpdateInfo;
};

export type UpdateCourseCompletionSummaryResponsesMutationResponse = {
  __typename?: "UpdateCourseCompletionSummaryResponsesMutationResponse";
  courseCompletionSummaryResponses: Array<CourseCompletionSummaryResponse>;
  info: UpdateInfo;
};

export type UpdateCourseCreationResponsesMutationResponse = {
  __typename?: "UpdateCourseCreationResponsesMutationResponse";
  courseCreationResponses: Array<CourseCreationResponse>;
  info: UpdateInfo;
};

export type UpdateCourseDetailedAnalyticsMutationResponse = {
  __typename?: "UpdateCourseDetailedAnalyticsMutationResponse";
  courseDetailedAnalytics: Array<CourseDetailedAnalytics>;
  info: UpdateInfo;
};

export type UpdateCourseDetailedResponsesMutationResponse = {
  __typename?: "UpdateCourseDetailedResponsesMutationResponse";
  courseDetailedResponses: Array<CourseDetailedResponse>;
  info: UpdateInfo;
};

export type UpdateCourseHierarchyResponsesMutationResponse = {
  __typename?: "UpdateCourseHierarchyResponsesMutationResponse";
  courseHierarchyResponses: Array<CourseHierarchyResponse>;
  info: UpdateInfo;
};

export type UpdateCourseInput = {
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCourseProgressAnalyticsMutationResponse = {
  __typename?: "UpdateCourseProgressAnalyticsMutationResponse";
  courseProgressAnalytics: Array<CourseProgressAnalytics>;
  info: UpdateInfo;
};

export type UpdateCourseProgressDetailsMutationResponse = {
  __typename?: "UpdateCourseProgressDetailsMutationResponse";
  courseProgressDetails: Array<CourseProgressDetails>;
  info: UpdateInfo;
};

export type UpdateCourseResponsesMutationResponse = {
  __typename?: "UpdateCourseResponsesMutationResponse";
  courseResponses: Array<CourseResponse>;
  info: UpdateInfo;
};

export type UpdateCourseRosterDataMutationResponse = {
  __typename?: "UpdateCourseRosterDataMutationResponse";
  courseRosterData: Array<CourseRosterData>;
  info: UpdateInfo;
};

export type UpdateCourseRosterServiceResultsMutationResponse = {
  __typename?: "UpdateCourseRosterServiceResultsMutationResponse";
  courseRosterServiceResults: Array<CourseRosterServiceResult>;
  info: UpdateInfo;
};

export type UpdateCourseSummariesMutationResponse = {
  __typename?: "UpdateCourseSummariesMutationResponse";
  courseSummaries: Array<CourseSummary>;
  info: UpdateInfo;
};

export type UpdateCoursesByQueryDataMutationResponse = {
  __typename?: "UpdateCoursesByQueryDataMutationResponse";
  coursesByQueryData: Array<CoursesByQueryData>;
  info: UpdateInfo;
};

export type UpdateCoursesByQueryResponsesMutationResponse = {
  __typename?: "UpdateCoursesByQueryResponsesMutationResponse";
  coursesByQueryResponses: Array<CoursesByQueryResponse>;
  info: UpdateInfo;
};

export type UpdateCoursesMutationResponse = {
  __typename?: "UpdateCoursesMutationResponse";
  courses: Array<Course>;
  info: UpdateInfo;
};

export type UpdateCurriculaMutationResponse = {
  __typename?: "UpdateCurriculaMutationResponse";
  curricula: Array<Curriculum>;
  info: UpdateInfo;
};

export type UpdateDailyCompletedTodosCountsMutationResponse = {
  __typename?: "UpdateDailyCompletedTodosCountsMutationResponse";
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  info: UpdateInfo;
};

export type UpdateDailyExerciseTotalsMutationResponse = {
  __typename?: "UpdateDailyExerciseTotalsMutationResponse";
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  info: UpdateInfo;
};

export type UpdateDailyNutritionTotalsMutationResponse = {
  __typename?: "UpdateDailyNutritionTotalsMutationResponse";
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  info: UpdateInfo;
};

export type UpdateDailySummariesMutationResponse = {
  __typename?: "UpdateDailySummariesMutationResponse";
  dailySummaries: Array<DailySummary>;
  info: UpdateInfo;
};

export type UpdateDegreeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDegreeResponsesMutationResponse = {
  __typename?: "UpdateDegreeResponsesMutationResponse";
  degreeResponses: Array<DegreeResponse>;
  info: UpdateInfo;
};

export type UpdateDegreesBySchoolByQueryDataMutationResponse = {
  __typename?: "UpdateDegreesBySchoolByQueryDataMutationResponse";
  degreesBySchoolByQueryData: Array<DegreesBySchoolByQueryData>;
  info: UpdateInfo;
};

export type UpdateDegreesBySchoolByQueryResponsesMutationResponse = {
  __typename?: "UpdateDegreesBySchoolByQueryResponsesMutationResponse";
  degreesBySchoolByQueryResponses: Array<DegreesBySchoolByQueryResponse>;
  info: UpdateInfo;
};

export type UpdateDegreesMutationResponse = {
  __typename?: "UpdateDegreesMutationResponse";
  degrees: Array<Degree>;
  info: UpdateInfo;
};

export type UpdateDeleteContentAssignmentResponsesMutationResponse = {
  __typename?: "UpdateDeleteContentAssignmentResponsesMutationResponse";
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateDeleteContentAssignmentVariablesMutationResponse = {
  __typename?: "UpdateDeleteContentAssignmentVariablesMutationResponse";
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  info: UpdateInfo;
};

export type UpdateDeleteContentModuleResponsesMutationResponse = {
  __typename?: "UpdateDeleteContentModuleResponsesMutationResponse";
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  info: UpdateInfo;
};

export type UpdateDeleteContentModuleVariablesMutationResponse = {
  __typename?: "UpdateDeleteContentModuleVariablesMutationResponse";
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  info: UpdateInfo;
};

export type UpdateDeleteCourseResponsesMutationResponse = {
  __typename?: "UpdateDeleteCourseResponsesMutationResponse";
  deleteCourseResponses: Array<DeleteCourseResponse>;
  info: UpdateInfo;
};

export type UpdateDeviceSessionsMutationResponse = {
  __typename?: "UpdateDeviceSessionsMutationResponse";
  deviceSessions: Array<DeviceSession>;
  info: UpdateInfo;
};

export type UpdateEmotionalStatesMutationResponse = {
  __typename?: "UpdateEmotionalStatesMutationResponse";
  emotionalStates: Array<EmotionalState>;
  info: UpdateInfo;
};

export type UpdateEventsMutationResponse = {
  __typename?: "UpdateEventsMutationResponse";
  events: Array<Event>;
  info: UpdateInfo;
};

export type UpdateExportCsvResponsesMutationResponse = {
  __typename?: "UpdateExportCsvResponsesMutationResponse";
  exportCsvResponses: Array<ExportCsvResponse>;
  info: UpdateInfo;
};

export type UpdateFacultyDataCoursesMutationResponse = {
  __typename?: "UpdateFacultyDataCoursesMutationResponse";
  facultyDataCourses: Array<FacultyDataCourse>;
  info: UpdateInfo;
};

export type UpdateFacultyDataRolesMutationResponse = {
  __typename?: "UpdateFacultyDataRolesMutationResponse";
  facultyDataRoles: Array<FacultyDataRole>;
  info: UpdateInfo;
};

export type UpdateFacultyDataSessionDeviceSessionsMutationResponse = {
  __typename?: "UpdateFacultyDataSessionDeviceSessionsMutationResponse";
  facultyDataSessionDeviceSessions: Array<FacultyDataSessionDeviceSession>;
  info: UpdateInfo;
};

export type UpdateFacultyProfileInput = {
  biography?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFacultyQueryResponseDataMutationResponse = {
  __typename?: "UpdateFacultyQueryResponseDataMutationResponse";
  facultyQueryResponseData: Array<FacultyQueryResponseData>;
  info: UpdateInfo;
};

export type UpdateFacultyQueryResponseWithCountDataMutationResponse = {
  __typename?: "UpdateFacultyQueryResponseWithCountDataMutationResponse";
  facultyQueryResponseWithCountData: Array<FacultyQueryResponseWithCountData>;
  info: UpdateInfo;
};

export type UpdateFacultyQueryResponsesMutationResponse = {
  __typename?: "UpdateFacultyQueryResponsesMutationResponse";
  facultyQueryResponses: Array<FacultyQueryResponse>;
  info: UpdateInfo;
};

export type UpdateFeedbacksMutationResponse = {
  __typename?: "UpdateFeedbacksMutationResponse";
  feedbacks: Array<Feedback>;
  info: UpdateInfo;
};

export type UpdateFieldsMutationResponse = {
  __typename?: "UpdateFieldsMutationResponse";
  fields: Array<Field>;
  info: UpdateInfo;
};

export type UpdateFileResponsesMutationResponse = {
  __typename?: "UpdateFileResponsesMutationResponse";
  fileResponses: Array<FileResponse>;
  info: UpdateInfo;
};

export type UpdateFileUploadResponsesMutationResponse = {
  __typename?: "UpdateFileUploadResponsesMutationResponse";
  fileUploadResponses: Array<FileUploadResponse>;
  info: UpdateInfo;
};

export type UpdateFilesMutationResponse = {
  __typename?: "UpdateFilesMutationResponse";
  files: Array<File>;
  info: UpdateInfo;
};

export type UpdateFoodItemsMutationResponse = {
  __typename?: "UpdateFoodItemsMutationResponse";
  foodItems: Array<FoodItem>;
  info: UpdateInfo;
};

export type UpdateFoodsMutationResponse = {
  __typename?: "UpdateFoodsMutationResponse";
  foods: Array<Food>;
  info: UpdateInfo;
};

export type UpdateFormattedAiTutorsMutationResponse = {
  __typename?: "UpdateFormattedAiTutorsMutationResponse";
  formattedAiTutors: Array<FormattedAiTutor>;
  info: UpdateInfo;
};

export type UpdateFormattedChatsMutationResponse = {
  __typename?: "UpdateFormattedChatsMutationResponse";
  formattedChats: Array<FormattedChat>;
  info: UpdateInfo;
};

export type UpdateFormattedObjectivesMutationResponse = {
  __typename?: "UpdateFormattedObjectivesMutationResponse";
  formattedObjectives: Array<FormattedObjective>;
  info: UpdateInfo;
};

export type UpdateFormattedVisualizationsMutationResponse = {
  __typename?: "UpdateFormattedVisualizationsMutationResponse";
  formattedVisualizations: Array<FormattedVisualization>;
  info: UpdateInfo;
};

export type UpdateGamesMutationResponse = {
  __typename?: "UpdateGamesMutationResponse";
  games: Array<Game>;
  info: UpdateInfo;
};

export type UpdateGenerateCourseContentDataMutationResponse = {
  __typename?: "UpdateGenerateCourseContentDataMutationResponse";
  generateCourseContentData: Array<GenerateCourseContentData>;
  info: UpdateInfo;
};

export type UpdateGenerateCourseContentResponsesMutationResponse = {
  __typename?: "UpdateGenerateCourseContentResponsesMutationResponse";
  generateCourseContentResponses: Array<GenerateCourseContentResponse>;
  info: UpdateInfo;
};

export type UpdateGeneratedAiTutorsMutationResponse = {
  __typename?: "UpdateGeneratedAiTutorsMutationResponse";
  generatedAiTutors: Array<GeneratedAiTutor>;
  info: UpdateInfo;
};

export type UpdateGeneratedObjectivesMutationResponse = {
  __typename?: "UpdateGeneratedObjectivesMutationResponse";
  generatedObjectives: Array<GeneratedObjective>;
  info: UpdateInfo;
};

export type UpdateGeneratedsMutationResponse = {
  __typename?: "UpdateGeneratedsMutationResponse";
  generateds: Array<Generated>;
  info: UpdateInfo;
};

export type UpdateGetAssignmentObjectiveResponsesMutationResponse = {
  __typename?: "UpdateGetAssignmentObjectiveResponsesMutationResponse";
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  info: UpdateInfo;
};

export type UpdateGetAssignmentResponsesMutationResponse = {
  __typename?: "UpdateGetAssignmentResponsesMutationResponse";
  getAssignmentResponses: Array<GetAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateGetJourneyByIdResponsesMutationResponse = {
  __typename?: "UpdateGetJourneyByIdResponsesMutationResponse";
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  info: UpdateInfo;
};

export type UpdateGetJourneysResponsesMutationResponse = {
  __typename?: "UpdateGetJourneysResponsesMutationResponse";
  getJourneysResponses: Array<GetJourneysResponse>;
  info: UpdateInfo;
};

export type UpdateGoalsMutationResponse = {
  __typename?: "UpdateGoalsMutationResponse";
  goals: Array<Goal>;
  info: UpdateInfo;
};

export type UpdateGoogleAuthTokensMutationResponse = {
  __typename?: "UpdateGoogleAuthTokensMutationResponse";
  googleAuthTokens: Array<GoogleAuthTokens>;
  info: UpdateInfo;
};

export type UpdateHierarchiesMutationResponse = {
  __typename?: "UpdateHierarchiesMutationResponse";
  hierarchies: Array<Hierarchy>;
  info: UpdateInfo;
};

export type UpdateHierarchyActivitiesMutationResponse = {
  __typename?: "UpdateHierarchyActivitiesMutationResponse";
  hierarchyActivities: Array<HierarchyActivity>;
  info: UpdateInfo;
};

export type UpdateHierarchyActivityDetailsMutationResponse = {
  __typename?: "UpdateHierarchyActivityDetailsMutationResponse";
  hierarchyActivityDetails: Array<HierarchyActivityDetails>;
  info: UpdateInfo;
};

export type UpdateHierarchyCourseDetailsMutationResponse = {
  __typename?: "UpdateHierarchyCourseDetailsMutationResponse";
  hierarchyCourseDetails: Array<HierarchyCourseDetails>;
  info: UpdateInfo;
};

export type UpdateHierarchyCoursesMutationResponse = {
  __typename?: "UpdateHierarchyCoursesMutationResponse";
  hierarchyCourses: Array<HierarchyCourse>;
  info: UpdateInfo;
};

export type UpdateHierarchyModuleDetailsMutationResponse = {
  __typename?: "UpdateHierarchyModuleDetailsMutationResponse";
  hierarchyModuleDetails: Array<HierarchyModuleDetails>;
  info: UpdateInfo;
};

export type UpdateHierarchyModulesMutationResponse = {
  __typename?: "UpdateHierarchyModulesMutationResponse";
  hierarchyModules: Array<HierarchyModule>;
  info: UpdateInfo;
};

export type UpdateHierarchyNodesMutationResponse = {
  __typename?: "UpdateHierarchyNodesMutationResponse";
  hierarchyNodes: Array<HierarchyNode>;
  info: UpdateInfo;
};

export type UpdateHierarchyObjectivesMutationResponse = {
  __typename?: "UpdateHierarchyObjectivesMutationResponse";
  hierarchyObjectives: Array<HierarchyObjective>;
  info: UpdateInfo;
};

export type UpdateHobbiesMutationResponse = {
  __typename?: "UpdateHobbiesMutationResponse";
  hobbies: Array<Hobby>;
  info: UpdateInfo;
};

/** Information about the number of nodes and relationships created and deleted during an update mutation */
export type UpdateInfo = {
  __typename?: "UpdateInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesCreated: Scalars["Int"]["output"];
  nodesDeleted: Scalars["Int"]["output"];
  relationshipsCreated: Scalars["Int"]["output"];
  relationshipsDeleted: Scalars["Int"]["output"];
};

export type UpdateInterestsMutationResponse = {
  __typename?: "UpdateInterestsMutationResponse";
  info: UpdateInfo;
  interests: Array<Interest>;
};

export type UpdateInviteCountResponsesMutationResponse = {
  __typename?: "UpdateInviteCountResponsesMutationResponse";
  info: UpdateInfo;
  inviteCountResponses: Array<InviteCountResponse>;
};

export type UpdateInviteUsersToEnrollInCourseResponsesMutationResponse = {
  __typename?: "UpdateInviteUsersToEnrollInCourseResponsesMutationResponse";
  info: UpdateInfo;
  inviteUsersToEnrollInCourseResponses: Array<InviteUsersToEnrollInCourseResponse>;
};

export type UpdateInviteUsersToEnrollInDegreeResponsesMutationResponse = {
  __typename?: "UpdateInviteUsersToEnrollInDegreeResponsesMutationResponse";
  info: UpdateInfo;
  inviteUsersToEnrollInDegreeResponses: Array<InviteUsersToEnrollInDegreeResponse>;
};

export type UpdateInvitedUsersMutationResponse = {
  __typename?: "UpdateInvitedUsersMutationResponse";
  info: UpdateInfo;
  invitedUsers: Array<InvitedUsers>;
};

export type UpdateJourneysMutationResponse = {
  __typename?: "UpdateJourneysMutationResponse";
  info: UpdateInfo;
  journeys: Array<Journey>;
};

export type UpdateLearningCheckoutSessionsMutationResponse = {
  __typename?: "UpdateLearningCheckoutSessionsMutationResponse";
  info: UpdateInfo;
  learningCheckoutSessions: Array<LearningCheckoutSession>;
};

export type UpdateLearningNodesMutationResponse = {
  __typename?: "UpdateLearningNodesMutationResponse";
  info: UpdateInfo;
  learningNodes: Array<LearningNode>;
};

export type UpdateLearningObjectiveResponsesMutationResponse = {
  __typename?: "UpdateLearningObjectiveResponsesMutationResponse";
  info: UpdateInfo;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
};

export type UpdateLearningObjectivesMutationResponse = {
  __typename?: "UpdateLearningObjectivesMutationResponse";
  info: UpdateInfo;
  learningObjectives: Array<LearningObjective>;
};

export type UpdateLearningPaymentsMutationResponse = {
  __typename?: "UpdateLearningPaymentsMutationResponse";
  info: UpdateInfo;
  learningPayments: Array<LearningPayment>;
};

export type UpdateLearningSummariesMutationResponse = {
  __typename?: "UpdateLearningSummariesMutationResponse";
  info: UpdateInfo;
  learningSummaries: Array<LearningSummary>;
};

export type UpdateLearningTreesMutationResponse = {
  __typename?: "UpdateLearningTreesMutationResponse";
  info: UpdateInfo;
  learningTrees: Array<LearningTree>;
};

export type UpdateLessonsMutationResponse = {
  __typename?: "UpdateLessonsMutationResponse";
  info: UpdateInfo;
  lessons: Array<Lesson>;
};

export type UpdateLevelDetailsMutationResponse = {
  __typename?: "UpdateLevelDetailsMutationResponse";
  info: UpdateInfo;
  levelDetails: Array<LevelDetails>;
};

export type UpdateLifeAspirationsMutationResponse = {
  __typename?: "UpdateLifeAspirationsMutationResponse";
  info: UpdateInfo;
  lifeAspirations: Array<LifeAspiration>;
};

export type UpdateMasteryResultsMutationResponse = {
  __typename?: "UpdateMasteryResultsMutationResponse";
  info: UpdateInfo;
  masteryResults: Array<MasteryResult>;
};

export type UpdateMeResponsesMutationResponse = {
  __typename?: "UpdateMeResponsesMutationResponse";
  info: UpdateInfo;
  meResponses: Array<MeResponse>;
};

export type UpdateMessageResponsesMutationResponse = {
  __typename?: "UpdateMessageResponsesMutationResponse";
  info: UpdateInfo;
  messageResponses: Array<MessageResponse>;
};

export type UpdateMessagesMutationResponse = {
  __typename?: "UpdateMessagesMutationResponse";
  info: UpdateInfo;
  messages: Array<Message>;
};

export type UpdateModuleAnalyticsDetailsMutationResponse = {
  __typename?: "UpdateModuleAnalyticsDetailsMutationResponse";
  info: UpdateInfo;
  moduleAnalyticsDetails: Array<ModuleAnalyticsDetails>;
};

export type UpdateModuleAnalyticsMutationResponse = {
  __typename?: "UpdateModuleAnalyticsMutationResponse";
  info: UpdateInfo;
  moduleAnalytics: Array<ModuleAnalytics>;
};

export type UpdateModuleAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateModuleAnalyticsResponsesMutationResponse";
  info: UpdateInfo;
  moduleAnalyticsResponses: Array<ModuleAnalyticsResponse>;
};

export type UpdateModuleCompletionDetailsActivitiesMutationResponse = {
  __typename?: "UpdateModuleCompletionDetailsActivitiesMutationResponse";
  info: UpdateInfo;
  moduleCompletionDetailsActivities: Array<ModuleCompletionDetailsActivities>;
};

export type UpdateModuleCompletionDetailsDataMutationResponse = {
  __typename?: "UpdateModuleCompletionDetailsDataMutationResponse";
  info: UpdateInfo;
  moduleCompletionDetailsData: Array<ModuleCompletionDetailsData>;
};

export type UpdateModuleCompletionDetailsFullsMutationResponse = {
  __typename?: "UpdateModuleCompletionDetailsFullsMutationResponse";
  info: UpdateInfo;
  moduleCompletionDetailsFulls: Array<ModuleCompletionDetailsFull>;
};

export type UpdateModuleCompletionDetailsMutationResponse = {
  __typename?: "UpdateModuleCompletionDetailsMutationResponse";
  info: UpdateInfo;
  moduleCompletionDetails: Array<ModuleCompletionDetails>;
};

export type UpdateModuleCompletionDetailsResponsesMutationResponse = {
  __typename?: "UpdateModuleCompletionDetailsResponsesMutationResponse";
  info: UpdateInfo;
  moduleCompletionDetailsResponses: Array<ModuleCompletionDetailsResponse>;
};

export type UpdateModuleInput = {
  isAssessmentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateModuleProgressAnalyticsMutationResponse = {
  __typename?: "UpdateModuleProgressAnalyticsMutationResponse";
  info: UpdateInfo;
  moduleProgressAnalytics: Array<ModuleProgressAnalytics>;
};

export type UpdateModuleProgressDetailsMutationResponse = {
  __typename?: "UpdateModuleProgressDetailsMutationResponse";
  info: UpdateInfo;
  moduleProgressDetails: Array<ModuleProgressDetails>;
};

export type UpdateModuleResponsesMutationResponse = {
  __typename?: "UpdateModuleResponsesMutationResponse";
  info: UpdateInfo;
  moduleResponses: Array<ModuleResponse>;
};

export type UpdateModulesMutationResponse = {
  __typename?: "UpdateModulesMutationResponse";
  info: UpdateInfo;
  modules: Array<Module>;
};

export type UpdateMonthlySummariesMutationResponse = {
  __typename?: "UpdateMonthlySummariesMutationResponse";
  info: UpdateInfo;
  monthlySummaries: Array<MonthlySummary>;
};

export type UpdateNodeIdentitiesMutationResponse = {
  __typename?: "UpdateNodeIdentitiesMutationResponse";
  info: UpdateInfo;
  nodeIdentities: Array<NodeIdentity>;
};

export type UpdateNodePropertiesMutationResponse = {
  __typename?: "UpdateNodePropertiesMutationResponse";
  info: UpdateInfo;
  nodeProperties: Array<NodeProperties>;
};

export type UpdateNutritionsMutationResponse = {
  __typename?: "UpdateNutritionsMutationResponse";
  info: UpdateInfo;
  nutritions: Array<Nutrition>;
};

export type UpdateOAuthAccessTokensMutationResponse = {
  __typename?: "UpdateOAuthAccessTokensMutationResponse";
  info: UpdateInfo;
  oAuthAccessTokens: Array<OAuthAccessToken>;
};

export type UpdateOAuthClientsMutationResponse = {
  __typename?: "UpdateOAuthClientsMutationResponse";
  info: UpdateInfo;
  oAuthClients: Array<OAuthClient>;
};

export type UpdateObjectiveAnalyticsMutationResponse = {
  __typename?: "UpdateObjectiveAnalyticsMutationResponse";
  info: UpdateInfo;
  objectiveAnalytics: Array<ObjectiveAnalytics>;
};

export type UpdateObjectiveAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateObjectiveAnalyticsResponsesMutationResponse";
  info: UpdateInfo;
  objectiveAnalyticsResponses: Array<ObjectiveAnalyticsResponse>;
};

export type UpdateObjectiveCompletionDetailsFullsMutationResponse = {
  __typename?: "UpdateObjectiveCompletionDetailsFullsMutationResponse";
  info: UpdateInfo;
  objectiveCompletionDetailsFulls: Array<ObjectiveCompletionDetailsFull>;
};

export type UpdateObjectiveCompletionDetailsMutationResponse = {
  __typename?: "UpdateObjectiveCompletionDetailsMutationResponse";
  info: UpdateInfo;
  objectiveCompletionDetails: Array<ObjectiveCompletionDetails>;
};

export type UpdateObjectiveCompletionDetailsResponsesMutationResponse = {
  __typename?: "UpdateObjectiveCompletionDetailsResponsesMutationResponse";
  info: UpdateInfo;
  objectiveCompletionDetailsResponses: Array<ObjectiveCompletionDetailsResponse>;
};

export type UpdateObjectiveProgressAnalyticsMutationResponse = {
  __typename?: "UpdateObjectiveProgressAnalyticsMutationResponse";
  info: UpdateInfo;
  objectiveProgressAnalytics: Array<ObjectiveProgressAnalytics>;
};

export type UpdateObjectivesMutationResponse = {
  __typename?: "UpdateObjectivesMutationResponse";
  info: UpdateInfo;
  objectives: Array<Objective>;
};

export type UpdatePageInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePageResponsesMutationResponse = {
  __typename?: "UpdatePageResponsesMutationResponse";
  info: UpdateInfo;
  pageResponses: Array<PageResponse>;
};

export type UpdatePagesMutationResponse = {
  __typename?: "UpdatePagesMutationResponse";
  info: UpdateInfo;
  pages: Array<Page>;
};

export type UpdateParametersTypesMutationResponse = {
  __typename?: "UpdateParametersTypesMutationResponse";
  info: UpdateInfo;
  parametersTypes: Array<ParametersType>;
};

export type UpdateParentRefsMutationResponse = {
  __typename?: "UpdateParentRefsMutationResponse";
  info: UpdateInfo;
  parentRefs: Array<ParentRef>;
};

export type UpdatePaymentsMutationResponse = {
  __typename?: "UpdatePaymentsMutationResponse";
  info: UpdateInfo;
  payments: Array<Payment>;
};

export type UpdatePermissionsMutationResponse = {
  __typename?: "UpdatePermissionsMutationResponse";
  info: UpdateInfo;
  permissions: Array<Permission>;
};

export type UpdatePersonalityTraitsMutationResponse = {
  __typename?: "UpdatePersonalityTraitsMutationResponse";
  info: UpdateInfo;
  personalityTraits: Array<PersonalityTrait>;
};

export type UpdatePreferencesMutationResponse = {
  __typename?: "UpdatePreferencesMutationResponse";
  info: UpdateInfo;
  preferences: Array<Preferences>;
};

export type UpdatePrerequisitesMutationResponse = {
  __typename?: "UpdatePrerequisitesMutationResponse";
  info: UpdateInfo;
  prerequisites: Array<Prerequisite>;
};

export type UpdateProgressDetailsMutationResponse = {
  __typename?: "UpdateProgressDetailsMutationResponse";
  info: UpdateInfo;
  progressDetails: Array<ProgressDetails>;
};

export type UpdateProgressesMutationResponse = {
  __typename?: "UpdateProgressesMutationResponse";
  info: UpdateInfo;
  progresses: Array<Progress>;
};

export type UpdateQuarterlySummariesMutationResponse = {
  __typename?: "UpdateQuarterlySummariesMutationResponse";
  info: UpdateInfo;
  quarterlySummaries: Array<QuarterlySummary>;
};

export type UpdateRateLimitResultsMutationResponse = {
  __typename?: "UpdateRateLimitResultsMutationResponse";
  info: UpdateInfo;
  rateLimitResults: Array<RateLimitResult>;
};

export type UpdateRecentCategoriesMutationResponse = {
  __typename?: "UpdateRecentCategoriesMutationResponse";
  info: UpdateInfo;
  recentCategories: Array<RecentCategory>;
};

export type UpdateRecentTracksMutationResponse = {
  __typename?: "UpdateRecentTracksMutationResponse";
  info: UpdateInfo;
  recentTracks: Array<RecentTrack>;
};

export type UpdateRecomendedsMutationResponse = {
  __typename?: "UpdateRecomendedsMutationResponse";
  info: UpdateInfo;
  recomendeds: Array<Recomended>;
};

export type UpdateReflectionsMutationResponse = {
  __typename?: "UpdateReflectionsMutationResponse";
  info: UpdateInfo;
  reflections: Array<Reflection>;
};

export type UpdateRefreshQuestResponsesMutationResponse = {
  __typename?: "UpdateRefreshQuestResponsesMutationResponse";
  info: UpdateInfo;
  refreshQuestResponses: Array<RefreshQuestResponse>;
};

export type UpdateRelationshipFieldsMutationResponse = {
  __typename?: "UpdateRelationshipFieldsMutationResponse";
  info: UpdateInfo;
  relationshipFields: Array<RelationshipField>;
};

export type UpdateResourceOrderInput = {
  moduleId: Scalars["ID"]["input"];
  resourceOrder: Array<Scalars["String"]["input"]>;
};

export type UpdateResponseTypesMutationResponse = {
  __typename?: "UpdateResponseTypesMutationResponse";
  info: UpdateInfo;
  responseTypes: Array<ResponseType>;
};

export type UpdateRolesMutationResponse = {
  __typename?: "UpdateRolesMutationResponse";
  info: UpdateInfo;
  roles: Array<Role>;
};

export type UpdateSchoolsMutationResponse = {
  __typename?: "UpdateSchoolsMutationResponse";
  info: UpdateInfo;
  schools: Array<School>;
};

export type UpdateSessionResponsesMutationResponse = {
  __typename?: "UpdateSessionResponsesMutationResponse";
  info: UpdateInfo;
  sessionResponses: Array<SessionResponse>;
};

export type UpdateStudentActivityProgressDataMutationResponse = {
  __typename?: "UpdateStudentActivityProgressDataMutationResponse";
  info: UpdateInfo;
  studentActivityProgressData: Array<StudentActivityProgressData>;
};

export type UpdateStudentActivityProgressResponsesMutationResponse = {
  __typename?: "UpdateStudentActivityProgressResponsesMutationResponse";
  info: UpdateInfo;
  studentActivityProgressResponses: Array<StudentActivityProgressResponse>;
};

export type UpdateStudentAnalyticsDetailsMutationResponse = {
  __typename?: "UpdateStudentAnalyticsDetailsMutationResponse";
  info: UpdateInfo;
  studentAnalyticsDetails: Array<StudentAnalyticsDetails>;
};

export type UpdateStudentAnalyticsMutationResponse = {
  __typename?: "UpdateStudentAnalyticsMutationResponse";
  info: UpdateInfo;
  studentAnalytics: Array<StudentAnalytics>;
};

export type UpdateStudentAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateStudentAnalyticsResponsesMutationResponse";
  info: UpdateInfo;
  studentAnalyticsResponses: Array<StudentAnalyticsResponse>;
};

export type UpdateStudentAnalyticsSummaryResponsesMutationResponse = {
  __typename?: "UpdateStudentAnalyticsSummaryResponsesMutationResponse";
  info: UpdateInfo;
  studentAnalyticsSummaryResponses: Array<StudentAnalyticsSummaryResponse>;
};

export type UpdateStudentChatAnalyticsMutationResponse = {
  __typename?: "UpdateStudentChatAnalyticsMutationResponse";
  info: UpdateInfo;
  studentChatAnalytics: Array<StudentChatAnalytics>;
};

export type UpdateStudentChatAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateStudentChatAnalyticsResponsesMutationResponse";
  info: UpdateInfo;
  studentChatAnalyticsResponses: Array<StudentChatAnalyticsResponse>;
};

export type UpdateStudentCourseProgressResponsesMutationResponse = {
  __typename?: "UpdateStudentCourseProgressResponsesMutationResponse";
  info: UpdateInfo;
  studentCourseProgressResponses: Array<StudentCourseProgressResponse>;
};

export type UpdateStudentDetailedAnalyticsMutationResponse = {
  __typename?: "UpdateStudentDetailedAnalyticsMutationResponse";
  info: UpdateInfo;
  studentDetailedAnalytics: Array<StudentDetailedAnalytics>;
};

export type UpdateStudentDetailedAnalyticsResponsesMutationResponse = {
  __typename?: "UpdateStudentDetailedAnalyticsResponsesMutationResponse";
  info: UpdateInfo;
  studentDetailedAnalyticsResponses: Array<StudentDetailedAnalyticsResponse>;
};

export type UpdateStudentLearningInsightsMutationResponse = {
  __typename?: "UpdateStudentLearningInsightsMutationResponse";
  info: UpdateInfo;
  studentLearningInsights: Array<StudentLearningInsights>;
};

export type UpdateStudentLearningInsightsResponsesMutationResponse = {
  __typename?: "UpdateStudentLearningInsightsResponsesMutationResponse";
  info: UpdateInfo;
  studentLearningInsightsResponses: Array<StudentLearningInsightsResponse>;
};

export type UpdateStudentModuleProgressDataMutationResponse = {
  __typename?: "UpdateStudentModuleProgressDataMutationResponse";
  info: UpdateInfo;
  studentModuleProgressData: Array<StudentModuleProgressData>;
};

export type UpdateStudentModuleProgressResponsesMutationResponse = {
  __typename?: "UpdateStudentModuleProgressResponsesMutationResponse";
  info: UpdateInfo;
  studentModuleProgressResponses: Array<StudentModuleProgressResponse>;
};

export type UpdateStudentSummariesMutationResponse = {
  __typename?: "UpdateStudentSummariesMutationResponse";
  info: UpdateInfo;
  studentSummaries: Array<StudentSummary>;
};

export type UpdateSubchapterDetailsMutationResponse = {
  __typename?: "UpdateSubchapterDetailsMutationResponse";
  info: UpdateInfo;
  subchapterDetails: Array<SubchapterDetails>;
};

export type UpdateSubchaptersMutationResponse = {
  __typename?: "UpdateSubchaptersMutationResponse";
  info: UpdateInfo;
  subchapters: Array<Subchapter>;
};

export type UpdateSubfieldsMutationResponse = {
  __typename?: "UpdateSubfieldsMutationResponse";
  info: UpdateInfo;
  subfields: Array<Subfield>;
};

export type UpdateSubmissionListResponseDataMutationResponse = {
  __typename?: "UpdateSubmissionListResponseDataMutationResponse";
  info: UpdateInfo;
  submissionListResponseData: Array<SubmissionListResponseData>;
};

export type UpdateSubmissionListResponseFormattedsMutationResponse = {
  __typename?: "UpdateSubmissionListResponseFormattedsMutationResponse";
  info: UpdateInfo;
  submissionListResponseFormatteds: Array<SubmissionListResponseFormatted>;
};

export type UpdateSubmissionListResponsesMutationResponse = {
  __typename?: "UpdateSubmissionListResponsesMutationResponse";
  info: UpdateInfo;
  submissionListResponses: Array<SubmissionListResponse>;
};

export type UpdateSubmissionResponsesMutationResponse = {
  __typename?: "UpdateSubmissionResponsesMutationResponse";
  info: UpdateInfo;
  submissionResponses: Array<SubmissionResponse>;
};

export type UpdateSubmissionsMutationResponse = {
  __typename?: "UpdateSubmissionsMutationResponse";
  info: UpdateInfo;
  submissions: Array<Submission>;
};

export type UpdateSuggestVideoInsightsResponsesMutationResponse = {
  __typename?: "UpdateSuggestVideoInsightsResponsesMutationResponse";
  info: UpdateInfo;
  suggestVideoInsightsResponses: Array<SuggestVideoInsightsResponse>;
};

export type UpdateSuggestionsMutationResponse = {
  __typename?: "UpdateSuggestionsMutationResponse";
  info: UpdateInfo;
  suggestions: Array<Suggestion>;
};

export type UpdateTenantResponsesMutationResponse = {
  __typename?: "UpdateTenantResponsesMutationResponse";
  info: UpdateInfo;
  tenantResponses: Array<TenantResponse>;
};

export type UpdateTenantSchemaResponsesMutationResponse = {
  __typename?: "UpdateTenantSchemaResponsesMutationResponse";
  info: UpdateInfo;
  tenantSchemaResponses: Array<TenantSchemaResponse>;
};

export type UpdateTenantsMutationResponse = {
  __typename?: "UpdateTenantsMutationResponse";
  info: UpdateInfo;
  tenants: Array<Tenant>;
};

export type UpdateTestingsMutationResponse = {
  __typename?: "UpdateTestingsMutationResponse";
  info: UpdateInfo;
  testings: Array<Testing>;
};

export type UpdateTodosMutationResponse = {
  __typename?: "UpdateTodosMutationResponse";
  info: UpdateInfo;
  todos: Array<Todo>;
};

export type UpdateTopicsMutationResponse = {
  __typename?: "UpdateTopicsMutationResponse";
  info: UpdateInfo;
  topics: Array<Topic>;
};

export type UpdateTrackOverviewsMutationResponse = {
  __typename?: "UpdateTrackOverviewsMutationResponse";
  info: UpdateInfo;
  trackOverviews: Array<TrackOverview>;
};

export type UpdateTransactionByDatesMutationResponse = {
  __typename?: "UpdateTransactionByDatesMutationResponse";
  info: UpdateInfo;
  transactionByDates: Array<TransactionByDate>;
};

export type UpdateTransactionsMutationResponse = {
  __typename?: "UpdateTransactionsMutationResponse";
  info: UpdateInfo;
  transactions: Array<Transaction>;
};

export type UpdateTranscriptSegmentsMutationResponse = {
  __typename?: "UpdateTranscriptSegmentsMutationResponse";
  info: UpdateInfo;
  transcriptSegments: Array<TranscriptSegment>;
};

export type UpdateUpdateAssignmentResponsesMutationResponse = {
  __typename?: "UpdateUpdateAssignmentResponsesMutationResponse";
  info: UpdateInfo;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
};

export type UpdateUpdateContentModuleNameResponsesMutationResponse = {
  __typename?: "UpdateUpdateContentModuleNameResponsesMutationResponse";
  info: UpdateInfo;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
};

export type UpdateUpdateUserModalOnboardingResponsesMutationResponse = {
  __typename?: "UpdateUpdateUserModalOnboardingResponsesMutationResponse";
  info: UpdateInfo;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
};

export type UpdateUploadCourseThumbnailResponsesMutationResponse = {
  __typename?: "UpdateUploadCourseThumbnailResponsesMutationResponse";
  info: UpdateInfo;
  uploadCourseThumbnailResponses: Array<UploadCourseThumbnailResponse>;
};

export type UpdateUploadDocumentsResponsesMutationResponse = {
  __typename?: "UpdateUploadDocumentsResponsesMutationResponse";
  info: UpdateInfo;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
};

export type UpdateUploadDocumentsToBucketResponsesMutationResponse = {
  __typename?: "UpdateUploadDocumentsToBucketResponsesMutationResponse";
  info: UpdateInfo;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
};

export type UpdateUploadFacultyProfileImageResponsesMutationResponse = {
  __typename?: "UpdateUploadFacultyProfileImageResponsesMutationResponse";
  info: UpdateInfo;
  uploadFacultyProfileImageResponses: Array<UploadFacultyProfileImageResponse>;
};

export type UpdateUserCareerLoadingResponsesMutationResponse = {
  __typename?: "UpdateUserCareerLoadingResponsesMutationResponse";
  info: UpdateInfo;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
};

export type UpdateUserCourseLoadingResponsesMutationResponse = {
  __typename?: "UpdateUserCourseLoadingResponsesMutationResponse";
  info: UpdateInfo;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
};

export type UpdateUserMasteriesMutationResponse = {
  __typename?: "UpdateUserMasteriesMutationResponse";
  info: UpdateInfo;
  userMasteries: Array<UserMastery>;
};

export type UpdateUserModalOnboardingResponse = {
  __typename?: "UpdateUserModalOnboardingResponse";
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
};

export type UpdateUserModalOnboardingResponseAggregateSelection = {
  __typename?: "UpdateUserModalOnboardingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  modalOnboarding: StringAggregateSelection;
};

export type UpdateUserModalOnboardingResponseCreateInput = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponseEdge = {
  __typename?: "UpdateUserModalOnboardingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateUserModalOnboardingResponse;
};

export type UpdateUserModalOnboardingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateUserModalOnboardingResponseSort objects to sort UpdateUserModalOnboardingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateUserModalOnboardingResponseSort>>;
};

/** Fields to sort UpdateUserModalOnboardingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateUserModalOnboardingResponseSort object. */
export type UpdateUserModalOnboardingResponseSort = {
  modalOnboarding?: InputMaybe<SortDirection>;
};

export type UpdateUserModalOnboardingResponseUpdateInput = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponseWhere = {
  AND?: InputMaybe<Array<UpdateUserModalOnboardingResponseWhere>>;
  NOT?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
  OR?: InputMaybe<Array<UpdateUserModalOnboardingResponseWhere>>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponsesConnection = {
  __typename?: "UpdateUserModalOnboardingResponsesConnection";
  edges: Array<UpdateUserModalOnboardingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateUserProgressesMutationResponse = {
  __typename?: "UpdateUserProgressesMutationResponse";
  info: UpdateInfo;
  userProgresses: Array<UserProgress>;
};

export type UpdateUsersMutationResponse = {
  __typename?: "UpdateUsersMutationResponse";
  info: UpdateInfo;
  users: Array<User>;
};

export type UpdateValidityResponsesMutationResponse = {
  __typename?: "UpdateValidityResponsesMutationResponse";
  info: UpdateInfo;
  validityResponses: Array<ValidityResponse>;
};

export type UpdateVideoPoolItemsMutationResponse = {
  __typename?: "UpdateVideoPoolItemsMutationResponse";
  info: UpdateInfo;
  videoPoolItems: Array<VideoPoolItem>;
};

export type UpdateVideoServiceResultsMutationResponse = {
  __typename?: "UpdateVideoServiceResultsMutationResponse";
  info: UpdateInfo;
  videoServiceResults: Array<VideoServiceResult>;
};

export type UpdateVideosMutationResponse = {
  __typename?: "UpdateVideosMutationResponse";
  info: UpdateInfo;
  videos: Array<Video>;
};

export type UpdateVisualizationResponsesMutationResponse = {
  __typename?: "UpdateVisualizationResponsesMutationResponse";
  info: UpdateInfo;
  visualizationResponses: Array<VisualizationResponse>;
};

export type UpdateVisualizationsMutationResponse = {
  __typename?: "UpdateVisualizationsMutationResponse";
  info: UpdateInfo;
  visualizations: Array<Visualization>;
};

export type UpdateWaterIntakeSummariesMutationResponse = {
  __typename?: "UpdateWaterIntakeSummariesMutationResponse";
  info: UpdateInfo;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
};

export type UpdateWaterIntakesMutationResponse = {
  __typename?: "UpdateWaterIntakesMutationResponse";
  info: UpdateInfo;
  waterIntakes: Array<WaterIntake>;
};

export type UpdateWebhooksMutationResponse = {
  __typename?: "UpdateWebhooksMutationResponse";
  info: UpdateInfo;
  webhooks: Array<Webhook>;
};

export type UpdateWeeklySummariesMutationResponse = {
  __typename?: "UpdateWeeklySummariesMutationResponse";
  info: UpdateInfo;
  weeklySummaries: Array<WeeklySummary>;
};

export type UpdateWhitelistedEmailsMutationResponse = {
  __typename?: "UpdateWhitelistedEmailsMutationResponse";
  info: UpdateInfo;
  whitelistedEmails: Array<WhitelistedEmail>;
};

export type UpdateWorkoutsMutationResponse = {
  __typename?: "UpdateWorkoutsMutationResponse";
  info: UpdateInfo;
  workouts: Array<Workout>;
};

export type UpdateYearlySummariesMutationResponse = {
  __typename?: "UpdateYearlySummariesMutationResponse";
  info: UpdateInfo;
  yearlySummaries: Array<YearlySummary>;
};

export type UploadCourseThumbnailInput = {
  contentType: Scalars["String"]["input"];
  courseId: Scalars["ID"]["input"];
  image: Scalars["Upload"]["input"];
  size: Scalars["Int"]["input"];
};

export type UploadCourseThumbnailResponse = {
  __typename?: "UploadCourseThumbnailResponse";
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UploadCourseThumbnailResponseAggregateSelection = {
  __typename?: "UploadCourseThumbnailResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  imageUrl: StringAggregateSelection;
  message: StringAggregateSelection;
};

export type UploadCourseThumbnailResponseCreateInput = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UploadCourseThumbnailResponseEdge = {
  __typename?: "UploadCourseThumbnailResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadCourseThumbnailResponse;
};

export type UploadCourseThumbnailResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadCourseThumbnailResponseSort objects to sort UploadCourseThumbnailResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadCourseThumbnailResponseSort>>;
};

/** Fields to sort UploadCourseThumbnailResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadCourseThumbnailResponseSort object. */
export type UploadCourseThumbnailResponseSort = {
  imageUrl?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadCourseThumbnailResponseUpdateInput = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadCourseThumbnailResponseWhere = {
  AND?: InputMaybe<Array<UploadCourseThumbnailResponseWhere>>;
  NOT?: InputMaybe<UploadCourseThumbnailResponseWhere>;
  OR?: InputMaybe<Array<UploadCourseThumbnailResponseWhere>>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadCourseThumbnailResponsesConnection = {
  __typename?: "UploadCourseThumbnailResponsesConnection";
  edges: Array<UploadCourseThumbnailResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UploadDocumentsResponse = {
  __typename?: "UploadDocumentsResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type UploadDocumentsResponseAggregateSelection = {
  __typename?: "UploadDocumentsResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UploadDocumentsResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type UploadDocumentsResponseEdge = {
  __typename?: "UploadDocumentsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadDocumentsResponse;
};

export type UploadDocumentsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadDocumentsResponseSort objects to sort UploadDocumentsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadDocumentsResponseSort>>;
};

/** Fields to sort UploadDocumentsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadDocumentsResponseSort object. */
export type UploadDocumentsResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadDocumentsResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadDocumentsResponseWhere = {
  AND?: InputMaybe<Array<UploadDocumentsResponseWhere>>;
  NOT?: InputMaybe<UploadDocumentsResponseWhere>;
  OR?: InputMaybe<Array<UploadDocumentsResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadDocumentsResponsesConnection = {
  __typename?: "UploadDocumentsResponsesConnection";
  edges: Array<UploadDocumentsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UploadDocumentsToBucketResponse = {
  __typename?: "UploadDocumentsToBucketResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
  urls: Array<Scalars["String"]["output"]>;
};

export type UploadDocumentsToBucketResponseAggregateSelection = {
  __typename?: "UploadDocumentsToBucketResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UploadDocumentsToBucketResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
  urls: Array<Scalars["String"]["input"]>;
};

export type UploadDocumentsToBucketResponseEdge = {
  __typename?: "UploadDocumentsToBucketResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadDocumentsToBucketResponse;
};

export type UploadDocumentsToBucketResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadDocumentsToBucketResponseSort objects to sort UploadDocumentsToBucketResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadDocumentsToBucketResponseSort>>;
};

/** Fields to sort UploadDocumentsToBucketResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadDocumentsToBucketResponseSort object. */
export type UploadDocumentsToBucketResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadDocumentsToBucketResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  urls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  urls_POP?: InputMaybe<Scalars["Int"]["input"]>;
  urls_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UploadDocumentsToBucketResponseWhere = {
  AND?: InputMaybe<Array<UploadDocumentsToBucketResponseWhere>>;
  NOT?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
  OR?: InputMaybe<Array<UploadDocumentsToBucketResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  urls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  urls_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadDocumentsToBucketResponsesConnection = {
  __typename?: "UploadDocumentsToBucketResponsesConnection";
  edges: Array<UploadDocumentsToBucketResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UploadFacultyProfileImageInput = {
  contentType: Scalars["String"]["input"];
  image?: InputMaybe<Scalars["Upload"]["input"]>;
  size: Scalars["Int"]["input"];
};

export type UploadFacultyProfileImageResponse = {
  __typename?: "UploadFacultyProfileImageResponse";
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UploadFacultyProfileImageResponseAggregateSelection = {
  __typename?: "UploadFacultyProfileImageResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  imageUrl: StringAggregateSelection;
  message: StringAggregateSelection;
};

export type UploadFacultyProfileImageResponseCreateInput = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UploadFacultyProfileImageResponseEdge = {
  __typename?: "UploadFacultyProfileImageResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadFacultyProfileImageResponse;
};

export type UploadFacultyProfileImageResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadFacultyProfileImageResponseSort objects to sort UploadFacultyProfileImageResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadFacultyProfileImageResponseSort>>;
};

/** Fields to sort UploadFacultyProfileImageResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadFacultyProfileImageResponseSort object. */
export type UploadFacultyProfileImageResponseSort = {
  imageUrl?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadFacultyProfileImageResponseUpdateInput = {
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadFacultyProfileImageResponseWhere = {
  AND?: InputMaybe<Array<UploadFacultyProfileImageResponseWhere>>;
  NOT?: InputMaybe<UploadFacultyProfileImageResponseWhere>;
  OR?: InputMaybe<Array<UploadFacultyProfileImageResponseWhere>>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadFacultyProfileImageResponsesConnection = {
  __typename?: "UploadFacultyProfileImageResponsesConnection";
  edges: Array<UploadFacultyProfileImageResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type User = {
  __typename?: "User";
  _empty?: Maybe<Scalars["String"]["output"]>;
  achievements: Array<Achievements>;
  achievementsAggregate?: Maybe<UserAchievementsAchievementsAggregationSelection>;
  achievementsConnection: UserAchievementsConnection;
  assessments: Array<Assessment>;
  assessmentsAggregate?: Maybe<UserAssessmentAssessmentsAggregationSelection>;
  assessmentsConnection: UserAssessmentsConnection;
  attributes: Array<Attribute>;
  attributesAggregate?: Maybe<UserAttributeAttributesAggregationSelection>;
  attributesConnection: UserAttributesConnection;
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
  biography?: Maybe<Scalars["String"]["output"]>;
  birthday?: Maybe<Scalars["DateTime"]["output"]>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["DateTime"]["output"];
  crmUserId?: Maybe<Scalars["String"]["output"]>;
  currentWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email: Scalars["String"]["output"];
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  enrolledCourses: Array<Course>;
  enrolledCoursesAggregate?: Maybe<UserCourseEnrolledCoursesAggregationSelection>;
  enrolledCoursesConnection: UserEnrolledCoursesConnection;
  enrolledDegrees: Array<Degree>;
  enrolledDegreesAggregate?: Maybe<UserDegreeEnrolledDegreesAggregationSelection>;
  enrolledDegreesConnection: UserEnrolledDegreesConnection;
  enrolledInTopics: Array<Topic>;
  enrolledInTopicsAggregate?: Maybe<UserTopicEnrolledInTopicsAggregationSelection>;
  enrolledInTopicsConnection: UserEnrolledInTopicsConnection;
  enrolledTracks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  feedback?: Maybe<Feedback>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  goalWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  googleAuthTokens?: Maybe<Scalars["String"]["output"]>;
  googleCalendarAuthToken?: Maybe<Scalars["String"]["output"]>;
  hasChapterSkills: Array<Chapter>;
  hasChapterSkillsAggregate?: Maybe<UserChapterHasChapterSkillsAggregationSelection>;
  hasChapterSkillsConnection: UserHasChapterSkillsConnection;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<UserChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: UserHasChatChatsConnection;
  hasFile: Array<File>;
  hasFileAggregate?: Maybe<UserFileHasFileAggregationSelection>;
  hasFileConnection: UserHasFileConnection;
  hasFoodItemFoodItems: Array<FoodItem>;
  hasFoodItemFoodItemsAggregate?: Maybe<UserFoodItemHasFoodItemFoodItemsAggregationSelection>;
  hasFoodItemFoodItemsConnection: UserHasFoodItemFoodItemsConnection;
  hasJourneyJourneys: Array<Journey>;
  hasJourneyJourneysAggregate?: Maybe<UserJourneyHasJourneyJourneysAggregationSelection>;
  hasJourneyJourneysConnection: UserHasJourneyJourneysConnection;
  hasKnowledgeChapters: Array<Chapter>;
  hasKnowledgeChaptersAggregate?: Maybe<UserChapterHasKnowledgeChaptersAggregationSelection>;
  hasKnowledgeChaptersConnection: UserHasKnowledgeChaptersConnection;
  hasKnowledgeTopics: Array<Topic>;
  hasKnowledgeTopicsAggregate?: Maybe<UserTopicHasKnowledgeTopicsAggregationSelection>;
  hasKnowledgeTopicsConnection: UserHasKnowledgeTopicsConnection;
  hasPaymentPayments: Array<Payment>;
  hasPaymentPaymentsAggregate?: Maybe<UserPaymentHasPaymentPaymentsAggregationSelection>;
  hasPaymentPaymentsConnection: UserHasPaymentPaymentsConnection;
  hasPreferencesPreferences: Array<Preferences>;
  hasPreferencesPreferencesAggregate?: Maybe<UserPreferencesHasPreferencesPreferencesAggregationSelection>;
  hasPreferencesPreferencesConnection: UserHasPreferencesPreferencesConnection;
  hasProgress: Array<Progress>;
  hasProgressAggregate?: Maybe<UserProgressHasProgressAggregationSelection>;
  hasProgressConnection: UserHasProgressConnection;
  hasRoleRoles: Array<Role>;
  hasRoleRolesAggregate?: Maybe<UserRoleHasRoleRolesAggregationSelection>;
  hasRoleRolesConnection: UserHasRoleRolesConnection;
  hasSessionDeviceSessions: Array<DeviceSession>;
  hasSessionDeviceSessionsAggregate?: Maybe<UserDeviceSessionHasSessionDeviceSessionsAggregationSelection>;
  hasSessionDeviceSessionsConnection: UserHasSessionDeviceSessionsConnection;
  hasSubchapterSkills: Array<Subchapter>;
  hasSubchapterSkillsAggregate?: Maybe<UserSubchapterHasSubchapterSkillsAggregationSelection>;
  hasSubchapterSkillsConnection: UserHasSubchapterSkillsConnection;
  hasSubmitted: Array<Submission>;
  hasSubmittedAggregate?: Maybe<UserSubmissionHasSubmittedAggregationSelection>;
  hasSubmittedConnection: UserHasSubmittedConnection;
  hasSuggestionSuggestions: Array<Suggestion>;
  hasSuggestionSuggestionsAggregate?: Maybe<UserSuggestionHasSuggestionSuggestionsAggregationSelection>;
  hasSuggestionSuggestionsConnection: UserHasSuggestionSuggestionsConnection;
  hasSummaryDailySummaries: Array<DailySummary>;
  hasSummaryDailySummariesAggregate?: Maybe<UserDailySummaryHasSummaryDailySummariesAggregationSelection>;
  hasSummaryDailySummariesConnection: UserHasSummaryDailySummariesConnection;
  hasSummaryMonthlySummaries: Array<MonthlySummary>;
  hasSummaryMonthlySummariesAggregate?: Maybe<UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection>;
  hasSummaryMonthlySummariesConnection: UserHasSummaryMonthlySummariesConnection;
  hasSummaryQuarterlySummaries: Array<QuarterlySummary>;
  hasSummaryQuarterlySummariesAggregate?: Maybe<UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection>;
  hasSummaryQuarterlySummariesConnection: UserHasSummaryQuarterlySummariesConnection;
  hasSummaryWeeklySummaries: Array<WeeklySummary>;
  hasSummaryWeeklySummariesAggregate?: Maybe<UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection>;
  hasSummaryWeeklySummariesConnection: UserHasSummaryWeeklySummariesConnection;
  hasSummaryYearlySummaries: Array<YearlySummary>;
  hasSummaryYearlySummariesAggregate?: Maybe<UserYearlySummaryHasSummaryYearlySummariesAggregationSelection>;
  hasSummaryYearlySummariesConnection: UserHasSummaryYearlySummariesConnection;
  hasWaterIntakeWaterIntakes: Array<WaterIntake>;
  hasWaterIntakeWaterIntakesAggregate?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection>;
  hasWaterIntakeWaterIntakesConnection: UserHasWaterIntakeWaterIntakesConnection;
  hasWhitelistedEmails: Array<WhitelistedEmail>;
  hasWhitelistedEmailsAggregate?: Maybe<UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection>;
  hasWhitelistedEmailsConnection: UserHasWhitelistedEmailsConnection;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  invitationToken?: Maybe<Scalars["String"]["output"]>;
  invitationTokenExpiry?: Maybe<Scalars["DateTime"]["output"]>;
  inviteCount?: Maybe<Scalars["Int"]["output"]>;
  isCareerLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isCourseLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  learningPayments: Array<LearningPayment>;
  learningPaymentsAggregate?: Maybe<UserLearningPaymentLearningPaymentsAggregationSelection>;
  learningPaymentsConnection: UserLearningPaymentsConnection;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  meetingLink?: Maybe<Scalars["String"]["output"]>;
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  password: Scalars["String"]["output"];
  pendingCourseEnrollmentInvite?: Maybe<Scalars["Boolean"]["output"]>;
  pendingFacultyInvite?: Maybe<Scalars["Boolean"]["output"]>;
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferences?: Maybe<Preferences>;
  profilePicture?: Maybe<Scalars["String"]["output"]>;
  progress?: Maybe<Progress>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  schoolAssignments: Array<Assignment>;
  schoolAssignmentsAggregate?: Maybe<UserAssignmentSchoolAssignmentsAggregationSelection>;
  schoolAssignmentsConnection: UserSchoolAssignmentsConnection;
  schools: Array<School>;
  schoolsAggregate?: Maybe<UserSchoolSchoolsAggregationSelection>;
  schoolsConnection: UserSchoolsConnection;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerIdLearningPayments?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  teachingCourses: Array<Course>;
  teachingCoursesAggregate?: Maybe<UserCourseTeachingCoursesAggregationSelection>;
  teachingCoursesConnection: UserTeachingCoursesConnection;
  tenants: Array<Tenant>;
  tenantsAggregate?: Maybe<UserTenantTenantsAggregationSelection>;
  tenantsConnection: UserTenantsConnection;
  todos: Array<Todo>;
  todosAggregate?: Maybe<UserTodoTodosAggregationSelection>;
  todosConnection: UserTodosConnection;
  trialEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  undefinedFoodItems: Array<FoodItem>;
  undefinedFoodItemsAggregate?: Maybe<UserFoodItemUndefinedFoodItemsAggregationSelection>;
  undefinedFoodItemsConnection: UserUndefinedFoodItemsConnection;
  undefinedWaterIntakes: Array<WaterIntake>;
  undefinedWaterIntakesAggregate?: Maybe<UserWaterIntakeUndefinedWaterIntakesAggregationSelection>;
  undefinedWaterIntakesConnection: UserUndefinedWaterIntakesConnection;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UserAchievementsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserAchievementsConnectionSort>>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAssessmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssessmentOptions>;
  where?: InputMaybe<AssessmentWhere>;
};

export type UserAssessmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssessmentWhere>;
};

export type UserAssessmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserAssessmentsConnectionSort>>;
  where?: InputMaybe<UserAssessmentsConnectionWhere>;
};

export type UserAttributesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type UserAttributesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AttributeWhere>;
};

export type UserAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserAttributesConnectionSort>>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserEnrolledCoursesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type UserEnrolledCoursesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type UserEnrolledCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserEnrolledCoursesConnectionSort>>;
  where?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
};

export type UserEnrolledDegreesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DegreeOptions>;
  where?: InputMaybe<DegreeWhere>;
};

export type UserEnrolledDegreesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DegreeWhere>;
};

export type UserEnrolledDegreesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserEnrolledDegreesConnectionSort>>;
  where?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
};

export type UserEnrolledInTopicsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TopicOptions>;
  where?: InputMaybe<TopicWhere>;
};

export type UserEnrolledInTopicsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TopicWhere>;
};

export type UserEnrolledInTopicsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserEnrolledInTopicsConnectionSort>>;
  where?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
};

export type UserHasChapterSkillsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type UserHasChapterSkillsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChapterWhere>;
};

export type UserHasChapterSkillsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasChapterSkillsConnectionSort>>;
  where?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
};

export type UserHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasChatChatsConnectionSort>>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasFileArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type UserHasFileAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FileWhere>;
};

export type UserHasFileConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasFileConnectionSort>>;
  where?: InputMaybe<UserHasFileConnectionWhere>;
};

export type UserHasFoodItemFoodItemsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionSort>>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasJourneyJourneysArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasJourneyJourneysConnectionSort>>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasKnowledgeChaptersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type UserHasKnowledgeChaptersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChapterWhere>;
};

export type UserHasKnowledgeChaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasKnowledgeChaptersConnectionSort>>;
  where?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
};

export type UserHasKnowledgeTopicsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TopicOptions>;
  where?: InputMaybe<TopicWhere>;
};

export type UserHasKnowledgeTopicsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TopicWhere>;
};

export type UserHasKnowledgeTopicsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasKnowledgeTopicsConnectionSort>>;
  where?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
};

export type UserHasPaymentPaymentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PaymentOptions>;
  where?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasPaymentPaymentsConnectionSort>>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPreferencesPreferencesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PreferencesOptions>;
  where?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionSort>>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasProgressArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ProgressOptions>;
  where?: InputMaybe<ProgressWhere>;
};

export type UserHasProgressAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ProgressWhere>;
};

export type UserHasProgressConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasProgressConnectionSort>>;
  where?: InputMaybe<UserHasProgressConnectionWhere>;
};

export type UserHasRoleRolesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasRoleRolesConnectionSort>>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasSessionDeviceSessionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DeviceSessionOptions>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionSort>>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSubchapterSkillsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubchapterOptions>;
  where?: InputMaybe<SubchapterWhere>;
};

export type UserHasSubchapterSkillsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubchapterWhere>;
};

export type UserHasSubchapterSkillsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSubchapterSkillsConnectionSort>>;
  where?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
};

export type UserHasSubmittedArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SubmissionOptions>;
  where?: InputMaybe<SubmissionWhere>;
};

export type UserHasSubmittedAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SubmissionWhere>;
};

export type UserHasSubmittedConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSubmittedConnectionSort>>;
  where?: InputMaybe<UserHasSubmittedConnectionWhere>;
};

export type UserHasSuggestionSuggestionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SuggestionOptions>;
  where?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionSort>>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSummaryDailySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DailySummaryOptions>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MonthlySummaryOptions>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<QuarterlySummaryOptions>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WeeklySummaryOptions>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<YearlySummaryOptions>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionSort>>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWhitelistedEmailsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WhitelistedEmailOptions>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionSort>>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserLearningPaymentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<LearningPaymentOptions>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type UserLearningPaymentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<LearningPaymentWhere>;
};

export type UserLearningPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserLearningPaymentsConnectionSort>>;
  where?: InputMaybe<UserLearningPaymentsConnectionWhere>;
};

export type UserSchoolAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type UserSchoolAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type UserSchoolAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserSchoolAssignmentsConnectionSort>>;
  where?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
};

export type UserSchoolsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type UserSchoolsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SchoolWhere>;
};

export type UserSchoolsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserSchoolsConnectionSort>>;
  where?: InputMaybe<UserSchoolsConnectionWhere>;
};

export type UserTeachingCoursesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type UserTeachingCoursesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type UserTeachingCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserTeachingCoursesConnectionSort>>;
  where?: InputMaybe<UserTeachingCoursesConnectionWhere>;
};

export type UserTenantsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TenantOptions>;
  where?: InputMaybe<TenantWhere>;
};

export type UserTenantsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TenantWhere>;
};

export type UserTenantsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserTenantsConnectionSort>>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTodosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TodoOptions>;
  where?: InputMaybe<TodoWhere>;
};

export type UserTodosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TodoWhere>;
};

export type UserTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserTodosConnectionSort>>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserUndefinedFoodItemsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserUndefinedFoodItemsConnectionSort>>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedWaterIntakesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionSort>>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserAchievementsAchievementsAggregationSelection = {
  __typename?: "UserAchievementsAchievementsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserAchievementsAchievementsNodeAggregateSelection>;
};

export type UserAchievementsAchievementsNodeAggregateSelection = {
  __typename?: "UserAchievementsAchievementsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type UserAchievementsAggregateInput = {
  AND?: InputMaybe<Array<UserAchievementsAggregateInput>>;
  NOT?: InputMaybe<UserAchievementsAggregateInput>;
  OR?: InputMaybe<Array<UserAchievementsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserAchievementsNodeAggregationWhereInput>;
};

export type UserAchievementsConnectFieldInput = {
  connect?: InputMaybe<Array<AchievementsConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AchievementsConnectWhere>;
};

export type UserAchievementsConnection = {
  __typename?: "UserAchievementsConnection";
  edges: Array<UserAchievementsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserAchievementsConnectionSort = {
  node?: InputMaybe<AchievementsSort>;
};

export type UserAchievementsConnectionWhere = {
  AND?: InputMaybe<Array<UserAchievementsConnectionWhere>>;
  NOT?: InputMaybe<UserAchievementsConnectionWhere>;
  OR?: InputMaybe<Array<UserAchievementsConnectionWhere>>;
  node?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsCreateFieldInput = {
  node: AchievementsCreateInput;
};

export type UserAchievementsDeleteFieldInput = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAchievementsDisconnectFieldInput = {
  disconnect?: InputMaybe<AchievementsDisconnectInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAchievementsFieldInput = {
  connect?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
};

export type UserAchievementsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAchievementsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAchievementsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAchievementsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserAchievementsRelationship = {
  __typename?: "UserAchievementsRelationship";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type UserAchievementsUpdateConnectionInput = {
  node?: InputMaybe<AchievementsUpdateInput>;
};

export type UserAchievementsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserAchievementsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserAchievementsDisconnectFieldInput>>;
  update?: InputMaybe<UserAchievementsUpdateConnectionInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAggregateSelection = {
  __typename?: "UserAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type UserAssessmentAssessmentsAggregationSelection = {
  __typename?: "UserAssessmentAssessmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserAssessmentAssessmentsEdgeAggregateSelection>;
  node?: Maybe<UserAssessmentAssessmentsNodeAggregateSelection>;
};

export type UserAssessmentAssessmentsEdgeAggregateSelection = {
  __typename?: "UserAssessmentAssessmentsEdgeAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  grade: FloatAggregateSelection;
  remainingTime: StringAggregateSelection;
};

export type UserAssessmentAssessmentsNodeAggregateSelection = {
  __typename?: "UserAssessmentAssessmentsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type UserAssessmentsAggregateInput = {
  AND?: InputMaybe<Array<UserAssessmentsAggregateInput>>;
  NOT?: InputMaybe<UserAssessmentsAggregateInput>;
  OR?: InputMaybe<Array<UserAssessmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasAssessmentPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserAssessmentsNodeAggregationWhereInput>;
};

export type UserAssessmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssessmentConnectInput>>;
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssessmentConnectWhere>;
};

export type UserAssessmentsConnectOrCreateFieldInput = {
  onCreate: UserAssessmentsConnectOrCreateFieldInputOnCreate;
  where: AssessmentConnectOrCreateWhere;
};

export type UserAssessmentsConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  node: AssessmentOnCreateInput;
};

export type UserAssessmentsConnection = {
  __typename?: "UserAssessmentsConnection";
  edges: Array<UserAssessmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserAssessmentsConnectionSort = {
  edge?: InputMaybe<HasAssessmentPropertiesSort>;
  node?: InputMaybe<AssessmentSort>;
};

export type UserAssessmentsConnectionWhere = {
  AND?: InputMaybe<Array<UserAssessmentsConnectionWhere>>;
  NOT?: InputMaybe<UserAssessmentsConnectionWhere>;
  OR?: InputMaybe<Array<UserAssessmentsConnectionWhere>>;
  edge?: InputMaybe<HasAssessmentPropertiesWhere>;
  node?: InputMaybe<AssessmentWhere>;
};

export type UserAssessmentsCreateFieldInput = {
  edge?: InputMaybe<HasAssessmentPropertiesCreateInput>;
  node: AssessmentCreateInput;
};

export type UserAssessmentsDeleteFieldInput = {
  delete?: InputMaybe<AssessmentDeleteInput>;
  where?: InputMaybe<UserAssessmentsConnectionWhere>;
};

export type UserAssessmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssessmentDisconnectInput>;
  where?: InputMaybe<UserAssessmentsConnectionWhere>;
};

export type UserAssessmentsFieldInput = {
  connect?: InputMaybe<Array<UserAssessmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserAssessmentsCreateFieldInput>>;
};

export type UserAssessmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAssessmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAssessmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAssessmentsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserAssessmentsRelationship = {
  __typename?: "UserAssessmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assessment;
  properties: HasAssessmentProperties;
};

export type UserAssessmentsUpdateConnectionInput = {
  edge?: InputMaybe<HasAssessmentPropertiesUpdateInput>;
  node?: InputMaybe<AssessmentUpdateInput>;
};

export type UserAssessmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserAssessmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserAssessmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserAssessmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserAssessmentsDisconnectFieldInput>>;
  update?: InputMaybe<UserAssessmentsUpdateConnectionInput>;
  where?: InputMaybe<UserAssessmentsConnectionWhere>;
};

export type UserAssignmentSchoolAssignmentsAggregationSelection = {
  __typename?: "UserAssignmentSchoolAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserAssignmentSchoolAssignmentsNodeAggregateSelection>;
};

export type UserAssignmentSchoolAssignmentsNodeAggregateSelection = {
  __typename?: "UserAssignmentSchoolAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: DateTimeAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grade_points: FloatAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  videoUrl: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type UserAttributeAttributesAggregationSelection = {
  __typename?: "UserAttributeAttributesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserAttributeAttributesNodeAggregateSelection>;
};

export type UserAttributeAttributesNodeAggregateSelection = {
  __typename?: "UserAttributeAttributesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type UserAttributesAggregateInput = {
  AND?: InputMaybe<Array<UserAttributesAggregateInput>>;
  NOT?: InputMaybe<UserAttributesAggregateInput>;
  OR?: InputMaybe<Array<UserAttributesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserAttributesNodeAggregationWhereInput>;
};

export type UserAttributesConnectFieldInput = {
  connect?: InputMaybe<Array<AttributeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AttributeConnectWhere>;
};

export type UserAttributesConnection = {
  __typename?: "UserAttributesConnection";
  edges: Array<UserAttributesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserAttributesConnectionSort = {
  node?: InputMaybe<AttributeSort>;
};

export type UserAttributesConnectionWhere = {
  AND?: InputMaybe<Array<UserAttributesConnectionWhere>>;
  NOT?: InputMaybe<UserAttributesConnectionWhere>;
  OR?: InputMaybe<Array<UserAttributesConnectionWhere>>;
  node?: InputMaybe<AttributeWhere>;
};

export type UserAttributesCreateFieldInput = {
  node: AttributeCreateInput;
};

export type UserAttributesDeleteFieldInput = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserAttributesDisconnectFieldInput = {
  disconnect?: InputMaybe<AttributeDisconnectInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserAttributesFieldInput = {
  connect?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
};

export type UserAttributesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAttributesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAttributesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAttributesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserAttributesRelationship = {
  __typename?: "UserAttributesRelationship";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type UserAttributesUpdateConnectionInput = {
  node?: InputMaybe<AttributeUpdateInput>;
};

export type UserAttributesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserAttributesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserAttributesDisconnectFieldInput>>;
  update?: InputMaybe<UserAttributesUpdateConnectionInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserCareerLoadingResponse = {
  __typename?: "UserCareerLoadingResponse";
  isCareerLoading: Scalars["Boolean"]["output"];
};

export type UserCareerLoadingResponseAggregateSelection = {
  __typename?: "UserCareerLoadingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type UserCareerLoadingResponseCreateInput = {
  isCareerLoading: Scalars["Boolean"]["input"];
};

export type UserCareerLoadingResponseEdge = {
  __typename?: "UserCareerLoadingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UserCareerLoadingResponse;
};

export type UserCareerLoadingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserCareerLoadingResponseSort objects to sort UserCareerLoadingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserCareerLoadingResponseSort>>;
};

/** Fields to sort UserCareerLoadingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserCareerLoadingResponseSort object. */
export type UserCareerLoadingResponseSort = {
  isCareerLoading?: InputMaybe<SortDirection>;
};

export type UserCareerLoadingResponseUpdateInput = {
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCareerLoadingResponseWhere = {
  AND?: InputMaybe<Array<UserCareerLoadingResponseWhere>>;
  NOT?: InputMaybe<UserCareerLoadingResponseWhere>;
  OR?: InputMaybe<Array<UserCareerLoadingResponseWhere>>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCareerLoadingResponsesConnection = {
  __typename?: "UserCareerLoadingResponsesConnection";
  edges: Array<UserCareerLoadingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserChapterHasChapterSkillsAggregationSelection = {
  __typename?: "UserChapterHasChapterSkillsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserChapterHasChapterSkillsNodeAggregateSelection>;
};

export type UserChapterHasChapterSkillsNodeAggregateSelection = {
  __typename?: "UserChapterHasChapterSkillsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type UserChapterHasKnowledgeChaptersAggregationSelection = {
  __typename?: "UserChapterHasKnowledgeChaptersAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserChapterHasKnowledgeChaptersEdgeAggregateSelection>;
  node?: Maybe<UserChapterHasKnowledgeChaptersNodeAggregateSelection>;
};

export type UserChapterHasKnowledgeChaptersEdgeAggregateSelection = {
  __typename?: "UserChapterHasKnowledgeChaptersEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  total_mastery: FloatAggregateSelection;
};

export type UserChapterHasKnowledgeChaptersNodeAggregateSelection = {
  __typename?: "UserChapterHasKnowledgeChaptersNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type UserChatHasChatChatsAggregationSelection = {
  __typename?: "UserChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<UserChatHasChatChatsNodeAggregateSelection>;
};

export type UserChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "UserChatHasChatChatsEdgeAggregateSelection";
  ai_messages_count: IntAggregateSelection;
  average_ai_response_time: FloatAggregateSelection;
  average_student_response_time: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  num_messages: IntAggregateSelection;
  questions_asked: IntAggregateSelection;
  student_messages_count: IntAggregateSelection;
  summary: StringAggregateSelection;
  total_time_taken: FloatAggregateSelection;
};

export type UserChatHasChatChatsNodeAggregateSelection = {
  __typename?: "UserChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type UserConnectInput = {
  achievements?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  assessments?: InputMaybe<Array<UserAssessmentsConnectFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesConnectFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesConnectFieldInput>>;
  enrolledInTopics?: InputMaybe<Array<UserEnrolledInTopicsConnectFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<UserHasChapterSkillsConnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  hasFile?: InputMaybe<Array<UserHasFileConnectFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  hasKnowledgeChapters?: InputMaybe<Array<UserHasKnowledgeChaptersConnectFieldInput>>;
  hasKnowledgeTopics?: InputMaybe<Array<UserHasKnowledgeTopicsConnectFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  hasProgress?: InputMaybe<Array<UserHasProgressConnectFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<UserHasSubchapterSkillsConnectFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedConnectFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsConnectFieldInput>>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsConnectFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsConnectFieldInput>>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesConnectFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  todos?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
};

export type UserConnectOrCreateInput = {
  assessments?: InputMaybe<Array<UserAssessmentsConnectOrCreateFieldInput>>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesConnectOrCreateFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesConnectOrCreateFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedConnectOrCreateFieldInput>>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsConnectOrCreateFieldInput>>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsConnectOrCreateFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsConnectOrCreateFieldInput>>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesConnectOrCreateFieldInput>>;
};

export type UserConnectOrCreateWhere = {
  node: UserUniqueWhere;
};

export type UserConnectWhere = {
  node: UserWhere;
};

export type UserCourseEnrolledCoursesAggregationSelection = {
  __typename?: "UserCourseEnrolledCoursesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserCourseEnrolledCoursesEdgeAggregateSelection>;
  node?: Maybe<UserCourseEnrolledCoursesNodeAggregateSelection>;
};

export type UserCourseEnrolledCoursesEdgeAggregateSelection = {
  __typename?: "UserCourseEnrolledCoursesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type UserCourseEnrolledCoursesNodeAggregateSelection = {
  __typename?: "UserCourseEnrolledCoursesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type UserCourseLoadingResponse = {
  __typename?: "UserCourseLoadingResponse";
  isCourseLoading: Scalars["Boolean"]["output"];
};

export type UserCourseLoadingResponseAggregateSelection = {
  __typename?: "UserCourseLoadingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type UserCourseLoadingResponseCreateInput = {
  isCourseLoading: Scalars["Boolean"]["input"];
};

export type UserCourseLoadingResponseEdge = {
  __typename?: "UserCourseLoadingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UserCourseLoadingResponse;
};

export type UserCourseLoadingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserCourseLoadingResponseSort objects to sort UserCourseLoadingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserCourseLoadingResponseSort>>;
};

/** Fields to sort UserCourseLoadingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserCourseLoadingResponseSort object. */
export type UserCourseLoadingResponseSort = {
  isCourseLoading?: InputMaybe<SortDirection>;
};

export type UserCourseLoadingResponseUpdateInput = {
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCourseLoadingResponseWhere = {
  AND?: InputMaybe<Array<UserCourseLoadingResponseWhere>>;
  NOT?: InputMaybe<UserCourseLoadingResponseWhere>;
  OR?: InputMaybe<Array<UserCourseLoadingResponseWhere>>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCourseLoadingResponsesConnection = {
  __typename?: "UserCourseLoadingResponsesConnection";
  edges: Array<UserCourseLoadingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserCourseTeachingCoursesAggregationSelection = {
  __typename?: "UserCourseTeachingCoursesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserCourseTeachingCoursesNodeAggregateSelection>;
};

export type UserCourseTeachingCoursesNodeAggregateSelection = {
  __typename?: "UserCourseTeachingCoursesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  cost: FloatAggregateSelection;
  courseId: StringAggregateSelection;
  courseType: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  startDate: DateTimeAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  stripePriceId: StringAggregateSelection;
  stripeProductId: StringAggregateSelection;
  targetAudience: StringAggregateSelection;
  thumbnail: StringAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type UserCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  achievements?: InputMaybe<UserAchievementsFieldInput>;
  assessments?: InputMaybe<UserAssessmentsFieldInput>;
  attributes?: InputMaybe<UserAttributesFieldInput>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  crmUserId?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  enrolledCourses?: InputMaybe<UserEnrolledCoursesFieldInput>;
  enrolledDegrees?: InputMaybe<UserEnrolledDegreesFieldInput>;
  enrolledInTopics?: InputMaybe<UserEnrolledInTopicsFieldInput>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  hasChapterSkills?: InputMaybe<UserHasChapterSkillsFieldInput>;
  hasChatChats?: InputMaybe<UserHasChatChatsFieldInput>;
  hasFile?: InputMaybe<UserHasFileFieldInput>;
  hasFoodItemFoodItems?: InputMaybe<UserHasFoodItemFoodItemsFieldInput>;
  hasJourneyJourneys?: InputMaybe<UserHasJourneyJourneysFieldInput>;
  hasKnowledgeChapters?: InputMaybe<UserHasKnowledgeChaptersFieldInput>;
  hasKnowledgeTopics?: InputMaybe<UserHasKnowledgeTopicsFieldInput>;
  hasPaymentPayments?: InputMaybe<UserHasPaymentPaymentsFieldInput>;
  hasPreferencesPreferences?: InputMaybe<UserHasPreferencesPreferencesFieldInput>;
  hasProgress?: InputMaybe<UserHasProgressFieldInput>;
  hasRoleRoles?: InputMaybe<UserHasRoleRolesFieldInput>;
  hasSessionDeviceSessions?: InputMaybe<UserHasSessionDeviceSessionsFieldInput>;
  hasSubchapterSkills?: InputMaybe<UserHasSubchapterSkillsFieldInput>;
  hasSubmitted?: InputMaybe<UserHasSubmittedFieldInput>;
  hasSuggestionSuggestions?: InputMaybe<UserHasSuggestionSuggestionsFieldInput>;
  hasSummaryDailySummaries?: InputMaybe<UserHasSummaryDailySummariesFieldInput>;
  hasSummaryMonthlySummaries?: InputMaybe<UserHasSummaryMonthlySummariesFieldInput>;
  hasSummaryQuarterlySummaries?: InputMaybe<UserHasSummaryQuarterlySummariesFieldInput>;
  hasSummaryWeeklySummaries?: InputMaybe<UserHasSummaryWeeklySummariesFieldInput>;
  hasSummaryYearlySummaries?: InputMaybe<UserHasSummaryYearlySummariesFieldInput>;
  hasWaterIntakeWaterIntakes?: InputMaybe<UserHasWaterIntakeWaterIntakesFieldInput>;
  hasWhitelistedEmails?: InputMaybe<UserHasWhitelistedEmailsFieldInput>;
  id: Scalars["ID"]["input"];
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  learningPayments?: InputMaybe<UserLearningPaymentsFieldInput>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  schoolAssignments?: InputMaybe<UserSchoolAssignmentsFieldInput>;
  schools?: InputMaybe<UserSchoolsFieldInput>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  teachingCourses?: InputMaybe<UserTeachingCoursesFieldInput>;
  tenants?: InputMaybe<UserTenantsFieldInput>;
  todos?: InputMaybe<UserTodosFieldInput>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  undefinedFoodItems?: InputMaybe<UserUndefinedFoodItemsFieldInput>;
  undefinedWaterIntakes?: InputMaybe<UserUndefinedWaterIntakesFieldInput>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserDailySummaryHasSummaryDailySummariesAggregationSelection = {
  __typename?: "UserDailySummaryHasSummaryDailySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection>;
};

export type UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection = {
  __typename?: "UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserDegreeEnrolledDegreesAggregationSelection = {
  __typename?: "UserDegreeEnrolledDegreesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserDegreeEnrolledDegreesEdgeAggregateSelection>;
  node?: Maybe<UserDegreeEnrolledDegreesNodeAggregateSelection>;
};

export type UserDegreeEnrolledDegreesEdgeAggregateSelection = {
  __typename?: "UserDegreeEnrolledDegreesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type UserDegreeEnrolledDegreesNodeAggregateSelection = {
  __typename?: "UserDegreeEnrolledDegreesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type UserDeleteInput = {
  achievements?: InputMaybe<Array<UserAchievementsDeleteFieldInput>>;
  assessments?: InputMaybe<Array<UserAssessmentsDeleteFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesDeleteFieldInput>>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesDeleteFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesDeleteFieldInput>>;
  enrolledInTopics?: InputMaybe<Array<UserEnrolledInTopicsDeleteFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<UserHasChapterSkillsDeleteFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsDeleteFieldInput>>;
  hasFile?: InputMaybe<Array<UserHasFileDeleteFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsDeleteFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysDeleteFieldInput>>;
  hasKnowledgeChapters?: InputMaybe<Array<UserHasKnowledgeChaptersDeleteFieldInput>>;
  hasKnowledgeTopics?: InputMaybe<Array<UserHasKnowledgeTopicsDeleteFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsDeleteFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesDeleteFieldInput>>;
  hasProgress?: InputMaybe<Array<UserHasProgressDeleteFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesDeleteFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsDeleteFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<UserHasSubchapterSkillsDeleteFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedDeleteFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsDeleteFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesDeleteFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesDeleteFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDeleteFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesDeleteFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesDeleteFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDeleteFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsDeleteFieldInput>>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsDeleteFieldInput>>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsDeleteFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsDeleteFieldInput>>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesDeleteFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsDeleteFieldInput>>;
  todos?: InputMaybe<Array<UserTodosDeleteFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsDeleteFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesDeleteFieldInput>>;
};

export type UserDeviceSessionHasSessionDeviceSessionsAggregationSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection>;
  node?: Maybe<UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection>;
};

export type UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection";
  createdAt: DateTimeAggregateSelection;
  expiresAt: DateTimeAggregateSelection;
};

export type UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection";
  id: StringAggregateSelection;
};

export type UserDisconnectInput = {
  achievements?: InputMaybe<Array<UserAchievementsDisconnectFieldInput>>;
  assessments?: InputMaybe<Array<UserAssessmentsDisconnectFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesDisconnectFieldInput>>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesDisconnectFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesDisconnectFieldInput>>;
  enrolledInTopics?: InputMaybe<Array<UserEnrolledInTopicsDisconnectFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<UserHasChapterSkillsDisconnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsDisconnectFieldInput>>;
  hasFile?: InputMaybe<Array<UserHasFileDisconnectFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsDisconnectFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysDisconnectFieldInput>>;
  hasKnowledgeChapters?: InputMaybe<Array<UserHasKnowledgeChaptersDisconnectFieldInput>>;
  hasKnowledgeTopics?: InputMaybe<Array<UserHasKnowledgeTopicsDisconnectFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsDisconnectFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesDisconnectFieldInput>>;
  hasProgress?: InputMaybe<Array<UserHasProgressDisconnectFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesDisconnectFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsDisconnectFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<UserHasSubchapterSkillsDisconnectFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedDisconnectFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsDisconnectFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesDisconnectFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesDisconnectFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDisconnectFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesDisconnectFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesDisconnectFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDisconnectFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsDisconnectFieldInput>>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsDisconnectFieldInput>>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsDisconnectFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsDisconnectFieldInput>>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesDisconnectFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsDisconnectFieldInput>>;
  todos?: InputMaybe<Array<UserTodosDisconnectFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsDisconnectFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesDisconnectFieldInput>>;
};

export type UserEdge = {
  __typename?: "UserEdge";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type UserEnrolledCoursesAggregateInput = {
  AND?: InputMaybe<Array<UserEnrolledCoursesAggregateInput>>;
  NOT?: InputMaybe<UserEnrolledCoursesAggregateInput>;
  OR?: InputMaybe<Array<UserEnrolledCoursesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserEnrolledCoursesNodeAggregationWhereInput>;
};

export type UserEnrolledCoursesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  edge: EnrolledInPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type UserEnrolledCoursesConnectOrCreateFieldInput = {
  onCreate: UserEnrolledCoursesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type UserEnrolledCoursesConnectOrCreateFieldInputOnCreate = {
  edge: EnrolledInPropertiesCreateInput;
  node: CourseOnCreateInput;
};

export type UserEnrolledCoursesConnection = {
  __typename?: "UserEnrolledCoursesConnection";
  edges: Array<UserEnrolledCoursesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEnrolledCoursesConnectionSort = {
  edge?: InputMaybe<EnrolledInPropertiesSort>;
  node?: InputMaybe<CourseSort>;
};

export type UserEnrolledCoursesConnectionWhere = {
  AND?: InputMaybe<Array<UserEnrolledCoursesConnectionWhere>>;
  NOT?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
  OR?: InputMaybe<Array<UserEnrolledCoursesConnectionWhere>>;
  edge?: InputMaybe<EnrolledInPropertiesWhere>;
  node?: InputMaybe<CourseWhere>;
};

export type UserEnrolledCoursesCreateFieldInput = {
  edge: EnrolledInPropertiesCreateInput;
  node: CourseCreateInput;
};

export type UserEnrolledCoursesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
};

export type UserEnrolledCoursesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
};

export type UserEnrolledCoursesFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledCoursesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserEnrolledCoursesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledCoursesCreateFieldInput>>;
};

export type UserEnrolledCoursesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserEnrolledCoursesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserEnrolledCoursesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserEnrolledCoursesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserEnrolledCoursesRelationship = {
  __typename?: "UserEnrolledCoursesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
  properties: EnrolledInProperties;
};

export type UserEnrolledCoursesUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInPropertiesUpdateInput>;
  node?: InputMaybe<CourseUpdateInput>;
};

export type UserEnrolledCoursesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledCoursesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserEnrolledCoursesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledCoursesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserEnrolledCoursesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserEnrolledCoursesDisconnectFieldInput>>;
  update?: InputMaybe<UserEnrolledCoursesUpdateConnectionInput>;
  where?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
};

export type UserEnrolledDegreesAggregateInput = {
  AND?: InputMaybe<Array<UserEnrolledDegreesAggregateInput>>;
  NOT?: InputMaybe<UserEnrolledDegreesAggregateInput>;
  OR?: InputMaybe<Array<UserEnrolledDegreesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInDegreePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserEnrolledDegreesNodeAggregationWhereInput>;
};

export type UserEnrolledDegreesConnectFieldInput = {
  connect?: InputMaybe<Array<DegreeConnectInput>>;
  edge: EnrolledInDegreePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DegreeConnectWhere>;
};

export type UserEnrolledDegreesConnectOrCreateFieldInput = {
  onCreate: UserEnrolledDegreesConnectOrCreateFieldInputOnCreate;
  where: DegreeConnectOrCreateWhere;
};

export type UserEnrolledDegreesConnectOrCreateFieldInputOnCreate = {
  edge: EnrolledInDegreePropertiesCreateInput;
  node: DegreeOnCreateInput;
};

export type UserEnrolledDegreesConnection = {
  __typename?: "UserEnrolledDegreesConnection";
  edges: Array<UserEnrolledDegreesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEnrolledDegreesConnectionSort = {
  edge?: InputMaybe<EnrolledInDegreePropertiesSort>;
  node?: InputMaybe<DegreeSort>;
};

export type UserEnrolledDegreesConnectionWhere = {
  AND?: InputMaybe<Array<UserEnrolledDegreesConnectionWhere>>;
  NOT?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
  OR?: InputMaybe<Array<UserEnrolledDegreesConnectionWhere>>;
  edge?: InputMaybe<EnrolledInDegreePropertiesWhere>;
  node?: InputMaybe<DegreeWhere>;
};

export type UserEnrolledDegreesCreateFieldInput = {
  edge: EnrolledInDegreePropertiesCreateInput;
  node: DegreeCreateInput;
};

export type UserEnrolledDegreesDeleteFieldInput = {
  delete?: InputMaybe<DegreeDeleteInput>;
  where?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
};

export type UserEnrolledDegreesDisconnectFieldInput = {
  disconnect?: InputMaybe<DegreeDisconnectInput>;
  where?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
};

export type UserEnrolledDegreesFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledDegreesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserEnrolledDegreesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledDegreesCreateFieldInput>>;
};

export type UserEnrolledDegreesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserEnrolledDegreesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserEnrolledDegreesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserEnrolledDegreesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserEnrolledDegreesRelationship = {
  __typename?: "UserEnrolledDegreesRelationship";
  cursor: Scalars["String"]["output"];
  node: Degree;
  properties: EnrolledInDegreeProperties;
};

export type UserEnrolledDegreesUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInDegreePropertiesUpdateInput>;
  node?: InputMaybe<DegreeUpdateInput>;
};

export type UserEnrolledDegreesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledDegreesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserEnrolledDegreesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledDegreesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserEnrolledDegreesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserEnrolledDegreesDisconnectFieldInput>>;
  update?: InputMaybe<UserEnrolledDegreesUpdateConnectionInput>;
  where?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
};

export type UserEnrolledInTopicsAggregateInput = {
  AND?: InputMaybe<Array<UserEnrolledInTopicsAggregateInput>>;
  NOT?: InputMaybe<UserEnrolledInTopicsAggregateInput>;
  OR?: InputMaybe<Array<UserEnrolledInTopicsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<EnrolledInPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserEnrolledInTopicsNodeAggregationWhereInput>;
};

export type UserEnrolledInTopicsConnectFieldInput = {
  connect?: InputMaybe<Array<TopicConnectInput>>;
  edge: EnrolledInPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TopicConnectWhere>;
};

export type UserEnrolledInTopicsConnection = {
  __typename?: "UserEnrolledInTopicsConnection";
  edges: Array<UserEnrolledInTopicsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEnrolledInTopicsConnectionSort = {
  edge?: InputMaybe<EnrolledInPropertiesSort>;
  node?: InputMaybe<TopicSort>;
};

export type UserEnrolledInTopicsConnectionWhere = {
  AND?: InputMaybe<Array<UserEnrolledInTopicsConnectionWhere>>;
  NOT?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
  OR?: InputMaybe<Array<UserEnrolledInTopicsConnectionWhere>>;
  edge?: InputMaybe<EnrolledInPropertiesWhere>;
  node?: InputMaybe<TopicWhere>;
};

export type UserEnrolledInTopicsCreateFieldInput = {
  edge: EnrolledInPropertiesCreateInput;
  node: TopicCreateInput;
};

export type UserEnrolledInTopicsDeleteFieldInput = {
  delete?: InputMaybe<TopicDeleteInput>;
  where?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
};

export type UserEnrolledInTopicsDisconnectFieldInput = {
  disconnect?: InputMaybe<TopicDisconnectInput>;
  where?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
};

export type UserEnrolledInTopicsFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledInTopicsConnectFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledInTopicsCreateFieldInput>>;
};

export type UserEnrolledInTopicsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserEnrolledInTopicsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserEnrolledInTopicsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserEnrolledInTopicsNodeAggregationWhereInput>>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  mastery_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserEnrolledInTopicsRelationship = {
  __typename?: "UserEnrolledInTopicsRelationship";
  cursor: Scalars["String"]["output"];
  node: Topic;
  properties: EnrolledInProperties;
};

export type UserEnrolledInTopicsUpdateConnectionInput = {
  edge?: InputMaybe<EnrolledInPropertiesUpdateInput>;
  node?: InputMaybe<TopicUpdateInput>;
};

export type UserEnrolledInTopicsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserEnrolledInTopicsConnectFieldInput>>;
  create?: InputMaybe<Array<UserEnrolledInTopicsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserEnrolledInTopicsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserEnrolledInTopicsDisconnectFieldInput>>;
  update?: InputMaybe<UserEnrolledInTopicsUpdateConnectionInput>;
  where?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
};

export type UserFileHasFileAggregationSelection = {
  __typename?: "UserFileHasFileAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserFileHasFileNodeAggregateSelection>;
};

export type UserFileHasFileNodeAggregateSelection = {
  __typename?: "UserFileHasFileNodeAggregateSelection";
  bucketName: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  fileName: StringAggregateSelection;
  filePath: StringAggregateSelection;
  fileURL: StringAggregateSelection;
  id: IdAggregateSelection;
};

export type UserFoodItemHasFoodItemFoodItemsAggregationSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection>;
  node?: Maybe<UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection>;
};

export type UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection";
  consumption_date: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  meal: StringAggregateSelection;
  meal_date: StringAggregateSelection;
  meal_time: StringAggregateSelection;
  meal_type: StringAggregateSelection;
  portion: FloatAggregateSelection;
  quality: BigIntAggregateSelection;
  quantity: FloatAggregateSelection;
  strength: BigIntAggregateSelection;
  time: StringAggregateSelection;
};

export type UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection";
  calories: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type UserFoodItemUndefinedFoodItemsAggregationSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserFoodItemUndefinedFoodItemsEdgeAggregateSelection>;
  node?: Maybe<UserFoodItemUndefinedFoodItemsNodeAggregateSelection>;
};

export type UserFoodItemUndefinedFoodItemsEdgeAggregateSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserFoodItemUndefinedFoodItemsNodeAggregateSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsNodeAggregateSelection";
  calories: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type UserHasChapterSkillsAggregateInput = {
  AND?: InputMaybe<Array<UserHasChapterSkillsAggregateInput>>;
  NOT?: InputMaybe<UserHasChapterSkillsAggregateInput>;
  OR?: InputMaybe<Array<UserHasChapterSkillsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasChapterSkillsNodeAggregationWhereInput>;
};

export type UserHasChapterSkillsConnectFieldInput = {
  connect?: InputMaybe<Array<ChapterConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChapterConnectWhere>;
};

export type UserHasChapterSkillsConnection = {
  __typename?: "UserHasChapterSkillsConnection";
  edges: Array<UserHasChapterSkillsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasChapterSkillsConnectionSort = {
  node?: InputMaybe<ChapterSort>;
};

export type UserHasChapterSkillsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasChapterSkillsConnectionWhere>>;
  NOT?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasChapterSkillsConnectionWhere>>;
  node?: InputMaybe<ChapterWhere>;
};

export type UserHasChapterSkillsCreateFieldInput = {
  node: ChapterCreateInput;
};

export type UserHasChapterSkillsDeleteFieldInput = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
};

export type UserHasChapterSkillsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChapterDisconnectInput>;
  where?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
};

export type UserHasChapterSkillsFieldInput = {
  connect?: InputMaybe<Array<UserHasChapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChapterSkillsCreateFieldInput>>;
};

export type UserHasChapterSkillsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasChapterSkillsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasChapterSkillsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasChapterSkillsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasChapterSkillsRelationship = {
  __typename?: "UserHasChapterSkillsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chapter;
};

export type UserHasChapterSkillsUpdateConnectionInput = {
  node?: InputMaybe<ChapterUpdateInput>;
};

export type UserHasChapterSkillsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasChapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChapterSkillsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasChapterSkillsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasChapterSkillsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasChapterSkillsUpdateConnectionInput>;
  where?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
};

export type UserHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<UserHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<UserHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<UserHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasChatChatsNodeAggregationWhereInput>;
};

export type UserHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type UserHasChatChatsConnection = {
  __typename?: "UserHasChatChatsConnection";
  edges: Array<UserHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type UserHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<UserHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type UserHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
};

export type UserHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasChatChatsRelationship = {
  __typename?: "UserHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type UserHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type UserHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasFileAggregateInput = {
  AND?: InputMaybe<Array<UserHasFileAggregateInput>>;
  NOT?: InputMaybe<UserHasFileAggregateInput>;
  OR?: InputMaybe<Array<UserHasFileAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasFileNodeAggregationWhereInput>;
};

export type UserHasFileConnectFieldInput = {
  connect?: InputMaybe<Array<FileConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FileConnectWhere>;
};

export type UserHasFileConnection = {
  __typename?: "UserHasFileConnection";
  edges: Array<UserHasFileRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasFileConnectionSort = {
  node?: InputMaybe<FileSort>;
};

export type UserHasFileConnectionWhere = {
  AND?: InputMaybe<Array<UserHasFileConnectionWhere>>;
  NOT?: InputMaybe<UserHasFileConnectionWhere>;
  OR?: InputMaybe<Array<UserHasFileConnectionWhere>>;
  node?: InputMaybe<FileWhere>;
};

export type UserHasFileCreateFieldInput = {
  node: FileCreateInput;
};

export type UserHasFileDeleteFieldInput = {
  delete?: InputMaybe<FileDeleteInput>;
  where?: InputMaybe<UserHasFileConnectionWhere>;
};

export type UserHasFileDisconnectFieldInput = {
  disconnect?: InputMaybe<FileDisconnectInput>;
  where?: InputMaybe<UserHasFileConnectionWhere>;
};

export type UserHasFileFieldInput = {
  connect?: InputMaybe<Array<UserHasFileConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFileCreateFieldInput>>;
};

export type UserHasFileNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasFileNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasFileNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasFileNodeAggregationWhereInput>>;
  bucketName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bucketName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bucketName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  filePath_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  filePath_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fileURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fileURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasFileRelationship = {
  __typename?: "UserHasFileRelationship";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type UserHasFileUpdateConnectionInput = {
  node?: InputMaybe<FileUpdateInput>;
};

export type UserHasFileUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasFileConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFileCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasFileDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasFileDisconnectFieldInput>>;
  update?: InputMaybe<UserHasFileUpdateConnectionInput>;
  where?: InputMaybe<UserHasFileConnectionWhere>;
};

export type UserHasFoodItemFoodItemsAggregateInput = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsAggregateInput>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsAggregateInput>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasFoodItemFoodItemsNodeAggregationWhereInput>;
};

export type UserHasFoodItemFoodItemsConnectFieldInput = {
  connect?: InputMaybe<Array<FoodItemConnectInput>>;
  edge: HasFoodItemPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FoodItemConnectWhere>;
};

export type UserHasFoodItemFoodItemsConnection = {
  __typename?: "UserHasFoodItemFoodItemsConnection";
  edges: Array<UserHasFoodItemFoodItemsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasFoodItemFoodItemsConnectionSort = {
  edge?: InputMaybe<HasFoodItemPropertiesSort>;
  node?: InputMaybe<FoodItemSort>;
};

export type UserHasFoodItemFoodItemsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionWhere>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionWhere>>;
  edge?: InputMaybe<HasFoodItemPropertiesWhere>;
  node?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsCreateFieldInput = {
  edge: HasFoodItemPropertiesCreateInput;
  node: FoodItemCreateInput;
};

export type UserHasFoodItemFoodItemsDeleteFieldInput = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsDisconnectFieldInput = {
  disconnect?: InputMaybe<FoodItemDisconnectInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsFieldInput = {
  connect?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
};

export type UserHasFoodItemFoodItemsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsNodeAggregationWhereInput>>;
  calories_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sugar_grams_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserHasFoodItemFoodItemsRelationship = {
  __typename?: "UserHasFoodItemFoodItemsRelationship";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
  properties: HasFoodItemProperties;
};

export type UserHasFoodItemFoodItemsUpdateConnectionInput = {
  edge?: InputMaybe<HasFoodItemPropertiesUpdateInput>;
  node?: InputMaybe<FoodItemUpdateInput>;
};

export type UserHasFoodItemFoodItemsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasFoodItemFoodItemsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasFoodItemFoodItemsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasFoodItemFoodItemsUpdateConnectionInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasJourneyJourneysAggregateInput = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysAggregateInput>>;
  NOT?: InputMaybe<UserHasJourneyJourneysAggregateInput>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasJourneyPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasJourneyJourneysNodeAggregationWhereInput>;
};

export type UserHasJourneyJourneysConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type UserHasJourneyJourneysConnection = {
  __typename?: "UserHasJourneyJourneysConnection";
  edges: Array<UserHasJourneyJourneysRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasJourneyJourneysConnectionSort = {
  edge?: InputMaybe<HasJourneyPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type UserHasJourneyJourneysConnectionWhere = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysConnectionWhere>>;
  NOT?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysConnectionWhere>>;
  edge?: InputMaybe<HasJourneyPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysCreateFieldInput = {
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type UserHasJourneyJourneysDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasJourneyJourneysDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasJourneyJourneysFieldInput = {
  connect?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
};

export type UserHasJourneyJourneysNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasJourneyJourneysNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  dueDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasJourneyJourneysRelationship = {
  __typename?: "UserHasJourneyJourneysRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasJourneyProperties;
};

export type UserHasJourneyJourneysUpdateConnectionInput = {
  edge?: InputMaybe<HasJourneyPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type UserHasJourneyJourneysUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasJourneyJourneysDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasJourneyJourneysDisconnectFieldInput>>;
  update?: InputMaybe<UserHasJourneyJourneysUpdateConnectionInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasKnowledgeChaptersAggregateInput = {
  AND?: InputMaybe<Array<UserHasKnowledgeChaptersAggregateInput>>;
  NOT?: InputMaybe<UserHasKnowledgeChaptersAggregateInput>;
  OR?: InputMaybe<Array<UserHasKnowledgeChaptersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasKnowledgePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasKnowledgeChaptersNodeAggregationWhereInput>;
};

export type UserHasKnowledgeChaptersConnectFieldInput = {
  connect?: InputMaybe<Array<ChapterConnectInput>>;
  edge: HasKnowledgePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChapterConnectWhere>;
};

export type UserHasKnowledgeChaptersConnection = {
  __typename?: "UserHasKnowledgeChaptersConnection";
  edges: Array<UserHasKnowledgeChaptersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasKnowledgeChaptersConnectionSort = {
  edge?: InputMaybe<HasKnowledgePropertiesSort>;
  node?: InputMaybe<ChapterSort>;
};

export type UserHasKnowledgeChaptersConnectionWhere = {
  AND?: InputMaybe<Array<UserHasKnowledgeChaptersConnectionWhere>>;
  NOT?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
  OR?: InputMaybe<Array<UserHasKnowledgeChaptersConnectionWhere>>;
  edge?: InputMaybe<HasKnowledgePropertiesWhere>;
  node?: InputMaybe<ChapterWhere>;
};

export type UserHasKnowledgeChaptersCreateFieldInput = {
  edge: HasKnowledgePropertiesCreateInput;
  node: ChapterCreateInput;
};

export type UserHasKnowledgeChaptersDeleteFieldInput = {
  delete?: InputMaybe<ChapterDeleteInput>;
  where?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
};

export type UserHasKnowledgeChaptersDisconnectFieldInput = {
  disconnect?: InputMaybe<ChapterDisconnectInput>;
  where?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
};

export type UserHasKnowledgeChaptersFieldInput = {
  connect?: InputMaybe<Array<UserHasKnowledgeChaptersConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasKnowledgeChaptersCreateFieldInput>>;
};

export type UserHasKnowledgeChaptersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasKnowledgeChaptersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasKnowledgeChaptersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasKnowledgeChaptersNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasKnowledgeChaptersRelationship = {
  __typename?: "UserHasKnowledgeChaptersRelationship";
  cursor: Scalars["String"]["output"];
  node: Chapter;
  properties: HasKnowledgeProperties;
};

export type UserHasKnowledgeChaptersUpdateConnectionInput = {
  edge?: InputMaybe<HasKnowledgePropertiesUpdateInput>;
  node?: InputMaybe<ChapterUpdateInput>;
};

export type UserHasKnowledgeChaptersUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasKnowledgeChaptersConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasKnowledgeChaptersCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasKnowledgeChaptersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasKnowledgeChaptersDisconnectFieldInput>>;
  update?: InputMaybe<UserHasKnowledgeChaptersUpdateConnectionInput>;
  where?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
};

export type UserHasKnowledgeTopicsAggregateInput = {
  AND?: InputMaybe<Array<UserHasKnowledgeTopicsAggregateInput>>;
  NOT?: InputMaybe<UserHasKnowledgeTopicsAggregateInput>;
  OR?: InputMaybe<Array<UserHasKnowledgeTopicsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasKnowledgePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasKnowledgeTopicsNodeAggregationWhereInput>;
};

export type UserHasKnowledgeTopicsConnectFieldInput = {
  connect?: InputMaybe<Array<TopicConnectInput>>;
  edge: HasKnowledgePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TopicConnectWhere>;
};

export type UserHasKnowledgeTopicsConnection = {
  __typename?: "UserHasKnowledgeTopicsConnection";
  edges: Array<UserHasKnowledgeTopicsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasKnowledgeTopicsConnectionSort = {
  edge?: InputMaybe<HasKnowledgePropertiesSort>;
  node?: InputMaybe<TopicSort>;
};

export type UserHasKnowledgeTopicsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasKnowledgeTopicsConnectionWhere>>;
  NOT?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasKnowledgeTopicsConnectionWhere>>;
  edge?: InputMaybe<HasKnowledgePropertiesWhere>;
  node?: InputMaybe<TopicWhere>;
};

export type UserHasKnowledgeTopicsCreateFieldInput = {
  edge: HasKnowledgePropertiesCreateInput;
  node: TopicCreateInput;
};

export type UserHasKnowledgeTopicsDeleteFieldInput = {
  delete?: InputMaybe<TopicDeleteInput>;
  where?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
};

export type UserHasKnowledgeTopicsDisconnectFieldInput = {
  disconnect?: InputMaybe<TopicDisconnectInput>;
  where?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
};

export type UserHasKnowledgeTopicsFieldInput = {
  connect?: InputMaybe<Array<UserHasKnowledgeTopicsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasKnowledgeTopicsCreateFieldInput>>;
};

export type UserHasKnowledgeTopicsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasKnowledgeTopicsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasKnowledgeTopicsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasKnowledgeTopicsNodeAggregationWhereInput>>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  imageUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  imageUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  mastery_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasKnowledgeTopicsRelationship = {
  __typename?: "UserHasKnowledgeTopicsRelationship";
  cursor: Scalars["String"]["output"];
  node: Topic;
  properties: HasKnowledgeProperties;
};

export type UserHasKnowledgeTopicsUpdateConnectionInput = {
  edge?: InputMaybe<HasKnowledgePropertiesUpdateInput>;
  node?: InputMaybe<TopicUpdateInput>;
};

export type UserHasKnowledgeTopicsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasKnowledgeTopicsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasKnowledgeTopicsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasKnowledgeTopicsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasKnowledgeTopicsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasKnowledgeTopicsUpdateConnectionInput>;
  where?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
};

export type UserHasPaymentPaymentsAggregateInput = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsAggregateInput>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsAggregateInput>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasPaymentPaymentsNodeAggregationWhereInput>;
};

export type UserHasPaymentPaymentsConnectFieldInput = {
  connect?: InputMaybe<Array<PaymentConnectInput>>;
  edge: HasPaymentPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PaymentConnectWhere>;
};

export type UserHasPaymentPaymentsConnection = {
  __typename?: "UserHasPaymentPaymentsConnection";
  edges: Array<UserHasPaymentPaymentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasPaymentPaymentsConnectionSort = {
  edge?: InputMaybe<HasPaymentPropertiesSort>;
  node?: InputMaybe<PaymentSort>;
};

export type UserHasPaymentPaymentsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsConnectionWhere>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsConnectionWhere>>;
  edge?: InputMaybe<HasPaymentPropertiesWhere>;
  node?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsCreateFieldInput = {
  edge: HasPaymentPropertiesCreateInput;
  node: PaymentCreateInput;
};

export type UserHasPaymentPaymentsDeleteFieldInput = {
  delete?: InputMaybe<PaymentDeleteInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPaymentPaymentsDisconnectFieldInput = {
  disconnect?: InputMaybe<PaymentDisconnectInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPaymentPaymentsFieldInput = {
  connect?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
};

export type UserHasPaymentPaymentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsNodeAggregationWhereInput>>;
  domain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasPaymentPaymentsRelationship = {
  __typename?: "UserHasPaymentPaymentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Payment;
  properties: HasPaymentProperties;
};

export type UserHasPaymentPaymentsUpdateConnectionInput = {
  edge?: InputMaybe<HasPaymentPropertiesUpdateInput>;
  node?: InputMaybe<PaymentUpdateInput>;
};

export type UserHasPaymentPaymentsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasPaymentPaymentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasPaymentPaymentsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasPaymentPaymentsUpdateConnectionInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPreferencesPreferencesAggregateInput = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesAggregateInput>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesAggregateInput>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasPreferencesPreferencesNodeAggregationWhereInput>;
};

export type UserHasPreferencesPreferencesConnectFieldInput = {
  connect?: InputMaybe<Array<PreferencesConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PreferencesConnectWhere>;
};

export type UserHasPreferencesPreferencesConnection = {
  __typename?: "UserHasPreferencesPreferencesConnection";
  edges: Array<UserHasPreferencesPreferencesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasPreferencesPreferencesConnectionSort = {
  node?: InputMaybe<PreferencesSort>;
};

export type UserHasPreferencesPreferencesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionWhere>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionWhere>>;
  node?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesCreateFieldInput = {
  node: PreferencesCreateInput;
};

export type UserHasPreferencesPreferencesDeleteFieldInput = {
  delete?: InputMaybe<PreferencesDeleteInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasPreferencesPreferencesDisconnectFieldInput = {
  disconnect?: InputMaybe<PreferencesDisconnectInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasPreferencesPreferencesFieldInput = {
  connect?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
};

export type UserHasPreferencesPreferencesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesNodeAggregationWhereInput>>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  language_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasPreferencesPreferencesRelationship = {
  __typename?: "UserHasPreferencesPreferencesRelationship";
  cursor: Scalars["String"]["output"];
  node: Preferences;
};

export type UserHasPreferencesPreferencesUpdateConnectionInput = {
  node?: InputMaybe<PreferencesUpdateInput>;
};

export type UserHasPreferencesPreferencesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasPreferencesPreferencesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasPreferencesPreferencesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasPreferencesPreferencesUpdateConnectionInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasProgressAggregateInput = {
  AND?: InputMaybe<Array<UserHasProgressAggregateInput>>;
  NOT?: InputMaybe<UserHasProgressAggregateInput>;
  OR?: InputMaybe<Array<UserHasProgressAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasProgressNodeAggregationWhereInput>;
};

export type UserHasProgressConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ProgressConnectWhere>;
};

export type UserHasProgressConnection = {
  __typename?: "UserHasProgressConnection";
  edges: Array<UserHasProgressRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasProgressConnectionSort = {
  node?: InputMaybe<ProgressSort>;
};

export type UserHasProgressConnectionWhere = {
  AND?: InputMaybe<Array<UserHasProgressConnectionWhere>>;
  NOT?: InputMaybe<UserHasProgressConnectionWhere>;
  OR?: InputMaybe<Array<UserHasProgressConnectionWhere>>;
  node?: InputMaybe<ProgressWhere>;
};

export type UserHasProgressCreateFieldInput = {
  node: ProgressCreateInput;
};

export type UserHasProgressDeleteFieldInput = {
  where?: InputMaybe<UserHasProgressConnectionWhere>;
};

export type UserHasProgressDisconnectFieldInput = {
  where?: InputMaybe<UserHasProgressConnectionWhere>;
};

export type UserHasProgressFieldInput = {
  connect?: InputMaybe<Array<UserHasProgressConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasProgressCreateFieldInput>>;
};

export type UserHasProgressNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasProgressNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasProgressNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasProgressNodeAggregationWhereInput>>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  level_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  level_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasProgressRelationship = {
  __typename?: "UserHasProgressRelationship";
  cursor: Scalars["String"]["output"];
  node: Progress;
};

export type UserHasProgressUpdateConnectionInput = {
  node?: InputMaybe<ProgressUpdateInput>;
};

export type UserHasProgressUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasProgressConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasProgressCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasProgressDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasProgressDisconnectFieldInput>>;
  update?: InputMaybe<UserHasProgressUpdateConnectionInput>;
  where?: InputMaybe<UserHasProgressConnectionWhere>;
};

export type UserHasRoleRolesAggregateInput = {
  AND?: InputMaybe<Array<UserHasRoleRolesAggregateInput>>;
  NOT?: InputMaybe<UserHasRoleRolesAggregateInput>;
  OR?: InputMaybe<Array<UserHasRoleRolesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasRoleRolesNodeAggregationWhereInput>;
};

export type UserHasRoleRolesConnectFieldInput = {
  connect?: InputMaybe<Array<RoleConnectInput>>;
  edge: UserRolePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<RoleConnectWhere>;
};

export type UserHasRoleRolesConnection = {
  __typename?: "UserHasRoleRolesConnection";
  edges: Array<UserHasRoleRolesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasRoleRolesConnectionSort = {
  edge?: InputMaybe<UserRolePropertiesSort>;
  node?: InputMaybe<RoleSort>;
};

export type UserHasRoleRolesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasRoleRolesConnectionWhere>>;
  NOT?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasRoleRolesConnectionWhere>>;
  edge?: InputMaybe<UserRolePropertiesWhere>;
  node?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesCreateFieldInput = {
  edge: UserRolePropertiesCreateInput;
  node: RoleCreateInput;
};

export type UserHasRoleRolesDeleteFieldInput = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasRoleRolesDisconnectFieldInput = {
  disconnect?: InputMaybe<RoleDisconnectInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasRoleRolesFieldInput = {
  connect?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
};

export type UserHasRoleRolesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasRoleRolesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasRoleRolesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasRoleRolesNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasRoleRolesRelationship = {
  __typename?: "UserHasRoleRolesRelationship";
  cursor: Scalars["String"]["output"];
  node: Role;
  properties: UserRoleProperties;
};

export type UserHasRoleRolesUpdateConnectionInput = {
  edge?: InputMaybe<UserRolePropertiesUpdateInput>;
  node?: InputMaybe<RoleUpdateInput>;
};

export type UserHasRoleRolesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasRoleRolesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasRoleRolesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasRoleRolesUpdateConnectionInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasSessionDeviceSessionsAggregateInput = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsAggregateInput>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsAggregateInput>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasSessionDeviceSessionsNodeAggregationWhereInput>;
};

export type UserHasSessionDeviceSessionsConnectFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionConnectInput>>;
  edge: HasSessionPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DeviceSessionConnectWhere>;
};

export type UserHasSessionDeviceSessionsConnection = {
  __typename?: "UserHasSessionDeviceSessionsConnection";
  edges: Array<UserHasSessionDeviceSessionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSessionDeviceSessionsConnectionSort = {
  edge?: InputMaybe<HasSessionPropertiesSort>;
  node?: InputMaybe<DeviceSessionSort>;
};

export type UserHasSessionDeviceSessionsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionWhere>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionWhere>>;
  edge?: InputMaybe<HasSessionPropertiesWhere>;
  node?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsCreateFieldInput = {
  edge: HasSessionPropertiesCreateInput;
  node: DeviceSessionCreateInput;
};

export type UserHasSessionDeviceSessionsDeleteFieldInput = {
  delete?: InputMaybe<DeviceSessionDeleteInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSessionDeviceSessionsDisconnectFieldInput = {
  disconnect?: InputMaybe<DeviceSessionDisconnectInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSessionDeviceSessionsFieldInput = {
  connect?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
};

export type UserHasSessionDeviceSessionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsNodeAggregationWhereInput>>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSessionDeviceSessionsRelationship = {
  __typename?: "UserHasSessionDeviceSessionsRelationship";
  cursor: Scalars["String"]["output"];
  node: DeviceSession;
  properties: HasSessionProperties;
};

export type UserHasSessionDeviceSessionsUpdateConnectionInput = {
  edge?: InputMaybe<HasSessionPropertiesUpdateInput>;
  node?: InputMaybe<DeviceSessionUpdateInput>;
};

export type UserHasSessionDeviceSessionsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSessionDeviceSessionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSessionDeviceSessionsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSessionDeviceSessionsUpdateConnectionInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSubchapterSkillsAggregateInput = {
  AND?: InputMaybe<Array<UserHasSubchapterSkillsAggregateInput>>;
  NOT?: InputMaybe<UserHasSubchapterSkillsAggregateInput>;
  OR?: InputMaybe<Array<UserHasSubchapterSkillsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSubchapterSkillsNodeAggregationWhereInput>;
};

export type UserHasSubchapterSkillsConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubchapterConnectWhere>;
};

export type UserHasSubchapterSkillsConnection = {
  __typename?: "UserHasSubchapterSkillsConnection";
  edges: Array<UserHasSubchapterSkillsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSubchapterSkillsConnectionSort = {
  node?: InputMaybe<SubchapterSort>;
};

export type UserHasSubchapterSkillsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSubchapterSkillsConnectionWhere>>;
  NOT?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSubchapterSkillsConnectionWhere>>;
  node?: InputMaybe<SubchapterWhere>;
};

export type UserHasSubchapterSkillsCreateFieldInput = {
  node: SubchapterCreateInput;
};

export type UserHasSubchapterSkillsDeleteFieldInput = {
  where?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
};

export type UserHasSubchapterSkillsDisconnectFieldInput = {
  where?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
};

export type UserHasSubchapterSkillsFieldInput = {
  connect?: InputMaybe<Array<UserHasSubchapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSubchapterSkillsCreateFieldInput>>;
};

export type UserHasSubchapterSkillsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSubchapterSkillsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSubchapterSkillsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSubchapterSkillsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSubchapterSkillsRelationship = {
  __typename?: "UserHasSubchapterSkillsRelationship";
  cursor: Scalars["String"]["output"];
  node: Subchapter;
};

export type UserHasSubchapterSkillsUpdateConnectionInput = {
  node?: InputMaybe<SubchapterUpdateInput>;
};

export type UserHasSubchapterSkillsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSubchapterSkillsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSubchapterSkillsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSubchapterSkillsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSubchapterSkillsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSubchapterSkillsUpdateConnectionInput>;
  where?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
};

export type UserHasSubmittedAggregateInput = {
  AND?: InputMaybe<Array<UserHasSubmittedAggregateInput>>;
  NOT?: InputMaybe<UserHasSubmittedAggregateInput>;
  OR?: InputMaybe<Array<UserHasSubmittedAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSubmittedNodeAggregationWhereInput>;
};

export type UserHasSubmittedConnectFieldInput = {
  connect?: InputMaybe<Array<SubmissionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SubmissionConnectWhere>;
};

export type UserHasSubmittedConnectOrCreateFieldInput = {
  onCreate: UserHasSubmittedConnectOrCreateFieldInputOnCreate;
  where: SubmissionConnectOrCreateWhere;
};

export type UserHasSubmittedConnectOrCreateFieldInputOnCreate = {
  node: SubmissionOnCreateInput;
};

export type UserHasSubmittedConnection = {
  __typename?: "UserHasSubmittedConnection";
  edges: Array<UserHasSubmittedRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSubmittedConnectionSort = {
  node?: InputMaybe<SubmissionSort>;
};

export type UserHasSubmittedConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSubmittedConnectionWhere>>;
  NOT?: InputMaybe<UserHasSubmittedConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSubmittedConnectionWhere>>;
  node?: InputMaybe<SubmissionWhere>;
};

export type UserHasSubmittedCreateFieldInput = {
  node: SubmissionCreateInput;
};

export type UserHasSubmittedDeleteFieldInput = {
  delete?: InputMaybe<SubmissionDeleteInput>;
  where?: InputMaybe<UserHasSubmittedConnectionWhere>;
};

export type UserHasSubmittedDisconnectFieldInput = {
  disconnect?: InputMaybe<SubmissionDisconnectInput>;
  where?: InputMaybe<UserHasSubmittedConnectionWhere>;
};

export type UserHasSubmittedFieldInput = {
  connect?: InputMaybe<Array<UserHasSubmittedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserHasSubmittedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserHasSubmittedCreateFieldInput>>;
};

export type UserHasSubmittedNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSubmittedNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSubmittedNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSubmittedNodeAggregationWhereInput>>;
  completedAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completedAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  feedback_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  feedback_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  feedback_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserHasSubmittedRelationship = {
  __typename?: "UserHasSubmittedRelationship";
  cursor: Scalars["String"]["output"];
  node: Submission;
};

export type UserHasSubmittedUpdateConnectionInput = {
  node?: InputMaybe<SubmissionUpdateInput>;
};

export type UserHasSubmittedUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSubmittedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserHasSubmittedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserHasSubmittedCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSubmittedDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSubmittedDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSubmittedUpdateConnectionInput>;
  where?: InputMaybe<UserHasSubmittedConnectionWhere>;
};

export type UserHasSuggestionSuggestionsAggregateInput = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsAggregateInput>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsAggregateInput>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSuggestionSuggestionsNodeAggregationWhereInput>;
};

export type UserHasSuggestionSuggestionsConnectFieldInput = {
  connect?: InputMaybe<Array<SuggestionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SuggestionConnectWhere>;
};

export type UserHasSuggestionSuggestionsConnection = {
  __typename?: "UserHasSuggestionSuggestionsConnection";
  edges: Array<UserHasSuggestionSuggestionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSuggestionSuggestionsConnectionSort = {
  node?: InputMaybe<SuggestionSort>;
};

export type UserHasSuggestionSuggestionsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionWhere>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionWhere>>;
  node?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsCreateFieldInput = {
  node: SuggestionCreateInput;
};

export type UserHasSuggestionSuggestionsDeleteFieldInput = {
  delete?: InputMaybe<SuggestionDeleteInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsDisconnectFieldInput = {
  disconnect?: InputMaybe<SuggestionDisconnectInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsFieldInput = {
  connect?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
};

export type UserHasSuggestionSuggestionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsNodeAggregationWhereInput>>;
  assignmentId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  classId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  integrationId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSuggestionSuggestionsRelationship = {
  __typename?: "UserHasSuggestionSuggestionsRelationship";
  cursor: Scalars["String"]["output"];
  node: Suggestion;
};

export type UserHasSuggestionSuggestionsUpdateConnectionInput = {
  node?: InputMaybe<SuggestionUpdateInput>;
};

export type UserHasSuggestionSuggestionsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSuggestionSuggestionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSuggestionSuggestionsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSuggestionSuggestionsUpdateConnectionInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSummaryDailySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryDailySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryDailySummariesConnectFieldInput = {
  connect?: InputMaybe<Array<DailySummaryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DailySummaryConnectWhere>;
};

export type UserHasSummaryDailySummariesConnection = {
  __typename?: "UserHasSummaryDailySummariesConnection";
  edges: Array<UserHasSummaryDailySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryDailySummariesConnectionSort = {
  node?: InputMaybe<DailySummarySort>;
};

export type UserHasSummaryDailySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionWhere>>;
  node?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesCreateFieldInput = {
  node: DailySummaryCreateInput;
};

export type UserHasSummaryDailySummariesDeleteFieldInput = {
  delete?: InputMaybe<DailySummaryDeleteInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryDailySummariesDisconnectFieldInput = {
  disconnect?: InputMaybe<DailySummaryDisconnectInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryDailySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
};

export type UserHasSummaryDailySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryDailySummariesRelationship = {
  __typename?: "UserHasSummaryDailySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: DailySummary;
};

export type UserHasSummaryDailySummariesUpdateConnectionInput = {
  node?: InputMaybe<DailySummaryUpdateInput>;
};

export type UserHasSummaryDailySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryDailySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryDailySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryDailySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryMonthlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MonthlySummaryConnectWhere>;
};

export type UserHasSummaryMonthlySummariesConnection = {
  __typename?: "UserHasSummaryMonthlySummariesConnection";
  edges: Array<UserHasSummaryMonthlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryMonthlySummariesConnectionSort = {
  node?: InputMaybe<MonthlySummarySort>;
};

export type UserHasSummaryMonthlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionWhere>>;
  node?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesCreateFieldInput = {
  node: MonthlySummaryCreateInput;
};

export type UserHasSummaryMonthlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
};

export type UserHasSummaryMonthlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryMonthlySummariesRelationship = {
  __typename?: "UserHasSummaryMonthlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: MonthlySummary;
};

export type UserHasSummaryMonthlySummariesUpdateConnectionInput = {
  node?: InputMaybe<MonthlySummaryUpdateInput>;
};

export type UserHasSummaryMonthlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryMonthlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryMonthlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryMonthlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryQuarterlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<QuarterlySummaryConnectWhere>;
};

export type UserHasSummaryQuarterlySummariesConnection = {
  __typename?: "UserHasSummaryQuarterlySummariesConnection";
  edges: Array<UserHasSummaryQuarterlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryQuarterlySummariesConnectionSort = {
  node?: InputMaybe<QuarterlySummarySort>;
};

export type UserHasSummaryQuarterlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionWhere>>;
  node?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesCreateFieldInput = {
  node: QuarterlySummaryCreateInput;
};

export type UserHasSummaryQuarterlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
};

export type UserHasSummaryQuarterlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryQuarterlySummariesRelationship = {
  __typename?: "UserHasSummaryQuarterlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: QuarterlySummary;
};

export type UserHasSummaryQuarterlySummariesUpdateConnectionInput = {
  node?: InputMaybe<QuarterlySummaryUpdateInput>;
};

export type UserHasSummaryQuarterlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryQuarterlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryWeeklySummariesConnectFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WeeklySummaryConnectWhere>;
};

export type UserHasSummaryWeeklySummariesConnection = {
  __typename?: "UserHasSummaryWeeklySummariesConnection";
  edges: Array<UserHasSummaryWeeklySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryWeeklySummariesConnectionSort = {
  node?: InputMaybe<WeeklySummarySort>;
};

export type UserHasSummaryWeeklySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionWhere>>;
  node?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesCreateFieldInput = {
  node: WeeklySummaryCreateInput;
};

export type UserHasSummaryWeeklySummariesDeleteFieldInput = {
  delete?: InputMaybe<WeeklySummaryDeleteInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesDisconnectFieldInput = {
  disconnect?: InputMaybe<WeeklySummaryDisconnectInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
};

export type UserHasSummaryWeeklySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryWeeklySummariesRelationship = {
  __typename?: "UserHasSummaryWeeklySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: WeeklySummary;
};

export type UserHasSummaryWeeklySummariesUpdateConnectionInput = {
  node?: InputMaybe<WeeklySummaryUpdateInput>;
};

export type UserHasSummaryWeeklySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryWeeklySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryWeeklySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryWeeklySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryYearlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryYearlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<YearlySummaryConnectWhere>;
};

export type UserHasSummaryYearlySummariesConnection = {
  __typename?: "UserHasSummaryYearlySummariesConnection";
  edges: Array<UserHasSummaryYearlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryYearlySummariesConnectionSort = {
  node?: InputMaybe<YearlySummarySort>;
};

export type UserHasSummaryYearlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionWhere>>;
  node?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesCreateFieldInput = {
  node: YearlySummaryCreateInput;
};

export type UserHasSummaryYearlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
};

export type UserHasSummaryYearlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryYearlySummariesRelationship = {
  __typename?: "UserHasSummaryYearlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: YearlySummary;
};

export type UserHasSummaryYearlySummariesUpdateConnectionInput = {
  node?: InputMaybe<YearlySummaryUpdateInput>;
};

export type UserHasSummaryYearlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryYearlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryYearlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryYearlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesAggregateInput = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesAggregateInput>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesAggregateInput>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>;
};

export type UserHasWaterIntakeWaterIntakesConnectFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeConnectInput>>;
  edge: HasWaterIntakePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WaterIntakeConnectWhere>;
};

export type UserHasWaterIntakeWaterIntakesConnection = {
  __typename?: "UserHasWaterIntakeWaterIntakesConnection";
  edges: Array<UserHasWaterIntakeWaterIntakesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasWaterIntakeWaterIntakesConnectionSort = {
  edge?: InputMaybe<HasWaterIntakePropertiesSort>;
  node?: InputMaybe<WaterIntakeSort>;
};

export type UserHasWaterIntakeWaterIntakesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionWhere>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionWhere>>;
  edge?: InputMaybe<HasWaterIntakePropertiesWhere>;
  node?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesCreateFieldInput = {
  edge: HasWaterIntakePropertiesCreateInput;
  node: WaterIntakeCreateInput;
};

export type UserHasWaterIntakeWaterIntakesDeleteFieldInput = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesDisconnectFieldInput = {
  disconnect?: InputMaybe<WaterIntakeDisconnectInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesFieldInput = {
  connect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
};

export type UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserHasWaterIntakeWaterIntakesRelationship = {
  __typename?: "UserHasWaterIntakeWaterIntakesRelationship";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
  properties: HasWaterIntakeProperties;
};

export type UserHasWaterIntakeWaterIntakesUpdateConnectionInput = {
  edge?: InputMaybe<HasWaterIntakePropertiesUpdateInput>;
  node?: InputMaybe<WaterIntakeUpdateInput>;
};

export type UserHasWaterIntakeWaterIntakesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasWaterIntakeWaterIntakesUpdateConnectionInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWhitelistedEmailsAggregateInput = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsAggregateInput>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsAggregateInput>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasWhitelistedEmailsNodeAggregationWhereInput>;
};

export type UserHasWhitelistedEmailsConnectFieldInput = {
  connect?: InputMaybe<Array<WhitelistedEmailConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WhitelistedEmailConnectWhere>;
};

export type UserHasWhitelistedEmailsConnection = {
  __typename?: "UserHasWhitelistedEmailsConnection";
  edges: Array<UserHasWhitelistedEmailsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasWhitelistedEmailsConnectionSort = {
  node?: InputMaybe<WhitelistedEmailSort>;
};

export type UserHasWhitelistedEmailsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionWhere>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionWhere>>;
  node?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsCreateFieldInput = {
  node: WhitelistedEmailCreateInput;
};

export type UserHasWhitelistedEmailsDeleteFieldInput = {
  delete?: InputMaybe<WhitelistedEmailDeleteInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserHasWhitelistedEmailsDisconnectFieldInput = {
  disconnect?: InputMaybe<WhitelistedEmailDisconnectInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserHasWhitelistedEmailsFieldInput = {
  connect?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
};

export type UserHasWhitelistedEmailsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsNodeAggregationWhereInput>>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasWhitelistedEmailsRelationship = {
  __typename?: "UserHasWhitelistedEmailsRelationship";
  cursor: Scalars["String"]["output"];
  node: WhitelistedEmail;
};

export type UserHasWhitelistedEmailsUpdateConnectionInput = {
  node?: InputMaybe<WhitelistedEmailUpdateInput>;
};

export type UserHasWhitelistedEmailsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasWhitelistedEmailsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasWhitelistedEmailsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasWhitelistedEmailsUpdateConnectionInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserInput = {
  id: Scalars["ID"]["input"];
};

export type UserJourneyHasJourneyJourneysAggregationSelection = {
  __typename?: "UserJourneyHasJourneyJourneysAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserJourneyHasJourneyJourneysEdgeAggregateSelection>;
  node?: Maybe<UserJourneyHasJourneyJourneysNodeAggregateSelection>;
};

export type UserJourneyHasJourneyJourneysEdgeAggregateSelection = {
  __typename?: "UserJourneyHasJourneyJourneysEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type UserJourneyHasJourneyJourneysNodeAggregateSelection = {
  __typename?: "UserJourneyHasJourneyJourneysNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  dueDate: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type UserLearningPaymentLearningPaymentsAggregationSelection = {
  __typename?: "UserLearningPaymentLearningPaymentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserLearningPaymentLearningPaymentsNodeAggregateSelection>;
};

export type UserLearningPaymentLearningPaymentsNodeAggregateSelection = {
  __typename?: "UserLearningPaymentLearningPaymentsNodeAggregateSelection";
  amount: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
};

export type UserLearningPaymentsAggregateInput = {
  AND?: InputMaybe<Array<UserLearningPaymentsAggregateInput>>;
  NOT?: InputMaybe<UserLearningPaymentsAggregateInput>;
  OR?: InputMaybe<Array<UserLearningPaymentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserLearningPaymentsNodeAggregationWhereInput>;
};

export type UserLearningPaymentsConnectFieldInput = {
  connect?: InputMaybe<Array<LearningPaymentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<LearningPaymentConnectWhere>;
};

export type UserLearningPaymentsConnectOrCreateFieldInput = {
  onCreate: UserLearningPaymentsConnectOrCreateFieldInputOnCreate;
  where: LearningPaymentConnectOrCreateWhere;
};

export type UserLearningPaymentsConnectOrCreateFieldInputOnCreate = {
  node: LearningPaymentOnCreateInput;
};

export type UserLearningPaymentsConnection = {
  __typename?: "UserLearningPaymentsConnection";
  edges: Array<UserLearningPaymentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserLearningPaymentsConnectionSort = {
  node?: InputMaybe<LearningPaymentSort>;
};

export type UserLearningPaymentsConnectionWhere = {
  AND?: InputMaybe<Array<UserLearningPaymentsConnectionWhere>>;
  NOT?: InputMaybe<UserLearningPaymentsConnectionWhere>;
  OR?: InputMaybe<Array<UserLearningPaymentsConnectionWhere>>;
  node?: InputMaybe<LearningPaymentWhere>;
};

export type UserLearningPaymentsCreateFieldInput = {
  node: LearningPaymentCreateInput;
};

export type UserLearningPaymentsDeleteFieldInput = {
  delete?: InputMaybe<LearningPaymentDeleteInput>;
  where?: InputMaybe<UserLearningPaymentsConnectionWhere>;
};

export type UserLearningPaymentsDisconnectFieldInput = {
  disconnect?: InputMaybe<LearningPaymentDisconnectInput>;
  where?: InputMaybe<UserLearningPaymentsConnectionWhere>;
};

export type UserLearningPaymentsFieldInput = {
  connect?: InputMaybe<Array<UserLearningPaymentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserLearningPaymentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserLearningPaymentsCreateFieldInput>>;
};

export type UserLearningPaymentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserLearningPaymentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserLearningPaymentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserLearningPaymentsNodeAggregationWhereInput>>;
  amount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  amount_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type UserLearningPaymentsRelationship = {
  __typename?: "UserLearningPaymentsRelationship";
  cursor: Scalars["String"]["output"];
  node: LearningPayment;
};

export type UserLearningPaymentsUpdateConnectionInput = {
  node?: InputMaybe<LearningPaymentUpdateInput>;
};

export type UserLearningPaymentsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserLearningPaymentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserLearningPaymentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserLearningPaymentsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserLearningPaymentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserLearningPaymentsDisconnectFieldInput>>;
  update?: InputMaybe<UserLearningPaymentsUpdateConnectionInput>;
  where?: InputMaybe<UserLearningPaymentsConnectionWhere>;
};

export type UserMasteriesConnection = {
  __typename?: "UserMasteriesConnection";
  edges: Array<UserMasteryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserMastery = {
  __typename?: "UserMastery";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type UserMasteryAggregateSelection = {
  __typename?: "UserMasteryAggregateSelection";
  count: Scalars["Int"]["output"];
  lastModified: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type UserMasteryCreateInput = {
  lastModified: Scalars["String"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMasteryEdge = {
  __typename?: "UserMasteryEdge";
  cursor: Scalars["String"]["output"];
  node: UserMastery;
};

export type UserMasteryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserMasterySort objects to sort UserMasteries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserMasterySort>>;
};

/** Fields to sort UserMasteries by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserMasterySort object. */
export type UserMasterySort = {
  lastModified?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type UserMasteryUpdateInput = {
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMasteryWhere = {
  AND?: InputMaybe<Array<UserMasteryWhere>>;
  NOT?: InputMaybe<UserMasteryWhere>;
  OR?: InputMaybe<Array<UserMasteryWhere>>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection = {
  __typename?: "UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection>;
};

export type UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection = {
  __typename?: "UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserOnCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  crmUserId?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserSort objects to sort Users by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserSort>>;
};

export type UserPaymentHasPaymentPaymentsAggregationSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserPaymentHasPaymentPaymentsEdgeAggregateSelection>;
  node?: Maybe<UserPaymentHasPaymentPaymentsNodeAggregateSelection>;
};

export type UserPaymentHasPaymentPaymentsEdgeAggregateSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsEdgeAggregateSelection";
  integrationId: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
};

export type UserPaymentHasPaymentPaymentsNodeAggregateSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsNodeAggregateSelection";
  domain: StringAggregateSelection;
};

export type UserPreferencesHasPreferencesPreferencesAggregationSelection = {
  __typename?: "UserPreferencesHasPreferencesPreferencesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserPreferencesHasPreferencesPreferencesNodeAggregateSelection>;
};

export type UserPreferencesHasPreferencesPreferencesNodeAggregateSelection = {
  __typename?: "UserPreferencesHasPreferencesPreferencesNodeAggregateSelection";
  contentType: StringAggregateSelection;
  language: StringAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
};

export type UserProgress = {
  __typename?: "UserProgress";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  parent?: Maybe<ChildInfo>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type UserProgressAggregateSelection = {
  __typename?: "UserProgressAggregateSelection";
  count: Scalars["Int"]["output"];
  lastModified: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type UserProgressCreateInput = {
  lastModified: Scalars["String"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressEdge = {
  __typename?: "UserProgressEdge";
  cursor: Scalars["String"]["output"];
  node: UserProgress;
};

export type UserProgressHasProgressAggregationSelection = {
  __typename?: "UserProgressHasProgressAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserProgressHasProgressNodeAggregateSelection>;
};

export type UserProgressHasProgressNodeAggregateSelection = {
  __typename?: "UserProgressHasProgressNodeAggregateSelection";
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  level: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type UserProgressOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserProgressSort objects to sort UserProgresses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserProgressSort>>;
};

/** Fields to sort UserProgresses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserProgressSort object. */
export type UserProgressSort = {
  lastModified?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type UserProgressUpdateInput = {
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressWhere = {
  AND?: InputMaybe<Array<UserProgressWhere>>;
  NOT?: InputMaybe<UserProgressWhere>;
  OR?: InputMaybe<Array<UserProgressWhere>>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressesConnection = {
  __typename?: "UserProgressesConnection";
  edges: Array<UserProgressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection = {
  __typename?: "UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection>;
};

export type UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection = {
  __typename?: "UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserRelationInput = {
  achievements?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
  assessments?: InputMaybe<Array<UserAssessmentsCreateFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesCreateFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesCreateFieldInput>>;
  enrolledInTopics?: InputMaybe<Array<UserEnrolledInTopicsCreateFieldInput>>;
  hasChapterSkills?: InputMaybe<Array<UserHasChapterSkillsCreateFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
  hasFile?: InputMaybe<Array<UserHasFileCreateFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
  hasKnowledgeChapters?: InputMaybe<Array<UserHasKnowledgeChaptersCreateFieldInput>>;
  hasKnowledgeTopics?: InputMaybe<Array<UserHasKnowledgeTopicsCreateFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
  hasProgress?: InputMaybe<Array<UserHasProgressCreateFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<UserHasSubchapterSkillsCreateFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedCreateFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsCreateFieldInput>>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsCreateFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsCreateFieldInput>>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesCreateFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
  todos?: InputMaybe<Array<UserTodosCreateFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
};

export type UserRoleHasRoleRolesAggregationSelection = {
  __typename?: "UserRoleHasRoleRolesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserRoleHasRoleRolesEdgeAggregateSelection>;
  node?: Maybe<UserRoleHasRoleRolesNodeAggregateSelection>;
};

export type UserRoleHasRoleRolesEdgeAggregateSelection = {
  __typename?: "UserRoleHasRoleRolesEdgeAggregateSelection";
  tenantId: IdAggregateSelection;
};

export type UserRoleHasRoleRolesNodeAggregateSelection = {
  __typename?: "UserRoleHasRoleRolesNodeAggregateSelection";
  name: StringAggregateSelection;
};

/**
 * The edge properties for the following fields:
 * * User.hasRoleRoles
 * * Role.usersHasRole
 */
export type UserRoleProperties = {
  __typename?: "UserRoleProperties";
  tenantId: Scalars["ID"]["output"];
};

export type UserRolePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<UserRolePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<UserRolePropertiesAggregationWhereInput>>;
};

export type UserRolePropertiesCreateInput = {
  tenantId: Scalars["ID"]["input"];
};

export type UserRolePropertiesSort = {
  tenantId?: InputMaybe<SortDirection>;
};

export type UserRolePropertiesUpdateInput = {
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserRolePropertiesWhere = {
  AND?: InputMaybe<Array<UserRolePropertiesWhere>>;
  NOT?: InputMaybe<UserRolePropertiesWhere>;
  OR?: InputMaybe<Array<UserRolePropertiesWhere>>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tenantId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserSchoolAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<UserSchoolAssignmentsAggregateInput>>;
  NOT?: InputMaybe<UserSchoolAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<UserSchoolAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserSchoolAssignmentsNodeAggregationWhereInput>;
};

export type UserSchoolAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type UserSchoolAssignmentsConnectOrCreateFieldInput = {
  onCreate: UserSchoolAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type UserSchoolAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type UserSchoolAssignmentsConnection = {
  __typename?: "UserSchoolAssignmentsConnection";
  edges: Array<UserSchoolAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserSchoolAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type UserSchoolAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<UserSchoolAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<UserSchoolAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type UserSchoolAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type UserSchoolAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
};

export type UserSchoolAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
};

export type UserSchoolAssignmentsFieldInput = {
  connect?: InputMaybe<Array<UserSchoolAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserSchoolAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserSchoolAssignmentsCreateFieldInput>>;
};

export type UserSchoolAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserSchoolAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserSchoolAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserSchoolAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  due_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grade_points_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grade_points_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  videoUrl_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  videoUrl_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserSchoolAssignmentsRelationship = {
  __typename?: "UserSchoolAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type UserSchoolAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type UserSchoolAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserSchoolAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserSchoolAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserSchoolAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserSchoolAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserSchoolAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<UserSchoolAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
};

export type UserSchoolSchoolsAggregationSelection = {
  __typename?: "UserSchoolSchoolsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserSchoolSchoolsEdgeAggregateSelection>;
  node?: Maybe<UserSchoolSchoolsNodeAggregateSelection>;
};

export type UserSchoolSchoolsEdgeAggregateSelection = {
  __typename?: "UserSchoolSchoolsEdgeAggregateSelection";
  access_token: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  domain: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  profile_id: StringAggregateSelection;
  vendor: StringAggregateSelection;
};

export type UserSchoolSchoolsNodeAggregateSelection = {
  __typename?: "UserSchoolSchoolsNodeAggregateSelection";
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type UserSchoolsAggregateInput = {
  AND?: InputMaybe<Array<UserSchoolsAggregateInput>>;
  NOT?: InputMaybe<UserSchoolsAggregateInput>;
  OR?: InputMaybe<Array<UserSchoolsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasSchoolPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserSchoolsNodeAggregationWhereInput>;
};

export type UserSchoolsConnectFieldInput = {
  connect?: InputMaybe<Array<SchoolConnectInput>>;
  edge?: InputMaybe<HasSchoolPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SchoolConnectWhere>;
};

export type UserSchoolsConnectOrCreateFieldInput = {
  onCreate: UserSchoolsConnectOrCreateFieldInputOnCreate;
  where: SchoolConnectOrCreateWhere;
};

export type UserSchoolsConnectOrCreateFieldInputOnCreate = {
  edge?: InputMaybe<HasSchoolPropertiesCreateInput>;
  node: SchoolOnCreateInput;
};

export type UserSchoolsConnection = {
  __typename?: "UserSchoolsConnection";
  edges: Array<UserSchoolsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserSchoolsConnectionSort = {
  edge?: InputMaybe<HasSchoolPropertiesSort>;
  node?: InputMaybe<SchoolSort>;
};

export type UserSchoolsConnectionWhere = {
  AND?: InputMaybe<Array<UserSchoolsConnectionWhere>>;
  NOT?: InputMaybe<UserSchoolsConnectionWhere>;
  OR?: InputMaybe<Array<UserSchoolsConnectionWhere>>;
  edge?: InputMaybe<HasSchoolPropertiesWhere>;
  node?: InputMaybe<SchoolWhere>;
};

export type UserSchoolsCreateFieldInput = {
  edge?: InputMaybe<HasSchoolPropertiesCreateInput>;
  node: SchoolCreateInput;
};

export type UserSchoolsDeleteFieldInput = {
  delete?: InputMaybe<SchoolDeleteInput>;
  where?: InputMaybe<UserSchoolsConnectionWhere>;
};

export type UserSchoolsDisconnectFieldInput = {
  disconnect?: InputMaybe<SchoolDisconnectInput>;
  where?: InputMaybe<UserSchoolsConnectionWhere>;
};

export type UserSchoolsFieldInput = {
  connect?: InputMaybe<Array<UserSchoolsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserSchoolsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserSchoolsCreateFieldInput>>;
};

export type UserSchoolsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserSchoolsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserSchoolsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserSchoolsNodeAggregationWhereInput>>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserSchoolsRelationship = {
  __typename?: "UserSchoolsRelationship";
  cursor: Scalars["String"]["output"];
  node: School;
  properties: HasSchoolProperties;
};

export type UserSchoolsUpdateConnectionInput = {
  edge?: InputMaybe<HasSchoolPropertiesUpdateInput>;
  node?: InputMaybe<SchoolUpdateInput>;
};

export type UserSchoolsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserSchoolsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserSchoolsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserSchoolsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserSchoolsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserSchoolsDisconnectFieldInput>>;
  update?: InputMaybe<UserSchoolsUpdateConnectionInput>;
  where?: InputMaybe<UserSchoolsConnectionWhere>;
};

/** Fields to sort Users by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserSort object. */
export type UserSort = {
  _empty?: InputMaybe<SortDirection>;
  audioOn?: InputMaybe<SortDirection>;
  biography?: InputMaybe<SortDirection>;
  birthday?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  crmUserId?: InputMaybe<SortDirection>;
  currentWeightLbs?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  goalWeightLbs?: InputMaybe<SortDirection>;
  googleAuthTokens?: InputMaybe<SortDirection>;
  googleCalendarAuthToken?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  invitationToken?: InputMaybe<SortDirection>;
  invitationTokenExpiry?: InputMaybe<SortDirection>;
  inviteCount?: InputMaybe<SortDirection>;
  isCareerLoading?: InputMaybe<SortDirection>;
  isCourseLoading?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  meetingLink?: InputMaybe<SortDirection>;
  modalOnboarding?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  password?: InputMaybe<SortDirection>;
  pendingCourseEnrollmentInvite?: InputMaybe<SortDirection>;
  pendingFacultyInvite?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  profilePicture?: InputMaybe<SortDirection>;
  stripeCustomerId?: InputMaybe<SortDirection>;
  stripeCustomerIdLearningPayments?: InputMaybe<SortDirection>;
  stripeSubscriptionId?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type UserSubchapterHasSubchapterSkillsAggregationSelection = {
  __typename?: "UserSubchapterHasSubchapterSkillsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserSubchapterHasSubchapterSkillsNodeAggregateSelection>;
};

export type UserSubchapterHasSubchapterSkillsNodeAggregateSelection = {
  __typename?: "UserSubchapterHasSubchapterSkillsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type UserSubmissionHasSubmittedAggregationSelection = {
  __typename?: "UserSubmissionHasSubmittedAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserSubmissionHasSubmittedNodeAggregateSelection>;
};

export type UserSubmissionHasSubmittedNodeAggregateSelection = {
  __typename?: "UserSubmissionHasSubmittedNodeAggregateSelection";
  completedAt: DateTimeAggregateSelection;
  created: DateTimeAggregateSelection;
  feedback: StringAggregateSelection;
  grade: FloatAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type UserSuggestionHasSuggestionSuggestionsAggregationSelection = {
  __typename?: "UserSuggestionHasSuggestionSuggestionsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection>;
};

export type UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection = {
  __typename?: "UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection";
  assignmentId: StringAggregateSelection;
  classId: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  integrationId: StringAggregateSelection;
  journeyId: StringAggregateSelection;
  trackName: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type UserTeachingCoursesAggregateInput = {
  AND?: InputMaybe<Array<UserTeachingCoursesAggregateInput>>;
  NOT?: InputMaybe<UserTeachingCoursesAggregateInput>;
  OR?: InputMaybe<Array<UserTeachingCoursesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserTeachingCoursesNodeAggregationWhereInput>;
};

export type UserTeachingCoursesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type UserTeachingCoursesConnectOrCreateFieldInput = {
  onCreate: UserTeachingCoursesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type UserTeachingCoursesConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type UserTeachingCoursesConnection = {
  __typename?: "UserTeachingCoursesConnection";
  edges: Array<UserTeachingCoursesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserTeachingCoursesConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type UserTeachingCoursesConnectionWhere = {
  AND?: InputMaybe<Array<UserTeachingCoursesConnectionWhere>>;
  NOT?: InputMaybe<UserTeachingCoursesConnectionWhere>;
  OR?: InputMaybe<Array<UserTeachingCoursesConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type UserTeachingCoursesCreateFieldInput = {
  node: CourseCreateInput;
};

export type UserTeachingCoursesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<UserTeachingCoursesConnectionWhere>;
};

export type UserTeachingCoursesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<UserTeachingCoursesConnectionWhere>;
};

export type UserTeachingCoursesFieldInput = {
  connect?: InputMaybe<Array<UserTeachingCoursesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeachingCoursesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserTeachingCoursesCreateFieldInput>>;
};

export type UserTeachingCoursesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserTeachingCoursesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserTeachingCoursesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserTeachingCoursesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  cost_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  cost_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripePriceId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripePriceId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeProductId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeProductId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  thumbnail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  thumbnail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTeachingCoursesRelationship = {
  __typename?: "UserTeachingCoursesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type UserTeachingCoursesUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type UserTeachingCoursesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserTeachingCoursesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<UserTeachingCoursesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<UserTeachingCoursesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserTeachingCoursesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserTeachingCoursesDisconnectFieldInput>>;
  update?: InputMaybe<UserTeachingCoursesUpdateConnectionInput>;
  where?: InputMaybe<UserTeachingCoursesConnectionWhere>;
};

export type UserTenantTenantsAggregationSelection = {
  __typename?: "UserTenantTenantsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserTenantTenantsNodeAggregateSelection>;
};

export type UserTenantTenantsNodeAggregateSelection = {
  __typename?: "UserTenantTenantsNodeAggregateSelection";
  bugReportEmails: StringAggregateSelection;
  companionName: StringAggregateSelection;
  connectURL: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emailBackgroundColor: StringAggregateSelection;
  emailFooterColor: StringAggregateSelection;
  emailRadius: StringAggregateSelection;
  emulationStatement: StringAggregateSelection;
  externalSendGridAppInviteTemplateId: StringAggregateSelection;
  externalSendGridDailyEmailTemplateId: StringAggregateSelection;
  externalSendGridFromEmail: StringAggregateSelection;
  externalSendGridFromName: StringAggregateSelection;
  externalSendGridTwilioServiceSID: StringAggregateSelection;
  gameMenuBg: StringAggregateSelection;
  gameMenuCardBg: StringAggregateSelection;
  gameMenuCardButton: StringAggregateSelection;
  gameMenuCardButtonText: StringAggregateSelection;
  gameMenuCardRefresh: StringAggregateSelection;
  gameMenuProgress: StringAggregateSelection;
  gameMenuRefresh: StringAggregateSelection;
  gameMenuRefreshText: StringAggregateSelection;
  gameMenuTrack: StringAggregateSelection;
  generalPrompt: StringAggregateSelection;
  id: IdAggregateSelection;
  key: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningEmulationStatement: StringAggregateSelection;
  learningFieldColor: StringAggregateSelection;
  learningSubFieldColor: StringAggregateSelection;
  logo: StringAggregateSelection;
  metaTagsAppleTouchIcon: StringAggregateSelection;
  metaTagsDescription: StringAggregateSelection;
  metaTagsThemeColor: StringAggregateSelection;
  metaTagsTitle: StringAggregateSelection;
  metaTagsTwitterCard: StringAggregateSelection;
  metaTagsTwitterDescription: StringAggregateSelection;
  metaTagsTwitterImage: StringAggregateSelection;
  metaTagsTwitterSite: StringAggregateSelection;
  metaTagsTwitterTitle: StringAggregateSelection;
  newsSearchTerms: StringAggregateSelection;
  newsSectionTitle: StringAggregateSelection;
  onboardingPrompt: StringAggregateSelection;
  primaryColor: StringAggregateSelection;
  stripeSecretKey: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  stripeTrialDays: IntAggregateSelection;
  tenantDomain: StringAggregateSelection;
  tenantName: StringAggregateSelection;
  termsOfServiceLink: StringAggregateSelection;
  upgradeModalBody: StringAggregateSelection;
};

export type UserTenantsAggregateInput = {
  AND?: InputMaybe<Array<UserTenantsAggregateInput>>;
  NOT?: InputMaybe<UserTenantsAggregateInput>;
  OR?: InputMaybe<Array<UserTenantsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserTenantsNodeAggregationWhereInput>;
};

export type UserTenantsConnectFieldInput = {
  connect?: InputMaybe<Array<TenantConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TenantConnectWhere>;
};

export type UserTenantsConnection = {
  __typename?: "UserTenantsConnection";
  edges: Array<UserTenantsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserTenantsConnectionSort = {
  node?: InputMaybe<TenantSort>;
};

export type UserTenantsConnectionWhere = {
  AND?: InputMaybe<Array<UserTenantsConnectionWhere>>;
  NOT?: InputMaybe<UserTenantsConnectionWhere>;
  OR?: InputMaybe<Array<UserTenantsConnectionWhere>>;
  node?: InputMaybe<TenantWhere>;
};

export type UserTenantsCreateFieldInput = {
  node: TenantCreateInput;
};

export type UserTenantsDeleteFieldInput = {
  delete?: InputMaybe<TenantDeleteInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTenantsDisconnectFieldInput = {
  disconnect?: InputMaybe<TenantDisconnectInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTenantsFieldInput = {
  connect?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  create?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
};

export type UserTenantsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserTenantsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserTenantsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserTenantsNodeAggregationWhereInput>>;
  bugReportEmails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTenantsRelationship = {
  __typename?: "UserTenantsRelationship";
  cursor: Scalars["String"]["output"];
  node: Tenant;
};

export type UserTenantsUpdateConnectionInput = {
  node?: InputMaybe<TenantUpdateInput>;
};

export type UserTenantsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  create?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserTenantsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserTenantsDisconnectFieldInput>>;
  update?: InputMaybe<UserTenantsUpdateConnectionInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTodoTodosAggregationSelection = {
  __typename?: "UserTodoTodosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserTodoTodosEdgeAggregateSelection>;
  node?: Maybe<UserTodoTodosNodeAggregateSelection>;
};

export type UserTodoTodosEdgeAggregateSelection = {
  __typename?: "UserTodoTodosEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserTodoTodosNodeAggregateSelection = {
  __typename?: "UserTodoTodosNodeAggregateSelection";
  completed_date: DateTimeAggregateSelection;
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type UserTodosAggregateInput = {
  AND?: InputMaybe<Array<UserTodosAggregateInput>>;
  NOT?: InputMaybe<UserTodosAggregateInput>;
  OR?: InputMaybe<Array<UserTodosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserTodosNodeAggregationWhereInput>;
};

export type UserTodosConnectFieldInput = {
  connect?: InputMaybe<Array<TodoConnectInput>>;
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TodoConnectWhere>;
};

export type UserTodosConnection = {
  __typename?: "UserTodosConnection";
  edges: Array<UserTodosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserTodosConnectionSort = {
  edge?: InputMaybe<HasTodoPropertiesSort>;
  node?: InputMaybe<TodoSort>;
};

export type UserTodosConnectionWhere = {
  AND?: InputMaybe<Array<UserTodosConnectionWhere>>;
  NOT?: InputMaybe<UserTodosConnectionWhere>;
  OR?: InputMaybe<Array<UserTodosConnectionWhere>>;
  edge?: InputMaybe<HasTodoPropertiesWhere>;
  node?: InputMaybe<TodoWhere>;
};

export type UserTodosCreateFieldInput = {
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  node: TodoCreateInput;
};

export type UserTodosDeleteFieldInput = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserTodosDisconnectFieldInput = {
  disconnect?: InputMaybe<TodoDisconnectInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserTodosFieldInput = {
  connect?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  create?: InputMaybe<Array<UserTodosCreateFieldInput>>;
};

export type UserTodosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserTodosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserTodosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserTodosNodeAggregationWhereInput>>;
  completed_date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  completed_date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTodosRelationship = {
  __typename?: "UserTodosRelationship";
  cursor: Scalars["String"]["output"];
  node: Todo;
  properties: HasTodoProperties;
};

export type UserTodosUpdateConnectionInput = {
  edge?: InputMaybe<HasTodoPropertiesUpdateInput>;
  node?: InputMaybe<TodoUpdateInput>;
};

export type UserTodosUpdateFieldInput = {
  connect?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  create?: InputMaybe<Array<UserTodosCreateFieldInput>>;
  delete?: InputMaybe<Array<UserTodosDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserTodosDisconnectFieldInput>>;
  update?: InputMaybe<UserTodosUpdateConnectionInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserTopicEnrolledInTopicsAggregationSelection = {
  __typename?: "UserTopicEnrolledInTopicsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserTopicEnrolledInTopicsEdgeAggregateSelection>;
  node?: Maybe<UserTopicEnrolledInTopicsNodeAggregateSelection>;
};

export type UserTopicEnrolledInTopicsEdgeAggregateSelection = {
  __typename?: "UserTopicEnrolledInTopicsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type UserTopicEnrolledInTopicsNodeAggregateSelection = {
  __typename?: "UserTopicEnrolledInTopicsNodeAggregateSelection";
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type UserTopicHasKnowledgeTopicsAggregationSelection = {
  __typename?: "UserTopicHasKnowledgeTopicsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserTopicHasKnowledgeTopicsEdgeAggregateSelection>;
  node?: Maybe<UserTopicHasKnowledgeTopicsNodeAggregateSelection>;
};

export type UserTopicHasKnowledgeTopicsEdgeAggregateSelection = {
  __typename?: "UserTopicHasKnowledgeTopicsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  total_mastery: FloatAggregateSelection;
};

export type UserTopicHasKnowledgeTopicsNodeAggregateSelection = {
  __typename?: "UserTopicHasKnowledgeTopicsNodeAggregateSelection";
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type UserUndefinedFoodItemsAggregateInput = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsAggregateInput>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsAggregateInput>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserUndefinedFoodItemsNodeAggregationWhereInput>;
};

export type UserUndefinedFoodItemsConnectFieldInput = {
  connect?: InputMaybe<Array<FoodItemConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FoodItemConnectWhere>;
};

export type UserUndefinedFoodItemsConnection = {
  __typename?: "UserUndefinedFoodItemsConnection";
  edges: Array<UserUndefinedFoodItemsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserUndefinedFoodItemsConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<FoodItemSort>;
};

export type UserUndefinedFoodItemsConnectionWhere = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsConnectionWhere>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: FoodItemCreateInput;
};

export type UserUndefinedFoodItemsDeleteFieldInput = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedFoodItemsDisconnectFieldInput = {
  disconnect?: InputMaybe<FoodItemDisconnectInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedFoodItemsFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
};

export type UserUndefinedFoodItemsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsNodeAggregationWhereInput>>;
  calories_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sugar_grams_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserUndefinedFoodItemsRelationship = {
  __typename?: "UserUndefinedFoodItemsRelationship";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
  properties: UndefinedProperties;
};

export type UserUndefinedFoodItemsUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<FoodItemUpdateInput>;
};

export type UserUndefinedFoodItemsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUndefinedFoodItemsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUndefinedFoodItemsDisconnectFieldInput>>;
  update?: InputMaybe<UserUndefinedFoodItemsUpdateConnectionInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedWaterIntakesAggregateInput = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesAggregateInput>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesAggregateInput>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserUndefinedWaterIntakesNodeAggregationWhereInput>;
};

export type UserUndefinedWaterIntakesConnectFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WaterIntakeConnectWhere>;
};

export type UserUndefinedWaterIntakesConnection = {
  __typename?: "UserUndefinedWaterIntakesConnection";
  edges: Array<UserUndefinedWaterIntakesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserUndefinedWaterIntakesConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<WaterIntakeSort>;
};

export type UserUndefinedWaterIntakesConnectionWhere = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionWhere>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: WaterIntakeCreateInput;
};

export type UserUndefinedWaterIntakesDeleteFieldInput = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUndefinedWaterIntakesDisconnectFieldInput = {
  disconnect?: InputMaybe<WaterIntakeDisconnectInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUndefinedWaterIntakesFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
};

export type UserUndefinedWaterIntakesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserUndefinedWaterIntakesRelationship = {
  __typename?: "UserUndefinedWaterIntakesRelationship";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
  properties: UndefinedProperties;
};

export type UserUndefinedWaterIntakesUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<WaterIntakeUpdateInput>;
};

export type UserUndefinedWaterIntakesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUndefinedWaterIntakesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUndefinedWaterIntakesDisconnectFieldInput>>;
  update?: InputMaybe<UserUndefinedWaterIntakesUpdateConnectionInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUniqueWhere = {
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  achievements?: InputMaybe<Array<UserAchievementsUpdateFieldInput>>;
  assessments?: InputMaybe<Array<UserAssessmentsUpdateFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesUpdateFieldInput>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledCourses?: InputMaybe<Array<UserEnrolledCoursesUpdateFieldInput>>;
  enrolledDegrees?: InputMaybe<Array<UserEnrolledDegreesUpdateFieldInput>>;
  enrolledInTopics?: InputMaybe<Array<UserEnrolledInTopicsUpdateFieldInput>>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_POP?: InputMaybe<Scalars["Int"]["input"]>;
  enrolledTracks_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  hasChapterSkills?: InputMaybe<Array<UserHasChapterSkillsUpdateFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsUpdateFieldInput>>;
  hasFile?: InputMaybe<Array<UserHasFileUpdateFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsUpdateFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysUpdateFieldInput>>;
  hasKnowledgeChapters?: InputMaybe<Array<UserHasKnowledgeChaptersUpdateFieldInput>>;
  hasKnowledgeTopics?: InputMaybe<Array<UserHasKnowledgeTopicsUpdateFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsUpdateFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesUpdateFieldInput>>;
  hasProgress?: InputMaybe<Array<UserHasProgressUpdateFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesUpdateFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsUpdateFieldInput>>;
  hasSubchapterSkills?: InputMaybe<Array<UserHasSubchapterSkillsUpdateFieldInput>>;
  hasSubmitted?: InputMaybe<Array<UserHasSubmittedUpdateFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsUpdateFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesUpdateFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesUpdateFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesUpdateFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesUpdateFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesUpdateFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesUpdateFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  learningPayments?: InputMaybe<Array<UserLearningPaymentsUpdateFieldInput>>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  schoolAssignments?: InputMaybe<Array<UserSchoolAssignmentsUpdateFieldInput>>;
  schools?: InputMaybe<Array<UserSchoolsUpdateFieldInput>>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  teachingCourses?: InputMaybe<Array<UserTeachingCoursesUpdateFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsUpdateFieldInput>>;
  todos?: InputMaybe<Array<UserTodosUpdateFieldInput>>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsUpdateFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesUpdateFieldInput>>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection>;
  node?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection>;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type UserWaterIntakeUndefinedWaterIntakesAggregationSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection>;
  node?: Maybe<UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection>;
};

export type UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection = {
  __typename?: "UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection>;
};

export type UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection = {
  __typename?: "UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserWhere = {
  AND?: InputMaybe<Array<UserWhere>>;
  NOT?: InputMaybe<UserWhere>;
  OR?: InputMaybe<Array<UserWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  achievementsAggregate?: InputMaybe<UserAchievementsAggregateInput>;
  /** Return Users where all of the related UserAchievementsConnections match this filter */
  achievementsConnection_ALL?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where none of the related UserAchievementsConnections match this filter */
  achievementsConnection_NONE?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where one of the related UserAchievementsConnections match this filter */
  achievementsConnection_SINGLE?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where some of the related UserAchievementsConnections match this filter */
  achievementsConnection_SOME?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where all of the related Achievements match this filter */
  achievements_ALL?: InputMaybe<AchievementsWhere>;
  /** Return Users where none of the related Achievements match this filter */
  achievements_NONE?: InputMaybe<AchievementsWhere>;
  /** Return Users where one of the related Achievements match this filter */
  achievements_SINGLE?: InputMaybe<AchievementsWhere>;
  /** Return Users where some of the related Achievements match this filter */
  achievements_SOME?: InputMaybe<AchievementsWhere>;
  assessmentsAggregate?: InputMaybe<UserAssessmentsAggregateInput>;
  /** Return Users where all of the related UserAssessmentsConnections match this filter */
  assessmentsConnection_ALL?: InputMaybe<UserAssessmentsConnectionWhere>;
  /** Return Users where none of the related UserAssessmentsConnections match this filter */
  assessmentsConnection_NONE?: InputMaybe<UserAssessmentsConnectionWhere>;
  /** Return Users where one of the related UserAssessmentsConnections match this filter */
  assessmentsConnection_SINGLE?: InputMaybe<UserAssessmentsConnectionWhere>;
  /** Return Users where some of the related UserAssessmentsConnections match this filter */
  assessmentsConnection_SOME?: InputMaybe<UserAssessmentsConnectionWhere>;
  /** Return Users where all of the related Assessments match this filter */
  assessments_ALL?: InputMaybe<AssessmentWhere>;
  /** Return Users where none of the related Assessments match this filter */
  assessments_NONE?: InputMaybe<AssessmentWhere>;
  /** Return Users where one of the related Assessments match this filter */
  assessments_SINGLE?: InputMaybe<AssessmentWhere>;
  /** Return Users where some of the related Assessments match this filter */
  assessments_SOME?: InputMaybe<AssessmentWhere>;
  attributesAggregate?: InputMaybe<UserAttributesAggregateInput>;
  /** Return Users where all of the related UserAttributesConnections match this filter */
  attributesConnection_ALL?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where none of the related UserAttributesConnections match this filter */
  attributesConnection_NONE?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where one of the related UserAttributesConnections match this filter */
  attributesConnection_SINGLE?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where some of the related UserAttributesConnections match this filter */
  attributesConnection_SOME?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where all of the related Attributes match this filter */
  attributes_ALL?: InputMaybe<AttributeWhere>;
  /** Return Users where none of the related Attributes match this filter */
  attributes_NONE?: InputMaybe<AttributeWhere>;
  /** Return Users where one of the related Attributes match this filter */
  attributes_SINGLE?: InputMaybe<AttributeWhere>;
  /** Return Users where some of the related Attributes match this filter */
  attributes_SOME?: InputMaybe<AttributeWhere>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  biography?: InputMaybe<Scalars["String"]["input"]>;
  biography_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  biography_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  biography_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  biography_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  birthday_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId?: InputMaybe<Scalars["String"]["input"]>;
  crmUserId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  crmUserId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  crmUserId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  crmUserId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  currentWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrolledCoursesAggregate?: InputMaybe<UserEnrolledCoursesAggregateInput>;
  /** Return Users where all of the related UserEnrolledCoursesConnections match this filter */
  enrolledCoursesConnection_ALL?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
  /** Return Users where none of the related UserEnrolledCoursesConnections match this filter */
  enrolledCoursesConnection_NONE?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
  /** Return Users where one of the related UserEnrolledCoursesConnections match this filter */
  enrolledCoursesConnection_SINGLE?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
  /** Return Users where some of the related UserEnrolledCoursesConnections match this filter */
  enrolledCoursesConnection_SOME?: InputMaybe<UserEnrolledCoursesConnectionWhere>;
  /** Return Users where all of the related Courses match this filter */
  enrolledCourses_ALL?: InputMaybe<CourseWhere>;
  /** Return Users where none of the related Courses match this filter */
  enrolledCourses_NONE?: InputMaybe<CourseWhere>;
  /** Return Users where one of the related Courses match this filter */
  enrolledCourses_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Users where some of the related Courses match this filter */
  enrolledCourses_SOME?: InputMaybe<CourseWhere>;
  enrolledDegreesAggregate?: InputMaybe<UserEnrolledDegreesAggregateInput>;
  /** Return Users where all of the related UserEnrolledDegreesConnections match this filter */
  enrolledDegreesConnection_ALL?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
  /** Return Users where none of the related UserEnrolledDegreesConnections match this filter */
  enrolledDegreesConnection_NONE?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
  /** Return Users where one of the related UserEnrolledDegreesConnections match this filter */
  enrolledDegreesConnection_SINGLE?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
  /** Return Users where some of the related UserEnrolledDegreesConnections match this filter */
  enrolledDegreesConnection_SOME?: InputMaybe<UserEnrolledDegreesConnectionWhere>;
  /** Return Users where all of the related Degrees match this filter */
  enrolledDegrees_ALL?: InputMaybe<DegreeWhere>;
  /** Return Users where none of the related Degrees match this filter */
  enrolledDegrees_NONE?: InputMaybe<DegreeWhere>;
  /** Return Users where one of the related Degrees match this filter */
  enrolledDegrees_SINGLE?: InputMaybe<DegreeWhere>;
  /** Return Users where some of the related Degrees match this filter */
  enrolledDegrees_SOME?: InputMaybe<DegreeWhere>;
  enrolledInTopicsAggregate?: InputMaybe<UserEnrolledInTopicsAggregateInput>;
  /** Return Users where all of the related UserEnrolledInTopicsConnections match this filter */
  enrolledInTopicsConnection_ALL?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
  /** Return Users where none of the related UserEnrolledInTopicsConnections match this filter */
  enrolledInTopicsConnection_NONE?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
  /** Return Users where one of the related UserEnrolledInTopicsConnections match this filter */
  enrolledInTopicsConnection_SINGLE?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
  /** Return Users where some of the related UserEnrolledInTopicsConnections match this filter */
  enrolledInTopicsConnection_SOME?: InputMaybe<UserEnrolledInTopicsConnectionWhere>;
  /** Return Users where all of the related Topics match this filter */
  enrolledInTopics_ALL?: InputMaybe<TopicWhere>;
  /** Return Users where none of the related Topics match this filter */
  enrolledInTopics_NONE?: InputMaybe<TopicWhere>;
  /** Return Users where one of the related Topics match this filter */
  enrolledInTopics_SINGLE?: InputMaybe<TopicWhere>;
  /** Return Users where some of the related Topics match this filter */
  enrolledInTopics_SOME?: InputMaybe<TopicWhere>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  goalWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleAuthTokens_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleCalendarAuthToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChapterSkillsAggregate?: InputMaybe<UserHasChapterSkillsAggregateInput>;
  /** Return Users where all of the related UserHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_ALL?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
  /** Return Users where none of the related UserHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_NONE?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
  /** Return Users where one of the related UserHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_SINGLE?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
  /** Return Users where some of the related UserHasChapterSkillsConnections match this filter */
  hasChapterSkillsConnection_SOME?: InputMaybe<UserHasChapterSkillsConnectionWhere>;
  /** Return Users where all of the related Chapters match this filter */
  hasChapterSkills_ALL?: InputMaybe<ChapterWhere>;
  /** Return Users where none of the related Chapters match this filter */
  hasChapterSkills_NONE?: InputMaybe<ChapterWhere>;
  /** Return Users where one of the related Chapters match this filter */
  hasChapterSkills_SINGLE?: InputMaybe<ChapterWhere>;
  /** Return Users where some of the related Chapters match this filter */
  hasChapterSkills_SOME?: InputMaybe<ChapterWhere>;
  hasChatChatsAggregate?: InputMaybe<UserHasChatChatsAggregateInput>;
  /** Return Users where all of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where none of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where one of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where some of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Users where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Users where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Users where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasFileAggregate?: InputMaybe<UserHasFileAggregateInput>;
  /** Return Users where all of the related UserHasFileConnections match this filter */
  hasFileConnection_ALL?: InputMaybe<UserHasFileConnectionWhere>;
  /** Return Users where none of the related UserHasFileConnections match this filter */
  hasFileConnection_NONE?: InputMaybe<UserHasFileConnectionWhere>;
  /** Return Users where one of the related UserHasFileConnections match this filter */
  hasFileConnection_SINGLE?: InputMaybe<UserHasFileConnectionWhere>;
  /** Return Users where some of the related UserHasFileConnections match this filter */
  hasFileConnection_SOME?: InputMaybe<UserHasFileConnectionWhere>;
  /** Return Users where all of the related Files match this filter */
  hasFile_ALL?: InputMaybe<FileWhere>;
  /** Return Users where none of the related Files match this filter */
  hasFile_NONE?: InputMaybe<FileWhere>;
  /** Return Users where one of the related Files match this filter */
  hasFile_SINGLE?: InputMaybe<FileWhere>;
  /** Return Users where some of the related Files match this filter */
  hasFile_SOME?: InputMaybe<FileWhere>;
  hasFoodItemFoodItemsAggregate?: InputMaybe<UserHasFoodItemFoodItemsAggregateInput>;
  /** Return Users where all of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_ALL?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where none of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_NONE?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where one of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_SINGLE?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where some of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_SOME?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where all of the related FoodItems match this filter */
  hasFoodItemFoodItems_ALL?: InputMaybe<FoodItemWhere>;
  /** Return Users where none of the related FoodItems match this filter */
  hasFoodItemFoodItems_NONE?: InputMaybe<FoodItemWhere>;
  /** Return Users where one of the related FoodItems match this filter */
  hasFoodItemFoodItems_SINGLE?: InputMaybe<FoodItemWhere>;
  /** Return Users where some of the related FoodItems match this filter */
  hasFoodItemFoodItems_SOME?: InputMaybe<FoodItemWhere>;
  hasJourneyJourneysAggregate?: InputMaybe<UserHasJourneyJourneysAggregateInput>;
  /** Return Users where all of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_ALL?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where none of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_NONE?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where one of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_SINGLE?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where some of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_SOME?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where all of the related Journeys match this filter */
  hasJourneyJourneys_ALL?: InputMaybe<JourneyWhere>;
  /** Return Users where none of the related Journeys match this filter */
  hasJourneyJourneys_NONE?: InputMaybe<JourneyWhere>;
  /** Return Users where one of the related Journeys match this filter */
  hasJourneyJourneys_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Users where some of the related Journeys match this filter */
  hasJourneyJourneys_SOME?: InputMaybe<JourneyWhere>;
  hasKnowledgeChaptersAggregate?: InputMaybe<UserHasKnowledgeChaptersAggregateInput>;
  /** Return Users where all of the related UserHasKnowledgeChaptersConnections match this filter */
  hasKnowledgeChaptersConnection_ALL?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
  /** Return Users where none of the related UserHasKnowledgeChaptersConnections match this filter */
  hasKnowledgeChaptersConnection_NONE?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
  /** Return Users where one of the related UserHasKnowledgeChaptersConnections match this filter */
  hasKnowledgeChaptersConnection_SINGLE?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
  /** Return Users where some of the related UserHasKnowledgeChaptersConnections match this filter */
  hasKnowledgeChaptersConnection_SOME?: InputMaybe<UserHasKnowledgeChaptersConnectionWhere>;
  /** Return Users where all of the related Chapters match this filter */
  hasKnowledgeChapters_ALL?: InputMaybe<ChapterWhere>;
  /** Return Users where none of the related Chapters match this filter */
  hasKnowledgeChapters_NONE?: InputMaybe<ChapterWhere>;
  /** Return Users where one of the related Chapters match this filter */
  hasKnowledgeChapters_SINGLE?: InputMaybe<ChapterWhere>;
  /** Return Users where some of the related Chapters match this filter */
  hasKnowledgeChapters_SOME?: InputMaybe<ChapterWhere>;
  hasKnowledgeTopicsAggregate?: InputMaybe<UserHasKnowledgeTopicsAggregateInput>;
  /** Return Users where all of the related UserHasKnowledgeTopicsConnections match this filter */
  hasKnowledgeTopicsConnection_ALL?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
  /** Return Users where none of the related UserHasKnowledgeTopicsConnections match this filter */
  hasKnowledgeTopicsConnection_NONE?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
  /** Return Users where one of the related UserHasKnowledgeTopicsConnections match this filter */
  hasKnowledgeTopicsConnection_SINGLE?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
  /** Return Users where some of the related UserHasKnowledgeTopicsConnections match this filter */
  hasKnowledgeTopicsConnection_SOME?: InputMaybe<UserHasKnowledgeTopicsConnectionWhere>;
  /** Return Users where all of the related Topics match this filter */
  hasKnowledgeTopics_ALL?: InputMaybe<TopicWhere>;
  /** Return Users where none of the related Topics match this filter */
  hasKnowledgeTopics_NONE?: InputMaybe<TopicWhere>;
  /** Return Users where one of the related Topics match this filter */
  hasKnowledgeTopics_SINGLE?: InputMaybe<TopicWhere>;
  /** Return Users where some of the related Topics match this filter */
  hasKnowledgeTopics_SOME?: InputMaybe<TopicWhere>;
  hasPaymentPaymentsAggregate?: InputMaybe<UserHasPaymentPaymentsAggregateInput>;
  /** Return Users where all of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_ALL?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where none of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_NONE?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where one of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_SINGLE?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where some of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_SOME?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where all of the related Payments match this filter */
  hasPaymentPayments_ALL?: InputMaybe<PaymentWhere>;
  /** Return Users where none of the related Payments match this filter */
  hasPaymentPayments_NONE?: InputMaybe<PaymentWhere>;
  /** Return Users where one of the related Payments match this filter */
  hasPaymentPayments_SINGLE?: InputMaybe<PaymentWhere>;
  /** Return Users where some of the related Payments match this filter */
  hasPaymentPayments_SOME?: InputMaybe<PaymentWhere>;
  hasPreferencesPreferencesAggregate?: InputMaybe<UserHasPreferencesPreferencesAggregateInput>;
  /** Return Users where all of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_ALL?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where none of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_NONE?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where one of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_SINGLE?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where some of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_SOME?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where all of the related Preferences match this filter */
  hasPreferencesPreferences_ALL?: InputMaybe<PreferencesWhere>;
  /** Return Users where none of the related Preferences match this filter */
  hasPreferencesPreferences_NONE?: InputMaybe<PreferencesWhere>;
  /** Return Users where one of the related Preferences match this filter */
  hasPreferencesPreferences_SINGLE?: InputMaybe<PreferencesWhere>;
  /** Return Users where some of the related Preferences match this filter */
  hasPreferencesPreferences_SOME?: InputMaybe<PreferencesWhere>;
  hasProgressAggregate?: InputMaybe<UserHasProgressAggregateInput>;
  /** Return Users where all of the related UserHasProgressConnections match this filter */
  hasProgressConnection_ALL?: InputMaybe<UserHasProgressConnectionWhere>;
  /** Return Users where none of the related UserHasProgressConnections match this filter */
  hasProgressConnection_NONE?: InputMaybe<UserHasProgressConnectionWhere>;
  /** Return Users where one of the related UserHasProgressConnections match this filter */
  hasProgressConnection_SINGLE?: InputMaybe<UserHasProgressConnectionWhere>;
  /** Return Users where some of the related UserHasProgressConnections match this filter */
  hasProgressConnection_SOME?: InputMaybe<UserHasProgressConnectionWhere>;
  /** Return Users where all of the related Progresses match this filter */
  hasProgress_ALL?: InputMaybe<ProgressWhere>;
  /** Return Users where none of the related Progresses match this filter */
  hasProgress_NONE?: InputMaybe<ProgressWhere>;
  /** Return Users where one of the related Progresses match this filter */
  hasProgress_SINGLE?: InputMaybe<ProgressWhere>;
  /** Return Users where some of the related Progresses match this filter */
  hasProgress_SOME?: InputMaybe<ProgressWhere>;
  hasRoleRolesAggregate?: InputMaybe<UserHasRoleRolesAggregateInput>;
  /** Return Users where all of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_ALL?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where none of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_NONE?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where one of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_SINGLE?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where some of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_SOME?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where all of the related Roles match this filter */
  hasRoleRoles_ALL?: InputMaybe<RoleWhere>;
  /** Return Users where none of the related Roles match this filter */
  hasRoleRoles_NONE?: InputMaybe<RoleWhere>;
  /** Return Users where one of the related Roles match this filter */
  hasRoleRoles_SINGLE?: InputMaybe<RoleWhere>;
  /** Return Users where some of the related Roles match this filter */
  hasRoleRoles_SOME?: InputMaybe<RoleWhere>;
  hasSessionDeviceSessionsAggregate?: InputMaybe<UserHasSessionDeviceSessionsAggregateInput>;
  /** Return Users where all of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_ALL?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where none of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_NONE?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where one of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_SINGLE?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where some of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_SOME?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where all of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_ALL?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where none of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_NONE?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where one of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_SINGLE?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where some of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_SOME?: InputMaybe<DeviceSessionWhere>;
  hasSubchapterSkillsAggregate?: InputMaybe<UserHasSubchapterSkillsAggregateInput>;
  /** Return Users where all of the related UserHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_ALL?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
  /** Return Users where none of the related UserHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_NONE?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
  /** Return Users where one of the related UserHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_SINGLE?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
  /** Return Users where some of the related UserHasSubchapterSkillsConnections match this filter */
  hasSubchapterSkillsConnection_SOME?: InputMaybe<UserHasSubchapterSkillsConnectionWhere>;
  /** Return Users where all of the related Subchapters match this filter */
  hasSubchapterSkills_ALL?: InputMaybe<SubchapterWhere>;
  /** Return Users where none of the related Subchapters match this filter */
  hasSubchapterSkills_NONE?: InputMaybe<SubchapterWhere>;
  /** Return Users where one of the related Subchapters match this filter */
  hasSubchapterSkills_SINGLE?: InputMaybe<SubchapterWhere>;
  /** Return Users where some of the related Subchapters match this filter */
  hasSubchapterSkills_SOME?: InputMaybe<SubchapterWhere>;
  hasSubmittedAggregate?: InputMaybe<UserHasSubmittedAggregateInput>;
  /** Return Users where all of the related UserHasSubmittedConnections match this filter */
  hasSubmittedConnection_ALL?: InputMaybe<UserHasSubmittedConnectionWhere>;
  /** Return Users where none of the related UserHasSubmittedConnections match this filter */
  hasSubmittedConnection_NONE?: InputMaybe<UserHasSubmittedConnectionWhere>;
  /** Return Users where one of the related UserHasSubmittedConnections match this filter */
  hasSubmittedConnection_SINGLE?: InputMaybe<UserHasSubmittedConnectionWhere>;
  /** Return Users where some of the related UserHasSubmittedConnections match this filter */
  hasSubmittedConnection_SOME?: InputMaybe<UserHasSubmittedConnectionWhere>;
  /** Return Users where all of the related Submissions match this filter */
  hasSubmitted_ALL?: InputMaybe<SubmissionWhere>;
  /** Return Users where none of the related Submissions match this filter */
  hasSubmitted_NONE?: InputMaybe<SubmissionWhere>;
  /** Return Users where one of the related Submissions match this filter */
  hasSubmitted_SINGLE?: InputMaybe<SubmissionWhere>;
  /** Return Users where some of the related Submissions match this filter */
  hasSubmitted_SOME?: InputMaybe<SubmissionWhere>;
  hasSuggestionSuggestionsAggregate?: InputMaybe<UserHasSuggestionSuggestionsAggregateInput>;
  /** Return Users where all of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_ALL?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where none of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_NONE?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where one of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_SINGLE?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where some of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_SOME?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where all of the related Suggestions match this filter */
  hasSuggestionSuggestions_ALL?: InputMaybe<SuggestionWhere>;
  /** Return Users where none of the related Suggestions match this filter */
  hasSuggestionSuggestions_NONE?: InputMaybe<SuggestionWhere>;
  /** Return Users where one of the related Suggestions match this filter */
  hasSuggestionSuggestions_SINGLE?: InputMaybe<SuggestionWhere>;
  /** Return Users where some of the related Suggestions match this filter */
  hasSuggestionSuggestions_SOME?: InputMaybe<SuggestionWhere>;
  hasSummaryDailySummariesAggregate?: InputMaybe<UserHasSummaryDailySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_ALL?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_NONE?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_SOME?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where all of the related DailySummaries match this filter */
  hasSummaryDailySummaries_ALL?: InputMaybe<DailySummaryWhere>;
  /** Return Users where none of the related DailySummaries match this filter */
  hasSummaryDailySummaries_NONE?: InputMaybe<DailySummaryWhere>;
  /** Return Users where one of the related DailySummaries match this filter */
  hasSummaryDailySummaries_SINGLE?: InputMaybe<DailySummaryWhere>;
  /** Return Users where some of the related DailySummaries match this filter */
  hasSummaryDailySummaries_SOME?: InputMaybe<DailySummaryWhere>;
  hasSummaryMonthlySummariesAggregate?: InputMaybe<UserHasSummaryMonthlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_ALL?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_NONE?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_SOME?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where all of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_ALL?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where none of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_NONE?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where one of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_SINGLE?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where some of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_SOME?: InputMaybe<MonthlySummaryWhere>;
  hasSummaryQuarterlySummariesAggregate?: InputMaybe<UserHasSummaryQuarterlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_ALL?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_NONE?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_SOME?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where all of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_ALL?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where none of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_NONE?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where one of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_SINGLE?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where some of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_SOME?: InputMaybe<QuarterlySummaryWhere>;
  hasSummaryWeeklySummariesAggregate?: InputMaybe<UserHasSummaryWeeklySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_ALL?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_NONE?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_SOME?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where all of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_ALL?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where none of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_NONE?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where one of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_SINGLE?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where some of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_SOME?: InputMaybe<WeeklySummaryWhere>;
  hasSummaryYearlySummariesAggregate?: InputMaybe<UserHasSummaryYearlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_ALL?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_NONE?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_SOME?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where all of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_ALL?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where none of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_NONE?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where one of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_SINGLE?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where some of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_SOME?: InputMaybe<YearlySummaryWhere>;
  hasWaterIntakeWaterIntakesAggregate?: InputMaybe<UserHasWaterIntakeWaterIntakesAggregateInput>;
  /** Return Users where all of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_ALL?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where none of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_NONE?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where one of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_SINGLE?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where some of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_SOME?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where all of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_ALL?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where none of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_NONE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where one of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_SINGLE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where some of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_SOME?: InputMaybe<WaterIntakeWhere>;
  hasWhitelistedEmailsAggregate?: InputMaybe<UserHasWhitelistedEmailsAggregateInput>;
  /** Return Users where all of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_ALL?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where none of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_NONE?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where one of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_SINGLE?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where some of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_SOME?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where all of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_ALL?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where none of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_NONE?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where one of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_SINGLE?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where some of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_SOME?: InputMaybe<WhitelistedEmailWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  invitationTokenExpiry?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  invitationTokenExpiry_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  invitationToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  inviteCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  learningPaymentsAggregate?: InputMaybe<UserLearningPaymentsAggregateInput>;
  /** Return Users where all of the related UserLearningPaymentsConnections match this filter */
  learningPaymentsConnection_ALL?: InputMaybe<UserLearningPaymentsConnectionWhere>;
  /** Return Users where none of the related UserLearningPaymentsConnections match this filter */
  learningPaymentsConnection_NONE?: InputMaybe<UserLearningPaymentsConnectionWhere>;
  /** Return Users where one of the related UserLearningPaymentsConnections match this filter */
  learningPaymentsConnection_SINGLE?: InputMaybe<UserLearningPaymentsConnectionWhere>;
  /** Return Users where some of the related UserLearningPaymentsConnections match this filter */
  learningPaymentsConnection_SOME?: InputMaybe<UserLearningPaymentsConnectionWhere>;
  /** Return Users where all of the related LearningPayments match this filter */
  learningPayments_ALL?: InputMaybe<LearningPaymentWhere>;
  /** Return Users where none of the related LearningPayments match this filter */
  learningPayments_NONE?: InputMaybe<LearningPaymentWhere>;
  /** Return Users where one of the related LearningPayments match this filter */
  learningPayments_SINGLE?: InputMaybe<LearningPaymentWhere>;
  /** Return Users where some of the related LearningPayments match this filter */
  learningPayments_SOME?: InputMaybe<LearningPaymentWhere>;
  meetingLink?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meetingLink_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meetingLink_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  password_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  password_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  password_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pendingCourseEnrollmentInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  pendingFacultyInvite?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  profilePicture_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  profilePicture_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  schoolAssignmentsAggregate?: InputMaybe<UserSchoolAssignmentsAggregateInput>;
  /** Return Users where all of the related UserSchoolAssignmentsConnections match this filter */
  schoolAssignmentsConnection_ALL?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
  /** Return Users where none of the related UserSchoolAssignmentsConnections match this filter */
  schoolAssignmentsConnection_NONE?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
  /** Return Users where one of the related UserSchoolAssignmentsConnections match this filter */
  schoolAssignmentsConnection_SINGLE?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
  /** Return Users where some of the related UserSchoolAssignmentsConnections match this filter */
  schoolAssignmentsConnection_SOME?: InputMaybe<UserSchoolAssignmentsConnectionWhere>;
  /** Return Users where all of the related Assignments match this filter */
  schoolAssignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Users where none of the related Assignments match this filter */
  schoolAssignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Users where one of the related Assignments match this filter */
  schoolAssignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Users where some of the related Assignments match this filter */
  schoolAssignments_SOME?: InputMaybe<AssignmentWhere>;
  schoolsAggregate?: InputMaybe<UserSchoolsAggregateInput>;
  /** Return Users where all of the related UserSchoolsConnections match this filter */
  schoolsConnection_ALL?: InputMaybe<UserSchoolsConnectionWhere>;
  /** Return Users where none of the related UserSchoolsConnections match this filter */
  schoolsConnection_NONE?: InputMaybe<UserSchoolsConnectionWhere>;
  /** Return Users where one of the related UserSchoolsConnections match this filter */
  schoolsConnection_SINGLE?: InputMaybe<UserSchoolsConnectionWhere>;
  /** Return Users where some of the related UserSchoolsConnections match this filter */
  schoolsConnection_SOME?: InputMaybe<UserSchoolsConnectionWhere>;
  /** Return Users where all of the related Schools match this filter */
  schools_ALL?: InputMaybe<SchoolWhere>;
  /** Return Users where none of the related Schools match this filter */
  schools_NONE?: InputMaybe<SchoolWhere>;
  /** Return Users where one of the related Schools match this filter */
  schools_SINGLE?: InputMaybe<SchoolWhere>;
  /** Return Users where some of the related Schools match this filter */
  schools_SOME?: InputMaybe<SchoolWhere>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerIdLearningPayments_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeCustomerIdLearningPayments_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeCustomerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teachingCoursesAggregate?: InputMaybe<UserTeachingCoursesAggregateInput>;
  /** Return Users where all of the related UserTeachingCoursesConnections match this filter */
  teachingCoursesConnection_ALL?: InputMaybe<UserTeachingCoursesConnectionWhere>;
  /** Return Users where none of the related UserTeachingCoursesConnections match this filter */
  teachingCoursesConnection_NONE?: InputMaybe<UserTeachingCoursesConnectionWhere>;
  /** Return Users where one of the related UserTeachingCoursesConnections match this filter */
  teachingCoursesConnection_SINGLE?: InputMaybe<UserTeachingCoursesConnectionWhere>;
  /** Return Users where some of the related UserTeachingCoursesConnections match this filter */
  teachingCoursesConnection_SOME?: InputMaybe<UserTeachingCoursesConnectionWhere>;
  /** Return Users where all of the related Courses match this filter */
  teachingCourses_ALL?: InputMaybe<CourseWhere>;
  /** Return Users where none of the related Courses match this filter */
  teachingCourses_NONE?: InputMaybe<CourseWhere>;
  /** Return Users where one of the related Courses match this filter */
  teachingCourses_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Users where some of the related Courses match this filter */
  teachingCourses_SOME?: InputMaybe<CourseWhere>;
  tenantsAggregate?: InputMaybe<UserTenantsAggregateInput>;
  /** Return Users where all of the related UserTenantsConnections match this filter */
  tenantsConnection_ALL?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where none of the related UserTenantsConnections match this filter */
  tenantsConnection_NONE?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where one of the related UserTenantsConnections match this filter */
  tenantsConnection_SINGLE?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where some of the related UserTenantsConnections match this filter */
  tenantsConnection_SOME?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where all of the related Tenants match this filter */
  tenants_ALL?: InputMaybe<TenantWhere>;
  /** Return Users where none of the related Tenants match this filter */
  tenants_NONE?: InputMaybe<TenantWhere>;
  /** Return Users where one of the related Tenants match this filter */
  tenants_SINGLE?: InputMaybe<TenantWhere>;
  /** Return Users where some of the related Tenants match this filter */
  tenants_SOME?: InputMaybe<TenantWhere>;
  todosAggregate?: InputMaybe<UserTodosAggregateInput>;
  /** Return Users where all of the related UserTodosConnections match this filter */
  todosConnection_ALL?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where none of the related UserTodosConnections match this filter */
  todosConnection_NONE?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where one of the related UserTodosConnections match this filter */
  todosConnection_SINGLE?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where some of the related UserTodosConnections match this filter */
  todosConnection_SOME?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where all of the related Todos match this filter */
  todos_ALL?: InputMaybe<TodoWhere>;
  /** Return Users where none of the related Todos match this filter */
  todos_NONE?: InputMaybe<TodoWhere>;
  /** Return Users where one of the related Todos match this filter */
  todos_SINGLE?: InputMaybe<TodoWhere>;
  /** Return Users where some of the related Todos match this filter */
  todos_SOME?: InputMaybe<TodoWhere>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  undefinedFoodItemsAggregate?: InputMaybe<UserUndefinedFoodItemsAggregateInput>;
  /** Return Users where all of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_ALL?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where none of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_NONE?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where one of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_SINGLE?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where some of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_SOME?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where all of the related FoodItems match this filter */
  undefinedFoodItems_ALL?: InputMaybe<FoodItemWhere>;
  /** Return Users where none of the related FoodItems match this filter */
  undefinedFoodItems_NONE?: InputMaybe<FoodItemWhere>;
  /** Return Users where one of the related FoodItems match this filter */
  undefinedFoodItems_SINGLE?: InputMaybe<FoodItemWhere>;
  /** Return Users where some of the related FoodItems match this filter */
  undefinedFoodItems_SOME?: InputMaybe<FoodItemWhere>;
  undefinedWaterIntakesAggregate?: InputMaybe<UserUndefinedWaterIntakesAggregateInput>;
  /** Return Users where all of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_ALL?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where none of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_NONE?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where one of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_SINGLE?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where some of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_SOME?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where all of the related WaterIntakes match this filter */
  undefinedWaterIntakes_ALL?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where none of the related WaterIntakes match this filter */
  undefinedWaterIntakes_NONE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where one of the related WaterIntakes match this filter */
  undefinedWaterIntakes_SINGLE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where some of the related WaterIntakes match this filter */
  undefinedWaterIntakes_SOME?: InputMaybe<WaterIntakeWhere>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  username_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  username_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection = {
  __typename?: "UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection>;
};

export type UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection = {
  __typename?: "UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection";
  email: StringAggregateSelection;
};

export type UserYearlySummaryHasSummaryYearlySummariesAggregationSelection = {
  __typename?: "UserYearlySummaryHasSummaryYearlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection>;
};

export type UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection = {
  __typename?: "UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UsersConnection = {
  __typename?: "UsersConnection";
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ValidityResponse = {
  __typename?: "ValidityResponse";
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ValidityResponseAggregateSelection = {
  __typename?: "ValidityResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type ValidityResponseCreateInput = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ValidityResponseEdge = {
  __typename?: "ValidityResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ValidityResponse;
};

export type ValidityResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ValidityResponseSort objects to sort ValidityResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ValidityResponseSort>>;
};

/** Fields to sort ValidityResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ValidityResponseSort object. */
export type ValidityResponseSort = {
  isValid?: InputMaybe<SortDirection>;
};

export type ValidityResponseUpdateInput = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ValidityResponseWhere = {
  AND?: InputMaybe<Array<ValidityResponseWhere>>;
  NOT?: InputMaybe<ValidityResponseWhere>;
  OR?: InputMaybe<Array<ValidityResponseWhere>>;
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ValidityResponsesConnection = {
  __typename?: "ValidityResponsesConnection";
  edges: Array<ValidityResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Video = {
  __typename?: "Video";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  fullTranscript: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isTeacherAdded: Scalars["Boolean"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  messageHasVideo?: Maybe<Message>;
  messageHasVideoAggregate?: Maybe<VideoMessageMessageHasVideoAggregationSelection>;
  messageHasVideoConnection: VideoMessageHasVideoConnection;
  objectiveHasVideos: Objective;
  objectiveHasVideosAggregate?: Maybe<VideoObjectiveObjectiveHasVideosAggregationSelection>;
  objectiveHasVideosConnection: VideoObjectiveHasVideosConnection;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  videoId: Scalars["String"]["output"];
};

export type VideoMessageHasVideoArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type VideoMessageHasVideoAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type VideoMessageHasVideoConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<VideoMessageHasVideoConnectionSort>>;
  where?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
};

export type VideoObjectiveHasVideosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type VideoObjectiveHasVideosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type VideoObjectiveHasVideosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<VideoObjectiveHasVideosConnectionSort>>;
  where?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
};

export type VideoAggregateSelection = {
  __typename?: "VideoAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  fullTranscript: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  thumbnailUrl: StringAggregateSelection;
  title: StringAggregateSelection;
  url: StringAggregateSelection;
  videoId: StringAggregateSelection;
};

export type VideoConnectInput = {
  messageHasVideo?: InputMaybe<VideoMessageHasVideoConnectFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosConnectFieldInput>;
};

export type VideoConnectOrCreateInput = {
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosConnectOrCreateFieldInput>;
};

export type VideoConnectOrCreateWhere = {
  node: VideoUniqueWhere;
};

export type VideoConnectWhere = {
  node: VideoWhere;
};

export type VideoCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript: Scalars["String"]["input"];
  isTeacherAdded: Scalars["Boolean"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  messageHasVideo?: InputMaybe<VideoMessageHasVideoFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosFieldInput>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type VideoData = {
  isTeacherAdded: Scalars["Boolean"]["input"];
  objectiveId: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type VideoDataInput = {
  isTeacherAdded?: InputMaybe<Scalars["Boolean"]["input"]>;
  objectiveSequence?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveText: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type VideoDeleteInput = {
  messageHasVideo?: InputMaybe<VideoMessageHasVideoDeleteFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosDeleteFieldInput>;
};

export type VideoDisconnectInput = {
  messageHasVideo?: InputMaybe<VideoMessageHasVideoDisconnectFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosDisconnectFieldInput>;
};

export type VideoEdge = {
  __typename?: "VideoEdge";
  cursor: Scalars["String"]["output"];
  node: Video;
};

export type VideoMessageHasVideoAggregateInput = {
  AND?: InputMaybe<Array<VideoMessageHasVideoAggregateInput>>;
  NOT?: InputMaybe<VideoMessageHasVideoAggregateInput>;
  OR?: InputMaybe<Array<VideoMessageHasVideoAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<VideoMessageHasVideoNodeAggregationWhereInput>;
};

export type VideoMessageHasVideoConnectFieldInput = {
  connect?: InputMaybe<MessageConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type VideoMessageHasVideoConnection = {
  __typename?: "VideoMessageHasVideoConnection";
  edges: Array<VideoMessageHasVideoRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type VideoMessageHasVideoConnectionSort = {
  node?: InputMaybe<MessageSort>;
};

export type VideoMessageHasVideoConnectionWhere = {
  AND?: InputMaybe<Array<VideoMessageHasVideoConnectionWhere>>;
  NOT?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
  OR?: InputMaybe<Array<VideoMessageHasVideoConnectionWhere>>;
  node?: InputMaybe<MessageWhere>;
};

export type VideoMessageHasVideoCreateFieldInput = {
  node: MessageCreateInput;
};

export type VideoMessageHasVideoDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
};

export type VideoMessageHasVideoDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
};

export type VideoMessageHasVideoFieldInput = {
  connect?: InputMaybe<VideoMessageHasVideoConnectFieldInput>;
  create?: InputMaybe<VideoMessageHasVideoCreateFieldInput>;
};

export type VideoMessageHasVideoNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<VideoMessageHasVideoNodeAggregationWhereInput>>;
  NOT?: InputMaybe<VideoMessageHasVideoNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<VideoMessageHasVideoNodeAggregationWhereInput>>;
  aspectDetails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VideoMessageHasVideoRelationship = {
  __typename?: "VideoMessageHasVideoRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type VideoMessageHasVideoUpdateConnectionInput = {
  node?: InputMaybe<MessageUpdateInput>;
};

export type VideoMessageHasVideoUpdateFieldInput = {
  connect?: InputMaybe<VideoMessageHasVideoConnectFieldInput>;
  create?: InputMaybe<VideoMessageHasVideoCreateFieldInput>;
  delete?: InputMaybe<VideoMessageHasVideoDeleteFieldInput>;
  disconnect?: InputMaybe<VideoMessageHasVideoDisconnectFieldInput>;
  update?: InputMaybe<VideoMessageHasVideoUpdateConnectionInput>;
  where?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
};

export type VideoMessageMessageHasVideoAggregationSelection = {
  __typename?: "VideoMessageMessageHasVideoAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<VideoMessageMessageHasVideoNodeAggregateSelection>;
};

export type VideoMessageMessageHasVideoNodeAggregateSelection = {
  __typename?: "VideoMessageMessageHasVideoNodeAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type VideoObjectiveHasVideosAggregateInput = {
  AND?: InputMaybe<Array<VideoObjectiveHasVideosAggregateInput>>;
  NOT?: InputMaybe<VideoObjectiveHasVideosAggregateInput>;
  OR?: InputMaybe<Array<VideoObjectiveHasVideosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasVideoPropertiesAggregationWhereInput>;
  node?: InputMaybe<VideoObjectiveHasVideosNodeAggregationWhereInput>;
};

export type VideoObjectiveHasVideosConnectFieldInput = {
  connect?: InputMaybe<ObjectiveConnectInput>;
  edge: HasVideoPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ObjectiveConnectWhere>;
};

export type VideoObjectiveHasVideosConnectOrCreateFieldInput = {
  onCreate: VideoObjectiveHasVideosConnectOrCreateFieldInputOnCreate;
  where: ObjectiveConnectOrCreateWhere;
};

export type VideoObjectiveHasVideosConnectOrCreateFieldInputOnCreate = {
  edge: HasVideoPropertiesCreateInput;
  node: ObjectiveOnCreateInput;
};

export type VideoObjectiveHasVideosConnection = {
  __typename?: "VideoObjectiveHasVideosConnection";
  edges: Array<VideoObjectiveHasVideosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type VideoObjectiveHasVideosConnectionSort = {
  edge?: InputMaybe<HasVideoPropertiesSort>;
  node?: InputMaybe<ObjectiveSort>;
};

export type VideoObjectiveHasVideosConnectionWhere = {
  AND?: InputMaybe<Array<VideoObjectiveHasVideosConnectionWhere>>;
  NOT?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
  OR?: InputMaybe<Array<VideoObjectiveHasVideosConnectionWhere>>;
  edge?: InputMaybe<HasVideoPropertiesWhere>;
  node?: InputMaybe<ObjectiveWhere>;
};

export type VideoObjectiveHasVideosCreateFieldInput = {
  edge: HasVideoPropertiesCreateInput;
  node: ObjectiveCreateInput;
};

export type VideoObjectiveHasVideosDeleteFieldInput = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
};

export type VideoObjectiveHasVideosDisconnectFieldInput = {
  disconnect?: InputMaybe<ObjectiveDisconnectInput>;
  where?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
};

export type VideoObjectiveHasVideosFieldInput = {
  connect?: InputMaybe<VideoObjectiveHasVideosConnectFieldInput>;
  connectOrCreate?: InputMaybe<VideoObjectiveHasVideosConnectOrCreateFieldInput>;
  create?: InputMaybe<VideoObjectiveHasVideosCreateFieldInput>;
};

export type VideoObjectiveHasVideosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<VideoObjectiveHasVideosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<VideoObjectiveHasVideosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<VideoObjectiveHasVideosNodeAggregationWhereInput>>;
  objective_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sequence_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type VideoObjectiveHasVideosRelationship = {
  __typename?: "VideoObjectiveHasVideosRelationship";
  cursor: Scalars["String"]["output"];
  node: Objective;
  properties: HasVideoProperties;
};

export type VideoObjectiveHasVideosUpdateConnectionInput = {
  edge?: InputMaybe<HasVideoPropertiesUpdateInput>;
  node?: InputMaybe<ObjectiveUpdateInput>;
};

export type VideoObjectiveHasVideosUpdateFieldInput = {
  connect?: InputMaybe<VideoObjectiveHasVideosConnectFieldInput>;
  connectOrCreate?: InputMaybe<VideoObjectiveHasVideosConnectOrCreateFieldInput>;
  create?: InputMaybe<VideoObjectiveHasVideosCreateFieldInput>;
  delete?: InputMaybe<VideoObjectiveHasVideosDeleteFieldInput>;
  disconnect?: InputMaybe<VideoObjectiveHasVideosDisconnectFieldInput>;
  update?: InputMaybe<VideoObjectiveHasVideosUpdateConnectionInput>;
  where?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
};

export type VideoObjectiveObjectiveHasVideosAggregationSelection = {
  __typename?: "VideoObjectiveObjectiveHasVideosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<VideoObjectiveObjectiveHasVideosEdgeAggregateSelection>;
  node?: Maybe<VideoObjectiveObjectiveHasVideosNodeAggregateSelection>;
};

export type VideoObjectiveObjectiveHasVideosEdgeAggregateSelection = {
  __typename?: "VideoObjectiveObjectiveHasVideosEdgeAggregateSelection";
  relevanceScore: FloatAggregateSelection;
  timestamp: FloatAggregateSelection;
};

export type VideoObjectiveObjectiveHasVideosNodeAggregateSelection = {
  __typename?: "VideoObjectiveObjectiveHasVideosNodeAggregateSelection";
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type VideoOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript: Scalars["String"]["input"];
  isTeacherAdded: Scalars["Boolean"]["input"];
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type VideoOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more VideoSort objects to sort Videos by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<VideoSort>>;
};

export type VideoPoolItem = {
  __typename?: "VideoPoolItem";
  description?: Maybe<Scalars["String"]["output"]>;
  objectiveSequence?: Maybe<Scalars["Float"]["output"]>;
  objectiveText?: Maybe<Scalars["String"]["output"]>;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  videoId: Scalars["String"]["output"];
};

export type VideoPoolItemAggregateSelection = {
  __typename?: "VideoPoolItemAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  objectiveSequence: FloatAggregateSelection;
  objectiveText: StringAggregateSelection;
  thumbnailUrl: StringAggregateSelection;
  title: StringAggregateSelection;
  url: StringAggregateSelection;
  videoId: StringAggregateSelection;
};

export type VideoPoolItemCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  objectiveSequence?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveText?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type VideoPoolItemEdge = {
  __typename?: "VideoPoolItemEdge";
  cursor: Scalars["String"]["output"];
  node: VideoPoolItem;
};

export type VideoPoolItemOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more VideoPoolItemSort objects to sort VideoPoolItems by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<VideoPoolItemSort>>;
};

/** Fields to sort VideoPoolItems by. The order in which sorts are applied is not guaranteed when specifying many fields in one VideoPoolItemSort object. */
export type VideoPoolItemSort = {
  description?: InputMaybe<SortDirection>;
  objectiveSequence?: InputMaybe<SortDirection>;
  objectiveText?: InputMaybe<SortDirection>;
  thumbnailUrl?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  videoId?: InputMaybe<SortDirection>;
};

export type VideoPoolItemUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  objectiveSequence?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveText?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  videoId?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideoPoolItemWhere = {
  AND?: InputMaybe<Array<VideoPoolItemWhere>>;
  NOT?: InputMaybe<VideoPoolItemWhere>;
  OR?: InputMaybe<Array<VideoPoolItemWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectiveSequence?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  objectiveSequence_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveSequence_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectiveText?: InputMaybe<Scalars["String"]["input"]>;
  objectiveText_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objectiveText_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectiveText_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectiveText_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  thumbnailUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoId?: InputMaybe<Scalars["String"]["input"]>;
  videoId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  videoId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoId_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  videoId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideoPoolItemsConnection = {
  __typename?: "VideoPoolItemsConnection";
  edges: Array<VideoPoolItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type VideoRelationInput = {
  messageHasVideo?: InputMaybe<VideoMessageHasVideoCreateFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosCreateFieldInput>;
};

export type VideoServiceResult = {
  __typename?: "VideoServiceResult";
  count: Scalars["Int"]["output"];
  data?: Maybe<Array<VideoPoolItem>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type VideoServiceResultAggregateSelection = {
  __typename?: "VideoServiceResultAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type VideoServiceResultCreateInput = {
  count: Scalars["Int"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type VideoServiceResultEdge = {
  __typename?: "VideoServiceResultEdge";
  cursor: Scalars["String"]["output"];
  node: VideoServiceResult;
};

export type VideoServiceResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more VideoServiceResultSort objects to sort VideoServiceResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<VideoServiceResultSort>>;
};

/** Fields to sort VideoServiceResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one VideoServiceResultSort object. */
export type VideoServiceResultSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type VideoServiceResultUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VideoServiceResultWhere = {
  AND?: InputMaybe<Array<VideoServiceResultWhere>>;
  NOT?: InputMaybe<VideoServiceResultWhere>;
  OR?: InputMaybe<Array<VideoServiceResultWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VideoServiceResultsConnection = {
  __typename?: "VideoServiceResultsConnection";
  edges: Array<VideoServiceResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Videos by. The order in which sorts are applied is not guaranteed when specifying many fields in one VideoSort object. */
export type VideoSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  fullTranscript?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isTeacherAdded?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  thumbnailUrl?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  videoId?: InputMaybe<SortDirection>;
};

export type VideoUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type VideoUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript?: InputMaybe<Scalars["String"]["input"]>;
  isTeacherAdded?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  messageHasVideo?: InputMaybe<VideoMessageHasVideoUpdateFieldInput>;
  objectiveHasVideos?: InputMaybe<VideoObjectiveHasVideosUpdateFieldInput>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  videoId?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideoWhere = {
  AND?: InputMaybe<Array<VideoWhere>>;
  NOT?: InputMaybe<VideoWhere>;
  OR?: InputMaybe<Array<VideoWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullTranscript_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fullTranscript_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isTeacherAdded?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  messageHasVideo?: InputMaybe<MessageWhere>;
  messageHasVideoAggregate?: InputMaybe<VideoMessageHasVideoAggregateInput>;
  messageHasVideoConnection?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
  messageHasVideoConnection_NOT?: InputMaybe<VideoMessageHasVideoConnectionWhere>;
  messageHasVideo_NOT?: InputMaybe<MessageWhere>;
  objectiveHasVideos?: InputMaybe<ObjectiveWhere>;
  objectiveHasVideosAggregate?: InputMaybe<VideoObjectiveHasVideosAggregateInput>;
  objectiveHasVideosConnection?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
  objectiveHasVideosConnection_NOT?: InputMaybe<VideoObjectiveHasVideosConnectionWhere>;
  objectiveHasVideos_NOT?: InputMaybe<ObjectiveWhere>;
  thumbnailUrl?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  thumbnailUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  thumbnailUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoId?: InputMaybe<Scalars["String"]["input"]>;
  videoId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  videoId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  videoId_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  videoId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type VideosConnection = {
  __typename?: "VideosConnection";
  edges: Array<VideoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Visualization = {
  __typename?: "Visualization";
  aiTutorHasVisualization?: Maybe<AiTutor>;
  aiTutorHasVisualizationAggregate?: Maybe<VisualizationAiTutorAiTutorHasVisualizationAggregationSelection>;
  aiTutorHasVisualizationConnection: VisualizationAiTutorHasVisualizationConnection;
  code?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lastModified: Scalars["DateTime"]["output"];
  messageHasVisualization?: Maybe<Message>;
  messageHasVisualizationAggregate?: Maybe<VisualizationMessageMessageHasVisualizationAggregationSelection>;
  messageHasVisualizationConnection: VisualizationMessageHasVisualizationConnection;
  status: VisualizationStatus;
  visualizationDescription?: Maybe<Scalars["String"]["output"]>;
  visualizationType?: Maybe<Scalars["String"]["output"]>;
};

export type VisualizationAiTutorHasVisualizationArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AiTutorOptions>;
  where?: InputMaybe<AiTutorWhere>;
};

export type VisualizationAiTutorHasVisualizationAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AiTutorWhere>;
};

export type VisualizationAiTutorHasVisualizationConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<VisualizationAiTutorHasVisualizationConnectionSort>>;
  where?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
};

export type VisualizationMessageHasVisualizationArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type VisualizationMessageHasVisualizationAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type VisualizationMessageHasVisualizationConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<VisualizationMessageHasVisualizationConnectionSort>>;
  where?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
};

export type VisualizationAiTutorAiTutorHasVisualizationAggregationSelection = {
  __typename?: "VisualizationAITutorAiTutorHasVisualizationAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<VisualizationAiTutorAiTutorHasVisualizationNodeAggregateSelection>;
};

export type VisualizationAiTutorAiTutorHasVisualizationNodeAggregateSelection = {
  __typename?: "VisualizationAITutorAiTutorHasVisualizationNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  targetAudience: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type VisualizationAggregateSelection = {
  __typename?: "VisualizationAggregateSelection";
  code: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  visualizationDescription: StringAggregateSelection;
  visualizationType: StringAggregateSelection;
};

export type VisualizationAiTutorHasVisualizationAggregateInput = {
  AND?: InputMaybe<Array<VisualizationAiTutorHasVisualizationAggregateInput>>;
  NOT?: InputMaybe<VisualizationAiTutorHasVisualizationAggregateInput>;
  OR?: InputMaybe<Array<VisualizationAiTutorHasVisualizationAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<VisualizationAiTutorHasVisualizationNodeAggregationWhereInput>;
};

export type VisualizationAiTutorHasVisualizationConnectFieldInput = {
  connect?: InputMaybe<AiTutorConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AiTutorConnectWhere>;
};

export type VisualizationAiTutorHasVisualizationConnectOrCreateFieldInput = {
  onCreate: VisualizationAiTutorHasVisualizationConnectOrCreateFieldInputOnCreate;
  where: AiTutorConnectOrCreateWhere;
};

export type VisualizationAiTutorHasVisualizationConnectOrCreateFieldInputOnCreate = {
  node: AiTutorOnCreateInput;
};

export type VisualizationAiTutorHasVisualizationConnection = {
  __typename?: "VisualizationAiTutorHasVisualizationConnection";
  edges: Array<VisualizationAiTutorHasVisualizationRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type VisualizationAiTutorHasVisualizationConnectionSort = {
  node?: InputMaybe<AiTutorSort>;
};

export type VisualizationAiTutorHasVisualizationConnectionWhere = {
  AND?: InputMaybe<Array<VisualizationAiTutorHasVisualizationConnectionWhere>>;
  NOT?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
  OR?: InputMaybe<Array<VisualizationAiTutorHasVisualizationConnectionWhere>>;
  node?: InputMaybe<AiTutorWhere>;
};

export type VisualizationAiTutorHasVisualizationCreateFieldInput = {
  node: AiTutorCreateInput;
};

export type VisualizationAiTutorHasVisualizationDeleteFieldInput = {
  delete?: InputMaybe<AiTutorDeleteInput>;
  where?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
};

export type VisualizationAiTutorHasVisualizationDisconnectFieldInput = {
  disconnect?: InputMaybe<AiTutorDisconnectInput>;
  where?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
};

export type VisualizationAiTutorHasVisualizationFieldInput = {
  connect?: InputMaybe<VisualizationAiTutorHasVisualizationConnectFieldInput>;
  connectOrCreate?: InputMaybe<VisualizationAiTutorHasVisualizationConnectOrCreateFieldInput>;
  create?: InputMaybe<VisualizationAiTutorHasVisualizationCreateFieldInput>;
};

export type VisualizationAiTutorHasVisualizationNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<VisualizationAiTutorHasVisualizationNodeAggregationWhereInput>>;
  NOT?: InputMaybe<VisualizationAiTutorHasVisualizationNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<VisualizationAiTutorHasVisualizationNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  targetAudience_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  targetAudience_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  targetAudience_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VisualizationAiTutorHasVisualizationRelationship = {
  __typename?: "VisualizationAiTutorHasVisualizationRelationship";
  cursor: Scalars["String"]["output"];
  node: AiTutor;
};

export type VisualizationAiTutorHasVisualizationUpdateConnectionInput = {
  node?: InputMaybe<AiTutorUpdateInput>;
};

export type VisualizationAiTutorHasVisualizationUpdateFieldInput = {
  connect?: InputMaybe<VisualizationAiTutorHasVisualizationConnectFieldInput>;
  connectOrCreate?: InputMaybe<VisualizationAiTutorHasVisualizationConnectOrCreateFieldInput>;
  create?: InputMaybe<VisualizationAiTutorHasVisualizationCreateFieldInput>;
  delete?: InputMaybe<VisualizationAiTutorHasVisualizationDeleteFieldInput>;
  disconnect?: InputMaybe<VisualizationAiTutorHasVisualizationDisconnectFieldInput>;
  update?: InputMaybe<VisualizationAiTutorHasVisualizationUpdateConnectionInput>;
  where?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
};

export type VisualizationConnectInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationConnectFieldInput>;
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationConnectFieldInput>;
};

export type VisualizationConnectOrCreateInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationConnectOrCreateFieldInput>;
};

export type VisualizationConnectWhere = {
  node: VisualizationWhere;
};

export type VisualizationContentInput = {
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveInput>;
  title: Scalars["String"]["input"];
};

export type VisualizationCreateInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationFieldInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  id: Scalars["ID"]["input"];
  lastModified: Scalars["DateTime"]["input"];
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationFieldInput>;
  status: VisualizationStatus;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type VisualizationDeleteInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationDeleteFieldInput>;
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationDeleteFieldInput>;
};

export type VisualizationDisconnectInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationDisconnectFieldInput>;
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationDisconnectFieldInput>;
};

export type VisualizationEdge = {
  __typename?: "VisualizationEdge";
  cursor: Scalars["String"]["output"];
  node: Visualization;
};

export type VisualizationMessageHasVisualizationAggregateInput = {
  AND?: InputMaybe<Array<VisualizationMessageHasVisualizationAggregateInput>>;
  NOT?: InputMaybe<VisualizationMessageHasVisualizationAggregateInput>;
  OR?: InputMaybe<Array<VisualizationMessageHasVisualizationAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<VisualizationMessageHasVisualizationNodeAggregationWhereInput>;
};

export type VisualizationMessageHasVisualizationConnectFieldInput = {
  connect?: InputMaybe<MessageConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type VisualizationMessageHasVisualizationConnection = {
  __typename?: "VisualizationMessageHasVisualizationConnection";
  edges: Array<VisualizationMessageHasVisualizationRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type VisualizationMessageHasVisualizationConnectionSort = {
  node?: InputMaybe<MessageSort>;
};

export type VisualizationMessageHasVisualizationConnectionWhere = {
  AND?: InputMaybe<Array<VisualizationMessageHasVisualizationConnectionWhere>>;
  NOT?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
  OR?: InputMaybe<Array<VisualizationMessageHasVisualizationConnectionWhere>>;
  node?: InputMaybe<MessageWhere>;
};

export type VisualizationMessageHasVisualizationCreateFieldInput = {
  node: MessageCreateInput;
};

export type VisualizationMessageHasVisualizationDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
};

export type VisualizationMessageHasVisualizationDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
};

export type VisualizationMessageHasVisualizationFieldInput = {
  connect?: InputMaybe<VisualizationMessageHasVisualizationConnectFieldInput>;
  create?: InputMaybe<VisualizationMessageHasVisualizationCreateFieldInput>;
};

export type VisualizationMessageHasVisualizationNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<VisualizationMessageHasVisualizationNodeAggregationWhereInput>>;
  NOT?: InputMaybe<VisualizationMessageHasVisualizationNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<VisualizationMessageHasVisualizationNodeAggregationWhereInput>>;
  aspectDetails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  aspectDetails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  aspectDetails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  index_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  index_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  parameters_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  parameters_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VisualizationMessageHasVisualizationRelationship = {
  __typename?: "VisualizationMessageHasVisualizationRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type VisualizationMessageHasVisualizationUpdateConnectionInput = {
  node?: InputMaybe<MessageUpdateInput>;
};

export type VisualizationMessageHasVisualizationUpdateFieldInput = {
  connect?: InputMaybe<VisualizationMessageHasVisualizationConnectFieldInput>;
  create?: InputMaybe<VisualizationMessageHasVisualizationCreateFieldInput>;
  delete?: InputMaybe<VisualizationMessageHasVisualizationDeleteFieldInput>;
  disconnect?: InputMaybe<VisualizationMessageHasVisualizationDisconnectFieldInput>;
  update?: InputMaybe<VisualizationMessageHasVisualizationUpdateConnectionInput>;
  where?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
};

export type VisualizationMessageMessageHasVisualizationAggregationSelection = {
  __typename?: "VisualizationMessageMessageHasVisualizationAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<VisualizationMessageMessageHasVisualizationNodeAggregateSelection>;
};

export type VisualizationMessageMessageHasVisualizationNodeAggregateSelection = {
  __typename?: "VisualizationMessageMessageHasVisualizationNodeAggregateSelection";
  aspectDetails: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  index: IntAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  parameters: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type VisualizationOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more VisualizationSort objects to sort Visualizations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<VisualizationSort>>;
};

export type VisualizationRelationInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationCreateFieldInput>;
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationCreateFieldInput>;
};

export type VisualizationResponse = {
  __typename?: "VisualizationResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<FormattedVisualization>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type VisualizationResponseAggregateSelection = {
  __typename?: "VisualizationResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type VisualizationResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VisualizationResponseEdge = {
  __typename?: "VisualizationResponseEdge";
  cursor: Scalars["String"]["output"];
  node: VisualizationResponse;
};

export type VisualizationResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more VisualizationResponseSort objects to sort VisualizationResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<VisualizationResponseSort>>;
};

/** Fields to sort VisualizationResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one VisualizationResponseSort object. */
export type VisualizationResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type VisualizationResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VisualizationResponseWhere = {
  AND?: InputMaybe<Array<VisualizationResponseWhere>>;
  NOT?: InputMaybe<VisualizationResponseWhere>;
  OR?: InputMaybe<Array<VisualizationResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VisualizationResponsesConnection = {
  __typename?: "VisualizationResponsesConnection";
  edges: Array<VisualizationResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Visualizations by. The order in which sorts are applied is not guaranteed when specifying many fields in one VisualizationSort object. */
export type VisualizationSort = {
  code?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  visualizationDescription?: InputMaybe<SortDirection>;
  visualizationType?: InputMaybe<SortDirection>;
};

export enum VisualizationStatus {
  Completed = "completed",
  Failed = "failed",
  Processing = "processing",
}

export type VisualizationUpdateInput = {
  aiTutorHasVisualization?: InputMaybe<VisualizationAiTutorHasVisualizationUpdateFieldInput>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  messageHasVisualization?: InputMaybe<VisualizationMessageHasVisualizationUpdateFieldInput>;
  status?: InputMaybe<VisualizationStatus>;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
};

export type VisualizationWhere = {
  AND?: InputMaybe<Array<VisualizationWhere>>;
  NOT?: InputMaybe<VisualizationWhere>;
  OR?: InputMaybe<Array<VisualizationWhere>>;
  aiTutorHasVisualization?: InputMaybe<AiTutorWhere>;
  aiTutorHasVisualizationAggregate?: InputMaybe<VisualizationAiTutorHasVisualizationAggregateInput>;
  aiTutorHasVisualizationConnection?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
  aiTutorHasVisualizationConnection_NOT?: InputMaybe<VisualizationAiTutorHasVisualizationConnectionWhere>;
  aiTutorHasVisualization_NOT?: InputMaybe<AiTutorWhere>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  code_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  code_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  code_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  code_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  messageHasVisualization?: InputMaybe<MessageWhere>;
  messageHasVisualizationAggregate?: InputMaybe<VisualizationMessageHasVisualizationAggregateInput>;
  messageHasVisualizationConnection?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
  messageHasVisualizationConnection_NOT?: InputMaybe<VisualizationMessageHasVisualizationConnectionWhere>;
  messageHasVisualization_NOT?: InputMaybe<MessageWhere>;
  status?: InputMaybe<VisualizationStatus>;
  status_IN?: InputMaybe<Array<VisualizationStatus>>;
  visualizationDescription?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  visualizationDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  visualizationType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  visualizationType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type VisualizationsConnection = {
  __typename?: "VisualizationsConnection";
  edges: Array<VisualizationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntake = {
  __typename?: "WaterIntake";
  created: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  usersHasWaterIntake: Array<User>;
  usersHasWaterIntakeAggregate?: Maybe<WaterIntakeUserUsersHasWaterIntakeAggregationSelection>;
  usersHasWaterIntakeConnection: WaterIntakeUsersHasWaterIntakeConnection;
  usersundefined: Array<User>;
  usersundefinedAggregate?: Maybe<WaterIntakeUserUsersundefinedAggregationSelection>;
  usersundefinedConnection: WaterIntakeUsersundefinedConnection;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type WaterIntakeUsersHasWaterIntakeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionSort>>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersundefinedArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionSort>>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeAggregateSelection = {
  __typename?: "WaterIntakeAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type WaterIntakeConnectInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
};

export type WaterIntakeConnectOrCreateInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedConnectOrCreateFieldInput>>;
};

export type WaterIntakeConnectWhere = {
  node: WaterIntakeWhere;
};

export type WaterIntakeCreateInput = {
  created: Scalars["DateTime"]["input"];
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  usersHasWaterIntake?: InputMaybe<WaterIntakeUsersHasWaterIntakeFieldInput>;
  usersundefined?: InputMaybe<WaterIntakeUsersundefinedFieldInput>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeDeleteInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDeleteFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedDeleteFieldInput>>;
};

export type WaterIntakeDisconnectInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDisconnectFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedDisconnectFieldInput>>;
};

export type WaterIntakeEdge = {
  __typename?: "WaterIntakeEdge";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
};

export type WaterIntakeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WaterIntakeSort objects to sort WaterIntakes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WaterIntakeSort>>;
};

export type WaterIntakeRelationInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
};

/** Fields to sort WaterIntakes by. The order in which sorts are applied is not guaranteed when specifying many fields in one WaterIntakeSort object. */
export type WaterIntakeSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  water_intake_oz?: InputMaybe<SortDirection>;
};

export type WaterIntakeSummariesConnection = {
  __typename?: "WaterIntakeSummariesConnection";
  edges: Array<WaterIntakeSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeSummary = {
  __typename?: "WaterIntakeSummary";
  goalOz: Scalars["Float"]["output"];
  percentageTowardsGoal: Scalars["Float"]["output"];
  totalWaterIntake: Scalars["Float"]["output"];
};

export type WaterIntakeSummaryAggregateSelection = {
  __typename?: "WaterIntakeSummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  goalOz: FloatAggregateSelection;
  percentageTowardsGoal: FloatAggregateSelection;
  totalWaterIntake: FloatAggregateSelection;
};

export type WaterIntakeSummaryCreateInput = {
  goalOz: Scalars["Float"]["input"];
  percentageTowardsGoal: Scalars["Float"]["input"];
  totalWaterIntake: Scalars["Float"]["input"];
};

export type WaterIntakeSummaryEdge = {
  __typename?: "WaterIntakeSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: WaterIntakeSummary;
};

export type WaterIntakeSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WaterIntakeSummarySort objects to sort WaterIntakeSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WaterIntakeSummarySort>>;
};

/** Fields to sort WaterIntakeSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one WaterIntakeSummarySort object. */
export type WaterIntakeSummarySort = {
  goalOz?: InputMaybe<SortDirection>;
  percentageTowardsGoal?: InputMaybe<SortDirection>;
  totalWaterIntake?: InputMaybe<SortDirection>;
};

export type WaterIntakeSummaryUpdateInput = {
  goalOz?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeSummaryWhere = {
  AND?: InputMaybe<Array<WaterIntakeSummaryWhere>>;
  NOT?: InputMaybe<WaterIntakeSummaryWhere>;
  OR?: InputMaybe<Array<WaterIntakeSummaryWhere>>;
  goalOz?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  goalOz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_GT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  percentageTowardsGoal_LT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  totalWaterIntake_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeUpdateFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedUpdateFieldInput>>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeUserUsersHasWaterIntakeAggregationSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection>;
  node?: Maybe<WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection>;
};

export type WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type WaterIntakeUserUsersundefinedAggregationSelection = {
  __typename?: "WaterIntakeUserUsersundefinedAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<WaterIntakeUserUsersundefinedEdgeAggregateSelection>;
  node?: Maybe<WaterIntakeUserUsersundefinedNodeAggregateSelection>;
};

export type WaterIntakeUserUsersundefinedEdgeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersundefinedEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type WaterIntakeUserUsersundefinedNodeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersundefinedNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type WaterIntakeUsersHasWaterIntakeAggregateInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeAggregateInput>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeAggregateInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  node?: InputMaybe<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>;
};

export type WaterIntakeUsersHasWaterIntakeConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasWaterIntakePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInput = {
  onCreate: WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInputOnCreate = {
  edge: HasWaterIntakePropertiesCreateInput;
  node: UserOnCreateInput;
};

export type WaterIntakeUsersHasWaterIntakeConnection = {
  __typename?: "WaterIntakeUsersHasWaterIntakeConnection";
  edges: Array<WaterIntakeUsersHasWaterIntakeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeUsersHasWaterIntakeConnectionSort = {
  edge?: InputMaybe<HasWaterIntakePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type WaterIntakeUsersHasWaterIntakeConnectionWhere = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionWhere>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionWhere>>;
  edge?: InputMaybe<HasWaterIntakePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeCreateFieldInput = {
  edge: HasWaterIntakePropertiesCreateInput;
  node: UserCreateInput;
};

export type WaterIntakeUsersHasWaterIntakeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersHasWaterIntakeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersHasWaterIntakeFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
};

export type WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WaterIntakeUsersHasWaterIntakeRelationship = {
  __typename?: "WaterIntakeUsersHasWaterIntakeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasWaterIntakeProperties;
};

export type WaterIntakeUsersHasWaterIntakeUpdateConnectionInput = {
  edge?: InputMaybe<HasWaterIntakePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type WaterIntakeUsersHasWaterIntakeUpdateFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
  delete?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDisconnectFieldInput>>;
  update?: InputMaybe<WaterIntakeUsersHasWaterIntakeUpdateConnectionInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersundefinedAggregateInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedAggregateInput>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedAggregateInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<WaterIntakeUsersundefinedNodeAggregationWhereInput>;
};

export type WaterIntakeUsersundefinedConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WaterIntakeUsersundefinedConnectOrCreateFieldInput = {
  onCreate: WaterIntakeUsersundefinedConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type WaterIntakeUsersundefinedConnectOrCreateFieldInputOnCreate = {
  edge: UndefinedPropertiesCreateInput;
  node: UserOnCreateInput;
};

export type WaterIntakeUsersundefinedConnection = {
  __typename?: "WaterIntakeUsersundefinedConnection";
  edges: Array<WaterIntakeUsersundefinedRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeUsersundefinedConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type WaterIntakeUsersundefinedConnectionWhere = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionWhere>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: UserCreateInput;
};

export type WaterIntakeUsersundefinedDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeUsersundefinedDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeUsersundefinedFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WaterIntakeUsersundefinedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
};

export type WaterIntakeUsersundefinedNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WaterIntakeUsersundefinedRelationship = {
  __typename?: "WaterIntakeUsersundefinedRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UndefinedProperties;
};

export type WaterIntakeUsersundefinedUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type WaterIntakeUsersundefinedUpdateFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WaterIntakeUsersundefinedConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
  delete?: InputMaybe<Array<WaterIntakeUsersundefinedDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WaterIntakeUsersundefinedDisconnectFieldInput>>;
  update?: InputMaybe<WaterIntakeUsersundefinedUpdateConnectionInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeWhere = {
  AND?: InputMaybe<Array<WaterIntakeWhere>>;
  NOT?: InputMaybe<WaterIntakeWhere>;
  OR?: InputMaybe<Array<WaterIntakeWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasWaterIntakeAggregate?: InputMaybe<WaterIntakeUsersHasWaterIntakeAggregateInput>;
  /** Return WaterIntakes where all of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_ALL?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where none of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_NONE?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where one of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_SINGLE?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where some of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_SOME?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where all of the related Users match this filter */
  usersHasWaterIntake_ALL?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where none of the related Users match this filter */
  usersHasWaterIntake_NONE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where one of the related Users match this filter */
  usersHasWaterIntake_SINGLE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where some of the related Users match this filter */
  usersHasWaterIntake_SOME?: InputMaybe<UserWhere>;
  usersundefinedAggregate?: InputMaybe<WaterIntakeUsersundefinedAggregateInput>;
  /** Return WaterIntakes where all of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_ALL?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where none of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_NONE?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where one of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_SINGLE?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where some of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_SOME?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where all of the related Users match this filter */
  usersundefined_ALL?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where none of the related Users match this filter */
  usersundefined_NONE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where one of the related Users match this filter */
  usersundefined_SINGLE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where some of the related Users match this filter */
  usersundefined_SOME?: InputMaybe<UserWhere>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  water_intake_oz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakesConnection = {
  __typename?: "WaterIntakesConnection";
  edges: Array<WaterIntakeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Webhook = {
  __typename?: "Webhook";
  events?: Maybe<Array<Scalars["String"]["output"]>>;
  id: Scalars["ID"]["output"];
  tenant: Tenant;
  tenantAggregate?: Maybe<WebhookTenantTenantAggregationSelection>;
  tenantConnection: WebhookTenantConnection;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type WebhookTenantArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TenantOptions>;
  where?: InputMaybe<TenantWhere>;
};

export type WebhookTenantAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TenantWhere>;
};

export type WebhookTenantConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WebhookTenantConnectionSort>>;
  where?: InputMaybe<WebhookTenantConnectionWhere>;
};

export type WebhookAggregateSelection = {
  __typename?: "WebhookAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  url: StringAggregateSelection;
};

export type WebhookConnectInput = {
  tenant?: InputMaybe<WebhookTenantConnectFieldInput>;
};

export type WebhookConnectWhere = {
  node: WebhookWhere;
};

export type WebhookCreateInput = {
  events?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenant?: InputMaybe<WebhookTenantFieldInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebhookDeleteInput = {
  tenant?: InputMaybe<WebhookTenantDeleteFieldInput>;
};

export type WebhookDisconnectInput = {
  tenant?: InputMaybe<WebhookTenantDisconnectFieldInput>;
};

export type WebhookEdge = {
  __typename?: "WebhookEdge";
  cursor: Scalars["String"]["output"];
  node: Webhook;
};

export type WebhookOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WebhookSort objects to sort Webhooks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WebhookSort>>;
};

export type WebhookRelationInput = {
  tenant?: InputMaybe<WebhookTenantCreateFieldInput>;
};

/** Fields to sort Webhooks by. The order in which sorts are applied is not guaranteed when specifying many fields in one WebhookSort object. */
export type WebhookSort = {
  id?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type WebhookTenantAggregateInput = {
  AND?: InputMaybe<Array<WebhookTenantAggregateInput>>;
  NOT?: InputMaybe<WebhookTenantAggregateInput>;
  OR?: InputMaybe<Array<WebhookTenantAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<WebhookTenantNodeAggregationWhereInput>;
};

export type WebhookTenantConnectFieldInput = {
  connect?: InputMaybe<TenantConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TenantConnectWhere>;
};

export type WebhookTenantConnection = {
  __typename?: "WebhookTenantConnection";
  edges: Array<WebhookTenantRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WebhookTenantConnectionSort = {
  node?: InputMaybe<TenantSort>;
};

export type WebhookTenantConnectionWhere = {
  AND?: InputMaybe<Array<WebhookTenantConnectionWhere>>;
  NOT?: InputMaybe<WebhookTenantConnectionWhere>;
  OR?: InputMaybe<Array<WebhookTenantConnectionWhere>>;
  node?: InputMaybe<TenantWhere>;
};

export type WebhookTenantCreateFieldInput = {
  node: TenantCreateInput;
};

export type WebhookTenantDeleteFieldInput = {
  delete?: InputMaybe<TenantDeleteInput>;
  where?: InputMaybe<WebhookTenantConnectionWhere>;
};

export type WebhookTenantDisconnectFieldInput = {
  disconnect?: InputMaybe<TenantDisconnectInput>;
  where?: InputMaybe<WebhookTenantConnectionWhere>;
};

export type WebhookTenantFieldInput = {
  connect?: InputMaybe<WebhookTenantConnectFieldInput>;
  create?: InputMaybe<WebhookTenantCreateFieldInput>;
};

export type WebhookTenantNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WebhookTenantNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WebhookTenantNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WebhookTenantNodeAggregationWhereInput>>;
  bugReportEmails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailBackgroundColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailFooterColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emailRadius_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emailRadius_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSecretKey_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeTrialDays_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeTrialDays_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WebhookTenantRelationship = {
  __typename?: "WebhookTenantRelationship";
  cursor: Scalars["String"]["output"];
  node: Tenant;
};

export type WebhookTenantTenantAggregationSelection = {
  __typename?: "WebhookTenantTenantAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<WebhookTenantTenantNodeAggregateSelection>;
};

export type WebhookTenantTenantNodeAggregateSelection = {
  __typename?: "WebhookTenantTenantNodeAggregateSelection";
  bugReportEmails: StringAggregateSelection;
  companionName: StringAggregateSelection;
  connectURL: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emailBackgroundColor: StringAggregateSelection;
  emailFooterColor: StringAggregateSelection;
  emailRadius: StringAggregateSelection;
  emulationStatement: StringAggregateSelection;
  externalSendGridAppInviteTemplateId: StringAggregateSelection;
  externalSendGridDailyEmailTemplateId: StringAggregateSelection;
  externalSendGridFromEmail: StringAggregateSelection;
  externalSendGridFromName: StringAggregateSelection;
  externalSendGridTwilioServiceSID: StringAggregateSelection;
  gameMenuBg: StringAggregateSelection;
  gameMenuCardBg: StringAggregateSelection;
  gameMenuCardButton: StringAggregateSelection;
  gameMenuCardButtonText: StringAggregateSelection;
  gameMenuCardRefresh: StringAggregateSelection;
  gameMenuProgress: StringAggregateSelection;
  gameMenuRefresh: StringAggregateSelection;
  gameMenuRefreshText: StringAggregateSelection;
  gameMenuTrack: StringAggregateSelection;
  generalPrompt: StringAggregateSelection;
  id: IdAggregateSelection;
  key: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningEmulationStatement: StringAggregateSelection;
  learningFieldColor: StringAggregateSelection;
  learningSubFieldColor: StringAggregateSelection;
  logo: StringAggregateSelection;
  metaTagsAppleTouchIcon: StringAggregateSelection;
  metaTagsDescription: StringAggregateSelection;
  metaTagsThemeColor: StringAggregateSelection;
  metaTagsTitle: StringAggregateSelection;
  metaTagsTwitterCard: StringAggregateSelection;
  metaTagsTwitterDescription: StringAggregateSelection;
  metaTagsTwitterImage: StringAggregateSelection;
  metaTagsTwitterSite: StringAggregateSelection;
  metaTagsTwitterTitle: StringAggregateSelection;
  newsSearchTerms: StringAggregateSelection;
  newsSectionTitle: StringAggregateSelection;
  onboardingPrompt: StringAggregateSelection;
  primaryColor: StringAggregateSelection;
  stripeSecretKey: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  stripeTrialDays: IntAggregateSelection;
  tenantDomain: StringAggregateSelection;
  tenantName: StringAggregateSelection;
  termsOfServiceLink: StringAggregateSelection;
  upgradeModalBody: StringAggregateSelection;
};

export type WebhookTenantUpdateConnectionInput = {
  node?: InputMaybe<TenantUpdateInput>;
};

export type WebhookTenantUpdateFieldInput = {
  connect?: InputMaybe<WebhookTenantConnectFieldInput>;
  create?: InputMaybe<WebhookTenantCreateFieldInput>;
  delete?: InputMaybe<WebhookTenantDeleteFieldInput>;
  disconnect?: InputMaybe<WebhookTenantDisconnectFieldInput>;
  update?: InputMaybe<WebhookTenantUpdateConnectionInput>;
  where?: InputMaybe<WebhookTenantConnectionWhere>;
};

export type WebhookUpdateInput = {
  events?: InputMaybe<Array<Scalars["String"]["input"]>>;
  events_POP?: InputMaybe<Scalars["Int"]["input"]>;
  events_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenant?: InputMaybe<WebhookTenantUpdateFieldInput>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebhookWhere = {
  AND?: InputMaybe<Array<WebhookWhere>>;
  NOT?: InputMaybe<WebhookWhere>;
  OR?: InputMaybe<Array<WebhookWhere>>;
  events?: InputMaybe<Array<Scalars["String"]["input"]>>;
  events_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  tenant?: InputMaybe<TenantWhere>;
  tenantAggregate?: InputMaybe<WebhookTenantAggregateInput>;
  tenantConnection?: InputMaybe<WebhookTenantConnectionWhere>;
  tenantConnection_NOT?: InputMaybe<WebhookTenantConnectionWhere>;
  tenant_NOT?: InputMaybe<TenantWhere>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type WebhooksConnection = {
  __typename?: "WebhooksConnection";
  edges: Array<WebhookEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummariesConnection = {
  __typename?: "WeeklySummariesConnection";
  edges: Array<WeeklySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummary = {
  __typename?: "WeeklySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
  usersHasSummary: Array<User>;
  usersHasSummaryAggregate?: Maybe<WeeklySummaryUserUsersHasSummaryAggregationSelection>;
  usersHasSummaryConnection: WeeklySummaryUsersHasSummaryConnection;
};

export type WeeklySummaryUsersHasSummaryArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionSort>>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryAggregateSelection = {
  __typename?: "WeeklySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type WeeklySummaryConnectInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
};

export type WeeklySummaryConnectOrCreateInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
};

export type WeeklySummaryConnectWhere = {
  node: WeeklySummaryWhere;
};

export type WeeklySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
  usersHasSummary?: InputMaybe<WeeklySummaryUsersHasSummaryFieldInput>;
};

export type WeeklySummaryDeleteInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDeleteFieldInput>>;
};

export type WeeklySummaryDisconnectInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDisconnectFieldInput>>;
};

export type WeeklySummaryEdge = {
  __typename?: "WeeklySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: WeeklySummary;
};

export type WeeklySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WeeklySummarySort objects to sort WeeklySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WeeklySummarySort>>;
};

export type WeeklySummaryRelationInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
};

/** Fields to sort WeeklySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one WeeklySummarySort object. */
export type WeeklySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type WeeklySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryUpdateFieldInput>>;
};

export type WeeklySummaryUserUsersHasSummaryAggregationSelection = {
  __typename?: "WeeklySummaryUserUsersHasSummaryAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<WeeklySummaryUserUsersHasSummaryNodeAggregateSelection>;
};

export type WeeklySummaryUserUsersHasSummaryNodeAggregateSelection = {
  __typename?: "WeeklySummaryUserUsersHasSummaryNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type WeeklySummaryUsersHasSummaryAggregateInput = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryAggregateInput>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryAggregateInput>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>;
};

export type WeeklySummaryUsersHasSummaryConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WeeklySummaryUsersHasSummaryConnectOrCreateFieldInput = {
  onCreate: WeeklySummaryUsersHasSummaryConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type WeeklySummaryUsersHasSummaryConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type WeeklySummaryUsersHasSummaryConnection = {
  __typename?: "WeeklySummaryUsersHasSummaryConnection";
  edges: Array<WeeklySummaryUsersHasSummaryRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummaryUsersHasSummaryConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type WeeklySummaryUsersHasSummaryConnectionWhere = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionWhere>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryCreateFieldInput = {
  node: UserCreateInput;
};

export type WeeklySummaryUsersHasSummaryDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryUsersHasSummaryDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryUsersHasSummaryFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
};

export type WeeklySummaryUsersHasSummaryNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WeeklySummaryUsersHasSummaryRelationship = {
  __typename?: "WeeklySummaryUsersHasSummaryRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type WeeklySummaryUsersHasSummaryUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type WeeklySummaryUsersHasSummaryUpdateFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
  delete?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDisconnectFieldInput>>;
  update?: InputMaybe<WeeklySummaryUsersHasSummaryUpdateConnectionInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryWhere = {
  AND?: InputMaybe<Array<WeeklySummaryWhere>>;
  NOT?: InputMaybe<WeeklySummaryWhere>;
  OR?: InputMaybe<Array<WeeklySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummaryAggregate?: InputMaybe<WeeklySummaryUsersHasSummaryAggregateInput>;
  /** Return WeeklySummaries where all of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_ALL?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where none of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_NONE?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where one of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SINGLE?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where some of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SOME?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where all of the related Users match this filter */
  usersHasSummary_ALL?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where none of the related Users match this filter */
  usersHasSummary_NONE?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where one of the related Users match this filter */
  usersHasSummary_SINGLE?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where some of the related Users match this filter */
  usersHasSummary_SOME?: InputMaybe<UserWhere>;
};

export type WhitelistedEmail = {
  __typename?: "WhitelistedEmail";
  email: Scalars["String"]["output"];
  invitee: User;
  inviteeAggregate?: Maybe<WhitelistedEmailUserInviteeAggregationSelection>;
  inviteeConnection: WhitelistedEmailInviteeConnection;
};

export type WhitelistedEmailInviteeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WhitelistedEmailInviteeConnectionSort>>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailAggregateSelection = {
  __typename?: "WhitelistedEmailAggregateSelection";
  count: Scalars["Int"]["output"];
  email: StringAggregateSelection;
};

export type WhitelistedEmailConnectInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
};

export type WhitelistedEmailConnectOrCreateInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeConnectOrCreateFieldInput>;
};

export type WhitelistedEmailConnectWhere = {
  node: WhitelistedEmailWhere;
};

export type WhitelistedEmailCreateInput = {
  email: Scalars["String"]["input"];
  invitee?: InputMaybe<WhitelistedEmailInviteeFieldInput>;
};

export type WhitelistedEmailDeleteInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeDeleteFieldInput>;
};

export type WhitelistedEmailDisconnectInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeDisconnectFieldInput>;
};

export type WhitelistedEmailEdge = {
  __typename?: "WhitelistedEmailEdge";
  cursor: Scalars["String"]["output"];
  node: WhitelistedEmail;
};

export type WhitelistedEmailInviteeAggregateInput = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeAggregateInput>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeAggregateInput>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<WhitelistedEmailInviteeNodeAggregationWhereInput>;
};

export type WhitelistedEmailInviteeConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WhitelistedEmailInviteeConnectOrCreateFieldInput = {
  onCreate: WhitelistedEmailInviteeConnectOrCreateFieldInputOnCreate;
  where: UserConnectOrCreateWhere;
};

export type WhitelistedEmailInviteeConnectOrCreateFieldInputOnCreate = {
  node: UserOnCreateInput;
};

export type WhitelistedEmailInviteeConnection = {
  __typename?: "WhitelistedEmailInviteeConnection";
  edges: Array<WhitelistedEmailInviteeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WhitelistedEmailInviteeConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type WhitelistedEmailInviteeConnectionWhere = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeConnectionWhere>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeCreateFieldInput = {
  node: UserCreateInput;
};

export type WhitelistedEmailInviteeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailInviteeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailInviteeFieldInput = {
  connect?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
  connectOrCreate?: InputMaybe<WhitelistedEmailInviteeConnectOrCreateFieldInput>;
  create?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
};

export type WhitelistedEmailInviteeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  biography_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  biography_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  biography_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  birthday_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  birthday_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  crmUserId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  crmUserId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  crmUserId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationTokenExpiry_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationTokenExpiry_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitationToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  invitationToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  invitationToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meetingLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meetingLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  profilePicture_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  profilePicture_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerIdLearningPayments_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WhitelistedEmailInviteeRelationship = {
  __typename?: "WhitelistedEmailInviteeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type WhitelistedEmailInviteeUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type WhitelistedEmailInviteeUpdateFieldInput = {
  connect?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
  connectOrCreate?: InputMaybe<WhitelistedEmailInviteeConnectOrCreateFieldInput>;
  create?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
  delete?: InputMaybe<WhitelistedEmailInviteeDeleteFieldInput>;
  disconnect?: InputMaybe<WhitelistedEmailInviteeDisconnectFieldInput>;
  update?: InputMaybe<WhitelistedEmailInviteeUpdateConnectionInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WhitelistedEmailSort objects to sort WhitelistedEmails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WhitelistedEmailSort>>;
};

export type WhitelistedEmailRelationInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
};

/** Fields to sort WhitelistedEmails by. The order in which sorts are applied is not guaranteed when specifying many fields in one WhitelistedEmailSort object. */
export type WhitelistedEmailSort = {
  email?: InputMaybe<SortDirection>;
};

export type WhitelistedEmailUpdateInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  invitee?: InputMaybe<WhitelistedEmailInviteeUpdateFieldInput>;
};

export type WhitelistedEmailUserInviteeAggregationSelection = {
  __typename?: "WhitelistedEmailUserInviteeAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<WhitelistedEmailUserInviteeNodeAggregateSelection>;
};

export type WhitelistedEmailUserInviteeNodeAggregateSelection = {
  __typename?: "WhitelistedEmailUserInviteeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  biography: StringAggregateSelection;
  birthday: DateTimeAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  crmUserId: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  invitationToken: StringAggregateSelection;
  invitationTokenExpiry: DateTimeAggregateSelection;
  inviteCount: IntAggregateSelection;
  meetingLink: StringAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  profilePicture: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeCustomerIdLearningPayments: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
  username: StringAggregateSelection;
};

export type WhitelistedEmailWhere = {
  AND?: InputMaybe<Array<WhitelistedEmailWhere>>;
  NOT?: InputMaybe<WhitelistedEmailWhere>;
  OR?: InputMaybe<Array<WhitelistedEmailWhere>>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  invitee?: InputMaybe<UserWhere>;
  inviteeAggregate?: InputMaybe<WhitelistedEmailInviteeAggregateInput>;
  inviteeConnection?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  inviteeConnection_NOT?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  invitee_NOT?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailsConnection = {
  __typename?: "WhitelistedEmailsConnection";
  edges: Array<WhitelistedEmailEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Workout = {
  __typename?: "Workout";
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type WorkoutAggregateSelection = {
  __typename?: "WorkoutAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type WorkoutCreateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutEdge = {
  __typename?: "WorkoutEdge";
  cursor: Scalars["String"]["output"];
  node: Workout;
};

export type WorkoutOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WorkoutSort objects to sort Workouts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WorkoutSort>>;
};

/** Fields to sort Workouts by. The order in which sorts are applied is not guaranteed when specifying many fields in one WorkoutSort object. */
export type WorkoutSort = {
  name?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type WorkoutUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutWhere = {
  AND?: InputMaybe<Array<WorkoutWhere>>;
  NOT?: InputMaybe<WorkoutWhere>;
  OR?: InputMaybe<Array<WorkoutWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutsConnection = {
  __typename?: "WorkoutsConnection";
  edges: Array<WorkoutEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type YearlySummariesConnection = {
  __typename?: "YearlySummariesConnection";
  edges: Array<YearlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type YearlySummary = {
  __typename?: "YearlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type YearlySummaryAggregateSelection = {
  __typename?: "YearlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type YearlySummaryConnectWhere = {
  node: YearlySummaryWhere;
};

export type YearlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type YearlySummaryEdge = {
  __typename?: "YearlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: YearlySummary;
};

export type YearlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more YearlySummarySort objects to sort YearlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<YearlySummarySort>>;
};

/** Fields to sort YearlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one YearlySummarySort object. */
export type YearlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type YearlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type YearlySummaryWhere = {
  AND?: InputMaybe<Array<YearlySummaryWhere>>;
  NOT?: InputMaybe<YearlySummaryWhere>;
  OR?: InputMaybe<Array<YearlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  date_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: {
    __typename?: "AuthPayloadResponse";
    token: string;
    user?: {
      __typename?: "AuthPayloadUser";
      id?: string | null;
      email?: string | null;
      isOnboarded?: boolean | null;
      isPro?: boolean | null;
      name?: string | null;
      isEmailVerified?: boolean | null;
      otpPreference?: string | null;
      countryCode?: string | null;
      phoneNumber?: string | null;
      fullPhoneNumber?: string | null;
      googleAuthTokens?: {
        __typename?: "GoogleAuthTokens";
        access_token?: string | null;
        refresh_token?: string | null;
        scope?: string | null;
        token_type?: string | null;
        expiry_date?: number | null;
      } | null;
    } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: { __typename?: "ResponseType"; message?: string | null; success: boolean } | null;
};

export type SignupMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  language: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type SignupMutation = {
  __typename?: "Mutation";
  signup?: {
    __typename?: "AuthPayloadResponse";
    token: string;
    user?: {
      __typename?: "AuthPayloadUser";
      id?: string | null;
      email?: string | null;
      name?: string | null;
      isPro?: boolean | null;
      isOnboarded?: boolean | null;
      otpPreference?: string | null;
      isEmailVerified?: boolean | null;
      fullPhoneNumber?: string | null;
      preferences?: { __typename?: "AuthPayloadUserPreferences"; language?: string | null } | null;
    } | null;
  } | null;
};

export type CareerIsLoadingMutationVariables = Exact<{
  isLoading: Scalars["Boolean"]["input"];
}>;

export type CareerIsLoadingMutation = {
  __typename?: "Mutation";
  careerIsLoading: { __typename?: "UserCareerLoadingResponse"; isCareerLoading: boolean };
};

export type CareerSubmitFormMutationVariables = Exact<{
  currentOccupation: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  careerGoal: Scalars["String"]["input"];
}>;

export type CareerSubmitFormMutation = { __typename?: "Mutation"; careerSubmitForm: boolean };

export type GenerateSuggestedCareersMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateSuggestedCareersMutation = {
  __typename?: "Mutation";
  generateSuggestedCareers?: { __typename?: "Career"; name: string; id: string } | null;
};

export type SetCareerPathActiveMutationVariables = Exact<{
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type SetCareerPathActiveMutation = { __typename?: "Mutation"; setCareerPathActive: boolean };

export type DeleteAspectMutationVariables = Exact<{
  nodeId: Scalars["ID"]["input"];
}>;

export type DeleteAspectMutation = {
  __typename?: "Mutation";
  deleteAspect: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteMessageMutation = {
  __typename?: "Mutation";
  deleteMessage?: { __typename?: "Message"; id: string } | null;
};

export type ProcessAttachmentMutationVariables = Exact<{
  fileId: Scalars["String"]["input"];
}>;

export type ProcessAttachmentMutation = {
  __typename?: "Mutation";
  processAttachment: {
    __typename?: "ChatAttachmentResponse";
    message?: string | null;
    count?: number | null;
    success: boolean;
  };
};

export type UploadCourseThumbnailMutationVariables = Exact<{
  input: UploadCourseThumbnailInput;
}>;

export type UploadCourseThumbnailMutation = {
  __typename?: "Mutation";
  uploadCourseThumbnail?: {
    __typename?: "UploadCourseThumbnailResponse";
    success: boolean;
    message?: string | null;
    imageUrl?: string | null;
  } | null;
};

export type AddAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
}>;

export type AddAssignmentMutation = {
  __typename?: "Mutation";
  addAssignment: { __typename?: "AddAssignmentResponse"; success: boolean; message?: string | null };
};

export type AddContentModuleMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
}>;

export type AddContentModuleMutation = {
  __typename?: "Mutation";
  addContentModule: { __typename?: "AddContentModuleResponse"; success: boolean; message?: string | null };
};

export type CourseCreationMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  standard: Scalars["String"]["input"];
  weeks: Scalars["Int"]["input"];
  objectives: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  urls: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CourseCreationMutation = {
  __typename?: "Mutation";
  courseCreation?: {
    __typename?: "CourseCreationResponse";
    success: boolean;
    message?: string | null;
    data?: {
      __typename?: "Curriculum";
      name: string;
      standard: string;
      weeks: number;
      objectives: Array<string>;
      created?: any | null;
      last_modified?: any | null;
      modules: Array<{
        __typename?: "Module";
        module_name?: string | null;
        assignments: Array<{ __typename?: "Assignment"; assignment_name?: string | null; html?: string | null }>;
      }>;
    } | null;
  } | null;
};

export type CourseIsLoadingMutationVariables = Exact<{
  isLoading: Scalars["Boolean"]["input"];
}>;

export type CourseIsLoadingMutation = {
  __typename?: "Mutation";
  courseIsLoading: { __typename?: "UserCourseLoadingResponse"; isCourseLoading: boolean };
};

export type DeleteContentAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  assignmentName: Scalars["String"]["input"];
}>;

export type DeleteContentAssignmentMutation = {
  __typename?: "Mutation";
  deleteContentAssignment: {
    __typename?: "DeleteContentAssignmentResponse";
    success: boolean;
    message?: string | null;
  };
};

export type DeleteContentModuleMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
}>;

export type DeleteContentModuleMutation = {
  __typename?: "Mutation";
  deleteContentModule: { __typename?: "DeleteContentModuleResponse"; success: boolean; message?: string | null };
};

export type DeleteCourseMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type DeleteCourseMutation = {
  __typename?: "Mutation";
  deleteCourse: { __typename?: "DeleteCourseResponse"; success: boolean; message: string };
};

export type UpdateAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  currentAssignmentName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
}>;

export type UpdateAssignmentMutation = {
  __typename?: "Mutation";
  updateAssignment: {
    __typename?: "UpdateAssignmentResponse";
    success: boolean;
    message?: string | null;
    data?: { __typename?: "Assignment"; assignment_name?: string | null; html?: string | null } | null;
  };
};

export type UpdateContentModuleNameMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  oldModuleName: Scalars["String"]["input"];
  newModuleName: Scalars["String"]["input"];
}>;

export type UpdateContentModuleNameMutation = {
  __typename?: "Mutation";
  updateContentModuleName: {
    __typename?: "UpdateContentModuleNameResponse";
    success: boolean;
    message?: string | null;
  };
};

export type UploadDocumentsMutationVariables = Exact<{
  files: Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"];
}>;

export type UploadDocumentsMutation = {
  __typename?: "Mutation";
  uploadDocuments?: { __typename?: "UploadDocumentsResponse"; success: boolean; message: string } | null;
};

export type UploadDocumentsToBucketMutationVariables = Exact<{
  files: Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"];
}>;

export type UploadDocumentsToBucketMutation = {
  __typename?: "Mutation";
  uploadDocumentsToBucket?: {
    __typename?: "UploadDocumentsToBucketResponse";
    success: boolean;
    message: string;
    urls: Array<string>;
  } | null;
};

export type MakeCoursePaymentMutationVariables = Exact<{
  courseId: Scalars["String"]["input"];
}>;

export type MakeCoursePaymentMutation = {
  __typename?: "Mutation";
  createCheckoutSession?: {
    __typename?: "ResponseType";
    success: boolean;
    message?: string | null;
    url?: string | null;
  } | null;
};

export type CreateAiTutorMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  enableVideoInsight?: InputMaybe<Scalars["Boolean"]["input"]>;
  parentModuleId: Scalars["ID"]["input"];
  objectives: Array<ObjectiveInput> | ObjectiveInput;
  context?: InputMaybe<Scalars["String"]["input"]>;
  visualizationId?: InputMaybe<Scalars["ID"]["input"]>;
  videoPool?: InputMaybe<Array<InputMaybe<VideoDataInput>> | InputMaybe<VideoDataInput>>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateAiTutorMutation = {
  __typename?: "Mutation";
  createAITutor?: {
    __typename?: "AITutorResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "FormattedAITutor";
      title: string;
      description?: string | null;
      targetAudience?: string | null;
      objectives?: Array<{
        __typename?: "FormattedObjective";
        objective: string;
        sequence?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DeleteAiTutorMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAiTutorMutation = {
  __typename?: "Mutation";
  deleteAITutor?: { __typename?: "AITutorResponse"; success?: boolean | null; message?: string | null } | null;
};

export type EditAiTutorMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  objectives: Array<ObjectiveInput> | ObjectiveInput;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EditAiTutorMutation = {
  __typename?: "Mutation";
  editAITutor?: {
    __typename?: "AITutorResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "FormattedAITutor";
      title: string;
      description?: string | null;
      targetAudience?: string | null;
      objectives?: Array<{
        __typename?: "FormattedObjective";
        objective: string;
        sequence?: number | null;
        id?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateAxioResourceMutationVariables = Exact<{
  moduleId: Scalars["ID"]["input"];
  order: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  files?: InputMaybe<Array<FileInput> | FileInput>;
  videoUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateAxioResourceMutation = {
  __typename?: "Mutation";
  createAxioResource: {
    __typename?: "AxioResourceResponse";
    success: boolean;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "AxioResource";
      id: string;
      name: string;
      description?: string | null;
      videoUrl?: string | null;
      order: number;
      created?: any | null;
      lastModified?: any | null;
    } | null> | null;
  };
};

export type CreateCoursePlaceholderMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  schoolId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
  cost?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
  degreeIds?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
}>;

export type CreateCoursePlaceholderMutation = {
  __typename?: "Mutation";
  createCoursePlaceholder?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    count?: number | null;
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      courseType?: string | null;
      description?: string | null;
      objectives?: Array<string> | null;
      targetAudience?: string | null;
      cost?: number | null;
      startDate?: any | null;
      degreesHasCourse: Array<{ __typename?: "Degree"; id: string; name: string }>;
    } | null> | null;
  } | null;
};

export type DeleteAxioResourceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAxioResourceMutation = {
  __typename?: "Mutation";
  deleteAxioResource: { __typename?: "AxioResourceResponse"; success: boolean; message?: string | null };
};

export type GenerateAiTutorFromTitleMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  targetAudience?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GenerateAiTutorFromTitleMutation = {
  __typename?: "Mutation";
  generateAiTutorFromTitle?: {
    __typename?: "AITutorGeneratedResponse";
    message?: string | null;
    success?: boolean | null;
    data?: {
      __typename?: "GeneratedAiTutor";
      description: string;
      objectives?: Array<{ __typename?: "GeneratedObjective"; objective: string; sequence?: number | null }> | null;
    } | null;
  } | null;
};

export type GenerateCourseContentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type GenerateCourseContentMutation = {
  __typename?: "Mutation";
  generateCourseContent?: {
    __typename?: "GenerateCourseContentResponse";
    count: number;
    message: string;
    success: boolean;
    data?: { __typename?: "GenerateCourseContentData"; id: string } | null;
  } | null;
};

export type GenerateEvalCriteriaMutationVariables = Exact<{
  input: GenerateEvalCriteriaInput;
}>;

export type GenerateEvalCriteriaMutation = {
  __typename?: "Mutation";
  generateEvalCriteria?: {
    __typename?: "AITutorEvalCriteriaResponse";
    message?: string | null;
    success?: boolean | null;
    data?: Array<{
      __typename?: "FormattedObjective";
      objective: string;
      evalCriteria?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateAxioResourceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  resource: UpdateAxioResourceInput;
}>;

export type UpdateAxioResourceMutation = {
  __typename?: "Mutation";
  updateAxioResource: {
    __typename?: "AxioResourceResponse";
    success: boolean;
    message?: string | null;
    data?: Array<{
      __typename?: "AxioResource";
      id: string;
      name: string;
      description?: string | null;
      videoUrl?: string | null;
      order: number;
    } | null> | null;
  };
};

export type CreateVideoPoolMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveForVideoInput> | ObjectiveForVideoInput;
}>;

export type CreateVideoPoolMutation = {
  __typename?: "Mutation";
  createVideoPool: {
    __typename?: "VideoServiceResult";
    success: boolean;
    message?: string | null;
    count: number;
    data?: Array<{
      __typename?: "VideoPoolItem";
      videoId: string;
      url: string;
      title: string;
      description?: string | null;
      thumbnailUrl?: string | null;
      objectiveText?: string | null;
      objectiveSequence?: number | null;
    }> | null;
  };
};

export type AddDegreesToCourseMutationVariables = Exact<{
  input: AddDegreesToCourseInput;
}>;

export type AddDegreesToCourseMutation = {
  __typename?: "Mutation";
  addDegreesToCourse?: { __typename?: "CourseResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateAssignmentMutationVariables = Exact<{
  assignment: CreateAssignmentInput;
}>;

export type CreateAssignmentMutation = {
  __typename?: "Mutation";
  createAssignment?: { __typename?: "AssignmentResponse"; success?: boolean | null; message?: string | null } | null;
};

export type DeleteCourseCascadeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCourseCascadeMutation = {
  __typename?: "Mutation";
  deleteCourseCascade?: { __typename?: "CourseResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateCourseMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  parentSchoolId: Scalars["String"]["input"];
  cost?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type CreateCourseMutation = {
  __typename?: "Mutation";
  createCourse?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Course";
      name?: string | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      cost?: number | null;
    } | null> | null;
  } | null;
};

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateCourseMutation = {
  __typename?: "Mutation";
  updateCourse?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Course"; name?: string | null; isDraft?: boolean | null } | null> | null;
  } | null;
};

export type DeleteModuleCascadeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteModuleCascadeMutation = {
  __typename?: "Mutation";
  deleteModuleCascade?: { __typename?: "ModuleResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateModuleMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  order: Scalars["Float"]["input"];
  parentCourseId: Scalars["ID"]["input"];
}>;

export type CreateModuleMutation = {
  __typename?: "Mutation";
  createModule?: {
    __typename?: "ModuleResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Module"; title?: string | null; order?: number | null } | null> | null;
  } | null;
};

export type UpdateModuleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  isAssessmentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateModuleMutation = {
  __typename?: "Mutation";
  updateModule?: {
    __typename?: "ModuleResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Module"; title?: string | null; order?: number | null } | null> | null;
  } | null;
};

export type CreatePageMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  body?: InputMaybe<Scalars["String"]["input"]>;
  parentModuleId: Scalars["ID"]["input"];
}>;

export type CreatePageMutation = {
  __typename?: "Mutation";
  createPage?: {
    __typename?: "PageResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Page"; title: string; body?: string | null } | null> | null;
  } | null;
};

export type DeletePageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePageMutation = {
  __typename?: "Mutation";
  deletePage?: { __typename?: "PageResponse"; success?: boolean | null; message?: string | null } | null;
};

export type UpdatePageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdatePageMutation = {
  __typename?: "Mutation";
  updatePage?: {
    __typename?: "PageResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Page"; title: string; body?: string | null } | null> | null;
  } | null;
};

export type InviteUsersToEnrollInCourseMutationVariables = Exact<{
  input: InviteUsersToEnrollInCourseInput;
}>;

export type InviteUsersToEnrollInCourseMutation = {
  __typename?: "Mutation";
  inviteUsersToEnrollInCourse?: {
    __typename?: "InviteUsersToEnrollInCourseResponse";
    success?: boolean | null;
    count?: number | null;
    data?: Array<{ __typename?: "InvitedUsers"; email: string; id: string } | null> | null;
  } | null;
};

export type RemoveDegreesFromCourseMutationVariables = Exact<{
  input: RemoveDegreesFromCourseInput;
}>;

export type RemoveDegreesFromCourseMutation = {
  __typename?: "Mutation";
  removeDegreesFromCourse?: { __typename?: "CourseResponse"; success?: boolean | null; message?: string | null } | null;
};

export type RemoveStudentsFromCourseMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  studentIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type RemoveStudentsFromCourseMutation = {
  __typename?: "Mutation";
  removeStudentsFromCourse?: {
    __typename?: "CourseRosterServiceResult";
    success?: boolean | null;
    count?: number | null;
    data?: Array<{ __typename?: "CourseRosterData"; id: string; name: string } | null> | null;
  } | null;
};

export type SubmitStudentAssignmentMutationVariables = Exact<{
  assignmentId: Scalars["ID"]["input"];
  files: Array<FileInput> | FileInput;
}>;

export type SubmitStudentAssignmentMutation = {
  __typename?: "Mutation";
  submitStudentAssignment?: {
    __typename?: "SubmissionResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Submission"; id: string } | null> | null;
  } | null;
};

export type UpdateAssessmentGradeMutationVariables = Exact<{
  assessmentId: Scalars["ID"]["input"];
  grade: Scalars["Float"]["input"];
}>;

export type UpdateAssessmentGradeMutation = {
  __typename?: "Mutation";
  updateAssessmentGrade?: {
    __typename?: "AssessmentResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Assessment"; id: string } | null> | null;
  } | null;
};

export type UpdateResourceOrderMutationVariables = Exact<{
  input: UpdateResourceOrderInput;
}>;

export type UpdateResourceOrderMutation = {
  __typename?: "Mutation";
  updateResourceOrder?: {
    __typename?: "ModuleResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Module"; id: string; resourceOrder?: Array<string> | null } | null> | null;
  } | null;
};

export type UpdateStudentAssignmentGradeMutationVariables = Exact<{
  submissionId: Scalars["ID"]["input"];
  grade: Scalars["Float"]["input"];
  feedback?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateStudentAssignmentGradeMutation = {
  __typename?: "Mutation";
  updateStudentAssignmentGrade?: {
    __typename?: "SubmissionResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Submission"; id: string } | null> | null;
  } | null;
};

export type AddCoursesToDegreeMutationVariables = Exact<{
  input: AddCoursesToDegreeInput;
}>;

export type AddCoursesToDegreeMutation = {
  __typename?: "Mutation";
  addCoursesToDegree?: { __typename?: "DegreeResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateDegreeMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  schoolId: Scalars["ID"]["input"];
}>;

export type CreateDegreeMutation = {
  __typename?: "Mutation";
  createDegree?: {
    __typename?: "DegreeResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Degree"; id: string; name: string } | null> | null;
  } | null;
};

export type DeleteDegreeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDegreeMutation = {
  __typename?: "Mutation";
  deleteDegree?: { __typename?: "DegreeResponse"; success?: boolean | null; message?: string | null } | null;
};

export type EnrollUserInDegreeMutationVariables = Exact<{
  degreeId: Scalars["ID"]["input"];
}>;

export type EnrollUserInDegreeMutation = {
  __typename?: "Mutation";
  enrollInDegree?: {
    __typename?: "DegreeResponse";
    success?: boolean | null;
    data?: Array<{
      __typename?: "Degree";
      id: string;
      name: string;
      usersEnrolledIn: Array<{ __typename?: "User"; id: string }>;
    } | null> | null;
  } | null;
};

export type GenerateSuggestedDegreesMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateSuggestedDegreesMutation = {
  __typename?: "Mutation";
  generateSuggestedDegrees: {
    __typename?: "DegreeResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Degree";
      id: string;
      name: string;
      created?: any | null;
      last_modified?: any | null;
    } | null> | null;
  };
};

export type RemoveCoursesFromDegreeMutationVariables = Exact<{
  input: RemoveCoursesFromDegreeInput;
}>;

export type RemoveCoursesFromDegreeMutation = {
  __typename?: "Mutation";
  removeCoursesFromDegree?: { __typename?: "DegreeResponse"; success?: boolean | null; message?: string | null } | null;
};

export type UpdateDegreeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  degree: UpdateDegreeInput;
}>;

export type UpdateDegreeMutation = {
  __typename?: "Mutation";
  updateDegree?: {
    __typename?: "DegreeResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Degree";
      id: string;
      name: string;
      description?: string | null;
      usersEnrolledIn: Array<{ __typename?: "User"; id: string; name: string }>;
      coursesHasCourse: Array<{ __typename?: "Course"; id: string; name?: string | null }>;
    } | null> | null;
  } | null;
};

export type GenerateKeywordsMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type GenerateKeywordsMutation = { __typename?: "Mutation"; generateYoutubeKeywords?: string | null };

export type GenerateUploadUrlMutationVariables = Exact<{
  fileName: Scalars["String"]["input"];
  storagePath: StoragePathType;
}>;

export type GenerateUploadUrlMutation = {
  __typename?: "Mutation";
  generateUploadUrl: {
    __typename?: "FileResponse";
    message?: string | null;
    success: boolean;
    data?: Array<{
      __typename?: "File";
      id: string;
      fileName: string;
      fileURL: string;
      created: any;
      status: FileStatus;
    } | null> | null;
  };
};

export type MarkQuestCompleteMutationVariables = Exact<{
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
}>;

export type MarkQuestCompleteMutation = {
  __typename?: "Mutation";
  markQuestComplete?: {
    __typename?: "Chat";
    id: string;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type RefreshQuestMutationVariables = Exact<{
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type RefreshQuestMutation = {
  __typename?: "Mutation";
  refreshQuest?: {
    __typename?: "RefreshQuestResponse";
    success: boolean;
    message: string;
    quests: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    }>;
  } | null;
};

export type AudioToggleUserMutationVariables = Exact<{
  audioOn: Scalars["Boolean"]["input"];
}>;

export type AudioToggleUserMutation = {
  __typename?: "Mutation";
  audioToggleUser?: { __typename?: "AudioToggleUserResponse"; audioOn?: boolean | null } | null;
};

export type UpdateNotificationsMutationVariables = Exact<{
  dailyEmailOptIn: Scalars["Boolean"]["input"];
}>;

export type UpdateNotificationsMutation = {
  __typename?: "Mutation";
  updateNotifications?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type AddToWhitelistMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type AddToWhitelistMutation = {
  __typename?: "Mutation";
  addToWhitelist: { __typename?: "WhitelistedEmail"; email: string };
};

export type CompletePendingInviteMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
  service: Scalars["String"]["input"];
}>;

export type CompletePendingInviteMutation = {
  __typename?: "Mutation";
  completePendingInvite?: { __typename?: "ResponseType"; message?: string | null; success: boolean } | null;
};

export type CompleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type CompleteJourneyMutation = {
  __typename?: "Mutation";
  completeJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type DeleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type DeleteJourneyMutation = {
  __typename?: "Mutation";
  deleteJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type JourneySubmitFormMutationVariables = Exact<{
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type JourneySubmitFormMutation = {
  __typename?: "Mutation";
  journeySubmitForm?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type CreateEducationChildrenMutationVariables = Exact<{
  label: NodeLabel;
  name: Scalars["String"]["input"];
}>;

export type CreateEducationChildrenMutation = {
  __typename?: "Mutation";
  createEducationChildren: {
    __typename?: "Generated";
    success: boolean;
    message?: string | null;
    data?: Array<{ __typename?: "Subfield"; name: string } | null> | null;
  };
};

export type DropUserFromCourseMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type DropUserFromCourseMutation = {
  __typename?: "Mutation";
  dropUserFromCourse?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type EnrollUserInCourseMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type EnrollUserInCourseMutation = {
  __typename?: "Mutation";
  enrollUserInCourse?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type EnrollUserInTrackMutationVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type EnrollUserInTrackMutation = {
  __typename?: "Mutation";
  enrollUserInTrack: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetKnowledgeRelevanceMutationVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: Scalars["String"]["input"];
  isRelevant: Scalars["Boolean"]["input"];
}>;

export type SetKnowledgeRelevanceMutation = {
  __typename?: "Mutation";
  setKnowledgeRelevance: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateTopicMutationVariables = Exact<{
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
}>;

export type UpdateTopicMutation = {
  __typename?: "Mutation";
  updateTopic: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateLearningObjectiveCompletionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  completed: Scalars["Boolean"]["input"];
}>;

export type UpdateLearningObjectiveCompletionMutation = {
  __typename?: "Mutation";
  updateLearningObjectiveCompletion: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetOnboardingStatusMutationVariables = Exact<{
  isOnboarded: Scalars["Boolean"]["input"];
}>;

export type SetOnboardingStatusMutation = {
  __typename?: "Mutation";
  setOnboardingStatus: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type UpdateUserModalOnboardingMutationVariables = Exact<{
  modalOnboarding: Scalars["String"]["input"];
}>;

export type UpdateUserModalOnboardingMutation = {
  __typename?: "Mutation";
  updateUserModalOnboarding?: {
    __typename?: "UpdateUserModalOnboardingResponse";
    modalOnboarding?: string | null;
  } | null;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type ResetAccountMutationVariables = Exact<{ [key: string]: never }>;

export type ResetAccountMutation = {
  __typename?: "Mutation";
  resetAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type AddTodoMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type AddTodoMutation = {
  __typename?: "Mutation";
  addTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
  } | null;
};

export type UpdateTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type UpdateTodoMutation = {
  __typename?: "Mutation";
  updateTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    completed: boolean;
    pointsReceived?: number | null;
    timezone?: string | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null;
};

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  tenant: TenantInput;
}>;

export type UpdateTenantMutation = {
  __typename?: "Mutation";
  updateTenant?: {
    __typename?: "TenantResponse";
    count?: number | null;
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Tenant";
      id?: string | null;
      tenantDomain?: string | null;
      metaTagsTitle?: string | null;
      metaTagsDescription?: string | null;
      metaTagsThemeColor?: string | null;
      metaTagsAppleTouchIcon?: string | null;
      metaTagsTwitterCard?: string | null;
      metaTagsTwitterSite?: string | null;
      metaTagsTwitterTitle?: string | null;
      metaTagsTwitterDescription?: string | null;
      metaTagsTwitterImage?: string | null;
      stripeSubscriptionId?: string | null;
      hasTrial?: boolean | null;
      stripeTrialDays?: number | null;
      externalSendGridAppInviteTemplateId?: string | null;
      externalSendGridDailyEmailTemplateId?: string | null;
      externalSendGridFromEmail?: string | null;
      externalSendGridFromName?: string | null;
      externalSendGridTwilioServiceSID?: string | null;
      moderationSexual?: boolean | null;
      moderationHate?: boolean | null;
      moderationHarassment?: boolean | null;
      moderationSelfHarm?: boolean | null;
      moderationSexualMinors?: boolean | null;
      moderationHateThreatening?: boolean | null;
      moderationViolenceGraphic?: boolean | null;
      moderationSelfHarmIntent?: boolean | null;
      moderationSelfHarmInstructions?: boolean | null;
      moderationHarassmentThreatening?: boolean | null;
      moderationViolence?: boolean | null;
      companionName?: string | null;
      key?: string | null;
      isLMS?: boolean | null;
      isLTI?: boolean | null;
      isAxioOffering?: boolean | null;
      isSuggestedInsights?: boolean | null;
      axioSchools?: Array<string | null> | null;
      disableWebsearchWhenRAG?: boolean | null;
      primaryColor?: string | null;
      learningFieldColor?: string | null;
      learningSubFieldColor?: string | null;
      gameMenuBg?: string | null;
      gameMenuTrack?: string | null;
      gameMenuRefresh?: string | null;
      gameMenuRefreshText?: string | null;
      gameMenuProgress?: string | null;
      gameMenuCardBg?: string | null;
      gameMenuCardButton?: string | null;
      gameMenuCardButtonText?: string | null;
      gameMenuCardRefresh?: string | null;
      emulationStatement?: string | null;
      learningEmulationStatement?: string | null;
      logo?: string | null;
      showMiniLogo?: boolean | null;
      scope?: Array<string | null> | null;
      additionalScope?: Array<string | null> | null;
      connectURL?: string | null;
      termsOfServiceLink?: string | null;
      showObjectivesTab?: boolean | null;
      showJobsTab?: boolean | null;
      generalPrompt?: string | null;
      onboardingPrompt?: string | null;
      newsSectionTitle?: string | null;
      customNews?: boolean | null;
      showNews?: boolean | null;
      newsSearchTerms?: string | null;
      showInviteFriends?: boolean | null;
      showFeedback?: boolean | null;
      isPrivateLabelIcon?: boolean | null;
      bugReportEmails?: string | null;
      upgradeModalBody?: string | null;
      isFreemium?: boolean | null;
      hasEmailVerification?: boolean | null;
      created?: any | null;
      last_modified?: any | null;
      hasProFlow?: boolean | null;
      stripeSecretKey?: string | null;
      hasDegrees?: boolean | null;
    } | null> | null;
  } | null;
};

export type AssignCourseToFacultyMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
  courseId: Scalars["ID"]["input"];
}>;

export type AssignCourseToFacultyMutation = {
  __typename?: "Mutation";
  assignCourseToFaculty?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type InviteUserToSchoolMutationVariables = Exact<{
  input: InviteUserToSchoolInput;
}>;

export type InviteUserToSchoolMutation = {
  __typename?: "Mutation";
  inviteUserToSchool?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type RemoveCourseAssignmentFromFacultyMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
  courseId: Scalars["ID"]["input"];
}>;

export type RemoveCourseAssignmentFromFacultyMutation = {
  __typename?: "Mutation";
  removeCourseAssignmentFromFaculty?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type RevokeFacultyMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type RevokeFacultyMutation = {
  __typename?: "Mutation";
  revokeFaculty?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type UpdateFacultyProfileMutationVariables = Exact<{
  input: UpdateFacultyProfileInput;
}>;

export type UpdateFacultyProfileMutation = {
  __typename?: "Mutation";
  updateFacultyProfile?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type UploadFacultyProfileImageMutationVariables = Exact<{
  input: UploadFacultyProfileImageInput;
}>;

export type UploadFacultyProfileImageMutation = {
  __typename?: "Mutation";
  uploadFacultyProfileImage?: {
    __typename?: "UploadFacultyProfileImageResponse";
    success: boolean;
    message?: string | null;
    imageUrl?: string | null;
  } | null;
};

export type CreateVisualizationFromContentMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveInput> | ObjectiveInput;
}>;

export type CreateVisualizationFromContentMutation = {
  __typename?: "Mutation";
  createVisualizationFromContent?: {
    __typename?: "VisualizationResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "FormattedVisualization";
      id: string;
      code?: string | null;
      visualizationType?: string | null;
      created?: any | null;
      lastModified?: any | null;
    } | null> | null;
  } | null;
};

export type DeleteVisualizationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteVisualizationMutation = {
  __typename?: "Mutation";
  deleteVisualization?: {
    __typename?: "VisualizationResponse";
    count?: number | null;
    message?: string | null;
    success?: boolean | null;
  } | null;
};

export type UpdateVisualizationMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  objectives: Array<ObjectiveInput> | ObjectiveInput;
}>;

export type UpdateVisualizationMutation = {
  __typename?: "Mutation";
  updateVisualization?: {
    __typename?: "VisualizationResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "FormattedVisualization";
      id: string;
      code?: string | null;
      visualizationType?: string | null;
      created?: any | null;
      lastModified?: any | null;
    } | null> | null;
  } | null;
};

export type GetActivityCompletionDetailsQueryVariables = Exact<{
  aiTutorId: Scalars["ID"]["input"];
}>;

export type GetActivityCompletionDetailsQuery = {
  __typename?: "Query";
  getActivityCompletionDetails: {
    __typename?: "ActivityCompletionDetailsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ActivityCompletionDetailsFull";
      id: string;
      label: string;
      module_id?: string | null;
      module_name?: string | null;
      course_id?: string | null;
      course_name?: string | null;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
      details: {
        __typename?: "ActivityCompletionDetailsObjectives";
        objectives: Array<{
          __typename?: "ObjectiveCompletionDetails";
          id: string;
          label: string;
          completionStats: {
            __typename?: "CompletionStats";
            completed: number;
            inProgress: number;
            notStarted: number;
          };
        }>;
      };
    }>;
  };
};

export type GetCourseCompletionDetailsQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type GetCourseCompletionDetailsQuery = {
  __typename?: "Query";
  getCourseCompletionDetails: {
    __typename?: "CourseCompletionDetailsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CourseCompletionDetails";
      id: string;
      label: string;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
      details: {
        __typename?: "CourseCompletionDetailsData";
        modules: Array<{
          __typename?: "ModuleCompletionDetails";
          id: string;
          label: string;
          completionStats: {
            __typename?: "CompletionStats";
            completed: number;
            inProgress: number;
            notStarted: number;
          };
          details: {
            __typename?: "ModuleCompletionDetailsData";
            activities: Array<{
              __typename?: "ActivityCompletionDetails";
              id: string;
              label: string;
              completionStats: {
                __typename?: "CompletionStats";
                completed: number;
                inProgress: number;
                notStarted: number;
              };
              details: {
                __typename?: "ActivityCompletionDetailsData";
                objectives: Array<{
                  __typename?: "ObjectiveCompletionDetails";
                  id: string;
                  label: string;
                  completionStats: {
                    __typename?: "CompletionStats";
                    completed: number;
                    inProgress: number;
                    notStarted: number;
                  };
                }>;
              };
            }>;
          };
        }>;
      };
    }>;
  };
};

export type GetCourseCompletionSummaryBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCourseCompletionSummaryBySchoolQuery = {
  __typename?: "Query";
  getCourseCompletionSummaryBySchool?: {
    __typename?: "CourseCompletionSummaryResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CourseCompletionSummary";
      id: string;
      label: string;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
    }>;
  } | null;
};

export type GetModuleCompletionDetailsQueryVariables = Exact<{
  moduleId: Scalars["ID"]["input"];
}>;

export type GetModuleCompletionDetailsQuery = {
  __typename?: "Query";
  getModuleCompletionDetails: {
    __typename?: "ModuleCompletionDetailsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ModuleCompletionDetailsFull";
      id: string;
      label: string;
      course_id: string;
      course_name: string;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
      details: {
        __typename?: "ModuleCompletionDetailsActivities";
        activities: Array<{
          __typename?: "ActivityCompletionDetails";
          id: string;
          label: string;
          completionStats: {
            __typename?: "CompletionStats";
            completed: number;
            inProgress: number;
            notStarted: number;
          };
          details: {
            __typename?: "ActivityCompletionDetailsData";
            objectives: Array<{
              __typename?: "ObjectiveCompletionDetails";
              id: string;
              label: string;
              completionStats: {
                __typename?: "CompletionStats";
                completed: number;
                inProgress: number;
                notStarted: number;
              };
            }>;
          };
        }>;
      };
    }>;
  };
};

export type GetObjectiveCompletionDetailsQueryVariables = Exact<{
  objectiveId: Scalars["ID"]["input"];
}>;

export type GetObjectiveCompletionDetailsQuery = {
  __typename?: "Query";
  getObjectiveCompletionDetails: {
    __typename?: "ObjectiveCompletionDetailsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ObjectiveCompletionDetailsFull";
      id: string;
      label: string;
      aiTutor_id?: string | null;
      aiTutor_name?: string | null;
      module_id?: string | null;
      module_name?: string | null;
      course_id?: string | null;
      course_name?: string | null;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
    }>;
  };
};

export type GetActivityAnalyticsByModuleQueryVariables = Exact<{
  moduleId: Scalars["ID"]["input"];
}>;

export type GetActivityAnalyticsByModuleQuery = {
  __typename?: "Query";
  getActivityAnalyticsByModule?: {
    __typename?: "ActivityAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ActivityAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      enrollment: number;
      average_time_taken?: number | null;
      summaries?: Array<string | null> | null;
      success_points_details?: Array<string | null> | null;
      confusion_points_details?: Array<string | null> | null;
      further_recommendations?: Array<string | null> | null;
      details: {
        __typename?: "ActivityAnalyticsDetails";
        objectives: Array<{
          __typename?: "ObjectiveAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          average_time_taken?: number | null;
        }>;
      };
    }>;
  } | null;
};

export type GetCourseAnalyticsByIdQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type GetCourseAnalyticsByIdQuery = {
  __typename?: "Query";
  getCourseAnalyticsById?: {
    __typename?: "CourseDetailedResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CourseDetailedAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      enrollment: number;
      average_time_taken?: number | null;
      details: {
        __typename?: "CourseAnalyticsDetails";
        modules: Array<{
          __typename?: "ModuleAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          enrollment: number;
          average_time_taken?: number | null;
          details: {
            __typename?: "ModuleAnalyticsDetails";
            activities: Array<{
              __typename?: "ActivityAnalytics";
              id: string;
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              enrollment: number;
              average_time_taken?: number | null;
              summaries?: Array<string | null> | null;
              success_points_details?: Array<string | null> | null;
              confusion_points_details?: Array<string | null> | null;
              further_recommendations?: Array<string | null> | null;
              details: {
                __typename?: "ActivityAnalyticsDetails";
                objectives: Array<{
                  __typename?: "ObjectiveAnalytics";
                  id: string;
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  average_time_taken?: number | null;
                }>;
              };
            }>;
          };
        }>;
      };
    }>;
  } | null;
};

export type GetCourseHierarchyQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCourseHierarchyQuery = {
  __typename?: "Query";
  getCourseHierarchy?: {
    __typename?: "CourseHierarchyResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "HierarchyCourse";
      id: string;
      label: string;
      level: string;
      details?: {
        __typename?: "HierarchyCourseDetails";
        modules: Array<{
          __typename?: "HierarchyModule";
          id: string;
          label: string;
          level: string;
          parentCourse?: { __typename?: "ParentRef"; id: string; label: string } | null;
          details?: {
            __typename?: "HierarchyModuleDetails";
            activities: Array<{
              __typename?: "HierarchyActivity";
              id: string;
              label: string;
              level: string;
              parentModule?: { __typename?: "ParentRef"; id: string; label: string } | null;
              parentCourse?: { __typename?: "ParentRef"; id: string; label: string } | null;
              details?: {
                __typename?: "HierarchyActivityDetails";
                objectives: Array<{
                  __typename?: "HierarchyObjective";
                  id: string;
                  label: string;
                  level: string;
                  parentActivity?: { __typename?: "ParentRef"; id: string; label: string } | null;
                  parentModule?: { __typename?: "ParentRef"; id: string; label: string } | null;
                  parentCourse?: { __typename?: "ParentRef"; id: string; label: string } | null;
                }>;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetCoursesSummaryBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCoursesSummaryBySchoolQuery = {
  __typename?: "Query";
  getCoursesSummaryBySchool?: {
    __typename?: "CourseAnalyticsSummaryResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CourseSummary";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      enrollment: number;
      average_time_taken?: number | null;
    }>;
  } | null;
};

export type GetModuleAnalyticsByCourseQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type GetModuleAnalyticsByCourseQuery = {
  __typename?: "Query";
  getModuleAnalyticsByCourse?: {
    __typename?: "ModuleAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ModuleAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      enrollment: number;
      average_time_taken?: number | null;
      details: {
        __typename?: "ModuleAnalyticsDetails";
        activities: Array<{
          __typename?: "ActivityAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          enrollment: number;
          average_time_taken?: number | null;
        }>;
      };
    }>;
  } | null;
};

export type GetObjectiveAnalyticsByActivityQueryVariables = Exact<{
  aiTutorId: Scalars["ID"]["input"];
}>;

export type GetObjectiveAnalyticsByActivityQuery = {
  __typename?: "Query";
  getObjectiveAnalyticsByActivity?: {
    __typename?: "ObjectiveAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ObjectiveAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      average_time_taken?: number | null;
    }>;
  } | null;
};

export type GetActivityInsightsDataQueryVariables = Exact<{
  aiTutorId: Scalars["ID"]["input"];
}>;

export type GetActivityInsightsDataQuery = {
  __typename?: "Query";
  getActivityInsightsData: {
    __typename?: "ActivityInsightsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "ActivityInsights";
      id: string;
      label: string;
      success_points_details: Array<string>;
      confusion_points_details: Array<string>;
      further_recommendations: Array<string>;
      average_time_taken: number;
      completion_rate: number;
      student_engagement: string;
      overall_sentiment: string;
    }>;
  };
};

export type GetStudentChatAnalyticsQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  schoolId: Scalars["ID"]["input"];
}>;

export type GetStudentChatAnalyticsQuery = {
  __typename?: "Query";
  getStudentChatAnalytics: {
    __typename?: "StudentChatAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentChatAnalytics";
      id: string;
      courseId: string;
      courseName: string;
      moduleId: string;
      moduleName: string;
      activityId: string;
      activityName: string;
      sentiment_analysis?: string | null;
      originality_analysis?: string | null;
      num_messages: number;
      student_messages_count: number;
      ai_messages_count: number;
      average_student_response_time: number;
      average_ai_response_time: number;
      total_time_taken: number;
    }>;
  };
};

export type GetStudentLearningInsightsQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  schoolId: Scalars["ID"]["input"];
}>;

export type GetStudentLearningInsightsQuery = {
  __typename?: "Query";
  getStudentLearningInsights: {
    __typename?: "StudentLearningInsightsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentLearningInsights";
      id: string;
      label: string;
      success_points_details: Array<string>;
      confusion_points_details: Array<string>;
      further_recommendations: Array<string>;
      total_time_taken: number;
      active_interaction_count: number;
      total_sessions: number;
      questions_asked: number;
    }>;
  };
};

export type GetStudentActivityProgressQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  aiTutorId: Scalars["ID"]["input"];
}>;

export type GetStudentActivityProgressQuery = {
  __typename?: "Query";
  getStudentActivityProgress?: {
    __typename?: "StudentActivityProgressResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentActivityProgressData";
      id: string;
      label: string;
      module_id?: string | null;
      module_name?: string | null;
      course_id?: string | null;
      course_name?: string | null;
      student_id: string;
      student_name: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      completion_percentage?: number | null;
      total_time_taken?: number | null;
      chatAnalytics?: Array<{
        __typename?: "ChatAnalytics";
        id: string;
        level?: string | null;
        confusion_points?: Array<string | null> | null;
        success_points?: Array<string | null> | null;
        average_student_question_complexity?: number | null;
        further_recommendations: Array<string>;
        questions_asked: number;
        distractions: number;
        sentiment_analysis?: string | null;
        originality_analysis?: string | null;
        summary: string;
        num_messages: number;
        student_messages_count: number;
        ai_messages_count: number;
        average_student_response_time: number;
        average_ai_response_time: number;
        total_time_taken: number;
      } | null> | null;
      details: {
        __typename?: "ActivityProgressDetails";
        objectives: Array<{
          __typename?: "ObjectiveProgressAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          status?: string | null;
          progress_details?: {
            __typename?: "ProgressDetails";
            level?: string | null;
            confusion_points?: Array<string | null> | null;
            success_points?: Array<string | null> | null;
            average_student_question_complexity?: string | null;
            further_recommendations?: Array<string | null> | null;
            questions_asked?: number | null;
            distractions?: Array<string | null> | null;
            sentiment_analysis?: string | null;
            originality_analysis?: string | null;
            summary?: string | null;
            num_messages?: number | null;
            student_messages_count?: number | null;
            ai_messages_count?: number | null;
            average_student_response_time?: number | null;
            average_ai_response_time?: number | null;
            total_time_taken?: number | null;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type GetStudentCourseProgressQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  courseId: Scalars["ID"]["input"];
}>;

export type GetStudentCourseProgressQuery = {
  __typename?: "Query";
  getStudentCourseProgress?: {
    __typename?: "StudentCourseProgressResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CourseProgressAnalytics";
      id: string;
      label: string;
      student_id?: string | null;
      student_name?: string | null;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      completion_percentage?: number | null;
      total_time_taken?: number | null;
      details: {
        __typename?: "CourseProgressDetails";
        modules: Array<{
          __typename?: "ModuleProgressAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          completion_percentage?: number | null;
          total_time_taken?: number | null;
          details: {
            __typename?: "ModuleProgressDetails";
            activities: Array<{
              __typename?: "ActivityProgressAnalytics";
              id: string;
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              completion_percentage?: number | null;
              total_time_taken?: number | null;
              details: {
                __typename?: "ActivityProgressDetails";
                objectives: Array<{
                  __typename?: "ObjectiveProgressAnalytics";
                  id: string;
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  questions_asked: number;
                  active_interaction_count: number;
                  distractions: number;
                  status?: string | null;
                }>;
              };
            }>;
          };
        }>;
      };
    }>;
  } | null;
};

export type GetStudentDetailedAnalyticsQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  schoolId: Scalars["ID"]["input"];
}>;

export type GetStudentDetailedAnalyticsQuery = {
  __typename?: "Query";
  getStudentDetailedAnalytics?: {
    __typename?: "StudentDetailedAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentDetailedAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      completion_percentage?: number | null;
      total_time_taken?: number | null;
      details: {
        __typename?: "StudentAnalyticsDetails";
        courses: Array<{
          __typename?: "CourseProgressAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          completion_percentage?: number | null;
          total_time_taken?: number | null;
          details: {
            __typename?: "CourseProgressDetails";
            modules: Array<{
              __typename?: "ModuleProgressAnalytics";
              id: string;
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              completion_percentage?: number | null;
              total_time_taken?: number | null;
              details: {
                __typename?: "ModuleProgressDetails";
                aiTutors?: Array<{
                  __typename?: "AITutorProgressAnalytics";
                  id: string;
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  questions_asked: number;
                  active_interaction_count: number;
                  distractions: number;
                  sentiment: string;
                  completion_percentage?: number | null;
                  total_time_taken?: number | null;
                  chatAnalytics?: Array<{
                    __typename?: "ChatAnalytics";
                    id: string;
                    level?: string | null;
                    confusion_points?: Array<string | null> | null;
                    success_points?: Array<string | null> | null;
                    average_student_question_complexity?: number | null;
                    further_recommendations: Array<string>;
                    questions_asked: number;
                    distractions: number;
                    sentiment_analysis?: string | null;
                    originality_analysis?: string | null;
                    summary: string;
                    num_messages: number;
                    student_messages_count: number;
                    ai_messages_count: number;
                    average_student_response_time: number;
                    average_ai_response_time: number;
                    total_time_taken: number;
                  } | null> | null;
                  details: {
                    __typename?: "AITutorProgressDetails";
                    objectives: Array<{
                      __typename?: "ObjectiveProgressAnalytics";
                      id: string;
                      label: string;
                      success_points: number;
                      confusion_points: number;
                      questions_asked: number;
                      active_interaction_count: number;
                      distractions: number;
                      status?: string | null;
                      progress_details?: {
                        __typename?: "ProgressDetails";
                        level?: string | null;
                        confusion_points?: Array<string | null> | null;
                        success_points?: Array<string | null> | null;
                        average_student_question_complexity?: string | null;
                        further_recommendations?: Array<string | null> | null;
                        questions_asked?: number | null;
                        distractions?: Array<string | null> | null;
                        sentiment_analysis?: string | null;
                        originality_analysis?: string | null;
                        summary?: string | null;
                        num_messages?: number | null;
                        student_messages_count?: number | null;
                        ai_messages_count?: number | null;
                        average_student_response_time?: number | null;
                        average_ai_response_time?: number | null;
                        total_time_taken?: number | null;
                      } | null;
                    }>;
                  };
                } | null> | null;
              };
            }>;
          };
        }>;
      };
    }>;
  } | null;
};

export type GetStudentModuleProgressQueryVariables = Exact<{
  studentId: Scalars["ID"]["input"];
  moduleId: Scalars["ID"]["input"];
}>;

export type GetStudentModuleProgressQuery = {
  __typename?: "Query";
  getStudentModuleProgress?: {
    __typename?: "StudentModuleProgressResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentModuleProgressData";
      id: string;
      label: string;
      course_id?: string | null;
      course_name?: string | null;
      student_id: string;
      student_name: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      completion_percentage?: number | null;
      total_time_taken?: number | null;
      details: {
        __typename?: "ModuleProgressDetails";
        activities: Array<{
          __typename?: "ActivityProgressAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          completion_percentage?: number | null;
          total_time_taken?: number | null;
          details: {
            __typename?: "ActivityProgressDetails";
            objectives: Array<{
              __typename?: "ObjectiveProgressAnalytics";
              id: string;
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              status?: string | null;
            }>;
          };
        }>;
      };
    }>;
  } | null;
};

export type GetStudentsSummaryBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetStudentsSummaryBySchoolQuery = {
  __typename?: "Query";
  getStudentsSummaryBySchool?: {
    __typename?: "StudentAnalyticsSummaryResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentSummary";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      total_time_taken?: number | null;
    }>;
  } | null;
};

export type GetUserPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPermissionsQuery = { __typename?: "Query"; getUserPermissions: Array<string> };

export type SessionQueryVariables = Exact<{ [key: string]: never }>;

export type SessionQuery = {
  __typename?: "Query";
  validateSession: { __typename?: "SessionResponse"; isValid: boolean };
};

export type CareerByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CareerByIdQuery = {
  __typename?: "Query";
  getCareerById?: {
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null;
};

export type GetCareerHeaderQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareerHeaderQuery = {
  __typename?: "Query";
  getCareerHeader: {
    __typename?: "CareerHeader";
    jobTitle?: string | null;
    careerGoal?: string | null;
    careerName?: string | null;
    careerId?: string | null;
  };
};

export type GetCareerTopicsQueryVariables = Exact<{
  careerId: Scalars["ID"]["input"];
}>;

export type GetCareerTopicsQuery = {
  __typename?: "Query";
  getCareerTopics?: Array<{
    __typename?: "Topic";
    name: string;
    description?: string | null;
    mastery?: number | null;
  } | null> | null;
};

export type GetCareersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareersQuery = {
  __typename?: "Query";
  getCareers?: Array<{
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null> | null;
};

export type DailyLimitQueryVariables = Exact<{ [key: string]: never }>;

export type DailyLimitQuery = {
  __typename?: "Query";
  getDailyLimit?: { __typename?: "RateLimitResult"; dailyRequests?: number | null; result?: boolean | null } | null;
};

export type GetChatByContextAndParamsQueryVariables = Exact<{
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
}>;

export type GetChatByContextAndParamsQuery = {
  __typename?: "Query";
  getChatByContextAndParams?: {
    __typename?: "ChatResponse";
    id: string;
    date?: string | null;
    name: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageResponse";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      file?: { __typename?: "File"; fileName: string; id: string } | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetChatByIdQuery = {
  __typename?: "Query";
  getChatById?: {
    __typename?: "ChatResponse";
    id: string;
    date?: string | null;
    name: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageResponse";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      file?: { __typename?: "File"; fileName: string; id: string } | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByObjectIdQueryVariables = Exact<{
  objectId: Scalars["ID"]["input"];
  context: Scalars["String"]["input"];
}>;

export type GetChatByObjectIdQuery = {
  __typename?: "Query";
  getChatByObjectId?: {
    __typename?: "ChatResponse";
    id: string;
    date?: string | null;
    name: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageResponse";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      file?: { __typename?: "File"; fileName: string; id: string } | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChatsQuery = {
  __typename?: "Query";
  getChats?: Array<{
    __typename?: "Chat";
    id: string;
    date?: any | null;
    name: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    url?: string | null;
  } | null> | null;
};

export type TodayChatQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayChatQuery = {
  __typename?: "Query";
  getTodayChat?: {
    __typename?: "ChatResponse";
    id: string;
    date?: string | null;
    name: string;
    timezone?: string | null;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageResponse";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      file?: { __typename?: "File"; fileName: string; id: string } | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetContentByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetContentByIdQuery = {
  __typename?: "Query";
  getContentById?: {
    __typename?: "Curriculum";
    id: string;
    name: string;
    standard: string;
    weeks: number;
    objectives: Array<string>;
    created?: any | null;
    last_modified?: any | null;
    modules: Array<{
      __typename?: "Module";
      module_name?: string | null;
      assignments: Array<{ __typename?: "Assignment"; assignment_name?: string | null; html?: string | null }>;
    }>;
  } | null;
};

export type GetGeneratedContentQueryVariables = Exact<{ [key: string]: never }>;

export type GetGeneratedContentQuery = {
  __typename?: "Query";
  getGeneratedContent?: Array<{
    __typename?: "Curriculum";
    id: string;
    name: string;
    standard: string;
    weeks: number;
    objectives: Array<string>;
    created?: any | null;
    last_modified?: any | null;
    modules: Array<{
      __typename?: "Module";
      module_name?: string | null;
      assignments: Array<{ __typename?: "Assignment"; assignment_name?: string | null; html?: string | null }>;
    }>;
  } | null> | null;
};

export type GetAllAxioResourcesByCourseQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type GetAllAxioResourcesByCourseQuery = {
  __typename?: "Query";
  getAllAxioResourcesByCourse: {
    __typename?: "AxioResourceResponse";
    success: boolean;
    message?: string | null;
    data?: Array<{
      __typename?: "AxioResource";
      id: string;
      moduleId: string;
      order: number;
      name: string;
      description?: string | null;
      videoUrl?: string | null;
      created?: any | null;
      lastModified?: any | null;
      files: Array<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileURL: string;
        created: any;
        status: FileStatus;
      }>;
    } | null> | null;
  };
};

export type GetAllCoursesBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetAllCoursesBySchoolQuery = {
  __typename?: "Query";
  getAllCoursesBySchool?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      isDraft?: boolean | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      last_modified?: any | null;
      thumbnail?: string | null;
      cost?: number | null;
      startDate?: any | null;
      description?: string | null;
      courseType?: string | null;
      targetAudience?: string | null;
    } | null> | null;
  } | null;
};

export type GetAxioResourceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetAxioResourceQuery = {
  __typename?: "Query";
  getAxioResource: {
    __typename?: "AxioResourceResponse";
    success: boolean;
    message?: string | null;
    data?: Array<{
      __typename?: "AxioResource";
      id: string;
      moduleId: string;
      order: number;
      name: string;
      description?: string | null;
      videoUrl?: string | null;
      created?: any | null;
      lastModified?: any | null;
      files: Array<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileURL: string;
        created: any;
        status: FileStatus;
      }>;
    } | null> | null;
  };
};

export type GetCompletionAnalyticsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCompletionAnalyticsBySchoolQuery = {
  __typename?: "Query";
  getCompletionAnalyticsBySchool: {
    __typename?: "CompletionAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "CompletionAnalytics";
      label: string;
      completionStats: { __typename?: "CompletionStats"; completed: number; inProgress: number; notStarted: number };
      details: {
        __typename?: "CompletionCourseDetails";
        modules: Array<{
          __typename?: "CompletionModuleAnalytics";
          label: string;
          completionStats: {
            __typename?: "CompletionStats";
            completed: number;
            inProgress: number;
            notStarted: number;
          };
          details: {
            __typename?: "CompletionModuleDetails";
            activities: Array<{
              __typename?: "CompletionActivityAnalytics";
              label: string;
              completionStats: {
                __typename?: "CompletionStats";
                completed: number;
                inProgress: number;
                notStarted: number;
              };
              details: {
                __typename?: "CompletionActivityDetails";
                objectives: Array<{
                  __typename?: "CompletionObjectiveAnalytics";
                  label: string;
                  completionStats: {
                    __typename?: "CompletionStats";
                    completed: number;
                    inProgress: number;
                    notStarted: number;
                  };
                }>;
              };
            }>;
          };
        }>;
      };
    }>;
  };
};

export type GetCoursesAnalyticsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCoursesAnalyticsBySchoolQuery = {
  __typename?: "Query";
  getCoursesAnalyticsBySchool?: {
    __typename?: "CourseAnalyticsResponse";
    count?: number | null;
    data?: Array<{
      __typename?: "CourseAnalytics";
      label: string;
      id: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      enrollment: number;
      average_time_taken?: number | null;
      details: {
        __typename?: "CourseAnalyticsDetails";
        modules: Array<{
          __typename?: "ModuleAnalytics";
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          enrollment: number;
          average_time_taken?: number | null;
          details: {
            __typename?: "ModuleAnalyticsDetails";
            activities: Array<{
              __typename?: "ActivityAnalytics";
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              enrollment: number;
              average_time_taken?: number | null;
              summaries?: Array<string | null> | null;
              success_points_details?: Array<string | null> | null;
              confusion_points_details?: Array<string | null> | null;
              further_recommendations?: Array<string | null> | null;
              distractions_details?: Array<string | null> | null;
              details: {
                __typename?: "ActivityAnalyticsDetails";
                objectives: Array<{
                  __typename?: "ObjectiveAnalytics";
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  average_time_taken?: number | null;
                }>;
              };
            }>;
          };
        }>;
      };
    } | null> | null;
  } | null;
};

export type GetCoursesByDegreeByQueryQueryVariables = Exact<{
  degreeId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCoursesByDegreeByQueryQuery = {
  __typename?: "Query";
  getCoursesByDegreeByQuery?: {
    __typename?: "CoursesByQueryResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: {
      __typename?: "CoursesByQueryData";
      totalCount?: number | null;
      courses?: Array<{
        __typename?: "Course";
        id: string;
        name?: string | null;
        learningStandards?: string | null;
        lengthInWeeks?: number | null;
        objectives?: Array<string> | null;
        thumbnail?: string | null;
        last_modified?: any | null;
        generationStatus?: CourseGenerationStatus | null;
        courseType?: string | null;
        teachingFaculty: Array<{ __typename?: "User"; id: string; name: string }>;
        enrolledStudents: Array<{ __typename?: "User"; id: string; name: string }>;
      } | null> | null;
    } | null;
  } | null;
};

export type GetCoursesByFacultyQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCoursesByFacultyQuery = {
  __typename?: "Query";
  getCoursesByFaculty?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      modules: Array<{ __typename?: "Module"; id: string; title?: string | null }>;
    } | null> | null;
  } | null;
};

export type GetCoursesBySchoolByQueryQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetCoursesBySchoolByQueryQuery = {
  __typename?: "Query";
  getCoursesBySchoolByQuery?: {
    __typename?: "CoursesByQueryResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: {
      __typename?: "CoursesByQueryData";
      totalCount?: number | null;
      courses?: Array<{
        __typename?: "Course";
        id: string;
        name?: string | null;
        learningStandards?: string | null;
        lengthInWeeks?: number | null;
        objectives?: Array<string> | null;
        thumbnail?: string | null;
        last_modified?: any | null;
        generationStatus?: CourseGenerationStatus | null;
        courseType?: string | null;
        teachingFaculty: Array<{ __typename?: "User"; id: string; name: string }>;
        enrolledStudents: Array<{ __typename?: "User"; id: string; name: string }>;
        degreesHasCourse: Array<{ __typename?: "Degree"; id: string; name: string }>;
      } | null> | null;
    } | null;
  } | null;
};

export type GetEnrolledCoursesBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetEnrolledCoursesBySchoolQuery = {
  __typename?: "Query";
  getEnrolledCoursesBySchool?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      isDraft?: boolean | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      last_modified?: any | null;
      thumbnail?: string | null;
      cost?: number | null;
      startDate?: any | null;
      description?: string | null;
      courseType?: string | null;
      targetAudience?: string | null;
      generationStatus?: CourseGenerationStatus | null;
    } | null> | null;
  } | null;
};

export type GetFacultyByCourseIdQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetFacultyByCourseIdQuery = {
  __typename?: "Query";
  getFacultyByCourseId?: {
    __typename?: "FacultyQueryResponse";
    data?: {
      __typename?: "FacultyQueryResponseWithCountData";
      totalCount?: number | null;
      faculties: Array<{
        __typename?: "FacultyQueryResponseData";
        id?: string | null;
        name?: string | null;
        profilePicture?: string | null;
        email?: string | null;
        meetingLink?: string | null;
        biography?: string | null;
      }>;
    } | null;
  } | null;
};

export type GetNotEnrolledCoursesBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetNotEnrolledCoursesBySchoolQuery = {
  __typename?: "Query";
  getNotEnrolledCoursesBySchool?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      isDraft?: boolean | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      last_modified?: any | null;
      thumbnail?: string | null;
      cost?: number | null;
      startDate?: any | null;
      description?: string | null;
      courseType?: string | null;
      targetAudience?: string | null;
      generationStatus?: CourseGenerationStatus | null;
    } | null> | null;
  } | null;
};

export type GetStudentAnalyticsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetStudentAnalyticsBySchoolQuery = {
  __typename?: "Query";
  getStudentAnalyticsBySchool: {
    __typename?: "StudentAnalyticsResponse";
    success: boolean;
    message?: string | null;
    count: number;
    data: Array<{
      __typename?: "StudentAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      details: {
        __typename?: "StudentAnalyticsDetails";
        courses: Array<{
          __typename?: "CourseProgressAnalytics";
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          details: {
            __typename?: "CourseProgressDetails";
            modules: Array<{
              __typename?: "ModuleProgressAnalytics";
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              details: {
                __typename?: "ModuleProgressDetails";
                aiTutors?: Array<{
                  __typename?: "AITutorProgressAnalytics";
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  questions_asked: number;
                  active_interaction_count: number;
                  distractions: number;
                  sentiment: string;
                  details: {
                    __typename?: "AITutorProgressDetails";
                    objectives: Array<{
                      __typename?: "ObjectiveProgressAnalytics";
                      label: string;
                      success_points: number;
                      confusion_points: number;
                      questions_asked: number;
                      active_interaction_count: number;
                      distractions: number;
                    }>;
                  };
                } | null> | null;
              };
            }>;
          };
        }>;
      };
    }>;
  };
};

export type GetStudentAnalyticsForStudentQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
  studentId: Scalars["ID"]["input"];
}>;

export type GetStudentAnalyticsForStudentQuery = {
  __typename?: "Query";
  getStudentAnalyticsForStudent: {
    __typename?: "StudentAnalyticsResponse";
    success: boolean;
    message?: string | null;
    data: Array<{
      __typename?: "StudentAnalytics";
      id: string;
      label: string;
      success_points: number;
      confusion_points: number;
      questions_asked: number;
      active_interaction_count: number;
      distractions: number;
      sentiment: string;
      completion_percentage?: number | null;
      total_time_taken?: number | null;
      details: {
        __typename?: "StudentAnalyticsDetails";
        courses: Array<{
          __typename?: "CourseProgressAnalytics";
          id: string;
          label: string;
          success_points: number;
          confusion_points: number;
          questions_asked: number;
          active_interaction_count: number;
          distractions: number;
          sentiment: string;
          completion_percentage?: number | null;
          total_time_taken?: number | null;
          details: {
            __typename?: "CourseProgressDetails";
            modules: Array<{
              __typename?: "ModuleProgressAnalytics";
              id: string;
              label: string;
              success_points: number;
              confusion_points: number;
              questions_asked: number;
              active_interaction_count: number;
              distractions: number;
              sentiment: string;
              completion_percentage?: number | null;
              total_time_taken?: number | null;
              details: {
                __typename?: "ModuleProgressDetails";
                aiTutors?: Array<{
                  __typename?: "AITutorProgressAnalytics";
                  id: string;
                  label: string;
                  success_points: number;
                  confusion_points: number;
                  questions_asked: number;
                  active_interaction_count: number;
                  distractions: number;
                  sentiment: string;
                  completion_percentage?: number | null;
                  total_time_taken?: number | null;
                  details: {
                    __typename?: "AITutorProgressDetails";
                    objectives: Array<{
                      __typename?: "ObjectiveProgressAnalytics";
                      id: string;
                      label: string;
                      success_points: number;
                      confusion_points: number;
                      questions_asked: number;
                      active_interaction_count: number;
                      distractions: number;
                      status?: string | null;
                      progress_details?: {
                        __typename?: "ProgressDetails";
                        level?: string | null;
                        confusion_points?: Array<string | null> | null;
                        success_points?: Array<string | null> | null;
                        average_student_question_complexity?: string | null;
                        further_recommendations?: Array<string | null> | null;
                        questions_asked?: number | null;
                        distractions?: Array<string | null> | null;
                        sentiment_analysis?: string | null;
                        originality_analysis?: string | null;
                        summary?: string | null;
                        num_messages?: number | null;
                        student_messages_count?: number | null;
                        ai_messages_count?: number | null;
                        average_student_response_time?: number | null;
                        average_ai_response_time?: number | null;
                        total_time_taken?: number | null;
                      } | null;
                    }>;
                  };
                  chatAnalytics?: Array<{
                    __typename?: "ChatAnalytics";
                    id: string;
                    level?: string | null;
                    confusion_points?: Array<string | null> | null;
                    success_points?: Array<string | null> | null;
                    average_student_question_complexity?: number | null;
                    further_recommendations: Array<string>;
                    questions_asked: number;
                    distractions: number;
                    sentiment_analysis?: string | null;
                    originality_analysis?: string | null;
                    summary: string;
                    num_messages: number;
                    student_messages_count: number;
                    ai_messages_count: number;
                    average_student_response_time: number;
                    average_ai_response_time: number;
                    total_time_taken: number;
                  } | null> | null;
                } | null> | null;
              };
            }>;
          };
        }>;
      };
    }>;
  };
};

export type GetStudentsByCourseIdQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetStudentsByCourseIdQuery = {
  __typename?: "Query";
  getStudentsByCourseId?: {
    __typename?: "CourseRosterServiceResult";
    success?: boolean | null;
    count?: number | null;
    data?: Array<{
      __typename?: "CourseRosterData";
      id: string;
      name: string;
      email: string;
      pendingCourseEnrollmentInvite?: boolean | null;
      enrollmentDate: any;
    } | null> | null;
  } | null;
};

export type GetAiTutorQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetAiTutorQuery = {
  __typename?: "Query";
  getAITutor?: {
    __typename?: "AITutorResponse";
    data?: Array<{
      __typename?: "FormattedAITutor";
      id: string;
      title: string;
      description?: string | null;
      created?: any | null;
      lastModified?: any | null;
      enableVideoInsight?: boolean | null;
      hasChatChats?: Array<{ __typename?: "FormattedChat"; id: string } | null> | null;
      objectives?: Array<{
        __typename?: "FormattedObjective";
        id?: string | null;
        objective: string;
        sequence?: number | null;
        evalCriteria?: Array<string | null> | null;
        status?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetAllAiTutorsByCourseQueryVariables = Exact<{
  courseIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type GetAllAiTutorsByCourseQuery = {
  __typename?: "Query";
  getAllAITutorsByCourse?: {
    __typename?: "AITutorResponse";
    data?: Array<{
      __typename?: "FormattedAITutor";
      id: string;
      title: string;
      courseId?: string | null;
      visualization?: {
        __typename?: "Visualization";
        id: string;
        code?: string | null;
        visualizationType?: string | null;
      } | null;
      objectives?: Array<{
        __typename?: "FormattedObjective";
        id?: string | null;
        objective: string;
        status?: string | null;
        evalCriteria?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetAssessmentQueryVariables = Exact<{
  assessmentId: Scalars["ID"]["input"];
}>;

export type GetAssessmentQuery = {
  __typename?: "Query";
  getAssessment?: {
    __typename?: "AssessmentResponse";
    data?: Array<{
      __typename?: "Assessment";
      id: string;
      created?: any | null;
      lastModified?: any | null;
      isEnabled?: boolean | null;
      moduleHasAssessment: { __typename?: "Module"; id: string; title?: string | null };
      userHasAssessmentConnection: {
        __typename?: "AssessmentUserHasAssessmentConnection";
        edges: Array<{
          __typename?: "AssessmentUserHasAssessmentRelationship";
          properties: {
            __typename?: "HasAssessmentProperties";
            isCompleted?: boolean | null;
            remainingTime?: string | null;
          };
        }>;
      };
    } | null> | null;
  } | null;
};

export type GetAssessmentByFacultyQueryVariables = Exact<{
  input: GetAssessmentByFacultyInput;
}>;

export type GetAssessmentByFacultyQuery = {
  __typename?: "Query";
  getAssessmentByFaculty?: {
    __typename?: "AssessmentListResponse";
    success?: boolean | null;
    count?: number | null;
    data?: {
      __typename?: "AssessmentListResponseData";
      totalCount?: number | null;
      assessments?: Array<{
        __typename?: "AssessmentListResponseFormatted";
        id?: string | null;
        created?: any | null;
        lastModified?: any | null;
        moduleName?: string | null;
        courseName?: string | null;
        userName?: string | null;
        isCompleted?: boolean | null;
        completedAt?: any | null;
        grade?: number | null;
        hasTeacherGraded?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetAssignmentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetAssignmentQuery = {
  __typename?: "Query";
  getAssignment?: {
    __typename?: "AssignmentResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Assignment";
      id: string;
      name?: string | null;
      description?: string | null;
      due_date?: any | null;
      enableStudentFileUpload?: boolean | null;
      videoUrl?: string | null;
      created?: any | null;
      lastModified?: any | null;
      files: Array<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileURL: string;
        created: any;
        status: FileStatus;
      }>;
    } | null> | null;
  } | null;
};

export type GetAssignmentAndSubmissionBySubmissionIdQueryVariables = Exact<{
  submissionId: Scalars["ID"]["input"];
}>;

export type GetAssignmentAndSubmissionBySubmissionIdQuery = {
  __typename?: "Query";
  getAssignmentAndSubmissionBySubmissionId?: {
    __typename?: "AssignmentAndSubmissionResponse";
    success?: boolean | null;
    data?: Array<{
      __typename?: "AssignmentAndSubmission";
      assignment?: {
        __typename?: "Assignment";
        id: string;
        name?: string | null;
        description?: string | null;
        due_date?: any | null;
      } | null;
      submission?: {
        __typename?: "Submission";
        id: string;
        created?: any | null;
        lastModified?: any | null;
        grade?: number | null;
        isCompleted?: boolean | null;
        completedAt?: any | null;
        feedback?: string | null;
        hasTeacherGraded?: boolean | null;
        files: Array<{
          __typename?: "File";
          id: string;
          fileName: string;
          fileURL: string;
          created: any;
          status: FileStatus;
        }>;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCoursesForTranscriptGenerationQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetCoursesForTranscriptGenerationQuery = {
  __typename?: "Query";
  getCoursesForTranscriptGeneration?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      modules: Array<{
        __typename?: "Module";
        title?: string | null;
        aiTutors: Array<{
          __typename?: "AITutor";
          title: string;
          id: string;
          hasChatChats: Array<{
            __typename?: "Chat";
            id: string;
            date?: any | null;
            name: string;
            context?: string | null;
            params?: any | null;
            hasMessageMessages: Array<{
              __typename?: "Message";
              id: string;
              content?: string | null;
              index?: number | null;
              parameters?: string | null;
              aspectDetails?: string | null;
              sender?: string | null;
              created?: any | null;
              snackType?: string | null;
              type?: string | null;
            }>;
          }>;
        }>;
      }>;
    } | null> | null;
  } | null;
};

export type GetCourseByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetCourseByIdQuery = {
  __typename?: "Query";
  getCourseById?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      name?: string | null;
      isDraft?: boolean | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      thumbnail?: string | null;
      last_modified?: any | null;
      modules: Array<{
        __typename?: "Module";
        id: string;
        title?: string | null;
        order?: number | null;
        lastModified?: any | null;
        resourceOrder?: Array<string> | null;
        aiTutors: Array<{
          __typename?: "AITutor";
          id: string;
          title: string;
          description?: string | null;
          lastModified?: any | null;
          visualization?: {
            __typename?: "Visualization";
            id: string;
            code?: string | null;
            visualizationType?: string | null;
          } | null;
          objectives: Array<{
            __typename?: "Objective";
            id: string;
            objective?: string | null;
            sequence?: number | null;
            evalCriteria?: Array<string | null> | null;
            videos: Array<{
              __typename?: "Video";
              id: string;
              videoId: string;
              title: string;
              url: string;
              thumbnailUrl?: string | null;
              fullTranscript: string;
              isTeacherAdded: boolean;
              created?: any | null;
              lastModified?: any | null;
            }>;
            userHasProgressConnection: {
              __typename?: "ObjectiveUserHasProgressConnection";
              edges: Array<{
                __typename?: "ObjectiveUserHasProgressRelationship";
                properties: { __typename?: "HasProgressProperties"; status?: string | null };
              }>;
            };
          }>;
        }>;
        pages: Array<{
          __typename?: "Page";
          id: string;
          title: string;
          body?: string | null;
          lastModified?: any | null;
        }>;
        assessment?: {
          __typename?: "Assessment";
          id: string;
          isEnabled?: boolean | null;
          userHasAssessmentConnection: {
            __typename?: "AssessmentUserHasAssessmentConnection";
            edges: Array<{
              __typename?: "AssessmentUserHasAssessmentRelationship";
              properties: {
                __typename?: "HasAssessmentProperties";
                isCompleted?: boolean | null;
                remainingTime?: string | null;
              };
            }>;
          };
        } | null;
        axioResources: Array<{
          __typename?: "AxioResource";
          id: string;
          name: string;
          description?: string | null;
          videoUrl?: string | null;
          order: number;
          created?: any | null;
          lastModified?: any | null;
          files: Array<{
            __typename?: "File";
            id: string;
            fileName: string;
            fileURL: string;
            created: any;
            status: FileStatus;
          }>;
        }>;
        assignments: Array<{
          __typename?: "Assignment";
          id: string;
          name?: string | null;
          description?: string | null;
          due_date?: any | null;
          enableStudentFileUpload?: boolean | null;
          videoUrl?: string | null;
          created?: any | null;
          lastModified?: any | null;
          files: Array<{ __typename?: "File"; id: string; fileName: string; fileURL: string; created: any }>;
        }>;
      }>;
    } | null> | null;
  } | null;
};

export type GetUserSubmissionsQueryVariables = Exact<{
  assignmentId: Scalars["ID"]["input"];
}>;

export type GetUserSubmissionsQuery = {
  __typename?: "Query";
  getUserSubmissions?: {
    __typename?: "SubmissionResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Submission";
      id: string;
      isCompleted?: boolean | null;
      files: Array<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileURL: string;
        created: any;
        status: FileStatus;
      }>;
    } | null> | null;
  } | null;
};

export type GetUserSubmissionsByFacultyQueryVariables = Exact<{
  input: GetUserSubmissionsByFacultyInput;
}>;

export type GetUserSubmissionsByFacultyQuery = {
  __typename?: "Query";
  getUserSubmissionsByFaculty?: {
    __typename?: "SubmissionListResponse";
    success?: boolean | null;
    count?: number | null;
    data?: {
      __typename?: "SubmissionListResponseData";
      totalCount?: number | null;
      submissions?: Array<{
        __typename?: "SubmissionListResponseFormatted";
        id?: string | null;
        created?: any | null;
        lastModified?: any | null;
        moduleName?: string | null;
        courseName?: string | null;
        userName?: string | null;
        isCompleted?: boolean | null;
        completedAt?: any | null;
        grade?: number | null;
        hasTeacherGraded?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestionsQuery = {
  __typename?: "Query";
  getSuggestions?: Array<{
    __typename?: "Suggestion";
    type: string;
    content: string;
    integrationId?: string | null;
    classId?: string | null;
    assignmentId?: string | null;
    journeyId?: string | null;
    trackName?: string | null;
  } | null> | null;
};

export type GetDegreeBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetDegreeBySchoolQuery = {
  __typename?: "Query";
  getDegreeBySchool?: {
    __typename?: "DegreeResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "Degree";
      id: string;
      name: string;
      created?: any | null;
      last_modified?: any | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type GetDegreesBySchoolByQueryQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetDegreesBySchoolByQueryQuery = {
  __typename?: "Query";
  getDegreesBySchoolByQuery?: {
    __typename?: "DegreesBySchoolByQueryResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: {
      __typename?: "DegreesBySchoolByQueryData";
      totalCount?: number | null;
      degrees?: Array<{
        __typename?: "Degree";
        id: string;
        name: string;
        description?: string | null;
        usersEnrolledIn: Array<{ __typename?: "User"; id: string; name: string }>;
        coursesHasCourse: Array<{ __typename?: "Course"; id: string; name?: string | null }>;
      } | null> | null;
    } | null;
  } | null;
};

export type GetDegreeForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetDegreeForUserQuery = {
  __typename?: "Query";
  getDegreeForUser?: {
    __typename?: "DegreeResponse";
    count?: number | null;
    message?: string | null;
    success?: boolean | null;
    data?: Array<{ __typename?: "Degree"; description?: string | null; name: string; id: string } | null> | null;
  } | null;
};

export type GetDegreesByCourseByQueryQueryVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  query?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetDegreesByCourseByQueryQuery = {
  __typename?: "Query";
  getDegreesByCourseByQuery?: {
    __typename?: "DegreesBySchoolByQueryResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: {
      __typename?: "DegreesBySchoolByQueryData";
      totalCount?: number | null;
      degrees?: Array<{
        __typename?: "Degree";
        id: string;
        name: string;
        description?: string | null;
        usersEnrolledIn: Array<{ __typename?: "User"; id: string; name: string }>;
        coursesHasCourse: Array<{ __typename?: "Course"; id: string; name?: string | null }>;
      } | null> | null;
    } | null;
  } | null;
};

export type GetFileQueryVariables = Exact<{
  fileId: Scalars["ID"]["input"];
}>;

export type GetFileQuery = {
  __typename?: "Query";
  getFile: {
    __typename?: "FileResponse";
    count?: number | null;
    message?: string | null;
    success: boolean;
    data?: Array<{ __typename?: "File"; fileName: string; id: string; status: FileStatus } | null> | null;
  };
};

export type TodayGameQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayGameQuery = {
  __typename?: "Query";
  getTodayGame?: {
    __typename?: "Game";
    chatId?: string | null;
    refreshCounter?: number | null;
    dailyTaskGoalForToday?: number | null;
    dailyTaskCompletedForToday?: number | null;
    dailyLearningModulesGoalForToday?: number | null;
    dailyLearningModulesCompletedForToday?: number | null;
    dailyCalorieGoalForToday?: number | null;
    dailyCalorieCompletedForToday?: number | null;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type DailyExerciseTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyExerciseTotalsByDateQuery = {
  __typename?: "Query";
  getDailyExerciseTotalsByDate?: {
    __typename?: "DailyExerciseTotals";
    totalCaloriesBurned?: number | null;
    totalDurationMinutes?: number | null;
    totalWorkouts?: number | null;
    workoutsList?: Array<{ __typename?: "Workout"; name?: string | null; type?: string | null } | null> | null;
  } | null;
};

export type DailyNutritionTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyNutritionTotalsByDateQuery = {
  __typename?: "Query";
  getDailyNutritionTotalsByDate?: {
    __typename?: "DailyNutritionTotals";
    totalCalories?: number | null;
    totalCarbs?: number | null;
    totalFats?: number | null;
    totalProteins?: number | null;
  } | null;
};

export type FoodByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type FoodByDateQuery = {
  __typename?: "Query";
  getFoodsByDate?: Array<{
    __typename?: "Food";
    name: string;
    calories?: number | null;
    carbohydrates_grams?: number | null;
    fat_grams?: number | null;
    fiber_grams?: number | null;
    protein_grams?: number | null;
    serving_size?: string | null;
    sodium_mg?: number | null;
    sugars_grams?: number | null;
  } | null> | null;
};

export type WaterIntakeByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type WaterIntakeByDateQuery = {
  __typename?: "Query";
  getWaterIntakeByDate?: {
    __typename?: "WaterIntakeSummary";
    totalWaterIntake: number;
    goalOz: number;
    percentageTowardsGoal: number;
  } | null;
};

export type InviteCountQueryVariables = Exact<{ [key: string]: never }>;

export type InviteCountQuery = {
  __typename?: "Query";
  userInviteCount?: { __typename?: "InviteCountResponse"; count: number } | null;
  me?: {
    __typename?: "MeResponse";
    name?: string | null;
    username?: string | null;
    gender?: string | null;
    isOnboarded?: boolean | null;
    isPro?: boolean | null;
    phoneNumber?: string | null;
    isEmailVerified?: boolean | null;
  } | null;
};

export type DateForChatQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DateForChatQuery = {
  __typename?: "Query";
  getChatForDate?: {
    __typename?: "ChatResponse";
    id: string;
    date?: string | null;
    timezone?: string | null;
    conversation: Array<{
      __typename?: "MessageResponse";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      file?: { __typename?: "File"; fileName: string; id: string } | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetJourneyByIdQueryVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type GetJourneyByIdQuery = {
  __typename?: "Query";
  getJourneyById?: {
    __typename?: "GetJourneyByIdResponse";
    id: string;
    name: string;
    description?: string | null;
    dueDate?: any | null;
    url?: string | null;
    created?: any | null;
    lastModified?: any | null;
    tasks?: Array<{
      __typename?: "Todo";
      id: string;
      name: string;
      description?: string | null;
      priority?: string | null;
      date?: any | null;
      completed: boolean;
      isRecurring?: boolean | null;
      recurringFrequency?: string | null;
      recurringDaily?: boolean | null;
      recurringDayOfWeek?: string | null;
      recurringDayOfMonth?: number | null;
      recurringTime?: string | null;
      timeOfDay?: string | null;
      timezone?: string | null;
      isPartOfJourney?: boolean | null;
      journeyId?: string | null;
    } | null> | null;
  } | null;
};

export type GetJourneysQueryVariables = Exact<{ [key: string]: never }>;

export type GetJourneysQuery = {
  __typename?: "Query";
  getJourneys?: Array<{
    __typename?: "GetJourneysResponse";
    id: string;
    name: string;
    description?: string | null;
    url?: string | null;
    created?: any | null;
    lastModified?: any | null;
    dueDate?: any | null;
    totalTasks?: number | null;
    completedTasks?: number | null;
  } | null> | null;
};

export type FieldsQueryVariables = Exact<{ [key: string]: never }>;

export type FieldsQuery = {
  __typename?: "Query";
  getFields: Array<{
    __typename?: "Field";
    name: string;
    id: string;
    mastery?: number | null;
    isRelevant?: boolean | null;
    imageUrl?: string | null;
  } | null>;
};

export type GetLearningTreeQueryVariables = Exact<{
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type GetLearningTreeQuery = {
  __typename?: "Query";
  getLearningTree?: {
    __typename?: "LearningTree";
    FieldName?: string | null;
    SubfieldName?: string | null;
    TopicName?: string | null;
    ChapterName?: string | null;
    SubchapterName?: string | null;
  } | null;
};

export type NodeGraphQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type NodeGraphQuery = {
  __typename?: "Query";
  getNodeChildren: Array<{
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    unitsCount?: number | null;
    imageUrl?: string | null;
  } | null>;
  getNode?: {
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    imageUrl?: string | null;
  } | null;
  getNodeParents: Array<{
    __typename?: "LearningNode";
    name: string;
    id: string;
    mastery?: number | null;
    label?: string | null;
  } | null>;
};

export type RecentTracksQueryVariables = Exact<{
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RecentTracksQuery = {
  __typename?: "Query";
  getRecentTracks?: Array<{
    __typename?: "RecentTrack";
    name: string;
    description?: string | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentFields?: Array<string | null> | null;
    parentSubfield?: string | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type SiblingSubchaptersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  chapterName: Scalars["String"]["input"];
  subchapterName: Scalars["String"]["input"];
}>;

export type SiblingSubchaptersQuery = {
  __typename?: "Query";
  getSiblingSubchapters?: Array<{
    __typename?: "HierarchyNode";
    name: string;
    label: string;
    mastery?: number | null;
  } | null> | null;
};

export type SubchapterPrerequisitesQueryVariables = Exact<{
  subchapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SubchapterPrerequisitesQuery = {
  __typename?: "Query";
  getSubchapterPrerequisites?: Array<{
    __typename?: "HierarchyNode";
    label: string;
    name: string;
    mastery?: number | null;
  } | null> | null;
};

export type TableOfContentsQueryVariables = Exact<{
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
  hierarchyLabel: Scalars["String"]["input"];
}>;

export type TableOfContentsQuery = {
  __typename?: "Query";
  getTableOfContents?: Array<{
    __typename?: "Hierarchy";
    name: string;
    mastery: number;
    id?: string | null;
    isRelevant: boolean;
    parentName: string;
    description?: string | null;
    completed?: number | null;
    index?: number | null;
  } | null> | null;
};

export type TrackOverviewQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type TrackOverviewQuery = {
  __typename?: "Query";
  getTrackOverview?: {
    __typename?: "TrackOverview";
    name?: string | null;
    description?: string | null;
    objectives?: Array<string | null> | null;
    skillRequirements?: Array<string | null> | null;
    otherRequirements?: Array<string | null> | null;
    otherRequirementsTitle?: string | null;
    lessons?: Array<{ __typename?: "Lesson"; name?: string | null; description?: string | null } | null> | null;
    prerequisites?: Array<{
      __typename?: "Prerequisite";
      name?: string | null;
      subfield?: string | null;
      field?: string | null;
      mastery?: number | null;
    } | null> | null;
  } | null;
};

export type UserOverallProgressQueryVariables = Exact<{ [key: string]: never }>;

export type UserOverallProgressQuery = {
  __typename?: "Query";
  getUserOverallProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: { __typename?: "ChildInfo"; name: string; label: string };
    parent?: { __typename?: "ChildInfo"; label: string; name: string } | null;
  }>;
};

export type UserProgressQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type UserProgressQuery = {
  __typename?: "Query";
  getUserProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: {
      __typename?: "ChildInfo";
      name: string;
      label: string;
      totalTracks?: number | null;
      completedTracks?: number | null;
    };
  }>;
};

export type GetFirstSubchapterByTopicNameQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type GetFirstSubchapterByTopicNameQuery = {
  __typename?: "Query";
  getFirstSubchapterByTopicName: {
    __typename?: "SubchapterDetails";
    success: boolean;
    message?: string | null;
    id?: string | null;
    name?: string | null;
    description?: string | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  };
};

export type GetLearningObjectiveByIdQueryVariables = Exact<{
  loId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectiveByIdQuery = {
  __typename?: "Query";
  getLearningObjectiveById: {
    __typename?: "LearningObjectiveResponse";
    success: boolean;
    message?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    completed?: number | null;
  };
};

export type GetLearningObjectivesBySubchapterQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectivesBySubchapterQuery = {
  __typename?: "Query";
  getLearningObjectivesBySubchapter?: Array<{
    __typename?: "LearningObjective";
    id: string;
    title: string;
    description: string;
    index?: number | null;
    completed?: number | null;
  } | null> | null;
};

export type SubchapterByIdQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type SubchapterByIdQuery = {
  __typename?: "Query";
  getSubchapterById?: {
    __typename?: "SubchapterDetails";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    totalMastery?: number | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  } | null;
};

export type GetMasteryQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetMasteryQuery = {
  __typename?: "Query";
  getUserLearningObjectiveMastery?: {
    __typename?: "MasteryResult";
    learningObjectiveId?: string | null;
    userId?: string | null;
    totalMastery?: number | null;
  } | null;
};

export type ExportCsvQueryVariables = Exact<{ [key: string]: never }>;

export type ExportCsvQuery = {
  __typename?: "Query";
  exportCsv: { __typename?: "ExportCsvResponse"; success: boolean; csvData?: string | null; message?: string | null };
};

export type AssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type AssignmentsQuery = {
  __typename?: "Query";
  getAssignments?: Array<{
    __typename?: "GetAssignmentResponse";
    id: string;
    title: string;
    description?: string | null;
    gradePoints?: number | null;
    createdDate?: string | null;
    pointsPossible?: number | null;
    dueDate?: string | null;
    url?: string | null;
    objectives?: Array<{
      __typename?: "GetAssignmentObjectiveResponse";
      id: string;
      objective?: string | null;
      status?: string | null;
    } | null> | null;
  } | null> | null;
};

export type SchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolsQuery = {
  __typename?: "Query";
  getSchools?: Array<{
    __typename?: "School";
    id: string;
    providerName?: string | null;
    teamName?: string | null;
    icon?: string | null;
  } | null> | null;
};

export type SuggestVideoInsightsQueryVariables = Exact<{
  queryData: SuggestVideoInsightsInput;
}>;

export type SuggestVideoInsightsQuery = {
  __typename?: "Query";
  suggestVideoInsights?: {
    __typename?: "SuggestVideoInsightsResponse";
    success?: boolean | null;
    data?: any | null;
  } | null;
};

export type DeleteTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteTodoMutation = {
  __typename?: "Mutation";
  deleteTodo?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type RecentTodosQueryVariables = Exact<{ [key: string]: never }>;

export type RecentTodosQuery = {
  __typename?: "Query";
  recentTodos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type TodosQueryVariables = Exact<{ [key: string]: never }>;

export type TodosQuery = {
  __typename?: "Query";
  todos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type GetAllTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTenantsQuery = {
  __typename?: "Query";
  getAllTenants?: {
    __typename?: "TenantResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "Tenant";
      id?: string | null;
      tenantDomain?: string | null;
      metaTagsTitle?: string | null;
      metaTagsDescription?: string | null;
      metaTagsThemeColor?: string | null;
      metaTagsAppleTouchIcon?: string | null;
      metaTagsTwitterCard?: string | null;
      metaTagsTwitterSite?: string | null;
      metaTagsTwitterTitle?: string | null;
      metaTagsTwitterDescription?: string | null;
      metaTagsTwitterImage?: string | null;
      stripeSubscriptionId?: string | null;
      hasTrial?: boolean | null;
      stripeTrialDays?: number | null;
      externalSendGridAppInviteTemplateId?: string | null;
      externalSendGridDailyEmailTemplateId?: string | null;
      externalSendGridFromEmail?: string | null;
      externalSendGridFromName?: string | null;
      externalSendGridTwilioServiceSID?: string | null;
      moderationSexual?: boolean | null;
      moderationHate?: boolean | null;
      moderationHarassment?: boolean | null;
      moderationSelfHarm?: boolean | null;
      moderationSexualMinors?: boolean | null;
      moderationHateThreatening?: boolean | null;
      moderationViolenceGraphic?: boolean | null;
      moderationSelfHarmIntent?: boolean | null;
      moderationSelfHarmInstructions?: boolean | null;
      moderationHarassmentThreatening?: boolean | null;
      moderationViolence?: boolean | null;
      companionName?: string | null;
      key?: string | null;
      isLMS?: boolean | null;
      isLTI?: boolean | null;
      isAxioOffering?: boolean | null;
      isSuggestedInsights?: boolean | null;
      axioSchools?: Array<string | null> | null;
      disableWebsearchWhenRAG?: boolean | null;
      primaryColor?: string | null;
      learningFieldColor?: string | null;
      learningSubFieldColor?: string | null;
      gameMenuBg?: string | null;
      gameMenuTrack?: string | null;
      gameMenuRefresh?: string | null;
      gameMenuRefreshText?: string | null;
      gameMenuProgress?: string | null;
      gameMenuCardBg?: string | null;
      gameMenuCardButton?: string | null;
      gameMenuCardButtonText?: string | null;
      gameMenuCardRefresh?: string | null;
      emulationStatement?: string | null;
      learningEmulationStatement?: string | null;
      logo?: string | null;
      showMiniLogo?: boolean | null;
      scope?: Array<string | null> | null;
      additionalScope?: Array<string | null> | null;
      connectURL?: string | null;
      termsOfServiceLink?: string | null;
      showObjectivesTab?: boolean | null;
      showJobsTab?: boolean | null;
      generalPrompt?: string | null;
      onboardingPrompt?: string | null;
      newsSectionTitle?: string | null;
      customNews?: boolean | null;
      showNews?: boolean | null;
      newsSearchTerms?: string | null;
      showInviteFriends?: boolean | null;
      showFeedback?: boolean | null;
      isPrivateLabelIcon?: boolean | null;
      bugReportEmails?: string | null;
      upgradeModalBody?: string | null;
      isFreemium?: boolean | null;
      hasEmailVerification?: boolean | null;
      created?: any | null;
      last_modified?: any | null;
      hasProFlow?: boolean | null;
      stripeSecretKey?: string | null;
      hasDegrees?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetTenantQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetTenantQuery = {
  __typename?: "Query";
  getTenant?: {
    __typename?: "TenantResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: Array<{
      __typename?: "Tenant";
      id?: string | null;
      tenantDomain?: string | null;
      metaTagsTitle?: string | null;
      metaTagsDescription?: string | null;
      metaTagsThemeColor?: string | null;
      metaTagsAppleTouchIcon?: string | null;
      metaTagsTwitterCard?: string | null;
      metaTagsTwitterSite?: string | null;
      metaTagsTwitterTitle?: string | null;
      metaTagsTwitterDescription?: string | null;
      metaTagsTwitterImage?: string | null;
      stripeSubscriptionId?: string | null;
      hasTrial?: boolean | null;
      stripeTrialDays?: number | null;
      externalSendGridAppInviteTemplateId?: string | null;
      externalSendGridDailyEmailTemplateId?: string | null;
      externalSendGridFromEmail?: string | null;
      externalSendGridFromName?: string | null;
      externalSendGridTwilioServiceSID?: string | null;
      moderationSexual?: boolean | null;
      moderationHate?: boolean | null;
      moderationHarassment?: boolean | null;
      moderationSelfHarm?: boolean | null;
      moderationSexualMinors?: boolean | null;
      moderationHateThreatening?: boolean | null;
      moderationViolenceGraphic?: boolean | null;
      moderationSelfHarmIntent?: boolean | null;
      moderationSelfHarmInstructions?: boolean | null;
      moderationHarassmentThreatening?: boolean | null;
      moderationViolence?: boolean | null;
      companionName?: string | null;
      key?: string | null;
      isLMS?: boolean | null;
      isLTI?: boolean | null;
      isAxioOffering?: boolean | null;
      isSuggestedInsights?: boolean | null;
      axioSchools?: Array<string | null> | null;
      disableWebsearchWhenRAG?: boolean | null;
      primaryColor?: string | null;
      learningFieldColor?: string | null;
      learningSubFieldColor?: string | null;
      gameMenuBg?: string | null;
      gameMenuTrack?: string | null;
      gameMenuRefresh?: string | null;
      gameMenuRefreshText?: string | null;
      gameMenuProgress?: string | null;
      gameMenuCardBg?: string | null;
      gameMenuCardButton?: string | null;
      gameMenuCardButtonText?: string | null;
      gameMenuCardRefresh?: string | null;
      emulationStatement?: string | null;
      learningEmulationStatement?: string | null;
      logo?: string | null;
      showMiniLogo?: boolean | null;
      scope?: Array<string | null> | null;
      additionalScope?: Array<string | null> | null;
      connectURL?: string | null;
      termsOfServiceLink?: string | null;
      showObjectivesTab?: boolean | null;
      showJobsTab?: boolean | null;
      generalPrompt?: string | null;
      onboardingPrompt?: string | null;
      newsSectionTitle?: string | null;
      customNews?: boolean | null;
      showNews?: boolean | null;
      newsSearchTerms?: string | null;
      showInviteFriends?: boolean | null;
      showFeedback?: boolean | null;
      isPrivateLabelIcon?: boolean | null;
      bugReportEmails?: string | null;
      upgradeModalBody?: string | null;
      isFreemium?: boolean | null;
      hasEmailVerification?: boolean | null;
      created?: any | null;
      last_modified?: any | null;
      hasProFlow?: boolean | null;
      stripeSecretKey?: string | null;
      hasDegrees?: boolean | null;
    } | null> | null;
  } | null;
};

export type GetTenantSchemaQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantSchemaQuery = {
  __typename?: "Query";
  getTenantSchema?: {
    __typename?: "TenantSchemaResponse";
    success?: boolean | null;
    message?: string | null;
    data?: string | null;
  } | null;
};

export type GetActiveTracksQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveTracksQuery = {
  __typename?: "Query";
  getActiveTracks?: Array<{
    __typename?: "ActiveTrack";
    name?: string | null;
    description?: string | null;
    last_modified?: any | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentSubfield?: string | null;
    parentFields?: Array<string | null> | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "MeResponse";
    audioOn?: boolean | null;
    countryCode?: string | null;
    currentWeightLbs?: number | null;
    dailyEmailOptIn?: boolean | null;
    enrolledTracks?: Array<string | null> | null;
    email?: string | null;
    fullPhoneNumber?: string | null;
    gender?: string | null;
    goalWeightLbs?: number | null;
    googleCalendarAuthToken?: string | null;
    id: string;
    isCareerLoading?: boolean | null;
    isCourseLoading?: boolean | null;
    isEmailVerified?: boolean | null;
    isPro?: boolean | null;
    isOnboarded?: boolean | null;
    modalOnboarding?: string | null;
    name?: string | null;
    otpPreference?: string | null;
    phoneNumber?: string | null;
    trialEndDate?: any | null;
    username?: string | null;
    meetingLink?: string | null;
    profilePicture?: string | null;
    biography?: string | null;
  } | null;
};

export type GetFacultyByNameOrEmailQueryVariables = Exact<{
  nameOrEmail: Scalars["String"]["input"];
  schoolId: Scalars["ID"]["input"];
  page: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetFacultyByNameOrEmailQuery = {
  __typename?: "Query";
  getFacultyByNameOrEmail?: {
    __typename?: "FacultyQueryResponse";
    success?: boolean | null;
    message?: string | null;
    count?: number | null;
    data?: {
      __typename?: "FacultyQueryResponseWithCountData";
      totalCount?: number | null;
      faculties: Array<{
        __typename?: "FacultyQueryResponseData";
        id?: string | null;
        name?: string | null;
        email?: string | null;
        pendingFacultyInvite?: boolean | null;
        hasRoleRoles: Array<{ __typename?: "FacultyDataRole"; name?: string | null }>;
        teachingCourses: Array<{ __typename?: "FacultyDataCourse"; id?: string | null; name?: string | null }>;
        hasSessionDeviceSessions: Array<{ __typename?: "FacultyDataSessionDeviceSession"; id?: string | null }>;
      }>;
    } | null;
  } | null;
};

export type GetIsInvitationTokenValidQueryVariables = Exact<{
  token: Scalars["String"]["input"];
  service: Scalars["String"]["input"];
}>;

export type GetIsInvitationTokenValidQuery = {
  __typename?: "Query";
  getIsInvitationTokenValid?: { __typename?: "ValidityResponse"; isValid?: boolean | null } | null;
};

export type GetVisualizationQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetVisualizationQuery = {
  __typename?: "Query";
  getVisualization?: {
    __typename?: "VisualizationResponse";
    count?: number | null;
    message?: string | null;
    success?: boolean | null;
    data?: Array<{
      __typename?: "FormattedVisualization";
      code?: string | null;
      created?: any | null;
      id: string;
      lastModified?: any | null;
      visualizationType?: string | null;
      visualizationDescription?: string | null;
      status: VisualizationStatus;
    } | null> | null;
  } | null;
};

export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        isOnboarded
        isPro
        name
        googleAuthTokens {
          access_token
          refresh_token
          scope
          token_type
          expiry_date
        }
        isEmailVerified
        otpPreference
        countryCode
        phoneNumber
        fullPhoneNumber
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      message
      success
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      message
      success
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $name: String!
    $password: String!
    $gender: String
    $birthday: String!
    $dailyEmailOptIn: Boolean!
    $language: String!
    $timezone: String!
  ) {
    signup(
      email: $email
      name: $name
      password: $password
      gender: $gender
      birthday: $birthday
      dailyEmailOptIn: $dailyEmailOptIn
      language: $language
      timezone: $timezone
    ) {
      token
      user {
        id
        email
        name
        isPro
        isOnboarded
        otpPreference
        isEmailVerified
        fullPhoneNumber
        preferences {
          language
        }
      }
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      gender: // value for 'gender'
 *      birthday: // value for 'birthday'
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *      language: // value for 'language'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const CareerIsLoadingDocument = gql`
  mutation careerIsLoading($isLoading: Boolean!) {
    careerIsLoading(isLoading: $isLoading) {
      isCareerLoading
    }
  }
`;
export type CareerIsLoadingMutationFn = Apollo.MutationFunction<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;

/**
 * __useCareerIsLoadingMutation__
 *
 * To run a mutation, you first call `useCareerIsLoadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerIsLoadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerIsLoadingMutation, { data, loading, error }] = useCareerIsLoadingMutation({
 *   variables: {
 *      isLoading: // value for 'isLoading'
 *   },
 * });
 */
export function useCareerIsLoadingMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>(
    CareerIsLoadingDocument,
    options
  );
}
export type CareerIsLoadingMutationHookResult = ReturnType<typeof useCareerIsLoadingMutation>;
export type CareerIsLoadingMutationResult = Apollo.MutationResult<CareerIsLoadingMutation>;
export type CareerIsLoadingMutationOptions = Apollo.BaseMutationOptions<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;
export const CareerSubmitFormDocument = gql`
  mutation CareerSubmitForm($currentOccupation: String!, $careerPathName: String!, $careerGoal: String!) {
    careerSubmitForm(currentOccupation: $currentOccupation, careerPathName: $careerPathName, careerGoal: $careerGoal)
  }
`;
export type CareerSubmitFormMutationFn = Apollo.MutationFunction<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;

/**
 * __useCareerSubmitFormMutation__
 *
 * To run a mutation, you first call `useCareerSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerSubmitFormMutation, { data, loading, error }] = useCareerSubmitFormMutation({
 *   variables: {
 *      currentOccupation: // value for 'currentOccupation'
 *      careerPathName: // value for 'careerPathName'
 *      careerGoal: // value for 'careerGoal'
 *   },
 * });
 */
export function useCareerSubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>(
    CareerSubmitFormDocument,
    options
  );
}
export type CareerSubmitFormMutationHookResult = ReturnType<typeof useCareerSubmitFormMutation>;
export type CareerSubmitFormMutationResult = Apollo.MutationResult<CareerSubmitFormMutation>;
export type CareerSubmitFormMutationOptions = Apollo.BaseMutationOptions<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;
export const GenerateSuggestedCareersDocument = gql`
  mutation generateSuggestedCareers {
    generateSuggestedCareers {
      name
      id
    }
  }
`;
export type GenerateSuggestedCareersMutationFn = Apollo.MutationFunction<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;

/**
 * __useGenerateSuggestedCareersMutation__
 *
 * To run a mutation, you first call `useGenerateSuggestedCareersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSuggestedCareersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSuggestedCareersMutation, { data, loading, error }] = useGenerateSuggestedCareersMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSuggestedCareersMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>(
    GenerateSuggestedCareersDocument,
    options
  );
}
export type GenerateSuggestedCareersMutationHookResult = ReturnType<typeof useGenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationResult = Apollo.MutationResult<GenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationOptions = Apollo.BaseMutationOptions<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;
export const SetCareerPathActiveDocument = gql`
  mutation SetCareerPathActive($careerId: ID) {
    setCareerPathActive(careerId: $careerId)
  }
`;
export type SetCareerPathActiveMutationFn = Apollo.MutationFunction<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;

/**
 * __useSetCareerPathActiveMutation__
 *
 * To run a mutation, you first call `useSetCareerPathActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareerPathActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareerPathActiveMutation, { data, loading, error }] = useSetCareerPathActiveMutation({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useSetCareerPathActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>(
    SetCareerPathActiveDocument,
    options
  );
}
export type SetCareerPathActiveMutationHookResult = ReturnType<typeof useSetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationResult = Apollo.MutationResult<SetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationOptions = Apollo.BaseMutationOptions<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;
export const DeleteAspectDocument = gql`
  mutation DeleteAspect($nodeId: ID!) {
    deleteAspect(nodeId: $nodeId) {
      message
      success
    }
  }
`;
export type DeleteAspectMutationFn = Apollo.MutationFunction<DeleteAspectMutation, DeleteAspectMutationVariables>;

/**
 * __useDeleteAspectMutation__
 *
 * To run a mutation, you first call `useDeleteAspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAspectMutation, { data, loading, error }] = useDeleteAspectMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteAspectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAspectMutation, DeleteAspectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAspectMutation, DeleteAspectMutationVariables>(DeleteAspectDocument, options);
}
export type DeleteAspectMutationHookResult = ReturnType<typeof useDeleteAspectMutation>;
export type DeleteAspectMutationResult = Apollo.MutationResult<DeleteAspectMutation>;
export type DeleteAspectMutationOptions = Apollo.BaseMutationOptions<
  DeleteAspectMutation,
  DeleteAspectMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation DeleteMessage($id: String!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const ProcessAttachmentDocument = gql`
  mutation ProcessAttachment($fileId: String!) {
    processAttachment(fileId: $fileId) {
      message
      count
      success
    }
  }
`;
export type ProcessAttachmentMutationFn = Apollo.MutationFunction<
  ProcessAttachmentMutation,
  ProcessAttachmentMutationVariables
>;

/**
 * __useProcessAttachmentMutation__
 *
 * To run a mutation, you first call `useProcessAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processAttachmentMutation, { data, loading, error }] = useProcessAttachmentMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useProcessAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<ProcessAttachmentMutation, ProcessAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProcessAttachmentMutation, ProcessAttachmentMutationVariables>(
    ProcessAttachmentDocument,
    options
  );
}
export type ProcessAttachmentMutationHookResult = ReturnType<typeof useProcessAttachmentMutation>;
export type ProcessAttachmentMutationResult = Apollo.MutationResult<ProcessAttachmentMutation>;
export type ProcessAttachmentMutationOptions = Apollo.BaseMutationOptions<
  ProcessAttachmentMutation,
  ProcessAttachmentMutationVariables
>;
export const UploadCourseThumbnailDocument = gql`
  mutation UploadCourseThumbnail($input: UploadCourseThumbnailInput!) {
    uploadCourseThumbnail(input: $input) {
      success
      message
      imageUrl
    }
  }
`;
export type UploadCourseThumbnailMutationFn = Apollo.MutationFunction<
  UploadCourseThumbnailMutation,
  UploadCourseThumbnailMutationVariables
>;

/**
 * __useUploadCourseThumbnailMutation__
 *
 * To run a mutation, you first call `useUploadCourseThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCourseThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCourseThumbnailMutation, { data, loading, error }] = useUploadCourseThumbnailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCourseThumbnailMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadCourseThumbnailMutation, UploadCourseThumbnailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadCourseThumbnailMutation, UploadCourseThumbnailMutationVariables>(
    UploadCourseThumbnailDocument,
    options
  );
}
export type UploadCourseThumbnailMutationHookResult = ReturnType<typeof useUploadCourseThumbnailMutation>;
export type UploadCourseThumbnailMutationResult = Apollo.MutationResult<UploadCourseThumbnailMutation>;
export type UploadCourseThumbnailMutationOptions = Apollo.BaseMutationOptions<
  UploadCourseThumbnailMutation,
  UploadCourseThumbnailMutationVariables
>;
export const AddAssignmentDocument = gql`
  mutation AddAssignment($courseId: ID!, $moduleName: String!, $newAssignmentName: String!, $updatedHtml: String!) {
    addAssignment(
      courseId: $courseId
      moduleName: $moduleName
      newAssignmentName: $newAssignmentName
      updatedHtml: $updatedHtml
    ) {
      success
      message
    }
  }
`;
export type AddAssignmentMutationFn = Apollo.MutationFunction<AddAssignmentMutation, AddAssignmentMutationVariables>;

/**
 * __useAddAssignmentMutation__
 *
 * To run a mutation, you first call `useAddAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssignmentMutation, { data, loading, error }] = useAddAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      newAssignmentName: // value for 'newAssignmentName'
 *      updatedHtml: // value for 'updatedHtml'
 *   },
 * });
 */
export function useAddAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssignmentMutation, AddAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssignmentMutation, AddAssignmentMutationVariables>(AddAssignmentDocument, options);
}
export type AddAssignmentMutationHookResult = ReturnType<typeof useAddAssignmentMutation>;
export type AddAssignmentMutationResult = Apollo.MutationResult<AddAssignmentMutation>;
export type AddAssignmentMutationOptions = Apollo.BaseMutationOptions<
  AddAssignmentMutation,
  AddAssignmentMutationVariables
>;
export const AddContentModuleDocument = gql`
  mutation AddContentModule($courseId: ID!, $moduleName: String!) {
    addContentModule(courseId: $courseId, moduleName: $moduleName) {
      success
      message
    }
  }
`;
export type AddContentModuleMutationFn = Apollo.MutationFunction<
  AddContentModuleMutation,
  AddContentModuleMutationVariables
>;

/**
 * __useAddContentModuleMutation__
 *
 * To run a mutation, you first call `useAddContentModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentModuleMutation, { data, loading, error }] = useAddContentModuleMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *   },
 * });
 */
export function useAddContentModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddContentModuleMutation, AddContentModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContentModuleMutation, AddContentModuleMutationVariables>(
    AddContentModuleDocument,
    options
  );
}
export type AddContentModuleMutationHookResult = ReturnType<typeof useAddContentModuleMutation>;
export type AddContentModuleMutationResult = Apollo.MutationResult<AddContentModuleMutation>;
export type AddContentModuleMutationOptions = Apollo.BaseMutationOptions<
  AddContentModuleMutation,
  AddContentModuleMutationVariables
>;
export const CourseCreationDocument = gql`
  mutation CourseCreation(
    $title: String!
    $standard: String!
    $weeks: Int!
    $objectives: [String!]!
    $urls: [String!]!
  ) {
    courseCreation(title: $title, standard: $standard, weeks: $weeks, objectives: $objectives, urls: $urls) {
      success
      message
      data {
        name
        standard
        weeks
        objectives
        modules {
          module_name
          assignments {
            assignment_name
            html
          }
        }
        created
        last_modified
      }
    }
  }
`;
export type CourseCreationMutationFn = Apollo.MutationFunction<CourseCreationMutation, CourseCreationMutationVariables>;

/**
 * __useCourseCreationMutation__
 *
 * To run a mutation, you first call `useCourseCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCreationMutation, { data, loading, error }] = useCourseCreationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      standard: // value for 'standard'
 *      weeks: // value for 'weeks'
 *      objectives: // value for 'objectives'
 *      urls: // value for 'urls'
 *   },
 * });
 */
export function useCourseCreationMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseCreationMutation, CourseCreationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseCreationMutation, CourseCreationMutationVariables>(CourseCreationDocument, options);
}
export type CourseCreationMutationHookResult = ReturnType<typeof useCourseCreationMutation>;
export type CourseCreationMutationResult = Apollo.MutationResult<CourseCreationMutation>;
export type CourseCreationMutationOptions = Apollo.BaseMutationOptions<
  CourseCreationMutation,
  CourseCreationMutationVariables
>;
export const CourseIsLoadingDocument = gql`
  mutation courseIsLoading($isLoading: Boolean!) {
    courseIsLoading(isLoading: $isLoading) {
      isCourseLoading
    }
  }
`;
export type CourseIsLoadingMutationFn = Apollo.MutationFunction<
  CourseIsLoadingMutation,
  CourseIsLoadingMutationVariables
>;

/**
 * __useCourseIsLoadingMutation__
 *
 * To run a mutation, you first call `useCourseIsLoadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseIsLoadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseIsLoadingMutation, { data, loading, error }] = useCourseIsLoadingMutation({
 *   variables: {
 *      isLoading: // value for 'isLoading'
 *   },
 * });
 */
export function useCourseIsLoadingMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseIsLoadingMutation, CourseIsLoadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseIsLoadingMutation, CourseIsLoadingMutationVariables>(
    CourseIsLoadingDocument,
    options
  );
}
export type CourseIsLoadingMutationHookResult = ReturnType<typeof useCourseIsLoadingMutation>;
export type CourseIsLoadingMutationResult = Apollo.MutationResult<CourseIsLoadingMutation>;
export type CourseIsLoadingMutationOptions = Apollo.BaseMutationOptions<
  CourseIsLoadingMutation,
  CourseIsLoadingMutationVariables
>;
export const DeleteContentAssignmentDocument = gql`
  mutation DeleteContentAssignment($courseId: ID!, $moduleName: String!, $assignmentName: String!) {
    deleteContentAssignment(courseId: $courseId, moduleName: $moduleName, assignmentName: $assignmentName) {
      success
      message
    }
  }
`;
export type DeleteContentAssignmentMutationFn = Apollo.MutationFunction<
  DeleteContentAssignmentMutation,
  DeleteContentAssignmentMutationVariables
>;

/**
 * __useDeleteContentAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteContentAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentAssignmentMutation, { data, loading, error }] = useDeleteContentAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      assignmentName: // value for 'assignmentName'
 *   },
 * });
 */
export function useDeleteContentAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContentAssignmentMutation, DeleteContentAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContentAssignmentMutation, DeleteContentAssignmentMutationVariables>(
    DeleteContentAssignmentDocument,
    options
  );
}
export type DeleteContentAssignmentMutationHookResult = ReturnType<typeof useDeleteContentAssignmentMutation>;
export type DeleteContentAssignmentMutationResult = Apollo.MutationResult<DeleteContentAssignmentMutation>;
export type DeleteContentAssignmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentAssignmentMutation,
  DeleteContentAssignmentMutationVariables
>;
export const DeleteContentModuleDocument = gql`
  mutation DeleteContentModule($courseId: ID!, $moduleName: String!) {
    deleteContentModule(courseId: $courseId, moduleName: $moduleName) {
      success
      message
    }
  }
`;
export type DeleteContentModuleMutationFn = Apollo.MutationFunction<
  DeleteContentModuleMutation,
  DeleteContentModuleMutationVariables
>;

/**
 * __useDeleteContentModuleMutation__
 *
 * To run a mutation, you first call `useDeleteContentModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentModuleMutation, { data, loading, error }] = useDeleteContentModuleMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *   },
 * });
 */
export function useDeleteContentModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContentModuleMutation, DeleteContentModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContentModuleMutation, DeleteContentModuleMutationVariables>(
    DeleteContentModuleDocument,
    options
  );
}
export type DeleteContentModuleMutationHookResult = ReturnType<typeof useDeleteContentModuleMutation>;
export type DeleteContentModuleMutationResult = Apollo.MutationResult<DeleteContentModuleMutation>;
export type DeleteContentModuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentModuleMutation,
  DeleteContentModuleMutationVariables
>;
export const DeleteCourseDocument = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      success
      message
    }
  }
`;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDeleteCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
}
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseMutation,
  DeleteCourseMutationVariables
>;
export const UpdateAssignmentDocument = gql`
  mutation UpdateAssignment(
    $courseId: ID!
    $moduleName: String!
    $currentAssignmentName: String!
    $newAssignmentName: String!
    $updatedHtml: String!
  ) {
    updateAssignment(
      courseId: $courseId
      moduleName: $moduleName
      currentAssignmentName: $currentAssignmentName
      newAssignmentName: $newAssignmentName
      updatedHtml: $updatedHtml
    ) {
      success
      message
      data {
        assignment_name
        html
      }
    }
  }
`;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      currentAssignmentName: // value for 'currentAssignmentName'
 *      newAssignmentName: // value for 'newAssignmentName'
 *      updatedHtml: // value for 'updatedHtml'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(
    UpdateAssignmentDocument,
    options
  );
}
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
export const UpdateContentModuleNameDocument = gql`
  mutation UpdateContentModuleName($courseId: ID!, $oldModuleName: String!, $newModuleName: String!) {
    updateContentModuleName(courseId: $courseId, oldModuleName: $oldModuleName, newModuleName: $newModuleName) {
      success
      message
    }
  }
`;
export type UpdateContentModuleNameMutationFn = Apollo.MutationFunction<
  UpdateContentModuleNameMutation,
  UpdateContentModuleNameMutationVariables
>;

/**
 * __useUpdateContentModuleNameMutation__
 *
 * To run a mutation, you first call `useUpdateContentModuleNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentModuleNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentModuleNameMutation, { data, loading, error }] = useUpdateContentModuleNameMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      oldModuleName: // value for 'oldModuleName'
 *      newModuleName: // value for 'newModuleName'
 *   },
 * });
 */
export function useUpdateContentModuleNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContentModuleNameMutation, UpdateContentModuleNameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContentModuleNameMutation, UpdateContentModuleNameMutationVariables>(
    UpdateContentModuleNameDocument,
    options
  );
}
export type UpdateContentModuleNameMutationHookResult = ReturnType<typeof useUpdateContentModuleNameMutation>;
export type UpdateContentModuleNameMutationResult = Apollo.MutationResult<UpdateContentModuleNameMutation>;
export type UpdateContentModuleNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentModuleNameMutation,
  UpdateContentModuleNameMutationVariables
>;
export const UploadDocumentsDocument = gql`
  mutation UploadDocuments($files: [Upload!]!) {
    uploadDocuments(files: $files) {
      success
      message
    }
  }
`;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<
  UploadDocumentsMutation,
  UploadDocumentsMutationVariables
>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(
    UploadDocumentsDocument,
    options
  );
}
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentsMutation,
  UploadDocumentsMutationVariables
>;
export const UploadDocumentsToBucketDocument = gql`
  mutation UploadDocumentsToBucket($files: [Upload!]!) {
    uploadDocumentsToBucket(files: $files) {
      success
      message
      urls
    }
  }
`;
export type UploadDocumentsToBucketMutationFn = Apollo.MutationFunction<
  UploadDocumentsToBucketMutation,
  UploadDocumentsToBucketMutationVariables
>;

/**
 * __useUploadDocumentsToBucketMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsToBucketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsToBucketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsToBucketMutation, { data, loading, error }] = useUploadDocumentsToBucketMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadDocumentsToBucketMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentsToBucketMutation, UploadDocumentsToBucketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentsToBucketMutation, UploadDocumentsToBucketMutationVariables>(
    UploadDocumentsToBucketDocument,
    options
  );
}
export type UploadDocumentsToBucketMutationHookResult = ReturnType<typeof useUploadDocumentsToBucketMutation>;
export type UploadDocumentsToBucketMutationResult = Apollo.MutationResult<UploadDocumentsToBucketMutation>;
export type UploadDocumentsToBucketMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentsToBucketMutation,
  UploadDocumentsToBucketMutationVariables
>;
export const MakeCoursePaymentDocument = gql`
  mutation MakeCoursePayment($courseId: String!) {
    createCheckoutSession(courseId: $courseId) {
      success
      message
      url
    }
  }
`;
export type MakeCoursePaymentMutationFn = Apollo.MutationFunction<
  MakeCoursePaymentMutation,
  MakeCoursePaymentMutationVariables
>;

/**
 * __useMakeCoursePaymentMutation__
 *
 * To run a mutation, you first call `useMakeCoursePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCoursePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCoursePaymentMutation, { data, loading, error }] = useMakeCoursePaymentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useMakeCoursePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<MakeCoursePaymentMutation, MakeCoursePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeCoursePaymentMutation, MakeCoursePaymentMutationVariables>(
    MakeCoursePaymentDocument,
    options
  );
}
export type MakeCoursePaymentMutationHookResult = ReturnType<typeof useMakeCoursePaymentMutation>;
export type MakeCoursePaymentMutationResult = Apollo.MutationResult<MakeCoursePaymentMutation>;
export type MakeCoursePaymentMutationOptions = Apollo.BaseMutationOptions<
  MakeCoursePaymentMutation,
  MakeCoursePaymentMutationVariables
>;
export const CreateAiTutorDocument = gql`
  mutation CreateAITutor(
    $title: String!
    $description: String!
    $enableVideoInsight: Boolean
    $parentModuleId: ID!
    $objectives: [ObjectiveInput!]!
    $context: String
    $visualizationId: ID
    $videoPool: [VideoDataInput]
    $targetAudience: String
  ) {
    createAITutor(
      aiTutor: {
        title: $title
        description: $description
        parentModuleId: $parentModuleId
        objectives: $objectives
        context: $context
        enableVideoInsight: $enableVideoInsight
        visualizationId: $visualizationId
        videoPool: $videoPool
        targetAudience: $targetAudience
      }
    ) {
      success
      message
      data {
        title
        description
        targetAudience
        objectives {
          objective
          sequence
        }
      }
    }
  }
`;
export type CreateAiTutorMutationFn = Apollo.MutationFunction<CreateAiTutorMutation, CreateAiTutorMutationVariables>;

/**
 * __useCreateAiTutorMutation__
 *
 * To run a mutation, you first call `useCreateAiTutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiTutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiTutorMutation, { data, loading, error }] = useCreateAiTutorMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      enableVideoInsight: // value for 'enableVideoInsight'
 *      parentModuleId: // value for 'parentModuleId'
 *      objectives: // value for 'objectives'
 *      context: // value for 'context'
 *      visualizationId: // value for 'visualizationId'
 *      videoPool: // value for 'videoPool'
 *      targetAudience: // value for 'targetAudience'
 *   },
 * });
 */
export function useCreateAiTutorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAiTutorMutation, CreateAiTutorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAiTutorMutation, CreateAiTutorMutationVariables>(CreateAiTutorDocument, options);
}
export type CreateAiTutorMutationHookResult = ReturnType<typeof useCreateAiTutorMutation>;
export type CreateAiTutorMutationResult = Apollo.MutationResult<CreateAiTutorMutation>;
export type CreateAiTutorMutationOptions = Apollo.BaseMutationOptions<
  CreateAiTutorMutation,
  CreateAiTutorMutationVariables
>;
export const DeleteAiTutorDocument = gql`
  mutation DeleteAITutor($id: ID!) {
    deleteAITutor(id: $id) {
      success
      message
    }
  }
`;
export type DeleteAiTutorMutationFn = Apollo.MutationFunction<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>;

/**
 * __useDeleteAiTutorMutation__
 *
 * To run a mutation, you first call `useDeleteAiTutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAiTutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAiTutorMutation, { data, loading, error }] = useDeleteAiTutorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAiTutorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>(DeleteAiTutorDocument, options);
}
export type DeleteAiTutorMutationHookResult = ReturnType<typeof useDeleteAiTutorMutation>;
export type DeleteAiTutorMutationResult = Apollo.MutationResult<DeleteAiTutorMutation>;
export type DeleteAiTutorMutationOptions = Apollo.BaseMutationOptions<
  DeleteAiTutorMutation,
  DeleteAiTutorMutationVariables
>;
export const EditAiTutorDocument = gql`
  mutation EditAITutor(
    $id: ID!
    $title: String
    $description: String
    $objectives: [ObjectiveInput!]!
    $targetAudience: String
  ) {
    editAITutor(
      id: $id
      aiTutor: { title: $title, description: $description, objectives: $objectives, targetAudience: $targetAudience }
    ) {
      success
      message
      data {
        title
        description
        targetAudience
        objectives {
          objective
          sequence
          id
        }
      }
    }
  }
`;
export type EditAiTutorMutationFn = Apollo.MutationFunction<EditAiTutorMutation, EditAiTutorMutationVariables>;

/**
 * __useEditAiTutorMutation__
 *
 * To run a mutation, you first call `useEditAiTutorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAiTutorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAiTutorMutation, { data, loading, error }] = useEditAiTutorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      objectives: // value for 'objectives'
 *      targetAudience: // value for 'targetAudience'
 *   },
 * });
 */
export function useEditAiTutorMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAiTutorMutation, EditAiTutorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAiTutorMutation, EditAiTutorMutationVariables>(EditAiTutorDocument, options);
}
export type EditAiTutorMutationHookResult = ReturnType<typeof useEditAiTutorMutation>;
export type EditAiTutorMutationResult = Apollo.MutationResult<EditAiTutorMutation>;
export type EditAiTutorMutationOptions = Apollo.BaseMutationOptions<EditAiTutorMutation, EditAiTutorMutationVariables>;
export const CreateAxioResourceDocument = gql`
  mutation CreateAxioResource(
    $moduleId: ID!
    $order: Int!
    $name: String!
    $description: String!
    $files: [FileInput!]
    $videoUrl: String
  ) {
    createAxioResource(
      resource: {
        moduleId: $moduleId
        order: $order
        name: $name
        description: $description
        files: $files
        videoUrl: $videoUrl
      }
    ) {
      success
      message
      data {
        id
        name
        description
        videoUrl
        order
        created
        lastModified
      }
      count
    }
  }
`;
export type CreateAxioResourceMutationFn = Apollo.MutationFunction<
  CreateAxioResourceMutation,
  CreateAxioResourceMutationVariables
>;

/**
 * __useCreateAxioResourceMutation__
 *
 * To run a mutation, you first call `useCreateAxioResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAxioResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAxioResourceMutation, { data, loading, error }] = useCreateAxioResourceMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      order: // value for 'order'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      files: // value for 'files'
 *      videoUrl: // value for 'videoUrl'
 *   },
 * });
 */
export function useCreateAxioResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAxioResourceMutation, CreateAxioResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAxioResourceMutation, CreateAxioResourceMutationVariables>(
    CreateAxioResourceDocument,
    options
  );
}
export type CreateAxioResourceMutationHookResult = ReturnType<typeof useCreateAxioResourceMutation>;
export type CreateAxioResourceMutationResult = Apollo.MutationResult<CreateAxioResourceMutation>;
export type CreateAxioResourceMutationOptions = Apollo.BaseMutationOptions<
  CreateAxioResourceMutation,
  CreateAxioResourceMutationVariables
>;
export const CreateCoursePlaceholderDocument = gql`
  mutation CreateCoursePlaceholder(
    $name: String!
    $type: String!
    $schoolId: ID!
    $description: String
    $objectives: String
    $targetAudience: String
    $cost: Float
    $startDate: String
    $degreeIds: [ID!]
  ) {
    createCoursePlaceholder(
      course: {
        name: $name
        courseType: $type
        schoolId: $schoolId
        description: $description
        objectives: $objectives
        targetAudience: $targetAudience
        cost: $cost
        startDate: $startDate
        degreeIds: $degreeIds
      }
    ) {
      success
      data {
        id
        name
        courseType
        description
        objectives
        targetAudience
        cost
        startDate
        degreesHasCourse {
          id
          name
        }
      }
      count
    }
  }
`;
export type CreateCoursePlaceholderMutationFn = Apollo.MutationFunction<
  CreateCoursePlaceholderMutation,
  CreateCoursePlaceholderMutationVariables
>;

/**
 * __useCreateCoursePlaceholderMutation__
 *
 * To run a mutation, you first call `useCreateCoursePlaceholderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoursePlaceholderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoursePlaceholderMutation, { data, loading, error }] = useCreateCoursePlaceholderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      schoolId: // value for 'schoolId'
 *      description: // value for 'description'
 *      objectives: // value for 'objectives'
 *      targetAudience: // value for 'targetAudience'
 *      cost: // value for 'cost'
 *      startDate: // value for 'startDate'
 *      degreeIds: // value for 'degreeIds'
 *   },
 * });
 */
export function useCreateCoursePlaceholderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCoursePlaceholderMutation, CreateCoursePlaceholderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCoursePlaceholderMutation, CreateCoursePlaceholderMutationVariables>(
    CreateCoursePlaceholderDocument,
    options
  );
}
export type CreateCoursePlaceholderMutationHookResult = ReturnType<typeof useCreateCoursePlaceholderMutation>;
export type CreateCoursePlaceholderMutationResult = Apollo.MutationResult<CreateCoursePlaceholderMutation>;
export type CreateCoursePlaceholderMutationOptions = Apollo.BaseMutationOptions<
  CreateCoursePlaceholderMutation,
  CreateCoursePlaceholderMutationVariables
>;
export const DeleteAxioResourceDocument = gql`
  mutation DeleteAxioResource($id: ID!) {
    deleteAxioResource(id: $id) {
      success
      message
    }
  }
`;
export type DeleteAxioResourceMutationFn = Apollo.MutationFunction<
  DeleteAxioResourceMutation,
  DeleteAxioResourceMutationVariables
>;

/**
 * __useDeleteAxioResourceMutation__
 *
 * To run a mutation, you first call `useDeleteAxioResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAxioResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAxioResourceMutation, { data, loading, error }] = useDeleteAxioResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAxioResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAxioResourceMutation, DeleteAxioResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAxioResourceMutation, DeleteAxioResourceMutationVariables>(
    DeleteAxioResourceDocument,
    options
  );
}
export type DeleteAxioResourceMutationHookResult = ReturnType<typeof useDeleteAxioResourceMutation>;
export type DeleteAxioResourceMutationResult = Apollo.MutationResult<DeleteAxioResourceMutation>;
export type DeleteAxioResourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteAxioResourceMutation,
  DeleteAxioResourceMutationVariables
>;
export const GenerateAiTutorFromTitleDocument = gql`
  mutation GenerateAiTutorFromTitle($title: String!, $targetAudience: String) {
    generateAiTutorFromTitle(title: $title, targetAudience: $targetAudience) {
      message
      success
      data {
        description
        objectives {
          objective
          sequence
        }
      }
    }
  }
`;
export type GenerateAiTutorFromTitleMutationFn = Apollo.MutationFunction<
  GenerateAiTutorFromTitleMutation,
  GenerateAiTutorFromTitleMutationVariables
>;

/**
 * __useGenerateAiTutorFromTitleMutation__
 *
 * To run a mutation, you first call `useGenerateAiTutorFromTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiTutorFromTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiTutorFromTitleMutation, { data, loading, error }] = useGenerateAiTutorFromTitleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      targetAudience: // value for 'targetAudience'
 *   },
 * });
 */
export function useGenerateAiTutorFromTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateAiTutorFromTitleMutation, GenerateAiTutorFromTitleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateAiTutorFromTitleMutation, GenerateAiTutorFromTitleMutationVariables>(
    GenerateAiTutorFromTitleDocument,
    options
  );
}
export type GenerateAiTutorFromTitleMutationHookResult = ReturnType<typeof useGenerateAiTutorFromTitleMutation>;
export type GenerateAiTutorFromTitleMutationResult = Apollo.MutationResult<GenerateAiTutorFromTitleMutation>;
export type GenerateAiTutorFromTitleMutationOptions = Apollo.BaseMutationOptions<
  GenerateAiTutorFromTitleMutation,
  GenerateAiTutorFromTitleMutationVariables
>;
export const GenerateCourseContentDocument = gql`
  mutation GenerateCourseContent($courseId: ID!) {
    generateCourseContent(courseId: $courseId) {
      count
      data {
        id
      }
      message
      success
    }
  }
`;
export type GenerateCourseContentMutationFn = Apollo.MutationFunction<
  GenerateCourseContentMutation,
  GenerateCourseContentMutationVariables
>;

/**
 * __useGenerateCourseContentMutation__
 *
 * To run a mutation, you first call `useGenerateCourseContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCourseContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCourseContentMutation, { data, loading, error }] = useGenerateCourseContentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGenerateCourseContentMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateCourseContentMutation, GenerateCourseContentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCourseContentMutation, GenerateCourseContentMutationVariables>(
    GenerateCourseContentDocument,
    options
  );
}
export type GenerateCourseContentMutationHookResult = ReturnType<typeof useGenerateCourseContentMutation>;
export type GenerateCourseContentMutationResult = Apollo.MutationResult<GenerateCourseContentMutation>;
export type GenerateCourseContentMutationOptions = Apollo.BaseMutationOptions<
  GenerateCourseContentMutation,
  GenerateCourseContentMutationVariables
>;
export const GenerateEvalCriteriaDocument = gql`
  mutation GenerateEvalCriteria($input: GenerateEvalCriteriaInput!) {
    generateEvalCriteria(input: $input) {
      message
      success
      data {
        objective
        evalCriteria
      }
    }
  }
`;
export type GenerateEvalCriteriaMutationFn = Apollo.MutationFunction<
  GenerateEvalCriteriaMutation,
  GenerateEvalCriteriaMutationVariables
>;

/**
 * __useGenerateEvalCriteriaMutation__
 *
 * To run a mutation, you first call `useGenerateEvalCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEvalCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEvalCriteriaMutation, { data, loading, error }] = useGenerateEvalCriteriaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateEvalCriteriaMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateEvalCriteriaMutation, GenerateEvalCriteriaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateEvalCriteriaMutation, GenerateEvalCriteriaMutationVariables>(
    GenerateEvalCriteriaDocument,
    options
  );
}
export type GenerateEvalCriteriaMutationHookResult = ReturnType<typeof useGenerateEvalCriteriaMutation>;
export type GenerateEvalCriteriaMutationResult = Apollo.MutationResult<GenerateEvalCriteriaMutation>;
export type GenerateEvalCriteriaMutationOptions = Apollo.BaseMutationOptions<
  GenerateEvalCriteriaMutation,
  GenerateEvalCriteriaMutationVariables
>;
export const UpdateAxioResourceDocument = gql`
  mutation UpdateAxioResource($id: ID!, $resource: UpdateAxioResourceInput!) {
    updateAxioResource(id: $id, resource: $resource) {
      success
      message
      data {
        id
        name
        description
        videoUrl
        order
      }
    }
  }
`;
export type UpdateAxioResourceMutationFn = Apollo.MutationFunction<
  UpdateAxioResourceMutation,
  UpdateAxioResourceMutationVariables
>;

/**
 * __useUpdateAxioResourceMutation__
 *
 * To run a mutation, you first call `useUpdateAxioResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAxioResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAxioResourceMutation, { data, loading, error }] = useUpdateAxioResourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      resource: // value for 'resource'
 *   },
 * });
 */
export function useUpdateAxioResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAxioResourceMutation, UpdateAxioResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAxioResourceMutation, UpdateAxioResourceMutationVariables>(
    UpdateAxioResourceDocument,
    options
  );
}
export type UpdateAxioResourceMutationHookResult = ReturnType<typeof useUpdateAxioResourceMutation>;
export type UpdateAxioResourceMutationResult = Apollo.MutationResult<UpdateAxioResourceMutation>;
export type UpdateAxioResourceMutationOptions = Apollo.BaseMutationOptions<
  UpdateAxioResourceMutation,
  UpdateAxioResourceMutationVariables
>;
export const CreateVideoPoolDocument = gql`
  mutation CreateVideoPool($title: String!, $description: String!, $objectives: [ObjectiveForVideoInput!]!) {
    createVideoPool(title: $title, description: $description, objectives: $objectives) {
      success
      message
      data {
        videoId
        url
        title
        description
        thumbnailUrl
        objectiveText
        objectiveSequence
      }
      count
    }
  }
`;
export type CreateVideoPoolMutationFn = Apollo.MutationFunction<
  CreateVideoPoolMutation,
  CreateVideoPoolMutationVariables
>;

/**
 * __useCreateVideoPoolMutation__
 *
 * To run a mutation, you first call `useCreateVideoPoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoPoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoPoolMutation, { data, loading, error }] = useCreateVideoPoolMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      objectives: // value for 'objectives'
 *   },
 * });
 */
export function useCreateVideoPoolMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVideoPoolMutation, CreateVideoPoolMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVideoPoolMutation, CreateVideoPoolMutationVariables>(
    CreateVideoPoolDocument,
    options
  );
}
export type CreateVideoPoolMutationHookResult = ReturnType<typeof useCreateVideoPoolMutation>;
export type CreateVideoPoolMutationResult = Apollo.MutationResult<CreateVideoPoolMutation>;
export type CreateVideoPoolMutationOptions = Apollo.BaseMutationOptions<
  CreateVideoPoolMutation,
  CreateVideoPoolMutationVariables
>;
export const AddDegreesToCourseDocument = gql`
  mutation AddDegreesToCourse($input: AddDegreesToCourseInput!) {
    addDegreesToCourse(input: $input) {
      success
      message
    }
  }
`;
export type AddDegreesToCourseMutationFn = Apollo.MutationFunction<
  AddDegreesToCourseMutation,
  AddDegreesToCourseMutationVariables
>;

/**
 * __useAddDegreesToCourseMutation__
 *
 * To run a mutation, you first call `useAddDegreesToCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDegreesToCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDegreesToCourseMutation, { data, loading, error }] = useAddDegreesToCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDegreesToCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDegreesToCourseMutation, AddDegreesToCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDegreesToCourseMutation, AddDegreesToCourseMutationVariables>(
    AddDegreesToCourseDocument,
    options
  );
}
export type AddDegreesToCourseMutationHookResult = ReturnType<typeof useAddDegreesToCourseMutation>;
export type AddDegreesToCourseMutationResult = Apollo.MutationResult<AddDegreesToCourseMutation>;
export type AddDegreesToCourseMutationOptions = Apollo.BaseMutationOptions<
  AddDegreesToCourseMutation,
  AddDegreesToCourseMutationVariables
>;
export const CreateAssignmentDocument = gql`
  mutation CreateAssignment($assignment: CreateAssignmentInput!) {
    createAssignment(assignment: $assignment) {
      success
      message
    }
  }
`;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      assignment: // value for 'assignment'
 *   },
 * });
 */
export function useCreateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(
    CreateAssignmentDocument,
    options
  );
}
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;
export const DeleteCourseCascadeDocument = gql`
  mutation DeleteCourseCascade($id: ID!) {
    deleteCourseCascade(id: $id) {
      success
      message
    }
  }
`;
export type DeleteCourseCascadeMutationFn = Apollo.MutationFunction<
  DeleteCourseCascadeMutation,
  DeleteCourseCascadeMutationVariables
>;

/**
 * __useDeleteCourseCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteCourseCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseCascadeMutation, { data, loading, error }] = useDeleteCourseCascadeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseCascadeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseCascadeMutation, DeleteCourseCascadeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseCascadeMutation, DeleteCourseCascadeMutationVariables>(
    DeleteCourseCascadeDocument,
    options
  );
}
export type DeleteCourseCascadeMutationHookResult = ReturnType<typeof useDeleteCourseCascadeMutation>;
export type DeleteCourseCascadeMutationResult = Apollo.MutationResult<DeleteCourseCascadeMutation>;
export type DeleteCourseCascadeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseCascadeMutation,
  DeleteCourseCascadeMutationVariables
>;
export const CreateCourseDocument = gql`
  mutation CreateCourse(
    $name: String!
    $learningStandards: String
    $lengthInWeeks: Float
    $objectives: [String!]
    $parentSchoolId: String!
    $cost: Float
  ) {
    createCourse(
      course: {
        name: $name
        learningStandards: $learningStandards
        lengthInWeeks: $lengthInWeeks
        objectives: $objectives
        parentSchoolId: $parentSchoolId
        cost: $cost
      }
    ) {
      success
      message
      data {
        name
        learningStandards
        lengthInWeeks
        objectives
        cost
      }
    }
  }
`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      learningStandards: // value for 'learningStandards'
 *      lengthInWeeks: // value for 'lengthInWeeks'
 *      objectives: // value for 'objectives'
 *      parentSchoolId: // value for 'parentSchoolId'
 *      cost: // value for 'cost'
 *   },
 * });
 */
export function useCreateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
}
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const UpdateCourseDocument = gql`
  mutation UpdateCourse($id: ID!, $name: String, $isDraft: Boolean) {
    updateCourse(id: $id, course: { name: $name, isDraft: $isDraft }) {
      success
      message
      data {
        name
        isDraft
      }
    }
  }
`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isDraft: // value for 'isDraft'
 *   },
 * });
 */
export function useUpdateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
}
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const DeleteModuleCascadeDocument = gql`
  mutation DeleteModuleCascade($id: ID!) {
    deleteModuleCascade(id: $id) {
      success
      message
    }
  }
`;
export type DeleteModuleCascadeMutationFn = Apollo.MutationFunction<
  DeleteModuleCascadeMutation,
  DeleteModuleCascadeMutationVariables
>;

/**
 * __useDeleteModuleCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteModuleCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleCascadeMutation, { data, loading, error }] = useDeleteModuleCascadeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModuleCascadeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteModuleCascadeMutation, DeleteModuleCascadeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteModuleCascadeMutation, DeleteModuleCascadeMutationVariables>(
    DeleteModuleCascadeDocument,
    options
  );
}
export type DeleteModuleCascadeMutationHookResult = ReturnType<typeof useDeleteModuleCascadeMutation>;
export type DeleteModuleCascadeMutationResult = Apollo.MutationResult<DeleteModuleCascadeMutation>;
export type DeleteModuleCascadeMutationOptions = Apollo.BaseMutationOptions<
  DeleteModuleCascadeMutation,
  DeleteModuleCascadeMutationVariables
>;
export const CreateModuleDocument = gql`
  mutation CreateModule($title: String!, $order: Float!, $parentCourseId: ID!) {
    createModule(module: { title: $title, order: $order, parentCourseId: $parentCourseId }) {
      success
      message
      data {
        title
        order
      }
    }
  }
`;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      order: // value for 'order'
 *      parentCourseId: // value for 'parentCourseId'
 *   },
 * });
 */
export function useCreateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
}
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateModuleMutation,
  CreateModuleMutationVariables
>;
export const UpdateModuleDocument = gql`
  mutation UpdateModule($id: ID!, $title: String, $order: Float, $isAssessmentEnabled: Boolean) {
    updateModule(id: $id, module: { title: $title, order: $order, isAssessmentEnabled: $isAssessmentEnabled }) {
      success
      message
      data {
        title
        order
      }
    }
  }
`;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      order: // value for 'order'
 *      isAssessmentEnabled: // value for 'isAssessmentEnabled'
 *   },
 * });
 */
export function useUpdateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
}
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;
export const CreatePageDocument = gql`
  mutation CreatePage($title: String!, $body: String, $parentModuleId: ID!) {
    createPage(page: { title: $title, body: $body, parentModuleId: $parentModuleId }) {
      success
      message
      data {
        title
        body
      }
    }
  }
`;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      parentModuleId: // value for 'parentModuleId'
 *   },
 * });
 */
export function useCreatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
}
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const DeletePageDocument = gql`
  mutation DeletePage($id: ID!) {
    deletePage(id: $id) {
      success
      message
    }
  }
`;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
}
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const UpdatePageDocument = gql`
  mutation UpdatePage($id: ID!, $title: String, $body: String) {
    updatePage(id: $id, page: { title: $title, body: $body }) {
      success
      message
      data {
        title
        body
      }
    }
  }
`;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
}
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const InviteUsersToEnrollInCourseDocument = gql`
  mutation InviteUsersToEnrollInCourse($input: InviteUsersToEnrollInCourseInput!) {
    inviteUsersToEnrollInCourse(input: $input) {
      success
      data {
        email
        id
      }
      count
    }
  }
`;
export type InviteUsersToEnrollInCourseMutationFn = Apollo.MutationFunction<
  InviteUsersToEnrollInCourseMutation,
  InviteUsersToEnrollInCourseMutationVariables
>;

/**
 * __useInviteUsersToEnrollInCourseMutation__
 *
 * To run a mutation, you first call `useInviteUsersToEnrollInCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersToEnrollInCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersToEnrollInCourseMutation, { data, loading, error }] = useInviteUsersToEnrollInCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersToEnrollInCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersToEnrollInCourseMutation,
    InviteUsersToEnrollInCourseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUsersToEnrollInCourseMutation, InviteUsersToEnrollInCourseMutationVariables>(
    InviteUsersToEnrollInCourseDocument,
    options
  );
}
export type InviteUsersToEnrollInCourseMutationHookResult = ReturnType<typeof useInviteUsersToEnrollInCourseMutation>;
export type InviteUsersToEnrollInCourseMutationResult = Apollo.MutationResult<InviteUsersToEnrollInCourseMutation>;
export type InviteUsersToEnrollInCourseMutationOptions = Apollo.BaseMutationOptions<
  InviteUsersToEnrollInCourseMutation,
  InviteUsersToEnrollInCourseMutationVariables
>;
export const RemoveDegreesFromCourseDocument = gql`
  mutation RemoveDegreesFromCourse($input: RemoveDegreesFromCourseInput!) {
    removeDegreesFromCourse(input: $input) {
      success
      message
    }
  }
`;
export type RemoveDegreesFromCourseMutationFn = Apollo.MutationFunction<
  RemoveDegreesFromCourseMutation,
  RemoveDegreesFromCourseMutationVariables
>;

/**
 * __useRemoveDegreesFromCourseMutation__
 *
 * To run a mutation, you first call `useRemoveDegreesFromCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDegreesFromCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDegreesFromCourseMutation, { data, loading, error }] = useRemoveDegreesFromCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDegreesFromCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveDegreesFromCourseMutation, RemoveDegreesFromCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDegreesFromCourseMutation, RemoveDegreesFromCourseMutationVariables>(
    RemoveDegreesFromCourseDocument,
    options
  );
}
export type RemoveDegreesFromCourseMutationHookResult = ReturnType<typeof useRemoveDegreesFromCourseMutation>;
export type RemoveDegreesFromCourseMutationResult = Apollo.MutationResult<RemoveDegreesFromCourseMutation>;
export type RemoveDegreesFromCourseMutationOptions = Apollo.BaseMutationOptions<
  RemoveDegreesFromCourseMutation,
  RemoveDegreesFromCourseMutationVariables
>;
export const RemoveStudentsFromCourseDocument = gql`
  mutation RemoveStudentsFromCourse($courseId: ID!, $studentIds: [ID!]!) {
    removeStudentsFromCourse(courseId: $courseId, studentIds: $studentIds) {
      success
      data {
        id
        name
      }
      count
    }
  }
`;
export type RemoveStudentsFromCourseMutationFn = Apollo.MutationFunction<
  RemoveStudentsFromCourseMutation,
  RemoveStudentsFromCourseMutationVariables
>;

/**
 * __useRemoveStudentsFromCourseMutation__
 *
 * To run a mutation, you first call `useRemoveStudentsFromCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentsFromCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentsFromCourseMutation, { data, loading, error }] = useRemoveStudentsFromCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useRemoveStudentsFromCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveStudentsFromCourseMutation, RemoveStudentsFromCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveStudentsFromCourseMutation, RemoveStudentsFromCourseMutationVariables>(
    RemoveStudentsFromCourseDocument,
    options
  );
}
export type RemoveStudentsFromCourseMutationHookResult = ReturnType<typeof useRemoveStudentsFromCourseMutation>;
export type RemoveStudentsFromCourseMutationResult = Apollo.MutationResult<RemoveStudentsFromCourseMutation>;
export type RemoveStudentsFromCourseMutationOptions = Apollo.BaseMutationOptions<
  RemoveStudentsFromCourseMutation,
  RemoveStudentsFromCourseMutationVariables
>;
export const SubmitStudentAssignmentDocument = gql`
  mutation submitStudentAssignment($assignmentId: ID!, $files: [FileInput!]!) {
    submitStudentAssignment(assignmentId: $assignmentId, files: $files) {
      success
      message
      data {
        id
      }
    }
  }
`;
export type SubmitStudentAssignmentMutationFn = Apollo.MutationFunction<
  SubmitStudentAssignmentMutation,
  SubmitStudentAssignmentMutationVariables
>;

/**
 * __useSubmitStudentAssignmentMutation__
 *
 * To run a mutation, you first call `useSubmitStudentAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStudentAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStudentAssignmentMutation, { data, loading, error }] = useSubmitStudentAssignmentMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useSubmitStudentAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitStudentAssignmentMutation, SubmitStudentAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitStudentAssignmentMutation, SubmitStudentAssignmentMutationVariables>(
    SubmitStudentAssignmentDocument,
    options
  );
}
export type SubmitStudentAssignmentMutationHookResult = ReturnType<typeof useSubmitStudentAssignmentMutation>;
export type SubmitStudentAssignmentMutationResult = Apollo.MutationResult<SubmitStudentAssignmentMutation>;
export type SubmitStudentAssignmentMutationOptions = Apollo.BaseMutationOptions<
  SubmitStudentAssignmentMutation,
  SubmitStudentAssignmentMutationVariables
>;
export const UpdateAssessmentGradeDocument = gql`
  mutation updateAssessmentGrade($assessmentId: ID!, $grade: Float!) {
    updateAssessmentGrade(assessmentId: $assessmentId, grade: $grade) {
      success
      message
      data {
        id
      }
    }
  }
`;
export type UpdateAssessmentGradeMutationFn = Apollo.MutationFunction<
  UpdateAssessmentGradeMutation,
  UpdateAssessmentGradeMutationVariables
>;

/**
 * __useUpdateAssessmentGradeMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentGradeMutation, { data, loading, error }] = useUpdateAssessmentGradeMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      grade: // value for 'grade'
 *   },
 * });
 */
export function useUpdateAssessmentGradeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentGradeMutation, UpdateAssessmentGradeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssessmentGradeMutation, UpdateAssessmentGradeMutationVariables>(
    UpdateAssessmentGradeDocument,
    options
  );
}
export type UpdateAssessmentGradeMutationHookResult = ReturnType<typeof useUpdateAssessmentGradeMutation>;
export type UpdateAssessmentGradeMutationResult = Apollo.MutationResult<UpdateAssessmentGradeMutation>;
export type UpdateAssessmentGradeMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentGradeMutation,
  UpdateAssessmentGradeMutationVariables
>;
export const UpdateResourceOrderDocument = gql`
  mutation UpdateResourceOrder($input: UpdateResourceOrderInput!) {
    updateResourceOrder(input: $input) {
      success
      message
      data {
        id
        resourceOrder
      }
    }
  }
`;
export type UpdateResourceOrderMutationFn = Apollo.MutationFunction<
  UpdateResourceOrderMutation,
  UpdateResourceOrderMutationVariables
>;

/**
 * __useUpdateResourceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateResourceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceOrderMutation, { data, loading, error }] = useUpdateResourceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateResourceOrderMutation, UpdateResourceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateResourceOrderMutation, UpdateResourceOrderMutationVariables>(
    UpdateResourceOrderDocument,
    options
  );
}
export type UpdateResourceOrderMutationHookResult = ReturnType<typeof useUpdateResourceOrderMutation>;
export type UpdateResourceOrderMutationResult = Apollo.MutationResult<UpdateResourceOrderMutation>;
export type UpdateResourceOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceOrderMutation,
  UpdateResourceOrderMutationVariables
>;
export const UpdateStudentAssignmentGradeDocument = gql`
  mutation updateStudentAssignmentGrade($submissionId: ID!, $grade: Float!, $feedback: String) {
    updateStudentAssignmentGrade(submissionId: $submissionId, grade: $grade, feedback: $feedback) {
      success
      message
      data {
        id
      }
    }
  }
`;
export type UpdateStudentAssignmentGradeMutationFn = Apollo.MutationFunction<
  UpdateStudentAssignmentGradeMutation,
  UpdateStudentAssignmentGradeMutationVariables
>;

/**
 * __useUpdateStudentAssignmentGradeMutation__
 *
 * To run a mutation, you first call `useUpdateStudentAssignmentGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentAssignmentGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentAssignmentGradeMutation, { data, loading, error }] = useUpdateStudentAssignmentGradeMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      grade: // value for 'grade'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useUpdateStudentAssignmentGradeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStudentAssignmentGradeMutation,
    UpdateStudentAssignmentGradeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStudentAssignmentGradeMutation, UpdateStudentAssignmentGradeMutationVariables>(
    UpdateStudentAssignmentGradeDocument,
    options
  );
}
export type UpdateStudentAssignmentGradeMutationHookResult = ReturnType<typeof useUpdateStudentAssignmentGradeMutation>;
export type UpdateStudentAssignmentGradeMutationResult = Apollo.MutationResult<UpdateStudentAssignmentGradeMutation>;
export type UpdateStudentAssignmentGradeMutationOptions = Apollo.BaseMutationOptions<
  UpdateStudentAssignmentGradeMutation,
  UpdateStudentAssignmentGradeMutationVariables
>;
export const AddCoursesToDegreeDocument = gql`
  mutation AddCoursesToDegree($input: AddCoursesToDegreeInput!) {
    addCoursesToDegree(input: $input) {
      success
      message
    }
  }
`;
export type AddCoursesToDegreeMutationFn = Apollo.MutationFunction<
  AddCoursesToDegreeMutation,
  AddCoursesToDegreeMutationVariables
>;

/**
 * __useAddCoursesToDegreeMutation__
 *
 * To run a mutation, you first call `useAddCoursesToDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCoursesToDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCoursesToDegreeMutation, { data, loading, error }] = useAddCoursesToDegreeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCoursesToDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCoursesToDegreeMutation, AddCoursesToDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCoursesToDegreeMutation, AddCoursesToDegreeMutationVariables>(
    AddCoursesToDegreeDocument,
    options
  );
}
export type AddCoursesToDegreeMutationHookResult = ReturnType<typeof useAddCoursesToDegreeMutation>;
export type AddCoursesToDegreeMutationResult = Apollo.MutationResult<AddCoursesToDegreeMutation>;
export type AddCoursesToDegreeMutationOptions = Apollo.BaseMutationOptions<
  AddCoursesToDegreeMutation,
  AddCoursesToDegreeMutationVariables
>;
export const CreateDegreeDocument = gql`
  mutation CreateDegree($name: String!, $schoolId: ID!) {
    createDegree(degree: { name: $name, schoolId: $schoolId }) {
      success
      message
      data {
        id
        name
      }
    }
  }
`;
export type CreateDegreeMutationFn = Apollo.MutationFunction<CreateDegreeMutation, CreateDegreeMutationVariables>;

/**
 * __useCreateDegreeMutation__
 *
 * To run a mutation, you first call `useCreateDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDegreeMutation, { data, loading, error }] = useCreateDegreeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useCreateDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDegreeMutation, CreateDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDegreeMutation, CreateDegreeMutationVariables>(CreateDegreeDocument, options);
}
export type CreateDegreeMutationHookResult = ReturnType<typeof useCreateDegreeMutation>;
export type CreateDegreeMutationResult = Apollo.MutationResult<CreateDegreeMutation>;
export type CreateDegreeMutationOptions = Apollo.BaseMutationOptions<
  CreateDegreeMutation,
  CreateDegreeMutationVariables
>;
export const DeleteDegreeDocument = gql`
  mutation DeleteDegree($id: ID!) {
    deleteDegree(id: $id) {
      success
      message
    }
  }
`;
export type DeleteDegreeMutationFn = Apollo.MutationFunction<DeleteDegreeMutation, DeleteDegreeMutationVariables>;

/**
 * __useDeleteDegreeMutation__
 *
 * To run a mutation, you first call `useDeleteDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDegreeMutation, { data, loading, error }] = useDeleteDegreeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDegreeMutation, DeleteDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDegreeMutation, DeleteDegreeMutationVariables>(DeleteDegreeDocument, options);
}
export type DeleteDegreeMutationHookResult = ReturnType<typeof useDeleteDegreeMutation>;
export type DeleteDegreeMutationResult = Apollo.MutationResult<DeleteDegreeMutation>;
export type DeleteDegreeMutationOptions = Apollo.BaseMutationOptions<
  DeleteDegreeMutation,
  DeleteDegreeMutationVariables
>;
export const EnrollUserInDegreeDocument = gql`
  mutation EnrollUserInDegree($degreeId: ID!) {
    enrollInDegree(degreeId: $degreeId) {
      success
      data {
        id
        name
        usersEnrolledIn {
          id
        }
      }
    }
  }
`;
export type EnrollUserInDegreeMutationFn = Apollo.MutationFunction<
  EnrollUserInDegreeMutation,
  EnrollUserInDegreeMutationVariables
>;

/**
 * __useEnrollUserInDegreeMutation__
 *
 * To run a mutation, you first call `useEnrollUserInDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollUserInDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollUserInDegreeMutation, { data, loading, error }] = useEnrollUserInDegreeMutation({
 *   variables: {
 *      degreeId: // value for 'degreeId'
 *   },
 * });
 */
export function useEnrollUserInDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollUserInDegreeMutation, EnrollUserInDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollUserInDegreeMutation, EnrollUserInDegreeMutationVariables>(
    EnrollUserInDegreeDocument,
    options
  );
}
export type EnrollUserInDegreeMutationHookResult = ReturnType<typeof useEnrollUserInDegreeMutation>;
export type EnrollUserInDegreeMutationResult = Apollo.MutationResult<EnrollUserInDegreeMutation>;
export type EnrollUserInDegreeMutationOptions = Apollo.BaseMutationOptions<
  EnrollUserInDegreeMutation,
  EnrollUserInDegreeMutationVariables
>;
export const GenerateSuggestedDegreesDocument = gql`
  mutation generateSuggestedDegrees {
    generateSuggestedDegrees {
      success
      message
      data {
        id
        name
        created
        last_modified
      }
    }
  }
`;
export type GenerateSuggestedDegreesMutationFn = Apollo.MutationFunction<
  GenerateSuggestedDegreesMutation,
  GenerateSuggestedDegreesMutationVariables
>;

/**
 * __useGenerateSuggestedDegreesMutation__
 *
 * To run a mutation, you first call `useGenerateSuggestedDegreesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSuggestedDegreesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSuggestedDegreesMutation, { data, loading, error }] = useGenerateSuggestedDegreesMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSuggestedDegreesMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateSuggestedDegreesMutation, GenerateSuggestedDegreesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSuggestedDegreesMutation, GenerateSuggestedDegreesMutationVariables>(
    GenerateSuggestedDegreesDocument,
    options
  );
}
export type GenerateSuggestedDegreesMutationHookResult = ReturnType<typeof useGenerateSuggestedDegreesMutation>;
export type GenerateSuggestedDegreesMutationResult = Apollo.MutationResult<GenerateSuggestedDegreesMutation>;
export type GenerateSuggestedDegreesMutationOptions = Apollo.BaseMutationOptions<
  GenerateSuggestedDegreesMutation,
  GenerateSuggestedDegreesMutationVariables
>;
export const RemoveCoursesFromDegreeDocument = gql`
  mutation RemoveCoursesFromDegree($input: RemoveCoursesFromDegreeInput!) {
    removeCoursesFromDegree(input: $input) {
      success
      message
    }
  }
`;
export type RemoveCoursesFromDegreeMutationFn = Apollo.MutationFunction<
  RemoveCoursesFromDegreeMutation,
  RemoveCoursesFromDegreeMutationVariables
>;

/**
 * __useRemoveCoursesFromDegreeMutation__
 *
 * To run a mutation, you first call `useRemoveCoursesFromDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCoursesFromDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCoursesFromDegreeMutation, { data, loading, error }] = useRemoveCoursesFromDegreeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCoursesFromDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCoursesFromDegreeMutation, RemoveCoursesFromDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveCoursesFromDegreeMutation, RemoveCoursesFromDegreeMutationVariables>(
    RemoveCoursesFromDegreeDocument,
    options
  );
}
export type RemoveCoursesFromDegreeMutationHookResult = ReturnType<typeof useRemoveCoursesFromDegreeMutation>;
export type RemoveCoursesFromDegreeMutationResult = Apollo.MutationResult<RemoveCoursesFromDegreeMutation>;
export type RemoveCoursesFromDegreeMutationOptions = Apollo.BaseMutationOptions<
  RemoveCoursesFromDegreeMutation,
  RemoveCoursesFromDegreeMutationVariables
>;
export const UpdateDegreeDocument = gql`
  mutation UpdateDegree($id: ID!, $degree: UpdateDegreeInput!) {
    updateDegree(id: $id, degree: $degree) {
      success
      message
      data {
        id
        name
        description
        usersEnrolledIn {
          id
          name
        }
        coursesHasCourse {
          id
          name
        }
      }
    }
  }
`;
export type UpdateDegreeMutationFn = Apollo.MutationFunction<UpdateDegreeMutation, UpdateDegreeMutationVariables>;

/**
 * __useUpdateDegreeMutation__
 *
 * To run a mutation, you first call `useUpdateDegreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDegreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDegreeMutation, { data, loading, error }] = useUpdateDegreeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      degree: // value for 'degree'
 *   },
 * });
 */
export function useUpdateDegreeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDegreeMutation, UpdateDegreeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDegreeMutation, UpdateDegreeMutationVariables>(UpdateDegreeDocument, options);
}
export type UpdateDegreeMutationHookResult = ReturnType<typeof useUpdateDegreeMutation>;
export type UpdateDegreeMutationResult = Apollo.MutationResult<UpdateDegreeMutation>;
export type UpdateDegreeMutationOptions = Apollo.BaseMutationOptions<
  UpdateDegreeMutation,
  UpdateDegreeMutationVariables
>;
export const GenerateKeywordsDocument = gql`
  mutation GenerateKeywords($title: String!, $description: String!) {
    generateYoutubeKeywords(title: $title, description: $description)
  }
`;
export type GenerateKeywordsMutationFn = Apollo.MutationFunction<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;

/**
 * __useGenerateKeywordsMutation__
 *
 * To run a mutation, you first call `useGenerateKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateKeywordsMutation, { data, loading, error }] = useGenerateKeywordsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>(
    GenerateKeywordsDocument,
    options
  );
}
export type GenerateKeywordsMutationHookResult = ReturnType<typeof useGenerateKeywordsMutation>;
export type GenerateKeywordsMutationResult = Apollo.MutationResult<GenerateKeywordsMutation>;
export type GenerateKeywordsMutationOptions = Apollo.BaseMutationOptions<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;
export const GenerateUploadUrlDocument = gql`
  mutation GenerateUploadUrl($fileName: String!, $storagePath: StoragePathType!) {
    generateUploadUrl(fileName: $fileName, storagePath: $storagePath) {
      data {
        id
        fileName
        fileURL
        created
        status
      }
      message
      success
    }
  }
`;
export type GenerateUploadUrlMutationFn = Apollo.MutationFunction<
  GenerateUploadUrlMutation,
  GenerateUploadUrlMutationVariables
>;

/**
 * __useGenerateUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadUrlMutation, { data, loading, error }] = useGenerateUploadUrlMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      storagePath: // value for 'storagePath'
 *   },
 * });
 */
export function useGenerateUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateUploadUrlMutation, GenerateUploadUrlMutationVariables>(
    GenerateUploadUrlDocument,
    options
  );
}
export type GenerateUploadUrlMutationHookResult = ReturnType<typeof useGenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationResult = Apollo.MutationResult<GenerateUploadUrlMutation>;
export type GenerateUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateUploadUrlMutation,
  GenerateUploadUrlMutationVariables
>;
export const MarkQuestCompleteDocument = gql`
  mutation MarkQuestComplete($chatId: ID!, $questId: ID!) {
    markQuestComplete(chatId: $chatId, questId: $questId) {
      id
      assignedQuests {
        id
        title
        isComplete
      }
    }
  }
`;
export type MarkQuestCompleteMutationFn = Apollo.MutationFunction<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;

/**
 * __useMarkQuestCompleteMutation__
 *
 * To run a mutation, you first call `useMarkQuestCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuestCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuestCompleteMutation, { data, loading, error }] = useMarkQuestCompleteMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      questId: // value for 'questId'
 *   },
 * });
 */
export function useMarkQuestCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>(
    MarkQuestCompleteDocument,
    options
  );
}
export type MarkQuestCompleteMutationHookResult = ReturnType<typeof useMarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationResult = Apollo.MutationResult<MarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationOptions = Apollo.BaseMutationOptions<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;
export const RefreshQuestDocument = gql`
  mutation RefreshQuest($questIdToReplace: ID!, $timezone: String!) {
    refreshQuest(questIdToReplace: $questIdToReplace, timezone: $timezone) {
      success
      message
      quests {
        id
        title
        description
        isComplete
      }
    }
  }
`;
export type RefreshQuestMutationFn = Apollo.MutationFunction<RefreshQuestMutation, RefreshQuestMutationVariables>;

/**
 * __useRefreshQuestMutation__
 *
 * To run a mutation, you first call `useRefreshQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshQuestMutation, { data, loading, error }] = useRefreshQuestMutation({
 *   variables: {
 *      questIdToReplace: // value for 'questIdToReplace'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useRefreshQuestMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshQuestMutation, RefreshQuestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshQuestMutation, RefreshQuestMutationVariables>(RefreshQuestDocument, options);
}
export type RefreshQuestMutationHookResult = ReturnType<typeof useRefreshQuestMutation>;
export type RefreshQuestMutationResult = Apollo.MutationResult<RefreshQuestMutation>;
export type RefreshQuestMutationOptions = Apollo.BaseMutationOptions<
  RefreshQuestMutation,
  RefreshQuestMutationVariables
>;
export const AudioToggleUserDocument = gql`
  mutation AudioToggleUser($audioOn: Boolean!) {
    audioToggleUser(audioOn: $audioOn) {
      audioOn
    }
  }
`;
export type AudioToggleUserMutationFn = Apollo.MutationFunction<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;

/**
 * __useAudioToggleUserMutation__
 *
 * To run a mutation, you first call `useAudioToggleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioToggleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioToggleUserMutation, { data, loading, error }] = useAudioToggleUserMutation({
 *   variables: {
 *      audioOn: // value for 'audioOn'
 *   },
 * });
 */
export function useAudioToggleUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AudioToggleUserMutation, AudioToggleUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AudioToggleUserMutation, AudioToggleUserMutationVariables>(
    AudioToggleUserDocument,
    options
  );
}
export type AudioToggleUserMutationHookResult = ReturnType<typeof useAudioToggleUserMutation>;
export type AudioToggleUserMutationResult = Apollo.MutationResult<AudioToggleUserMutation>;
export type AudioToggleUserMutationOptions = Apollo.BaseMutationOptions<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;
export const UpdateNotificationsDocument = gql`
  mutation UpdateNotifications($dailyEmailOptIn: Boolean!) {
    updateNotifications(dailyEmailOptIn: $dailyEmailOptIn) {
      success
      message
    }
  }
`;
export type UpdateNotificationsMutationFn = Apollo.MutationFunction<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;

/**
 * __useUpdateNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsMutation, { data, loading, error }] = useUpdateNotificationsMutation({
 *   variables: {
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *   },
 * });
 */
export function useUpdateNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>(
    UpdateNotificationsDocument,
    options
  );
}
export type UpdateNotificationsMutationHookResult = ReturnType<typeof useUpdateNotificationsMutation>;
export type UpdateNotificationsMutationResult = Apollo.MutationResult<UpdateNotificationsMutation>;
export type UpdateNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;
export const AddToWhitelistDocument = gql`
  mutation AddToWhitelist($email: String!) {
    addToWhitelist(email: $email) {
      email
    }
  }
`;
export type AddToWhitelistMutationFn = Apollo.MutationFunction<AddToWhitelistMutation, AddToWhitelistMutationVariables>;

/**
 * __useAddToWhitelistMutation__
 *
 * To run a mutation, you first call `useAddToWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWhitelistMutation, { data, loading, error }] = useAddToWhitelistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddToWhitelistMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToWhitelistMutation, AddToWhitelistMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToWhitelistMutation, AddToWhitelistMutationVariables>(AddToWhitelistDocument, options);
}
export type AddToWhitelistMutationHookResult = ReturnType<typeof useAddToWhitelistMutation>;
export type AddToWhitelistMutationResult = Apollo.MutationResult<AddToWhitelistMutation>;
export type AddToWhitelistMutationOptions = Apollo.BaseMutationOptions<
  AddToWhitelistMutation,
  AddToWhitelistMutationVariables
>;
export const CompletePendingInviteDocument = gql`
  mutation CompletePendingInvite($token: String!, $newPassword: String!, $service: String!) {
    completePendingInvite(token: $token, newPassword: $newPassword, service: $service) {
      message
      success
    }
  }
`;
export type CompletePendingInviteMutationFn = Apollo.MutationFunction<
  CompletePendingInviteMutation,
  CompletePendingInviteMutationVariables
>;

/**
 * __useCompletePendingInviteMutation__
 *
 * To run a mutation, you first call `useCompletePendingInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePendingInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePendingInviteMutation, { data, loading, error }] = useCompletePendingInviteMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCompletePendingInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<CompletePendingInviteMutation, CompletePendingInviteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompletePendingInviteMutation, CompletePendingInviteMutationVariables>(
    CompletePendingInviteDocument,
    options
  );
}
export type CompletePendingInviteMutationHookResult = ReturnType<typeof useCompletePendingInviteMutation>;
export type CompletePendingInviteMutationResult = Apollo.MutationResult<CompletePendingInviteMutation>;
export type CompletePendingInviteMutationOptions = Apollo.BaseMutationOptions<
  CompletePendingInviteMutation,
  CompletePendingInviteMutationVariables
>;
export const CompleteJourneyDocument = gql`
  mutation CompleteJourney($journeyId: ID!) {
    completeJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type CompleteJourneyMutationFn = Apollo.MutationFunction<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;

/**
 * __useCompleteJourneyMutation__
 *
 * To run a mutation, you first call `useCompleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeJourneyMutation, { data, loading, error }] = useCompleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useCompleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteJourneyMutation, CompleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteJourneyMutation, CompleteJourneyMutationVariables>(
    CompleteJourneyDocument,
    options
  );
}
export type CompleteJourneyMutationHookResult = ReturnType<typeof useCompleteJourneyMutation>;
export type CompleteJourneyMutationResult = Apollo.MutationResult<CompleteJourneyMutation>;
export type CompleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;
export const DeleteJourneyDocument = gql`
  mutation DeleteJourney($journeyId: ID!) {
    deleteJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type DeleteJourneyMutationFn = Apollo.MutationFunction<DeleteJourneyMutation, DeleteJourneyMutationVariables>;

/**
 * __useDeleteJourneyMutation__
 *
 * To run a mutation, you first call `useDeleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJourneyMutation, { data, loading, error }] = useDeleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useDeleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJourneyMutation, DeleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJourneyMutation, DeleteJourneyMutationVariables>(DeleteJourneyDocument, options);
}
export type DeleteJourneyMutationHookResult = ReturnType<typeof useDeleteJourneyMutation>;
export type DeleteJourneyMutationResult = Apollo.MutationResult<DeleteJourneyMutation>;
export type DeleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  DeleteJourneyMutation,
  DeleteJourneyMutationVariables
>;
export const JourneySubmitFormDocument = gql`
  mutation JourneySubmitForm($description: String!, $dueDate: String!, $timezone: String!) {
    journeySubmitForm(description: $description, dueDate: $dueDate, timezone: $timezone) {
      success
      message
    }
  }
`;
export type JourneySubmitFormMutationFn = Apollo.MutationFunction<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;

/**
 * __useJourneySubmitFormMutation__
 *
 * To run a mutation, you first call `useJourneySubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJourneySubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journeySubmitFormMutation, { data, loading, error }] = useJourneySubmitFormMutation({
 *   variables: {
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useJourneySubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>(
    JourneySubmitFormDocument,
    options
  );
}
export type JourneySubmitFormMutationHookResult = ReturnType<typeof useJourneySubmitFormMutation>;
export type JourneySubmitFormMutationResult = Apollo.MutationResult<JourneySubmitFormMutation>;
export type JourneySubmitFormMutationOptions = Apollo.BaseMutationOptions<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;
export const CreateEducationChildrenDocument = gql`
  mutation CreateEducationChildren($label: NodeLabel!, $name: String!) {
    createEducationChildren(label: $label, name: $name) {
      success
      message
      data {
        name
      }
    }
  }
`;
export type CreateEducationChildrenMutationFn = Apollo.MutationFunction<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;

/**
 * __useCreateEducationChildrenMutation__
 *
 * To run a mutation, you first call `useCreateEducationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationChildrenMutation, { data, loading, error }] = useCreateEducationChildrenMutation({
 *   variables: {
 *      label: // value for 'label'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEducationChildrenMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>(
    CreateEducationChildrenDocument,
    options
  );
}
export type CreateEducationChildrenMutationHookResult = ReturnType<typeof useCreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationResult = Apollo.MutationResult<CreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationOptions = Apollo.BaseMutationOptions<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;
export const DropUserFromCourseDocument = gql`
  mutation DropUserFromCourse($courseId: ID!) {
    dropUserFromCourse(courseId: $courseId) {
      success
      message
    }
  }
`;
export type DropUserFromCourseMutationFn = Apollo.MutationFunction<
  DropUserFromCourseMutation,
  DropUserFromCourseMutationVariables
>;

/**
 * __useDropUserFromCourseMutation__
 *
 * To run a mutation, you first call `useDropUserFromCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropUserFromCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropUserFromCourseMutation, { data, loading, error }] = useDropUserFromCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDropUserFromCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<DropUserFromCourseMutation, DropUserFromCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DropUserFromCourseMutation, DropUserFromCourseMutationVariables>(
    DropUserFromCourseDocument,
    options
  );
}
export type DropUserFromCourseMutationHookResult = ReturnType<typeof useDropUserFromCourseMutation>;
export type DropUserFromCourseMutationResult = Apollo.MutationResult<DropUserFromCourseMutation>;
export type DropUserFromCourseMutationOptions = Apollo.BaseMutationOptions<
  DropUserFromCourseMutation,
  DropUserFromCourseMutationVariables
>;
export const EnrollUserInCourseDocument = gql`
  mutation EnrollUserInCourse($courseId: ID!) {
    enrollUserInCourse(courseId: $courseId) {
      success
      message
    }
  }
`;
export type EnrollUserInCourseMutationFn = Apollo.MutationFunction<
  EnrollUserInCourseMutation,
  EnrollUserInCourseMutationVariables
>;

/**
 * __useEnrollUserInCourseMutation__
 *
 * To run a mutation, you first call `useEnrollUserInCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollUserInCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollUserInCourseMutation, { data, loading, error }] = useEnrollUserInCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useEnrollUserInCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollUserInCourseMutation, EnrollUserInCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollUserInCourseMutation, EnrollUserInCourseMutationVariables>(
    EnrollUserInCourseDocument,
    options
  );
}
export type EnrollUserInCourseMutationHookResult = ReturnType<typeof useEnrollUserInCourseMutation>;
export type EnrollUserInCourseMutationResult = Apollo.MutationResult<EnrollUserInCourseMutation>;
export type EnrollUserInCourseMutationOptions = Apollo.BaseMutationOptions<
  EnrollUserInCourseMutation,
  EnrollUserInCourseMutationVariables
>;
export const EnrollUserInTrackDocument = gql`
  mutation EnrollUserInTrack($topicName: String!) {
    enrollUserInTrack(topicName: $topicName) {
      success
      message
    }
  }
`;
export type EnrollUserInTrackMutationFn = Apollo.MutationFunction<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;

/**
 * __useEnrollUserInTrackMutation__
 *
 * To run a mutation, you first call `useEnrollUserInTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollUserInTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollUserInTrackMutation, { data, loading, error }] = useEnrollUserInTrackMutation({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useEnrollUserInTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>(
    EnrollUserInTrackDocument,
    options
  );
}
export type EnrollUserInTrackMutationHookResult = ReturnType<typeof useEnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationResult = Apollo.MutationResult<EnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationOptions = Apollo.BaseMutationOptions<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;
export const SetKnowledgeRelevanceDocument = gql`
  mutation SetKnowledgeRelevance($nodeName: String!, $nodeLabel: String!, $isRelevant: Boolean!) {
    setKnowledgeRelevance(nodeName: $nodeName, nodeLabel: $nodeLabel, isRelevant: $isRelevant) {
      success
      message
    }
  }
`;
export type SetKnowledgeRelevanceMutationFn = Apollo.MutationFunction<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;

/**
 * __useSetKnowledgeRelevanceMutation__
 *
 * To run a mutation, you first call `useSetKnowledgeRelevanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKnowledgeRelevanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKnowledgeRelevanceMutation, { data, loading, error }] = useSetKnowledgeRelevanceMutation({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *      isRelevant: // value for 'isRelevant'
 *   },
 * });
 */
export function useSetKnowledgeRelevanceMutation(
  baseOptions?: Apollo.MutationHookOptions<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>(
    SetKnowledgeRelevanceDocument,
    options
  );
}
export type SetKnowledgeRelevanceMutationHookResult = ReturnType<typeof useSetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationResult = Apollo.MutationResult<SetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationOptions = Apollo.BaseMutationOptions<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;
export const UpdateTopicDocument = gql`
  mutation UpdateTopic($operationType: String!, $topicName: String!) {
    updateTopic(operationType: $operationType, topicName: $topicName) {
      success
      message
    }
  }
`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      operationType: // value for 'operationType'
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useUpdateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
}
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const UpdateLearningObjectiveCompletionDocument = gql`
  mutation UpdateLearningObjectiveCompletion($id: ID!, $completed: Boolean!) {
    updateLearningObjectiveCompletion(id: $id, completed: $completed) {
      success
      message
    }
  }
`;
export type UpdateLearningObjectiveCompletionMutationFn = Apollo.MutationFunction<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;

/**
 * __useUpdateLearningObjectiveCompletionMutation__
 *
 * To run a mutation, you first call `useUpdateLearningObjectiveCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningObjectiveCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningObjectiveCompletionMutation, { data, loading, error }] = useUpdateLearningObjectiveCompletionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useUpdateLearningObjectiveCompletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >(UpdateLearningObjectiveCompletionDocument, options);
}
export type UpdateLearningObjectiveCompletionMutationHookResult = ReturnType<
  typeof useUpdateLearningObjectiveCompletionMutation
>;
export type UpdateLearningObjectiveCompletionMutationResult =
  Apollo.MutationResult<UpdateLearningObjectiveCompletionMutation>;
export type UpdateLearningObjectiveCompletionMutationOptions = Apollo.BaseMutationOptions<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;
export const SetOnboardingStatusDocument = gql`
  mutation SetOnboardingStatus($isOnboarded: Boolean!) {
    setOnboardingStatus(isOnboarded: $isOnboarded) {
      message
      success
    }
  }
`;
export type SetOnboardingStatusMutationFn = Apollo.MutationFunction<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;

/**
 * __useSetOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useSetOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOnboardingStatusMutation, { data, loading, error }] = useSetOnboardingStatusMutation({
 *   variables: {
 *      isOnboarded: // value for 'isOnboarded'
 *   },
 * });
 */
export function useSetOnboardingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>(
    SetOnboardingStatusDocument,
    options
  );
}
export type SetOnboardingStatusMutationHookResult = ReturnType<typeof useSetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationResult = Apollo.MutationResult<SetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;
export const UpdateUserModalOnboardingDocument = gql`
  mutation UpdateUserModalOnboarding($modalOnboarding: String!) {
    updateUserModalOnboarding(modalOnboarding: $modalOnboarding) {
      modalOnboarding
    }
  }
`;
export type UpdateUserModalOnboardingMutationFn = Apollo.MutationFunction<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;

/**
 * __useUpdateUserModalOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserModalOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserModalOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserModalOnboardingMutation, { data, loading, error }] = useUpdateUserModalOnboardingMutation({
 *   variables: {
 *      modalOnboarding: // value for 'modalOnboarding'
 *   },
 * });
 */
export function useUpdateUserModalOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserModalOnboardingMutation,
    UpdateUserModalOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserModalOnboardingMutation, UpdateUserModalOnboardingMutationVariables>(
    UpdateUserModalOnboardingDocument,
    options
  );
}
export type UpdateUserModalOnboardingMutationHookResult = ReturnType<typeof useUpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationResult = Apollo.MutationResult<UpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount {
      success
      message
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const ResetAccountDocument = gql`
  mutation resetAccount {
    resetAccount {
      success
      message
    }
  }
`;
export type ResetAccountMutationFn = Apollo.MutationFunction<ResetAccountMutation, ResetAccountMutationVariables>;

/**
 * __useResetAccountMutation__
 *
 * To run a mutation, you first call `useResetAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountMutation, { data, loading, error }] = useResetAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetAccountMutation, ResetAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetAccountMutation, ResetAccountMutationVariables>(ResetAccountDocument, options);
}
export type ResetAccountMutationHookResult = ReturnType<typeof useResetAccountMutation>;
export type ResetAccountMutationResult = Apollo.MutationResult<ResetAccountMutation>;
export type ResetAccountMutationOptions = Apollo.BaseMutationOptions<
  ResetAccountMutation,
  ResetAccountMutationVariables
>;
export const AddTodoDocument = gql`
  mutation AddTodo(
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $timezone: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    addTodo(
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      timezone: $timezone
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
    }
  }
`;
export type AddTodoMutationFn = Apollo.MutationFunction<AddTodoMutation, AddTodoMutationVariables>;

/**
 * __useAddTodoMutation__
 *
 * To run a mutation, you first call `useAddTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTodoMutation, { data, loading, error }] = useAddTodoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      timezone: // value for 'timezone'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useAddTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTodoMutation, AddTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTodoMutation, AddTodoMutationVariables>(AddTodoDocument, options);
}
export type AddTodoMutationHookResult = ReturnType<typeof useAddTodoMutation>;
export type AddTodoMutationResult = Apollo.MutationResult<AddTodoMutation>;
export type AddTodoMutationOptions = Apollo.BaseMutationOptions<AddTodoMutation, AddTodoMutationVariables>;
export const UpdateTodoDocument = gql`
  mutation UpdateTodo(
    $id: String!
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $completed: Boolean
    $pointsReceived: Int
    $timezone: String!
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    updateTodo(
      id: $id
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      completed: $completed
      pointsReceived: $pointsReceived
      timezone: $timezone
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      completed
      pointsReceived
      timezone
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      isPartOfJourney
      journeyId
    }
  }
`;
export type UpdateTodoMutationFn = Apollo.MutationFunction<UpdateTodoMutation, UpdateTodoMutationVariables>;

/**
 * __useUpdateTodoMutation__
 *
 * To run a mutation, you first call `useUpdateTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodoMutation, { data, loading, error }] = useUpdateTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      completed: // value for 'completed'
 *      pointsReceived: // value for 'pointsReceived'
 *      timezone: // value for 'timezone'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useUpdateTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTodoMutation, UpdateTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTodoMutation, UpdateTodoMutationVariables>(UpdateTodoDocument, options);
}
export type UpdateTodoMutationHookResult = ReturnType<typeof useUpdateTodoMutation>;
export type UpdateTodoMutationResult = Apollo.MutationResult<UpdateTodoMutation>;
export type UpdateTodoMutationOptions = Apollo.BaseMutationOptions<UpdateTodoMutation, UpdateTodoMutationVariables>;
export const UpdateTenantDocument = gql`
  mutation UpdateTenant($id: ID!, $tenant: TenantInput!) {
    updateTenant(id: $id, tenant: $tenant) {
      count
      success
      message
      data {
        id
        tenantDomain
        metaTagsTitle
        metaTagsDescription
        metaTagsThemeColor
        metaTagsAppleTouchIcon
        metaTagsTwitterCard
        metaTagsTwitterSite
        metaTagsTwitterTitle
        metaTagsTwitterDescription
        metaTagsTwitterImage
        stripeSubscriptionId
        hasTrial
        stripeTrialDays
        externalSendGridAppInviteTemplateId
        externalSendGridDailyEmailTemplateId
        externalSendGridFromEmail
        externalSendGridFromName
        externalSendGridTwilioServiceSID
        moderationSexual
        moderationHate
        moderationHarassment
        moderationSelfHarm
        moderationSexualMinors
        moderationHateThreatening
        moderationViolenceGraphic
        moderationSelfHarmIntent
        moderationSelfHarmInstructions
        moderationHarassmentThreatening
        moderationViolence
        companionName
        key
        isLMS
        isLTI
        isAxioOffering
        isSuggestedInsights
        axioSchools
        disableWebsearchWhenRAG
        primaryColor
        learningFieldColor
        learningSubFieldColor
        gameMenuBg
        gameMenuTrack
        gameMenuRefresh
        gameMenuRefreshText
        gameMenuProgress
        gameMenuCardBg
        gameMenuCardButton
        gameMenuCardButtonText
        gameMenuCardRefresh
        emulationStatement
        learningEmulationStatement
        logo
        showMiniLogo
        scope
        additionalScope
        connectURL
        termsOfServiceLink
        showObjectivesTab
        showJobsTab
        generalPrompt
        onboardingPrompt
        newsSectionTitle
        customNews
        showNews
        newsSearchTerms
        showInviteFriends
        showFeedback
        isPrivateLabelIcon
        bugReportEmails
        upgradeModalBody
        isFreemium
        hasEmailVerification
        created
        last_modified
        hasProFlow
        stripeSecretKey
        hasDegrees
      }
    }
  }
`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
}
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;
export const AssignCourseToFacultyDocument = gql`
  mutation AssignCourseToFaculty($userId: ID!, $courseId: ID!) {
    assignCourseToFaculty(userId: $userId, courseId: $courseId) {
      success
      message
    }
  }
`;
export type AssignCourseToFacultyMutationFn = Apollo.MutationFunction<
  AssignCourseToFacultyMutation,
  AssignCourseToFacultyMutationVariables
>;

/**
 * __useAssignCourseToFacultyMutation__
 *
 * To run a mutation, you first call `useAssignCourseToFacultyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCourseToFacultyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCourseToFacultyMutation, { data, loading, error }] = useAssignCourseToFacultyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useAssignCourseToFacultyMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignCourseToFacultyMutation, AssignCourseToFacultyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignCourseToFacultyMutation, AssignCourseToFacultyMutationVariables>(
    AssignCourseToFacultyDocument,
    options
  );
}
export type AssignCourseToFacultyMutationHookResult = ReturnType<typeof useAssignCourseToFacultyMutation>;
export type AssignCourseToFacultyMutationResult = Apollo.MutationResult<AssignCourseToFacultyMutation>;
export type AssignCourseToFacultyMutationOptions = Apollo.BaseMutationOptions<
  AssignCourseToFacultyMutation,
  AssignCourseToFacultyMutationVariables
>;
export const InviteUserToSchoolDocument = gql`
  mutation InviteUserToSchool($input: InviteUserToSchoolInput!) {
    inviteUserToSchool(input: $input) {
      success
      message
    }
  }
`;
export type InviteUserToSchoolMutationFn = Apollo.MutationFunction<
  InviteUserToSchoolMutation,
  InviteUserToSchoolMutationVariables
>;

/**
 * __useInviteUserToSchoolMutation__
 *
 * To run a mutation, you first call `useInviteUserToSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToSchoolMutation, { data, loading, error }] = useInviteUserToSchoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserToSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserToSchoolMutation, InviteUserToSchoolMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserToSchoolMutation, InviteUserToSchoolMutationVariables>(
    InviteUserToSchoolDocument,
    options
  );
}
export type InviteUserToSchoolMutationHookResult = ReturnType<typeof useInviteUserToSchoolMutation>;
export type InviteUserToSchoolMutationResult = Apollo.MutationResult<InviteUserToSchoolMutation>;
export type InviteUserToSchoolMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToSchoolMutation,
  InviteUserToSchoolMutationVariables
>;
export const RemoveCourseAssignmentFromFacultyDocument = gql`
  mutation RemoveCourseAssignmentFromFaculty($userId: ID!, $courseId: ID!) {
    removeCourseAssignmentFromFaculty(userId: $userId, courseId: $courseId) {
      success
      message
    }
  }
`;
export type RemoveCourseAssignmentFromFacultyMutationFn = Apollo.MutationFunction<
  RemoveCourseAssignmentFromFacultyMutation,
  RemoveCourseAssignmentFromFacultyMutationVariables
>;

/**
 * __useRemoveCourseAssignmentFromFacultyMutation__
 *
 * To run a mutation, you first call `useRemoveCourseAssignmentFromFacultyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseAssignmentFromFacultyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseAssignmentFromFacultyMutation, { data, loading, error }] = useRemoveCourseAssignmentFromFacultyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useRemoveCourseAssignmentFromFacultyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCourseAssignmentFromFacultyMutation,
    RemoveCourseAssignmentFromFacultyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCourseAssignmentFromFacultyMutation,
    RemoveCourseAssignmentFromFacultyMutationVariables
  >(RemoveCourseAssignmentFromFacultyDocument, options);
}
export type RemoveCourseAssignmentFromFacultyMutationHookResult = ReturnType<
  typeof useRemoveCourseAssignmentFromFacultyMutation
>;
export type RemoveCourseAssignmentFromFacultyMutationResult =
  Apollo.MutationResult<RemoveCourseAssignmentFromFacultyMutation>;
export type RemoveCourseAssignmentFromFacultyMutationOptions = Apollo.BaseMutationOptions<
  RemoveCourseAssignmentFromFacultyMutation,
  RemoveCourseAssignmentFromFacultyMutationVariables
>;
export const RevokeFacultyDocument = gql`
  mutation RevokeFaculty($userId: ID!) {
    revokeFaculty(userId: $userId) {
      success
      message
    }
  }
`;
export type RevokeFacultyMutationFn = Apollo.MutationFunction<RevokeFacultyMutation, RevokeFacultyMutationVariables>;

/**
 * __useRevokeFacultyMutation__
 *
 * To run a mutation, you first call `useRevokeFacultyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeFacultyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeFacultyMutation, { data, loading, error }] = useRevokeFacultyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeFacultyMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeFacultyMutation, RevokeFacultyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RevokeFacultyMutation, RevokeFacultyMutationVariables>(RevokeFacultyDocument, options);
}
export type RevokeFacultyMutationHookResult = ReturnType<typeof useRevokeFacultyMutation>;
export type RevokeFacultyMutationResult = Apollo.MutationResult<RevokeFacultyMutation>;
export type RevokeFacultyMutationOptions = Apollo.BaseMutationOptions<
  RevokeFacultyMutation,
  RevokeFacultyMutationVariables
>;
export const UpdateFacultyProfileDocument = gql`
  mutation UpdateFacultyProfile($input: UpdateFacultyProfileInput!) {
    updateFacultyProfile(input: $input) {
      success
      message
    }
  }
`;
export type UpdateFacultyProfileMutationFn = Apollo.MutationFunction<
  UpdateFacultyProfileMutation,
  UpdateFacultyProfileMutationVariables
>;

/**
 * __useUpdateFacultyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateFacultyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacultyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacultyProfileMutation, { data, loading, error }] = useUpdateFacultyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacultyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFacultyProfileMutation, UpdateFacultyProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFacultyProfileMutation, UpdateFacultyProfileMutationVariables>(
    UpdateFacultyProfileDocument,
    options
  );
}
export type UpdateFacultyProfileMutationHookResult = ReturnType<typeof useUpdateFacultyProfileMutation>;
export type UpdateFacultyProfileMutationResult = Apollo.MutationResult<UpdateFacultyProfileMutation>;
export type UpdateFacultyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFacultyProfileMutation,
  UpdateFacultyProfileMutationVariables
>;
export const UploadFacultyProfileImageDocument = gql`
  mutation UploadFacultyProfileImage($input: UploadFacultyProfileImageInput!) {
    uploadFacultyProfileImage(input: $input) {
      success
      message
      imageUrl
    }
  }
`;
export type UploadFacultyProfileImageMutationFn = Apollo.MutationFunction<
  UploadFacultyProfileImageMutation,
  UploadFacultyProfileImageMutationVariables
>;

/**
 * __useUploadFacultyProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadFacultyProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFacultyProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFacultyProfileImageMutation, { data, loading, error }] = useUploadFacultyProfileImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFacultyProfileImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFacultyProfileImageMutation,
    UploadFacultyProfileImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFacultyProfileImageMutation, UploadFacultyProfileImageMutationVariables>(
    UploadFacultyProfileImageDocument,
    options
  );
}
export type UploadFacultyProfileImageMutationHookResult = ReturnType<typeof useUploadFacultyProfileImageMutation>;
export type UploadFacultyProfileImageMutationResult = Apollo.MutationResult<UploadFacultyProfileImageMutation>;
export type UploadFacultyProfileImageMutationOptions = Apollo.BaseMutationOptions<
  UploadFacultyProfileImageMutation,
  UploadFacultyProfileImageMutationVariables
>;
export const CreateVisualizationFromContentDocument = gql`
  mutation CreateVisualizationFromContent($title: String!, $description: String!, $objectives: [ObjectiveInput!]!) {
    createVisualizationFromContent(content: { title: $title, description: $description, objectives: $objectives }) {
      success
      message
      data {
        id
        code
        visualizationType
        created
        lastModified
      }
      count
    }
  }
`;
export type CreateVisualizationFromContentMutationFn = Apollo.MutationFunction<
  CreateVisualizationFromContentMutation,
  CreateVisualizationFromContentMutationVariables
>;

/**
 * __useCreateVisualizationFromContentMutation__
 *
 * To run a mutation, you first call `useCreateVisualizationFromContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisualizationFromContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisualizationFromContentMutation, { data, loading, error }] = useCreateVisualizationFromContentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      objectives: // value for 'objectives'
 *   },
 * });
 */
export function useCreateVisualizationFromContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVisualizationFromContentMutation,
    CreateVisualizationFromContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVisualizationFromContentMutation, CreateVisualizationFromContentMutationVariables>(
    CreateVisualizationFromContentDocument,
    options
  );
}
export type CreateVisualizationFromContentMutationHookResult = ReturnType<
  typeof useCreateVisualizationFromContentMutation
>;
export type CreateVisualizationFromContentMutationResult =
  Apollo.MutationResult<CreateVisualizationFromContentMutation>;
export type CreateVisualizationFromContentMutationOptions = Apollo.BaseMutationOptions<
  CreateVisualizationFromContentMutation,
  CreateVisualizationFromContentMutationVariables
>;
export const DeleteVisualizationDocument = gql`
  mutation DeleteVisualization($id: ID!) {
    deleteVisualization(id: $id) {
      count
      message
      success
    }
  }
`;
export type DeleteVisualizationMutationFn = Apollo.MutationFunction<
  DeleteVisualizationMutation,
  DeleteVisualizationMutationVariables
>;

/**
 * __useDeleteVisualizationMutation__
 *
 * To run a mutation, you first call `useDeleteVisualizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisualizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisualizationMutation, { data, loading, error }] = useDeleteVisualizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVisualizationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVisualizationMutation, DeleteVisualizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVisualizationMutation, DeleteVisualizationMutationVariables>(
    DeleteVisualizationDocument,
    options
  );
}
export type DeleteVisualizationMutationHookResult = ReturnType<typeof useDeleteVisualizationMutation>;
export type DeleteVisualizationMutationResult = Apollo.MutationResult<DeleteVisualizationMutation>;
export type DeleteVisualizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteVisualizationMutation,
  DeleteVisualizationMutationVariables
>;
export const UpdateVisualizationDocument = gql`
  mutation UpdateVisualization($id: ID!, $title: String!, $description: String!, $objectives: [ObjectiveInput!]!) {
    updateVisualization(id: $id, content: { title: $title, description: $description, objectives: $objectives }) {
      success
      message
      data {
        id
        code
        visualizationType
        created
        lastModified
      }
      count
    }
  }
`;
export type UpdateVisualizationMutationFn = Apollo.MutationFunction<
  UpdateVisualizationMutation,
  UpdateVisualizationMutationVariables
>;

/**
 * __useUpdateVisualizationMutation__
 *
 * To run a mutation, you first call `useUpdateVisualizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisualizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisualizationMutation, { data, loading, error }] = useUpdateVisualizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      objectives: // value for 'objectives'
 *   },
 * });
 */
export function useUpdateVisualizationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVisualizationMutation, UpdateVisualizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVisualizationMutation, UpdateVisualizationMutationVariables>(
    UpdateVisualizationDocument,
    options
  );
}
export type UpdateVisualizationMutationHookResult = ReturnType<typeof useUpdateVisualizationMutation>;
export type UpdateVisualizationMutationResult = Apollo.MutationResult<UpdateVisualizationMutation>;
export type UpdateVisualizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisualizationMutation,
  UpdateVisualizationMutationVariables
>;
export const GetActivityCompletionDetailsDocument = gql`
  query GetActivityCompletionDetails($aiTutorId: ID!) {
    getActivityCompletionDetails(aiTutorId: $aiTutorId) {
      success
      message
      count
      data {
        id
        label
        module_id
        module_name
        course_id
        course_name
        completionStats {
          completed
          inProgress
          notStarted
        }
        details {
          objectives {
            id
            label
            completionStats {
              completed
              inProgress
              notStarted
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetActivityCompletionDetailsQuery__
 *
 * To run a query within a React component, call `useGetActivityCompletionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCompletionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCompletionDetailsQuery({
 *   variables: {
 *      aiTutorId: // value for 'aiTutorId'
 *   },
 * });
 */
export function useGetActivityCompletionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityCompletionDetailsQuery, GetActivityCompletionDetailsQueryVariables> &
    ({ variables: GetActivityCompletionDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivityCompletionDetailsQuery, GetActivityCompletionDetailsQueryVariables>(
    GetActivityCompletionDetailsDocument,
    options
  );
}
export function useGetActivityCompletionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityCompletionDetailsQuery,
    GetActivityCompletionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivityCompletionDetailsQuery, GetActivityCompletionDetailsQueryVariables>(
    GetActivityCompletionDetailsDocument,
    options
  );
}
export function useGetActivityCompletionDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetActivityCompletionDetailsQuery, GetActivityCompletionDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActivityCompletionDetailsQuery, GetActivityCompletionDetailsQueryVariables>(
    GetActivityCompletionDetailsDocument,
    options
  );
}
export type GetActivityCompletionDetailsQueryHookResult = ReturnType<typeof useGetActivityCompletionDetailsQuery>;
export type GetActivityCompletionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetActivityCompletionDetailsLazyQuery
>;
export type GetActivityCompletionDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityCompletionDetailsSuspenseQuery
>;
export type GetActivityCompletionDetailsQueryResult = Apollo.QueryResult<
  GetActivityCompletionDetailsQuery,
  GetActivityCompletionDetailsQueryVariables
>;
export const GetCourseCompletionDetailsDocument = gql`
  query GetCourseCompletionDetails($courseId: ID!) {
    getCourseCompletionDetails(courseId: $courseId) {
      success
      message
      count
      data {
        id
        label
        completionStats {
          completed
          inProgress
          notStarted
        }
        details {
          modules {
            id
            label
            completionStats {
              completed
              inProgress
              notStarted
            }
            details {
              activities {
                id
                label
                completionStats {
                  completed
                  inProgress
                  notStarted
                }
                details {
                  objectives {
                    id
                    label
                    completionStats {
                      completed
                      inProgress
                      notStarted
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseCompletionDetailsQuery__
 *
 * To run a query within a React component, call `useGetCourseCompletionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCompletionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCompletionDetailsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseCompletionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables> &
    ({ variables: GetCourseCompletionDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables>(
    GetCourseCompletionDetailsDocument,
    options
  );
}
export function useGetCourseCompletionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables>(
    GetCourseCompletionDetailsDocument,
    options
  );
}
export function useGetCourseCompletionDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseCompletionDetailsQuery, GetCourseCompletionDetailsQueryVariables>(
    GetCourseCompletionDetailsDocument,
    options
  );
}
export type GetCourseCompletionDetailsQueryHookResult = ReturnType<typeof useGetCourseCompletionDetailsQuery>;
export type GetCourseCompletionDetailsLazyQueryHookResult = ReturnType<typeof useGetCourseCompletionDetailsLazyQuery>;
export type GetCourseCompletionDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetCourseCompletionDetailsSuspenseQuery
>;
export type GetCourseCompletionDetailsQueryResult = Apollo.QueryResult<
  GetCourseCompletionDetailsQuery,
  GetCourseCompletionDetailsQueryVariables
>;
export const GetCourseCompletionSummaryBySchoolDocument = gql`
  query GetCourseCompletionSummaryBySchool($schoolId: ID!) {
    getCourseCompletionSummaryBySchool(schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        completionStats {
          completed
          inProgress
          notStarted
        }
      }
    }
  }
`;

/**
 * __useGetCourseCompletionSummaryBySchoolQuery__
 *
 * To run a query within a React component, call `useGetCourseCompletionSummaryBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCompletionSummaryBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCompletionSummaryBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCourseCompletionSummaryBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseCompletionSummaryBySchoolQuery,
    GetCourseCompletionSummaryBySchoolQueryVariables
  > &
    ({ variables: GetCourseCompletionSummaryBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseCompletionSummaryBySchoolQuery, GetCourseCompletionSummaryBySchoolQueryVariables>(
    GetCourseCompletionSummaryBySchoolDocument,
    options
  );
}
export function useGetCourseCompletionSummaryBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseCompletionSummaryBySchoolQuery,
    GetCourseCompletionSummaryBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseCompletionSummaryBySchoolQuery, GetCourseCompletionSummaryBySchoolQueryVariables>(
    GetCourseCompletionSummaryBySchoolDocument,
    options
  );
}
export function useGetCourseCompletionSummaryBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCourseCompletionSummaryBySchoolQuery,
        GetCourseCompletionSummaryBySchoolQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCourseCompletionSummaryBySchoolQuery,
    GetCourseCompletionSummaryBySchoolQueryVariables
  >(GetCourseCompletionSummaryBySchoolDocument, options);
}
export type GetCourseCompletionSummaryBySchoolQueryHookResult = ReturnType<
  typeof useGetCourseCompletionSummaryBySchoolQuery
>;
export type GetCourseCompletionSummaryBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetCourseCompletionSummaryBySchoolLazyQuery
>;
export type GetCourseCompletionSummaryBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetCourseCompletionSummaryBySchoolSuspenseQuery
>;
export type GetCourseCompletionSummaryBySchoolQueryResult = Apollo.QueryResult<
  GetCourseCompletionSummaryBySchoolQuery,
  GetCourseCompletionSummaryBySchoolQueryVariables
>;
export const GetModuleCompletionDetailsDocument = gql`
  query GetModuleCompletionDetails($moduleId: ID!) {
    getModuleCompletionDetails(moduleId: $moduleId) {
      success
      message
      count
      data {
        id
        label
        course_id
        course_name
        completionStats {
          completed
          inProgress
          notStarted
        }
        details {
          activities {
            id
            label
            completionStats {
              completed
              inProgress
              notStarted
            }
            details {
              objectives {
                id
                label
                completionStats {
                  completed
                  inProgress
                  notStarted
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetModuleCompletionDetailsQuery__
 *
 * To run a query within a React component, call `useGetModuleCompletionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleCompletionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleCompletionDetailsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetModuleCompletionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables> &
    ({ variables: GetModuleCompletionDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables>(
    GetModuleCompletionDetailsDocument,
    options
  );
}
export function useGetModuleCompletionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables>(
    GetModuleCompletionDetailsDocument,
    options
  );
}
export function useGetModuleCompletionDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetModuleCompletionDetailsQuery, GetModuleCompletionDetailsQueryVariables>(
    GetModuleCompletionDetailsDocument,
    options
  );
}
export type GetModuleCompletionDetailsQueryHookResult = ReturnType<typeof useGetModuleCompletionDetailsQuery>;
export type GetModuleCompletionDetailsLazyQueryHookResult = ReturnType<typeof useGetModuleCompletionDetailsLazyQuery>;
export type GetModuleCompletionDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetModuleCompletionDetailsSuspenseQuery
>;
export type GetModuleCompletionDetailsQueryResult = Apollo.QueryResult<
  GetModuleCompletionDetailsQuery,
  GetModuleCompletionDetailsQueryVariables
>;
export const GetObjectiveCompletionDetailsDocument = gql`
  query GetObjectiveCompletionDetails($objectiveId: ID!) {
    getObjectiveCompletionDetails(objectiveId: $objectiveId) {
      success
      message
      data {
        id
        label
        aiTutor_id
        aiTutor_name
        module_id
        module_name
        course_id
        course_name
        completionStats {
          completed
          inProgress
          notStarted
        }
      }
      count
    }
  }
`;

/**
 * __useGetObjectiveCompletionDetailsQuery__
 *
 * To run a query within a React component, call `useGetObjectiveCompletionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectiveCompletionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectiveCompletionDetailsQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useGetObjectiveCompletionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObjectiveCompletionDetailsQuery,
    GetObjectiveCompletionDetailsQueryVariables
  > &
    ({ variables: GetObjectiveCompletionDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetObjectiveCompletionDetailsQuery, GetObjectiveCompletionDetailsQueryVariables>(
    GetObjectiveCompletionDetailsDocument,
    options
  );
}
export function useGetObjectiveCompletionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObjectiveCompletionDetailsQuery,
    GetObjectiveCompletionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetObjectiveCompletionDetailsQuery, GetObjectiveCompletionDetailsQueryVariables>(
    GetObjectiveCompletionDetailsDocument,
    options
  );
}
export function useGetObjectiveCompletionDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetObjectiveCompletionDetailsQuery, GetObjectiveCompletionDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetObjectiveCompletionDetailsQuery, GetObjectiveCompletionDetailsQueryVariables>(
    GetObjectiveCompletionDetailsDocument,
    options
  );
}
export type GetObjectiveCompletionDetailsQueryHookResult = ReturnType<typeof useGetObjectiveCompletionDetailsQuery>;
export type GetObjectiveCompletionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetObjectiveCompletionDetailsLazyQuery
>;
export type GetObjectiveCompletionDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetObjectiveCompletionDetailsSuspenseQuery
>;
export type GetObjectiveCompletionDetailsQueryResult = Apollo.QueryResult<
  GetObjectiveCompletionDetailsQuery,
  GetObjectiveCompletionDetailsQueryVariables
>;
export const GetActivityAnalyticsByModuleDocument = gql`
  query GetActivityAnalyticsByModule($moduleId: ID!) {
    getActivityAnalyticsByModule(moduleId: $moduleId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        enrollment
        average_time_taken
        summaries
        success_points_details
        confusion_points_details
        further_recommendations
        details {
          objectives {
            id
            label
            success_points
            confusion_points
            average_time_taken
          }
        }
      }
    }
  }
`;

/**
 * __useGetActivityAnalyticsByModuleQuery__
 *
 * To run a query within a React component, call `useGetActivityAnalyticsByModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityAnalyticsByModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityAnalyticsByModuleQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetActivityAnalyticsByModuleQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityAnalyticsByModuleQuery, GetActivityAnalyticsByModuleQueryVariables> &
    ({ variables: GetActivityAnalyticsByModuleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivityAnalyticsByModuleQuery, GetActivityAnalyticsByModuleQueryVariables>(
    GetActivityAnalyticsByModuleDocument,
    options
  );
}
export function useGetActivityAnalyticsByModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityAnalyticsByModuleQuery,
    GetActivityAnalyticsByModuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivityAnalyticsByModuleQuery, GetActivityAnalyticsByModuleQueryVariables>(
    GetActivityAnalyticsByModuleDocument,
    options
  );
}
export function useGetActivityAnalyticsByModuleSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetActivityAnalyticsByModuleQuery, GetActivityAnalyticsByModuleQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActivityAnalyticsByModuleQuery, GetActivityAnalyticsByModuleQueryVariables>(
    GetActivityAnalyticsByModuleDocument,
    options
  );
}
export type GetActivityAnalyticsByModuleQueryHookResult = ReturnType<typeof useGetActivityAnalyticsByModuleQuery>;
export type GetActivityAnalyticsByModuleLazyQueryHookResult = ReturnType<
  typeof useGetActivityAnalyticsByModuleLazyQuery
>;
export type GetActivityAnalyticsByModuleSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityAnalyticsByModuleSuspenseQuery
>;
export type GetActivityAnalyticsByModuleQueryResult = Apollo.QueryResult<
  GetActivityAnalyticsByModuleQuery,
  GetActivityAnalyticsByModuleQueryVariables
>;
export const GetCourseAnalyticsByIdDocument = gql`
  query GetCourseAnalyticsById($courseId: ID!) {
    getCourseAnalyticsById(courseId: $courseId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        enrollment
        average_time_taken
        details {
          modules {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            enrollment
            average_time_taken
            details {
              activities {
                id
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                enrollment
                average_time_taken
                summaries
                success_points_details
                confusion_points_details
                further_recommendations
                details {
                  objectives {
                    id
                    label
                    success_points
                    confusion_points
                    average_time_taken
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseAnalyticsByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseAnalyticsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseAnalyticsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseAnalyticsByIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseAnalyticsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables> &
    ({ variables: GetCourseAnalyticsByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables>(
    GetCourseAnalyticsByIdDocument,
    options
  );
}
export function useGetCourseAnalyticsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables>(
    GetCourseAnalyticsByIdDocument,
    options
  );
}
export function useGetCourseAnalyticsByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseAnalyticsByIdQuery, GetCourseAnalyticsByIdQueryVariables>(
    GetCourseAnalyticsByIdDocument,
    options
  );
}
export type GetCourseAnalyticsByIdQueryHookResult = ReturnType<typeof useGetCourseAnalyticsByIdQuery>;
export type GetCourseAnalyticsByIdLazyQueryHookResult = ReturnType<typeof useGetCourseAnalyticsByIdLazyQuery>;
export type GetCourseAnalyticsByIdSuspenseQueryHookResult = ReturnType<typeof useGetCourseAnalyticsByIdSuspenseQuery>;
export type GetCourseAnalyticsByIdQueryResult = Apollo.QueryResult<
  GetCourseAnalyticsByIdQuery,
  GetCourseAnalyticsByIdQueryVariables
>;
export const GetCourseHierarchyDocument = gql`
  query GetCourseHierarchy($schoolId: ID!) {
    getCourseHierarchy(schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        level
        details {
          modules {
            id
            label
            level
            parentCourse {
              id
              label
            }
            details {
              activities {
                id
                label
                level
                parentModule {
                  id
                  label
                }
                parentCourse {
                  id
                  label
                }
                details {
                  objectives {
                    id
                    label
                    level
                    parentActivity {
                      id
                      label
                    }
                    parentModule {
                      id
                      label
                    }
                    parentCourse {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseHierarchyQuery__
 *
 * To run a query within a React component, call `useGetCourseHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseHierarchyQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCourseHierarchyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables> &
    ({ variables: GetCourseHierarchyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables>(
    GetCourseHierarchyDocument,
    options
  );
}
export function useGetCourseHierarchyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables>(
    GetCourseHierarchyDocument,
    options
  );
}
export function useGetCourseHierarchySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseHierarchyQuery, GetCourseHierarchyQueryVariables>(
    GetCourseHierarchyDocument,
    options
  );
}
export type GetCourseHierarchyQueryHookResult = ReturnType<typeof useGetCourseHierarchyQuery>;
export type GetCourseHierarchyLazyQueryHookResult = ReturnType<typeof useGetCourseHierarchyLazyQuery>;
export type GetCourseHierarchySuspenseQueryHookResult = ReturnType<typeof useGetCourseHierarchySuspenseQuery>;
export type GetCourseHierarchyQueryResult = Apollo.QueryResult<
  GetCourseHierarchyQuery,
  GetCourseHierarchyQueryVariables
>;
export const GetCoursesSummaryBySchoolDocument = gql`
  query GetCoursesSummaryBySchool($schoolId: ID!) {
    getCoursesSummaryBySchool(schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        enrollment
        average_time_taken
      }
    }
  }
`;

/**
 * __useGetCoursesSummaryBySchoolQuery__
 *
 * To run a query within a React component, call `useGetCoursesSummaryBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesSummaryBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesSummaryBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCoursesSummaryBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables> &
    ({ variables: GetCoursesSummaryBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables>(
    GetCoursesSummaryBySchoolDocument,
    options
  );
}
export function useGetCoursesSummaryBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables>(
    GetCoursesSummaryBySchoolDocument,
    options
  );
}
export function useGetCoursesSummaryBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoursesSummaryBySchoolQuery, GetCoursesSummaryBySchoolQueryVariables>(
    GetCoursesSummaryBySchoolDocument,
    options
  );
}
export type GetCoursesSummaryBySchoolQueryHookResult = ReturnType<typeof useGetCoursesSummaryBySchoolQuery>;
export type GetCoursesSummaryBySchoolLazyQueryHookResult = ReturnType<typeof useGetCoursesSummaryBySchoolLazyQuery>;
export type GetCoursesSummaryBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetCoursesSummaryBySchoolSuspenseQuery
>;
export type GetCoursesSummaryBySchoolQueryResult = Apollo.QueryResult<
  GetCoursesSummaryBySchoolQuery,
  GetCoursesSummaryBySchoolQueryVariables
>;
export const GetModuleAnalyticsByCourseDocument = gql`
  query GetModuleAnalyticsByCourse($courseId: ID!) {
    getModuleAnalyticsByCourse(courseId: $courseId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        enrollment
        average_time_taken
        details {
          activities {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            enrollment
            average_time_taken
          }
        }
      }
    }
  }
`;

/**
 * __useGetModuleAnalyticsByCourseQuery__
 *
 * To run a query within a React component, call `useGetModuleAnalyticsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleAnalyticsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleAnalyticsByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetModuleAnalyticsByCourseQuery(
  baseOptions: Apollo.QueryHookOptions<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables> &
    ({ variables: GetModuleAnalyticsByCourseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables>(
    GetModuleAnalyticsByCourseDocument,
    options
  );
}
export function useGetModuleAnalyticsByCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables>(
    GetModuleAnalyticsByCourseDocument,
    options
  );
}
export function useGetModuleAnalyticsByCourseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetModuleAnalyticsByCourseQuery, GetModuleAnalyticsByCourseQueryVariables>(
    GetModuleAnalyticsByCourseDocument,
    options
  );
}
export type GetModuleAnalyticsByCourseQueryHookResult = ReturnType<typeof useGetModuleAnalyticsByCourseQuery>;
export type GetModuleAnalyticsByCourseLazyQueryHookResult = ReturnType<typeof useGetModuleAnalyticsByCourseLazyQuery>;
export type GetModuleAnalyticsByCourseSuspenseQueryHookResult = ReturnType<
  typeof useGetModuleAnalyticsByCourseSuspenseQuery
>;
export type GetModuleAnalyticsByCourseQueryResult = Apollo.QueryResult<
  GetModuleAnalyticsByCourseQuery,
  GetModuleAnalyticsByCourseQueryVariables
>;
export const GetObjectiveAnalyticsByActivityDocument = gql`
  query GetObjectiveAnalyticsByActivity($aiTutorId: ID!) {
    getObjectiveAnalyticsByActivity(aiTutorId: $aiTutorId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        average_time_taken
      }
    }
  }
`;

/**
 * __useGetObjectiveAnalyticsByActivityQuery__
 *
 * To run a query within a React component, call `useGetObjectiveAnalyticsByActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectiveAnalyticsByActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectiveAnalyticsByActivityQuery({
 *   variables: {
 *      aiTutorId: // value for 'aiTutorId'
 *   },
 * });
 */
export function useGetObjectiveAnalyticsByActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObjectiveAnalyticsByActivityQuery,
    GetObjectiveAnalyticsByActivityQueryVariables
  > &
    ({ variables: GetObjectiveAnalyticsByActivityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetObjectiveAnalyticsByActivityQuery, GetObjectiveAnalyticsByActivityQueryVariables>(
    GetObjectiveAnalyticsByActivityDocument,
    options
  );
}
export function useGetObjectiveAnalyticsByActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObjectiveAnalyticsByActivityQuery,
    GetObjectiveAnalyticsByActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetObjectiveAnalyticsByActivityQuery, GetObjectiveAnalyticsByActivityQueryVariables>(
    GetObjectiveAnalyticsByActivityDocument,
    options
  );
}
export function useGetObjectiveAnalyticsByActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetObjectiveAnalyticsByActivityQuery,
        GetObjectiveAnalyticsByActivityQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetObjectiveAnalyticsByActivityQuery, GetObjectiveAnalyticsByActivityQueryVariables>(
    GetObjectiveAnalyticsByActivityDocument,
    options
  );
}
export type GetObjectiveAnalyticsByActivityQueryHookResult = ReturnType<typeof useGetObjectiveAnalyticsByActivityQuery>;
export type GetObjectiveAnalyticsByActivityLazyQueryHookResult = ReturnType<
  typeof useGetObjectiveAnalyticsByActivityLazyQuery
>;
export type GetObjectiveAnalyticsByActivitySuspenseQueryHookResult = ReturnType<
  typeof useGetObjectiveAnalyticsByActivitySuspenseQuery
>;
export type GetObjectiveAnalyticsByActivityQueryResult = Apollo.QueryResult<
  GetObjectiveAnalyticsByActivityQuery,
  GetObjectiveAnalyticsByActivityQueryVariables
>;
export const GetActivityInsightsDataDocument = gql`
  query GetActivityInsightsData($aiTutorId: ID!) {
    getActivityInsightsData(aiTutorId: $aiTutorId) {
      success
      message
      count
      data {
        id
        label
        success_points_details
        confusion_points_details
        further_recommendations
        average_time_taken
        completion_rate
        student_engagement
        overall_sentiment
      }
    }
  }
`;

/**
 * __useGetActivityInsightsDataQuery__
 *
 * To run a query within a React component, call `useGetActivityInsightsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityInsightsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityInsightsDataQuery({
 *   variables: {
 *      aiTutorId: // value for 'aiTutorId'
 *   },
 * });
 */
export function useGetActivityInsightsDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables> &
    ({ variables: GetActivityInsightsDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables>(
    GetActivityInsightsDataDocument,
    options
  );
}
export function useGetActivityInsightsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables>(
    GetActivityInsightsDataDocument,
    options
  );
}
export function useGetActivityInsightsDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActivityInsightsDataQuery, GetActivityInsightsDataQueryVariables>(
    GetActivityInsightsDataDocument,
    options
  );
}
export type GetActivityInsightsDataQueryHookResult = ReturnType<typeof useGetActivityInsightsDataQuery>;
export type GetActivityInsightsDataLazyQueryHookResult = ReturnType<typeof useGetActivityInsightsDataLazyQuery>;
export type GetActivityInsightsDataSuspenseQueryHookResult = ReturnType<typeof useGetActivityInsightsDataSuspenseQuery>;
export type GetActivityInsightsDataQueryResult = Apollo.QueryResult<
  GetActivityInsightsDataQuery,
  GetActivityInsightsDataQueryVariables
>;
export const GetStudentChatAnalyticsDocument = gql`
  query GetStudentChatAnalytics($studentId: ID!, $schoolId: ID!) {
    getStudentChatAnalytics(studentId: $studentId, schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        courseId
        courseName
        moduleId
        moduleName
        activityId
        activityName
        sentiment_analysis
        originality_analysis
        num_messages
        student_messages_count
        ai_messages_count
        average_student_response_time
        average_ai_response_time
        total_time_taken
      }
    }
  }
`;

/**
 * __useGetStudentChatAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetStudentChatAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentChatAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentChatAnalyticsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentChatAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables> &
    ({ variables: GetStudentChatAnalyticsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables>(
    GetStudentChatAnalyticsDocument,
    options
  );
}
export function useGetStudentChatAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables>(
    GetStudentChatAnalyticsDocument,
    options
  );
}
export function useGetStudentChatAnalyticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentChatAnalyticsQuery, GetStudentChatAnalyticsQueryVariables>(
    GetStudentChatAnalyticsDocument,
    options
  );
}
export type GetStudentChatAnalyticsQueryHookResult = ReturnType<typeof useGetStudentChatAnalyticsQuery>;
export type GetStudentChatAnalyticsLazyQueryHookResult = ReturnType<typeof useGetStudentChatAnalyticsLazyQuery>;
export type GetStudentChatAnalyticsSuspenseQueryHookResult = ReturnType<typeof useGetStudentChatAnalyticsSuspenseQuery>;
export type GetStudentChatAnalyticsQueryResult = Apollo.QueryResult<
  GetStudentChatAnalyticsQuery,
  GetStudentChatAnalyticsQueryVariables
>;
export const GetStudentLearningInsightsDocument = gql`
  query GetStudentLearningInsights($studentId: ID!, $schoolId: ID!) {
    getStudentLearningInsights(studentId: $studentId, schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        success_points_details
        confusion_points_details
        further_recommendations
        total_time_taken
        active_interaction_count
        total_sessions
        questions_asked
      }
    }
  }
`;

/**
 * __useGetStudentLearningInsightsQuery__
 *
 * To run a query within a React component, call `useGetStudentLearningInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentLearningInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentLearningInsightsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentLearningInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables> &
    ({ variables: GetStudentLearningInsightsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables>(
    GetStudentLearningInsightsDocument,
    options
  );
}
export function useGetStudentLearningInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables>(
    GetStudentLearningInsightsDocument,
    options
  );
}
export function useGetStudentLearningInsightsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentLearningInsightsQuery, GetStudentLearningInsightsQueryVariables>(
    GetStudentLearningInsightsDocument,
    options
  );
}
export type GetStudentLearningInsightsQueryHookResult = ReturnType<typeof useGetStudentLearningInsightsQuery>;
export type GetStudentLearningInsightsLazyQueryHookResult = ReturnType<typeof useGetStudentLearningInsightsLazyQuery>;
export type GetStudentLearningInsightsSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentLearningInsightsSuspenseQuery
>;
export type GetStudentLearningInsightsQueryResult = Apollo.QueryResult<
  GetStudentLearningInsightsQuery,
  GetStudentLearningInsightsQueryVariables
>;
export const GetStudentActivityProgressDocument = gql`
  query GetStudentActivityProgress($studentId: ID!, $aiTutorId: ID!) {
    getStudentActivityProgress(studentId: $studentId, aiTutorId: $aiTutorId) {
      success
      message
      count
      data {
        id
        label
        module_id
        module_name
        course_id
        course_name
        student_id
        student_name
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        completion_percentage
        total_time_taken
        chatAnalytics {
          id
          level
          confusion_points
          success_points
          average_student_question_complexity
          further_recommendations
          questions_asked
          distractions
          sentiment_analysis
          originality_analysis
          summary
          num_messages
          student_messages_count
          ai_messages_count
          average_student_response_time
          average_ai_response_time
          total_time_taken
        }
        details {
          objectives {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            status
            progress_details {
              level
              confusion_points
              success_points
              average_student_question_complexity
              further_recommendations
              questions_asked
              distractions
              sentiment_analysis
              originality_analysis
              summary
              num_messages
              student_messages_count
              ai_messages_count
              average_student_response_time
              average_ai_response_time
              total_time_taken
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentActivityProgressQuery__
 *
 * To run a query within a React component, call `useGetStudentActivityProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentActivityProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentActivityProgressQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      aiTutorId: // value for 'aiTutorId'
 *   },
 * });
 */
export function useGetStudentActivityProgressQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables> &
    ({ variables: GetStudentActivityProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables>(
    GetStudentActivityProgressDocument,
    options
  );
}
export function useGetStudentActivityProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables>(
    GetStudentActivityProgressDocument,
    options
  );
}
export function useGetStudentActivityProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentActivityProgressQuery, GetStudentActivityProgressQueryVariables>(
    GetStudentActivityProgressDocument,
    options
  );
}
export type GetStudentActivityProgressQueryHookResult = ReturnType<typeof useGetStudentActivityProgressQuery>;
export type GetStudentActivityProgressLazyQueryHookResult = ReturnType<typeof useGetStudentActivityProgressLazyQuery>;
export type GetStudentActivityProgressSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentActivityProgressSuspenseQuery
>;
export type GetStudentActivityProgressQueryResult = Apollo.QueryResult<
  GetStudentActivityProgressQuery,
  GetStudentActivityProgressQueryVariables
>;
export const GetStudentCourseProgressDocument = gql`
  query GetStudentCourseProgress($studentId: ID!, $courseId: ID!) {
    getStudentCourseProgress(studentId: $studentId, courseId: $courseId) {
      success
      message
      count
      data {
        id
        label
        student_id
        student_name
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        completion_percentage
        total_time_taken
        details {
          modules {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            completion_percentage
            total_time_taken
            details {
              activities {
                id
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                completion_percentage
                total_time_taken
                details {
                  objectives {
                    id
                    label
                    success_points
                    confusion_points
                    questions_asked
                    active_interaction_count
                    distractions
                    status
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentCourseProgressQuery__
 *
 * To run a query within a React component, call `useGetStudentCourseProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentCourseProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentCourseProgressQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetStudentCourseProgressQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables> &
    ({ variables: GetStudentCourseProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables>(
    GetStudentCourseProgressDocument,
    options
  );
}
export function useGetStudentCourseProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables>(
    GetStudentCourseProgressDocument,
    options
  );
}
export function useGetStudentCourseProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentCourseProgressQuery, GetStudentCourseProgressQueryVariables>(
    GetStudentCourseProgressDocument,
    options
  );
}
export type GetStudentCourseProgressQueryHookResult = ReturnType<typeof useGetStudentCourseProgressQuery>;
export type GetStudentCourseProgressLazyQueryHookResult = ReturnType<typeof useGetStudentCourseProgressLazyQuery>;
export type GetStudentCourseProgressSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentCourseProgressSuspenseQuery
>;
export type GetStudentCourseProgressQueryResult = Apollo.QueryResult<
  GetStudentCourseProgressQuery,
  GetStudentCourseProgressQueryVariables
>;
export const GetStudentDetailedAnalyticsDocument = gql`
  query GetStudentDetailedAnalytics($studentId: ID!, $schoolId: ID!) {
    getStudentDetailedAnalytics(studentId: $studentId, schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        completion_percentage
        total_time_taken
        details {
          courses {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            completion_percentage
            total_time_taken
            details {
              modules {
                id
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                completion_percentage
                total_time_taken
                details {
                  aiTutors {
                    id
                    label
                    success_points
                    confusion_points
                    questions_asked
                    active_interaction_count
                    distractions
                    sentiment
                    completion_percentage
                    total_time_taken
                    chatAnalytics {
                      id
                      level
                      confusion_points
                      success_points
                      average_student_question_complexity
                      further_recommendations
                      questions_asked
                      distractions
                      sentiment_analysis
                      originality_analysis
                      summary
                      num_messages
                      student_messages_count
                      ai_messages_count
                      average_student_response_time
                      average_ai_response_time
                      total_time_taken
                    }
                    details {
                      objectives {
                        id
                        label
                        success_points
                        confusion_points
                        questions_asked
                        active_interaction_count
                        distractions
                        status
                        progress_details {
                          level
                          confusion_points
                          success_points
                          average_student_question_complexity
                          further_recommendations
                          questions_asked
                          distractions
                          sentiment_analysis
                          originality_analysis
                          summary
                          num_messages
                          student_messages_count
                          ai_messages_count
                          average_student_response_time
                          average_ai_response_time
                          total_time_taken
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentDetailedAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetStudentDetailedAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentDetailedAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentDetailedAnalyticsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentDetailedAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables> &
    ({ variables: GetStudentDetailedAnalyticsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables>(
    GetStudentDetailedAnalyticsDocument,
    options
  );
}
export function useGetStudentDetailedAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables>(
    GetStudentDetailedAnalyticsDocument,
    options
  );
}
export function useGetStudentDetailedAnalyticsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentDetailedAnalyticsQuery, GetStudentDetailedAnalyticsQueryVariables>(
    GetStudentDetailedAnalyticsDocument,
    options
  );
}
export type GetStudentDetailedAnalyticsQueryHookResult = ReturnType<typeof useGetStudentDetailedAnalyticsQuery>;
export type GetStudentDetailedAnalyticsLazyQueryHookResult = ReturnType<typeof useGetStudentDetailedAnalyticsLazyQuery>;
export type GetStudentDetailedAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentDetailedAnalyticsSuspenseQuery
>;
export type GetStudentDetailedAnalyticsQueryResult = Apollo.QueryResult<
  GetStudentDetailedAnalyticsQuery,
  GetStudentDetailedAnalyticsQueryVariables
>;
export const GetStudentModuleProgressDocument = gql`
  query GetStudentModuleProgress($studentId: ID!, $moduleId: ID!) {
    getStudentModuleProgress(studentId: $studentId, moduleId: $moduleId) {
      success
      message
      count
      data {
        id
        label
        course_id
        course_name
        student_id
        student_name
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        completion_percentage
        total_time_taken
        details {
          activities {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            completion_percentage
            total_time_taken
            details {
              objectives {
                id
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                status
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentModuleProgressQuery__
 *
 * To run a query within a React component, call `useGetStudentModuleProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentModuleProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentModuleProgressQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetStudentModuleProgressQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables> &
    ({ variables: GetStudentModuleProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables>(
    GetStudentModuleProgressDocument,
    options
  );
}
export function useGetStudentModuleProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables>(
    GetStudentModuleProgressDocument,
    options
  );
}
export function useGetStudentModuleProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentModuleProgressQuery, GetStudentModuleProgressQueryVariables>(
    GetStudentModuleProgressDocument,
    options
  );
}
export type GetStudentModuleProgressQueryHookResult = ReturnType<typeof useGetStudentModuleProgressQuery>;
export type GetStudentModuleProgressLazyQueryHookResult = ReturnType<typeof useGetStudentModuleProgressLazyQuery>;
export type GetStudentModuleProgressSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentModuleProgressSuspenseQuery
>;
export type GetStudentModuleProgressQueryResult = Apollo.QueryResult<
  GetStudentModuleProgressQuery,
  GetStudentModuleProgressQueryVariables
>;
export const GetStudentsSummaryBySchoolDocument = gql`
  query GetStudentsSummaryBySchool($schoolId: ID!) {
    getStudentsSummaryBySchool(schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        total_time_taken
      }
    }
  }
`;

/**
 * __useGetStudentsSummaryBySchoolQuery__
 *
 * To run a query within a React component, call `useGetStudentsSummaryBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsSummaryBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsSummaryBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentsSummaryBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables> &
    ({ variables: GetStudentsSummaryBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables>(
    GetStudentsSummaryBySchoolDocument,
    options
  );
}
export function useGetStudentsSummaryBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables>(
    GetStudentsSummaryBySchoolDocument,
    options
  );
}
export function useGetStudentsSummaryBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentsSummaryBySchoolQuery, GetStudentsSummaryBySchoolQueryVariables>(
    GetStudentsSummaryBySchoolDocument,
    options
  );
}
export type GetStudentsSummaryBySchoolQueryHookResult = ReturnType<typeof useGetStudentsSummaryBySchoolQuery>;
export type GetStudentsSummaryBySchoolLazyQueryHookResult = ReturnType<typeof useGetStudentsSummaryBySchoolLazyQuery>;
export type GetStudentsSummaryBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentsSummaryBySchoolSuspenseQuery
>;
export type GetStudentsSummaryBySchoolQueryResult = Apollo.QueryResult<
  GetStudentsSummaryBySchoolQuery,
  GetStudentsSummaryBySchoolQueryVariables
>;
export const GetUserPermissionsDocument = gql`
  query GetUserPermissions {
    getUserPermissions
  }
`;

/**
 * __useGetUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(
    GetUserPermissionsDocument,
    options
  );
}
export function useGetUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(
    GetUserPermissionsDocument,
    options
  );
}
export function useGetUserPermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(
    GetUserPermissionsDocument,
    options
  );
}
export type GetUserPermissionsQueryHookResult = ReturnType<typeof useGetUserPermissionsQuery>;
export type GetUserPermissionsLazyQueryHookResult = ReturnType<typeof useGetUserPermissionsLazyQuery>;
export type GetUserPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetUserPermissionsSuspenseQuery>;
export type GetUserPermissionsQueryResult = Apollo.QueryResult<
  GetUserPermissionsQuery,
  GetUserPermissionsQueryVariables
>;
export const SessionDocument = gql`
  query Session {
    validateSession {
      isValid
    }
  }
`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SessionQuery, SessionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionSuspenseQueryHookResult = ReturnType<typeof useSessionSuspenseQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const CareerByIdDocument = gql`
  query CareerById($id: ID!) {
    getCareerById(id: $id) {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useCareerByIdQuery__
 *
 * To run a query within a React component, call `useCareerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables> &
    ({ variables: CareerByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export type CareerByIdQueryHookResult = ReturnType<typeof useCareerByIdQuery>;
export type CareerByIdLazyQueryHookResult = ReturnType<typeof useCareerByIdLazyQuery>;
export type CareerByIdSuspenseQueryHookResult = ReturnType<typeof useCareerByIdSuspenseQuery>;
export type CareerByIdQueryResult = Apollo.QueryResult<CareerByIdQuery, CareerByIdQueryVariables>;
export const GetCareerHeaderDocument = gql`
  query GetCareerHeader {
    getCareerHeader {
      jobTitle
      careerGoal
      careerName
      careerId
    }
  }
`;

/**
 * __useGetCareerHeaderQuery__
 *
 * To run a query within a React component, call `useGetCareerHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareerHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export type GetCareerHeaderQueryHookResult = ReturnType<typeof useGetCareerHeaderQuery>;
export type GetCareerHeaderLazyQueryHookResult = ReturnType<typeof useGetCareerHeaderLazyQuery>;
export type GetCareerHeaderSuspenseQueryHookResult = ReturnType<typeof useGetCareerHeaderSuspenseQuery>;
export type GetCareerHeaderQueryResult = Apollo.QueryResult<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>;
export const GetCareerTopicsDocument = gql`
  query getCareerTopics($careerId: ID!) {
    getCareerTopics(careerId: $careerId) {
      name
      description
      mastery
    }
  }
`;

/**
 * __useGetCareerTopicsQuery__
 *
 * To run a query within a React component, call `useGetCareerTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerTopicsQuery({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useGetCareerTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables> &
    ({ variables: GetCareerTopicsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export type GetCareerTopicsQueryHookResult = ReturnType<typeof useGetCareerTopicsQuery>;
export type GetCareerTopicsLazyQueryHookResult = ReturnType<typeof useGetCareerTopicsLazyQuery>;
export type GetCareerTopicsSuspenseQueryHookResult = ReturnType<typeof useGetCareerTopicsSuspenseQuery>;
export type GetCareerTopicsQueryResult = Apollo.QueryResult<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>;
export const GetCareersDocument = gql`
  query GetCareers {
    getCareers {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useGetCareersQuery__
 *
 * To run a query within a React component, call `useGetCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareersQuery(baseOptions?: Apollo.QueryHookOptions<GetCareersQuery, GetCareersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export type GetCareersQueryHookResult = ReturnType<typeof useGetCareersQuery>;
export type GetCareersLazyQueryHookResult = ReturnType<typeof useGetCareersLazyQuery>;
export type GetCareersSuspenseQueryHookResult = ReturnType<typeof useGetCareersSuspenseQuery>;
export type GetCareersQueryResult = Apollo.QueryResult<GetCareersQuery, GetCareersQueryVariables>;
export const DailyLimitDocument = gql`
  query DailyLimit {
    getDailyLimit {
      dailyRequests
      result
    }
  }
`;

/**
 * __useDailyLimitQuery__
 *
 * To run a query within a React component, call `useDailyLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyLimitQuery(baseOptions?: Apollo.QueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export type DailyLimitQueryHookResult = ReturnType<typeof useDailyLimitQuery>;
export type DailyLimitLazyQueryHookResult = ReturnType<typeof useDailyLimitLazyQuery>;
export type DailyLimitSuspenseQueryHookResult = ReturnType<typeof useDailyLimitSuspenseQuery>;
export type DailyLimitQueryResult = Apollo.QueryResult<DailyLimitQuery, DailyLimitQueryVariables>;
export const GetChatByContextAndParamsDocument = gql`
  query GetChatByContextAndParams($context: String!, $params: JSON) {
    getChatByContextAndParams(context: $context, params: $params) {
      id
      date
      name
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        file {
          fileName
          id
        }
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByContextAndParamsQuery__
 *
 * To run a query within a React component, call `useGetChatByContextAndParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByContextAndParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByContextAndParamsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetChatByContextAndParamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables> &
    ({ variables: GetChatByContextAndParamsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export type GetChatByContextAndParamsQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsQuery>;
export type GetChatByContextAndParamsLazyQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsLazyQuery>;
export type GetChatByContextAndParamsSuspenseQueryHookResult = ReturnType<
  typeof useGetChatByContextAndParamsSuspenseQuery
>;
export type GetChatByContextAndParamsQueryResult = Apollo.QueryResult<
  GetChatByContextAndParamsQuery,
  GetChatByContextAndParamsQueryVariables
>;
export const GetChatByIdDocument = gql`
  query GetChatById($id: ID!) {
    getChatById(id: $id) {
      id
      date
      name
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        file {
          fileName
          id
        }
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByIdQuery__
 *
 * To run a query within a React component, call `useGetChatByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables> &
    ({ variables: GetChatByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export type GetChatByIdQueryHookResult = ReturnType<typeof useGetChatByIdQuery>;
export type GetChatByIdLazyQueryHookResult = ReturnType<typeof useGetChatByIdLazyQuery>;
export type GetChatByIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByIdSuspenseQuery>;
export type GetChatByIdQueryResult = Apollo.QueryResult<GetChatByIdQuery, GetChatByIdQueryVariables>;
export const GetChatByObjectIdDocument = gql`
  query GetChatByObjectId($objectId: ID!, $context: String!) {
    getChatByObjectId(objectId: $objectId, context: $context) {
      id
      date
      name
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        file {
          fileName
          id
        }
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByObjectIdQuery__
 *
 * To run a query within a React component, call `useGetChatByObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByObjectIdQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetChatByObjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables> &
    ({ variables: GetChatByObjectIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(GetChatByObjectIdDocument, options);
}
export function useGetChatByObjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export function useGetChatByObjectIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export type GetChatByObjectIdQueryHookResult = ReturnType<typeof useGetChatByObjectIdQuery>;
export type GetChatByObjectIdLazyQueryHookResult = ReturnType<typeof useGetChatByObjectIdLazyQuery>;
export type GetChatByObjectIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByObjectIdSuspenseQuery>;
export type GetChatByObjectIdQueryResult = Apollo.QueryResult<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>;
export const GetChatsDocument = gql`
  query GetChats {
    getChats {
      id
      date
      name
      objectId
      context
      params
      url
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsSuspenseQueryHookResult = ReturnType<typeof useGetChatsSuspenseQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const TodayChatDocument = gql`
  query TodayChat($timezone: String!) {
    getTodayChat(timezone: $timezone) {
      id
      date
      name
      timezone
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        file {
          fileName
          id
        }
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useTodayChatQuery__
 *
 * To run a query within a React component, call `useTodayChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayChatQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayChatQuery(
  baseOptions: Apollo.QueryHookOptions<TodayChatQuery, TodayChatQueryVariables> &
    ({ variables: TodayChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export type TodayChatQueryHookResult = ReturnType<typeof useTodayChatQuery>;
export type TodayChatLazyQueryHookResult = ReturnType<typeof useTodayChatLazyQuery>;
export type TodayChatSuspenseQueryHookResult = ReturnType<typeof useTodayChatSuspenseQuery>;
export type TodayChatQueryResult = Apollo.QueryResult<TodayChatQuery, TodayChatQueryVariables>;
export const GetContentByIdDocument = gql`
  query GetContentById($id: ID!) {
    getContentById(id: $id) {
      id
      name
      standard
      weeks
      objectives
      modules {
        module_name
        assignments {
          assignment_name
          html
        }
      }
      created
      last_modified
    }
  }
`;

/**
 * __useGetContentByIdQuery__
 *
 * To run a query within a React component, call `useGetContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables> &
    ({ variables: GetContentByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export function useGetContentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export function useGetContentByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export type GetContentByIdQueryHookResult = ReturnType<typeof useGetContentByIdQuery>;
export type GetContentByIdLazyQueryHookResult = ReturnType<typeof useGetContentByIdLazyQuery>;
export type GetContentByIdSuspenseQueryHookResult = ReturnType<typeof useGetContentByIdSuspenseQuery>;
export type GetContentByIdQueryResult = Apollo.QueryResult<GetContentByIdQuery, GetContentByIdQueryVariables>;
export const GetGeneratedContentDocument = gql`
  query GetGeneratedContent {
    getGeneratedContent {
      id
      name
      standard
      weeks
      objectives
      modules {
        module_name
        assignments {
          assignment_name
          html
        }
      }
      created
      last_modified
    }
  }
`;

/**
 * __useGetGeneratedContentQuery__
 *
 * To run a query within a React component, call `useGetGeneratedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneratedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export function useGetGeneratedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export function useGetGeneratedContentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export type GetGeneratedContentQueryHookResult = ReturnType<typeof useGetGeneratedContentQuery>;
export type GetGeneratedContentLazyQueryHookResult = ReturnType<typeof useGetGeneratedContentLazyQuery>;
export type GetGeneratedContentSuspenseQueryHookResult = ReturnType<typeof useGetGeneratedContentSuspenseQuery>;
export type GetGeneratedContentQueryResult = Apollo.QueryResult<
  GetGeneratedContentQuery,
  GetGeneratedContentQueryVariables
>;
export const GetAllAxioResourcesByCourseDocument = gql`
  query GetAllAxioResourcesByCourse($courseId: ID!) {
    getAllAxioResourcesByCourse(courseId: $courseId) {
      success
      message
      data {
        id
        moduleId
        order
        name
        description
        files {
          id
          fileName
          fileURL
          created
          status
        }
        videoUrl
        created
        lastModified
      }
    }
  }
`;

/**
 * __useGetAllAxioResourcesByCourseQuery__
 *
 * To run a query within a React component, call `useGetAllAxioResourcesByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAxioResourcesByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAxioResourcesByCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetAllAxioResourcesByCourseQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables> &
    ({ variables: GetAllAxioResourcesByCourseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables>(
    GetAllAxioResourcesByCourseDocument,
    options
  );
}
export function useGetAllAxioResourcesByCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables>(
    GetAllAxioResourcesByCourseDocument,
    options
  );
}
export function useGetAllAxioResourcesByCourseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllAxioResourcesByCourseQuery, GetAllAxioResourcesByCourseQueryVariables>(
    GetAllAxioResourcesByCourseDocument,
    options
  );
}
export type GetAllAxioResourcesByCourseQueryHookResult = ReturnType<typeof useGetAllAxioResourcesByCourseQuery>;
export type GetAllAxioResourcesByCourseLazyQueryHookResult = ReturnType<typeof useGetAllAxioResourcesByCourseLazyQuery>;
export type GetAllAxioResourcesByCourseSuspenseQueryHookResult = ReturnType<
  typeof useGetAllAxioResourcesByCourseSuspenseQuery
>;
export type GetAllAxioResourcesByCourseQueryResult = Apollo.QueryResult<
  GetAllAxioResourcesByCourseQuery,
  GetAllAxioResourcesByCourseQueryVariables
>;
export const GetAllCoursesBySchoolDocument = gql`
  query GetAllCoursesBySchool($schoolId: ID!) {
    getAllCoursesBySchool(schoolId: $schoolId) {
      data {
        id
        name
        isDraft
        learningStandards
        lengthInWeeks
        objectives
        last_modified
        thumbnail
        cost
        startDate
        description
        courseType
        targetAudience
      }
    }
  }
`;

/**
 * __useGetAllCoursesBySchoolQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetAllCoursesBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables> &
    ({ variables: GetAllCoursesBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export function useGetAllCoursesBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export function useGetAllCoursesBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export type GetAllCoursesBySchoolQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolQuery>;
export type GetAllCoursesBySchoolLazyQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolLazyQuery>;
export type GetAllCoursesBySchoolSuspenseQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolSuspenseQuery>;
export type GetAllCoursesBySchoolQueryResult = Apollo.QueryResult<
  GetAllCoursesBySchoolQuery,
  GetAllCoursesBySchoolQueryVariables
>;
export const GetAxioResourceDocument = gql`
  query GetAxioResource($id: ID!) {
    getAxioResource(id: $id) {
      success
      message
      data {
        id
        moduleId
        order
        name
        description
        files {
          id
          fileName
          fileURL
          created
          status
        }
        videoUrl
        created
        lastModified
      }
    }
  }
`;

/**
 * __useGetAxioResourceQuery__
 *
 * To run a query within a React component, call `useGetAxioResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAxioResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAxioResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAxioResourceQuery(
  baseOptions: Apollo.QueryHookOptions<GetAxioResourceQuery, GetAxioResourceQueryVariables> &
    ({ variables: GetAxioResourceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAxioResourceQuery, GetAxioResourceQueryVariables>(GetAxioResourceDocument, options);
}
export function useGetAxioResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAxioResourceQuery, GetAxioResourceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAxioResourceQuery, GetAxioResourceQueryVariables>(GetAxioResourceDocument, options);
}
export function useGetAxioResourceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAxioResourceQuery, GetAxioResourceQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAxioResourceQuery, GetAxioResourceQueryVariables>(GetAxioResourceDocument, options);
}
export type GetAxioResourceQueryHookResult = ReturnType<typeof useGetAxioResourceQuery>;
export type GetAxioResourceLazyQueryHookResult = ReturnType<typeof useGetAxioResourceLazyQuery>;
export type GetAxioResourceSuspenseQueryHookResult = ReturnType<typeof useGetAxioResourceSuspenseQuery>;
export type GetAxioResourceQueryResult = Apollo.QueryResult<GetAxioResourceQuery, GetAxioResourceQueryVariables>;
export const GetCompletionAnalyticsBySchoolDocument = gql`
  query GetCompletionAnalyticsBySchool($schoolId: ID!) {
    getCompletionAnalyticsBySchool(schoolId: $schoolId) {
      success
      message
      count
      data {
        label
        completionStats {
          completed
          inProgress
          notStarted
        }
        details {
          modules {
            label
            completionStats {
              completed
              inProgress
              notStarted
            }
            details {
              activities {
                label
                completionStats {
                  completed
                  inProgress
                  notStarted
                }
                details {
                  objectives {
                    label
                    completionStats {
                      completed
                      inProgress
                      notStarted
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompletionAnalyticsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetCompletionAnalyticsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionAnalyticsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionAnalyticsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCompletionAnalyticsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompletionAnalyticsBySchoolQuery,
    GetCompletionAnalyticsBySchoolQueryVariables
  > &
    ({ variables: GetCompletionAnalyticsBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompletionAnalyticsBySchoolQuery, GetCompletionAnalyticsBySchoolQueryVariables>(
    GetCompletionAnalyticsBySchoolDocument,
    options
  );
}
export function useGetCompletionAnalyticsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompletionAnalyticsBySchoolQuery,
    GetCompletionAnalyticsBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompletionAnalyticsBySchoolQuery, GetCompletionAnalyticsBySchoolQueryVariables>(
    GetCompletionAnalyticsBySchoolDocument,
    options
  );
}
export function useGetCompletionAnalyticsBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompletionAnalyticsBySchoolQuery, GetCompletionAnalyticsBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompletionAnalyticsBySchoolQuery, GetCompletionAnalyticsBySchoolQueryVariables>(
    GetCompletionAnalyticsBySchoolDocument,
    options
  );
}
export type GetCompletionAnalyticsBySchoolQueryHookResult = ReturnType<typeof useGetCompletionAnalyticsBySchoolQuery>;
export type GetCompletionAnalyticsBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetCompletionAnalyticsBySchoolLazyQuery
>;
export type GetCompletionAnalyticsBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetCompletionAnalyticsBySchoolSuspenseQuery
>;
export type GetCompletionAnalyticsBySchoolQueryResult = Apollo.QueryResult<
  GetCompletionAnalyticsBySchoolQuery,
  GetCompletionAnalyticsBySchoolQueryVariables
>;
export const GetCoursesAnalyticsBySchoolDocument = gql`
  query GetCoursesAnalyticsBySchool($schoolId: ID!) {
    getCoursesAnalyticsBySchool(schoolId: $schoolId) {
      count
      data {
        label
        id
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        enrollment
        average_time_taken
        details {
          modules {
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            enrollment
            average_time_taken
            details {
              activities {
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                enrollment
                average_time_taken
                summaries
                success_points_details
                confusion_points_details
                further_recommendations
                distractions_details
                details {
                  objectives {
                    label
                    success_points
                    confusion_points
                    average_time_taken
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCoursesAnalyticsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetCoursesAnalyticsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesAnalyticsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesAnalyticsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCoursesAnalyticsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables> &
    ({ variables: GetCoursesAnalyticsBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables>(
    GetCoursesAnalyticsBySchoolDocument,
    options
  );
}
export function useGetCoursesAnalyticsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables>(
    GetCoursesAnalyticsBySchoolDocument,
    options
  );
}
export function useGetCoursesAnalyticsBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoursesAnalyticsBySchoolQuery, GetCoursesAnalyticsBySchoolQueryVariables>(
    GetCoursesAnalyticsBySchoolDocument,
    options
  );
}
export type GetCoursesAnalyticsBySchoolQueryHookResult = ReturnType<typeof useGetCoursesAnalyticsBySchoolQuery>;
export type GetCoursesAnalyticsBySchoolLazyQueryHookResult = ReturnType<typeof useGetCoursesAnalyticsBySchoolLazyQuery>;
export type GetCoursesAnalyticsBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetCoursesAnalyticsBySchoolSuspenseQuery
>;
export type GetCoursesAnalyticsBySchoolQueryResult = Apollo.QueryResult<
  GetCoursesAnalyticsBySchoolQuery,
  GetCoursesAnalyticsBySchoolQueryVariables
>;
export const GetCoursesByDegreeByQueryDocument = gql`
  query GetCoursesByDegreeByQuery($degreeId: ID!, $query: String, $page: Int, $pageSize: Int) {
    getCoursesByDegreeByQuery(degreeId: $degreeId, query: $query, page: $page, pageSize: $pageSize) {
      success
      message
      data {
        courses {
          id
          name
          learningStandards
          lengthInWeeks
          objectives
          thumbnail
          last_modified
          generationStatus
          teachingFaculty {
            id
            name
          }
          enrolledStudents {
            id
            name
          }
          courseType
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetCoursesByDegreeByQueryQuery__
 *
 * To run a query within a React component, call `useGetCoursesByDegreeByQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesByDegreeByQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesByDegreeByQueryQuery({
 *   variables: {
 *      degreeId: // value for 'degreeId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCoursesByDegreeByQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables> &
    ({ variables: GetCoursesByDegreeByQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables>(
    GetCoursesByDegreeByQueryDocument,
    options
  );
}
export function useGetCoursesByDegreeByQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables>(
    GetCoursesByDegreeByQueryDocument,
    options
  );
}
export function useGetCoursesByDegreeByQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoursesByDegreeByQueryQuery, GetCoursesByDegreeByQueryQueryVariables>(
    GetCoursesByDegreeByQueryDocument,
    options
  );
}
export type GetCoursesByDegreeByQueryQueryHookResult = ReturnType<typeof useGetCoursesByDegreeByQueryQuery>;
export type GetCoursesByDegreeByQueryLazyQueryHookResult = ReturnType<typeof useGetCoursesByDegreeByQueryLazyQuery>;
export type GetCoursesByDegreeByQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetCoursesByDegreeByQuerySuspenseQuery
>;
export type GetCoursesByDegreeByQueryQueryResult = Apollo.QueryResult<
  GetCoursesByDegreeByQueryQuery,
  GetCoursesByDegreeByQueryQueryVariables
>;
export const GetCoursesByFacultyDocument = gql`
  query GetCoursesByFaculty($userId: ID!, $query: String) {
    getCoursesByFaculty(userId: $userId, query: $query) {
      data {
        id
        name
        modules {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useGetCoursesByFacultyQuery__
 *
 * To run a query within a React component, call `useGetCoursesByFacultyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesByFacultyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesByFacultyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetCoursesByFacultyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables> &
    ({ variables: GetCoursesByFacultyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables>(
    GetCoursesByFacultyDocument,
    options
  );
}
export function useGetCoursesByFacultyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables>(
    GetCoursesByFacultyDocument,
    options
  );
}
export function useGetCoursesByFacultySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoursesByFacultyQuery, GetCoursesByFacultyQueryVariables>(
    GetCoursesByFacultyDocument,
    options
  );
}
export type GetCoursesByFacultyQueryHookResult = ReturnType<typeof useGetCoursesByFacultyQuery>;
export type GetCoursesByFacultyLazyQueryHookResult = ReturnType<typeof useGetCoursesByFacultyLazyQuery>;
export type GetCoursesByFacultySuspenseQueryHookResult = ReturnType<typeof useGetCoursesByFacultySuspenseQuery>;
export type GetCoursesByFacultyQueryResult = Apollo.QueryResult<
  GetCoursesByFacultyQuery,
  GetCoursesByFacultyQueryVariables
>;
export const GetCoursesBySchoolByQueryDocument = gql`
  query GetCoursesBySchoolByQuery($schoolId: ID!, $query: String, $page: Int, $pageSize: Int) {
    getCoursesBySchoolByQuery(schoolId: $schoolId, query: $query, page: $page, pageSize: $pageSize) {
      success
      message
      data {
        courses {
          id
          name
          learningStandards
          lengthInWeeks
          objectives
          thumbnail
          last_modified
          generationStatus
          teachingFaculty {
            id
            name
          }
          enrolledStudents {
            id
            name
          }
          degreesHasCourse {
            id
            name
          }
          courseType
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetCoursesBySchoolByQueryQuery__
 *
 * To run a query within a React component, call `useGetCoursesBySchoolByQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesBySchoolByQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesBySchoolByQueryQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCoursesBySchoolByQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables> &
    ({ variables: GetCoursesBySchoolByQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables>(
    GetCoursesBySchoolByQueryDocument,
    options
  );
}
export function useGetCoursesBySchoolByQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables>(
    GetCoursesBySchoolByQueryDocument,
    options
  );
}
export function useGetCoursesBySchoolByQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoursesBySchoolByQueryQuery, GetCoursesBySchoolByQueryQueryVariables>(
    GetCoursesBySchoolByQueryDocument,
    options
  );
}
export type GetCoursesBySchoolByQueryQueryHookResult = ReturnType<typeof useGetCoursesBySchoolByQueryQuery>;
export type GetCoursesBySchoolByQueryLazyQueryHookResult = ReturnType<typeof useGetCoursesBySchoolByQueryLazyQuery>;
export type GetCoursesBySchoolByQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetCoursesBySchoolByQuerySuspenseQuery
>;
export type GetCoursesBySchoolByQueryQueryResult = Apollo.QueryResult<
  GetCoursesBySchoolByQueryQuery,
  GetCoursesBySchoolByQueryQueryVariables
>;
export const GetEnrolledCoursesBySchoolDocument = gql`
  query GetEnrolledCoursesBySchool($schoolId: ID!) {
    getEnrolledCoursesBySchool(schoolId: $schoolId) {
      data {
        id
        name
        isDraft
        learningStandards
        lengthInWeeks
        objectives
        last_modified
        thumbnail
        cost
        startDate
        description
        courseType
        targetAudience
        generationStatus
      }
    }
  }
`;

/**
 * __useGetEnrolledCoursesBySchoolQuery__
 *
 * To run a query within a React component, call `useGetEnrolledCoursesBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrolledCoursesBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrolledCoursesBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetEnrolledCoursesBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables> &
    ({ variables: GetEnrolledCoursesBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables>(
    GetEnrolledCoursesBySchoolDocument,
    options
  );
}
export function useGetEnrolledCoursesBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables>(
    GetEnrolledCoursesBySchoolDocument,
    options
  );
}
export function useGetEnrolledCoursesBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetEnrolledCoursesBySchoolQuery, GetEnrolledCoursesBySchoolQueryVariables>(
    GetEnrolledCoursesBySchoolDocument,
    options
  );
}
export type GetEnrolledCoursesBySchoolQueryHookResult = ReturnType<typeof useGetEnrolledCoursesBySchoolQuery>;
export type GetEnrolledCoursesBySchoolLazyQueryHookResult = ReturnType<typeof useGetEnrolledCoursesBySchoolLazyQuery>;
export type GetEnrolledCoursesBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetEnrolledCoursesBySchoolSuspenseQuery
>;
export type GetEnrolledCoursesBySchoolQueryResult = Apollo.QueryResult<
  GetEnrolledCoursesBySchoolQuery,
  GetEnrolledCoursesBySchoolQueryVariables
>;
export const GetFacultyByCourseIdDocument = gql`
  query GetFacultyByCourseId($courseId: ID!, $query: String) {
    getFacultyByCourseId(courseId: $courseId, query: $query) {
      data {
        faculties {
          id
          name
          profilePicture
          email
          meetingLink
          biography
        }
        totalCount
      }
    }
  }
`;

/**
 * __useGetFacultyByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetFacultyByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacultyByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacultyByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetFacultyByCourseIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables> &
    ({ variables: GetFacultyByCourseIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables>(
    GetFacultyByCourseIdDocument,
    options
  );
}
export function useGetFacultyByCourseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables>(
    GetFacultyByCourseIdDocument,
    options
  );
}
export function useGetFacultyByCourseIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFacultyByCourseIdQuery, GetFacultyByCourseIdQueryVariables>(
    GetFacultyByCourseIdDocument,
    options
  );
}
export type GetFacultyByCourseIdQueryHookResult = ReturnType<typeof useGetFacultyByCourseIdQuery>;
export type GetFacultyByCourseIdLazyQueryHookResult = ReturnType<typeof useGetFacultyByCourseIdLazyQuery>;
export type GetFacultyByCourseIdSuspenseQueryHookResult = ReturnType<typeof useGetFacultyByCourseIdSuspenseQuery>;
export type GetFacultyByCourseIdQueryResult = Apollo.QueryResult<
  GetFacultyByCourseIdQuery,
  GetFacultyByCourseIdQueryVariables
>;
export const GetNotEnrolledCoursesBySchoolDocument = gql`
  query GetNotEnrolledCoursesBySchool($schoolId: ID!) {
    getNotEnrolledCoursesBySchool(schoolId: $schoolId) {
      data {
        id
        name
        isDraft
        learningStandards
        lengthInWeeks
        objectives
        last_modified
        thumbnail
        cost
        startDate
        description
        courseType
        targetAudience
        generationStatus
      }
    }
  }
`;

/**
 * __useGetNotEnrolledCoursesBySchoolQuery__
 *
 * To run a query within a React component, call `useGetNotEnrolledCoursesBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotEnrolledCoursesBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotEnrolledCoursesBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetNotEnrolledCoursesBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotEnrolledCoursesBySchoolQuery,
    GetNotEnrolledCoursesBySchoolQueryVariables
  > &
    ({ variables: GetNotEnrolledCoursesBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotEnrolledCoursesBySchoolQuery, GetNotEnrolledCoursesBySchoolQueryVariables>(
    GetNotEnrolledCoursesBySchoolDocument,
    options
  );
}
export function useGetNotEnrolledCoursesBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotEnrolledCoursesBySchoolQuery,
    GetNotEnrolledCoursesBySchoolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotEnrolledCoursesBySchoolQuery, GetNotEnrolledCoursesBySchoolQueryVariables>(
    GetNotEnrolledCoursesBySchoolDocument,
    options
  );
}
export function useGetNotEnrolledCoursesBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetNotEnrolledCoursesBySchoolQuery, GetNotEnrolledCoursesBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNotEnrolledCoursesBySchoolQuery, GetNotEnrolledCoursesBySchoolQueryVariables>(
    GetNotEnrolledCoursesBySchoolDocument,
    options
  );
}
export type GetNotEnrolledCoursesBySchoolQueryHookResult = ReturnType<typeof useGetNotEnrolledCoursesBySchoolQuery>;
export type GetNotEnrolledCoursesBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetNotEnrolledCoursesBySchoolLazyQuery
>;
export type GetNotEnrolledCoursesBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetNotEnrolledCoursesBySchoolSuspenseQuery
>;
export type GetNotEnrolledCoursesBySchoolQueryResult = Apollo.QueryResult<
  GetNotEnrolledCoursesBySchoolQuery,
  GetNotEnrolledCoursesBySchoolQueryVariables
>;
export const GetStudentAnalyticsBySchoolDocument = gql`
  query GetStudentAnalyticsBySchool($schoolId: ID!) {
    getStudentAnalyticsBySchool(schoolId: $schoolId) {
      success
      message
      count
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        details {
          courses {
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            details {
              modules {
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                details {
                  aiTutors {
                    label
                    success_points
                    confusion_points
                    questions_asked
                    active_interaction_count
                    distractions
                    sentiment
                    details {
                      objectives {
                        label
                        success_points
                        confusion_points
                        questions_asked
                        active_interaction_count
                        distractions
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentAnalyticsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetStudentAnalyticsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAnalyticsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAnalyticsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentAnalyticsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables> &
    ({ variables: GetStudentAnalyticsBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables>(
    GetStudentAnalyticsBySchoolDocument,
    options
  );
}
export function useGetStudentAnalyticsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables>(
    GetStudentAnalyticsBySchoolDocument,
    options
  );
}
export function useGetStudentAnalyticsBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentAnalyticsBySchoolQuery, GetStudentAnalyticsBySchoolQueryVariables>(
    GetStudentAnalyticsBySchoolDocument,
    options
  );
}
export type GetStudentAnalyticsBySchoolQueryHookResult = ReturnType<typeof useGetStudentAnalyticsBySchoolQuery>;
export type GetStudentAnalyticsBySchoolLazyQueryHookResult = ReturnType<typeof useGetStudentAnalyticsBySchoolLazyQuery>;
export type GetStudentAnalyticsBySchoolSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentAnalyticsBySchoolSuspenseQuery
>;
export type GetStudentAnalyticsBySchoolQueryResult = Apollo.QueryResult<
  GetStudentAnalyticsBySchoolQuery,
  GetStudentAnalyticsBySchoolQueryVariables
>;
export const GetStudentAnalyticsForStudentDocument = gql`
  query GetStudentAnalyticsForStudent($schoolId: ID!, $studentId: ID!) {
    getStudentAnalyticsForStudent(schoolId: $schoolId, studentId: $studentId) {
      success
      message
      data {
        id
        label
        success_points
        confusion_points
        questions_asked
        active_interaction_count
        distractions
        sentiment
        completion_percentage
        total_time_taken
        details {
          courses {
            id
            label
            success_points
            confusion_points
            questions_asked
            active_interaction_count
            distractions
            sentiment
            completion_percentage
            total_time_taken
            details {
              modules {
                id
                label
                success_points
                confusion_points
                questions_asked
                active_interaction_count
                distractions
                sentiment
                completion_percentage
                total_time_taken
                details {
                  aiTutors {
                    id
                    label
                    success_points
                    confusion_points
                    questions_asked
                    active_interaction_count
                    distractions
                    sentiment
                    completion_percentage
                    total_time_taken
                    details {
                      objectives {
                        id
                        label
                        success_points
                        confusion_points
                        questions_asked
                        active_interaction_count
                        distractions
                        status
                        progress_details {
                          level
                          confusion_points
                          success_points
                          average_student_question_complexity
                          further_recommendations
                          questions_asked
                          distractions
                          sentiment_analysis
                          originality_analysis
                          summary
                          num_messages
                          student_messages_count
                          ai_messages_count
                          average_student_response_time
                          average_ai_response_time
                          total_time_taken
                        }
                      }
                    }
                    chatAnalytics {
                      id
                      level
                      confusion_points
                      success_points
                      average_student_question_complexity
                      further_recommendations
                      questions_asked
                      distractions
                      sentiment_analysis
                      originality_analysis
                      summary
                      num_messages
                      student_messages_count
                      ai_messages_count
                      average_student_response_time
                      average_ai_response_time
                      total_time_taken
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetStudentAnalyticsForStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentAnalyticsForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAnalyticsForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAnalyticsForStudentQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentAnalyticsForStudentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStudentAnalyticsForStudentQuery,
    GetStudentAnalyticsForStudentQueryVariables
  > &
    ({ variables: GetStudentAnalyticsForStudentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentAnalyticsForStudentQuery, GetStudentAnalyticsForStudentQueryVariables>(
    GetStudentAnalyticsForStudentDocument,
    options
  );
}
export function useGetStudentAnalyticsForStudentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStudentAnalyticsForStudentQuery,
    GetStudentAnalyticsForStudentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentAnalyticsForStudentQuery, GetStudentAnalyticsForStudentQueryVariables>(
    GetStudentAnalyticsForStudentDocument,
    options
  );
}
export function useGetStudentAnalyticsForStudentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentAnalyticsForStudentQuery, GetStudentAnalyticsForStudentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentAnalyticsForStudentQuery, GetStudentAnalyticsForStudentQueryVariables>(
    GetStudentAnalyticsForStudentDocument,
    options
  );
}
export type GetStudentAnalyticsForStudentQueryHookResult = ReturnType<typeof useGetStudentAnalyticsForStudentQuery>;
export type GetStudentAnalyticsForStudentLazyQueryHookResult = ReturnType<
  typeof useGetStudentAnalyticsForStudentLazyQuery
>;
export type GetStudentAnalyticsForStudentSuspenseQueryHookResult = ReturnType<
  typeof useGetStudentAnalyticsForStudentSuspenseQuery
>;
export type GetStudentAnalyticsForStudentQueryResult = Apollo.QueryResult<
  GetStudentAnalyticsForStudentQuery,
  GetStudentAnalyticsForStudentQueryVariables
>;
export const GetStudentsByCourseIdDocument = gql`
  query GetStudentsByCourseId($courseId: ID!, $query: String, $page: Int, $pageSize: Int) {
    getStudentsByCourseId(courseId: $courseId, query: $query, page: $page, pageSize: $pageSize) {
      success
      data {
        id
        name
        email
        pendingCourseEnrollmentInvite
        enrollmentDate
      }
      count
    }
  }
`;

/**
 * __useGetStudentsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetStudentsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByCourseIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetStudentsByCourseIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables> &
    ({ variables: GetStudentsByCourseIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables>(
    GetStudentsByCourseIdDocument,
    options
  );
}
export function useGetStudentsByCourseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables>(
    GetStudentsByCourseIdDocument,
    options
  );
}
export function useGetStudentsByCourseIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStudentsByCourseIdQuery, GetStudentsByCourseIdQueryVariables>(
    GetStudentsByCourseIdDocument,
    options
  );
}
export type GetStudentsByCourseIdQueryHookResult = ReturnType<typeof useGetStudentsByCourseIdQuery>;
export type GetStudentsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetStudentsByCourseIdLazyQuery>;
export type GetStudentsByCourseIdSuspenseQueryHookResult = ReturnType<typeof useGetStudentsByCourseIdSuspenseQuery>;
export type GetStudentsByCourseIdQueryResult = Apollo.QueryResult<
  GetStudentsByCourseIdQuery,
  GetStudentsByCourseIdQueryVariables
>;
export const GetAiTutorDocument = gql`
  query getAITutor($id: ID!) {
    getAITutor(id: $id) {
      data {
        id
        title
        description
        created
        lastModified
        enableVideoInsight
        hasChatChats {
          id
        }
        objectives {
          id
          objective
          sequence
          evalCriteria
          status
        }
      }
    }
  }
`;

/**
 * __useGetAiTutorQuery__
 *
 * To run a query within a React component, call `useGetAiTutorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiTutorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiTutorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAiTutorQuery(
  baseOptions: Apollo.QueryHookOptions<GetAiTutorQuery, GetAiTutorQueryVariables> &
    ({ variables: GetAiTutorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAiTutorQuery, GetAiTutorQueryVariables>(GetAiTutorDocument, options);
}
export function useGetAiTutorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAiTutorQuery, GetAiTutorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAiTutorQuery, GetAiTutorQueryVariables>(GetAiTutorDocument, options);
}
export function useGetAiTutorSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAiTutorQuery, GetAiTutorQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAiTutorQuery, GetAiTutorQueryVariables>(GetAiTutorDocument, options);
}
export type GetAiTutorQueryHookResult = ReturnType<typeof useGetAiTutorQuery>;
export type GetAiTutorLazyQueryHookResult = ReturnType<typeof useGetAiTutorLazyQuery>;
export type GetAiTutorSuspenseQueryHookResult = ReturnType<typeof useGetAiTutorSuspenseQuery>;
export type GetAiTutorQueryResult = Apollo.QueryResult<GetAiTutorQuery, GetAiTutorQueryVariables>;
export const GetAllAiTutorsByCourseDocument = gql`
  query GetAllAiTutorsByCourse($courseIds: [ID!]!) {
    getAllAITutorsByCourse(courseIds: $courseIds) {
      data {
        id
        title
        visualization {
          id
          code
          visualizationType
        }
        objectives {
          id
          objective
          status
          evalCriteria
        }
        courseId
      }
    }
  }
`;

/**
 * __useGetAllAiTutorsByCourseQuery__
 *
 * To run a query within a React component, call `useGetAllAiTutorsByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAiTutorsByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAiTutorsByCourseQuery({
 *   variables: {
 *      courseIds: // value for 'courseIds'
 *   },
 * });
 */
export function useGetAllAiTutorsByCourseQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables> &
    ({ variables: GetAllAiTutorsByCourseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables>(
    GetAllAiTutorsByCourseDocument,
    options
  );
}
export function useGetAllAiTutorsByCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables>(
    GetAllAiTutorsByCourseDocument,
    options
  );
}
export function useGetAllAiTutorsByCourseSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllAiTutorsByCourseQuery, GetAllAiTutorsByCourseQueryVariables>(
    GetAllAiTutorsByCourseDocument,
    options
  );
}
export type GetAllAiTutorsByCourseQueryHookResult = ReturnType<typeof useGetAllAiTutorsByCourseQuery>;
export type GetAllAiTutorsByCourseLazyQueryHookResult = ReturnType<typeof useGetAllAiTutorsByCourseLazyQuery>;
export type GetAllAiTutorsByCourseSuspenseQueryHookResult = ReturnType<typeof useGetAllAiTutorsByCourseSuspenseQuery>;
export type GetAllAiTutorsByCourseQueryResult = Apollo.QueryResult<
  GetAllAiTutorsByCourseQuery,
  GetAllAiTutorsByCourseQueryVariables
>;
export const GetAssessmentDocument = gql`
  query getAssessment($assessmentId: ID!) {
    getAssessment(assessmentId: $assessmentId) {
      data {
        id
        created
        lastModified
        isEnabled
        moduleHasAssessment {
          id
          title
        }
        userHasAssessmentConnection {
          edges {
            properties {
              isCompleted
              remainingTime
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssessmentQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables> &
    ({ variables: GetAssessmentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
}
export function useGetAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
}
export function useGetAssessmentSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
}
export type GetAssessmentQueryHookResult = ReturnType<typeof useGetAssessmentQuery>;
export type GetAssessmentLazyQueryHookResult = ReturnType<typeof useGetAssessmentLazyQuery>;
export type GetAssessmentSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentSuspenseQuery>;
export type GetAssessmentQueryResult = Apollo.QueryResult<GetAssessmentQuery, GetAssessmentQueryVariables>;
export const GetAssessmentByFacultyDocument = gql`
  query GetAssessmentByFaculty($input: GetAssessmentByFacultyInput!) {
    getAssessmentByFaculty(input: $input) {
      success
      data {
        assessments {
          id
          created
          lastModified
          moduleName
          courseName
          userName
          isCompleted
          completedAt
          grade
          hasTeacherGraded
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetAssessmentByFacultyQuery__
 *
 * To run a query within a React component, call `useGetAssessmentByFacultyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentByFacultyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentByFacultyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentByFacultyQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables> &
    ({ variables: GetAssessmentByFacultyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables>(
    GetAssessmentByFacultyDocument,
    options
  );
}
export function useGetAssessmentByFacultyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables>(
    GetAssessmentByFacultyDocument,
    options
  );
}
export function useGetAssessmentByFacultySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAssessmentByFacultyQuery, GetAssessmentByFacultyQueryVariables>(
    GetAssessmentByFacultyDocument,
    options
  );
}
export type GetAssessmentByFacultyQueryHookResult = ReturnType<typeof useGetAssessmentByFacultyQuery>;
export type GetAssessmentByFacultyLazyQueryHookResult = ReturnType<typeof useGetAssessmentByFacultyLazyQuery>;
export type GetAssessmentByFacultySuspenseQueryHookResult = ReturnType<typeof useGetAssessmentByFacultySuspenseQuery>;
export type GetAssessmentByFacultyQueryResult = Apollo.QueryResult<
  GetAssessmentByFacultyQuery,
  GetAssessmentByFacultyQueryVariables
>;
export const GetAssignmentDocument = gql`
  query getAssignment($id: ID!) {
    getAssignment(id: $id) {
      success
      message
      data {
        id
        name
        description
        due_date
        enableStudentFileUpload
        files {
          id
          fileName
          fileURL
          created
          status
        }
        videoUrl
        created
        lastModified
      }
    }
  }
`;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables> &
    ({ variables: GetAssignmentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
}
export function useGetAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
}
export function useGetAssignmentSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
}
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentSuspenseQuery>;
export type GetAssignmentQueryResult = Apollo.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const GetAssignmentAndSubmissionBySubmissionIdDocument = gql`
  query getAssignmentAndSubmissionBySubmissionId($submissionId: ID!) {
    getAssignmentAndSubmissionBySubmissionId(submissionId: $submissionId) {
      success
      data {
        assignment {
          id
          name
          description
          due_date
        }
        submission {
          id
          created
          lastModified
          grade
          isCompleted
          completedAt
          feedback
          hasTeacherGraded
          files {
            id
            fileName
            fileURL
            created
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssignmentAndSubmissionBySubmissionIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentAndSubmissionBySubmissionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentAndSubmissionBySubmissionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentAndSubmissionBySubmissionIdQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useGetAssignmentAndSubmissionBySubmissionIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignmentAndSubmissionBySubmissionIdQuery,
    GetAssignmentAndSubmissionBySubmissionIdQueryVariables
  > &
    ({ variables: GetAssignmentAndSubmissionBySubmissionIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignmentAndSubmissionBySubmissionIdQuery,
    GetAssignmentAndSubmissionBySubmissionIdQueryVariables
  >(GetAssignmentAndSubmissionBySubmissionIdDocument, options);
}
export function useGetAssignmentAndSubmissionBySubmissionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignmentAndSubmissionBySubmissionIdQuery,
    GetAssignmentAndSubmissionBySubmissionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignmentAndSubmissionBySubmissionIdQuery,
    GetAssignmentAndSubmissionBySubmissionIdQueryVariables
  >(GetAssignmentAndSubmissionBySubmissionIdDocument, options);
}
export function useGetAssignmentAndSubmissionBySubmissionIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetAssignmentAndSubmissionBySubmissionIdQuery,
        GetAssignmentAndSubmissionBySubmissionIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAssignmentAndSubmissionBySubmissionIdQuery,
    GetAssignmentAndSubmissionBySubmissionIdQueryVariables
  >(GetAssignmentAndSubmissionBySubmissionIdDocument, options);
}
export type GetAssignmentAndSubmissionBySubmissionIdQueryHookResult = ReturnType<
  typeof useGetAssignmentAndSubmissionBySubmissionIdQuery
>;
export type GetAssignmentAndSubmissionBySubmissionIdLazyQueryHookResult = ReturnType<
  typeof useGetAssignmentAndSubmissionBySubmissionIdLazyQuery
>;
export type GetAssignmentAndSubmissionBySubmissionIdSuspenseQueryHookResult = ReturnType<
  typeof useGetAssignmentAndSubmissionBySubmissionIdSuspenseQuery
>;
export type GetAssignmentAndSubmissionBySubmissionIdQueryResult = Apollo.QueryResult<
  GetAssignmentAndSubmissionBySubmissionIdQuery,
  GetAssignmentAndSubmissionBySubmissionIdQueryVariables
>;
export const GetCoursesForTranscriptGenerationDocument = gql`
  query GetCoursesForTranscriptGeneration($schoolId: ID!) {
    getCoursesForTranscriptGeneration(schoolId: $schoolId) {
      success
      message
      data {
        id
        name
        modules {
          title
          aiTutors {
            title
            id
            hasChatChats {
              id
              date
              name
              context
              params
              hasMessageMessages {
                id
                content
                index
                parameters
                aspectDetails
                sender
                created
                snackType
                type
              }
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useGetCoursesForTranscriptGenerationQuery__
 *
 * To run a query within a React component, call `useGetCoursesForTranscriptGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForTranscriptGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForTranscriptGenerationQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetCoursesForTranscriptGenerationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoursesForTranscriptGenerationQuery,
    GetCoursesForTranscriptGenerationQueryVariables
  > &
    ({ variables: GetCoursesForTranscriptGenerationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoursesForTranscriptGenerationQuery, GetCoursesForTranscriptGenerationQueryVariables>(
    GetCoursesForTranscriptGenerationDocument,
    options
  );
}
export function useGetCoursesForTranscriptGenerationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoursesForTranscriptGenerationQuery,
    GetCoursesForTranscriptGenerationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoursesForTranscriptGenerationQuery, GetCoursesForTranscriptGenerationQueryVariables>(
    GetCoursesForTranscriptGenerationDocument,
    options
  );
}
export function useGetCoursesForTranscriptGenerationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCoursesForTranscriptGenerationQuery,
        GetCoursesForTranscriptGenerationQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCoursesForTranscriptGenerationQuery,
    GetCoursesForTranscriptGenerationQueryVariables
  >(GetCoursesForTranscriptGenerationDocument, options);
}
export type GetCoursesForTranscriptGenerationQueryHookResult = ReturnType<
  typeof useGetCoursesForTranscriptGenerationQuery
>;
export type GetCoursesForTranscriptGenerationLazyQueryHookResult = ReturnType<
  typeof useGetCoursesForTranscriptGenerationLazyQuery
>;
export type GetCoursesForTranscriptGenerationSuspenseQueryHookResult = ReturnType<
  typeof useGetCoursesForTranscriptGenerationSuspenseQuery
>;
export type GetCoursesForTranscriptGenerationQueryResult = Apollo.QueryResult<
  GetCoursesForTranscriptGenerationQuery,
  GetCoursesForTranscriptGenerationQueryVariables
>;
export const GetCourseByIdDocument = gql`
  query GetCourseById($id: ID!) {
    getCourseById(id: $id) {
      data {
        id
        name
        isDraft
        learningStandards
        lengthInWeeks
        objectives
        thumbnail
        last_modified
        modules {
          id
          title
          order
          lastModified
          resourceOrder
          aiTutors {
            id
            title
            description
            lastModified
            visualization {
              id
              code
              visualizationType
            }
            objectives {
              id
              objective
              sequence
              evalCriteria
              videos {
                id
                videoId
                title
                url
                thumbnailUrl
                fullTranscript
                isTeacherAdded
                created
                lastModified
              }
              userHasProgressConnection {
                edges {
                  properties {
                    status
                  }
                }
              }
            }
          }
          pages {
            id
            title
            body
            lastModified
          }
          assessment {
            id
            isEnabled
            userHasAssessmentConnection {
              edges {
                properties {
                  isCompleted
                  remainingTime
                }
              }
            }
          }
          axioResources {
            id
            name
            description
            files {
              id
              fileName
              fileURL
              created
              status
            }
            videoUrl
            order
            created
            lastModified
          }
          assignments {
            id
            name
            description
            due_date
            enableStudentFileUpload
            files {
              id
              fileName
              fileURL
              created
            }
            videoUrl
            created
            lastModified
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables> &
    ({ variables: GetCourseByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, options);
}
export function useGetCourseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, options);
}
export function useGetCourseByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(GetCourseByIdDocument, options);
}
export type GetCourseByIdQueryHookResult = ReturnType<typeof useGetCourseByIdQuery>;
export type GetCourseByIdLazyQueryHookResult = ReturnType<typeof useGetCourseByIdLazyQuery>;
export type GetCourseByIdSuspenseQueryHookResult = ReturnType<typeof useGetCourseByIdSuspenseQuery>;
export type GetCourseByIdQueryResult = Apollo.QueryResult<GetCourseByIdQuery, GetCourseByIdQueryVariables>;
export const GetUserSubmissionsDocument = gql`
  query getUserSubmissions($assignmentId: ID!) {
    getUserSubmissions(assignmentId: $assignmentId) {
      success
      message
      data {
        id
        isCompleted
        files {
          id
          fileName
          fileURL
          created
          status
        }
      }
    }
  }
`;

/**
 * __useGetUserSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubmissionsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetUserSubmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables> &
    ({ variables: GetUserSubmissionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(
    GetUserSubmissionsDocument,
    options
  );
}
export function useGetUserSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(
    GetUserSubmissionsDocument,
    options
  );
}
export function useGetUserSubmissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserSubmissionsQuery, GetUserSubmissionsQueryVariables>(
    GetUserSubmissionsDocument,
    options
  );
}
export type GetUserSubmissionsQueryHookResult = ReturnType<typeof useGetUserSubmissionsQuery>;
export type GetUserSubmissionsLazyQueryHookResult = ReturnType<typeof useGetUserSubmissionsLazyQuery>;
export type GetUserSubmissionsSuspenseQueryHookResult = ReturnType<typeof useGetUserSubmissionsSuspenseQuery>;
export type GetUserSubmissionsQueryResult = Apollo.QueryResult<
  GetUserSubmissionsQuery,
  GetUserSubmissionsQueryVariables
>;
export const GetUserSubmissionsByFacultyDocument = gql`
  query GetUserSubmissionsByFaculty($input: GetUserSubmissionsByFacultyInput!) {
    getUserSubmissionsByFaculty(input: $input) {
      success
      data {
        submissions {
          id
          created
          lastModified
          moduleName
          courseName
          userName
          isCompleted
          completedAt
          grade
          hasTeacherGraded
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetUserSubmissionsByFacultyQuery__
 *
 * To run a query within a React component, call `useGetUserSubmissionsByFacultyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubmissionsByFacultyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubmissionsByFacultyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserSubmissionsByFacultyQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables> &
    ({ variables: GetUserSubmissionsByFacultyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables>(
    GetUserSubmissionsByFacultyDocument,
    options
  );
}
export function useGetUserSubmissionsByFacultyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables>(
    GetUserSubmissionsByFacultyDocument,
    options
  );
}
export function useGetUserSubmissionsByFacultySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserSubmissionsByFacultyQuery, GetUserSubmissionsByFacultyQueryVariables>(
    GetUserSubmissionsByFacultyDocument,
    options
  );
}
export type GetUserSubmissionsByFacultyQueryHookResult = ReturnType<typeof useGetUserSubmissionsByFacultyQuery>;
export type GetUserSubmissionsByFacultyLazyQueryHookResult = ReturnType<typeof useGetUserSubmissionsByFacultyLazyQuery>;
export type GetUserSubmissionsByFacultySuspenseQueryHookResult = ReturnType<
  typeof useGetUserSubmissionsByFacultySuspenseQuery
>;
export type GetUserSubmissionsByFacultyQueryResult = Apollo.QueryResult<
  GetUserSubmissionsByFacultyQuery,
  GetUserSubmissionsByFacultyQueryVariables
>;
export const SuggestionsDocument = gql`
  query Suggestions {
    getSuggestions {
      type
      content
      integrationId
      classId
      assignmentId
      journeyId
      trackName
    }
  }
`;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<typeof useSuggestionsLazyQuery>;
export type SuggestionsSuspenseQueryHookResult = ReturnType<typeof useSuggestionsSuspenseQuery>;
export type SuggestionsQueryResult = Apollo.QueryResult<SuggestionsQuery, SuggestionsQueryVariables>;
export const GetDegreeBySchoolDocument = gql`
  query GetDegreeBySchool($schoolId: ID!) {
    getDegreeBySchool(schoolId: $schoolId) {
      success
      message
      data {
        id
        name
        created
        last_modified
        description
      }
      count
    }
  }
`;

/**
 * __useGetDegreeBySchoolQuery__
 *
 * To run a query within a React component, call `useGetDegreeBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDegreeBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDegreeBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetDegreeBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables> &
    ({ variables: GetDegreeBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>(GetDegreeBySchoolDocument, options);
}
export function useGetDegreeBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>(
    GetDegreeBySchoolDocument,
    options
  );
}
export function useGetDegreeBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>(
    GetDegreeBySchoolDocument,
    options
  );
}
export type GetDegreeBySchoolQueryHookResult = ReturnType<typeof useGetDegreeBySchoolQuery>;
export type GetDegreeBySchoolLazyQueryHookResult = ReturnType<typeof useGetDegreeBySchoolLazyQuery>;
export type GetDegreeBySchoolSuspenseQueryHookResult = ReturnType<typeof useGetDegreeBySchoolSuspenseQuery>;
export type GetDegreeBySchoolQueryResult = Apollo.QueryResult<GetDegreeBySchoolQuery, GetDegreeBySchoolQueryVariables>;
export const GetDegreesBySchoolByQueryDocument = gql`
  query GetDegreesBySchoolByQuery($schoolId: ID!, $query: String, $page: Int, $pageSize: Int) {
    getDegreesBySchoolByQuery(schoolId: $schoolId, query: $query, page: $page, pageSize: $pageSize) {
      success
      message
      data {
        degrees {
          id
          name
          description
          usersEnrolledIn {
            id
            name
          }
          coursesHasCourse {
            id
            name
          }
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetDegreesBySchoolByQueryQuery__
 *
 * To run a query within a React component, call `useGetDegreesBySchoolByQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDegreesBySchoolByQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDegreesBySchoolByQueryQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetDegreesBySchoolByQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables> &
    ({ variables: GetDegreesBySchoolByQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables>(
    GetDegreesBySchoolByQueryDocument,
    options
  );
}
export function useGetDegreesBySchoolByQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables>(
    GetDegreesBySchoolByQueryDocument,
    options
  );
}
export function useGetDegreesBySchoolByQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDegreesBySchoolByQueryQuery, GetDegreesBySchoolByQueryQueryVariables>(
    GetDegreesBySchoolByQueryDocument,
    options
  );
}
export type GetDegreesBySchoolByQueryQueryHookResult = ReturnType<typeof useGetDegreesBySchoolByQueryQuery>;
export type GetDegreesBySchoolByQueryLazyQueryHookResult = ReturnType<typeof useGetDegreesBySchoolByQueryLazyQuery>;
export type GetDegreesBySchoolByQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetDegreesBySchoolByQuerySuspenseQuery
>;
export type GetDegreesBySchoolByQueryQueryResult = Apollo.QueryResult<
  GetDegreesBySchoolByQueryQuery,
  GetDegreesBySchoolByQueryQueryVariables
>;
export const GetDegreeForUserDocument = gql`
  query GetDegreeForUser {
    getDegreeForUser {
      count
      message
      success
      data {
        description
        name
        id
      }
    }
  }
`;

/**
 * __useGetDegreeForUserQuery__
 *
 * To run a query within a React component, call `useGetDegreeForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDegreeForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDegreeForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDegreeForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>(GetDegreeForUserDocument, options);
}
export function useGetDegreeForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>(GetDegreeForUserDocument, options);
}
export function useGetDegreeForUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>(
    GetDegreeForUserDocument,
    options
  );
}
export type GetDegreeForUserQueryHookResult = ReturnType<typeof useGetDegreeForUserQuery>;
export type GetDegreeForUserLazyQueryHookResult = ReturnType<typeof useGetDegreeForUserLazyQuery>;
export type GetDegreeForUserSuspenseQueryHookResult = ReturnType<typeof useGetDegreeForUserSuspenseQuery>;
export type GetDegreeForUserQueryResult = Apollo.QueryResult<GetDegreeForUserQuery, GetDegreeForUserQueryVariables>;
export const GetDegreesByCourseByQueryDocument = gql`
  query GetDegreesByCourseByQuery($courseId: ID!, $query: String, $page: Int, $pageSize: Int) {
    getDegreesByCourseByQuery(courseId: $courseId, query: $query, page: $page, pageSize: $pageSize) {
      success
      message
      data {
        degrees {
          id
          name
          description
          usersEnrolledIn {
            id
            name
          }
          coursesHasCourse {
            id
            name
          }
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetDegreesByCourseByQueryQuery__
 *
 * To run a query within a React component, call `useGetDegreesByCourseByQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDegreesByCourseByQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDegreesByCourseByQueryQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetDegreesByCourseByQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables> &
    ({ variables: GetDegreesByCourseByQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables>(
    GetDegreesByCourseByQueryDocument,
    options
  );
}
export function useGetDegreesByCourseByQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables>(
    GetDegreesByCourseByQueryDocument,
    options
  );
}
export function useGetDegreesByCourseByQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDegreesByCourseByQueryQuery, GetDegreesByCourseByQueryQueryVariables>(
    GetDegreesByCourseByQueryDocument,
    options
  );
}
export type GetDegreesByCourseByQueryQueryHookResult = ReturnType<typeof useGetDegreesByCourseByQueryQuery>;
export type GetDegreesByCourseByQueryLazyQueryHookResult = ReturnType<typeof useGetDegreesByCourseByQueryLazyQuery>;
export type GetDegreesByCourseByQuerySuspenseQueryHookResult = ReturnType<
  typeof useGetDegreesByCourseByQuerySuspenseQuery
>;
export type GetDegreesByCourseByQueryQueryResult = Apollo.QueryResult<
  GetDegreesByCourseByQueryQuery,
  GetDegreesByCourseByQueryQueryVariables
>;
export const GetFileDocument = gql`
  query GetFile($fileId: ID!) {
    getFile(fileId: $fileId) {
      count
      data {
        fileName
        id
        status
      }
      message
      success
    }
  }
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables> &
    ({ variables: GetFileQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(GetFileDocument, options);
}
export function useGetFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(GetFileDocument, options);
}
export function useGetFileSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFileQuery, GetFileQueryVariables>(GetFileDocument, options);
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileSuspenseQueryHookResult = ReturnType<typeof useGetFileSuspenseQuery>;
export type GetFileQueryResult = Apollo.QueryResult<GetFileQuery, GetFileQueryVariables>;
export const TodayGameDocument = gql`
  query TodayGame($timezone: String!) {
    getTodayGame(timezone: $timezone) {
      chatId
      refreshCounter
      dailyTaskGoalForToday
      dailyTaskCompletedForToday
      dailyLearningModulesGoalForToday
      dailyLearningModulesCompletedForToday
      dailyCalorieGoalForToday
      dailyCalorieCompletedForToday
      assignedQuests {
        id
        title
        description
        isComplete
      }
    }
  }
`;

/**
 * __useTodayGameQuery__
 *
 * To run a query within a React component, call `useTodayGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayGameQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayGameQuery(
  baseOptions: Apollo.QueryHookOptions<TodayGameQuery, TodayGameQueryVariables> &
    ({ variables: TodayGameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export type TodayGameQueryHookResult = ReturnType<typeof useTodayGameQuery>;
export type TodayGameLazyQueryHookResult = ReturnType<typeof useTodayGameLazyQuery>;
export type TodayGameSuspenseQueryHookResult = ReturnType<typeof useTodayGameSuspenseQuery>;
export type TodayGameQueryResult = Apollo.QueryResult<TodayGameQuery, TodayGameQueryVariables>;
export const DailyExerciseTotalsByDateDocument = gql`
  query DailyExerciseTotalsByDate($date: String!, $timezone: String!) {
    getDailyExerciseTotalsByDate(date: $date, timezone: $timezone) {
      totalCaloriesBurned
      totalDurationMinutes
      totalWorkouts
      workoutsList {
        name
        type
      }
    }
  }
`;

/**
 * __useDailyExerciseTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyExerciseTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyExerciseTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyExerciseTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyExerciseTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables> &
    ({ variables: DailyExerciseTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export type DailyExerciseTotalsByDateQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateQuery>;
export type DailyExerciseTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateLazyQuery>;
export type DailyExerciseTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyExerciseTotalsByDateSuspenseQuery
>;
export type DailyExerciseTotalsByDateQueryResult = Apollo.QueryResult<
  DailyExerciseTotalsByDateQuery,
  DailyExerciseTotalsByDateQueryVariables
>;
export const DailyNutritionTotalsByDateDocument = gql`
  query DailyNutritionTotalsByDate($date: String!, $timezone: String!) {
    getDailyNutritionTotalsByDate(date: $date, timezone: $timezone) {
      totalCalories
      totalCarbs
      totalFats
      totalProteins
    }
  }
`;

/**
 * __useDailyNutritionTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyNutritionTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyNutritionTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyNutritionTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyNutritionTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables> &
    ({ variables: DailyNutritionTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export type DailyNutritionTotalsByDateQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateQuery>;
export type DailyNutritionTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateLazyQuery>;
export type DailyNutritionTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyNutritionTotalsByDateSuspenseQuery
>;
export type DailyNutritionTotalsByDateQueryResult = Apollo.QueryResult<
  DailyNutritionTotalsByDateQuery,
  DailyNutritionTotalsByDateQueryVariables
>;
export const FoodByDateDocument = gql`
  query FoodByDate($date: String!, $timezone: String!) {
    getFoodsByDate(date: $date, timezone: $timezone) {
      name
      calories
      carbohydrates_grams
      fat_grams
      fiber_grams
      protein_grams
      serving_size
      sodium_mg
      sugars_grams
    }
  }
`;

/**
 * __useFoodByDateQuery__
 *
 * To run a query within a React component, call `useFoodByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useFoodByDateQuery(
  baseOptions: Apollo.QueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables> &
    ({ variables: FoodByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export type FoodByDateQueryHookResult = ReturnType<typeof useFoodByDateQuery>;
export type FoodByDateLazyQueryHookResult = ReturnType<typeof useFoodByDateLazyQuery>;
export type FoodByDateSuspenseQueryHookResult = ReturnType<typeof useFoodByDateSuspenseQuery>;
export type FoodByDateQueryResult = Apollo.QueryResult<FoodByDateQuery, FoodByDateQueryVariables>;
export const WaterIntakeByDateDocument = gql`
  query WaterIntakeByDate($date: String!, $timezone: String!) {
    getWaterIntakeByDate(date: $date, timezone: $timezone) {
      totalWaterIntake
      goalOz
      percentageTowardsGoal
    }
  }
`;

/**
 * __useWaterIntakeByDateQuery__
 *
 * To run a query within a React component, call `useWaterIntakeByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterIntakeByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterIntakeByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useWaterIntakeByDateQuery(
  baseOptions: Apollo.QueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables> &
    ({ variables: WaterIntakeByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(WaterIntakeByDateDocument, options);
}
export function useWaterIntakeByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export function useWaterIntakeByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export type WaterIntakeByDateQueryHookResult = ReturnType<typeof useWaterIntakeByDateQuery>;
export type WaterIntakeByDateLazyQueryHookResult = ReturnType<typeof useWaterIntakeByDateLazyQuery>;
export type WaterIntakeByDateSuspenseQueryHookResult = ReturnType<typeof useWaterIntakeByDateSuspenseQuery>;
export type WaterIntakeByDateQueryResult = Apollo.QueryResult<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>;
export const InviteCountDocument = gql`
  query InviteCount {
    userInviteCount {
      count
    }
    me {
      name
      username
      gender
      isOnboarded
      isPro
      phoneNumber
      isEmailVerified
    }
  }
`;

/**
 * __useInviteCountQuery__
 *
 * To run a query within a React component, call `useInviteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteCountQuery(
  baseOptions?: Apollo.QueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export type InviteCountQueryHookResult = ReturnType<typeof useInviteCountQuery>;
export type InviteCountLazyQueryHookResult = ReturnType<typeof useInviteCountLazyQuery>;
export type InviteCountSuspenseQueryHookResult = ReturnType<typeof useInviteCountSuspenseQuery>;
export type InviteCountQueryResult = Apollo.QueryResult<InviteCountQuery, InviteCountQueryVariables>;
export const DateForChatDocument = gql`
  query DateForChat($date: String!, $timezone: String!) {
    getChatForDate(date: $date, timezone: $timezone) {
      id
      date
      timezone
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        file {
          fileName
          id
        }
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useDateForChatQuery__
 *
 * To run a query within a React component, call `useDateForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDateForChatQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDateForChatQuery(
  baseOptions: Apollo.QueryHookOptions<DateForChatQuery, DateForChatQueryVariables> &
    ({ variables: DateForChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export type DateForChatQueryHookResult = ReturnType<typeof useDateForChatQuery>;
export type DateForChatLazyQueryHookResult = ReturnType<typeof useDateForChatLazyQuery>;
export type DateForChatSuspenseQueryHookResult = ReturnType<typeof useDateForChatSuspenseQuery>;
export type DateForChatQueryResult = Apollo.QueryResult<DateForChatQuery, DateForChatQueryVariables>;
export const GetJourneyByIdDocument = gql`
  query GetJourneyById($journeyId: ID!) {
    getJourneyById(journeyId: $journeyId) {
      id
      name
      description
      dueDate
      url
      created
      lastModified
      tasks {
        id
        name
        description
        priority
        date
        completed
        isRecurring
        recurringFrequency
        recurringDaily
        recurringDayOfWeek
        recurringDayOfMonth
        recurringTime
        timeOfDay
        timezone
        isPartOfJourney
        journeyId
      }
    }
  }
`;

/**
 * __useGetJourneyByIdQuery__
 *
 * To run a query within a React component, call `useGetJourneyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneyByIdQuery({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useGetJourneyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables> &
    ({ variables: GetJourneyByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export type GetJourneyByIdQueryHookResult = ReturnType<typeof useGetJourneyByIdQuery>;
export type GetJourneyByIdLazyQueryHookResult = ReturnType<typeof useGetJourneyByIdLazyQuery>;
export type GetJourneyByIdSuspenseQueryHookResult = ReturnType<typeof useGetJourneyByIdSuspenseQuery>;
export type GetJourneyByIdQueryResult = Apollo.QueryResult<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>;
export const GetJourneysDocument = gql`
  query GetJourneys {
    getJourneys {
      id
      name
      description
      url
      created
      lastModified
      dueDate
      totalTasks
      completedTasks
    }
  }
`;

/**
 * __useGetJourneysQuery__
 *
 * To run a query within a React component, call `useGetJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJourneysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export type GetJourneysQueryHookResult = ReturnType<typeof useGetJourneysQuery>;
export type GetJourneysLazyQueryHookResult = ReturnType<typeof useGetJourneysLazyQuery>;
export type GetJourneysSuspenseQueryHookResult = ReturnType<typeof useGetJourneysSuspenseQuery>;
export type GetJourneysQueryResult = Apollo.QueryResult<GetJourneysQuery, GetJourneysQueryVariables>;
export const FieldsDocument = gql`
  query Fields {
    getFields {
      name
      id
      mastery
      isRelevant
      imageUrl
    }
  }
`;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldsQuery(baseOptions?: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FieldsQuery, FieldsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsSuspenseQueryHookResult = ReturnType<typeof useFieldsSuspenseQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;
export const GetLearningTreeDocument = gql`
  query GetLearningTree($level: String!, $name: String!) {
    getLearningTree(level: $level, name: $name) {
      FieldName
      SubfieldName
      TopicName
      ChapterName
      SubchapterName
    }
  }
`;

/**
 * __useGetLearningTreeQuery__
 *
 * To run a query within a React component, call `useGetLearningTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningTreeQuery({
 *   variables: {
 *      level: // value for 'level'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetLearningTreeQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables> &
    ({ variables: GetLearningTreeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export type GetLearningTreeQueryHookResult = ReturnType<typeof useGetLearningTreeQuery>;
export type GetLearningTreeLazyQueryHookResult = ReturnType<typeof useGetLearningTreeLazyQuery>;
export type GetLearningTreeSuspenseQueryHookResult = ReturnType<typeof useGetLearningTreeSuspenseQuery>;
export type GetLearningTreeQueryResult = Apollo.QueryResult<GetLearningTreeQuery, GetLearningTreeQueryVariables>;
export const NodeGraphDocument = gql`
  query NodeGraph($nodeName: String!, $nodeLabel: NodeLabel!) {
    getNodeChildren(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      unitsCount
      imageUrl
    }
    getNode(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      imageUrl
    }
    getNodeParents(label: $nodeLabel, name: $nodeName) {
      name
      id
      mastery
      label
    }
  }
`;

/**
 * __useNodeGraphQuery__
 *
 * To run a query within a React component, call `useNodeGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeGraphQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useNodeGraphQuery(
  baseOptions: Apollo.QueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables> &
    ({ variables: NodeGraphQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export type NodeGraphQueryHookResult = ReturnType<typeof useNodeGraphQuery>;
export type NodeGraphLazyQueryHookResult = ReturnType<typeof useNodeGraphLazyQuery>;
export type NodeGraphSuspenseQueryHookResult = ReturnType<typeof useNodeGraphSuspenseQuery>;
export type NodeGraphQueryResult = Apollo.QueryResult<NodeGraphQuery, NodeGraphQueryVariables>;
export const RecentTracksDocument = gql`
  query RecentTracks($filterByCategory: String) {
    getRecentTracks(filterByCategory: $filterByCategory) {
      name
      description
      totalUnits
      completedUnits
      parentFields
      parentSubfield
      mastery
      imageUrl
    }
  }
`;

/**
 * __useRecentTracksQuery__
 *
 * To run a query within a React component, call `useRecentTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTracksQuery({
 *   variables: {
 *      filterByCategory: // value for 'filterByCategory'
 *   },
 * });
 */
export function useRecentTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export type RecentTracksQueryHookResult = ReturnType<typeof useRecentTracksQuery>;
export type RecentTracksLazyQueryHookResult = ReturnType<typeof useRecentTracksLazyQuery>;
export type RecentTracksSuspenseQueryHookResult = ReturnType<typeof useRecentTracksSuspenseQuery>;
export type RecentTracksQueryResult = Apollo.QueryResult<RecentTracksQuery, RecentTracksQueryVariables>;
export const SiblingSubchaptersDocument = gql`
  query SiblingSubchapters($limit: Int!, $chapterName: String!, $subchapterName: String!) {
    getSiblingSubchapters(limit: $limit, chapterName: $chapterName, subchapterName: $subchapterName) {
      name
      label
      mastery
    }
  }
`;

/**
 * __useSiblingSubchaptersQuery__
 *
 * To run a query within a React component, call `useSiblingSubchaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiblingSubchaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiblingSubchaptersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      chapterName: // value for 'chapterName'
 *      subchapterName: // value for 'subchapterName'
 *   },
 * });
 */
export function useSiblingSubchaptersQuery(
  baseOptions: Apollo.QueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables> &
    ({ variables: SiblingSubchaptersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export type SiblingSubchaptersQueryHookResult = ReturnType<typeof useSiblingSubchaptersQuery>;
export type SiblingSubchaptersLazyQueryHookResult = ReturnType<typeof useSiblingSubchaptersLazyQuery>;
export type SiblingSubchaptersSuspenseQueryHookResult = ReturnType<typeof useSiblingSubchaptersSuspenseQuery>;
export type SiblingSubchaptersQueryResult = Apollo.QueryResult<
  SiblingSubchaptersQuery,
  SiblingSubchaptersQueryVariables
>;
export const SubchapterPrerequisitesDocument = gql`
  query SubchapterPrerequisites($subchapterName: String!, $limit: Int) {
    getSubchapterPrerequisites(subchapterName: $subchapterName, limit: $limit) {
      label
      name
      mastery
    }
  }
`;

/**
 * __useSubchapterPrerequisitesQuery__
 *
 * To run a query within a React component, call `useSubchapterPrerequisitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterPrerequisitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterPrerequisitesQuery({
 *   variables: {
 *      subchapterName: // value for 'subchapterName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSubchapterPrerequisitesQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables> &
    ({ variables: SubchapterPrerequisitesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export type SubchapterPrerequisitesQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesQuery>;
export type SubchapterPrerequisitesLazyQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesLazyQuery>;
export type SubchapterPrerequisitesSuspenseQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesSuspenseQuery>;
export type SubchapterPrerequisitesQueryResult = Apollo.QueryResult<
  SubchapterPrerequisitesQuery,
  SubchapterPrerequisitesQueryVariables
>;
export const TableOfContentsDocument = gql`
  query TableOfContents($learningNodeNames: [String]!, $hierarchyLabel: String!) {
    getTableOfContents(learningNodeNames: $learningNodeNames, hierarchyLabel: $hierarchyLabel) {
      name
      mastery
      id
      isRelevant
      parentName
      description
      completed
      index
    }
  }
`;

/**
 * __useTableOfContentsQuery__
 *
 * To run a query within a React component, call `useTableOfContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableOfContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableOfContentsQuery({
 *   variables: {
 *      learningNodeNames: // value for 'learningNodeNames'
 *      hierarchyLabel: // value for 'hierarchyLabel'
 *   },
 * });
 */
export function useTableOfContentsQuery(
  baseOptions: Apollo.QueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables> &
    ({ variables: TableOfContentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export type TableOfContentsQueryHookResult = ReturnType<typeof useTableOfContentsQuery>;
export type TableOfContentsLazyQueryHookResult = ReturnType<typeof useTableOfContentsLazyQuery>;
export type TableOfContentsSuspenseQueryHookResult = ReturnType<typeof useTableOfContentsSuspenseQuery>;
export type TableOfContentsQueryResult = Apollo.QueryResult<TableOfContentsQuery, TableOfContentsQueryVariables>;
export const TrackOverviewDocument = gql`
  query TrackOverview($topicName: String!) {
    getTrackOverview(topicName: $topicName) {
      name
      description
      objectives
      lessons {
        name
        description
      }
      skillRequirements
      otherRequirements
      otherRequirementsTitle
      prerequisites {
        name
        subfield
        field
        mastery
      }
    }
  }
`;

/**
 * __useTrackOverviewQuery__
 *
 * To run a query within a React component, call `useTrackOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackOverviewQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useTrackOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables> &
    ({ variables: TrackOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export type TrackOverviewQueryHookResult = ReturnType<typeof useTrackOverviewQuery>;
export type TrackOverviewLazyQueryHookResult = ReturnType<typeof useTrackOverviewLazyQuery>;
export type TrackOverviewSuspenseQueryHookResult = ReturnType<typeof useTrackOverviewSuspenseQuery>;
export type TrackOverviewQueryResult = Apollo.QueryResult<TrackOverviewQuery, TrackOverviewQueryVariables>;
export const UserOverallProgressDocument = gql`
  query UserOverallProgress {
    getUserOverallProgress {
      child {
        name
        label
      }
      parent {
        label
        name
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserOverallProgressQuery__
 *
 * To run a query within a React component, call `useUserOverallProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOverallProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOverallProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOverallProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export type UserOverallProgressQueryHookResult = ReturnType<typeof useUserOverallProgressQuery>;
export type UserOverallProgressLazyQueryHookResult = ReturnType<typeof useUserOverallProgressLazyQuery>;
export type UserOverallProgressSuspenseQueryHookResult = ReturnType<typeof useUserOverallProgressSuspenseQuery>;
export type UserOverallProgressQueryResult = Apollo.QueryResult<
  UserOverallProgressQuery,
  UserOverallProgressQueryVariables
>;
export const UserProgressDocument = gql`
  query UserProgress($nodeName: String!, $nodeLabel: NodeLabel!) {
    getUserProgress(nodeName: $nodeName, nodeLabel: $nodeLabel) {
      child {
        name
        label
        totalTracks
        completedTracks
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserProgressQuery__
 *
 * To run a query within a React component, call `useUserProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProgressQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useUserProgressQuery(
  baseOptions: Apollo.QueryHookOptions<UserProgressQuery, UserProgressQueryVariables> &
    ({ variables: UserProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export type UserProgressQueryHookResult = ReturnType<typeof useUserProgressQuery>;
export type UserProgressLazyQueryHookResult = ReturnType<typeof useUserProgressLazyQuery>;
export type UserProgressSuspenseQueryHookResult = ReturnType<typeof useUserProgressSuspenseQuery>;
export type UserProgressQueryResult = Apollo.QueryResult<UserProgressQuery, UserProgressQueryVariables>;
export const GetFirstSubchapterByTopicNameDocument = gql`
  query GetFirstSubchapterByTopicName($topicName: String!) {
    getFirstSubchapterByTopicName(topicName: $topicName) {
      success
      message
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
    }
  }
`;

/**
 * __useGetFirstSubchapterByTopicNameQuery__
 *
 * To run a query within a React component, call `useGetFirstSubchapterByTopicNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstSubchapterByTopicNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstSubchapterByTopicNameQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useGetFirstSubchapterByTopicNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  > &
    ({ variables: GetFirstSubchapterByTopicNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export type GetFirstSubchapterByTopicNameQueryHookResult = ReturnType<typeof useGetFirstSubchapterByTopicNameQuery>;
export type GetFirstSubchapterByTopicNameLazyQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameLazyQuery
>;
export type GetFirstSubchapterByTopicNameSuspenseQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameSuspenseQuery
>;
export type GetFirstSubchapterByTopicNameQueryResult = Apollo.QueryResult<
  GetFirstSubchapterByTopicNameQuery,
  GetFirstSubchapterByTopicNameQueryVariables
>;
export const GetLearningObjectiveByIdDocument = gql`
  query GetLearningObjectiveById($loId: ID!) {
    getLearningObjectiveById(loId: $loId) {
      success
      message
      id
      title
      description
      completed
    }
  }
`;

/**
 * __useGetLearningObjectiveByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectiveByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectiveByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectiveByIdQuery({
 *   variables: {
 *      loId: // value for 'loId'
 *   },
 * });
 */
export function useGetLearningObjectiveByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables> &
    ({ variables: GetLearningObjectiveByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export type GetLearningObjectiveByIdQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdQuery>;
export type GetLearningObjectiveByIdLazyQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdLazyQuery>;
export type GetLearningObjectiveByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectiveByIdSuspenseQuery
>;
export type GetLearningObjectiveByIdQueryResult = Apollo.QueryResult<
  GetLearningObjectiveByIdQuery,
  GetLearningObjectiveByIdQueryVariables
>;
export const GetLearningObjectivesBySubchapterDocument = gql`
  query GetLearningObjectivesBySubchapter($learningObjectiveId: ID!) {
    getLearningObjectivesBySubchapter(learningObjectiveId: $learningObjectiveId) {
      id
      title
      description
      index
      completed
    }
  }
`;

/**
 * __useGetLearningObjectivesBySubchapterQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectivesBySubchapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectivesBySubchapterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectivesBySubchapterQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetLearningObjectivesBySubchapterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  > &
    ({ variables: GetLearningObjectivesBySubchapterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLearningObjectivesBySubchapterQuery,
        GetLearningObjectivesBySubchapterQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >(GetLearningObjectivesBySubchapterDocument, options);
}
export type GetLearningObjectivesBySubchapterQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterQuery
>;
export type GetLearningObjectivesBySubchapterLazyQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterLazyQuery
>;
export type GetLearningObjectivesBySubchapterSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterSuspenseQuery
>;
export type GetLearningObjectivesBySubchapterQueryResult = Apollo.QueryResult<
  GetLearningObjectivesBySubchapterQuery,
  GetLearningObjectivesBySubchapterQueryVariables
>;
export const SubchapterByIdDocument = gql`
  query SubchapterById($name: String!) {
    getSubchapterById(name: $name) {
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
      totalMastery
    }
  }
`;

/**
 * __useSubchapterByIdQuery__
 *
 * To run a query within a React component, call `useSubchapterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterByIdQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSubchapterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables> &
    ({ variables: SubchapterByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export type SubchapterByIdQueryHookResult = ReturnType<typeof useSubchapterByIdQuery>;
export type SubchapterByIdLazyQueryHookResult = ReturnType<typeof useSubchapterByIdLazyQuery>;
export type SubchapterByIdSuspenseQueryHookResult = ReturnType<typeof useSubchapterByIdSuspenseQuery>;
export type SubchapterByIdQueryResult = Apollo.QueryResult<SubchapterByIdQuery, SubchapterByIdQueryVariables>;
export const GetMasteryDocument = gql`
  query GetMastery($learningObjectiveId: ID!) {
    getUserLearningObjectiveMastery(learningObjectiveId: $learningObjectiveId) {
      learningObjectiveId
      userId
      totalMastery
    }
  }
`;

/**
 * __useGetMasteryQuery__
 *
 * To run a query within a React component, call `useGetMasteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasteryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasteryQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetMasteryQuery(
  baseOptions: Apollo.QueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables> &
    ({ variables: GetMasteryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasteryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasterySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export type GetMasteryQueryHookResult = ReturnType<typeof useGetMasteryQuery>;
export type GetMasteryLazyQueryHookResult = ReturnType<typeof useGetMasteryLazyQuery>;
export type GetMasterySuspenseQueryHookResult = ReturnType<typeof useGetMasterySuspenseQuery>;
export type GetMasteryQueryResult = Apollo.QueryResult<GetMasteryQuery, GetMasteryQueryVariables>;
export const ExportCsvDocument = gql`
  query ExportCsv {
    exportCsv {
      success
      csvData
      message
    }
  }
`;

/**
 * __useExportCsvQuery__
 *
 * To run a query within a React component, call `useExportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export type ExportCsvQueryHookResult = ReturnType<typeof useExportCsvQuery>;
export type ExportCsvLazyQueryHookResult = ReturnType<typeof useExportCsvLazyQuery>;
export type ExportCsvSuspenseQueryHookResult = ReturnType<typeof useExportCsvSuspenseQuery>;
export type ExportCsvQueryResult = Apollo.QueryResult<ExportCsvQuery, ExportCsvQueryVariables>;
export const AssignmentsDocument = gql`
  query Assignments {
    getAssignments {
      id
      title
      description
      gradePoints
      createdDate
      pointsPossible
      dueDate
      url
      objectives {
        id
        objective
        status
      }
    }
  }
`;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export function useAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export function useAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsSuspenseQueryHookResult = ReturnType<typeof useAssignmentsSuspenseQuery>;
export type AssignmentsQueryResult = Apollo.QueryResult<AssignmentsQuery, AssignmentsQueryVariables>;
export const SchoolsDocument = gql`
  query Schools {
    getSchools {
      id
      providerName
      teamName
      icon
    }
  }
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsSuspenseQueryHookResult = ReturnType<typeof useSchoolsSuspenseQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const SuggestVideoInsightsDocument = gql`
  query SuggestVideoInsights($queryData: SuggestVideoInsightsInput!) {
    suggestVideoInsights(queryData: $queryData) {
      success
      data
    }
  }
`;

/**
 * __useSuggestVideoInsightsQuery__
 *
 * To run a query within a React component, call `useSuggestVideoInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestVideoInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestVideoInsightsQuery({
 *   variables: {
 *      queryData: // value for 'queryData'
 *   },
 * });
 */
export function useSuggestVideoInsightsQuery(
  baseOptions: Apollo.QueryHookOptions<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables> &
    ({ variables: SuggestVideoInsightsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables>(
    SuggestVideoInsightsDocument,
    options
  );
}
export function useSuggestVideoInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables>(
    SuggestVideoInsightsDocument,
    options
  );
}
export function useSuggestVideoInsightsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuggestVideoInsightsQuery, SuggestVideoInsightsQueryVariables>(
    SuggestVideoInsightsDocument,
    options
  );
}
export type SuggestVideoInsightsQueryHookResult = ReturnType<typeof useSuggestVideoInsightsQuery>;
export type SuggestVideoInsightsLazyQueryHookResult = ReturnType<typeof useSuggestVideoInsightsLazyQuery>;
export type SuggestVideoInsightsSuspenseQueryHookResult = ReturnType<typeof useSuggestVideoInsightsSuspenseQuery>;
export type SuggestVideoInsightsQueryResult = Apollo.QueryResult<
  SuggestVideoInsightsQuery,
  SuggestVideoInsightsQueryVariables
>;
export const DeleteTodoDocument = gql`
  mutation DeleteTodo($id: String!) {
    deleteTodo(id: $id) {
      success
      message
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<DeleteTodoMutation, DeleteTodoMutationVariables>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTodoMutation, DeleteTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(DeleteTodoDocument, options);
}
export type DeleteTodoMutationHookResult = ReturnType<typeof useDeleteTodoMutation>;
export type DeleteTodoMutationResult = Apollo.MutationResult<DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<DeleteTodoMutation, DeleteTodoMutationVariables>;
export const RecentTodosDocument = gql`
  query RecentTodos {
    recentTodos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useRecentTodosQuery__
 *
 * To run a query within a React component, call `useRecentTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentTodosQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export type RecentTodosQueryHookResult = ReturnType<typeof useRecentTodosQuery>;
export type RecentTodosLazyQueryHookResult = ReturnType<typeof useRecentTodosLazyQuery>;
export type RecentTodosSuspenseQueryHookResult = ReturnType<typeof useRecentTodosSuspenseQuery>;
export type RecentTodosQueryResult = Apollo.QueryResult<RecentTodosQuery, RecentTodosQueryVariables>;
export const TodosDocument = gql`
  query Todos {
    todos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodosQuery(baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodosQuery, TodosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosSuspenseQueryHookResult = ReturnType<typeof useTodosSuspenseQuery>;
export type TodosQueryResult = Apollo.QueryResult<TodosQuery, TodosQueryVariables>;
export const GetAllTenantsDocument = gql`
  query GetAllTenants {
    getAllTenants {
      success
      message
      data {
        id
        tenantDomain
        metaTagsTitle
        metaTagsDescription
        metaTagsThemeColor
        metaTagsAppleTouchIcon
        metaTagsTwitterCard
        metaTagsTwitterSite
        metaTagsTwitterTitle
        metaTagsTwitterDescription
        metaTagsTwitterImage
        stripeSubscriptionId
        hasTrial
        stripeTrialDays
        externalSendGridAppInviteTemplateId
        externalSendGridDailyEmailTemplateId
        externalSendGridFromEmail
        externalSendGridFromName
        externalSendGridTwilioServiceSID
        moderationSexual
        moderationHate
        moderationHarassment
        moderationSelfHarm
        moderationSexualMinors
        moderationHateThreatening
        moderationViolenceGraphic
        moderationSelfHarmIntent
        moderationSelfHarmInstructions
        moderationHarassmentThreatening
        moderationViolence
        companionName
        key
        isLMS
        isLTI
        isAxioOffering
        isSuggestedInsights
        axioSchools
        disableWebsearchWhenRAG
        primaryColor
        learningFieldColor
        learningSubFieldColor
        gameMenuBg
        gameMenuTrack
        gameMenuRefresh
        gameMenuRefreshText
        gameMenuProgress
        gameMenuCardBg
        gameMenuCardButton
        gameMenuCardButtonText
        gameMenuCardRefresh
        emulationStatement
        learningEmulationStatement
        logo
        showMiniLogo
        scope
        additionalScope
        connectURL
        termsOfServiceLink
        showObjectivesTab
        showJobsTab
        generalPrompt
        onboardingPrompt
        newsSectionTitle
        customNews
        showNews
        newsSearchTerms
        showInviteFriends
        showFeedback
        isPrivateLabelIcon
        bugReportEmails
        upgradeModalBody
        isFreemium
        hasEmailVerification
        created
        last_modified
        hasProFlow
        stripeSecretKey
        hasDegrees
      }
      count
    }
  }
`;

/**
 * __useGetAllTenantsQuery__
 *
 * To run a query within a React component, call `useGetAllTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllTenantsQuery, GetAllTenantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTenantsQuery, GetAllTenantsQueryVariables>(GetAllTenantsDocument, options);
}
export function useGetAllTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllTenantsQuery, GetAllTenantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTenantsQuery, GetAllTenantsQueryVariables>(GetAllTenantsDocument, options);
}
export function useGetAllTenantsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllTenantsQuery, GetAllTenantsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllTenantsQuery, GetAllTenantsQueryVariables>(GetAllTenantsDocument, options);
}
export type GetAllTenantsQueryHookResult = ReturnType<typeof useGetAllTenantsQuery>;
export type GetAllTenantsLazyQueryHookResult = ReturnType<typeof useGetAllTenantsLazyQuery>;
export type GetAllTenantsSuspenseQueryHookResult = ReturnType<typeof useGetAllTenantsSuspenseQuery>;
export type GetAllTenantsQueryResult = Apollo.QueryResult<GetAllTenantsQuery, GetAllTenantsQueryVariables>;
export const GetTenantDocument = gql`
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      success
      message
      data {
        id
        tenantDomain
        metaTagsTitle
        metaTagsDescription
        metaTagsThemeColor
        metaTagsAppleTouchIcon
        metaTagsTwitterCard
        metaTagsTwitterSite
        metaTagsTwitterTitle
        metaTagsTwitterDescription
        metaTagsTwitterImage
        stripeSubscriptionId
        hasTrial
        stripeTrialDays
        externalSendGridAppInviteTemplateId
        externalSendGridDailyEmailTemplateId
        externalSendGridFromEmail
        externalSendGridFromName
        externalSendGridTwilioServiceSID
        moderationSexual
        moderationHate
        moderationHarassment
        moderationSelfHarm
        moderationSexualMinors
        moderationHateThreatening
        moderationViolenceGraphic
        moderationSelfHarmIntent
        moderationSelfHarmInstructions
        moderationHarassmentThreatening
        moderationViolence
        companionName
        key
        isLMS
        isLTI
        isAxioOffering
        isSuggestedInsights
        axioSchools
        disableWebsearchWhenRAG
        primaryColor
        learningFieldColor
        learningSubFieldColor
        gameMenuBg
        gameMenuTrack
        gameMenuRefresh
        gameMenuRefreshText
        gameMenuProgress
        gameMenuCardBg
        gameMenuCardButton
        gameMenuCardButtonText
        gameMenuCardRefresh
        emulationStatement
        learningEmulationStatement
        logo
        showMiniLogo
        scope
        additionalScope
        connectURL
        termsOfServiceLink
        showObjectivesTab
        showJobsTab
        generalPrompt
        onboardingPrompt
        newsSectionTitle
        customNews
        showNews
        newsSearchTerms
        showInviteFriends
        showFeedback
        isPrivateLabelIcon
        bugReportEmails
        upgradeModalBody
        isFreemium
        hasEmailVerification
        created
        last_modified
        hasProFlow
        stripeSecretKey
        hasDegrees
      }
      count
    }
  }
`;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables> &
    ({ variables: GetTenantQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export function useGetTenantSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantSuspenseQueryHookResult = ReturnType<typeof useGetTenantSuspenseQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export const GetTenantSchemaDocument = gql`
  query GetTenantSchema {
    getTenantSchema {
      success
      message
      data
    }
  }
`;

/**
 * __useGetTenantSchemaQuery__
 *
 * To run a query within a React component, call `useGetTenantSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantSchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantSchemaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>(GetTenantSchemaDocument, options);
}
export function useGetTenantSchemaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>(GetTenantSchemaDocument, options);
}
export function useGetTenantSchemaSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>(GetTenantSchemaDocument, options);
}
export type GetTenantSchemaQueryHookResult = ReturnType<typeof useGetTenantSchemaQuery>;
export type GetTenantSchemaLazyQueryHookResult = ReturnType<typeof useGetTenantSchemaLazyQuery>;
export type GetTenantSchemaSuspenseQueryHookResult = ReturnType<typeof useGetTenantSchemaSuspenseQuery>;
export type GetTenantSchemaQueryResult = Apollo.QueryResult<GetTenantSchemaQuery, GetTenantSchemaQueryVariables>;
export const GetActiveTracksDocument = gql`
  query GetActiveTracks {
    getActiveTracks {
      name
      description
      last_modified
      totalUnits
      completedUnits
      parentSubfield
      parentFields
      mastery
      imageUrl
    }
  }
`;

/**
 * __useGetActiveTracksQuery__
 *
 * To run a query within a React component, call `useGetActiveTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export type GetActiveTracksQueryHookResult = ReturnType<typeof useGetActiveTracksQuery>;
export type GetActiveTracksLazyQueryHookResult = ReturnType<typeof useGetActiveTracksLazyQuery>;
export type GetActiveTracksSuspenseQueryHookResult = ReturnType<typeof useGetActiveTracksSuspenseQuery>;
export type GetActiveTracksQueryResult = Apollo.QueryResult<GetActiveTracksQuery, GetActiveTracksQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      audioOn
      countryCode
      currentWeightLbs
      dailyEmailOptIn
      enrolledTracks
      email
      fullPhoneNumber
      gender
      goalWeightLbs
      googleCalendarAuthToken
      id
      isCareerLoading
      isCourseLoading
      isEmailVerified
      isPro
      isOnboarded
      modalOnboarding
      name
      otpPreference
      phoneNumber
      trialEndDate
      username
      meetingLink
      profilePicture
      biography
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetFacultyByNameOrEmailDocument = gql`
  query GetFacultyByNameOrEmail($nameOrEmail: String!, $schoolId: ID!, $page: Int!, $pageSize: Int) {
    getFacultyByNameOrEmail(nameOrEmail: $nameOrEmail, schoolId: $schoolId, page: $page, pageSize: $pageSize) {
      success
      message
      data {
        faculties {
          id
          name
          email
          pendingFacultyInvite
          hasRoleRoles {
            name
          }
          teachingCourses {
            id
            name
          }
          hasSessionDeviceSessions {
            id
          }
        }
        totalCount
      }
      count
    }
  }
`;

/**
 * __useGetFacultyByNameOrEmailQuery__
 *
 * To run a query within a React component, call `useGetFacultyByNameOrEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacultyByNameOrEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacultyByNameOrEmailQuery({
 *   variables: {
 *      nameOrEmail: // value for 'nameOrEmail'
 *      schoolId: // value for 'schoolId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetFacultyByNameOrEmailQuery(
  baseOptions: Apollo.QueryHookOptions<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables> &
    ({ variables: GetFacultyByNameOrEmailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables>(
    GetFacultyByNameOrEmailDocument,
    options
  );
}
export function useGetFacultyByNameOrEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables>(
    GetFacultyByNameOrEmailDocument,
    options
  );
}
export function useGetFacultyByNameOrEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFacultyByNameOrEmailQuery, GetFacultyByNameOrEmailQueryVariables>(
    GetFacultyByNameOrEmailDocument,
    options
  );
}
export type GetFacultyByNameOrEmailQueryHookResult = ReturnType<typeof useGetFacultyByNameOrEmailQuery>;
export type GetFacultyByNameOrEmailLazyQueryHookResult = ReturnType<typeof useGetFacultyByNameOrEmailLazyQuery>;
export type GetFacultyByNameOrEmailSuspenseQueryHookResult = ReturnType<typeof useGetFacultyByNameOrEmailSuspenseQuery>;
export type GetFacultyByNameOrEmailQueryResult = Apollo.QueryResult<
  GetFacultyByNameOrEmailQuery,
  GetFacultyByNameOrEmailQueryVariables
>;
export const GetIsInvitationTokenValidDocument = gql`
  query GetIsInvitationTokenValid($token: String!, $service: String!) {
    getIsInvitationTokenValid(token: $token, service: $service) {
      isValid
    }
  }
`;

/**
 * __useGetIsInvitationTokenValidQuery__
 *
 * To run a query within a React component, call `useGetIsInvitationTokenValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsInvitationTokenValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsInvitationTokenValidQuery({
 *   variables: {
 *      token: // value for 'token'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useGetIsInvitationTokenValidQuery(
  baseOptions: Apollo.QueryHookOptions<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables> &
    ({ variables: GetIsInvitationTokenValidQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables>(
    GetIsInvitationTokenValidDocument,
    options
  );
}
export function useGetIsInvitationTokenValidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables>(
    GetIsInvitationTokenValidDocument,
    options
  );
}
export function useGetIsInvitationTokenValidSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetIsInvitationTokenValidQuery, GetIsInvitationTokenValidQueryVariables>(
    GetIsInvitationTokenValidDocument,
    options
  );
}
export type GetIsInvitationTokenValidQueryHookResult = ReturnType<typeof useGetIsInvitationTokenValidQuery>;
export type GetIsInvitationTokenValidLazyQueryHookResult = ReturnType<typeof useGetIsInvitationTokenValidLazyQuery>;
export type GetIsInvitationTokenValidSuspenseQueryHookResult = ReturnType<
  typeof useGetIsInvitationTokenValidSuspenseQuery
>;
export type GetIsInvitationTokenValidQueryResult = Apollo.QueryResult<
  GetIsInvitationTokenValidQuery,
  GetIsInvitationTokenValidQueryVariables
>;
export const GetVisualizationDocument = gql`
  query GetVisualization($id: ID!) {
    getVisualization(id: $id) {
      count
      data {
        code
        created
        id
        lastModified
        visualizationType
        visualizationDescription
        status
      }
      message
      success
    }
  }
`;

/**
 * __useGetVisualizationQuery__
 *
 * To run a query within a React component, call `useGetVisualizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisualizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisualizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVisualizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetVisualizationQuery, GetVisualizationQueryVariables> &
    ({ variables: GetVisualizationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisualizationQuery, GetVisualizationQueryVariables>(GetVisualizationDocument, options);
}
export function useGetVisualizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVisualizationQuery, GetVisualizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisualizationQuery, GetVisualizationQueryVariables>(GetVisualizationDocument, options);
}
export function useGetVisualizationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVisualizationQuery, GetVisualizationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVisualizationQuery, GetVisualizationQueryVariables>(
    GetVisualizationDocument,
    options
  );
}
export type GetVisualizationQueryHookResult = ReturnType<typeof useGetVisualizationQuery>;
export type GetVisualizationLazyQueryHookResult = ReturnType<typeof useGetVisualizationLazyQuery>;
export type GetVisualizationSuspenseQueryHookResult = ReturnType<typeof useGetVisualizationSuspenseQuery>;
export type GetVisualizationQueryResult = Apollo.QueryResult<GetVisualizationQuery, GetVisualizationQueryVariables>;
