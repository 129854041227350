import React from "react";
import { Text, StyleSheet } from "react-native";
import { useAppConfig } from "../../../../AppConfigProvider";
import { useTranslation } from "react-i18next";
import { decodeHTMLEntities } from "../../../../utils/textDecoder";

const CustomText = ({
  text,
  size = "m",
  styleType = "dark",
  weight = "medium",
  textType = "text",
  style = {},
  role = "text",
  useTranslationText = true,
  interpolation = {},
  ...props
}) => {
  const { t } = useTranslation();
  const { primaryColor } = useAppConfig();

  const textStyle = [
    styles.textType[textType][size],
    styles.weight[weight],
    colorStyles[styleType](primaryColor),
    style,
  ];

  // Conditional text rendering based on the useTranslationText prop
  const displayText = useTranslationText ? (text ? t(`text.${text}`, interpolation) : "") : text;
  const decodedText = typeof displayText === "string" ? decodeHTMLEntities(displayText) : displayText;

  return (
    <Text role={role} style={textStyle} {...props}>
      {decodedText}
    </Text>
  );
};

export default CustomText;

const styles = StyleSheet.create({
  /* eslint-disable react-native/no-unused-styles */
  textType: {
    text: {
      xs: { fontSize: 12, lineHeight: 18, fontFamily: "Inter" },
      s: { fontSize: 14, lineHeight: 20, fontFamily: "Inter" },
      m: { fontSize: 16, lineHeight: 24, fontFamily: "Inter" },
      l: { fontSize: 18, lineHeight: 28, fontFamily: "Inter" },
      xl: { fontSize: 20, lineHeight: 30, fontFamily: "Inter" },
      xxl: { fontSize: 24, lineHeight: 34, fontFamily: "Inter" },
    },
    display: {
      xs: { fontSize: 24, lineHeight: 32, fontFamily: "AbrilFatface" },
      s: { fontSize: 30, lineHeight: 38, fontFamily: "AbrilFatface" },
      m: { fontSize: 36, lineHeight: 44, fontFamily: "AbrilFatface" },
      l: { fontSize: 48, lineHeight: 60, fontFamily: "AbrilFatface" },
      xl: { fontSize: 60, lineHeight: 72, fontFamily: "AbrilFatface" },
      xxl: { fontSize: 72, lineHeight: 90, fontFamily: "AbrilFatface" },
    },
  },
  weight: {
    light: { fontWeight: "300" },
    normal: { fontWeight: "400" },
    medium: { fontWeight: "500" },
    bold: { fontWeight: "600" },
    ultraBold: { fontWeight: "700" },
  },
});
/* eslint-enable react-native/no-unused-styles */

const colorStyles = {
  dark: () => ({ color: "#2D2F36" }),
  light: () => ({ color: "#FFF" }),
  primary: (primaryColor) => ({ color: primaryColor }),
};
