import * as React from "react";
import Svg, { Path } from "react-native-svg";

function BlackMemoryIcon(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M23.25 11.625a5.26 5.26 0 00-3-4.745v-.13A4.5 4.5 0 0012 4.266 4.5 4.5 0 003.75 6.75v.13a5.25 5.25 0 000 9.488v.132A4.5 4.5 0 0012 18.983a4.5 4.5 0 008.25-2.483v-.132a5.258 5.258 0 003-4.743zm-15 7.875a3 3 0 01-2.982-2.678c.242.035.487.053.732.053h.75a.75.75 0 100-1.5H6a3.75 3.75 0 01-1.25-7.287.75.75 0 00.5-.707V6.75a3 3 0 116 0v6.4a4.481 4.481 0 00-3-1.15.75.75 0 100 1.5 3 3 0 110 6zM18 15.375h-.75a.75.75 0 100 1.5H18c.245 0 .49-.018.732-.053A3 3 0 1115.75 13.5a.75.75 0 100-1.5 4.481 4.481 0 00-3 1.15v-6.4a3 3 0 016 0v.63a.75.75 0 00.5.708A3.75 3.75 0 0118 15.375zm1.5-4.875a.75.75 0 01-.75.75h-.375A3.375 3.375 0 0115 7.875V7.5a.75.75 0 111.5 0v.375a1.875 1.875 0 001.875 1.875h.375a.75.75 0 01.75.75zm-13.875.75H5.25a.75.75 0 110-1.5h.375A1.875 1.875 0 007.5 7.875V7.5a.75.75 0 111.5 0v.375a3.375 3.375 0 01-3.375 3.375z"
        fill="#000000"
      />
    </Svg>
  );
}

export default BlackMemoryIcon;
