import React, { useState, useRef, useEffect } from "react";
import { View, Text, TouchableOpacity, Modal, FlatList, StyleSheet, Platform } from "react-native";
import CustomText from "../CustomText/CustomText";
import { DownArrow } from "../../../svgs/common";

interface CustomMultiSelectProps {
  data: { key: string; value: string }[];
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  label: string;
}

const CustomMultiSelect = ({ data, selectedItems, setSelectedItems, label }: CustomMultiSelectProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const isWeb = Platform.OS === "web";

  useEffect(() => {
    if (isWeb && isVisible && buttonRef.current) {
      buttonRef.current.measure?.((x, y, width, height, pageX, pageY) => {
        setDropdownPosition({
          top: pageY + height,
          left: pageX,
          width: width,
        });
      });
    }
  }, [isVisible, isWeb]);

  useEffect(() => {
    if (isWeb && isVisible) {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsVisible(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isVisible, isWeb]);

  const toggleCheckbox = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const renderItem = ({ item }) => {
    const isSelected = selectedItems?.includes(item.key);
    const isHovered = hoveredItem === item.key;

    const itemProps = isWeb
      ? {
          onMouseEnter: () => setHoveredItem(item.key),
          onMouseLeave: () => setHoveredItem(null),
        }
      : {};

    return (
      <TouchableOpacity
        style={[styles.item, isWeb && isHovered && styles.hoveredItem]}
        onPress={() => toggleCheckbox(item.key)}
        {...itemProps}>
        <View style={[styles.itemContainer, isSelected && { backgroundColor: "#EEF2F6" }]}>
          <Text style={styles.itemText}>{item.value}</Text>
          <View style={styles.checkboxContainer}>
            <View style={styles.checkbox}>{isSelected && <Text style={styles.checkmark}>✓</Text>}</View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderDropdownContent = () => (
    <View style={styles.dropdown}>
      <FlatList data={data} renderItem={renderItem} keyExtractor={(item) => item.key} style={styles.list} />
    </View>
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity ref={buttonRef} style={styles.button} onPress={() => setIsVisible((prev) => !prev)}>
        <CustomText text={label || ""} />
        <DownArrow />
      </TouchableOpacity>

      {isWeb ? (
        isVisible && (
          <View
            ref={dropdownRef}
            style={[
              styles.webDropdownContainer,
              {
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                zIndex: 9999,
              },
            ]}>
            {renderDropdownContent()}
          </View>
        )
      ) : (
        <Modal visible={isVisible} transparent animationType="slide" onRequestClose={() => setIsVisible(false)}>
          <TouchableOpacity style={styles.modalContainer} activeOpacity={1} onPress={() => setIsVisible(false)}>
            <TouchableOpacity activeOpacity={1} onPress={(e) => e.stopPropagation()} style={styles.modalContent}>
              {renderDropdownContent()}
            </TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      )}
    </View>
  );
};

export default CustomMultiSelect;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    zIndex: 50,
  },
  button: {
    padding: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#D0D5DD",
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.04,
    shadowRadius: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 8,
    overflow: "hidden",
  },
  webDropdownContainer: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
    backgroundColor: "#fff",
    zIndex: 9999,
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#D0D5DD",
    width: 325,
    marginTop: 10,
    padding: 8,
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 8,
    overflow: "hidden",
    gap: 8,
  },
  list: {
    maxHeight: 300,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 4,
    backgroundColor: "#FFF",
    fontSize: 12,
  },
  itemContainer: { padding: 4, width: "100%", flexDirection: "row", alignItems: "center", borderRadius: 4 },
  hoveredItem: {
    backgroundColor: "#EEF2F6",
  },
  itemText: {
    flex: 1,
    fontSize: 14,
    color: "#364152",
    fontWeight: "400",
  },
  checkboxContainer: {
    marginLeft: 12,
  },
  checkbox: {
    width: 20,
    height: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  checkmark: {
    color: "#364152",
    fontSize: 14,
    lineHeight: 20,
  },
});
