import React, { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Linking, Platform, Easing, TouchableOpacity } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BackButton from "../components/navigation/BackButton/BackButton";
import { useNavigation, useRoute, useFocusEffect, RouteProp } from "@react-navigation/native";
import { MotiView } from "moti";
import { searchWeb } from "../services";
import FileCard from "../components/lms/FileCard/FileCard";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import SourcesList from "../components/lms/SourcesList/SourcesList";
import ImageCardList from "../components/lms/ImageCardList/ImageCardList";
import VideoCardList from "../components/lms/VideoCardList/VideoCardList";
import Divider from "../components/common/layout/Divider/Divider";
import Loader from "../components/common/status/Loader/Loader";
import { useAppConfig } from "../AppConfigProvider";
import Sentry from "../utils/sentry";
import {
  FileStatus,
  StoragePathType,
  useGetAssignmentQuery,
  useGetAxioResourceQuery,
  useGetUserSubmissionsQuery,
  useSubmitStudentAssignmentMutation,
} from "../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import { useAppState } from "../contexts/AppStateContext";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import ActivityInstructionsModal from "../components/lms/ActivityInstructionsModal/ActivityInstructionsModal";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import PDFViewer from "../components/lms/PDFViewer/PDFViewer";
import YoutubeVideoPlayer from "../components/youtube/YoutubeVideoPlayer";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import NewCustomAxioActionModal from "../containers/settings/UsersTabContainer/NewCustomAxioActionModal";
import useFileUpload from "../hooks/useFileUpload";

function CustomResourceStudentView() {
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Custom Resource Student">>();
  const {
    resource_type: resourceType,
    resource_id: resourceId,
    course_id: courseId,
    integration_id: integrationId,
  } = route.params;

  const [submitStudentAssignment] = useSubmitStudentAssignmentMutation();

  const { isMedium } = useResponsiveScreen();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submissionConfirmationModalVisible, setSubmissionConfirmationModalVisible] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);

  const appConfig = useAppConfig();
  const isSuggestedInsights = appConfig.isSuggestedInsights;

  const [videoCardData, setVideoCardData] = useState([]);
  const [sources, setSources] = useState([]);
  const [imageCardData, setImageCardData] = useState([]);

  const { dispatch } = useAppState();

  const {
    data: axioResourceData,
    loading: loadingAxioResource,
    error: axioResourceError,
  } = useGetAxioResourceQuery({
    variables: { id: resourceId },
    skip: resourceType !== "axioResource",
  });

  const {
    data: assignmentData,
    loading: loadingAssignment,
    error: assignmentError,
  } = useGetAssignmentQuery({
    variables: { id: resourceId },
    skip: resourceType !== "assignment",
  });

  const { data: userSubmissionsData, refetch: refetchUserSubmissions } = useGetUserSubmissionsQuery({
    variables: { assignmentId: resourceId },
    skip: resourceType !== "assignment",
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (resourceType === "assignment") {
      setFiles([]);
      refetchUserSubmissions();
    }
  }, [resourceType, refetchUserSubmissions, resourceId]);

  useEffect(() => {
    if (userSubmissionsData?.getUserSubmissions?.data?.[0]?.files) {
      setFiles(userSubmissionsData?.getUserSubmissions?.data?.[0]?.files);
    }
  }, [userSubmissionsData]);

  const [selectedFile, setSelectedFile] = useState(null);

  useFocusEffect(
    useCallback(() => {
      // Reset media state when the component is focused
      dispatch({
        type: "SET_MEDIA",
        payload: {
          video: null,
          wiki: null,
        },
      });
      setShowVideo(true);

      return () => {
        setSelectedFile(null);
        setShowVideo(false);
        dispatch({
          type: "SET_MEDIA",
          payload: {
            video: null,
            wiki: null,
          },
        });
      };
    }, [dispatch])
  );

  useEffect(() => {
    if (
      (resourceType === "axioResource" && axioResourceData?.getAxioResource?.data?.[0].name) ||
      (resourceType === "assignment" && assignmentData?.getAssignment?.data?.[0].name)
    ) {
      const id =
        resourceType === "axioResource"
          ? axioResourceData?.getAxioResource?.data?.[0].id
          : assignmentData?.getAssignment?.data?.[0].id;
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true);
          const token = await AsyncStorage.getItem("token");
          const data = await searchWeb(id, token);

          const videos = data.video.map((item) => ({
            imageUrl: item.metadata.videoThumbnail,
            title: item.metadata.title,
            url: item.metadata.source,
          }));

          const images = data.images.flatMap((item) =>
            item.metadata.images.slice(0, 1).map((image) => ({
              imageUrl: image,
              title: item.metadata.title,
              source: item.metadata.source,
            }))
          );

          const wikipedia = data.Wikipedia.map((item) => ({
            content: item.pageContent,
            title: item.metadata.title,
            source: item.metadata.source,
          }));

          setVideoCardData(videos);
          setImageCardData(images);
          setSources(wikipedia);
          setIsLoadingSidebarData(false);
        } catch (error) {
          Sentry.captureException(error);
          setIsLoadingSidebarData(false);
        }
      };

      fetchData();
    }
  }, [axioResourceData, assignmentData, resourceType]);

  const handleBackPress = () => {
    navigation.navigate("Custom Course Student", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) return null;

    const isPDFFile = async (url) => {
      try {
        const response = await fetch(url, { method: "HEAD" });
        const contentType = response.headers.get("content-type");
        return contentType?.toLowerCase().includes("pdf");
      } catch (error) {
        console.error("Error checking file type:", error);
        return false;
      }
    };

    const handleFileClick = async (file) => {
      try {
        // const isPDF = await isPDFFile(file.fileURL);
        handleOpenURL(file.fileURL);
        // if (isPDF && Platform.OS === "web") {
        //   setSelectedFile(file);
        // } else {
        //   handleOpenURL(file.fileURL);
        // }
      } catch (error) {
        console.error("Error handling file click:", error);
        handleOpenURL(file.fileURL);
      }
    };

    return (
      <>
        <CustomText text={resourceType === "assignment" ? "Uploaded Assignments" : "Files"} size="m" weight="bold" />
        <CustomSpacing type="vertical" size="s" />
        <View style={styles.filesContainer}>
          {files.map((file) => (
            <FileCard
              key={file.fileId || file.id}
              title={file.fileName}
              url={file.fileURL}
              onPress={() => handleFileClick(file)}
            />
          ))}
        </View>
        {selectedFile && Platform.OS === "web" && (
          <>
            <CustomSpacing type="vertical" size="l" />
            <View style={styles.previewContainer}>
              <PDFViewer url={selectedFile.fileURL} />
            </View>
          </>
        )}
        <CustomSpacing type="vertical" size="xl" />
      </>
    );
  };

  const renderVideo = () => {
    const videoUrl =
      resourceType === "axioResource"
        ? axioResourceData?.getAxioResource?.data?.[0].videoUrl
        : assignmentData?.getAssignment?.data?.[0].videoUrl;
    const youtubeVideoId = extractYoutubeVideoId(videoUrl);
    if (youtubeVideoId && showVideo) {
      return <YoutubeVideoPlayer id={youtubeVideoId} />;
    }

    return (
      <CustomText
        text={videoUrl}
        useTranslationText={false}
        style={styles.linkText}
        onPress={() => handleOpenURL(videoUrl)}
      />
    );
  };

  const renderSidebar = () => {
    const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;

    if (!hasData) {
      return null;
    }

    return (
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={10}
        style={styles.sidebarContainer}
        // @ts-expect-error - This is a workaround to fix the type error
        transition={{ type: "timing", duration: 600 }}>
        {sources.length > 0 && (
          <>
            <CustomText text="Sources" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <SourcesList sources={sources} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {imageCardData.length > 0 && (
          <>
            <CustomText text="Images" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <ImageCardList imageCardData={imageCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {videoCardData.length > 0 && (
          <>
            <CustomText text="Videos" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <VideoCardList videoCardData={videoCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}
      </MotiView>
    );
  };

  const handleBeforeUpload = () => {
    setPendingSubmission(true);
  };

  const handleAfterUpload = ({ id, fileName, fileURL }) => {
    setFiles((prev) => [...prev, { id, fileName, fileURL, created: new Date(), status: FileStatus.Pending }]);
    setPendingSubmission(false);
  };

  const handleFileUploadError = () => {
    setPendingSubmission(false);
  };

  const { renderFileUpload } = useFileUpload({
    storagePath: StoragePathType.ResourceDocument,
    beforeUpload: handleBeforeUpload,
    afterUpload: handleAfterUpload,
    onError: handleFileUploadError,
  });

  const handleSubmitAssignment = async () => {
    try {
      await submitStudentAssignment({
        variables: {
          assignmentId: resourceId,
          files: files.map((file) => ({ fileId: file.id, fileName: file.fileName, fileURL: file.fileURL })),
        },
      });
      setSubmissionConfirmationModalVisible(true);
      setPendingSubmission(false);
    } catch (error) {
      console.error("Error submitting assignment:", error);
    }
  };

  const renderSkeletonLoader = () => (
    <View style={styles.skeletonContainer}>
      <View style={styles.detailsContainer}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <Skeleton width={40} height={40} circle={true} />
          <CustomSpacing type="horizontal" size="m" />
          <Skeleton width="60%" height={24} />
        </View>

        <CustomSpacing type="vertical" size="m" />

        <CustomSpacing type="vertical" size="l" />

        {/* Button Section */}
        <Skeleton width="30%" height={40} borderRadius={8} />

        <CustomSpacing type="vertical" size="l" />

        {/* Suggested Insights Section */}
        <Skeleton width="100%" height={200} borderRadius={12} />
      </View>

      {/* Sidebar Section */}
      <View style={styles.sidebarContainer}>
        <Skeleton width="80%" height={24} />
        <CustomSpacing type="vertical" size="s" />
        <Skeleton width="100%" height={150} borderRadius={8} />
      </View>
    </View>
  );

  const handleOpenURL = async (url) => {
    if (Platform.OS === "web") {
      try {
        const token = await AsyncStorage.getItem("token");

        // Create a form that posts to the URL
        const form = document.createElement("form");
        form.method = "POST";
        form.action = url;
        form.target = "_blank";

        // Add token as hidden field
        const tokenInput = document.createElement("input");
        tokenInput.type = "hidden";
        tokenInput.name = "token";
        tokenInput.value = token;
        form.appendChild(tokenInput);

        // Submit form
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      } catch (err) {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      }
    } else {
      // For mobile platforms
      try {
        const token = await AsyncStorage.getItem("token");

        // Make POST request
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `token=${encodeURIComponent(token)}`,
        });

        // Get the final URL after redirects
        const finalUrl = response.url;

        // Open the final URL in the device's browser
        await Linking.openURL(finalUrl);
      } catch (err) {
        console.error("Failed to open URL:", err);
        Sentry.captureException(err);
      }
    }
  };

  const extractYoutubeVideoId = (url: string): string | null => {
    const patterns = [
      /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^&\n?#]+)/,
      /^[a-zA-Z0-9_-]{11}$/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }
    return null;
  };

  if (axioResourceError || assignmentError) {
    return (
      <View style={styles.errorContainer}>
        <CustomText text="Failed to load Axio Resource data." color="red" />
      </View>
    );
  }

  return (
    <NoChatBarLayout>
      <ActivityInstructionsModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
      {loadingAxioResource || loadingAssignment ? (
        renderSkeletonLoader()
      ) : (
        <View style={isMedium ? styles.mainContainer : styles.mainContainerMobile}>
          <View style={isMedium ? styles.rowContainer : styles.columnContainer}>
            <View style={isMedium ? styles.contentContainer : styles.fullWidthContentContainer}>
              {/* Header Section */}
              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={10}
                // @ts-expect-error - This is a workaround to fix the type error
                transition={{ type: "timing", duration: 600 }}>
                <View style={styles.headerContainer}>
                  <BackButton onPress={handleBackPress} />
                  {resourceType === "axioResource" && axioResourceData?.getAxioResource?.data?.[0].name && (
                    <CustomText
                      useTranslationText={false}
                      text={axioResourceData?.getAxioResource?.data?.[0].name}
                      size={isMedium ? "xs" : "m"}
                      weight={isMedium ? "medium" : "bold"}
                    />
                  )}
                  {resourceType === "assignment" && assignmentData?.getAssignment?.data?.[0].name && (
                    <CustomText
                      useTranslationText={false}
                      text={assignmentData.getAssignment.data?.[0].name}
                      size={isMedium ? "xs" : "m"}
                      weight={isMedium ? "medium" : "bold"}
                    />
                  )}
                </View>
              </MotiView>

              <MotiView
                from={{
                  opacity: 0,
                  translateY: 20,
                  scale: 1,
                }}
                animate={{
                  opacity: 1,
                  translateY: 0,
                  scale: 1,
                }}
                delay={600}
                // @ts-expect-error - This is a workaround to fix the type error
                transition={{ type: "timing", duration: 600 }}
                style={{ paddingHorizontal: 30, paddingVertical: 10 }}>
                {resourceType === "assignment" && (
                  <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
                    <CustomText text="Due Date" size="m" weight="bold" useTranslationText={false} />
                    <CustomText text={": "} size="m" weight="bold" useTranslationText={false} />
                    <CustomText
                      text={assignmentData?.getAssignment?.data?.[0].due_date.split("T")[0]}
                      size="m"
                      weight="bold"
                      useTranslationText={false}
                    />
                  </View>
                )}
                <CustomSpacing type="vertical" size="s" />
                <CustomText text="Description" size="m" weight="bold" />
                <CustomSpacing type="vertical" size="s" />
                {resourceType === "axioResource" && axioResourceData?.getAxioResource?.data?.[0].description && (
                  <CustomHtml html={axioResourceData?.getAxioResource?.data?.[0].description} />
                )}
                {resourceType === "assignment" && assignmentData?.getAssignment?.data?.[0].description && (
                  <CustomHtml html={assignmentData?.getAssignment?.data?.[0].description} />
                )}

                <CustomSpacing type="vertical" size="xl" />

                {/* Files Section */}
                {renderFiles(
                  resourceType === "axioResource"
                    ? axioResourceData?.getAxioResource?.data?.[0].files
                    : assignmentData?.getAssignment?.data?.[0].files
                )}

                {/* Video URL Section */}
                {resourceType === "axioResource" && axioResourceData?.getAxioResource?.data?.[0].videoUrl && (
                  <>
                    <CustomText text="Video" size="m" weight="bold" />
                    <CustomSpacing type="vertical" size="s" />
                    {renderVideo()}
                    <CustomSpacing type="vertical" size="xl" />
                  </>
                )}
                {resourceType === "assignment" && assignmentData?.getAssignment?.data?.[0].videoUrl && (
                  <>
                    <CustomText text="Video" size="m" weight="bold" />
                    <CustomSpacing type="vertical" size="s" />
                    {renderVideo()}
                    <CustomSpacing type="vertical" size="xl" />
                  </>
                )}
                {resourceType === "assignment" && assignmentData?.getAssignment?.data?.[0].enableStudentFileUpload && (
                  <>
                    <CustomText text="Upload Your Assignment" size="m" weight="bold" />
                    <CustomSpacing type="vertical" size="s" />
                    {renderFileUpload()}
                    <CustomSpacing type="vertical" size="s" />
                    {renderFiles(files)}
                    <CustomSpacing type="vertical" size="s" />
                    {userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted ? (
                      <CustomText
                        text={"You have already submitted your assignment. You can not change your submission."}
                        size="m"
                        weight="bold"
                      />
                    ) : (
                      <CustomText
                        text={
                          "You can submit your assignment once. You can not change your submission once you have submitted."
                        }
                        size="m"
                        weight="bold"
                      />
                    )}
                    <CustomSpacing type="vertical" size="s" />
                    <CustomButton
                      styleType="primary"
                      disabled={pendingSubmission || userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted}
                      text="Submit Assignment"
                      size="m"
                      weight="bold"
                      onPress={handleSubmitAssignment}
                      style={
                        pendingSubmission || userSubmissionsData?.getUserSubmissions?.data?.[0]?.isCompleted
                          ? { backgroundColor: "#ccc" }
                          : {}
                      }
                    />
                  </>
                )}
              </MotiView>

              <CustomSpacing type="vertical" size="l" />
            </View>

            {/* Sidebar Section */}
            {isSuggestedInsights && (
              <>
                {isMedium ? (
                  <View style={[{ width: 300 }, isLoadingSidebarData ? { alignItems: "center" } : {}]}>
                    <CustomText
                      style={{ margin: 12 }}
                      textType="display"
                      text="Suggested Insights"
                      size="xs"
                      bold={false}
                      role="heading"
                      aria-level="1"
                    />
                    {isLoadingSidebarData ? (
                      <View style={styles.insightsCenterView}>
                        <Loader />
                      </View>
                    ) : (
                      <>{renderSidebar()}</>
                    )}
                  </View>
                ) : (
                  <>
                    <Divider size="l" />
                    <CustomText
                      textType="display"
                      text="Suggested Insights"
                      size="xs"
                      bold={true}
                      weight="bold"
                      role="heading"
                      aria-level="1"
                    />
                    <View style={isLoadingSidebarData ? { alignItems: "center" } : {}}>
                      {isLoadingSidebarData ? (
                        <View style={styles.fullWidthContentContainer}>
                          <Loader />
                        </View>
                      ) : (
                        <>{renderSidebar()}</>
                      )}
                    </View>
                  </>
                )}
              </>
            )}
          </View>
        </View>
      )}
      <NewCustomAxioActionModal
        visible={submissionConfirmationModalVisible}
        onClose={() => setSubmissionConfirmationModalVisible(false)}
        modalTexts={{
          title: "Submission Confirmation",
          warningText: "You have successfully submitted your assignment.",
          ariaLabel: "Submission Confirmation",
          actionButtonText: "Close",
        }}
      />
    </NoChatBarLayout>
  );
}

const styles = StyleSheet.create({
  skeletonContainer: {
    flex: 1,
    flexDirection: "row", // Reflects the row layout in the loaded UI
    justifyContent: "space-between",
  },
  // Details container for central content
  detailsContainer: {
    flex: 3, // Takes 3/4 of the width for the main content
    paddingRight: 16,
  },
  mainContainerMobile: {
    height: 20,
  },
  mainContainer: {
    // flex: 1,
    height: 20,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
  },
  columnContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fullWidthContentContainer: {
    padding: 16,
    // height: 20,
  },
  contentContainer: {
    flex: 3, // Takes 3/4 of the width
    padding: 16,
  },
  headerText: {
    marginBottom: 6,
  },
  sidebarContainer: {
    flex: 1, // Takes 1/4 of the width
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  insightsCenterView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  filesContainer: {
    flexDirection: "column",
    gap: 10,
    maxWidth: 320,
  },
  previewContainer: {
    minHeight: 600,
    borderWidth: 1,
    borderColor: "#D9D9D9",
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  linkText: {
    color: "blue",
    textDecorationLine: "underline",
    textAlign: "left",
    borderWidth: 0,
  },
  // Error Container for Error Handling
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CustomResourceStudentView;
