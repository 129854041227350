import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";
import {
  useGetCoursesSummaryBySchoolQuery,
  useGetCourseAnalyticsByIdQuery,
  useGetModuleAnalyticsByCourseQuery,
  useGetActivityAnalyticsByModuleQuery,
  useGetObjectiveAnalyticsByActivityQuery,
  useGetCourseCompletionSummaryBySchoolQuery,
  useGetCourseCompletionDetailsQuery,
  useGetModuleCompletionDetailsQuery,
  useGetActivityCompletionDetailsQuery,
  useSchoolsQuery,
  useGetCourseHierarchyQuery,
} from "../graphql/generated/graphql";
import NoChatBarLayout from "../layouts/NoChatBarLayout.web";
import BubbleChartContainer from "../containers/analytics/BubbleChartContainer/BubbleChartContainer";
import AccordionAnalyticsContainer from "../containers/analytics/AccordionAnalyticsContainer.js/AccordionAnalyticsContainer";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AnalyticsSearchBar from "../components/analytics/AnalyticsSearchBar/AnalyticsSearchBar";
import { MotiView } from "moti";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import ActivityInsights from "../components/analytics/ActivityInsights/ActivityInsights";

// GraphQL logging helper
const logQuery = (queryName, variables, data) => {
  console.log(`GraphQL Query Executed: ${queryName}`, {
    variables,
    results: data ? `Received data with ${Array.isArray(data) ? data.length : 1} items` : "No data received",
  });
};

const computeGlobalExtremes = (data) => {
  let maxAbsX = 0;
  let maxAbsY = 0;
  const processItem = (item) => {
    const s = item.success_points || 0;
    const c = item.confusion_points || 0;
    const q = item.questions_asked || 0;
    const a = item.active_interaction_count || 0;
    const d = item.distractions || 0;
    const xVal = s - c;
    const yVal = q + a - d;
    maxAbsX = Math.max(maxAbsX, Math.abs(xVal));
    maxAbsY = Math.max(maxAbsY, Math.abs(yVal));
    if (item.details) {
      ["modules", "activities", "objectives"].forEach((key) => {
        if (item.details[key]) {
          item.details[key].forEach(processItem);
        }
      });
    }
  };
  data.forEach(processItem);
  return { maxAbsX: Math.max(1, maxAbsX), maxAbsY: Math.max(1, maxAbsY) };
};

const mapDataWithGlobalScale = (data, level, globalScale) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    console.warn(`No valid data to map for level: ${level}`);
    return [];
  }
  const { maxAbsX, maxAbsY } = globalScale;
  return data.map((item, index) => {
    const s = item.success_points || 0;
    const c = item.confusion_points || 0;
    const q = item.questions_asked || 0;
    const a = item.active_interaction_count || 0;
    const d = item.distractions || 0;
    const xVal = ((s - c) / maxAbsX) * 10;
    const yVal = ((q + a - d) / maxAbsY) * 10;
    const sentiment = item.sentiment || "neutral";
    const enrollment = item.enrollment || 1;
    return {
      x: xVal,
      y: yVal,
      size: Math.max((enrollment / 300) * 30, 10),
      color:
        sentiment === "positive"
          ? "#85E139"
          : sentiment === "mixed"
            ? "#0084FF"
            : sentiment === "negative"
              ? "#F63D68"
              : "#0084FF",
      label: item.label || `Item ${index + 1}`,
      level,
      id: item.id,
      ...item,
    };
  });
};

const mergeCompletionData = (analyticsData, completionData) => {
  if (!analyticsData || !completionData || !completionData.length) {
    return analyticsData;
  }
  return analyticsData.map((item) => {
    const completionItem = completionData.find((c) => c.id === item.id);
    if (completionItem) {
      return { ...item, completionStats: completionItem.completionStats };
    }
    return item;
  });
};

const AnalyticsView = () => {
  const { data: schoolsData } = useSchoolsQuery();
  const schoolId = schoolsData?.getSchools[0]?.id;

  // New state to hold the current course details (for the label)
  const [currentCourse, setCurrentCourse] = useState(null);

  // State tracking – we no longer cache data; we re-fetch fresh data every time
  const [viewState, setViewState] = useState({
    level: "course", // course, module, activity, objective
    courseId: null,
    moduleId: null,
    activityId: null,
  });
  const [currentData, setCurrentData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [globalScale, setGlobalScale] = useState({ maxAbsX: 10, maxAbsY: 10 });
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [expandedItemIds, setExpandedItemIds] = useState({});
  const [childrenData, setChildrenData] = useState({});
  const [loadingChildren, setLoadingChildren] = useState({});
  const [dataLoaded, setDataLoaded] = useState({
    course: false,
    module: false,
    activity: false,
    objective: false,
  });

  const containerRef = useRef(null);
  const route = useRoute();
  const params = route.params;

  // Extract refetch functions to always re-fetch fresh data
  const {
    data: courseDetailData,
    loading: courseDetailLoading,
    refetch: refetchCourseDetail,
  } = useGetCourseAnalyticsByIdQuery({
    variables: { courseId: viewState.courseId },
    skip: !viewState.courseId,
    onCompleted: (data) => {
      logQuery("getCourseAnalyticsById", { courseId: viewState.courseId }, data?.getCourseAnalyticsById?.data);
      setDataLoaded((prev) => ({ ...prev, course: true }));
    },
  });

  const {
    data: moduleData,
    loading: moduleLoading,
    refetch: refetchModuleData,
  } = useGetModuleAnalyticsByCourseQuery({
    variables: { courseId: viewState.courseId },
    skip: !viewState.courseId || viewState.level !== "module",
    onCompleted: (data) => {
      logQuery("getModuleAnalyticsByCourse", { courseId: viewState.courseId }, data?.getModuleAnalyticsByCourse?.data);
      setDataLoaded((prev) => ({ ...prev, module: true }));
    },
  });

  const {
    data: activityData,
    loading: activityLoading,
    refetch: refetchActivityData,
  } = useGetActivityAnalyticsByModuleQuery({
    variables: { moduleId: viewState.moduleId },
    skip: !viewState.moduleId || viewState.level !== "activity",
    onCompleted: (data) => {
      logQuery(
        "getActivityAnalyticsByModule",
        { moduleId: viewState.moduleId },
        data?.getActivityAnalyticsByModule?.data
      );
      setDataLoaded((prev) => ({ ...prev, activity: true }));
    },
  });

  const {
    data: objectiveData,
    loading: objectiveLoading,
    refetch: refetchObjective,
  } = useGetObjectiveAnalyticsByActivityQuery({
    variables: { aiTutorId: viewState.activityId },
    skip: !viewState.activityId || viewState.level !== "objective",
    onCompleted: (data) => {
      logQuery(
        "getObjectiveAnalyticsByActivity",
        { aiTutorId: viewState.activityId },
        data?.getObjectiveAnalyticsByActivity?.data
      );
      setDataLoaded((prev) => ({ ...prev, objective: true }));
    },
  });

  const {
    data: courseSummaryData,
    loading: courseSummaryLoading,
    refetch: refetchCourseSummary,
  } = useGetCoursesSummaryBySchoolQuery({
    variables: { schoolId },
    // We always want to fetch summary when at course level
    skip: !schoolId || viewState.level !== "course" || !!viewState.courseId,
    onCompleted: (data) => {
      logQuery("getCoursesSummaryBySchool", { schoolId }, data?.getCoursesSummaryBySchool?.data);
    },
  });

  const {
    data: completionData,
    loading: completionLoading,
    refetch: refetchCompletionSummary,
  } = useGetCourseCompletionSummaryBySchoolQuery({
    variables: { schoolId },
    skip: !schoolId || viewState.level !== "course" || !!viewState.courseId,
    onCompleted: (data) => {
      logQuery("getCourseCompletionSummaryBySchool", { schoolId }, data?.getCourseCompletionSummaryBySchool?.data);
    },
  });

  const {
    data: courseCompletionDetailData,
    loading: courseCompletionDetailLoading,
    refetch: refetchCourseCompletionDetail,
  } = useGetCourseCompletionDetailsQuery({
    variables: { courseId: viewState.courseId },
    skip: !viewState.courseId,
    onCompleted: (data) => {
      logQuery("getCourseCompletionDetails", { courseId: viewState.courseId }, data?.getCourseCompletionDetails?.data);
    },
  });

  const {
    data: moduleCompletionData,
    loading: moduleCompletionLoading,
    refetch: refetchModuleCompletion,
  } = useGetModuleCompletionDetailsQuery({
    variables: { moduleId: viewState.moduleId },
    skip: !viewState.moduleId,
    onCompleted: (data) => {
      logQuery("getModuleCompletionDetails", { moduleId: viewState.moduleId }, data?.getModuleCompletionDetails?.data);
    },
  });

  const {
    data: activityCompletionData,
    loading: activityCompletionLoading,
    refetch: refetchActivityCompletion,
  } = useGetActivityCompletionDetailsQuery({
    variables: { aiTutorId: viewState.activityId },
    skip: !viewState.activityId,
    onCompleted: (data) => {
      logQuery(
        "getActivityCompletionDetails",
        { aiTutorId: viewState.activityId },
        data?.getActivityCompletionDetails?.data
      );
    },
  });

  // Remove objectives since we don't want them in the search hierarchy
  const { data: hierarchyData, loading: hierarchyLoading } = useGetCourseHierarchyQuery({
    variables: { schoolId },
    skip: !schoolId,
    onCompleted: (data) => {
      logQuery("getCourseHierarchy", { schoolId }, data?.getCourseHierarchy?.data);
    },
  });

  // Process the hierarchy data to remove objectives for the search bar
  const searchHierarchyData = useMemo(() => {
    if (!hierarchyData?.getCourseHierarchy?.data) return [];

    // Create a deep copy and modify it to exclude objectives
    return hierarchyData.getCourseHierarchy.data.map((course) => ({
      id: course.id,
      label: course.label,
      level: course.level,
      details: {
        modules: course.details.modules.map((module) => ({
          id: module.id,
          label: module.label,
          level: module.level,
          parentCourse: module.parentCourse,
          details: {
            activities: module.details.activities.map((activity) => ({
              id: activity.id,
              label: activity.label,
              level: activity.level,
              parentModule: activity.parentModule,
              parentCourse: activity.parentCourse,
              // Exclude objectives by not including them in this object
              details: {}, // Empty object or null instead of activity.details
            })),
          },
        })),
      },
    }));
  }, [hierarchyData]);

  const isLoading =
    courseSummaryLoading ||
    completionLoading ||
    courseDetailLoading ||
    moduleLoading ||
    activityLoading ||
    objectiveLoading ||
    courseCompletionDetailLoading ||
    moduleCompletionLoading ||
    hierarchyLoading ||
    activityCompletionLoading;

  // Function to reset data loading state
  const resetDataLoaded = (level) => {
    const newState = { ...dataLoaded };

    // Reset all levels higher than the current one
    if (level === "course" || !level) {
      newState.course = false;
      newState.module = false;
      newState.activity = false;
      newState.objective = false;
    } else if (level === "module") {
      newState.module = false;
      newState.activity = false;
      newState.objective = false;
    } else if (level === "activity") {
      newState.activity = false;
      newState.objective = false;
    } else if (level === "objective") {
      newState.objective = false;
    }

    setDataLoaded(newState);
  };

  // Refetch all data for current view state
  const refetchCurrentView = useCallback(async () => {
    switch (viewState.level) {
      case "course":
        if (viewState.courseId) {
          console.log("Refetching course detail data:", viewState.courseId);
          await refetchCourseDetail({ courseId: viewState.courseId });
          await refetchCourseCompletionDetail({ courseId: viewState.courseId });
        } else {
          console.log("Refetching all courses data");
          await refetchCourseSummary({ schoolId });
          await refetchCompletionSummary({ schoolId });
        }
        break;
      case "module":
        console.log("Refetching module data for course:", viewState.courseId);
        await refetchModuleData({ courseId: viewState.courseId });
        if (viewState.moduleId) {
          await refetchModuleCompletion({ moduleId: viewState.moduleId });
        }
        break;
      case "activity":
        console.log("Refetching activity data for module:", viewState.moduleId);
        await refetchActivityData({ moduleId: viewState.moduleId });
        if (viewState.activityId) {
          await refetchActivityCompletion({ aiTutorId: viewState.activityId });
        }
        break;
      case "objective":
        console.log("Refetching objective data for activity:", viewState.activityId);
        await refetchObjective({ aiTutorId: viewState.activityId });
        break;
    }
  }, [viewState, schoolId]);

  // Process course summary data (initial view)
  useEffect(() => {
    if (
      viewState.level === "course" &&
      !viewState.courseId &&
      courseSummaryData?.getCoursesSummaryBySchool?.data &&
      !courseSummaryLoading
    ) {
      console.log("Processing course summary data");
      const courseData = courseSummaryData.getCoursesSummaryBySchool.data;
      const scale = computeGlobalExtremes(courseData);
      setGlobalScale(scale);
      let processedData = courseData;
      if (completionData?.getCourseCompletionSummaryBySchool?.data) {
        processedData = mergeCompletionData(courseData, completionData.getCourseCompletionSummaryBySchool.data);
      }
      const transformedData = mapDataWithGlobalScale(processedData, "course", scale);
      setCurrentData(transformedData);
      setBreadcrumb([]);
      setInitialized(true);
    }
  }, [viewState.level, viewState.courseId, courseSummaryData, completionData, courseSummaryLoading]);

  // Process course detail data and capture current course
  useEffect(() => {
    if (
      viewState.courseId &&
      courseDetailData?.getCourseAnalyticsById?.data &&
      !courseDetailLoading &&
      dataLoaded.course
    ) {
      console.log("Processing course detail data for ID:", viewState.courseId);
      const courseDetail = courseDetailData.getCourseAnalyticsById.data[0];
      if (courseDetail && courseDetail.details?.modules) {
        let modules = courseDetail.details.modules;
        if (courseCompletionDetailData?.getCourseCompletionDetails?.data) {
          const courseCompletionDetail = courseCompletionDetailData.getCourseCompletionDetails.data[0];
          if (courseCompletionDetail?.details?.modules) {
            modules = modules.map((module) => {
              const completionModule = courseCompletionDetail.details.modules.find((m) => m.id === module.id);
              return completionModule ? { ...module, completionStats: completionModule.completionStats } : module;
            });
          }
        }
        const transformedData = mapDataWithGlobalScale(modules, "module", globalScale);
        setCurrentData(transformedData);
        // Set current course details so its label is available
        setCurrentCourse(courseDetail);
        // Only set breadcrumb if it's empty or the top level doesn't match
        if (breadcrumb.length === 0 || breadcrumb[0]?.id !== courseDetail.id) {
          setBreadcrumb([{ label: courseDetail.label, level: "course", id: courseDetail.id }]);
        }
      }
    }
  }, [
    viewState.courseId,
    courseDetailData,
    courseCompletionDetailData,
    globalScale,
    courseDetailLoading,
    dataLoaded.course,
  ]);

  // Process module data and build breadcrumb using currentCourse if available
  useEffect(() => {
    if (viewState.level === "module" && viewState.moduleId && dataLoaded.module) {
      console.log("Processing module data for module ID:", viewState.moduleId);
      if (moduleData?.getModuleAnalyticsByCourse?.data) {
        const modules = moduleData.getModuleAnalyticsByCourse.data;
        const selectedModule = modules.find((m) => m.id === viewState.moduleId);
        if (selectedModule && selectedModule.details?.activities) {
          let activities = selectedModule.details.activities;
          if (moduleCompletionData?.getModuleCompletionDetails?.data) {
            const moduleCompletionDetail = moduleCompletionData.getModuleCompletionDetails.data[0];
            if (moduleCompletionDetail?.details?.activities) {
              activities = activities.map((activity) => {
                const completionActivity = moduleCompletionDetail.details.activities.find((a) => a.id === activity.id);
                return completionActivity
                  ? { ...activity, completionStats: completionActivity.completionStats }
                  : activity;
              });
            }
          }
          const transformedData = mapDataWithGlobalScale(activities, "activity", globalScale);
          setCurrentData(transformedData);
          // Build breadcrumb as [currentCourse, module]
          const courseItem = currentCourse
            ? { label: currentCourse.label, level: "course", id: currentCourse.id }
            : { label: "", id: viewState.courseId, level: "course" };

          // Only update breadcrumb if needed
          if (breadcrumb.length < 2 || breadcrumb[1]?.id !== selectedModule.id) {
            setBreadcrumb([courseItem, { label: selectedModule.label, level: "module", id: selectedModule.id }]);
          }
        }
      }
    }
  }, [
    viewState.level,
    viewState.moduleId,
    moduleData,
    moduleCompletionData,
    globalScale,
    moduleLoading,
    currentCourse,
    dataLoaded.module,
  ]);

  // Process activity data and build breadcrumb as [currentCourse, currentModule, activity]
  useEffect(() => {
    if (viewState.level === "activity" && viewState.activityId && dataLoaded.activity) {
      console.log("Processing activity data");
      if (activityData?.getActivityAnalyticsByModule?.data) {
        const activities = activityData.getActivityAnalyticsByModule.data;
        const selectedActivityData = activities.find((a) => a.id === viewState.activityId);
        if (selectedActivityData && selectedActivityData.details?.objectives) {
          let objectives = selectedActivityData.details.objectives.map((obj) => ({ ...obj }));
          if (activityCompletionData?.getActivityCompletionDetails?.data) {
            const activityCompletionDetail = activityCompletionData.getActivityCompletionDetails.data[0];
            if (activityCompletionDetail?.details?.objectives) {
              objectives = objectives.map((objective) => {
                const completionObjective = activityCompletionDetail.details.objectives.find(
                  (o) => o.id === objective.id
                );
                return completionObjective
                  ? { ...objective, completionStats: completionObjective.completionStats }
                  : objective;
              });
            }
            setSelectedActivity({
              ...selectedActivityData,
              completionStats: activityCompletionDetail.completionStats,
            });
          } else {
            setSelectedActivity({ ...selectedActivityData });
          }
          const transformedData = mapDataWithGlobalScale(objectives, "objective", globalScale);
          setCurrentData(transformedData);
          // Build breadcrumb using currentCourse and current module
          const courseItem = currentCourse
            ? { label: currentCourse.label, level: "course", id: currentCourse.id }
            : { label: "", id: viewState.courseId, level: "course" };
          const moduleItem = moduleData?.getModuleAnalyticsByCourse?.data
            ? moduleData.getModuleAnalyticsByCourse.data.find((m) => m.id === viewState.moduleId) || {
                label: "",
                id: viewState.moduleId,
                level: "module",
              }
            : { label: "", id: viewState.moduleId, level: "module" };

          // Only update breadcrumb if needed
          if (breadcrumb.length < 3 || breadcrumb[2]?.id !== selectedActivityData.id) {
            setBreadcrumb([
              courseItem,
              { label: moduleItem.label, level: "module", id: moduleItem.id },
              { label: selectedActivityData.label, level: "activity", id: selectedActivityData.id },
            ]);
          }
        }
      }
    }
  }, [
    viewState.level,
    viewState.activityId,
    activityData,
    activityCompletionData,
    globalScale,
    activityLoading,
    currentCourse,
    moduleData,
    dataLoaded.activity,
  ]);

  // Clear children data on navigation to force re-fetching every time
  const clearChildrenData = () => {
    setChildrenData({});
    setExpandedItemIds({});
  };

  // Handle route params for direct navigation
  useFocusEffect(
    useCallback(() => {
      if (initialized && params?.courseId) {
        console.log("Direct navigation to course:", params.courseId);
        clearChildrenData();
        resetDataLoaded("course");
        setViewState({ level: "course", courseId: params.courseId, moduleId: null, activityId: null });
      }
    }, [params?.courseId, initialized])
  );

  // Handle drill down clicks (forwards navigation)
  const handleDrillDown = (clickedItem) => {
    if (!clickedItem || !clickedItem.level) {
      console.warn("Invalid item clicked:", clickedItem);
      return;
    }
    console.log("Drill down clicked:", clickedItem.level, clickedItem.id, clickedItem.label);
    clearChildrenData();

    if (clickedItem.level === "course") {
      resetDataLoaded("course");
      setBreadcrumb([{ label: clickedItem.label, level: "course", id: clickedItem.id }]);
      setViewState({ level: "course", courseId: clickedItem.id, moduleId: null, activityId: null });
    } else if (clickedItem.level === "module") {
      resetDataLoaded("module");
      // Build breadcrumb as [currentCourse, module]
      const courseItem = currentCourse
        ? { label: currentCourse.label, level: "course", id: currentCourse.id }
        : { label: "", id: viewState.courseId, level: "course" };
      setBreadcrumb([courseItem, { label: clickedItem.label, level: "module", id: clickedItem.id }]);
      setViewState((prev) => ({
        level: "module",
        courseId: prev.courseId,
        moduleId: clickedItem.id,
        activityId: null,
      }));
    } else if (clickedItem.level === "activity") {
      resetDataLoaded("activity");

      // Check if we have parentModule information from the search
      const parentModule = clickedItem.parentModule;

      // Build breadcrumb as [currentCourse, parentModule/currentModule, activity]
      const courseItem = currentCourse
        ? { label: currentCourse.label, level: "course", id: currentCourse.id }
        : clickedItem.parentCourse
          ? { label: clickedItem.parentCourse.label, id: clickedItem.parentCourse.id, level: "course" }
          : { label: "", id: viewState.courseId, level: "course" };

      // Use parentModule from clickedItem if available, otherwise try to find from moduleData
      const moduleItem = parentModule
        ? { label: parentModule.label, level: "module", id: parentModule.id }
        : moduleData?.getModuleAnalyticsByCourse?.data
          ? moduleData.getModuleAnalyticsByCourse.data.find((m) => m.id === viewState.moduleId) || {
              label: "",
              id: viewState.moduleId,
              level: "module",
            }
          : { label: "", id: viewState.moduleId, level: "module" };

      setBreadcrumb([courseItem, moduleItem, { label: clickedItem.label, level: "activity", id: clickedItem.id }]);

      setViewState((prev) => ({
        level: "activity",
        courseId: courseItem.id || prev.courseId,
        moduleId: moduleItem.id || prev.moduleId,
        activityId: clickedItem.id,
      }));
    }
  };

  // Handle breadcrumb navigation (backwards)
  const handleBreadcrumbClick = (index) => {
    if (index < 0 || index >= breadcrumb.length) return;
    clearChildrenData();
    const target = breadcrumb[index];
    console.log("Breadcrumb click:", target.level, target.id);
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    setBreadcrumb(newBreadcrumb);

    if (target.level === "course") {
      resetDataLoaded("course");
      setViewState({ level: "course", courseId: target.id, moduleId: null, activityId: null });
    } else if (target.level === "module") {
      resetDataLoaded("module");
      setViewState({ level: "module", courseId: breadcrumb[0].id, moduleId: target.id, activityId: null });
    } else if (target.level === "activity") {
      resetDataLoaded("activity");
      setViewState({
        level: "activity",
        courseId: breadcrumb[0].id,
        moduleId: breadcrumb[1].id,
        activityId: target.id,
      });
    }
  };

  // Back to all courses
  const handleBackToAllCourses = () => {
    clearChildrenData();
    resetDataLoaded();
    setViewState({ level: "course", courseId: null, moduleId: null, activityId: null });
    setBreadcrumb([]);
    setSelectedActivity(null);
  };

  // Effect to mark data as loaded after viewState changes
  useEffect(() => {
    if (viewState.level === "course" && viewState.courseId) {
      setDataLoaded((prev) => ({ ...prev, course: true }));
    } else if (viewState.level === "module" && viewState.moduleId) {
      setDataLoaded((prev) => ({ ...prev, module: true }));
    } else if (viewState.level === "activity" && viewState.activityId) {
      setDataLoaded((prev) => ({ ...prev, activity: true }));
    }
  }, [viewState]);

  // loadChildData always re-fetches fresh data based on level
  const loadChildData = async (itemId, level) => {
    setLoadingChildren((prev) => ({ ...prev, [itemId]: true }));
    try {
      console.log(`Loading children for ${level} with ID ${itemId}`);
      if (level === "course") {
        const result = await refetchCourseDetail({ courseId: itemId });
        if (result.data?.getCourseAnalyticsById?.data?.[0]?.details?.modules) {
          const modules = result.data.getCourseAnalyticsById.data[0].details.modules;
          setChildrenData((prev) => ({ ...prev, [itemId]: modules }));
        }
      } else if (level === "module") {
        const result = await refetchActivityData({ moduleId: itemId });
        if (result.data?.getActivityAnalyticsByModule?.data) {
          const activities = result.data.getActivityAnalyticsByModule.data;
          setChildrenData((prev) => ({ ...prev, [itemId]: activities }));
        }
      } else if (level === "activity") {
        const result = await refetchObjective({ aiTutorId: itemId });
        if (result.data?.getObjectiveAnalyticsByActivity?.data) {
          const objectives = result.data.getObjectiveAnalyticsByActivity.data;
          setChildrenData((prev) => ({ ...prev, [itemId]: objectives }));
        }
      }
    } catch (error) {
      console.error(`Error loading children for ${level} ${itemId}:`, error);
    } finally {
      setLoadingChildren((prev) => ({ ...prev, [itemId]: false }));
    }
  };

  const titleText =
    viewState.level === "activity"
      ? "Activity Insights"
      : `${viewState.level.charAt(0).toUpperCase() + viewState.level.slice(1)}s`;

  const hasValidData = currentData && Array.isArray(currentData) && currentData.length > 0;

  return (
    <NoChatBarLayout>
      <MotiView
        from={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <CustomText useTranslationText={false} size="m" textType="display" text="Analytics" weight="ultraBold" />
      </MotiView>
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.searchContainer}>
        <AnalyticsSearchBar
          data={searchHierarchyData}
          mergedData={searchHierarchyData}
          onDrillDown={handleDrillDown}
          searchTypeDefault="all"
          defaultSearchText=""
          isLoading={isLoading || hierarchyLoading}
          error={null}
        />
      </View>
      <View style={styles.contentContainer}>
        <CustomSpacing type="vertical" size="xl" />
        <View style={styles.breadcrumb}>
          <TouchableOpacity onPress={handleBackToAllCourses}>
            <CustomText
              useTranslationText={false}
              size="s"
              text="All Courses"
              style={[styles.breadcrumbText, styles.breadcrumbLink]}
            />
          </TouchableOpacity>
          {breadcrumb.map((crumb, index) => (
            <React.Fragment key={index}>
              <CustomText useTranslationText={false} size="s" text=" > " style={styles.breadcrumbText} />
              <TouchableOpacity onPress={() => handleBreadcrumbClick(index)}>
                <CustomText
                  useTranslationText={false}
                  size="s"
                  text={crumb.label}
                  style={[styles.breadcrumbText, styles.breadcrumbLink]}
                />
              </TouchableOpacity>
            </React.Fragment>
          ))}
        </View>
        <CustomText
          size="xl"
          text="Breakdown & Stats"
          weight="bold"
          useTranslationText={false}
          style={{ paddingBottom: 20 }}
        />
        <View style={styles.container} ref={containerRef}>
          {isLoading ? (
            <View style={styles.loadingContainer}>
              <CustomText useTranslationText={false} textType="text" text="Loading analytics data..." size="m" />
            </View>
          ) : !hasValidData ? (
            <CustomText useTranslationText={false} textType="text" text="No analytics data available" size="m" />
          ) : viewState.level === "activity" && selectedActivity ? (
            <ActivityInsights activityData={currentData} parentActivity={selectedActivity} />
          ) : (
            <>
              <BubbleChartContainer data={currentData} onDrillDown={handleDrillDown} />
              <CustomSpacing type="vertical" size="xxl" />
              <CustomText size="xl" text={titleText} weight="bold" useTranslationText={false} />
              <AccordionAnalyticsContainer
                data={currentData}
                onListDrillDown={handleDrillDown}
                breadcrumb={breadcrumb}
                loadChildData={loadChildData}
                expandedItemIds={expandedItemIds}
                setExpandedItemIds={setExpandedItemIds}
                childrenData={childrenData}
                setChildrenData={setChildrenData}
                loadingChildren={loadingChildren}
                setLoadingChildren={setLoadingChildren}
              />
            </>
          )}
        </View>
      </View>
    </NoChatBarLayout>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    position: "relative",
    zIndex: 1,
  },
  searchContainer: {
    position: "relative",
    zIndex: 1000,
    marginBottom: 16,
  },
  breadcrumb: {
    flexDirection: "row",
    marginBottom: 10,
    position: "relative",
    zIndex: 1,
    flexWrap: "wrap",
    alignItems: "center",
  },
  breadcrumbText: {
    color: "#6B7280",
    fontWeight: "500",
  },
  breadcrumbLink: {
    color: "#0084FF",
    textDecorationLine: "underline",
  },
  container: {
    flex: 1,
    width: "100%",
    position: "relative",
    zIndex: 1,
  },
  loadingContainer: {
    padding: 40,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AnalyticsView;
