import React, { useEffect, useRef, useState } from "react";
import { FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import { actions, RichEditor, RichToolbar } from "react-native-pell-rich-editor";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { Switch } from "@rneui/themed";
import { File, Remove } from "../../svgs/CustomCoursework";
import {
  StoragePathType,
  useCreateVisualizationFromContentMutation,
  useDeleteVisualizationMutation,
  useGenerateAiTutorFromTitleMutation,
  useGenerateEvalCriteriaMutation,
  useGetVisualizationQuery,
  useUpdateVisualizationMutation,
} from "../../../graphql/generated/graphql";
import LoadingOverlay from "../../common/status/LoadingOverlay/LoadingOverlay";
import WebView from "react-native-webview";
import { DatePickerInput } from "react-native-paper-dates";
// HEAD:frontend/components/customCoursework/EditActivity/EditActivity.js
import VideoPoolManager from "../../../containers/video/VideoPoolManager";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import { gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
//
import useFileUpload from "../../../hooks/useFileUpload";
//master:frontend/components/customCoursework/EditActivity/EditActivity.tsx

// Add declaration for window.__lastGeneratedObjectives
declare global {
  interface Window {
    __lastGeneratedObjectives: Array<{
      objective: string;
      evalCriteria: string[];
      __typename: string;
    }> | null;
  }
}

const EditActivity = ({ activityType, activityData, onSave, onCancel, error, isSubmitting, isNewActivity = false }) => {
  const [activityName, setActivityName] = useState(activityData?.title || "");
  const [description, setDescription] = useState(activityData?.description || "");
  const [objectives, setObjectives] = useState(activityData?.objectives || []);
  const [files, setFiles] = useState(activityData?.files || []);
  const [targetAudience, setTargetAudience] = useState("");
  const [videoUrl, setVideoUrl] = useState(activityData?.videoUrl || "");
  const [dueDate, setDueDate] = useState(activityData?.dueDate || "");
  const [enableFileUpload, setEnableFileUpload] = useState(activityData?.enableFileUpload || false);
  const [contextText, setContextText] = useState(activityData?.contextText || "");
  // HEAD:frontend/components/customCoursework/EditActivity/EditActivity.js
  const [formError, setFormError] = useState("");
  //
  const [enableVideoInsight, setEnableVideoInsight] = useState(true);
  //master:frontend/components/customCoursework/EditActivity/EditActivity.tsx
  const editorRef = useRef(null);

  // Handle evaluation criteria - preserve from cloned activity if available
  const [evalCriteria, setEvalCriteria] = useState(activityData?.evalCriteria || []);

  // Determine if criteria were already generated - for cloned activities this should be true
  const [hasGeneratedCriteria, setHasGeneratedCriteria] = useState(
    activityData?.evalCriteria?.some((obj) => obj.evalCriteria?.length > 0) || false
  );

  // Handle visualization state - preserve from cloned activity
  const [visualizationId, setVisualizationId] = useState(activityData?.visualizationId || null);
  const [visualization, setVisualization] = useState(null);
  const [generatingVisualization, setGeneratingVisualization] = useState(false);
  const [visualizationError, setVisualizationError] = useState(null);

  const [generateEvalCriteria] = useGenerateEvalCriteriaMutation();
  const [generateAiTutorFromTitle] = useGenerateAiTutorFromTitleMutation();

  // Apollo mutations for visualization
  const [updateVisualization] = useUpdateVisualizationMutation();
  const [createVisualizationFromContent] = useCreateVisualizationFromContentMutation();
  const [deleteVisualization] = useDeleteVisualizationMutation();

  const {
    data: visualizationData,
    startPolling,
    stopPolling,
    refetch: refetchVisualization,
  } = useGetVisualizationQuery({
    variables: { id: "" },
    fetchPolicy: "network-only",
  });

  const [evalGenerationState, setEvalGenerationState] = useState({
    isGenerating: false,
    step: null,
    isCancellable: true,
    progress: 0,
  });
  const [generationState, setGenerationState] = useState({
    isGenerating: false,
    step: null,
    progress: 0,
    isCancellable: true,
  });
  const abortControllerRef = useRef(null);

  // HEAD:frontend/components/customCoursework/EditActivity/EditActivity.js
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
  const ACCEPTED_MIME_TYPES = ["application/pdf"];

  // Add video pool state
  const [showVideoManager, setShowVideoManager] = useState(false);
  const [videoPool, setVideoPool] = useState(activityData?.videoPool || []);
  const [objectiveIdMap, setObjectiveIdMap] = useState({});

  const client = useApolloClient();

  // Add a state for the loading state of the generate button
  const [isGeneratingVideos, setIsGeneratingVideos] = useState(false);

  //
  //master:frontend/components/customCoursework/EditActivity/EditActivity.tsx
  useEffect(() => {
    if (visualizationData?.getVisualization?.data?.length > 0) {
      const vis = visualizationData.getVisualization.data[0];
      if (vis.status === "completed") {
        setGeneratingVisualization(false);
        stopPolling();
        setVisualizationId(vis.id);
        setVisualization({
          code: vis.code,
          visualizationType: vis.visualizationType,
          id: vis.id,
        });
      } else if (vis.status === "failed") {
        setGeneratingVisualization(false);
        stopPolling();
        setVisualizationError("Failed to generate visualization");
      } else {
        setGeneratingVisualization(true);
      }
    }
  }, [visualizationData, setVisualizationId, stopPolling]);

  // If this is a cloned visualization with a visualizationId, fetch it
  useEffect(() => {
    if (visualizationId && !visualization) {
      // If we have a visualization ID but no visualization data yet, refetch it
      refetchVisualization({ id: visualizationId });
    }
  }, [visualizationId]);

  // When component mounts or activityData changes, ensure proper setup of evaluation criteria
  useEffect(() => {
    if (activityData) {
      console.log("EditActivity - Processing activityData:", {
        hasEvalCriteria: !!activityData.evalCriteria,
        evalCriteriaLength: activityData.evalCriteria?.length || 0,
        hasObjectives: !!activityData.objectives,
        objectivesLength: activityData.objectives?.length || 0,
        rawObjectives: activityData.rawObjectives ? "present" : "not present",
      });

      // First check if we have evalCriteria directly in activityData
      if (activityData.evalCriteria && activityData.evalCriteria.length > 0) {
        console.log(
          "Setting eval criteria from activityData.evalCriteria:",
          JSON.stringify(activityData.evalCriteria, null, 2)
        );

        // Make sure each evalCriteria item has an evalCriteria array that is accessible
        const processedEvalCriteria = activityData.evalCriteria.map((item) => {
          // Check if evalCriteria property exists and is an array
          if (!item.evalCriteria || !Array.isArray(item.evalCriteria)) {
            console.log("Adding empty evalCriteria array for objective:", item.objective);
            return {
              ...item,
              evalCriteria: [],
            };
          }
          return item;
        });

        setEvalCriteria(processedEvalCriteria);
        setHasGeneratedCriteria(
          processedEvalCriteria.some((obj) => Array.isArray(obj.evalCriteria) && obj.evalCriteria.length > 0)
        );

        console.log("Processed evalCriteria:", JSON.stringify(processedEvalCriteria, null, 2));
      } // Next check if we have evalCriteria in the raw objectives (common for saved activities)
      else if (activityData.rawObjectives && activityData.rawObjectives.length > 0) {
        console.log("Checking rawObjectives for evalCriteria");

        // Check if any raw objective has evalCriteria
        const hasEvalCriteriaInRawObjectives = activityData.rawObjectives.some(
          (obj) =>
            typeof obj === "object" &&
            obj.evalCriteria &&
            Array.isArray(obj.evalCriteria) &&
            obj.evalCriteria.length > 0
        );

        if (hasEvalCriteriaInRawObjectives) {
          console.log("Found evalCriteria in rawObjectives");

          const processedEvalCriteria = activityData.rawObjectives.map((obj, index) => {
            const objective = typeof obj === "string" ? obj : obj.objective;
            const criteria = typeof obj === "object" && Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [];

            console.log(`Extracted criteria for "${objective}":`, criteria);

            return {
              id: `obj-${Math.random().toString(36).substring(2, 11)}`,
              objective: objective,
              sequence: index + 1,
              evalCriteria: criteria,
              status: obj.status || "notStarted",
            };
          });

          setEvalCriteria(processedEvalCriteria);
          setHasGeneratedCriteria(
            processedEvalCriteria.some((obj) => Array.isArray(obj.evalCriteria) && obj.evalCriteria.length > 0)
          );

          console.log("Processed evalCriteria from rawObjectives:", JSON.stringify(processedEvalCriteria, null, 2));
        } // If no evalCriteria in raw objectives, fall back to creating from objectives
        else if (activityData.objectives && activityData.objectives.length > 0) {
          console.log("No evalCriteria found in rawObjectives, creating from objectives");

          const newEvalCriteria = activityData.objectives.map((obj, index) => {
            const objective = typeof obj === "string" ? obj : obj.objective;
            return {
              id: `obj-${Math.random().toString(36).substring(2, 11)}`,
              objective: objective,
              sequence: index + 1,
              evalCriteria: [],
              status: "notStarted",
            };
          });

          console.log("Created evalCriteria from objectives:", JSON.stringify(newEvalCriteria, null, 2));
          setEvalCriteria(newEvalCriteria);
          setHasGeneratedCriteria(false);
        }
      } // Finally, if we have no evalCriteria but do have objectives, create empty evalCriteria entries
      else if (activityData.objectives && activityData.objectives.length > 0) {
        console.log("No evalCriteria found, creating from objectives");

        const newEvalCriteria = activityData.objectives.map((obj, index) => {
          const objective = typeof obj === "string" ? obj : obj.objective;
          return {
            id: `obj-${Math.random().toString(36).substring(2, 11)}`,
            objective: objective,
            sequence: index + 1,
            evalCriteria: [],
            status: "notStarted",
          };
        });

        console.log("Created evalCriteria from objectives:", JSON.stringify(newEvalCriteria, null, 2));
        setEvalCriteria(newEvalCriteria);
        setHasGeneratedCriteria(false);
      }
    }
  }, [activityData]);

  // Log state for debugging
  useEffect(() => {
    console.log("Current objectives:", objectives);
    console.log("Current evalCriteria:", evalCriteria);
    console.log("hasGeneratedCriteria:", hasGeneratedCriteria);
  }, [objectives, evalCriteria, hasGeneratedCriteria]);

  // Add a new effect to keep objectives and evalCriteria in sync
  useEffect(() => {
    // When objectives change, make sure we have corresponding evalCriteria entries
    if (objectives && objectives.length > 0) {
      // Create a map of existing evaluation criteria by objective text
      const existingCriteriaMap = {};
      if (evalCriteria && evalCriteria.length > 0) {
        evalCriteria.forEach((item) => {
          if (item.objective) {
            existingCriteriaMap[item.objective] = item.evalCriteria || [];
          }
        });
      }

      // Create updated evalCriteria with all current objectives
      const updatedEvalCriteria = objectives.map((obj, idx) => {
        const objectiveText = typeof obj === "string" ? obj : obj.objective;
        const existingCriteria = existingCriteriaMap[objectiveText] || [];

        return {
          id: `objective-${idx}`,
          objective: objectiveText,
          evalCriteria: existingCriteria,
          sequence: idx + 1,
        };
      });

      // Only update if the structure has changed
      if (JSON.stringify(updatedEvalCriteria) !== JSON.stringify(evalCriteria)) {
        console.log("Synchronizing evalCriteria with objectives:", updatedEvalCriteria);
        setEvalCriteria(updatedEvalCriteria);
      }
    }
  }, [objectives]);

  // Handle adding a new objective
  const handleAddObjective = (objective) => {
    if (!objective) return;

    const newObjectives = [...objectives, objective];
    setObjectives(newObjectives);

    // Create an entry in evalCriteria for the new objective
    const newEvalCriteria = [
      ...evalCriteria,
      {
        id: `objective-${evalCriteria.length}`,
        objective: objective,
        evalCriteria: [],
        sequence: evalCriteria.length + 1,
      },
    ];

    setEvalCriteria(newEvalCriteria);
  };

  // Handle removing an objective
  const handleRemoveObjective = (index) => {
    const newObjectives = [...objectives];
    newObjectives.splice(index, 1);
    setObjectives(newObjectives);

    // Also remove the corresponding evalCriteria entry
    const newEvalCriteria = evalCriteria.filter((item) => item.sequence !== index + 1);

    // Re-sequence the remaining criteria
    newEvalCriteria.forEach((item, idx) => {
      item.sequence = idx + 1;
    });

    setEvalCriteria(newEvalCriteria);
  };

  const handleGenerateVisualization = async () => {
    if (!activityName || !objectives || objectives.length === 0) {
      setVisualizationError("Activity name and objectives are required to generate a visualization");
      return;
    }

    try {
      setGeneratingVisualization(true);
      setVisualizationError(null);

      console.log("Generating visualization for objectives:", objectives);
      // Use our new mutation that takes content directly
      const result = await createVisualizationFromContent({
        variables: {
          title: activityName,
          description: description || "",
          objectives: objectives.map((obj, idx) => ({
            objective: obj,
            sequence: idx + 1,
          })),
          ...(targetAudience && { context: `Target Audience: ${targetAudience}` }),
        },
      });

      if (!result.data?.createVisualizationFromContent?.success) {
        throw new Error(result.data?.createVisualizationFromContent?.message || "Failed to create visualization");
      }

      const visId = result.data?.createVisualizationFromContent?.data[0].id;

      refetchVisualization({ id: visId });
      startPolling(10000);
    } catch (error) {
      console.error("Error generating visualization:", error);
      setVisualizationError(`Error generating visualization: ${error.message}`);
    }
  };

  const handleRegenerateVisualization = async () => {
    if (!activityName || !objectives || objectives.length === 0) {
      setVisualizationError("Activity name and objectives are required to generate a visualization");
      return;
    }

    try {
      setGeneratingVisualization(true);
      setVisualizationError(null);

      if (visualizationId) {
        const result = await updateVisualization({
          variables: {
            id: visualizationId,
            title: activityName,
            description: description || "",
            objectives: objectives.map((obj, idx) => ({
              objective: obj,
              sequence: idx + 1,
            })),
          },
        });

        if (!result.data?.updateVisualization?.success) {
          throw new Error(result.data?.updateVisualization?.message || "Failed to update visualization");
        }

        const visId = result.data?.updateVisualization?.data[0].id;

        refetchVisualization({ id: visId });
        startPolling(10000);
      } else {
        // If no ID exists, create a new one
        await handleGenerateVisualization();
      }
    } catch (error) {
      console.error("Error regenerating visualization:", error);
      setVisualizationError(`Error regenerating visualization: ${error.message}`);
    }
  };

  const handleDeleteVisualization = async () => {
    try {
      setVisualizationError(null);

      if (visualizationId) {
        const result = await deleteVisualization({
          variables: { id: visualizationId },
        });

        if (!result.data?.deleteVisualization?.success) {
          throw new Error(result.data?.deleteVisualization?.message || "Failed to delete visualization");
        }

        setVisualizationId(null);
        setVisualization(null);
      }
    } catch (error) {
      console.error("Error deleting visualization:", error);
      setVisualizationError(`Error deleting visualization: ${error.message}`);
    }
  };

  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleGenerateEvalCriteria = async () => {
    if (!objectives || objectives.length === 0) return;

    try {
      setEvalGenerationState({
        isGenerating: true,
        step: "Generating evaluation criteria",
        progress: 1,
        isCancellable: true,
      });

      // If we already have evaluation criteria and are regenerating,
      // prepare a mapping of objective text to existing criteria
      const existingCriteriaMap = {};
      if (evalCriteria && evalCriteria.length > 0) {
        evalCriteria.forEach((criteria) => {
          if (criteria.objective && criteria.evalCriteria && criteria.evalCriteria.length > 0) {
            existingCriteriaMap[criteria.objective] = criteria.evalCriteria;
          }
        });
      }

      console.log("Generating evaluation criteria for objectives:", objectives);
      console.log("Existing criteria map:", existingCriteriaMap);

      const result = await generateEvalCriteria({
        variables: {
          input: {
            title: activityName,
            description: description,
            objectives: objectives.map((obj, idx) => ({
              objective: obj,
              sequence: idx + 1,
            })),
          },
        },
      });

      if (result.data?.generateEvalCriteria?.success) {
        const newCriteria = result.data.generateEvalCriteria.data;
        console.log("Received new criteria:", JSON.stringify(newCriteria, null, 2));

        // Ensure each objective has exactly one evaluation criterion
        const processedCriteria = newCriteria.map((obj, idx) => {
          // Ensure evalCriteria is consistently an array with exactly one item
          let criteriaArray = [];

          if (obj.evalCriteria) {
            // If evalCriteria is a string, wrap it in an array
            if (typeof obj.evalCriteria === "string") {
              criteriaArray = [obj.evalCriteria];
            } // If evalCriteria is already an array, take just the first item or add a placeholder
            else if (Array.isArray(obj.evalCriteria)) {
              criteriaArray =
                obj.evalCriteria.length > 0
                  ? [obj.evalCriteria[0]] // Take just the first item
                  : ["Student demonstrates understanding of the objective"];
            }
          } else {
            // If no criteria were generated, add a placeholder
            criteriaArray = ["Student demonstrates understanding of the objective"];
          }

          // Log what we're using for this objective
          console.log(`For objective "${obj.objective}", using criterion:`, criteriaArray[0]);

          return {
            id: `objective-${idx}`,
            objective: obj.objective,
            evalCriteria: criteriaArray,
            sequence: idx + 1,
          };
        });

        console.log("Setting processed criteria:", JSON.stringify(processedCriteria, null, 2));
        setEvalCriteria(processedCriteria);
        setHasGeneratedCriteria(true);

        // CRITICAL: Also create a formattedObjectives array that contains the evalCriteria
        // This will be used by the cloning function to preserve the criteria
        // Create global version for cloning
        window.__lastGeneratedObjectives = processedCriteria.map((obj) => ({
          objective: obj.objective,
          evalCriteria: [...obj.evalCriteria], // Spread to make a copy
          __typename: "FormattedObjective",
        }));

        console.log("Stored generated objectives globally for cloning:", window.__lastGeneratedObjectives);
      } else {
        console.error("Error in generateEvalCriteria response:", result.data?.generateEvalCriteria?.message);
      }
    } catch (error) {
      console.error("Error generating evaluation criteria:", error);
    } finally {
      setEvalGenerationState({
        isGenerating: false,
        step: null,
        isCancellable: true,
        progress: 0,
      });
    }
  };

  const handleCriteriaEdit = (objectiveId, newCriteria) => {
    setEvalCriteria((prev) =>
      prev.map((obj) => (obj.id === objectiveId ? { ...obj, evalCriteria: newCriteria } : obj))
    );
  };

  const handleSubmit = async () => {
    if (!activityName.trim()) {
      setFormError("Activity name is required");
      return;
    }

    // Create the base activity data with all common fields
    const activityData = {
      title: activityName,
      description,
      objectives: evalCriteria.map((obj) => ({
        objective: obj.objective,
        sequence: obj.sequence,
        evalCriteria: Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [],
      })),
      files,
      videoUrl,
      dueDate,
      enableVideoInsight,
      enableFileUpload,
      contextText,
      type: activityType,
      visualizationId: visualizationId,
      targetAudience: targetAudience,
      // Explicitly include evalCriteria to ensure it's passed through
      evalCriteria: evalCriteria.map((obj) => ({
        id: obj.id,
        objective: obj.objective,
        sequence: obj.sequence,
        evalCriteria: Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [],
        status: obj.status || "notStarted",
      })),

      // CRITICAL: Add formattedObjectives to ensure criteria are preserved when cloning
      formattedObjectives: evalCriteria.map((obj) => ({
        objective: obj.objective,
        evalCriteria: Array.isArray(obj.evalCriteria) ? [...obj.evalCriteria] : [],
        __typename: "FormattedObjective",
      })),

      // Include any globally stored generated objectives if they exist
      __generatedObjectives: window.__lastGeneratedObjectives || null,
      // Process video pool data to match what the backend expects
      // The backend expects an array of VideoDataInput objects
      videoPool: videoPool.map((video) => ({
        videoId: video.videoId,
        objectiveText: video.objectiveId, // The objectiveId from VideoPoolManager is actually the objective text
        objectiveSequence: video.objectiveSequence,
        isTeacherAdded: video.isTeacherAdded || false, // Add fallback to ensure the flag is never undefined
      })),
    };

    // Add context as expected by the backend if it exists
    if (contextText) {
      activityData.context = contextText;
    }

    console.log("Saving activity with evalCriteria:", JSON.stringify(activityData.evalCriteria, null, 2));
    console.log("Saving activity with formattedObjectives:", JSON.stringify(activityData.formattedObjectives, null, 2));
    console.log("Saving activity with videoPool:", JSON.stringify(activityData.videoPool, null, 2));
    onSave(activityData);
  };

  // Add a separate handler for closing the modal via the X button
  const handleCloseVideoManager = () => {
    console.log("Closing video manager modal");
    setShowVideoManager(false);
  };

  // Add function to handle video pool data that supports auto-save
  const handleVideoPoolReady = (videoPool) => {
    setVideoPool(videoPool);
    setShowVideoManager(false);

    // Log the video pool with usage information
    if (videoPool.length > 0) {
      console.log("Video pool ready with usage settings:", videoPool);
    }
  };

  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      const text = e.nativeEvent.text || e.target.value;
      if (text && text.trim()) {
        handleAddObjective(text.trim());
        if (e.target) e.target.value = "";
      }
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const renderFileList = () => {
    return files.map((file, index) => (
      <View key={index} style={styles.uploadedFile}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <File />
          <CustomSpacing type="horizontal" size="s" />
          <CustomText size="m" weight="ultraBold" text={truncateText(file.fileName, 40)} useTranslationText={false} />
        </View>

        <CustomButton
          leftIcon={<Remove style={{ paddingRight: 4 }} />}
          text="Remove"
          size="s"
          style={{ backgroundColor: "#D0D5DD", borderRadius: 4 }}
          textStyle={{ fontSize: 14, fontWeight: 700 }}
          onPress={() => handleFileRemove(file)}
        />
      </View>
    ));
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    setGenerationState({
      isGenerating: false,
      step: null,
      progress: 0,
      isCancellable: true,
    });
  };

  const handleAutoGenerate = async () => {
    if (!activityName) return;

    try {
      setGenerationState({
        isGenerating: true,
        step: "Generating activity content",
        progress: 1,
        isCancellable: true,
      });
      abortControllerRef.current = new AbortController();

      // Disable editor while generating
      editorRef.current?.setContentHTML("");

      const result = await generateAiTutorFromTitle({
        variables: {
          title: activityName,
          targetAudience: targetAudience,
        },
      });

      if (result.data?.generateAiTutorFromTitle?.success) {
        const content = result.data.generateAiTutorFromTitle.data;

        // Update state with generated content
        editorRef.current?.setContentHTML(content.description);
        setDescription(content.description);
        setObjectives(content.objectives.map((obj) => obj.objective));

        setGenerationState((prev) => ({
          ...prev,
          step: "Generating evaluation criteria",
          progress: 2,
          isCancellable: false,
        }));
      }
    } catch (error) {
      console.error("Error auto-generating content:", error);
    } finally {
      setGenerationState({
        isGenerating: false,
        step: null,
        progress: 0,
        isCancellable: true,
      });
    }
  };

  const handleAfterUpload = async ({ id, fileName, fileURL, mimeType, size }) => {
    setFiles([
      ...files,
      {
        fileId: id,
        fileName: fileName,
        fileUrl: fileURL,
        contentType: mimeType,
        size: size,
      },
    ]);
  };

  const { renderFileUpload } = useFileUpload({
    storagePath: StoragePathType.ResourceDocument,
    afterUpload: handleAfterUpload,
  });

  // Function to render the visualization preview
  const renderVisualizationPreview = () => {
    if (!visualization) return null;

    const getVisualizationHtml = () => {
      if (visualization.visualizationType === "static") {
        return `
          <!DOCTYPE html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <script src="https://cdn.jsdelivr.net/npm/mermaid@9.4.3/dist/mermaid.min.js"></script>
              <style>
                html, body {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #EAECF0;
                  font-family: Arial, sans-serif;
                  overflow: hidden;
                }
                .mermaid-container {
                  background-color: white;
                  border-radius: 8px;
                  padding: 16px;
                  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                  width: 100%;
                  max-width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  overflow: auto;
                }
                .mermaid {
                  width: 100%;
                  overflow: visible;
                }
                #error-message {
                  color: #DC2626;
                  font-size: 14px;
                  text-align: center;
                  display: none;
                }
              </style>
            </head>
            <body>
              <div class="mermaid-container">
                <div id="error-message">Error rendering diagram</div>
                <pre class="mermaid">
                  ${visualization.code}
                </pre>
              </div>
              <script>
                // Initialize mermaid with specific configuration
                mermaid.initialize({
                  startOnLoad: true,
                  theme: 'default',
                  securityLevel: 'loose',
                  logLevel: 'error',
                  flowchart: { htmlLabels: true, useMaxWidth: true },
                  er: { useMaxWidth: true },
                  sequence: { useMaxWidth: true },
                  journey: { useMaxWidth: true }
                });

                // Handle mermaid errors
                mermaid.parseError = function(err, hash) {
                  console.error('Mermaid parse error:', err);
                  document.getElementById('error-message').style.display = 'block';
                };

                // Try to render diagram after a short delay
                setTimeout(function() {
                  try {
                    mermaid.init(undefined, document.querySelectorAll('.mermaid'));
                  } catch (e) {
                    console.error('Mermaid init error:', e);
                    document.getElementById('error-message').style.display = 'block';
                  }
                }, 500);
              </script>
            </body>
          </html>
        `;
      } else if (visualization.visualizationType === "nonInteractive") {
        // For GSAP non-interactive visualizations
        return `
          <!DOCTYPE html>
          <html>
              <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Security-Policy" content="
                  default-src 'self' data:;
                  script-src 'self' https://cdn.jsdelivr.net https://cdnjs.cloudflare.com 'unsafe-inline';
                  style-src 'self' 'unsafe-inline';
                  img-src 'self' data:;
                  connect-src 'none';">
              <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js"></script>
              <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/ScrollTrigger.min.js"></script>
              <style>
                  html, body {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #EAECF0;
                  overflow: hidden;
                  }
                  #visualization-container {
                  background-color: white;
                  border-radius: 8px;
                  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                  width: 100%;
                  max-width: 100%;
                  height: 100%;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  }
                  /* Styles for GSAP reset button if used */
                  .reset-button {
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #4D5E80;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    padding: 8px 16px;
                    cursor: pointer;
                    font-weight: bold;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .reset-button:hover {
                    background-color: #3D4E70;
                  }
                  /* Ensure SVG elements are responsive */
                  svg {
                    width: 100%;
                    height: auto;
                    display: block;
                  }
              </style>
              </head>
              <body>
              <div id="visualization-container"></div>
              <script>
                  try {
                  ${visualization.code}
                  } catch (e) {
                  console.error('GSAP Error:', e);
                  }
              </script>
              </body>
          </html>
        `;
      } else {
        // For p5.js interactive visualizations
        return `
          <!DOCTYPE html>
          <html>
              <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="Content-Security-Policy" content="
                  default-src 'self' data:;
                  script-src 'self' https://cdn.jsdelivr.net 'unsafe-inline';
                  style-src 'self' 'unsafe-inline';
                  img-src 'self' data:;
                  connect-src 'none';">
              <script src="https://cdn.jsdelivr.net/npm/p5@1.4.1/lib/p5.js"></script>
              <style>
                  html, body {
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #EAECF0;
                  overflow: hidden;
                  }
                  #visualization-container {
                  background-color: white;
                  border-radius: 8px;
                  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                  width: 100%;
                  max-width: 100%;
                  height: 100%;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  main {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
              </style>
              </head>
              <body>
              <div id="visualization-container"></div>
              <script>
                  try {
                  ${visualization.code}
                  } catch (e) {
                  console.error('p5.js Error:', e);
                  }
              </script>
              </body>
          </html>
        `;
      }
    };

    return (
      <View style={styles.visualizationContainer}>
        <View style={styles.visualizationHeader}>
          <CustomText
            text={`${visualization.visualizationType === "static" ? "Diagram" : "Interactive Visualization"}`}
            size="m"
            weight="ultraBold"
          />
          <View style={styles.visualizationButtons}>
            <CustomButton
              text="Regenerate"
              onPress={handleRegenerateVisualization}
              loading={generatingVisualization}
              disabled={generatingVisualization}
              styleType="secondary"
              size="s"
              style={styles.regenerateButton}
            />
            <CustomSpacing type="horizontal" size="s" />
            <CustomButton
              text="Remove"
              onPress={handleDeleteVisualization}
              loading={generatingVisualization}
              disabled={generatingVisualization}
              styleType="danger"
              size="s"
            />
          </View>
        </View>

        <View style={styles.webViewContainer}>
          <WebView
            source={{ html: getVisualizationHtml() }}
            style={styles.webView}
            scrollEnabled={true}
            javaScriptEnabled={true}
            domStorageEnabled={false}
            allowFileAccess={false}
            allowUniversalAccessFromFileURLs={false}
            originWhitelist={["*"]}
            startInLoadingState={true}
            onError={(syntheticEvent) => {
              const { nativeEvent } = syntheticEvent;
              console.error("WebView error:", nativeEvent);
            }}
            onHttpError={(syntheticEvent) => {
              const { nativeEvent } = syntheticEvent;
              console.error("WebView HTTP error:", nativeEvent);
            }}
          />
        </View>
      </View>
    );
  };

  const renderAutoGenerateButton = () => (
    <View style={styles.autoGenerateContainer}>
      <CustomButton
        text="Auto Generate Content"
        onPress={handleAutoGenerate}
        loading={generationState.isGenerating}
        disabled={!activityName || generationState.isGenerating}
        styleType="secondary"
      />
      <CustomText
        text="Generate description and objectives based on the activity name"
        useTranslationText={false}
        style={styles.autoGenerateHint}
        size="xs"
      />
    </View>
  );

  const ButtonsComponent = () => {
    return (
      <View style={styles.buttons}>
        <CustomButton
          text="Cancel"
          styleType="primaryLight"
          size="s"
          textStyle={{ fontSize: 14, fontWeight: 700 }}
          style={styles.cancelButton}
          onPress={onCancel}
        />
        <CustomSpacing type="horizontal" size="s" />
        <CustomButton
          text={isNewActivity ? "Create" : "Save"}
          size="s"
          textStyle={{ fontSize: 14, fontWeight: 700 }}
          onPress={handleSubmit}
          styleType="primary"
          disabled={!activityName || !description || isSubmitting}
          style={
            !activityName || !description || (activityType === "Assignment" && !dueDate) || isSubmitting
              ? { backgroundColor: "#ccc" }
              : {}
          }
        />
      </View>
    );
  };

  const renderSpecificFields = () => {
    switch (activityType) {
      case "Interactive Learning Experience":
        return (
          <>
            <CustomText text="Target Audience" size="m" weight="ultraBold" useTranslationText={false} />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="This activity for 3rd graders. Focus on who, what, where, when questions. Break concepts into small pieces. Use concrete examples and stories, not abstractions. Encourage meaningful memorization. Maintain enthusiastic tone with positive reinforcement. Use playful elements while maintaining educational rigor."
              value={targetAudience}
              onChangeText={setTargetAudience}
              multiline={true}
              numberOfLines={4}
              style={styles.targetAudienceInput}
            />
            <CustomSpacing type="vertical" size="l" />
            <CustomSpacing type="vertical" size="m" />
            <View style={styles.autoGenerateContainer}>
              <CustomButton
                text="Auto Generate Content"
                onPress={handleAutoGenerate}
                loading={generationState.isGenerating}
                disabled={!activityName || generationState.isGenerating}
                styleType="primary"
                style={!activityName ? { backgroundColor: "#ccc" } : {}}
              />
              <CustomText
                text="Generate description and objectives based on the activity name"
                useTranslationText={false}
                style={styles.autoGenerateHint}
                size="xs"
              />
            </View>
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={[styles.editor, generationState.isGenerating && styles.disabledEditor]}
              ref={editorRef}
              initialContentHTML={description}
              onChange={(html) => setDescription(html)}
              disabled={generationState.isGenerating}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Learning Objectives" size="m" weight="ultraBold" />
            <CustomText
              text="Define the specific goals or skills students should achieve through this activity. These guide the AI's teaching focus."
              style={styles.subText}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Add an objective and press enter"
              onSubmitEditing={handleKeyPress}
              editable={!generationState.isGenerating}
            />
            <CustomSpacing type="vertical" size="m" />
            <View style={styles.objectivesList}>
              {objectives.map((objective, index) => (
                <View key={index} style={styles.objectiveItem}>
                  <CustomText text={objective} size="s" useTranslationText={false} style={styles.objectiveText} />
                  <CustomButton
                    styleType="transparent"
                    leftIcon={<Remove />}
                    size="s"
                    onPress={() => handleRemoveObjective(index)}
                  />
                </View>
              ))}
            </View>

            {/* Add Video Manager Button */}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video Recommendations" size="m" weight="ultraBold" />
            <CustomText
              text="Generate and manage videos that will be recommended to students for each learning objective."
              style={styles.subText}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="s" />
            <CustomButton
              text={videoPool.length > 0 ? "Manage Video Recommendations" : "Add Video Recommendations"}
              onPress={() => {
                // Open the modal directly without generating videos first
                setShowVideoManager(true);
              }}
              styleType="secondary"
              disabled={objectives.length === 0}
            />
            {videoPool.length > 0 && (
              <CustomText
                text={`${videoPool.length} video${videoPool.length !== 1 ? "s" : ""} selected`}
                useTranslationText={false}
                style={styles.videoCountText}
                size="xs"
              />
            )}

            {/* Video Manager Modal */}
            {showVideoManager && (
              <CustomModal
                visible={showVideoManager}
                onClose={handleCloseVideoManager}
                title="Video Recommendations"
                size="lg"
                showCloseButton>
                <VideoPoolManager
                  title={activityName}
                  description={description}
                  objectives={objectives.map((objective, index) => ({
                    objective,
                    sequence: index + 1,
                  }))}
                  onVideoPoolReady={handleVideoPoolReady}
                  initialVideoPool={videoPool}
                />
              </CustomModal>
            )}

            <View style={styles.actionButtons}>
              <CustomButton
                text={hasGeneratedCriteria ? "Regenerate Evaluation Criteria" : "Generate Evaluation Criteria"}
                onPress={handleGenerateEvalCriteria}
                loading={evalGenerationState.isGenerating}
                disabled={!objectives || objectives.length === 0 || evalGenerationState.isGenerating}
                styleType={objectives && objectives.length > 0 ? "primary" : "disabled"}
              />
            </View>

            {evalCriteria && evalCriteria.length > 0 && (
              <View style={styles.evalCriteriaPreview}>
                <CustomText
                  style={styles.previewTitle}
                  text="Evaluation Criteria"
                  useTranslationText={false}
                  size="m"
                  weight="ultraBold"
                />
                <FlatList data={evalCriteria} keyExtractor={(item) => item.id} renderItem={renderCriteriaItem} />
              </View>
            )}

            {/* Visualization Generation Section */}
            <CustomSpacing type="vertical" size="xl" />
            <View style={styles.visualizationSection}>
              <CustomText text="Learning Visualization" size="m" weight="ultraBold" />
              <CustomText
                text="Generate an interactive visualization to help students understand the concepts."
                style={styles.subText}
                useTranslationText={false}
              />
              <CustomSpacing type="vertical" size="m" />
              <View style={styles.generateVisualizationContainer}>
                <CustomButton
                  text={visualization ? "Regenerate Visualization" : "Generate Learning Visualization"}
                  onPress={visualization ? handleRegenerateVisualization : handleGenerateVisualization}
                  loading={generatingVisualization}
                  disabled={generatingVisualization || !objectives || objectives.length === 0 || !hasGeneratedCriteria}
                  styleType={!objectives || objectives.length === 0 || !hasGeneratedCriteria ? "disabled" : "secondary"}
                />
                {visualizationError && (
                  <CustomText text={visualizationError} style={styles.errorText} size="xs" useTranslationText={false} />
                )}
                {(!objectives?.length || !hasGeneratedCriteria) && (
                  <CustomText
                    text="Complete activity name, objectives, and evaluation criteria before generating visualization"
                    style={styles.helperText}
                    size="xs"
                    useTranslationText={false}
                  />
                )}
              </View>

              {/* Visualization Preview */}
              {visualization && renderVisualizationPreview()}
            </View>

            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Contextual Alignment" size="m" weight="ultraBold" />
            <CustomText
              text="Add additional context and enrich the AI's teaching focus for this activity."
              style={styles.subText}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="l" />
            <View style={styles.toggleContainer}>
              <CustomText text="Video Insights" size="m" weight="ultraBold" useTranslationText={false} />
              <CustomSpacing type="horizontal" size="s" />
              <Switch value={enableVideoInsight} onValueChange={setEnableVideoInsight} />
            </View>
            <CustomSpacing type="vertical" size="l" />
            <View style={styles.counterContainer}>
              <CustomTextInput
                placeholder="Add further Contextual Alignment"
                value={contextText}
                onChangeText={setContextText}
                maxLength={10000}
                numberOfLines={4}
                multiline
              />
              <View style={styles.infoRow}>
                {(!objectives?.length || !hasGeneratedCriteria || !evalCriteria?.length) && (
                  <CustomText
                    text={
                      !objectives?.length
                        ? "Add learning objectives to continue"
                        : "Generate evaluation criteria to continue"
                    }
                    size="xs"
                    style={styles.helperText}
                    useTranslationText={false}
                  />
                )}
                <CustomText
                  text={`${contextText.length} / 10000`}
                  size="xs"
                  style={styles.counter}
                  useTranslationText={false}
                />
              </View>
            </View>

            <View style={styles.buttons}>
              <View style={styles.buttonContainer}>
                <CustomButton
                  text="Cancel"
                  styleType="primaryLight"
                  size="s"
                  textStyle={{ fontSize: 14, weight: 700 }}
                  style={styles.cancelButton}
                  onPress={onCancel}
                />
                <CustomSpacing type="horizontal" size="s" />
                <CustomButton
                  text={isNewActivity ? "Create" : "Save"}
                  size="s"
                  textStyle={{ fontSize: 14, fontWeight: 700 }}
                  onPress={handleSubmit}
                  disabled={
                    generationState.isGenerating ||
                    !objectives?.length ||
                    !hasGeneratedCriteria ||
                    !evalCriteria?.length ||
                    !activityName ||
                    !description ||
                    isSubmitting
                  }
                  styleType={"primary"}
                  style={
                    !objectives?.length || !hasGeneratedCriteria || !evalCriteria?.length || isSubmitting
                      ? { backgroundColor: "#ccc" }
                      : {}
                  }
                />
              </View>
            </View>
          </>
        );

      case "Resource":
        return (
          <>
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={[styles.editor, generationState.isGenerating && styles.disabledEditor]}
              ref={editorRef}
              initialContentHTML={description}
              onChange={(html) => setDescription(html)}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            {renderFileUpload()}
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
            <CustomSpacing type="vertical" size="xl" />
            <ButtonsComponent />
          </>
        );

      case "Assignment":
        return (
          <>
            <CustomText text="Due Date" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <DatePickerInput
              locale="en"
              value={dueDate}
              onChange={(d) => setDueDate(d)}
              inputMode="start"
              mode="outlined"
              outlineColor={"#D1D5DB"}
              selectionColor={false}
              activeOutlineColor={"blue"}
              withDateFormatInLabel={false}
              placeholder="MM/DD/YYYY"
              inputProps={{
                color: "#000000",
                mode: "outline",
              }}
              theme={{
                colors: "#000000",
                roundness: 8,
                opacity: 1,
              }}
              style={styles.datePicker}
              aria-label="Due date date picker"
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={[styles.editor, generationState.isGenerating && styles.disabledEditor]}
              ref={editorRef}
              initialContentHTML={description}
              onChange={(html) => setDescription(html)}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            {renderFileUpload()}
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
            <CustomSpacing type="vertical" size="xl" />
            <View style={styles.toggleContainer}>
              <CustomText text="Enable student file upload" size="m" weight="ultraBold" />
              <CustomSpacing type="horizontal" size="s" />
              <Switch value={enableFileUpload} onValueChange={setEnableFileUpload} />
            </View>
            <CustomSpacing type="vertical" size="xl" />
            <ButtonsComponent />
          </>
        );

      case "Project":
        return (
          <>
            <CustomText text="Activity Description" size="m" weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <RichEditor
              style={[styles.editor, generationState.isGenerating && styles.disabledEditor]}
              ref={editorRef}
              initialContentHTML={description}
              onChange={(html) => setDescription(html)}
            />
            <RichToolbar
              editor={editorRef}
              actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
              style={styles.toolbar}
            />
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="File Upload" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Explanation about resource upload should go here." />
            <CustomSpacing type="vertical" size="s" />
            {renderFileUpload()}
            {renderFileList()}
            <CustomSpacing type="vertical" size="xl" />
            <CustomText text="Video URL" size="m" weight="ultraBold" />
            <CustomText style={styles.subText} text="Paste either a YouTube or Vimeo link here" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput
              placeholder="Paste either a YouTube or Vimeo link here"
              value={videoUrl}
              onChangeText={setVideoUrl}
            />
            <CustomSpacing type="vertical" size="xl" />
            <View style={styles.toggleContainer}>
              <CustomText text="Enable student file upload" size="m" weight="ultraBold" />
              <CustomSpacing type="horizontal" size="s" />
              <Switch value={enableFileUpload} onValueChange={setEnableFileUpload} />
            </View>
          </>
        );

      default:
        return null;
    }
  };

  // Add a helper function to correctly render criteria items
  const renderCriteriaItem = ({ item }) => {
    console.log("Rendering criteria item:", JSON.stringify(item, null, 2));

    return (
      <View style={styles.criteriaItem}>
        <CustomText
          style={styles.criteriaObjective}
          text={`Objective ${item.sequence}: ${item.objective}`}
          size="s"
          useTranslationText={false}
        />
        {Array.isArray(item.evalCriteria) && item.evalCriteria.length > 0 ? (
          item.evalCriteria.map((criteria, criteriaIndex) => (
            <CustomTextInput
              key={`criteria-${criteriaIndex}`}
              style={styles.criteriaText}
              value={criteria}
              onChangeText={(text) => {
                const newCriteria = [...item.evalCriteria];
                newCriteria[criteriaIndex] = text;
                handleCriteriaEdit(item.id, newCriteria);
              }}
              multiline
            />
          ))
        ) : (
          <CustomText
            style={styles.noCriteriaText}
            text="No evaluation criteria yet. Generate criteria or add them manually."
            size="xs"
            useTranslationText={false}
          />
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <LoadingOverlay
        visible={generationState.isGenerating}
        step={generationState.step}
        progress={generationState.progress}
        isCancellable={generationState.isCancellable}
        onCancel={handleCancel}
        message="Generating activity..."
      />
      <LoadingOverlay
        visible={evalGenerationState.isGenerating}
        step={evalGenerationState.step}
        progress={evalGenerationState.progress}
        message="Generating evaluation criteria..."
        isCancellable={evalGenerationState.isCancellable}
        onCancel={() =>
          setEvalGenerationState({
            isGenerating: false,
            step: null,
            isCancellable: true,
            progress: 0,
          })
        }
      />
      <LoadingOverlay
        visible={generatingVisualization}
        progress={1}
        step="Generating learning visualization"
        message="Creating an interactive visualization based on your content..."
        isCancellable={true}
        onCancel={() => setGeneratingVisualization(false)}
      />
      <ScrollView
        style={styles.scrollView}
        pointerEvents={
          generationState.isGenerating || evalGenerationState.isGenerating || generatingVisualization ? "none" : "auto"
        }>
        <View style={styles.header}>
          <CustomText
            text={`${isNewActivity ? "Create" : "Edit"} ${activityType}`}
            size="xl"
            weight="bold"
            style={{ padding: 32 }}
            useTranslationText={false}
          />
        </View>
        <View style={styles.content}>
          <CustomSpacing type="vertical" size="xl" />
          <CustomText text="Activity Name" size="m" weight="ultraBold" />
          <CustomSpacing type="vertical" size="s" />
          <CustomTextInput
            placeholder="Enter activity name"
            value={activityName}
            onChangeText={setActivityName}
            editable={!generationState.isGenerating}
          />
          <CustomSpacing type="vertical" size="xl" />
          {renderSpecificFields()}
          {error && <CustomText text={error} size="m" useTranslationText={false} style={styles.errorText} />}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1000,
  },
  scrollView: {
    flex: 1,
  },
  header: {
    backgroundColor: "#F2F4F7",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  content: {
    paddingHorizontal: 32,
  },
  objectivesList: {
    marginTop: 8,
  },
  objectiveItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: "#E6EBFF",
    borderRadius: 8,
    marginBottom: 4,
  },
  objectiveText: {
    flex: 1,
    fontSize: 14,
    fontWeight: 400,
  },
  uploadedFile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    backgroundColor: "#F3F4F6",
    borderRadius: 8,
    marginBottom: 16,
    marginTop: 16,
  },
  editor: {
    minHeight: 200,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
  },
  toolbar: {
    backgroundColor: "#f1f1f1",
    borderColor: "#ccc",
    borderTopWidth: 1,
  },
  subText: {
    color: "#667085",
    fontSize: 14,
    fontWeight: "400",
  },
  toggleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cancelButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 24,
    paddingBottom: 40,
  },
  errorText: {
    color: "#FF0000",
    marginTop: 8,
  },
  actionButtons: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 20,
  },
  evalCriteriaPreview: {
    marginTop: 15,
    padding: 15,
    backgroundColor: "#F2F4F7",
    borderRadius: 8,
  },
  criteriaItem: {
    marginBottom: 15,
    padding: 10,
    backgroundColor: "white",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#eee",
  },
  criteriaObjective: {
    fontWeight: "700",
    marginBottom: 8,
  },
  criteriaText: {
    color: "#667085",
    marginTop: 8,
    paddingLeft: 16,
    height: 100,
  },
  previewTitle: {
    marginBottom: 15,
  },
  autoGenerateContainer: {
    alignItems: "flex-start",
    marginTop: 8,
  },
  autoGenerateHint: {
    color: "#667085",
    marginTop: 4,
  },
  disabledEditor: {
    opacity: 0.7,
    backgroundColor: "#f5f5f5",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  helperText: {
    color: "#667085",
    marginTop: 4,
    marginBottom: 8,
  },
  visualizationSection: {
    marginTop: 15,
  },
  generateVisualizationContainer: {
    alignItems: "flex-start",
  },
  visualizationContainer: {
    marginTop: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 12,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
  },
  visualizationHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    backgroundColor: "#F9FAFB",
  },
  visualizationButtons: {
    flexDirection: "row",
  },
  regenerateButton: {
    backgroundColor: "#F3F4F6",
    borderRadius: 4,
  },
  webViewContainer: {
    height: 700,
    width: "100%",
  },
  webView: {
    flex: 1,
    backgroundColor: "#EAECF0",
  },
  noCriteriaText: {
    color: "#667085",
    fontSize: 14,
    fontWeight: "400",
  },
  videoCountText: {
    marginTop: 8,
    color: "#666",
  },
  datePicker: {
    borderWidth: 0,
    paddingVertical: 10,
    backgroundColor: "#F8F8F8",
    borderColor: "#000000",
    borderRadius: 10,
    color: "#000000",
    fontSize: 14,
  },
  counterContainer: {
    // margin: 16,
  },
  infoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 4,
  },
  counter: {
    color: "#667085",
    marginTop: 4,
    marginBottom: 8,
    textAlign: "right",
  },
  targetAudienceInput: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
    padding: 10,
    textAlignVertical: "top",
    height: 120,
  },
});

export default EditActivity;
