import React, { useState, useEffect } from "react";
import { VictoryChart, VictoryScatter, VictoryAxis } from "victory-native";
import { View, Text, TouchableOpacity } from "react-native";

const StudentBubbleChartContainer = ({ containerWidth = 800, data, onDrillDown }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [viewMode, setViewMode] = useState("all");

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // Compute domain from data
  const allX = data.map((d) => d.x);
  const allY = data.map((d) => d.y);
  const minX = Math.min(...allX, 0);
  const maxX = Math.max(...allX, 10);
  const minY = Math.min(...allY, 0);
  const maxY = Math.max(...allY, 10);
  const domain = { x: [minX - 1, maxX + 1], y: [minY - 1, maxY + 1] };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#fff",
        borderRadius: 8,
        padding: 4,
        borderColor: "#05060f1a",
        borderWidth: 1,
      }}>
      <View style={{ flexDirection: "row", gap: 24 }}>
        {/* Chart Section */}
        <View style={{ flex: 1, position: "relative" }}>
          {/* Quadrant backgrounds */}
          <View
            style={{
              position: "absolute",
              top: 50,
              left: 50,
              right: 50,
              bottom: 50,
              overflow: "hidden",
            }}>
            <View
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: "50%",
                bottom: "50%",
                backgroundColor: "#E6FFFA",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                right: 0,
                bottom: "50%",
                backgroundColor: "#FFF7EB",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: "50%",
                bottom: 0,
                backgroundColor: "#FAF4FF",
              }}
            />
            <View
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                right: 0,
                bottom: 0,
                backgroundColor: "#FFEBE6",
              }}
            />
          </View>

          <VictoryChart
            width={containerWidth}
            height={600}
            domain={domain}
            padding={{ top: 50, bottom: 50, left: 50, right: 50 }}>
            <VictoryAxis
              crossAxis
              style={{
                axis: { stroke: "#E5E7EB", strokeWidth: 1 },
                grid: { stroke: "transparent" },
                tickLabels: { fontSize: 0 },
              }}
              label="Risk →"
            />
            <VictoryAxis
              dependentAxis
              crossAxis
              style={{
                axis: { stroke: "#E5E7EB", strokeWidth: 1 },
                grid: { stroke: "transparent" },
                tickLabels: { fontSize: 0 },
              }}
              label="Performance ↑"
            />
            <VictoryScatter
              data={filteredData.filter((d) => !isNaN(d.x) && !isNaN(d.y))}
              size={({ datum }) => datum.size}
              style={{
                data: {
                  fill: ({ datum }) => datum.color,
                  opacity: 0.85,
                  stroke: "#fff",
                  strokeWidth: 2,
                },
              }}
              labels={({ datum }) => datum.label}
              events={[
                {
                  target: "data",
                  eventHandlers: {
                    onClick: (event, props) => {
                      const clickedData = props.datum;
                      if (onDrillDown) {
                        onDrillDown(clickedData);
                      }
                      return [];
                    },
                  },
                },
              ]}
            />
          </VictoryChart>
        </View>

        {/* Legend Section */}
        <View
          style={{
            width: 200,
            paddingLeft: 16,
            height: 700,
            paddingVertical: 50,
            justifyContent: "space-between",
          }}>
          {/* View Mode Selection */}
          <View>
            <TouchableOpacity
              style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}
              onPress={() => setViewMode("risk")}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: "#D1D5DB",
                  marginRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {viewMode === "risk" && (
                  <View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#3B82F6" }} />
                )}
              </View>
              <Text>Show at Risk</Text>
            </TouchableOpacity>

            <TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={() => setViewMode("all")}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: "#D1D5DB",
                  marginRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {viewMode === "all" && (
                  <View style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: "#3B82F6" }} />
                )}
              </View>
              <Text>Show all</Text>
            </TouchableOpacity>
          </View>

          {/* Explainer Text */}
          <View style={{ marginTop: 32, marginBottom: 32 }}>
            <Text style={{ fontSize: 14, fontWeight: "600", color: "#111827", marginBottom: 12 }}>
              How to read this chart
            </Text>
            <View style={{ gap: 8 }}>
              <View>
                <Text style={{ fontSize: 13, color: "#374151", fontWeight: "500", marginBottom: 4 }}>
                  X-axis (Risk)
                </Text>
                <Text style={{ fontSize: 12, color: "#6B7280", lineHeight: 16 }}>
                  Confusion + Distractions (plus penalty if negative sentiment)
                </Text>
              </View>
              <View>
                <Text style={{ fontSize: 13, color: "#374151", fontWeight: "500", marginBottom: 4 }}>
                  Y-axis (Performance)
                </Text>
                <Text style={{ fontSize: 12, color: "#6B7280", lineHeight: 16 }}>
                  Success + Active Interaction + 0.5 × Questions
                </Text>
              </View>
            </View>
          </View>

          {/* Color Legend */}
          <View>
            <Text style={{ fontWeight: "700", marginBottom: 16 }}>Legend</Text>
            <View style={{ gap: 8 }}>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#4ECDC4" }} />
                <Text>Positive</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#FFDD57" }} />
                <Text>Mixed</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#FF6B6B" }} />
                <Text>Negative</Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                <View style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: "#0084FF" }} />
                <Text>None</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default StudentBubbleChartContainer;
