import { Text, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { CheckMark } from "../../svgs/common";
import LinearGradient from "react-native-web-linear-gradient";
import Active from "../../common/status/Active/Active";

const ProgressTracker = ({ data }) => {
  const isAllCompleted = data.every((objective) => objective.isComplete);

  return (
    <View
      style={{
        marginHorizontal: 8,
        borderWidth: 1,
        borderColor: "#F2F4F7",
        overflow: "hidden",
        borderRadius: 24,
        backgroundColor: "#fff",
        shadowColor: "rgba(0, 0, 0, 0.08)",
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 4,
      }}>
      {/* Active positioned in top right */}
      {isAllCompleted ? (
        <View
          style={{
            position: "absolute",
            top: 22.5,
            right: 15,
            zIndex: 1,
            width: 20,
            height: 20,
            borderRadius: 10,
            backgroundColor: "#22C55E",
            marginRight: 8,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CheckMark width={14} height={16} fill="#FFFFFF" />
        </View>
      ) : (
        <></>
        // <View
        //   style={{
        //     position: "absolute",
        //     top: 15,
        //     right: 15,
        //     zIndex: 1,
        //     width: 35,
        //     height: 35,
        //   }}>
        //   <Active />
        // </View>
      )}

      {/* Content */}
      <View style={{ padding: 24 }}>
        <CustomText useTranslationText={false} text="Progress Tracker" size="m" weight="bold" textType="text" />

        <Text
          style={{
            fontFamily: "Inter",
            letterSpacing: 0.5,
            lineHeight: 18,
            weight: "medium",
            fontSize: 12,
            color: "#344054",
            textTransform: "uppercase",
            marginBottom: 9,
            marginTop: 9,
          }}>
          Topics
        </Text>

        {data.map((objective) => (
          <View key={objective.id}>
            {/* Objective title row */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
                marginBottom: objective.evalCriterias ? 8 : 12,
              }}>
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: objective.isComplete ? "#22C55E" : "#E5E7EB",
                  marginRight: 8,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {objective.inProgress ? <Active /> : <CheckMark width={14} height={16} fill="#FFFFFF" />}
              </View>
              <Text
                style={{
                  fontSize: 14,
                  color: objective.isComplete ? "#22C55E" : "#6B7280",
                  fontWeight: objective.inProgress ? "bold" : "normal",
                }}>
                {objective.title}
              </Text>
            </View>

            {/* Evaluation criteria section */}
            {objective.evalCriterias && objective.evalCriterias.length > 0 && objective.inProgress && (
              <View
                style={{
                  marginLeft: 0,
                  marginBottom: 12,
                  padding: 16,
                  paddingLeft: 16,
                  backgroundColor: "#F9FAFB",
                  borderRadius: 8,
                  maxWidth: "100%",
                }}>
                {objective.evalCriterias.map((criteria, idx) => (
                  <Text
                    key={idx}
                    style={{
                      fontSize: 12,
                      color: "#6B7280",
                      marginBottom: idx < objective.evalCriterias.length - 1 ? 12 : 0,
                      lineHeight: 18,
                      flexShrink: 1,
                      flexWrap: "wrap",
                      width: "100%",
                    }}>
                    {criteria}
                  </Text>
                ))}
              </View>
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

export default ProgressTracker;
