import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { z } from "zod";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import NewCustomAxioActionModal from "../UsersTabContainer/NewCustomAxioActionModal";
import { SearchSelectMulti } from "../../../components/common/form/SearchSelect/SearchSelectMulti";
import { useGetCoursesBySchoolByQueryQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import { useAppConfig } from "../../../AppConfigProvider";

const modals = {
  addConfirmation: {
    title: "Success!",
    ariaLabel: "Courses Added Successfully Modal",
    warningText: "Courses have been added.",
    actionButtonText: "OK",
  },
  addError: {
    title: "Error!",
    ariaLabel: "Courses Added Error Modal",
    warningText: "An error occurred while adding the courses. Please try again.",
    actionButtonText: "CLOSE",
  },
};

export enum AddCoursesModalState {
  CLOSED = "CLOSED",
  ADD_CONFIRMATION = "ADD_CONFIRMATION",
  ADD_ERROR = "ADD_ERROR",
}

const formSchema = z.object({
  courses: z.array(z.object({ id: z.string(), name: z.string() })),
});

type FormData = {
  courses: { id: string; name: string }[];
};

interface AddCoursesModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (courseIds: string[]) => Promise<boolean>;
}

const AddCoursesModalContainer = ({ visible, onClose, onSubmit }: AddCoursesModalProps) => {
  const { isMedium } = useResponsiveScreen();
  const [modalState, setModalState] = useState(AddCoursesModalState.CLOSED);
  const [searchQuery, setSearchQuery] = useState("");

  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  const { control, handleSubmit, reset } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      courses: [],
    },
    mode: "onChange",
  });
  const { data: schoolsData } = useSchoolsQuery();
  const {
    data: coursesData,
    refetch,
    loading,
  } = useGetCoursesBySchoolByQueryQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0].id,
      query: searchQuery,
      page: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    refetch();
  }, [searchQuery]);

  const handleFormSubmit = async (formData: FormData) => {
    try {
      const courseIds = formData.courses.map((c) => c.id);

      const success = await onSubmit(courseIds);
      if (success) {
        reset({ courses: [] });
        onClose();
        setModalState(AddCoursesModalState.ADD_CONFIRMATION);
      } else {
        setModalState(AddCoursesModalState.ADD_ERROR);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalState(AddCoursesModalState.ADD_ERROR);
    }
  };

  const handleModalClose = () => {
    setModalState(AddCoursesModalState.CLOSED);
  };

  return (
    <>
      <CustomModal
        visible={visible}
        onRequestClose={onClose}
        title="Add Courses"
        exitFill="#6B7280"
        ariaLabel="Add courses modal"
        style={{ width: isMedium ? "50%" : "90%" }}
        onShow={() => {}}
        showCloseButton={true}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.formGroup}>
              <View style={styles.inputContainer}>
                <SearchSelectMulti
                  name="courses"
                  control={control}
                  label="Courses"
                  loading={loading}
                  placeholder="Start typing to search and select one or more courses."
                  backgroundColor={primaryColor}
                  fetchOptions={async (searchQuery) => {
                    setSearchQuery(searchQuery);

                    return (
                      coursesData?.getCoursesBySchoolByQuery?.data.courses.map((d) => ({
                        id: d.id,
                        name: d.name,
                      })) ?? []
                    );
                  }}
                />
              </View>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <CustomButton
              text="Cancel"
              onPress={onClose}
              styleType="secondary"
              size="m"
              style={styles.cancelButton}
              textStyle={styles.cancelButtonText}
              leftIcon={null}
              rightIcon={null}
              disabled={false}
              textProps={{}}
            />
            <CustomButton
              text="Add Courses"
              onPress={handleSubmit(handleFormSubmit)}
              styleType="primary"
              size="m"
              style={styles.sendButton}
              textStyle={styles.sendButtonText}
              leftIcon={null}
              rightIcon={null}
              textProps={{}}
            />
          </View>
        </View>
      </CustomModal>
      <NewCustomAxioActionModal
        visible={modalState === AddCoursesModalState.ADD_CONFIRMATION}
        onClose={handleModalClose}
        modalTexts={modals.addConfirmation}
      />
      <NewCustomAxioActionModal
        visible={modalState === AddCoursesModalState.ADD_ERROR}
        onClose={handleModalClose}
        modalTexts={modals.addError}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 24,
    minHeight: 550,
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    gap: 16,
    marginBottom: 16,
    alignItems: "flex-end",
  },
  formGroup: {
    flex: 1,
  },
  inputContainer: {
    minHeight: 70,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 12,
    marginTop: 8,
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  sendButton: {
    backgroundColor: "#4F46E5",
    borderRadius: 6,
    paddingHorizontal: 16,
  },
  cancelButtonText: {
    color: "#4B5563",
  },
  sendButtonText: {
    color: "#FFFFFF",
  },
});

export default AddCoursesModalContainer;
