import React, { createContext, useState } from "react";
import {
  GetNotEnrolledCoursesBySchoolDocument,
  GetEnrolledCoursesBySchoolDocument,
  useEnrollUserInCourseMutation,
  useMakeCoursePaymentMutation,
  useSchoolsQuery,
} from "../graphql/generated/graphql";
import { Platform } from "react-native";
import handleOpenLink from "../utils/handleOpenLink";

const CourseDetailsContext = createContext();

const defaultValues = {
  courseId: "",
  name: "",
  description: "",
  objectives: "",
  targetAudience: "",
  cost: 0,
};

const CourseDetailsProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [courseData, setCourseData] = useState(defaultValues);
  const { data: schoolsData } = useSchoolsQuery();

  const [createCheckoutSession, { loading: creatingPaymentSession }] = useMakeCoursePaymentMutation();
  const [enrollStudent, { loading: enrollingUserInCourse }] = useEnrollUserInCourseMutation({
    refetchQueries: [
      {
        query: GetEnrolledCoursesBySchoolDocument,
        variables: {
          schoolId: schoolsData?.getSchools[0]?.id,
        },
        skip: !schoolsData?.getSchools[0]?.id,
      },
      {
        query: GetNotEnrolledCoursesBySchoolDocument,
        variables: {
          schoolId: schoolsData?.getSchools[0]?.id,
        },
        skip: !schoolsData?.getSchools[0]?.id,
      },
    ],
  });

  const onEnroll = async (id, hasCost) => {
    let newWindow;

    if (hasCost) {
      const data = await createCheckoutSession({
        variables: {
          courseId: id,
        },
      });

      if (Platform.OS === "web") {
        newWindow = window.open("", "_blank");
      }
      const stripeUrl = data?.data?.createCheckoutSession?.url;

      await handleOpenLink(stripeUrl, newWindow);
    } else {
      await enrollStudent({
        variables: {
          courseId: id,
        },
      });
    }

    setIsVisible(false);
    setCourseData(defaultValues);
  };

  const loading = creatingPaymentSession || enrollingUserInCourse;

  return (
    <CourseDetailsContext.Provider
      value={{
        isVisible,
        setIsVisible,
        courseData,
        setCourseData,
        onEnroll,
        loading,
      }}>
      {children}
    </CourseDetailsContext.Provider>
  );
};

export { CourseDetailsContext, CourseDetailsProvider };
