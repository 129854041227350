import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Control } from "react-hook-form";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { SearchSelectMulti } from "../../../components/common/form/SearchSelect/SearchSelectMulti";
import { useGetDegreesBySchoolByQueryQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import { useAppConfig } from "../../../AppConfigProvider";

interface AddCourseDegreesStepProps {
  control: Control;
}

const AddCourseDegreesStep: React.FC<AddCourseDegreesStepProps> = ({ control }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { primaryColor } = useAppConfig();

  const { data: schoolsData } = useSchoolsQuery();

  const {
    refetch: degreesRefetch,
    data: degreesData,
    loading,
  } = useGetDegreesBySchoolByQueryQuery({
    variables: {
      schoolId: schoolsData?.getSchools[0].id,
      query: searchQuery,
      page: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    degreesRefetch();
  }, [searchQuery]);

  return (
    <View style={styles.formGroup}>
      <CustomText text="Course Degrees" size="s" textType="text" style={{ color: "#182230" }} weight="ultraBold" />
      <SearchSelectMulti
        name="degrees"
        control={control}
        label=""
        loading={loading}
        placeholder="Start typing to search and select one or more degrees."
        backgroundColor={primaryColor}
        fetchOptions={async (searchQuery) => {
          setSearchQuery(searchQuery);

          return (
            degreesData?.getDegreesBySchoolByQuery?.data.degrees.map((d) => ({
              id: d.id,
              name: d.name,
            })) ?? []
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  formGroup: {
    marginBottom: 24,
  },
});

export default AddCourseDegreesStep;
