import { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../../../hooks/useWebSocket";
import AIMessage from "../../../components/chat/AIMessage/AIMessage";
import UserMessage from "../../../components/chat/UserMessage/UserMessage";
import LearningMessage from "../../../components/chat/LearningMessage/LearningMessage";
import AspectMessage from "../../../components/chat/AspectMessage/AspectMessage";
import AspectModal from "../../../components/chat/AspectModal/AspectModal";
import AspectFormContainer from "../AspectFormContainer/AspectFormContainer";
import QuestMessage from "../../../components/chat/QuestMessage/QuestMessage";
import QuestModal from "../../../components/gamification/QuestModal/QuestModal";
import Attachment from "../../../components/chat/Attachment/Attachment";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { MotiView } from "moti";
import { View, Text, Modal } from "react-native";
import {
  useMeQuery,
  useGetLearningTreeQuery,
  useEnrollUserInTrackMutation,
  MeDocument,
} from "../../../graphql/generated/graphql";
import { useChatBar } from "../../../contexts/ChatBarContext";
import Sentry from "../../../utils/sentry";
import VisualizationMessage from "../../../components/chat/VisualizationMessage/VisualizationMessage";
import YoutubeVideoPlayerAdvanced from "../../../components/youtube/YoutubeVideoPlayerAdvanced";
import { WebView } from "react-native-webview";

function MessageContainer({ message, index }) {
  const { sendMessage } = useWebSocket();
  const { setShowChat } = useChatBar();
  const navigation = useNavigation();
  const { data: meData } = useMeQuery({});
  const level = message.parameters?.lastGeneratedLabel;
  const name = message.parameters?.lastGeneratedTopic;
  const [enrollUserInTrack] = useEnrollUserInTrackMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  const [aspectModalVisible, setAspectModalVisible] = useState(false);
  const [questModalVisible, setQuestModalVisible] = useState(false);
  const [visualizationModalVisible, setVisualizationModalVisible] = useState(false);

  let type = message.type || message.sender || null;
  if (index === 0 && message.sender === "ai") {
    type = "first-ai";
  }
  if (index === 0 && message.sender === "human") {
    type = "first-human";
  }

  const handleOpenAspectModal = () => {
    setAspectModalVisible(true);
  };

  const handleCloseAspectModal = () => {
    setAspectModalVisible(false);
  };

  const handleOpenQuestModal = () => {
    setQuestModalVisible(true);
  };

  const handleCloseQuestModal = () => {
    setQuestModalVisible(false);
  };

  const handleToggleVisualizationModal = () => {
    setVisualizationModalVisible(!visualizationModalVisible);
  };

  const {
    data: learningTreeData,
    loading,
    error,
  } = useGetLearningTreeQuery({
    variables: { level, name },
    skip: !level || !name,
  });

  const enrolledTracks = meData?.me?.enrolledTracks || [];

  const handleLearningPress = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
      state: {},
      content: {},
    });

    if (loading) return;
    if (error) {
      Sentry.captureException(error);
      return;
    }

    const tree = learningTreeData?.getLearningTree;

    if (!tree) {
      Sentry.captureException("No learning tree data available");
      return;
    }

    setShowChat(false);

    switch (level) {
      case "Field":
        navigation.navigate("Categories", { field: tree.FieldName });
        break;
      case "Subfield":
        navigation.navigate("Tracks", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
        });
        break;
      case "Topic":
        if (enrolledTracks.includes(name)) {
          navigation.navigate("Lesson", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        } else {
          navigation.navigate("Track Overview", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        }
        break;
      case "Chapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
          ...(tree.SubchapterName && { subchapter: tree.SubchapterName }),
        });
        break;
      }
      case "Subchapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
          subchapter: tree.SubchapterName,
        });
        break;
      }
      default:
        Sentry.captureException(`Unhandled learning type: ${level}`);
    }
  };

  let visualizationData;
  let visualizationMessage;

  switch (type) {
    case "ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <AIMessage text={message.content} />
          <CustomSpacing type="vertical" size="xs" />
        </>
      );
    case "human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <UserMessage text={message.content} />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    case "first-ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <AIMessage text={message.content} isFirst={true} />
          <CustomSpacing type="vertical" size="xs" />
        </>
      );
    case "first-human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <MotiView
            from={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ type: "timing", duration: 600 }}>
            <UserMessage text={message.content} />
          </MotiView>
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    case "aspect":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <AspectMessage message={message} onPress={handleOpenAspectModal} />
          <CustomSpacing type="vertical" size="m" />

          <AspectModal message={message} visible={aspectModalVisible} onClose={handleCloseAspectModal}>
            <CustomSpacing type="vertical" size="m" />
            <AspectFormContainer message={message} onClose={handleCloseAspectModal} />
          </AspectModal>
        </>
      );
    case "quest":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <QuestMessage type={message.snackType} onPress={handleOpenQuestModal} />
          <CustomSpacing type="vertical" size="m" />

          <QuestModal
            type={message.snackType}
            userResponse={message.parameters.userResponse}
            visible={questModalVisible}
            onClose={handleCloseQuestModal}
          />
        </>
      );
    case "snack":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <LearningMessage
            topic={message.parameters.lastGeneratedTopic}
            label={message.parameters.lastGeneratedLabel}
            onPress={handleLearningPress}
          />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    case "document":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <View style={{ alignItems: "flex-end" }}>
            <Attachment title={message.file.fileName} fileType="PDF" showDeleteButton={false} isLoading={false} />
            <CustomSpacing type="vertical" size="m" />
          </View>
        </>
      );
    case "video": {
      // Extract video data from the message
      let videoData;
      console.log("Video data:", message);
      try {
        if (typeof message.video === "object") {
          videoData = message.video;
        } else if (typeof message.content === "string" && message.content.startsWith("{")) {
          videoData = JSON.parse(message.content);
        } else {
          console.error("Invalid video data format");
          return <></>;
        }
      } catch (e) {
        console.error("Failed to parse video content:", e);
        return <></>;
      }

      // Get the videoId
      const videoId = videoData.videoId;
      // Get timestamp if available
      const timestamp = videoData.timestamp || null;
      // Check if there's an intro message that should be displayed before the video
      const introText = message.text || message.finalOutput || null;

      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          {introText && <AIMessage text={introText} />}
          <View style={{ width: "100%", marginVertical: 10 }}>
            <View
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 12,
                overflow: "hidden",
                width: "100%",
                shadowColor: "#000",
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.1,
                shadowRadius: 8,
                elevation: 4,
                borderWidth: 1,
                borderColor: "#E5E7EB",
              }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 12,
                  borderBottomWidth: 1,
                  borderBottomColor: "#E5E7EB",
                  backgroundColor: "#F9FAFB",
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    color: "#111827",
                  }}>
                  {videoData.title || "Video"}
                </Text>
              </View>
              <View>
                <YoutubeVideoPlayerAdvanced id={videoId} />
              </View>
            </View>
          </View>
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    }
    case "visualization": {
      let visualizationData;
      // Parse the content which now contains all visualization data
      try {
        visualizationData = JSON.parse(message.content);
      } catch (e) {
        console.error("Failed to parse visualization content:", e);
        return <></>;
      }
      let cleanContent = visualizationData.content;

      console.log("Visualization data:", visualizationData);
      // Construct visualization object in the expected format
      const visualizationMessage = {
        visualization: {
          type: visualizationData.visualizationType || "interactive",
          content: cleanContent,
          visualizationId: visualizationData.visualizationId,
        },
      };

      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <View style={{ width: "100%" }}>
            <VisualizationMessage
              message={visualizationMessage}
              onExpand={handleToggleVisualizationModal}
              isWider={true}
            />
          </View>
          <CustomSpacing type="vertical" size="m" />

          <Modal
            animationType="fade"
            transparent={true}
            visible={visualizationModalVisible}
            onRequestClose={handleToggleVisualizationModal}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                padding: 20,
              }}>
              <View
                style={{
                  width: "95%",
                  height: "90%",
                  backgroundColor: "white",
                  borderRadius: 12,
                  overflow: "hidden",
                }}>
                <VisualizationMessage
                  message={visualizationMessage}
                  onExpand={handleToggleVisualizationModal}
                  isFullScreen={true}
                />
              </View>
            </View>
          </Modal>
        </>
      );
    }
    default:
      return <></>;
  }
}

export default MessageContainer;
