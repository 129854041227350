import React, { useState, useEffect } from "react";
import { View, StyleSheet, Modal, Image } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import CustomModal from "../../components/common/general/CustomModal/CustomModal";

const AiTutorCompleteModal = ({ visible, onBackPress, onNextPress, hasNextActivity }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      role="dialog"
      aria-modal="true"
      aria-label="completion modal">
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Image
            style={styles.image}
            source={require("../../assets/completed.png")}
            resizeMode="contain"
            accessibilityLabel="aiTutor completed"
            alt="aiTutor completed"
          />
          <CustomSpacing type="vertical" size="xs" />
          <CustomSpacing type="vertical" size="xxl" />
          <CustomText
            aria-level="1"
            text="Activity Completed"
            size="xxl"
            weight="ultraBold"
            style={{ color: "#101828", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="l" />
          <CustomText
            text="You’ve finished the activity. You can return to the conversation anytime to review your responses."
            size="l"
            weight="normal"
            style={{ color: "#344054", textAlign: "center" }}
          />
          <CustomSpacing type="vertical" size="xs" />
          <CustomSpacing type="vertical" size="xxl" />
          <View style={styles.buttonBox}>
            <CustomButton
              aria-label="Back to the course page"
              text="Back to the course page"
              onPress={onBackPress}
              styleType="primary"
              style={styles.exitButton}
              textStyle={{ fontWeight: "700", fontSize: 18, fontFamily: "Inter" }}
            />
            {hasNextActivity && (
              <CustomButton
                aria-label="Next activity"
                text="Next activity"
                onPress={onNextPress}
                styleType="primary"
                style={styles.exitButton}
                textStyle={{ fontWeight: "700", fontSize: 18, fontFamily: "Inter" }}
              />
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    borderRadius: 40,
    padding: 40,
    height: "auto",
    maxWidth: 688,
    width: "auto",
    backgroundColor: "white",
    alignItems: "center",
  },
  exitButton: {
    backgroundColor: "#3E68FE",
    padding: 16,
    borderRadius: 8,
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 16,
  },
  image: {
    width: 241,
    height: 215,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AiTutorCompleteModal;
