import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Document({ fill = "#98A2B3", ...props }) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.913 6.213l-4.375-4.375a.938.938 0 00-.663-.276h-7.5a1.562 1.562 0 00-1.563 1.563v13.75a1.563 1.563 0 001.563 1.563h11.25a1.563 1.563 0 001.563-1.563v-10a.937.937 0 00-.275-.662zM12.5 4.453l1.797 1.797H12.5V4.453zm-7.813 12.11V3.438h5.938v3.75a.938.938 0 00.938.937h3.75v8.438H4.687zm8.75-6.25a.938.938 0 01-.937.937h-5a.938.938 0 010-1.875h5a.937.937 0 01.938.938zm0 3.124a.938.938 0 01-.937.938h-5a.938.938 0 010-1.875h5a.938.938 0 01.938.938z"
        fill={fill}
      />
    </Svg>
  );
}

export default Document;
