import React, { useState, useEffect, useMemo } from "react";
import { View, StyleSheet, TextInput, ScrollView, TouchableOpacity } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import SelectDropdown from "react-native-select-dropdown";

const SearchSuggestion = ({ item, onSelect }) => (
  <TouchableOpacity onPress={() => onSelect(item)} style={styles.suggestionItem}>
    <CustomText text={item.label} useTranslationText={false} size="s" />
  </TouchableOpacity>
);

const StudentAnalyticsSearchBar = ({ data, onDrillDown, defaultSearchText = "", isLoading = false }) => {
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // Create options for the student filter dropdown
  const studentOptions = useMemo(() => {
    return data.map((student) => ({
      label: student.label,
      value: student,
    }));
  }, [data]);

  // Handle search suggestions
  useEffect(() => {
    if (!searchText) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const filtered = data
      .filter((student) => student.label.toLowerCase().includes(searchText.toLowerCase()))
      .slice(0, 5);

    setSuggestions(filtered);
    setShowSuggestions(true);
  }, [searchText, data]);

  const handleSuggestionSelect = (item) => {
    setShowSuggestions(false);
    setSearchText("");
    onDrillDown(item);
  };

  const handleApplyFilter = () => {
    setShowFilterModal(false);
    if (selectedStudent) {
      onDrillDown(selectedStudent);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search students..."
          value={searchText}
          onChangeText={setSearchText}
        />
      </View>

      <CustomButton text="Filter" styleType="primary" size="s" onPress={() => setShowFilterModal(true)} />

      {/* Suggestions Portal - Positioned fixed */}
      {showSuggestions && suggestions.length > 0 && (
        <View style={styles.suggestionsWrapper}>
          <ScrollView style={styles.suggestionsContainer}>
            {suggestions.map((item, index) => (
              <SearchSuggestion key={index} item={item} onSelect={handleSuggestionSelect} />
            ))}
          </ScrollView>
        </View>
      )}

      <CustomModal
        visible={showFilterModal}
        onRequestClose={() => setShowFilterModal(false)}
        title="Select Student"
        showCloseButton>
        <View style={styles.modalContent}>
          <View style={styles.filterDropdown}>
            <CustomText text="Student" useTranslationText={false} size="s" style={styles.label} />
            <SelectDropdown
              data={studentOptions}
              defaultValue={selectedStudent}
              onSelect={(item) => setSelectedStudent(item.value)}
              buttonStyle={styles.dropdown}
              buttonTextStyle={styles.dropdownText}
              dropdownStyle={styles.dropdownList}
              rowStyle={styles.dropdownRow}
              rowTextStyle={styles.dropdownRowText}
              defaultButtonText="Select student..."
              renderDropdownIcon={(isOpened) => (
                <View style={[styles.dropdownIcon, isOpened && styles.dropdownIconUp]} />
              )}
              dropdownIconPosition="right"
              renderCustomizedButtonChild={(selectedItem) => (
                <View style={styles.dropdownButton}>
                  {selectedItem ? (
                    <CustomText
                      text={selectedItem.label}
                      size="xs"
                      style={styles.dropdownButtonText}
                      useTranslationText={false}
                    />
                  ) : (
                    <CustomText
                      text="Select student..."
                      size="xs"
                      style={styles.dropdownPlaceholder}
                      useTranslationText={false}
                    />
                  )}
                </View>
              )}
              renderCustomizedRowChild={(item) => (
                <View style={styles.dropdownRow}>
                  <CustomText text={item.label} size="xs" style={styles.dropdownRowText} useTranslationText={false} />
                </View>
              )}
            />
          </View>

          <View style={styles.modalButtons}>
            <CustomButton text="Clear" styleType="transparent" size="s" onPress={() => setSelectedStudent(null)} />
            <CustomButton text="Apply" styleType="primary" size="s" onPress={handleApplyFilter} />
          </View>
        </View>
      </CustomModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    backgroundColor: "#f3f4f6",
    borderRadius: 8,
    gap: 8,
    position: "relative",
  },
  searchContainer: {
    flex: 1,
    position: "relative",
  },
  searchInput: {
    backgroundColor: "#fff",
    padding: 8,
    height: 40,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
  },
  suggestionsWrapper: {
    position: "absolute",
    top: 48,
    left: 8, // match container padding
    right: 8, // match container padding
    zIndex: 1002,
  },
  suggestionsContainer: {
    backgroundColor: "#fff",
    maxHeight: 200,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  suggestionItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#f3f4f6",
  },
  modalContent: {
    gap: 16,
    padding: 16,
  },
  modalButtons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 8,
    marginTop: 16,
  },
  filterDropdown: {
    gap: 4,
  },
  label: {
    color: "#111827",
    marginBottom: 8,
    fontWeight: "500",
  },
  dropdown: {
    width: "100%",
    height: 40,
    backgroundColor: "white",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    paddingHorizontal: 12,
  },
  dropdownList: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#D1D5DB",
    backgroundColor: "white",
  },
  dropdownRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    height: 40,
  },
  dropdownRowText: {
    fontSize: 14,
    color: "#111827",
  },
  dropdownIcon: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 5,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: "#6B7280",
  },
  dropdownIconUp: {
    transform: [{ rotate: "180deg" }],
  },
  dropdownButton: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  dropdownButtonText: {
    color: "#111827",
    fontSize: 14,
  },
  dropdownPlaceholder: {
    color: "#6B7280",
    fontSize: 14,
  },
});

export default StudentAnalyticsSearchBar;
