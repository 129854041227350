import React, { createContext, useEffect, useReducer, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import { actions, useAppState } from "./AppStateContext";
import { useSessionQuery, useLogoutMutation } from "../graphql/generated/graphql";
import { usePostHog } from "posthog-js/react";
import Sentry from "../utils/sentry";

interface DecodedToken {
  userId: string;
  email: string;
  name: string;
  deviceId: string;
  isEmailVerified: boolean;
  otpVerifiedForSession: boolean;
  iat: number;
  exp: number;
}

interface AuthContextType {
  isUpdating: boolean;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  otpVerifiedForSession: boolean;
  login: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  verifyOTPForSession: (token: string) => Promise<void>;
}

export const decodeToken = async (token: string) => {
  const decoded = jwtDecode<DecodedToken>(token);
  return decoded;
};

export const AuthContext = createContext<AuthContextType>({
  isUpdating: false,
  isAuthenticated: false,
  isEmailVerified: false,
  otpVerifiedForSession: false,
  login: async () => {},
  logout: async () => {},
  verifyOTPForSession: async () => {},
});

const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_AUTH_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const AuthProvider = ({ children, apolloClient }) => {
  const [isUpdating, setIsUpdating] = useState(true);
  const [state, dispatch] = useReducer(authReducer, {
    isLoading: true,
    isAuthenticated: false,
    isEmailVerified: false,
    otpVerifiedForSession: false,
  });

  const { dispatch: appDispatch } = useAppState();
  const posthog = usePostHog();

  const { data, loading, startPolling, stopPolling } = useSessionQuery({
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
    skip: !state.isAuthenticated,
  });

  const [logoutMutation] = useLogoutMutation();

  useEffect(() => {
    const checkToken = async () => {
      const token = await AsyncStorage.getItem("token");

      if (token) {
        const decoded = await decodeToken(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          // Token has expired
          posthog?.reset(true);
          logout();
        } else {
          posthog?.identify(decoded.userId);
          dispatch({
            type: "SET_AUTH_STATE",
            payload: {
              isLoading: false,
              isAuthenticated: true,
              isEmailVerified: decoded.isEmailVerified,
              otpVerifiedForSession: decoded.otpVerifiedForSession,
            },
          });
        }
      } else {
        posthog?.reset(true);
        dispatch({
          type: "SET_AUTH_STATE",
          payload: {
            isLoading: false,
            isAuthenticated: false,
            isEmailVerified: false,
            otpVerifiedForSession: false,
          },
        });
      }
    };

    if (isUpdating) {
      checkToken();
    }
    setIsUpdating(false);
  }, [isUpdating]);

  useEffect(() => {
    if (data && !data.validateSession.isValid) {
      posthog?.reset(true);
      logout();
    }
  }, [data]);

  useEffect(() => {
    const pollInterval = 60000;
    startPolling(pollInterval);

    return () => {
      stopPolling();
    };
  }, []);

  const login = async (token) => {
    setIsUpdating(true);

    try {
      await AsyncStorage.setItem("token", token);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const logout = async () => {
    setIsUpdating(true);
    try {
      const result = await logoutMutation();

      if (!result?.data?.logout?.success) {
        throw new Error("Failed to logout");
      }

      await AsyncStorage.removeItem("token");

      setIsUpdating(true);

      if (apolloClient) {
        await apolloClient.clearStore();
      }

      appDispatch({
        type: actions.RESET_STATE,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const verifyOTPForSession = async (token) => {
    try {
      await AsyncStorage.setItem("token", token);
      setIsUpdating(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (loading || state.isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        isUpdating,
        isAuthenticated: state.isAuthenticated,
        isEmailVerified: state.isEmailVerified,
        otpVerifiedForSession: state.otpVerifiedForSession,
        login,
        logout,
        verifyOTPForSession,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
