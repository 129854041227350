import React, { createContext, useEffect, useState } from "react";
import { useAppState } from "./AppStateContext";
import { useAppConfig } from "../AppConfigProvider";
import Sentry from "../utils/sentry";

interface WebSocketContextType {
  socket: WebSocket | null;
  reconnect: () => void;
  sendHandler: (message: any) => Promise<boolean>;
}

const initialState: WebSocketContextType = {
  socket: null,
  reconnect: () => {},
  sendHandler: () => Promise.resolve(false),
};

export const WebSocketContext = createContext<WebSocketContextType>(initialState);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { dispatch } = useAppState();
  const appConfig = useAppConfig();
  const currentDomain = appConfig.currentDomain;

  const initializeSocket = async () => {
    // const currentDomain = await AsyncStorage.getItem("domain");
    const ws_domain = `wss://${currentDomain}/backend/ws`;
    // if (process.env.EXPO_PUBLIC_RELEASE == "production") {
    //   ws_domain = `wss://${currentDomain}/backend/ws`;
    // } else if (process.env.EXPO_PUBLIC_RELEASE == "stage") {
    //   ws_domain = `wss://${currentDomain}/backend/ws`;
    // }

    const ws = new WebSocket(ws_domain);

    setSocket(ws);
  };

  useEffect(() => {
    initializeSocket();
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [dispatch]);

  const reconnect = () => {
    if (socket) {
      socket.close();
    }
    initializeSocket();
  };

  const sendHandler = (message: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        try {
          socket.send(JSON.stringify(message));
          resolve(true);
        } catch (error) {
          Sentry.captureException(error);
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  };

  return <WebSocketContext.Provider value={{ socket, reconnect, sendHandler }}>{children}</WebSocketContext.Provider>;
};
