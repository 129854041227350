import DefaultLayout from "../layouts/DefaultLayout";
import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { MotiView } from "moti";

import useResponsiveScreen from "../hooks/useResponsiveScreen";
import DegreeCardListContainer from "../containers/degreeContainer/DegreeCardListContainer";

function DegreeView() {
  const { isMedium } = useResponsiveScreen();

  return (
    <>
      <DefaultLayout menuType="null">
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={10}
          transition={{ type: "timing", duration: 500 }}
          style={{
            width: "100%",
            alignSelf: "center",
            paddingHorizontal: 20,
          }}>
          <CustomSpacing type="vertical" size="s" />
          <CustomSpacing type="vertical" size="s" />
          <View style={{ flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", flex: 1 }}>
            <CustomText
              text="Choose a Degree"
              weight={isMedium ? "ultraBold" : "bold"}
              size={isMedium ? "s" : "xs"}
              textType="display"
            />
            <CustomSpacing type="vertical" size="s" />
            <CustomSpacing type="vertical" size="s" />
            <CustomSpacing type="vertical" size="s" />
            <View
              style={{
                width: "100%",
                maxWidth: 900,
                alignSelf: "center",
              }}>
              <CustomText
                style={[styles.contentText, { textAlign: "center" }]}
                text="Based on your career goals and interests, Axio has tailored degree options just for you. Choose the one that fits your path, and we’ll start building your personalized degree plan. Don’t worry—you're not locked in! You can always change your degree later if your goals evolve."
                size="l"
              />
            </View>
          </View>
          <CustomSpacing type="vertical" size="s" />
          <CustomSpacing type="vertical" size="s" />
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={800}
            transition={{ type: "timing", duration: 600 }}>
            <DegreeCardListContainer />
          </MotiView>

          <CustomSpacing type="vertical" size="s" />
          <CustomSpacing type="vertical" size="s" />

          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 16,
            }}>
            <CustomButton
              text="Save and Continue"
              textStyle={styles.continueText}
              style={[
                styles.centeredButton,
                {
                  backgroundColor: "#3E68FE",
                  borderColor: "#3E68FE",
                },
              ]}
              size="s"
              bold={true}
              //   onPress={() => handleExportPress(data?.getContentById)}
              aria-label="Export data"
              accessibilityHint="Press to export data"
            />
            <CustomButton
              text="Show me more"
              textStyle={styles.saveText}
              style={[
                styles.centeredButton,
                {
                  shadowColor: "transparent",
                  elevation: 0,
                  padding: "8px",
                  gap: "4px",
                  border: "2px solid #e6e6e7",
                },
              ]}
              size="s"
              bold={true}
              //   onPress={() => handleExportPress(data?.getContentById)}
              aria-label="Export data"
              accessibilityHint="Press to export data"
            />
          </View>
        </MotiView>
      </DefaultLayout>
    </>
  );
}
const styles = StyleSheet.create({
  centeredButton: {
    width: "80%",
    maxWidth: 388,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 2,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  saveText: {
    color: "#3E68FE",
  },
  continueText: {
    color: "white",
  },
  contentText: {
    color: "#667085",
  },
});
export default DegreeView;
