import { View, StyleSheet } from "react-native";
import BlankLayout from "../layouts/BlankLayout";
import SetPasswordContainer from "../containers/auth/SetPasswordContainer/SetPasswordContainer";
import { useGetIsInvitationTokenValidQuery, useMeQuery } from "../graphql/generated/graphql";
import { useRoute, RouteProp } from "@react-navigation/native";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import Loader from "../components/common/status/Loader/Loader";
import CustomText from "../components/common/general/CustomText/CustomText";
import Logo from "../components/general/logos/Logo/Logo";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { useAppConfig } from "../AppConfigProvider";

function SetPasswordView() {
  const { params } = useRoute();
  const appConfig = useAppConfig();
  const domainKey = appConfig.key;
  const { data, loading } = useGetIsInvitationTokenValidQuery({
    variables: {
      token: params?.token,
      service: params?.service,
    },
    skip: !params?.token,
  });

  const isTokenValid = data?.getIsInvitationTokenValid.isValid;

  return (
    <BlankLayout>
      <View style={styles.container}>
        <View style={styles.formContainer}>
          {loading ? (
            <Loader />
          ) : isTokenValid ? (
            <SetPasswordContainer />
          ) : (
            <View style={styles.invalidLinkContainer}>
              <Logo name={domainKey} height={25} width={92} />
              <CustomSpacing type="vertical" size="xl" />
              <CustomText text="This link is no longer valid" />
            </View>
          )}
        </View>
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  formContainer: {
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidLinkContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

export default SetPasswordView;
