import { View, StyleSheet, Pressable } from "react-native";
import CustomText from "../../components/common/general/CustomText/CustomText";
import Divider from "../../components/common/layout/Divider/Divider";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";
import { useAppConfig } from "../../AppConfigProvider";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";
import { Menu } from "react-native-paper";
import { ThreeDot } from "../svgs/common";
import { useState } from "react";

const VIEW_COURSE = "View Course";
const EDIT_COURSE = "Edit Course";
const DELETE_COURSE = "Delete Course";

const menuOptions = [
  { id: 1, text: VIEW_COURSE },
  { id: 2, text: EDIT_COURSE },
  { id: 3, text: DELETE_COURSE },
];

const CustomCourseCard = ({ course, courseIndex, handleCardPress, handleDeleteCourseModalOpen, handleEditCourse }) => {
  const [menuVisible, setMenuVisible] = useState(null);
  const { isMedium } = useResponsiveScreen();
  const { primaryColor } = useAppConfig();

  const handleOpenOptionsMenu = (id) => {
    setMenuVisible(id);
  };

  const handleOptionPress = (optionId, courseId, courseName) => {
    switch (optionId) {
      case 1:
        handleCardPress(courseName, courseId);
        setMenuVisible(null);
        break;
      case 2:
        handleEditCourse(courseId);
        setMenuVisible(null);
        break;
      case 3:
        handleDeleteCourseModalOpen(courseId);
        setMenuVisible(null);
        break;
      default:
        break;
    }
  };

  return (
    <View key={`course-${courseIndex}`} style={styles.courseContainer}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: isMedium ? "center" : "flex-start",
        }}>
        <CustomText useTranslationText={false} ultraBold text={course?.name} size="xxl" />
        <View>
          {isMedium ? (
            <View style={styles.buttonGroup}>
              <CustomButton
                onPress={() => handleCardPress(course.name, course.id)}
                styleType="primaryLight"
                text={VIEW_COURSE}
              />
              <Pressable onPress={() => handleEditCourse(course.id)}>
                <CustomText
                  style={{
                    color: primaryColor,
                    padding: 12,
                    fontSize: 16,
                    fontFamily: "inter",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  text={EDIT_COURSE}
                />
              </Pressable>
              <Pressable onPress={() => handleDeleteCourseModalOpen(course.id)}>
                <CustomText
                  style={{
                    color: "#F75C8A",
                    padding: 12,
                    fontSize: 16,
                    fontFamily: "inter",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  text={DELETE_COURSE}
                />
              </Pressable>
            </View>
          ) : (
            <View>
              <Menu
                contentStyle={styles.menu}
                visible={menuVisible === course.id}
                onDismiss={() => setMenuVisible(null)}
                anchor={
                  <Pressable onPress={() => handleOpenOptionsMenu(course.id)} style={{ height: 35, width: 35 }}>
                    <ThreeDot fill="#000" height={35} width={35} />
                  </Pressable>
                }>
                {menuOptions.map((option) => (
                  <Menu.Item
                    key={option.id}
                    onPress={() => handleOptionPress(option.id, course.id, course.name)}
                    title={option.text}
                  />
                ))}
              </Menu>
            </View>
          )}
        </View>
      </View>
      <Divider type="vertical" />
      <View style={styles.detailsContainer}>
        <View
          style={{
            flexDirection: isMedium ? "row" : "column",
            justifyContent: "flex-start",
            alignItems: isMedium ? "center" : "flex-start",
          }}>
          <View style={{ flexDirection: "row", alignItems: "center", marginRight: 20 }}>
            <CustomText ultraBold text="Learning Standards" size="l" />
            <CustomText
              style={{ color: "#777f89", marginLeft: 5 }}
              useTranslationText={false}
              text={course?.learningStandards}
              size="m"
            />
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <CustomText ultraBold text="Number of Weeks" size="l" />
            <CustomText
              style={{ color: "#777f89", marginLeft: 5 }}
              useTranslationText={false}
              text={course?.lengthInWeeks}
              size="m"
            />
          </View>
        </View>

        <View style={styles.objectivesContainer}>
          <CustomText ultraBold text="Objectives" size="l" />
          {course?.objectives?.map((obj, objIndex) => (
            <CustomText
              style={{ color: "#777f89", marginLeft: 5 }}
              useTranslationText={false}
              key={`objective-${objIndex}`}
              text={obj}
              size="m"
            />
          ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  courseContainer: {
    borderWidth: 1,
    borderColor: "#dcd6d6",
    borderRadius: 20,
    marginBottom: 30,
    padding: 20,
  },
  buttonGroup: {
    flexDirection: "row",
    gap: 10,
  },
  objectivesContainer: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  detailsContainer: {
    marginTop: 10,
  },
  menu: {
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
});

export default CustomCourseCard;
