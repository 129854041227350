import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { MotiView } from "moti";
import CustomText from "../../../common/general/CustomText/CustomText";

function DataItem({
  title,
  avgTime,
  completionStats,
  successPoints,
  confusionPoints,
  questionsAsked,
  activeInteractionCount,
  distractions,
  enrollment,
  sentiment,
}) {
  // Round the completion stats to whole numbers for display
  const completed = completionStats ? Math.round(completionStats.completed) : 0;
  const inProgress = completionStats ? Math.round(completionStats.inProgress) : 0;
  const notStarted = completionStats ? Math.round(completionStats.notStarted) : 0;

  const avgTimeDisplay = avgTime || "N/A";

  return (
    <MotiView
      aria-label="Task card"
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ type: "timing", duration: 500 }}>
      <Pressable onPress={null} accessibilityHint="Press to see details">
        <View style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              borderBottomWidth: 1,
              borderBottomColor: "#e0e0e0",
              paddingBottom: 8,
            }}>
            <CustomText useTranslationText={false} text={title} size="s" weight="medium" style={styles.title} />
            <View style={{ flexDirection: "row" }}>
              <View
                style={{ flexDirection: "column", borderRightWidth: 1, borderRightColor: "#e0e0e0", paddingRight: 16 }}>
                <CustomText useTranslationText={false} text={avgTimeDisplay} size="s" weight="ultraBold" style={{}} />
                <CustomText
                  useTranslationText={false}
                  text="AVG. TIME"
                  weight="medium"
                  style={{ color: "#667085", fontSize: 10 }}
                />
              </View>
              <View style={{ flexDirection: "column", paddingHorizontal: 20 }}>
                <CustomText useTranslationText={false} text={`${completed}%`} size="s" weight="ultraBold" style={{}} />
                <CustomText
                  useTranslationText={false}
                  text="COMPLETED"
                  weight="medium"
                  style={{ color: "#667085", fontSize: 10 }}
                />
              </View>
              <View style={{ flexDirection: "column", paddingRight: 20 }}>
                <CustomText useTranslationText={false} text={`${inProgress}%`} size="s" weight="ultraBold" style={{}} />
                <CustomText
                  useTranslationText={false}
                  text="IN PROGRESS"
                  weight="medium"
                  style={{ color: "#667085", fontSize: 10 }}
                />
              </View>
              <View style={{ flexDirection: "column" }}>
                <CustomText useTranslationText={false} text={`${notStarted}%`} size="s" weight="ultraBold" style={{}} />
                <CustomText
                  useTranslationText={false}
                  text="NOT ATTEMPTED"
                  weight="medium"
                  style={{ color: "#667085", fontSize: 10 }}
                />
              </View>
            </View>
          </View>
          <View style={styles.dataRow}>
            {/* 1) AVG. TIME */}
            {/* <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${enrollment}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="Enrollments"
                style={styles.subText}
              />
            </View> */}

            {/* 2) Success Points */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${successPoints}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="SUCCESS" style={styles.subText} />
            </View>

            {/* 3) Confusion Points */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${confusionPoints}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="CONFUSION" style={styles.subText} />
            </View>

            {/* 4) Questions Asked */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${questionsAsked}`}
                style={styles.boldText}
              />
              <CustomText useTranslationText={false} size="s" weight="medium" text="QUESTIONS" style={styles.subText} />
            </View>

            {/* 5) Active Interaction */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${activeInteractionCount}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="Interactions"
                style={styles.subText}
              />
            </View>

            {/* 6) Distractions */}
            <View style={styles.dataItem}>
              <CustomText
                useTranslationText={false}
                size="s"
                weight="bold"
                text={`${distractions}`}
                style={styles.boldText}
              />
              <CustomText
                useTranslationText={false}
                size="s"
                weight="medium"
                text="DISTRACTIONS"
                style={styles.subText}
              />
            </View>
          </View>
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dataRow: {
    flexDirection: "row", // All data items in a row
    flexWrap: "wrap", // Allow wrapping if necessary
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 8,
  },
  dataItem: {
    alignItems: "center",
    marginBottom: 16,
    paddingHorizontal: 4,
  },
  boldText: {
    color: "#000",
  },
  subText: {
    fontSize: 10,
    lineHeight: 18,
    color: "#667085",
    textTransform: "uppercase",
  },
});

export default DataItem;
