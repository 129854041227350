import React, { createContext, useContext } from "react";

export type DomainConfig = {
  hasTrial: boolean;
  companionName: string;
  key: string;
  isLMS: boolean;
  isLTI: boolean;
  isAxioOffering: boolean;
  isSuggestedInsights: boolean;
  axioSchools: string[];
  primaryColor: string;
  learningFieldColor: string;
  learningSubFieldColor: string;
  gameMenuBg: string;
  gameMenuTrack: string;
  gameMenuRefresh: string;
  gameMenuRefreshText: string;
  gameMenuProgress: string;
  gameMenuCardBg: string;
  gameMenuCardButton: string;
  gameMenuCardButtonText: string;
  gameMenuCardRefresh: string;
  logo: string;
  showMiniLogo: boolean;
  scope: string[];
  additionalScope?: string[]; // { name: string; externalURL: string; index: number }
  connectURL: string;
  termsOfServiceLink: string;
  showObjectivesTab: boolean;
  showJobsTab: boolean;
  showInviteFriends: boolean;
  allowFormSignup: boolean;
  showFeedback: boolean;
  isPrivateLabelIcon: boolean;
  upgradeModalBody: string;
  isFreemium: boolean;
  hasEmailVerification: boolean;
  isAnalyticsEnabled: boolean;
  hasProFlow: boolean;
  hasDegrees: boolean;
  hasCourseEnrollment: boolean;
};

type ExtendedDomainConfig = DomainConfig & {
  currentDomain: string;
};

// Create a Context
const AppConfigContext = createContext<ExtendedDomainConfig | null>(null);

export const useAppConfig = () => useContext(AppConfigContext);

export const AppConfigProvider = ({
  children,
  currentDomain,
  appConfig,
}: {
  children: React.ReactNode;
  currentDomain: string;
  appConfig: DomainConfig;
}) => {
  // No need for useState for appConfig or isLoading here

  const value = {
    ...appConfig,
    currentDomain,
  };

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};
