import { useEffect, useState } from "react";
import { useAppConfig } from "../../../AppConfigProvider";
import { usePostHog } from "posthog-js/react";
import { useNavigation } from "@react-navigation/native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useLoginMutation, MeDocument } from "../../../graphql/generated/graphql";
import { useAuth } from "../../../hooks/useAuth";
import LoginForm from "../../../components/auth/LoginForm/LoginForm";
import { Platform } from "react-native";
import { announceForAccessibility } from "../../../utils/accessibility";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";
import Sentry from "../../../utils/sentry";
import { decodeToken } from "../../../contexts/AuthContext";

function LoginFormContainer() {
  const posthog = usePostHog();
  const { login, isUpdating } = useAuth();
  const appConfig = useAppConfig();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { dispatch } = useAppState();
  const [loginMutation] = useLoginMutation({
    refetchQueries: [{ query: MeDocument }],
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const isAxioOffering = appConfig.isAxioOffering;

  const domainKey = appConfig.key;

  useEffect(() => {
    posthog?.capture("Login Page Visited");
  }, []);

  useEffect(() => {
    if (Platform.OS === "ios" && errorMessage) {
      announceForAccessibility({ message: "Error alert: " + errorMessage });
    }

    if (Platform.OS === "ios" && !isEmailValid) {
      announceForAccessibility({ message: "Please enter valid email" });
    }
  }, [errorMessage, isEmailValid]);

  const handleEmailChangeText = (text) => {
    setEmail(text); // Keep what user types
    // validateEmail(text.trim().toLowerCase()); // Sanitize only for validation
  };

  const handlePasswordChangeText = (text) => {
    setPassword(text);
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    if (!isEmailValid) {
      setErrorMessage("Invalid email");
      return;
    }
    const cleanedEmail = email.trim().toLowerCase();

    try {
      const result = await loginMutation({
        variables: { email: cleanedEmail, password },
      });

      if (result.data) {
        const token = result.data.login.token;
        await login(token);

        setErrorMessage("");

        dispatch({
          type: actions.SET_META,
          payload: { user: result.data.login.user, email: cleanedEmail },
        });

        const decoded = await decodeToken(token);

        if (decoded.isEmailVerified && decoded.otpVerifiedForSession) {
          navigation.reset({
            index: 0,
            routes: [isAxioOffering ? { name: "Custom School Student" } : { name: "My Day" }],
          });
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: "Verify Email" }],
          });
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  const handleResetPasswordPress = () => {
    navigation.navigate("Reset Password");
  };

  const handleSignupPress = () => {
    navigation.navigate("Signup");
  };

  const validateEmail = (rawEmail: string) => {
    const trimmed = rawEmail.trim().toLowerCase();
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(pattern.test(trimmed));
  };

  return (
    <LoginForm
      email={email}
      password={password}
      isValidEmail={isEmailValid}
      errorMessage={errorMessage}
      companionName={domainKey}
      onEmailChangeText={handleEmailChangeText}
      onPasswordChangeText={handlePasswordChangeText}
      onEmailBlur={() => validateEmail(email)}
      onSubmit={handleSubmit}
      onResetPasswordPress={handleResetPasswordPress}
      onSignupPress={handleSignupPress}
      isLoading={isUpdating}
    />
  );
}

export default LoginFormContainer;
